<template>
  <SwitchGroup as="div" class="flex items-center">
    <Switch
      v-model="enabled"
      :class="[
        enabled ? 'bg-amber-600' : 'bg-amber-100',
        'relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-amber-600 focus:ring-offset-2',
      ]"
    >
      <span class="sr-only">Use setting</span>
      <span
        :class="[
          enabled ? 'translate-x-5' : 'translate-x-0',
          'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-amber-50 shadow ring-0 transition duration-200 ease-in-out',
        ]"
      >
        <span
          :class="[
            enabled
              ? 'opacity-0 duration-100 ease-out'
              : 'opacity-100 duration-200 ease-in',
            'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity',
          ]"
          aria-hidden="true"
        >
          <PauseIcon class="h-3 w-3 text-amber-950" />
        </span>
        <span
          :class="[
            enabled
              ? 'opacity-100 duration-200 ease-in'
              : 'opacity-0 duration-100 ease-out',
            'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity',
          ]"
          aria-hidden="true"
        >
          <PlayIcon class="h-3 w-3 text-amber-600" />
        </span>
      </span>
    </Switch>
    <SwitchLabel as="span" class="ml-2 text-sm" :class="labelColor">
      <span class="font-medium">{{ enabled ? "Active" : "Paused" }}</span>
    </SwitchLabel>
  </SwitchGroup>
</template>

<script setup>
import { useUserStore } from "@/stores/user";
import { Switch, SwitchGroup, SwitchLabel } from "@headlessui/vue";
import { computed } from "vue";
import { storeToRefs } from "pinia";
import { PauseIcon, PlayIcon } from "@heroicons/vue/20/solid";

defineProps(["labelColor"]);

const userStore = useUserStore();
const { activeEasyDataInputPayloadItem } = storeToRefs(userStore);

const enabled = computed({
  get() {
    return !activeEasyDataInputPayloadItem.value?.paused;
  },
  set(bool) {
    activeEasyDataInputPayloadItem.value.paused = !bool;
  },
});
</script>
