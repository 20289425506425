import { defineStore, acceptHMRUpdate } from "pinia";
import { ref } from "vue";
import _ from "lodash";

export const useDataFieldLoadingStore = defineStore("dataFieldLoading", () => {
  const loadingIds = ref([]);

  function isLoading(id) {
    return _.includes(loadingIds.value, id);
  }

  function patchIds(newIds) {
    loadingIds.value = _.union(newIds, loadingIds.value);
  }

  function dropId(id) {
    loadingIds.value = _.difference(loadingIds.value, [id]);
  }

  return {
    loadingIds,
    isLoading,
    patchIds,
    dropId,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useDataFieldLoadingStore, import.meta.hot),
  );
}
