<template>
  <div>
    <DataField
      :data-field="dataField"
      primary-text-path="fieldContent.name"
      class="mt-2 flex items-center"
      text-classes="text-sm font-medium"
      text-styles=""
      @unlocked="emit('unlocked')"
      @open-sourced="emit('completed')"
      @completed="emit('completed')"
    />
    <li
      v-if="file?.hostName"
      class="relative col-span-1 flex shadow-sm rounded-md"
    >
      <div
        @click="viewEmbed"
        class="flex-shrink-0 flex items-center justify-center w-16 bg-gray-200 text-white text-sm rounded-l-md cursor-pointer"
      >
        <svg
          v-if="file.hostName === 'dropbox'"
          v-tooltip="'View with Dropbox'"
          class="h-4 w-4 text-gray-800"
          fill="currentColor"
          viewBox="0 0 528 512"
        >
          <path
            d="M264.4 116.3l-132 84.3 132 84.3-132 84.3L0 284.1l132.3-84.3L0 116.3 132.3 32l132.1 84.3zM131.6 395.7l132-84.3 132 84.3-132 84.3-132-84.3zm132.8-111.6l132-84.3-132-83.6L395.7 32 528 116.3l-132.3 84.3L528 284.8l-132.3 84.3-131.3-85z"
          />
        </svg>
        <svg
          v-else-if="file.hostName === 'google'"
          v-tooltip="'View with Google Drive'"
          class="h-4 w-4 text-gray-800"
          fill="currentColor"
          viewBox="0 0 512 512"
        >
          <path
            d="M339 314.9L175.4 32h161.2l163.6 282.9H339zm-137.5 23.6L120.9 480h310.5L512 338.5H201.5zM154.1 67.4L0 338.5 80.6 480 237 208.8 154.1 67.4z"
          />
        </svg>
        <svg
          v-else-if="file.hostName === 'microsoft'"
          v-tooltip="'View with OneDrive or SharePoint'"
          class="h-4 w-4 text-gray-800"
          fill="currentColor"
          viewBox="0 0 448 512"
        >
          <path
            d="M0 32h214.6v214.6H0V32zm233.4 0H448v214.6H233.4V32zM0 265.4h214.6V480H0V265.4zm233.4 0H448V480H233.4V265.4z"
          />
        </svg>
        <svg
          v-else-if="file.hostName === 'tower_hunt'"
          v-tooltip="'View uploaded file'"
          class="h-4 w-4 text-gray-800"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path
            fill-rule="evenodd"
            d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z"
            clip-rule="evenodd"
          />
        </svg>
        <svg
          v-else-if="file.hostName === 'url'"
          v-tooltip="'View linked file'"
          class="h-4 w-4 text-gray-800"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path
            fill-rule="evenodd"
            d="M12.586 4.586a2 2 0 112.828 2.828l-3 3a2 2 0 01-2.828 0 1 1 0 00-1.414 1.414 4 4 0 005.656 0l3-3a4 4 0 00-5.656-5.656l-1.5 1.5a1 1 0 101.414 1.414l1.5-1.5zm-5 5a2 2 0 012.828 0 1 1 0 101.414-1.414 4 4 0 00-5.656 0l-3 3a4 4 0 105.656 5.656l1.5-1.5a1 1 0 10-1.414-1.414l-1.5 1.5a2 2 0 11-2.828-2.828l3-3z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
      <div
        class="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate"
      >
        <div class="flex-1 px-4 py-2 text-sm truncate">
          <a
            href=""
            @click.prevent="viewEmbed"
            v-tooltip="file.name"
            class="text-gray-900 font-medium hover:text-gray-600"
            >{{ $filters.gutMiddle(file.name, 20) }}</a
          >
          <p class="flex items-center text-gray-500">
            <span>File</span>
          </p>
        </div>
      </div>
    </li>
  </div>
</template>

<script setup>
import DataField from "@/components/crowdsourcing/DataField.vue";
import DropboxEmbedViewer from "@/components/DropboxEmbedViewer.vue";
import IFrameEmbedViewer from "@/components/IFrameEmbedViewer.vue";
import { computed, markRaw } from "vue";
import { useModalStore } from "@/stores/modal";
import { useUploadedFileStore } from "@/stores/uploadedFile";
import { storeToRefs } from "pinia";
import _ from "lodash";

const props = defineProps(["dataField"]);
const emit = defineEmits(["unlocked", "completed"]);
const uploadedFileStore = useUploadedFileStore();
const modalStore = useModalStore();
const { modalPayload } = storeToRefs(modalStore);
const file = computed(() => {
  return _.get(props.dataField, "fieldContent", null);
});

function viewEmbed() {
  let component = null;

  switch (file.value.hostName) {
    case "dropbox":
      component = DropboxEmbedViewer;
      break;
    case "google":
    case "microsoft":
      component = IFrameEmbedViewer;
      break;
    case "tower_hunt":
    default:
      uploadedFileStore.viewEmbed(file);
  }

  if (component) {
    modalPayload.value = {
      size: "xl",
      theme: "dark",
      component: markRaw(component),
      props: { file, document: file },
    };
  }
}
</script>
