<template>
  <div class="mt-4 p-2">
    <div class="flex items-center">
      <div class="flex-auto">
        <h1 class="text-base font-semibold leading-6 text-gray-900">
          Leases / Licenses
        </h1>
        <p class="mt-2 text-sm text-gray-700">
          Use this table to identify datapoints of interest.
        </p>
      </div>
    </div>
    <div v-if="zoom >= 11" class="mt-4 flow-root">
      <div
        v-if="refreshingMainTimeline || fetchingSpaceUsageAvailabilityFields"
        class="mt-6 flex h-full w-full items-center justify-center"
      >
        <SquareLoader :loading="true" size="32px" color="#0d9488" />
      </div>
      <div
        v-else-if="paginatedRecords.length > 0"
        class="-my-2 overflow-x-auto"
      >
        <div class="inline-block min-w-full py-2 align-middle">
          <table class="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th scope="col" class="relative whitespace-nowrap p-3">
                  <span class="sr-only">Expand</span>
                </th>
                <th
                  scope="col"
                  class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Space Usage(s)
                </th>
                <th
                  scope="col"
                  class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Area
                </th>
                <th
                  scope="col"
                  class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Size (SF)
                </th>
                <template v-if="workspaceLayout === 'sideBySide'">
                  <th
                    scope="col"
                    class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Term (Mths)
                  </th>
                  <th
                    scope="col"
                    class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    In-Place Rent
                  </th>
                </template>
                <template v-else>
                  <th
                    scope="col"
                    class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    LCD
                  </th>
                  <th
                    scope="col"
                    class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    LXD
                  </th>
                  <th
                    scope="col"
                    class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Term (Mths)
                  </th>
                  <th
                    scope="col"
                    class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Rent Schedule
                  </th>
                </template>

                <th
                  v-if="false"
                  scope="col"
                  class="relative whitespace-nowrap py-3.5 pl-3"
                >
                  <span class="sr-only">Edit</span>
                </th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200 bg-white">
              <SpaceUsageTableRow
                v-for="(record, index) in paginatedRecords"
                :key="`${record.rowType}${index}`"
                :record="record"
              />
            </tbody>
            <InfiniteLoading
              v-if="loaded && !reachedEnd"
              @infinite="infiniteNext"
            />
          </table>
        </div>
      </div>
      <div
        v-else
        class="relative block w-full rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        <FunnelIcon class="mx-auto h-12 w-12 text-gray-400" />
        <span class="mt-2 block text-sm font-medium text-gray-900">
          No matching space usages
        </span>
      </div>
    </div>
    <section v-else class="mt-4">
      <div
        class="relative block w-full rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        <MagnifyingGlassPlusIcon class="mx-auto h-12 w-12 text-gray-400" />
        <span class="mt-2 block text-sm font-medium text-gray-900">
          Zoom in more
        </span>
      </div>
    </section>
  </div>
</template>

<script setup>
import SquareLoader from "vue-spinner/src/SquareLoader.vue";
import SpaceUsageTableRow from "@/components/analyze/SpaceUsageTableRow.vue";
import { MagnifyingGlassPlusIcon, FunnelIcon } from "@heroicons/vue/24/outline";
import { useTimeTravelStore } from "@/stores/timeTravel";
import { useAnalyzePanelStore } from "@/stores/analyzePanel";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { useMainMapStore } from "@/stores/mainMap";
import { storeToRefs } from "pinia";
import { onMounted, ref, watch } from "vue";
import _ from "lodash";

const timeTravelStore = useTimeTravelStore();
const { refreshingMainTimeline } = storeToRefs(timeTravelStore);
const mapStore = useMainMapStore();
const { zoom } = storeToRefs(mapStore);
const analyzePanelStore = useAnalyzePanelStore();
const {
  lastCheckedPolygonArea,
  displayableSpaceUsageTableRows,
  spaceUsageCount,
  fetchingSpaceUsageAvailabilityFields,
} = storeToRefs(analyzePanelStore);
const layoutStore = useWorkspaceLayoutStore();
const { workspaceLayout } = storeToRefs(layoutStore);

const nearbyPageIncrement = 5;
const fetchedNearbyIndex = ref(0);
const loaded = ref(false);
const reachedEnd = ref(false);
const paginatedRecords = ref([]);

watch(spaceUsageCount, () => {
  if (loaded.value) {
    debouncedReset();
  }
});

watch(lastCheckedPolygonArea, () => {
  if (loaded.value) {
    debouncedReset();
  }
});

onMounted(() => {
  if (fetchedNearbyIndex.value === 0) {
    nextNearbyPage("initial");
  }
});

function reset() {
  paginatedRecords.value = [];
  fetchedNearbyIndex.value = 0;
  reachedEnd.value = false;
  nextNearbyPage();
}

const debouncedReset = _.debounce(function () {
  reset();
}, 2000);

const infiniteNext = async ($state) => {
  if (!reachedEnd.value) {
    try {
      nextNearbyPage();
    } catch (error) {
      $state.error();
    }
  } else {
    $state.complete();
  }
};

function nextNearbyPage(loadState = null) {
  const arr = displayableSpaceUsageTableRows.value;
  const length = arr.length;
  const start = fetchedNearbyIndex.value;
  const end = _.min([length, fetchedNearbyIndex.value + nearbyPageIncrement]);
  const newSlice = _.slice(arr, start, end);

  newSlice.forEach((element) => {
    paginatedRecords.value.push(element);
  });

  if (paginatedRecords.value.length !== length) {
    fetchedNearbyIndex.value = fetchedNearbyIndex.value + nearbyPageIncrement;
  } else {
    reachedEnd.value = true;
  }

  if (loadState === "initial") loaded.value = true;
}
</script>
