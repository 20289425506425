<template>
  <div class="">
    <h3 class="text-base leading-6 font-medium text-gray-900">
      Shared Payout Accounts
    </h3>
    <p class="mt-2 max-w-xl text-sm leading-5 text-gray-500">
      Payout accounts that can be connected to a
      <a href="" @click.prevent="goToTeams" class="underline">team</a>, allowing
      multiple users to pool earnings. Tower Hunt partners with
      <a href="https://stripe.com" target="_blank" class="underline">Stripe</a>
      to manage payments and payouts.
    </p>
    <div v-if="accounts.length > 0" class="flow-root mt-6">
      <ul class="-my-5 divide-y divide-gray-200">
        <SharedPayoutAccountRow
          v-for="account in accounts"
          :key="account.id"
          :account="account"
          @refetch="fetchAccounts"
        />
      </ul>
    </div>
    <div class="mt-6">
      <a
        href=""
        @click.prevent="newAccount"
        :disabled="creating"
        :class="creating ? 'cursor-not-allowed' : ''"
        class="w-full flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
        data-test="new-shared-payout-account-button"
      >
        Setup new account
      </a>
    </div>
  </div>
</template>

<script setup>
import SharedPayoutAccountRow from "@/components/users/SharedPayoutAccountRow.vue";
import api from "@/router/api";
import { onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

const accounts = ref([]);
const creating = ref(false);

onMounted(async () => {
  fetchAccounts();
});

async function fetchAccounts() {
  api.get(`shared_payout_accounts`).then((json) => {
    accounts.value = json.data;
  });
}
async function newAccount() {
  creating.value = true;
  api.post(`shared_payout_accounts`).then(() => {
    creating.value = false;
    fetchAccounts();
  });
}

const route = useRoute();
const router = useRouter();

function goToTeams() {
  router.push({
    name: route.name,
    query: {
      ...route.query,
      horizontalTab: "Me",
      verticalTab: "Teams",
    },
  });
}
</script>
