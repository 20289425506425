<template>
  <div class="h-full flex flex-col">
    <MigrationWelcomeBanner v-if="!signedIn" />
    <UnverifiedEmailBanner v-else-if="emailUnverified" />
    <EasyDataActiveInputPayloadItemBanner
      v-else-if="activeEasyDataInputPayloadItem"
    />
    <Disclosure as="nav" class="bg-white border-b border-gray-300">
      <div class="mx-auto px-6">
        <div id="shell-header-banner" class="flex h-16 justify-between">
          <div class="flex items-center space-x-4">
            <HomeLogo />
            <li v-if="hasDetailPageLabels" class="flex h-full">
              <div class="flex items-center">
                <svg
                  class="shrink-0 w-6 h-full text-gray-200"
                  viewBox="0 0 24 44"
                  preserveAspectRatio="none"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                >
                  <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                </svg>
                <span class="flex items-center space-x-2 ml-4 font-medium">
                  <LinkIcon
                    v-if="
                      contactLabel &&
                      contactIdParam == currentUser?.linkedContactId
                    "
                    v-tooltip="'Contact linked to your Tower Hunt user account'"
                    class="h-5 w-5 text-green-400"
                  />
                  <span
                    class="truncate font-semibold text-lg text-gray-700"
                    data-test="detail-page-label"
                    >{{ contactLabel || companyLabel || propertyLabels }}</span
                  >
                  <span
                    class="flex items-center"
                    :class="
                      timeTravelTo ? 'text-red-500 font-bold' : 'text-gray-500'
                    "
                  >
                    <CalendarDaysIcon class="shrink-0 mr-1.5 h-4 w-4" />
                    <p class="shrink-0 text-sm">
                      as of
                      <time :datetime="asOfDate">{{
                        moment(asOfDate).format("MMM YYYY")
                      }}</time>
                    </p>
                  </span>
                </span>
              </div>
            </li>
          </div>
          <div
            v-if="!activeEasyDataInputPayloadItem && !isAdmin"
            class="flex items-center"
          >
            <DataInputEasyButton />
          </div>
          <div class="ml-6 flex items-center space-x-3">
            <template v-if="signedIn">
              <KeyPlatformActions />
              <LayoutSelector />
              <UserMenu />
            </template>
            <template v-else>
              <ViewHelpButton />
              <LayoutSelector />
              <a
                @click.prevent="logIn('SignIn')"
                href=""
                class="whitespace-nowrap text-sm leading-4 p-2 font-medium text-gray-500 hover:text-gray-900 focus:outline-none focus:text-gray-900"
                data-test="sign-in-button"
              >
                Sign in
              </a>
              <a
                @click.prevent="logIn('Register')"
                href=""
                class="whitespace-nowrap bg-indigo-100 border border-transparent rounded-md p-2 inline-flex items-center justify-center text-sm leading-4 font-medium text-indigo-700 hover:text-indigo-600 hover:bg-indigo-50 focus:outline-none focus:ring-indigo focus:border-indigo-300 active:bg-indigo-200"
                data-test="sign-up-button"
              >
                Sign up
              </a>
            </template>
          </div>
        </div>
      </div>
    </Disclosure>

    <div
      id="workspace-shell-grid-container"
      class="flex-grow grid w-full h-full overflow-hidden"
      :style="gridLayout"
    >
      <RouterView />
      <template v-if="workspaceLayout !== 'fullScreen'">
        <div
          id="workspace-shell-split-gutter"
          class="bg-indigo-600 hover:bg-indigo-800"
          :style="gutterStyles"
          data-test="grid-gutter"
        >
          <div
            class="text-gray-300 hover:text-gray-200 flex w-full h-full place-content-center"
          >
            <svg
              v-if="workspaceLayout === 'topAndBottom'"
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 place-self-center"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              data-test="top-and-bottom-gutter"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"
              />
            </svg>
            <svg
              v-else-if="workspaceLayout === 'sideBySide'"
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 place-self-center"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              data-test="side-by-side-gutter"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
              />
            </svg>
          </div>
        </div>
        <SecondaryPanel />
      </template>
    </div>
  </div>
</template>

<script setup>
import { Disclosure } from "@headlessui/vue";
import { CalendarDaysIcon, LinkIcon } from "@heroicons/vue/20/solid";
import { useUserStore } from "@/stores/user";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { useSecondaryPanelStore } from "@/stores/secondaryPanel";
import { usePropertyDiagramStore } from "@/stores/propertyDiagram";
import { useCompanyDetailStore } from "@/stores/companyDetail";
import { useContactDetailStore } from "@/stores/contactDetail";
import { useTimeTravelStore } from "@/stores/timeTravel";
import { useMainMapStore } from "@/stores/mainMap";
import { useAnalyzePanelStore } from "@/stores/analyzePanel";
import { storeToRefs } from "pinia";
import { ref, computed, watch, onMounted, nextTick } from "vue";
import { useRouter, useRoute } from "vue-router";
import moment from "moment";
import Split from "split-grid";
import LayoutSelector from "@/components/main-layout/LayoutSelector.vue";
import SecondaryPanel from "@/components/main-layout/SecondaryPanel.vue";
import UserMenu from "@/components/users/UserMenu.vue";
import KeyPlatformActions from "@/components/main-layout/KeyPlatformActions.vue";
import MigrationWelcomeBanner from "@/components/users/MigrationWelcomeBanner.vue";
import EasyDataActiveInputPayloadItemBanner from "@/components/users/EasyDataActiveInputPayloadItemBanner.vue";
import HomeLogo from "@/components/HomeLogo.vue";
import DataInputEasyButton from "@/components/DataInputEasyButton.vue";
import _ from "lodash";
import UnverifiedEmailBanner from "@/components/users/UnverifiedEmailBanner.vue";
import ViewHelpButton from "@/components/main-layout/ViewHelpButton.vue";

const userStore = useUserStore();
const {
  emailUnverified,
  activeEasyDataInputPayloadItem,
  signedIn,
  isAdmin,
  currentUser,
} = storeToRefs(userStore);
const secondaryPanelStore = useSecondaryPanelStore();
const layoutStore = useWorkspaceLayoutStore();
const { workspaceLayout, workspaceResized, isDesktop } =
  storeToRefs(layoutStore);
const propertyDiagramStore = usePropertyDiagramStore();
const { propertyIdParam, propertyDiagramPropertyDataFields } =
  storeToRefs(propertyDiagramStore);
const companyDetailStore = useCompanyDetailStore();
const { companyIdParam, companyDataField } = storeToRefs(companyDetailStore);
const contactDetailStore = useContactDetailStore();
const { contactIdParam, contactDataField } = storeToRefs(contactDetailStore);
const timeTravelStore = useTimeTravelStore();
const { asOfDate, timeTravelTo } = storeToRefs(timeTravelStore);
const analyzePanelStore = useAnalyzePanelStore();
const { analyzeDisplayable } = storeToRefs(analyzePanelStore);
const mapStore = useMainMapStore();
const { map } = storeToRefs(mapStore);
const split = ref(null);
const temporaryMapBounds = ref(null);

onMounted(async () => {
  split.value = Split({
    columnMinSize: 450,
    rowMinSize: 200,
    rowMaxSize: 500,
    onDragStart: function () {
      if (map.value) {
        temporaryMapBounds.value = map.value?.getBounds();
      }
    },
    onDragEnd: function () {
      resizeMap();
    },
    columnGutters: [
      {
        track: 1,
        element: document.querySelector("#workspace-shell-split-gutter"),
      },
    ],
  });

  await nextTick();
  updateGridTracks();
  setTabsFromQuery();
});

const gridLayout = computed(() => {
  switch (workspaceLayout.value) {
    case "sideBySide":
      return "grid-template-columns: 3fr 10px 450px;";
    case "topAndBottom":
      return "grid-template-rows: 1fr 10px 1fr;";
    default:
      return "";
  }
});
const gutterStyles = computed(() => {
  switch (workspaceLayout.value) {
    case "sideBySide":
      return "grid-row: 1/-1; cursor: col-resize; grid-column: 2;";
    case "topAndBottom":
      return "grid-column: 1/-1; cursor: row-resize; grid-row: 2;";
    default:
      return "";
  }
});
const hasDetailPageLabels = computed(() => {
  return contactLabel.value || companyLabel.value || propertyLabels.value;
});
const companyLabel = computed(() => {
  if (companyIdParam.value && companyDataField.value) {
    return companyDataField.value.fieldContent.name;
  } else {
    return null;
  }
});
const contactLabel = computed(() => {
  if (contactIdParam.value && contactDataField.value) {
    return contactDataField.value.fieldContent.name;
  } else {
    return null;
  }
});
const propertyLabels = computed(() => {
  if (
    propertyIdParam.value &&
    _.size(propertyDiagramPropertyDataFields.value) > 0
  ) {
    return _.map(propertyDiagramPropertyDataFields.value, function (dataField) {
      const airLayer = propertyDiagramStore.propertyIsAirLayer(
        dataField.fieldContentId,
      );

      if (airLayer) {
        return `${dataField.fieldContent.name} (Air Rights)`;
      } else {
        return dataField.fieldContent.name;
      }
    }).join(", ");
  } else {
    return null;
  }
});
watch(workspaceLayout, () => {
  updateGridTracks();
});
watch(contactLabel, () => {
  if (contactLabel.value) {
    document.title = `${contactLabel.value} | Tower Hunt`;
  }
});
watch(companyLabel, () => {
  if (companyLabel.value) {
    document.title = `${companyLabel.value} | Tower Hunt`;
  }
});
watch(propertyLabels, () => {
  if (propertyLabels.value) {
    document.title = `${propertyLabels.value} | Tower Hunt`;
  }
});
function resizeMap() {
  workspaceResized.value = temporaryMapBounds.value || true;
}
function clearSplit() {
  split.value.removeRowGutter(1);
  split.value.removeColumnGutter(1);
}
function updateGridTracks() {
  switch (workspaceLayout.value) {
    case "sideBySide":
      setTimeout(() => {
        clearSplit();
        split.value.addColumnGutter(
          document.querySelector("#workspace-shell-split-gutter"),
          1,
        );
      }, 50);
      break;
    case "topAndBottom":
      setTimeout(() => {
        clearSplit();
        split.value.addRowGutter(
          document.querySelector("#workspace-shell-split-gutter"),
          1,
        );
      }, 50);
      break;
    case "fullScreen":
      clearSplit();
      break;
    default:
      break;
  }
}

const router = useRouter();
const route = useRoute();
const query = computed(() => route.query);

watch(query, async (val, oldVal) => {
  if (val?.horizontalTab !== oldVal?.horizontalTab) {
    setTabsFromQuery();
  }
});
watch(analyzeDisplayable, () => {
  if (query.value?.horizontalTab === "Analyze") {
    if (analyzeDisplayable.value) {
      setTabsFromQuery();
    } else {
      if (propertyIdParam.value) {
        router.push({
          name: route.name,
          query: {
            ...route.query,
            horizontalTab: "Details",
            verticalTab: undefined,
          },
        });
      } else {
        router.push({
          name: route.name,
          query: {
            ...route.query,
            horizontalTab: "Me",
            verticalTab: "News",
          },
        });
      }
    }
  }
});

function setTabsFromQuery() {
  if (_.isEmpty(query.value));
  else {
    secondaryPanelStore.setTabsFromQuery();
  }
}

function logIn(destination) {
  if (isDesktop.value) {
    secondaryPanelStore.setAuthenticate({ name: destination });
    router.push({
      name: route.name,
      query: {
        ...route.query,
        horizontalTab: "Authenticate",
        verticalTab: destination,
      },
    });
  } else {
    router.push({ name: destination });
  }
}
</script>
