<template>
  <main v-if="dataField && tabs && selectedTab" class="h-full flex flex-col">
    <div class="p-2 flex items-center space-x-2 bg-indigo-600 text-white">
      <span>for</span>
      <DataField
        :data-field="focalDataField"
        text-classes="font-semibold"
        text-styles=""
        @unlocked="unlocked"
        @completed="completed"
        @open-sourced="completed"
      />
      <template v-if="displaysPropertyDataField">
        <span>at</span>
        <DataField
          :data-field="propertyDataField"
          primary-text-path="fieldContent.name"
          text-classes="font-semibold"
          text-styles=""
          @unlocked="unlocked"
          @completed="completed"
          @open-sourced="completed"
        />
      </template>
    </div>
    <div class="flex flex-1">
      <!-- Narrow sidebar-->
      <nav
        aria-label="Sidebar"
        class="flex-shrink-0 p-2 bg-gray-100 overflow-y-auto"
      >
        <div class="relative flex flex-col space-y-2">
          <a
            v-for="tab in tabs"
            :key="tab.name"
            @click.prevent="selectTab(tab)"
            :href="tab.href"
            v-tooltip="tab.name"
            :class="[
              selectedTab.name === tab.name
                ? 'text-gray-700 bg-gray-300'
                : 'text-gray-500 hover:bg-gray-200',
              'flex-shrink-0 inline-flex items-center justify-center h-8 w-8 rounded-lg',
            ]"
            :data-test="`${_.kebabCase(tab.tabQuery)}-nav`"
          >
            <span class="sr-only">{{ tab.name }}</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              :class="selectedTab.name === tab.name ? '' : ''"
              class="h-5 w-5"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 24 24"
              v-html="pathsForIcon(tab.svgPaths)"
            ></svg>
          </a>
        </div>
      </nav>

      <div
        v-if="display"
        class="flex-grow overflow-y-auto"
        :class="fromMenu ? 'max-h-[350px]' : ''"
      >
        <component :is="selectedTab.component" v-bind="selectedTabProperties" />
      </div>
    </div>
  </main>
</template>

<script setup>
import DataField from "@/components/crowdsourcing/DataField.vue";
import {
  initialSelectedTabFor,
  tabPropertiesFor,
  tabsFor,
} from "@/components/crowdsourcing/dataFieldDetailsTabs";
import { useRoute, useRouter } from "vue-router";
import { computed, ref, onMounted, watch, nextTick } from "vue";
import { usePropertyDiagramStore } from "@/stores/propertyDiagram";
import { useCompanyDetailStore } from "@/stores/companyDetail";
import { useContactDetailStore } from "@/stores/contactDetail";
import { storeToRefs } from "pinia";
import pathsForIcon from "@/assets/pathsForIcon";
import _ from "lodash";

const props = defineProps([
  "dataField",
  "decoratingContent",
  "decoratingContentType",
  "fromMenu",
]);
const propertyDiagramStore = usePropertyDiagramStore();
const {
  propertyIdParam,
  propertyDiagramRefetch,
  propertyDataField,
  propertySelectedRecordDataField,
} = storeToRefs(propertyDiagramStore);
const companyDetailStore = useCompanyDetailStore();
const { companyIdParam } = storeToRefs(companyDetailStore);
const contactDetailStore = useContactDetailStore();
const { contactIdParam } = storeToRefs(contactDetailStore);
const refreshingTabs = ref(false);
const display = ref(true);
const tabs = ref(tabsFor(props.dataField));
const selectedTab = ref(null);
const dataFieldLocalId = computed(() => _.get(props.dataField, "localId"));
const selectedTabProperties = computed(() => tabPropertiesFor(props.dataField));
const horizontalIsDetails = computed(
  () => _.get(query.value, "horizontalTab") === "Details",
);
const focalDataField = computed(() => {
  if (contactIdParam.value || companyIdParam.value || props.fromMenu) {
    return props.dataField;
  } else if (propertySelectedRecordDataField.value) {
    return propertySelectedRecordDataField.value;
  } else {
    return null;
  }
});

const router = useRouter();
const route = useRoute();
const query = computed(() => route.query);
const verticalTab = computed(() => _.get(query.value, "verticalTab"));
const hasVerticalTab = computed(() => !!verticalTab.value);
const displaysPropertyDataField = computed(() => {
  return (
    propertyIdParam.value &&
    propertySelectedRecordDataField.value?.localId !==
      propertyDataField.value?.localId
  );
});

onMounted(() => {
  refreshTabs();
});

watch(dataFieldLocalId, () => {
  refreshTabs();
});

watch(horizontalIsDetails, () => {
  if (horizontalIsDetails.value) refreshTabs();
});
watch(verticalTab, () => {
  if (hasVerticalTab.value && !refreshingTabs.value) refreshTabs();
});

async function refreshTabs() {
  refreshingTabs.value = true;
  display.value = false;
  tabs.value = tabsFor(props.dataField);
  selectTab().then(async () => {
    await nextTick();
    display.value = true;
    refreshingTabs.value = false;
  });
}

async function selectTab(tab) {
  const oldTabQuery = _.get(selectedTab.value, "tabQuery");

  return new Promise((resolve) => {
    if (tab) selectedTab.value = tab;
    else if (dataFieldLocalId.value && horizontalIsDetails) {
      if (hasVerticalTab.value) {
        const matchingTab = _.find(tabs.value, {
          tabQuery: query.value.verticalTab,
        });

        if (matchingTab) selectedTab.value = matchingTab;
        else selectedTab.value = initialSelectedTabFor(props.dataField);
      } else if (props.dataField) {
        selectedTab.value = initialSelectedTabFor(props.dataField);
      }
    }

    const newTabQuery = _.get(selectedTab.value, "tabQuery");

    if (selectedTab.value && newTabQuery !== oldTabQuery && !props.fromMenu) {
      router.push({
        name: route.name,
        query: {
          ...route.query,
          verticalTab: selectedTab.value.tabQuery,
        },
      });
    }

    resolve();
  });
}

function refetch() {
  if (contactIdParam.value) {
    contactDetailStore.refetchDetails();
  } else if (companyIdParam.value) {
    companyDetailStore.refetchDetails();
  } else if (propertySelectedRecordDataField.value) {
    propertyDiagramRefetch.value = propertySelectedRecordDataField.value;
  }
}

function unlocked() {
  refetch();
}
function completed() {
  refetch();
}
</script>
