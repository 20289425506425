<template>
  <div class="flex items-center space-x-2">
    <select
      @change="setSelectedValue($event)"
      id="investment_state"
      name="investment_state"
      data-test="investment-state-select"
      class="flex-grow pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
    >
      <option
        v-for="option in dropdownOptions"
        :key="option.value"
        :value="option.value"
      >
        {{ option.name }}
      </option>
    </select>
    <template v-if="selectedValue">
      <div class="flex items-center justify-end space-x-2">
        <button
          @click="cancel"
          type="button"
          class="inline-flex items-center p-1 border border-gray-300 rounded-full shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <XMarkIcon class="h-4 w-4" />
        </button>

        <DataVisibilityButton
          :visibility="visibility"
          :tooltip="`Save status`"
          class="inline-flex"
        >
          <template v-slot:button>
            <button
              @click="save(null)"
              :disabled="originatingData"
              type="button"
              :class="
                visibility === 'safezone'
                  ? 'bg-yellow-500 hover:bg-yellow-600 focus:ring-yellow-600'
                  : 'bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500'
              "
              class="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2"
              data-test="investment-state-save"
            >
              <PulseLoader
                v-if="originatingData"
                :loading="true"
                size="3px"
                color="#f3f4f6"
              />
              <CheckIcon v-else class="h-4 w-4" />
            </button>
          </template>
        </DataVisibilityButton>
      </div>
    </template>
  </div>
</template>

<script setup>
import { XMarkIcon, CheckIcon } from "@heroicons/vue/20/solid";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import DataVisibilityButton from "@/components/crowdsourcing/DataVisibilityButton.vue";
import { useCrowdsourcedChangeGroupStore } from "@/stores/crowdsourcedChangeGroup";
import { useDealBuilderStore } from "@/stores/dealBuilder";
import { useTimeTravelStore } from "@/stores/timeTravel";
import { useNotificationsStore } from "@/stores/notifications";
import { useProveForValueStore } from "@/stores/proveForValue";
import { storeToRefs } from "pinia";
import api from "@/router/api";
import milestones from "@/assets/contentMilestones";
import _ from "lodash";
import { ref, computed, onMounted } from "vue";
import { useRoute } from "vue-router";

const props = defineProps([
  "decoratingDataField",
  "existingState",
  "fetchRequestKey",
  "parentSelectedValue",
  "investmentGroupId",
  "availabilityGroupId",
  "challengeDataField",
  "proveForValue",
  "fieldNameModifier",
  "context",
]);
const emit = defineEmits([
  "unlocked",
  "completed",
  "cancel",
  "set-selected-value",
]);

const notificationsStore = useNotificationsStore();
const proveForValueStore = useProveForValueStore();
const changeGroupStore = useCrowdsourcedChangeGroupStore();
const { changeGroupId, originatingData } = storeToRefs(changeGroupStore);
const dealBuilderStore = useDealBuilderStore();
const timeTravelStore = useTimeTravelStore();

const visibility = computed(() =>
  props.proveForValue ? "public" : "safezone",
);

const selectedValue = ref(null);
const dropdownOptions = computed(() => {
  let matchingOptions = null;
  if (props.investmentGroupId || props.availabilityGroupId) {
    switch (props.context) {
      case "contract":
        matchingOptions = _.get(milestones, "SpaceUsage", []);
        break;
      case "occupancy":
        matchingOptions = _.get(milestones, "SpaceUsageOccupancy", []);
        break;
      default:
        matchingOptions = _.get(milestones, "Investment", []);
        break;
    }
  } else if (props.decoratingDataField.fieldContentType === "PropertyRight") {
    matchingOptions = _.get(
      milestones,
      `PropertyRight[${props.decoratingDataField.fieldContent.type}]`,
      [],
    );
  } else if (props.fieldNameModifier === "occupancy_state") {
    matchingOptions = _.get(milestones, "SpaceUsageOccupancy", []);
  } else {
    matchingOptions = _.get(
      milestones,
      props.decoratingDataField.fieldContentType,
      [],
    );
  }

  return _.sortBy(matchingOptions, ["order"]);
});

onMounted(() => {
  console.log(
    "set default selected value",
    _.head(dropdownOptions.value).value,
  );
  if (props.parentSelectedValue) {
    selectedValue.value = props.parentSelectedValue;
  } else {
    if (_.get(props.existingState, "fieldValue")) {
      selectedValue.value = _.find(dropdownOptions.value, {
        value: props.existingState.fieldValue,
      }).value;
    } else {
      selectedValue.value = _.head(dropdownOptions.value).value;
    }
  }
});

function setSelectedValue(event) {
  const newSelectedValue = event.target.value;
  console.log("dropdown set selected value", newSelectedValue);

  if (props.parentSelectedValue) {
    emit("set-selected-value", newSelectedValue);
    selectedValue.value = newSelectedValue;
  } else {
    selectedValue.value = newSelectedValue;
  }
}

function cancel() {
  selectedValue.value = null;
  emit("cancel");
}

function save(newProofStatus) {
  const apiRequestFunc = () => persist(newProofStatus);
  const successCallback = (json) => afterPersist(json);
  const failureCallback = () => cancel();
  return changeGroupStore.originateData(
    apiRequestFunc,
    successCallback,
    failureCallback,
  );
}

async function persist(newProofStatus) {
  if (props.proveForValue && !newProofStatus) {
    let payload = {
      fieldValue: selectedValue.value,
      fieldValueType: "string",
      isInner: false,
    };
    if (props.investmentGroupId) {
      payload.investmentGroupId = props.investmentGroupId;
    } else if (props.availabilityGroupId) {
      payload.availabilityGroupId = props.availabilityGroupId;
    }
    const proofResponse = await proveForValueStore.submitProof(
      props.challengeDataField,
      payload,
    );

    return proofResponse;
  } else {
    let payload = { changeGroupId: changeGroupId.value };
    let response;

    if (props.investmentGroupId) {
      payload.investmentGroupId = props.investmentGroupId;
      payload.state = selectedValue.value;

      response = await api.post(`investment_group_states`, payload);
    } else if (props.availabilityGroupId) {
      payload.availabilityGroupId = props.availabilityGroupId;
      payload.state = selectedValue.value;

      response = await api.post(`availability_group_states`, payload);
    } else {
      payload.fieldValue = selectedValue.value;
      const contentType = props.decoratingDataField.fieldContentType;
      const contentId = props.decoratingDataField.fieldContent.id;

      if (props.fieldNameModifier !== "state") {
        payload.fieldNameModifier = props.fieldNameModifier;
      }

      response = await api.post(
        `content_states/${contentType}/${contentId}`,
        payload,
      );
    }

    if (newProofStatus === "rejected") {
      if (response.data.dataField) {
        const declassifyPayload = {
          id: response.data.dataField.localId,
        };

        const declassifiedResponse = await api.post(
          `declassify_datapoint`,
          declassifyPayload,
        );
        response.data.dataField = declassifiedResponse.data;
      } else if (response.data.dataFields) {
        const declassifyPayload = {
          ids: response.data.dataFields.map((field) => field.localId),
        };
        await api.post(`declassify_datapoints`, declassifyPayload);
      }

      notificationsStore.addNotification("proofRejected");
    }

    return response;
  }
}

const route = useRoute();
const query = computed(() => route.query);
const horizontalIsDeals = computed(
  () => _.get(query.value, "horizontalTab") === "Deals",
);

async function afterPersist(json) {
  if (json.data?.proofStatus === "rejected") {
    if (props.investmentGroupId || props.availabilityGroupId) {
      await dismissGroupState();
    } else {
      await changeGroupStore.dismissData({
        dataFieldId: props.challengeDataField.localId,
        successCallback: () => {},
      });
      save(json.data.proofStatus);
    }
  } else if (json.data?.proofStatus === "accepted") {
    notificationsStore.addNotification("proofAccepted");
    emit("unlocked", { override: "state" });
  } else if (props.investmentGroupId || props.availabilityGroupId) {
    emit("completed", { dataField: _.head(json.data?.dataFields) });
  } else {
    await dismissAndUpdate(json);
  }
}

async function dismissAndUpdate(persistJson) {
  const successCallback = async (dismissJson) => {
    const stateDataField = _.find(persistJson.data.dataFields, {
      fieldName: props.fieldNameModifier,
      deprecatedAt: null,
    });

    if (
      props.existingState &&
      props.existingState.fieldValue !== stateDataField?.fieldValue
    ) {
      timeTravelStore.triggerRefetch();
    } else {
      if (horizontalIsDeals.value && props.fetchRequestKey) {
        dealBuilderStore.clearDealBuilder(false);
        await dealBuilderStore.postEditingPatch(dismissJson);
        await dealBuilderStore.postEditingPatch(
          persistJson,
          props.fetchRequestKey,
        );
      }
      emit("completed", { dataField: stateDataField });
    }
    cancel();
  };

  await dismiss(props.existingState.localId, successCallback);
}

async function dismiss(id, successCallback = () => {}) {
  await changeGroupStore.dismissData({
    dataFieldId: id,
    byUser: false,
    successCallback,
  });
}

async function dismissGroupState() {
  if (props.investmentGroupId) {
    let payload = {
      investmentGroupId: props.investmentGroupId,
      destroyableFieldId: props.challengeDataField.localId,
      changeGroupId: changeGroupId.value,
    };

    await api.post(`investment_group_state_deletions`, payload);

    save("rejected");
  } else if (props.availabilityGroupId) {
    let payload = {
      availabilityGroupId: props.availabilityGroupId,
      destroyableFieldId: props.challengeDataField.localId,
      changeGroupId: changeGroupId.value,
    };

    await api.post(`availability_group_state_deletions`, payload);

    save("rejected");
  } else {
    return null;
  }
}
</script>
