import { ref } from "vue";
import { defineStore, acceptHMRUpdate } from "pinia";
import _ from "lodash";

export const useDataInputJobStatusesChannelStore = defineStore(
  "dataInputJobStatusesChannel",
  () => {
    const DataInputJobStatusesChannel = ref(null);

    return {
      DataInputJobStatusesChannel,
    };
  },
);

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useDataInputJobStatusesChannelStore, import.meta.hot),
  );
}
