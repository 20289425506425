<template>
  <div class="flex flex-wrap items-center space-x-2">
    <li
      v-for="(payoutAccount, index) in payoutAccounts"
      :key="payoutAccount.id"
      class="inline-flex rounded-full items-center my-1 py-0.5 px-1 space-x-1 text-sm font-medium bg-emerald-100 text-emerald-700"
      :data-test="`member-${member.memberId}-payout-account-${index}-listing`"
    >
      <button
        v-tooltip="primaryTooltip(payoutAccount)"
        @click="setPrimaryPayoutAccount(payoutAccount)"
        type="button"
        :class="
          payoutAccount.isPrimary
            ? 'text-blue-400 hover:bg-blue-200 hover:text-blue-500 focus:bg-blue-500'
            : 'text-gray-400 hover:bg-gray-200 hover:text-gray-500 focus:bg-gray-500'
        "
        class="inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full focus:text-white focus:outline-none"
        :data-test="`member-${member.memberId}-payout-account-${index}-primary-toggle-button`"
      >
        <span class="sr-only">Designate a primary payout account</span>
        <StarIcon
          v-if="payoutAccount.isPrimary"
          class="w-3 h-3"
          :data-test="`member-${member.memberId}-payout-account-${index}-primary-icon`"
        />
        <StarOutlineIcon
          v-else
          class="w-3 h-3"
          :data-test="`member-${member.memberId}-payout-account-${index}-non-primary-icon`"
        />
      </button>
      <span v-tooltip="'Shared payout account'" class="truncate">{{
        payoutAccount.account.name ||
        `${_.capitalize(payoutAccount.account.category)} Account ${
          payoutAccount.account.id
        }`
      }}</span>
      <button
        v-if="canEdit && !payoutAccount.isPrimary"
        v-tooltip="'Remove'"
        @click="removePayoutAccount(payoutAccount)"
        type="button"
        class="flex-shrink-0 h-4 w-4 rounded-full inline-flex items-center justify-center text-indigo-400 hover:bg-indigo-200 hover:text-indigo-500 focus:outline-none focus:bg-indigo-500 focus:text-white"
        :data-test="`member-${member.memberId}-payout-account-${index}-remove-button`"
      >
        <span class="sr-only">Remove</span>
        <XMarkIcon class="h-3 w-3" />
      </button>
    </li>

    <li
      v-for="(payee, index) in payees"
      :key="payee.id"
      class="inline-flex rounded-full items-center my-1 py-0.5 px-1 space-x-1 text-sm font-medium bg-amber-100 text-amber-700"
      :data-test="`member-${member.memberId}-payment-method-${index}-listing`"
    >
      <button
        v-tooltip="primaryTooltip(payee)"
        @click="setPrimaryPayee(payee)"
        type="button"
        :class="
          payee.isPrimary
            ? 'text-blue-400 hover:bg-blue-200 hover:text-blue-500 focus:bg-blue-500'
            : 'text-gray-400 hover:bg-gray-200 hover:text-gray-500 focus:bg-gray-500'
        "
        class="inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full focus:text-white focus:outline-none"
        :data-test="`member-${member.memberId}-payment-method-${index}-primary-toggle-button`"
      >
        <span class="sr-only">Designate a primary payment method</span>
        <StarIcon
          v-if="payee.isPrimary"
          class="w-3 h-3"
          :data-test="`member-${member.memberId}-payment-method-${index}-primary-icon`"
        />
        <StarOutlineIcon
          v-else
          class="w-3 h-3"
          :data-test="`member-${member.memberId}-payment-method-${index}-non-primary-icon`"
        />
      </button>
      <svg
        v-if="unknownCard(payee.cardRecord.card.brand)"
        v-tooltip="'Payment method'"
        class="flex-shrink-0 h-4 w-auto text-gray-400"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <title>{{ payee.cardRecord.card.brand }}</title>
        <path d="M4 4a2 2 0 00-2 2v1h16V6a2 2 0 00-2-2H4z" />
        <path
          fill-rule="evenodd"
          d="M18 9H2v5a2 2 0 002 2h12a2 2 0 002-2V9zM4 13a1 1 0 011-1h1a1 1 0 110 2H5a1 1 0 01-1-1zm5-1a1 1 0 100 2h1a1 1 0 100-2H9z"
          clip-rule="evenodd"
        />
      </svg>
      <img
        v-else
        v-tooltip="'Payment method'"
        class="flex-shrink-0 h-4 w-auto text-gray-400"
        :src="`https://assets.towerhunt.com/site/${brandLogoFor(
          payee.cardRecord.card.brand
        )}0.png`"
        :alt="payee.cardRecord.card.brand"
      />
      <span v-tooltip="'Payment method'"
        >x{{ payee.cardRecord.card.last4 }}</span
      >
      <button
        v-if="canEdit && !payee.isPrimary"
        v-tooltip="'Remove'"
        @click="removePayee(payee)"
        type="button"
        class="flex-shrink-0 h-4 w-4 rounded-full inline-flex items-center justify-center text-indigo-400 hover:bg-indigo-200 hover:text-indigo-500 focus:outline-none focus:bg-indigo-500 focus:text-white"
        :data-test="`member-${member.memberId}-payment-method-${index}-remove-button`"
      >
        <span class="sr-only">Remove</span>
        <XMarkIcon class="h-3 w-3" />
      </button>
    </li>
  </div>
</template>

<script setup>
import { StarIcon, XMarkIcon } from "@heroicons/vue/20/solid";
import { StarIcon as StarOutlineIcon } from "@heroicons/vue/24/outline";
import { onMounted, ref } from "vue";
import api from "@/router/api";
import _ from "lodash";

const props = defineProps(["member", "canEdit"]);
const emit = defineEmits(["refetch-members", "refetch-team"]);
const payoutAccounts = ref([]);
const payees = ref([]);

onMounted(() => {
  fetchPayoutAccounts();
  fetchPaymentMethods();
});

function primaryTooltip(record) {
  if (record.isPrimary) {
    return "Primary";
  } else if (props.canEdit) {
    return "Make primary";
  } else {
    return "";
  }
}

async function fetchPayoutAccounts() {
  api
    .get(
      `team_memberships/${props.member.memberId}/team_shared_payout_accounts`
    )
    .then((json) => {
      payoutAccounts.value = json.data;
    });
}
async function fetchPaymentMethods() {
  api
    .get(`team_memberships/${props.member.memberId}/team_payees`)
    .then((json) => {
      payees.value = json.data;
    });
}

function removePayoutAccount(account) {
  if (props.canEdit && !account.isPrimary) {
    api
      .delete(
        `team_memberships/${props.member.memberId}/team_shared_payout_accounts/${account.id}`
      )
      .then(() => {
        fetchPayoutAccounts();
      });
  }
}
function removePayee(payee) {
  if (props.canEdit && !payee.isPrimary) {
    api
      .delete(
        `team_memberships/${props.member.memberId}/team_payees/${payee.id}`
      )
      .then(() => {
        fetchPaymentMethods();
      });
  }
}

function setPrimaryPayoutAccount(account) {
  if (props.canEdit && !account.isPrimary) {
    api.patch(`primary_team_shared_payout_accounts/${account.id}`).then(() => {
      emit("refetch-members");
    });
  }
}
function setPrimaryPayee(payee) {
  if (props.canEdit && !payee.isPrimary) {
    api.patch(`primary_team_payees/${payee.id}`).then(() => {
      emit("refetch-members");
      emit("refetch-team");
    });
  }
}

function trimmedLower(str) {
  return str.toLowerCase().replace(/\s/g, "");
}
function unknownCard(brand) {
  return !_.includes(
    ["americanexpress", "discover", "mastercard", "visa"],
    trimmedLower(brand)
  );
}
function brandLogoFor(brand) {
  return trimmedLower(brand);
}
</script>
