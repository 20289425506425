<template>
  <div
    v-tooltip="tooltip || ''"
    class="w-full h-full rounded-md overflow-hidden relative"
  >
    <div
      class="absolute w-full h-full flex items-center justify-center"
      style="z-index: 3"
    >
      <div
        v-if="allFieldsData && licenseableCount > 0"
        v-tooltip="
          licensedCount === licenseableCount
            ? 'You\'ve unlocked everything'
            : 'Data available to unlock'
        "
        class="text-sm text-white font-semibold"
      >
        {{ licensedCount }} / {{ licenseableCount }}
      </div>
      <div v-else-if="allFieldsData" class="text-sm text-gray-500">
        Nothing to unlock
      </div>
      <PulseLoader
        v-else
        :loading="true"
        size="8px"
        :color="allFieldsData && licenseableCount > 0 ? 'white' : '#D4D4D8'"
      />
    </div>
    <div
      v-if="allFieldsData && licensedCount > 0"
      class="absolute h-full bg-indigo-500"
      :style="`z-index: 2; width: ${licensedWidth}%;`"
    />
    <div
      :class="
        licenseableCount === 0
          ? 'bg-white border-2 border-gray-300 border-dashed'
          : 'bg-indigo-900'
      "
      class="absolute w-full h-full"
      style="z-index: 1"
    />
  </div>
</template>

<script setup>
import { computed } from "vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import _ from "lodash";

const props = defineProps(["allFieldsData", "tooltip"]);
const licenseableCount = computed(
  () => _.get(props.allFieldsData, "licenseableIds", []).length
);
const licensedCount = computed(
  () =>
    licenseableCount.value -
    _.get(props.allFieldsData, "unlicensedIds", []).length
);
const licensedWidth = computed(() => {
  const rawPercent = licensedCount.value / licenseableCount.value;

  return _.round(rawPercent * 100);
});
</script>
