<template>
  <tr
    v-if="availabilityId"
    v-observe-visibility="{ callback: fetchAvailabilityDataField, once: true }"
    :class="portfolioAvailability ? 'bg-orange-50' : ''"
  >
    <td>
      <span class="sr-only">Empty</span>
    </td>
    <td
      v-if="availabilityDataField"
      @mouseenter="handleHover"
      @mouseleave="clearHover"
      class="whitespace-nowrap py-2 pr-3"
      :class="portfolioAvailability ? 'pl-4' : ''"
    >
      <div v-if="availabilityDataField" class="flex items-center">
        <DataField
          v-if="availabilityDataField"
          :data-field="availabilityDataField"
          :property-data-field="propertyDataField"
          text-classes="text-sm font-medium"
          dropdown-placement="left-start"
          text-styles=""
          :analyze="true"
          @completed="refetchAll"
          @unlocked="refetchAll"
          @open-sourced="refetchAll"
        />
      </div>
      <div
        v-if="constructionStatusField || floorLayoutDataFields.length > 0"
        class="flex items-center space-x-1"
      >
        <DataField
          v-if="constructionStatusField"
          :data-field="constructionStatusField"
          text-classes="text-sm font-medium"
          dropdown-placement="left-start"
          text-styles=""
          :analyze="true"
          @completed="refetchAll"
          @unlocked="refetchAll"
          @open-sourced="refetchAll"
        />
        <span
          v-if="constructionStatusField && floorLayoutDataFields.length > 0"
          class="text-xl font-bold text-gray-500"
          >&middot;</span
        >
        <template v-if="floorLayoutDataFields.length > 0">
          <span class="text-xs text-gray-500">Layouts:</span>
          <VMenu
            theme="diagram-popup"
            v-for="layout in floorLayoutDataFields"
            :key="layout.localId"
          >
            <QrCodeIcon
              class="h-4 w-4 rounded-sm text-violet-500 cursor-pointer"
            />
            <template #popper>
              <DataFieldInfoPopup :data-field="layout" />
            </template>
          </VMenu>
        </template>
      </div>
    </td>
    <td
      v-if="availabilityDataField"
      class="whitespace-nowrap px-2 py-2 text-xs text-gray-500"
    >
      <DataField
        v-if="locationDataField"
        :data-field="locationDataField"
        text-classes="text-sm"
        :analyze="true"
      />
      <template v-else>Unlock</template>
    </td>
    <td class="whitespace-nowrap px-2 py-2 text-xs text-gray-500">
      <div class="grid grid-cols-2 gap-x-2">
        <template v-if="portfolioAvailability">
          <div>See above</div>
          <div>See above</div>
        </template>
        <template v-else>
          <PrincipalsDetail
            v-if="availabilityDataField?.fieldContent"
            transaction-context="leasing"
            transaction-side="supply"
            display-context="table-row"
            :availability="availabilityDataField.fieldContent"
          />
          <AdvisorsDetail
            v-if="availabilityDataField?.fieldContent"
            :availability="availabilityDataField.fieldContent"
            :fetch-milliseconds="fetchMilliseconds"
            clientRole="spaceProvider"
            context="table-row"
          />
        </template>
      </div>
    </td>
    <td class="whitespace-nowrap px-2 py-2 text-xs text-gray-500">
      <SizesUses
        v-if="sizeable"
        :standalone-content-type="availabilityDataField.fieldContentType"
        :standalone-content-ids="[availabilityDataField.fieldContentId]"
        :standalone-content-data-field="availabilityDataField"
      />
      <template v-else>Unlock</template>
    </td>
  </tr>

  <tr
    v-if="availabilityGroupId"
    v-observe-visibility="{
      callback: fetchAvailabilityGroupDataField,
      once: true,
    }"
  >
    <td class="relative whitespace-nowrap py-2 px-3 text-sm font-medium">
      <button
        @click="expanded = !expanded"
        type="button"
        v-tooltip="
          expanded ? 'Hide group availabilities' : 'View group availabilities'
        "
        class="h-5 w-5 inline-flex justify-center items-center text-sm text-gray-700 hover:text-gray-900"
      >
        <ChevronDownIcon class="h-5 w-5" />
      </button>
    </td>
    <td
      v-if="availabilityGroupDataField"
      @mouseenter="handleHover"
      @mouseleave="clearHover"
      class="whitespace-nowrap px-2 py-2 text-sm text-gray-500"
    >
      <div class="flex items-center space-x-1">
        <ExclamationTriangleIcon
          v-if="offMapGroupAvailabilities"
          class="h-5 w-5 text-orange-500"
          v-tooltip="
            `Some group availabilities are outside the ${
              geographyFilterPolygon ? 'polygon' : 'map'
            } boundaries`
          "
        />
        <DataField
          v-if="availabilityGroupDataField"
          :data-field="availabilityGroupDataField"
          text-classes="text-sm font-medium"
          dropdown-placement="left-start"
          text-styles=""
          :analyze="true"
          @completed="fetchAvailabilityGroupDataField"
        />
      </div>
    </td>
    <td
      @click="expanded = !expanded"
      class="whitespace-nowrap px-2 py-2 text-xs text-gray-500 cursor-pointer"
    >
      {{ expanded ? "See below" : "Expand to see" }}
    </td>
    <td
      class="whitespace-nowrap px-2 py-2 text-xs text-gray-500 cursor-pointer"
    >
      <div class="grid grid-cols-2 gap-x-2">
        <GroupPrincipalsDetail
          transaction-context="leasing"
          transaction-side="supply"
          display-context="table-row"
          :existingGroupId="availabilityGroupId"
          :fetched-timing-fields="groupTimingDataFields"
        />
        <AvailabilityGroupAdvisors
          :existingGroupId="availabilityGroupId"
          context="table-row"
          clientRole="spaceProvider"
          :fetch-milliseconds="fetchMilliseconds"
        />
      </div>
    </td>
    <td class="whitespace-nowrap px-2 py-2 text-xs text-gray-500">
      <SizesUses
        v-if="sizeable"
        standalone-content-type="SpaceAvailability"
        :standalone-content-ids="
          availabilityGroupDataField.fieldContent.availabilityIds
        "
        :standalone-content-data-field="availabilityGroupDataField"
      />
      <template v-else>Unlock</template>
    </td>
  </tr>

  <template v-if="availabilityGroupDataField && expanded">
    <SpaceAvailabilityTableRow
      v-for="id in withinMapGroupAvailabilityIds"
      :key="`${availabilityGroupDataField.localId}_Availability${id}`"
      :record="{ id, type: 'SpaceAvailability' }"
      :portfolio-availability="true"
    />
  </template>
</template>

<script setup>
import AdvisorsDetail from "@/components/deal-builder/AdvisorsDetail.vue";
import PrincipalsDetail from "@/components/deal-builder/PrincipalsDetail.vue";
import GroupPrincipalsDetail from "@/components/deal-builder/GroupPrincipalsDetail.vue";
import AvailabilityGroupAdvisors from "@/components/space-usage-builder/AvailabilityGroupAdvisors.vue";
import DataField from "@/components/crowdsourcing/DataField.vue";
import DataFieldInfoPopup from "@/components/crowdsourcing/DataFieldInfoPopup.vue";
import SizesUses from "@/components/analyze/calculations/SizesUses.vue";
import { useMainMapStore } from "@/stores/mainMap";
import { useAnalyzePanelStore } from "@/stores/analyzePanel";
import { useTimeTravelStore } from "@/stores/timeTravel";
import { storeToRefs } from "pinia";
import { computed, ref } from "vue";
import fullyUnlocked from "@/components/crowdsourcing/fullyUnlocked";
import _ from "lodash";
import {
  ChevronDownIcon,
  ExclamationTriangleIcon,
  QrCodeIcon,
} from "@heroicons/vue/20/solid";
import moment from "moment";

const props = defineProps(["record", "portfolioAvailability"]);
const mapStore = useMainMapStore();
const { propertyMarkerPulseId, nearbyPropertyDataFields } =
  storeToRefs(mapStore);
const analyzePanelStore = useAnalyzePanelStore();
const {
  analyzeSpaceAvailabilityFields,
  fetchedSpaceAvailabilityKeys,
  geographyFilterPolygon,
  fetchedAvailabilityFloorAreaLayoutFields,
  hoveringTableRowContent,
  routeName,
} = storeToRefs(analyzePanelStore);
const timeTravelStore = useTimeTravelStore();
const { datedSpaceAvailabilityIds, asOfMilliseconds } =
  storeToRefs(timeTravelStore);

const expanded = ref(true);
const availabilityId = computed(() =>
  props.record.type === "SpaceAvailability" ? props.record.id : null,
);
const availabilityGroupId = computed(() =>
  props.record.type === "SpaceAvailabilityGroup" ? props.record.id : null,
);
const fetchMilliseconds = computed(() => {
  if (availabilityDataField.value) {
    return availabilityDataField.value?.fieldContent?.date
      ? moment(availabilityDataField.value?.fieldContent?.date).valueOf()
      : asOfMilliseconds.value;
  } else if (availabilityGroupDataField.value) {
    if (groupTimingUnlocked.value) {
      const uniqueFields = _.uniqBy(groupTimingDataFields.value, "fieldName");
      const dateField = _.head(
        uniqueFields.filter((field) => field.fieldName !== "state"),
      );

      return dateField ? moment(dateField.fieldValue).valueOf() : null;
    } else if (groupDate.value) {
      return moment(groupDate.value).valueOf();
    } else {
      return null;
    }
  } else {
    return null;
  }
});
const sizeable = computed(() => {
  if (availabilityGroupDataField.value?.fieldContent?.availabilities) {
    return _.every(
      availabilityGroupDataField.value.fieldContent.availabilities,
      function ({ topLevelSpace }) {
        const checkType =
          topLevelSpace?.fieldContentType || topLevelSpace?.recordType;
        return checkType === "FloorArea";
      },
    );
  } else if (availabilityDataField.value?.fieldContent) {
    const checkType =
      availabilityDataField.value.fieldContent.topLevelSpace
        ?.fieldContentType ||
      availabilityDataField.value.fieldContent.topLevelSpace?.recordType;
    return checkType === "FloorArea";
  } else {
    return false;
  }
});
const propertyId = computed(
  () =>
    availabilityDataField.value?.fieldContent?.topLevelPropertyId ||
    availabilityGroupDataField.value?.fieldContent?.topLevelPropertyId,
);
const propertyDataField = computed(() => {
  if (propertyId.value) {
    return analyzeSpaceAvailabilityFields.value[`Property${propertyId.value}`];
  } else {
    return null;
  }
});
const availabilityFetchKey = computed(
  () => `SpaceAvailability${availabilityId.value}`,
);
const availabilityDataField = computed(() => {
  if (availabilityId.value) {
    return analyzeSpaceAvailabilityFields.value[availabilityFetchKey.value];
  } else {
    return null;
  }
});
const topLevelSpaceField = computed(
  () => availabilityDataField.value?.fieldContent?.topLevelSpace,
);
const constructionStatusField = computed(() => {
  if (topLevelSpaceField.value?.fieldContent) {
    return topLevelSpaceField.value.fieldContent.displayableStateField;
  } else if (topLevelSpaceField.value?.displayableStateField) {
    return topLevelSpaceField.value.displayableStateField;
  } else {
    return null;
  }
});
const floorLayoutDataFields = computed(() => {
  let fields = [];
  if (topLevelSpaceField.value?.fieldContentType === "FloorArea") {
    fields = fetchedAvailabilityFloorAreaLayoutFields.value.filter((df) => {
      return (
        df.decoratingContentType === "FloorArea" &&
        df.decoratingContentId === topLevelSpaceField.value.fieldContentId
      );
    });
  } else if (topLevelSpaceField.value?.recordType === "FloorArea") {
    fields = fetchedAvailabilityFloorAreaLayoutFields.value.filter((df) => {
      return (
        df.decoratingContentType === "FloorArea" &&
        df.decoratingContentId === topLevelSpaceField.value.id
      );
    });
  } else if (topLevelSpaceField.value?.fieldContentType === "LayoutPartition") {
    fields = fetchedAvailabilityFloorAreaLayoutFields.value.filter((df) => {
      return (
        df.decoratingContentType === "FloorArea" &&
        df.fieldContentType === "FloorAreaLayout" &&
        df.fieldContentId ===
          topLevelSpaceField.value.fieldContent?.floorAreaLayoutId
      );
    });
  } else if (topLevelSpaceField.value?.recordType === "LayoutPartition") {
    fields = fetchedAvailabilityFloorAreaLayoutFields.value.filter((df) => {
      return (
        df.decoratingContentType === "FloorArea" &&
        df.fieldContentType === "FloorAreaLayout" &&
        df.fieldContentId === topLevelSpaceField.value.floorAreaLayoutId
      );
    });
  } else {
    fields = [];
  }

  return _.uniqBy(fields, "localId");
});
const availabilityGroupFetchKey = computed(
  () => `SpaceAvailabilityGroup${availabilityGroupId.value}`,
);
const availabilityGroupDataField = computed(() => {
  if (availabilityGroupId.value) {
    return analyzeSpaceAvailabilityFields.value[
      availabilityGroupFetchKey.value
    ];
  } else {
    return null;
  }
});
const groupAvailabilityIds = computed(
  () => availabilityGroupDataField.value?.fieldContent?.availabilityIds || [],
);
const withinMapGroupAvailabilityIds = computed(() => {
  if (groupAvailabilityIds.value.length > 0) {
    return groupAvailabilityIds.value.filter((id) => {
      return _.includes(datedSpaceAvailabilityIds.value, id);
    });
  } else {
    return [];
  }
});
const offMapGroupAvailabilities = computed(
  () =>
    groupAvailabilityIds.value.length > 0 &&
    groupAvailabilityIds.value.length !==
      withinMapGroupAvailabilityIds.value.length,
);

const groupTimingFetchKey = computed(() => {
  if (availabilityGroupId.value) {
    return `SpaceAvailabilityGroup${availabilityGroupId.value}Timing`;
  } else {
    return null;
  }
});
const matchingGroupTimingKeys = computed(() => {
  return fetchedSpaceAvailabilityKeys.value.filter((key) => {
    return _.includes(key, groupTimingFetchKey.value);
  });
});
const groupTimingDataFields = computed(() => {
  if (groupTimingFetchKey.value) {
    return _.filter(
      analyzeSpaceAvailabilityFields.value,
      function (dataField, key) {
        return _.includes(matchingGroupTimingKeys.value, key);
      },
    );
  } else {
    return [];
  }
});
const groupTimingUnlocked = computed(() => {
  return fullyUnlocked(groupTimingDataFields.value);
});
const groupDateState = computed(() => {
  const availabilities = _.compact(
    groupAvailabilityIds.value.map((id) => {
      const fetchKey = `SpaceAvailability${id}`;
      const availabilityField = analyzeSpaceAvailabilityFields.value[fetchKey];

      return availabilityField?.fieldContent;
    }),
  );
  const ids = availabilities.map((av) => av?.id);
  const dates = _.compact(
    _.uniq(
      availabilities.map((av) => {
        if (av.date) {
          return moment(av.date).format("YYYYMMDD");
        } else {
          return null;
        }
      }),
    ),
  );
  const states = _.compact(
    _.uniq(
      availabilities.map((av) => {
        if (_.isObject(av.state)) {
          return null;
        } else if (av.state) {
          return av.state;
        } else {
          return null;
        }
      }),
    ),
  );

  return { ids, dates, states };
});
const groupDate = computed(() => {
  if (groupDateState.value.dates.length === 1) {
    let unparsed = _.head(groupDateState.value.dates);

    return moment(unparsed).toDate();
  } else {
    return null;
  }
});

const locationFromMap = computed(() => {
  if (propertyId.value) {
    return _.get(nearbyPropertyDataFields.value, propertyId.value);
  } else {
    return null;
  }
});
const locationDataField = computed(() => {
  if (propertyId.value) {
    if (locationFromMap.value) {
      return locationFromMap.value;
    } else if (propertyDataField.value) {
      return propertyDataField.value.fieldContent?.locationDataField;
    } else {
      return null;
    }
  } else {
    return null;
  }
});

function clearRow() {
  console.log("clear row");
  delete analyzeSpaceAvailabilityFields.value[availabilityFetchKey.value];
  delete analyzeSpaceAvailabilityFields.value[availabilityGroupFetchKey.value];
}

function refetchAll() {
  clearRow();
  switch (props.record.type) {
    case "SpaceAvailability":
      delete analyzeSpaceAvailabilityFields.value[availabilityFetchKey.value];
      fetchAvailabilityDataField();
      break;
    case "SpaceAvailabilityGroup":
      delete analyzeSpaceAvailabilityFields.value[
        availabilityGroupFetchKey.value
      ];
      fetchAvailabilityGroupDataField();
      break;
  }
}

async function fetchAvailabilityDataField() {
  if (!availabilityDataField.value) {
    await analyzePanelStore.fetchSpaceAvailabilityDataField(
      availabilityId.value,
      availabilityFetchKey.value,
    );
    analyzePanelStore.fetchPropertyDataField(
      propertyId.value,
      "spaceAvailabilityFields",
    );
    fetchFloorAreaLayouts();
  } else {
    analyzePanelStore.fetchPropertyDataField(
      propertyId.value,
      "spaceAvailabilityFields",
    );
  }
}

async function fetchAvailabilityGroupDataField() {
  if (!availabilityGroupDataField.value) {
    await analyzePanelStore.fetchSpaceAvailabilityGroupDataField(
      availabilityGroupId.value,
      availabilityGroupFetchKey.value,
    );

    fetchAvailabilityGroupTiming();

    if (propertyId.value)
      analyzePanelStore.fetchPropertyDataField(
        propertyId.value,
        "spaceAvailabilityFields",
      );
  } else {
    fetchAvailabilityGroupTiming();

    if (propertyId.value)
      analyzePanelStore.fetchPropertyDataField(
        propertyId.value,
        "spaceAvailabilityFields",
      );
  }
}

async function fetchAvailabilityGroupTiming() {
  if (availabilityGroupId.value && _.size(groupTimingDataFields.value) === 0) {
    await analyzePanelStore.fetchSpaceAvailabilityGroupTimingDataFields(
      availabilityGroupId.value,
    );
  }
}

function fetchFloorAreaLayouts() {
  if (topLevelSpaceField.value?.fieldContentType === "FloorArea") {
    analyzePanelStore.fetchFloorAreaLayouts({
      floorAreaId: topLevelSpaceField.value.fieldContentId,
      collection: "spaceAvailabilityFields",
    });
  } else if (topLevelSpaceField.value?.recordType === "FloorArea") {
    analyzePanelStore.fetchFloorAreaLayouts({
      floorAreaId: topLevelSpaceField.value.id,
      collection: "spaceAvailabilityFields",
    });
  } else if (topLevelSpaceField.value?.fieldContentType === "LayoutPartition") {
    analyzePanelStore.fetchFloorAreaLayouts({
      layoutPartitionId: topLevelSpaceField.value.fieldContentId,
      collection: "spaceAvailabilityFields",
    });
  } else if (topLevelSpaceField.value?.recordType === "LayoutPartition") {
    analyzePanelStore.fetchFloorAreaLayouts({
      layoutPartitionId: topLevelSpaceField.value.id,
      collection: "spaceAvailabilityFields",
    });
  }
}

function handleHover() {
  pulseProperty();
  highlightDiagramElement();
}

function clearHover() {
  propertyMarkerPulseId.value = null;
  hoveringTableRowContent.value = null;
}

function pulseProperty() {
  if (propertyDataField.value) {
    const pulseId =
      propertyDataField.value.fieldContent?.groundLayerPropertyId ||
      propertyDataField.value.fieldContentId;
    propertyMarkerPulseId.value = pulseId;
  }
}

function highlightDiagramElement() {
  if (routeName.value === "PropertyShell") {
    if (topLevelSpaceField.value) {
      hoveringTableRowContent.value = topLevelSpaceField.value;
    } else if (availabilityGroupDataField.value) {
      hoveringTableRowContent.value = availabilityGroupDataField.value;
    }
  }
}
</script>
