<template>
  <!-- SUPPLY SIDE -->
  <template
    v-if="transactionContext === 'capMarkets' && transactionSide === 'supply'"
  >
    <div
      class="col-span-1"
      :data-test="`investment-group-${groupId}-capital-consumers-container`"
    >
      <dt class="text-sm font-medium text-gray-500">
        {{ supplySideLabel }}
      </dt>
      <dd
        v-if="timingUnlocked && capitalConsumers.length > 0"
        class="mt-1 space-y-2"
      >
        <InvolvedCompany
          v-for="companyId in capitalConsumers"
          :key="`group-consumer-${companyId}`"
          :fetch-company-id="companyId"
          layer-type="OwnershipInterest"
          layer-color="bg-yellow-400"
          :providers-count="capitalConsumers.length"
          :investment-group-id="existingGroupId"
          client-role="internalDispositions"
          :must-be-closed="mustBeClosed"
          context="investment-group-players"
          :fetch-milliseconds="fetchMilliseconds"
          :parent-component-save-function="persistCapitalConsumers"
          @override-refetch="fetchInvolvedCompanies"
        />
      </dd>
      <template v-else-if="addable">
        <dd
          v-if="timingUnlocked && capitalConsumers.length === 0"
          class="mt-4 text-center"
        >
          <svg
            class="mx-auto h-8 w-8 text-gray-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M12.066 11.2a1 1 0 000 1.6l5.334 4A1 1 0 0019 16V8a1 1 0 00-1.6-.8l-5.333 4zM4.066 11.2a1 1 0 000 1.6l5.334 4A1 1 0 0011 16V8a1 1 0 00-1.6-.8l-5.334 4z"
            />
          </svg>
          <h3 class="mt-2 text-sm font-medium text-gray-900">
            No {{ supplySideLabel }}
          </h3>
          <p class="mt-1 text-sm text-gray-500">
            Historical deals are the source for sell-side players.
          </p>
        </dd>
        <dd v-else-if="!timingUnlocked" class="mt-4 text-center">
          <CalendarDaysIcon class="mx-auto h-8 w-8 text-gray-400" />
          <h3 class="mt-2 text-sm font-medium text-gray-900">
            Unlockable dates/states
          </h3>
          <p class="mt-1 text-sm text-gray-500">
            All timing datapoints need to be unlocked. Please check the asset
            list.
          </p>
        </dd>
        <dd v-else-if="!homogeneousDateState" class="mt-4 text-center">
          <CalendarDaysIcon class="mx-auto h-8 w-8 text-gray-400" />
          <h3 class="mt-2 text-sm font-medium text-gray-900">
            Multiple dates/states
          </h3>
          <p class="mt-1 text-sm text-gray-500">
            The investments don't all share the same state. Please edit via the
            asset list.
          </p>
        </dd>
        <dd v-else-if="!allExistingTransactions" class="mt-4 text-center">
          <EllipsisHorizontalIcon class="mx-auto h-8 w-8 text-gray-400" />
          <h3 class="mt-2 text-sm font-medium text-gray-900">
            Saved and unsaved investments
          </h3>
          <p class="mt-1 text-sm text-gray-500">
            Please save all of the investments first.
          </p>
        </dd>
      </template>
    </div>
  </template>

  <template
    v-else-if="transactionContext === 'leasing' && transactionSide === 'supply'"
  >
    <div
      class="col-span-1"
      :data-test="`availability-group-${groupId}-space-providers-container`"
    >
      <dt class="text-sm font-medium text-gray-500">
        {{ supplySideLabel }}
      </dt>
      <dd
        v-if="timingUnlocked && spaceProviders.length > 0"
        class="mt-1 space-y-2"
      >
        <InvolvedCompany
          v-for="companyId in spaceProviderCompanies"
          :key="`group-space-provider-${companyId}`"
          :fetch-company-id="companyId"
          layer-type="OwnershipInterest"
          layer-color="bg-yellow-400"
          :providers-count="spaceProviders.length"
          :availability-group-id="existingGroupId"
          client-role="internalLeasing"
          :must-be-closed="mustBeClosed"
          context="availability-group-players"
          :fetch-milliseconds="fetchMilliseconds"
          :parent-component-save-function="persistSpaceProviders"
          @override-refetch="fetchInvolvedCompanies"
        />
      </dd>
      <template v-else-if="addable">
        <dd
          v-if="timingUnlocked && spaceProviders.length === 0"
          class="mt-4 text-center"
        >
          <svg
            class="mx-auto h-8 w-8 text-gray-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M12.066 11.2a1 1 0 000 1.6l5.334 4A1 1 0 0019 16V8a1 1 0 00-1.6-.8l-5.333 4zM4.066 11.2a1 1 0 000 1.6l5.334 4A1 1 0 0011 16V8a1 1 0 00-1.6-.8l-5.334 4z"
            />
          </svg>
          <h3 class="mt-2 text-sm font-medium text-gray-900">
            No {{ supplySideLabel }}
          </h3>
          <p v-if="subleasePresent" class="mt-1 text-sm text-gray-500">
            The direct lease is the source for sublandlord players.
          </p>
          <p v-else class="mt-1 text-sm text-gray-500">
            The capital stack is the source for landlord-side players.
          </p>
        </dd>
        <dd v-else-if="!timingUnlocked" class="mt-4 text-center">
          <CalendarDaysIcon class="mx-auto h-8 w-8 text-gray-400" />
          <h3 class="mt-2 text-sm font-medium text-gray-900">
            Unlockable dates/states
          </h3>
          <p class="mt-1 text-sm text-gray-500">
            All timing datapoints need to be unlocked. Please check the space
            list.
          </p>
        </dd>
        <dd v-else-if="!homogeneousDateState" class="mt-4 text-center">
          <CalendarDaysIcon class="mx-auto h-8 w-8 text-gray-400" />
          <h3 class="mt-2 text-sm font-medium text-gray-900">
            Multiple dates/states
          </h3>
          <p class="mt-1 text-sm text-gray-500">
            The available spaces don't all share the same state. Please edit via
            the space list.
          </p>
        </dd>
        <dd v-else-if="!allExistingTransactions" class="mt-4 text-center">
          <EllipsisHorizontalIcon class="mx-auto h-8 w-8 text-gray-400" />
          <h3 class="mt-2 text-sm font-medium text-gray-900">
            Saved and unsaved available spaces
          </h3>
          <p class="mt-1 text-sm text-gray-500">
            Please save all of the available spaces first.
          </p>
        </dd>
      </template>
    </div>
  </template>

  <!-- DEMAND SIDE -->
  <template
    v-if="transactionContext === 'capMarkets' && transactionSide === 'demand'"
  >
    <div
      class="col-span-1"
      :data-test="`investment-group-${groupId}-capital-providers-container`"
    >
      <dt class="flex items-center justify-between">
        <p class="text-sm font-medium text-gray-500">
          {{ demandSideLabel }}
        </p>
        <VDropdown v-if="displayAwardedDropAction">
          <button
            type="button"
            :disabled="originatingData"
            v-tooltip="'Deal actions menu'"
            class="inline-flex items-center shadow-sm p-1 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 duration-150"
          >
            <PulseLoader
              v-if="originatingData"
              :loading="true"
              size="3px"
              color="#f3f4f6"
            /><ArrowsRightLeftIcon v-else class="h-4 w-4" />
          </button>

          <template #popper>
            <DealActionDropdown
              :deal-builder-asset-data-field="{}"
              :local-asset-data-field="{}"
              :decorating-data-field="{}"
              :investment-group-id="groupId"
              :providers-count="awardedCapitalProviders.length"
              layer-type="AwardedOwnershipInterest"
              context="awarded-capital-providers"
              @refetch-group-investments="refetchGroupTransactions"
            />
          </template>
        </VDropdown>
      </dt>
      <dd v-if="capitalProviders.length > 0" class="mt-1 space-y-2">
        <InvolvedCompany
          v-for="companyId in capitalProviders"
          :key="`group-provider-${companyId}`"
          :fetch-company-id="companyId"
          layer-type="OwnershipInterest"
          layer-color="bg-yellow-400"
          :providers-count="capitalProviders.length"
          context="investment-group-players"
          :investment-group-id="existingGroupId"
          client-role="internalAcquisitions"
          :fetch-milliseconds="fetchMilliseconds"
          :parent-component-save-function="persistCapitalProviders"
          @refetch="fetchInvolvedCompanies"
          @override-refetch="fetchInvolvedCompanies"
        />
      </dd>
      <dd v-if="awardedCapitalProviders.length > 0" class="mt-1 space-y-2">
        <InvolvedCompany
          v-for="companyId in awardedCapitalProviders"
          :key="`group-awarded-provider-${
            _.isObject(companyId) ? companyId.fieldName : companyId
          }`"
          :fetch-company-id="companyId"
          layer-type="AwardedOwnershipInterest"
          layer-color="bg-orange-400"
          context="investment-group-players"
          :investment-group-id="existingGroupId"
          client-role="internalAcquisitions"
          :fetch-milliseconds="fetchMilliseconds"
          :parent-component-save-function="persistCapitalProviders"
          @refetch="refetchGroupInvestments"
          @override-refetch="refetchGroupInvestments"
        />
      </dd>
      <div v-if="editingDemand" class="mt-1 flex flex-col space-y-1">
        <div
          class="border border-gray-300 rounded-lg shadow-sm focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500"
        >
          <CompanyContactAutocomplete
            label="Capital providers"
            :companies="localDemandCompanies"
            :input-key="`investment-group-${groupId}`"
            @new-companies="setNewCompanies"
            @remove-company="removeCompany"
            @set-cross-interaction="setCrossInteraction"
          />
        </div>
        <div v-if="existingGroupId" class="flex justify-end space-x-2">
          <button
            @click="cancelDemandEditing"
            type="button"
            v-tooltip="'Cancel'"
            class="inline-flex items-center p-1 border border-gray-300 rounded-full shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <XMarkIcon class="h-4 w-4" />
          </button>

          <DataVisibilityButton visibility="safezone" class="inline-flex">
            <template v-slot:button>
              <button
                @click="saveProviders"
                :disabled="originatingData"
                type="button"
                class="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-yellow-500 hover:bg-yellow-600 focus:ring-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2"
                data-test="investment-players-provider-save"
              >
                <PulseLoader
                  v-if="originatingData"
                  :loading="true"
                  size="3px"
                  color="#f3f4f6"
                />
                <CheckIcon v-else class="h-4 w-4" />
              </button>
            </template>
          </DataVisibilityButton>
        </div>
      </div>
      <dd
        v-else-if="localDemandCompanies.length > 0"
        class="mt-1 flex flex-wrap"
      >
        <li
          @click="editingDemand = true"
          v-for="company in localDemandCompanies"
          :key="company"
          v-tooltip="'Unsaved'"
          class="inline-flex rounded-full items-center my-1 mr-1 py-0.5 px-2.5 text-sm font-medium bg-indigo-100 text-indigo-700"
        >
          {{ company?.name }}
        </li>
      </dd>
      <div v-else-if="addable" class="mt-2 flex items-center space-x-2">
        <DataVisibilityButton visibility="safezone">
          <template v-slot:button>
            <button
              @click="editingDemand = true"
              type="button"
              class="group py-1 px-1.5 flex items-center justify-between rounded-full border border-gray-300 shadow-sm space-x-2 text-left hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <span class="min-w-0 flex-1 flex items-center space-x-1">
                <span class="flex-shrink-0 flex items-center">
                  <span
                    class="inline-flex items-center justify-center h-5 w-5 rounded-full bg-yellow-500"
                  >
                    <BriefcaseIcon class="h-4 w-4 text-white" />
                  </span>
                </span>
                <span class="min-w-0 flex-1">
                  <span class="text-sm font-medium text-gray-900 truncate"
                    >Add companies</span
                  >
                </span>
              </span>
            </button>
          </template>
        </DataVisibilityButton>
      </div>
    </div>
  </template>

  <template
    v-else-if="transactionContext === 'leasing' && transactionSide === 'demand'"
  >
    <div
      class="col-span-1"
      :data-test="`availability-group-${groupId}-space-users-container`"
    >
      <dt class="flex items-center justify-between">
        <p class="text-sm font-medium text-gray-500">
          {{ demandSideLabel }}
        </p>
        <VDropdown v-if="displayAwardedDropAction">
          <button
            type="button"
            :disabled="originatingData"
            v-tooltip="'Space actions menu'"
            class="inline-flex items-center shadow-sm p-1 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 duration-150"
          >
            <PulseLoader
              v-if="originatingData"
              :loading="true"
              size="3px"
              color="#f3f4f6"
            /><ArrowsRightLeftIcon v-else class="h-4 w-4" />
          </button>

          <template #popper>
            <SpaceUsageActionDropdown
              :top-level-space-data-field="{}"
              :decorating-data-field="{}"
              :availability-group-id="groupId"
              layer-type="AwardedSpaceUser"
              context="awarded-space-users"
              @refetch-group-availabilities="refetchGroupAvailabilities"
            />
          </template>
        </VDropdown>
      </dt>
      <dd v-if="spaceUsers.length > 0" class="mt-1 space-y-2">
        <InvolvedCompany
          v-for="companyId in spaceUsers"
          :key="`group-space-user-${companyId}`"
          :fetch-company-id="companyId"
          layer-type="SpaceUser"
          layer-color="bg-blue-400"
          :space-users-count="spaceUsers.length"
          context="availability-group-players"
          :availability-group-id="existingGroupId"
          client-role="internalRealEstate"
          :fetch-milliseconds="fetchMilliseconds"
          :parent-component-save-function="persistSpaceUsers"
          @refetch="fetchInvolvedCompanies"
          @override-refetch="fetchInvolvedCompanies"
        />
      </dd>
      <dd v-if="awardedSpaceUsers.length > 0" class="mt-1 space-y-2">
        <InvolvedCompany
          v-for="companyId in awardedSpaceUsers"
          :key="`group-awarded-space-user-${
            _.isObject(companyId) ? companyId.fieldName : companyId
          }`"
          :fetch-company-id="companyId"
          layer-type="AwardedOwnershipInterest"
          layer-color="bg-orange-400"
          context="availability-group-players"
          :availability-group-id="existingGroupId"
          client-role="internalRealEstate"
          :fetch-milliseconds="fetchMilliseconds"
          :parent-component-save-function="persistSpaceUsers"
          @refetch="refetchGroupAvailabilities"
          @override-refetch="refetchGroupAvailabilities"
        />
      </dd>
      <div v-if="editingDemand" class="mt-1 flex flex-col space-y-1">
        <div
          class="border border-gray-300 rounded-lg shadow-sm focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500"
        >
          <CompanyContactAutocomplete
            label="Space users"
            :companies="localDemandCompanies"
            :input-key="`availability-group-${groupId}`"
            @new-companies="setNewCompanies"
            @remove-company="removeCompany"
            @set-cross-interaction="setCrossInteraction"
          />
        </div>
        <div v-if="existingGroupId" class="flex justify-end space-x-2">
          <button
            @click="cancelDemandEditing"
            type="button"
            v-tooltip="'Cancel'"
            class="inline-flex items-center p-1 border border-gray-300 rounded-full shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <XMarkIcon class="h-4 w-4" />
          </button>

          <DataVisibilityButton visibility="safezone" class="inline-flex">
            <template v-slot:button>
              <button
                @click="saveProviders"
                :disabled="originatingData"
                type="button"
                class="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-yellow-500 hover:bg-yellow-600 focus:ring-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2"
                data-test="availability-players-space-user-save"
              >
                <PulseLoader
                  v-if="originatingData"
                  :loading="true"
                  size="3px"
                  color="#f3f4f6"
                />
                <CheckIcon v-else class="h-4 w-4" />
              </button>
            </template>
          </DataVisibilityButton>
        </div>
      </div>
      <dd
        v-else-if="localDemandCompanies.length > 0"
        class="mt-1 flex flex-wrap"
      >
        <li
          @click="editingDemand = true"
          v-for="company in localDemandCompanies"
          :key="company"
          v-tooltip="'Unsaved'"
          class="inline-flex rounded-full items-center my-1 mr-1 py-0.5 px-2.5 text-sm font-medium bg-indigo-100 text-indigo-700"
        >
          {{ company.name }}
        </li>
      </dd>
      <div v-else-if="addable" class="mt-2 flex items-center space-x-2">
        <DataVisibilityButton visibility="safezone">
          <template v-slot:button>
            <button
              @click="editingDemand = true"
              type="button"
              class="group py-1 px-1.5 flex items-center justify-between rounded-full border border-gray-300 shadow-sm space-x-2 text-left hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <span class="min-w-0 flex-1 flex items-center space-x-1">
                <span class="flex-shrink-0 flex items-center">
                  <span
                    class="inline-flex items-center justify-center h-5 w-5 rounded-full bg-yellow-500"
                  >
                    <BriefcaseIcon class="h-4 w-4 text-white" />
                  </span>
                </span>
                <span class="min-w-0 flex-1">
                  <span class="text-sm font-medium text-gray-900 truncate"
                    >Add companies</span
                  >
                </span>
              </span>
            </button>
          </template>
        </DataVisibilityButton>
      </div>
    </div>
  </template>
</template>

<script setup>
import {
  XMarkIcon,
  CheckIcon,
  ArrowsRightLeftIcon,
} from "@heroicons/vue/20/solid";
import {
  BriefcaseIcon,
  CalendarDaysIcon,
  EllipsisHorizontalIcon,
} from "@heroicons/vue/24/outline";
import CompanyContactAutocomplete from "@/components/crowdsourcing/CompanyContactAutocomplete.vue";
import InvolvedCompany from "@/components/crowdsourcing/InvolvedCompany.vue";
import DealActionDropdown from "@/components/deal-builder/DealActionDropdown.vue";
import SpaceUsageActionDropdown from "@/components/space-usage-builder/SpaceUsageActionDropdown.vue";
import DataVisibilityButton from "@/components/crowdsourcing/DataVisibilityButton.vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { useDealBuilderStore } from "@/stores/dealBuilder";
import { useSpaceUsageBuilderStore } from "@/stores/spaceUsageBuilder";
import { useAnalyzePanelStore } from "@/stores/analyzePanel";
import { useCrowdsourcedChangeGroupStore } from "@/stores/crowdsourcedChangeGroup";
import { ref, computed, watch, onMounted } from "vue";
import { storeToRefs } from "pinia";
import api from "@/router/api";
import moment from "moment";
import _ from "lodash";
import fullyUnlocked from "@/components/crowdsourcing/fullyUnlocked";

const props = defineProps([
  "transactionContext",
  "transactionSide",
  "displayContext",
  "existingGroupId",
  "placeholderGroupId",
  "fetchedTimingFields",
]);
const emit = defineEmits(["set-space-providers"]);

const dealBuilderStore = useDealBuilderStore();
const { investmentGroups } = storeToRefs(dealBuilderStore);
const spaceUsageBuilderStore = useSpaceUsageBuilderStore();
const { availabilityGroups } = storeToRefs(spaceUsageBuilderStore);
const analyzePanelStore = useAnalyzePanelStore();
const { analyzeInvestmentFields, analyzeSpaceAvailabilityFields } =
  storeToRefs(analyzePanelStore);
const changeGroupStore = useCrowdsourcedChangeGroupStore();
const { originatingData, changeGroupId } = storeToRefs(changeGroupStore);

const addable = computed(() => props.displayContext !== "table-row");

const capitalConsumers = ref([]);
const capitalProviders = ref([]);
const awardedCapitalProviders = ref([]);
const spaceProviders = ref([]);
const spaceProviderCompanies = ref([]);
const spaceUsers = ref([]);
const awardedSpaceUsers = ref([]);
const editingDemand = ref(false);

const groupId = computed(() => {
  return props.existingGroupId || props.placeholderGroupId;
});
const group = computed(() => {
  if (props.displayContext === "details-panel") {
    const finder = props.existingGroupId
      ? { id: props.existingGroupId }
      : { placeholderId: props.placeholderGroupId };
    let groups = null;

    switch (props.transactionContext) {
      case "capMarkets":
        groups = investmentGroups.value;
        break;
      case "leasing":
        groups = availabilityGroups.value;
        break;
    }

    return _.find(groups, finder);
  } else if (props.displayContext === "table-row") {
    switch (props.transactionContext) {
      case "capMarkets": {
        const fetchKey = `InvestmentGroup${groupId.value}`;
        return analyzeInvestmentFields.value[fetchKey];
      }

      case "leasing": {
        const fetchKey = `SpaceAvailabilityGroup${groupId.value}`;
        return analyzeSpaceAvailabilityFields.value[fetchKey];
      }
      default:
        return null;
    }
  } else {
    return null;
  }
});

const supplySideLabel = computed(() => {
  switch (props.transactionContext) {
    case "capMarkets":
      switch (_.get(group.value, "dealAction", null)) {
        case "originateLoan":
        case "refinance":
        case "Loan":
          return "Borrowers";
        default:
          return "Sellers";
      }
    case "leasing": {
      if (subleasePresent.value) {
        if (licensePresent.value) {
          return "Licensors";
        } else {
          return "Sublandlords";
        }
      } else {
        return "Landlords";
      }
    }
    default:
      return null;
  }
});
const demandSideLabel = computed(() => {
  switch (props.transactionContext) {
    case "capMarkets":
      switch (_.get(group.value, "dealAction", null)) {
        case "originateLoan":
        case "refinance":
        case "Loan":
          return "Lenders";
        default:
          return "Buyers";
      }
    case "leasing":
      if (subleasePresent.value) {
        if (licensePresent.value) {
          return "Licensees";
        } else {
          return "Subtenants";
        }
      } else {
        return "Tenants";
      }

    default:
      return null;
  }
});

const timingUnlocked = computed(() => {
  return fullyUnlocked(timingFields.value);
});
const allExistingTransactions = computed(() => {
  return _.every(groupDateState.value.ids, function (id) {
    return !_.isNil(id);
  });
});
const homogeneousDateState = computed(() => {
  return (
    groupDateState.value.dates.length <= 1 &&
    groupDateState.value.states.length <= 1 &&
    groupDateState.value.dealActions.length <= 1
  );
});
const displayAwardedDropAction = computed(() => {
  return props.existingGroupId && groupState.value === "under_contract";
});
const groupDate = computed(() => {
  if (groupDateState.value.dates.length === 1) {
    let unparsed = _.head(groupDateState.value.dates);

    return moment(unparsed).toDate();
  } else {
    return null;
  }
});
const groupState = computed(() => {
  if (timingUnlocked.value) {
    const uniqueFields = _.uniqBy(timingFields.value, "fieldName");
    const stateField = _.head(
      uniqueFields.filter((field) => field.fieldName === "state"),
    );

    return stateField?.fieldValue;
  } else if (groupDateState.value.states.length === 1) {
    return _.head(groupDateState.value.states);
  } else {
    return null;
  }
});
const groupDealAction = computed(() => {
  if (groupDateState.value.dealActions.length === 1) {
    return _.head(groupDateState.value.dealActions);
  } else {
    return null;
  }
});
const groupLayerType = computed(() => {
  switch (groupDealAction.value) {
    case "originateLoan":
    case "refinance":
      return "Loan";
    default:
      return "OwnershipInterest";
  }
});
const groupDateState = computed(() => {
  const fetchKey =
    props.transactionContext === "capMarkets"
      ? "investments"
      : "availabilities";
  const transactions = _.map(
    _.get(group.value, fetchKey, []),
    function (groupTransaction) {
      return focalTransactionFor(groupTransaction);
    },
  );

  function focalTransactionFor(groupTransaction) {
    const fetchKey =
      props.transactionContext === "capMarkets"
        ? "existingInvestment"
        : "existingAvailability";
    return _.get(groupTransaction, fetchKey) || groupTransaction;
  }

  const ids = transactions.map((i) => i.id);
  const dates = _.uniq(
    transactions.map((i) => {
      if (i.date) {
        return moment(i.date).format("YYYYMMDD");
      } else {
        return null;
      }
    }),
  );
  const states = _.uniq(transactions.map((i) => i.state));
  const dealActions = _.uniq(transactions.map((i) => i.dealAction));

  return { ids, dates, states, dealActions };
});
const timingFields = computed(() => {
  return props.fetchedTimingFields || _.get(group.value, "timingFields", []);
});
const transactions = computed(() => {
  if (props.displayContext === "details-panel") {
    const fetchKey =
      props.transactionContext === "capMarkets"
        ? "investments"
        : "availabilities";
    return _.get(group.value, fetchKey, {});
  } else if (props.displayContext === "table-row") {
    const fetchKey =
      props.transactionContext === "capMarkets"
        ? "fieldContent.investmentIds"
        : "fieldContent.availabilityIds";
    const fetchIds = _.get(group.value, fetchKey, []);
    const records = fetchIds.map((id) => {
      const fetchKey =
        props.transactionContext === "capMarkets"
          ? `Investment${id}`
          : `SpaceAvailability${id}`;

      if (props.transactionContext === "capMarkets") {
        return analyzeInvestmentFields.value[fetchKey];
      } else if (props.transactionContext === "leasing") {
        return analyzeSpaceAvailabilityFields.value[fetchKey];
      } else {
        return null;
      }
    });

    return _.compact(records);
  } else {
    return [];
  }
});
const subleaseSpaceFields = computed(() => {
  if (props.transactionContext === "leasing") {
    return _.map(transactions.value, function (record) {
      return record.space || record.fieldContent?.space;
    });
  } else {
    return [];
  }
});
const subleasePresent = computed(() => {
  if (props.transactionContext === "leasing") {
    return _.some(subleaseSpaceFields.value, function (dataField) {
      return (
        dataField?.fieldContentType &&
        _.includes(
          ["SpaceUsage", "LayoutPartition"],
          dataField.fieldContentType,
        )
      );
    });
  } else {
    return false;
  }
});
const licensePresent = computed(() => {
  if (props.transactionContext === "leasing") {
    return _.some(subleaseSpaceFields.value, function (dataField) {
      return dataField?.fieldContentType === "LayoutPartition";
    });
  } else {
    return false;
  }
});
const localDemandCompanies = computed(() => {
  const fetchKey =
    props.transactionContext === "capMarkets"
      ? "existingInvestment.capitalProviders"
      : "existingAvailability.spaceUsers";
  const combined = _.flatMap(transactions.value, function (transaction) {
    const fallback =
      props.transactionContext === "capMarkets"
        ? transaction.capitalProviders
        : transaction.spaceUsers;
    return _.get(transaction, fetchKey, fallback);
  });

  return _.uniq(combined, function (c) {
    return c.name;
  });
});

const fetchMilliseconds = computed(() => {
  if (timingUnlocked.value) {
    const uniqueFields = _.uniqBy(timingFields.value, "fieldName");
    const dateField = _.head(
      uniqueFields.filter((field) => field.fieldName !== "state"),
    );

    return dateField ? moment(dateField.fieldValue).valueOf() : null;
  } else if (groupDate.value) {
    return moment(groupDate.value).valueOf();
  } else {
    return null;
  }
});
const mustBeClosed = computed(
  () =>
    fetchMilliseconds.value &&
    groupState.value === "closed" &&
    groupLayerType.value === "OwnershipInterest",
);

watch(groupState, (id, oldId) => {
  if (id && id !== oldId) {
    debouncedFetchCompanies();
  }
});

watch(fetchMilliseconds, () => {
  if (fetchMilliseconds.value) {
    debouncedFetchCompanies();
  }
});

watch(subleasePresent, () => {
  debouncedFetchCompanies();
});

onMounted(() => {
  debouncedFetchCompanies();

  if (props.existingGroupId && !props.fetchedTimingFields) {
    switch (props.transactionContext) {
      case "capMarkets":
        dealBuilderStore.fetchGroupTimingFields(props.existingGroupId);
        break;
      case "leasing":
        spaceUsageBuilderStore.fetchGroupTimingFields(props.existingGroupId);
        break;
    }
  }
});

const debouncedFetchCompanies = _.debounce(function () {
  fetchInvolvedCompanies();
}, 500);

function setNewCompanies(newCompanies) {
  const addedCompanies = _.differenceBy(
    newCompanies,
    localDemandCompanies.value,
    "name",
  );

  _.forEach(transactions.value, function (transaction) {
    addCompanies(addedCompanies, transaction.temporaryId);
  });
}
function addCompanies(addedCompanies, combinedKey) {
  switch (props.transactionContext) {
    case "capMarkets":
      dealBuilderStore.addInvestmentGroupInvestmentPlayers({
        groupId: groupId.value,
        investmentCombinedKey: combinedKey,
        addedCompanies,
        path: "capitalProviders",
        compareBy: function (c) {
          return `${c.name}`;
        },
      });
      break;
    case "leasing":
      spaceUsageBuilderStore.addAvailabilityGroupAvailabilityPlayers({
        groupId: groupId.value,
        availabilityCombinedKey: combinedKey,
        addedCompanies,
        path: "spaceUsers",
        compareBy: function (c) {
          return `${c.name}`;
        },
      });
      break;
  }
}
function removeCompany(company) {
  _.forEach(transactions.value, function (transaction) {
    switch (props.transactionContext) {
      case "capMarkets":
        dealBuilderStore.removeInvestmentGroupInvestmentPlayer({
          groupId: groupId.value,
          investmentCombinedKey: transaction.temporaryId,
          toRemove: company,
          path: "capitalProviders",
          compareBy: function (c) {
            return `${c.name}`;
          },
        });
        break;
      case "leasing":
        spaceUsageBuilderStore.removeAvailabilityGroupAvailabilityPlayer({
          groupId: groupId.value,
          availabilityCombinedKey: transaction.temporaryId,
          toRemove: company,
          path: "spaceUsers",
          compareBy: function (c) {
            return `${c.name}`;
          },
        });
        break;
    }
  });
}
function setCrossInteraction() {}

function cancelDemandEditing() {
  editingDemand.value = false;
}
function clearDemandEditing() {
  localDemandCompanies.value.forEach((c) => {
    removeCompany(c);
  });
  cancelDemandEditing();
}

const persistCapitalConsumers = async (proofCompanies = null) => {
  const payload = {
    investmentGroupId: props.existingGroupId,
    investmentRole: mustBeClosed.value ? "capital_consumer" : null,
    companies: proofCompanies,
    changeGroupId: changeGroupId.value,
  };

  const response = await api.post(`retired_group_ownership_interests`, payload);

  console.log(
    "group players consumers persist",
    proofCompanies,
    payload,
    response,
  );

  return response;
};
const persistCapitalProviders = async (proofCompanies = null) => {
  const payload = {
    investmentGroupId: props.existingGroupId,
    companies: proofCompanies || localDemandCompanies.value,
    changeGroupId: changeGroupId.value,
  };

  const response = await api.post(
    `investment_group_ownership_interests`,
    payload,
  );

  console.log("group players persist", proofCompanies, payload, response);

  return response;
};

const persistSpaceProviders = async (proofCompanies = null) => {
  const payload = {
    availabilityGroupId: props.existingGroupId,
    availabilityRole: mustBeClosed.value ? "space_provider" : null,
    companies: proofCompanies,
    changeGroupId: changeGroupId.value,
  };

  const response = await api.post(`retired_group_ownership_interests`, payload);

  console.log(
    "group players space providers persist",
    proofCompanies,
    payload,
    response,
  );

  return response;
};
const persistSpaceUsers = async (proofCompanies = null) => {
  const payload = {
    availabilityGroupId: props.existingGroupId,
    companies: proofCompanies || localDemandCompanies.value,
    changeGroupId: changeGroupId.value,
  };

  const response = await api.post(`availability_group_space_users`, payload);

  console.log(
    "group players space users persist",
    proofCompanies,
    payload,
    response,
  );

  return response;
};

function saveProviders() {
  setTimeout(() => {
    if (props.existingGroupId) {
      switch (props.transactionContext) {
        case "capMarkets": {
          const apiRequestFunc = () => persistCapitalProviders();
          const successCallback = () => {
            clearDemandEditing();
            refetchGroupTransactions();
            // Flash: "Players added to all investments in the portfolio."
          };
          const failureCallback = () => clearDemandEditing();

          return changeGroupStore.originateData(
            apiRequestFunc,
            successCallback,
            failureCallback,
          );
        }
        case "leasing": {
          const apiRequestFunc = () => persistSpaceUsers();
          const successCallback = () => {
            clearDemandEditing();
            refetchGroupTransactions();
            // Flash: "Players added to all availabilities in the group."
          };
          const failureCallback = () => clearDemandEditing();

          return changeGroupStore.originateData(
            apiRequestFunc,
            successCallback,
            failureCallback,
          );
        }
      }
    }
  }, 125);
}

function refetchGroupTransactions() {
  if (props.existingGroupId) {
    switch (props.transactionContext) {
      case "capMarkets":
        dealBuilderStore.refreshInvestmentGroup({
          groupId: props.existingGroupId,
          dealAction: group.value?.dealAction,
          isExistingGroup: true,
        });
        break;
      case "leasing":
        spaceUsageBuilderStore.refreshAvailabilityGroup({
          groupId: props.existingGroupId,
          dealAction: group.value?.dealAction,
          isExistingGroup: true,
        });
        break;
    }
  }
}

function fetchInvolvedCompanies() {
  if (props.existingGroupId) {
    switch (props.transactionContext) {
      case "capMarkets":
        fetchCapitalMarketsCompanies();
        break;
      case "leasing":
        fetchLeasingCompanies();
        break;
    }
  }
}

function fetchCapitalMarketsCompanies() {
  const unknownConsumers =
    !fetchMilliseconds.value && groupState.value === "closed";

  if (
    !unknownConsumers &&
    fetchMilliseconds.value &&
    props.transactionSide === "supply"
  ) {
    fetchCapitalMarketsSupply();
  }

  if (props.transactionSide === "demand") {
    fetchCapitalMarketsDemand();
  }
}

function fetchCapitalMarketsSupply() {
  api
    .get(
      `ownership_interests/?content_type=InvestmentGroup&content_id=${
        props.existingGroupId
      }&as_of=${fetchMilliseconds.value}${
        mustBeClosed.value ? "&investment_role=capital_consumer" : ""
      }`,
    )
    .then((json) => {
      capitalConsumers.value = json.data;
    });
}

function fetchCapitalMarketsDemand() {
  api
    .get(
      `investment_group_ownership_interests/?investment_group_id=${props.existingGroupId}`,
    )
    .then((json) => {
      capitalProviders.value = json.data;

      if (_.includes(["under_contract", "withdrawn"], groupState.value)) {
        api
          .get(
            `investment_group_ownership_interests/?investment_group_id=${props.existingGroupId}&conditional_modifier=awarded`,
          )
          .then((json) => {
            awardedCapitalProviders.value = json.data;
          });
      }
    });
}

async function fetchLeasingCompanies() {
  if (props.existingGroupId) {
    const unknownOwners =
      !fetchMilliseconds.value && groupState.value === "closed";

    if (
      !unknownOwners &&
      fetchMilliseconds.value &&
      props.transactionSide === "supply"
    ) {
      if (subleasePresent.value) {
        await fetchSublandlords();
      } else {
        await fetchOwners();
      }

      if (props.displayContext === "details-panel") {
        emit("set-space-providers", spaceProviders.value);
      }
    }

    if (props.transactionSide === "demand") {
      fetchUsers();
    }
  }
}

async function fetchSublandlords() {
  let newProviders = [];
  for (const spaceField of subleaseSpaceFields.value) {
    if (spaceField.fieldContent) {
      const spaceType =
        spaceField.fieldContent.spaceType || spaceField.fieldContent.recordType;
      const spaceId =
        spaceField.fieldContent.spaceId || spaceField.fieldContent.id;
      const sublandlordsResponse = await api.get(
        `sublandlords/?content_type=${spaceType}&content_id=${spaceId}&as_of=${fetchMilliseconds.value}`,
      );

      if (sublandlordsResponse?.data) {
        newProviders = _.unionBy(
          sublandlordsResponse.data,
          newProviders,
          "localId",
        );
      }
    }
  }

  spaceProviders.value = _.unionBy(
    newProviders,
    spaceProviders.value,
    "localId",
  );

  if (newProviders.length > 0) {
    const companyIds = newProviders.map(
      (dataField) => dataField.fieldContent?.companyId,
    );
    spaceProviderCompanies.value = _.union(
      _.compact(companyIds),
      spaceProviderCompanies.value,
    );
  }
}

async function fetchOwners() {
  let newProviders = [];
  const assetsResponse = await api.get(
    `operational_controlling_assets/?availability_group_id=${props.existingGroupId}`,
  );

  if (assetsResponse?.data) {
    for (const controllingAsset of assetsResponse.data) {
      const contentType =
        controllingAsset.fieldContentType || controllingAsset.recordType;
      const contentId = controllingAsset.fieldContentId || controllingAsset.id;
      const providerResponse = await api.get(
        `ownership_interests/?content_type=${contentType}&content_id=${contentId}&as_of=${
          fetchMilliseconds.value
        }${mustBeClosed.value ? "&availability_role=space_provider" : ""}`,
      );

      if (providerResponse?.data) {
        newProviders = _.unionBy(
          providerResponse.data,
          newProviders,
          "localId",
        );
      }
    }

    spaceProviders.value = _.unionBy(
      newProviders,
      spaceProviders.value,
      "localId",
    );

    if (newProviders.length > 0) {
      const payload = {
        ownershipInterestIds: newProviders.map(
          (dataField) => dataField.fieldContentId,
        ),
      };
      const companiesResponse = await api.post(
        `ownership_interest_companies`,
        payload,
      );

      if (companiesResponse?.data) {
        spaceProviderCompanies.value = _.union(
          companiesResponse.data,
          spaceProviderCompanies.value,
        );
      }
    }
  }
}

async function fetchUsers() {
  const userResponse = await api.get(
    `space_users/?content_type=SpaceAvailabilityGroup&content_id=${props.existingGroupId}&as_of=${fetchMilliseconds.value}`,
  );

  if (userResponse?.data) {
    spaceUsers.value = userResponse.data;

    if (_.includes(["under_contract", "withdrawn"], groupState.value)) {
      const awardedResponse = await api.get(
        `availability_group_awardings/?availability_group_id=${props.existingGroupId}`,
      );

      if (awardedResponse?.data) {
        awardedSpaceUsers.value = awardedResponse.data;
      }
    }
  }
}

function refetchGroupAvailabilities() {
  if (props.existingGroupId) {
    spaceUsageBuilderStore.refreshAvailabilityGroup({
      groupId: props.existingGroupId,
      isExistingGroup: true,
    });
  }
}
</script>
