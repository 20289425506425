<template>
  <VDropdown :placement="placement || 'top-end'">
    <slot name="button">
      <button
        v-tooltip.bottom="'Unlock data'"
        type="button"
        :class="[pulseUnlockerButton ? 'pulse-unlocker' : '']"
        class="h-6 w-6 inline-flex justify-center items-center bg-white border border-gray-300 rounded-md text-xs text-indigo-500 hover:text-indigo-700"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-5 w-5"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            d="M10 2a5 5 0 00-5 5v2a2 2 0 00-2 2v5a2 2 0 002 2h10a2 2 0 002-2v-5a2 2 0 00-2-2H7V7a3 3 0 015.905-.75 1 1 0 001.937-.5A5.002 5.002 0 0010 2z"
          />
        </svg>
      </button>
    </slot>

    <template #popper>
      <div
        class="w-64 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="menu-button"
        tabindex="-1"
      >
        <CrowdsourceBundle
          v-for="bundle in bundles"
          :context="context"
          :key="bundle.apiParam"
          :calculation-name="calculationName"
          :data-field="dataField"
          :bundle="bundle"
          :all-fields-data="allFieldsData"
          :user-store="userStore"
          :modal-store="modalStore"
          :unlocker-store="unlockerStore"
          :analyze="analyze"
          @unlocked="unlocked"
          @reset-displayable="resetDisplayable"
        />

        <div
          v-if="fieldUnlockable"
          class="text-gray-700 hover:text-gray-900 hover:bg-gray-100 px-2 py-1 space-y-1"
        >
          <div class="flex justify-between">
            <div>
              <div class="text-sm font-medium">Individual field</div>
              <div class="text-xs">
                {{
                  _.startCase(
                    dataField?.fieldName ||
                      dataField?.fieldContentType ||
                      "Unlockable field",
                  )
                }}
              </div>
            </div>
            <div>
              <div class="text-xs">1 field</div>
            </div>
          </div>
          <CrowdsourcingUnlocker
            :context="context"
            :data-field="dataField"
            :user-store="userStore"
            :modal-store="modalStore"
            :unlocker-store="unlockerStore"
            @unlocked="unlocked"
            @reset-displayable="resetDisplayable"
          />
        </div>
        <div
          v-if="overrideLabel"
          class="text-gray-700 hover:text-gray-900 hover:bg-gray-100 px-2 py-1 space-y-1"
        >
          <div class="flex justify-between">
            <div>
              <div class="text-sm font-medium">{{ overrideLabel }}</div>
            </div>
            <div>
              <div class="text-xs">{{ overrideRecords.length }} Datapoints</div>
            </div>
          </div>
          <CrowdsourcingUnlocker
            :context="context"
            :override-records="overrideRecords"
            :override-label="overrideLabel"
            :user-store="userStore"
            :modal-store="modalStore"
            :unlocker-store="unlockerStore"
            @unlocked="unlocked"
            @reset-displayable="resetDisplayable"
          />
        </div>
      </div>
    </template>
  </VDropdown>
</template>

<script setup>
import CrowdsourceBundle from "@/components/crowdsourcing/CrowdsourceBundle.vue";
import CrowdsourcingUnlocker from "@/components/crowdsourcing/CrowdsourcingUnlocker.vue";
import { computed, onBeforeUnmount, onMounted, ref } from "vue";
import { useCompanyDetailStore } from "@/stores/companyDetail";
import { storeToRefs } from "pinia";
import _ from "lodash";

const props = defineProps([
  "context",
  "userStore",
  "modalStore",
  "unlockerStore",
  "calculationName",
  "dataField",
  "bundles",
  "allFieldsData",
  "placement",
  "overrideRecords",
  "overrideLabel",
  "analyze",
]);
const emit = defineEmits(["unlocked", "reset-displayable"]);

const companyDetailStore = useCompanyDetailStore();
const {
  companyIdParam,
  companySelectedRecordDataField,
  companyAllFetchedFields,
} = storeToRefs(companyDetailStore);
const pulseUnlockerButton = ref(false);
const pulseIntervalId = ref(null);

const companyMaskedFields = computed(() =>
  companyAllFetchedFields.value.filter(
    (field) =>
      field.fieldContentType === "CompanyInvolvement" && !field.unmasked,
  ),
);
const detailPageOverride = computed(() => {
  if (
    companyIdParam.value &&
    companySelectedRecordDataField.value?.fieldContentType === "Company" &&
    _.size(companyMaskedFields.value) > 0
  ) {
    return companyMaskedFields.value.map((field) => field.price);
  } else {
    return null;
  }
});
const fieldUnlockable = computed(
  () => _.get(props.dataField, "price") || _.size(detailPageOverride.value) > 0,
);

onMounted(() => {
  let modalStoreTemp = props.modalStore;
  let unlockerStoreTemp = props.unlockerStore;

  resetPulse();
});
onBeforeUnmount(() => clearPulse());

function unlocked() {
  console.log("bundles unlocked");
  emit("unlocked");
}
function resetDisplayable() {
  emit("reset-displayable");
}

function resetPulse() {
  clearPulse();
  beginPulse();
}

function clearPulse() {
  pulseUnlockerButton.value = false;
  if (pulseIntervalId.value) {
    clearInterval(pulseIntervalId.value);
    pulseIntervalId.value = null;
  }
}

function beginPulse() {
  if (!pulseIntervalId.value) {
    pulseIntervalId.value = setInterval(() => {
      logoCardPulse();
    }, 2100);
  }
}

function logoCardPulse() {
  pulseUnlockerButton.value = true;

  setTimeout(() => {
    pulseUnlockerButton.value = false;
  }, 1600);
}
</script>

<style scoped>
.pulse-unlocker {
  animation: pulse-unlocker 1.5s;
  box-shadow: 0 0 0 2em transparent;
}

@keyframes pulse-unlocker {
  0% {
    box-shadow: 0 0 0 0 #312e81;
  }
}
</style>
