<template>
  <div class="mt-4">
    <dd v-if="availabilityGroupId" class="mt-4 text-center">
      <Squares2X2Icon class="mx-auto h-8 w-8 text-gray-400" />
      <h3 class="mt-2 text-sm font-medium text-gray-900">Multiple spaces</h3>
      <p class="mt-1 text-sm text-gray-500">Please link via the space list.</p>
    </dd>
    <template v-else>
      <div class="flex justify-between">
        <div class="text-sm text-gray-500">
          Add valuable context to this TI allowance by linking it to related
          floor area layouts.
        </div>
        <div class="ml-3 flex-shrink-0 flex items-center space-x-1">
          <template v-if="propertyIdParam && adding">
            <DataVisibilityButton
              v-if="
                propertyDiagramAdded.length > 0 &&
                propertyDiagramEditAction?.floorArea?.fieldContentId
              "
              tooltip="Add a new layout to this floor area"
              class="inline-flex"
            >
              <template v-slot:button>
                <button
                  @click="saveLayout"
                  type="button"
                  :disabled="actionInProgress"
                  data-test="save-relocated-floor-area-button"
                  class="inline-flex items-center px-2 py-1 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  <PulseLoader
                    v-if="actionInProgress"
                    :loading="true"
                    size="6px"
                    color="#f3f4f6"
                  />
                  <template v-else>Save</template>
                </button>
              </template>
            </DataVisibilityButton>
            <span v-else class="text-xs text-indigo-600 font-semibold"
              >Select related floor areas, or:</span
            >
            <button
              @click="cancelLayoutAddition"
              type="button"
              class="rounded bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              Cancel
            </button>
          </template>
          <DataVisibilityButton
            v-else-if="propertyIdParam && floorAreaField"
            tooltip="Create a new buildout/layout"
            class="inline-flex"
          >
            <template v-slot:button>
              <button
                @click="addLayout"
                type="button"
                :disabled="actionInProgress"
                data-test="create-floor-area-layout-button"
                class="inline-flex items-center px-2 py-1 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <PulseLoader
                  v-if="actionInProgress"
                  :loading="true"
                  size="6px"
                  color="#f3f4f6"
                />
                <template v-else>Add Layout</template>
              </button>
            </template>
          </DataVisibilityButton>
        </div>
      </div>
      <div class="mt-4 grid grid-cols-2">
        <ol
          v-if="linkedLayoutDataFields.length > 0"
          class="flex flex-col space-y-2"
        >
          <dt class="text-sm font-medium text-gray-500">Linked Layouts:</dt>
          <DataField
            v-for="layoutField in linkedLayoutDataFields"
            :key="layoutField.localId"
            :data-field="layoutField"
            text-classes="text-sm font-medium max-w-48"
            text-styles=""
            @completed="fetchLinkedLayouts"
            @unlocked="fetchLinkedLayouts"
            @open-sourced="fetchLinkedLayouts"
          />
        </ol>
        <div
          v-else
          class="text-center"
          data-test="my-contributions-empty-state"
        >
          <LinkIcon class="mt-8 mx-auto h-8 w-8 text-gray-500" />
          <h3 class="mt-2 text-sm font-medium text-gray-900">
            No Linked Layouts
          </h3>
          <p class="mt-1 text-sm text-gray-500">
            Link layouts to this TI allowance.
          </p>
        </div>
        <ol
          v-if="unlinkedLayoutDataFields.length > 0"
          class="flex flex-col space-y-2"
        >
          <dt class="text-sm font-medium text-gray-500">Unlinked Layouts:</dt>
          <FloorAreaLayoutLinkingControl
            v-for="layoutField in unlinkedLayoutDataFields"
            :key="layoutField.localId"
            :layout="layoutField"
            @linking="setLinking"
            @perform-link="persistLink"
          />
        </ol>
        <div
          v-else
          class="text-center"
          data-test="my-contributions-empty-state"
        >
          <QrCodeIcon class="mt-8 mx-auto h-8 w-8 text-gray-500" />
          <h3 class="mt-2 text-sm font-medium text-gray-900">
            No Unlinked Layouts
          </h3>
          <p class="mt-1 text-sm text-gray-500">
            Layouts for this floor area will appear here.
          </p>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup>
import { Squares2X2Icon } from "@heroicons/vue/24/outline";
import { LinkIcon, QrCodeIcon } from "@heroicons/vue/20/solid";
import { ref, computed, onMounted, watch, nextTick } from "vue";
import { storeToRefs } from "pinia";
import { useCrowdsourcedChangeGroupStore } from "@/stores/crowdsourcedChangeGroup";
import { usePropertyDiagramActionsStore } from "@/stores/propertyDiagramActions";
import { usePropertyDiagramStore } from "@/stores/propertyDiagram";
import { useTimeTravelStore } from "@/stores/timeTravel";
import DataField from "@/components/crowdsourcing/DataField.vue";
import DataVisibilityButton from "@/components/crowdsourcing/DataVisibilityButton.vue";
import FloorAreaLayoutLinkingControl from "@/components/crowdsourcing/FloorAreaLayoutLinkingControl.vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import centerConsoleActions from "@/components/crowdsourcing/centerConsoleActions";
import api from "@/router/api";
import _ from "lodash";

const props = defineProps([
  "dataField",
  "availabilityGroupId",
  "dateFields",
  "perAreaSize",
  "scheduleDataField",
]);

const changeGroupStore = useCrowdsourcedChangeGroupStore();
const { changeGroupId } = storeToRefs(changeGroupStore);
const { asOfMilliseconds } = storeToRefs(useTimeTravelStore());
const diagramActionsStore = usePropertyDiagramActionsStore();
const { actionInProgress, focalAction } = storeToRefs(diagramActionsStore);
const propertyDiagramStore = usePropertyDiagramStore();
const {
  propertyIdParam,
  editingMode,
  propertyDiagramAdded,
  propertyDiagramSelected,
  propertyDiagramSelectedPropertyId,
  propertyDiagramActionTrigger,
  propertyDiagramEditAction,
  floorAreaDataFields,
} = storeToRefs(propertyDiagramStore);

const layoutDataFields = ref([]);
const linkedLayoutDataFields = ref([]);
const linking = ref(null);
const adding = ref(false);

const unlinkedLayoutDataFields = computed(() =>
  _.differenceBy(
    layoutDataFields.value,
    linkedLayoutDataFields.value,
    "fieldContentId",
  ),
);

const floorAreaId = computed(() => props.dataField.fieldContent?.space?.id);
const floorAreaField = computed(() => {
  if (floorAreaId.value) {
    return _.find(floorAreaDataFields.value, {
      fieldContentId: floorAreaId.value,
      deprecatedAt: null,
    });
  } else {
    return null;
  }
});
const addLayoutButtonData = computed(() =>
  _.get(centerConsoleActions, "addLayout"),
);
const saveActionButtonData = computed(() =>
  _.get(centerConsoleActions, "saveEditAction"),
);

watch(
  propertyDiagramEditAction,
  async (val, oldVal) => {
    if (oldVal && !val && adding.value && propertyIdParam.value) {
      console.log("layout created completed");
      adding.value = false;
      await nextTick();
      if (!propertyDiagramSelected.value) {
        fetchLayouts();
      }
    }
  },
  { deep: true },
);

onMounted(() => {
  fetchLinkedLayouts();

  if (floorAreaId.value) {
    fetchLayouts();
  }
});

async function fetchLayouts() {
  if (props.availabilityGroupId) {
    console.log("group id");
  } else {
    const response = await api.get(
      `floor_area_layouts/?floor_area_id=${floorAreaId.value}&as_of=${asOfMilliseconds.value}`,
    );

    if (response?.data) {
      layoutDataFields.value = response.data;
    }
  }
}

async function fetchLinkedLayouts() {
  if (props.availabilityGroupId) {
    console.log("group id");
  } else {
    const response = await api.get(
      `tenant_improvement_allowance_layouts/?cashflow_schedule_id=${props.scheduleDataField.fieldContentId}`,
    );

    if (response?.data) {
      linkedLayoutDataFields.value = response.data;
    }
  }
}

function saveLayout() {
  if (
    propertyIdParam.value &&
    propertyDiagramEditAction.value?.floorArea?.fieldContentId
  ) {
    focalAction.value = saveActionButtonData.value;
    propertyDiagramActionTrigger.value = {
      propertyId: propertyDiagramSelectedPropertyId.value,
      action: saveActionButtonData.value.directAction,
    };
  }
}

function addLayout() {
  if (propertyIdParam.value) {
    adding.value = true;
    editingMode.value = true;
    propertyDiagramSelected.value = {
      recordType: "FloorArea",
      dataField: floorAreaField.value,
    };
    focalAction.value = addLayoutButtonData.value;
    propertyDiagramActionTrigger.value = {
      propertyId: propertyDiagramSelectedPropertyId.value,
      action: addLayoutButtonData.value.directAction,
    };
  }
}

function cancelLayoutAddition() {
  diagramActionsStore.cancel(propertyDiagramSelectedPropertyId.value);
  editingMode.value = false;
  adding.value = false;
}

function setLinking(layoutDataField = null) {
  linking.value = layoutDataField;
}

function persistLink() {
  if (linking.value) {
    const persistPayload = {
      layoutId: linking.value.fieldContentId,
      cashflowScheduleId: props.scheduleDataField.fieldContentId,
      changeGroupId: changeGroupId.value,
    };
    setTimeout(() => {
      const apiRequestFunc = () =>
        api.post(`tenant_improvement_allowance_layouts`, persistPayload);
      const successCallback = async () => {
        fetchLinkedLayouts();
        fetchLayouts();
      };
      const failureCallback = () => setLinking();
      return changeGroupStore.originateData(
        apiRequestFunc,
        successCallback,
        failureCallback,
      );
    }, 125);
  }
}
</script>
