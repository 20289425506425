<template>
  <div class="absolute top-2 left-2">
    <img
      class="h-6 w-auto sm:h-8"
      src="https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png"
      alt="Tower Hunt"
    />
    <div class="text-indigo-400" style="font-size: 8px">
      {{ cardMonthYear }}
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import moment from "moment";

const props = defineProps(["card"]);
const cardMonthYear = computed(() => {
  return moment(props.card.sortDate).format("MMMYYYY");
});
</script>
