<template>
  <VDropdown class="inline-flex flex-shrink-0">
    <button
      v-tooltip="`${selectedOption.label}. Click to change.`"
      type="button"
      :class="
        selectedOption.value === 'recipient'
          ? 'text-indigo-400 hover:bg-indigo-200 hover:text-indigo-500 focus:bg-indigo-500'
          : 'text-orange-400 hover:bg-orange-200 hover:text-orange-500 focus:bg-orange-500'
      "
      class="inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full focus:text-white focus:outline-none"
      data-test="sharing-subsidy-button"
    >
      <span class="sr-only">Choose who pays for this data</span>
      <svg
        v-if="selectedOption.value === 'recipient'"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        class="w-3 h-3"
      >
        <path
          d="M10.75 10.818v2.614A3.13 3.13 0 0011.888 13c.482-.315.612-.648.612-.875 0-.227-.13-.56-.612-.875a3.13 3.13 0 00-1.138-.432zM8.33 8.62c.053.055.115.11.184.164.208.16.46.284.736.363V6.603a2.45 2.45 0 00-.35.13c-.14.065-.27.143-.386.233-.377.292-.514.627-.514.909 0 .184.058.39.202.592.037.051.08.102.128.152z"
        />
        <path
          fill-rule="evenodd"
          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-6a.75.75 0 01.75.75v.316a3.78 3.78 0 011.653.713c.426.33.744.74.925 1.2a.75.75 0 01-1.395.55 1.35 1.35 0 00-.447-.563 2.187 2.187 0 00-.736-.363V9.3c.698.093 1.383.32 1.959.696.787.514 1.29 1.27 1.29 2.13 0 .86-.504 1.616-1.29 2.13-.576.377-1.261.603-1.96.696v.299a.75.75 0 11-1.5 0v-.3c-.697-.092-1.382-.318-1.958-.695-.482-.315-.857-.717-1.078-1.188a.75.75 0 111.359-.636c.08.173.245.376.54.569.313.205.706.353 1.138.432v-2.748a3.782 3.782 0 01-1.653-.713C6.9 9.433 6.5 8.681 6.5 7.875c0-.805.4-1.558 1.097-2.096a3.78 3.78 0 011.653-.713V4.75A.75.75 0 0110 4z"
          clip-rule="evenodd"
        />
      </svg>
      <svg
        v-else
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        class="w-3 h-3"
      >
        <path
          fill-rule="evenodd"
          d="M14 6a2.5 2.5 0 00-4-3 2.5 2.5 0 00-4 3H3.25C2.56 6 2 6.56 2 7.25v.5C2 8.44 2.56 9 3.25 9h6V6h1.5v3h6C17.44 9 18 8.44 18 7.75v-.5C18 6.56 17.44 6 16.75 6H14zm-1-1.5a1 1 0 01-1 1h-1v-1a1 1 0 112 0zm-6 0a1 1 0 001 1h1v-1a1 1 0 00-2 0z"
          clip-rule="evenodd"
        />
        <path
          d="M9.25 10.5H3v4.75A2.75 2.75 0 005.75 18h3.5v-7.5zM10.75 18v-7.5H17v4.75A2.75 2.75 0 0114.25 18h-3.5z"
        />
      </svg>
    </button>

    <template #popper>
      <div
        class="w-56 divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="menu-button"
        tabindex="-1"
      >
        <div class="py-1" role="none">
          <div class="flex items-center px-4 py-2 space-x-2 hover:bg-gray-100">
            <svg
              v-if="selectedOption.value === 'recipient'"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              class="w-5 h-5 text-indigo-600"
            >
              <path
                fill-rule="evenodd"
                d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                clip-rule="evenodd"
              />
            </svg>
            <a
              @click.prevent="
                set({ label: 'Recipient pays', value: 'recipient' })
              "
              href=""
              :class="
                selectedOption.value === 'recipient'
                  ? 'text-indigo-600'
                  : 'text-gray-700'
              "
              class="text-sm"
              role="menuitem"
              tabindex="-1"
              >Recipient pays</a
            >
          </div>
        </div>
        <div class="py-1" role="none">
          <div
            v-for="(option, index) in options"
            :key="option.value"
            class="flex items-center px-4 py-2 space-x-2 hover:bg-gray-100"
          >
            <svg
              v-if="selectedOption.value === option.value"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              class="w-5 h-5 text-indigo-600"
            >
              <path
                fill-rule="evenodd"
                d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                clip-rule="evenodd"
              />
            </svg>
            <a
              @click.prevent="set(option)"
              href=""
              :class="
                selectedOption.value === option.value
                  ? 'text-indigo-600'
                  : 'text-gray-700'
              "
              class="text-sm"
              role="menuitem"
              tabindex="-1"
              v-close-popper
              >{{ option.label }}</a
            >
          </div>
        </div>
      </div>
    </template>
  </VDropdown>
</template>

<script setup>
import { computed } from "vue";
import { useSharingStore } from "@/stores/sharing";

const sharingStore = useSharingStore();
const props = defineProps(["recipient"]);
const options = [
  {
    label: "You pay for 1 week",
    value: "grantor_week",
  },
  {
    label: "You pay for 1 month",
    value: "grantor_month",
  },
  {
    label: "You pay for 6 months",
    value: "grantor_half_year",
  },
  {
    label: "You pay for 1 year",
    value: "grantor_year",
  },
  {
    label: "You always pay",
    value: "grantor_perpetual",
  },
];
const selectedOption = computed(() => props.recipient.payStructure);
function set(option) {
  sharingStore.setRecipientPayStructure({
    option,
    recipient: props.recipient,
  });
}
</script>
