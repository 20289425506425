<template>
  <div
    v-observe-visibility="{ callback: fetchGroupMetadata, once: true }"
    class="w-80 bg-white divide-y divide-gray-100 focus:outline-none"
    role="menu"
    aria-orientation="vertical"
    aria-labelledby="menu-button"
    tabindex="-1"
  >
    <div v-if="canViewExistingAvailability" class="py-1" role="none">
      <a
        @click.prevent="viewExistingAvailability"
        href=""
        class="text-gray-700 group flex items-center p-2 text-xs hover:bg-gray-100 hover:text-gray-900"
        role="menuitem"
        tabindex="-1"
        id="menu-item-4"
        data-test="view-source-availability-button"
      >
        <svg
          class="flex-shrink-0 mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            d="M8.433 7.418c.155-.103.346-.196.567-.267v1.698a2.305 2.305 0 01-.567-.267C8.07 8.34 8 8.114 8 8c0-.114.07-.34.433-.582zM11 12.849v-1.698c.22.071.412.164.567.267.364.243.433.468.433.582 0 .114-.07.34-.433.582a2.305 2.305 0 01-.567.267z"
          />
          <path
            fill-rule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a1 1 0 10-2 0v.092a4.535 4.535 0 00-1.676.662C6.602 6.234 6 7.009 6 8c0 .99.602 1.765 1.324 2.246.48.32 1.054.545 1.676.662v1.941c-.391-.127-.68-.317-.843-.504a1 1 0 10-1.51 1.31c.562.649 1.413 1.076 2.353 1.253V15a1 1 0 102 0v-.092a4.535 4.535 0 001.676-.662C13.398 13.766 14 12.991 14 12c0-.99-.602-1.765-1.324-2.246A4.535 4.535 0 0011 9.092V7.151c.391.127.68.317.843.504a1 1 0 101.511-1.31c-.563-.649-1.413-1.076-2.354-1.253V5z"
            clip-rule="evenodd"
          />
        </svg>
        View source deal
      </a>
    </div>
    <div v-if="availabilityGroupId && !companyId" class="py-1" role="none">
      <a
        @click.prevent="viewExistingAvailabilityGroup"
        href=""
        class="text-gray-700 group flex items-center p-2 text-xs hover:bg-gray-100 hover:text-gray-900"
        role="menuitem"
        tabindex="-1"
        id="menu-item-4"
        data-test="view-source-availability-button"
      >
        <Squares2X2Icon
          class="flex-shrink-0 mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500"
        />
        View space group
      </a>
    </div>
    <div
      v-if="spaceUsageId"
      v-observe-visibility="{ callback: fetchSpaceUsageDataField, once: true }"
      class="py-1"
      role="none"
    >
      <VDropdown v-if="spaceUsageDataField">
        <button
          class="w-full text-gray-700 group flex items-center justify-between p-2 text-xs hover:bg-gray-100 hover:text-gray-900"
          role="menuitem"
          tabindex="-1"
          id="menu-item-0"
          data-test="space-usage-detail-button"
        >
          <div class="flex items-center">
            <DocumentTextIcon
              class="mr-2 h-4 w-4 text-gray-400 group-hover:text-indigo-500"
            />
            Space Usage quick access
          </div>
          <ChevronRightIcon class="h-4 w-4" />
        </button>
        <template #popper>
          <SpaceUsageDetail :space-usage-data-field="spaceUsageDataField" />
        </template>
      </VDropdown>
    </div>
    <template v-if="!restricted">
      <div v-if="layerType === 'Prospect'" class="py-1" role="none">
        <VDropdown
          v-if="prospectHuntIds.length > 0 || prospectHunts.data.length > 0"
          placement="left-start"
        >
          <button
            @click="fetchProspectHunts"
            type="button"
            class="w-full text-gray-700 group flex items-center justify-between p-2 text-xs hover:bg-gray-100 hover:text-gray-900"
            data-test="add-task-button"
          >
            <div class="flex items-center">
              <div
                class="inline-flex items-center justify-center flex-shrink-0 text-[1rem] mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500"
              >
                <i class="fas fa-crosshairs" />
              </div>
              View related Hunts
            </div>
            <ChevronRightIcon class="h-4 w-4" />
          </button>
          <template #popper>
            <div class="w-64 flex flex-col">
              <div class="space-y-2">
                <h1 class="px-2 pt-2 text-xs font-semibold text-gray-700">
                  View a Hunt linked to this prospect:
                </h1>
                <ul class="max-h-56 overflow-y-auto divide-y divide-gray-200">
                  <li v-for="hunt in prospectHunts.data" :key="hunt.id">
                    <a
                      @click.prevent="viewHunt(hunt.id)"
                      href=""
                      class="block hover:bg-gray-50"
                    >
                      <div
                        class="flex min-w-0 flex-1 items-center p-1 space-x-2"
                      >
                        <div class="flex-shrink-0 rounded-full p-1.5">
                          <div
                            class="inline-flex items-center justify-center flex-shrink-0 text-[1rem] h-4 w-4 text-gray-400 group-hover:text-gray-500"
                          >
                            <i class="fas fa-crosshairs" />
                          </div>
                        </div>
                        <div class="min-w-0 flex-1">
                          <p class="truncate text-xs font-medium text-gray-700">
                            {{
                              hunt.name === "Hunt"
                                ? `Hunt ${hunt.id}`
                                : hunt.name
                            }}
                          </p>
                        </div>
                      </div>
                    </a>
                  </li>
                  <InfiniteLoading
                    v-if="
                      signedIn && prospectHuntsPagy && prospectHuntsPagy.next
                    "
                    @infinite="loadProspectHunts"
                  />
                </ul>
              </div>
            </div>
          </template>
        </VDropdown>
        <VDropdown
          v-if="
            companyHuntIds.length > 0 || prospectEligibleHunts.data.length > 0
          "
          placement="left-start"
        >
          <button
            @click="fetchProspectEligibleHunts"
            type="button"
            class="w-full text-gray-700 group flex items-center justify-between p-2 text-xs hover:bg-gray-100 hover:text-gray-900"
            data-test="add-task-button"
          >
            <div class="flex items-center">
              <LinkIcon
                class="mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500"
              />
              Link to active Hunt
            </div>
            <ChevronRightIcon class="h-4 w-4" />
          </button>
          <template #popper>
            <div class="w-64 flex flex-col">
              <div class="space-y-2">
                <h1 class="px-2 pt-2 text-xs font-semibold text-gray-700">
                  Select a Hunt<template v-if="companyName">
                    for {{ companyName }}:</template
                  >
                </h1>
                <ul class="max-h-56 overflow-y-auto divide-y divide-gray-200">
                  <li v-for="hunt in prospectEligibleHunts.data" :key="hunt.id">
                    <a
                      v-if="!standaloneCompanyId && !availabilityGroupId"
                      @click.prevent="prospectHuntConfirmation(hunt.id)"
                      href=""
                      class="block hover:bg-gray-50"
                    >
                      <div
                        class="flex min-w-0 flex-1 items-center p-1 space-x-2"
                      >
                        <div class="flex-shrink-0 rounded-full p-1.5">
                          <div
                            class="inline-flex items-center justify-center flex-shrink-0 text-[1rem] h-4 w-4 text-gray-400 group-hover:text-gray-500"
                          >
                            <i class="fas fa-crosshairs" />
                          </div>
                        </div>
                        <div class="min-w-0 flex flex-1">
                          <span
                            class="truncate text-xs font-medium text-gray-700"
                          >
                            {{
                              hunt.name === "Hunt"
                                ? `Hunt ${hunt.id}`
                                : hunt.name
                            }}
                          </span>
                        </div>
                      </div>
                    </a>
                    <a
                      v-else-if="companyId"
                      @click.prevent="prospectHuntGroupConfirmation(hunt.id)"
                      href=""
                      class="block hover:bg-gray-50"
                    >
                      <div
                        class="flex min-w-0 flex-1 items-center p-1 space-x-2"
                      >
                        <div class="flex-shrink-0 rounded-full p-1.5">
                          <div
                            class="inline-flex items-center justify-center flex-shrink-0 text-[1rem] h-4 w-4 text-gray-400 group-hover:text-gray-500"
                          >
                            <i class="fas fa-crosshairs" />
                          </div>
                        </div>
                        <div class="min-w-0 flex flex-1">
                          <span
                            class="truncate text-xs font-medium text-gray-700"
                          >
                            {{
                              hunt.name === "Hunt"
                                ? `Hunt ${hunt.id}`
                                : hunt.name
                            }}
                          </span>
                        </div>
                      </div>
                    </a>
                    <VDropdown v-else placement="right">
                      <button
                        class="w-full text-gray-700 group flex items-center justify-between p-2 text-xs hover:bg-gray-50 hover:text-gray-900"
                      >
                        <div
                          class="flex min-w-0 flex-1 items-center p-1 space-x-2"
                        >
                          <div class="flex-shrink-0 rounded-full p-1.5">
                            <div
                              class="inline-flex items-center justify-center flex-shrink-0 text-[1rem] h-4 w-4 text-gray-400 group-hover:text-gray-500"
                            >
                              <i class="fas fa-crosshairs" />
                            </div>
                          </div>
                          <div class="min-w-0 flex flex-1">
                            <span
                              class="truncate text-xs font-medium text-gray-700"
                            >
                              {{
                                hunt.name === "Hunt"
                                  ? `Hunt ${hunt.id}`
                                  : hunt.name
                              }}
                            </span>
                          </div>
                        </div>
                        <ChevronRightIcon class="h-4 w-4" />
                      </button>
                      <template #popper>
                        <SpaceUsageActionGroupSelector
                          @single-space-action="
                            prospectHuntConfirmation(hunt.id)
                          "
                          @space-group-action="
                            prospectHuntGroupConfirmation(hunt.id)
                          "
                        />
                      </template>
                    </VDropdown>
                  </li>
                  <InfiniteLoading
                    v-if="
                      signedIn &&
                      prospectEligibleHuntsPagy &&
                      prospectEligibleHuntsPagy.next
                    "
                    @infinite="loadProspectEligibleHunts"
                  />
                </ul>
              </div>
            </div>
          </template>
        </VDropdown>

        <button
          @click.prevent="awardExistingAvailability"
          :disabled="originatingData"
          class="w-full text-gray-700 group flex items-center p-2 text-xs hover:bg-gray-100 hover:text-gray-900"
          role="menuitem"
          tabindex="-1"
          id="menu-item-4"
          data-test="award-existing-availability-button"
        >
          <svg
            class="flex-shrink-0 mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              d="M8.433 7.418c.155-.103.346-.196.567-.267v1.698a2.305 2.305 0 01-.567-.267C8.07 8.34 8 8.114 8 8c0-.114.07-.34.433-.582zM11 12.849v-1.698c.22.071.412.164.567.267.364.243.433.468.433.582 0 .114-.07.34-.433.582a2.305 2.305 0 01-.567.267z"
            />
            <path
              fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a1 1 0 10-2 0v.092a4.535 4.535 0 00-1.676.662C6.602 6.234 6 7.009 6 8c0 .99.602 1.765 1.324 2.246.48.32 1.054.545 1.676.662v1.941c-.391-.127-.68-.317-.843-.504a1 1 0 10-1.51 1.31c.562.649 1.413 1.076 2.353 1.253V15a1 1 0 102 0v-.092a4.535 4.535 0 001.676-.662C13.398 13.766 14 12.991 14 12c0-.99-.602-1.765-1.324-2.246A4.535 4.535 0 0011 9.092V7.151c.391.127.68.317.843.504a1 1 0 101.511-1.31c-.563-.649-1.413-1.076-2.354-1.253V5z"
              clip-rule="evenodd"
            />
          </svg>
          Move to signed LOI
        </button>
      </div>
      <div v-if="context === 'awarded-space-users'" class="py-1" role="none">
        <button
          @click.prevent="dropUnderContract"
          :disabled="originatingData"
          class="w-full text-gray-700 group flex items-center p-2 text-xs hover:bg-gray-100 hover:text-gray-900"
          role="menuitem"
          tabindex="-1"
          id="menu-item-4"
          data-test="drop-under-contract-availability-button"
        >
          <NoSymbolIcon
            class="flex-shrink-0 mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500"
          />
          Move back to available
        </button>
      </div>
      <template v-if="dealActionsDisplayable">
        <div v-if="existingAvailabilityId" class="py-1" role="none">
          <VDropdown
            v-if="
              usageTriggeringHuntIds.length > 0 ||
              usageFulfillingHuntIds.length > 0
            "
            placement="left-start"
          >
            <button
              @click="fetchLinkedHunts"
              type="button"
              class="w-full text-gray-700 group flex items-center justify-between p-2 text-xs hover:bg-gray-100 hover:text-gray-900"
              data-test="add-task-button"
            >
              <div class="flex items-center">
                <div
                  class="inline-flex items-center justify-center flex-shrink-0 text-[1rem] mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                >
                  <i class="fas fa-crosshairs" />
                </div>
                View related Hunts
              </div>
              <ChevronRightIcon class="h-4 w-4" />
            </button>
            <template #popper>
              <div class="w-64 flex flex-col">
                <div class="space-y-2">
                  <h1 class="px-2 pt-2 text-xs font-semibold text-gray-700">
                    View a Hunt linked to this space usage:
                  </h1>
                  <ul class="max-h-56 overflow-y-auto divide-y divide-gray-200">
                    <li v-for="hunt in linkedHunts.data" :key="hunt.id">
                      <a
                        @click.prevent="viewHunt(hunt.id)"
                        href=""
                        class="block hover:bg-gray-50"
                      >
                        <div
                          class="flex min-w-0 flex-1 items-center p-1 space-x-2"
                        >
                          <div class="flex-shrink-0 rounded-full p-1.5">
                            <div
                              class="inline-flex items-center justify-center flex-shrink-0 text-[1rem] h-4 w-4 text-gray-400 group-hover:text-gray-500"
                            >
                              <i class="fas fa-crosshairs" />
                            </div>
                          </div>
                          <div class="min-w-0 flex-1">
                            <p
                              class="truncate text-xs font-medium text-gray-700"
                            >
                              {{
                                hunt.name === "Hunt"
                                  ? `Hunt ${hunt.id}`
                                  : hunt.name
                              }}
                            </p>
                          </div>
                        </div>
                      </a>
                    </li>
                    <InfiniteLoading
                      v-if="signedIn && linkedHuntsPagy && linkedHuntsPagy.next"
                      @infinite="loadLinkedHunts"
                    />
                  </ul>
                </div>
              </div>
            </template>
          </VDropdown>
          <template v-else>
            <a
              v-if="!availabilityGroupId"
              @click.prevent="linkedHuntConfirmation"
              href=""
              class="text-gray-700 group flex items-center p-2 text-xs hover:bg-gray-100 hover:text-gray-900"
              role="menuitem"
              tabindex="-1"
              id="menu-item-1"
              data-test="create-hunt-button"
            >
              <div
                class="inline-flex items-center justify-center flex-shrink-0 text-[1rem] mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500"
              >
                <i class="fas fa-crosshairs" />
              </div>
              Link to new Hunt
            </a>
            <VDropdown v-else placement="right">
              <button
                class="w-full text-gray-700 group flex items-center justify-between p-2 text-xs hover:bg-gray-100 hover:text-gray-900"
                role="menuitem"
                tabindex="-1"
                id="menu-item-0"
              >
                <div class="flex items-center">
                  <div
                    class="inline-flex items-center justify-center flex-shrink-0 text-[1rem] mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                  >
                    <i class="fas fa-crosshairs" />
                  </div>
                  Link to new Hunt
                </div>
                <ChevronRightIcon class="h-4 w-4" />
              </button>
              <template #popper>
                <SpaceUsageActionGroupSelector
                  @single-space-action="singleAction('newHunt')"
                  @space-group-action="groupAction('newHunt')"
                />
              </template>
            </VDropdown>
          </template>
          <VDropdown
            v-if="untriggeredHuntIds.length > 0"
            placement="left-start"
          >
            <button
              @click="fetchUntriggeredHunts"
              type="button"
              class="w-full text-gray-700 group flex items-center justify-between p-2 text-xs hover:bg-gray-100 hover:text-gray-900"
              data-test="add-task-button"
            >
              <div class="flex items-center">
                <LinkIcon
                  class="mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                />
                Link to active Hunt
              </div>
              <ChevronRightIcon class="h-4 w-4" />
            </button>
            <template #popper>
              <div class="w-64 flex flex-col">
                <div class="space-y-2">
                  <h1 class="px-2 pt-2 text-xs font-semibold text-gray-700">
                    Select a Hunt<template v-if="companyName">
                      for {{ companyName }}:</template
                    >
                  </h1>
                  <ul class="max-h-56 overflow-y-auto divide-y divide-gray-200">
                    <li v-for="hunt in untriggeredHunts.data" :key="hunt.id">
                      <a
                        v-if="!availabilityGroupId"
                        @click.prevent="triggerHuntConfirmation(hunt.id)"
                        href=""
                        class="block hover:bg-gray-50"
                      >
                        <div
                          class="flex min-w-0 flex-1 items-center p-1 space-x-2"
                        >
                          <div class="flex-shrink-0 rounded-full p-1.5">
                            <div
                              class="inline-flex items-center justify-center flex-shrink-0 text-[1rem] h-4 w-4 text-gray-400 group-hover:text-gray-500"
                            >
                              <i class="fas fa-crosshairs" />
                            </div>
                          </div>
                          <div class="min-w-0 flex flex-1">
                            <span
                              class="truncate text-xs font-medium text-gray-700"
                            >
                              {{
                                hunt.name === "Hunt"
                                  ? `Hunt ${hunt.id}`
                                  : hunt.name
                              }}
                            </span>
                          </div>
                        </div>
                      </a>
                      <VDropdown v-else placement="right">
                        <button
                          class="w-full text-gray-700 group flex items-center justify-between p-2 text-xs hover:bg-gray-50 hover:text-gray-900"
                        >
                          <div
                            class="flex min-w-0 flex-1 items-center p-1 space-x-2"
                          >
                            <div class="flex-shrink-0 rounded-full p-1.5">
                              <div
                                class="inline-flex items-center justify-center flex-shrink-0 text-[1rem] h-4 w-4 text-gray-400 group-hover:text-gray-500"
                              >
                                <i class="fas fa-crosshairs" />
                              </div>
                            </div>
                            <div class="min-w-0 flex flex-1">
                              <span
                                class="truncate text-xs font-medium text-gray-700"
                              >
                                {{
                                  hunt.name === "Hunt"
                                    ? `Hunt ${hunt.id}`
                                    : hunt.name
                                }}
                              </span>
                            </div>
                          </div>
                          <ChevronRightIcon class="h-4 w-4" />
                        </button>
                        <template #popper>
                          <SpaceUsageActionGroupSelector
                            @single-space-action="
                              triggerHuntConfirmation(hunt.id)
                            "
                            @space-group-action="
                              triggerHuntGroupConfirmation(hunt.id)
                            "
                          />
                        </template>
                      </VDropdown>
                    </li>
                    <InfiniteLoading
                      v-if="
                        signedIn &&
                        untriggeredHuntsPagy &&
                        untriggeredHuntsPagy.next
                      "
                      @infinite="loadUntriggeredHunts"
                    />
                  </ul>
                </div>
              </div>
            </template>
          </VDropdown>
          <VDropdown
            v-if="unfulfilledHuntIds.length > 0"
            placement="left-start"
          >
            <button
              @click="fetchUnfulfilledHunts"
              type="button"
              class="w-full text-gray-700 group flex items-center justify-between p-2 text-xs hover:bg-gray-100 hover:text-gray-900"
              data-test="add-task-button"
            >
              <div class="flex items-center">
                <CheckBadgeIcon
                  class="mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                />
                Fulfill a Hunt
              </div>
              <ChevronRightIcon class="h-4 w-4" />
            </button>
            <template #popper>
              <div class="w-64 flex flex-col">
                <div class="space-y-2">
                  <h1 class="px-2 pt-2 text-xs font-semibold text-gray-700">
                    Select a Hunt<template v-if="companyName">
                      for {{ companyName }}:</template
                    >
                  </h1>
                  <ul class="max-h-56 overflow-y-auto divide-y divide-gray-200">
                    <li v-for="hunt in unfulfilledHunts.data" :key="hunt.id">
                      <a
                        v-if="!availabilityGroupId"
                        @click.prevent="fulfillHuntConfirmation(hunt.id)"
                        href=""
                        class="block hover:bg-gray-50"
                      >
                        <div
                          class="flex min-w-0 flex-1 items-center p-1 space-x-2"
                        >
                          <div class="flex-shrink-0 rounded-full p-1.5">
                            <div
                              class="inline-flex items-center justify-center flex-shrink-0 text-[1rem] h-4 w-4 text-gray-400 group-hover:text-gray-500"
                            >
                              <i class="fas fa-crosshairs" />
                            </div>
                          </div>
                          <div class="min-w-0 flex flex-1">
                            <span
                              class="truncate text-xs font-medium text-gray-700"
                            >
                              {{
                                hunt.name === "Hunt"
                                  ? `Hunt ${hunt.id}`
                                  : hunt.name
                              }}
                            </span>
                          </div>
                        </div>
                      </a>
                      <VDropdown v-else placement="right">
                        <button
                          class="w-full text-gray-700 group flex items-center justify-between p-2 text-xs hover:bg-gray-50 hover:text-gray-900"
                        >
                          <div
                            class="flex min-w-0 flex-1 items-center p-1 space-x-2"
                          >
                            <div class="flex-shrink-0 rounded-full p-1.5">
                              <div
                                class="inline-flex items-center justify-center flex-shrink-0 text-[1rem] h-4 w-4 text-gray-400 group-hover:text-gray-500"
                              >
                                <i class="fas fa-crosshairs" />
                              </div>
                            </div>
                            <div class="min-w-0 flex flex-1">
                              <span
                                class="truncate text-xs font-medium text-gray-700"
                              >
                                {{
                                  hunt.name === "Hunt"
                                    ? `Hunt ${hunt.id}`
                                    : hunt.name
                                }}
                              </span>
                            </div>
                          </div>
                          <ChevronRightIcon class="h-4 w-4" />
                        </button>
                        <template #popper>
                          <SpaceUsageActionGroupSelector
                            @single-space-action="
                              fulfillHuntConfirmation(hunt.id)
                            "
                            @space-group-action="
                              fulfillHuntGroupConfirmation(hunt.id)
                            "
                          />
                        </template>
                      </VDropdown>
                    </li>
                    <InfiniteLoading
                      v-if="
                        signedIn &&
                        unfulfilledHuntsPagy &&
                        unfulfilledHuntsPagy.next
                      "
                      @infinite="loadUnfulfilledHunts"
                    />
                  </ul>
                </div>
              </div>
            </template>
          </VDropdown>
        </div>
        <div v-if="existingAvailabilityId" class="py-1" role="none">
          <template v-if="!isSublease">
            <a
              v-if="!availabilityGroupId"
              @click.prevent="makeAvailableSingleConfirmation"
              href=""
              class="text-gray-700 group flex items-center p-2 text-xs hover:bg-gray-100 hover:text-gray-900"
              role="menuitem"
              tabindex="-1"
              id="menu-item-0"
              data-test="make-available-button"
            >
              <FlagIcon
                class="mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500"
              />
              Make available
            </a>
            <VDropdown v-else placement="right">
              <button
                class="w-full text-gray-700 group flex items-center justify-between p-2 text-xs hover:bg-gray-100 hover:text-gray-900"
                role="menuitem"
                tabindex="-1"
                id="menu-item-0"
              >
                <div class="flex items-center">
                  <FlagIcon
                    class="mr-2 h-4 w-4 text-gray-400 group-hover:text-indigo-500"
                  />
                  Make available
                </div>
                <ChevronRightIcon class="h-4 w-4" />
              </button>
              <template #popper>
                <SpaceUsageActionGroupSelector
                  @single-space-action="singleAction('makeAvailable')"
                  @space-group-action="groupAction('makeAvailable')"
                />
              </template>
            </VDropdown>
          </template>
          <template v-if="renewable && !isSublease">
            <a
              v-if="!availabilityGroupId"
              @click.prevent="renewSingleConfirmation"
              href=""
              class="text-gray-700 group flex items-center p-2 text-xs hover:bg-gray-100 hover:text-gray-900"
              role="menuitem"
              tabindex="-1"
              id="menu-item-0"
              data-test="renew-button"
            >
              <ArrowPathIcon
                class="mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500"
              />
              Renew
            </a>
            <VDropdown v-else placement="right">
              <button
                class="w-full text-gray-700 group flex items-center justify-between p-2 text-xs hover:bg-gray-100 hover:text-gray-900"
                role="menuitem"
                tabindex="-1"
                id="menu-item-0"
              >
                <div class="flex items-center">
                  <ArrowPathIcon
                    class="mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                  />
                  Renew
                </div>
                <ChevronRightIcon class="h-4 w-4" />
              </button>
              <template #popper>
                <SpaceUsageActionGroupSelector
                  @single-space-action="singleAction('renew')"
                  @space-group-action="groupAction('renew')"
                />
              </template>
            </VDropdown>
          </template>
          <template v-if="vacatable && renewable">
            <a
              v-if="!availabilityGroupId"
              @click.prevent="vacateUponExpirationSingleConfirmation"
              href=""
              class="text-gray-700 group flex items-center p-2 text-xs hover:bg-gray-100 hover:text-gray-900"
              role="menuitem"
              tabindex="-1"
              id="menu-item-1"
              data-test="vacate-upon-expiration-button"
            >
              <TruckIcon
                class="mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500"
              />
              Vacate Upon Expiration
            </a>
            <VDropdown v-else placement="right">
              <button
                class="w-full text-gray-700 group flex items-center justify-between p-2 text-xs hover:bg-gray-100 hover:text-gray-900"
                role="menuitem"
                tabindex="-1"
                id="menu-item-0"
              >
                <div class="flex items-center">
                  <TruckIcon
                    class="mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                  />
                  Vacate Upon Expiration
                </div>
                <ChevronRightIcon class="h-4 w-4" />
              </button>
              <template #popper>
                <SpaceUsageActionGroupSelector
                  @single-space-action="singleAction('vacateUponExpiration')"
                  @space-group-action="groupAction('vacateUponExpiration')"
                />
              </template>
            </VDropdown>
          </template>
          <template v-if="vacatable">
            <a
              v-if="!availabilityGroupId"
              @click.prevent="vacateNowSingleConfirmation"
              href=""
              class="text-gray-700 group flex items-center p-2 text-xs hover:bg-gray-100 hover:text-gray-900"
              role="menuitem"
              tabindex="-1"
              id="menu-item-1"
              data-test="vacate-now-button"
            >
              <TruckIcon
                class="mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500"
              />
              Vacate Now
            </a>
            <VDropdown v-else placement="right">
              <button
                class="w-full text-gray-700 group flex items-center justify-between p-2 text-xs hover:bg-gray-100 hover:text-gray-900"
                role="menuitem"
                tabindex="-1"
                id="menu-item-0"
              >
                <div class="flex items-center">
                  <TruckIcon
                    class="mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                  />
                  Vacate Now
                </div>
                <ChevronRightIcon class="h-4 w-4" />
              </button>
              <template #popper>
                <SpaceUsageActionGroupSelector
                  @single-space-action="singleAction('vacateNow')"
                  @space-group-action="groupAction('vacateNow')"
                />
              </template>
            </VDropdown>
          </template>
          <!-- TODO: IMPLEMENT TERMINATION OPTIONS AND DRIVE OFF OF THOSE -->
          <template v-if="false">
            <a
              v-if="!availabilityGroupId"
              @click.prevent="terminateSingleConfirmation"
              href=""
              class="text-gray-700 group flex items-center p-2 text-xs hover:bg-gray-100 hover:text-gray-900"
              role="menuitem"
              tabindex="-1"
              id="menu-item-1"
              data-test="terminate-button"
            >
              <NoSymbolIcon
                class="mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500"
              />
              Terminate
            </a>
            <VDropdown v-else placement="right">
              <button
                class="w-full text-gray-700 group flex items-center justify-between p-2 text-xs hover:bg-gray-100 hover:text-gray-900"
                role="menuitem"
                tabindex="-1"
                id="menu-item-0"
              >
                <div class="flex items-center">
                  <NoSymbolIcon
                    class="mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                  />
                  Terminate
                </div>
                <ChevronRightIcon class="h-4 w-4" />
              </button>
              <template #popper>
                <SpaceUsageActionGroupSelector
                  @single-space-action="singleAction('terminate')"
                  @space-group-action="groupAction('terminate')"
                />
              </template>
            </VDropdown>
          </template>
        </div>
        <div
          v-if="isSublease && directLeaseConvertible"
          class="py-1"
          role="none"
        >
          <button
            v-if="!availabilityGroupId"
            @click.prevent="subleaseToDirectUponExpirationSingleConfirmation"
            :disabled="originatingData"
            class="w-full text-gray-700 group flex items-center p-2 text-xs hover:bg-gray-100 hover:text-gray-900"
            role="menuitem"
            tabindex="-1"
            id="menu-item-4"
            data-test="sublease-go-direct-button"
          >
            <ArrowDownOnSquareIcon
              class="mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500"
            />
            Convert to Direct Lease Upon Expiration
          </button>
          <VDropdown v-else placement="right">
            <button
              class="w-full text-gray-700 group flex items-center justify-between p-2 text-xs hover:bg-gray-100 hover:text-gray-900"
              role="menuitem"
              tabindex="-1"
              id="menu-item-0"
            >
              <div class="flex items-center">
                <ArrowDownOnSquareIcon
                  class="mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                />
                Convert to Direct Lease Upon Expiration
              </div>
              <ChevronRightIcon class="h-4 w-4" />
            </button>
            <template #popper>
              <SpaceUsageActionGroupSelector
                @single-space-action="
                  singleAction('subleaseToDirectUponExpiration')
                "
                @space-group-action="
                  groupAction('subleaseToDirectUponExpiration')
                "
              />
            </template>
          </VDropdown>
        </div>
      </template>
    </template>
  </div>
</template>

<script setup>
import SpaceUsageActionGroupSelector from "@/components/space-usage-builder/SpaceUsageActionGroupSelector.vue";
import SpaceUsageDetail from "@/components/space-usage-builder/SpaceUsageDetail.vue";
import { useSpaceUsageBuilderStore } from "@/stores/spaceUsageBuilder";
import { useCrowdsourcedChangeGroupStore } from "@/stores/crowdsourcedChangeGroup";
import { useTimeTravelStore } from "@/stores/timeTravel";
import { useModalStore } from "@/stores/modal";
import { useUserStore } from "@/stores/user";
import { ref, computed } from "vue";
import { storeToRefs } from "pinia";
import _ from "lodash";
import api from "@/router/api";
import moment from "moment";
import decoratingAndFieldKey from "@/components/crowdsourcing/decoratingAndFieldKey";
import {
  ArrowPathIcon,
  NoSymbolIcon,
  TruckIcon,
  CheckBadgeIcon,
  LinkIcon,
  FlagIcon,
  ChevronRightIcon,
  Squares2X2Icon,
  ArrowDownOnSquareIcon,
  DocumentTextIcon,
} from "@heroicons/vue/20/solid";
import { useRoute, useRouter } from "vue-router";

const props = defineProps([
  "topLevelSpaceDataField",
  "decoratingDataField",
  "existingAvailabilityId",
  "availabilityGroupId",
  "companyId",
  "layerType",
  "context",
  "depth",
  "restricted",
]);
const emit = defineEmits([
  "refetch-availability",
  "refetch-group-availabilities",
]);

const userStore = useUserStore();
const { signedIn } = storeToRefs(userStore);
const modalStore = useModalStore();
const { confirmationPayload } = storeToRefs(modalStore);

const spaceUsageBuilderStore = useSpaceUsageBuilderStore();
const { spaceUsageBuilder, crossInteraction } = storeToRefs(
  spaceUsageBuilderStore,
);
const crowdsourcedChangeGroupStore = useCrowdsourcedChangeGroupStore();
const { originatingData, changeGroupId } = storeToRefs(
  crowdsourcedChangeGroupStore,
);
const timeTravelStore = useTimeTravelStore();
const { asOfMilliseconds } = storeToRefs(timeTravelStore);

const spaceUsageDataField = ref(null);
const linkedHunts = ref({
  data: [],
  pagy: null,
});
const linkedHuntsPagy = computed(() => linkedHunts.value.pagy);
const prospectHunts = ref({
  data: [],
  pagy: null,
});
const prospectHuntsPagy = computed(() => prospectHunts.value.pagy);
const prospectEligibleHunts = ref({
  data: [],
  pagy: null,
});
const prospectEligibleHuntsPagy = computed(
  () => prospectEligibleHunts.value.pagy,
);
const untriggeredHunts = ref({
  data: [],
  pagy: null,
});
const untriggeredHuntsPagy = computed(() => untriggeredHunts.value.pagy);
const unfulfilledHunts = ref({
  data: [],
  pagy: null,
});
const unfulfilledHuntsPagy = computed(() => unfulfilledHunts.value.pagy);

const dealActionsDisplayable = computed(() => {
  return !_.includes(
    [
      "space availability-players",
      "availability-group-players",
      "awarded-space-users",
    ],
    props.context,
  );
});
const canViewExistingAvailability = computed(() => {
  return (
    props.existingAvailabilityId &&
    _.includes(["space-usage-stack", "floor-area-pop-up"], props.context)
  );
});
const spaceUsageId = computed(
  () => props.decoratingDataField?.fieldContent?.spaceUsageId,
);
const renewable = computed(() => {
  return props.decoratingDataField?.fieldContent?.spaceUsageRenewable;
});
const vacatable = computed(() => {
  return props.decoratingDataField?.fieldContent?.spaceUsageVacatable;
});
const directLeaseConvertible = computed(() => {
  return props.decoratingDataField?.fieldContent?.spaceUsageDirectCovertible;
});
const isSublease = computed(() => {
  return props.decoratingDataField?.fieldContent?.isSublease;
});
const companyName = computed(
  () => props.decoratingDataField?.fieldContent?.name,
);
const standaloneCompanyId = computed(() => {
  if (
    props.companyId &&
    props.companyId === props.decoratingDataField?.fieldContent?.companyId
  ) {
    return props.companyId;
  } else {
    return null;
  }
});
const companyHuntIds = computed(
  () => props.decoratingDataField?.fieldContent?.huntIds || [],
);
const prospectHuntIds = computed(
  () => props.decoratingDataField?.fieldContent?.prospectHuntIds || [],
);
const usageTriggeringHuntIds = computed(
  () =>
    props.decoratingDataField?.fieldContent?.spaceUsageTriggeringHuntIds || [],
);
const usageFulfillingHuntIds = computed(
  () =>
    props.decoratingDataField?.fieldContent?.spaceUsageFulfillingHuntIds || [],
);
const usageCombinedHuntIds = computed(() =>
  _.union(usageTriggeringHuntIds.value, usageFulfillingHuntIds.value),
);
const untriggeredHuntIds = computed(() => {
  return _.difference(companyHuntIds.value, usageTriggeringHuntIds.value);
});
const unfulfilledHuntIds = computed(() => {
  return _.difference(companyHuntIds.value, usageFulfillingHuntIds.value);
});

function cleanUrl(url) {
  return url.replace("/api/v1/", "");
}

async function fetchProspectEligibleHunts() {
  if (companyHuntIds.value) {
    // TODO: Filter by availability property use, location, size, etc.
    const payload = {
      huntIds: companyHuntIds.value,
      huntType: "occupier",
      asOf: asOfMilliseconds.value,
    };
    const huntsResponse = await api.post(`company_hunts`, payload);

    prospectEligibleHunts.value = huntsResponse?.data;
  }
}

const loadProspectEligibleHunts = async ($state) => {
  if (signedIn.value && prospectEligibleHuntsPagy.value.next) {
    const endpoint = cleanUrl(prospectEligibleHuntsPagy.value.next_url);
    try {
      api.get(endpoint).then((json) => {
        const { data, pagy } = json.data;

        prospectEligibleHunts.value.data = _.concat(
          prospectEligibleHunts.value.data,
          data,
        );
        prospectEligibleHunts.value.pagy = pagy;
        if (data.length < 20) $state.complete();
        else {
          $state.loaded();
        }
      });
    } catch (error) {
      $state.error();
    }
  } else {
    $state.complete();
  }
};

async function fetchUntriggeredHunts() {
  const payload = {
    huntIds: untriggeredHuntIds.value,
  };
  const huntsResponse = await api.post(`company_hunts`, payload);

  untriggeredHunts.value = huntsResponse?.data;
}

const loadUntriggeredHunts = async ($state) => {
  if (signedIn.value && untriggeredHuntsPagy.value.next) {
    const endpoint = cleanUrl(untriggeredHuntsPagy.value.next_url);
    try {
      api.get(endpoint).then((json) => {
        const { data, pagy } = json.data;

        untriggeredHunts.value.data = _.concat(
          untriggeredHunts.value.data,
          data,
        );
        untriggeredHunts.value.pagy = pagy;
        if (data.length < 20) $state.complete();
        else {
          $state.loaded();
        }
      });
    } catch (error) {
      $state.error();
    }
  } else {
    $state.complete();
  }
};

async function fetchUnfulfilledHunts() {
  const payload = {
    huntIds: unfulfilledHuntIds.value,
  };
  const huntsResponse = await api.post(`company_hunts`, payload);

  unfulfilledHunts.value = huntsResponse?.data;
}

const loadUnfulfilledHunts = async ($state) => {
  if (signedIn.value && unfulfilledHuntsPagy.value.next) {
    const endpoint = cleanUrl(unfulfilledHuntsPagy.value.next_url);
    try {
      api.get(endpoint).then((json) => {
        const { data, pagy } = json.data;

        unfulfilledHunts.value.data = _.concat(
          unfulfilledHunts.value.data,
          data,
        );
        unfulfilledHunts.value.pagy = pagy;
        if (data.length < 20) $state.complete();
        else {
          $state.loaded();
        }
      });
    } catch (error) {
      $state.error();
    }
  } else {
    $state.complete();
  }
};

async function fetchProspectHunts() {
  if (prospectHuntIds.value) {
    const payload = {
      huntIds: prospectHuntIds.value,
    };
    const huntsResponse = await api.post(`company_hunts`, payload);

    prospectHunts.value = huntsResponse?.data;
  }
}

const loadProspectHunts = async ($state) => {
  if (signedIn.value && prospectHuntsPagy.value.next) {
    const endpoint = cleanUrl(prospectHuntsPagy.value.next_url);
    try {
      api.get(endpoint).then((json) => {
        const { data, pagy } = json.data;

        prospectHunts.value.data = _.concat(prospectHunts.value.data, data);
        prospectHunts.value.pagy = pagy;
        if (data.length < 20) $state.complete();
        else {
          $state.loaded();
        }
      });
    } catch (error) {
      $state.error();
    }
  } else {
    $state.complete();
  }
};

async function fetchLinkedHunts() {
  const payload = {
    huntIds: usageCombinedHuntIds.value,
  };
  const huntsResponse = await api.post(`company_hunts`, payload);

  linkedHunts.value = huntsResponse?.data;
}

const loadLinkedHunts = async ($state) => {
  if (signedIn.value && linkedHuntsPagy.value.next) {
    const endpoint = cleanUrl(linkedHuntsPagy.value.next_url);
    try {
      api.get(endpoint).then((json) => {
        const { data, pagy } = json.data;

        linkedHunts.value.data = _.concat(linkedHunts.value.data, data);
        linkedHunts.value.pagy = pagy;
        if (data.length < 20) $state.complete();
        else {
          $state.loaded();
        }
      });
    } catch (error) {
      $state.error();
    }
  } else {
    $state.complete();
  }
};

function viewExistingAvailability() {
  const spaceKey = decoratingAndFieldKey(props.topLevelSpaceDataField);
  if (!spaceUsageBuilder.value) {
    spaceUsageBuilderStore.beginBuilder({
      spaceDataField: props.topLevelSpaceDataField,
    });
  } else if (!spaceUsageBuilder.value.spaces[spaceKey]) {
    spaceUsageBuilderStore.upsertSpaceUsageBuilder({
      spaceDataField: props.topLevelSpaceDataField,
    });
  }

  crossInteraction.value = {
    spaceKey: decoratingAndFieldKey(props.topLevelSpaceDataField),
    combinedKey: props.existingAvailabilityId,
    focus: "Players",
    source: "InvolvedCompanyMenu",
  };
}
function viewExistingAvailabilityGroup() {
  if (props.availabilityGroupId) {
    spaceUsageBuilderStore.upsertSpaceUsageBuilder({
      existingGroupId: props.availabilityGroupId,
    });
  }
}
async function awardExistingAvailability() {
  if (!originatingData.value) {
    if (props.availabilityGroupId) {
      spaceUsageBuilderStore.clearBuilder(false);
      await spaceUsageBuilderStore.awardGroupAvailability({
        availabilityGroupId: props.availabilityGroupId,
        companyId: props.companyId,
      });
      emit("refetch-group-availabilities");
    } else {
      spaceUsageBuilderStore.clearBuilder(false);
      await spaceUsageBuilderStore.awardAvailability({
        availabilityId: props.existingAvailabilityId,
        companyInvolvementId: props.decoratingDataField.fieldContentId,
      });
      emit("refetch-availability");
    }
  }
}
async function dropUnderContract() {
  if (!originatingData.value) {
    if (props.availabilityGroupId) {
      spaceUsageBuilderStore.clearBuilder(false);
      await spaceUsageBuilderStore.dropAwardedGroupAvailability({
        availabilityGroupId: props.availabilityGroupId,
      });
      emit("refetch-group-availabilities");
    } else {
      spaceUsageBuilderStore.clearBuilder(false);
      await spaceUsageBuilderStore.dropAwardedAvailability({
        availabilityId: props.existingAvailabilityId,
      });
      emit("refetch-availability");
    }
  }
}

function singleAction(actionType) {
  switch (actionType) {
    case "makeAvailable":
      makeAvailableSingleConfirmation();
      break;
    case "renew":
      renewSingleConfirmation();
      break;
    case "terminate":
      terminateSingleConfirmation();
      break;
    case "vacateNow":
      vacateNowSingleConfirmation();
      break;
    case "vacateUponExpiration":
      vacateUponExpirationSingleConfirmation();
      break;
    case "subleaseToDirectUponExpiration":
      subleaseToDirectUponExpirationSingleConfirmation();
      break;
    case "newHunt":
      linkedHuntConfirmation();
      break;
    default:
      break;
  }
}

function groupAction(actionType) {
  switch (actionType) {
    case "makeAvailable":
      makeAvailableGroupConfirmation();
      break;
    case "renew":
      renewGroupConfirmation();
      break;
    case "terminate":
      terminateGroupConfirmation();
      break;
    case "vacateNow":
      vacateNowGroupConfirmation();
      break;
    case "vacateUponExpiration":
      vacateUponExpirationGroupConfirmation();
      break;
    case "subleaseToDirectUponExpiration":
      subleaseToDirectUponExpirationGroupConfirmation();
      break;
    case "newHunt":
      linkedHuntGroupConfirmation();
      break;
    default:
      break;
  }
}

function makeAvailableSingleConfirmation() {
  confirmationPayload.value = {
    title: "Make Single Space Available",
    message:
      "This declares that the space is available for lease even though the existing tenant is still under contract. Proceed?",
    affirmText: "Make available",
    affirmCallback: createSpaceAvailability,
  };
}

function makeAvailableGroupConfirmation() {
  confirmationPayload.value = {
    title: "Make Space Group Available",
    message:
      "This declares that the entire premises (all spaces) is available for lease even though the existing tenant is still under contract. Proceed?",
    affirmText: "Make available",
    affirmCallback: createSpaceAvailabilityGroup,
  };
}

function renewSingleConfirmation() {
  confirmationPayload.value = {
    title: "Renew Single Space",
    message:
      "This creates a new space usage with an execution date of today and a commencement date immediately following the existing expiration date. Proceed?",
    affirmText: "Renew",
    affirmCallback: () => {
      renew({
        availabilityId: props.existingAvailabilityId,
        availabilityGroupId: null,
      });
    },
  };
}

function renewGroupConfirmation() {
  confirmationPayload.value = {
    title: "Renew Space Group",
    message:
      "This creates a new space usage across the entire premises (all spaces) with an execution date of today and a commencement date immediately following the existing expiration date. Proceed?",
    affirmText: "Renew",
    affirmCallback: () => {
      renew({
        availabilityId: null,
        availabilityGroupId: props.availabilityGroupId,
      });
    },
  };
}

function vacateNowSingleConfirmation() {
  confirmationPayload.value = {
    title: "Vacate Single Space Now",
    message:
      "This triggers an immediate move-out for this space. The expiration date (if any) is not impacted. Proceed?",
    affirmText: "Vacate Now",
    affirmCallback: () => {
      vacateNow({
        availabilityId: props.existingAvailabilityId,
        availabilityGroupId: null,
      });
    },
  };
}

function vacateNowGroupConfirmation() {
  confirmationPayload.value = {
    title: "Vacate Space Group Now",
    message:
      "This triggers an immediate move-out for the entire premises (all spaces). The expiration date (if any) is not impacted. Proceed?",
    affirmText: "Vacate Now",
    affirmCallback: () => {
      vacateNow({
        availabilityId: null,
        availabilityGroupId: props.availabilityGroupId,
      });
    },
  };
}

function vacateUponExpirationSingleConfirmation() {
  confirmationPayload.value = {
    title: "Vacate Single Space Upon Expiration",
    message:
      "This sets the move-out date for this space to match the expiration date of the lease. Proceed?",
    affirmText: "Vacate Upon Expiration",
    affirmCallback: () => {
      vacateUponExpiration({
        availabilityId: props.existingAvailabilityId,
        availabilityGroupId: null,
      });
    },
  };
}

function vacateUponExpirationGroupConfirmation() {
  confirmationPayload.value = {
    title: "Vacate Space Group Upon Expiration",
    message:
      "This sets the move-out date for the entire premises (all spaces) to match the expiration date of the lease. Proceed?",
    affirmText: "Vacate Upon Expiration",
    affirmCallback: () => {
      vacateUponExpiration({
        availabilityId: null,
        availabilityGroupId: props.availabilityGroupId,
      });
    },
  };
}

function terminateSingleConfirmation() {
  confirmationPayload.value = {
    title: "Terminate Single Space",
    message: "This does something... Proceed?",
    affirmText: "Terminate",
    affirmCallback: () => {
      return;
    },
  };
}

function terminateGroupConfirmation() {
  confirmationPayload.value = {
    title: "Terminate Space Group",
    message: "This does something... Proceed?",
    affirmText: "Terminate",
    affirmCallback: () => {
      return;
    },
  };
}

function subleaseToDirectUponExpirationSingleConfirmation() {
  confirmationPayload.value = {
    title: "Convert Sublease to Direct Lease Upon Expiration (Single Space)",
    message:
      "This creates a new direct lease for the currently selected space with the currently selected sub-tenant. The new lease will have an execution date of today and a commencement date immediately following the existing sublease expiration date. Proceed?",
    affirmText: "Go Direct Upon Expiration",
    affirmCallback: () => {
      subleaseToDirectUponExpiration({
        availabilityId: props.existingAvailabilityId,
        availabilityGroupId: null,
      });
    },
  };
}

function subleaseToDirectUponExpirationGroupConfirmation() {
  confirmationPayload.value = {
    title: "Convert Sublease to Direct Lease Upon Expiration (Space Group)",
    message:
      "This creates a new direct lease for the entire premises (all spaces) with the currently selected sub-tenant. The new lease will have an execution date of today and a commencement date immediately following the existing sublease expiration date. Proceed?",
    affirmText: "Go Direct Upon Expiration",
    affirmCallback: () => {
      subleaseToDirectUponExpiration({
        availabilityId: null,
        availabilityGroupId: props.availabilityGroupId,
      });
    },
  };
}

function linkedHuntConfirmation() {
  confirmationPayload.value = {
    title: "Create Linked Hunt (Single Space)",
    message:
      "Do you want to create a new (Safezone) Hunt for this company that also links to this space usage?",
    affirmText: "Create",
    affirmCallback: () => {
      createLinkedHunt({
        availabilityId: props.existingAvailabilityId,
        availabilityGroupId: null,
      });
    },
  };
}

function linkedHuntGroupConfirmation() {
  confirmationPayload.value = {
    title: "Create Linked Hunt (Space Group)",
    message:
      "Do you want to create a new (Safezone) Hunt for this company that also links to the entire premises (all spaces)?",
    affirmText: "Create",
    affirmCallback: () => {
      createLinkedHunt({
        availabilityId: null,
        availabilityGroupId: props.availabilityGroupId,
      });
    },
  };
}

function triggerHuntConfirmation(huntId) {
  confirmationPayload.value = {
    title: "Link to Active Hunt (Single Space)",
    message:
      "Do you want to link this space to an existing (Safezone) Hunt for this company?",
    affirmText: "Create",
    affirmCallback: () => {
      linkHunt({
        linkType: "triggerHunt",
        huntId,
        availabilityId: props.existingAvailabilityId,
        availabilityGroupId: null,
      });
    },
  };
}

function triggerHuntGroupConfirmation(huntId) {
  confirmationPayload.value = {
    title: "Link to Active Hunt (Space Group)",
    message:
      "Do you want to link the entire premises (all spaces) to an existing (Safezone) Hunt for this company?",
    affirmText: "Create",
    affirmCallback: () => {
      linkHunt({
        linkType: "triggerHunt",
        huntId,
        availabilityId: null,
        availabilityGroupId: props.availabilityGroupId,
      });
    },
  };
}

function fulfillHuntConfirmation(huntId) {
  confirmationPayload.value = {
    title: "Fulfill Hunt (Single Space)",
    message:
      "Do you want to indicate that this space fulfills a (Safezone) Hunt for this company?",
    affirmText: "Create",
    affirmCallback: () => {
      linkHunt({
        linkType: "fulfillHunt",
        huntId,
        availabilityId: props.existingAvailabilityId,
        availabilityGroupId: null,
      });
    },
  };
}

function fulfillHuntGroupConfirmation(huntId) {
  confirmationPayload.value = {
    title: "Fulfill Hunt (Space Group)",
    message:
      "Do you want to indicate that the entire premises (all spaces) fulfills a (Safezone) Hunt for this company?",
    affirmText: "Create",
    affirmCallback: () => {
      linkHunt({
        linkType: "fulfillHunt",
        huntId,
        availabilityId: null,
        availabilityGroupId: props.availabilityGroupId,
      });
    },
  };
}

function prospectHuntConfirmation(huntId) {
  confirmationPayload.value = {
    title: "Link to Active Hunt (Single Space)",
    message:
      "Do you want to link this space to an existing (Safezone) Hunt for this company?",
    affirmText: "Create",
    affirmCallback: () => {
      linkHunt({
        linkType: "prospectHunt",
        huntId,
        availabilityId: props.existingAvailabilityId,
        availabilityGroupId: null,
      });
    },
  };
}

function prospectHuntGroupConfirmation(huntId) {
  confirmationPayload.value = {
    title: "Link to Active Hunt (Space Group)",
    message:
      "Do you want to link the entire premises (all spaces) to an existing (Safezone) Hunt for this company?",
    affirmText: "Create",
    affirmCallback: () => {
      linkHunt({
        linkType: "prospectHunt",
        huntId,
        availabilityId: null,
        availabilityGroupId: props.availabilityGroupId,
      });
    },
  };
}

async function createSpaceAvailability() {
  if (!originatingData.value) {
    const { fieldContentId, fieldContentType } = props.topLevelSpaceDataField;

    const persistPayload = {
      contentId: fieldContentId,
      contentType: fieldContentType,
      upstreamSpaceUsageId: null,
      existingDataFieldId: null,
      companies: [],
      advisors: [],
      date: moment().subtract(1, "days").startOf("day").toDate(),
      state: "live",
      changeGroupId: changeGroupId.value,
    };

    const apiRequestFunc = () =>
      api.post(`space_availabilities`, persistPayload);
    const successCallback = () => {
      timeTravelStore.triggerRefetch();
    };
    const failureCallback = () => {};

    crowdsourcedChangeGroupStore.originateData(
      apiRequestFunc,
      successCallback,
      failureCallback,
    );
  }
}

async function createSpaceAvailabilityGroup() {
  if (!originatingData.value) {
    const persistPayload = {
      availabilityId: null,
      availabilityGroupId: props.availabilityGroupId,
      date: moment().subtract(1, "days").startOf("day").toDate(),
      actionType: "makeAvailable",
      changeGroupId: changeGroupId.value,
    };

    const apiRequestFunc = () =>
      api.post(`space_usage_actions`, persistPayload);
    const successCallback = () => {
      timeTravelStore.triggerRefetch();
    };
    const failureCallback = () => {};

    crowdsourcedChangeGroupStore.originateData(
      apiRequestFunc,
      successCallback,
      failureCallback,
    );
  }
}

async function renew({ availabilityId, availabilityGroupId }) {
  if (!originatingData.value) {
    const persistPayload = {
      availabilityId,
      availabilityGroupId,
      date: moment().subtract(1, "days").startOf("day").toDate(),
      actionType: "renew",
      changeGroupId: changeGroupId.value,
    };

    const apiRequestFunc = () =>
      api.post(`space_usage_actions`, persistPayload);
    const successCallback = () => {
      timeTravelStore.triggerRefetch();
    };
    const failureCallback = () => {};

    crowdsourcedChangeGroupStore.originateData(
      apiRequestFunc,
      successCallback,
      failureCallback,
    );
  }
}

async function vacateUponExpiration({ availabilityId, availabilityGroupId }) {
  if (!originatingData.value) {
    const persistPayload = {
      availabilityId,
      availabilityGroupId,
      actionType: "vacateUponExpiration",
      changeGroupId: changeGroupId.value,
    };

    const apiRequestFunc = () =>
      api.post(`space_usage_actions`, persistPayload);
    const successCallback = () => {
      timeTravelStore.triggerRefetch();
    };
    const failureCallback = () => {};

    crowdsourcedChangeGroupStore.originateData(
      apiRequestFunc,
      successCallback,
      failureCallback,
    );
  }
}

async function vacateNow({ availabilityId, availabilityGroupId }) {
  if (!originatingData.value) {
    const persistPayload = {
      availabilityId,
      availabilityGroupId,
      date: moment().subtract(1, "days").startOf("day").toDate(),
      actionType: "vacateNow",
      changeGroupId: changeGroupId.value,
    };

    const apiRequestFunc = () =>
      api.post(`space_usage_actions`, persistPayload);
    const successCallback = () => {
      timeTravelStore.triggerRefetch();
    };
    const failureCallback = () => {};

    crowdsourcedChangeGroupStore.originateData(
      apiRequestFunc,
      successCallback,
      failureCallback,
    );
  }
}

async function subleaseToDirectUponExpiration({
  availabilityId,
  availabilityGroupId,
}) {
  if (!originatingData.value) {
    const persistPayload = {
      availabilityId,
      availabilityGroupId,
      date: moment().subtract(1, "days").startOf("day").toDate(),
      actionType: "subleaseToDirectUponExpiration",
      changeGroupId: changeGroupId.value,
    };

    const apiRequestFunc = () =>
      api.post(`space_usage_actions`, persistPayload);
    const successCallback = () => {
      timeTravelStore.triggerRefetch();
    };
    const failureCallback = () => {};

    crowdsourcedChangeGroupStore.originateData(
      apiRequestFunc,
      successCallback,
      failureCallback,
    );
  }
}

const router = useRouter();
const route = useRoute();

async function createLinkedHunt({ availabilityId, availabilityGroupId }) {
  if (!originatingData.value) {
    const persistPayload = {
      availabilityId,
      availabilityGroupId,
      companyId: props.decoratingDataField.fieldContent.companyId,
      actionType: "linkedHunt",
      changeGroupId: changeGroupId.value,
    };

    const apiRequestFunc = () =>
      api.post(`space_usage_actions`, persistPayload);
    const successCallback = () => {
      timeTravelStore.triggerRefetch();
    };
    const failureCallback = () => {};

    crowdsourcedChangeGroupStore.originateData(
      apiRequestFunc,
      successCallback,
      failureCallback,
    );
  }
}

async function linkHunt({
  linkType,
  huntId,
  availabilityId,
  availabilityGroupId,
}) {
  if (!originatingData.value) {
    const persistPayload = {
      availabilityId,
      availabilityGroupId,
      huntId,
      companyId:
        linkType === "prospectHunt" && availabilityGroupId
          ? props.companyId
          : null,
      companyInvolvementId:
        linkType === "prospectHunt" && availabilityId
          ? props.decoratingDataField.fieldContentId
          : null,
      actionType: linkType,
      changeGroupId: changeGroupId.value,
    };

    const apiRequestFunc = () =>
      api.post(`space_usage_actions`, persistPayload);
    const successCallback = () => {
      timeTravelStore.triggerRefetch();
    };
    const failureCallback = () => {};

    crowdsourcedChangeGroupStore.originateData(
      apiRequestFunc,
      successCallback,
      failureCallback,
    );
  }
}

async function viewHunt(huntId) {
  if (props.decoratingDataField.fieldContent?.companyId) {
    router.push({
      name: "CompanyShell",
      params: { companyId: props.decoratingDataField.fieldContent.companyId },
      query: {
        ...route.query,
        propertyIds: undefined,
        huntId,
      },
    });
  }
}

async function fetchSpaceUsageDataField() {
  if (spaceUsageId.value) {
    const usageResponse = await api.get(
      `space_usages/${spaceUsageId.value}?json_format=outer_field`,
    );

    if (usageResponse?.data) {
      spaceUsageDataField.value = usageResponse.data;
    }
  }
}

async function fetchGroupMetadata(isVisible) {
  if (isVisible && props.availabilityGroupId && props.companyId) {
    const allPayload = {
      companyId: props.companyId,
      huntType: "occupier",
    };
    const prospectsPayload = {
      companyId: props.companyId,
      huntType: "occupier",
      asOf: asOfMilliseconds.value,
    };
    api.post(`company_hunts`, allPayload).then((json) => {
      prospectHunts.value = json.data;
    });
    api.post(`company_hunts`, prospectsPayload).then((json) => {
      prospectEligibleHunts.value = json.data;
    });
  }
}
</script>
