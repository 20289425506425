<template>
  <tfoot class="border-t border-gray-200 bg-white">
    <tr>
      <td :colspan="tableMeta.colspan" class="px-4 py-3.5">
        <div
          :class="
            workspaceLayout === 'sideBySide' && false
              ? 'flex flex-1 justify-between'
              : 'hidden'
          "
          class=""
        >
          <a
            v-if="paginationMeta.prev"
            href=""
            @click.prevent="previous"
            class="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
            >Previous</a
          >
          <div v-else class="w-1" />
          <a
            v-if="paginationMeta.next"
            href=""
            @click.prevent="next"
            class="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
            >Next</a
          >
        </div>
        <div
          :class="
            workspaceLayout === 'sideBySide' && false
              ? 'hidden'
              : 'flex flex-1 items-center justify-between'
          "
          class=""
        >
          <div
            class="flex items-center space-x-2"
            data-test="pagination-footer"
          >
            <InfiniteLoading v-if="paginationMeta.next" @infinite="fetch" />
            <p class="text-sm text-gray-700">
              Showing
              {{ " " }}
              <span class="font-medium">{{ paginationMeta.from }}</span>
              {{ " " }}
              to
              {{ " " }}
              <span class="font-medium">{{ paginationMeta.to }}</span>
              {{ " " }}
              of
              {{ " " }}
              <span class="font-medium">{{ paginationMeta.count }}</span>
              {{ " " }}
              results
            </p>
          </div>
          <div v-if="false" class="flex items-center">
            <nav
              class="isolate inline-flex -space-x-px rounded-md shadow-sm"
              aria-label="Pagination"
            >
              <a
                href=""
                @click.prevent="previous"
                class="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
              >
                <span class="sr-only">Previous</span>
                <ChevronLeftIcon class="h-5 w-5" aria-hidden="true" />
              </a>
              <a
                v-for="step in displayableSteps"
                :key="step"
                href=""
                @click.prevent="goToPage(step)"
                :class="
                  paginationMeta.page === step
                    ? currentSelectionStyling
                    : inactiveSelectionStyling
                "
                class="relative inline-flex items-center border px-4 py-2 text-sm font-medium focus:z-20"
                >{{ step || "..." }}</a
              >
              <a
                href=""
                @click.prevent="next"
                class="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
              >
                <span class="sr-only">Next</span>
                <ChevronRightIcon class="h-5 w-5" aria-hidden="true" />
              </a>
            </nav>
          </div>
        </div>
      </td>
    </tr>
  </tfoot>
</template>

<script setup>
import { computed } from "vue";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { storeToRefs } from "pinia";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/vue/20/solid";
import _ from "lodash";

const props = defineProps(["paginationMeta", "tableMeta"]);
const emit = defineEmits(["previous", "next", "page", "infinite-fetch"]);
const layoutStore = useWorkspaceLayoutStore();
const { workspaceLayout } = storeToRefs(layoutStore);

const currentSelectionStyling = computed(
  () => "z-10 bg-indigo-50 border-indigo-500 text-indigo-600"
);
const inactiveSelectionStyling = computed(
  () => "bg-white border-gray-300 text-gray-500 hover:bg-gray-50"
);

const displayableSteps = computed(() => {
  if (props.paginationMeta.last <= 5) {
    return _.range(1, props.paginationMeta.last + 1);
  } else if (
    props.paginationMeta.page - 1 !== 1 &&
    props.paginationMeta.page + 1 !== props.paginationMeta.last
  ) {
    return _.concat(
      [1, null],
      [
        props.paginationMeta.page - 1,
        props.paginationMeta.page,
        props.paginationMeta.page + 1,
      ],
      [null, props.paginationMeta.last]
    );
  }

  switch (props.paginationMeta.page) {
    case 1:
      return _.concat([1, 2, 3, null], [props.paginationMeta.last]);
    case props.paginationMeta.last:
      return _.concat(
        [1, null],
        [
          props.paginationMeta.last - 2,
          props.paginationMeta.last - 1,
          props.paginationMeta.last,
        ]
      );
    default:
      return _.concat([
        1,
        2,
        3,
        null,
        props.paginationMeta.last - 2,
        props.paginationMeta.last - 1,
        props.paginationMeta.last,
      ]);
  }
});

function previous() {
  if (props.paginationMeta.page === 1) {
    goToPage(1);
  } else {
    emit("previous", cleanUrl(props.paginationMeta.prev_url));
  }
}
function next() {
  if (props.paginationMeta.page === props.paginationMeta.last) {
    goToPage(props.paginationMeta.last);
  } else {
    emit("next", cleanUrl(props.paginationMeta.next_url));
  }
}
function goToPage(number) {
  if (number) {
    const pageUrl = props.paginationMeta.scaffold_url.replace(
      /__pagy_page__/,
      number
    );

    emit("page", cleanUrl(pageUrl));
  }
}
function cleanUrl(url) {
  return url.replace("/api/v1/", "");
}

const fetch = async ($state) => {
  emit("infinite-fetch", $state);
};
</script>
