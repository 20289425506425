<template>
  <div class="flex items-center space-x-2">
    <input
      v-observe-visibility="{ callback: focusInput, once: true }"
      v-model="editedDate"
      type="date"
      name="date"
      :id="`investment-group-${dateFieldSnakeCaseName}-input`"
      :data-test="`investment-group-${dateFieldSnakeCaseName}-input`"
      class="w-full rounded-full border-gray-300 pl-2 focus:border-indigo-500 focus:ring-indigo-500 text-xs"
    />
    <template v-if="editedDate || existingDate">
      <div class="flex items-center justify-end space-x-2">
        <button
          @click="cancel"
          v-tooltip="`Cancel`"
          type="button"
          class="inline-flex items-center p-1 border border-gray-300 rounded-full shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <XMarkIcon class="h-4 w-4" />
        </button>

        <DataVisibilityButton
          :visibility="visibility"
          :tooltip="
            proveForValue
              ? null
              : `Set ${
                  milestoneObject.statusLabel || 'Unknown state'
                } date portfolio-wide`
          "
          class="inline-flex"
        >
          <template v-slot:button>
            <button
              v-if="editedDate"
              @click="save(null)"
              :disabled="originatingData"
              type="button"
              :class="
                visibility === 'safezone'
                  ? 'bg-yellow-500 hover:bg-yellow-600 focus:ring-yellow-600'
                  : 'bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500'
              "
              class="inline-flex flex-shrink-0 items-center p-1 border border-transparent rounded-full shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2"
              :data-test="`investment-group-${dateFieldSnakeCaseName}-save-button`"
            >
              <PulseLoader
                v-if="originatingData"
                :loading="true"
                size="3px"
                color="#f3f4f6"
              />
              <CheckIcon v-else class="h-4 w-4" />
            </button>
          </template>
        </DataVisibilityButton>
      </div>
    </template>
  </div>
</template>

<script setup>
import { XMarkIcon, CheckIcon } from "@heroicons/vue/20/solid";
import { useCrowdsourcedChangeGroupStore } from "@/stores/crowdsourcedChangeGroup";
import { useNotificationsStore } from "@/stores/notifications";
import { useProveForValueStore } from "@/stores/proveForValue";
import { ref, computed } from "vue";
import moment from "moment";
import api from "@/router/api";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import DataVisibilityButton from "@/components/crowdsourcing/DataVisibilityButton.vue";
import { storeToRefs } from "pinia";

const props = defineProps([
  "investmentGroupId",
  "milestoneObject",
  "existingDate",
  "focus",
  "proveForValue",
  "challengeDataField",
]);
const emit = defineEmits(["completed", "unlocked", "cancel"]);

const crowdsourcedChangeGroupStore = useCrowdsourcedChangeGroupStore();
const { originatingData, changeGroupId } = storeToRefs(
  crowdsourcedChangeGroupStore,
);
const notificationsStore = useNotificationsStore();
const proveForValueStore = useProveForValueStore();

const editedDate = ref(null);

const visibility = computed(() =>
  props.proveForValue ? "public" : "safezone",
);
const snakeCaseProgressionValue = computed(() => props.milestoneObject.value);
const dateFieldSnakeCaseName = computed(() => {
  return `${snakeCaseProgressionValue.value}_date`;
});

function focusInput(isVisible) {
  if (isVisible && props.focus) {
    setTimeout(() => {
      selectInput();
    }, 100);
  }
}
function selectInput() {
  const el = document.getElementById(
    `investment-group-${dateFieldSnakeCaseName.value}-input`,
  );

  if (el) {
    el.focus();
  }
}

function cancel() {
  editedDate.value = null;
  emit("cancel");
}

async function dismissGroupDate() {
  let payload = {
    investmentGroupId: props.investmentGroupId,
    destroyableFieldId: props.challengeDataField.localId,
    changeGroupId: changeGroupId.value,
  };

  const response = await api.post(`investment_group_date_deletions`, payload);

  return response;
}

async function afterPersist(json) {
  if (json.data?.proofStatus === "rejected") {
    await dismissGroupDate();

    save(json.data.proofStatus);
  } else if (json.data?.proofStatus === "accepted") {
    notificationsStore.addNotification("proofAccepted");
    emit("unlocked", { override: "date" });
  } else {
    emit("completed", json.data);
    // this.$store.dispatch("flash", "Saved!");
  }
}

async function persist(newProofStatus) {
  console.log(
    "group date persist",
    props.challengeDataField,
    props.proveForValue,
    newProofStatus,
  );
  if (props.proveForValue && !newProofStatus) {
    let payload = {
      fieldValue: moment(editedDate.value).valueOf(),
      fieldValueType: "date",
      investmentGroupId: props.investmentGroupId,
    };
    const proofResponse = await proveForValueStore.submitProof(
      props.challengeDataField,
      payload,
    );

    return proofResponse;
  } else {
    const response = await api.post(`investment_group_dates`, {
      investmentGroupId: props.investmentGroupId,
      date: moment(editedDate.value).valueOf(),
      fieldName: dateFieldSnakeCaseName.value,
      changeGroupId: changeGroupId.value,
    });

    if (newProofStatus === "rejected") {
      const declassifyPayload = {
        ids: response.data.dataFields.map((field) => field.localId),
      };
      await api.post(`declassify_datapoints`, declassifyPayload);

      notificationsStore.addNotification("proofRejected");
    }

    return response;
  }
}
function save(newProofStatus = null) {
  const apiRequestFunc = () => persist(newProofStatus);
  const successCallback = (json) => afterPersist(json);
  const failureCallback = () => cancel();

  return crowdsourcedChangeGroupStore.originateData(
    apiRequestFunc,
    successCallback,
    failureCallback,
  );
}
</script>
