import { ref } from "vue";
import { defineStore, acceptHMRUpdate } from "pinia";

export const useUserActiveEasyDataInputPayloadItemChannelStore = defineStore(
  "userActiveEasyDataInputPayloadItemChannel",
  () => {
    const UserActiveEasyDataInputPayloadItemChannel = ref(null);

    return {
      UserActiveEasyDataInputPayloadItemChannel,
    };
  },
);

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(
      useUserActiveEasyDataInputPayloadItemChannelStore,
      import.meta.hot,
    ),
  );
}
