<template>
  <button
    v-if="!selectedAccount && sharedPayoutAccounts.length === 0"
    @click="goToSettings"
    type="button"
    class="space-x-1 inline-flex items-center rounded border border-gray-300 bg-white px-2 py-1 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
  >
    <CurrencyDollarIcon class="h-4 w-4" />
    <span>Add a payout account</span>
  </button>
  <div
    v-else
    class="inline-flex divide-x divide-gray-300 border border-gray-300 rounded-md shadow-sm"
  >
    <div
      v-if="bulkIds"
      class="space-x-2 inline-flex items-center rounded-l-md bg-white px-2 py-1 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100"
    >
      <p class="text-xs font-medium">Choose account</p>
    </div>
    <div
      v-else
      v-tooltip="'Earnings paying to:'"
      class="space-x-2 inline-flex items-center rounded-l-md bg-white px-2 py-1 text-xs font-medium text-indigo-600 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100"
    >
      <CheckIcon class="h-4 w-4" aria-hidden="true" />
      <p
        class="text-xs font-medium"
        :data-test="`${testId}-selected-payout-account-label`"
      >
        {{ selectedAccountLabel }}
      </p>
    </div>
    <VDropdown placement="bottom-end" class="rounded-l-none rounded-r-md">
      <button
        class="inline-flex items-center rounded-l-none rounded-r-md bg-white p-2 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100"
        role="menuitem"
        tabindex="-1"
        :data-test="`${testId}-payout-account-button`"
      >
        <span class="sr-only">Choose account</span>
        <ChevronDownIcon class="h-4 w-4 text-gray-700" aria-hidden="true" />
      </button>
      <template #popper>
        <div
          class="w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          tabindex="-1"
        >
          <div class="py-1" role="none">
            <a
              v-if="personalPayable"
              @click.prevent="payTo({ className: 'User' })"
              href=""
              role="menuitem"
              tabindex="-1"
              class="flex space-x-2 items-center px-2 py-1 text-xs hover:bg-gray-200 hover:text-gray-900"
              :data-test="`${testId}-personal-payout-account-button`"
            >
              <span
                class="flex-shrink-0 inline-flex items-center justify-center h-5 w-5 rounded-full"
                :class="
                  props.selectedAccount?.className === 'User'
                    ? 'bg-indigo-400'
                    : 'bg-white'
                "
                :data-test="`personal-payout-account-icon`"
              >
                <CheckIcon
                  v-if="props.selectedAccount?.className === 'User'"
                  class="h-4 w-4 text-white"
                />
                <CurrencyDollarIcon
                  v-else
                  class="h-5 w-5"
                  :class="
                    props.selectedAccount?.className === 'User'
                      ? 'text-indigo-400'
                      : 'text-gray-700'
                  "
                />
              </span>
              <span
                :class="
                  props.selectedAccount?.className === 'User'
                    ? 'text-indigo-600 font-medium'
                    : 'text-gray-700'
                "
                >Personal account</span
              >
            </a>
            <a
              v-else
              href=""
              @click.prevent="goToSettings"
              v-close-popper
              class="flex items-center space-x-2 text-gray-700 px-2 py-1 text-xs hover:bg-gray-200 hover:text-gray-900"
              role="menuitem"
              tabindex="-1"
            >
              <span
                class="flex-shrink-0 inline-flex items-center justify-center h-5 w-5 bg-white rounded-full"
              >
                <CurrencyDollarIcon class="h-5 w-5 text-gray-700" />
              </span>
              <span>Setup personal account</span>
            </a>
            <a
              v-for="(record, index) in sharedPayoutAccounts"
              :key="`${record.className}-${record.id}`"
              href=""
              @click.prevent="payTo(record)"
              v-close-popper
              class="flex items-center space-x-2 text-gray-700 px-2 py-1 text-xs hover:bg-gray-200 hover:text-gray-900"
              role="menuitem"
              tabindex="-1"
              :data-test="`${testId}-payout-account-${index}-button`"
            >
              <span
                class="flex-shrink-0 inline-flex items-center justify-center h-5 w-5 rounded-full"
                :class="isSelected(record) ? 'bg-indigo-400' : 'bg-gray-700'"
                :data-test="`${
                  record.teamName || record.category
                }-shared-payout-account-icon`"
              >
                <CheckIcon
                  v-if="isSelected(record)"
                  class="h-4 w-4 text-white"
                />
                <BuildingOfficeIcon
                  v-else-if="
                    record.category === 'building' ||
                    record.account?.category === 'building'
                  "
                  class="h-4 w-4 text-white"
                />
                <UserGroupIcon
                  v-else-if="record.teamName || record.category === 'team'"
                  class="h-4 w-4 text-white"
                />
                <BriefcaseIcon
                  v-else-if="
                    record.category === 'company' ||
                    record.account?.category === 'company'
                  "
                  class="h-4 w-4 text-white"
                />
              </span>
              <span
                :class="
                  isSelected(record)
                    ? 'text-indigo-600 font-medium'
                    : 'text-gray-700'
                "
                >{{
                  record.teamName ||
                  record.name ||
                  `${_.capitalize(record.category)} Account ${record.id}`
                }}</span
              >
            </a>
            <a
              v-if="sharedPayoutAccounts.length === 0"
              href=""
              @click.prevent="goToSettings"
              v-close-popper
              class="flex items-center space-x-2 text-gray-700 px-2 py-1 text-xs hover:bg-gray-200 hover:text-gray-900"
              role="menuitem"
              tabindex="-1"
            >
              <span
                class="flex-shrink-0 inline-flex items-center justify-center h-5 w-5 bg-white rounded-full"
              >
                <PlusCircleIcon class="h-5 w-5 text-gray-700" />
              </span>
              <span>Add an account</span>
            </a>
          </div>
        </div>
      </template>
    </VDropdown>
  </div>
</template>

<script setup>
import {
  BriefcaseIcon,
  BuildingOfficeIcon,
  CheckIcon,
  ChevronDownIcon,
  CurrencyDollarIcon,
  PlusCircleIcon,
  UserGroupIcon,
} from "@heroicons/vue/20/solid";
import { computed, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import api from "@/router/api";
import _ from "lodash";

const props = defineProps([
  "attribution",
  "selectedAccount",
  "sharedPayoutAccounts",
  "bulkIds",
  "userDefaultAccount",
  "index",
]);
const emit = defineEmits(["update-account", "refetch"]);
const personalPayable = ref(null);

const testId = computed(() => {
  if (props.userDefaultAccount) {
    return "user-default";
  } else if (props.bulkIds) {
    return "contribution-bulk";
  } else {
    return `contribution-${props.index}`;
  }
});

const selectedAccountLabel = computed(() => {
  switch (_.get(props.selectedAccount, "className")) {
    case "User":
      return "Personal account";
    case "SharedPayoutAccount":
      return props.selectedAccount.name;
    case "TeamSharedPayoutAccount":
      return props.selectedAccount.teamName;
    default:
      return "Unpayable";
  }
});

onMounted(() => {
  checkPayablePersonal();
});

async function checkPayablePersonal() {
  const response = await api.get(`payable_personal_payout_account`);

  if (response?.data) {
    personalPayable.value = response.data.payable;
  }
}

async function payTo(account) {
  if (props.userDefaultAccount) {
    const payload = {
      accountId: account.id,
      accountType: account.className,
    };
    api.post(`user_default_payout_accounts`, payload).then((json) => {
      emit("update-account", json.data);
    });
  } else {
    const payload = {
      attributionId: props.bulkIds ? null : props.attribution.id,
      accountId: account.id,
      accountType: account.className,
      attributionIds: props.bulkIds,
    };
    api.post(`attribution_payout_accounts`, payload).then((json) => {
      if (props.bulkIds) {
        emit("refetch");
      } else {
        emit("update-account", json.data);
      }
    });
  }
}

const router = useRouter();
const route = useRoute();

function goToSettings() {
  router.push({
    name: "MainMap",
    query: {
      ...route.query,
      horizontalTab: "Account",
      verticalTab: "Settings",
    },
  });
}

function isSelected(record) {
  if (props.bulkIds) {
    return false;
  } else {
    return (
      _.get(props.selectedAccount, "className") === record.className &&
      _.get(props.selectedAccount, "id") === record.id
    );
  }
}
</script>
