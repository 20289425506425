<template>
  <RadioGroup v-if="displayCitationTypes" v-model="selectedCitationType">
    <RadioGroupLabel class="text-sm font-medium text-gray-900"
      >Select citation type</RadioGroupLabel
    >

    <div class="mt-2 grid grid-cols-3 gap-x-2">
      <RadioGroupOption
        as="template"
        v-for="citationType in citationTypes"
        :key="citationType.id"
        :value="citationType"
        v-slot="{ checked, active }"
        :data-test="`citation-type-${citationType.apiValue}`"
      >
        <div
          :class="[
            checked ? 'border-transparent' : 'border-gray-300',
            active ? 'border-indigo-500 ring-1 ring-indigo-500' : '',
            'relative flex cursor-pointer rounded-lg border bg-white p-2 shadow-sm focus:outline-none',
          ]"
        >
          <span class="flex flex-1">
            <span class="flex flex-col">
              <RadioGroupLabel
                as="span"
                class="block text-xs font-medium text-gray-900"
                >{{ citationType.title }}</RadioGroupLabel
              >
              <RadioGroupDescription
                as="span"
                class="mt-1 flex items-center text-xs text-gray-500"
                >{{ citationType.description }}</RadioGroupDescription
              >
            </span>
          </span>
          <CheckCircleIcon
            :class="[!checked ? 'invisible' : '', 'h-4 w-4 text-indigo-600']"
            aria-hidden="true"
          />
          <span
            :class="[
              active ? 'border' : 'border-2',
              checked ? 'border-indigo-500' : 'border-transparent',
              'pointer-events-none absolute -inset-px rounded-md',
            ]"
            aria-hidden="true"
          />
        </div>
      </RadioGroupOption>
    </div>
  </RadioGroup>
</template>

<script setup>
import { CheckCircleIcon } from "@heroicons/vue/20/solid";
import {
  RadioGroup,
  RadioGroupDescription,
  RadioGroupLabel,
  RadioGroupOption,
} from "@headlessui/vue";
import { useCitationStore } from "@/stores/citation";
import { storeToRefs } from "pinia";

const citationStore = useCitationStore();
const { citationTypes, selectedCitationType, displayCitationTypes } = storeToRefs(citationStore);
</script>
