<template>
  <div
    v-if="loaded"
    class="flex items-center space-x-1"
    :class="proveForValue ? 'justify-center' : ''"
  >
    <template v-if="editing || proveForValue">
      <select
        v-if="dropdownOptions"
        v-model="inputValue"
        :name="`${fieldName}${proveForValue ? '-proof' : ''}-dropdown`"
        :id="`${fieldName}${proveForValue ? '-proof' : ''}-dropdown`"
        :data-test="`${fieldName}${proveForValue ? '-proof' : ''}-dropdown`"
        class="pl-3 pr-10 py-1 text-sm border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 rounded-md"
        :class="[proveForValue ? 'w-48' : '', inputClasses]"
      >
        <option
          v-for="option in dropdownOptions"
          :key="option.value"
          :value="option.value"
        >
          {{ option.name }}
        </option>
      </select>
      <div v-else-if="isCurrency" class="relative rounded-md shadow-sm">
        <div
          class="absolute inset-y-0 left-0 pl-2 flex items-center pointer-events-none"
        >
          <span class="text-gray-500 text-sm">$</span>
        </div>
        <CurrencyInput
          v-focus
          v-model="inputValue"
          :options="currencyInputOptions"
          :name="`${fieldName}${proveForValue ? '-proof' : ''}-currency-input`"
          :id="`${fieldName}${proveForValue ? '-proof' : ''}-currency-input`"
          :data-test="`${fieldName}${
            proveForValue ? '-proof' : ''
          }-currency-input`"
          class="focus:ring-indigo-500 focus:border-indigo-500 pl-5 pr-1 text-sm border-gray-300 rounded-md"
          :class="[proveForValue ? 'w-48' : '', inputClasses]"
          placeholder=""
        />
      </div>
      <div
        v-else-if="isInteger || isFloat"
        class="relative rounded-md shadow-sm"
      >
        <input
          v-focus
          v-model="inputValue"
          type="number"
          :name="`${fieldName}${proveForValue ? '-proof' : ''}-number-input`"
          :id="`${fieldName}${proveForValue ? '-proof' : ''}-number-input`"
          :data-test="`${fieldName}${
            proveForValue ? '-proof' : ''
          }-number-input`"
          :class="[
            {
              'pr-12': trailingAddOn,
              'pr-1': !trailingAddOn,
              'w-48': proveForValue,
            },
            inputClasses,
          ]"
          class="focus:ring-indigo-500 focus:border-indigo-500 pl-1 text-sm border-gray-300 rounded-md"
          placeholder=""
        />
        <div
          v-if="trailingAddOn"
          class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
        >
          <span class="text-gray-500 sm:text-sm">
            {{ trailingAddOn }}
          </span>
        </div>
      </div>
      <input
        v-else-if="isDate"
        v-observe-visibility="{ callback: focusInput, once: true }"
        v-model="inputValue"
        type="date"
        :name="`${fieldName}${proveForValue ? '-proof' : ''}-date-input`"
        :id="`${fieldName}${proveForValue ? '-proof' : ''}-date-input`"
        :data-test="`${fieldName}${proveForValue ? '-proof' : ''}-date-input`"
        class="rounded-full border-gray-300 pl-2 focus:border-indigo-500 focus:ring-indigo-500 text-xs"
        :class="[proveForValue ? 'w-48' : '', inputClasses]"
      />
      <input
        v-else
        v-focus
        v-model="inputValue"
        type="text"
        :name="`${fieldName}${proveForValue ? '-proof' : ''}-text-input`"
        :id="`${fieldName}${proveForValue ? '-proof' : ''}-text-input`"
        :data-test="`${fieldName}${proveForValue ? '-proof' : ''}-text-input`"
        class="text-sm shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
        :class="[proveForValue ? 'w-48' : '', inputClasses]"
      />

      <button
        @click="cancel"
        type="button"
        class="inline-flex flex-shrink-0 items-center p-1 border border-gray-300 rounded-full shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        <XMarkIcon class="h-4 w-4" />
      </button>

      <DataVisibilityButton
        v-if="complete"
        :visibility="visibility"
        class="inline-flex"
      >
        <template v-slot:button>
          <button
            v-if="complete"
            @click="save(null)"
            :disabled="originatingData"
            type="button"
            :class="
              visibility === 'safezone'
                ? 'bg-yellow-500 hover:bg-yellow-600 focus:ring-yellow-600'
                : 'bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500'
            "
            class="inline-flex flex-shrink-0 items-center p-1 border border-transparent rounded-full shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2"
            :data-test="`${fieldName}${proveForValue ? '-proof' : ''}-save`"
          >
            <PulseLoader
              v-if="originatingData"
              :loading="true"
              size="3px"
              color="#f3f4f6"
            />
            <CheckIcon v-else class="h-4 w-4" />
          </button>
        </template>
      </DataVisibilityButton>
    </template>

    <BranchingCalcFieldChooser
      v-else-if="fieldName === 'loan_term_years'"
      :field-name="fieldName"
      context="loan_table_row"
      :decorating-content-data-field="decoratingContentDataField"
      :prove-for-value="proveForValue"
      :optional="optional"
      @edit="edit"
      @suggest-change="suggestChange"
      @unlocked="unlocked"
      @completed="completed"
      @open-sourced="openSourced"
      @set-proof="setProof"
    />

    <BranchingCalcFieldChooser
      v-else-if="fieldName === 'maturity_date'"
      :field-name="fieldName"
      context="loan_table_row"
      :decorating-content-data-field="decoratingContentDataField"
      :prove-for-value="proveForValue"
      :optional="optional"
      @edit="edit"
      @suggest-change="suggestChange"
      @unlocked="unlocked"
      @completed="completed"
      @open-sourced="openSourced"
      @set-proof="setProof"
    />

    <DataField
      v-else-if="fetchedDataField && !proveForValue"
      :data-field="fetchedDataField"
      :text-classes="textClasses"
      :text-styles="textStyles"
      :dismiss-on-save="dismissOnSave"
      @dismiss="dismiss"
      @suggest-change="suggestChange"
      @unlocked="unlocked"
      @completed="completed"
      @open-sourced="openSourced"
      @set-proof="setProof"
    />

    <DataVisibilityButton
      v-else-if="!proveForValue"
      visibility="safezone"
      class="w-full flex"
    >
      <template v-slot:button>
        <button
          @click="edit"
          type="button"
          class="w-full flex items-center hover:bg-gray-50"
          :data-test="`${fieldName}-create`"
        >
          <span class="flex-shrink-0 flex items-center">
            <span
              class="inline-flex items-center justify-center h-5 w-5 rounded-full bg-yellow-500"
            >
              <PencilIcon class="h-3 w-3 text-white" />
            </span>
          </span>
        </button>
      </template>
    </DataVisibilityButton>
  </div>
</template>

<script setup>
import { XMarkIcon, CheckIcon } from "@heroicons/vue/20/solid";
import { PencilIcon } from "@heroicons/vue/24/outline";
import CurrencyInput from "@/components/crowdsourcing/CurrencyInput.vue";
import DataField from "@/components/crowdsourcing/DataField.vue";
import { useCrowdsourcedChangeGroupStore } from "@/stores/crowdsourcedChangeGroup";
import { useDealBuilderStore } from "@/stores/dealBuilder";
import { useProveForValueStore } from "@/stores/proveForValue";
import { useNotificationsStore } from "@/stores/notifications";
import { ref, computed, watch, onMounted } from "vue";
import { storeToRefs } from "pinia";
import dateValid from "@/assets/dateValid";
import moment from "moment";
import api from "@/router/api";
import _ from "lodash";
import BranchingCalcFieldChooser from "@/components/analyze/calculations/BranchingCalcFieldChooser.vue";
import DataVisibilityButton from "@/components/crowdsourcing/DataVisibilityButton.vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";

const props = defineProps([
  "fieldName",
  "textClasses",
  "inputClasses",
  "textStyles",
  "decoratingContentDataField",
  "isCurrency",
  "isInteger",
  "isFloat",
  "isDate",
  "dropdownOptions",
  "optional",
  "dismissOnSave",
  "proveForValue",
  "challengeDataField",
]);
const emit = defineEmits([
  "set-proof",
  "open-sourced",
  "completed",
  "unlocked",
]);

const notificationsStore = useNotificationsStore();
const proveForValueStore = useProveForValueStore();
const changeGroupStore = useCrowdsourcedChangeGroupStore();
const { originatingData, changeGroupId } = storeToRefs(changeGroupStore);
const dealBuilderStore = useDealBuilderStore();

const loaded = ref(false);
const fetchedDataField = ref(null);
const editing = ref(false);
const dismissOnSaveId = ref(null);
const inputValue = ref(null);
const currencyInputOptions = ref({
  currency: "USD",
  currencyDisplay: "hidden",
  hideCurrencySymbolOnFocus: true,
  hideGroupingSeparatorOnFocus: false,
  hideNegligibleDecimalDigitsOnFocus: false,
  autoDecimalDigits: false,
  autoSign: true,
  useGrouping: true,
  accountingSign: false,
});

const visibility = computed(() =>
  props.proveForValue ? "public" : "safezone",
);
const decoratingContentType = computed(() => {
  return _.get(props.decoratingContentDataField, "fieldContentType");
});
const decoratingContentId = computed(() => {
  return _.get(props.decoratingContentDataField, "fieldContentId");
});
const combinedId = computed(() => {
  return `${decoratingContentType.value}${decoratingContentId.value}`;
});
const isNumeric = computed(
  () => props.isCurrency || props.isFloat || props.isInteger,
);
const fieldValue = computed(() => {
  const rawValue = _.get(fetchedDataField.value, "fieldValue", null);

  return isNumeric.value ? _.toNumber(rawValue) : rawValue;
});
const complete = computed(() => {
  const filled = inputValue.value && _.trim(inputValue.value) !== "";
  const validDate = props.isDate ? dateValid(inputValue.value) : true;
  const changed = hasFieldValue.value
    ? _.trim(inputValue.value) !== _.trim(fieldValue.value)
    : true;
  return filled && validDate && changed;
});
const hasFieldValue = computed(
  () => fieldValue.value && _.trim(fieldValue.value) !== "",
);
const trailingAddOn = computed(() => {
  switch (props.fieldName) {
    case "loan_term_years":
      return "Yrs";
    case "loan_coupon_rate":
    case "density_percent_modifier":
      return "%";
    case "min_area":
    case "max_area":
      return "SF";
    default:
      return null;
  }
});

const useDealBuilder = computed(() => {
  return props.decoratingContentDataField?.fieldContentType !== "Hunt";
});
const fetchRequestKey = computed(
  () =>
    `crowdsourced_data_fields_${decoratingContentType.value}_${decoratingContentId.value}?field_name=${props.fieldName}`,
);

watch(combinedId, () => {
  fetchDataField();
});

onMounted(() => fetchDataField());

function focusInput(isVisible) {
  if (isVisible) {
    setTimeout(() => {
      selectInput();
    }, 100);
  }
}
function selectInput() {
  const el = document.getElementById(
    `${props.fieldName}${props.proveForValue ? "-proof" : ""}-date-input`,
  );

  if (el) {
    el.focus();
  }
}

function setProof(fieldName) {
  emit("set-proof", fieldName);
}

async function fetchDataField(emitPayload = {}, override = false) {
  loaded.value = false;

  if (props.proveForValue && !override) {
    loaded.value = true;
  } else if (
    useDealBuilder.value &&
    dealBuilderStore.alreadyFetched(fetchRequestKey.value) &&
    !override
  ) {
    fetchedDataField.value = _.head(
      dealBuilderStore.alreadyFetchedFieldsFor(fetchRequestKey.value),
    );
    loaded.value = true;
  } else {
    const fieldResponse = await api.get(
      `crowdsourced_data_fields/${decoratingContentType.value}/${decoratingContentId.value}?field_name=${props.fieldName}`,
    );

    if (fieldResponse.data) {
      if (useDealBuilder.value) {
        dealBuilderStore.interceptablePatch(
          [fieldResponse.data],
          fetchRequestKey.value,
        );
      }
    }

    fetchedDataField.value = fieldResponse.data;
    loaded.value = true;
  }

  if (
    (!fetchedDataField.value && !props.optional) ||
    _.get(emitPayload, "replaceAfterFetch", false)
  ) {
    edit();
  }
}
function unlocked() {
  if (props.proveForValue) {
    console.log("unlocked proof");
    emit("unlocked");
  } else {
    console.log("unlocked displayable number");
    fetchDataField({}, true);
  }
}
function completed() {
  fetchDataField();
  emit("completed");
}
function openSourced() {
  fetchDataField();
  emit("open-sourced");
}
async function dismiss(id, successCallback = () => {}) {
  await changeGroupStore.dismissData({
    dataFieldId: id,
    successCallback,
  });
}

async function suggestChange(emitPayload) {
  if (_.get(emitPayload, "dismissOnSave", false)) {
    dismissOnSaveId.value = fetchedDataField.value.localId;
    inputValue.value = fieldValue.value;
    editing.value = true;
  } else {
    await dismiss(fetchedDataField.value.localId, async (json) => {
      if (useDealBuilder.value) {
        await dealBuilderStore.postEditingPatch(json, fetchRequestKey.value);
      }
      fetchDataField({ replaceAfterFetch: true });
    });
  }
}
function edit() {
  if (fieldValue.value) {
    dismissOnSaveId.value = fetchedDataField.value.localId;

    inputValue.value = props.dropdownOptions
      ? _.find(props.dropdownOptions, { value: fieldValue.value }).value
      : fieldValue.value;
  } else {
    inputValue.value = props.dropdownOptions
      ? _.head(props.dropdownOptions).value
      : null;
  }
  editing.value = true;
}
function cancel() {
  inputValue.value = null;
  dismissOnSaveId.value = null;
  editing.value = false;
}

async function save(newProofStatus = null) {
  if (dismissOnSaveId.value)
    await dismiss(dismissOnSaveId.value, async (json) => {
      if (useDealBuilder.value) {
        await dealBuilderStore.postEditingPatch(json, fetchRequestKey.value);
      }
    });

  const apiRequestFunc = () => persist(newProofStatus);
  const successCallback = (json) => afterPersist(json);
  const failureCallback = () => cancel();

  if (complete.value) {
    return changeGroupStore.originateData(
      apiRequestFunc,
      successCallback,
      failureCallback,
    );
  }
}

async function persist(newProofStatus) {
  let fieldValueType = null;

  if (props.isCurrency || props.isFloat) {
    fieldValueType = "float";
  } else if (props.isInteger) {
    fieldValueType = "integer";
  } else if (props.isDate) {
    fieldValueType = "date";
  } else {
    fieldValueType = "string";
  }
  if (props.proveForValue && !newProofStatus) {
    let payload = {
      fieldValue: props.isDate
        ? moment(inputValue.value).valueOf()
        : inputValue.value,
      fieldValueType,
      isInner: false,
    };
    const proofResponse = await proveForValueStore.submitProof(
      props.challengeDataField,
      payload,
    );

    return proofResponse;
  } else {
    let endpoint;
    let payload = {
      fieldValue: props.isDate
        ? moment(inputValue.value).valueOf()
        : inputValue.value,
      fieldValueType,
    };

    switch (props.fieldName) {
      case "value":
        endpoint = "dollar_values";
        break;
      case "fully_funded_loan_amount":
        endpoint = "loan_amounts";
        break;
      case "loan_term_years":
        endpoint = "loan_terms";
        break;
      case "maturity_date":
        endpoint = "maturity_dates";
        break;
      case "loan_coupon_rate":
        endpoint = "loan_rates";
        break;
      case "equity_type":
        endpoint = "equity_types";
        break;
      case "involvement_role":
        endpoint = "involvement_roles";
        break;
      case "target_occupancy_date":
        endpoint = "target_occupancy_dates";
        break;
      case "min_investment_size":
      case "max_investment_size":
      case "min_area_per_person":
      case "max_area_per_person":
      case "density_percent_modifier":
      case "min_area":
      case "max_area":
      case "min_headcount":
      case "max_headcount":
      case "target_date":
        endpoint = "content_direct_inputs";
        payload.fieldName = props.fieldName;
        break;
    }

    if (endpoint) {
      let response = await api.post(
        `${endpoint}/${decoratingContentType.value}/${decoratingContentId.value}`,
        _.merge({}, payload, { changeGroupId: changeGroupId.value }),
      );

      if (newProofStatus === "rejected") {
        const declassifyPayload = {
          id: response.data.dataField.localId,
        };

        const declassifiedResponse = await api.post(
          `declassify_datapoint`,
          declassifyPayload,
        );
        response.data.dataField = declassifiedResponse.data;
        notificationsStore.addNotification("proofRejected");
        unlocked();
      }

      return response;
    }
  }
}
async function afterPersist(json) {
  if (json.data?.proofStatus === "rejected") {
    dismissOnSaveId.value = props.challengeDataField.localId;
    save(json.data.proofStatus);
  } else if (json.data?.proofStatus === "accepted") {
    notificationsStore.addNotification("proofAccepted");
    unlocked();
    cancel();
  } else {
    if (useDealBuilder.value) {
      await dealBuilderStore.postEditingPatch(json, fetchRequestKey.value);
    }
    fetchDataField({}, true);
    completed();
    cancel();
    // this.$store.dispatch("flash", "Saved!");
  }
}
</script>
