<template>
  <div v-if="expanded || expandedOverride" class="flex flex-1">
    <!-- Narrow sidebar-->
    <nav
      aria-label="Sidebar"
      class="flex-shrink-0 p-2 bg-gray-100 overflow-y-auto"
    >
      <div class="relative flex flex-col space-y-2">
        <a
          v-for="tab in contextedTabs"
          :key="tab.name"
          @click.prevent="selectedTab = tab"
          :href="tab.href"
          v-tooltip="tab.name"
          :class="[
            selectedTab.name === tab.name
              ? 'text-gray-700 bg-gray-300'
              : 'text-gray-500 hover:bg-gray-200',
            'flex-shrink-0 inline-flex items-center justify-center h-8 w-8 rounded-lg',
          ]"
          class=""
          :data-test="`${_.kebabCase(tab.name)}-nav`"
        >
          <span class="sr-only">{{ tab.name }}</span>
          <svg
            :class="selectedTab.name === tab.name ? '' : ''"
            class="h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1"
              :d="tab.svgPath"
            />
          </svg>
        </a>
      </div>
    </nav>

    <div
      class="flex-grow overflow-y-auto"
      :class="fromMenu ? 'max-h-[350px]' : ''"
    >
      <component
        :is="selectedTab.component"
        v-bind="selectedTabProperties"
        @open-portfolio="openPortfolio"
      />
    </div>
  </div>
</template>

<script setup>
import moment from "moment";
import ContentAppearance from "@/components/crowdsourcing/ContentAppearance.vue";
import ContentFiles from "@/components/crowdsourcing/ContentFiles.vue";
import InvestmentPlayers from "@/components/deal-builder/InvestmentPlayers.vue";
import InvestmentPortfolio from "@/components/deal-builder/InvestmentPortfolio.vue";
import InvestmentTiming from "@/components/deal-builder/InvestmentTiming.vue";
import LoansDetail from "@/components/deal-builder/LoansDetail.vue";
import ValuationsDetail from "@/components/deal-builder/ValuationsDetail.vue";
import { useDealBuilderStore } from "@/stores/dealBuilder";
import { storeToRefs } from "pinia";
import { ref, computed, watch, nextTick, onMounted, markRaw } from "vue";
import VueScrollTo from "vue-scrollto";
import _ from "lodash";
import decoratingAndFieldKey from "@/components/crowdsourcing/decoratingAndFieldKey";
import investmentStates from "@/components/deal-builder/investmentStates";

const props = defineProps([
  "assetObject",
  "investment",
  "investmentGroupId",
  "expandedOverride",
  "fromMenu",
]);

const dealBuilderStore = useDealBuilderStore();
const { dealBuilder, crossInteraction } = storeToRefs(dealBuilderStore);

const expanded = ref(false);
const tabs = ref([
  {
    name: "Valuations",
    href: "",
    svgPath:
      "M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z",
    component: markRaw(ValuationsDetail),
  },
  {
    name: "Players",
    href: "",
    svgPath:
      "M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z",
    component: markRaw(InvestmentPlayers),
  },
  {
    name: "Timing",
    href: "",
    svgPath: "M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z",
    component: markRaw(InvestmentTiming),
  },
  {
    name: "Files",
    href: "",
    svgPath:
      "M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z",
    component: markRaw(ContentFiles),
  },
  {
    name: "Portfolio",
    href: "",
    svgPath:
      "M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z",
    component: markRaw(InvestmentPortfolio),
  },
  {
    name: "Appearance",
    href: "",
    svgPath:
      "M10 6H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V8a2 2 0 00-2-2h-5m-4 0V5a2 2 0 114 0v1m-4 0a2 2 0 104 0m-5 8a2 2 0 100-4 2 2 0 000 4zm0 0c1.306 0 2.417.835 2.83 2M9 14a3.001 3.001 0 00-2.83 2M15 11h3m-3 4h2",
    component: markRaw(ContentAppearance),
  },
]);
const selectedTab = ref({
  name: "Valuations",
  href: "",
  svgPath:
    "M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z",
  component: markRaw(ValuationsDetail),
});

const dealAction = computed(() => {
  return focalInvestment.value.dealAction;
});
const contentTypedTabs = computed(() => {
  const loanTab = [
    {
      name: "Loan",
      href: "",
      svgPath:
        "M8 14v3m4-3v3m4-3v3M3 21h18M3 10h18M3 7l9-4 9 4M4 10h16v11H4V10z",
      component: markRaw(LoansDetail),
    },
  ];
  switch (dealAction.value) {
    case "refinance":
    case "originateLoan":
      return _.concat(loanTab, tabs.value);
    default: {
      if (asset.value?.decoratingContentType === "Loan") {
        return _.concat(loanTab, tabs.value);
      } else {
        return tabs.value;
      }
    }
  }
});
const portfolioFilteredTabs = computed(() => {
  if (props.investmentGroupId) {
    return contentTypedTabs.value.filter((tab) => {
      return tab.name !== "Portfolio";
    });
  } else {
    return contentTypedTabs.value;
  }
});
const contextedTabs = computed(() => {
  if (existingInvestmentId.value) {
    return portfolioFilteredTabs.value;
  } else {
    return portfolioFilteredTabs.value.filter((tab) => {
      return _.includes(["Loan", "Valuations", "Players", "Timing"], tab.name);
    });
  }
});
const selectedTabProperties = computed(() => {
  return {
    investment: focalInvestment.value,
    assetObject: props.assetObject,
    fieldContent: focalInvestment.value,
    fieldContentType: "Investment",
    dataField: {
      fieldContent: focalInvestment.value,
      fieldContentType: "Investment",
    },
    hasPortfolio: existingPortfolio.value,
    optional: true,
  };
});

const investmentGroups = computed(() => {
  return _.get(dealBuilder.value, "investmentGroups", []);
});
const dataFieldInvestmentFieldContent = computed(() => {
  return _.get(props.investment, "fieldContent");
});
const focalInvestment = computed(() => {
  const dealBuilderExistingInvestment = _.get(
    props.investment,
    "existingInvestment",
  );

  return (
    dataFieldInvestmentFieldContent.value ||
    dealBuilderExistingInvestment ||
    props.investment
  );
});
const portfolioId = computed(() => {
  return (
    _.get(focalInvestment.value, "portfolioId") ||
    _.get(matchingInvestmentGroup.value, "placeholderId")
  );
});
const existingPortfolio = computed(
  () => !props.investmentGroupId && portfolioId.value,
);
const portfolioDealAction = computed(() => {
  return _.get(focalInvestment.value, "portfolioDealAction");
});
const existingInvestmentId = computed(() => {
  return (
    _.get(focalInvestment.value, "id") ||
    _.get(focalInvestment.value, "fieldContentId")
  );
});
const investmentId = computed(() => {
  return _.get(focalInvestment.value, "id", dealBuilderCombinedKey.value);
});
const dealBuilderCombinedKey = computed(() => {
  if (
    focalInvestment.value.capitalStackTopLevelAsset &&
    focalInvestment.value.investmentFieldContent
  ) {
    const assetKey = decoratingAndFieldKey(
      focalInvestment.value.capitalStackTopLevelAsset,
    );
    const investmentKey = decoratingAndFieldKey(
      focalInvestment.value.investmentFieldContent,
    );

    return `${assetKey}_${investmentKey}`;
  } else {
    return _.get(props.investment, "temporaryId");
  }
});
const asset = computed(
  () =>
    _.get(focalInvestment.value, "asset") ||
    _.get(focalInvestment.value, "capitalStackTopLevelAsset"),
);
const dateLabel = computed(() => {
  switch (focalInvestment.value.state) {
    case "withdrawn":
    case "live":
    case "under_contract":
      return _.find(investmentStates, {
        value: focalInvestment.value.state,
      }).statusLabel;
    case "closed":
    default: {
      if (focalInvestment.value.date) {
        return moment(focalInvestment.value.date).format("MMM YYYY");
      } else {
        return "Undated";
      }
    }
  }
});
const matchingInvestmentGroup = computed(() => {
  return _.find(investmentGroups.value, function (group) {
    return _.find(group.investments, function (investment) {
      const localInvestmentId =
        _.get(props.investment, "existingInvestment.id") ||
        _.get(props.investment, "id") ||
        _.get(props.investment, "temporaryId");

      return (
        group.id === portfolioId.value ||
        _.get(investment, "existingInvestment.id") === localInvestmentId
      );
    });
  });
});

watch(crossInteraction, () => {
  expandWhenInteracting();
});
watch(dateLabel, () => {
  expandWhenInteracting();
});
watch(investmentId, (id, oldId) => {
  if (id !== oldId) {
    expandWhenInteracting();
  }
});
watch(dealAction, (val, oldVal) => {
  if (val && val !== oldVal) {
    setLoanTab();
    expandWhenInteracting();
  }
});
onMounted(() => {
  expandWhenInteracting();
});

function setLoanTab() {
  if (_.includes(["refinance", "originateLoan"], dealAction.value)) {
    selectedTab.value = {
      name: "Loan",
      href: "",
      svgPath:
        "M8 14v3m4-3v3m4-3v3M3 21h18M3 10h18M3 7l9-4 9 4M4 10h16v11H4V10z",
      component: markRaw(LoansDetail),
    };
  } else {
    selectedTab.value = {
      name: "Valuations",
      href: "",
      svgPath:
        "M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z",
      component: markRaw(ValuationsDetail),
    };
  }
}
function expandWhenInteracting() {
  if (investmentId.value && crossInteraction.value) {
    const { combinedKey, focus, source } = crossInteraction.value;
    const shouldActivate = _.includes(["ContentStateAlertCard"], source);

    if (shouldActivate && combinedKey === investmentId.value) {
      console.log("expand when interacting", investmentId.value);
      expanded.value = true;
      const newTab = _.find(contextedTabs.value, { name: focus });

      if (newTab) {
        selectedTab.value = newTab;

        if (
          !_.includes(
            [
              "TimelineInvestment",
              "InvolvedCompanyMenu",
              "ContentStateAlertCard",
            ],
            source,
          )
        ) {
          setTimeout(() => {
            crossInteraction.value = null;
          }, 750);
        }
      }
    } else {
      expanded.value = false;
    }
  } else if (props.fromMenu) {
    expanded.value = true;
  }
}
function focusInvestmentGroup(investmentGroup) {
  dealBuilderStore.collapsePortfolioAssets({
    groupId: investmentGroup.id || investmentGroup.placeholderId,
  });
  dealBuilderStore.setDealBuilderInvestmentGroupExpanded({
    groupId: investmentGroup.id || investmentGroup.placeholderId,
    expanded: true,
  });
}
async function openPortfolio() {
  if (matchingInvestmentGroup.value) {
    focusInvestmentGroup(matchingInvestmentGroup.value);
  } else {
    dealBuilderStore.addDealBuilderInvestmentGroup({
      existingGroupId: portfolioId.value,
      dealAction: portfolioDealAction.value,
    });
  }

  // dealBuilderStore.collapseAllAssetsExcept({ assetKey: null });
  await nextTick();
  VueScrollTo.scrollTo("#investment-portfolios-list", 100, {
    container: "#lists-panel-container",
  });
}
</script>
