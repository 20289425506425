<template>
  <div class="bg-white">
    <div class="p-2">
      <div v-if="!timingUnlocked" class="mt-4 text-center">
        <svg
          class="mx-auto h-8 w-8 text-gray-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
          />
        </svg>
        <h3 class="mt-2 text-sm font-medium text-gray-900">
          Unlockable dates/states
        </h3>
        <p class="mt-1 text-sm text-gray-500">
          All timing datapoints need to be unlocked. Please check the space
          list.
        </p>
      </div>
      <dl v-else class="grid grid-cols-2 gap-x-4 gap-y-8">
        <GroupPrincipalsDetail
          transaction-context="leasing"
          transaction-side="supply"
          display-context="details-panel"
          :existingGroupId="existingGroupId"
          :placeholderGroupId="placeholderGroupId"
          @set-space-providers="setSpaceProviders"
        />
        <GroupPrincipalsDetail
          transaction-context="leasing"
          transaction-side="demand"
          display-context="details-panel"
          :existingGroupId="existingGroupId"
          :placeholderGroupId="placeholderGroupId"
        />
        <AvailabilityGroupAdvisors
          :existingGroupId="existingGroupId"
          :placeholderGroupId="placeholderGroupId"
          clientRole="spaceProvider"
          :existing-space-providers="spaceProviders"
          :fetch-milliseconds="fetchMilliseconds"
        />
        <AvailabilityGroupAdvisors
          :existingGroupId="existingGroupId"
          :placeholderGroupId="placeholderGroupId"
          clientRole="spaceUser"
          :fetch-milliseconds="fetchMilliseconds"
        />
        <AvailabilityGroupProspects
          v-if="allExistingAvailabilities"
          :existingGroupId="existingGroupId"
          :placeholderGroupId="placeholderGroupId"
          :fetch-milliseconds="fetchMilliseconds"
          @refetch="refetchGroupAvailabilities"
          @override-refetch="refetchGroupAvailabilities"
        />
      </dl>
    </div>
  </div>
</template>

<script setup>
import GroupPrincipalsDetail from "@/components/deal-builder/GroupPrincipalsDetail.vue";
import AvailabilityGroupAdvisors from "@/components/space-usage-builder/AvailabilityGroupAdvisors.vue";
import AvailabilityGroupProspects from "@/components/space-usage-builder/AvailabilityGroupProspects.vue";
import { useSpaceUsageBuilderStore } from "@/stores/spaceUsageBuilder";
import { ref, computed, onMounted } from "vue";
import { storeToRefs } from "pinia";
import moment from "moment";
import _ from "lodash";
import fullyUnlocked from "@/components/crowdsourcing/fullyUnlocked";

const props = defineProps(["existingGroupId", "placeholderGroupId"]);

const spaceUsageBuilderStore = useSpaceUsageBuilderStore();
const { availabilityGroups } = storeToRefs(spaceUsageBuilderStore);

const spaceProviders = ref([]);

function setSpaceProviders(newProviders) {
  spaceProviders.value = newProviders;
}

const availabilityGroup = computed(() => {
  const finder = props.existingGroupId
    ? { id: props.existingGroupId }
    : { placeholderId: props.placeholderGroupId };

  return _.find(availabilityGroups.value, finder);
});
const timingUnlocked = computed(() => {
  return fullyUnlocked(timingFields.value);
});
const allExistingAvailabilities = computed(() => {
  return _.every(groupDateState.value.ids, function (id) {
    return !_.isNil(id);
  });
});
const groupDate = computed(() => {
  if (groupDateState.value.dates.length === 1) {
    let unparsed = _.head(groupDateState.value.dates);

    return moment(unparsed).toDate();
  } else {
    return null;
  }
});
const groupDateState = computed(() => {
  const availabilities = _.map(
    _.get(availabilityGroup.value, "availabilities", []),
    function (groupAvailability) {
      return focalAvailabilityFor(groupAvailability);
    },
  );
  const ids = availabilities.map((i) => i.id);
  const dates = _.uniq(
    availabilities.map((i) => {
      if (i.date) {
        return moment(i.date).format("YYYYMMDD");
      } else {
        return null;
      }
    }),
  );
  const states = _.uniq(availabilities.map((i) => i.state));
  const dealActions = _.uniq(availabilities.map((i) => i.dealAction));

  return { ids, dates, states, dealActions };
});
const timingFields = computed(() => {
  return _.get(availabilityGroup.value, "timingFields", []);
});
const fetchMilliseconds = computed(() => {
  if (timingUnlocked.value) {
    const uniqueFields = _.uniqBy(timingFields.value, "fieldName");
    const dateField = _.head(
      uniqueFields.filter((field) => field.fieldName !== "state"),
    );

    return dateField ? moment(dateField.fieldValue).valueOf() : null;
  } else if (groupDate.value) {
    return moment(groupDate.value).valueOf();
  } else {
    return null;
  }
});

onMounted(async () => {
  if (props.existingGroupId) {
    await spaceUsageBuilderStore.fetchGroupTimingFields(props.existingGroupId);
  }
});

function focalAvailabilityFor(groupAvailability) {
  return _.get(groupAvailability, "existingAvailability") || groupAvailability;
}
function refetchGroupAvailabilities() {
  if (props.existingGroupId) {
    spaceUsageBuilderStore.refreshAvailabilityGroup({
      groupId: props.existingGroupId,
      isExistingGroup: true,
    });
  }
}
</script>
