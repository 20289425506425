<template>
  <div
    class="flex flex-1 flex-wrap items-center"
    :class="
      proveForValue
        ? 'border border-gray-300 rounded-lg shadow-sm focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500'
        : ''
    "
  >
    <label for="company_name" class="sr-only">{{ label }}</label>
    <div
      v-if="displayCompanies.length > 0"
      class="flex flex-wrap pl-2 pt-1 pb-1 pr-1"
    >
      <li
        v-for="company in displayCompanies"
        :key="company.name || company"
        class="inline-flex rounded-full items-center my-1 mr-1 py-0.5 pl-2.5 pr-1 text-sm font-medium bg-yellow-100 text-yellow-700"
      >
        {{ company.name || company }}
        <button
          @click="remove(company)"
          type="button"
          class="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-yellow-400 hover:bg-yellow-200 hover:text-yellow-500 focus:outline-none focus:bg-yellow-500 focus:text-white"
        >
          <span class="sr-only">Remove company</span>
          <svg
            class="h-2 w-2"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 8 8"
          >
            <path
              stroke-linecap="round"
              stroke-width="1.5"
              d="M1 1l6 6m0-6L1 7"
            />
          </svg>
        </button>
      </li>
    </div>
    <div class="flex flex-col">
      <Combobox v-model="selectedCompany" nullable>
        <ComboboxInput
          v-observe-visibility="{ callback: focusInput, once: true }"
          :id="`${inputKey}-company-search`"
          class="text-gray-800 placeholder-gray-400 text-sm"
          :class="inputClasses || 'border-0 bg-transparent focus:ring-0 pb-2'"
          @keydown="handleKeyDown"
          @blur="handleBlur"
          @focus="setCrossInteraction({ focus: 'Players' })"
          @change="query = $event.target.value"
          :placeholder="proveForValue ? 'Company name' : 'Company name(s)'"
          :displayValue="(record) => record?.name"
          :data-test="`${inputKey}${
            proveForValue ? '-proof' : ''
          }-company-search`"
        />
        <ComboboxOptions
          class="max-h-80 scroll-py-10 scroll-pb-2 space-y-2 overflow-y-auto"
        >
          <ComboboxOption
            v-for="(company, index) in filteredFetchedCompanies"
            :key="company.localId"
            :value="company"
            as="template"
            :data-test="`${inputKey}-company-search-result-${index}`"
            v-slot="{ active }"
          >
            <li
              :class="[
                'flex text-sm cursor-default select-none items-center p-2',
                active && 'bg-indigo-600 text-white',
              ]"
            >
              {{ company.recordLabel
              }}<template v-if="company.fieldValue !== company.recordLabel"
                >: {{ company.fieldValue }}</template
              >
            </li>
          </ComboboxOption>
        </ComboboxOptions>
      </Combobox>
    </div>
  </div>
  <div
    v-if="proveForValue && proofCompany.length > 0"
    class="mt-1 flex justify-end space-x-2"
  >
    <button
      @click="remove(_.head(proofCompany))"
      type="button"
      class="inline-flex items-center p-1 border border-gray-300 rounded-full shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
    >
      <XMarkIcon class="h-4 w-4" />
    </button>

    <DataVisibilityButton visibility="public" class="inline-flex">
      <template v-slot:button>
        <button
          @click="submitProof"
          :disabled="originatingData"
          type="button"
          data-test="company-proof-submit"
          class="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2"
        >
          <PulseLoader
            v-if="originatingData"
            :loading="true"
            size="3px"
            color="#f3f4f6"
          />
          <CheckIcon v-else class="h-4 w-4" />
        </button>
      </template>
    </DataVisibilityButton>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted } from "vue";
import {
  Combobox,
  ComboboxInput,
  ComboboxOptions,
  ComboboxOption,
} from "@headlessui/vue";
import { XMarkIcon, CheckIcon } from "@heroicons/vue/20/solid";
import DataVisibilityButton from "@/components/crowdsourcing/DataVisibilityButton.vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { useCrowdsourcedChangeGroupStore } from "@/stores/crowdsourcedChangeGroup";
import { useNotificationsStore } from "@/stores/notifications";
import { useProveForValueStore } from "@/stores/proveForValue";
import { storeToRefs } from "pinia";
import api from "@/router/api";
import _ from "lodash";
import moment from "moment";

const props = defineProps([
  "inputKey",
  "label",
  "companies",
  "filteredCompanies",
  "clientRole",
  "inputClasses",
  "challengeDataField",
  "proveForValue",
  "investmentGroupId",
  "availabilityGroupId",
  "fieldName",
  "fetchMilliseconds",
  "investmentRole",
  "isInnerField",
  "parentComponentSaveFunction",
  "admin",
]);
const emit = defineEmits([
  "set-cross-interaction",
  "new-companies",
  "remove-company",
  "unlocked",
  "completed",
]);

const changeGroupStore = useCrowdsourcedChangeGroupStore();
const { originatingData } = storeToRefs(changeGroupStore);
const notificationsStore = useNotificationsStore();
const proveForValueStore = useProveForValueStore();

const contactFetchTime = ref(null);
const selectedCompany = ref(null);
const query = ref("");
function clearInput(refocus = true) {
  setTimeout(() => {
    selectedCompany.value = null;
    query.value = "";
    fetchedCompanies.value = [];

    const inputEl = document.getElementById(`${props.inputKey}-company-search`);
    if (inputEl) inputEl.value = "";
    if (refocus) selectInput();
  }, 50);
}

const fetchedCompanies = ref([]);
const filteredFetchedCompanies = computed(() => {
  const filtered = fetchedCompanies.value.filter(
    (dataFieldSearchResult) => dataFieldSearchResult.recordType === "Company",
  );
  const ordered = _.orderBy(
    filtered,
    [nameFirstResults, sortedRecordType],
    ["desc", "desc"],
  );
  return _.uniqBy(ordered, "recordId");
});
function nameFirstResults(result) {
  if (result.fieldName === "interal_name") {
    return 10;
  } else if (result.fieldName === "ticker_symbol") {
    return 9;
  } else if (result.fieldName === "name") {
    return 8;
  } else {
    return 1;
  }
}
function sortedRecordType(result) {
  if (result.fieldType === "ContentAlias") {
    return 11;
  } else {
    return 1;
  }
}
const proofCompany = ref([]);
const displayCompanies = computed(() => {
  if (props.proveForValue) {
    return proofCompany.value;
  } else {
    return props.filteredCompanies || props.companies;
  }
});

watch(selectedCompany, async () => {
  onSelect();
});

watch(query, async () => {
  if (_.trim(query.value) !== "") {
    debouncedFilterCompanies();
  }
});

onMounted(() => {});

const debouncedFilterCompanies = _.debounce(function () {
  filterCompanies();
}, 250);

function setCrossInteraction({ focus }) {
  emit("set-cross-interaction", { focus });
}
function focusInput(isVisible) {
  if (isVisible) {
    setTimeout(() => {
      selectInput();
    }, 100);
  }
}
function selectInput() {
  document.getElementById(`${props.inputKey}-company-search`)?.focus();
}
function filterCompanies() {
  if (query.value.length > 0) {
    const fetchTime = moment().valueOf();
    contactFetchTime.value = fetchTime;
    const endpoint = props.admin
      ? `admin/company_searches`
      : `company_searches`;
    const request =
      query.value === "" || query.value === null
        ? null
        : _.toLower(query.value);

    api.post(endpoint, { query: request }).then(
      (json) => {
        if (fetchTime === contactFetchTime.value) {
          fetchedCompanies.value = json.data;
          contactFetchTime.value = null;
        }
      },
      (failure) => {
        console.log(failure);
        // this.$store.dispatch("flash", "Invalid search");
      },
    );
  } else {
    fetchedCompanies.value = [];
  }
}
async function onSelect(refocus = true) {
  const output = selectedCompany.value ? selectedCompany.value : query.value;
  const selection = output === "" || output === null ? null : output;

  if (selection) {
    let placeholder = {
      name: null,
      id: null,
    };
    if (selectedCompany.value) {
      placeholder.name =
        selection.fieldValue !== selection.recordLabel
          ? `${selection.recordLabel}: ${selection.fieldValue}`
          : selection.recordLabel;
      placeholder.id = selection.recordId;
    } else {
      placeholder.name = selection;
    }

    if (props.clientRole) {
      placeholder.clientRole = props.clientRole;
      placeholder.advisorRole = null;

      if (props.proveForValue) {
        proofCompany.value = [placeholder];
      } else {
        emit(
          "new-companies",
          _.unionBy(props.companies, [placeholder], function (advObj) {
            return `${advObj.name}_${advObj.clientRole}`;
          }),
        );
      }
    } else {
      if (props.proveForValue) {
        proofCompany.value = [placeholder];
      } else {
        emit(
          "new-companies",
          _.unionBy(props.companies, [placeholder], "name"),
        );
      }
    }
  }

  clearInput(refocus);
}

function handleBlur() {
  setTimeout(() => {
    const input = query.value;
    const typedVal = input === "" || input === null ? null : input;

    if (typedVal) {
      onSelect();
    } else {
      clearInput(false);
    }
  }, 75);
}
function handleKeyDown(e) {
  const input = query.value;
  const typedVal = input === "" || input === null ? null : input;

  if (typedVal && ["Tab"].includes(e.key)) {
    onSelect(false);
  } else if (typedVal && ["Enter"].includes(e.key) && !selectedCompany.value) {
    onSelect();
  } else if (
    !typedVal &&
    displayCompanies.value.length > 0 &&
    ["Backspace"].includes(e.key)
  ) {
    remove(_.last(displayCompanies.value));
  }
}
function remove(company) {
  if (props.proveForValue) {
    proofCompany.value = [];
  } else {
    emit("remove-company", company);
  }
  focusInput();
}

async function submitProof() {
  originatingData.value = true;
  const company = _.head(proofCompany.value);

  let payload = {
    asOf: props.fetchMilliseconds,
    investmentGroupId: props.investmentGroupId,
    availabilityGroupId: props.availabilityGroupId,
    investmentRole: props.investmentRole,
    isInner: props.isInnerField,
  };

  if (company?.id) {
    payload.fieldValue = company.id;
    payload.fieldValueType = "id";
  } else {
    payload.fieldValue = company.name;
    payload.fieldValueType = "string";
  }
  const proofResponse = await proveForValueStore.submitProof(
    props.challengeDataField,
    payload,
  );

  if (proofResponse.data?.proofStatus === "rejected") {
    console.log("rejected");
    await persistRejectedProofData();
  } else if (proofResponse.data?.proofStatus === "accepted") {
    console.log("accepted");
    notificationsStore.addNotification("proofAccepted");
    // TODO: CONDITIONALLY CHOOSE capitalStack vs spaceUsageStack refetch trigger
    proveForValueStore.setRefetchTrigger("capitalStack");
    emit("unlocked", { override: "CompanyInvolvement" });
  }
  originatingData.value = false;
}

async function persistRejectedProofData() {
  console.log("persist rejected proof");
  const apiRequestFunc = () =>
    props.parentComponentSaveFunction(proofCompany.value);
  const successCallback = async (json) => {
    remove(_.last(displayCompanies.value));
    if (json?.data?.dataField) {
      const declassifyPayload = {
        id: json.data.dataField.localId,
      };

      const declassifiedResponse = await api.post(
        `declassify_datapoint`,
        declassifyPayload,
      );
      json.data.dataField = declassifiedResponse.data;
    } else if (json?.data?.dataFields) {
      const declassifyPayload = {
        ids: json.data.dataFields.map((field) => field.localId),
      };
      await api.post(`declassify_datapoints`, declassifyPayload);
    }
    notificationsStore.addNotification("proofRejected");
    proveForValueStore.setRefetchTrigger("capitalStack");
    emit("completed", { override: "CompanyInvolvement" });
  };
  const failureCallback = () => remove(_.head(proofCompany.value));

  return changeGroupStore.originateData(
    apiRequestFunc,
    successCallback,
    failureCallback,
  );
}
</script>
