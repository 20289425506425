<template>
  <div
    v-if="dataField || calculationName || analyze"
    class="text-gray-700 hover:text-gray-900 hover:bg-gray-100 px-2 py-1 space-y-2"
  >
    <div class="flex justify-between">
      <div>
        <div class="text-sm font-medium">{{ bundle.name }}</div>
        <div class="text-xs">{{ bundle.description }}</div>
      </div>
      <div class="w-16">
        <ProgressBar
          v-if="displayableData && fieldCount > 0"
          :all-fields-data="displayableData"
          tooltip="Your unlockable collection"
          class="w-full"
          style="height: 20px"
        />
        <MoonLoader
          v-else
          :loading="!bundlePricingData"
          size="8px"
          color="#5850ec"
        />
      </div>
    </div>
    <CrowdsourcingUnlocker
      v-if="unlicensedCount > 0"
      :context="context"
      :calculation-name="calculationName"
      :data-field="dataField"
      :bundle-api-param="bundle.apiParam"
      :bundle-pricing-data="displayableData"
      :user-store="userStore"
      :modal-store="modalStore"
      :unlocker-store="unlockerStore"
      :analyze="analyze"
      @unlocked="unlocked"
      @reset-displayable="resetDisplayable"
    />
    <div
      v-else-if="displayableData"
      class="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-green-100 text-green-800"
    >
      <svg
        class="-ml-0.5 mr-1.5 h-4 w-4 text-green-400"
        fill="currentColor"
        viewBox="0 0 20 20"
      >
        <path
          fill-rule="evenodd"
          d="M17.776 3.294c.296.29.3.765.01 1.061a39.55 39.55 0 00-8.35 12.672.75.75 0 01-1.224.246L2.22 11.281a.75.75 0 111.06-1.061l5.208 5.207a41.072 41.072 0 018.227-12.122.75.75 0 011.061-.01z"
          clip-rule="evenodd"
        />
      </svg>
      100% unlocked
    </div>
  </div>
</template>

<script setup>
import CrowdsourcingUnlocker from "@/components/crowdsourcing/CrowdsourcingUnlocker.vue";
import MoonLoader from "vue-spinner/src/MoonLoader.vue";
import ProgressBar from "@/components/crowdsourcing/ProgressBar.vue";
import api from "@/router/api";
import { computed, onMounted, ref } from "vue";
import _ from "lodash";

const emit = defineEmits(["unlocked", "reset-displayable"]);
const props = defineProps([
  "context",
  "userStore",
  "modalStore",
  "unlockerStore",
  "calculationName",
  "dataField",
  "bundle",
  "allFieldsData",
  "analyze",
]);
const bundlePricingData = ref(null);
const allFields = computed(() => {
  return (
    _.includes(
      ["all_fields", "calculation", "filters"],
      props.bundle.apiParam,
    ) && props.allFieldsData
  );
});
const displayableData = computed(() => {
  return allFields.value ? props.allFieldsData : bundlePricingData.value;
});
const fieldCount = computed(() => {
  return _.get(displayableData.value, "licenseableIds", []).length;
});
const unlicensedCount = computed(() => {
  return _.get(displayableData.value, "unlicensedIds", []).length;
});

onMounted(() => {
  let modalStoreTemp = props.modalStore;
  let unlockerStoreTemp = props.unlockerStore;
  if (!allFields.value) {
    fetchBundlePricing();
  }
});

async function fetchBundlePricing() {
  console.log("fetch bundle pricing", props.dataField.localId);
  api
    .get(
      `crowdsourced_data_field_bundles/${props.dataField.localId}?bundle=${props.bundle.apiParam}`,
    )
    .then((json) => {
      bundlePricingData.value = json.data;
    });
}

function unlocked() {
  console.log("bundle unlocked");
  emit("unlocked");
}
function resetDisplayable() {
  emit("reset-displayable");
}
</script>
