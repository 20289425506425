import moment from "moment";
import _ from "lodash";

export default function scheduleTimes() {
  const startOfToday = moment().startOf("day").add(8, "hours");
  const effectiveToday = moment().isAfter(startOfToday)
    ? moment().add(1, "hour").startOf("minute")
    : startOfToday;
  let times = [
    {
      name: "Past Due",
      importance: "major",
      unit: null,
      date: null,
    },
    {
      name: "Today",
      importance: "major",
      unit: "day",
      date: startOfToday,
    },
    {
      name: "Tomorrow",
      importance: "minor",
      unit: "day",
      date: moment().add(1, "day").startOf("day").add(8, "hours"),
    },
    {
      name: moment()
        .add(2, "days")
        .startOf("day")
        .add(8, "hours")
        .format("ddd MMM D"),
      importance: "minor",
      unit: "day",
      date: moment().add(2, "days").startOf("day").add(8, "hours"),
    },
    {
      name: moment()
        .add(3, "days")
        .startOf("day")
        .add(8, "hours")
        .format("ddd MMM D"),
      importance: "minor",
      unit: "day",
      date: moment().add(3, "days").startOf("day").add(8, "hours"),
    },
    {
      name: moment()
        .add(4, "days")
        .startOf("day")
        .add(8, "hours")
        .format("ddd MMM D"),
      importance: "minor",
      unit: "day",
      date: moment().add(4, "days").startOf("day").add(8, "hours"),
    },
    {
      name: moment()
        .add(5, "days")
        .startOf("day")
        .add(8, "hours")
        .format("ddd MMM D"),
      importance: "minor",
      unit: "day",
      date: moment().add(5, "days").startOf("day").add(8, "hours"),
    },
    {
      name: moment()
        .add(6, "days")
        .startOf("day")
        .add(8, "hours")
        .format("ddd MMM D"),
      importance: "minor",
      unit: "day",
      date: moment().add(6, "days").startOf("day").add(8, "hours"),
    },
    {
      name: `Rest of ${moment().add(7, "days").startOf("day").format("MMMM")}`,
      importance: "major",
      unit: "month",
      date: moment().add(7, "days").startOf("day").add(8, "hours"),
    },
  ];
  const nextMonth = moment()
    .add(7, "days")
    .endOf("month")
    .add(1, "minute")
    .startOf("day");
  const forwardMonths = _.times(11, function (i) {
    let newMonth = nextMonth
      .clone()
      .add(i, "months")
      .startOf("day")
      .add(8, "hours");
    return {
      name: newMonth.format("MMMM"),
      importance: "submajor",
      unit: "month",
      date: newMonth,
    };
  });

  return _.concat(times, forwardMonths);
}
