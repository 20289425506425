<template>
  <tbody class="divide-y divide-gray-200 bg-white">
    <tr>
      <td
        class="relative whitespace-nowrap flex items-center py-2 pl-4 pr-3 text-sm font-medium"
      >
        <button
          @click.prevent="expanded = !expanded"
          type="button"
          class="h-5 w-5 inline-flex justify-center items-center text-sm text-gray-700 hover:text-gray-900"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clip-rule="evenodd"
            />
          </svg>
        </button>
      </td>
      <td class="whitespace-nowrap px-2 py-2">
        <DataField
          :data-field="valuation"
          primary-text-path="fieldContent.valueType"
          text-classes="text-sm"
          text-styles=""
          @completed="refetchValuations"
          @dismiss="refetchValuations"
        />
      </td>
      <td class="whitespace-nowrap px-2 py-2">
        <EditableDataField
          v-bind="_.find(availableProps, { fieldName: 'value' })"
          @open-sourced="refetchValuations"
          @completed="refetchValuations"
          @dismiss="refetchValuations"
          @set-proof="setProof"
        />
      </td>
      <td class="whitespace-nowrap px-2 py-2">
        <PricePerArea
          :content-type="valuation?.fieldContentType"
          :content-ids="[valuation?.fieldContentId]"
          :decorating-content-type="fieldContentType"
          :decorating-content-id="fieldContent?.id"
          context="deal-builder"
          text-classes="text-sm font-medium"
          @refetch="refetchValuations"
        />
      </td>
    </tr>

    <tr v-if="expanded">
      <td colspan="100">Coming Soon</td>
    </tr>
  </tbody>
</template>

<script setup>
import DataField from "@/components/crowdsourcing/DataField.vue";
import EditableDataField from "@/components/crowdsourcing/EditableDataField.vue";
import PricePerArea from "@/components/analyze/calculations/PricePerArea.vue";
import { useProveForValueStore } from "@/stores/proveForValue";
import { ref, computed } from "vue";
import _ from "lodash";

const props = defineProps(["valuation", "fieldContentType", "fieldContent"]);
const emit = defineEmits(["refetch"]);

const proveForValueStore = useProveForValueStore();

const expanded = ref(false);

const availableProps = computed(() => {
  return [
    {
      fieldName: "value",
      textClasses: "text-sm font-medium",
      decoratingContentDataField: props.valuation,
      isCurrency: true,
      optional: true,
      dismissOnSave: true,
    },
  ];
});

function refetchValuations(scope = "valuations") {
  emit("refetch", scope);
}

function setProof(fieldName) {
  const matchedProps = _.find(availableProps.value, { fieldName });

  if (matchedProps) {
    proveForValueStore.setProofComponent(EditableDataField, matchedProps);
  }
}
</script>
