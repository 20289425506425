import _ from "lodash";

export default function sortedTasksFor({
  signedIn,
  useStandaloneShowCompleted = false,
  standaloneShowCompleted = false,
  taskListObject,
  allTasks,
  incompleteTasks,
}) {
  const effectiveShowCompleted = useStandaloneShowCompleted
    ? standaloneShowCompleted
    : taskListObject.showCompleted;

  if (signedIn) {
    if (taskListObject.sortBy === "manual") {
      if (effectiveShowCompleted) return _.orderBy(allTasks, ["createdAt"]);
      else return _.orderBy(incompleteTasks, ["createdAt"]);
    } else if (taskListObject.sortBy === "priority") {
      const sortingFunction = (task) => {
        if (taskListObject.sortDirection === "asc") {
          switch (task.priority) {
            case "high":
              return 1;
            case "medium":
              return 2;
            case "low":
              return 3;
            default:
              return 4;
          }
        } else {
          switch (task.priority) {
            case "high":
              return 4;
            case "medium":
              return 3;
            case "low":
              return 2;
            default:
              return 1;
          }
        }
      };

      if (effectiveShowCompleted) {
        return _.sortBy(allTasks, [sortingFunction]);
      } else {
        return _.sortBy(incompleteTasks, [sortingFunction]);
      }
    } else {
      if (effectiveShowCompleted)
        return _.orderBy(
          allTasks,
          [_.camelCase(taskListObject.sortBy)],
          [taskListObject.sortDirection]
        );
      else {
        return _.orderBy(
          incompleteTasks,
          [_.camelCase(taskListObject.sortBy)],
          [taskListObject.sortDirection]
        );
      }
    }
  } else {
    if (effectiveShowCompleted) return _.orderBy(allTasks, ["id"]);
    else return _.orderBy(incompleteTasks, ["id"]);
  }
}
