<template>
  <tr
    v-if="singleSpaceUsageId"
    v-observe-visibility="{ callback: fetchSingleSpaceUsage, once: true }"
    class="bg-orange-50"
  >
    <td>
      <span class="sr-only">Empty</span>
    </td>
    <td
      v-if="spaceUsageDataField"
      @mouseenter="handleHover"
      @mouseleave="clearHover"
      class="align-top whitespace-nowrap py-2 pr-3 pl-4"
    >
      <div class="flex items-center space-x-1">
        <DataField
          v-for="user in userDataFields"
          :key="user.localId"
          :data-field="user"
          text-classes="text-sm font-medium"
          text-styles=""
          @completed="refetchAll"
          @unlocked="refetchAll"
          @open-sourced="refetchAll"
        />
      </div>
      <DataField
        v-if="spaceUsageDataField"
        :data-field="spaceUsageDataField"
        self-label-output-format="rentRollName"
        text-classes="text-sm font-medium"
        dropdown-placement="left-start"
        text-styles=""
        :analyze="true"
        @completed="refetchAll"
        @unlocked="refetchAll"
        @open-sourced="refetchAll"
      />
      <div
        v-if="constructionStatusField || floorLayoutDataFields.length > 0"
        class="flex items-center space-x-1"
      >
        <DataField
          v-if="constructionStatusField"
          :data-field="constructionStatusField"
          text-classes="text-sm font-medium"
          dropdown-placement="left-start"
          text-styles=""
          :analyze="true"
          @completed="refetchAll"
          @unlocked="refetchAll"
          @open-sourced="refetchAll"
        />
        <span
          v-if="constructionStatusField && floorLayoutDataFields.length > 0"
          class="text-xl font-bold text-gray-500"
          >&middot;</span
        >
        <template v-if="floorLayoutDataFields.length > 0">
          <span class="text-xs text-gray-500">Layouts:</span>
          <VMenu
            theme="diagram-popup"
            v-for="layout in floorLayoutDataFields"
            :key="layout.localId"
          >
            <QrCodeIcon
              class="h-4 w-4 rounded-sm text-violet-500 cursor-pointer"
            />
            <template #popper>
              <DataFieldInfoPopup :data-field="layout" />
            </template>
          </VMenu>
        </template>
      </div>
    </td>
    <td
      v-else
      class="align-top whitespace-nowrap px-2 py-2 text-xs text-gray-500"
    >
      <span>Unlock</span>
    </td>
    <td class="align-top whitespace-nowrap px-2 py-2 text-xs text-gray-500">
      <span class="sr-only">Empty</span>
    </td>
    <td class="align-top whitespace-nowrap px-2 py-2 text-xs text-gray-500">
      <SizesUses
        v-if="
          spaceUsageDataField?.fieldContent?.space?.recordType === 'FloorArea'
        "
        :standalone-content-type="spaceUsageDataField.fieldContentType"
        :standalone-content-ids="[spaceUsageDataField.fieldContentId]"
        :standalone-content-data-field="spaceUsageDataField"
        @numeric-area="setPerAreaSize"
      />
    </td>
    <template v-if="workspaceLayout === 'sideBySide'">
      <td class="align-top whitespace-nowrap px-2 py-2 text-xs text-gray-500">
        <DataField
          v-if="termMonths"
          calculation-name="space_usage_term_months"
          :calculation-value="termMonths"
          :bundle-field-ids="[
            commencedDateDataField.localId,
            expiredDateDataField.localId,
          ]"
          text-classes="text-sm font-medium"
          text-styles=""
          @unlocked="refetchAll"
        />
        <span v-else class="sr-only">Empty</span>
      </td>
      <td class="align-top whitespace-nowrap px-2 py-2 text-xs text-gray-500">
        <DataField
          v-if="
            spaceUsageDataField &&
            inPlaceRentFor(spaceUsageDataField.fieldContent)
          "
          calculation-name="in_place_rent"
          :calculation-value="inPlaceRentFor(spaceUsageDataField.fieldContent)"
          :bundle-field-ids="
            inPlaceRentFieldIdsFor(spaceUsageDataField.fieldContent)
          "
          text-classes="text-sm font-medium"
          text-styles=""
          @unlocked="refetchAll"
        />
        <span v-else class="sr-only">Empty</span>
      </td>
    </template>
    <template v-else>
      <td class="align-top whitespace-nowrap px-2 py-2 text-xs text-gray-500">
        <DataField
          v-if="commencedDateDataField"
          :data-field="commencedDateDataField"
          text-classes="text-sm font-medium"
          text-styles=""
          @unlocked="refetchAll"
        />
        <span v-else class="sr-only">Empty</span>
      </td>
      <td class="align-top whitespace-nowrap px-2 py-2 text-xs text-gray-500">
        <DataField
          v-if="expiredDateDataField"
          :data-field="expiredDateDataField"
          text-classes="text-sm font-medium"
          text-styles=""
          @unlocked="refetchAll"
        />
        <span v-else class="sr-only">Empty</span>
      </td>
      <td class="align-top whitespace-nowrap px-2 py-2 text-xs text-gray-500">
        <DataField
          v-if="termMonths"
          calculation-name="space_usage_term_months"
          :calculation-value="termMonths"
          :bundle-field-ids="[
            commencedDateDataField.localId,
            expiredDateDataField.localId,
          ]"
          text-classes="text-sm font-medium"
          text-styles=""
          @unlocked="refetchAll"
        />
        <span v-else class="sr-only">Empty</span>
      </td>
      <td
        class="align-top whitespace-nowrap px-2 py-2 text-xs text-gray-500 max-w-96"
      >
        <CashflowScheduleField
          v-if="spaceUsageDataField?.fieldContent?.baseRentSchedule"
          :schedule-data-field="
            spaceUsageDataField?.fieldContent?.baseRentSchedule
          "
          :content-data-field="spaceUsageDataField"
          :date-fields="dateFields"
          :per-area-size="perAreaSize"
          :analyze="true"
          :removable="true"
          @unlocked="refetchAll"
          @open-sourced="refetchAll"
        />
        <CashflowScheduleField
          v-if="spaceUsageDataField?.fieldContent?.tenantImprovementSchedule"
          :schedule-data-field="
            spaceUsageDataField?.fieldContent?.tenantImprovementSchedule
          "
          :content-data-field="spaceUsageDataField"
          :date-fields="dateFields"
          :per-area-size="perAreaSize"
          :analyze="true"
          :removable="true"
          @unlocked="refetchAll"
          @open-sourced="refetchAll"
        />
        <span
          v-if="
            !spaceUsageDataField?.fieldContent?.baseRentSchedule &&
            !spaceUsageDataField?.fieldContent?.tenantImprovementSchedule
          "
          class="sr-only"
          >Empty</span
        >
      </td>
    </template>
  </tr>

  <tr
    v-if="record?.rowType === 'singlePropertySpaceUsage'"
    v-observe-visibility="{
      callback: fetchSinglePropertySpaceUsages,
      once: true,
    }"
  >
    <td class="relative whitespace-nowrap py-2 px-3 text-sm font-medium">
      <button
        @click="expanded = !expanded"
        type="button"
        v-tooltip="expanded ? 'Hide leases' : 'View leases'"
        class="h-5 w-5 inline-flex justify-center items-center text-sm text-gray-700 hover:text-gray-900"
      >
        <ChevronDownIcon class="h-5 w-5" />
      </button>
    </td>
    <td
      v-if="propertyDataField"
      @mouseenter="pulseProperty"
      @mouseleave="propertyMarkerPulseId = null"
      class="whitespace-nowrap py-2 pr-3"
    >
      <DataField
        v-if="propertyDataField"
        :data-field="propertyDataField"
        primary-text-path="fieldContent.name"
        text-classes="text-sm font-medium"
        dropdown-placement="left-start"
        text-styles=""
        :analyze="true"
        @completed="refetchAll"
        @unlocked="refetchAll"
        @open-sourced="refetchAll"
      />
    </td>
    <td v-else class="whitespace-nowrap px-2 py-2 text-xs text-gray-500">
      <span>Unlock leases below</span>
    </td>
    <td class="whitespace-nowrap px-2 py-2 text-xs text-gray-500">
      <DataField
        v-if="locationDataField"
        :data-field="locationDataField"
        text-classes="text-sm"
        :analyze="true"
      />
      <template v-else>Unlock</template>
    </td>
    <td
      @click="expanded = !expanded"
      class="whitespace-nowrap px-2 py-2 text-xs text-gray-500 cursor-pointer"
    >
      {{ expanded ? "See below" : "Expand to see" }}
    </td>
    <template v-if="workspaceLayout !== 'sideBySide'">
      <td
        @click="expanded = !expanded"
        class="whitespace-nowrap px-2 py-2 text-xs text-gray-500 cursor-pointer"
      >
        {{ expanded ? "See below" : "Expand to see" }}
      </td>
      <td
        @click="expanded = !expanded"
        class="whitespace-nowrap px-2 py-2 text-xs text-gray-500 cursor-pointer"
      >
        {{ expanded ? "See below" : "Expand to see" }}
      </td>
    </template>
    <td
      @click="expanded = !expanded"
      class="whitespace-nowrap px-2 py-2 text-xs text-gray-500 cursor-pointer"
    >
      <div>See below</div>
    </td>
    <td
      @click="expanded = !expanded"
      class="whitespace-nowrap px-2 py-2 text-xs text-gray-500 cursor-pointer"
    >
      {{ expanded ? "See below" : "Expand to see" }}
    </td>
  </tr>

  <tr
    v-else-if="record?.rowType === 'singlePropertyPortfolioSpaceUsage'"
    v-observe-visibility="{
      callback: fetchSinglePropertyPortfolioSpaceUsages,
      once: true,
    }"
  >
    <td class="relative whitespace-nowrap py-2 px-3 text-sm font-medium">
      <button
        @click="expanded = !expanded"
        type="button"
        v-tooltip="expanded ? 'Hide group spaces' : 'View group spaces'"
        class="h-5 w-5 inline-flex justify-center items-center text-sm text-gray-700 hover:text-gray-900"
      >
        <ChevronDownIcon class="h-5 w-5" />
      </button>
    </td>
    <td
      v-if="spaceAvailabilityGroupDataField && propertyDataField"
      @mouseenter="handleHover"
      @mouseleave="clearHover"
      class="align-top whitespace-nowrap py-2 pr-3"
    >
      <DataField
        v-if="spaceAvailabilityGroupDataField"
        :data-field="spaceAvailabilityGroupDataField"
        :property-data-field="propertyDataField"
        text-classes="text-sm font-medium"
        dropdown-placement="left-start"
        text-styles=""
        :analyze="true"
        @completed="refetchAll"
        @unlocked="refetchAll"
        @open-sourced="refetchAll"
      />
    </td>
    <td
      v-else-if="spaceAvailabilityGroupDataField"
      class="align-top whitespace-nowrap py-2 pr-3"
    >
      <DataField
        v-if="spaceAvailabilityGroupDataField"
        :data-field="spaceAvailabilityGroupDataField"
        :property-data-field="propertyDataField"
        text-classes="text-sm font-medium"
        dropdown-placement="left-start"
        text-styles=""
        :analyze="true"
        @completed="refetchAll"
        @unlocked="refetchAll"
        @open-sourced="refetchAll"
      />
    </td>
    <td
      v-else
      class="align-top whitespace-nowrap px-2 py-2 text-xs text-gray-500"
    >
      <span>Unlock SpaceUsage</span>
    </td>
    <td class="align-top whitespace-nowrap px-2 py-2 text-xs text-gray-500">
      <DataField
        v-if="locationDataField"
        :data-field="locationDataField"
        text-classes="text-sm"
        :analyze="true"
      />
      <template v-else>Unlock</template>
    </td>
    <td class="align-top whitespace-nowrap px-2 py-2 text-xs text-gray-500">
      <SizesUses
        v-if="floorAreaGroup"
        standalone-content-type="SpaceAvailability"
        :standalone-content-ids="
          spaceAvailabilityGroupDataField.fieldContent?.availabilityIds
        "
        :standalone-content-data-field="spaceAvailabilityGroupDataField"
        @numeric-area="setPerAreaSize"
      />
    </td>
    <template v-if="workspaceLayout !== 'sideBySide'">
      <td class="align-top whitespace-nowrap px-2 py-2 text-xs text-gray-500">
        <div>See below</div>
      </td>
      <td class="align-top whitespace-nowrap px-2 py-2 text-xs text-gray-500">
        <div>See below</div>
      </td>
    </template>
    <td class="align-top whitespace-nowrap px-2 py-2 text-xs text-gray-500">
      <div>See below</div>
    </td>
    <td class="align-top whitespace-nowrap px-2 py-2 text-xs text-gray-500">
      <div>See below</div>
    </td>
  </tr>

  <template v-if="record?.spaceUsageStubs && expanded">
    <SpaceUsageTableRow
      v-for="(spaceUsageRecord, index) in record.spaceUsageStubs"
      :key="`SpaceUsage${spaceUsageRecord.spaceUsageId}-${index}`"
      :single-space-usage-id="spaceUsageRecord.spaceUsageId"
      :single-space-usage-property-id="record.propertyId"
    />
  </template>
</template>

<script setup>
import DataField from "@/components/crowdsourcing/DataField.vue";
import DataFieldInfoPopup from "@/components/crowdsourcing/DataFieldInfoPopup.vue";
import SizesUses from "@/components/analyze/calculations/SizesUses.vue";
import CashflowScheduleField from "@/components/crowdsourcing/CashflowScheduleField.vue";
import { useMainMapStore } from "@/stores/mainMap";
import { useAnalyzePanelStore } from "@/stores/analyzePanel";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { useTimeTravelStore } from "@/stores/timeTravel";
import { storeToRefs } from "pinia";
import { currencyAmount } from "@/assets/numberHelpers";
import { computed, ref } from "vue";
import {
  inPlaceRentFields,
  inPlaceRent,
} from "@/components/analyze/calculations/inPlaceRent";
import _ from "lodash";
import { ChevronDownIcon, QrCodeIcon } from "@heroicons/vue/20/solid";
import moment from "moment";

const props = defineProps([
  "record",
  "singleSpaceUsageId",
  "singleSpaceUsagePropertyId",
]);
const mapStore = useMainMapStore();
const { propertyMarkerPulseId, nearbyPropertyDataFields } =
  storeToRefs(mapStore);
const analyzePanelStore = useAnalyzePanelStore();
const {
  analyzeSpaceUsageFields,
  fetchedSpaceUsageUserFields,
  fetchedUsageFloorAreaLayoutFields,
  hoveringTableRowContent,
  routeName,
} = storeToRefs(analyzePanelStore);
const layoutStore = useWorkspaceLayoutStore();
const { workspaceLayout } = storeToRefs(layoutStore);
const timeTravelStore = useTimeTravelStore();
const { timeTravelTo } = storeToRefs(timeTravelStore);

const expanded = ref(true);

const propertyDataField = computed(() => {
  if (_.isNumber(props.record?.propertyId)) {
    return analyzeSpaceUsageFields.value[`Property${props.record.propertyId}`];
  } else if (_.isNumber(props.singleSpaceUsagePropertyId)) {
    return analyzeSpaceUsageFields.value[
      `Property${props.singleSpaceUsagePropertyId}`
    ];
  } else if (_.isNumber(props.record?.spaceUsageStub?.propertyId)) {
    return analyzeSpaceUsageFields.value[
      `Property${props.record.spaceUsageStub.propertyId}`
    ];
  } else {
    return null;
  }
});
const spaceUsageDataField = computed(() => {
  if (props.singleSpaceUsageId) {
    return analyzeSpaceUsageFields.value[
      `SpaceUsage${props.singleSpaceUsageId}`
    ];
  } else if (props.record?.spaceUsageStub?.spaceUsageId) {
    return analyzeSpaceUsageFields.value[
      `SpaceUsage${props.record.spaceUsageStub.spaceUsageId}`
    ];
  } else {
    return null;
  }
});
const spaceAvailabilityGroupDataField = computed(() => {
  if (props.record?.rowType === "singlePropertyPortfolioSpaceUsage") {
    const availabilityGroupId = _.head(
      props.record.spaceUsageStubs,
    ).availabilityGroupId;
    return analyzeSpaceUsageFields.value[
      `SpaceAvailabilityGroup${availabilityGroupId}`
    ];
  } else {
    return null;
  }
});
const floorAreaGroup = computed(() => {
  if (spaceAvailabilityGroupDataField.value) {
    const availabilities =
      spaceAvailabilityGroupDataField.value.fieldContent?.availabilities;

    return _.every(availabilities, function ({ topLevelSpace }) {
      return topLevelSpace?.fieldContentType === "FloorArea";
    });
  } else {
    return false;
  }
});
const topLevelSpaceContent = computed(
  () => spaceUsageDataField.value?.fieldContent?.space,
);
const constructionStatusField = computed(() => {
  if (topLevelSpaceContent.value) {
    return topLevelSpaceContent.value.displayableStateField;
  } else {
    return null;
  }
});
const floorLayoutDataFields = computed(() => {
  let fields = [];
  if (topLevelSpaceContent.value?.recordType === "FloorArea") {
    fields = fetchedUsageFloorAreaLayoutFields.value.filter((df) => {
      return (
        df.decoratingContentType === "FloorArea" &&
        df.decoratingContentId === topLevelSpaceContent.value.id
      );
    });
  } else if (topLevelSpaceContent.value?.recordType === "LayoutPartition") {
    fields = fetchedUsageFloorAreaLayoutFields.value.filter((df) => {
      return (
        df.decoratingContentType === "FloorArea" &&
        df.fieldContentType === "FloorAreaLayout" &&
        df.fieldContentId === topLevelSpaceContent.value.floorAreaLayoutId
      );
    });
  } else {
    fields = [];
  }

  return _.uniqBy(fields, "localId");
});
const locationFromMap = computed(() => {
  if (props.record?.propertyId) {
    return _.get(nearbyPropertyDataFields.value, props.record.propertyId);
  } else {
    return null;
  }
});
const locationDataField = computed(() => {
  if (props.record?.propertyId || propertyDataField.value) {
    if (locationFromMap.value) {
      return locationFromMap.value;
    } else if (propertyDataField.value) {
      return propertyDataField.value.fieldContent?.locationDataField;
    } else {
      return null;
    }
  } else {
    return null;
  }
});
const userDataFields = computed(() => {
  if (spaceUsageDataField.value?.fieldContentId) {
    return fetchedSpaceUsageUserFields.value.filter(
      ({ decoratingContentId }) => {
        return decoratingContentId === spaceUsageDataField.value.fieldContentId;
      },
    );
  } else {
    return [];
  }
});
const commencedDateDataField = computed(() => {
  if (spaceUsageDataField.value?.fieldContentId) {
    const commencedDateFetchKey = `SpaceUsage${spaceUsageDataField.value?.fieldContentId}commenced_date`;

    return analyzeSpaceUsageFields.value[commencedDateFetchKey];
  } else {
    return null;
  }
});
const expiredDateDataField = computed(() => {
  if (spaceUsageDataField.value?.fieldContentId) {
    return spaceUsageDataField.value.fieldContent?.expirationField;
  } else {
    return null;
  }
});
const dateFields = computed(() => {
  return {
    commenced_date: commencedDateDataField.value,
    expired_date: expiredDateDataField.value,
  };
});
const termMonths = computed(() => {
  if (
    commencedDateDataField.value?.fieldDate &&
    expiredDateDataField.value?.fieldDate
  ) {
    const commencedMoment = moment(commencedDateDataField.value.fieldDate);
    const expiredMoment = moment(expiredDateDataField.value.fieldDate);
    const adjustedFinalMoment = expiredMoment.endOf("month").add(1, "day");
    const monthsRemaining = adjustedFinalMoment.diff(commencedMoment, "months");
    return `${monthsRemaining} Mths`;
  } else {
    return null;
  }
});
const perAreaSize = ref(null);
function setPerAreaSize(numericArea) {
  perAreaSize.value = numericArea;
}

async function fetchSinglePropertySpaceUsages() {
  if (
    props.record.rowType === "singlePropertySpaceUsage" &&
    _.isNumber(props.record.propertyId)
  ) {
    analyzePanelStore.fetchPropertyDataField(
      props.record.propertyId,
      "spaceUsageFields",
    );
  }
}

async function fetchSinglePropertyPortfolioSpaceUsages() {
  if (props.record.rowType === "singlePropertyPortfolioSpaceUsage") {
    if (_.isNumber(props.record.propertyId)) {
      analyzePanelStore.fetchPropertyDataField(
        props.record.propertyId,
        "spaceUsageFields",
      );
    }
    const usageIds = props.record.spaceUsageStubs.map(
      ({ spaceUsageId }) => spaceUsageId,
    );

    for (const id of usageIds) {
      await analyzePanelStore.fetchSpaceUsageDataField(id, "spaceUsageFields");
      fetchSpaceUsageCommencedDate(id);
    }

    const availabilityGroupId = _.head(
      props.record.spaceUsageStubs,
    ).availabilityGroupId;

    analyzePanelStore.fetchSpaceAvailabilityGroupDataField(
      availabilityGroupId,
      `SpaceAvailabilityGroup${availabilityGroupId}`,
      "spaceUsageFields",
    );
  }
}

async function fetchSingleSpaceUsage() {
  if (props.singleSpaceUsageId) {
    await analyzePanelStore.fetchSpaceUsageDataField(props.singleSpaceUsageId);
    fetchSpaceUsageCommencedDate();
    fetchSpaceUsageUsers();
    fetchFloorAreaLayouts();
  }
}

function fetchFloorAreaLayouts() {
  if (topLevelSpaceContent.value?.recordType === "FloorArea") {
    analyzePanelStore.fetchFloorAreaLayouts({
      floorAreaId: topLevelSpaceContent.value.id,
      collection: "spaceUsageFields",
    });
  } else if (topLevelSpaceContent.value?.recordType === "LayoutPartition") {
    analyzePanelStore.fetchFloorAreaLayouts({
      layoutPartitionId: topLevelSpaceContent.value.id,
      collection: "spaceUsageFields",
    });
  }
}

async function fetchSpaceUsageCommencedDate(usageId = null) {
  const fetchId = usageId || spaceUsageDataField.value?.fieldContentId;
  if (fetchId) {
    await analyzePanelStore.fetchSpaceUsageCommencedDateFor(
      null,
      null,
      fetchId,
      "spaceUsageFields",
    );
  }
}

async function fetchSpaceUsageUsers() {
  if (spaceUsageDataField.value?.fieldContentId) {
    await analyzePanelStore.fetchSpaceUsageUserDataFields(
      spaceUsageDataField.value?.fieldContentId,
    );
  }
}

function handleHover() {
  pulseProperty();
  highlightDiagramElement();
}

function clearHover() {
  propertyMarkerPulseId.value = null;
  hoveringTableRowContent.value = null;
}

function pulseProperty() {
  if (propertyDataField.value) {
    const pulseId =
      propertyDataField.value.fieldContent?.groundLayerPropertyId ||
      propertyDataField.value.fieldContentId;
    propertyMarkerPulseId.value = pulseId;
  }
}

function highlightDiagramElement() {
  if (routeName.value === "PropertyShell") {
    if (topLevelSpaceContent.value) {
      hoveringTableRowContent.value = topLevelSpaceContent.value;
    } else if (spaceAvailabilityGroupDataField.value) {
      hoveringTableRowContent.value = spaceAvailabilityGroupDataField.value;
    }
  }
}

function clearRow() {
  console.log("clear row", props.record?.rowType);
}

function refetchAll() {
  clearRow();
  if (props.singleSpaceUsageId) {
    console.log("spaceUsage row refetch single", props.singleSpaceUsageId);
    delete analyzeSpaceUsageFields.value[
      `SpaceUsage${props.singleSpaceUsageId}`
    ];
    fetchSingleSpaceUsage();
  } else {
    console.log("spaceUsage row refetch all", props.record.rowType);
    switch (props.record.rowType) {
      case "singlePropertyPortfolioSpaceUsage":
        console.log("refetch singlePropertyPortfolio");
        break;
      case "singlePropertySpaceUsage":
        delete analyzeSpaceUsageFields.value[
          `Property${props.record.propertyId}`
        ];
        fetchSinglePropertySpaceUsages();
        break;
    }
  }
}

function inPlaceRentFor(spaceUsage) {
  const numeric = inPlaceRent(spaceUsage, timeTravelTo.value);

  return _.isNumber(numeric) ? `$${currencyAmount(numeric, 2)}/SF` : null;
}

function inPlaceRentFieldIdsFor(spaceUsage) {
  const { baseRentScheduleField, expirationField, perAreaSizeField } =
    inPlaceRentFields(spaceUsage);
  const ids = _.compact([
    baseRentScheduleField,
    expirationField,
    perAreaSizeField,
  ]).map(({ localId }) => localId);

  return ids;
}
</script>
