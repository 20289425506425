import { computed, ref } from "vue";
import { defineStore, acceptHMRUpdate } from "pinia";
import _ from "lodash";

export const useEasyDataInputStore = defineStore("easyDataInput", () => {
  const savingContribution = ref(false);
  const contributionUploadPayload = ref(null);
  const description = ref(null);
  const emptyDescription = computed(() => {
    return _.isNull(description.value) || _.trim(description.value) === "";
  });

  async function clearContribution() {
    contributionUploadPayload.value = null;
    description.value = null;
    savingContribution.value = false;
  }
  function clearAttachment() {
    contributionUploadPayload.value = null;
  }

  return {
    savingContribution,
    contributionUploadPayload,
    description,
    emptyDescription,
    clearContribution,
    clearAttachment,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useEasyDataInputStore, import.meta.hot),
  );
}
