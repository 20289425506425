<template>
  <div
    :class="
      activeEasyDataInputPayloadItem.paused ? 'bg-amber-950' : 'bg-amber-500'
    "
    class="flex items-center gap-x-1 py-2.5 px-3.5"
    id="easy-data-active-payload-item-banner"
  >
    <EasyDataActiveInputPayloadPauseSwitch label-color="text-white" />
    <VMenu>
      <p class="text-sm leading-6 text-white">
        <a @click.prevent="viewContributions" href="" class="space-x-1">
          <strong class="font-semibold underline"
            >Data Input Job Item {{ activeEasyDataInputPayloadItem.id }}</strong
          >
          <span>&middot;</span>
          <span
            >Due
            {{
              moment(activeEasyDataInputPayloadItem.deadline).fromNow()
            }}</span
          >
        </a>
      </p>
      <template #popper>
        <SelectedInputPayloadItemSummary :pop-up="true" />
      </template>
    </VMenu>
  </div>
</template>

<script setup>
import { useUserStore } from "@/stores/user";
import moment from "moment";
import { storeToRefs } from "pinia";
import { useRouter, useRoute } from "vue-router";
import EasyDataActiveInputPayloadPauseSwitch from "@/components/users/EasyDataActiveInputPayloadPauseSwitch.vue";
import SelectedInputPayloadItemSummary from "@/components/crowdsourcing/contributions/SelectedInputPayloadItemSummary.vue";

const userStore = useUserStore();
const { activeEasyDataInputPayloadItem } = storeToRefs(userStore);

const router = useRouter();
const route = useRoute();

function viewContributions() {
  router.push({
    name: route.name,
    query: {
      ...route.query,
      horizontalTab: "Me",
      verticalTab: "Contributions",
    },
  });
}
</script>
