<template>
  <VDropdown placement="bottom-end">
    <button
      aria-has-popup="true"
      type="button"
      class="w-8 h-8 inline-flex items-center justify-center text-gray-400 rounded-full bg-transparent hover:text-gray-500 focus:outline-none focus:text-gray-500 focus:bg-gray-100"
      data-test="shared-payout-account-menu-button"
    >
      <EllipsisVerticalIcon class="w-5 h-5" />
    </button>

    <template #popper>
      <div
        class="w-56 overflow-hidden rounded-md bg-white ring-1 ring-black ring-opacity-5"
      >
        <div class="py-1">
          <span
            class="group pointer-events-none flex items-center px-4 py-2 text-sm font-semibold leading-5 text-gray-700"
            role="menuitem"
          >
            Account type:
          </span>
        </div>
        <div class="border-t border-gray-100"></div>
        <div class="relative z-0 flex divide-x divide-gray-200">
          <a
            href=""
            @click.prevent="setCategory('building')"
            aria-current="page"
            :class="
              account.category === 'building'
                ? 'text-gray-900'
                : 'text-gray-500 hover:text-gray-700'
            "
            class="group relative min-w-0 flex-1 overflow-hidden bg-white p-2 text-xs font-medium text-center hover:bg-gray-50 focus:z-10"
            data-test="shared-payout-account-building-category-button"
          >
            <span>Building</span>
            <span
              aria-hidden="true"
              :class="
                account.category === 'building'
                  ? 'bg-indigo-500'
                  : 'bg-transparent'
              "
              class="absolute inset-x-0 bottom-0 h-0.5"
            ></span>
          </a>

          <a
            href=""
            @click.prevent="setCategory('team')"
            aria-current="false"
            :class="
              account.category === 'team'
                ? 'text-gray-900'
                : 'text-gray-500 hover:text-gray-700'
            "
            class="group relative min-w-0 flex-1 overflow-hidden bg-white p-2 text-xs font-medium text-center hover:bg-gray-50 focus:z-10"
            data-test="shared-payout-account-team-category-button"
          >
            <span>Team</span>
            <span
              aria-hidden="true"
              :class="
                account.category === 'team' ? 'bg-indigo-500' : 'bg-transparent'
              "
              class="absolute inset-x-0 bottom-0 h-0.5"
            ></span>
          </a>

          <a
            href=""
            @click.prevent="setCategory('company')"
            aria-current="false"
            :class="
              account.category === 'company'
                ? 'text-gray-900'
                : 'text-gray-500 hover:text-gray-700'
            "
            class="group relative min-w-0 flex-1 overflow-hidden bg-white p-2 text-xs font-medium text-center hover:bg-gray-50 focus:z-10"
            data-test="shared-payout-account-company-category-button"
          >
            <span>Company</span>
            <span
              aria-hidden="true"
              :class="
                account.category === 'company'
                  ? 'bg-indigo-500'
                  : 'bg-transparent'
              "
              class="absolute inset-x-0 bottom-0 h-0.5"
            ></span>
          </a>
        </div>
        <div class="border-t border-gray-100"></div>
        <div class="">
          <a
            href=""
            @click.prevent="deleteAccount"
            class="group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
            role="menuitem"
            data-test="shared-payout-account-delete-button"
          >
            <TrashIcon
              class="mr-2 h-4 w-4 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500"
            />
            Delete
          </a>
        </div>
      </div>
    </template>
  </VDropdown>
</template>

<script setup>
import { EllipsisVerticalIcon, TrashIcon } from "@heroicons/vue/20/solid";
import api from "@/router/api";

const props = defineProps(["account"]);
const emit = defineEmits(["refetch", "category"]);

async function deleteAccount() {
  if (props.account.managed) {
    api.delete(`shared_payout_accounts/${props.account.id}`).then(() => {
      // this.$store.dispatch("flash", "Account deleted");
      emit("refetch");
    });
  }
}
function setCategory(category) {
  emit("category", category);
}
</script>
