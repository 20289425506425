<template>
  <VDropdown>
    <button
      v-tooltip="person.name"
      type="button"
      :data-test="`${_.kebabCase(testLabel)}-contact-field`"
      class="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 duration-150"
    >
      <AvatarPhoto
        :person="person"
        :border-class="`ring-4 ${dynamicColor}`"
        shape-size="h-6 w-6"
        icon-size="h-4 w-4"
        text-size="xs"
      />
    </button>

    <template #popper>
      <div class="w-72 flex flex-col">
        <div class="p-2 space-y-2">
          <DataField
            v-if="dataField"
            :data-field="dataField"
            primary-text-path="fieldContent.name"
            text-classes="leading-5 font-medium"
            :dropdown-placement="
              workspaceLayout === 'topAndBottom' ? null : 'left-start'
            "
            text-styles=""
            @open-sourced="openSourced"
            @unlocked="unlocked"
            @completed="completed"
            @set-proof="setProof"
          />
          <div
            v-else-if="contact || user"
            class="inline-flex rounded-full items-center py-0.5 px-2 text-sm font-medium bg-gray-100 text-gray-700"
          >
            {{ person.name }}
          </div>
          <ContactInfo
            v-if="person && !unlockable"
            :contact-data-field="dataField"
            :contact="contact"
            :user="user"
          />
        </div>
        <ContentNotes
          v-if="!unlockable"
          :content-data-field="dataField"
          :contact="contact"
          :user="user"
          context="contactDataField"
        />
      </div>
    </template>
  </VDropdown>
</template>

<script setup>
import AvatarPhoto from "@/components/users/AvatarPhoto.vue";
import DataField from "@/components/crowdsourcing/DataField.vue";
import ContactInfo from "@/components/crowdsourcing/ContactInfo.vue";
import ContentNotes from "@/components/crowdsourcing/ContentNotes.vue";
import ContactAutocomplete from "@/components/crowdsourcing/ContactAutocomplete.vue";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { useProveForValueStore } from "@/stores/proveForValue";
import { computed } from "vue";
import { storeToRefs } from "pinia";
import _ from "lodash";

const props = defineProps([
  "dataField",
  "contact",
  "user",
  "inputKey",
  "investmentGroupId",
  "availabilityGroupId",
  "parentComponentSaveFunction",
]);
const emit = defineEmits(["open-sourced", "completed", "unlocked"]);

const layoutStore = useWorkspaceLayoutStore();
const { workspaceLayout } = storeToRefs(layoutStore);
const proveForValueStore = useProveForValueStore();

const safezone = computed(() => _.get(props.dataField, "safezone"));
const platformState = computed(() => {
  return _.get(props.dataField, "state", null);
});
const unstaked = computed(() => platformState.value === "unstaked");
const priced = computed(() => {
  const rawPrice = _.get(props.dataField, "price");
  const price = rawPrice ? _.toNumber(rawPrice) : null;
  return price && price > 0;
});
const unlockable = computed(() => {
  if (!safezone.value) return unstaked.value || priced.value;
  else return priced.value;
});
const person = computed(() => {
  return _.get(props.dataField, "fieldContent") || props.contact || props.user;
});
const testLabel = computed(() => {
  return (
    person.value?.name || `${person.value?.recordType} ${person.value?.id}`
  );
});
const dynamicColor = computed(() => {
  if (props.user || props.contact || unlockable.value) {
    return "ring-gray-900";
  } else if (safezone.value) {
    return "ring-yellow-500";
  } else {
    return "ring-indigo-700";
  }
});
const availableProps = computed(() => {
  return [
    {
      fieldName: "ContactCompanyInvolvement",
      investmentGroupId: props.investmentGroupId,
      availabilityGroupId: props.availabilityGroupId,
      isInnerField: false,
      removeInline: true,
      focus: true,
      inputKey: props.inputKey,
      parentComponentSaveFunction: props.parentComponentSaveFunction,
    },
  ];
});
function openSourced() {
  emit("open-sourced");
}
function completed(maybePayload) {
  console.log("contact completed", maybePayload);
  emit("completed", maybePayload);
}
function unlocked(maybePayload) {
  console.log("contact unlocked", maybePayload);
  emit("unlocked", maybePayload);
}

function setProof(fieldName) {
  const matchedProps = _.find(availableProps.value, { fieldName });

  if (matchedProps) {
    proveForValueStore.setProofComponent(ContactAutocomplete, matchedProps);
  }
}
</script>
