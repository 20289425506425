<template>
  <div class="overflow-hidden rounded-lg p-3 shadow bg-gray-100">
    <dt class="truncate text-sm font-medium text-gray-500">$ Volume</dt>
    <div v-if="fetchingData || refreshingMainTimeline" class="mt-1 w-full flex">
      <SquareLoader :loading="true" size="24px" color="#9ca3af" />
    </div>
    <dd v-else class="mt-1 text-xl font-semibold tracking-tight text-gray-900">
      <DataField
        calculation-name="investment_volume"
        :calculation-option-data="calculationOptions"
        :intercept-calculation="interceptCalculation"
        :fully-licensed="fullyLicensed"
        :calculation-value="outputData?.totalValue"
        :calculation-content-type="calculationContentType"
        :calculation-content-ids="calculationContentIds"
        :calculation-is-survey="true"
        :analyze="true"
        text-classes="text-xl font-semibold tracking-tight"
      />
    </dd>
    <CalculationWarning
      v-if="hasOutputs && outputData?.warnings.length > 0"
      :warning-data="outputData?.warnings"
      class="mt-1"
    />
  </div>
</template>

<script setup>
import SquareLoader from "vue-spinner/src/SquareLoader.vue";
import DataField from "@/components/crowdsourcing/DataField.vue";
import CalculationWarning from "@/components/analyze/calculations/CalculationWarning.vue";
import { investmentVolumeOutputs } from "@/components/analyze/calculations/investmentVolume";
import { useAnalyzePanelStore } from "@/stores/analyzePanel";
import { useTimeTravelStore } from "@/stores/timeTravel";
import { storeToRefs } from "pinia";
import { computed, onMounted, ref, watch } from "vue";
import _ from "lodash";

const { refreshingMainTimeline, datedInvestmentIds } = storeToRefs(
  useTimeTravelStore(),
);
const analyzePanelStore = useAnalyzePanelStore();
const {
  calculationData,
  calculationOptions,
  latLngMismatch,
  timeMismatch,
  zoomMismatch,
  combinedFilteredInvestmentIds,
  valueFilterApplied,
  investmentVolumeSurveyFetched,
} = storeToRefs(analyzePanelStore);

const fetchingData = ref(false);
const dataEmptyState = {
  investmentVolume: {
    fetchRequests: [],
    interceptableFields: [],
    unlicensedFields: [],
    investmentDataFields: [],
    investmentGroupDataFields: [],
    investmentGroupValuationDataFields: [],
    investmentValuationDataFields: [],
    valueDataFields: [],
    loanAmountDataFields: [],
  },
};

const scopedCalculationData = computed(() => {
  if (investmentVolumeSurveyFetched.value) {
    return calculationData.value;
  } else {
    return dataEmptyState;
  }
});
const calculationContentType = computed(() => {
  return "Investment";
});
const calculationContentIds = computed(() => {
  return _.intersection(
    datedInvestmentIds.value,
    combinedFilteredInvestmentIds.value,
  );
});
const interceptCalculation = computed(() => {
  return (
    scopedCalculationData.value.investmentVolume?.interceptableFields?.length >
    0
  );
});
const fullyLicensed = computed(() => {
  return (
    scopedCalculationData.value.investmentVolume?.unlicensedFields?.length === 0
  );
});
const hasOutputs = computed(() => {
  return _.some(
    scopedCalculationData.value.investmentVolume,
    function (fields) {
      return !_.isEmpty(fields);
    },
  );
});
const outputData = computed(() => {
  if (hasOutputs.value && fullyLicensed.value && !interceptCalculation.value) {
    return investmentVolumeOutputs(
      scopedCalculationData.value,
      calculationOptions.value,
      analyzePanelStore,
    );
  } else {
    return null;
  }
});

watch(latLngMismatch, (val) => {
  if (val) refetchData();
});

watch(timeMismatch, (val) => {
  if (val) refetchData();
});

watch(zoomMismatch, async (val) => {
  if (val) refetchData();
});
watch(refreshingMainTimeline, async (val) => {
  if (val) refetchData();
});

watch(valueFilterApplied, async () => {
  refetchData();
});

watch(
  combinedFilteredInvestmentIds,
  () => {
    debouncedrefetchData();
  },
  { deep: true },
);

onMounted(() => {
  debouncedrefetchData();
});

const debouncedrefetchData = _.debounce(function () {
  refetchData();
}, 1000);

function refetchData() {
  fetchingData.value = true;

  calculationData.value.investmentVolume = {
    fetchRequests: [],
    interceptableFields: [],
    unlicensedFields: [],
    investmentDataFields: [],
    investmentGroupDataFields: [],
    investmentGroupValuationDataFields: [],
    investmentValuationDataFields: [],
    valueDataFields: [],
    loanAmountDataFields: [],
  };

  setTimeout(() => {
    fetchingData.value = false;
  }, 500);
}
</script>
