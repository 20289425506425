<template>
  <button
    @click="easyButtonClickHandler"
    type="button"
    class="inline-flex items-center gap-x-2 rounded-md bg-amber-500 px-3.5 py-2.5 text-sm font-semibold text-gray-700 shadow-md hover:bg-amber-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-amber-500"
  >
    <BoltIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
    <template v-if="isDesktop">Add Data (Earn $)</template>
    <template v-else>Add Data</template>
  </button>
</template>

<script setup>
import { BoltIcon } from "@heroicons/vue/20/solid";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { useSecondaryPanelStore } from "@/stores/secondaryPanel";
import { useUserStore } from "@/stores/user";
import { storeToRefs } from "pinia";
import { useRouter, useRoute } from "vue-router";
import _ from "lodash";

const layoutStore = useWorkspaceLayoutStore();
const { isDesktop } = storeToRefs(layoutStore);
const secondaryPanelStore = useSecondaryPanelStore();
const userStore = useUserStore();
const { emailVerified, emailUnverified } = storeToRefs(userStore);

const router = useRouter();
const route = useRoute();

function easyButtonClickHandler() {
  if (emailVerified.value) {
    router.push({ name: "DataEasyButton" });
  } else if (emailUnverified.value) {
    userStore.promptToVerify();
  } else {
    register();
  }
}

function register() {
  if (
    isDesktop.value &&
    !_.includes(
      [
        "HelpCenter",
        "HelpCategories",
        "HelpArticle",
        "WhyTowerHunt",
        "TermsOfService",
        "PrivacyPolicy",
        "LegalCenter",
        "LegalShell",
      ],
      route.name,
    )
  ) {
    secondaryPanelStore.setAuthenticate({ name: "Register" });
    router.push({
      name: route.name,
      query: {
        ...route.query,
        horizontalTab: "Authenticate",
        verticalTab: "Register",
      },
    });
  } else {
    router.push({ name: "Register" });
  }
}
</script>
