<template>
  <VDropdown placement="left-start">
    <slot name="button">
      <a
        href=""
        @click.prevent
        class="flex items-center text-gray-700 p-2 text-xs hover:bg-gray-100 hover:text-gray-900"
        role="menuitem"
        tabindex="-1"
        :data-test="`citations-menu-button`"
      >
        <BookOpenIcon
          class="mr-2 h-4 w-4 text-gray-400 group-hover:text-gray-500"
        />
        {{ pluralize("Citation", citations.length, true) }}</a
      >
    </slot>

    <template #popper>
      <div
        class="w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none"
        role="menu"
        aria-orientation="vertical"
        tabindex="-1"
      >
        <div
          class="py-1 max-h-64 overflow-y-auto divide-y divide-gray-300"
          role="none"
        >
          <CitationForm
            class="flex items-center"
            :data-field="dataField"
            @refetch="fetchCitations"
          >
            <template v-slot:button>
              <button
                type="button"
                class="w-full flex items-center space-x-2 text-gray-700 p-2 text-xs hover:bg-gray-200 hover:text-gray-900"
                role="menuitem"
                tabindex="-1"
                :data-test="`create-citation-button`"
              >
                <PlusCircleIcon
                  class="h-4 w-4 text-gray-400 group-hover:text-gray-500"
                />
                <span>Add citation</span>
              </button>
            </template>
          </CitationForm>
          <CitationDetails
            v-for="(citationField, index) in citations"
            class="w-full"
            :key="citationField.localId"
            :citation-field="citationField"
            :citation-scope="'visible'"
            :removable="true"
            :compact="true"
            role="menuitem"
            tabindex="-1"
            :data-test="`citation-${index}`"
            @refetch="emit('refetch')"
          />
        </div>
      </div>
    </template>
  </VDropdown>
</template>

<script setup>
import CitationDetails from "@/components/crowdsourcing/CitationDetails.vue";
import CitationForm from "@/components/crowdsourcing/CitationForm.vue";
import { ref, onMounted } from "vue";
import pluralize from "pluralize";
import api from "@/router/api";
import { BookOpenIcon, PlusCircleIcon } from "@heroicons/vue/20/solid";

const props = defineProps(["dataField"]);
const emit = defineEmits(["refetch"]);

const citations = ref([]);

onMounted(() => {
  fetchCitations();
});

async function fetchCitations() {
  citations.value = [];
  api
    .get(`data_field_citations/${props.dataField.localId}?scope=visible`)
    .then((json) => {
      citations.value = json.data;
    });
}
</script>
