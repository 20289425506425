<template>
  <VDropdown v-if="hasOptions">
    <button
      class="w-8 h-8 bg-white inline-flex items-center justify-center text-gray-400 rounded-full bg-transparent hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      :data-test="`member-${member.memberId || invitation.id}-menu-button`"
    >
      <span class="sr-only">Open options</span>
      <!-- Heroicon name: solid/dots-vertical -->
      <svg
        class="w-5 h-5"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        aria-hidden="true"
      >
        <path
          d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z"
        />
      </svg>
    </button>

    <template #popper>
      <div
        class="w-52 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none"
        role="menu"
        aria-orientation="vertical"
        tabindex="-1"
      >
        <div v-if="member" class="py-1" role="none">
          <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" -->
          <VDropdown v-if="isTeamAdmin" placement="left-start">
            <a
              href=""
              @click.prevent
              class="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-200 hover:text-gray-900"
              role="menuitem"
              tabindex="-1"
              :data-test="`change-member-${member.memberId}-role-button`"
              >Change role</a
            >

            <template #popper>
              <div
                class="w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none"
                role="menu"
                aria-orientation="vertical"
                tabindex="-1"
              >
                <div class="py-1" role="none">
                  <a
                    v-for="(teamRole, index) in teamRoles"
                    :key="teamRole.value"
                    href=""
                    @click.prevent="changeMemberRole(teamRole)"
                    v-close-popper
                    class="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-200 hover:text-gray-900"
                    role="menuitem"
                    tabindex="-1"
                    :data-test="`member-${member.memberId}-role-${index}-button`"
                    >{{ teamRole.label }}</a
                  >
                </div>
              </div>
            </template>
          </VDropdown>
          <VDropdown v-if="handlesPayments" placement="left-start">
            <a
              href=""
              @click.prevent="fetchPaymentMethods"
              class="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-200 hover:text-gray-900"
              role="menuitem"
              tabindex="-1"
              :data-test="`add-member-${member.memberId}-payment-method-button`"
              >Connect payment method</a
            >

            <template #popper>
              <div
                class="w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none"
                role="menu"
                aria-orientation="vertical"
                tabindex="-1"
              >
                <div class="py-1" role="none">
                  <a
                    v-for="(record, index) in paymentMethods"
                    :key="index"
                    href=""
                    @click.prevent="payWith(record)"
                    v-close-popper
                    class="flex items-center space-x-2 text-gray-700 px-4 py-2 text-sm hover:bg-gray-200 hover:text-gray-900"
                    role="menuitem"
                    tabindex="-1"
                    :data-test="`member-${member.memberId}-payment-method-${index}-button`"
                  >
                    <svg
                      v-if="unknownCard(record.card.brand)"
                      class="flex-shrink-0 h-4 w-auto text-gray-400"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <title>{{ record.card.brand }}</title>
                      <path d="M4 4a2 2 0 00-2 2v1h16V6a2 2 0 00-2-2H4z" />
                      <path
                        fill-rule="evenodd"
                        d="M18 9H2v5a2 2 0 002 2h12a2 2 0 002-2V9zM4 13a1 1 0 011-1h1a1 1 0 110 2H5a1 1 0 01-1-1zm5-1a1 1 0 100 2h1a1 1 0 100-2H9z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    <img
                      v-else
                      class="flex-shrink-0 h-4 w-auto text-gray-400"
                      :src="`https://assets.towerhunt.com/site/${brandLogoFor(
                        record.card.brand
                      )}0.png`"
                      :alt="record.card.brand"
                    />
                    <span>x{{ record.card.last4 }}</span></a
                  >
                  <a
                    v-if="paymentMethodsLoaded && paymentMethods.length === 0"
                    href=""
                    @click.prevent="goToSettings"
                    v-close-popper
                    class="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-200 hover:text-gray-900"
                    role="menuitem"
                    tabindex="-1"
                    >Add a payment method...</a
                  >
                </div>
              </div>
            </template>
          </VDropdown>
          <VDropdown v-if="handlesPayments" placement="left-start">
            <a
              href=""
              @click.prevent="fetchPayoutAccounts"
              class="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-200 hover:text-gray-900"
              role="menuitem"
              tabindex="-1"
              :data-test="`add-member-${member.memberId}-payout-account-button`"
              >Connect payout account</a
            >

            <template #popper>
              <div
                class="w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none"
                role="menu"
                aria-orientation="vertical"
                tabindex="-1"
              >
                <div class="py-1" role="none">
                  <a
                    v-for="(account, index) in sharedPayoutAccounts"
                    :key="account.id"
                    href=""
                    @click.prevent="payTo(account)"
                    v-close-popper
                    class="flex items-center space-x-2 text-gray-700 px-4 py-2 text-sm hover:bg-gray-200 hover:text-gray-900"
                    role="menuitem"
                    tabindex="-1"
                    :data-test="`member-${member.memberId}-payout-account-${index}-button`"
                  >
                    <span
                      v-tooltip="_.capitalize(account.category)"
                      class="flex-shrink-0 inline-flex items-center justify-center h-6 w-6 rounded-full bg-green-400"
                      :data-test="`${account.category}-shared-payout-account-icon`"
                    >
                      <BuildingOfficeIcon
                        v-if="account.category === 'building'"
                        class="h-4 w-4 text-white"
                      />
                      <UserGroupIcon
                        v-else-if="account.category === 'team'"
                        class="h-4 w-4 text-white"
                      />
                      <BriefcaseIcon
                        v-else-if="account.category === 'company'"
                        class="h-4 w-4 text-white"
                      />
                    </span>
                    <span>{{
                      account.name ||
                      `${_.capitalize(account.category)} Account ${account.id}`
                    }}</span>
                  </a>
                  <a
                    v-if="
                      payoutAccountsLoaded && sharedPayoutAccounts.length === 0
                    "
                    href=""
                    @click.prevent="goToSettings"
                    v-close-popper
                    class="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-200 hover:text-gray-900"
                    role="menuitem"
                    tabindex="-1"
                    >Add an account...</a
                  >
                </div>
              </div>
            </template>
          </VDropdown>
          <a
            v-if="canRemove"
            href=""
            @click.prevent="removeMember"
            v-close-popper
            class="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-200 hover:text-gray-900"
            role="menuitem"
            tabindex="-1"
            :data-test="`remove-member-${member.memberId}-button`"
            >Remove</a
          >
        </div>
        <template v-else-if="invitation">
          <div class="py-1" role="none">
            <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" -->
            <a
              href=""
              @click.prevent="resendInvitation"
              v-close-popper
              class="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-200 hover:text-gray-900"
              role="menuitem"
              tabindex="-1"
              :data-test="`resend-invitation-${invitation.id}-button`"
              >Resend</a
            >
          </div>
          <div class="py-1" role="none">
            <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" -->
            <a
              href=""
              @click.prevent="revokeInvitation"
              v-close-popper
              class="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-200 hover:text-gray-900"
              role="menuitem"
              tabindex="-1"
              :data-test="`revoke-invitation-${invitation.id}-button`"
              >Revoke</a
            >
          </div>
        </template>
      </div>
    </template>
  </VDropdown>
</template>

<script setup>
import {
  BriefcaseIcon,
  BuildingOfficeIcon,
  UserGroupIcon,
} from "@heroicons/vue/20/solid";
import api from "@/router/api";
import { ref, computed } from "vue";
import { useUserStore } from "@/stores/user";
import _ from "lodash";
import { storeToRefs } from "pinia";
import { useRouter, useRoute } from "vue-router";

const props = defineProps(["invitation", "member", "role"]);
const emit = defineEmits([
  "refetch-members",
  "refetch-invitations",
  "refetch-team",
  "stop-editing",
]);
const userStore = useUserStore();
const { currentUser } = storeToRefs(userStore);
const teamRoles = computed(() => {
  const roles = [
    { value: "primary_owner", label: "Primary owner" },
    { value: "billing_member", label: "Billing member" },
    { value: "full_member", label: "Full member" },
  ];

  return roles.filter((teamRole) => teamRole.label !== props.member.teamRole);
});
const paymentMethods = ref([]);
const paymentMethodsLoaded = ref(false);
const sharedPayoutAccounts = ref([]);
const payoutAccountsLoaded = ref(false);
const hasOptions = computed(() => isTeamAdmin.value || handlesPayments.value);
const isManager = computed(() =>
  _.includes(["Primary owner", "Billing member"], props.member.teamRole)
);
const isTeamAdmin = computed(() => props.role === "Primary owner");
const handlesPayments = computed(() => isSelf.value && isManager.value);
const isSelf = computed(
  () => _.get(props.member, "userId") == currentUser.value.id
);

const canRemove = computed(
  () => isTeamAdmin.value && _.get(props.member, "canLeave", false)
);
function trimmedLower(str) {
  return str.toLowerCase().replace(/\s/g, "");
}
function unknownCard(brand) {
  return !_.includes(
    ["americanexpress", "discover", "mastercard", "visa"],
    trimmedLower(brand)
  );
}
function brandLogoFor(brand) {
  return trimmedLower(brand);
}
async function fetchPaymentMethods() {
  if (paymentMethods.value.length === 0) {
    api.get(`my_payment_methods`).then((json) => {
      paymentMethods.value = json.data.cards;
      paymentMethodsLoaded.value = true;
    });
  }
}

function payWith(cardRecord) {
  const payload = {
    teamMembershipId: props.member.memberId,
    cardToken: cardRecord.id,
    customerToken: cardRecord.customer,
  };
  api.post(`team_payees`, payload).then(() => {
    emit("refetch-members");
    emit("refetch-team");
  });
}

async function fetchPayoutAccounts() {
  if (sharedPayoutAccounts.value.length === 0) {
    api.get(`shared_payout_accounts`).then((json) => {
      const accounts = json.data.filter((account) => account.chargesEnabled);
      sharedPayoutAccounts.value = accounts;
      payoutAccountsLoaded.value = true;
    });
  }
}

function payTo(account) {
  const payload = {
    teamMembershipId: props.member.memberId,
    accountId: account.id,
  };
  api.post(`team_shared_payout_accounts`, payload).then(() => {
    emit("refetch-members");
  });
}

const router = useRouter();
const route = useRoute();

function goToSettings() {
  router.push({
    name: "MainMap",
    query: {
      ...route.query,
      horizontalTab: "Account",
      verticalTab: "Settings",
    },
  });
}

function removeMember() {
  const endpoint = `teams/${props.member.teamId}/team_memberships/${props.member.memberId}`;

  api.delete(endpoint).then(() => {
    // this.$store.dispatch("flash", `${props.member.name} removed successfully.`);
    if (isSelf.value) {
      emit("stop-editing");
    } else {
      emit("refetch-members");
    }
  });
}
function resendInvitation() {
  api.post(`team_invitations/${props.invitation.token}`).then(() => {
    // this.$store.dispatch("flash", `Invitation resent`);
  });
}
function revokeInvitation() {
  api.delete(`team_invitations/${props.invitation.token}`).then(() => {
    // this.$store.dispatch("flash", `Invitation revoked`);
    emit("refetch-invitations");
  });
}

function changeMemberRole(newRole) {
  const payload = { teamRole: newRole.value };

  api
    .patch(`team_membership_roles/${props.member.memberId}`, payload)
    .then(() => {
      // this.$store.dispatch("flash", `Invitation resent`);
      emit("refetch-members");
    });
}
</script>
