<template>
  <div :class="workspaceLayout === 'topAndBottom' ? 'flex-1' : ''">
    <form v-if="creating" @submit.prevent>
      <div
        class="flex items-center justify-between"
        aria-orientation="horizontal"
        role="tablist"
      >
        <label for="space-availability" class="sr-only"
          >New space usage/availability</label
        >

        <div class="flex items-center space-x-5">
          <div class="flex items-center">
            <button
              @click="cancel"
              type="button"
              class="-m-2.5 w-10 h-10 rounded-full inline-flex items-center justify-center text-gray-400 hover:text-gray-500"
            >
              <span class="sr-only">Cancel</span>
              <XMarkIcon class="h-5 w-5" />
            </button>
          </div>
        </div>
      </div>

      <div
        class="mt-1 border border-gray-300 rounded-lg shadow-sm focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500"
      >
        <CompanyContactAutocomplete
          :input-key="combinedKey"
          label="Space users"
          :companies="companies"
          @new-companies="setNewCompanies"
          @remove-company="removeCompany"
          @set-cross-interaction="setCrossInteraction"
        />

        <div class="pl-3 pr-2 py-2 flex justify-between">
          <div class="flex flex-wrap items-center space-x-2 mr-2">
            <DateStateField
              event-type="spaceAvailability"
              :data-field="dataField"
              :state="state"
              :date="date"
              :compact="true"
              @new-date="setNewDate"
              @new-state="setNewState"
              @set-cross-interaction="setCrossInteraction"
            />

            <DateStateField
              v-if="state === 'closed'"
              event-type="spaceAvailabilityCommenced"
              :data-field="dataField"
              :state="state"
              :date="commencedDate"
              :compact="true"
              @new-date="(newDate) => (commencedDate = newDate)"
              @new-state="setNewState"
              @set-cross-interaction="setCrossInteraction"
            />

            <DateStateField
              v-if="state === 'closed'"
              event-type="spaceAvailabilityExpired"
              :data-field="dataField"
              :state="state"
              :date="subleaseExpirationDate || expiredDate"
              :commenced-date="commencedDate"
              :upstream-space-usage-id="upstreamSpaceUsageId"
              :compact="true"
              @new-date="(newDate) => (expiredDate = newDate)"
              @use-sublease-expiration-date="
                (newDate) => (subleaseExpirationDate = newDate)
              "
              @new-state="setNewState"
              @set-cross-interaction="setCrossInteraction"
            />

            <div v-if="dateLabel" class="rounded-md bg-teal-100 p-2">
              <div class="flex">
                <div class="flex-shrink-0">
                  <InformationCircleIcon
                    class="h-4 w-4 text-teal-600"
                    aria-hidden="true"
                  />
                </div>
                <div class="ml-2 flex-1 flex">
                  <p class="text-xs text-teal-700">{{ dateLabel }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-shrink-0 items-end">
            <DataVisibilityButton
              :tooltip="
                canPersist ? null : 'Company or date/status required to save'
              "
              :visibility="canPersist ? 'safezone' : 'none'"
              class="inline-flex"
            >
              <template v-slot:button>
                <button
                  @click="persist"
                  :disabled="originatingData || !canPersist"
                  type="button"
                  :data-test="`${combinedKey}-save-capital-event-button`"
                  :class="
                    canPersist
                      ? 'bg-yellow-500 hover:bg-yellow-600 focus:ring-yellow-600'
                      : 'bg-gray-300 hover:bg-gray-400 focus:ring-gray-500'
                  "
                  class="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2"
                >
                  <PulseLoader
                    v-if="originatingData"
                    :loading="true"
                    size="3px"
                    color="#f3f4f6"
                  />
                  <span v-else>Save</span>
                </button>
              </template>
            </DataVisibilityButton>
          </div>
        </div>
      </div>
    </form>

    <div v-else class="flex flex-col space-y-2">
      <DataVisibilityButton visibility="safezone">
        <template v-slot:button>
          <button
            @click="beginCreating"
            type="button"
            :data-test="`${combinedKey}-add-player-button`"
            class="group p-1.5 w-full flex items-center justify-between rounded-full border border-gray-300 shadow-sm space-x-2 text-left hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <span class="min-w-0 flex-1 flex items-center space-x-1">
              <span class="flex-shrink-0 flex items-center">
                <span
                  class="inline-flex items-center justify-center h-6 w-6 rounded-full bg-yellow-500"
                >
                  <PencilIcon class="h-4 w-4 text-white" />
                </span>
              </span>
              <span class="min-w-0 flex-1">
                <span class="text-sm font-medium text-gray-900 truncate"
                  >Add {{ actorLabel }}</span
                >
              </span>
            </span>
            <span
              class="flex-shrink-0 h-7 w-7 inline-flex items-center justify-center"
            >
              <PlusIcon
                class="h-5 w-5 text-gray-400 group-hover:text-gray-500"
              />
            </span>
          </button>
        </template>
      </DataVisibilityButton>
    </div>
  </div>
</template>

<script setup>
import {
  PlusIcon,
  XMarkIcon,
  InformationCircleIcon,
} from "@heroicons/vue/20/solid";
import { PencilIcon } from "@heroicons/vue/24/outline";
import CompanyContactAutocomplete from "@/components/crowdsourcing/CompanyContactAutocomplete.vue";
import DateStateField from "@/components/crowdsourcing/DateStateField.vue";
import DataVisibilityButton from "@/components/crowdsourcing/DataVisibilityButton.vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { useCrowdsourcedChangeGroupStore } from "@/stores/crowdsourcedChangeGroup";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { useSpaceUsageBuilderStore } from "@/stores/spaceUsageBuilder";
import { storeToRefs } from "pinia";
import { ref, computed, onMounted, nextTick } from "vue";
import api from "@/router/api";
import moment from "moment";
import decoratingAndFieldKey from "@/components/crowdsourcing/decoratingAndFieldKey";
import leaseTermEditingLabel from "@/assets/leaseTermEditingLabel";
import _ from "lodash";
import { useRoute } from "vue-router";

const props = defineProps([
  "propertyId",
  "topLevelSpaceDataField",
  "dataField",
  "upstreamSpaceUsageId",
  "future",
  "depth",
  "preactivate",
  "insertDirection",
  "portfolioToAdd",
]);
const emit = defineEmits(["cancel", "refetch-layer"]);

const layoutStore = useWorkspaceLayoutStore();
const { workspaceLayout } = storeToRefs(layoutStore);
const spaceUsageBuilderStore = useSpaceUsageBuilderStore();
const { spaceUsageBuilder, crossInteraction } = storeToRefs(
  spaceUsageBuilderStore,
);
const changeGroupStore = useCrowdsourcedChangeGroupStore();
const { originatingData, changeGroupId } = storeToRefs(changeGroupStore);

const creating = ref(false);
const localCompanies = ref([]);
const selectedDateMenuOption = ref(null);
const localDate = ref(null);
const localCommencedDate = ref(null);
const localExpiredDate = ref(null);
const localState = ref(null);
const subleaseExpirationDate = ref(null);

const before = computed(() => {
  return !props.future;
});
const route = useRoute();
const query = computed(() => route.query);
const horizontalIsSpaces = computed(
  () => _.get(query.value, "horizontalTab") === "Spaces",
);

const companies = computed({
  get() {
    if (editingFuture.value) {
      return futureDeal.value.spaceUsers;
    } else {
      return localCompanies.value;
    }
  },
  set(newVal) {
    if (editingFuture.value) {
      let newAvailability = _.merge({}, futureDeal.value);

      newAvailability.spaceUsers = newVal;
      updateStoreAvailability(newAvailability);
    } else {
      localCompanies.value = newVal;
    }
  },
});

const date = computed({
  get() {
    if (editingFuture.value) {
      return futureDeal.value.date;
    } else {
      return localDate.value;
    }
  },
  set(newVal) {
    if (editingFuture.value) {
      let newAvailability = _.merge({}, futureDeal.value);

      newAvailability.date = newVal;
      updateStoreAvailability(newAvailability);
    } else {
      localDate.value = newVal;
    }
  },
});

const commencedDate = computed({
  get() {
    if (editingFuture.value) {
      return futureDeal.value.commencedDate;
    } else {
      return localCommencedDate.value;
    }
  },
  set(newVal) {
    if (editingFuture.value) {
      let newAvailability = _.merge({}, futureDeal.value);

      newAvailability.commencedDate = newVal;
      updateStoreAvailability(newAvailability);
    } else {
      localCommencedDate.value = newVal;
    }
  },
});

const expiredDate = computed({
  get() {
    if (editingFuture.value) {
      return futureDeal.value.expiredDate;
    } else {
      return localExpiredDate.value;
    }
  },
  set(newVal) {
    if (editingFuture.value) {
      let newAvailability = _.merge({}, futureDeal.value);

      newAvailability.expiredDate = newVal;
      updateStoreAvailability(newAvailability);
    } else {
      localExpiredDate.value = newVal;
    }
  },
});

const state = computed({
  get() {
    if (editingFuture.value) {
      return futureDeal.value.state;
    } else {
      return localState.value;
    }
  },
  set(newVal) {
    if (editingFuture.value) {
      let newAvailability = _.merge({}, futureDeal.value);

      newAvailability.state = newVal;
      updateStoreAvailability(newAvailability);
    } else {
      localState.value = newVal;
    }
  },
});

const matchingAvailabilityGroup = computed(() => {
  return _.find(
    _.get(spaceUsageBuilder.value, "availabilityGroups", []),
    findMatchingGroupAvailability,
  );
});
const futureDeal = computed(() => {
  if (matchingAvailabilityGroup.value) {
    return findMatchingGroupAvailability(matchingAvailabilityGroup.value);
  } else {
    return null;
  }
});

function findMatchingGroupAvailability(group) {
  const spaceKey = decoratingAndFieldKey(props.dataField);
  const topLevelKey = decoratingAndFieldKey(props.topLevelSpaceDataField);

  return _.find(group.availabilities, function (availability) {
    if (availability.topLevelSpace) {
      const spaceMatch =
        spaceKey === decoratingAndFieldKey(availability.topLevelSpace);
      const topLevelMatch =
        topLevelKey === decoratingAndFieldKey(availability.topLevelSpace);

      return spaceMatch || topLevelMatch;
    } else {
      return false;
    }
  });
}

const dateLabel = computed(() => {
  return leaseTermEditingLabel(
    state.value,
    date.value,
    commencedDate.value,
    expiredDate.value,
    subleaseExpirationDate.value,
    validContractDates.value,
  );
});
const editingFuture = computed(() => {
  return props.future && futureDeal.value;
});
const spaceKey = computed(() => {
  return decoratingAndFieldKey(props.dataField);
});
const combinedKey = computed(() => {
  if (editingFuture.value) {
    const spaceKey = decoratingAndFieldKey(futureDeal.value.topLevelSpace);
    const availabilityKey = decoratingAndFieldKey(
      futureDeal.value.availabilityFieldContent,
    );

    return `${spaceKey}_${availabilityKey}`;
  } else if (props.future) {
    const spaceKey = decoratingAndFieldKey(props.topLevelSpaceDataField);
    const availabilityKey = decoratingAndFieldKey(props.dataField);

    return `${spaceKey}_${availabilityKey}`;
  } else {
    return spaceKey.value;
  }
});
const contentId = computed(() => {
  return props.dataField.fieldContentId;
});
const contentType = computed(() => {
  return props.dataField.fieldContentType;
});
const actorLabel = computed(() => {
  return "space user or availability";
});
const hasCompanies = computed(() => persistPayload.value.companies.length > 0);
const hasState = computed(() => !!persistPayload.value.state);
const validContractDates = computed(() => {
  if (commencedDate.value && expiredDate.value) {
    return moment(expiredDate.value).isAfter(commencedDate.value);
  } else {
    return (
      commencedDate.value ||
      expiredDate.value ||
      (!commencedDate.value && !expiredDate.value)
    );
  }
});
const canPersist = computed(() => {
  return (hasCompanies.value || hasState.value) && validContractDates.value;
});
const directDepth = computed(() => {
  if (props.insertDirection?.direction === "superior") {
    return 7;
  } else {
    return 6;
  }
});
const beforeSideBuilderSublease = computed(() => {
  return (
    horizontalIsSpaces.value &&
    before.value &&
    props.depth !== directDepth.value
  );
});
const dataFieldBeingSubordinated = computed(() => {
  const shouldProvide =
    props.insertDirection?.direction === "superior" &&
    props.depth !== directDepth.value;

  if (shouldProvide) {
    return _.get(props.dataField, "localId");
  } else {
    return null;
  }
});
const persistPayload = computed(() => {
  let upstreamSpaceUsageId = null;

  if (props.depth < directDepth.value) {
    if (props.future) {
      upstreamSpaceUsageId = _.get(
        futureDeal.value,
        "upstreamSpaceUsageId",
        null,
      );
    } else if (props.upstreamSpaceUsageId) {
      upstreamSpaceUsageId = props.upstreamSpaceUsageId;
    }
  }

  return {
    contentId: contentId.value,
    contentType: contentType.value,
    upstreamSpaceUsageId,
    existingDataFieldId: dataFieldBeingSubordinated.value,
    companies: companies.value,
    advisors: _.get(futureDeal.value, "advisors", []),
    date: date.value,
    commencedDate: commencedDate.value,
    expiredDate: expiredDate.value,
    state: state.value,
    changeGroupId: changeGroupId.value,
  };
});

onMounted(() => {
  if (props.preactivate || editingFuture.value) {
    beginCreating();

    if (beforeSideBuilderSublease.value) {
      sendToSpaceUsageBuilder();
    }
  }
});

function sendToSpaceUsageBuilder(newState = null) {
  console.log("send to builder?");
  let stubbed = stubbedAvailability(newState);
  const addablePortfolio =
    props.portfolioToAdd || props.insertDirection?.availabilityGroup;
  if (addablePortfolio && addablePortfolio !== "new") {
    spaceUsageBuilderStore.addAvailabilityToGroup({
      existingAvailability: null,
      stubbedAvailability: stubbed,
      spaceDataField: props.dataField,
      availabilityFieldContent: props.dataField,
      availabilityGroupPlaceholderId: addablePortfolio.placeholderId,
    });
    spaceUsageBuilderStore.collapsePortfolioSpaces({
      groupId: addablePortfolio.id || addablePortfolio.placeholderId,
    });
    spaceUsageBuilderStore.setAvailabilityGroupExpanded({
      groupId: addablePortfolio.id || addablePortfolio.placeholderId,
      expanded: true,
    });
  } else {
    spaceUsageBuilderStore.prepopulateBuilder({
      stubbedAvailability: stubbed,
      topLevelSpaceDataField: props.topLevelSpaceDataField,
      decoratingDataField: props.dataField,
    });
  }

  setTimeout(() => {
    cancel();
  }, 50);
}
function stubbedAvailability(newState = null) {
  return {
    spaceUsers: companies.value,
    advisors: [],
    date: date.value,
    state: newState,
    insertDirection: props.insertDirection?.direction,
    upstreamDataField: props.dataField,
    upstreamSpaceUsageId: props.upstreamSpaceUsageId,
    topLevelSpace: props.topLevelSpaceDataField,
    depth: "new",
  };
}

function removeCompany(company) {
  if (editingFuture.value) {
    const groupId = _.get(matchingAvailabilityGroup.value, "placeholderId");

    spaceUsageBuilderStore.removeAvailabilityGroupPlayer({
      groupId,
      availabilityCombinedKey: combinedKey.value,
      toRemove: company,
      path: "spaceUsers",
    });
  } else {
    const filteredCompanies = _.difference(companies.value, [company]);

    setNewCompanies(filteredCompanies);
  }
}
function updateStoreAvailability(newAvailability) {
  const groupId = _.get(matchingAvailabilityGroup.value, "placeholderId");

  spaceUsageBuilderStore.updateAvailabilityGroupAvailability({
    groupId,
    newAvailability,
  });
}

function setNewCompanies(newCompanies) {
  companies.value = newCompanies;
}
function setNewDate(newDate) {
  date.value = newDate;
}
function setNewState(newState) {
  if (
    !editingFuture.value &&
    _.includes(["live", "under_contract"], newState)
  ) {
    sendToSpaceUsageBuilder(newState);
  } else {
    state.value = newState;
  }
}

function beginCreating() {
  creating.value = true;
}
function cancel() {
  creating.value = false;
  companies.value = [];
  date.value = null;
  subleaseExpirationDate.value = null;
  state.value = null;
  selectedDateMenuOption.value = null;
  crossInteraction.value = null;

  removeAvailability();
  emit("cancel");
}
function removeAvailability() {
  spaceUsageBuilderStore.removeSpaceAvailability({
    availabilityKey: combinedKey.value,
  });
}

function setCrossInteraction({ focus }) {
  if (editingFuture.value) {
    const newCrossInteraction = {
      spaceKey: decoratingAndFieldKey(futureDeal.value.topLevelSpace),
      combinedKey: combinedKey.value,
      focus,
      source: "CreateSpaceAvailability",
    };

    crossInteraction.value = newCrossInteraction;
  }
}

function persist() {
  if (canPersist.value) {
    setTimeout(() => {
      const apiRequestFunc = () =>
        api.post(`space_availabilities`, persistPayload.value);
      const successCallback = async (json) => {
        await spaceUsageBuilderStore.postEditingPatch(json);
        spaceUsageBuilderStore.clearBuilder(false);
        emit("refetch-layer");
        await nextTick();
        cancel();
      };
      const failureCallback = () => cancel();
      return changeGroupStore.originateData(
        apiRequestFunc,
        successCallback,
        failureCallback,
      );
    }, 125);
  }
}
</script>
