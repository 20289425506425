<template>
  <li
    class="col-span-1 flex space-x-3"
    :class="workspaceLayout === 'topAndBottom' ? 'flex-1' : ''"
  >
    <div class="flex-shrink-0">
      <span
        :class="`inline-flex items-center justify-center h-8 w-8 rounded-full ${equityAwareColor}`"
      >
        <BriefcaseIcon v-tooltip="'Company'" class="h-5 w-5 text-white" />
      </span>
    </div>
    <div class="min-w-0 flex-1 flex flex-col space-y-2">
      <div class="min-w-0 flex-1 flex justify-between">
        <div class="min-w-0 flex-1 flex flex-col space-y-1">
          <DataField
            v-if="decoratingContentDataField"
            :data-field="decoratingContentDataField"
            :inner-field-name="datafieldInnerFieldName"
            :parent-component-save-function="parentComponentSaveFunction"
            text-classes="text-sm font-medium"
            primary-text-path="fieldContent.name"
            text-styles=""
            @open-sourced="openSourced"
            @unlocked="refetchAll"
            @completed="refetchAll"
            @set-proof="setProof"
          />
          <DataField
            v-else-if="groupFetchedCompany"
            :data-field="groupFetchedCompany"
            :parent-component-save-function="parentComponentSaveFunction"
            text-classes="text-sm font-medium"
            primary-text-path="fieldContent.name"
            text-styles=""
            @open-sourced="openSourced"
            @unlocked="refetchAll"
            @completed="refetchAll"
            @set-proof="setProof"
          />
          <DataField
            v-else-if="_.isObject(fetchCompanyId)"
            :data-field="fetchCompanyId"
            :parent-component-save-function="parentComponentSaveFunction"
            text-classes="text-sm font-medium"
            text-styles=""
            @open-sourced="openSourced"
            @unlocked="refetchAll"
            @completed="refetchAll"
            @set-proof="setProof"
          />
          <div
            v-else-if="fetchCompanyId"
            class="text-gray-700 text-sm font-medium"
          >
            {{ fetchCompanyId }}
          </div>
          <p
            v-if="staticRoleDisplayable && role"
            v-tooltip="layerType === 'Loan' ? '' : 'Equity type'"
            class="text-sm font-medium text-gray-500 truncate"
            :data-test="`Investment-${investmentId}-company-${decoratingContentDataField?.fieldContentId}-role-label`"
          >
            {{ _.capitalize(role) }}
          </p>
          <EditableDataField
            v-else-if="layerType === 'OwnershipInterest' && ownershipInterest"
            field-name="equity_type"
            text-classes="text-sm"
            :decorating-content-data-field="decoratingContentDataField"
            :dropdown-options="equityTypeOptions"
            :optional="false"
            :dismiss-on-save="true"
            @completed="refetch"
            @open-sourced="refetchAll"
          />
          <InvestmentOffers
            v-if="bidsDisplayable"
            :companyInvolvementDataField="focalCompany"
            :context="context"
            :layerType="layerType"
            :rawCompanyId="fetchCompanyId"
            :investmentGroupId="investmentGroupId"
          />
          <ContentInvolvementRoles
            v-if="rolesDisplayable"
            :contentDataField="focalCompany"
            :context="context"
            :layerType="layerType"
            :rawCompanyId="fetchCompanyId"
            :investmentGroupId="investmentGroupId"
            :availabilityGroupId="availabilityGroupId"
            :availabilityId="availabilityId"
            :clientRole="clientRole"
          />
        </div>

        <div class="flex items-center self-start space-x-1">
          <VDropdown
            v-if="
              dealActionsDisplayable && !future && !existingInvestmentSelected
            "
          >
            <button
              type="button"
              v-tooltip="'Deal actions menu'"
              :disabled="originatingData"
              :data-test="`${focalCompany?.decoratingContentType}-${focalCompany?.decoratingContentId}-deal-actions-menu`"
              class="inline-flex items-center shadow-sm p-1 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 duration-150"
            >
              <PulseLoader
                v-if="originatingData"
                :loading="true"
                size="3px"
                color="#374151"
              />
              <ArrowsRightLeftIcon v-else class="h-4 w-4" />
            </button>

            <template #popper>
              <DealActionDropdown
                :deal-builder-asset-data-field="dealBuilderAssetDataField"
                :local-asset-data-field="localAssetDataField"
                :decorating-data-field="decoratingContentDataField"
                :providers-count="providersCount"
                :company-id="companyId || fetchCompanyId"
                :existing-investment-id="existingInvestmentId"
                :investment-group-id="investmentGroupId"
                :ownership-interest-ids="ownershipInterestIds"
                :layer-type="layerType"
                :context="context"
                :allows-senior-loan="allowsSeniorLoan"
                :restricted="unlockable"
                @refetch-investment="refetchInvestment"
                @refetch-group-investments="refetchGroupInvestments"
              />
            </template>
          </VDropdown>

          <VDropdown v-else-if="operationalAdvisorActionsDisplayable">
            <button
              type="button"
              v-tooltip="'Operational advisor actions menu'"
              :disabled="originatingData"
              :data-test="`${focalCompany?.decoratingContentType}-${focalCompany?.decoratingContentId}-operational-advisor-actions-menu`"
              class="inline-flex items-center shadow-sm p-1 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 duration-150"
            >
              <PulseLoader
                v-if="originatingData"
                :loading="true"
                size="3px"
                color="#374151"
              />
              <ArrowsRightLeftIcon v-else class="h-4 w-4" />
            </button>

            <template #popper>
              <OperationalAdvisorActionDropdown
                :decorating-data-field="decoratingContentDataField"
                :restricted="unlockable"
                @refetch-investment="refetchInvestment"
                @already-terminated="setTerminated"
              />
            </template>
          </VDropdown>

          <VDropdown v-else-if="spaceActionsDisplayable && !future">
            <button
              type="button"
              v-tooltip="'Space actions menu'"
              :disabled="originatingData"
              :data-test="`${focalCompany?.decoratingContentType}-${focalCompany?.decoratingContentId}-space-actions-menu`"
              class="inline-flex items-center shadow-sm p-1 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 duration-150"
            >
              <PulseLoader
                v-if="originatingData"
                :loading="true"
                size="3px"
                color="#374151"
              />
              <ArrowsRightLeftIcon v-else class="h-4 w-4" />
            </button>

            <template #popper>
              <SpaceUsageActionDropdown
                :top-level-space-data-field="
                  unlocatedSpaces
                    ? immediateSpaceDataField
                    : topLevelSpaceDataField
                "
                :decorating-data-field="decoratingContentDataField"
                :company-id="companyId || fetchCompanyId"
                :existing-availability-id="
                  decoratingContentDataField?.fieldContent?.availabilityId
                "
                :availability-group-id="
                  availabilityGroupId ||
                  decoratingContentDataField.fieldContent?.availabilityGroupId
                "
                :layer-type="layerType"
                :context="context"
                :restricted="unlockable"
                @refetch-availability="refetchAvailability"
                @refetch-group-availabilities="refetchGroupAvailabilities"
              />
            </template>
          </VDropdown>
        </div>
      </div>

      <div
        v-if="contactsDisplayable"
        class="flex flex-shrink-0 items-center space-x-2"
      >
        <DataVisibilityButton
          v-if="!creatingContact"
          visibility="safezone"
          :tooltip="addContactTooltip"
          class="mr-1 flex-shrink-0 inline-flex"
        >
          <template v-slot:button>
            <button
              @click="addContact"
              type="button"
              :data-test="`${decoratingContentDataField?.decoratingContentType}-${decoratingContentDataField?.decoratingContentId}-company-${decoratingContentDataField?.fieldContentId}-add-contacts-button`"
              class="mr-1 flex-shrink-0 bg-white inline-flex h-6 w-6 items-center justify-center rounded-full border-2 border-dashed border-yellow-300 text-yellow-400 hover:text-yellow-500 hover:border-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
            >
              <span class="sr-only">Add contact</span>
              <UserPlusIcon class="h-4 w-4" />
            </button>
          </template>
        </DataVisibilityButton>

        <div class="flex flex-shrink-0 -space-x-0.5" style="width: 84px">
          <template v-if="contacts.length > 0">
            <ContactDataField
              v-for="contactDataField in contacts"
              :key="`content-${decoratingContentDataField.fieldContentId}-contact-${contactDataField.localId}`"
              :data-field="contactDataField"
              :input-key="`contact-${layerType}s`"
              @completed="refetchAll"
              @unlocked="refetchAll"
            />
          </template>
          <template v-else-if="groupFetchedContacts.length > 0">
            <ContactDataField
              v-for="contact in groupFetchedContacts"
              :key="`content-${fetchCompanyId}-contact-${contact.id}`"
              :data-field="contact"
              :investment-group-id="investmentGroupId"
              :availability-group-id="availabilityGroupId"
              :input-key="`${
                investmentGroupId ? 'investment' : 'availability'
              }-group-contact-${layerType}s`"
              :parent-component-save-function="
                parentComponentContactSaveFunction
              "
              @completed="refetchAll"
              @unlocked="refetchAll"
            />
          </template>
        </div>
      </div>

      <div
        v-if="contactsDisplayable && creatingContact"
        class="mt-1 flex flex-col space-y-1"
      >
        <div
          class="border border-gray-300 rounded-lg shadow-sm focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500"
        >
          <ContactAutocomplete
            :label="`${layerType}s`"
            :selections="addedContacts"
            :input-key="`contact-${layerType}s`"
            :focus="true"
            :remove-inline="true"
            @new-contacts="setNewContacts"
            @remove="removeContact"
          />
        </div>
        <div class="flex items-center justify-end space-x-2">
          <button
            @click="clearContactEditing"
            type="button"
            class="inline-flex items-center p-1 border border-gray-300 rounded-full shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <XMarkIcon class="h-4 w-4" />
          </button>

          <DataVisibilityButton visibility="safezone" class="inline-flex">
            <template v-slot:button>
              <button
                @click="saveContacts"
                :disabled="originatingData"
                type="button"
                class="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-yellow-500 hover:bg-yellow-600 focus:ring-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2"
                :data-test="`contact-${layerType}s-save`"
              >
                <PulseLoader
                  v-if="originatingData"
                  :loading="true"
                  size="3px"
                  color="#f3f4f6"
                />
                <CheckIcon v-else class="h-4 w-4" />
              </button>
            </template>
          </DataVisibilityButton>
        </div>
      </div>
    </div>
  </li>
</template>

<script setup>
import { BriefcaseIcon } from "@heroicons/vue/24/outline";
import {
  UserPlusIcon,
  XMarkIcon,
  CheckIcon,
  ArrowsRightLeftIcon,
} from "@heroicons/vue/20/solid";
import ContactDataField from "@/components/crowdsourcing/ContactDataField.vue";
import ContactAutocomplete from "@/components/crowdsourcing/ContactAutocomplete.vue";
import CompanyContactAutocomplete from "@/components/crowdsourcing/CompanyContactAutocomplete.vue";
import ContentInvolvementRoles from "@/components/crowdsourcing/ContentInvolvementRoles.vue";
import DataField from "@/components/crowdsourcing/DataField.vue";
import DealActionDropdown from "@/components/deal-builder/DealActionDropdown.vue";
import EditableDataField from "@/components/crowdsourcing/EditableDataField.vue";
import InvestmentOffers from "@/components/crowdsourcing/InvestmentOffers.vue";
import { useCrowdsourcedChangeGroupStore } from "@/stores/crowdsourcedChangeGroup";
import { useDataLicensesChannelStore } from "@/stores/dataLicensesChannel";
import { useDealBuilderStore } from "@/stores/dealBuilder";
import { useSpaceUsageBuilderStore } from "@/stores/spaceUsageBuilder";
import { useDataSharingsChannelStore } from "@/stores/dataSharingsChannel";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { useProveForValueStore } from "@/stores/proveForValue";
import { ref, computed, watch, onMounted } from "vue";
import { storeToRefs } from "pinia";
import api from "@/router/api";
import _ from "lodash";
import decoratingAndFieldKey from "@/components/crowdsourcing/decoratingAndFieldKey";
import DataVisibilityButton from "@/components/crowdsourcing/DataVisibilityButton.vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import OperationalAdvisorActionDropdown from "@/components/deal-builder/OperationalAdvisorActionDropdown.vue";
import SpaceUsageActionDropdown from "@/components/space-usage-builder/SpaceUsageActionDropdown.vue";

const props = defineProps([
  "dealBuilderAssetDataField",
  "topLevelSpaceDataField",
  "immediateSpaceDataField",
  "localAssetDataField",
  "decoratingContentDataField",
  "layerType",
  "layerColor",
  "providersCount",
  "allowsSeniorLoan",
  "context",
  "investmentId",
  "investmentGroupId",
  "availabilityId",
  "availabilityGroupId",
  "clientRole",
  "fetchCompanyId",
  "future",
  "existingInvestmentSelected",
  "mustBeClosed",
  "fetchMilliseconds",
  "canAward",
  "parentComponentSaveFunction",
]);
const emit = defineEmits([
  "refetch",
  "refetch-investment",
  "refetch-group-investments",
  "refetch-availability",
  "refetch-group-availabilities",
  "refetch-deal-builder",
  "refetch-space-usage-builder",
  "override-refetch",
]);

const dataLicensesChannelStore = useDataLicensesChannelStore();
const { dataLicensesChannelDataQueue } = storeToRefs(dataLicensesChannelStore);
const dataSharingsChannelStore = useDataSharingsChannelStore();
const { dataSharingsChannelDataQueue } = storeToRefs(dataSharingsChannelStore);

const dealBuilderStore = useDealBuilderStore();
const {
  dealBuilder,
  allFetchedFields: dealBuilderFetchedFields,
  contactFieldSize: dealBuilderContactFieldSize,
  patchableCompanyInvolvementFieldIds:
    dealBuilderPatchableCompanyInvolvementFieldIds,
  patchableCompanySize: dealBuilderPatchableCompanySize,
} = storeToRefs(dealBuilderStore);
const spaceUsageBuilderStore = useSpaceUsageBuilderStore();
const {
  spaceUsageBuilder,
  allFetchedFields: spaceUsageBuilderFetchedFields,
  contactFieldSize: spaceUsageBuilderContactFieldSize,
  patchableCompanyInvolvementFieldIds:
    spaceUsageBuilderPatchableCompanyInvolvementFieldIds,
  patchableCompanySize: spaceUsageBuilderPatchableCompanySize,
} = storeToRefs(spaceUsageBuilderStore);
const layoutStore = useWorkspaceLayoutStore();
const { workspaceLayout } = storeToRefs(layoutStore);
const changeGroupStore = useCrowdsourcedChangeGroupStore();
const { originatingData, changeGroupId } = storeToRefs(changeGroupStore);
const proveForValueStore = useProveForValueStore();

const company = ref(null);
const groupFetchedCompany = ref(null);
const groupFetchedContacts = ref([]);
const addedContacts = ref([]);
const contacts = ref([]);
const creatingContact = ref(false);
const alreadyTerminated = ref(false);

const unlocatedSpaces = computed(
  () => props.topLevelSpaceDataField?.fieldContentType === "LandCovering",
);
const equityAwareColor = computed(() => {
  if (props.context === "space-usage-stack") {
    if (props.immediateSpaceDataField?.fieldContentType === "SpaceUsage") {
      return "bg-cyan-400";
    } else {
      return "bg-blue-400";
    }
  } else if (
    props.layerType === "OwnershipInterest" &&
    props.decoratingContentDataField?.fieldContentType === "OwnershipInterest"
  ) {
    if (
      props.decoratingContentDataField.fieldContent?.equityType === "preferred"
    ) {
      return "bg-orange-400";
    } else {
      return props.layerColor;
    }
  } else {
    return props.layerColor;
  }
});

const dealActionsDisplayable = computed(() => {
  if (props.canAward) {
    return !_.includes(
      [
        "space-usage-stack",
        "space availability-players",
        "availability-group-players",
      ],
      props.context,
    );
  } else if (props.context === "capital-stack") {
    return true;
  } else if (props.context === "controlling-asset") {
    return props.layerType === "OwnershipInterest";
  } else {
    return false;
  }
});
const spaceActionsDisplayable = computed(() => {
  if (props.canAward) {
    return _.includes(
      [
        "space-usage-stack",
        "space availability-players",
        "availability-group-players",
      ],
      props.context,
    );
  } else {
    return props.topLevelSpaceDataField;
  }
});
const operationalAdvisorActionsDisplayable = computed(() => {
  const operationalAdvisor =
    props.context === "contacts" &&
    _.get(props.decoratingContentDataField, "fieldContentSubType") ===
      "operational_advisor";
  const huntAdvisor = _.includes(
    ["HuntGeographyIntent", "Hunt"],
    props.decoratingContentDataField?.decoratingContentType,
  );
  return (operationalAdvisor || huntAdvisor) && !alreadyTerminated.value;
});
const contactsDisplayable = computed(() => {
  return !props.future;
});
const bidsDisplayable = computed(() => {
  return (
    !props.future &&
    (focalCompany.value || groupFetchedCompany.value) &&
    props.layerType === "Prospect" &&
    !_.includes(
      [
        "space-usage-stack",
        "space availability-players",
        "availability-group-players",
      ],
      props.context,
    )
  );
});
const rolesDisplayable = computed(() => {
  return (
    !props.future &&
    (focalCompany.value || groupFetchedCompany.value) &&
    ((dealActionsDisplayable.value && props.layerType !== "Prospect") ||
      props.layerType === "Advisor")
  );
});
const canAddInvestmentContact = computed(() => {
  return (
    _.includes(["OwnershipInterest", "Loan"], props.layerType) &&
    _.includes(
      ["investment-players", "investment-group-players"],
      props.context,
    )
  );
});
const canAddAvailabilityContact = computed(() => {
  return (
    _.includes(["OwnershipInterest"], props.layerType) &&
    _.includes(
      ["availability-players", "availability-group-players"],
      props.context,
    )
  );
});
const addContactTooltip = computed(() => {
  if (
    _.includes(
      [
        "investment-players",
        "availability-players",
        "prospect-advisor-partner",
      ],
      props.context,
    )
  ) {
    return "Add contacts who worked on this deal.";
  } else if (
    _.includes(
      ["investment-group-players", "investment-group-prospect-advisor-partner"],
      props.context,
    )
  ) {
    return "Add contacts who worked on this portfolio. They'll be applied throughout the portfolio.";
  } else if (props.context === "space-usage-stack") {
    return "Add real estate contacts for this user.";
  } else {
    return "Add contacts who are operating the asset.";
  }
});
const directCompanyInvolvement = computed(() => {
  return (
    _.includes(
      ["AwardedOwnershipInterest", "Advisor", "Prospect"],
      props.layerType,
    ) ||
    _.includes(
      ["SpaceUsage"],
      props.decoratingContentDataField?.decoratingContentType,
    )
  );
});
const focalCompany = computed(() => {
  if (directCompanyInvolvement.value) {
    if (_.isObject(props.fetchCompanyId)) {
      return props.fetchCompanyId;
    } else {
      return props.decoratingContentDataField;
    }
  } else {
    return company.value;
  }
});
const companyId = computed(() => {
  return _.get(focalCompany.value, "fieldContent.id");
});
const existingInvestmentId = computed(() => {
  return _.get(ownershipInterest.value, "investmentId", props.investmentId);
});
const ownershipInterestIds = computed(() => {
  if (directCompanyInvolvement.value) {
    return [];
  } else {
    return [props.decoratingContentDataField.fieldContentId];
  }
});
const safezone = computed(() => _.get(company.value, "safezone"));
const unstaked = computed(() => _.get(company.value, "state") === "unstaked");
const unlockable = computed(() => {
  const rawPrice = _.get(company.value, "price");
  const price = rawPrice ? _.toNumber(rawPrice) : null;

  return (unstaked.value && !safezone.value) || (price && price > 0);
});
const ownershipInterest = computed(() => {
  return _.get(props.decoratingContentDataField, "fieldContent", null);
});
const datafieldInnerFieldName = computed(() => {
  if (directCompanyInvolvement.value) {
    return null;
  } else {
    return "CompanyInvolvement";
  }
});
const staticRoleDisplayable = computed(() => {
  return _.includes(["AwardedOwnershipInterest", "Loan"], props.layerType);
});
const role = computed(() => {
  if (props.layerType === "AwardedOwnershipInterest") {
    return "Under contract";
  } else if (props.layerType === "Loan") {
    return "lender";
  } else if (ownershipInterest.value && props.context === "space-usage-stack") {
    return "User";
  } else if (ownershipInterest.value) {
    return ownershipInterest.value.equityType;
  } else {
    return null;
  }
});
const equityTypeOptions = computed(() => {
  return [
    {
      name: "Common",
      value: "common",
    },
    {
      name: "Preferred",
      value: "preferred",
    },
  ];
});
const availableProps = computed(() => {
  const inputKeyField =
    company.value || props.decoratingContentDataField || props.fetchCompanyId;
  let investmentRole = null;

  if (props.clientRole === "internalDispositions" && props.mustBeClosed) {
    investmentRole = "capital_consumer";
  }

  return [
    {
      fieldName: "CompanyInvolvement",
      investmentGroupId: props.investmentGroupId,
      availabilityGroupId: props.availabilityGroupId,
      clientRole: props.clientRole,
      inputKey: `${inputKeyField.decoratingContentType}-${inputKeyField.decoratingContentId}-${inputKeyField.fieldContentType}-${inputKeyField.fieldContentId}`,
      isInnerField: !!datafieldInnerFieldName.value,
      fetchMilliseconds: props.fetchMilliseconds,
      investmentRole,
    },
    {
      fieldName: "awarded_at",
      investmentGroupId: props.investmentGroupId,
      availabilityGroupId: props.availabilityGroupId,
      inputKey: `${inputKeyField.decoratingContentType}-${inputKeyField.decoratingContentId}-${inputKeyField.fieldName}`,
      isInnerField: !!datafieldInnerFieldName.value,
      fetchMilliseconds: props.fetchMilliseconds,
      investmentRole,
    },
  ];
});

watch(dealBuilderPatchableCompanySize, () => {
  if (company.value && dealBuilderPatchableCompanySize.value > 0) {
    const matchingField = _.find(dealBuilderFetchedFields.value, {
      localId: company.value.localId,
    });

    if (matchingField) {
      company.value = matchingField;
    }

    setTimeout(() => {
      dealBuilderPatchableCompanyInvolvementFieldIds.value = [];
    }, 20000);
  }
});
watch(dataLicensesChannelDataQueue, () => {
  const data = _.last(dataLicensesChannelDataQueue.value);
  console.log("involved company licenses channel watcher", data);

  if (data.dataFieldIds && data.context !== "provings_controller") {
    refetchAll();
  }
});

watch(dataSharingsChannelDataQueue, () => {
  const data = _.last(dataSharingsChannelDataQueue.value);

  if (data.dataFieldIds) {
    refetchAll();
  }
});
watch(companyId, () => {
  if (contactsDisplayable.value) {
    fetchInvolvedContacts();
  }
});
watch(dealBuilderContactFieldSize, () => {
  if (contactsDisplayable.value) {
    fetchInvolvedContacts();
  }
});

onMounted(() => initialFetch());

function setProof(fieldName) {
  const matchedProps = _.find(availableProps.value, { fieldName });

  if (matchedProps) {
    proveForValueStore.setProofComponent(
      CompanyContactAutocomplete,
      matchedProps,
    );
  }
}
function initialFetch(maybePayload) {
  if (directCompanyInvolvement.value) {
    if (contactsDisplayable.value) {
      fetchInvolvedContacts(maybePayload);
    }
    if (props.investmentGroupId || props.availabilityGroupId) {
      fetchInvolvedCompanies(maybePayload);
    }
    if (spaceUsageBuilder.value && props.context === "space-usage-stack") {
      fetchSpaceAvailability();
    }
  } else {
    fetchInvolvedCompanies(maybePayload);

    if (
      maybePayload?.override ||
      (props.investmentGroupId && contactsDisplayable.value)
    ) {
      fetchInvolvedContacts(maybePayload);
    }

    if (
      ownershipInterest.value &&
      dealBuilder.value &&
      props.context === "capital-stack"
    ) {
      fetchInvestment();
    }
  }
}
function refetch() {
  emit("refetch");
}
function refetchAll(maybePayload) {
  initialFetch(maybePayload);

  if (!maybePayload?.override) {
    emit("refetch", maybePayload);
  } else {
    emit("override-refetch", maybePayload);
  }
}
function fetchInvolvedCompanies(maybePayload) {
  if (props.investmentGroupId || props.availabilityGroupId) {
    if (props.fetchCompanyId && _.isNumber(props.fetchCompanyId)) {
      api.get(`companies/${props.fetchCompanyId}`).then((json) => {
        groupFetchedCompany.value = json.data;
      });
    } else {
      console.log("no company id to fetch");
    }
  } else {
    const fetchRequestKey = `crowdsourced_data_fields_${props.decoratingContentDataField.fieldContentType}_${props.decoratingContentDataField.fieldContentId}?field_name=CompanyInvolvement`;

    if (
      dealBuilderStore.alreadyFetched(fetchRequestKey) &&
      !maybePayload?.override
    ) {
      company.value = _.head(
        dealBuilderStore.alreadyFetchedFieldsFor(fetchRequestKey),
      );
    } else {
      api
        .get(
          `crowdsourced_data_fields/${props.decoratingContentDataField.fieldContentType}/${props.decoratingContentDataField.fieldContentId}?field_name=CompanyInvolvement`,
        )
        .then((json) => {
          company.value = _.head(json.data);
          dealBuilderStore.interceptablePatch(json.data, fetchRequestKey);
        });
    }
  }
}
function fetchInvestment() {
  if (props.investmentGroupId) {
    console.log("handle group investment fetch");
  } else {
    const fetchRequestKey = `ownership_interest_investments_${ownershipInterest.value.id}`;

    if (dealBuilderStore.alreadyFetched(fetchRequestKey)) {
      dealBuilderStore.updateDealBuilderAssetInvestments({
        assetKey: decoratingAndFieldKey(props.dealBuilderAssetDataField),
        investments: dealBuilderStore.alreadyFetchedFieldsFor(fetchRequestKey),
      });
    } else {
      api
        .get(`ownership_interest_investments/${ownershipInterest.value.id}`)
        .then((json) => {
          if (_.isEmpty(json.data)) {
            console.log("empty investments", json.data);
            dealBuilderStore.interceptablePatch([json.data], fetchRequestKey);
          } else {
            dealBuilderStore.interceptablePatch([json.data], fetchRequestKey);
            dealBuilderStore.updateDealBuilderAssetInvestments({
              assetKey: decoratingAndFieldKey(props.dealBuilderAssetDataField),
              investments: [json.data],
            });
          }
        });
    }
  }
}
function fetchSpaceAvailability() {
  if (props.availabilityGroupId) {
    console.log("handle group availability fetch");
  } else if (props.decoratingContentDataField) {
    const fetchRequestKey = `space_user_availabilities_${props.decoratingContentDataField.decoratingContentId}`;

    if (spaceUsageBuilderStore.alreadyFetched(fetchRequestKey)) {
      if (props.topLevelSpaceDataField) {
        spaceUsageBuilderStore.updateSpaceAvailabilities({
          spaceKey: decoratingAndFieldKey(props.topLevelSpaceDataField),
          availabilities:
            spaceUsageBuilderStore.alreadyFetchedFieldsFor(fetchRequestKey),
        });
      }
    } else {
      api
        .get(
          `space_user_availabilities/${props.decoratingContentDataField.decoratingContentId}`,
        )
        .then((json) => {
          if (_.isEmpty(json.data)) {
            console.log("empty availabilities", json.data);
            spaceUsageBuilderStore.interceptablePatch(
              [json.data],
              fetchRequestKey,
            );
          } else {
            spaceUsageBuilderStore.interceptablePatch(
              [json.data],
              fetchRequestKey,
            );

            if (props.topLevelSpaceDataField) {
              spaceUsageBuilderStore.updateSpaceAvailabilities({
                spaceKey: decoratingAndFieldKey(props.topLevelSpaceDataField),
                availabilities: [json.data],
              });
            }
          }
        });
    }
  }
}
function openSourced() {
  emit("refetch-deal-builder");
  emit("refetch-space-usage-builder");
}
function setTerminated() {
  alreadyTerminated.value = true;

  const huntAdvisor = _.includes(
    ["HuntGeographyIntent", "Hunt"],
    props.decoratingContentDataField?.decoratingContentType,
  );

  if (huntAdvisor) {
    refetch();
  }
}
function addContact() {
  creatingContact.value = true;
}
function setNewContacts(newContacts) {
  addedContacts.value = newContacts;
}
function removeContact(contact) {
  const filteredContacts = _.differenceBy(
    addedContacts.value,
    [contact],
    "name",
  );

  setNewContacts(filteredContacts);
}
function clearContactEditing() {
  creatingContact.value = false;
  addedContacts.value = [];
}
async function contactCreated(json, requestKey) {
  clearContactEditing();
  if (
    canAddAvailabilityContact.value ||
    props.context === "space-usage-stack"
  ) {
    await spaceUsageBuilderStore.postEditingPatch(json, requestKey);
  } else {
    await dealBuilderStore.postEditingPatch(json, requestKey);
  }
  fetchInvolvedContacts();
}
const investmentContactFetchRequestKey = computed(
  () =>
    `company_investment_contacts_${props.investmentId}_${companyId.value}_${props.clientRole}`,
);
const availabilityContactFetchRequestKey = computed(
  () =>
    `company_space_availability_contacts_${props.availabilityId}_${companyId.value}_${props.clientRole}`,
);
const involvementContactFetchRequestKey = computed(
  () =>
    `crowdsourced_data_fields_${focalCompany.value.fieldContentType}_${focalCompany.value.fieldContentId}?field_name=ContactCompanyInvolvement`,
);

async function fetchDirectInvestmentContacts() {
  let directInvestmentContactsResponse = [];

  if (props.context === "prospect-advisor-partner" && props.investmentId) {
    // fetch direct investment contacts
    directInvestmentContactsResponse = await api.get(
      `direct_investment_contacts/${focalCompany.value.fieldContentType}/${focalCompany.value.fieldContentId}?investment_id=${props.investmentId}`,
    );
  }

  return directInvestmentContactsResponse;
}

async function fetchDirectSpaceUsageContacts() {
  let directSpaceUsageContactsResponse = [];

  if (props.context === "prospect-advisor-partner" && props.availabilityId) {
    // fetch direct space usage contacts
    directSpaceUsageContactsResponse = await api.get(
      `direct_investment_contacts/${focalCompany.value.fieldContentType}/${focalCompany.value.fieldContentId}?availability_id=${props.availabilityId}`,
    );
  }

  return directSpaceUsageContactsResponse;
}

async function fetchInvolvedContacts(maybePayload) {
  if (props.investmentGroupId) {
    if (props.fetchCompanyId) {
      if (_.isNumber(props.fetchCompanyId)) {
        api
          .get(
            `investment_group_contacts/?investment_group_id=${props.investmentGroupId}&raw_company_id=${props.fetchCompanyId}&client_role=${props.clientRole}`,
          )
          .then((json) => {
            groupFetchedContacts.value = json.data;
          });
      } else {
        api
          .get(
            `investment_group_contacts/?investment_group_id=${props.investmentGroupId}&field_id=${props.fetchCompanyId.localId}&client_role=${props.clientRole}`,
          )
          .then((json) => {
            groupFetchedContacts.value = json.data;
          });
      }
    }
  } else if (props.availabilityGroupId) {
    if (props.fetchCompanyId) {
      if (_.isNumber(props.fetchCompanyId)) {
        api
          .get(
            `availability_group_contacts/?availability_group_id=${props.availabilityGroupId}&raw_company_id=${props.fetchCompanyId}&client_role=${props.clientRole}`,
          )
          .then((json) => {
            groupFetchedContacts.value = json.data;
          });
      } else {
        api
          .get(
            `availability_group_contacts/?availability_group_id=${props.availabilityGroupId}&field_id=${props.fetchCompanyId.localId}&client_role=${props.clientRole}`,
          )
          .then((json) => {
            groupFetchedContacts.value = json.data;
          });
      }
    }
  } else if (companyId.value) {
    if (
      canAddInvestmentContact.value &&
      props.investmentId &&
      props.clientRole
    ) {
      if (
        dealBuilderStore.alreadyFetched(
          investmentContactFetchRequestKey.value,
        ) &&
        !maybePayload?.override
      ) {
        contacts.value = dealBuilderStore.alreadyFetchedFieldsFor(
          investmentContactFetchRequestKey.value,
        );
      } else {
        api
          .get(
            `company_investment_contacts/?investment_id=${props.investmentId}&company_involvement_id=${companyId.value}&client_role=${props.clientRole}`,
          )
          .then((json) => {
            contacts.value = json.data;
            dealBuilderStore.interceptablePatch(
              json.data,
              investmentContactFetchRequestKey.value,
            );
          });
      }
    } else if (
      canAddAvailabilityContact.value &&
      props.availabilityId &&
      props.clientRole
    ) {
      if (
        spaceUsageBuilderStore.alreadyFetched(
          availabilityContactFetchRequestKey.value,
        ) &&
        !maybePayload?.override
      ) {
        contacts.value = spaceUsageBuilderStore.alreadyFetchedFieldsFor(
          availabilityContactFetchRequestKey.value,
        );
      } else {
        api
          .get(
            `company_space_availability_contacts/?availability_id=${props.availabilityId}&company_involvement_id=${companyId.value}&client_role=${props.clientRole}`,
          )
          .then((json) => {
            contacts.value = json.data;
            spaceUsageBuilderStore.interceptablePatch(
              json.data,
              availabilityContactFetchRequestKey.value,
            );
          });
      }
    } else {
      if (
        dealBuilder.value &&
        dealBuilderStore.alreadyFetched(
          involvementContactFetchRequestKey.value,
        ) &&
        !maybePayload?.override
      ) {
        const fetchedContacts = dealBuilderStore.alreadyFetchedFieldsFor(
          involvementContactFetchRequestKey.value,
        );
        const directInvestmentContacts = await fetchDirectInvestmentContacts();
        const combinedContacts = _.unionBy(
          fetchedContacts,
          directInvestmentContacts.data,
          function (cdf) {
            return cdf.fieldContent?.id;
          },
        );
        contacts.value = combinedContacts;
      } else if (
        spaceUsageBuilder.value &&
        spaceUsageBuilderStore.alreadyFetched(
          involvementContactFetchRequestKey.value,
        ) &&
        !maybePayload?.override
      ) {
        console.log("TODO: Restore already fetched space builder contacts");
        const fetchedContacts = spaceUsageBuilderStore.alreadyFetchedFieldsFor(
          involvementContactFetchRequestKey.value,
        );
        const directSpaceUsageContacts = await fetchDirectSpaceUsageContacts();
        const combinedContacts = _.unionBy(
          fetchedContacts,
          directSpaceUsageContacts.data,
          function (cdf) {
            return cdf.fieldContent?.id;
          },
        );
        contacts.value = combinedContacts;
      } else {
        api
          .get(
            `crowdsourced_data_fields/${focalCompany.value.fieldContentType}/${focalCompany.value.fieldContentId}?field_name=ContactCompanyInvolvement`,
          )
          .then(async (json) => {
            const directInvestmentContacts =
              await fetchDirectInvestmentContacts();
            const directSpaceUsageContacts =
              await fetchDirectSpaceUsageContacts();
            const combinedContacts = _.unionBy(
              json.data,
              directInvestmentContacts.data,
              directSpaceUsageContacts.data,
              function (cdf) {
                return cdf.fieldContent?.id;
              },
            );
            contacts.value = combinedContacts;
            dealBuilderStore.interceptablePatch(
              combinedContacts,
              involvementContactFetchRequestKey.value,
            );
          });
      }
    }
  }
}
function saveContacts() {
  setTimeout(() => {
    if (props.investmentGroupId) {
      saveInvestmentGroupContacts();
    } else if (props.availabilityGroupId) {
      saveAvailabilityGroupContacts();
    } else if (canAddInvestmentContact.value) {
      saveInvestmentContacts();
    } else if (canAddAvailabilityContact.value) {
      saveAvailabilityContacts();
    } else {
      saveInvolvementContacts();
    }
  }, 125);
}
const parentComponentContactSaveFunction = async (proofContacts = null) => {
  let response = null;

  if (props.investmentGroupId) {
    response = await persistInvestmentGroupContacts(proofContacts);
  } else if (canAddInvestmentContact.value) {
    response = await persistInvestmentContacts(proofContacts);
  } else if (canAddAvailabilityContact.value) {
    response = await persistAvailabilityContacts(proofContacts);
  } else {
    response = await persistInvolvementContacts(proofContacts);
  }

  return response;
};
function saveInvolvementContacts() {
  const apiRequestFunc = () => persistInvolvementContacts();
  const successCallback = (json) =>
    contactCreated(json, involvementContactFetchRequestKey.value);
  const failureCallback = () => clearContactEditing();

  return changeGroupStore.originateData(
    apiRequestFunc,
    successCallback,
    failureCallback,
  );
}
function saveAvailabilityGroupContacts() {
  if (props.availabilityGroupId && props.fetchCompanyId && props.clientRole) {
    const apiRequestFunc = () => persistAvailabilityGroupContacts();
    const successCallback = (json) => contactCreated(json);
    const failureCallback = () => clearContactEditing();

    return changeGroupStore.originateData(
      apiRequestFunc,
      successCallback,
      failureCallback,
    );
  }
}
function saveAvailabilityContacts() {
  if (props.availabilityId && companyId.value && props.clientRole) {
    const apiRequestFunc = () => persistAvailabilityContacts();
    const successCallback = (json) =>
      contactCreated(json, availabilityContactFetchRequestKey.value);
    const failureCallback = () => clearContactEditing();

    return changeGroupStore.originateData(
      apiRequestFunc,
      successCallback,
      failureCallback,
    );
  } else {
    console.log("missing existing availability and/or company");
  }
}
const persistAvailabilityContacts = async (proofContacts = null) => {
  const payload = {
    contentType: "SpaceAvailability",
    contentId: props.availabilityId,
    contacts: proofContacts || addedContacts.value,
    companyInvolvementId: companyId.value,
    clientRole: props.clientRole,
    changeGroupId: changeGroupId.value,
  };

  const response = await api.post(`contacts`, payload);

  return response;
};
const persistAvailabilityGroupContacts = async (proofContacts = null) => {
  const payload = {
    availabilityGroupId: props.availabilityGroupId,
    contacts: proofContacts || addedContacts.value,
    rawCompanyId: props.fetchCompanyId,
    fieldId: _.isObject(props.fetchCompanyId)
      ? props.fetchCompanyId.localId
      : null,
    clientRole: props.clientRole,
    changeGroupId: changeGroupId.value,
  };

  const response = await api.post(`availability_group_contacts`, payload);

  return response;
};
function saveInvestmentGroupContacts() {
  if (props.investmentGroupId && props.fetchCompanyId && props.clientRole) {
    const apiRequestFunc = () => persistInvestmentGroupContacts();
    const successCallback = (json) => contactCreated(json);
    const failureCallback = () => clearContactEditing();

    return changeGroupStore.originateData(
      apiRequestFunc,
      successCallback,
      failureCallback,
    );
  }
}
function saveInvestmentContacts() {
  if (props.investmentId && companyId.value && props.clientRole) {
    const apiRequestFunc = () => persistInvestmentContacts();
    const successCallback = (json) =>
      contactCreated(json, investmentContactFetchRequestKey.value);
    const failureCallback = () => clearContactEditing();

    return changeGroupStore.originateData(
      apiRequestFunc,
      successCallback,
      failureCallback,
    );
  } else {
    console.log("missing existing investment and/or company");
  }
}
const persistInvestmentGroupContacts = async (proofContacts = null) => {
  const payload = {
    investmentGroupId: props.investmentGroupId,
    contacts: proofContacts || addedContacts.value,
    rawCompanyId: props.fetchCompanyId,
    fieldId: _.isObject(props.fetchCompanyId)
      ? props.fetchCompanyId.localId
      : null,
    clientRole: props.clientRole,
    changeGroupId: changeGroupId.value,
  };

  const response = await api.post(`investment_group_contacts`, payload);

  return response;
};
const persistInvestmentContacts = async (proofContacts = null) => {
  const payload = {
    contentType: "Investment",
    contentId: props.investmentId,
    contacts: proofContacts || addedContacts.value,
    companyInvolvementId: companyId.value,
    clientRole: props.clientRole,
    changeGroupId: changeGroupId.value,
  };

  const response = await api.post(`contacts`, payload);

  return response;
};
const persistInvolvementContacts = async (proofContacts = null) => {
  const payload = {
    contentType: focalCompany.value.fieldContentType,
    contentId: focalCompany.value.fieldContentId,
    contacts: proofContacts || addedContacts.value,
    changeGroupId: changeGroupId.value,
  };
  const response = await api.post(`contacts`, payload);

  return response;
};
function refetchInvestment() {
  emit("refetch-investment");
}
function refetchGroupInvestments() {
  emit("refetch-group-investments");
}
function refetchAvailability() {
  emit("refetch-availability");
}
function refetchGroupAvailabilities() {
  emit("refetch-group-availabilities");
}
</script>
