import { DirectUpload } from "@rails/activestorage";

export default class Uploader {
  constructor(
    file,
    url,
    filesRef,
    pendingFileCountRef,
    uploadingRef,
    uploadingProgressRef,
    uploadingTotalRef
  ) {
    this.file = file;
    this.url = url;
    this.upload = new DirectUpload(this.file, this.url, this);
    this.uploadingRef = uploadingRef;
    this.uploadingProgressRef = uploadingProgressRef;
    this.uploadingTotalRef = uploadingTotalRef;
    this.filesRef = filesRef;
    this.pendingFileCountRef = pendingFileCountRef;
    this.directUpload = () => {
      this.upload.create((error, blob) => {
        if (error) {
          console.error(error);
        } else {
          this.filesRef.value.push(
            Object.assign(
              {},
              {
                name: this.file.name,
                size: this.file.size / 1024 / 1024, // MB
                signedId: blob.signed_id,
                errorMessage: false,
              }
            )
          );
          this.pendingFileCountRef.value--;
        }
      });
    };
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener("loadstart", (event) =>
      this.directUploadDidStart(event)
    );
    request.upload.addEventListener("progress", (event) =>
      this.directUploadDidProgress(event)
    );
  }

  directUploadDidStart(event) {
    // console.log("began uploading", progressWidth);
    this.uploadingRef.value = true;
    this.uploadingProgressRef.value = 1;
    this.uploadingTotalRef.value = event.total;
  }

  directUploadDidProgress(event) {
    this.uploadingProgressRef.value = event.loaded;
    // console.log("uploading progressed", progressWidth);

    if (event.loaded === event.total) {
      // console.log("finished uploading");
      setTimeout(() => {
        this.uploadingRef.value = false;
        this.uploadingProgressRef.value = null;
        this.uploadingTotalRef.value = null;
      }, 1000);
    }
  }
}
