<template>
  <tbody v-if="job" class="divide-y divide-gray-200 bg-white">
    <tr>
      <td class="relative whitespace-nowrap py-2 pl-4 pr-3 text-sm font-medium">
        <button
          @click.prevent="expanded = !expanded"
          type="button"
          class="h-5 w-5 inline-flex justify-center items-center text-sm text-gray-700 hover:text-gray-900"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clip-rule="evenodd"
            />
          </svg>
        </button>
      </td>
      <td class="whitespace-nowrap py-3 pr-3 text-sm sm:pl-0">
        <div class="ml-4">
          <span
            :class="statusStyling"
            class="inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset"
            >{{ _.startCase(job.packagingStatus) }}</span
          >
        </div>
      </td>
      <td class="whitespace-nowrap px-3 py-3 text-sm text-gray-500">
        <div class="">
          <div
            v-html="linkifiedBody"
            class="font-medium text-gray-900 whitespace-pre-line dont-break-out"
          />
          <div
            v-if="job.files.length > 0"
            class="mt-1 max-w-[400px] flex flex-wrap"
          >
            <a
              v-for="file in job.files"
              :key="`file-${file.id}`"
              href=""
              @click.prevent="viewEmbed(file)"
              class="flex items-center p-2"
            >
              <div
                class="flex max-w-48 flex-1 items-center font-medium text-indigo-600 hover:text-indigo-500"
              >
                <PaperClipIcon
                  class="h-4 w-4 flex-shrink-0"
                  aria-hidden="true"
                />
                <div class="ml-2 flex min-w-0 flex-1 gap-2 text-xs">
                  <span class="truncate font-medium">{{ file.name }}</span>
                </div>
              </div>
            </a>
          </div>
          <div v-else class="mt-1 text-gray-500 text-xs">No files</div>
        </div>
      </td>
      <td class="whitespace-nowrap px-3 py-3 text-sm text-gray-500">
        <div class="mt-1 text-gray-500">
          {{ moment(job.createdAt).format("MMM D YYYY") }}
        </div>
      </td>
    </tr>

    <tr v-if="expanded">
      <td colspan="100">
        <div class="p-2">
          <div
            class="grid"
            :class="selectedItem ? 'grid-cols-3' : 'grid-cols-1'"
          >
            <div :class="selectedItem ? 'col-span-2' : ''">
              <!-- Preemptions table -->
              <div v-if="reviewing || live || finalized" class="space-y-2">
                <div class="flex items-center">
                  <div class="text-sm font-semibold">
                    Pre-existing Datapoints
                  </div>
                </div>

                <div class="grid grid-cols-2 gap-3">
                  <div
                    v-for="preemption in job.preemptions"
                    :key="`preemption-${preemption.id}`"
                    class="rounded-md border border-gray-300"
                  >
                    <div class="">
                      <div class="flex justify-between p-2 bg-gray-100">
                        <div class="flex flex-1">
                          <div v-if="preemption.content" class="space-y-1">
                            <DataField
                              :data-field="preemption.content"
                              :safezone-tab="true"
                              text-classes="text-sm font-medium"
                            />
                            <div class="text-gray-500 text-xs pl-1">
                              {{ fieldType(preemption) }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  v-if="job.preemptions && job.preemptions.length === 0"
                  class="border-b border-gray-100"
                >
                  <div class="px-0 py-2">
                    <div class="font-medium text-sm text-gray-500">
                      Datapoints that were already part of Tower Hunt will
                      appear here to help you quickly jump to related content.
                    </div>
                  </div>
                </div>
              </div>

              <!-- Payloads -->
              <div v-if="reviewing || live || finalized" class="mt-4 space-y-2">
                <div class="flex items-center">
                  <div class="text-sm font-semibold">Data to Add</div>
                </div>

                <div
                  v-for="payload in job.payloads"
                  :key="`payload-${payload.id}`"
                  class="rounded-md border border-gray-300"
                >
                  <DataInputJobExistingPayload
                    :payload="payload"
                    @select-item="setSelectedItem"
                  />
                </div>

                <div
                  v-if="job.payloads && job.payloads.length === 0"
                  class="border-b border-gray-100"
                >
                  <div class="px-0 py-2">
                    <div class="font-medium text-sm text-gray-500">
                      Payloads will appear here...
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <SelectedInputPayloadItemDetails
              v-if="selectedItem"
              :local-item="selectedItem"
              :preemptions="job.preemptions"
              @close-panel="selectedItem = null"
            />
          </div>
        </div>
      </td>
    </tr>
  </tbody>
</template>

<script setup>
import { PaperClipIcon } from "@heroicons/vue/20/solid";
import { useUploadedFileStore } from "@/stores/uploadedFile";
import { computed, ref } from "vue";
import DataField from "@/components/crowdsourcing/DataField.vue";
import DataInputJobExistingPayload from "@/views/admin/DataInputJobExistingPayload.vue";
import SelectedInputPayloadItemDetails from "@/components/crowdsourcing/contributions/SelectedInputPayloadItemDetails.vue";
import linkifyHtml from "linkify-html";
import moment from "moment";
import _ from "lodash";

const props = defineProps(["job"]);

const uploadedFileStore = useUploadedFileStore();

const expanded = ref(false);
const selectedItem = ref(null);

const reviewing = computed(() => props.job.packagingStatus === "reviewing");
const live = computed(() => props.job.packagingStatus === "live");
const finalized = computed(() => props.job.packagingStatus === "finalized");
const linkifiedBody = computed(() => {
  return linkifyHtml(props.job.narrative || "No Narrative", {
    defaultProtocol: "https",
    target: "_blank",
    className: "font-medium text-indigo-600 hover:text-indigo-500",
  });
});

const statusStyling = computed(() => {
  switch (props.job.packagingStatus) {
    case "finalized":
      return "bg-violet-50 text-violet-800 ring-violet-600/10";
    case "live":
      return "bg-emerald-50 text-emerald-800 ring-emerald-600/10";
    case "reviewing":
      return "bg-yellow-50 text-yellow-800 ring-yellow-600/10";
    case "unread":
    default:
      return "bg-gray-50 text-gray-600 ring-gray-500/10";
  }
});

function viewEmbed(file) {
  uploadedFileStore.viewEmbed(file);
}

function fieldType(preemption) {
  const field = preemption.content;
  if (field) {
    if (field.fieldContentType) {
      return _.startCase(field.fieldContentType);
    } else if (field?.fieldName) {
      return _.startCase(field.fieldName);
    } else {
      return "Unknown field type";
    }
  } else {
    return "Unknown content type";
  }
}

function setSelectedItem(item) {
  selectedItem.value = item;
}
</script>
