<template>
  <div class="bg-teal-50 z-[50]">
    <!-- Filters -->
    <Disclosure
      as="section"
      aria-labelledby="filter-heading"
      class="grid items-center border-b border-gray-300"
    >
      <h2 id="filter-heading" class="sr-only">Filters</h2>
      <div
        class="relative col-start-1 row-start-1 flex items-center justify-between py-4"
      >
        <div
          :class="[workspaceLayout === 'sideBySide' ? 'px-2' : 'px-4']"
          class="flex space-x-6 divide-x divide-gray-200 text-sm"
        >
          <div>
            <DisclosureButton
              @click="expanded = !expanded"
              class="group flex items-center font-medium text-gray-700"
            >
              <FunnelIcon
                class="mr-2 h-5 w-5 flex-none text-gray-400 group-hover:text-gray-500"
                aria-hidden="true"
              />
              {{ pluralize("Filter", selectedFilterCategories, true) }}
            </DisclosureButton>
          </div>
          <div v-if="selectedFilterCategories > 0" class="pl-6">
            <button
              @click="analyzePanelStore.resetFilterCategories()"
              type="button"
              class="text-gray-500"
            >
              Clear all
            </button>
          </div>
        </div>
      </div>
      <div v-show="expanded" class="max-h-[300px] overflow-y-auto shadow-md">
        <DisclosurePanel static class="border-t border-gray-300 py-6">
          <div
            :class="[
              workspaceLayout === 'sideBySide'
                ? 'px-4 gap-x-4'
                : 'px-6 gap-x-6',
            ]"
            class="mx-auto grid grid-cols-2 text-sm"
          >
            <div
              :class="[
                workspaceLayout === 'sideBySide'
                  ? 'grid-cols-1 gap-x-3'
                  : 'grid-cols-2 gap-x-6',
              ]"
              class="grid auto-rows-min gap-y-10"
            >
              <fieldset>
                <legend class="block font-medium">Category</legend>
                <div class="grid grid-cols-2 gap-4 pt-4">
                  <div
                    v-for="(option, optionIdx) in filterCategories"
                    :key="option.value"
                    class="flex items-center text-sm"
                  >
                    <input
                      v-model="option.checked"
                      :id="`category-${optionIdx}`"
                      :name="`category-${optionIdx}`"
                      :value="option.value"
                      type="checkbox"
                      class="h-4 w-4 flex-shrink-0 rounded border-gray-300 text-teal-600 focus:ring-teal-500"
                      :checked="option.checked"
                    />
                    <label
                      :for="`category-${optionIdx}`"
                      class="ml-3 min-w-0 flex-1 text-gray-600"
                      >{{ option.label }}</label
                    >
                  </div>
                </div>
              </fieldset>
              <fieldset v-if="filterCompanyEventTypes">
                <legend class="block font-medium">Company Event Type</legend>
                <div class="space-y-4 pt-4">
                  <div
                    v-for="(option, optionIdx) in filterCompanyEventTypes"
                    :key="option.value"
                    class="flex items-center text-sm"
                  >
                    <input
                      v-model="option.checked"
                      :id="`event-type-${optionIdx}`"
                      :name="`event-type-${optionIdx}`"
                      :value="option.value"
                      type="checkbox"
                      class="h-4 w-4 flex-shrink-0 rounded border-gray-300 text-teal-600 focus:ring-teal-500"
                      :checked="option.checked"
                    />
                    <label
                      :for="`event-type-${optionIdx}`"
                      class="ml-3 min-w-0 flex-1 text-gray-600"
                      >{{ option.label }}</label
                    >
                  </div>
                </div>
              </fieldset>
              <fieldset v-if="filterGeography">
                <legend class="block font-medium">Geography</legend>
                <div class="space-y-4 pt-4">
                  <div
                    v-for="option in filterGeography"
                    :key="option.value"
                    class="flex items-center text-sm"
                  >
                    <input
                      v-model="selectedGeography"
                      :id="option.value"
                      :name="option.value"
                      :value="option"
                      type="radio"
                      class="h-4 w-4 border-gray-300 text-teal-600 focus:ring-teal-500"
                    />
                    <label
                      :for="option.value"
                      class="ml-3 min-w-0 flex-1 text-gray-600"
                      >{{ option.label }}</label
                    >
                  </div>
                  <div
                    v-if="editingPolygon && geographyFilterPolygon"
                    class="flex items-center space-x-2"
                  >
                    <button
                      @click="analyzePanelStore.setPolygonEditing(false)"
                      type="button"
                      class="rounded bg-teal-600 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                    >
                      Done
                    </button>
                  </div>
                  <div
                    v-else-if="geographyFilterPolygon"
                    class="flex items-center space-x-2"
                  >
                    <button
                      @click="analyzePanelStore.setPolygonEditing(true)"
                      type="button"
                      class="rounded bg-teal-600 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                    >
                      Edit
                    </button>
                    <button
                      @click="triggerClearMapPolygon = true"
                      type="button"
                      class="rounded bg-white px-2 py-1 text-xs font-semibold text-teal-900 shadow-sm ring-1 ring-inset ring-teal-300 hover:bg-teal-50"
                    >
                      Redraw
                    </button>
                  </div>
                  <div v-else-if="editingPolygon" class="flex items-center">
                    <button
                      @click="
                        analyzePanelStore.setSelectedGeography('boundaries')
                      "
                      type="button"
                      class="rounded bg-white px-2 py-1 text-xs font-semibold text-teal-900 shadow-sm ring-1 ring-inset ring-teal-300 hover:bg-teal-50"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </fieldset>
              <fieldset v-if="filterLandCoveringStates">
                <legend class="flex items-center space-x-2 h-[12px]">
                  <div class="font-medium">Building Status</div>
                  <SquareLoader
                    v-if="fetchingLandCoveringStateFilterData"
                    :loading="true"
                    size="12px"
                    color="#0d9488"
                  />
                </legend>

                <div class="space-y-4 pt-4">
                  <div
                    v-for="(option, optionIdx) in filterLandCoveringStates"
                    :key="option.label"
                    class="flex items-center text-sm"
                  >
                    <input
                      v-model="option.checked"
                      :id="`development-state-${optionIdx}`"
                      :name="`development-state-${optionIdx}`"
                      :value="option.value"
                      type="checkbox"
                      class="h-4 w-4 flex-shrink-0 rounded border-gray-300 text-teal-600 focus:ring-teal-500"
                      :checked="option.checked"
                    />
                    <label
                      :for="`development-state-${optionIdx}`"
                      class="ml-3 min-w-0 flex-1 text-gray-600"
                      >{{ option.label }}</label
                    >
                  </div>
                </div>
              </fieldset>
              <fieldset v-if="filterDates">
                <legend class="block font-medium">Time Period</legend>
                <div class="space-y-4 pt-4">
                  <div
                    v-for="(option, optionIdx) in filterDates"
                    :key="option.label"
                    class="flex items-center text-sm"
                  >
                    <label
                      :for="`date-${optionIdx}`"
                      v-tooltip="
                        option.label === 'From'
                          ? 'Rounds to start of month'
                          : 'Rounds to end of month'
                      "
                      class="mr-3 min-w-0 flex-1 text-gray-600"
                      >{{ option.label }}</label
                    >
                    <div v-if="option.label === 'From'">
                      <TimelineCustomDateField
                        v-if="
                          uncachedCustomDate.dateType === 'customLookbackDate'
                        "
                        :existing-date="uncachedCustomDate.existingDate"
                        :date-type="uncachedCustomDate.dateType"
                        @cancel="resetUncachedCustomDate"
                      />
                      <a
                        v-else
                        @click.prevent.stop="
                          chooseCustomDate('customLookbackDate')
                        "
                        href=""
                        class="text-teal-500 hover:text-teal-600 whitespace-nowrap text-sm font-medium tracking-wide"
                        >{{ moment(oldestDate).format("MMMM Do, YYYY") }}</a
                      >
                    </div>
                    <div v-else-if="option.label === 'To'">
                      <TimelineCustomDateField
                        v-if="
                          uncachedCustomDate.dateType === 'customForwardDate'
                        "
                        :existing-date="uncachedCustomDate.existingDate"
                        :date-type="uncachedCustomDate.dateType"
                        @cancel="resetUncachedCustomDate"
                      />
                      <a
                        v-else
                        @click.prevent.stop="
                          chooseCustomDate('customForwardDate')
                        "
                        href=""
                        class="text-teal-500 hover:text-teal-600 whitespace-nowrap text-sm font-medium tracking-wide"
                        >{{ moment(newestDate).format("MMMM Do, YYYY") }}</a
                      >
                    </div>
                  </div>
                </div>
              </fieldset>
              <fieldset v-if="filterSpaceUsageExpirationDate">
                <legend class="block font-medium">
                  Space Usage Expiration Date
                </legend>
                <div class="space-y-4 pt-4">
                  <div
                    v-for="(
                      option, optionIdx
                    ) in filterSpaceUsageExpirationDate"
                    :key="option.label"
                    class="flex items-center text-sm"
                  >
                    <label
                      :for="`expiration-date-${optionIdx}`"
                      class="mr-3 min-w-0 flex-1 text-gray-600"
                      >{{ option.label }}</label
                    >
                    <div>
                      <div
                        v-if="
                          spaceUsageExpirationDateFilterInput &&
                          !editingSpaceUsageExpirationDate
                        "
                        class="flex items-center space-x-2"
                      >
                        <a
                          @click.prevent.stop="
                            analyzePanelStore.setSpaceUsageExpirationDateEditing(
                              true,
                            )
                          "
                          href=""
                          class="text-teal-500 hover:text-teal-600 whitespace-nowrap text-sm font-medium tracking-wide"
                          >{{
                            moment(spaceUsageExpirationDateFilterInput).format(
                              "MMMM Do, YYYY",
                            )
                          }}</a
                        >
                        <button
                          @click="clearSpaceUsageExpirationDate"
                          type="button"
                          class="inline-flex items-center p-1 border border-gray-300 rounded-full shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
                        >
                          <XMarkIcon class="h-4 w-4" />
                        </button>
                      </div>
                      <div v-else class="flex items-center space-x-2">
                        <input
                          v-observe-visibility="{
                            callback: focusSpaceUsageExpirationDateInput,
                            once: true,
                          }"
                          v-model="localSpaceUsageExpirationDate"
                          type="date"
                          name="space-usage-expiration-date-filter"
                          id="space-usage-expiration-date-filter"
                          class="w-full rounded-md border-gray-300 pl-2 focus:border-teal-500 focus:ring-teal-500 text-xs"
                        />
                        <template v-if="localSpaceUsageExpirationDate">
                          <div class="flex items-center justify-end space-x-2">
                            <button
                              @click="cancelSpaceUsageExpirationEditing"
                              type="button"
                              class="inline-flex items-center p-1 border border-gray-300 rounded-full shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
                            >
                              <XMarkIcon class="h-4 w-4" />
                            </button>

                            <button
                              v-if="
                                spaceUsageExpirationDateChanged &&
                                spaceUsageExpirationDateIsValid
                              "
                              @click="saveSpaceUsageExpirationDate"
                              v-tooltip="'Set date'"
                              type="button"
                              :disabled="!spaceUsageExpirationDateIsValid"
                              class="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
                              data-test="content-name-save"
                            >
                              <CheckIcon class="h-4 w-4" />
                            </button>
                          </div>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>
              <fieldset v-if="filterLoanMaturityDate">
                <legend class="block font-medium">Loan Maturity Date</legend>
                <div class="space-y-4 pt-4">
                  <div
                    v-for="(option, optionIdx) in filterLoanMaturityDate"
                    :key="option.label"
                    class="flex items-center text-sm"
                  >
                    <label
                      :for="`maturity-date-${optionIdx}`"
                      class="mr-3 min-w-0 flex-1 text-gray-600"
                      >{{ option.label }}</label
                    >
                    <div>
                      <div
                        v-if="
                          loanMaturityDateFilterInput &&
                          !editingLoanMaturityDate
                        "
                        class="flex items-center space-x-2"
                      >
                        <a
                          @click.prevent.stop="
                            analyzePanelStore.setLoanMaturityDateEditing(true)
                          "
                          href=""
                          class="text-teal-500 hover:text-teal-600 whitespace-nowrap text-sm font-medium tracking-wide"
                          >{{
                            moment(loanMaturityDateFilterInput).format(
                              "MMMM Do, YYYY",
                            )
                          }}</a
                        >
                        <button
                          @click="clearLoanMaturityDate"
                          type="button"
                          class="inline-flex items-center p-1 border border-gray-300 rounded-full shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
                        >
                          <XMarkIcon class="h-4 w-4" />
                        </button>
                      </div>
                      <div v-else class="flex items-center space-x-2">
                        <input
                          v-observe-visibility="{
                            callback: focusLoanMaturityDateInput,
                            once: true,
                          }"
                          v-model="localLoanMaturityDate"
                          type="date"
                          name="loan-maturity-date-filter"
                          id="loan-maturity-date-filter"
                          class="w-full rounded-md border-gray-300 pl-2 focus:border-teal-500 focus:ring-teal-500 text-xs"
                        />
                        <template v-if="localLoanMaturityDate">
                          <div class="flex items-center justify-end space-x-2">
                            <button
                              @click="cancelLoanMaturityEditing"
                              type="button"
                              class="inline-flex items-center p-1 border border-gray-300 rounded-full shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
                            >
                              <XMarkIcon class="h-4 w-4" />
                            </button>

                            <button
                              v-if="
                                loanMaturityDateChanged &&
                                loanMaturityDateIsValid
                              "
                              @click="saveLoanMaturityDate"
                              v-tooltip="'Set date'"
                              type="button"
                              :disabled="!loanMaturityDateIsValid"
                              class="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
                              data-test="content-name-save"
                            >
                              <CheckIcon class="h-4 w-4" />
                            </button>
                          </div>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
            <div
              v-if="
                filterCompanyRoles ||
                filterValuations ||
                filterInvestmentTypes ||
                filterInvestmentAssetTypes ||
                filterLandCoveringUseTypes
              "
              :class="[
                workspaceLayout === 'sideBySide'
                  ? 'grid-cols-1 gap-x-3'
                  : 'grid-cols-2 gap-x-6',
              ]"
              class="grid auto-rows-min gap-y-10"
            >
              <fieldset v-if="filterLandCoveringUseTypes">
                <legend class="flex items-center space-x-2 h-[12px]">
                  <div class="font-medium">Use Type</div>
                  <SquareLoader
                    v-if="fetchingLandCoveringUseTypeFilterData"
                    :loading="true"
                    size="12px"
                    color="#0d9488"
                  />
                </legend>

                <div class="grid grid-cols-2 gap-4 pt-4">
                  <div
                    v-for="(option, optionIdx) in filterLandCoveringUseTypes"
                    :key="option.value"
                    class="flex items-center text-sm"
                  >
                    <input
                      v-model="option.checked"
                      :id="`use-type-${optionIdx}`"
                      :name="`use-type-${optionIdx}`"
                      :value="option.value"
                      type="checkbox"
                      class="h-4 w-4 flex-shrink-0 rounded border-gray-300 text-teal-600 focus:ring-teal-500"
                      :checked="option.checked"
                    />
                    <label
                      :for="`use-type-${optionIdx}`"
                      class="ml-3 min-w-0 flex-1 text-gray-600"
                      >{{ option.label }}</label
                    >
                  </div>
                </div>
              </fieldset>
              <fieldset v-if="filterCompanyRoles">
                <legend class="flex items-center space-x-2 h-[12px]">
                  <div class="font-medium">Company Role</div>
                </legend>

                <div class="space-y-4 pt-4">
                  <div
                    v-for="(option, optionIdx) in filterCompanyRoles"
                    :key="option.value"
                    class="flex items-center text-sm"
                  >
                    <input
                      v-model="option.checked"
                      :id="`company-role-${optionIdx}`"
                      :name="`company-role-${optionIdx}`"
                      :value="option.value"
                      type="checkbox"
                      class="h-4 w-4 flex-shrink-0 rounded border-gray-300 text-teal-600 focus:ring-teal-500"
                      :checked="option.checked"
                    />
                    <label
                      :for="`company-role-${optionIdx}`"
                      class="ml-3 min-w-0 flex-1"
                      :class="[
                        option.value === 'Prospect'
                          ? 'italic text-gray-400'
                          : 'text-gray-600',
                      ]"
                      >{{ option.label }}</label
                    >
                  </div>
                </div>
              </fieldset>
              <fieldset v-if="filterInvestmentTypes">
                <legend class="flex items-center space-x-2 h-[12px]">
                  <div class="font-medium">Investment Type</div>
                  <SquareLoader
                    v-if="fetchingInvestmentTypeFilterData"
                    :loading="true"
                    size="12px"
                    color="#0d9488"
                  />
                </legend>

                <div class="space-y-4 pt-4">
                  <div
                    v-for="(option, optionIdx) in filterInvestmentTypes"
                    :key="option.label"
                    class="flex items-center text-sm"
                  >
                    <input
                      v-model="option.checked"
                      :id="`investment-type-${optionIdx}`"
                      :name="`investment-type-${optionIdx}`"
                      :value="option.value"
                      type="checkbox"
                      class="h-4 w-4 flex-shrink-0 rounded border-gray-300 text-teal-600 focus:ring-teal-500"
                      :checked="option.checked"
                    />
                    <label
                      :for="`investment-type-${optionIdx}`"
                      class="ml-3 min-w-0 flex-1 text-gray-600"
                      >{{ option.label }}</label
                    >
                  </div>
                </div>
              </fieldset>
              <fieldset v-if="filterInvestmentAssetTypes">
                <legend class="flex items-center space-x-2 h-[12px]">
                  <div class="font-medium">Asset Type</div>
                  <SquareLoader
                    v-if="fetchingInvestmentAssetTypeFilterData"
                    :loading="true"
                    size="12px"
                    color="#0d9488"
                  />
                </legend>

                <div class="grid grid-cols-2 gap-4 pt-4">
                  <div
                    v-for="(option, optionIdx) in filterInvestmentAssetTypes"
                    :key="option.value"
                    class="flex items-center text-sm"
                  >
                    <input
                      v-model="option.checked"
                      :id="`asset-type-${optionIdx}`"
                      :name="`asset-type-${optionIdx}`"
                      :value="option.value"
                      type="checkbox"
                      class="h-4 w-4 flex-shrink-0 rounded border-gray-300 text-teal-600 focus:ring-teal-500"
                      :checked="option.checked"
                    />
                    <label
                      :for="`asset-type-${optionIdx}`"
                      class="ml-3 min-w-0 flex-1 text-gray-600"
                      >{{ option.label }}</label
                    >
                  </div>
                </div>
              </fieldset>
              <fieldset v-if="filterSpaceAvailabilitySpaceTypes">
                <legend class="flex items-center space-x-2 h-[12px]">
                  <div class="font-medium">Space Type</div>
                  <SquareLoader
                    v-if="fetchingSpaceAvailabilitySpaceTypeFilterData"
                    :loading="true"
                    size="12px"
                    color="#0d9488"
                  />
                </legend>

                <div class="grid grid-cols-2 gap-4 pt-4">
                  <div
                    v-for="(
                      option, optionIdx
                    ) in filterSpaceAvailabilitySpaceTypes"
                    :key="option.value"
                    class="flex items-center text-sm"
                  >
                    <input
                      v-model="option.checked"
                      :id="`space-availability-space-type-${optionIdx}`"
                      :name="`space-availability-space-type-${optionIdx}`"
                      :value="option.value"
                      type="checkbox"
                      class="h-4 w-4 flex-shrink-0 rounded border-gray-300 text-teal-600 focus:ring-teal-500"
                      :checked="option.checked"
                    />
                    <label
                      :for="`space-availability-space-type-${optionIdx}`"
                      class="ml-3 min-w-0 flex-1 text-gray-600"
                      >{{ option.label }}</label
                    >
                  </div>
                </div>
              </fieldset>
              <fieldset v-if="filterSpaceAvailabilityStates">
                <legend class="flex items-center space-x-2 h-[12px]">
                  <div class="font-medium">Space Availability Status</div>
                  <SquareLoader
                    v-if="fetchingSpaceAvailabilityStateFilterData"
                    :loading="true"
                    size="12px"
                    color="#0d9488"
                  />
                </legend>

                <div class="grid grid-cols-2 gap-4 pt-4">
                  <div
                    v-for="(option, optionIdx) in filterSpaceAvailabilityStates"
                    :key="option.value"
                    class="flex items-center text-sm"
                  >
                    <input
                      v-model="option.checked"
                      :id="`space-availability-state-${optionIdx}`"
                      :name="`space-availability-state-${optionIdx}`"
                      :value="option.value"
                      type="checkbox"
                      class="h-4 w-4 flex-shrink-0 rounded border-gray-300 text-teal-600 focus:ring-teal-500"
                      :checked="option.checked"
                    />
                    <label
                      :for="`space-availability-state-${optionIdx}`"
                      class="ml-3 min-w-0 flex-1 text-gray-600"
                      >{{ option.label }}</label
                    >
                  </div>
                </div>
              </fieldset>
              <fieldset v-if="filterSpaceUsageStates">
                <legend class="flex items-center space-x-2 h-[12px]">
                  <div class="font-medium">Space Usage Status</div>
                  <SquareLoader
                    v-if="fetchingSpaceUsageStateFilterData"
                    :loading="true"
                    size="12px"
                    color="#0d9488"
                  />
                </legend>

                <div class="grid grid-cols-2 gap-4 pt-4">
                  <div
                    v-for="(option, optionIdx) in filterSpaceUsageStates"
                    :key="option.value"
                    class="flex items-center text-sm"
                  >
                    <input
                      v-model="option.checked"
                      :id="`space-usage-state-${optionIdx}`"
                      :name="`space-usage-state-${optionIdx}`"
                      :value="option.value"
                      type="checkbox"
                      class="h-4 w-4 flex-shrink-0 rounded border-gray-300 text-teal-600 focus:ring-teal-500"
                      :checked="option.checked"
                    />
                    <label
                      :for="`space-usage-state-${optionIdx}`"
                      class="ml-3 min-w-0 flex-1 text-gray-600"
                      >{{ option.label }}</label
                    >
                  </div>
                </div>
              </fieldset>
              <fieldset v-if="filterSpaceAvailabilitySizes">
                <legend class="block font-medium">Size</legend>
                <div class="space-y-4 pt-4">
                  <div
                    v-for="(option, optionIdx) in filterSpaceAvailabilitySizes"
                    :key="option.label"
                    class="flex items-center text-sm"
                  >
                    <label
                      :for="`space-availability-size-${optionIdx}`"
                      class="mr-3 min-w-0 flex-1 text-gray-600"
                      >{{ option.label }}</label
                    >
                    <div
                      v-if="
                        option.label === 'Area (SF)' &&
                        !spaceAvailabilitySizeDisplayReset
                      "
                      class="flex space-x-2"
                    >
                      <div class="relative rounded-md shadow-sm">
                        <CurrencyInput
                          v-model="minSpaceAvailabilitySize"
                          @keydown.enter="
                            analyzePanelStore.fetchSizeFilteredSpaceAvailabilities()
                          "
                          :options="currencyInputOptions"
                          :name="`min-space-availability-size-filter-currency-input`"
                          :id="`min-space-availability-size-filter-currency-input`"
                          :data-test="`min-space-availability-size-filter-currency-input`"
                          class="w-32 pr-8 text-sm rounded-md"
                          :class="[
                            validMinSpaceAvailabilitySize
                              ? 'focus:ring-teal-500 focus:border-teal-500 border-gray-300'
                              : 'border-red-300 focus:ring-red-500 focus:border-red-500',
                          ]"
                          placeholder="Min"
                        />
                        <div
                          class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
                        >
                          <span class="text-gray-500 text-sm">SF</span>
                        </div>
                      </div>
                      <div class="relative rounded-md shadow-sm">
                        <CurrencyInput
                          v-model="maxSpaceAvailabilitySize"
                          @keydown.enter="
                            analyzePanelStore.fetchSizeFilteredSpaceAvailabilities()
                          "
                          :options="currencyInputOptions"
                          :name="`min-space-availability-size-filter-currency-input`"
                          :id="`min-space-availability-size-filter-currency-input`"
                          :data-test="`min-space-availability-size-filter-currency-input`"
                          class="w-32 pr-8 text-sm rounded-md"
                          :class="[
                            validMaxSpaceAvailabilitySize
                              ? 'focus:ring-teal-500 focus:border-teal-500 border-gray-300'
                              : 'border-red-300 focus:ring-red-500 focus:border-red-500',
                          ]"
                          placeholder="Max"
                        />
                        <div
                          class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
                        >
                          <span class="text-gray-500 text-sm">SF</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="
                      minSpaceAvailabilitySize ||
                      maxSpaceAvailabilitySize ||
                      spaceAvailabilitySizeFilterApplied
                    "
                    class="flex items-center space-x-2"
                  >
                    <button
                      v-if="
                        validSpaceAvailabilitySizeFilters &&
                        !spaceAvailabilitySizeFilterApplied
                      "
                      @click="
                        analyzePanelStore.fetchSizeFilteredSpaceAvailabilities()
                      "
                      type="button"
                      class="rounded bg-teal-600 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                    >
                      <PulseLoader
                        v-if="fetchingSpaceAvailabilitySizeFilterData"
                        :loading="true"
                        size="4px"
                        color="#f3f4f6"
                      />
                      <template v-else>Go</template>
                    </button>
                    <button
                      @click="
                        analyzePanelStore.clearSpaceAvailabilitySizeFilters()
                      "
                      type="button"
                      class="rounded bg-white px-2 py-1 text-xs font-semibold text-teal-900 shadow-sm ring-1 ring-inset ring-teal-300 hover:bg-teal-50"
                    >
                      Clear
                    </button>
                  </div>
                </div>
              </fieldset>
              <fieldset v-if="filterInvestmentStates">
                <legend class="flex items-center space-x-2 h-[12px]">
                  <div class="font-medium">Investment Status</div>
                  <SquareLoader
                    v-if="fetchingInvestmentStateFilterData"
                    :loading="true"
                    size="12px"
                    color="#0d9488"
                  />
                </legend>

                <div class="grid grid-cols-2 gap-4 pt-4">
                  <div
                    v-for="(option, optionIdx) in filterInvestmentStates"
                    :key="option.value"
                    class="flex items-center text-sm"
                  >
                    <input
                      v-model="option.checked"
                      :id="`investment-state-${optionIdx}`"
                      :name="`investment-state-${optionIdx}`"
                      :value="option.value"
                      type="checkbox"
                      class="h-4 w-4 flex-shrink-0 rounded border-gray-300 text-teal-600 focus:ring-teal-500"
                      :checked="option.checked"
                    />
                    <label
                      :for="`investment-state-${optionIdx}`"
                      class="ml-3 min-w-0 flex-1 text-gray-600"
                      >{{ option.label }}</label
                    >
                  </div>
                </div>
              </fieldset>
              <fieldset v-if="filterLoanStates">
                <legend class="flex items-center space-x-2 h-[12px]">
                  <div class="font-medium">Loan Status</div>
                  <SquareLoader
                    v-if="fetchingLoanStateFilterData"
                    :loading="true"
                    size="12px"
                    color="#0d9488"
                  />
                </legend>

                <div class="grid grid-cols-2 gap-4 pt-4">
                  <div
                    v-for="(option, optionIdx) in filterLoanStates"
                    :key="option.value"
                    class="flex items-center text-sm"
                  >
                    <input
                      v-model="option.checked"
                      :id="`loan-state-${optionIdx}`"
                      :name="`loan-state-${optionIdx}`"
                      :value="option.value"
                      type="checkbox"
                      class="h-4 w-4 flex-shrink-0 rounded border-gray-300 text-teal-600 focus:ring-teal-500"
                      :checked="option.checked"
                    />
                    <label
                      :for="`loan-state-${optionIdx}`"
                      class="ml-3 min-w-0 flex-1 text-gray-600"
                      >{{ option.label }}</label
                    >
                  </div>
                </div>
              </fieldset>
              <fieldset v-if="filterValuations">
                <legend class="block font-medium">
                  {{ loansChecked ? "Loan Amount" : "Valuation" }}
                </legend>
                <div class="space-y-4 pt-4">
                  <div
                    v-for="(option, optionIdx) in filterValuations"
                    :key="option.label"
                    class="flex items-center text-sm"
                  >
                    <label
                      :for="`valuation-${optionIdx}`"
                      class="mr-3 min-w-0 flex-1 text-gray-600"
                      >{{ option.label }}</label
                    >
                    <div
                      v-if="option.label === 'Value' && !valueDisplayReset"
                      class="flex space-x-2"
                    >
                      <div class="relative rounded-md shadow-sm">
                        <div
                          class="absolute inset-y-0 left-0 pl-2 flex items-center pointer-events-none"
                        >
                          <span class="text-gray-500 text-sm">$</span>
                        </div>
                        <CurrencyInput
                          v-model="minValue"
                          @keydown.enter="
                            analyzePanelStore.fetchValuationFilteredInvestments()
                          "
                          :options="currencyInputOptions"
                          :name="`min-value-filter-currency-input`"
                          :id="`min-value-filter-currency-input`"
                          :data-test="`min-value-filter-currency-input`"
                          class="w-32 pl-5 pr-1 text-sm rounded-md"
                          :class="[
                            validMinValue
                              ? 'focus:ring-teal-500 focus:border-teal-500 border-gray-300'
                              : 'border-red-300 focus:ring-red-500 focus:border-red-500',
                          ]"
                          placeholder="Min"
                        />
                      </div>
                      <div class="relative rounded-md shadow-sm">
                        <div
                          class="absolute inset-y-0 left-0 pl-2 flex items-center pointer-events-none"
                        >
                          <span class="text-gray-500 text-sm">$</span>
                        </div>
                        <CurrencyInput
                          v-model="maxValue"
                          @keydown.enter="
                            analyzePanelStore.fetchValuationFilteredInvestments()
                          "
                          :options="currencyInputOptions"
                          :name="`min-value-filter-currency-input`"
                          :id="`min-value-filter-currency-input`"
                          :data-test="`min-value-filter-currency-input`"
                          class="w-32 pl-5 pr-1 text-sm rounded-md"
                          :class="[
                            validMaxValue
                              ? 'focus:ring-teal-500 focus:border-teal-500 border-gray-300'
                              : 'border-red-300 focus:ring-red-500 focus:border-red-500',
                          ]"
                          placeholder="Max"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="minValue || maxValue || valueFilterApplied"
                    class="flex items-center space-x-2"
                  >
                    <button
                      v-if="validValuationFilters && !valueFilterApplied"
                      @click="
                        analyzePanelStore.fetchValuationFilteredInvestments()
                      "
                      type="button"
                      class="rounded bg-teal-600 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                    >
                      <PulseLoader
                        v-if="fetchingValuationFilterData"
                        :loading="true"
                        size="4px"
                        color="#f3f4f6"
                      />
                      <template v-else>Go</template>
                    </button>
                    <button
                      @click="analyzePanelStore.clearValuationFilters()"
                      type="button"
                      class="rounded bg-white px-2 py-1 text-xs font-semibold text-teal-900 shadow-sm ring-1 ring-inset ring-teal-300 hover:bg-teal-50"
                    >
                      Clear
                    </button>
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
        </DisclosurePanel>
      </div>
    </Disclosure>
  </div>
</template>

<script setup>
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import { FunnelIcon, XMarkIcon } from "@heroicons/vue/20/solid";
import { CheckIcon } from "@heroicons/vue/24/outline";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { useAnalyzePanelStore } from "@/stores/analyzePanel";
import { useTimeTravelStore } from "@/stores/timeTravel";
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router";
import { computed, nextTick, onMounted, ref, watch } from "vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import SquareLoader from "vue-spinner/src/SquareLoader.vue";
import TimelineCustomDateField from "@/components/main-layout/TimelineCustomDateField.vue";
import CurrencyInput from "@/components/crowdsourcing/CurrencyInput.vue";
import pluralize from "pluralize";
import dateValid from "@/assets/dateValid";
import moment from "moment";
import _ from "lodash";

const route = useRoute();
const routeName = computed(() => route.name);
const expanded = ref(false);

const layoutStore = useWorkspaceLayoutStore();
const { workspaceLayout } = storeToRefs(layoutStore);
const timeTravelStore = useTimeTravelStore();
const { mainTimelineCustomForwardDate, mainTimelineCustomLookbackDate } =
  storeToRefs(timeTravelStore);
const analyzePanelStore = useAnalyzePanelStore();
const {
  filterCategories,
  filterDates,
  filterGeography,
  filterValuations,
  filterCompanyEventTypes,
  filterCompanyRoles,
  filterLoanStates,
  filterSpaceUsageStates,
  filterSpaceAvailabilityStates,
  filterSpaceAvailabilitySpaceTypes,
  filterSpaceAvailabilitySizes,
  filterInvestmentStates,
  filterInvestmentTypes,
  filterInvestmentAssetTypes,
  filterLandCoveringStates,
  filterLandCoveringUseTypes,
  loansChecked,
  minValue,
  maxValue,
  valueFilterApplied,
  fetchingValuationFilterData,
  minSpaceAvailabilitySize,
  maxSpaceAvailabilitySize,
  spaceAvailabilitySizeFilterApplied,
  fetchingSpaceAvailabilitySizeFilterData,
  fetchingLoanStateFilterData,
  fetchingSpaceUsageStateFilterData,
  fetchingSpaceAvailabilityStateFilterData,
  fetchingSpaceAvailabilitySpaceTypeFilterData,
  fetchingInvestmentStateFilterData,
  fetchingInvestmentTypeFilterData,
  fetchingInvestmentAssetTypeFilterData,
  fetchingLandCoveringStateFilterData,
  fetchingLandCoveringUseTypeFilterData,
  validMaxValue,
  validMinValue,
  validValuationFilters,
  validMaxSpaceAvailabilitySize,
  validMinSpaceAvailabilitySize,
  validSpaceAvailabilitySizeFilters,
  selectedGeography,
  selectedFilterCategories,
  geographyFilterPolygon,
  editingPolygon,
  triggerClearMapPolygon,
  loanMaturityDateFilterInput,
  editingLoanMaturityDate,
  filterLoanMaturityDate,
  filterSpaceUsageExpirationDate,
  spaceUsageExpirationDateFilterInput,
  editingSpaceUsageExpirationDate,
} = storeToRefs(analyzePanelStore);

const currencyInputOptions = ref({
  currency: "USD",
  currencyDisplay: "hidden",
  hideCurrencySymbolOnFocus: true,
  hideGroupingSeparatorOnFocus: false,
  hideNegligibleDecimalDigitsOnFocus: false,
  autoDecimalDigits: false,
  autoSign: true,
  useGrouping: true,
  accountingSign: false,
});
const valueDisplayReset = ref(false);
const spaceAvailabilitySizeDisplayReset = ref(false);

const localSpaceUsageExpirationDate = ref(null);
const spaceUsageExpirationDateChanged = computed(() => {
  if (localSpaceUsageExpirationDate.value) {
    const newDateMoment = moment(localSpaceUsageExpirationDate.value);
    const existingMoment = moment(spaceUsageExpirationDateFilterInput.value);

    return !existingMoment.isSame(newDateMoment, "day");
  } else {
    return false;
  }
});
const spaceUsageExpirationDateIsValid = computed(() => {
  return dateValid(localSpaceUsageExpirationDate.value);
});

function cancelSpaceUsageExpirationEditing() {
  localSpaceUsageExpirationDate.value = null;

  if (spaceUsageExpirationDateFilterInput.value) {
    analyzePanelStore.setSpaceUsageExpirationDateEditing(false);
  }
}
function saveSpaceUsageExpirationDate() {
  analyzePanelStore.setSpaceUsageExpirationDate(
    moment(localSpaceUsageExpirationDate.value),
  );
  analyzePanelStore.setSpaceUsageExpirationDateEditing(false);
}
function clearSpaceUsageExpirationDate() {
  analyzePanelStore.clearSpaceUsageExpirationDate();
  localSpaceUsageExpirationDate.value = null;
}

function populateExistingSpaceUsageExpirationDate() {
  if (spaceUsageExpirationDateFilterInput.value) {
    console.log("populate existing expiration");
    const existingMoment = spaceUsageExpirationDateFilterInput.value.clone();

    localSpaceUsageExpirationDate.value = existingMoment.format("YYYY-MM-DD");
  }
}

function focusSpaceUsageExpirationDateInput(isVisible) {
  if (isVisible) {
    setTimeout(() => {
      populateExistingSpaceUsageExpirationDate();
      selectSpaceUsageExpirationDateInput();
    }, 100);
  }
}
function selectSpaceUsageExpirationDateInput() {
  // document.getElementById(`space-usage-expiration-date-filter`).focus();
}

const localLoanMaturityDate = ref(null);
const loanMaturityDateChanged = computed(() => {
  if (localLoanMaturityDate.value) {
    const newDateMoment = moment(localLoanMaturityDate.value);
    const existingMoment = moment(loanMaturityDateFilterInput.value);

    return !existingMoment.isSame(newDateMoment, "day");
  } else {
    return false;
  }
});
const loanMaturityDateIsValid = computed(() => {
  return dateValid(localLoanMaturityDate.value);
});

function cancelLoanMaturityEditing() {
  localLoanMaturityDate.value = null;

  if (loanMaturityDateFilterInput.value) {
    analyzePanelStore.setLoanMaturityDateEditing(false);
  }
}
function saveLoanMaturityDate() {
  analyzePanelStore.setLoanMaturityDate(moment(localLoanMaturityDate.value));
  analyzePanelStore.setLoanMaturityDateEditing(false);
}
function clearLoanMaturityDate() {
  analyzePanelStore.clearLoanMaturityDate();
  localLoanMaturityDate.value = null;
}

function populateExistingLoanMaturityDate() {
  if (loanMaturityDateFilterInput.value) {
    console.log("populate existing maturity");
    const existingMoment = loanMaturityDateFilterInput.value.clone();

    localLoanMaturityDate.value = existingMoment.format("YYYY-MM-DD");
  }
}

function focusLoanMaturityDateInput(isVisible) {
  if (isVisible) {
    setTimeout(() => {
      populateExistingLoanMaturityDate();
      selectLoanMaturityDateInput();
    }, 100);
  }
}
function selectLoanMaturityDateInput() {
  // document.getElementById(`loan-maturity-date-filter`).focus();
}
const uncachedCustomDate = ref({
  existingDate: null,
  dateType: null,
});
function resetUncachedCustomDate() {
  uncachedCustomDate.value = {
    existingDate: null,
    dateType: null,
  };
}
const futureBound = computed(() => {
  if (mainTimelineCustomForwardDate.value) {
    return moment(mainTimelineCustomForwardDate.value)
      .endOf("month")
      .diff(moment(), "months");
  } else if (routeName.value === "MainMap") {
    return 12;
  } else if (_.includes(["CompanyShell", "ContactShell"], routeName.value)) {
    return 36;
  } else {
    return 60;
  }
});
const pastBound = computed(() => {
  if (mainTimelineCustomLookbackDate.value) {
    return moment().diff(
      moment(mainTimelineCustomLookbackDate.value).startOf("month"),
      "months",
    );
  } else if (routeName.value === "MainMap") {
    return 1 * 12;
  } else if (_.includes(["CompanyShell", "ContactShell"], routeName.value)) {
    return 7 * 12;
  } else {
    return 10 * 12;
  }
});
const newest = computed(() => {
  return moment().add(futureBound.value, "months").endOf("month");
});
const newestDate = computed(() => {
  return newest.value.clone().toDate();
});
const oldest = computed(() => {
  return moment().subtract(pastBound.value, "months").startOf("month");
});
const oldestDate = computed(() => {
  return oldest.value.clone().toDate();
});

watch(valueFilterApplied, async (val, oldVal) => {
  if (val !== oldVal && !minValue.value && !maxValue.value) {
    valueDisplayReset.value = true;
    await nextTick();
    valueDisplayReset.value = false;
  }
});
watch(spaceAvailabilitySizeFilterApplied, async (val, oldVal) => {
  if (
    val !== oldVal &&
    !minSpaceAvailabilitySize.value &&
    !maxSpaceAvailabilitySize.value
  ) {
    spaceAvailabilitySizeDisplayReset.value = true;
    await nextTick();
    spaceAvailabilitySizeDisplayReset.value = false;
  }
});

onMounted(() => {
  if (selectedFilterCategories.value === 0) {
    expanded.value = true;
  }
});

function chooseCustomDate(dateType) {
  let existingDate;

  if (dateType === "customForwardDate") {
    existingDate = newestDate.value;
  } else if (dateType === "customLookbackDate") {
    existingDate = oldestDate.value;
  }

  uncachedCustomDate.value.existingDate = existingDate;
  uncachedCustomDate.value.dateType = dateType;
}
</script>
