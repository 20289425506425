<template>
  <PropertyRightDiagramColumn
    v-for="layer in allLayers"
    :key="layer.order"
    class="h-full w-full"
    :layer="layer"
    :internal-name="internalName"
    :property-id="propertyId"
  />
</template>

<script setup>
import { computed } from "vue";
import _ from "lodash";
import { usePropertyDiagramStore } from "@/stores/propertyDiagram";
import { storeToRefs } from "pinia";
import PropertyRightDiagramColumn from "@/components/property-diagram/PropertyRightDiagramColumn.vue";

const props = defineProps(["internalName", "propertyId"]);
const propertyDiagramStore = usePropertyDiagramStore();
const { renderableGridRows } = storeToRefs(propertyDiagramStore);
const rowObject = computed(() =>
  _.find(renderableGridRows.value, { internalName: props.internalName }),
);
const allLayers = computed(() => {
  const layers = _.get(rowObject.value, "layers", []);

  return _.orderBy(layers, ["order"], ["desc"]);
});
</script>
