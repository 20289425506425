<template>
  <div class="flex items-center space-x-2">
    <input
      v-observe-visibility="{ callback: focusInput, once: true }"
      v-model="date"
      type="date"
      name="date"
      id="timeline-custom-date"
      class="w-full rounded-full border-gray-300 pl-2 focus:border-indigo-500 focus:ring-indigo-500 text-xs"
    />
    <template v-if="date">
      <div class="flex items-center justify-end space-x-2">
        <button
          @click="cancel"
          type="button"
          class="inline-flex items-center p-1 border border-gray-300 rounded-full shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <XMarkIcon class="h-4 w-4" />
        </button>

        <button
          v-if="dateChanged && isValid"
          @click="save"
          v-tooltip="'Set date'"
          type="button"
          :disabled="!isValid"
          class="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          data-test="content-name-save"
        >
          <CheckIcon class="h-4 w-4" />
        </button>
      </div>
    </template>
  </div>
</template>

<script setup>
import { CheckIcon } from "@heroicons/vue/24/outline";
import { XMarkIcon } from "@heroicons/vue/20/solid";
import { ref, computed, onMounted } from "vue";
import { useTimeTravelStore } from "@/stores/timeTravel";
import { storeToRefs } from "pinia";
import dateValid from "@/assets/dateValid";
import moment from "moment";

const props = defineProps(["existingDate", "dateType", "timeTravelStore"]);
const emit = defineEmits(["cancel"]);
const timeTravelStore = props.timeTravelStore || useTimeTravelStore();
const { chosenDate } = storeToRefs(timeTravelStore);

const date = ref(null);
const dateChanged = computed(() => {
  if (date.value) {
    const newDateMoment = moment(date.value);
    const existingMoment = moment(props.existingDate);

    return !existingMoment.isSame(newDateMoment, "day");
  } else {
    return false;
  }
});
const isValid = computed(() => {
  return dateValid(date.value);
});

onMounted(() => {
  const existingMoment = moment(props.existingDate);

  date.value = existingMoment.format("YYYY-MM-DD");
});

function focusInput(isVisible) {
  if (isVisible) {
    setTimeout(() => {
      selectInput();
    }, 100);
  }
}
function selectInput() {
  document.getElementById(`timeline-custom-date`).focus();
}

function cancel() {
  emit("cancel");
}
function save() {
  if (isValid.value) {
    chosenDate.value = {
      date: moment(date.value),
      dateType: props.dateType,
    };

    setTimeout(() => cancel(), 200);
  }
}
</script>
