<template>
  <div>
    <ContentBody v-if="teams.length > 0">
      <li
        @click="createTeam"
        class="col-span-1 flex shadow-sm rounded-md cursor-pointer"
        data-test="create-team-button"
      >
        <div
          class="flex-shrink-0 flex items-center justify-center w-16 bg-gray-300 text-gray-500 text-sm font-medium rounded-l-md"
        >
          <PlusIcon class="h-6 w-6" />
        </div>
        <div
          class="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate"
        >
          <div class="flex-1 px-4 py-2 text-sm truncate">
            <span class="text-gray-900 font-medium">Create new team</span>
            <p class="text-gray-500">Build shared consciousness</p>
          </div>
        </div>
      </li>

      <li
        v-for="team in teams"
        :key="team.id"
        class="relative col-span-1 flex shadow-sm rounded-md"
        :data-test="`team-listing-${team.id}`"
      >
        <div
          @click="viewTeam(team)"
          :class="`flex-shrink-0 flex items-center justify-center w-16 ${roleColor(
            team
          )} text-white text-sm font-medium rounded-l-md cursor-pointer`"
        >
          <UserGroupIcon class="h-6 w-6" />
        </div>
        <div
          class="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate"
        >
          <div class="flex-1 px-4 py-2 text-sm truncate">
            <div class="flex items-center space-x-1">
              <div
                v-if="team.subsidizing"
                :data-test="`team-subsidizing-icon-${team.id}`"
              >
                <CurrencyDollarIcon
                  v-tooltip="'Paying for member usage'"
                  class="h-5 w-5 text-emerald-500"
                />
              </div>
              <div
                v-if="team.sharingAll"
                :data-test="`team-sharing-all-icon-${team.id}`"
              >
                <ShareIcon
                  v-tooltip="'Sharing all safezone data!'"
                  class="h-4 w-4 text-red-500"
                />
              </div>
              <div
                v-else-if="team.sharing"
                :data-test="`team-sharing-icon-${team.id}`"
              >
                <ShareIcon
                  v-tooltip="'Safezone sharing rules enabled'"
                  class="h-4 w-4 text-blue-500"
                />
              </div>
              <a
                href=""
                @click.prevent="viewTeam(team)"
                class="text-gray-900 font-medium hover:text-gray-600"
                :data-test="`team-name-${team.id}`"
                >{{ team.name }}</a
              >
            </div>
            <p class="text-gray-500" :data-test="`team-info-${team.id}`">
              {{ memberCount(team) }}&nbsp;&middot;&nbsp;{{ team.role }}
            </p>
          </div>
          <TeamMenu
            :team="team"
            @refetch="fetchTeams"
            @edit="editTeam(team)"
            @view-details="viewTeam(team)"
          />
        </div>
      </li>
    </ContentBody>

    <div v-else class="p-2">
      <button
        @click="createTeam"
        type="button"
        class="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        data-test="create-first-team-button"
      >
        <OutlineUserGroupIcon class="mx-auto h-12 w-12 text-gray-400" />
        <span class="mt-2 block text-sm font-medium text-gray-900"
          >Create a new team</span
        >
      </button>
    </div>
  </div>
</template>

<script setup>
import { CurrencyDollarIcon, ShareIcon } from "@heroicons/vue/24/solid";
import ContentBody from "@/components/main-layout/secondary-panel/ContentBody.vue";
import TeamMenu from "@/components/users/teams/TeamMenu.vue";
import { onMounted, ref, watch } from "vue";
import { useUserStore } from "@/stores/user";
import { useSecondaryPanelStore } from "@/stores/secondaryPanel";
import { storeToRefs } from "pinia";
import api from "@/router/api";
import pluralize from "pluralize";
import { PlusIcon, UserGroupIcon } from "@heroicons/vue/20/solid";
import { UserGroupIcon as OutlineUserGroupIcon } from "@heroicons/vue/24/outline";

const emit = defineEmits(["create-team", "edit-team", "view-team-details"]);
const teams = ref([]);

const userStore = useUserStore();
const { signedIn } = storeToRefs(userStore);
const secondaryPanelStore = useSecondaryPanelStore();

watch(signedIn, (val, oldVal) => {
  if (val && val !== oldVal) {
    fetchTeams();
  }
});

onMounted(() => {
  fetchTeams();
});

function createTeam() {
  if (signedIn.value) {
    emit("create-team");
  } else {
    secondaryPanelStore.promptToRegister();
  }
}
function viewTeam(team) {
  emit("view-team-details", team.id);
}
function editTeam(team) {
  emit("edit-team", team.id);
}
function memberCount(team) {
  return pluralize("Member", team.memberCount, true);
}
function roleColor(team) {
  switch (team.role) {
    case "Primary owner":
      return "bg-yellow-400";
    default:
      return "bg-indigo-600";
  }
}
function fetchTeams() {
  if (signedIn.value) {
    api.get(`teams`).then((json) => {
      teams.value = json.data;
    });
  }
}
</script>
