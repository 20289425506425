<template>
  <div
    @click.stop="clicked"
    :data-test="testKey"
    :class="
      dataField
        ? `truncate pointer-events-auto flex flex-grow items-start justify-start my-0.5 mx-2 p-2 rounded-lg ${outerStyling}`
        : 'mx-2 my-0.5'
    "
  >
    <template v-if="dataField">
      <div class="w-full flex justify-between">
        <ul class="flex space-x-1">
          <VMenu
            theme="diagram-popup"
            :disabled="editingMode"
            v-for="enhancement in enhancements"
            :key="enhancement.localId"
            :data-test="`${testKey}-enhancement-${enhancement.fieldContentId}`"
            class="h-3 w-3"
          >
            <SparklesIcon
              @click.stop="propertyEnhancementClickHandler(enhancement)"
              :class="`h-3 w-3 rounded-sm ${propertyEnhancementColorStyle(
                enhancement,
                propertyDiagramSelected,
              )}`"
            />
            <template #popper>
              <DataFieldInfoPopup :data-field="enhancement" />
            </template>
          </VMenu>
        </ul>
      </div>
    </template>
  </div>
</template>

<script setup>
import { usePropertyDiagramStore } from "@/stores/propertyDiagram";
import { useDealBuilderStore } from "@/stores/dealBuilder";
import { storeToRefs } from "pinia";
import propertyEnhancementColorStyle from "@/components/property-diagram/propertyEnhancementColorStyles";
import { computed, onMounted } from "vue";
import _ from "lodash";
import DataFieldInfoPopup from "@/components/crowdsourcing/DataFieldInfoPopup.vue";
import { SparklesIcon } from "@heroicons/vue/20/solid";

const props = defineProps([
  "dataField",
  "propertyId",
  "rightType",
  "backgroundStyle",
  "enhancements",
  "testKey",
]);
const emit = defineEmits(["clicked", "refetch", "property-enhancement-click"]);
const propertyDiagramStore = usePropertyDiagramStore();
const { propertyDiagramSelected, selectedTimelineEvent, editingMode } =
  storeToRefs(propertyDiagramStore);
const dealBuilderStore = useDealBuilderStore();

const outerStyling = computed(() => {
  return (
    props.backgroundStyle ||
    "text-gray-500 font-medium border-2 border-gray-300 bg-slate-100"
  );
});

onMounted(() => timelineSelectionHandler());

function timelineSelectionHandler() {
  if (!selectedTimelineEvent.value) return;
  if (!props.dataField) return;

  if (selectedTimelineEvent.value.eventType === "Investment") {
    dealBuilderStore.loadPropertyRightTimelineInvestment({
      propertyRightDataField: props.dataField,
    });
  } else {
    const timelineContentType =
      selectedTimelineEvent.value.dataField.decoratingContentType;
    const timelineContentId =
      selectedTimelineEvent.value.dataField.decoratingContentId;

    if (
      timelineContentType === "PropertyRight" &&
      timelineContentId === props.dataField.fieldContentId
    ) {
      clicked();
    }
  }
}

function clicked() {
  if (props.dataField) {
    emit("clicked");
  }
}
function propertyEnhancementClickHandler(dataField) {
  if (dataField) {
    emit("property-enhancement-click", {
      propertyId: props.propertyId,
      data: _.merge(
        {},
        {
          dataField,
          recordType: "PropertyEnhancement",
        },
      ),
    });
  }
}
</script>
