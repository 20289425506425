import ComingSoon from "@/components/ComingSoon.vue";
import ContentAppearance from "@/components/crowdsourcing/ContentAppearance.vue";
import ContentContactInfo from "@/components/crowdsourcing/ContentContactInfo.vue";
import ContentCapitalStack from "@/components/crowdsourcing/ContentCapitalStack.vue";
import ContentSpaceUsageStack from "@/components/crowdsourcing/ContentSpaceUsageStack.vue";
import ContentFiles from "@/components/crowdsourcing/ContentFiles.vue";
import ContentHighlight from "@/components/collectible-cards/ContentHighlight.vue";
import ContentPlayers from "@/components/crowdsourcing/ContentPlayers.vue";
import ContentPolygons from "@/components/crowdsourcing/ContentPolygons.vue";
import ContentPropertyUses from "@/components/crowdsourcing/ContentPropertyUses.vue";
import ContentNotes from "@/components/crowdsourcing/ContentNotes.vue";
import ContentTiming from "@/components/crowdsourcing/ContentTiming.vue";
import FloorAreaLayoutPartitions from "@/components/crowdsourcing/FloorAreaLayoutPartitions.vue";
import FloorAreaSizes from "@/components/crowdsourcing/FloorAreaSizes.vue";
import LandCoveringPhysical from "@/components/crowdsourcing/LandCoveringPhysical.vue";
import LandCoveringLevelHeight from "@/components/crowdsourcing/LandCoveringLevelHeight.vue";
import ContentLocation from "@/components/crowdsourcing/ContentLocation.vue";
import OccupierNeeds from "@/components/crowdsourcing/OccupierNeeds.vue";
import ParcelLocation from "@/components/crowdsourcing/ParcelLocation.vue";
import HuntLocations from "@/components/crowdsourcing/HuntLocations.vue";
import HuntInvestmentFilters from "@/components/crowdsourcing/HuntInvestmentFilters.vue";
import PhotosList from "@/components/crowdsourcing/PhotosList.vue";
import PropertyRightSize from "@/components/crowdsourcing/PropertyRightSize.vue";
import { markRaw } from "vue";
import _ from "lodash";

export function initialSelectedTabFor(dataField) {
  switch (_.get(dataField, "fieldContentType")) {
    case "PropertyRight": {
      if (
        _.includes(
          [
            "Fee simple",
            "fee_simple",
            "Leasehold",
            "leasehold",
            "Condominium",
            "condominium",
            "Co-op",
            "co_op",
          ],
          _.get(dataField, "fieldContent.type"),
        )
      ) {
        return _.get(rawTabs, "capitalStack");
      } else {
        return _.get(rawTabs, "appearance");
      }
    }
    case "LandCovering":
    case "LandCoveringGrouping":
    case "LandCoveringLevel":
    case "LandCoveringDiagramVerticalGrouping":
    case "FloorAreaLayout":
    case "PropertyEnhancement":
      return _.get(rawTabs, "appearance");
    case "FloorArea":
      return _.get(rawTabs, "uses");
    case "CompanyInvolvement":
    case "Property":
    case "Company":
    case "Contact":
    case "Hunt":
      return _.get(rawTabs, "highlight");
    default:
      return _.get(rawTabs, "comingSoon");
  }
}

export function tabsFor(dataField) {
  switch (_.get(dataField, "fieldContentType")) {
    case "PropertyRight":
      if (
        _.includes(
          ["Fee simple", "fee_simple"],
          _.get(dataField, "fieldContent.type"),
        )
      ) {
        return [
          _.get(rawTabs, "capitalStack"),
          _.get(rawTabs, "parcelLocation"),
          _.get(rawTabs, "propertyRightSize"),
          _.get(rawTabs, "appearance"),
          _.get(rawTabs, "timing"),
          _.get(rawTabs, "notes"),
          _.get(rawTabs, "files"),
          _.get(rawTabs, "photos"),
        ];
      } else if (
        _.includes(
          [
            "Leasehold",
            "leasehold",
            "Condominium",
            "condominium",
            "Co-op",
            "co_op",
          ],
          _.get(dataField, "fieldContent.type"),
        )
      ) {
        return [
          _.get(rawTabs, "capitalStack"),
          _.get(rawTabs, "appearance"),
          _.get(rawTabs, "timing"),
          _.get(rawTabs, "notes"),
          _.get(rawTabs, "files"),
          _.get(rawTabs, "photos"),
        ];
      } else if (
        _.includes(
          ["Unused Development Rights"],
          _.get(dataField, "fieldContent.type"),
        )
      ) {
        return [
          _.get(rawTabs, "capitalStack"),
          _.get(rawTabs, "appearance"),
          _.get(rawTabs, "timing"),
          _.get(rawTabs, "notes"),
          _.get(rawTabs, "files"),
        ];
      } else {
        return [
          _.get(rawTabs, "appearance"),
          _.get(rawTabs, "notes"),
          _.get(rawTabs, "files"),
        ];
      }
    case "LandCovering":
      if (_.get(dataField, "fieldContentSubType") === "surface_structure") {
        return [
          _.get(rawTabs, "appearance"),
          _.get(rawTabs, "timing"),
          _.get(rawTabs, "location"),
          _.get(rawTabs, "notes"),
          _.get(rawTabs, "files"),
          _.get(rawTabs, "photos"),
        ];
      } else {
        return [
          _.get(rawTabs, "appearance"),
          _.get(rawTabs, "timing"),
          _.get(rawTabs, "location"),
          _.get(rawTabs, "physical"),
          _.get(rawTabs, "notes"),
          _.get(rawTabs, "files"),
          _.get(rawTabs, "photos"),
        ];
      }
    case "LandCoveringLevel":
      return [
        _.get(rawTabs, "appearance"),
        _.get(rawTabs, "levelHeight"),
        _.get(rawTabs, "notes"),
      ];
    case "LandCoveringDiagramVerticalGrouping":
      return [_.get(rawTabs, "appearance"), _.get(rawTabs, "notes")];
    case "LandCoveringGrouping":
      return [
        _.get(rawTabs, "appearance"),
        _.get(rawTabs, "notes"),
        _.get(rawTabs, "files"),
        _.get(rawTabs, "photos"),
      ];
    case "PropertyEnhancement":
      return [
        _.get(rawTabs, "appearance"),
        _.get(rawTabs, "timing"),
        _.get(rawTabs, "spaceUsageStack"),
        _.get(rawTabs, "notes"),
        _.get(rawTabs, "files"),
        _.get(rawTabs, "photos"),
      ];
    case "Property":
      return [
        _.get(rawTabs, "highlight"),
        _.get(rawTabs, "appearance"),
        _.get(rawTabs, "location"),
        _.get(rawTabs, "notes"),
        _.get(rawTabs, "files"),
        _.get(rawTabs, "photos"),
      ];
    case "FloorArea":
      return [
        _.get(rawTabs, "uses"),
        _.get(rawTabs, "sizes"),
        _.get(rawTabs, "spaceUsageStack"),
        _.get(rawTabs, "appearance"),
        _.get(rawTabs, "timing"),
        _.get(rawTabs, "notes"),
        _.get(rawTabs, "files"),
      ];
    case "FloorAreaLayout":
      return [
        _.get(rawTabs, "appearance"),
        _.get(rawTabs, "layoutPartitions"),
        _.get(rawTabs, "timing"),
        _.get(rawTabs, "notes"),
        _.get(rawTabs, "files"),
        _.get(rawTabs, "photos"),
      ];
    case "LayoutPartition":
      return [
        _.get(rawTabs, "sizes"),
        _.get(rawTabs, "spaceUsageStack"),
        _.get(rawTabs, "appearance"),
        _.get(rawTabs, "notes"),
        _.get(rawTabs, "files"),
      ];
    case "Company":
      return [
        _.get(rawTabs, "highlight"),
        _.get(rawTabs, "appearance"),
        _.get(rawTabs, "notes"),
        _.get(rawTabs, "files"),
        _.get(rawTabs, "photos"),
      ];
    case "Contact":
      return [
        _.get(rawTabs, "highlight"),
        _.get(rawTabs, "appearance"),
        _.get(rawTabs, "contactInfo"),
        _.get(rawTabs, "notes"),
        _.get(rawTabs, "files"),
        _.get(rawTabs, "photos"),
      ];
    case "CompanyInvolvement": {
      switch (_.get(dataField, "fieldContentSubType")) {
        case "operational_advisor":
          return [
            _.get(rawTabs, "highlight"),
            _.get(rawTabs, "timing"),
            _.get(rawTabs, "notes"),
          ];
        default: {
          if (
            _.includes(
              ["Hunt", "HuntGeographyIntent"],
              _.get(dataField, "decoratingContentType"),
            )
          ) {
            const thirdParty =
              dataField.fieldContent?.name !==
              dataField.fieldContent?.huntClient?.name;
            return _.compact([
              _.get(rawTabs, "highlight"),
              thirdParty ? _.get(rawTabs, "timing") : null,
              dataField.decoratingContentType === "Hunt"
                ? _.get(rawTabs, "huntLocations")
                : _.get(rawTabs, "mapRegions"),
              _.get(rawTabs, "notes"),
            ]);
          } else {
            return [_.get(rawTabs, "highlight"), _.get(rawTabs, "notes")];
          }
        }
      }
    }
    case "Hunt":
      return [
        _.get(rawTabs, "highlight"),
        _.get(rawTabs, "huntLocations"),
        _.get(rawTabs, "timing"),
        _.get(rawTabs, "players"),
        _.get(rawTabs, "occupier"),
        _.get(rawTabs, "huntInvestmentCriteria"),
        _.get(rawTabs, "appearance"),
        _.get(rawTabs, "notes"),
      ];
    default:
      return [_.get(rawTabs, "comingSoon")];
  }
}

export function tabPropertiesFor(dataField) {
  return {
    dataField,
    contentDataField: dataField,
    focus: false,
    context: "detailsTab",
  };
}

export const rawTabs = {
  appearance: {
    name: "Appearance",
    tabQuery: "Appearance",
    href: "",
    svgPaths: [
      "M10 6H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V8a2 2 0 00-2-2h-5m-4 0V5a2 2 0 114 0v1m-4 0a2 2 0 104 0m-5 8a2 2 0 100-4 2 2 0 000 4zm0 0c1.306 0 2.417.835 2.83 2M9 14a3.001 3.001 0 00-2.83 2M15 11h3m-3 4h2",
    ],
    component: markRaw(ContentAppearance),
  },
  contactInfo: {
    name: "Contact Info",
    tabQuery: "ContactInfo",
    href: "",
    svgPaths: [
      "M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z",
    ],
    component: markRaw(ContentContactInfo),
  },
  capitalStack: {
    name: "Capital Stack",
    tabQuery: "CapitalStack",
    href: "",
    svgPaths: ["M3 4h13M3 8h9m-9 4h9m5-4v12m0 0l-4-4m4 4l4-4"],
    component: markRaw(ContentCapitalStack),
  },
  spaceUsageStack: {
    name: "Space Usages",
    tabQuery: "SpaceUsageStack",
    href: "",
    svgPaths: [
      "m21 7.5-9-5.25L3 7.5m18 0-9 5.25m9-5.25v9l-9 5.25M3 7.5l9 5.25M3 7.5v9l9 5.25m0-9v9",
    ],
    component: markRaw(ContentSpaceUsageStack),
  },
  files: {
    name: "Files",
    tabQuery: "Files",
    href: "",
    svgPaths: [
      "M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z",
    ],
    component: markRaw(ContentFiles),
  },
  notes: {
    name: "Notes",
    tabQuery: "Notes",
    href: "",
    svgPaths: [
      "M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z",
    ],
    component: markRaw(ContentNotes),
  },
  photos: {
    name: "Photos",
    tabQuery: "Photos",
    href: "",
    svgPaths: [
      "M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z",
    ],
    component: markRaw(PhotosList),
  },
  layoutPartitions: {
    name: "Rooms",
    tabQuery: "Rooms",
    href: "",
    svgPaths: [
      "M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4",
    ],
    component: markRaw(FloorAreaLayoutPartitions),
  },
  location: {
    name: "Location",
    tabQuery: "Location",
    href: "",
    svgPaths: [
      "M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z",
    ],
    component: markRaw(ContentLocation),
  },
  parcelLocation: {
    name: "Location",
    tabQuery: "Location",
    href: "",
    svgPaths: [
      "M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z",
    ],
    component: markRaw(ParcelLocation),
  },
  propertyRightSize: {
    name: "Size",
    tabQuery: "Size",
    href: "",
    svgPaths: [
      "M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5l-5-5m5 5v-4m0 4h-4",
    ],
    component: markRaw(PropertyRightSize),
  },
  physical: {
    name: "Physical",
    tabQuery: "Physical",
    href: "",
    svgPaths: [
      "M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5l-5-5m5 5v-4m0 4h-4",
    ],
    component: markRaw(LandCoveringPhysical),
  },
  levelHeight: {
    name: "Height",
    tabQuery: "Height",
    href: "",
    svgPaths: [
      "M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5l-5-5m5 5v-4m0 4h-4",
    ],
    component: markRaw(LandCoveringLevelHeight),
  },
  sizes: {
    name: "Sizes",
    tabQuery: "Sizes",
    href: "",
    svgPaths: [
      "M4 5a1 1 0 011-1h14a1 1 0 011 1v2a1 1 0 01-1 1H5a1 1 0 01-1-1V5zM4 13a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H5a1 1 0 01-1-1v-6zM16 13a1 1 0 011-1h2a1 1 0 011 1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-6z",
    ],
    component: markRaw(FloorAreaSizes),
  },
  huntInvestmentCriteria: {
    name: "Investment Criteria",
    tabQuery: "InvestmentCriteria",
    href: "",
    svgPaths: [
      "M7.5 21 3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5",
    ],
    component: markRaw(HuntInvestmentFilters),
  },
  huntLocations: {
    name: "Target Markets",
    tabQuery: "TargetMarkets",
    href: "",
    svgPaths: [
      "M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z",
    ],
    component: markRaw(HuntLocations),
  },
  mapRegions: {
    name: "Map Regions",
    tabQuery: "MapRegions",
    href: "",
    svgPaths: [
      "M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z",
    ],
    component: markRaw(ContentPolygons),
  },
  players: {
    name: "Players",
    tabQuery: "Players",
    href: "",
    svgPaths: [
      "M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z",
    ],
    component: markRaw(ContentPlayers),
  },
  occupier: {
    name: "Occupier Needs",
    tabQuery: "Occupier",
    href: "",
    svgPaths: [
      "M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3",
    ],
    component: markRaw(OccupierNeeds),
  },
  uses: {
    name: "Uses",
    tabQuery: "Uses",
    href: "",
    svgPaths: [
      "M7 11.5V14m0-2.5v-6a1.5 1.5 0 113 0m-3 6a1.5 1.5 0 00-3 0v2a7.5 7.5 0 0015 0v-5a1.5 1.5 0 00-3 0m-6-3V11m0-5.5v-1a1.5 1.5 0 013 0v1m0 0V11m0-5.5a1.5 1.5 0 013 0v3m0 0V11",
    ],
    component: markRaw(ContentPropertyUses),
  },
  timing: {
    name: "Timing",
    tabQuery: "Timing",
    href: "",
    svgPaths: ["M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"],
    component: markRaw(ContentTiming),
  },
  highlight: {
    name: "Highlight",
    tabQuery: "Highlight",
    href: "",
    svgPaths: [
      "M3 3v1.5M3 21v-6m0 0l2.77-.693a9 9 0 016.208.682l.108.054a9 9 0 006.086.71l3.114-.732a48.524 48.524 0 01-.005-10.499l-3.11.732a9 9 0 01-6.085-.711l-.108-.054a9 9 0 00-6.208-.682L3 4.5M3 15V4.5",
    ],
    component: markRaw(ContentHighlight),
  },
  comingSoon: {
    name: "Coming Soon",
    tabQuery: "ComingSoon",
    href: "",
    svgPaths: [
      "M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z",
    ],
    component: markRaw(ComingSoon),
  },
};
