<template>
  <section class="h-full w-full flex flex-col overflow-hidden">
    <ContentHeader
      :help-article="helpArticle"
      :no-margin="true"
      id="filter-header"
    >
      <template v-slot:title>Filter</template>
      <template v-slot:description
        >Display
        <template v-if="surveyUnlockableBundleFieldIds.length > 0"
          >and unlock</template
        >
        specific data.</template
      >
      <template v-slot:secondary>
        <FilterUnlocker />
      </template>
    </ContentHeader>

    <div class="flex-grow overflow-y-auto">
      <FilterSettings class="sticky top-0" />

      <FilterStats />

      <FilterHuntsTable v-if="huntsChecked" />

      <NearbyCompanies v-if="routeName === 'MainMap' && companiesChecked" />

      <FilterSpaceAvailabilitiesTable v-if="spaceAvailabilitiesChecked" />

      <FilterSpaceUsagesTable v-if="spaceUsagesChecked" />

      <FilterInvestmentsTable v-if="investmentsChecked" />

      <FilterLoansTable v-if="loansChecked" />

      <FilterPropertyTable v-if="rollUpView && sizesChecked" />

      <section v-if="selectedFilterCategories === 0">
        <div
          class="relative block w-full rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <FunnelIcon class="mx-auto h-12 w-12 text-gray-400" />
          <span class="mt-2 block text-sm font-medium text-gray-900">
            Choose filter categories to get started.
          </span>
        </div>
      </section>
    </div>
  </section>
</template>

<script setup>
import ContentHeader from "@/components/main-layout/secondary-panel/ContentHeader.vue";
import FilterSettings from "@/components/analyze/FilterSettings.vue";
import FilterStats from "@/components/analyze/FilterStats.vue";
import FilterPropertyTable from "@/components/analyze/FilterPropertyTable.vue";
import { useAnalyzePanelStore } from "@/stores/analyzePanel";
import { storeToRefs } from "pinia";
import { surveyProperties } from "@/assets/documentation/articles";
import { useRoute } from "vue-router";
import { computed } from "vue";
import NearbyCompanies from "@/components/maps/NearbyCompanies.vue";
import FilterInvestmentsTable from "@/components/analyze/FilterInvestmentsTable.vue";
import FilterSpaceAvailabilitiesTable from "@/components/analyze/FilterSpaceAvailabilitiesTable.vue";
import FilterSpaceUsagesTable from "@/components/analyze/FilterSpaceUsagesTable.vue";
import FilterLoansTable from "@/components/analyze/FilterLoansTable.vue";
import FilterHuntsTable from "@/components/analyze/FilterHuntsTable.vue";
import FilterUnlocker from "@/components/analyze/FilterUnlocker.vue";
import { FunnelIcon } from "@heroicons/vue/20/solid";
import _ from "lodash";

const analyzePanelStore = useAnalyzePanelStore();
const {
  selectedFilterCategories,
  sizesChecked,
  companiesChecked,
  spaceAvailabilitiesChecked,
  spaceUsagesChecked,
  investmentsChecked,
  loansChecked,
  huntsChecked,
  surveyUnlockableBundleFieldIds,
  unselectedPropertyDiagram,
} = storeToRefs(analyzePanelStore);

const route = useRoute();
const routeName = computed(() => route.name);

const helpArticle = computed(() => {
  switch (routeName.value) {
    case "MainMap":
    default:
      return surveyProperties;
  }
});

const rollUpView = computed(
  () =>
    _.includes(["MainMap", "CompanyShell", "ContactShell"], routeName.value) ||
    unselectedPropertyDiagram.value,
);
</script>
