<template>
  <div>
    <div class="p-2 bg-blue-50 border rounded-md">
      <dt class="flex items-center space-x-2">
        <ShieldExclamationIcon class="h-6 w-6 text-blue-400" />
        <span class="text-sm font-medium text-gray-900">Before you vote:</span>
      </dt>
      <ContentComponent
        v-if="displayable && changeType === 'addition'"
        :id="`${typedTestIndex}-guidance`"
        class="mt-1 prose prose-sm prose-a:text-indigo-700 max-w-none"
      />
      <dd
        v-else-if="changeType === 'removal'"
        class="mt-1 text-sm text-blue-700"
      >
        Make sure this data should be <strong>removed</strong>.
      </dd>
    </div>
  </div>
</template>

<script setup>
import Markdoc from "@markdoc/markdoc";
import render from "vue-markdoc";
import { config } from "@/assets/documentation/markdoc.config";
import { ShieldExclamationIcon } from "@heroicons/vue/24/solid";
import guidelineFor from "@/assets/dataFieldValidationGuidance";
import { computed, markRaw, onMounted, ref } from "vue";

const props = defineProps(["typedTestIndex", "metaKey", "changeType"]);

const displayable = ref(false);
const ContentComponent = ref(null);
const guideline = computed(() => guidelineFor(props.metaKey));
const configWithArticle = computed(() => {
  let newConfig = config;

  newConfig.variables.article = guideline.value;
  newConfig.variables.metaKey = props.metaKey;

  return newConfig;
});
const doc = `
{% callout type="caution" %}
Write guidelines for {% $metaKey %}
{% /callout %}
`;

onMounted(() => {
  refreshMarkdown(0);
});

function refreshMarkdown(timeout = 500) {
  displayable.value = false;

  setTimeout(() => {
    const ast = Markdoc.parse(guideline.value.markdown || doc);

    const content = Markdoc.transform(ast, configWithArticle.value);
    ContentComponent.value = markRaw(render(content));
    displayable.value = true;
  }, timeout);
}
</script>

<style scoped>
:deep(
    .prose-sm
      :where(.prose-sm > ul > li > *:first-child):not(
        :where([class~="not-prose"] *)
      )
  ) {
  margin-top: 0rem !important;
}
:deep(
    .prose
      :where(.prose > ul > li > *:first-child):not(
        :where([class~="not-prose"] *)
      )
  ) {
  margin-top: 0rem !important;
}
</style>
