<template>
  <section aria-labelledby="location">
    <ContentHeader>
      <template v-slot:title>Location</template>
      <template v-slot:description
        >Show exactly where this parcel is in the world.</template
      >
    </ContentHeader>

    <ContentBody v-if="joiningPropertyLocationDataField">
      <ParcelLocationEdit
        v-if="editing"
        :decorating-data-field="dataField"
        :polygon-data-field="polygonDataField"
        :joining-property-data-field="joiningPropertyLocationDataField"
        @completed="completed"
        @cancel="cancel"
      />
      <div v-else-if="contentPolygon" class="">
        <div class="">
          <DataField
            :data-field="polygonDataField"
            text-classes="text-sm"
            @suggest-change="suggestChange"
            @completed="completed"
          />
          <ParcelMap
            :polygon="contentPolygon"
            :nearby="true"
            class="mt-5 h-64 w-full"
          />
        </div>
      </div>

      <div v-else class="flex items-center">
        <DataVisibilityButton>
          <template v-slot:button>
            <button
              @click="editing = true"
              type="button"
              class="group py-1 px-1.5 flex items-center justify-between rounded-full border border-gray-300 shadow-sm space-x-2 text-left hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <span class="min-w-0 flex-1 flex items-center space-x-1">
                <span class="flex-shrink-0 flex items-center">
                  <span
                    class="inline-flex items-center justify-center h-5 w-5 rounded-full bg-indigo-500"
                  >
                    <i class="text-sm text-white fas fa-draw-polygon"></i>
                  </span>
                </span>
                <span class="min-w-0 flex-1">
                  <span class="text-sm font-medium text-gray-900 truncate"
                    >Add parcel boundaries</span
                  >
                </span>
              </span>
            </button>
          </template>
        </DataVisibilityButton>
      </div>
    </ContentBody>
  </section>
</template>

<script setup>
import { ref, computed, watch, onMounted } from "vue";
import ContentBody from "@/components/main-layout/secondary-panel/ContentBody.vue";
import ContentHeader from "@/components/main-layout/secondary-panel/ContentHeader.vue";
import DataField from "@/components/crowdsourcing/DataField.vue";
import ParcelLocationEdit from "@/components/crowdsourcing/ParcelLocationEdit.vue";
import ParcelMap from "@/components/maps/ParcelMap.vue";
import api from "@/router/api";
import decoratingAndFieldKey from "@/components/crowdsourcing/decoratingAndFieldKey";
import _ from "lodash";
import DataVisibilityButton from "@/components/crowdsourcing/DataVisibilityButton.vue";

const props = defineProps(["dataField"]);
const editing = ref(false);
const polygonDataField = ref(null);
const joiningPropertyLocationDataField = ref(null);
const loaded = ref(false);

const content = computed(() => {
  return props.dataField.fieldContent;
});
const contentPolygon = computed(() => {
  return _.get(polygonDataField.value, "fieldContent", null);
});
const combinedId = computed(() => {
  return decoratingAndFieldKey(props.dataField);
});
const joiningPropertyId = computed(() => {
  if (props.dataField.joiningContentType === "Property") {
    return props.dataField.joiningContentId;
  } else {
    return null;
  }
});

watch(combinedId, () => {
  fetchDataField();
});
watch(loaded, () => {
  if (loaded.value && !polygonDataField.value && joiningPropertyId.value) {
    fetchJoiningPropertyDataField();
  }
});

onMounted(() => fetchDataField());

function fetchDataField() {
  api
    .get(
      `crowdsourced_data_fields/${props.dataField.fieldContentType}/${content.value.id}?field_name=ContentPolygon`
    )
    .then((json) => {
      loaded.value = false;
      polygonDataField.value = null;

      setTimeout(() => {
        polygonDataField.value = json.data;
      }, 50);
      loaded.value = true;
    });
}
function fetchJoiningPropertyDataField() {
  api
    .get(
      `crowdsourced_data_fields/Property/${joiningPropertyId.value}?field_name=ContentLocation`
    )
    .then((json) => {
      joiningPropertyLocationDataField.value = null;

      setTimeout(() => {
        joiningPropertyLocationDataField.value = json.data;
      }, 50);
    });
}
function completed() {
  editing.value = false;
  fetchDataField();
}
function suggestChange() {
  editing.value = true;
}
function cancel() {
  editing.value = false;
}
</script>
