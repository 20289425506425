/* global analytics */
import { createRouter, createWebHistory } from "vue-router";
import { useEasyDataInputAdminStore } from "@/stores/easyDataInputAdmin";
import { useModalStore } from "@/stores/modal";
import MainView from "@/views/MainView.vue";
import api from "@/router/api";
import { storeToRefs } from "pinia";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/why/:featureId",
      name: "WhyTowerHunt",
      props: true,
      component: () => import("@/components/marketing/WhyTowerHunt.vue"),
    },
    {
      path: "/help/categories/:categoryId",
      name: "HelpCategories",
      props: true,
      component: () => import("@/views/HelpCategories.vue"),
    },
    {
      path: "/help/articles/:articleId",
      name: "HelpArticle",
      props: true,
      component: () => import("@/views/HelpArticle.vue"),
    },
    {
      path: "/help",
      name: "HelpCenter",
      component: () => import("@/views/HelpCenter.vue"),
    },
    {
      path: "/legal",
      name: "LegalShell",
      component: () => import("@/views/LegalShell.vue"),
      children: [
        {
          path: "terms-of-service",
          name: "TermsOfService",
          component: () => import("@/components/legal/TermsOfService.vue"),
        },
        {
          path: "privacy-policy",
          name: "PrivacyPolicy",
          component: () => import("@/components/legal/PrivacyPolicy.vue"),
        },
        {
          path: "",
          name: "LegalCenter",
          component: () => import("@/components/legal/LegalCenter.vue"),
        },
      ],
    },
    {
      path: "/sign-in",
      name: "SignIn",
      component: () => import("@/views/SignIn.vue"),
    },
    {
      path: "/register",
      name: "Register",
      component: () => import("@/views/RegisterVue.vue"),
    },
    {
      path: "/forgot-password",
      name: "ForgotPassword",
      component: () => import("@/views/ForgotPassword.vue"),
    },
    {
      path: "/password-resets/:token",
      name: "ResetPassword",
      props: true,
      component: () => import("@/views/ResetPassword.vue"),
    },
    {
      path: "/email-verifications/:token",
      name: "VerifyEmail",
      props: true,
      component: () => import("@/views/VerifyEmail.vue"),
    },
    {
      path: "/onboard-connected-account",
      component: () => import("@/views/OnboardConnectedAccountHandler.vue"),
      meta: { requiresAuth: true },
    },
    {
      path: "/onboard-shared-payout-account/:accountId",
      component: () => import("@/views/OnboardSharedPayoutAccountHandler.vue"),
      props: true,
      meta: { requiresAuth: true },
    },
    {
      path: "/easy-data",
      name: "DataEasyButton",
      component: () => import("@/views/EasyButtonDataForm.vue"),
      meta: { requiresAuth: true },
    },
    {
      path: "/",
      component: MainView,
      children: [
        {
          path: "admin",
          name: "AdminDashboard",
          component: () => import("@/views/AdminDashboard.vue"),
          meta: { requiresAuth: true, requiresAdmin: true },
          children: [
            {
              path: "dashboard",
              name: "AdminStats",
              component: () => import("@/views/admin/AdminStats.vue"),
            },
            {
              path: "data-input-jobs",
              name: "DataInputJobsAdmin",
              component: () => import("@/views/admin/DataInputJobsAdmin.vue"),
            },
            {
              path: "validations",
              name: "ValidationsAdmin",
              component: () => import("@/views/admin/ValidationsAdmin.vue"),
            },
            {
              path: "companies",
              name: "CompaniesAdmin",
              component: () => import("@/views/admin/CompaniesAdmin.vue"),
            },
            {
              path: "people",
              name: "PeopleAdmin",
              component: () => import("@/views/admin/PeopleAdmin.vue"),
            },
          ],
        },
        {
          path: "companies/:companyId",
          name: "CompanyShell",
          component: () =>
            import("@/components/company-detail/CompanyShell.vue"),
          props: true,
        },
        {
          path: "contacts/:contactId",
          name: "ContactShell",
          component: () =>
            import("@/components/contact-detail/ContactShell.vue"),
          props: true,
        },
        {
          path: "properties/:propertyId",
          name: "PropertyShell",
          component: () =>
            import("@/components/property-diagram/PropertyShell.vue"),
          props: true,
        },
        {
          path: "",
          name: "MainMap",
          component: () => import("@/components/maps/MainMap.vue"),
        },
      ],
    },
  ],
});

router.beforeEach(async (to, from) => {
  const easyDataInputAdminStore = useEasyDataInputAdminStore();
  const { globalUnsaved } = storeToRefs(easyDataInputAdminStore);
  const modalStore = useModalStore();
  const { confirmationPayload } = storeToRefs(modalStore);

  if (to.matched.length === 0) {
    console.log("unmatched route", to.fullPath);
    return { name: "MainMap" };
  } else if (to.meta.requiresAuth) {
    const noUserToken = localStorage.getItem("token") == null;

    if (noUserToken) {
      return { name: "SignIn", query: { redirect: to.fullPath } };
    } else if (to.meta.requiresAdmin) {
      const result = await api.get("whoami");

      if (result.data.admin || !!localStorage.getItem("adminToken")) {
        if (from.name === "DataInputJobsAdmin" && globalUnsaved.value) {
          const output = await new Promise((resolve) => {
            confirmationPayload.value = {
              title: "Unsaved job changes",
              message:
                "Navigating away from the Data Input Jobs page will abandon your changes. Proceed?",
              affirmText: "Go",
              affirmCallback: () => {
                easyDataInputAdminStore.resetCRUD();
                resolve(true);
              },
              cancelCallback: () => resolve(false),
            };
          });

          return output;
        } else {
          return true;
        }
      } else {
        return { name: "MainMap" };
      }
    } else {
      if (analytics) {
        analytics.page({ path: to.path });
      }

      return true;
    }
  } else return true;
});

export default router;
