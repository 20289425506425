import { defineStore, acceptHMRUpdate } from "pinia";
import { ref } from "vue";

export const useCardContainerStore = defineStore(
  "collectibleCardContainer",
  () => {
    const actioningCard = ref(false);

    return { actioningCard };
  }
);

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useCardContainerStore, import.meta.hot)
  );
}
