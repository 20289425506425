<template>
  <div class="p-2">
    <div class="flex items-center justify-between">
      <div class="flex-auto">
        <h1
          class="text-xl font-semibold text-gray-900"
          data-test="my-safezone-header"
        >
          My Safezone
        </h1>
        <p class="mt-2 text-sm text-gray-700">
          This data is only visible to you and the people you share it with.
        </p>
      </div>
      <router-link :to="helpCenterPath" target="_blank" v-tooltip="'Get help'">
        <QuestionMarkCircleIcon class="h-5 w-5 text-gray-700" />
      </router-link>
    </div>
    <div v-if="changes.pagy" class="mt-4 flex flex-col">
      <div v-if="changes.data.length > 0" class="-my-2 -mx-4 overflow-x-auto">
        <div class="inline-block min-w-full py-2 align-middle px-4">
          <div
            class="relative overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-lg"
          >
            <!-- Selected row actions, only show when rows are selected. -->
            <div
              v-if="selectionLength > 0"
              class="absolute top-0 left-12 flex h-12 items-center space-x-3 bg-gray-50"
            >
              <button
                v-if="declassifiable"
                @click="bulkDeclassifyConfirmation"
                :disabled="actionInProgress"
                type="button"
                class="inline-flex items-center rounded border border-transparent bg-indigo-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                data-test="safezone-bulk-declassify-button"
              >
                <PulseLoader
                  v-if="actionInProgress"
                  :loading="true"
                  size="4px"
                  color="#f9fafb"
                />
                <span v-else>Declassify</span>
              </button>
              <button
                @click="addToSharingStage"
                type="button"
                class="inline-flex items-center rounded border border-transparent bg-orange-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
                data-test="safezone-bulk-share-button"
              >
                Share
              </button>
              <button
                v-if="unlockable"
                @click="bulkUnlock"
                type="button"
                class="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30"
                data-test="safezone-bulk-share-button"
              >
                <PulseLoader
                  v-if="actionInProgress"
                  :loading="true"
                  size="4px"
                  color="#374151"
                />
                <span v-else
                  >Bulk unlock: ${{ currencyAmount(batchPrice, 2) }}</span
                >
              </button>
            </div>

            <table class="min-w-full table-fixed divide-y divide-gray-300">
              <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="relative w-12 px-4">
                    <input
                      @click="toggleOverall"
                      id="safezone-overall"
                      ref="safezoneOverall"
                      type="checkbox"
                      class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                      data-test="my-safezone-toggle-overall-selection-button"
                    />
                  </th>
                  <th
                    scope="col"
                    class="py-3.5 pl-1 pr-3 text-left text-sm font-semibold text-gray-900"
                  >
                    Belongs To
                  </th>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Datapoint
                  </th>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Group
                  </th>
                  <th
                    scope="col"
                    class="pl-3 pr-4 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Sharing
                  </th>
                </tr>
              </thead>
              <tbody
                v-if="rowsDisplayable"
                class="divide-y divide-gray-200 bg-white"
              >
                <SafezoneTableRow
                  v-for="(change, index) in changes.data"
                  :key="change.id"
                  :data-field-id="change.dataFieldId"
                  :selected="selected(change.dataFieldId)"
                  :index="index"
                  :data-test="`safezone-table-row-${index}`"
                  @unselect-classified-only="unselectClassifiedOnly"
                  @unselect="unselect(change.dataFieldId)"
                  @select="select(change.dataFieldId)"
                  @attach-field="attachField"
                  @select-decorating="selectDecorating"
                  @select-group="selectGroup"
                  @open-sourced="fetchChanges"
                  @dismissed="drop(change)"
                />
              </tbody>
              <PaginationFooter
                v-if="changes.pagy.prev || changes.pagy.next"
                :pagination-meta="changes.pagy"
                :table-meta="{ colspan: 5 }"
                @previous="fetchChanges"
                @next="fetchChanges"
                @page="fetchChanges"
                @infinite-fetch="loadChanges"
              />
            </table>
          </div>
        </div>
      </div>
      <div v-else class="text-center" data-test="my-safezone-empty-state">
        <LockClosedIcon class="mx-auto h-12 w-12 text-gray-400" />
        <h3 class="mt-2 text-sm font-medium text-gray-900">No data</h3>
        <p class="mt-1 text-sm text-gray-500">
          Your safezone intel will appear here.
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { QuestionMarkCircleIcon } from "@heroicons/vue/20/solid";
import { LockClosedIcon } from "@heroicons/vue/24/outline";
import SafezoneTableRow from "@/components/crowdsourcing/safezone/SafezoneTableRow.vue";
import PaginationFooter from "@/components/PaginationFooter.vue";
import { useDealBuilderStore } from "@/stores/dealBuilder";
import { useModalStore } from "@/stores/modal";
import { useAvailableValidationsChannelStore } from "@/stores/availableValidationsChannel";
import { useSharingStore } from "@/stores/sharing";
import { currencyAmount } from "@/assets/numberHelpers";
import api from "@/router/api";
import moment from "moment";
import { computed, onMounted, ref, watch } from "vue";
import { storeToRefs } from "pinia";
import _ from "lodash";

const changes = ref({
  data: [],
  pagy: null,
});
const activeSelection = ref([]);
const rowsDisplayable = ref(false);
const actionInProgress = ref(false);

const dealBuilderStore = useDealBuilderStore();
const availableValidationsChannelStore = useAvailableValidationsChannelStore();
const { availableValidationsChannelDataQueue } = storeToRefs(
  availableValidationsChannelStore,
);
const sharingStore = useSharingStore();
const modalStore = useModalStore();
const { confirmationPayload } = storeToRefs(modalStore);

const helpCenterPath = computed(() => {
  return {
    name: "HelpArticle",
    params: { articleId: "what-is-safezone" },
  };
});

const lastFetchedTime = ref(null);
const refetchNeeded = ref(false);
const selectionLength = computed(() => {
  return activeSelection.value.length;
});
const allSelected = computed(() => {
  return (
    changes.value.pagy && changes.value.data.length === selectionLength.value
  );
});
const indeterminate = computed(() => {
  return selectionLength.value > 0 && !allSelected.value;
});
const declassifiable = computed(() => {
  const fields = activeSelection.value.map((id) => {
    const change = _.find(changes.value.data, {
      dataFieldId: id,
    });

    return change.dataField;
  });

  return fields.every(
    (field) =>
      _.get(field, "authored", false) && _.get(field, "declassifiable", false),
  );
});
const unlockable = computed(() => {
  const fields = activeSelection.value.map((id) => {
    const change = _.find(changes.value.data, {
      dataFieldId: id,
    });

    return change.dataField;
  });

  return fields.every((field) => {
    const rawPrice = _.get(field, "price");
    const price = rawPrice ? _.toNumber(rawPrice) : null;

    return price && price > 0;
  });
});
const batchPrice = computed(() => {
  const fields = activeSelection.value.map((id) => {
    const change = _.find(changes.value.data, {
      dataFieldId: id,
    });

    return change.dataField;
  });

  return _.sumBy(fields, function (field) {
    const rawPrice = _.get(field, "price");
    const price = _.toNumber(rawPrice);

    return _.round(price, 3);
  });
});

watch(availableValidationsChannelDataQueue, () => {
  if (selectionLength.value === 0) {
    fetchChanges(null, true);
  } else {
    refetchNeeded.value = true;
  }
});
watch(selectionLength, () => {
  updateOverallCheckbox();

  if (selectionLength.value === 0 && refetchNeeded.value) {
    fetchChanges(null, true);
  }
});

onMounted(() => fetchChanges());

function fetchChanges(paginationUrl = null, debounce = false) {
  if (
    debounce &&
    lastFetchedTime.value &&
    lastFetchedTime.value.isAfter(moment().subtract(5, "minutes"))
  ) {
    console.log(
      "debounce",
      lastFetchedTime.value.toISOString(),
      moment().subtract(5, "minutes").toISOString(),
    );
    return;
  } else if (debounce) {
    refetchNeeded.value = false;
  }

  rowsDisplayable.value = false;
  api.get(paginationUrl || `my_safezone`).then((json) => {
    changes.value = json.data;
    rowsDisplayable.value = true;
    lastFetchedTime.value = null;
    lastFetchedTime.value = moment();
    actionInProgress.value = false;
  });
}

function cleanUrl(url) {
  return url.replace("/api/v1/", "");
}
const loadChanges = async ($state) => {
  if (changes.value.pagy?.next) {
    const endpoint = cleanUrl(changes.value.pagy?.next_url);
    try {
      api.get(endpoint).then((json) => {
        const { data, pagy } = json.data;

        changes.value.data = _.uniqBy(_.concat(changes.value.data, data), "id");
        changes.value.pagy = pagy;
        if (data.length < 10) $state.complete();
        else {
          $state.loaded();
        }
      });
    } catch (error) {
      $state.error();
    }
  } else {
    $state.complete();
  }
};

function drop(change) {
  const newChanges = _.differenceBy(changes.value.data, [change], "id");

  changes.value.data = newChanges;
}
function selected(id) {
  return _.includes(activeSelection.value, id);
}
function unselect(id) {
  const newSelection = _.difference(activeSelection.value, [id]);

  activeSelection.value = newSelection;
}
function select(id) {
  const newSelection = _.union(activeSelection.value, [id]);

  activeSelection.value = newSelection;
}
function addToSharingStage() {
  const fields = activeSelection.value.map((id) => {
    const change = _.find(changes.value.data, {
      dataFieldId: id,
    });

    return change.dataField;
  });

  sharingStore.addSharingDataFields(fields);
  activeSelection.value = [];
}
function attachField(dataField) {
  const oldChange = _.find(changes.value.data, {
    dataFieldId: dataField.localId,
  });

  if (oldChange) {
    const newChange = _.merge({}, oldChange, { dataField });
    const newChanges = changes.value.data.map((change) => {
      if (change.dataFieldId === newChange.dataFieldId) {
        return newChange;
      } else {
        return change;
      }
    });

    changes.value.data = newChanges;
  }
}
function selectDecorating({ contentType, contentId }) {
  const filtered = changes.value.data.filter((c) => {
    return (
      _.get(c, "dataField.decoratingContentType") === contentType &&
      _.get(c, "dataField.decoratingContentId") === contentId
    );
  });

  activeSelection.value = filtered.map((c) => c.dataFieldId);
}
function selectGroup({ groupId }) {
  const filtered = changes.value.data.filter((c) => {
    return _.get(c, "dataField.latestChangeGroupId") === groupId;
  });

  activeSelection.value = filtered.map((c) => c.dataFieldId);
}
function unselectClassifiedOnly() {
  const filtered = changes.value.data.filter((c) => {
    const declassifiable =
      _.get(c, "dataField.authored") && _.get(c, "dataField.declassifiable");
    return declassifiable;
  });

  activeSelection.value = filtered.map((c) => c.dataFieldId);
}
function toggleOverall() {
  if (indeterminate.value || allSelected.value) {
    activeSelection.value = [];
  } else {
    activeSelection.value = changes.value.data.map((c) => c.dataFieldId);
  }
}
function updateOverallCheckbox() {
  const overall = document.querySelector("#safezone-overall");

  if (!overall) {
    return;
  }

  if (selectionLength.value === 0) {
    overall.checked = false;
    overall.indeterminate = false;
  } else if (allSelected.value) {
    overall.checked = true;
    overall.indeterminate = false;
  } else {
    overall.checked = false;
    overall.indeterminate = true;
  }
}
function bulkDeclassifyConfirmation() {
  confirmationPayload.value = {
    title: "Declassify datapoints",
    message:
      "Declassifying these datapoints allows you to earn money but also makes them visible to other Tower Hunt users. Are you sure?",
    affirmText: "Declassify",
    affirmCallback: bulkDeclassify,
  };
}
async function bulkDeclassify() {
  if (!actionInProgress.value) {
    const payload = {
      ids: activeSelection.value,
    };

    actionInProgress.value = true;

    api.post(`declassify_datapoints`, payload).then(() => {
      changes.value = {
        data: [],
        pagy: null,
      };
      activeSelection.value = [];
      dealBuilderStore.clearDealBuilder(false);
      fetchChanges();
    });
  }
}
function bulkUnlock() {
  if (!actionInProgress.value) {
    const payload = {
      bundleDataFieldIds: activeSelection.value,
    };

    actionInProgress.value = true;

    api.post(`crowdsourced_data_licenses`, payload).then(() => {
      changes.value = {
        data: [],
        pagy: null,
      };
      activeSelection.value = [];
      dealBuilderStore.clearDealBuilder(false);
      fetchChanges();
    });
  }
}
</script>
