import { computed, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { defineStore, acceptHMRUpdate } from "pinia";
import { useMainMapStore } from "@/stores/mainMap";
import { useUserStore } from "@/stores/user";
import { useGuestProfileStore } from "@/stores/guestProfile";
import { useTaskListStore } from "@/stores/taskList";
import { storeToRefs } from "pinia";
import moment from "moment";
import api from "@/router/api";
import _ from "lodash";

export const useTasksStore = defineStore("tasks", () => {
  const guestProfileStore = useGuestProfileStore();
  const { guestTasks, guestTaskLists } = storeToRefs(guestProfileStore);
  const userStore = useUserStore();
  const { signedIn } = storeToRefs(userStore);
  const mapStore = useMainMapStore();
  const { map, mapBoundaryMeta } = storeToRefs(mapStore);
  const taskListStore = useTaskListStore();
  const { defaultDisplayOptions } = storeToRefs(taskListStore);

  const addingTask = ref(false);
  const addingInlineTask = ref(false);
  const addingList = ref(false);
  const sharingList = ref(false);
  const rawSearchQuery = ref("");
  const router = useRouter();
  const route = useRoute();
  const query = computed(() => route.query);
  const queryTaskIds = computed(() => _.get(query.value, "relatedTaskIds"));
  const hasQueryTasks = computed(
    () => _.isArray(queryTaskIds.value) || queryTaskIds.value,
  );
  const relatedTaskNumericIds = computed(() => {
    if (hasQueryTasks.value) {
      if (_.isArray(queryTaskIds.value)) {
        return queryTaskIds.value.map((id) => _.toNumber(id));
      } else {
        return [_.toNumber(queryTaskIds.value)];
      }
    } else return [];
  });

  const zoom = computed(() => {
    return _.get(route.query, "zoom") || _.get(mapBoundaryMeta.value, "zoom");
  });
  const searching = computed(
    () =>
      _.trim(rawSearchQuery.value) !== "" ||
      relatedTaskNumericIds.value.length > 0,
  );
  const allViewShowCompleted = ref(false);
  const nearbyViewShowCompleted = ref(false);
  const scheduledViewShowCompleted = ref(false);
  const todayViewGroupByTime = ref(true);
  const todayViewSortBy = ref("due_date");
  const todayViewSortDirection = ref("desc");
  const todayCount = ref(0);
  const scheduledCount = ref(0);
  const allCount = ref(0);
  const completedCount = ref(0);

  const nearbyTasks = ref([]);
  const nearbyTasksLoaded = ref(false);
  const nearbyCount = computed(() => nearbyTasks.value.length);

  const defaultLists = [
    _.merge({ name: "Reminders", color: "Blue" }, defaultDisplayOptions.value),
    _.merge({ name: "News", color: "Purple" }, defaultDisplayOptions.value),
  ];
  const listGroupedTaskPagyObjects = ref({});
  const taskLists = ref({
    data: defaultLists,
    pagy: null,
  });
  const taskListPagy = computed(() => taskLists.value.pagy);
  const selectedTaskList = ref(null);
  const selectedTask = ref(null);
  const batchSelection = ref(false);
  const batchTasks = ref([]);
  const taskBatchMove = ref(false);
  const taskBatchMoveToList = ref(null);
  const taskBatchTiming = ref(false);
  const taskBatchPriority = ref(false);
  const propertyTaskPriorities = ref([]);
  const batchPayload = computed(() => {
    return {
      taskIds: batchTasks.value.map((task) => task.id),
    };
  });

  function reset() {
    addingTask.value = false;
    addingInlineTask.value = false;
    addingList.value = false;
    sharingList.value = false;
    rawSearchQuery.value = "";
    selectedTaskList.value = null;
    selectedTask.value = null;
    batchSelection.value = false;
    batchTasks.value = [];
    taskBatchMove.value = false;
    taskBatchMoveToList.value = null;
    taskBatchTiming.value = false;
    taskBatchPriority.value = false;
  }

  function resetBatch() {
    batchSelection.value = false;
    batchTasks.value = [];
  }
  function dropBatch() {
    if (signedIn.value) {
      batchTasks.value.forEach((task) => {
        removeTask(task);
      });
    } else {
      guestTasks.value = _.differenceBy(
        guestTasks.value,
        batchTasks.value,
        "id",
      );
    }

    resetBatch();
  }
  function removeTask(task) {
    if (signedIn.value) {
      let listPagyObject =
        listGroupedTaskPagyObjects.value[
          task.accessTokenId || _.camelCase(task.taskListName)
        ];
      listPagyObject.data = _.differenceBy(listPagyObject.data, [task], "id");
    } else {
      guestTasks.value = _.differenceBy(guestTasks.value, [task], "id");
    }
  }
  function localBatchMove() {
    const newTasks = batchTasks.value.map((task) => {
      return _.merge({}, task, {
        taskListName: taskBatchMoveToList.value.name,
      });
    });

    patchTasks(newTasks);
    taskBatchMoveToList.value = null;
    taskBatchMove.value = false;
    resetBatch();
  }

  async function batchDelete() {
    if (signedIn.value) {
      api.post(`task_batch_deletions`, batchPayload.value).then(() => {
        dropBatch();
      });
    } else {
      dropBatch();
    }
  }

  async function batchMove() {
    const payloadWithDestination = _.merge({}, batchPayload.value, {
      destinationTaskListId: taskBatchMoveToList.value.id,
    });
    if (signedIn.value) {
      api.post(`task_batch_moves`, payloadWithDestination).then(() => {
        localBatchMove();
      });
    } else {
      localBatchMove();
    }
  }

  function localBatchUpdate(collection) {
    return new Promise((resolve) => {
      if (signedIn.value) {
        patchTasks(collection);
        resolve();
      } else {
        const newTasks = batchTasks.value.map((task) => {
          return _.merge({}, task, collection);
        });

        patchTasks(newTasks);
        resolve();
      }
    });
  }

  async function batchTimingUpdate(timingPayload) {
    const payloadWithTiming = _.merge({}, batchPayload.value, timingPayload);

    return new Promise((resolve) => {
      if (signedIn.value) {
        api.post(`task_batch_timings`, payloadWithTiming).then((json) => {
          const newTasks = json.data;
          localBatchUpdate(newTasks).then(() => resolve());
        });
      } else {
        localBatchUpdate(payloadWithTiming).then(() => resolve());
      }
    });
  }

  async function batchPriorityUpdate(priorityPayload) {
    const payloadWithPriority = _.merge(
      {},
      batchPayload.value,
      priorityPayload,
    );

    return new Promise((resolve) => {
      if (signedIn.value) {
        api.post(`task_batch_priorities`, payloadWithPriority).then((json) => {
          const newTasks = json.data;
          localBatchUpdate(newTasks).then(() => resolve());
        });
      } else {
        localBatchUpdate(payloadWithPriority).then(() => resolve());
      }
    });
  }

  const effectiveTasks = computed(() => {
    if (signedIn.value) {
      const listPagyObjects = _.values(listGroupedTaskPagyObjects.value);
      return _.flatMap(listPagyObjects, function (pagyObject) {
        const listName = _.head(pagyObject.data)?.taskListName;
        const nearby = nearbyTasks.value.filter(
          (task) => task.taskListName === listName,
        );

        return _.unionBy(nearby, pagyObject.data, "id");
      });
    } else {
      return _.orderBy(guestTasks.value, ["id"], ["asc"]);
    }
  });
  const listGroupedTasks = computed(() => {
    return _.groupBy(effectiveTasks.value, function (task) {
      return task.accessTokenId || task.taskListName;
    });
  });
  const effectiveTaskLists = computed(() => {
    if (signedIn.value) {
      return taskLists.value.data;
    } else {
      return _.concat(taskLists.value.data, guestTaskLists.value);
    }
  });

  const incompleteTasks = computed(() => {
    return effectiveTasks.value.filter((task) => !task.completedAt);
  });

  const completedTasks = computed(() => {
    return effectiveTasks.value.filter((task) => task.completedAt);
  });
  const completedLength = computed(() => completedTasks.value.length);

  const todayTasks = computed(() => {
    return incompleteTasks.value.filter((task) => {
      if (task.dueDate) {
        return moment.unix(task.dueDate).isSame(moment(), "day");
      } else {
        return false;
      }
    });
  });

  const scheduledTasks = computed(() => {
    return incompleteTasks.value.filter((task) => !!task.dueDate);
  });

  function taskCountFor(list) {
    if (list.taskCount) return list.taskCount;
    else return matchingGuestTasksFor(list);
  }
  function matchingGuestTasksFor(list) {
    return guestTasks.value.filter((task) => {
      return task.taskListName == list.name && !task.completedAt;
    }).length;
  }
  function patchTasks(newTasks, reverse = false) {
    if (signedIn.value) {
      newTasks.forEach((task) => addTaskToList(task, reverse));
    } else {
      guestTasks.value = _.unionBy(newTasks, guestTasks.value, "id");
    }
  }
  function addTaskToList(task, reverse = false) {
    let listPagyObject =
      listGroupedTaskPagyObjects.value[
        task.accessTokenId || _.camelCase(task.taskListName)
      ];
    if (reverse) {
      listPagyObject.data = _.unionBy(listPagyObject.data, [task], "id");
    } else {
      listPagyObject.data = _.unionBy([task], listPagyObject.data, "id");
    }

    if (_.find(nearbyTasks.value, { id: task.id })) {
      const newNearbyTasks = _.unionBy([task], nearbyTasks.value, "id");
      nearbyTasks.value = newNearbyTasks;
    }
  }
  function patchTaskList(newList) {
    if (signedIn.value || _.includes(["Reminders", "News"], newList.name)) {
      taskLists.value.data = _.unionBy(
        [newList],
        taskLists.value.data,
        function (comparableList) {
          return comparableList.accessTokenId || comparableList.name;
        },
      );
    } else {
      guestTaskLists.value = _.unionBy(
        [newList],
        guestTaskLists.value,
        function (comparableList) {
          return comparableList.accessTokenId || comparableList.name;
        },
      );
    }
  }
  function deleteTaskList(list) {
    if (signedIn.value) {
      delete listGroupedTaskPagyObjects.value[
        list.accessTokenId || _.camelCase(list.name)
      ];
      taskLists.value.data = _.differenceBy(
        taskLists.value.data,
        [list],
        function (comparableList) {
          return comparableList.accessTokenId || comparableList.name;
        },
      );
      mapStore.tapView();
    } else {
      guestTasks.value = guestTasks.value.filter(
        (task) => task.taskListName !== list.name,
      );
      guestTaskLists.value = _.differenceBy(
        guestTaskLists.value,
        [list],
        function (comparableList) {
          return comparableList.accessTokenId || comparableList.name;
        },
      );
    }
  }
  function deleteCompletedTasks(
    threshold = "all",
    selectedList = null,
    taskIds = [],
  ) {
    const monthFilterMoment = moment().subtract(1, "month").startOf("day");
    const deletionFilter = (task) => {
      const completedMoment = signedIn.value
        ? moment.unix(task.completedAt)
        : moment(task.completedAt);

      const deletableDate =
        threshold === "month"
          ? completedMoment.isBefore(monthFilterMoment)
          : true;
      const deletableList = selectedList
        ? task.taskListName === selectedList.name
        : true;

      if (taskIds.length > 0) {
        return _.includes(taskIds, task.id);
      } else if (task.completedAt) {
        return deletableDate && deletableList;
      } else {
        return false;
      }
    };
    if (signedIn.value) {
      _.forEach(listGroupedTaskPagyObjects.value, function (pagyObject) {
        pagyObject.data = _.reject(pagyObject.data, deletionFilter);
      });
    } else {
      guestTasks.value = _.reject(guestTasks.value, deletionFilter);
    }
  }

  async function fetchNearbyTasks() {
    if (signedIn.value && map.value && zoom.value >= 13) {
      nearbyTasksLoaded.value = false;

      const bounds = map.value.getBounds();
      const southwest = bounds.getSouthWest();
      const northeast = bounds.getNorthEast();
      const northeastLatLng = [northeast.lat, northeast.lng];
      const southwestLatLng = [southwest.lat, southwest.lng];

      const tasksResponse = await api.post(
        `nearby_tasks/${southwestLatLng}/${northeastLatLng}`,
      );

      if (tasksResponse?.data) {
        nearbyTasks.value = tasksResponse.data;
      } else {
        nearbyTasks.value = [];
      }
      nearbyTasksLoaded.value = true;
    }
  }

  async function fetchTasks(taskListId) {
    return new Promise((resolve) => {
      const list = _.find(effectiveTaskLists.value, {
        id: taskListId,
      });
      const listAccessToken = list?.accessTokenId;
      const listName = list?.name;
      if (signedIn.value && (listAccessToken || listName)) {
        const endpoint = taskListId
          ? `tasks?task_list_id=${taskListId}`
          : `tasks`;
        listGroupedTaskPagyObjects.value[
          listAccessToken || _.camelCase(listName)
        ] = {
          data: [],
          pagy: null,
        };
        api.get(endpoint).then((json) => {
          listGroupedTaskPagyObjects.value[
            listAccessToken || _.camelCase(listName)
          ] = json.data;
          resolve();
        });
      } else {
        resolve();
      }
    });
  }

  async function fetchLists() {
    return new Promise((resolve) => {
      if (signedIn.value) {
        taskLists.value = {
          data: defaultLists,
          pagy: null,
        };

        api.get(`task_lists`).then((json) => {
          taskLists.value = json.data;
          populateListGroups();
          resolve();
        });
      } else {
        resolve();
      }
    });
  }

  function populateListGroups() {
    listGroupedTaskPagyObjects.value = {};
    taskLists.value.data.forEach((listObject) => {
      listGroupedTaskPagyObjects.value[
        listObject.accessTokenId || _.camelCase(listObject.name)
      ] = {
        data: [],
        pagy: null,
      };
    });
  }

  async function fetchUserTaskViewsMeta() {
    return new Promise((resolve) => {
      if (signedIn.value) {
        api.get(`user_task_views_meta`).then((json) => {
          const {
            allShowCompleted,
            nearbyShowCompleted,
            scheduledShowCompleted,
            todayGroupByTime,
            todaySortBy,
            todaySortDirection,
            todaySize,
            scheduledSize,
            allSize,
            completedSize,
          } = json.data;

          allViewShowCompleted.value = allShowCompleted;
          nearbyViewShowCompleted.value = nearbyShowCompleted;
          scheduledViewShowCompleted.value = scheduledShowCompleted;
          todayViewGroupByTime.value = todayGroupByTime;
          todayViewSortBy.value = todaySortBy;
          todayViewSortDirection.value = todaySortDirection;
          todayCount.value = todaySize;
          scheduledCount.value = scheduledSize;
          allCount.value = allSize;
          completedCount.value = completedSize;
          resolve();
        });
      } else {
        resolve();
      }
    });
  }

  function backToLists() {
    resetBatch();
    addingInlineTask.value = false;
    selectedTaskList.value = null;
    router.push({
      name: route.name,
      query: {
        ...route.query,
        taskList: undefined,
        taskIds: undefined,
        relatedTaskIds: undefined,
      },
    });
  }

  return {
    rawSearchQuery,
    searching,
    relatedTaskNumericIds,
    addingTask,
    addingInlineTask,
    addingList,
    sharingList,
    batchSelection,
    batchTasks,
    taskBatchMove,
    taskBatchMoveToList,
    taskBatchTiming,
    taskBatchPriority,
    propertyTaskPriorities,
    listGroupedTaskPagyObjects,
    taskLists,
    taskListPagy,
    selectedTaskList,
    selectedTask,
    effectiveTaskLists,
    effectiveTasks,
    nearbyTasks,
    nearbyTasksLoaded,
    listGroupedTasks,
    todayTasks,
    scheduledTasks,
    incompleteTasks,
    completedTasks,
    completedLength,
    allViewShowCompleted,
    nearbyViewShowCompleted,
    scheduledViewShowCompleted,
    todayViewGroupByTime,
    todayViewSortBy,
    todayViewSortDirection,
    todayCount,
    scheduledCount,
    allCount,
    nearbyCount,
    completedCount,
    fetchUserTaskViewsMeta,
    fetchLists,
    fetchTasks,
    fetchNearbyTasks,
    taskCountFor,
    patchTasks,
    patchTaskList,
    deleteTaskList,
    deleteCompletedTasks,
    resetBatch,
    batchDelete,
    removeTask,
    batchMove,
    batchTimingUpdate,
    batchPriorityUpdate,
    backToLists,
    reset,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useTasksStore, import.meta.hot));
}
