<template>
  <div class="">
    <h3 class="text-base leading-6 font-medium text-gray-900">
      Personal Payouts Dashboard
    </h3>
    <div
      class="mt-2"
      :class="
        workspaceLayout === 'sideBySide'
          ? ''
          : 'flex items-start justify-between'
      "
    >
      <div class="max-w-xl text-sm leading-5 text-gray-500 space-y-2">
        <div class="rounded-md bg-green-50 p-2">
          <div class="flex">
            <div class="flex-shrink-0">
              <CheckCircleIcon
                class="h-5 w-5 text-green-400"
                aria-hidden="true"
              />
            </div>
            <div class="ml-3">
              <p class="text-sm font-medium text-green-800">
                Your payout account is active.
              </p>
            </div>
          </div>
        </div>
        <p>
          Tower Hunt partners with
          <a href="https://stripe.com" target="_blank" class="underline"
            >Stripe</a
          >
          to manage payments and payouts. Use the button below to visit your
          personal dashboard.
        </p>
      </div>
      <div
        class=""
        :class="
          workspaceLayout === 'sideBySide'
            ? 'mt-5'
            : 'ml-6 flex-shrink-0 flex items-center'
        "
      >
        <span class="inline-flex rounded-md shadow-sm">
          <button
            @click="visitPayoutsDashboard"
            type="button"
            class="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:ring-indigo active:bg-indigo-700"
            data-test="payouts-dashboard-button"
          >
            Payout settings
          </button>
        </span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { CheckCircleIcon } from "@heroicons/vue/20/solid";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { storeToRefs } from "pinia";
import api from "@/router/api";

const layoutStore = useWorkspaceLayoutStore();
const { workspaceLayout } = storeToRefs(layoutStore);

async function visitPayoutsDashboard() {
  api.get(`payment_connected_accounts`).then((json) => {
    const dashboardPath = json.data.url;

    window.open(dashboardPath, "_blank");
  });
}
</script>
