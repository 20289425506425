<template>
  <div class="space-y-2">
    <fieldset>
      <div>
        <legend class="text-base font-medium text-gray-900">New room(s)</legend>
        <p class="text-sm text-gray-500">
          Choose the type of room and how many are in this layout.
        </p>
      </div>
      <div class="mt-4 grid grid-cols-1 gap-4 sm:grid-cols-2">
        <div>
          <label
            for="partition_count"
            class="block text-sm font-medium text-gray-700"
            >Room count</label
          >
          <div class="mt-1">
            <input
              v-focus
              v-model="partitionCount"
              type="number"
              min="1"
              step="1"
              name="partition_count"
              id="partition_count"
              class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
        </div>
        <div>
          <label
            for="partition_type"
            class="block text-sm font-medium text-gray-700"
            >Room type</label
          >
          <select
            v-model="partitionType"
            id="partition_type"
            name="partition_type"
            data-test="layout-room-select"
            class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          >
            <option
              v-for="(option, index) in optionsWithCustom"
              :key="index"
              :value="option.value"
            >
              {{ option.name }}
            </option>
          </select>
        </div>
        <div v-if="partitionType === 'custom'" class="sm:col-span-2">
          <label
            for="custom_partition_type"
            class="block text-sm font-medium text-gray-700"
            >Custom room type</label
          >
          <div class="mt-1">
            <input
              v-focus
              v-model="customPartitionType"
              type="text"
              name="custom_partition_type"
              id="custom_partition_type"
              placeholder="What room?"
              data-test="layout-room-custom-type-input"
              class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
        </div>
      </div>
    </fieldset>
    <div class="flex justify-end">
      <button
        @click="cancel"
        type="button"
        class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Cancel
      </button>
      <DataVisibilityButton visibility="safezone" class="ml-3 inline-flex">
        <template v-slot:button>
          <button
            @click="save"
            :disabled="originatingData"
            type="button"
            data-test="layout-room-save"
            class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-500 hover:bg-yellow-600 focus:ring-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2"
          >
            <PulseLoader
              v-if="originatingData"
              :loading="true"
              size="3px"
              color="#f3f4f6"
            />
            <span v-else>Save</span>
          </button>
        </template>
      </DataVisibilityButton>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { useCrowdsourcedChangeGroupStore } from "@/stores/crowdsourcedChangeGroup";
import { usePropertyDiagramStore } from "@/stores/propertyDiagram";
import { storeToRefs } from "pinia";
import api from "@/router/api";
import _ from "lodash";
import floorAreaLayoutPartitionTypes from "@/assets/floorAreaLayoutPartitionTypes";
import DataVisibilityButton from "@/components/crowdsourcing/DataVisibilityButton.vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";

const props = defineProps(["floorArea", "layoutDataField"]);
const emit = defineEmits(["completed", "cancel"]);

const customPartitionType = ref(null);
const partitionType = ref(null);
const partitionCount = ref(1);

const propertyDiagramStore = usePropertyDiagramStore();
const crowdsourcedChangeGroupStore = useCrowdsourcedChangeGroupStore();
const { originatingData, changeGroupId } = storeToRefs(
  crowdsourcedChangeGroupStore
);

const useTypes = computed(() => {
  return _.get(props.floorArea, "useTypes", []);
});
const dropdownOptions = computed(() => {
  return _.uniq(
    useTypes.value.flatMap((useType) => {
      return _.get(floorAreaLayoutPartitionTypes, useType, []);
    })
  );
});
const optionsWithCustom = computed(() => {
  return _.concat(dropdownOptions.value, {
    name: "Custom",
    value: "custom",
  });
});
const complete = computed(() => {
  if (partitionType.value === "custom") {
    return _.trim(customPartitionType.value) !== "" && partitionCount.value > 0;
  } else {
    return partitionCount.value > 0;
  }
});
onMounted(() => {
  partitionType.value = _.get(
    _.head(optionsWithCustom.value),
    "value",
    "custom"
  );
});
function cancel() {
  emit("cancel");
}
function afterPersist(json) {
  propertyDiagramStore.postEditingPatch(json);
  emit("completed", json.data);
}
function persist() {
  const payload = {
    floorAreaLayoutId: props.layoutDataField.fieldContentId,
    partitionType: partitionType.value,
    partitionCount: partitionCount.value,
    customPartitionType: customPartitionType.value,
    changeGroupId: changeGroupId.value,
  };

  return api.post(`layout_partitions`, payload);
}
function save() {
  const apiRequestFunc = () => persist();
  const successCallback = (json) => afterPersist(json);
  const failureCallback = () => cancel();
  if (complete.value) {
    return crowdsourcedChangeGroupStore.originateData(
      apiRequestFunc,
      successCallback,
      failureCallback
    );
  }
}
</script>
