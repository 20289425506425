<template>
  <div v-if="workspaceLayout" id="app" class="h-screen">
    <ConfirmationAlert />
    <ModalVue />
    <NotificationsOverlay />
    <RouterView
      v-if="authStore.identityChecked"
      id="body-container"
      class="h-full"
    />
  </div>
</template>

<script setup>
/* global google */
import { useAuthStore } from "@/stores/auth";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { useDealBuilderStore } from "@/stores/dealBuilder";
import { useSpaceUsageBuilderStore } from "@/stores/spaceUsageBuilder";
import { useSecondaryPanelStore } from "@/stores/secondaryPanel";
import { useMobileNavigationStore } from "@/stores/mobileNavigation";
import { onMounted, onUnmounted, watch, nextTick } from "vue";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";
import ConfirmationAlert from "@/components/main-layout/ConfirmationAlert.vue";
import ModalVue from "@/components/main-layout/ModalVue.vue";
import NotificationsOverlay from "@/components/main-layout/NotificationsOverlay.vue";
import _ from "lodash";

const authStore = useAuthStore();
const layoutStore = useWorkspaceLayoutStore();
const { windowWidth, windowHeight, workspaceLayout, isPortrait, isDesktop } =
  storeToRefs(layoutStore);
const dealBuilderStore = useDealBuilderStore();
const spaceUsageBuilderStore = useSpaceUsageBuilderStore();
const secondaryPanelStore = useSecondaryPanelStore();
const { myAccountActive, validationsActive } = storeToRefs(secondaryPanelStore);
const { authenticateActive } = storeToRefs(secondaryPanelStore);
const mobileNavigationStore = useMobileNavigationStore();
const { selectedMobileTab } = storeToRefs(mobileNavigationStore);

const setWindowSize = _.debounce(() => {
  windowWidth.value = window.innerWidth;
  windowHeight.value = window.innerHeight;
}, 250);

onMounted(async () => {
  authStore.getCurrentUser();
  const { Map } = await google.maps.importLibrary("maps");
  setWindowSize();

  await nextTick();
  window.addEventListener("resize", setWindowSize);
  layoutStore.matchLayoutToScreen();
});

const router = useRouter();

watch(isPortrait, () => {
  layoutStore.matchLayoutToScreen();
});
watch(isDesktop, () => {
  if (isDesktop.value) {
    selectedMobileTab.value = "News";
    layoutStore.matchLayoutToScreen();
  } else {
    if (authenticateActive.value) {
      router.push(authenticateActive.value);
    }

    myAccountActive.value = false;
    validationsActive.value = false;
    secondaryPanelStore.setAuthenticate();
    dealBuilderStore.clearDealBuilder();
    spaceUsageBuilderStore.clearBuilder();
  }
});

onUnmounted(() => {
  window.removeEventListener("resize", setWindowSize);
});
</script>

<style>
.dont-break-out {
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}
</style>
