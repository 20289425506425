import selfSelected from "@/components/property-diagram/diagramBlockSelfSelected";
import useTypes from "@/assets/useTypes";
import _ from "lodash";

export const useTypesWithAddOns = _.concat(
  useTypes,
  {
    order: 13,
    name: "Common Area",
    value: "Common Area",
    abbreviation: "CA",
    frontEndKey: "commonArea",
    backEndKey: "common_area",
    measure: "SF",
    unselectedColor: "bg-amber-200",
    unselectedFromColor: "from-amber-200",
    unselectedViaColor: "via-amber-200",
    unselectedToColor: "to-amber-200",
    selectedColor: "bg-amber-300",
    selectedFromColor: "from-amber-300",
    selectedViaColor: "via-amber-300",
    selectedToColor: "to-amber-300",
  },
  {
    order: 14,
    name: "Mechanical",
    value: "Mechanical",
    abbreviation: "Mech",
    frontEndKey: "mechanical",
    backEndKey: "mechanical",
    measure: "SF",
    unselectedColor: "bg-slate-400",
    unselectedFromColor: "from-slate-400",
    unselectedViaColor: "via-slate-400",
    unselectedToColor: "to-slate-400",
    selectedColor: "bg-slate-500",
    selectedFromColor: "from-slate-500",
    selectedViaColor: "via-slate-500",
    selectedToColor: "to-slate-500",
  },
  {
    order: 15,
    name: "Parking",
    value: "Parking",
    abbreviation: "Prkg",
    frontEndKey: "parking",
    backEndKey: "parking",
    measure: "Spaces",
    unselectedColor: "bg-orange-200",
    unselectedFromColor: "from-orange-200",
    unselectedViaColor: "via-orange-200",
    unselectedToColor: "to-orange-200",
    selectedColor: "bg-orange-300",
    selectedFromColor: "from-orange-300",
    selectedViaColor: "via-orange-300",
    selectedToColor: "to-orange-300",
  },
);

function gradientColorFor(ordinal, colorKey, useNames) {
  const rawColors = useNames.map(
    (name) => _.find(useTypesWithAddOns, { name })[colorKey],
  );
  const colors = _.sortBy(_.uniq(rawColors));
  switch (ordinal) {
    case "first":
      return _.head(colors);
    case "middle":
      return colors[1];
    case "last":
      return _.last(colors);
    default:
      console.log("unknown color", ordinal, colorKey);
      return "bg-stone-400";
  }
}

export function floorAreaColorStyle(dataField, selectionObject) {
  const selectedText = "text-gray-700 font-semibold";
  const unselectedText = "text-gray-500 font-medium";
  const useNames = _.get(dataField, "fieldContent.useTypes", []);

  const rawUnselectedColors = useNames.map(
    (name) => _.find(useTypesWithAddOns, { name }).unselectedColor,
  );
  const unselectedColors = _.sortBy(_.uniq(rawUnselectedColors));

  switch (unselectedColors.length) {
    case 0:
      return selfSelected(_.get(dataField, "fieldContent"), selectionObject)
        ? `${selectedText} bg-stone-400`
        : `${unselectedText} bg-stone-300 hover:bg-stone-400`;
    case 1:
      return selfSelected(_.get(dataField, "fieldContent"), selectionObject)
        ? `${selectedText} ${gradientColorFor(
            "first",
            "selectedColor",
            useNames,
          )}`
        : `${unselectedText} ${gradientColorFor(
            "first",
            "unselectedColor",
            useNames,
          )} hover:${gradientColorFor("first", "selectedColor", useNames)}`;
    case 3: {
      return selfSelected(_.get(dataField, "fieldContent"), selectionObject)
        ? `${selectedText} bg-gradient-to-r ${gradientColorFor(
            "first",
            "selectedFromColor",
            useNames,
          )} ${gradientColorFor(
            "middle",
            "selectedViaColor",
            useNames,
          )} ${gradientColorFor("last", "selectedToColor", useNames)}`
        : `${unselectedText} bg-gradient-to-r ${gradientColorFor(
            "first",
            "unselectedFromColor",
            useNames,
          )} ${gradientColorFor(
            "middle",
            "unselectedViaColor",
            useNames,
          )} ${gradientColorFor(
            "last",
            "unselectedToColor",
            useNames,
          )} hover:${gradientColorFor(
            "first",
            "selectedFromColor",
            useNames,
          )} hover:${gradientColorFor(
            "middle",
            "selectedViaColor",
            useNames,
          )} hover:${gradientColorFor("last", "selectedToColor", useNames)}`;
    }
    default:
      return selfSelected(_.get(dataField, "fieldContent"), selectionObject)
        ? `${selectedText} bg-gradient-to-r ${gradientColorFor(
            "first",
            "selectedFromColor",
            useNames,
          )} ${gradientColorFor("last", "selectedToColor", useNames)}`
        : `${unselectedText} bg-gradient-to-r ${gradientColorFor(
            "first",
            "unselectedFromColor",
            useNames,
          )} ${gradientColorFor(
            "last",
            "unselectedToColor",
            useNames,
          )} hover:${gradientColorFor(
            "first",
            "selectedFromColor",
            useNames,
          )} hover:${gradientColorFor("last", "selectedToColor", useNames)}`;
  }
}
