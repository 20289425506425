<template>
  <div>
    <WorkspaceShell v-if="isDesktop" />
    <ActivitySwipeCardShell v-else />
  </div>
</template>

<script setup>
import ActivitySwipeCardShell from "@/components/main-layout/ActivitySwipeCardShell.vue";
import WorkspaceShell from "@/components/main-layout/WorkspaceShell.vue";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { storeToRefs } from "pinia";

const layoutStore = useWorkspaceLayoutStore();
const { isDesktop } = storeToRefs(layoutStore);
</script>
