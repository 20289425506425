<template>
  <div class="rounded-md border border-gray-300 p-2 space-y-2">
    <div class="text-sm font-semibold text-gray-600">New Space Definition</div>

    <div
      v-observe-visibility="{ callback: populateForm }"
      class="flex flex-col space-y-1"
    >
      <div class="text-sm font-medium text-gray-500">Property Use</div>
      <select
        v-model="selectedUse"
        v-focus
        id="dropdown-option"
        name="dropdown-option"
        class="block w-full shadow-sm pl-3 pr-10 py-2 border-gray-300 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 text-sm rounded-md"
      >
        <option
          v-for="option in actionableUses"
          :key="option.value"
          :value="option.value"
        >
          {{ option.name }}
        </option>
      </select>
    </div>

    <OccupierSpaceSizeTypeChooser />

    <div v-if="sizeTypeDisplayable" class="flex flex-col space-y-2">
      <div class="text-sm font-medium text-gray-500">Phasing</div>
      <OccupierSpaceDefinitionPhase
        v-for="(phase, phaseKey) in phases"
        :key="phaseKey"
        :phase-key="_.toNumber(phaseKey)"
        :phase="phase"
      />
      <button
        @click="occupierSpaceStore.addPhase()"
        :disabled="originatingData"
        type="button"
        class="group py-1 px-1.5 flex items-center justify-between rounded-full border border-gray-300 shadow-sm space-x-2 text-left hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
      >
        <PulseLoader
          v-if="originatingData"
          :loading="true"
          size="3px"
          color="#f3f4f6"
        />
        <span v-else class="min-w-0 flex-1 flex items-center space-x-1">
          <span class="flex-shrink-0 flex items-center">
            <span
              class="inline-flex items-center justify-center h-5 w-5 rounded-full bg-yellow-600"
            >
              <PlusIcon class="h-3 w-3 text-white" />
            </span>
          </span>
          <span class="min-w-0 flex-1">
            <span class="text-sm font-medium text-gray-900 truncate"
              >Add phase</span
            >
          </span>
        </span>
      </button>
    </div>

    <OccupierHeadcountToAreaAssumptions />

    <div class="flex items-center justify-end space-x-2">
      <button
        @click="emit('cancel')"
        type="button"
        class="inline-flex items-center p-1 border border-gray-300 rounded-full shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
      >
        <XMarkIcon class="h-4 w-4" />
      </button>
      <DataVisibilityButton
        v-if="savePayload"
        visibility="safezone"
        tooltip="Save"
        class="inline-flex"
      >
        <template v-slot:button>
          <button
            @click="save"
            :disabled="originatingData"
            type="button"
            class="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm bg-yellow-500 hover:bg-yellow-600 focus:ring-yellow-600 text-white focus:outline-none focus:ring-2 focus:ring-offset-2"
          >
            <PulseLoader
              v-if="originatingData"
              :loading="true"
              size="3px"
              color="#f3f4f6"
            />
            <CheckIcon v-else class="h-4 w-4" />
          </button>
        </template>
      </DataVisibilityButton>
    </div>
  </div>
</template>

<script setup>
import { XMarkIcon, CheckIcon } from "@heroicons/vue/20/solid";
import { PlusIcon } from "@heroicons/vue/24/outline";
import DataVisibilityButton from "@/components/crowdsourcing/DataVisibilityButton.vue";
import OccupierSpaceDefinitionPhase from "@/components/crowdsourcing/OccupierSpaceDefinitionPhase.vue";
import OccupierSpaceSizeTypeChooser from "@/components/crowdsourcing/OccupierSpaceSizeTypeChooser.vue";
import OccupierHeadcountToAreaAssumptions from "@/components/crowdsourcing/OccupierHeadcountToAreaAssumptions.vue";
import { useOccupierSpaceDefinitionStore } from "@/stores/occupierSpaceDefinition";
import { useCrowdsourcedChangeGroupStore } from "@/stores/crowdsourcedChangeGroup";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { storeToRefs } from "pinia";
import api from "@/router/api";
import _ from "lodash";
import {} from "vue";

const props = defineProps(["dataField"]);
const emit = defineEmits(["refetch", "cancel"]);
const occupierSpaceStore = useOccupierSpaceDefinitionStore();
const {
  actionableUses,
  selectedUse,
  sizeTypeDisplayable,
  phases,
  savePayload,
} = storeToRefs(occupierSpaceStore);
const changeGroupStore = useCrowdsourcedChangeGroupStore();
const { originatingData, changeGroupId } = storeToRefs(changeGroupStore);

function populateForm(isVisible) {
  if (isVisible) {
    occupierSpaceStore.reset();
  }
}
function persist() {
  const huntId = props.dataField.fieldContentId;
  return api.post(
    `hunt_space_requirements`,
    _.merge({}, savePayload.value, {
      huntId,
      changeGroupId: changeGroupId.value,
    }),
  );
}
function afterPersist() {
  occupierSpaceStore.reset();
  emit("refetch");
}
function cancel() {
  occupierSpaceStore.reset();
  emit("cancel");
}
async function save() {
  if (savePayload.value) {
    const apiRequestFunc = () => persist();
    const successCallback = (json) => afterPersist(json);
    const failureCallback = () => cancel();
    await changeGroupStore.originateData(
      apiRequestFunc,
      successCallback,
      failureCallback,
    );
  }
}
</script>
