<template>
  <div class="h-full rounded-lg overflow-hidden">
    <CardBranding :card="card" />
    <div class="absolute inset-0">
      <div
        class="absolute inset-0 bg-gradient-to-r from-rose-600 to-orange-400 mix-blend-multiply"
      />
    </div>
    <div class="h-full relative px-4 py-16 flex flex-col justify-center">
      <h1 class="text-center text-2xl font-bold tracking-tight">
        <span class="block text-white">{{ contentAlias }}</span>
        <span class="block text-orange-100">Key Date</span>
        <span class="block text-orange-200">{{ timingAlias }}</span>
      </h1>
      <p
        v-if="propertyField"
        class="mx-auto mt-8 max-w-lg text-center text-xl font-bold text-rose-200"
      >
        {{ propertyField?.fieldContent?.name }}
      </p>
      <p class="mx-auto mt-2 max-w-lg text-center text-lg text-rose-200">
        Can you assist?
      </p>
      <div
        v-if="dataField && isDesktop"
        class="mt-6 pb-3 flex flex-col items-center"
      >
        <a
          href=""
          @click.prevent="viewLinkedDataField"
          class="mt-4 flex items-center rounded-md border border-transparent bg-white px-4 py-2 text-base font-medium text-orange-700 shadow-sm hover:bg-orange-50"
        >
          <PulseLoader
            v-if="actionInProgress"
            :loading="true"
            size="4px"
            color="#e11d48"
          />
          <template v-else>View Datapoint</template>
        </a>
        <div v-if="propertyId" class="mt-2 flex items-center space-x-2">
          <a
            href=""
            @click.prevent="viewPropertyDiagram"
            class="mt-2 flex items-center justify-center rounded-md border border-white px-3 py-2 text-base font-medium text-white shadow-sm hover:border-orange-50 hover:text-orange-50"
            >View Property</a
          >
          <a
            href=""
            @click.prevent="centerOnMap"
            class="mt-2 flex items-center justify-center rounded-md border border-white px-3 py-2 text-base font-medium text-white shadow-sm hover:border-orange-50 hover:text-orange-50"
            >Center on Map</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import CardBranding from "@/components/collectible-cards/CardBranding.vue";
import { useMainMapStore } from "@/stores/mainMap";
import { usePropertyDiagramStore } from "@/stores/propertyDiagram";
import { useDealBuilderStore } from "@/stores/dealBuilder";
import { useCompanyDetailStore } from "@/stores/companyDetail";
import { useSpaceUsageBuilderStore } from "@/stores/spaceUsageBuilder";
import { usePropertyFieldsStore } from "@/stores/propertyFields";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { storeToRefs } from "pinia";
import { ref, computed, onMounted, watch, nextTick } from "vue";
import api from "@/router/api";
import _ from "lodash";
import { useRoute, useRouter } from "vue-router";

const props = defineProps([
  "card",
  "cardIndex",
  "stackIndex",
  "completedCardIds",
]);

const layoutStore = useWorkspaceLayoutStore();
const { isDesktop } = storeToRefs(layoutStore);
const mapStore = useMainMapStore();
const { temporaryMapCenter, newOriginOverride } = storeToRefs(mapStore);
const propertyDiagramStore = usePropertyDiagramStore();
const { selectableContent: propertyDiagramSelectableContent } =
  storeToRefs(propertyDiagramStore);
const propertyFieldsStore = usePropertyFieldsStore();
const companyDetailStore = useCompanyDetailStore();
const { selectableContent: companySelectableContent } =
  storeToRefs(companyDetailStore);
const dealStore = useDealBuilderStore();
const { crossInteraction: dealCrossInteraction } = storeToRefs(dealStore);
const spaceUsageBuilderStore = useSpaceUsageBuilderStore();
const { crossInteraction: availabilityCrossInteraction } = storeToRefs(
  spaceUsageBuilderStore,
);

const actionInProgress = ref(false);
const taskNameSplitParts = computed(() =>
  _.split(props.card.taskName, " Key Date "),
);
const contentAlias = computed(() => `${_.head(taskNameSplitParts.value)}`);
const timingAlias = computed(() => _.last(taskNameSplitParts.value));

const cardContents = ref([]);
const dataField = computed(() => _.head(cardContents.value)?.content);
const propertyField = computed(() => {
  if (propertyId.value) {
    return propertyFieldsStore.getPropertyField(propertyId.value);
  } else {
    return null;
  }
});
const propertyId = computed(() => dataField.value?.propertyId);
const locationCenter = computed(() => {
  const lat = _.get(
    propertyField.value,
    "fieldContent.locationDataField.fieldContent.lat",
  );
  const lng = _.get(
    propertyField.value,
    "fieldContent.locationDataField.fieldContent.lng",
  );

  if (lat && lng) {
    return [_.toNumber(lat), _.toNumber(lng)];
  } else {
    return null;
  }
});

watch(
  () => props.stackIndex,
  () => {
    if (!dataField.value) fetchTopCardDataField();
  },
);

onMounted(() => {
  fetchTopCardDataField();
});

function fetchTopCardDataField() {
  if (
    props.stackIndex === props.cardIndex &&
    !_.includes(props.completedCardIds, props.card.id)
  ) {
    api
      .get(
        `collectible_cards/${props.card.collectibleCardId}/collectible_card_contents`,
      )
      .then((json) => {
        cardContents.value = json.data;

        if (propertyId.value) {
          propertyFieldsStore.fetchPropertyDataField(propertyId.value);
        }
      });
  }
}

const router = useRouter();
const route = useRoute();

async function viewLinkedDataField() {
  if (dataField.value) {
    const selectableContentType = dataField.value.decoratingContentType;
    const selectableContentId = dataField.value.decoratingContentId;
    const stateName = dataField.value.fieldValue;

    switch (selectableContentType) {
      case "PropertyRight":
      case "FloorArea":
      case "LandCovering":
      case "PropertyEnhancement":
      case "FloorAreaLayout":
        {
          if (propertyId.value) {
            viewPropertyDiagram({
              propertyId: propertyId.value,
              contentType: selectableContentType,
              contentId: selectableContentId,
              focus: "Timing",
            });
          }
        }

        break;
      case "Investment":
      case "Loan":
        {
          const investmentId = selectableContentId;
          const modifier =
            selectableContentType === "Loan" ? "?modifier=Loan" : "";
          actionInProgress.value = true;
          const dealResponse = await api.get(
            `investment_deal_builder_assets/${investmentId}${modifier}`,
          );

          actionInProgress.value = false;
          if (dealResponse?.data) {
            const { investment, assetDataField, propertyDataField, date } =
              dealResponse.data;

            dealStore.patchPropertyDataFields([propertyDataField]);
            await dealStore.upsertDealBuilder({
              assetDataField,
              timeTravelDate: date,
            });
            dealCrossInteraction.value = {
              combinedKey: investment.id,
              focus: "Timing",
              source: "ContentStateAlertCard",
            };
          }
        }
        break;
      case "Hunt":
        {
          const huntId = selectableContentId;
          actionInProgress.value = true;
          const huntResponse = await api.get(`hunts/${huntId}`);

          if (huntResponse?.data) {
            actionInProgress.value = false;
            const companyId = huntResponse.data.fieldContent?.companyId;

            if (companyId) {
              router.push({
                name: "CompanyShell",
                params: { companyId },
                query: {
                  ...route.query,
                  propertyIds: undefined,
                },
              });

              await nextTick();

              companySelectableContent.value = {
                contentType: selectableContentType,
                contentId: selectableContentId,
                focus: "Timing",
              };
            }
          }
        }
        break;
      case "SpaceUsage":
      case "SpaceAvailability":
        {
          const availabilityId = selectableContentId;
          const modifier =
            selectableContentType === "SpaceUsage"
              ? "?modifier=SpaceUsage"
              : "";
          actionInProgress.value = true;
          const availabilityResponse = await api.get(
            `space_availability_builder_spaces/${availabilityId}${modifier}`,
          );
          actionInProgress.value = false;
          if (availabilityResponse?.data) {
            const { availability, spaceDataField, propertyDataField, date } =
              availabilityResponse.data;

            propertyFieldsStore.patchPropertyDataFields([propertyDataField]);
            await spaceUsageBuilderStore.upsertSpaceUsageBuilder({
              spaceDataField,
              timeTravelDate: date,
            });
            availabilityCrossInteraction.value = {
              combinedKey: availability.id,
              focus:
                selectableContentType === "SpaceUsage"
                  ? "Lease/License"
                  : "Timing",
              source: "ContentStateAlertCard",
            };
          }
        }
        break;

      default:
        console.log(
          "unknown linked datafield",
          selectableContentType,
          stateName,
        );
        break;
    }
  }
}

function viewPropertyDiagram(propertySelectableContent = null) {
  if (propertySelectableContent) {
    propertyDiagramSelectableContent.value = propertySelectableContent;
  }

  newOriginOverride.value = true;
  propertyDiagramStore.navigateToDiagram(propertyId.value, true);
}

function centerOnMap() {
  newOriginOverride.value = true;
  temporaryMapCenter.value = {
    lat: locationCenter.value[0],
    lng: locationCenter.value[1],
    zoom: 16.5,
  };
}
</script>
