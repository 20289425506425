<template>
  <div class="h-full flex flex-col flex-1 card">
    <div
      class="h-full w-full shadow content"
      :class="locked && flipped && isTopCard ? 'is-flipped' : ''"
    >
      <component
        v-if="!!cardBackComponent"
        :is="cardBackComponent"
        v-bind="cardBackProps"
        class="back absolute h-full w-full"
        :class="locked && flipped && isTopCard ? 'z-10' : ''"
        @discard="emit('discard')"
        @accept="emit('accept')"
      />
      <component
        :is="cardFrontComponent"
        v-bind="cardFrontProps"
        class="front absolute h-full w-full"
        @set-card-back="setCardBack"
        @discard="emit('discard')"
        @accept="emit('accept')"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";

defineProps([
  "flipped",
  "isTopCard",
  "cardBackComponent",
  "cardBackProps",
  "cardFrontComponent",
  "cardFrontProps",
]);
const emit = defineEmits(["accept", "discard", "set-card-back"]);
const locked = ref(false);

onMounted(() => (locked.value = true));

function setCardBack(component) {
  emit("set-card-back", component);
}
</script>

<style scoped>
.card {
  perspective: 500px;
}

.content {
  transition: transform 1s;
  transform-style: preserve-3d;
}

.is-flipped {
  transform: rotateY(180deg);
  transition: transform 0.5s;
}

.front,
.back {
  backface-visibility: hidden;
}

.back {
  transform: rotateY(180deg);
}
</style>

<!-- <style scoped>
.scene {
  perspective: 500px;
}
.card {
  transition: all 1s;
  transform-style: preserve-3d;
}
.card__face {
  backface-visibility: hidden;
}
.card__face--back {
  transform: rotateY(180deg);
}
.card.is-flipped {
  transform: rotateY(180deg);
  background-color: transparent;
}
</style> -->
