<template>
  <div
    @click="toggleLabelType"
    class="w-full h-full rounded-md overflow-hidden flex relative cursor-pointer"
  >
    <div
      v-if="!allFieldsData || totalCount === 0"
      class="absolute w-full h-full flex items-center justify-center bg-white border-2 border-gray-300 border-dashed"
      style="z-index: 4"
    >
      <PulseLoader
        v-if="!allFieldsData"
        :loading="true"
        size="8px"
        color="#D4D4D8"
      />
      <div v-else-if="totalCount === 0" class="text-sm text-gray-500">
        No fields yet
      </div>
    </div>
    <template v-if="fieldTypesLabel === 'cost'">
      <div
        v-tooltip="'Free'"
        class="bg-teal-500 h-full flex items-center justify-center text-sm text-white font-semibold"
        :style="`z-index: 1; width: ${widthFor(freeCount, 2)}%;`"
      >
        {{ labelFor(freeCount) }}
      </div>
      <div
        v-tooltip="'Unlockable'"
        class="bg-indigo-900 h-full flex items-center justify-center text-sm text-white font-semibold"
        :style="`z-index: 1; width: ${widthFor(licenseableCount, 2)}%;`"
      >
        {{ labelFor(licenseableCount) }}
      </div>
    </template>
    <template v-else>
      <div
        v-tooltip="'Open Source'"
        class="bg-cyan-500 h-full flex items-center justify-center text-sm text-white font-semibold"
        :style="`z-index: 1; width: ${widthFor(openSourceCount, 2)}%;`"
      >
        {{ labelFor(openSourceCount) }}
      </div>
      <div
        @click.stop="viewSafezone"
        v-tooltip="'Safezone'"
        class="bg-amber-500 h-full flex items-center justify-center text-sm text-white font-semibold"
        :style="`z-index: 1; width: ${widthFor(safezoneCount, 2)}%;`"
      >
        {{ labelFor(safezoneCount) }}
      </div>
    </template>
  </div>
</template>

<script setup>
import { computed } from "vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import _ from "lodash";
import { useRoute, useRouter } from "vue-router";

const props = defineProps(["allFieldsData", "fieldTypesLabel"]);
const emit = defineEmits(["set-field-types-label"]);

const licenseableCount = computed(() =>
  _.get(props.allFieldsData, "licenseableCount", 0)
);
const freeCount = computed(() => _.get(props.allFieldsData, "freeCount", 0));
const safezoneCount = computed(() =>
  _.get(props.allFieldsData, "safezoneCount", 0)
);
const openSourceCount = computed(() =>
  _.get(props.allFieldsData, "openSourceCount", 0)
);
const totalCount = computed(() => {
  if (props.fieldTypesLabel === "cost") {
    return _.sum([licenseableCount.value, freeCount.value]);
  } else {
    return _.sum([openSourceCount.value, safezoneCount.value]);
  }
});

function toggleLabelType() {
  if (props.fieldTypesLabel === "cost") {
    emit("set-field-types-label", "visibility");
  } else {
    emit("set-field-types-label", "cost");
  }
}
function widthFor(count, places = 0) {
  const rawPercent = count / totalCount.value;

  return _.round(rawPercent * 100, places);
}
function labelFor(count) {
  if (count === 0) {
    return "";
  } else {
    return count;
  }
}
const router = useRouter();
const route = useRoute();
function viewSafezone() {
  router.push({
    name: route.name,
    query: {
      ...route.query,
      horizontalTab: "Me",
      verticalTab: "Safezone",
    },
  });
}
</script>
