<template>
  <div class="">
    <h3 class="p-2 text-base font-semibold text-gray-900">
      Payload {{ payload.id }} Geography
    </h3>
    <div id="payload-geography-editing-map" class="h-96 w-full bg-gray-50" />
    <div class="p-2 bg-gray-50 flex flex-col items-end space-y-2">
      <fieldset class="w-full" aria-label="Choose a geography option">
        <div class="flex items-center justify-between">
          <div class="text-sm/6 font-medium text-gray-900">Geography Type</div>
        </div>

        <RadioGroup v-model="geo" class="mt-2 grid grid-cols-2 gap-3">
          <RadioGroupOption
            as="template"
            v-for="option in geographyOptions"
            v-focus
            :key="option.name"
            :value="option"
            v-slot="{ active, checked }"
          >
            <div
              :class="[
                active ? 'ring-2 ring-indigo-600 ring-offset-2' : '',
                checked
                  ? 'bg-indigo-600 text-white ring-0 hover:bg-indigo-500'
                  : 'bg-white text-gray-900 ring-1 ring-gray-300 hover:bg-gray-50',
                !active && !checked ? 'ring-inset' : '',
                active && checked ? 'ring-2' : '',
                'cursor-pointer focus:outline-none flex items-center justify-center rounded-md px-3 py-3 text-sm font-semibold uppercase sm:flex-1',
              ]"
            >
              {{ option.name }}
            </div>
          </RadioGroupOption>
        </RadioGroup>
      </fieldset>
      <div v-if="geo.name === 'Polygon'" class="w-full space-y-1">
        <div class="text-sm font-medium text-gray-500">Region Name</div>
        <input
          v-focus
          v-model="locationName"
          type="text"
          name="polygon-name"
          id="polygon-name"
          class="w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block text-sm border-gray-300 rounded-md"
          data-test="polygon-name"
        />
      </div>
      <div class="flex items-center space-x-2">
        <button
          @click="cancel"
          type="button"
          class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Cancel
        </button>
        <DataVisibilityButton visibility="public" class="ml-3 inline-flex">
          <template v-slot:button>
            <button
              @click="save"
              :disabled="persisting || !complete"
              type="button"
              :class="[saveButtonStyle]"
              class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2"
            >
              <PulseLoader
                v-if="persisting"
                :loading="true"
                size="3px"
                color="#f3f4f6"
              />
              <span v-else>Save</span>
            </button>
          </template>
        </DataVisibilityButton>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, nextTick, watch } from "vue";
import { RadioGroup, RadioGroupOption } from "@headlessui/vue";
import { storeToRefs } from "pinia";
import { useEasyDataInputAdminStore } from "@/stores/easyDataInputAdmin";
import { useModalStore } from "@/stores/modal";
import DataVisibilityButton from "@/components/crowdsourcing/DataVisibilityButton.vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import api from "@/router/api";
import mapStyles from "@/components/maps/mapStyles";
import _ from "lodash";
/* global L */

const props = defineProps(["payload", "lat", "lng", "regionName"]);

const easyDataInputAdminStore = useEasyDataInputAdminStore();
const { detailedSelectedJobCRUD } = storeToRefs(easyDataInputAdminStore);
const modalStore = useModalStore();

const geographyOptions = [{ name: "Lat/Lng" }, { name: "Polygon" }];
const geo = ref(geographyOptions[0]);

const map = ref(null);
const marker = ref(null);
const zoom = ref(17);
const featureGroup = ref(L.featureGroup());
const parcelFeatureGroup = ref(L.featureGroup());
const baseLayers = ref({
  Base: L.gridLayer.googleMutant({
    type: "roadmap",
    styles: mapStyles.styles,
  }),
  Satellite: L.gridLayer.googleMutant({
    type: "satellite",
    styles: [],
  }),
  Hybrid: L.gridLayer.googleMutant({
    type: "hybrid",
    styles: [],
  }),
  Transit: L.gridLayer.googleMutant({
    type: "roadmap",
    styles: [],
  }),
});

const center = computed(() => {
  return [props.lat, props.lng];
});
const locationName = ref(props.regionName);
const persisting = ref(false);

const nameComplete = computed(() => {
  return _.trim(locationName.value) !== "";
});
const polygonComplete = computed(() => {
  return !!_.head(featureGroup.value.pm.getLayers());
});
const complete = computed(() => nameComplete.value && polygonComplete.value);
const saveButtonStyle = computed(() => {
  if (!complete.value) {
    return "bg-gray-400 hover:bg-gray-500 focus:ring-gray-500 cursor-not-allowed";
  } else {
    return "bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500";
  }
});

watch(
  geo,
  async () => {
    clearMapLayers();
    await nextTick();

    if (geo.value.name === "Polygon") {
      zoom.value = 10;
      setupDrawing();
    } else {
      zoom.value = 17;
      addMarker();
    }

    map.value.setView(center.value, zoom.value);
  },
  { deep: true },
);

onMounted(() => {
  setupMap();
});

function setupDrawing() {
  map.value.pm.enableDraw("Polygon", {
    allowSelfIntersection: false,
  });
}

function setupMap() {
  const mapId = "payload-geography-editing-map";
  map.value = L.map(mapId, {
    fullscreenControl: false,
    zoomAnimation: false,
    fadeAnimation: true,
    zoomSnap: 0.5,
    zoomDelta: 0.5,
    keyboardPanDelta: 50,
    markerZoomAnimation: true,
  }).setView(center.value, zoom.value);

  baseLayers.value["Transit"].addGoogleLayer("TransitLayer");

  baseLayers.value["Satellite"].addTo(map.value);

  map.value.on("moveend", function () {
    transitionMapLayer();
  });

  map.value.on("pm:create", ({ layer }) => {
    featureGroup.value.addLayer(layer);
    setEditingControls();
  });

  map.value.on("pm:remove", () => {
    clearMapLayers();
  });

  parcelFeatureGroup.value.addTo(map.value);

  L.control
    .layers(
      baseLayers.value,
      {},
      {
        collapsed: true,
      },
    )
    .addTo(map.value);

  addMarker();
}

function transitionMapLayer() {
  const currentZoom = map.value.getZoom();

  if (currentZoom > 14.25) {
    toggleMapLayer("Hybrid");
  } else {
    toggleMapLayer("Base");
  }
}

function toggleMapLayer(layerName) {
  const layerToCheck = _.get(baseLayers.value, layerName);

  if (!layerToCheck) {
    return;
  } else if (map.value.hasLayer(layerToCheck)) {
    return;
  } else {
    let addedLayer;

    _.forEach(baseLayers.value, function (layer, key) {
      if (key == layerName) {
        addedLayer = layer;
      } else {
        layer.remove();
      }
    });

    addedLayer.addTo(map.value);
  }
}

function getCoordinates() {
  let polygonLayer = _.head(featureGroup.value.pm.getLayers());
  if (polygonLayer) {
    return polygonLayer.getLatLngs()[0].map((c) => {
      return { lat: c.lat, lng: c.lng };
    });
  }
}
async function clearMapLayers() {
  let group = featureGroup.value;

  group.eachLayer((layer) => {
    map.value.removeLayer(layer);
    group.removeLayer(layer);
  });

  await nextTick();

  locationName.value = props.regionName;
  marker.value = null;
}

function addMarker() {
  marker.value = L.marker(center.value, {
    draggable: false,
    zIndexOffset: 1000,
  });

  featureGroup.value.addLayer(marker.value);
  marker.value.addTo(map.value);
}

function setEditingControls() {
  map.value.pm.addControls({
    position: "topleft",
    drawMarker: false,
    drawCircleMarker: false,
    drawPolyline: false,
    drawRectangle: false,
    drawPolygon: false,
    drawCircle: false,
    drawText: false,
    editMode: false,
    dragMode: false,
    rotateMode: false,
    cutPolygon: false,
    removalMode: false,
  });
  featureGroup.value.pm.enable({
    allowSelfIntersection: false,
  });
}
function cancel() {
  modalStore.closeModal();
}
async function save() {
  featureGroup.value.pm.disable();

  if (detailedSelectedJobCRUD.value?.payloads) {
    let writablePayload = _.find(detailedSelectedJobCRUD.value.payloads, {
      id: props.payload.id,
    });

    if (geo.value.name === "Polygon") {
      const coords = getCoordinates();
      const eligibleCoords = coords && coords.length >= 3;

      if (eligibleCoords) {
        const polygonResponse = await persistPolygon();

        if (polygonResponse?.data) {
          writablePayload.geography = {
            geographyType: "region",
            geographyContent: polygonResponse.data,
          };
        }
      }
    } else {
      writablePayload.geography = {
        geographyType: "latLng",
        geographyContent: [props.lat, props.lng],
        geographyName: locationName.value,
      };
    }

    modalStore.closeModal();
  }
}

function persistPolygon() {
  let payload = {
    name: locationName.value,
  };

  let polygonLayer = _.head(featureGroup.value.pm.getLayers());

  if (polygonLayer) {
    _.merge(payload, {
      coordinates: getCoordinates(),
    });

    return api.post(`admin/geography_regions`, payload);
  } else {
    return {};
  }
}
</script>
