<template>
  <div
    class="col-span-1"
    :data-test="`investment-group-${clientRole}-advisors-container`"
  >
    <dt class="text-sm font-medium text-gray-500">
      {{ dealSideLabel }}Advisors
    </dt>
    <dd v-if="fetchedAdvisors.length > 0" class="mt-1 space-y-2">
      <InvolvedCompany
        v-for="companyId in fetchedAdvisors"
        :key="`group-${clientRole}-advisor-${companyId}`"
        :fetch-company-id="companyId"
        layer-type="Advisor"
        layer-color="bg-teal-400"
        :providers-count="fetchedAdvisors.length"
        context="investment-group-players"
        :client-role="clientRole"
        :investment-group-id="existingGroupId"
        :fetch-milliseconds="fetchMilliseconds"
        :parent-component-save-function="persistCompanies"
        @override-refetch="fetchAdvisors"
      />
    </dd>
    <div v-if="editingAdvisors" class="mt-1 flex flex-col space-y-1">
      <div
        class="border border-gray-300 rounded-lg shadow-sm focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500"
      >
        <CompanyContactAutocomplete
          label="Advisors"
          :companies="allLocalAdvisors"
          :filtered-companies="filteredLocalAdvisors"
          :client-role="clientRole"
          :input-key="`investment-group-${clientRole}-advisors`"
          @new-companies="setNewCompanies"
          @remove-company="removeCompany"
          @set-cross-interaction="setCrossInteraction"
        />
      </div>
      <div v-if="existingGroupId" class="flex justify-end space-x-2">
        <button
          @click="cancelAdvisorEditing"
          type="button"
          v-tooltip="'Cancel'"
          class="inline-flex items-center p-1 border border-gray-300 rounded-full shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <XMarkIcon class="h-4 w-4" />
        </button>

        <DataVisibilityButton visibility="safezone" class="inline-flex">
          <template v-slot:button>
            <button
              @click="saveAdvisors"
              :disabled="originatingData"
              type="button"
              class="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-yellow-500 hover:bg-yellow-600 focus:ring-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2"
              :data-test="`investment-group-${clientRole}-advisors-save`"
            >
              <PulseLoader
                v-if="originatingData"
                :loading="true"
                size="3px"
                color="#f3f4f6"
              />
              <CheckIcon v-else class="h-4 w-4" />
            </button>
          </template>
        </DataVisibilityButton>
      </div>
    </div>
    <dd
      v-else-if="filteredLocalAdvisors.length > 0"
      class="mt-1 flex flex-wrap"
    >
      <li
        @click="editingAdvisors = true"
        v-for="company in filteredLocalAdvisors"
        :key="company.name"
        v-tooltip="'Unsaved'"
        class="inline-flex rounded-full items-center my-1 mr-1 py-0.5 px-2.5 text-sm font-medium bg-indigo-100 text-indigo-700"
      >
        {{ company.name }}
      </li>
    </dd>
    <div v-else-if="addable" class="mt-2 flex items-center space-x-2">
      <DataVisibilityButton visibility="safezone">
        <template v-slot:button>
          <button
            @click="editingAdvisors = true"
            type="button"
            :data-test="`investment-group-${clientRole}-advisors-add-button`"
            class="group py-1 px-1.5 flex items-center justify-between rounded-full border border-gray-300 shadow-sm space-x-2 text-left hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <span class="min-w-0 flex-1 flex items-center space-x-1">
              <span class="flex-shrink-0 flex items-center">
                <span
                  class="inline-flex items-center justify-center h-5 w-5 rounded-full bg-yellow-500"
                >
                  <BriefcaseIcon class="h-4 w-4 text-white" />
                </span>
              </span>
              <span class="min-w-0 flex-1">
                <span class="text-sm font-medium text-gray-900 truncate"
                  >Add companies</span
                >
              </span>
            </span>
          </button>
        </template>
      </DataVisibilityButton>
    </div>
  </div>
</template>

<script setup>
import { XMarkIcon, CheckIcon } from "@heroicons/vue/20/solid";
import { BriefcaseIcon } from "@heroicons/vue/24/outline";
import CompanyContactAutocomplete from "@/components/crowdsourcing/CompanyContactAutocomplete.vue";
import InvolvedCompany from "@/components/crowdsourcing/InvolvedCompany.vue";
import { useDealBuilderStore } from "@/stores/dealBuilder";
import { useCrowdsourcedChangeGroupStore } from "@/stores/crowdsourcedChangeGroup";
import { ref, computed, watch, onMounted } from "vue";
import { storeToRefs } from "pinia";
import api from "@/router/api";
import moment from "moment";
import _ from "lodash";
import DataVisibilityButton from "@/components/crowdsourcing/DataVisibilityButton.vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";

const props = defineProps([
  "existingGroupId",
  "placeholderGroupId",
  "clientRole",
  "fetchMilliseconds",
  "context",
]);

const dealBuilderStore = useDealBuilderStore();
const { dealBuilder } = storeToRefs(dealBuilderStore);
const changeGroupStore = useCrowdsourcedChangeGroupStore();
const { originatingData, changeGroupId } = storeToRefs(changeGroupStore);

const fetchedAdvisors = ref([]);
const editingAdvisors = ref(false);

const addable = computed(() => props.context !== "table-row");

const dealSideLabel = computed(() => {
  switch (props.clientRole) {
    case "capitalConsumer":
      switch (_.get(investmentGroup.value, "dealAction", null)) {
        case "originateLoan":
        case "refinance":
        case "Loan":
          return "Borrower ";
        default:
          return "Seller ";
      }
    case "capitalProvider":
      switch (_.get(investmentGroup.value, "dealAction", null)) {
        case "originateLoan":
        case "refinance":
        case "Loan":
          return "Lender ";
        default:
          return "Buy ";
      }
    default:
      return "";
  }
});
const investmentGroupId = computed(() => {
  return props.existingGroupId || props.placeholderGroupId;
});
const investmentGroup = computed(() => {
  const finder = props.existingGroupId
    ? { id: props.existingGroupId }
    : { placeholderId: props.placeholderGroupId };

  return _.find(_.get(dealBuilder.value, "investmentGroups", []), finder);
});
const groupState = computed(() => {
  if (groupDateState.value.states.length === 1) {
    return _.head(groupDateState.value.states);
  } else {
    return null;
  }
});
const groupDateState = computed(() => {
  const investments = _.map(
    _.get(investmentGroup.value, "investments", []),
    function (groupInvestment) {
      return focalInvestmentFor(groupInvestment);
    },
  );
  const ids = investments.map((i) => i.id);
  const dates = _.uniq(
    investments.map((i) => {
      if (i.date) {
        return moment(i.date).format("YYYYMMDD");
      } else {
        return null;
      }
    }),
  );
  const states = _.uniq(investments.map((i) => i.state));

  return { ids, dates, states };
});
const investments = computed(() => {
  return _.get(investmentGroup.value, "investments", {});
});
const allLocalAdvisors = computed(() => {
  const combinedAdvisors = _.flatMap(investments.value, function (investment) {
    return _.get(
      investment,
      "existingInvestment.advisors",
      investment.advisors,
    );
  });

  return _.uniq(combinedAdvisors, function (advisorObject) {
    return advisorObject.name;
  });
});
const filteredLocalAdvisors = computed(() => {
  return allLocalAdvisors.value.filter((advisorObject) => {
    return advisorObject.clientRole === props.clientRole;
  });
});

watch(groupState, (id, oldId) => {
  if (id && id !== oldId) {
    fetchAdvisors();
  }
});

onMounted(() => {
  fetchAdvisors();
});

function focalInvestmentFor(groupInvestment) {
  return _.get(groupInvestment, "existingInvestment") || groupInvestment;
}
function setNewCompanies(newCompanies) {
  const addedCompanies = _.differenceBy(
    newCompanies,
    filteredLocalAdvisors.value,
    function (advisorObject) {
      return `${advisorObject.name}_${advisorObject.clientRole}`;
    },
  );

  _.forEach(investments.value, function (investment) {
    addAdvisors(addedCompanies, investment.temporaryId);
  });
}
function addAdvisors(addedCompanies, combinedKey) {
  dealBuilderStore.addInvestmentGroupInvestmentPlayers({
    groupId: investmentGroupId.value,
    investmentCombinedKey: combinedKey,
    addedCompanies,
    path: "advisors",
    compareBy: function (advisorObject) {
      return `${advisorObject.name}_${advisorObject.clientRole}`;
    },
  });
}
function removeCompany(advisorObject) {
  _.forEach(investments.value, function (investment) {
    dealBuilderStore.removeInvestmentGroupInvestmentPlayer({
      groupId: investmentGroupId.value,
      investmentCombinedKey: investment.temporaryId,
      toRemove: advisorObject,
      path: "advisors",
      compareBy: function (advisorObject) {
        return `${advisorObject.name}_${advisorObject.clientRole}`;
      },
    });
  });
}
function setCrossInteraction() {}
function cancelAdvisorEditing() {
  editingAdvisors.value = false;
}
function clearAdvisorEditing() {
  filteredLocalAdvisors.value.forEach((advisorObject) => {
    removeCompany(advisorObject);
  });
  cancelAdvisorEditing();
}
const persistCompanies = async (proofCompanies = null) => {
  const payload = {
    investmentGroupId: props.existingGroupId,
    advisors: proofCompanies || filteredLocalAdvisors.value,
    changeGroupId: changeGroupId.value,
  };

  const response = await api.post(`investment_group_advisors`, payload);

  console.log("group advisors persist", proofCompanies, payload, response);

  return response;
};
function saveAdvisors() {
  setTimeout(() => {
    if (props.existingGroupId) {
      const apiRequestFunc = () => persistCompanies();
      const successCallback = () => {
        clearAdvisorEditing();
        dealBuilderStore.fetchInvestmentGroupInvestments(props.existingGroupId);
        fetchAdvisors();
        // Flash: "Advisors added to all investments in the portfolio."
      };
      const failureCallback = () => clearAdvisorEditing();

      return changeGroupStore.originateData(
        apiRequestFunc,
        successCallback,
        failureCallback,
      );
    }
  }, 125);
}
function fetchAdvisors() {
  if (props.existingGroupId) {
    api
      .get(
        `investment_group_advisors/?investment_group_id=${props.existingGroupId}&client_role=${props.clientRole}`,
      )
      .then((json) => {
        fetchedAdvisors.value = json.data;
      });
  }
}
</script>
