import { currencyBelowThreshold } from "@/assets/numberHelpers";
import _ from "lodash";

function operableFloorAreas(calculationData, calculationOptions) {
  const floorAreaDataFields = _.get(
    calculationData,
    "sizesUses.floorAreaDataFields",
    [],
  );
  const includeAirRights = calculationOptions.sizesUses.includeAirRights;

  if (includeAirRights) {
    return floorAreaDataFields;
  } else {
    return floorAreaDataFields.filter(
      (df) => df.decoratingContentSubType !== "Unused Development Rights",
    );
  }
}

function areaSum(areaDataFields, calcFieldName = "standardized_area") {
  let numericAreas = [];
  if (calcFieldName === "standardized_area") {
    numericAreas = areaDataFields
      .filter(({ fieldName }) => fieldName === calcFieldName)
      .map((df) => _.toNumber(df.fieldValue));
  } else if (calcFieldName === "minimum_subdivision_standardized_area") {
    const grouped = _.groupBy(areaDataFields, "decoratingContentId");
    const areaFields = _.map(grouped, function (dfs) {
      return (
        _.find(dfs, { fieldName: calcFieldName }) ||
        _.find(dfs, { fieldName: "standardized_area" })
      );
    });
    numericAreas = areaFields.map((df) => _.toNumber(df.fieldValue));
  }
  return _.sum(numericAreas);
}

function standardizedArea(calculationData, calculationOptions) {
  const floorAreaIds = includedFloorAreas(
    calculationData,
    calculationOptions,
  ).map((df) => df.fieldContentId);
  const standardizedAreaDataFields = _.get(
    calculationData,
    "sizesUses.standardizedAreaDataFields",
    [],
  );
  const operableStandardizedAreas = standardizedAreaDataFields.filter((df) => {
    return _.includes(floorAreaIds, df.decoratingContentId);
  });

  return areaSum(operableStandardizedAreas);
}

function standardizedAreaString(calculationData, calculationOptions) {
  return `${currencyBelowThreshold(
    standardizedArea(calculationData, calculationOptions),
  )} SF`;
}

function multiUseAreas(calculationData, calculationOptions) {
  return operableFloorAreas(calculationData, calculationOptions).filter(
    (df) => {
      return _.get(df, "fieldContent.useTypes", []).length > 1;
    },
  );
}

function multiCoveringAreas(calculationData, calculationOptions) {
  return operableFloorAreas(calculationData, calculationOptions).filter(
    (df) => {
      return _.get(df, "fieldContent.landCoveringCount") > 1;
    },
  );
}

function noStandardizedArea(calculationData, calculationOptions) {
  const standardizedAreaDataFields = _.get(
    calculationData,
    "sizesUses.standardizedAreaDataFields",
    [],
  );
  return operableFloorAreas(calculationData, calculationOptions).filter(
    (df) => {
      return !_.find(standardizedAreaDataFields, {
        decoratingContentId: df.fieldContentId,
      });
    },
  );
}

function noUseBasedArea(calculationData, calculationOptions) {
  const useBasedAreaDataFields = _.get(
    calculationData,
    "sizesUses.useBasedAreaDataFields",
    [],
  );
  return operableFloorAreas(calculationData, calculationOptions)
    .filter((df) => {
      return _.some(df.fieldContent?.useTypes, function (useType) {
        return useBasedAreaType(useType);
      });
    })
    .filter((df) => {
      return !_.find(useBasedAreaDataFields, {
        decoratingContentId: df.fieldContentId,
      });
    });
}

function missingUseAreas(calculationData, calculationOptions) {
  const standardizedAreaDataFields = _.get(
    calculationData,
    "sizesUses.standardizedAreaDataFields",
    [],
  );
  const useBasedAreaDataFields = _.get(
    calculationData,
    "sizesUses.useBasedAreaDataFields",
    [],
  );
  return operableFloorAreas(calculationData, calculationOptions).filter(
    (df) => {
      return (
        _.isEmpty(_.get(df, "fieldContent.useTypes", [])) &&
        (_.find(standardizedAreaDataFields, {
          decoratingContentId: df.fieldContentId,
        }) ||
          _.find(useBasedAreaDataFields, {
            decoratingContentId: df.fieldContentId,
          }))
      );
    },
  );
}

function useTypes(calculationData, calculationOptions) {
  const floorAreaIds = operableFloorAreas(
    calculationData,
    calculationOptions,
  ).map((df) => df.fieldContentId);
  return _.uniq(
    calculationData.sizesUses.propertyUseDataFields
      .filter((df) => _.includes(floorAreaIds, df.decoratingContentId))
      .map((df) => {
        return _.get(df, "fieldContent.name");
      }),
  );
}

function includedFloorAreas(calculationData, calculationOptions) {
  const excludedUseTypes = calculationOptions.excludedUses.useTypes;
  return operableFloorAreas(calculationData, calculationOptions).filter(
    (df) => {
      const useTypes = _.get(df, "fieldContent.useTypes", []);
      return _.isEmpty(_.intersection(excludedUseTypes, useTypes));
    },
  );
}

function typedFloorAreas(useType, calculationData, calculationOptions) {
  return operableFloorAreas(calculationData, calculationOptions).filter(
    (df) => {
      return _.includes(_.get(df, "fieldContent.useTypes", []), useType);
    },
  );
}

function unitFor(useType) {
  switch (useType) {
    case "Office":
    case "Retail":
    case "Industrial":
    case "R&D":
    case "Research & Development":
    case "Healthcare":
    case "Casino":
    case "Common Area":
    case "Mechanical":
      return "SF";
    case "Residential":
    case "Multifamily":
    case "Senior Housing":
    case "Student Housing":
      return "Doors";
    case "Hotel":
      return "Keys";
    case "Self-Storage":
      return "Units";
    case "Data Center":
      return "MW";
    case "Parking":
      return "Spaces";
  }
}

function perAreaFor(useType) {
  switch (useType) {
    case "Office":
    case "Retail":
    case "Industrial":
    case "R&D":
    case "Research & Development":
    case "Healthcare":
    case "Casino":
    case "Common Area":
    case "Mechanical":
      return "SF";
    case "Residential":
    case "Multifamily":
    case "Senior Housing":
    case "Student Housing":
      return "Door";
    case "Hotel":
      return "Key";
    case "Self-Storage":
      return "Unit";
    case "Data Center":
      return "MW";
    case "Parking":
      return "Space";
  }
}

function minimumSubdivisionAreaFor(
  useType,
  calculationData,
  calculationOptions,
) {
  const floorAreaIds = typedFloorAreas(
    useType,
    calculationData,
    calculationOptions,
  ).map((df) => df.fieldContentId);
  const standardizedAreaDataFields = _.get(
    calculationData,
    "sizesUses.standardizedAreaDataFields",
    [],
  );
  const operableStandardizedAreas = standardizedAreaDataFields.filter((df) => {
    return _.includes(floorAreaIds, df.decoratingContentId);
  });

  return areaSum(
    operableStandardizedAreas,
    "minimum_subdivision_standardized_area",
  );
}

function minimumSubdivisionAreaStringFor(
  useType,
  calculationData,
  calculationOptions,
) {
  return `${currencyBelowThreshold(
    minimumSubdivisionAreaFor(useType, calculationData, calculationOptions),
  )} SF`;
}

function standardizedAreaFor(useType, calculationData, calculationOptions) {
  const floorAreaIds = typedFloorAreas(
    useType,
    calculationData,
    calculationOptions,
  ).map((df) => df.fieldContentId);
  const standardizedAreaDataFields = _.get(
    calculationData,
    "sizesUses.standardizedAreaDataFields",
    [],
  );
  const operableStandardizedAreas = standardizedAreaDataFields.filter((df) => {
    return _.includes(floorAreaIds, df.decoratingContentId);
  });

  return areaSum(operableStandardizedAreas);
}

function standardizedAreaStringFor(
  useType,
  calculationData,
  calculationOptions,
) {
  return `${currencyBelowThreshold(
    standardizedAreaFor(useType, calculationData, calculationOptions),
  )} SF`;
}

function useBasedAreaFor(useType, calculationData, calculationOptions) {
  const floorAreaIds = typedFloorAreas(
    useType,
    calculationData,
    calculationOptions,
  ).map((df) => df.fieldContentId);
  const useBasedAreaDataFields = _.get(
    calculationData,
    "sizesUses.useBasedAreaDataFields",
    [],
  );
  const operableStandardizedAreas = useBasedAreaDataFields.filter((df) => {
    return _.includes(floorAreaIds, df.decoratingContentId);
  });

  return areaSum(operableStandardizedAreas);
}

function useBasedAreaStringFor(useType, calculationData, calculationOptions) {
  return `${currencyBelowThreshold(
    useBasedAreaFor(useType, calculationData, calculationOptions),
  )} ${unitFor(useType)}`;
}

function bundleFieldIdsFor(
  useType,
  calculationData,
  calculationOptions,
  calculationName = null,
) {
  const floorAreaIds = typedFloorAreas(
    useType,
    calculationData,
    calculationOptions,
  ).map((df) => df.fieldContentId);
  const floorAreaFieldIds = typedFloorAreas(
    useType,
    calculationData,
    calculationOptions,
  ).map((df) => df.localId);
  let areaDataFields = [];

  switch (calculationName) {
    case "standardized": {
      const standardizedAreaDataFields = _.get(
        calculationData,
        "sizesUses.standardizedAreaDataFields",
        [],
      );
      areaDataFields = standardizedAreaDataFields.filter((df) => {
        return (
          _.includes(floorAreaIds, df.decoratingContentId) &&
          df.fieldName === "standardized_area"
        );
      });
      break;
    }
    case "minimum_subdivision_standardized": {
      const standardizedAreaDataFields = _.get(
        calculationData,
        "sizesUses.standardizedAreaDataFields",
        [],
      );
      areaDataFields = standardizedAreaDataFields.filter((df) => {
        return _.includes(floorAreaIds, df.decoratingContentId);
      });
      break;
    }
    case "useBased": {
      const useBasedAreaDataFields = _.get(
        calculationData,
        "sizesUses.useBasedAreaDataFields",
        [],
      );
      areaDataFields = useBasedAreaDataFields.filter((df) => {
        return _.includes(floorAreaIds, df.decoratingContentId);
      });
      break;
    }
  }

  return _.concat(
    floorAreaFieldIds,
    areaDataFields.map((df) => df.localId),
  );
}
function useBasedAreaType(useType) {
  return _.includes(
    [
      "Residential",
      "Multifamily",
      "Senior Housing",
      "Student Housing",
      "Hotel",
      "Self-Storage",
      "Data Center",
      "Parking",
    ],
    useType,
  );
}

// WARNINGS

function warningsForTopLevelAreas(calculationData, calculationOptions) {
  const missingUseAreaFields = missingUseAreas(
    calculationData,
    calculationOptions,
  );
  let missingUseAreaWarning;

  if (_.size(missingUseAreaFields) > 0) {
    missingUseAreaWarning = {
      id: "missing_use",
      description: "Floor areas with sizes but no uses.",
      extendedDescription:
        "Fix this by adding a property use to the floor area.",
      rawFields: missingUseAreaFields,
    };
  }

  return _.compact([missingUseAreaWarning]);
}

function warningsForUseType(
  useType,
  calculationContentType,
  calculationData,
  calculationOptions,
) {
  const multiUseAreaFields = multiUseAreas(
    calculationData,
    calculationOptions,
  ).filter((floorAreaField) => {
    return _.includes(floorAreaField.fieldContent.useTypes, useType);
  });
  let multiUseWarning;

  if (_.size(multiUseAreaFields) > 0) {
    multiUseWarning = {
      id: "multi_use",
      description: "Floor areas with multiple uses.",
      extendedDescription:
        "Fix this by splitting each use into its own floor area with a specific size.",
      rawFields: multiUseAreaFields,
    };
  }

  const multiCoveringAreaFields = multiCoveringAreas(
    calculationData,
    calculationOptions,
  ).filter((floorAreaField) => {
    return _.includes(floorAreaField.fieldContent.useTypes, useType);
  });
  let multiCoveringWarning;

  if (
    calculationContentType === "LandCovering" &&
    _.size(multiCoveringAreaFields) > 0
  ) {
    multiUseWarning = {
      id: "multi_covering",
      description: "Multi-building floor areas.",
      extendedDescription:
        "Fix this by placing each floor area within a single building.",
      rawFields: multiCoveringAreaFields,
    };
  }

  const noStandardizedAreaFields = noStandardizedArea(
    calculationData,
    calculationOptions,
  ).filter((floorAreaField) => {
    return _.includes(floorAreaField.fieldContent.useTypes, useType);
  });
  let noStandardizedAreaWarning;
  if (_.size(noStandardizedAreaFields) > 0) {
    noStandardizedAreaWarning = {
      id: "no_standardized_area",
      description: "Floor areas without standardized area.",
      extendedDescription: "Fix this by adding a size to the floor area.",
      rawFields: noStandardizedAreaFields,
    };
  }

  const noUseBasedAreaFields = noUseBasedArea(
    calculationData,
    calculationOptions,
  ).filter((floorAreaField) => {
    return _.includes(floorAreaField.fieldContent.useTypes, useType);
  });
  let noUseBasedAreaWarning;
  if (_.size(noUseBasedAreaFields) > 0) {
    noUseBasedAreaWarning = {
      id: "no_use_based_area",
      description: "Floor areas without use-based area.",
      extendedDescription:
        "Fix this by adding use-based units to the floor area.",
      rawFields: noUseBasedAreaFields,
    };
  }

  return _.compact([
    multiUseWarning,
    multiCoveringWarning,
    noStandardizedAreaWarning,
    noUseBasedAreaWarning,
  ]);
}

function useTypeOutputs(
  calculationContentType,
  calculationData,
  calculationOptions,
) {
  const rawTypes = useTypes(calculationData, calculationOptions).map(
    (useType) => {
      const excludedUseTypes = calculationOptions.excludedUses.useTypes;
      return {
        name: useType,
        excluded: _.includes(excludedUseTypes, useType),
        useBasedAreaType: useBasedAreaType(useType),
        numericUseBasedArea: useBasedAreaFor(
          useType,
          calculationData,
          calculationOptions,
        ),
        useBasedArea: useBasedAreaStringFor(
          useType,
          calculationData,
          calculationOptions,
        ),
        useBasedBundleFieldIds: bundleFieldIdsFor(
          useType,
          calculationData,
          calculationOptions,
          "useBased",
        ),
        unitName: unitFor(useType),
        perAreaName: perAreaFor(useType),
        numericStandardizedArea: standardizedAreaFor(
          useType,
          calculationData,
          calculationOptions,
        ),
        standardizedArea: standardizedAreaStringFor(
          useType,
          calculationData,
          calculationOptions,
        ),
        standardizedBundleFieldIds: bundleFieldIdsFor(
          useType,
          calculationData,
          calculationOptions,
          "standardized",
        ),
        numericMinimumSubdivisionArea: minimumSubdivisionAreaFor(
          useType,
          calculationData,
          calculationOptions,
        ),
        minimumSubdivisionArea: minimumSubdivisionAreaStringFor(
          useType,
          calculationData,
          calculationOptions,
        ),
        minimumSubdivisionBundleFieldIds: bundleFieldIdsFor(
          useType,
          calculationData,
          calculationOptions,
          "minimum_subdivision_standardized",
        ),
        warnings: warningsForUseType(
          useType,
          calculationContentType,
          calculationData,
          calculationOptions,
        ),
      };
    },
  );

  return _.orderBy(rawTypes, ["numericStandardizedArea"], ["desc"]);
}

export default function sizeUseOutputs(
  calculationContentType,
  calculationData,
  calculationOptions,
) {
  return {
    numericStandardizedArea: standardizedArea(
      calculationData,
      calculationOptions,
    ),
    standardizedArea: standardizedAreaString(
      calculationData,
      calculationOptions,
    ),
    warnings: warningsForTopLevelAreas(calculationData, calculationOptions),
    useTypes: useTypeOutputs(
      calculationContentType,
      calculationData,
      calculationOptions,
    ),
  };
}
