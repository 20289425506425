<template>
  <div class="mt-1 flex flex-col space-y-1">
    <div class="text-sm font-medium text-gray-500">Alias</div>
    <input
      v-focus
      v-model="name"
      type="text"
      :name="`${contentType}-alias`"
      :id="`${contentType}-alias`"
      class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-sm border-gray-300 rounded-md"
      :data-test="`content-alias-input`"
    />
    <div class="flex items-center justify-end space-x-2">
      <button
        @click="cancel"
        type="button"
        class="inline-flex items-center p-1 border border-gray-300 rounded-full shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        <XMarkIcon class="h-4 w-4" />
      </button>

      <DataVisibilityButton
        v-if="complete"
        visibility="public"
        tooltip="Save"
        class="inline-flex"
      >
        <template v-slot:button>
          <button
            @click="save"
            :disabled="originatingData"
            type="button"
            class="inline-flex items-center p-1 border border-transparent bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 rounded-full shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2"
            :data-test="`content-alias-save`"
          >
            <PulseLoader
              v-if="originatingData"
              :loading="true"
              size="3px"
              color="#f3f4f6"
            />
            <CheckIcon v-else class="h-4 w-4" />
          </button>
        </template>
      </DataVisibilityButton>
    </div>
  </div>
</template>

<script setup>
import { XMarkIcon, CheckIcon } from "@heroicons/vue/20/solid";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { useCrowdsourcedChangeGroupStore } from "@/stores/crowdsourcedChangeGroup";
import api from "@/router/api";
import { computed, ref } from "vue";
import { storeToRefs } from "pinia";
import _ from "lodash";
import DataVisibilityButton from "@/components/crowdsourcing/DataVisibilityButton.vue";

const props = defineProps(["content", "contentType"]);
const emit = defineEmits(["cancel", "completed", "unlocked"]);
const name = ref("");

const changeGroupStore = useCrowdsourcedChangeGroupStore();
const { originatingData, changeGroupId } = storeToRefs(changeGroupStore);

const complete = computed(() => {
  const filled = _.trim(name.value) !== "";
  return filled;
});

function persist() {
  const payload = {
    name: name.value,
    changeGroupId: changeGroupId.value,
  };
  return api.post(
    `content_aliases/${props.contentType}/${props.content.id}`,
    payload,
  );
}
function afterPersist() {
  emit("completed");
}
function cancel() {
  emit("cancel");
}

async function save() {
  if (complete.value) {
    const apiRequestFunc = () => persist();
    const successCallback = (json) => afterPersist(json);
    const failureCallback = () => cancel();
    await changeGroupStore.originateData(
      apiRequestFunc,
      successCallback,
      failureCallback,
    );
  }
}
</script>
