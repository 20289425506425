export default {
  CitationChangeGroup: [],
  ContentLocation: [
    {
      order: 1,
      name: "City",
      value: "city",
    },
    {
      order: 2,
      name: "State",
      value: "state",
    },
    {
      order: 3,
      name: "Latitude",
      value: "lat",
    },
    {
      order: 4,
      name: "Longitude",
      value: "lng",
    },
  ],
  GeographyRegion: [
    {
      order: 1,
      name: "Lat/Lng Coordinates",
      value: "coordinates",
    },
  ],
  ContentPolygon: [
    {
      order: 1,
      name: "Lat/Lng Coordinates",
      value: "coordinates",
    },
  ],
  PropertyRight: [
    {
      order: 1,
      name: "Right",
      value: "type",
    },
    {
      order: 2,
      name: "Name",
      value: "name",
    },
    {
      order: 3,
      name: "ID",
      value: "id",
    },
  ],
  PropertyEnhancement: [
    {
      order: 1,
      name: "Name",
      value: "name",
    },
    {
      order: 2,
      name: "ID",
      value: "id",
    },
  ],
  Hunt: [
    {
      order: 1,
      name: "Name",
      value: "name",
    },
    {
      order: 2,
      name: "ID",
      value: "id",
    },
  ],
  HuntGeographyIntent: [
    {
      order: 1,
      name: "Market",
      value: "name",
    },
  ],
  HuntSpaceRequirement: [
    {
      order: 1,
      name: "Use",
      value: "propertyUse.fieldContent.name",
    },
  ],
  SpaceRequirementPhase: [
    {
      order: 1,
      name: "Order",
      value: "orderLabel",
    },
  ],
  LandCovering: [
    {
      order: 1,
      name: "Name",
      value: "name",
    },
    {
      order: 2,
      name: "ID",
      value: "id",
    },
  ],
  LandCoveringGrouping: [
    {
      order: 1,
      name: "Name",
      value: "name",
    },
    {
      order: 2,
      name: "ID",
      value: "id",
    },
  ],
  LandCoveringLevel: [
    {
      order: 1,
      name: "Floor",
      value: "verticalLevel",
    },
  ],
  FloorArea: [
    {
      order: 1,
      name: "Uses",
      value: "useTypes",
    },
    {
      order: 2,
      name: "SF",
      value: "standardizedArea",
    },
    {
      order: 3,
      name: "Units",
      value: "useBasedArea",
    },
    {
      order: 4,
      name: "Multi-floor",
      value: "hasMultipleLandCoveringLevels",
    },
    {
      order: 5,
      name: "ID",
      value: "id",
    },
    {
      order: 6,
      name: "Outer container type",
      value: "joiningContentType",
    },
    {
      order: 7,
      name: "Outer container ID",
      value: "joiningContentId",
    },
  ],
  FloorAreaLayout: [
    {
      order: 1,
      name: "Name",
      value: "name",
    },
    {
      order: 2,
      name: "ID",
      value: "id",
    },
  ],
  LayoutPartition: [
    {
      order: 1,
      name: "Type",
      value: "type",
    },
    {
      order: 2,
      name: "ID",
      value: "id",
    },
  ],
  ContentPropertyUse: [
    {
      order: 1,
      name: "Use",
      value: "name",
    },
  ],
  FloorAreaDownwardVerticalOpening: [
    {
      order: 1,
      name: "ID",
      value: "id",
    },
  ],
  PropertyDiagramVisualOrdering: [
    {
      order: 1,
      name: "Direction",
      value: "direction",
    },
    {
      order: 2,
      name: "Order",
      value: "order",
    },
  ],
  CrowdsourcedFile: [
    {
      order: 1,
      name: "Host",
      value: "hostName",
    },
    {
      order: 2,
      name: "Name",
      value: "name",
    },
    {
      order: 3,
      name: "Folder?",
      value: "isDir",
    },
  ],
  Valuation: [
    {
      order: 1,
      name: "Amount",
      value: "value",
    },
    {
      order: 2,
      name: "Type",
      value: "valueType",
    },
  ],
  Investment: [
    {
      order: 1,
      name: "Type",
      value: "dealActionAlias",
    },
    {
      order: 2,
      name: "Asset",
      value: "asset.fieldContent.name",
    },
    {
      order: 3,
      name: "State",
      value: "state",
    },
    {
      order: 4,
      name: "Date",
      value: "date",
    },
  ],
  Loan: [
    {
      order: 1,
      name: "Name",
      value: "name",
    },
  ],
  InvolvementRole: [
    {
      order: 1,
      name: "Role",
      value: "name",
    },
  ],
  CompanyInvolvement: [
    {
      order: 1,
      name: "Company",
      value: "name",
    },
  ],
  ContactCompanyInvolvement: [
    {
      order: 1,
      name: "Contact",
      value: "name",
    },
  ],
  OwnershipInterest: [
    {
      order: 1,
      name: "Company",
      value: "company",
    },
    {
      order: 2,
      name: "Opened date",
      value: "openedAt",
    },
    {
      order: 3,
      name: "Retired date",
      value: "retiredAt",
    },
  ],
  ContentAlias: [
    {
      order: 1,
      name: "Alias",
      value: "name",
    },
  ],
  CashflowSchedule: [
    {
      order: 1,
      name: "Building Blocks",
      value: "buildingBlocks",
    },
  ],
};
