import TaskShell from "@/components/tasks/TaskShell.vue";
import StackOfCards from "@/components/collectible-cards/StackOfCards.vue";
import MySafezone from "@/components/crowdsourcing/safezone/MySafezone.vue";
import MyContributions from "@/components/crowdsourcing/contributions/MyContributions.vue";
import MyTeams from "@/components/users/MyTeams.vue";
import { markRaw } from "vue";
import _ from "lodash";

export function initialSelectedTab() {
  return _.get(tabs, "news");
}

export const tabs = {
  news: {
    name: "News",
    tabQuery: "News",
    href: "",
    svgPaths: [
      "M12 7.5h1.5m-1.5 3h1.5m-7.5 3h7.5m-7.5 3h7.5m3-9h3.375c.621 0 1.125.504 1.125 1.125V18a2.25 2.25 0 01-2.25 2.25M16.5 7.5V18a2.25 2.25 0 002.25 2.25M16.5 7.5V4.875c0-.621-.504-1.125-1.125-1.125H4.125C3.504 3.75 3 4.254 3 4.875V18a2.25 2.25 0 002.25 2.25h13.5M6 7.5h3v3H6v-3z",
    ],
    component: markRaw(StackOfCards),
  },
  tasks: {
    name: "Tasks",
    tabQuery: "Tasks",
    href: "",
    svgPaths: [
      "M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z",
    ],
    component: markRaw(TaskShell),
  },
  contributions: {
    name: "Contributions",
    tabQuery: "Contributions",
    href: "",
    svgPaths: [
      "M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z",
    ],
    component: markRaw(MyContributions),
  },
  safezone: {
    name: "Safezone",
    tabQuery: "Safezone",
    href: "",
    svgPaths: [
      "M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z",
    ],
    component: markRaw(MySafezone),
  },
  teams: {
    name: "My Teams",
    tabQuery: "Teams",
    href: "",
    svgPaths: [
      "M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z",
    ],
    component: markRaw(MyTeams),
  },
};
