<template>
  <div class="bg-white overflow-hidden">
    <div class="p-2">
      <dl class="grid grid-cols-1 gap-x-4 gap-y-8">
        <div class="col-span-1">
          <div class="flex items-start justify-between">
            <dt class="text-sm font-medium text-gray-700">
              Space Group Cashflows
            </dt>
            <div
              v-if="
                availabilityGroupDataField &&
                homogeneousDateState &&
                timingUnlocked
              "
              class="flex flex-col items-end space-y-2"
            >
              <DataVisibilityButton
                v-if="!editing && cashflowEligible"
                visibility="safezone"
              >
                <template v-slot:button>
                  <button
                    @click="edit"
                    :disabled="originatingData"
                    type="button"
                    class="group py-1 px-1.5 flex items-center justify-between rounded-full border border-gray-300 shadow-sm space-x-2 text-left hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    <PulseLoader
                      v-if="originatingData"
                      :loading="true"
                      size="3px"
                      color="#f3f4f6"
                    />
                    <span
                      v-else
                      class="min-w-0 flex-1 flex items-center space-x-1"
                    >
                      <span class="flex-shrink-0 flex items-center">
                        <span
                          class="inline-flex items-center justify-center h-5 w-5 rounded-full bg-yellow-500"
                        >
                          <PencilIcon class="h-3 w-3 text-white" />
                        </span>
                      </span>
                      <span class="min-w-0 flex-1">
                        <span class="text-sm font-medium text-gray-900 truncate"
                          >Add cashflow schedule</span
                        >
                      </span>
                    </span>
                  </button>
                </template>
              </DataVisibilityButton>
              <dt
                v-if="commencedDate || expiredDate"
                class="flex items-center space-x-1 text-sm text-gray-500"
              >
                <span v-if="commencedDate">LCD: {{ commencedDate }}</span>
                <span v-if="commencedDate && expiredDate">&middot;</span>
                <span v-if="expiredDate">LXD: {{ expiredDate }}</span>
              </dt>
              <div class="flex items-center space-x-1">
                <SizesUses
                  v-if="cashflowEligible && availabilityIds.length > 0"
                  standalone-content-type="SpaceAvailability"
                  :standalone-content-ids="availabilityIds"
                  :standalone-content-data-field="availabilityGroupDataField"
                  context="availability-group-cashflow"
                  @numeric-area="setPerAreaSize"
                />
              </div>
            </div>
          </div>
          <dd
            v-if="
              availabilityGroupDataField &&
              homogeneousDateState &&
              timingUnlocked &&
              cashflowEligible
            "
            class="mt-2 space-y-2"
          >
            <CashflowCreate
              v-if="editing"
              :data-field="availabilityGroupDataField"
              :date-fields="dateFields"
              :per-area-size="perAreaSize"
              @refetch="refetch"
              @cancel="cancel"
            />

            <SquareLoader
              v-if="!schedulesLoaded"
              :loading="true"
              size="24px"
              color="#4338ca"
            />

            <CashflowScheduleField
              v-for="cashflowField in schedules"
              :key="cashflowField.localId"
              :schedule-data-field="cashflowField"
              :availability-group-id="existingGroupId"
              :date-fields="dateFields"
              :per-area-size="perAreaSize"
              :removable="false"
            />
          </dd>
          <dd
            v-else-if="availabilityGroupDataField && !groupCashflowUnlockable"
            class="mt-4 text-center"
          >
            <svg
              class="mx-auto h-8 w-8 text-gray-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
              />
            </svg>
            <h3 class="mt-2 text-sm font-medium text-gray-900">
              Multiple cashflow schedules
            </h3>
            <p class="mt-1 text-sm text-gray-500">
              The available spaces don't all share the same cashflow structure.
              Please edit via the space list.
            </p>
          </dd>
          <dd
            v-else-if="
              availabilityGroupDataField &&
              !homogeneousDateState &&
              timingUnlocked
            "
            class="mt-4 text-center"
          >
            <svg
              class="mx-auto h-8 w-8 text-gray-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
              />
            </svg>
            <h3 class="mt-2 text-sm font-medium text-gray-900">
              Multiple dates/states
            </h3>
            <p class="mt-1 text-sm text-gray-500">
              The available spaces don't all share the same state. Please edit
              via the space list.
            </p>
          </dd>
          <div
            v-else-if="
              availabilityGroupDataField &&
              homogeneousDateState &&
              !timingUnlocked
            "
            class="mt-4 text-center"
          >
            <svg
              class="mx-auto h-8 w-8 text-gray-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
              />
            </svg>
            <h3 class="mt-2 text-sm font-medium text-gray-900">
              Unlockable dates/states
            </h3>
            <p class="mt-1 text-sm text-gray-500">
              All timing datapoints need to be unlocked. Please check the space
              list.
            </p>
          </div>
        </div>
      </dl>
    </div>
  </div>
</template>

<script setup>
import { useCashflowDefinitionStore } from "@/stores/cashflowDefinition";
import { useCrowdsourcedChangeGroupStore } from "@/stores/crowdsourcedChangeGroup";
import { useSpaceUsageBuilderStore } from "@/stores/spaceUsageBuilder";
import { ref, computed, watch, onMounted } from "vue";
import { storeToRefs } from "pinia";
import { PencilIcon } from "@heroicons/vue/24/outline";
import SquareLoader from "vue-spinner/src/SquareLoader.vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import SizesUses from "@/components/analyze/calculations/SizesUses.vue";
import DataVisibilityButton from "@/components/crowdsourcing/DataVisibilityButton.vue";
import CashflowCreate from "@/components/crowdsourcing/CashflowCreate.vue";
import CashflowScheduleField from "@/components/crowdsourcing/CashflowScheduleField.vue";
import moment from "moment";
import fullyUnlocked from "@/components/crowdsourcing/fullyUnlocked";
import api from "@/router/api";
import _ from "lodash";

const props = defineProps(["existingGroupId"]);

const cashflowDefinitionStore = useCashflowDefinitionStore();
const changeGroupStore = useCrowdsourcedChangeGroupStore();
const { originatingData } = storeToRefs(changeGroupStore);
const spaceUsageBuilderStore = useSpaceUsageBuilderStore();
const { spaceUsageBuilder } = storeToRefs(spaceUsageBuilderStore);

const editing = ref(false);
const availabilityGroupDataField = ref(null);
const groupTimingUnlockable = ref(false);
const groupCashflowUnlockable = ref(false);
const schedules = ref([]);
const schedulesLoaded = ref(false);
const dateFields = ref({
  commenced_date: null,
  expired_date: null,
});

const availabilityGroup = computed(() => {
  const finder = props.existingGroupId ? { id: props.existingGroupId } : null;

  if (finder) {
    return _.find(
      _.get(spaceUsageBuilder.value, "availabilityGroups", []),
      finder,
    );
  } else {
    return null;
  }
});
const availabilityIds = computed(() => {
  if (availabilityGroupDataField.value) {
    return availabilityGroupDataField.value.fieldContent?.availabilityIds;
  } else {
    return [];
  }
});
const timingFields = computed(() => {
  return _.get(availabilityGroup.value, "cashflowTimingFields", []);
});
const timingUnlocked = computed(() => {
  return fullyUnlocked(timingFields.value);
});
const cashflowEligible = computed(() => {
  return (
    (schedulesLoaded.value && schedules.value.length === 0) ||
    groupCashflowUnlockable.value
  );
});

const groupDateState = computed(() => {
  const uniqCommencedDates = _.uniq(
    dateFieldsFor("commenced").map((field) => {
      if (field.fieldDate) {
        return moment(field.fieldDate).format("YYYYMMDD");
      } else {
        return null;
      }
    }),
  );
  const commencedDates = _.compact(uniqCommencedDates);
  const uniqExpiredDates = _.uniq(
    dateFieldsFor("expired").map((field) => {
      if (field.fieldDate) {
        return moment(field.fieldDate).format("YYYYMMDD");
      } else {
        return null;
      }
    }),
  );
  const expiredDates = _.compact(uniqExpiredDates);
  const states = _.compact(
    _.uniq(
      timingFields.value
        .filter(({ fieldName }) => fieldName === "contract_state")
        .map(({ fieldValue }) => fieldValue),
    ),
  );

  return { commencedDates, expiredDates, states };
});

const homogeneousDateState = computed(() => {
  return (
    groupDateState.value.commencedDates.length <= 1 &&
    groupDateState.value.expiredDates.length <= 1 &&
    groupDateState.value.states.length <= 1
  );
});

const perAreaSize = ref(null);
function setPerAreaSize(numericArea) {
  perAreaSize.value = numericArea;
}

const commencedDate = computed(() => {
  if (groupDateState.value.commencedDates.length === 1) {
    let unparsed = _.head(groupDateState.value.commencedDates);

    return moment(unparsed).format("MMM D, YYYY");
  } else {
    return null;
  }
});
const expiredDate = computed(() => {
  if (groupDateState.value.expiredDates.length === 1) {
    let unparsed = _.head(groupDateState.value.expiredDates);

    return moment(unparsed).format("MMM D, YYYY");
  } else {
    return null;
  }
});

onMounted(async () => {
  await fetchAvailabilityGroupDataField();
  await refetchTimingFieldData();
  checkCashflowUnlockability();
});

function dateFieldsFor(state) {
  return timingFields.value.filter(
    ({ fieldName }) => fieldName === `${state}_date`,
  );
}

function setDateFields() {
  if (
    groupTimingUnlockable.value &&
    timingFields.value.length > 0 &&
    homogeneousDateState.value
  ) {
    const uniqCommencedDateFields = _.compact(
      _.uniqBy(dateFieldsFor("commenced"), "fieldDate"),
    );
    const commencedField =
      uniqCommencedDateFields.length === 1
        ? _.head(uniqCommencedDateFields)
        : null;
    const uniqExpiredDateFields = _.compact(
      _.uniqBy(dateFieldsFor("expired"), "fieldDate"),
    );
    const expiredField =
      uniqExpiredDateFields.length === 1 ? _.head(uniqExpiredDateFields) : null;

    dateFields.value.commenced_date = commencedField;
    dateFields.value.expired_date = expiredField;
  }
}

async function fetchAvailabilityGroupDataField() {
  if (props.existingGroupId) {
    const json = await api.get(
      `space_availability_groups/${props.existingGroupId}?field_output_format=outer_field`,
    );

    if (json?.data) {
      availabilityGroupDataField.value = json.data;
    }
  }
}

async function checkCashflowUnlockability() {
  const maybeJson =
    await spaceUsageBuilderStore.checkGroupCashflowUnlockability(
      props.existingGroupId,
    );

  if (_.isBoolean(maybeJson?.data)) {
    groupCashflowUnlockable.value = maybeJson.data;
    fetchSchedules();
  }
}

function fetchSchedules() {
  if (
    availabilityGroupDataField.value &&
    homogeneousDateState.value &&
    groupTimingUnlockable.value
  ) {
    api
      .get(
        `crowdsourced_data_fields/SpaceAvailabilityGroup/${props.existingGroupId}?field_name=CashflowSchedule&field_sub_type=any`,
      )
      .then((json) => {
        schedules.value = json.data;
        schedulesLoaded.value = true;
      });
  } else {
    schedulesLoaded.value = true;
  }
}

function edit() {
  editing.value = true;
}

function cancel() {
  editing.value = false;
  cashflowDefinitionStore.resetSchedule();
}

async function refetch() {
  editing.value = false;
  await fetchAvailabilityGroupDataField();
  await refetchTimingFieldData();
  checkCashflowUnlockability();
  // emit("refetch");
}

async function refetchTimingFieldData() {
  if (props.existingGroupId) {
    await spaceUsageBuilderStore.fetchGroupTimingFields(
      props.existingGroupId,
      "cashflow",
    );
    await checkTimingUnlockability();
  }
}

async function checkTimingUnlockability() {
  const maybeJson = await spaceUsageBuilderStore.checkGroupTimingUnlockability(
    props.existingGroupId,
    "cashflow",
  );

  if (maybeJson?.data) {
    groupTimingUnlockable.value = maybeJson.data;
    setDateFields();
  }
}
</script>
