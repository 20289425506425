<template>
  <section aria-labelledby="uses">
    <template v-if="inline">
      <div v-if="usesLoaded" class="space-y-2">
        <dt class="mb-2 text-sm font-medium text-gray-500">Property Uses</dt>
        <DataField
          v-for="useDataField in uses"
          :key="useDataField.localId"
          :data-field="useDataField"
          primary-text-path="fieldContent.name"
          text-classes="text-sm"
          @completed="completed"
          @open-sourced="fetchUses"
          @unlocked="fetchUses"
          @dismiss="dismiss"
          @set-proof="setProof"
        />

        <ContentPropertyUseCreate
          v-if="creatingUse"
          v-bind="
            _.find(availableProps, {
              fieldName: 'ContentPropertyUse',
            })
          "
          @completed="useCreated"
          @cancel="creatingUse = false"
        />

        <div v-else class="flex items-start">
          <DataVisibilityButton visibility="safezone">
            <template v-slot:button>
              <button
                @click="creatingUse = true"
                type="button"
                data-test="create-property-use-button"
                class="group py-1 px-1.5 flex items-center justify-between rounded-full border border-gray-300 shadow-sm space-x-2 text-left hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <span class="min-w-0 flex-1 flex items-center space-x-1">
                  <span class="flex-shrink-0 flex items-center">
                    <span
                      class="inline-flex items-center justify-center h-5 w-5 rounded-full bg-yellow-500"
                    >
                      <PencilIcon class="h-3 w-3 text-white" />
                    </span>
                  </span>
                  <span class="min-w-0 flex-1">
                    <span class="text-sm font-medium text-gray-900 truncate"
                      >Add a use</span
                    >
                  </span>
                </span>
              </button>
            </template>
          </DataVisibilityButton>
        </div>
      </div>
    </template>
    <template v-else>
      <ContentHeader>
        <template v-slot:title>Uses</template>
        <template v-slot:description
          >How is the space used, now or in the future?</template
        >
      </ContentHeader>

      <ContentBody v-if="usesLoaded">
        <DataField
          v-for="useDataField in uses"
          :key="useDataField.localId"
          :data-field="useDataField"
          primary-text-path="fieldContent.name"
          text-classes="text-sm"
          @completed="completed"
          @open-sourced="fetchUses"
          @unlocked="fetchUses"
          @dismiss="dismiss"
          @set-proof="setProof"
        />

        <ContentPropertyUseCreate
          v-if="creatingUse"
          v-bind="
            _.find(availableProps, {
              fieldName: 'ContentPropertyUse',
            })
          "
          @completed="useCreated"
          @cancel="creatingUse = false"
        />

        <div v-else class="flex items-start">
          <DataVisibilityButton visibility="safezone">
            <template v-slot:button>
              <button
                @click="creatingUse = true"
                type="button"
                data-test="create-property-use-button"
                class="group py-1 px-1.5 flex items-center justify-between rounded-full border border-gray-300 shadow-sm space-x-2 text-left hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <span class="min-w-0 flex-1 flex items-center space-x-1">
                  <span class="flex-shrink-0 flex items-center">
                    <span
                      class="inline-flex items-center justify-center h-5 w-5 rounded-full bg-yellow-500"
                    >
                      <PencilIcon class="h-3 w-3 text-white" />
                    </span>
                  </span>
                  <span class="min-w-0 flex-1">
                    <span class="text-sm font-medium text-gray-900 truncate"
                      >Add a use</span
                    >
                  </span>
                </span>
              </button>
            </template>
          </DataVisibilityButton>
        </div>
      </ContentBody>
    </template>
  </section>
</template>

<script setup>
import { PencilIcon } from "@heroicons/vue/24/outline";
import ContentPropertyUseCreate from "@/components/crowdsourcing/ContentPropertyUseCreate.vue";
import DataField from "@/components/crowdsourcing/DataField.vue";
import ContentBody from "@/components/main-layout/secondary-panel/ContentBody.vue";
import ContentHeader from "@/components/main-layout/secondary-panel/ContentHeader.vue";
import { useCrowdsourcedChangeGroupStore } from "@/stores/crowdsourcedChangeGroup";
import { useProveForValueStore } from "@/stores/proveForValue";
import api from "@/router/api";
import { ref, computed, watch, onMounted } from "vue";
import _ from "lodash";
import { usePropertyDiagramStore } from "@/stores/propertyDiagram";
import DataVisibilityButton from "@/components/crowdsourcing/DataVisibilityButton.vue";

const props = defineProps(["dataField", "optional", "inline"]);
const emit = defineEmits(["positive-uses"]);

const uses = ref([]);
const usesLoaded = ref(false);
const creatingUse = ref(false);
const dataFieldLocalId = computed(() => _.get(props.dataField, "localId"));
const changeGroupStore = useCrowdsourcedChangeGroupStore();
const propertyDiagramStore = usePropertyDiagramStore();
const proveForValueStore = useProveForValueStore();

const availableProps = computed(() => {
  return [
    {
      fieldName: "ContentPropertyUse",
      content: props.dataField?.fieldContent,
      contentType: props.dataField?.fieldContentType,
    },
  ];
});

watch(dataFieldLocalId, () => {
  if (props.dataField) fetchUses();
});

onMounted(() => fetchUses());

function setProof(fieldName) {
  const matchedProps = _.find(availableProps.value, { fieldName });

  if (matchedProps) {
    proveForValueStore.setProofComponent(
      ContentPropertyUseCreate,
      matchedProps,
    );
  }
}

function completed(payload) {
  if (payload?.dataFields) {
    patchUse(_.head(payload?.dataFields));
  } else {
    fetchUses();
  }
  if (props.dataField?.fieldContentType === "FloorArea") {
    propertyDiagramStore.refetchDiagramDataField(props.dataField.localId);
  }
}
function useCreated(payload) {
  const dataField = payload?.dataField;
  creatingUse.value = false;
  if (dataField) {
    patchUse(dataField);
  } else {
    fetchUses();
  }
}
function patchUse(dataField) {
  const newUses = _.unionBy([dataField], uses.value, "localId");

  uses.value = newUses;
  notifyUpstream();
}
function fetchUses() {
  api
    .get(
      `crowdsourced_data_fields/${props.dataField.fieldContentType}/${props.dataField.fieldContentId}?field_name=ContentPropertyUse`,
    )
    .then((json) => {
      uses.value = json.data;
      usesLoaded.value = true;
      notifyUpstream();
    });
}
async function dismiss(
  id,
  successCallback = (json) => {
    if (props.dataField?.fieldContentType === "FloorArea") {
      propertyDiagramStore.postEditingPatch(json);
    }
  },
) {
  if (!id) return;

  await changeGroupStore.dismissData({
    dataFieldId: id,
    successCallback: successCallback,
  });
}

function notifyUpstream() {
  if (uses.value.length > 0) {
    emit("positive-uses");
  }
}
</script>
