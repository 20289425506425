<template>
  <li class="relative">
    <div
      v-if="photoLink"
      class="group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden"
    >
      <img
        :src="photoLink"
        alt=""
        class="object-contain pointer-events-none group-hover:opacity-75"
      />
      <button
        @click="viewEmbed"
        type="button"
        class="absolute inset-0 focus:outline-none"
      >
        <span class="sr-only">View details for {{ photo.name }}</span>
      </button>
    </div>
    <div
      v-else
      class="w-full block aspect-w-10 aspect-h-7 text-center text-gray-300 rounded-lg bg-gray-100 border-2 border-gray-300 border-dashed overflow-hidden"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="mx-auto my-auto h-20 w-20"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        stroke-width="2"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
        />
      </svg>
    </div>
    <DataField
      :data-field="photoDataField"
      primary-text-path="fieldContent.name"
      class="mt-2 flex items-center"
      text-classes="text-sm font-medium"
      text-styles=""
      @unlocked="emit('unlocked')"
      @open-sourced="emit('open-sourced')"
    />
  </li>
</template>

<script setup>
import DataField from "@/components/crowdsourcing/DataField.vue";
import { useUploadedFileStore } from "@/stores/uploadedFile";
import { computed } from "vue";
import _ from "lodash";

const props = defineProps(["photoDataField"]);
const emit = defineEmits(["unlocked", "open-sourced"]);
const photoLink = computed(() => _.get(photo.value, "link"));
const photo = computed(() => _.get(props.photoDataField, "fieldContent"));

function viewEmbed() {
  const uploadedFileStore = useUploadedFileStore();

  uploadedFileStore.viewEmbed(photo.value);
}
</script>
