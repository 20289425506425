<template>
  <div>
    <div
      v-if="groupAuthor"
      class="p-2 flex items-center space-x-1 text-gray-700 text-sm"
    >
      <div class="flex items-center space-x-1">
        <div
          v-tooltip="reputationHelp"
          class="flex-shrink-0 flex items-center justify-center w-6"
        >
          <span
            v-if="authorName"
            class="inline-flex items-center justify-center h-6 w-6 bg-gray-400"
          >
            <span class="font-medium uppercase leading-none text-white">
              {{ $filters.initials(authorName) }}
            </span>
          </span>
          <img
            v-else
            :class="reputationColor"
            class="p-1 inline-block h-6 w-6"
            src="https://assets.towerhunt.com/site/Transparent_White_T0.png"
            alt="Tower Hunt logo"
          />
        </div>
        <div
          v-if="authorName"
          v-tooltip="authorName ? 'Only you can see your identity.' : ''"
          class="text-gray-700 text-sm hover:text-gray-600"
        >
          {{ authorName }} (Me)
        </div>
      </div>
      <div v-if="rawSubmittedDate" class="">submitted {{ submittedAt }}</div>
    </div>
    <div class="rounded-md bg-blue-50 m-2 p-2">
      <div class="flex">
        <div class="flex-shrink-0">
          <QuestionMarkCircleIcon
            class="h-5 w-5 text-blue-400"
            aria-hidden="true"
          />
        </div>
        <div class="ml-3 flex-1 md:flex md:justify-between">
          <p class="text-sm text-blue-700">
            Learn about validations in our Help Center.
          </p>
          <p class="mt-3 text-sm md:ml-6 md:mt-0">
            <router-link
              :to="helpCenterPath"
              target="_blank"
              v-tooltip="'Get help'"
              class="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600"
            >
              Details
              <span aria-hidden="true"> &rarr;</span>
            </router-link>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { QuestionMarkCircleIcon } from "@heroicons/vue/20/solid";
import moment from "moment";
import api from "@/router/api";
import { onMounted, ref, computed } from "vue";
import _ from "lodash";

const props = defineProps(["changeGroup"]);

const helpCenterPath = computed(() => {
  return {
    name: "HelpArticle",
    params: { articleId: "understand-validation" },
  };
});

const groupAuthor = ref(null);
const rawSubmittedDate = ref(null);
const submittedAt = computed(() => {
  return moment(rawSubmittedDate.value).fromNow();
});
const reputationHelp = computed(
  () => `Author reputation: ${_.capitalize(reputationLevel.value)}`,
);
const reputationColor = computed(() => {
  switch (reputationLevel.value) {
    case "bronze":
      return "bg-gradient-to-r from-yellow-500 to-orange-700 text-white hover:to-orange-800";
    case "silver":
      return "bg-gradient-to-r from-slate-200 to-gray-400 text-gray-900 hover:to-gray-500";
    case "gold":
      return "bg-gradient-to-r from-yellow-300 to-yellow-500 text-white hover:to-yellow-600";
    default:
      return "text-white bg-gray-800 hover:bg-gray-700";
  }
});
const reputationLevel = computed(() =>
  _.get(groupAuthor.value, "reputationLevel"),
);
const authorName = computed(() => _.get(groupAuthor.value, "name"));

onMounted(() => {
  fetchContext();
});

async function fetchContext() {
  api
    .get(`crowdsourced_change_group_context/${props.changeGroup.id}`)
    .then((json) => {
      groupAuthor.value = json.data.groupAuthor;
      rawSubmittedDate.value = json.data.submittedAt;
    });
}
</script>
