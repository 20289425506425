/* global L */
import { geojsonToLatLng } from "@/components/maps/fog/converter";

export default function mask() {
  const Mask = {
    options: {
      color: "#4f46e5",
      weight: 1,
      fillColor: "#FFFFFF",
      fillOpacity: 1,

      interactive: false,

      fitBounds: true,
      restrictBounds: true,
    },
    initialize: function (options) {
      this._layers = {};
      L.Util.setOptions(this, options);
      this._holes = {};
      this.setShadowLayer();
    },
    set: function (polygonName, polygonGeoJson) {
      const polygonLatLngs = geojsonToLatLng(polygonGeoJson);
      this._holes[polygonName] = polygonLatLngs;
      this.setShadowLayer();
    },
    setShadowLayer: function () {
      if (this._shadowLayer) {
        this.removeLayer(this._shadowLayer);
      }
      this._allWorld = [
        new L.LatLng(-900, -3600),
        new L.LatLng(900, -3600),
        new L.LatLng(900, 3600),
        new L.LatLng(-900, 3600),
      ];

      const polygonLatLngs = [];
      polygonLatLngs.push(this._allWorld);
      for (let latlngs of Object.values(this._holes)) {
        polygonLatLngs.push(latlngs);
      }
      this._shadowLayer = new L.Polygon(polygonLatLngs, this.options);
      this.addLayer(this._shadowLayer);
    },
  };

  L.Mask = L.LayerGroup.extend(Mask);

  L.mask = function (options) {
    return new L.Mask(options);
  };
}
