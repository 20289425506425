<template>
  <div
    :id="`${assetKey}-editor-container`"
    :data-test="`${assetKey}-editor-container`"
    class="bg-white overflow-visible shadow rounded-lg border border-gray-400 divide-y divide-gray-200"
  >
    <div
      :class="[expanded ? 'rounded-t-lg' : 'rounded-lg', transferActionColor]"
      class="flex items-center justify-between overflow-hidden p-2"
    >
      <div class="flex items-center space-x-2">
        <button
          type="button"
          @click="toggleExpanded"
          class="font-semibold uppercase tracking-wide"
        >
          {{ headerLabel }}
        </button>
        <button
          v-if="
            transferOriginAssetId === assetObject.dataField.fieldContentId &&
            contentType === 'PropertyRight'
          "
          @click="emit('cancel-capital-stack-transfer')"
          type="button"
          v-tooltip="'Cancel capital stack transfer'"
          class="h-5 w-5 inline-flex justify-center items-center text-sm"
        >
          <i class="fas fa-ban" />
        </button>
        <button
          v-else-if="transferOriginAssetId && contentType === 'PropertyRight'"
          @click="
            emit('set-capital-stack-transfer-destination', {
              destinationPropertyRightId: assetObject.dataField.fieldContentId,
              destinationLabel: headerLabel,
            })
          "
          type="button"
          v-tooltip="'Move investments here'"
          class="h-5 w-5 inline-flex justify-center items-center text-sm"
        >
          <ArrowRightEndOnRectangleIcon class="h-5 w-5" />
        </button>
        <button
          v-else-if="assetsLength > 1 && contentType === 'PropertyRight'"
          @click="
            emit('initiate-capital-stack-transfer', {
              id: assetObject.dataField.fieldContentId,
              label: headerLabel,
            })
          "
          type="button"
          v-tooltip="'Move all investments to another asset'"
          class="h-5 w-5 inline-flex justify-center items-center text-sm"
        >
          <ArrowRightStartOnRectangleIcon class="h-5 w-5" />
        </button>
      </div>
      <div v-if="!transferOriginAssetId" class="flex items-center space-x-2">
        <ZoomToButton
          v-if="propertyDataField && routeName === 'MainMap'"
          :property-id="propertyDataField.fieldContentId"
        />
        <button
          v-if="diagrammable && !alreadyInDiagram && propertyIdParam"
          @click="addPropertyToDiagram"
          type="button"
          v-tooltip="'Add to diagram'"
          class="h-5 w-5 inline-flex justify-center items-center text-sm"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM14 11a1 1 0 011 1v1h1a1 1 0 110 2h-1v1a1 1 0 11-2 0v-1h-1a1 1 0 110-2h1v-1a1 1 0 011-1z"
            />
          </svg>
        </button>
        <button
          v-else-if="
            diagrammable &&
            alreadyInDiagram &&
            propertyDiagramPropertyIds.length > 1 &&
            propertyIdParam
          "
          @click="switchToDiagramProperty"
          type="button"
          v-tooltip="'Focus in diagram'"
          class="h-5 w-5 inline-flex justify-center items-center text-sm"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M6.672 1.911a1 1 0 10-1.932.518l.259.966a1 1 0 001.932-.518l-.26-.966zM2.429 4.74a1 1 0 10-.517 1.932l.966.259a1 1 0 00.517-1.932l-.966-.26zm8.814-.569a1 1 0 00-1.415-1.414l-.707.707a1 1 0 101.415 1.415l.707-.708zm-7.071 7.072l.707-.707A1 1 0 003.465 9.12l-.708.707a1 1 0 001.415 1.415zm3.2-5.171a1 1 0 00-1.3 1.3l4 10a1 1 0 001.823.075l1.38-2.759 3.018 3.02a1 1 0 001.414-1.415l-3.019-3.02 2.76-1.379a1 1 0 00-.076-1.822l-10-4z"
              clip-rule="evenodd"
            />
          </svg>
        </button>
        <button
          v-else-if="diagrammable && !propertyIdParam"
          @click="viewPropertyDiagram"
          type="button"
          v-tooltip="'View property diagram'"
          class="h-5 w-5 inline-flex justify-center items-center text-sm"
        >
          <BuildingOffice2Icon class="h-5 w-5" />
        </button>
        <button
          @click="toggleExpanded"
          v-tooltip="expanded ? 'Minimize' : 'Expand'"
          type="button"
          :data-test="`${decoratingAndFieldKey(
            assetObject.dataField,
          )}-expand-button`"
          class="h-5 w-5 p-1 inline-flex justify-center items-center text-sm"
        >
          <i v-if="expanded" class="fas fa-compress" />
          <i v-else class="fas fa-expand" />
        </button>
        <button
          @click="removeAsset"
          type="button"
          :data-test="`${decoratingAndFieldKey(
            assetObject.dataField,
          )}-remove-asset-button`"
          class="h-5 w-5 p-1 inline-flex justify-center items-center text-sm"
        >
          <i class="fas fa-times" />
        </button>
      </div>
    </div>

    <div
      v-if="expanded"
      class="bg-gray-100 p-2 text-gray-700 text-xs font-semibold uppercase tracking-wide"
    >
      Timeline
    </div>
    <EventsTimeline
      v-if="expanded"
      :sources="[{ contentId, contentType, dataField }]"
      context="deal-builder-editor-container"
      :left-edge-id="`lists-panel-container`"
      :forward-months="12"
      :lookback-years="10"
      class="pt-2 pl-2"
    />

    <div
      v-if="expanded"
      class="flex items-center justify-between p-2 bg-gray-100"
    >
      <div class="text-gray-700 text-xs font-semibold uppercase tracking-wide">
        Capital Stack
      </div>
      <div class="flex items-center space-x-2">
        <button
          @click="capitalStackExpanded = !capitalStackExpanded"
          v-tooltip="capitalStackExpanded ? 'Minimize' : 'Expand'"
          type="button"
          class="h-5 w-5 p-1 inline-flex justify-center items-center text-sm text-gray-500 hover:text-gray-700"
        >
          <i v-if="capitalStackExpanded" class="fas fa-compress" />
          <i v-else class="fas fa-expand" />
        </button>
      </div>
    </div>
    <div
      v-if="expanded && capitalStackExpanded"
      class="grid gap-2 pb-6"
      style="grid-template-columns: 1fr 80px 1fr"
    >
      <div
        :data-test="`${decoratingAndFieldKey(
          assetObject.dataField,
        )}-before-capital-stack`"
      >
        <h2
          v-if="existingInvestmentSelected"
          class="p-2 text-gray-500 text-xs font-semibold uppercase tracking-wide"
        >
          Before
        </h2>
        <h2
          v-else
          class="p-2 text-gray-500 text-xs font-semibold uppercase tracking-wide"
        >
          as of
          <time :datetime="asOfDate">{{
            moment(asOfDate).format("MMM YYYY")
          }}</time>
        </h2>
        <!-- Current capital stack -->
        <CapitalStackDiagram
          :property-id="propertyId"
          :data-field="dataField"
        />
      </div>
      <div class="flex flex-col">
        <h2
          :class="
            existingInvestmentSelected ? 'text-orange-400' : 'text-gray-500'
          "
          class="flex justify-center items-center space-x-2 p-2 text-xs font-semibold uppercase tracking-wide"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="flex-shrink-0 h-4 w-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4"
            />
          </svg>
          <span
            >Deal<template v-if="!existingInvestmentSelected">?</template></span
          >
        </h2>

        <div
          v-if="existingInvestmentSelected"
          class="flex flex-col flex-grow w-full items-center justify-center text-orange-400 text-xs font-semibold uppercase tracking-wide"
        >
          <span
            ><time :datetime="asOfDate">{{
              moment(asOfDate).format("MMM YYYY")
            }}</time></span
          >
          <div class="mt-2 flex-grow border border-dashed border-orange-400" />
        </div>
      </div>
      <div
        :data-test="`${decoratingAndFieldKey(
          assetObject.dataField,
        )}-after-capital-stack`"
      >
        <h2
          class="p-2 text-gray-500 text-xs font-semibold uppercase tracking-wide"
        >
          After
        </h2>
        <!-- Future capital stack -->
        <CapitalStackDiagram
          v-if="existingInvestmentSelected || creatingFutureDeal"
          :property-id="propertyId"
          :data-field="dataField"
          :future="true"
        />

        <template v-else-if="currentOwnerUnknown">
          <VDropdown v-if="investmentGroups.length > 0">
            <button
              type="button"
              class="relative block w-full border-2 border-indigo-300 border-dashed rounded-lg p-12 text-center hover:border-indigo-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <PlusIcon class="mx-auto h-12 w-12 text-indigo-400" />
              <span class="mt-2 block text-sm font-medium text-indigo-900"
                >Create an active deal</span
              >
            </button>

            <template #popper>
              <div
                class="w-64 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="menu-button"
                tabindex="-1"
              >
                <div class="py-1" role="none">
                  <a
                    v-for="(group, index) in investmentGroups"
                    :key="index"
                    @click.prevent="addToPortfolio(group)"
                    href=""
                    class="text-gray-700 hover:bg-gray-100 hover:text-gray-900 group flex items-center px-4 py-2 text-sm"
                    role="menuitem"
                    tabindex="-1"
                    id="menu-item-1"
                  >
                    Add to{{
                      _.includes(group.placeholderId, "temp") ? " Draft " : " "
                    }}Portfolio{{
                      _.includes(group.placeholderId, "temp")
                        ? ""
                        : group.placeholderId
                    }}:
                    {{ pluralize("Asset", investmentsLength(group), true) }}
                    <template v-if="group.dealAction"
                      >&nbsp;({{ group.dealAction }})</template
                    >
                  </a>
                  <a
                    @click.prevent="createFutureDealNewPortfolio"
                    href=""
                    class="text-gray-700 hover:bg-gray-100 hover:text-gray-900 group flex items-center px-4 py-2 text-sm"
                    role="menuitem"
                    tabindex="-1"
                    id="menu-item-0"
                  >
                    New Portfolio
                  </a>
                </div>
              </div>
            </template>
          </VDropdown>

          <DataVisibilityButton
            v-else
            visibility="safezone"
            class="relative block w-full"
          >
            <template v-slot:button>
              <button
                @click="createFutureDealNewPortfolio"
                type="button"
                class="relative block w-full border-2 border-yellow-300 border-dashed rounded-lg p-12 text-center hover:border-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
              >
                <PlusIcon class="mx-auto h-12 w-12 text-yellow-400" />
                <span class="mt-2 block text-sm font-medium text-yellow-900"
                  >Create a deal or portfolio</span
                >
              </button>
            </template>
          </DataVisibilityButton>
        </template>

        <div
          v-else
          class="relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center"
        >
          <svg
            class="mx-auto h-12 w-12 text-gray-400"
            xmlns="http://www.w3.org/2000/svg"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 24 24"
            aria-hidden="true"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4"
            />
          </svg>
          <span class="mt-2 block text-sm font-medium text-gray-900"
            >Initiate from the before stack or select from the timeline</span
          >
        </div>
      </div>
    </div>

    <InvestmentsList v-if="expanded" :asset-object="assetObject" />
  </div>
</template>

<script setup>
import CapitalStackDiagram from "@/components/deal-builder/CapitalStackDiagram.vue";
import InvestmentsList from "@/components/deal-builder/InvestmentsList.vue";
import EventsTimeline from "@/components/main-layout/EventsTimeline.vue";
import ZoomToButton from "@/components/maps/ZoomToButton.vue";
import { useTimeTravelStore } from "@/stores/timeTravel";
import { useDealBuilderStore } from "@/stores/dealBuilder";
import { usePropertyDiagramStore } from "@/stores/propertyDiagram";
import { usePropertyFieldsStore } from "@/stores/propertyFields";
import { storeToRefs } from "pinia";
import { computed, watch, onMounted, nextTick, ref } from "vue";
import decoratingAndFieldKey from "@/components/crowdsourcing/decoratingAndFieldKey";
import moment from "moment";
import pluralize from "pluralize";
import propertyAssetLabel from "@/assets/investmentLabel";
import _ from "lodash";
import {
  ArrowRightStartOnRectangleIcon,
  ArrowRightEndOnRectangleIcon,
  PlusIcon,
} from "@heroicons/vue/24/outline";
import DataVisibilityButton from "@/components/crowdsourcing/DataVisibilityButton.vue";
import { BuildingOffice2Icon } from "@heroicons/vue/20/solid";
import { useRoute } from "vue-router";

const props = defineProps(["assetObject", "transferOriginAssetId"]);
const emit = defineEmits([
  "initiate-capital-stack-transfer",
  "set-capital-stack-transfer-destination",
  "cancel-capital-stack-transfer",
]);

const timeTravelStore = useTimeTravelStore();
const { asOfDate } = storeToRefs(timeTravelStore);
const propertyFieldsStore = usePropertyFieldsStore();
const dealBuilderStore = useDealBuilderStore();
const { dealBuilder, crossInteraction, refetchDealBuilderEditor } =
  storeToRefs(dealBuilderStore);
const propertyDiagramStore = usePropertyDiagramStore();
const {
  selectedTimelineEvent,
  propertyIdParam,
  propertyDiagramSelectedPropertyId,
  propertyDiagramPropertyIds,
} = storeToRefs(propertyDiagramStore);
const route = useRoute();
const routeName = computed(() => route.name);

const assets = computed(() => {
  return _.get(dealBuilder.value, "assets", {});
});
const assetsLength = computed(() => {
  return _.size(assets.value);
});
const transferActionColor = computed(() => {
  if (
    props.transferOriginAssetId === dataField.value?.fieldContentId &&
    contentType.value === "PropertyRight"
  ) {
    return "bg-orange-300 text-gray-700 hover:text-gray-800";
  } else if (
    props.transferOriginAssetId &&
    contentType.value === "PropertyRight"
  ) {
    return "bg-teal-400 text-gray-700 hover:text-gray-800";
  } else if (props.transferOriginAssetId && contentType.value === "Loan") {
    return "bg-gray-300 text-gray-400";
  } else {
    return "bg-yellow-300 text-gray-700 hover:text-gray-800";
  }
});

const currentOwnerUnknown = computed(() => {
  return props.assetObject.ownershipInterests.length === 0;
});
const investmentGroups = computed(() => {
  return _.get(dealBuilder.value, "investmentGroups", []);
});
const diagrammable = computed(() => {
  return !!propertyDataField.value && contentType.value !== "Loan";
});
const alreadyInDiagram = computed(() => {
  if (diagrammable.value) {
    return _.includes(
      propertyDiagramPropertyIds.value,
      dataField.value.joiningContentId,
    );
  } else {
    return false;
  }
});
const existingInvestmentSelected = computed(() => {
  return (
    _.get(crossInteraction.value, "source") === "TimelineInvestment" &&
    _.get(crossInteraction.value, "assetKey") === assetKey.value
  );
});
const existingInvestment = computed(() => {
  if (dealBuilder.value) {
    const asset = dealBuilder.value.assets[assetKey.value];
    const assetInvestments = _.get(asset, `investments`, []);
    const investment = _.find(assetInvestments, {
      id: _.get(crossInteraction.value, "combinedKey"),
    });

    return investment;
  } else {
    return null;
  }
});
const capitalStackExpanded = ref(true);
const expanded = computed({
  get() {
    return props.assetObject.expanded;
  },
  set(bool) {
    dealBuilderStore.setDealBuilderAssetExpanded({
      assetKey: assetKey.value,
      expanded: bool,
    });
  },
});
const creatingFutureDeal = computed(() => {
  const assetKey = decoratingAndFieldKey(props.assetObject.dataField);

  return _.find(dealBuilder.value.investmentGroups, function (group) {
    return _.some(group.investments, function (investment) {
      return (
        !investment.existingInvestment &&
        investment.capitalStackTopLevelAsset &&
        assetKey === decoratingAndFieldKey(investment.capitalStackTopLevelAsset)
      );
    });
  });
});
const assetKey = computed(() => {
  return decoratingAndFieldKey(dataField.value);
});
const dataField = computed(() => {
  return props.assetObject.dataField;
});
const headerLabel = computed(() => {
  return propertyAssetLabel(
    dataField.value,
    propertyDataField.value,
    loanSeniority.value,
  );
});
const propertyId = computed(() => {
  if (_.get(dataField.value, "joiningContentType") === "Property") {
    return _.get(dataField.value, "joiningContentId");
  } else if (_.get(dataField.value, "decoratingContentType") === "Property") {
    return _.get(dataField.value, "decoratingContentId");
  } else if (_.get(dataField.value, "fieldContentType") === "PropertyRight") {
    return dataField.value.fieldContent?.propertyId;
  } else {
    return props.assetObject?.dataField?.fieldContent?.propertyId;
  }
});
const loanSeniority = computed(() => {
  if (contentType.value === "Loan") {
    const loanInvestment = _.find(
      props.assetObject.investments,
      function (investmentField) {
        return investmentField.fieldContent?.loanSeniority;
      },
    );

    return loanInvestment?.fieldContent?.loanSeniority;
  } else {
    return null;
  }
});
const propertyDataField = computed(() => {
  if (propertyId.value) {
    return propertyFieldsStore.getPropertyField(propertyId.value);
  } else {
    return null;
  }
});
const subjectIsFieldContent = computed(() => {
  return _.includes(
    ["Property", "PropertyRight"],
    dataField.value.decoratingContentType,
  );
});
const contentId = computed(() => {
  if (subjectIsFieldContent.value) {
    return dataField.value.fieldContentId;
  } else {
    return dataField.value.decoratingContentId;
  }
});
const contentType = computed(() => {
  if (subjectIsFieldContent.value) {
    return dataField.value.fieldContentType;
  } else {
    return dataField.value.decoratingContentType;
  }
});

watch(refetchDealBuilderEditor, async () => {
  if (refetchDealBuilderEditor.value === assetKey.value) {
    expanded.value = false;

    await nextTick();
    expanded.value = true;
    refetchDealBuilderEditor.value = null;
  }
});
watch(selectedTimelineEvent, () => {
  focusSelectedEvent();
});
watch(existingInvestmentSelected, () => {
  if (!existingInvestmentSelected.value) {
    dealBuilderStore.clearDealBuilderAssetManipulatedLoans({
      assetKey: assetKey.value,
    });
    dealBuilderStore.clearDealBuilderAssetIndividualOwnershipInterests({
      assetKey: assetKey.value,
    });
  }
});
watch(existingInvestment, () => {
  if (_.isObject(existingInvestment.value)) {
    const payload = {
      assetKey: assetKey.value,
      investment: existingInvestment.value,
    };

    dealBuilderStore.mountInvestmentMetadata(payload);
  }
});

onMounted(() => {
  if (propertyId.value && !propertyDataField.value) {
    propertyFieldsStore.fetchPropertyDataField(propertyId.value);
  }
});

function toggleExpanded() {
  if (!props.transferOriginAssetId) {
    expanded.value = !expanded.value;
  }
}

function focusSelectedEvent() {
  if (_.get(selectedTimelineEvent.value, "eventType") === "Investment") {
    const investmentId =
      selectedTimelineEvent.value.event?.id ||
      selectedTimelineEvent.value.dataField?.fieldContentId;
    const matchingInvestment = _.find(
      props.assetObject.investments,
      function (investment) {
        return investment.fieldContentId === investmentId;
      },
    );
    console.log(
      "timeline event",
      selectedTimelineEvent.value,
      investmentId,
      matchingInvestment,
    );

    if (matchingInvestment) {
      const newCrossInteraction = {
        dealAction: null,
        assetKey: assetKey.value,
        combinedKey: investmentId,
        focus: "Valuations",
        source: "TimelineInvestment",
      };
      const assetObject = {
        dataField: dataField.value,
        investments: [],
        expanded: true,
        ownershipInterests: [],
      };

      dealBuilderStore.addDealBuilderAsset({
        assetObject,
        assetKey: assetKey.value,
      });
      crossInteraction.value = newCrossInteraction;
      selectedTimelineEvent.value = null;
    }
  }
}

function createFutureDealNewPortfolio() {
  crossInteraction.value = null;

  dealBuilderStore.addDealBuilderInvestmentGroup({
    assetDataField: props.assetObject.dataField,
    investmentFieldContent: props.assetObject.dataField,
    ownershipInterestIds: [],
    dealAction: "sellOutright",
  });
}
function addToPortfolio(investmentGroup) {
  dealBuilderStore.addDealBuilderInvestmentToGroup({
    existingInvestment: null,
    assetDataField: props.assetObject.dataField,
    investmentFieldContent: props.assetObject.dataField,
    ownershipInterestIds: [],
    investmentGroupPlaceholderId: investmentGroup.placeholderId,
  });
  dealBuilderStore.collapsePortfolioAssets({
    groupId: investmentGroup.id || investmentGroup.placeholderId,
  });
  dealBuilderStore.setDealBuilderInvestmentGroupExpanded({
    groupId: investmentGroup.id || investmentGroup.placeholderId,
    expanded: true,
  });
}
function investmentsLength(investmentGroup) {
  return _.size(investmentGroup.investments);
}

function viewPropertyDiagram() {
  if (diagrammable.value && !propertyIdParam.value) {
    propertyDiagramStore.navigateToDiagram(
      propertyDataField.value.fieldContentId,
      true,
    );
  }
}
function addPropertyToDiagram() {
  if (diagrammable.value && propertyIdParam.value) {
    propertyDiagramStore.addPropertyToDiagram(dataField.value.joiningContentId);
  }
}
function switchToDiagramProperty() {
  if (diagrammable.value && propertyIdParam.value) {
    propertyDiagramSelectedPropertyId.value = dataField.value.joiningContentId;
  }
}
function removeAsset() {
  dealBuilderStore.removeDealBuilderAsset(
    decoratingAndFieldKey(props.assetObject.dataField),
  );
}
</script>
