<template>
  <div class="h-full flex flex-col">
    <nav
      class="p-2 bg-gray-50 flex items-center justify-between"
      aria-label="Breadcrumb"
    >
      <div class="flex items-center space-x-2">
        <a @click.prevent="backToLists" href="" class="flex items-center">
          <ChevronLeftIcon
            class="h-5 w-5 flex-shrink-0 text-indigo-400"
            aria-hidden="true"
          />
          <span
            class="text-sm font-medium text-indigo-500 hover:text-indigo-700"
            >Lists</span
          >
        </a>
      </div>
      <h3
        v-if="!mainTitleVisible"
        class="flex min-w-0 flex-1 items-center justify-center font-medium text-gray-900"
      >
        <template v-if="batchSelection && batchTasks.length > 0">
          {{ batchTasks.length }} Selected
        </template>
        <template v-else-if="batchSelection"> Select Reminders </template>
        <template v-else> Nearby </template>
      </h3>
      <div class="flex items-center space-x-3">
        <VDropdown
          v-if="
            !batchSelection &&
            nearbyTasksLoaded &&
            displayableTaskLists.length > 0
          "
          class="flex items-center"
        >
          <button
            type="button"
            class="inline-flex justify-center p-0.5 items-center rounded-full border border-indigo-400 text-sm text-indigo-500 hover:text-indigo-700"
            data-test="task-list-options-button`"
          >
            <EllipsisHorizontalIcon class="h-4 w-4" />
          </button>

          <template #popper>
            <div
              class="w-64 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="menu-button"
              tabindex="-1"
            >
              <div class="py-1" role="none">
                <a
                  v-if="zoom >= 13"
                  @click.prevent="batchSelection = true"
                  href=""
                  class="text-gray-700 hover:bg-gray-100 hover:text-gray-900 group flex items-center px-4 py-2 text-sm"
                  role="menuitem"
                  tabindex="-1"
                >
                  Select Reminders
                </a>
                <a
                  @click.prevent="toggleShowCompleted"
                  href=""
                  class="text-gray-700 hover:bg-gray-100 hover:text-gray-900 group flex items-center px-4 py-2 text-sm"
                  role="menuitem"
                  tabindex="-1"
                >
                  {{ nearbyViewShowCompleted ? "Hide" : "Show" }}
                  Completed
                </a>
              </div>
            </div>
          </template>
        </VDropdown>
        <a
          v-if="addingInlineTask || selectedTask"
          @click.prevent="add"
          href=""
          id="inline-reminder-form-done-button"
          class="flex items-center text-sm font-bold text-indigo-500"
        >
          Done
        </a>
        <a
          v-else-if="batchSelection"
          @click.prevent="resetBatch"
          href=""
          class="flex items-center text-sm font-bold text-indigo-500"
        >
          Done
        </a>
      </div>
    </nav>

    <div class="p-2 flex flex-col flex-grow overflow-y-auto">
      <div class="lg:flex lg:items-center lg:justify-between">
        <div class="min-w-0 flex-1">
          <h2
            v-observe-visibility="pageTitleVisibilityChanged"
            class="mt-2 text-xl font-bold leading-6 sm:truncate sm:text-2xl sm:tracking-tight text-gray-700"
          >
            <template v-if="batchSelection && batchTasks.length > 0">
              {{ batchTasks.length }} Selected
            </template>
            <template v-else-if="batchSelection"> Select Reminders </template>
            <template v-else> Nearby </template>
          </h2>
          <div class="mt-2 flex items-center space-x-2">
            <div class="flex items-center text-sm text-gray-500">
              {{ completedTasks.length }} completed
            </div>
            <div class="text-gray-500 text-sm">&middot;</div>
            <VDropdown :disabled="batchSelection || nothingCompleted" class="">
              <a
                @click.prevent
                href=""
                :class="
                  batchSelection || nothingCompleted
                    ? 'text-gray-200'
                    : 'text-indigo-500'
                "
                class="flex items-center text-sm"
              >
                Clear
              </a>
              <template #popper>
                <div
                  class="w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="menu-button"
                  tabindex="-1"
                >
                  <div class="py-1" role="none">
                    <div
                      class="font-medium text-gray-500 group flex items-center p-2 text-xs"
                      role="menuitem"
                      tabindex="-1"
                      id="menu-item-0"
                    >
                      Completed Reminders
                    </div>
                  </div>
                  <div class="py-1" role="none">
                    <a
                      @click.prevent="clearCompleted()"
                      href=""
                      class="text-gray-700 hover:bg-gray-100 hover:text-gray-900 group flex items-center space-x-2 p-2 text-sm"
                      role="menuitem"
                      tabindex="-1"
                      id="menu-item-0"
                      data-test="`clear-all-completed-tasks-button`"
                    >
                      <span>All Completed</span>
                    </a>
                  </div>
                </div>
              </template>
            </VDropdown>
          </div>
        </div>
      </div>

      <TaskBatchActions v-if="batchSelection" />
      <section v-if="zoom < 14">
        <div
          class="flex-grow relative block w-full rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <MagnifyingGlassPlusIcon class="mx-auto h-12 w-12 text-gray-400" />
          <span class="mt-2 block text-sm font-medium text-gray-900">
            Zoom in more
          </span>
        </div>
      </section>
      <div
        v-else-if="!nearbyTasksLoaded"
        class="flex h-full w-full items-center justify-center"
      >
        <SquareLoader :loading="true" size="24px" color="#2563eb" />
      </div>
      <div
        v-else-if="displayableTaskLists.length > 0"
        class="mt-2 flex-grow space-y-2"
      >
        <ul
          v-for="listObject in displayableTaskLists"
          :key="listObject.name"
          v-observe-visibility="{
            callback: (isVisible, entry) =>
              fetchTasksFor(isVisible, entry, listObject),
            once: true,
          }"
          role="list"
          class="flex flex-col divide-y divide-gray-200"
        >
          <h2
            :class="[taskListStore.colorFor(listObject, 'textColor')]"
            class="text-lg font-bold leading-5 sm:truncate sm:text-xl sm:tracking-tight"
          >
            {{ listObject.name }}
          </h2>
          <SelectableTask
            v-for="task in visibleCollectionFor(listObject)"
            :key="task.id"
            :task="task"
            :grouped-list="listObject"
            @refetch-for-repeat="tasksStore.fetchTasks(listObject.id)"
          />
          <InfiniteLoading
            v-if="
              signedIn &&
              listGroupedTaskPagyObjects[_.camelCase(listObject.name)]?.pagy
                ?.next
            "
            @infinite="($state) => loadTasksFor($state, listObject)"
          />
        </ul>
      </div>
      <section v-else>
        <div
          class="flex-grow relative block w-full rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <QuestionMarkCircleIcon class="mx-auto h-12 w-12 text-gray-400" />
          <span class="mt-2 block text-sm font-medium text-gray-900">
            No tasks nearby.
          </span>
        </div>
      </section>
    </div>
  </div>
</template>

<script setup>
import {
  ChevronLeftIcon,
  EllipsisHorizontalIcon,
} from "@heroicons/vue/20/solid";
import {
  MagnifyingGlassPlusIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/vue/24/outline";
import { useTaskListStore } from "@/stores/taskList";
import { useTasksStore } from "@/stores/tasks";
import { useReminderStore } from "@/stores/reminder";
import { useUserStore } from "@/stores/user";
import { useMainMapStore } from "@/stores/mainMap";
import { ref, computed, watch } from "vue";
import { storeToRefs } from "pinia";
import SquareLoader from "vue-spinner/src/SquareLoader.vue";
import TaskBatchActions from "@/components/tasks/TaskBatchActions.vue";
import SelectableTask from "@/components/tasks/SelectableTask.vue";
import { useRoute, useRouter } from "vue-router";
import api from "@/router/api";
import sortedTasksFor from "./taskListSorting";
import _ from "lodash";

const taskListStore = useTaskListStore();
const tasksStore = useTasksStore();
const {
  listGroupedTaskPagyObjects,
  addingInlineTask,
  batchSelection,
  batchTasks,
  selectedTask,
  effectiveTasks,
  effectiveTaskLists,
  nearbyViewShowCompleted,
  nearbyTasks,
  nearbyTasksLoaded,
} = storeToRefs(tasksStore);
const userStore = useUserStore();
const { signedIn } = storeToRefs(userStore);
const reminderStore = useReminderStore();
const mapStore = useMainMapStore();
const { mapBoundaryMeta, mapSearch } = storeToRefs(mapStore);

const displayableTaskLists = computed(() => {
  return effectiveTaskLists.value.filter((list) => {
    return _.some(nearbyTasks.value, function (task) {
      const completedFilter = nearbyViewShowCompleted.value
        ? true
        : !task.completedAt;
      return task.taskListName === list.name && completedFilter;
    });
  });
});
const mainTitleVisible = ref(true);
const nothingCompleted = computed(() => completedTasks.value.length === 0);
const incompleteTasks = computed(() =>
  effectiveTasks.value.filter((task) => {
    return !task.completedAt && isNearby(task);
  })
);
const completedTasks = computed(() =>
  effectiveTasks.value.filter((task) => {
    return !!task.completedAt && isNearby(task);
  })
);

function visibleCollectionFor(listObject) {
  return sortedTasksFor({
    signedIn: signedIn.value,
    useStandaloneShowCompleted: true,
    standaloneShowCompleted: nearbyViewShowCompleted.value,
    taskListObject: listObject,
    allTasks: allTasksFor(listObject),
    incompleteTasks: incompleteTasksFor(listObject),
  });
}
function allTasksFor(listObject) {
  return effectiveTasks.value.filter((task) => {
    return task.accessTokenId === listObject.accessTokenId && isNearby(task);
  });
}
function incompleteTasksFor(listObject) {
  return incompleteTasks.value.filter((task) => {
    return task.accessTokenId === listObject.accessTokenId && isNearby(task);
  });
}
function isNearby(task) {
  return !!_.find(nearbyTasks.value, { id: task.id });
}

const router = useRouter();
const route = useRoute();

const zoom = computed(() => {
  return (
    _.get(route.query, "zoom") ||
    _.get(mapSearch.value, "zoom") ||
    _.get(mapBoundaryMeta.value, "zoom")
  );
});

watch(zoom, (val) => {
  if (val && val < 14) {
    resetBatch();
    nearbyTasks.value = [];
    nearbyTasksLoaded.value = true;
  }
});

async function fetchTasksFor(isVisible, entry, listObject) {
  if (isVisible) tasksStore.fetchTasks(listObject.id);
}
function cleanUrl(url) {
  return url.replace("/api/v1/", "");
}
async function loadTasksFor($state, listObject) {
  let taskPagy =
    listGroupedTaskPagyObjects.value[
      listObject.accessTokenId || _.camelCase(listObject.name)
    ]?.pagy;
  if (signedIn.value && taskPagy?.next) {
    const endpoint = cleanUrl(taskPagy.next_url);
    try {
      api.get(endpoint).then((json) => {
        const { data, pagy } = json.data;

        tasksStore.patchTasks(data, true);
        taskPagy = pagy;
        if (data.length < 25) $state.complete();
        else {
          $state.loaded();
        }
      });
    } catch (error) {
      $state.error();
    }
  } else {
    $state.complete();
  }
}

function clearTaskViewQuery() {
  router.push({
    name: route.name,
    query: {
      ...route.query,
      taskView: undefined,
    },
  });
}

function pageTitleVisibilityChanged(isVisible) {
  mainTitleVisible.value = isVisible;
}
function resetBatch() {
  tasksStore.resetBatch();
}
function backToLists() {
  tasksStore.backToLists();
  clearTaskViewQuery();
}
function toggleShowCompleted() {
  if (nearbyViewShowCompleted.value) {
    api
      .post(`user_task_views_meta`, {
        attributeName: "nearby_tasks_view_show_completed",
        attributeValue: false,
      })
      .then(() => {
        nearbyViewShowCompleted.value = false;
      });
  } else {
    api
      .post(`user_task_views_meta`, {
        attributeName: "nearby_tasks_view_show_completed",
        attributeValue: true,
      })
      .then(() => {
        nearbyViewShowCompleted.value = true;
      });
  }
}
function add() {
  reminderStore.createTask().then(async (json) => {
    if (json) {
      const task = json.data;
      tasksStore.patchTasks([task]);
    }
    reminderStore.reset();
    addingInlineTask.value = false;
    selectedTask.value = null;
  });
}
function clearCompleted() {
  if (signedIn.value) {
    api.post(`completed_task_deletions?threshold=all`).then(() => {
      tasksStore.deleteCompletedTasks("all");
    });
  } else {
    tasksStore.deleteCompletedTasks("all");
  }
}
</script>
