<template>
  <SwitchGroup as="div" class="flex items-center justify-between">
    <span class="flex flex-grow items-center space-x-2">
      <div class="rounded-md p-1 bg-red-500">
        <CalendarIcon class="h-4 w-4 text-white" aria-hidden="true" />
      </div>
      <SwitchLabel as="span" class="text-sm font-medium text-gray-900" passive
        >Timing</SwitchLabel
      >
    </span>
    <Switch
      v-model="usesTiming"
      :class="[
        usesTiming ? 'bg-indigo-600' : 'bg-gray-200',
        'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2',
      ]"
    >
      <span
        aria-hidden="true"
        :class="[
          usesTiming ? 'translate-x-5' : 'translate-x-0',
          'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
        ]"
      />
    </Switch>
  </SwitchGroup>
</template>

<script setup>
import { watch } from "vue";
import { Switch, SwitchGroup, SwitchLabel } from "@headlessui/vue";
import { useReminderStore } from "@/stores/reminder";
import { storeToRefs } from "pinia";
import { CalendarIcon } from "@heroicons/vue/20/solid";

const reminderStore = useReminderStore();
const { usesTiming } = storeToRefs(reminderStore);

watch(usesTiming, () => {
  if (!usesTiming.value) {
    reminderStore.resetTiming();
  }
});
</script>
