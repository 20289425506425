import { markRaw, ref } from "vue";
import { defineStore, storeToRefs, acceptHMRUpdate } from "pinia";
import { useModalStore } from "@/stores/modal";
import FileViewer from "@/components/crowdsourcing/FileViewer.vue";

export const useUploadedFileStore = defineStore("uploadedFile", () => {
  const completedUploadPayload = ref(null);
  const modalStore = useModalStore();
  const { modalPayload } = storeToRefs(modalStore);

  function viewEmbed(file) {
    modalPayload.value = {
      size: "xl",
      theme: "dark",
      component: markRaw(FileViewer),
      props: { file },
    };
  }

  return { completedUploadPayload, viewEmbed };
});

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useUploadedFileStore, import.meta.hot)
  );
}
