<template>
  <li class="flex">
    <div
      v-tooltip="reputationHelp"
      class="flex-shrink-0 flex items-center justify-center w-12"
    >
      <span
        v-if="attribution.name"
        :class="reputationColor"
        class="inline-flex items-center justify-center h-12 w-12"
      >
        <span class="font-medium uppercase leading-none text-white">
          {{ $filters.initials(attribution.name) }}
        </span>
      </span>
      <img
        v-else
        :class="reputationColor"
        class="p-1 inline-block h-12 w-12"
        src="https://assets.towerhunt.com/site/Transparent_White_T0.png"
        alt="Tower Hunt logo"
      />
    </div>
    <div class="flex-1 flex items-center justify-between bg-white truncate">
      <div class="flex-1 p-2 text-xs truncate">
        <a
          @click.prevent
          href=""
          v-tooltip="attribution.name ? 'Only you can see your identity.' : ''"
          class="text-gray-900 font-medium hover:text-gray-600"
        >
          <template v-if="attribution.name"
            >{{ attribution.name }} (Me)</template
          >
          <template v-else>{{ attribution.reputationLabel }}</template>
        </a>
        <p class="text-gray-500">
          {{ roles.join(", ") }}
        </p>
      </div>
    </div>
  </li>
</template>

<script setup>
import { computed } from "vue";
import _ from "lodash";

const props = defineProps(["attribution", "attributions"]);

const reputationHelp = computed(() =>
  _.capitalize(props.attribution.reputationLevel)
);
const reputationColor = computed(() => {
  switch (props.attribution.reputationLevel) {
    case "bronze":
      return "bg-gradient-to-r from-yellow-500 to-orange-700 text-white hover:to-orange-800";
    case "silver":
      return "bg-gradient-to-r from-slate-200 to-gray-400 text-gray-900 hover:to-gray-500";
    case "gold":
      return "bg-gradient-to-r from-yellow-300 to-yellow-500 text-white hover:to-yellow-600";
    default:
      return "text-white bg-gray-800 hover:bg-gray-700";
  }
});
const roles = computed(() => {
  if (props.attributions) {
    return _.sortBy(
      _.uniq(props.attributions.map((attr) => _.capitalize(attr.userRole)))
    );
  } else {
    return [];
  }
});
</script>
