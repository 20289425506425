<template>
  <fieldset>
    <legend class="sr-only">Denominators</legend>
    <div class="space-y-2">
      <div
        v-for="denominator in denominators"
        :key="denominator.apiValue"
        class="relative flex items-start"
      >
        <div class="flex h-5 items-center">
          <input
            @change="setDenominator(denominator.apiValue)"
            :id="denominator.apiValue"
            :aria-describedby="`${denominator.apiValue}-description`"
            name="denominator"
            type="radio"
            :checked="
              calculationOptions.pricePerArea.denominator ===
              denominator.apiValue
            "
            class="h-3 w-3 border-gray-300 text-indigo-600 focus:ring-indigo-600"
          />
        </div>
        <div class="ml-3 text-sm leading-6">
          <label
            :for="denominator.apiValue"
            class="text-xs font-medium text-gray-900"
            >{{ denominator.name }}</label
          >
          <p
            :id="`${denominator.apiValue}-description`"
            class="text-xs text-gray-500"
          >
            {{ denominator.description }}
          </p>
        </div>
      </div>
    </div>
  </fieldset>
</template>

<script setup>
import { useAnalyzePanelStore } from "@/stores/analyzePanel";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import _ from "lodash";

const props = defineProps(["optionData"]);

const analyzePanelStore = useAnalyzePanelStore();
const { calculationOptions } = storeToRefs(analyzePanelStore);

const useBasedDenominators = computed(() => {
  const filteredUses = _.get(props.optionData, "sizeRankedUses", []).filter(
    (useObject) => !useObject.excluded,
  );
  return filteredUses.flatMap((useObject) => {
    if (useObject.unitName === "SF") {
      return [
        {
          apiValue: `${useObject.name}_standardized`,
          name: `${useObject.name} SF`,
          description: `${useObject.standardizedArea}`,
        },
      ];
    } else {
      return [
        {
          apiValue: `${useObject.name}_useBased`,
          name: `${useObject.name} ${useObject.unitName}`,
          description: `${useObject.useBasedArea}`,
        },
        {
          apiValue: `${useObject.name}_standardized`,
          name: `${useObject.name} SF`,
          description: `${useObject.standardizedArea}`,
        },
      ];
    }
  });
});
const denominators = computed(() => {
  return _.concat(
    [
      {
        apiValue: "topLevel",
        name: "Total SF",
        description: _.get(
          props.optionData,
          "topLevelAreaString",
          "Sum of all uses, measured in SF",
        ),
      },
    ],
    useBasedDenominators.value,
  );
});

function setDenominator(newDenominator) {
  calculationOptions.value.pricePerArea.denominator = newDenominator;
}
</script>
