<template>
  <div class="h-full flex flex-col">
    <nav
      class="p-2 bg-gray-50 flex items-center justify-between"
      aria-label="Breadcrumb"
    >
      <div class="flex items-center space-x-2">
        <a @click.prevent="backToLists" href="" class="flex items-center">
          <ChevronLeftIcon
            class="h-5 w-5 flex-shrink-0 text-indigo-400"
            aria-hidden="true"
          />
          <span
            class="text-sm font-medium text-indigo-500 hover:text-indigo-700"
            >Lists</span
          >
        </a>
      </div>
      <h3
        v-if="!mainTitleVisible"
        class="flex min-w-0 flex-1 items-center justify-center font-medium text-gray-900"
      >
        <template v-if="batchSelection && batchTasks.length > 0">
          {{ batchTasks.length }} Selected
        </template>
        <template v-else-if="batchSelection"> Select Reminders </template>
        <template v-else> Today </template>
      </h3>
      <div class="flex items-center space-x-3">
        <VDropdown v-if="!batchSelection" class="flex items-center">
          <button
            type="button"
            class="inline-flex justify-center p-0.5 items-center rounded-full border border-indigo-400 text-sm text-indigo-500 hover:text-indigo-700"
            data-test="task-list-options-button`"
          >
            <EllipsisHorizontalIcon class="h-4 w-4" />
          </button>

          <template #popper>
            <div
              class="w-64 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="menu-button"
              tabindex="-1"
            >
              <div class="py-1" role="none">
                <a
                  @click.prevent="batchSelection = true"
                  href=""
                  class="text-gray-700 hover:bg-gray-100 hover:text-gray-900 group flex items-center px-4 py-2 text-sm"
                  role="menuitem"
                  tabindex="-1"
                >
                  Select Reminders
                </a>
                <a
                  @click.prevent="toggleGroupByTime"
                  href=""
                  class="text-gray-700 hover:bg-gray-100 hover:text-gray-900 group flex items-center px-4 py-2 text-sm space-x-1"
                  role="menuitem"
                  tabindex="-1"
                >
                  <CheckIcon
                    v-if="todayViewGroupByTime"
                    class="h-5 w-5 -ml-2 text-gray-700"
                  />
                  <div class="flex-grow">Group by Time</div>
                </a>
                <VDropdown v-if="signedIn">
                  <a
                    @click.prevent
                    href=""
                    class="hover:bg-gray-100 group flex items-center p-2 space-x-1"
                    role="menuitem"
                    tabindex="-1"
                    id="menu-item-0"
                  >
                    <ChevronRightIcon class="h-4 w-4 text-gray-700" />
                    <div class="flex-grow">
                      <div class="font-semibold text-gray-700 text-sm">
                        Sort By
                      </div>
                      <div class="text-gray-500 text-xs">
                        {{ sortOrderFor(todayViewSortBy)?.name }}
                      </div>
                    </div>
                    <ArrowsUpDownIcon class="h-4 w-4 text-gray-700" />
                  </a>
                  <template #popper>
                    <div
                      class="w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="menu-button"
                      tabindex="-1"
                    >
                      <div class="py-1" role="none">
                        <div
                          class="group flex items-center justify-between p-2"
                          role="menuitem"
                          tabindex="-1"
                          id="menu-item-0"
                        >
                          <div>
                            <div class="font-semibold text-gray-700 text-sm">
                              Sort By
                            </div>
                            <div class="text-gray-500 text-xs">
                              {{ sortOrderFor(todayViewSortBy)?.name }}
                            </div>
                          </div>
                          <ArrowsUpDownIcon class="h-4 w-4 text-gray-700" />
                        </div>
                      </div>
                      <div class="py-1" role="none">
                        <a
                          v-for="option in todayViewSortOptions"
                          :key="option.apiValue"
                          @click.prevent="
                            updateListSort(
                              'today_tasks_view_sort_by',
                              option.apiValue
                            )
                          "
                          href=""
                          class="text-gray-700 hover:bg-gray-100 hover:text-gray-900 group flex items-center space-x-2 p-2 text-sm"
                          role="menuitem"
                          tabindex="-1"
                          id="menu-item-0"
                          :data-test="`sort-task-list-by-${option.apiValue}-button`"
                        >
                          <CheckIcon
                            v-if="
                              sortOrderFor(todayViewSortBy)?.apiValue ===
                              option.apiValue
                            "
                            class="h-5 w-5"
                            aria-hidden="true"
                          />
                          <span>{{ option.name }}</span>
                        </a>
                      </div>
                      <div class="py-1" role="none">
                        <a
                          v-for="option in sortOrderFor(todayViewSortBy)
                            .directions"
                          :key="option.apiValue"
                          @click.prevent="
                            updateListSort(
                              'today_tasks_view_sort_direction',
                              option.apiValue
                            )
                          "
                          href=""
                          class="text-gray-700 hover:bg-gray-100 hover:text-gray-900 group flex items-center space-x-2 p-2 text-sm"
                          role="menuitem"
                          tabindex="-1"
                          id="menu-item-0"
                          :data-test="`sort-task-list-by-${option.apiValue}-button`"
                        >
                          <CheckIcon
                            v-if="todayViewSortDirection === option.apiValue"
                            class="h-5 w-5"
                            aria-hidden="true"
                          />
                          <span>{{ option.name }}</span>
                        </a>
                      </div>
                    </div>
                  </template>
                </VDropdown>
              </div>
            </div>
          </template>
        </VDropdown>
        <a
          v-if="addingInlineTask || selectedTask"
          @click.prevent="add"
          href=""
          id="inline-reminder-form-done-button"
          class="flex items-center text-sm font-bold text-indigo-500"
        >
          Done
        </a>
        <a
          v-else-if="batchSelection"
          @click.prevent="resetBatch"
          href=""
          class="flex items-center text-sm font-bold text-indigo-500"
        >
          Done
        </a>
      </div>
    </nav>

    <div class="p-2 flex flex-col flex-grow overflow-y-auto">
      <div class="lg:flex lg:items-center lg:justify-between">
        <div class="min-w-0 flex-1">
          <h2
            v-observe-visibility="pageTitleVisibilityChanged"
            class="mt-2 text-xl font-bold leading-6 sm:truncate sm:text-2xl sm:tracking-tight text-blue-500"
          >
            <template v-if="batchSelection && batchTasks.length > 0">
              {{ batchTasks.length }} Selected
            </template>
            <template v-else-if="batchSelection"> Select Reminders </template>
            <template v-else> Today </template>
          </h2>
        </div>
      </div>

      <TaskBatchActions v-if="isDesktop && batchSelection" />
      <div class="mt-2 flex-grow space-y-2">
        <InlineReminderForm
          v-if="!isDesktop && addingInlineTask"
          :grouped-list="remindersList"
          :time-object="todayMorningObject"
          @saved="patch"
        />
        <template v-if="todayViewGroupByTime">
          <ul
            v-for="timeObject in todayTimes"
            :key="timeObject.name"
            role="list"
            class="flex flex-col space-y-1"
          >
            <h3
              v-if="timeObject.name !== 'Past Due'"
              @click="addInlineTaskFor(timeObject)"
              class="text-sm font-medium leading-4 sm:truncate sm:text-base sm:tracking-tight text-gray-500"
            >
              {{ timeObject.name }}
            </h3>
            <SelectableTask
              v-for="task in tasksFor(timeObject.name)"
              :key="task.id"
              :task="task"
              :context="contextFor(task)"
              @completion-toggled="patch"
              @refetch-for-repeat="fetchTasks"
            />
            <InlineReminderForm
              v-if="addingInlineTask === timeObject.name"
              :grouped-list="remindersList"
              :time-object="timeObject"
              @saved="patch"
            />
            <div
              v-else-if="tasksFor(timeObject.name).length > 0"
              @click="addInlineTaskFor(timeObject)"
              class="block hover:bg-gray-100"
            >
              <div class="flex items-start p-2">
                <div class="flex min-w-0 flex-1 items-start">
                  <div
                    class="flex-shrink-0 flex items-center justify-center h-6 w-6 rounded-full border border-dashed border-gray-400"
                  />
                </div>
              </div>
            </div>
          </ul>
        </template>
        <template v-else>
          <ul class="flex flex-col space-y-1">
            <SelectableTask
              v-for="task in todayTasks"
              :key="task.id"
              :task="task"
              :context="contextFor(task)"
              @completion-toggled="patch"
              @refetch-for-repeat="fetchTasks"
            />
            <InlineReminderForm
              v-if="addingInlineTask"
              :grouped-list="remindersList"
              :time-object="todayMorningObject"
              @saved="patch"
            />
            <div
              v-else
              @click="addInlineTaskFor(null)"
              class="block hover:bg-gray-100"
            >
              <div class="flex items-start p-2">
                <div class="flex min-w-0 flex-1 items-start">
                  <div
                    class="flex-shrink-0 flex items-center justify-center h-6 w-6 rounded-full border border-dashed border-gray-400"
                  />
                </div>
              </div>
            </div>
          </ul>
        </template>
        <InfiniteLoading
          v-if="signedIn && serverSideTasks.pagy?.next"
          @infinite="loadTasks"
        />
      </div>
    </div>

    <template v-if="!isDesktop">
      <TaskBatchActions v-if="batchSelection" />
      <div v-else class="p-2 bg-gray-50 flex items-center justify-between">
        <a
          @click.prevent="addInlineTaskFor(null)"
          href=""
          class="flex items-center text-sm font-medium text-indigo-500"
        >
          <PlusCircleIcon
            class="mr-1.5 h-5 w-5 flex-shrink-0 text-indigo-400"
            aria-hidden="true"
          />
          New Task
        </a>
      </div>
    </template>
  </div>
</template>

<script setup>
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  PlusCircleIcon,
  EllipsisHorizontalIcon,
  ArrowsUpDownIcon,
  CheckIcon,
} from "@heroicons/vue/20/solid";
import { useTasksStore } from "@/stores/tasks";
import { useUserStore } from "@/stores/user";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { useReminderStore } from "@/stores/reminder";
import { ref, computed, onMounted } from "vue";
import { storeToRefs } from "pinia";
import { todayViewSortOptions } from "@/components/tasks/taskListSortOptions";
import TaskBatchActions from "@/components/tasks/TaskBatchActions.vue";
import InlineReminderForm from "@/components/tasks/InlineReminderForm.vue";
import SelectableTask from "@/components/tasks/SelectableTask.vue";
import { useRoute, useRouter } from "vue-router";
import api from "@/router/api";
import moment from "moment";
import _ from "lodash";

const tasksStore = useTasksStore();
const {
  addingInlineTask,
  batchSelection,
  batchTasks,
  selectedTask,
  effectiveTasks,
  effectiveTaskLists,
  todayViewGroupByTime,
  todayViewSortBy,
  todayViewSortDirection,
} = storeToRefs(tasksStore);
const layoutStore = useWorkspaceLayoutStore();
const { isDesktop } = storeToRefs(layoutStore);
const userStore = useUserStore();
const { signedIn } = storeToRefs(userStore);
const reminderStore = useReminderStore();
const { list, usesTiming, date, time } = storeToRefs(reminderStore);

const serverSideTasks = ref({
  data: [],
  pagy: null,
});
const mainTitleVisible = ref(true);
const todayTimes = ref([
  {
    name: "Past Due",
    date: moment().startOf("day").add(8, "hours"),
  },
  {
    name: "Morning",
    date: moment().startOf("day").add(8, "hours"),
  },
  {
    name: "Afternoon",
    date: moment().startOf("day").add(14, "hours"),
  },
  {
    name: "Tonight",
    date: moment().startOf("day").add(18, "hours"),
  },
]);
const todayMorningObject = computed(() =>
  _.find(todayTimes.value, { name: "Morning" })
);
const remindersList = computed(() =>
  _.find(effectiveTaskLists.value, { name: "Reminders" })
);
const todayTasks = computed(() => {
  let collection = [];
  if (signedIn.value) {
    collection = serverSideTasks.value.data;
  } else {
    collection = effectiveTasks.value;
  }

  return collection.filter((task) => {
    return (
      momentDueDateFor(task).isSameOrBefore(moment(), "day") &&
      !task.completedAt
    );
  });
});
function tasksFor(timeLabel) {
  return todayTasks.value.filter((task) => {
    const morning = moment().startOf("day");
    const noon = moment().startOf("day").add(12, "hours");
    const night = moment().startOf("day").add(18, "hours");
    const end = moment().endOf("day");
    const momentDate = momentDueDateFor(task);

    switch (timeLabel) {
      case "Morning":
        return momentDate.isBetween(morning, noon, undefined, "[)");
      case "Afternoon":
        return momentDate.isBetween(noon, night, undefined, "[)");
      case "Tonight":
        return momentDate.isBetween(night, end, undefined, "[]");
      default:
        return momentDate.isBefore(morning);
    }
  });
}

function momentDueDateFor(task) {
  if (signedIn.value) {
    return moment.unix(task.dueDate);
  } else {
    return moment(task.dueDate);
  }
}
function contextFor(task) {
  if (momentDueDateFor(task).isBefore(moment(), "day")) {
    return "todayPastDue";
  } else {
    return "today";
  }
}

onMounted(() => fetchTasks());

async function fetchTasks() {
  if (signedIn.value) {
    api.get(`today_tasks`).then((json) => {
      serverSideTasks.value = json.data;
    });
  }
}
function refetchForRepeat(task) {
  if (task.repeat !== "never") fetchTasks();
  else patch(task);
}
function patch(task) {
  if (signedIn.value) {
    serverSideTasks.value.data = _.unionBy(
      [task],
      serverSideTasks.value.data,
      "id"
    );
  }
}
function cleanUrl(url) {
  return url.replace("/api/v1/", "");
}
const loadTasks = async ($state) => {
  if (signedIn.value && serverSideTasks.value.pagy?.next) {
    const endpoint = cleanUrl(serverSideTasks.value.pagy.next_url);
    try {
      api.post(endpoint).then((json) => {
        const { data, pagy } = json.data;

        serverSideTasks.value.data = _.unionBy(
          serverSideTasks.value.data,
          data,
          "id"
        );
        serverSideTasks.value.pagy = pagy;
        if (data.length < 25) $state.complete();
        else {
          $state.loaded();
        }
      });
    } catch (error) {
      $state.error();
    }
  } else {
    $state.complete();
  }
};

const router = useRouter();
const route = useRoute();

function clearTaskViewQuery() {
  router.push({
    name: route.name,
    query: {
      ...route.query,
      taskView: undefined,
    },
  });
}

function pageTitleVisibilityChanged(isVisible) {
  mainTitleVisible.value = isVisible;
}
function resetBatch() {
  tasksStore.resetBatch();
}
function backToLists() {
  tasksStore.backToLists();
  clearTaskViewQuery();
}
function sortOrderFor(sortOrderApiValue) {
  return _.find(todayViewSortOptions, { apiValue: sortOrderApiValue });
}
function toggleGroupByTime() {
  if (signedIn.value) {
    if (todayViewGroupByTime.value) {
      api
        .post(`user_task_views_meta`, {
          attributeName: "today_tasks_view_group_by_time",
          attributeValue: false,
        })
        .then(() => {
          todayViewGroupByTime.value = false;
        });
    } else {
      api
        .post(`user_task_views_meta`, {
          attributeName: "today_tasks_view_group_by_time",
          attributeValue: true,
        })
        .then(() => {
          todayViewGroupByTime.value = true;
        });
    }
  } else {
    todayViewGroupByTime.value = !todayViewGroupByTime.value;
  }
}
function updateListSort(attributeName, attributeValue) {
  if (signedIn.value) {
    api
      .post(`user_task_views_meta`, {
        attributeName,
        attributeValue,
      })
      .then(() => {
        patchLocalMeta(attributeName, attributeValue);
        fetchTasks();
      });
  } else {
    patchLocalMeta(attributeName, attributeValue);
  }
}
function patchLocalMeta(attributeName, attributeValue) {
  switch (attributeName) {
    case "today_tasks_view_sort_by":
      todayViewSortBy.value = attributeValue;
      break;
    case "today_tasks_view_sort_direction":
      todayViewSortDirection.value = attributeValue;
      break;
    default:
      return;
  }
}
function add() {
  reminderStore.createTask().then(async (json) => {
    if (json) {
      const task = json.data;
      tasksStore.patchTasks([task]);
      patch(task);
    }
    reminderStore.reset();
    addingInlineTask.value = false;
    selectedTask.value = null;
  });
}
function addInlineTaskFor(timeObject) {
  if (!addingInlineTask.value && !selectedTask.value && !batchSelection.value) {
    addingInlineTask.value = timeObject?.name || true;
    list.value = remindersList.value;
    usesTiming.value = true;
    const effectiveTime = timeObject?.date || todayMorningObject.value?.date;

    date.value = effectiveTime.format("YYYY-MM-DD");
    time.value = effectiveTime.format("kk:mm");
  }
}
</script>
