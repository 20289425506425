import _ from "lodash";

export default function unlockableInnerContent(dataField) {
  const fieldContent = _.get(dataField, "fieldContent");

  if (_.isObject(fieldContent)) {
    const objectValues = _.values(fieldContent);
    const unlicensed = (val) => {
      return _.get(val, "meteringStatus") === "unlicensed";
    };

    return _.some(objectValues, unlicensed);
  } else {
    return false;
  }
}
