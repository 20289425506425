<template>
  <div
    class="container mx-auto md:mt-12 mb-4 px-4 sm:px-8 lg:px-10 flex flex-col items-center"
  >
    <iframe
      :style="embedHeight"
      :src="file.link"
      class="h-full w-full bg-gray-50"
    />
  </div>
</template>

<script setup>
import _ from "lodash";
import { ref, computed, onMounted, onBeforeUnmount } from "vue";

defineProps(["file"]);
const debouncedHeightUpdate = ref(null);
const modalHeight = ref(null);

const embedHeight = computed(() => {
  if (modalHeight.value) {
    const heightRatio = modalHeight.value > 400 ? 0.9 : 0.7;

    return `height: ${modalHeight.value * heightRatio}px;`;
  } else {
    return "height: 200px";
  }
});
onMounted(() => {
  modalHeight.value = document.getElementById("xlModalVue").offsetHeight;

  debouncedHeightUpdate.value = _.debounce(() => {
    modalHeight.value = document.getElementById("xlModalVue").offsetHeight;
  }, 100);

  window.addEventListener("resize", debouncedHeightUpdate.value);
});
onBeforeUnmount(() => {
  window.removeEventListener("resize", debouncedHeightUpdate.value);
});
</script>
