<template>
  <div class="rounded-md border border-gray-500 shadow-sm p-2 space-y-2">
    <DataField
      :data-field="dataField"
      text-classes="text-sm"
      @completed="refetch"
      @open-sourced="refetch"
      @unlocked="refetch"
      @dismiss="refetch"
    />

    <div v-if="phasesFields" class="flex flex-col space-y-2">
      <div class="text-sm font-medium text-gray-500">Phasing</div>
      <SpaceRequirementPhase
        v-for="phaseDataField in orderedPhases"
        :key="phaseDataField.localId"
        :phase-data-field="phaseDataField"
        :requirement-data-field="dataField"
        @refetch="refetch"
      />
    </div>

    <HuntSpaceRequirementHeadcountAssumptions
      v-if="assumptionsFields"
      :assumptions-fields="assumptionsFields"
      :requirement-data-field="dataField"
      @refetch="refetch"
    />
  </div>
</template>

<script setup>
import DataField from "@/components/crowdsourcing/DataField.vue";
import HuntSpaceRequirementHeadcountAssumptions from "@/components/crowdsourcing/HuntSpaceRequirementHeadcountAssumptions.vue";
import SpaceRequirementPhase from "@/components/crowdsourcing/SpaceRequirementPhase.vue";
import _ from "lodash";
import { computed } from "vue";

const props = defineProps(["dataField"]);
const emit = defineEmits(["refetch"]);

const assumptionsFields = computed(
  () => props.dataField.fieldContent?.headcountAssumptions,
);
const phasesFields = computed(() => props.dataField.fieldContent?.phases);
const orderedPhases = computed(() => {
  return _.orderBy(phasesFields.value, function (dataField) {
    return dataField.fieldContent.order;
  });
});

function refetch() {
  emit("refetch");
}
</script>
