import _ from "lodash";

export default function selfSelected(subjectContent, selectionObject) {
  return (
    _.get(selectionObject, "dataField.fieldContentId", false) ===
      _.get(subjectContent, "id", null) &&
    _.get(selectionObject, "dataField.fieldContentType", false) ===
      _.get(subjectContent, "recordType", null)
  );
}
