<template>
  <nav class="flex flex-col" aria-label="Key availability dates">
    <dt class="text-sm font-medium text-gray-500">
      {{ editingState ? "Current Milestone" : "Milestones" }}
    </dt>
    <ol v-if="loaded && editable" role="list" class="mt-2 space-y-4">
      <ContentMilestoneDropdown
        v-if="editingState"
        :availability-group-id="availabilityGroupId"
        :existing-state="state"
        :parent-selected-value="selectedValue"
        :context="context"
        @set-selected-value="setSelectedValue"
        @unlocked="unlocked"
        @completed="completed"
        @cancel="cancel"
      />
      <template v-else>
        <AvailabilityGroupKeyDate
          v-for="optionObj in dropdownOptions"
          :key="optionObj.value"
          :availability-group-id="availabilityGroupId"
          :availability-state="state || unlockableState"
          :milestone-object="optionObj"
          :all-milestones="dropdownOptions"
          :timing-fields="timingFields"
          :timing-unlockable="timingUnlockable"
          @unlocked="unlocked"
          @completed="completed"
          @suggest-state-change="suggestStateChange"
        />
      </template>
    </ol>
    <div v-else-if="loaded && timingUnlocked" class="mt-4 text-center">
      <svg
        class="mx-auto h-8 w-8 text-gray-400"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
        />
      </svg>
      <h3 class="mt-2 text-sm font-medium text-gray-900">
        Multiple dates/states
      </h3>
      <p class="mt-1 text-sm text-gray-500">
        The available spaces don't all share the same state. Please edit via the
        space list.
      </p>
    </div>
    <div
      v-else-if="loaded && _.size(timingFields) > 0"
      class="mt-4 text-center"
    >
      <svg
        class="mx-auto h-8 w-8 text-gray-400"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
        />
      </svg>
      <h3 class="mt-2 text-sm font-medium text-gray-900">
        Unlockable dates/states
      </h3>
      <p class="mt-1 text-sm text-gray-500">
        All timing datapoints need to be unlocked. Please check the space list.
      </p>
    </div>
  </nav>
</template>

<script setup>
import ContentMilestoneDropdown from "@/components/crowdsourcing/ContentMilestoneDropdown.vue";
import AvailabilityGroupKeyDate from "@/components/space-usage-builder/AvailabilityGroupKeyDate.vue";
import { ref, computed, watch, onMounted } from "vue";
import _ from "lodash";
import api from "@/router/api";
import fullyUnlocked from "@/components/crowdsourcing/fullyUnlocked";
import milestones from "@/assets/contentMilestones";

const props = defineProps([
  "availabilityGroupId",
  "timingFields",
  "timingUnlockable",
  "context",
]);
const emit = defineEmits(["refetch", "unlocked"]);

const loaded = ref(false);
const state = ref(null);
const unlockableState = computed(() => {
  if (props.timingUnlockable) {
    const uniqueFields = _.uniqBy(props.timingFields, "fieldName");
    return _.head(
      uniqueFields.filter((field) =>
        _.includes(
          ["state", "contract_state", "occupancy_state"],
          field.fieldName,
        ),
      ),
    );
  } else {
    return null;
  }
});
const editingState = ref(false);
const selectedValue = ref(null);
const editable = computed(() => {
  if (_.size(props.timingFields) > 0) {
    return state.value || unlockableState.value;
  } else {
    return props.context === "occupancy";
  }
});
const dropdownOptions = computed(() => {
  let matchingOptions = _.get(milestones, "Investment", []);

  switch (props.context) {
    case "contract":
      matchingOptions = _.get(milestones, "SpaceUsage", []);
      break;
    case "occupancy":
      matchingOptions = _.get(milestones, "SpaceUsageOccupancy", []);
      break;
  }

  return _.sortBy(matchingOptions, ["order"]);
});
const timingUnlocked = computed(() => {
  return _.size(props.timingFields) > 0 && fullyUnlocked(props.timingFields);
});

watch(
  () => props.availabilityGroupId,
  (id, oldId) => {
    if (id !== oldId) {
      reset();
      fetchState();
    }
  },
);
watch(
  () => props.context,
  (s, oldS) => {
    if (s !== oldS) {
      reset();
      fetchState();
    }
  },
);

onMounted(() => {
  fetchState();
});

function reset() {
  state.value = null;
  editingState.value = false;
  selectedValue.value = null;
}
function suggestStateChange() {
  setSelectedValue();
  editingState.value = true;
}
function setSelectedValue(newVal) {
  if (newVal) {
    selectedValue.value = newVal;
  } else if (state.value) {
    selectedValue.value = _.find(dropdownOptions.value, {
      value: state.value,
    }).value;
  } else {
    selectedValue.value = _.head(dropdownOptions.value).value;
  }
}
function unlocked(maybePayload) {
  emit("unlocked", maybePayload);
}
function completed(maybePayload) {
  if (maybePayload?.dataField) {
    cancel();
    // this.$store.dispatch("flash", "Saved!");
    fetchState(true);
  }
}
function cancel() {
  selectedValue.value = null;
  editingState.value = false;
}
function fetchState(refetch = false) {
  loaded.value = false;

  api
    .get(
      `availability_group_states/${props.availabilityGroupId}?context=${props.context}`,
    )
    .then((json) => {
      state.value = json.data;
      loaded.value = true;

      if (refetch) {
        emit("refetch");
      }
    });
}
</script>
