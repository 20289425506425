<template>
  <div class="h-full rounded-lg overflow-hidden">
    <CardBranding :card="card" />
    <div class="absolute inset-0">
      <div
        class="absolute inset-0 bg-gradient-to-r from-amber-600 to-yellow-400 mix-blend-multiply"
      />
    </div>
    <div
      v-if="noteDataField"
      class="h-full relative px-4 py-16 flex flex-col justify-center"
    >
      <h1 class="text-center font-bold tracking-tight">
        <span class="block text-3xl text-white">Note on</span>
        <span class="block text-xl text-yellow-200">{{ contextLabel }}</span>
      </h1>
      <div class="mt-6 flex justify-center">
        <DataField
          :data-field="noteDataField"
          primary-text-path="fieldContent.noteText"
          text-classes=""
          :always-private="true"
          @dismiss="dismiss"
          @suggest-change="suggestChange"
          @unlocked="unlocked"
          @completed="completed"
        />
      </div>
      <p class="mx-auto mt-3 max-w-lg text-center text-yellow-200">
        Shared by {{ sharedByName }}
      </p>
    </div>
  </div>
</template>

<script setup>
import CardBranding from "@/components/collectible-cards/CardBranding.vue";
import DataField from "@/components/crowdsourcing/DataField.vue";
import { hasManyGroupingData } from "@/components/crowdsourcing/validationGrouping";
import { ref, computed, watch, onMounted } from "vue";
import api from "@/router/api";
import _ from "lodash";

const props = defineProps([
  "card",
  "cardIndex",
  "stackIndex",
  "completedCardIds",
]);
const noteDataField = ref(null);
const contextLabel = ref(null);

const sharedByName = computed(() =>
  props.card.taskName.replace("Safezone data shared by ", "")
);

watch(
  () => props.stackIndex,
  () => {
    fetchNoteField();
  }
);

onMounted(() => {
  fetchNoteField();
  fetchContext();
});

function fetchNoteField() {
  if (!noteDataField.value) {
    api.get(`data_field_by_id/${props.card.bundleDataFieldId}`).then((json) => {
      noteDataField.value = json.data;
    });
  }
}
async function fetchContext() {
  const { contentType, contentId } = hasManyGroupingData(
    props.card.combinedKey
  );

  const fetchable =
    contentType &&
    _.trim(contentType) !== "" &&
    contentId &&
    _.trim(contentId) !== "";

  if (fetchable) {
    api.get(`validation_contexts/${contentType}/${contentId}`).then((json) => {
      contextLabel.value = json.data.summary;
    });
  }
}
function dismiss() {}
function suggestChange() {}
function completed() {}
function unlocked() {}
</script>
