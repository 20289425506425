<template>
  <ul
    role="list"
    :class="
      workspaceLayout === 'topAndBottom'
        ? 'grid-cols-2 grid-flow-row-dense'
        : 'grid-cols-1'
    "
    class="p-2 mt-3 grid gap-3"
  >
    <DataVisibilityButton
      v-if="loaded && photos.length > 0"
      visibility="safezone"
      class="w-full"
    >
      <template v-slot:button>
        <button
          @click="uploadPhotos"
          type="button"
          :class="workspaceLayout === 'topAndBottom' ? 'sm:col-span-2' : ''"
          class="w-full text-sm inline-flex justify-center rounded-md border border-yellow-300 shadow-sm px-4 py-2 bg-white font-medium text-yellow-700 hover:bg-yellow-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
        >
          Add photos
        </button>
      </template>
    </DataVisibilityButton>
    <PhotoField
      v-for="(photoDataField, index) in photos"
      :key="photoDataField.localId"
      :photo-data-field="photoDataField"
      :data-test="`photo-field-${index}`"
      @unlocked="refetch(photoDataField.localId)"
      @open-sourced="refetch(photoDataField.localId)"
    />
    <DataVisibilityButton
      v-if="loaded && photos.length === 0"
      visibility="safezone"
      class="relative block w-full"
    >
      <template v-slot:button>
        <button
          @click="uploadPhotos"
          type="button"
          class="relative block w-full border-2 border-yellow-400 border-dashed rounded-lg p-12 text-center hover:border-yellow-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-600"
        >
          <svg
            class="mx-auto h-12 w-12 text-yellow-500"
            xmlns="http://www.w3.org/2000/svg"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 24 24"
            aria-hidden="true"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
            />
          </svg>
          <span class="mt-2 block text-sm font-medium text-gray-900">
            Add photos
          </span>
        </button>
      </template>
    </DataVisibilityButton>
  </ul>
</template>

<script setup>
import api from "@/router/api";
import decoratingAndFieldKey from "@/components/crowdsourcing/decoratingAndFieldKey";
import FileDragDrop from "@/components/crowdsourcing/FileDragDrop.vue";
import PhotoField from "@/components/crowdsourcing/PhotoField.vue";
import { computed, onMounted, ref, markRaw, watch } from "vue";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { storeToRefs } from "pinia";
import { usePropertyDiagramStore } from "@/stores/propertyDiagram";
import { useModalStore } from "@/stores/modal";
import { useUnlockerStore } from "@/stores/unlocker";
import { useUploadedFileStore } from "@/stores/uploadedFile";
import { useDataLicensesChannelStore } from "@/stores/dataLicensesChannel";
import { useDataSharingsChannelStore } from "@/stores/dataSharingsChannel";
import subscribeInterceptor from "@/components/crowdsourcing/subscribeInterceptor";
import _ from "lodash";
import DataVisibilityButton from "@/components/crowdsourcing/DataVisibilityButton.vue";

const props = defineProps(["dataField"]);
const photos = ref([]);
const loaded = ref(false);
const layoutStore = useWorkspaceLayoutStore();
const { workspaceLayout } = storeToRefs(layoutStore);
const propertyDiagramStore = usePropertyDiagramStore();
const { propertyDiagramSelectedPropertyId } = storeToRefs(propertyDiagramStore);
const modalStore = useModalStore();
const { modalPayload } = storeToRefs(modalStore);
const uploadedFileStore = useUploadedFileStore();
const { completedUploadPayload } = storeToRefs(uploadedFileStore);
const dataLicensesChannelStore = useDataLicensesChannelStore();
const { dataLicensesChannelDataQueue } = storeToRefs(dataLicensesChannelStore);
const dataSharingsChannelStore = useDataSharingsChannelStore();
const { dataSharingsChannelDataQueue } = storeToRefs(dataSharingsChannelStore);

const combinedId = computed(() => {
  if (props.dataField.decoratingContentType) {
    return decoratingAndFieldKey(props.dataField);
  } else {
    return `${props.dataField.fieldContentType}${props.dataField.fieldContentId}`;
  }
});

watch(combinedId, async () => {
  fetchPhotos();
});

watch(dataLicensesChannelDataQueue, () => {
  const data = _.last(dataLicensesChannelDataQueue.value);

  if (data.dataFieldIds) {
    fetchPhotos();
  }
});

watch(dataSharingsChannelDataQueue, () => {
  const data = _.last(dataSharingsChannelDataQueue.value);

  if (data.dataFieldIds) {
    fetchPhotos();
  }
});

const unlockerStore = useUnlockerStore();
const { upgradeSuccessful } = storeToRefs(unlockerStore);

watch(completedUploadPayload, () => {
  if (completedUploadPayload.value) {
    console.log(completedUploadPayload.value);
    if (completedUploadPayload.value.stakeStatus === "prompt_to_subscribe") {
      subscribeInterceptor({
        successCallback: () => (completedUploadPayload.value = null),
        modalPayloadRef: modalPayload,
        upgradeSuccessfulRef: upgradeSuccessful,
        context: "originating",
      });
    } else {
      fetchPhotos();
    }
  }
});

onMounted(async () => fetchPhotos());

function patchPhotos(newPhotoField) {
  photos.value = _.unionBy([newPhotoField], photos.value, "localId");
}

async function refetch(photoFieldLocalId) {
  setTimeout(() => {
    api.get(`data_field_by_id/${photoFieldLocalId}`).then((json) => {
      patchPhotos(json.data);
    });
  }, 500);
}

async function fetchPhotos() {
  loaded.value = false;

  api
    .get(
      `content_photos/${props.dataField.fieldContentType}/${props.dataField.fieldContentId}`,
    )
    .then((json) => {
      photos.value = json.data;
      loaded.value = true;
      completedUploadPayload.value = null;
    });
}
async function uploadPhotos() {
  if (propertyDiagramSelectedPropertyId.value) {
    modalPayload.value = {
      size: "base",
      theme: "light",
      component: markRaw(FileDragDrop),
      props: {
        endpoint: `content_photos/${props.dataField.fieldContentType}/${props.dataField.fieldContentId}?property_id=${propertyDiagramSelectedPropertyId.value}`,
        fileTypes: "image/*",
        fileTypeLabels: "PNG, JPG, GIF",
        sizeLimit: 50,
        multiple: true,
        heading: "Add photos",
        marginBottom: true,
        flashMessage: "Photos saved successfully",
        afterAction: {
          type: "commit",
          actionName: "setUploadedFile",
        },
      },
    };
  }
}
</script>
