<template>
  <div>
    <div v-if="!urlPreviewFailed" class="w-full flex justify-center space-x-4">
      <a
        :href="file.link"
        target="_blank"
        class="inline-flex items-center justify-center font-medium text-sm text-gray-300"
        data-test="file-viewer-download-link"
      >
        <ArrowDownTrayIcon class="w-5 h-5 text-gray-400" />
        <span class="ml-2">View/Download in new tab</span>
      </a>
    </div>
    <div class="iframely-embed flex-1 mt-2 bg-gray-50">
      <a data-iframely-url :href="file.link"></a>
    </div>
    <FailedUrlPreviewEmptyState v-if="urlPreviewFailed" :url="file.link" />
  </div>
</template>

<script setup>
import { ArrowDownTrayIcon } from "@heroicons/vue/20/solid";
import { onMounted, ref } from "vue";
import FailedUrlPreviewEmptyState from "@/components/crowdsourcing/FailedUrlPreviewEmptyState.vue";

const iframely = window.iframely;
defineProps(["file"]);

const urlPreviewFailed = ref(false);

onMounted(() => {
  loadFrame();
});
async function loadFrame() {
  if (iframely) {
    setTimeout(() => {
      iframely.load();
    }, 500);

    iframely.on("cancel", function (url, parentNode) {
      parentNode.remove();
      urlPreviewFailed.value = true;
      // self.$store.dispatch(
      //   "flash",
      //   "File is temporarily unavailable. Please download or try again shortly."
      // );
    });
  }
}
</script>
