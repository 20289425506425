<template>
  <div
    :id="scrollId"
    :class="isCurrent ? 'border-4 border-orange-500' : 'border border-gray-400'"
    class="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200"
  >
    <div
      :class="isCurrent ? 'bg-orange-300' : 'bg-gray-200'"
      class="p-2 flex items-center justify-between text-gray-500"
    >
      <div class="flex items-center space-x-1">
        <button
          @click.prevent="toggleExpanded"
          type="button"
          class="h-5 w-5 inline-flex justify-center items-center text-sm text-gray-700 hover:text-gray-800"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clip-rule="evenodd"
            />
          </svg>
        </button>
        <div class="flex-grow">
          <a
            @click.prevent="toggleExpanded"
            href=""
            v-if="citationUpstreamField"
            class="p-1 flex items-center"
          >
            <div class="truncate text-gray-500">
              <div class="text-xs">
                The citation below is for the following data field:
              </div>
              <div class="text-sm font-medium">
                <template v-if="citationUpstreamField.fieldValue">
                  {{ citationUpstreamField.fieldValue }} ({{
                    citationUpstreamField.decoratingContentType
                  }}
                  {{ citationUpstreamField.fieldName }})
                </template>
                <template v-else>
                  {{ citationUpstreamField.decoratingContentType }}
                  {{ citationUpstreamField.fieldContentType }}
                </template>
              </div>
            </div>
          </a>
          <a
            @click.prevent="toggleExpanded"
            href=""
            v-else-if="secondaryDataName"
            class="flex items-center"
          >
            <div class="font-bold text-gray-700">
              {{ _.startCase(secondaryDataAlias) }}
            </div>
          </a>
          <div
            v-if="secondaryDataName === 'CrowdsourcedFile'"
            class="rounded-md bg-yellow-50 p-2"
          >
            <div class="flex">
              <div class="flex-shrink-0">
                <ExclamationTriangleIcon
                  class="h-5 w-5 text-yellow-400"
                  aria-hidden="true"
                />
              </div>
              <div class="ml-3 flex-1 flex">
                <p class="text-xs font-semibold text-yellow-800">
                  Please do not approve files that appear to represent IP theft.
                  Tower Hunt respects the intellectual property rights of others
                  and expects you to do the same.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="
            changeDirections.additionsCount > 0 ||
            changeDirections.removalsCount > 0
          "
          class="pl-2 flex items-center space-x-1"
        >
          <span
            v-if="changeDirections.additionsCount > 0"
            class="text-sm text-green-500"
            >{{
              pluralize("addition", changeDirections.additionsCount, true)
            }}</span
          >
          <span
            v-if="
              changeDirections.additionsCount > 0 &&
              changeDirections.removalsCount > 0
            "
            >&middot;</span
          >
          <span
            v-if="changeDirections.removalsCount > 0"
            class="text-sm text-red-500"
            >{{
              pluralize("removal", changeDirections.removalsCount, true)
            }}</span
          >
        </div>
      </div>
      <div
        v-if="adminApprovable || validationInput"
        class="flex-shrink-0 flex items-center space-x-2"
      >
        <CheckIcon
          v-if="validationInput === 'Accepted'"
          class="h-4 w-4 text-green-500"
        />
        <XMarkIcon
          v-if="validationInput === 'Rejected'"
          class="h-4 w-4 text-red-500"
        />
        <ChevronDoubleRightIcon
          v-if="validationInput === 'Skipped'"
          class="h-4 w-4 text-blue-500"
        />
        <div v-if="validationInput" :class="validationColor">
          {{ validationInput }}
        </div>
        <AdminValidationVoteSummary
          v-if="adminApprovable && validationSubmissions && !validationInput"
          :validation-submissions="validationSubmissions"
        />
      </div>
    </div>
    <div v-if="expanded && validationData" class="flex flex-col">
      <div class="px-2">
        <h2 class="text-gray-700 font-semibold text-sm mt-2">Differences</h2>
        <code-diff
          v-if="realEstateData"
          :old-string="oldString"
          :new-string="newString"
          :file-name="fieldName"
          :no-diff-line-feed="false"
          diff-style="char"
          language="plaintext"
          :output-format="outputFormat"
        />

        <EmbeddedVisualDiff
          v-for="field in filteredNewFields"
          :key="field.localId"
          :field-name="field.fieldContentType"
          :data-field="field"
          :after-fields="afterFields"
          :output-format="outputFormat"
          :investment-relations="investmentRelations"
          :change-investments="changeInvestments"
          :related-investments="relatedInvestments"
          :investment-valuations="investmentValuations"
          @set-investment-relation="setInvestmentRelation"
        />

        <CitationsList
          :after-fields="filteredNewFields"
          :before-fields="beforeFields"
          :output-format="outputFormat"
        />
      </div>

      <div class="bg-gray-200 border-t border-gray-400">
        <ValidationGuidance
          class="px-2 pt-2"
          :typed-test-index="scrollId"
          :meta-key="
            _.head(afterFields)?.metaKey || _.head(beforeFields)?.metaKey
          "
          :change-type="afterFields.length > 0 ? 'addition' : 'removal'"
        />

        <ValidationActions
          class="flex flex-col pt-2"
          :focal-change="isCurrent"
          :action-in-progress="actionInProgress"
          :admin-approvable="adminApprovable"
          :change-count="changeIds.length"
          :test-index="typedTestIndex"
          @approve="approveAll"
          @disapprove="disapproveAll"
          @accept="acceptAll"
          @reject="rejectAll"
          @skip="skipAll"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ExclamationTriangleIcon } from "@heroicons/vue/20/solid";
import ValidationActions from "@/components/crowdsourcing/ValidationActions.vue";
import api from "@/router/api";
import fieldDiffAttributes from "@/components/crowdsourcing/fieldDiffAttributes";
import { computed, onMounted, ref, watch } from "vue";
import { useUserStore } from "@/stores/user";
import { useModalStore } from "@/stores/modal";
import { useUnlockerStore } from "@/stores/unlocker";
import {
  CheckIcon,
  ChevronDoubleRightIcon,
  XMarkIcon,
} from "@heroicons/vue/20/solid";
import subscribeInterceptor from "@/components/crowdsourcing/subscribeInterceptor";
import { storeToRefs } from "pinia";
import _ from "lodash";
import dataFieldAliases from "@/assets/dataFieldAliases";
import CitationsList from "@/components/crowdsourcing/CitationsList.vue";
import EmbeddedVisualDiff from "./EmbeddedVisualDiff.vue";
import VueScrollTo from "vue-scrollto";
import ValidationGuidance from "./ValidationGuidance.vue";
import { currencyAmount } from "@/assets/numberHelpers";
import moment from "moment";
import pluralize from "pluralize";
import AdminValidationVoteSummary from "@/components/crowdsourcing/AdminValidationVoteSummary.vue";
import selfLabel from "./selfLabelDataField";

const props = defineProps([
  "validationData",
  "changeGroupId",
  "apiLookupKey",
  "changeIds",
  "outputFormat",
  "investmentRelations",
  "changeInvestments",
  "relatedInvestments",
  "investmentValuations",
  "testIndex",
  "groupIndex",
  "changeIndex",
  "nextUp",
  "validatedChangeIds",
]);
const emit = defineEmits([
  "validation-submitted",
  "viewed",
  "set-investment-relation",
]);
const expanded = ref(false);
const viewed = ref(false);
const changeDirections = ref({
  additionsCount: 0,
  removalsCount: 0,
});
const validationSubmissions = ref([]);
const validationInput = ref(null);
const actionInProgress = ref(false);
const validationContextMetaData = ref(null);
const userStore = useUserStore();
const { isAdmin } = storeToRefs(userStore);

const citationUpstreamField = ref(null);
const validationColor = computed(() => {
  switch (validationInput.value) {
    case "Accepted":
      return "text-green-500";
    case "Rejected":
      return "text-red-500";
    case "Skipped":
      return "text-blue-500";
    default:
      return "";
  }
});
const isCurrent = computed(() => {
  if (
    _.isNumber(props.nextUp.groupIndex) &&
    _.isNumber(props.nextUp.changeIndex)
  ) {
    return (
      props.nextUp.groupIndex === props.groupIndex &&
      props.nextUp.changeIndex === props.changeIndex
    );
  } else {
    return false;
  }
});
const isNext = computed(() => {
  if (
    _.isNumber(props.nextUp.groupIndex) &&
    _.isNumber(props.nextUp.changeIndex)
  ) {
    return props.nextUp.groupIndex === props.groupIndex;
  } else {
    return false;
  }
});
const scrollId = computed(
  () => `group-${props.groupIndex}-change-${props.changeIndex}`,
);
const typedTestIndex = computed(() => `has-many-${props.testIndex}`);
const adminApprovable = computed(() => {
  return isAdmin.value;
});
const keyData = computed(() => {
  const [keyType, ...rest] = _.split(props.apiLookupKey, "-");

  switch (keyType) {
    case "joiningContentFieldContent": {
      const [joiningType, joiningId, fieldContentType] = rest;

      return {
        joiningType,
        joiningId,
        fieldContentType,
        name: `${joiningType} ${joiningId}`,
        noIdName: `${joiningType}`,
        secondaryName: `${fieldContentType}`,
      };
    }
    case "decoratingContentFieldContent": {
      const [decoratingType, decoratingId, fieldContentType] = rest;

      return {
        decoratingType,
        decoratingId,
        fieldContentType,
        name: `${decoratingType} ${decoratingId}`,
        noIdName: `${decoratingType}`,
        secondaryName: `${fieldContentType}`,
      };
    }
    case "decoratingContentFieldContentWithSubType": {
      const [
        decoratingType,
        decoratingId,
        fieldContentType,
        fieldContentSubType,
      ] = rest;

      return {
        decoratingType,
        decoratingId,
        fieldContentType,
        name: `${decoratingType} ${decoratingId}`,
        noIdName: `${decoratingType}`,
        secondaryName: `${fieldContentType} ${fieldContentSubType}`,
      };
    }
    default:
      return null;
  }
});
const realEstateData = computed(() => !validationMetadata.value);
const validationMetadata = computed(() => {
  return _.get(keyData.value, "decoratingType") === "DataFieldChangeGroup";
});
const secondaryDataName = computed(() => {
  if (_.get(keyData.value, "decoratingType") === "DataFieldChangeGroup") {
    return "Citation was attached";
  } else {
    return keyData.value.secondaryName;
  }
});
const secondaryDataAlias = computed(() => {
  if (secondaryDataName.value === "Citation was attached") {
    return secondaryDataName.value;
  } else {
    return (
      _.get(dataFieldAliases, `${secondaryDataName.value}.plural`) ||
      `${secondaryDataName.value}`
    );
  }
});
const isTopLevel = computed(() => {
  return (
    `${_.get(topLevelField.value, "fieldContentType")} ${_.get(
      topLevelField.value,
      "fieldContentId",
    )}` === keyData.value.name
  );
});
const fieldName = computed(() => {
  return `${keyData.value.name} ${keyData.value.secondaryName}`;
});
const oldString = computed(() => {
  if (isNew.value) {
    return "";
  } else {
    const sorted = orderFields(beforeFields.value);

    return sorted
      .map((dataField) => {
        return modelToString(dataField);
      })
      .join("\n");
  }
});
const investmentRelated = computed(() => {
  const checkField = _.head(newFields.value);

  if (checkField) {
    return (
      _.includes(
        [
          "InvestmentGroup",
          "Investment",
          "Valuation",
          "OwnershipInterest",
          "CompanyInvolvement",
        ],
        checkField.decoratingContentType,
      ) &&
      !_.includes(
        [
          "CitationChangeGroup",
          "ContentPolygon",
          "GeographyRegion",
          "ContentLocation",
          "CrowdsourcedFile",
          "url",
        ],
        checkField.fieldContentType,
      )
    );
  } else {
    return false;
  }
});
const newFields = computed(() => {
  if (afterFields.value?.length > 0) {
    return _.differenceBy(afterFields.value, beforeFields.value, "localId");
  } else {
    return beforeFields.value;
  }
});
const filteredNewFields = computed(() => {
  if (investmentRelated.value) {
    return _.slice(newFields.value, 0, 1);
  } else {
    return newFields.value;
  }
});
const afterFields = computed(() => _.get(props.validationData, "after", []));
const beforeFields = computed(() => _.get(props.validationData, "before", []));
const newString = computed(() => {
  const sorted = orderFields(afterFields.value);

  return sorted
    .map((dataField) => {
      return modelToString(dataField);
    })
    .join("\n");
});
const isNew = computed(() => {
  return !_.get(props.validationData, "before");
});
const topLevelField = computed(() => {
  return _.get(props.validationData, "topLevelContent");
});

watch(
  () => props.validatedChangeIds,
  () => {
    if (_.intersection(props.validatedChangeIds, props.changeIds).length > 0) {
      expanded.value = false;
    }
  },
  { deep: true },
);

watch(isCurrent, () => {
  if (isCurrent.value) {
    if (props.nextUp.changeIndex === props.changeIndex) {
      debouncedScroll();
    }
  }
});

watch(isNext, () => {
  if (isNext.value) {
    expand();
    if (props.nextUp.changeIndex === props.changeIndex) {
      debouncedScroll();
    }
  }
});

onMounted(() => {
  if (isNext.value) expand();
  if (keyData.value && !isTopLevel.value) {
    fetchValidationContext();
  }
  fetchContext();
  fetchAdditionsRemovals();

  if (adminApprovable.value) {
    fetchValidationSubmissions();
  }
});

const debouncedScroll = _.debounce(
  function () {
    if (isCurrent.value) {
      VueScrollTo.scrollTo(`#${scrollId.value}`, 500, {
        container: "#changes-container",
        offset: -80,
      });
    }
  },
  500,
  { leading: true },
);

function toggleExpanded() {
  if (expanded.value) {
    expanded.value = false;
  } else {
    expand();
  }
}

function expand() {
  expanded.value = true;
  if (!viewed.value) {
    emit("viewed", {
      changeIds: props.changeIds,
    });
  }
  viewed.value = true;
}

async function fetchContext() {
  api
    .get(`data_field_change_group_context/${_.head(props.changeIds)}`)
    .then((json) => {
      citationUpstreamField.value = json.data.citationUpstreamField;
    });
}

function orderFields(fields) {
  switch (keyData.value.fieldContentType) {
    case "LandCoveringLevel":
      return _.orderBy(
        fields,
        [
          function (f) {
            return f.fieldContent.verticalLevel;
          },
        ],
        ["desc"],
      );
    default:
      return _.orderBy(
        fields,
        [
          function (f) {
            return f.localId;
          },
        ],
        ["asc"],
      );
  }
}
function modelToString(dataField) {
  if (dataField) {
    const contentType = dataField.fieldContentType;
    let attributesArray = _.get(fieldDiffAttributes, contentType);

    if (!attributesArray) {
      console.log("hook up attributes for", contentType);

      // attributesArray = [
      //   {
      //     order: 1,
      //     name: "Type",
      //     value: "recordType",
      //   },
      // ];
    }

    let associatedContent = associatedContentFor(dataField);
    let associatedString = "";

    if (associatedContent) {
      associatedString = `${_.startCase(
        _.get(
          dataFieldAliases,
          `${associatedContent.fieldContentType}.singular`,
        ),
      )}: ${modelToString(associatedContent)}`;
    }

    if (attributesArray) {
      const sortedArray = _.sortBy(attributesArray, ["order"]);

      const primaryString = _.compact(
        _.map(sortedArray, function (attrObj) {
          const label = attrObj.name;
          let value = null;
          if (
            _.includes(["date", "retiredAt", "openedAt"], attrObj.value) &&
            _.get(dataField.fieldContent, attrObj.value)
          ) {
            value = moment(_.get(dataField.fieldContent, attrObj.value)).format(
              "MMMM Do, YYYY",
            );
          } else if (
            _.includes(["value"], attrObj.value) &&
            _.get(dataField.fieldContent, attrObj.value)
          ) {
            return `$${currencyAmount(
              _.get(dataField.fieldContent, attrObj.value),
              0,
            )}`;
          } else if (
            _.includes(["buildingBlocks"], attrObj.value) &&
            _.get(dataField.fieldContent, attrObj.value)
          ) {
            const blocks = _.get(dataField.fieldContent, attrObj.value);
            value = blocks.map(({ date, dateType, inputType, inputValue }) => {
              const formattedDate =
                dateType === "Actual"
                  ? moment(date).format("MM/DD/YYYY")
                  : `Month ${date}`;
              const formattedVal = `$${currencyAmount(inputValue, 2)}`;

              return `${formattedDate}: ${formattedVal} (${inputType})\n`;
            });
          } else {
            value =
              _.get(dataField.fieldContent, attrObj.value) ||
              _.get(dataField, attrObj.value);
          }
          const emptyValue =
            _.isArray(value) || _.isObject(value)
              ? _.isEmpty(value)
              : _.isNil(value);

          if (emptyValue) {
            return null;
          } else if (_.includes(["buildingBlocks"], attrObj.value)) {
            return `${value.join("")}`;
          } else {
            const formattedValue = _.isArray(value) ? value.join(", ") : value;

            return `${label}: ${formattedValue}`;
          }
        }),
      ).join("; ");

      if (associatedString !== "") {
        return `${primaryString} - ${associatedString}`;
      } else {
        return primaryString;
      }
    } else {
      return selfLabel(dataField);
    }
  } else {
    return null;
  }
}
function associatedContentFor(dataField) {
  if (dataField.fieldContentType === "PropertyDiagramVisualOrdering") {
    const fieldContent = dataField.fieldContent;

    return (
      _.get(fieldContent, "FloorArea") ||
      _.get(fieldContent, "LandCovering") ||
      _.get(fieldContent, "PropertyRight")
    );
  } else if (
    dataField.fieldContentType === "FloorAreaDownwardVerticalOpening"
  ) {
    const fieldContent = dataField.fieldContent;

    return _.get(fieldContent, "FloorArea");
  } else {
    return null;
  }
}
function fetchValidationContext() {
  const [keyType] = _.split(props.apiLookupKey, "-");
  let contentType;
  let contentId;
  switch (keyType) {
    case "joiningContentFieldContent": {
      contentType = keyData.value.joiningType;
      contentId = keyData.value.joiningId;
      break;
    }
    case "decoratingContentFieldContent":
    case "decoratingContentFieldContentWithSubType": {
      contentType = keyData.value.decoratingType;
      contentId = keyData.value.decoratingId;
      break;
    }
  }
  const fetchable =
    contentType &&
    _.trim(contentType) !== "" &&
    contentId &&
    _.trim(contentId) !== "";

  if (fetchable) {
    api.get(`validation_contexts/${contentType}/${contentId}`).then((json) => {
      validationContextMetaData.value = json.data;
    });
  }
}

const modalStore = useModalStore();
const { modalPayload } = storeToRefs(modalStore);
const unlockerStore = useUnlockerStore();
const { upgradeSuccessful } = storeToRefs(unlockerStore);

function acceptAll() {
  const payload = {
    changeIds: props.changeIds.map((id) => {
      return { id };
    }),
  };
  const successCallback = () => {
    expanded.value = false;
    validationInput.value = "Accepted";
    emit("validation-submitted", {
      changeIds: props.changeIds,
      validationInput: validationInput.value,
    });
    actionInProgress.value = false;
  };

  actionInProgress.value = true;

  subscribeInterceptor({
    apiRequestFunc: () => api.post(`validation_accept_changes`, payload),
    successCallback,
    modalPayloadRef: modalPayload,
    upgradeSuccessfulRef: upgradeSuccessful,
    afterSubscribe: "apiRequest",
    context: "validating",
  });
}
function approveAll() {
  if (adminApprovable.value) {
    const payload = {
      changeIds: props.changeIds.map((id) => {
        return { id };
      }),
      adminOverride: "override",
    };

    actionInProgress.value = true;

    api.post(`validation_accept_changes`, payload).then(() => {
      expanded.value = false;
      validationInput.value = "Accepted";
      emit("validation-submitted", {
        changeIds: props.changeIds,
        validationInput: validationInput.value,
      });
      actionInProgress.value = false;
    });
  }
}
function rejectAll() {
  const payload = {
    changeIds: props.changeIds.map((id) => {
      return { id };
    }),
  };
  const successCallback = () => {
    expanded.value = false;
    validationInput.value = "Rejected";
    emit("validation-submitted", {
      changeIds: props.changeIds,
      validationInput: validationInput.value,
    });
    actionInProgress.value = false;
  };

  actionInProgress.value = true;

  subscribeInterceptor({
    apiRequestFunc: () => api.post(`validation_reject_changes`, payload),
    successCallback,
    modalPayloadRef: modalPayload,
    upgradeSuccessfulRef: upgradeSuccessful,
    afterSubscribe: "apiRequest",
    context: "validating",
  });
}
function disapproveAll() {
  if (adminApprovable.value) {
    const payload = {
      changeIds: props.changeIds.map((id) => {
        return { id };
      }),
      adminOverride: "override",
    };

    actionInProgress.value = true;

    api.post(`validation_reject_changes`, payload).then(() => {
      expanded.value = false;
      validationInput.value = "Rejected";
      emit("validation-submitted", {
        changeIds: props.changeIds,
        validationInput: validationInput.value,
      });
      actionInProgress.value = false;
    });
  }
}
function skipAll() {
  const payload = {
    changeIds: props.changeIds.map((id) => {
      return { id };
    }),
  };

  actionInProgress.value = true;

  api.post(`validation_skip_changes`, payload).then(() => {
    expanded.value = false;
    validationInput.value = "Skipped";
    emit("validation-submitted", {
      changeIds: props.changeIds,
      validationInput: validationInput.value,
    });
    actionInProgress.value = false;
  });
}

async function fetchValidationSubmissions() {
  const payload = {
    changeIds: props.changeIds,
  };

  const response = await api.post(
    `data_field_change_validation_submissions`,
    payload,
  );

  if (response?.data) {
    validationSubmissions.value = response.data;
  }
}

async function fetchAdditionsRemovals() {
  const payload = {
    changeIds: props.changeIds,
  };

  const response = await api.post(
    `data_field_change_direction_counts`,
    payload,
  );

  if (response?.data) {
    changeDirections.value = response.data;
  }
}

function setInvestmentRelation(payload) {
  emit("set-investment-relation", payload);
}
</script>
