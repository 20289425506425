<template>
  <div class="h-full rounded-lg overflow-hidden">
    <CardBranding :card="card" />
    <div class="absolute inset-0">
      <div
        class="absolute inset-0 bg-gradient-to-r from-amber-600 to-yellow-400 mix-blend-multiply"
      />
    </div>
    <div class="h-full relative px-4 py-16 flex flex-col justify-center">
      <h1 class="text-center text-4xl font-bold tracking-tight">
        <span class="block text-white">{{ card.contentsCount }} Safezone</span>
        <span class="block text-yellow-200">{{
          pluralize("Datapoint", card.contentsCount)
        }}</span>
      </h1>
      <p class="mx-auto mt-6 max-w-lg text-center text-lg text-yellow-200">
        Shared by {{ sharedByName }}
      </p>
      <p class="mt-6 flex items-center justify-center space-x-2">
        <LockOpenIcon class="h-5 w-5 text-yellow-100" />
        <span class="font-medium text-yellow-300"
          >Unlock card for details.</span
        >
      </p>
      <div class="mx-auto mt-10 max-w-sm flex justify-center">
        <div class="space-y-4">
          <a
            href=""
            @click.prevent="viewSafezone"
            class="flex items-center justify-center rounded-md border border-transparent bg-white px-4 py-3 text-base font-medium text-yellow-700 shadow-sm hover:bg-yellow-50 sm:px-8"
            >View Safezone</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import CardBranding from "@/components/collectible-cards/CardBranding.vue";
import SharedNoteCard from "@/components/collectible-cards/categories/safezone/SharedNoteCard.vue";
import { LockOpenIcon } from "@heroicons/vue/20/solid";
import { computed, onMounted, markRaw, watch } from "vue";
import pluralize from "pluralize";
import { useRoute, useRouter } from "vue-router";
import _ from "lodash";

const props = defineProps([
  "card",
  "cardIndex",
  "stackIndex",
  "completedCardIds",
  "displayBack",
]);
const emit = defineEmits(["set-card-back"]);

const sharedByName = computed(() =>
  props.card.taskName.replace("Safezone data shared by ", "")
);

const router = useRouter();
const route = useRoute();

watch(
  () => props.displayBack,
  () => {
    checkForCardBack();
  }
);

onMounted(() => {
  checkForCardBack();
});

function viewSafezone() {
  router.push({
    name: route.name,
    query: {
      ...route.query,
      horizontalTab: "Me",
      verticalTab: "Safezone",
    },
  });
}

function checkForCardBack() {
  const combinedKeyTokens = _.split(props.card.combinedKey, "-");

  if (props.displayBack && _.last(combinedKeyTokens) === "ContentNote") {
    emit("set-card-back", markRaw(SharedNoteCard));
  }
}
</script>
