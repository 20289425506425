<template>
  <div
    v-if="!displayBack || cardIndex > 0"
    class="h-full rounded-lg overflow-hidden"
  >
    <CardBranding :card="card" style="z-index: 9999" />
    <div class="absolute inset-0">
      <div
        :class="`absolute inset-0 bg-gradient-to-r ${cardTypeMetadata.fromColor} ${cardTypeMetadata.toColor} mix-blend-multiply`"
      />
    </div>
    <div class="h-full relative flex flex-col">
      <div
        v-if="dataFieldId"
        :id="`location-map-${card.id}-${dataFieldId}-${context}`"
        class="w-full"
        style="height: 237.5px"
      />
      <div
        v-else
        class="w-full"
        :class="cardTypeMetadata.placeholderColor"
        style="height: 237.5px"
      />
      <div
        class="mx-auto mt-6 max-w-sm flex flex-col align-center justify-center"
      >
        <h1
          class="mt-4 flex items-center justify-center text-center text-2xl font-bold tracking-tight space-x-1"
        >
          <span :class="`${cardTypeMetadata.headline1TextColor}`">{{
            cardTypeMetadata.headline1
          }}</span>
          <span :class="`${cardTypeMetadata.headline2TextColor}`">{{
            cardTypeMetadata.headline2
          }}</span>
        </h1>
        <p
          v-if="dealActionAlias"
          :class="`mt-4 mx-auto max-w-lg text-center font-medium text-lg ${cardTypeMetadata.secondaryTextColor}`"
        >
          {{ dealActionAlias }}
        </p>
        <p class="mt-6 flex items-center justify-center space-x-2">
          <LockOpenIcon :class="`h-5 w-5 ${cardTypeMetadata.lockIconColor}`" />
          <span :class="`font-medium ${cardTypeMetadata.lockTextColor}`"
            >Unlock card for details.</span
          >
        </p>
        <a
          v-if="dataFieldId && cardTypeMetadata.diagrammable && isDesktop"
          href=""
          @click.prevent="viewDiagram"
          :class="`mt-4 flex items-center justify-center rounded-md ${cardTypeMetadata.buttonColor} px-3 py-2 text-base font-medium shadow-sm sm:px-8`"
          >View Property</a
        >
      </div>
    </div>
  </div>
</template>

<script setup>
/* global L */
import mapStyles from "@/components/maps/mapStyles";
import CardBranding from "@/components/collectible-cards/CardBranding.vue";
import { usePropertyDiagramStore } from "@/stores/propertyDiagram";
import { useMainMapStore } from "@/stores/mainMap";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { useUserStore } from "@/stores/user";
import { storeToRefs } from "pinia";
import api from "@/router/api";
import { ref, computed, watch, onMounted, nextTick, markRaw } from "vue";
import _ from "lodash";
import { LockOpenIcon } from "@heroicons/vue/20/solid";
import UnlockedInvestmentCard from "@/components/collectible-cards/categories/news/UnlockedInvestmentCard.vue";
import UnlockedLeasingCard from "@/components/collectible-cards/categories/news/UnlockedLeasingCard.vue";
import UnlockedDevelopmentCard from "@/components/collectible-cards/categories/news/UnlockedDevelopmentCard.vue";

const props = defineProps([
  "card",
  "cardIndex",
  "stackIndex",
  "completedCardIds",
  "displayBack",
  "generic",
  "context",
  "timelineOverrideField",
]);
const emit = defineEmits(["set-card-back"]);

const propertyDiagramStore = usePropertyDiagramStore();
const mapStore = useMainMapStore();
const { newOriginOverride } = storeToRefs(mapStore);
const layoutStore = useWorkspaceLayoutStore();
const { isDesktop } = storeToRefs(layoutStore);
const userStore = useUserStore();
const { signedIn } = storeToRefs(userStore);

const map = ref(null);
const baseLayers = ref({
  Base: L.gridLayer.googleMutant({
    type: "roadmap",
    styles: mapStyles.styles,
  }),
});

const dataFieldId = ref(null);
const concealedCenter = ref({
  lat: null,
  lng: null,
});
const dealActionAlias = ref(null);
const cardTypeMetadata = computed(() => {
  switch (props.card.taskName) {
    case "Development project alert":
      return {
        placeholderColor: "bg-orange-500",
        fromColor: "from-orange-500",
        toColor: "to-pink-600",
        headline1TextColor: "text-white",
        headline2TextColor: "text-pink-200",
        secondaryTextColor: "text-pink-200",
        lockIconColor: "text-pink-100",
        lockTextColor: "text-pink-300",
        buttonColor:
          "border border-transparent bg-white text-pink-700 hover:bg-pink-50",
        headline1: "New Development",
        headline2: "Alert",
        generalizedCircleColor: "#f97316",
        diagrammable: false,
        cardBack: markRaw(UnlockedDevelopmentCard),
      };
    case "Deal alert":
      return {
        placeholderColor: "bg-teal-500",
        fromColor: "from-teal-500",
        toColor: "to-cyan-600",
        headline1TextColor: "text-white",
        headline2TextColor: "text-cyan-200",
        secondaryTextColor: "text-cyan-200",
        lockIconColor: "text-cyan-100",
        lockTextColor: "text-cyan-300",
        buttonColor:
          "border border-transparent bg-white text-cyan-700 hover:bg-cyan-50",
        headline1: "Deal",
        headline2: "Alert",
        generalizedCircleColor: "#14b8a6",
        diagrammable: false,
        cardBack: markRaw(UnlockedInvestmentCard),
      };
    case "Leasing alert":
    default:
      return {
        placeholderColor: "bg-blue-500",
        fromColor: "from-blue-500",
        toColor: "to-sky-600",
        headline1TextColor: "text-white",
        headline2TextColor: "text-sky-200",
        secondaryTextColor: "text-sky-200",
        lockIconColor: "text-sky-100",
        lockTextColor: "text-sky-300",
        buttonColor:
          "border border-transparent bg-white text-sky-700 hover:bg-sky-50",
        headline1: "Leasing",
        headline2: "Alert",
        generalizedCircleColor: "#3b82f6",
        diagrammable: false,
        cardBack: markRaw(UnlockedLeasingCard),
      };
  }
});
const isTopCard = computed(
  () =>
    props.stackIndex === props.cardIndex &&
    !_.includes(props.completedCardIds, props.card.id),
);

watch(
  () => props.stackIndex,
  () => {
    fetchTopCardContent();
  },
);

watch(
  () => props.displayBack,
  () => {
    checkForCardBack();
  },
);

onMounted(() => {
  var tempTimelineField = props.timelineOverrideField;
  fetchTopCardContent();
  checkForCardBack();
});

async function fetchTopCardContent() {
  if (isTopCard.value) {
    const lookupId =
      signedIn.value && !props.generic
        ? props.card.collectibleCardId
        : props.card.id;
    const cardContentsResponse = await api.get(
      `collectible_cards/${lookupId}/collectible_card_contents?template=concealed_location`,
    );
    dataFieldId.value = cardContentsResponse.data.dataFieldId;
    concealedCenter.value.lat = cardContentsResponse.data.concealedLatitude;
    concealedCenter.value.lng = cardContentsResponse.data.concealedLongitude;
    dealActionAlias.value = _.get(
      cardContentsResponse.data,
      "dealAction",
      null,
    );

    await nextTick();
    mountMap();
  }
}

function mountMap() {
  if (!props.displayBack && !map.value) {
    let blockEl = document.getElementById(
      `location-map-${props.card.id}-${dataFieldId.value}-${props.context}`,
    );

    if (!blockEl) return;

    map.value = L.map(
      `location-map-${props.card.id}-${dataFieldId.value}-${props.context}`,
      {
        scrollWheelZoom: false,
        zoomControl: false,
        dragging: false,
        boxZoom: false,
        doubleClickZoom: false,
        zoomAnimation: false,
        fadeAnimation: true,
        markerZoomAnimation: true,
      },
    ).setView([concealedCenter.value.lat, concealedCenter.value.lng], 12);

    baseLayers.value["Base"].addTo(map.value);

    generalizedCircle();
  }
}

function generalizedCircle() {
  L.circle([concealedCenter.value.lat, concealedCenter.value.lng], {
    radius: 1500,
    color: cardTypeMetadata.value.generalizedCircleColor,
    interactive: false,
  }).addTo(map.value);
}

function viewDiagram() {
  api.get(`data_field_top_level_content/${dataFieldId.value}`).then((json) => {
    let propertyId = null;
    if (json.data?.fieldContentType === "Property") {
      propertyId = json.data?.fieldContentId;
    } else if (
      json.data?.fieldContent?.propertyId ||
      json.data?.fieldContent?.topLevelPropertyId
    ) {
      propertyId =
        json.data?.fieldContent?.propertyId ||
        json.data?.fieldContent?.topLevelPropertyId;
    }
    newOriginOverride.value = true;
    propertyDiagramStore.navigateToDiagram(propertyId, true);
  });
}
function checkForCardBack() {
  if (
    props.displayBack &&
    !!cardTypeMetadata.value.cardBack &&
    isTopCard.value
  ) {
    emit("set-card-back", cardTypeMetadata.value.cardBack);
  }
}
</script>
