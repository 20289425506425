<template>
  <div class="h-full flex flex-col">
    <div class="p-2 bg-gray-50 flex items-center justify-around">
      <a @click.prevent="cancel" href="" class="text-sm text-indigo-500">
        Cancel
      </a>
      <h3
        class="flex min-w-0 flex-1 items-center justify-center font-medium text-gray-900"
      >
        Prioritize Reminders
      </h3>
      <a
        @click.prevent="applyPriority"
        href=""
        class="flex items-center text-sm text-indigo-500 font-medium"
      >
        Apply
      </a>
    </div>
    <div class="p-2 mt-2 overflow-y-auto bg-white shadow rounded-md">
      <ul role="list" class="divide-y divide-gray-200">
        <li v-for="option in priorities" :key="option.apiValue">
          <a
            @click.prevent="priority = option"
            href=""
            class="block hover:bg-gray-50"
          >
            <div class="flex items-center p-2">
              <div class="flex min-w-0 flex-1 items-center">
                <span
                  class="w-5 text-indigo-500 text-center font-medium text-lg"
                  >{{ display(option) }}</span
                >
                <div class="min-w-0 flex-1 px-4">
                  <p class="truncate text-gray-700 text-sm font-medium">
                    {{ option.name }}
                  </p>
                </div>
              </div>
              <CheckIcon
                v-if="priority.apiValue === option.apiValue"
                class="h-5 w-5 text-indigo-500"
                aria-hidden="true"
              />
            </div>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import { CheckIcon } from "@heroicons/vue/20/solid";
import { useTasksStore } from "@/stores/tasks";
import { useReminderStore } from "@/stores/reminder";

import { storeToRefs } from "pinia";

const tasksStore = useTasksStore();
const { taskBatchPriority } = storeToRefs(tasksStore);
const reminderStore = useReminderStore();
const { priority, priorities } = storeToRefs(reminderStore);

function display(priority) {
  switch (priority.apiValue) {
    case "low":
      return "!";
    case "medium":
      return "!!";
    case "high":
      return "!!!";
    default:
      return "";
  }
}
function cancel() {
  taskBatchPriority.value = false;
  reminderStore.reset();
}
function applyPriority() {
  const priorityPayload = {
    priority: priority.value.apiValue,
  };
  tasksStore.batchPriorityUpdate(priorityPayload).then(() => cancel());
}
</script>
