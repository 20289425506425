<template>
  <div v-if="dataFields.pagy" class="mt-4 flex flex-col">
    <div v-if="dataFields.data.length > 0" class="-my-2 -mx-4 overflow-x-auto">
      <div class="inline-block min-w-full py-2 align-middle px-4">
        <div
          class="relative overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-lg"
        >
          <!-- Selected row actions, only show when rows are selected. -->
          <div
            v-if="selectionLength > 0"
            class="absolute top-0 left-12 flex h-12 items-center space-x-3 bg-gray-50"
          >
            <button
              v-if="activeEasyDataInputPayloadItem?.id === payloadItem.id"
              @click="bulkExcludeConfirmation"
              :disabled="actionInProgress"
              type="button"
              class="inline-flex items-center rounded border border-transparent bg-rose-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-rose-700 focus:outline-none focus:ring-2 focus:ring-rose-500 focus:ring-offset-2"
              data-test="easy-data-input-bulk-exclude-button"
            >
              <PulseLoader
                v-if="actionInProgress"
                :loading="true"
                size="4px"
                color="#f9fafb"
              />
              <span v-else>Exclude</span>
            </button>
          </div>

          <table class="min-w-full table-fixed divide-y divide-gray-300">
            <thead class="bg-gray-50">
              <tr>
                <th scope="col" class="relative w-12 px-4">
                  <input
                    @click="toggleOverall"
                    id="payload-items-overall"
                    ref="payloadItemsOverall"
                    type="checkbox"
                    class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                    data-test="payload-items-toggle-overall-selection-button"
                  />
                </th>
                <th
                  scope="col"
                  class="py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
                >
                  Belongs To
                </th>
                <th
                  scope="col"
                  class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Datapoint
                </th>
                <th
                  scope="col"
                  class="pl-3 pr-4 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Economics
                </th>
              </tr>
            </thead>
            <tbody
              v-if="rowsDisplayable"
              class="divide-y divide-gray-200 bg-white"
            >
              <SelectedPayloadItemTableRow
                v-for="(dataField, index) in dataFields.data"
                :key="`${dataField.localId}-${index}`"
                :field="dataField"
                :selected="selected(dataField.localId)"
                :index="index"
                :data-test="`payload-item-table-row-${index}`"
                @unselect="unselect(dataField.localId)"
                @select="select(dataField.localId)"
                @select-decorating="selectDecorating"
                @open-sourced="fetchFields"
                @dismissed="drop(dataField)"
              />
            </tbody>
            <PaginationFooter
              v-if="dataFields.pagy.prev || dataFields.pagy.next"
              :pagination-meta="dataFields.pagy"
              :table-meta="{ colspan: 6 }"
              @previous="fetchFields"
              @next="fetchFields"
              @page="fetchFields"
              @infinite-fetch="loadFields"
            />
          </table>
        </div>
      </div>
    </div>
    <div v-else class="text-center" data-test="my-contributions-empty-state">
      <BoltIcon class="mt-4 mx-auto h-10 w-10 text-gray-400" />
      <h3 class="mt-2 text-sm font-medium text-gray-900">
        No Linked Datapoints
      </h3>
      <p class="mt-1 text-sm text-gray-500">
        Datapoints linked to this job will appear here.
      </p>
    </div>
  </div>
</template>

<script setup>
import SelectedPayloadItemTableRow from "@/components/crowdsourcing/contributions/SelectedPayloadItemTableRow.vue";
import PaginationFooter from "@/components/PaginationFooter.vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { BoltIcon } from "@heroicons/vue/24/outline";
import { useModalStore } from "@/stores/modal";
import { usePayloadItemDataFieldChannelStore } from "@/stores/dataInputPayloadItemDataFieldsChannel";
import { ref, computed, watch, onMounted, onBeforeUnmount } from "vue";
import { useUserStore } from "@/stores/user";
import { storeToRefs } from "pinia";
import api from "@/router/api";
import createChannel from "@/channels/channel";
import _ from "lodash";

const props = defineProps(["payloadItem"]);

const userStore = useUserStore();
const { activeEasyDataInputPayloadItem } = storeToRefs(userStore);
const modalStore = useModalStore();
const { confirmationPayload } = storeToRefs(modalStore);
const payloadItemDataFieldsChannelStore = usePayloadItemDataFieldChannelStore();
const { PayloadItemDataFieldChannel, payloadItemDataFieldsChannelDataQueue } =
  storeToRefs(payloadItemDataFieldsChannelStore);

const dataFields = ref({
  data: [],
  pagy: null,
});
const activeSelection = ref([]);
const rowsDisplayable = ref(false);
const actionInProgress = ref(false);

const selectionLength = computed(() => {
  return activeSelection.value.length;
});
const allSelected = computed(() => {
  return (
    dataFields.value.pagy &&
    dataFields.value.data.length === selectionLength.value
  );
});
const indeterminate = computed(() => {
  return selectionLength.value > 0 && !allSelected.value;
});

watch(selectionLength, () => {
  updateOverallCheckbox();
});

watch(payloadItemDataFieldsChannelDataQueue, async () => {
  debouncedFetch();
});

onMounted(() => {
  subscribeToChannel();
  fetchFields();
});

onBeforeUnmount(() => {
  if (PayloadItemDataFieldChannel.value) {
    PayloadItemDataFieldChannel.value.unsubscribe();
    PayloadItemDataFieldChannel.value = null;
  }
});

const debouncedFetch = _.debounce(async function () {
  const data = _.last(payloadItemDataFieldsChannelDataQueue.value);
  const fieldId = data.fieldId;
  const exclusion = data.exclusion;

  if (fieldId) {
    const response = await api.get(`data_field_by_id/${fieldId}`);

    if (response?.data) {
      dataFields.value.data = _.unionBy(
        [response.data],
        dataFields.value.data,
        "localId",
      );
    }
  } else if (exclusion && !activeEasyDataInputPayloadItem.value) {
    fetchFields();
  }
}, 5000);

function subscribeToChannel() {
  if (!PayloadItemDataFieldChannel.value) {
    PayloadItemDataFieldChannel.value = createChannel(
      {
        channel: "DataInputPayloadItemDataFieldsChannel",
        itemId: props.payloadItem.id,
      },
      {
        connected() {},
        received(data) {
          payloadItemDataFieldsChannelStore.pushAndTrim(data);
        },
      },
    );
  }
}

async function fetchFields(paginationUrl = null) {
  dataFields.value = ref({
    data: [],
    pagy: null,
  });
  activeSelection.value = [];
  rowsDisplayable.value = false;

  api
    .get(paginationUrl || `payload_item_data_fields/${props.payloadItem.id}`)
    .then((json) => {
      dataFields.value = json.data;
      rowsDisplayable.value = true;
    });
}

function cleanUrl(url) {
  return url.replace("/api/v1/", "");
}
const loadFields = async ($state) => {
  if (dataFields.value.pagy?.next) {
    const endpoint = cleanUrl(dataFields.value.pagy?.next_url);
    try {
      api.get(endpoint).then((json) => {
        const { data, pagy } = json.data;

        dataFields.value.data = _.concat(dataFields.value.data, data);
        dataFields.value.pagy = pagy;
        if (data.length < 10) $state.complete();
        else {
          $state.loaded();
        }
      });
    } catch (error) {
      $state.error();
    }
  } else {
    $state.complete();
  }
};

function drop(attribution) {
  const newChanges = _.differenceBy(dataFields.value.data, [attribution], "id");

  dataFields.value.data = newChanges;
}
function selected(id) {
  return _.includes(activeSelection.value, id);
}
function unselect(id) {
  const newSelection = _.difference(activeSelection.value, [id]);

  activeSelection.value = newSelection;
}
function select(id) {
  const newSelection = _.union(activeSelection.value, [id]);

  activeSelection.value = newSelection;
}
function selectDecorating({ contentType, contentId }) {
  const filtered = dataFields.value.data.filter((df) => {
    return (
      _.get(df, "decoratingContentType") === contentType &&
      _.get(df, "decoratingContentId") === contentId
    );
  });

  activeSelection.value = filtered.map((df) => df.localId);
}
function toggleOverall() {
  if (indeterminate.value || allSelected.value) {
    activeSelection.value = [];
  } else {
    activeSelection.value = dataFields.value.data.map((df) => df.localId);
  }
}
function updateOverallCheckbox() {
  const overall = document.querySelector("#payload-items-overall");

  if (!overall) {
    return;
  }

  if (selectionLength.value === 0) {
    overall.checked = false;
    overall.indeterminate = false;
  } else if (allSelected.value) {
    overall.checked = true;
    overall.indeterminate = false;
  } else {
    overall.checked = false;
    overall.indeterminate = true;
  }
}

function bulkExcludeConfirmation() {
  confirmationPayload.value = {
    title: "Exclude datapoints",
    message:
      "Excluding these datapoints carves them out of the data input job you are working on. This means the referral source for the data doesn't earn a split. Tower Hunt reviews exclusions and can override them. Are you sure?",
    affirmText: "Exclude",
    affirmCallback: bulkExclude,
  };
}
async function bulkExclude() {
  if (!actionInProgress.value) {
    const payload = {
      ids: activeSelection.value,
      itemId: props.payloadItem.id,
    };

    actionInProgress.value = true;

    api.post(`exclude_payload_item_datapoints`, payload).then(() => {
      dataFields.value = {
        data: [],
        pagy: null,
      };
      activeSelection.value = [];
      fetchFields();
      actionInProgress.value = false;
    });
  }
}
</script>
