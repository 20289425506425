<template>
  <div v-observe-visibility="{ callback: fetchDownstreamData }">
    <div v-if="dataField || calculationName" class="py-1" role="none">
      <div
        class="group flex items-center justify-between p-2 space-x-1"
        role="menuitem"
        tabindex="-1"
        id="menu-item-0"
      >
        <DataVisibilityButton :visibility="visibility" class="flex">
          <template v-slot:button>
            <div v-if="calculationName" class="flex items-center">
              <CalculatorIcon :class="`mr-1 h-4 w-4 ${fieldTextColor}`" />
              <span
                :class="`text-sm text-gray-800 underline decoration-2 ${fieldUnderlineClasses} font-bold`"
                >{{ _.startCase(calculationName) }}</span
              >
            </div>
            <div v-else class="flex items-center">
              <CodeBracketSquareIcon
                :class="`mr-1 h-4 w-4 ${fieldTextColor}`"
              />
              <span
                :class="`text-sm text-gray-800 underline decoration-2 ${fieldUnderlineClasses} font-bold`"
                >{{ timingFields ? "Portfolio " : ""
                }}{{ _.startCase(field) }}</span
              >
            </div>
          </template>
        </DataVisibilityButton>
        <div class="flex items-center space-x-1">
          <span
            v-if="lastUpdated"
            v-tooltip="'Last updated'"
            class="text-gray-700"
            style="font-size: 10px"
            >{{ lastUpdated }}</span
          >
          <button @click="getHelp" type="button" v-tooltip="'Get help'">
            <QuestionMarkCircleIcon class="h-5 w-5 text-gray-700" />
          </button>
        </div>
      </div>
      <div
        v-if="calculationDescription"
        class="text-gray-700 group flex items-center px-2 py-1 text-xs"
        role="menuitem"
        tabindex="-1"
        id="calculation-description"
      >
        <span>{{ calculationDescription }}</span>
      </div>
      <UnlockSelector
        v-if="dataField || calculationName"
        context="data-field-action-menu"
        :data-field="dataField"
        :timing-fields="timingFields"
        :fetched-all-fields-data="fetchedAllFieldsData"
        :calculation-name="calculationName"
        :bundle-field-ids="bundleFieldIds"
        :calculation-content-type="calculationContentType"
        :calculation-content-ids="calculationContentIds"
        :calculation-is-survey="calculationIsSurvey"
        :user-store="userStore"
        :modal-store="modalStore"
        :unlocker-store="unlockerStore"
        :documentation-store="documentationStore"
        :display-pricing-context="!!dataField"
        :parent-component-save-function="parentComponentSaveFunction"
        :analyze="analyze"
        @completed="completed"
        @unlocked="unlocked"
        @unlockable="nestedUnlockable = true"
        class=""
      />
      <template v-if="rejected">
        <div
          class="text-pink-600 group flex items-center p-2 text-xs"
          role="menuitem"
          tabindex="-1"
          id="menu-item-0"
        >
          <HandThumbDownIcon class="mr-2 h-4 w-4 text-pink-400" />
          <span
            class="underline decoration-2 decoration-dotted decoration-pink-400"
          >
            Rejected via peer review</span
          >
        </div>
      </template>
      <template
        v-else-if="
          reputable &&
          unpublished &&
          dataField.authored &&
          dataField.latestChangeId
        "
      >
        <button
          @click="publish"
          class="w-full text-emerald-600 hover:bg-emerald-50 group flex items-center p-2 text-xs"
          role="menuitem"
          tabindex="-1"
          id="menu-item-0"
          type="button"
        >
          <ShieldExclamationIcon class="mr-2 h-4 w-4 text-emerald-500" />
          <span>Unpublished! Click to publish</span>
        </button>
      </template>
      <template v-if="isSharingRecipient">
        <div
          class="text-gray-700 group flex items-center p-2 text-xs"
          role="menuitem"
          tabindex="-1"
          id="menu-item-0"
        >
          <PaperAirplaneIcon class="mr-2 h-4 w-4 text-gray-400" />
          <span
            v-if="sharingGrantor.sharedBy?.name"
            class="underline decoration-2 decoration-wavy decoration-emerald-400"
          >
            Shared by {{ sharingGrantor.sharedBy.name }}</span
          >
          <span
            v-else-if="sharingGrantor.easyDataInputReferral"
            class="underline decoration-2 decoration-wavy decoration-emerald-400"
          >
            Shared via Data Input Job</span
          >
        </div>
        <div
          v-if="subsidyExpiresAt"
          class="text-gray-700 group flex items-center p-2 text-xs"
          role="menuitem"
          tabindex="-1"
          id="menu-item-0"
          data-test="subsidy-expiration-label"
        >
          <GiftIcon class="mr-2 h-4 w-4 text-gray-400" />
          <span>Gift expires {{ remainingSubsidy }}</span>
        </div>
      </template>
      <CalculationOptions
        v-if="
          fullyLicensed &&
          calculationName &&
          actionableCalculationOptions.length > 0
        "
        :calculation-name="calculationName"
        :calculation-option-data="calculationOptionData"
      />
      <CitationsMenu
        v-if="canAddCitations"
        :data-field="dataField"
        @refetch="completed"
      />
      <a
        v-if="
          canAddCitations &&
          !citationStore.alreadyInStage(dataField) &&
          !citationsStage
        "
        @click.prevent="addToCitationsStage"
        href=""
        class="text-gray-700 group flex items-center p-2 text-xs hover:bg-gray-100 hover:text-gray-900"
        role="menuitem"
        tabindex="-1"
        id="menu-item-0"
      >
        <BookmarkIcon
          class="mr-2 h-4 w-4 text-gray-400 group-hover:text-gray-500"
        />
        Add to Citations Stage
      </a>
      <a
        v-else-if="
          canAddCitations &&
          citationStore.alreadyInStage(dataField) &&
          !citationsStage
        "
        @click.prevent="citationStore.removeCitationDataField([dataField])"
        href=""
        class="text-gray-700 group flex items-center p-2 text-xs hover:bg-gray-100 hover:text-gray-900"
        role="menuitem"
        tabindex="-1"
        id="menu-item-0"
      >
        <BookmarkIcon
          class="mr-2 h-4 w-4 text-red-400 group-hover:text-red-500"
        />
        Remove from Citations Stage
      </a>
    </div>
    <template v-if="!nestedUnlockable">
      <div
        v-if="
          dataField &&
          dataField.authored &&
          signedIn &&
          !sharingStage &&
          !adminView
        "
      >
        <div class="border-b border-gray-200">
          <span class="text-gray-700 block p-2 text-xs font-semibold"
            >Make changes</span
          >
        </div>
        <a
          v-if="approvable"
          @click.prevent="viewChangeGroup"
          href=""
          class="text-gray-700 group flex items-center p-2 text-xs hover:bg-gray-100 hover:text-gray-900"
          role="menuitem"
          tabindex="-1"
          id="menu-item-0"
        >
          <svg
            class="mr-2 h-4 w-4 text-gray-400 group-hover:text-gray-500"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
              clip-rule="evenodd"
            />
          </svg>
          View change group
        </a>
        <a
          v-if="editable"
          v-close-popper
          @click.prevent="suggestChange"
          v-tooltip="'Submit a correction, which can earn you revenue.'"
          href=""
          class="text-gray-700 group flex items-center p-2 text-xs hover:bg-gray-100 hover:text-gray-900"
          role="menuitem"
          tabindex="-1"
          id="menu-item-0"
          data-test="edit-data-field-button"
        >
          <WrenchIcon
            class="mr-2 h-4 w-4 text-gray-400 group-hover:text-indigo-500"
          />
          Edit
        </a>
        <VDropdown v-else>
          <button
            class="w-full text-gray-700 group flex items-center justify-between p-2 text-xs hover:bg-gray-100 hover:text-gray-900"
            role="menuitem"
            tabindex="-1"
            id="menu-item-0"
            data-test="data-field-details-button"
          >
            <div class="flex items-center">
              <WrenchIcon
                class="mr-2 h-4 w-4 text-gray-400 group-hover:text-indigo-500"
              />
              Details quick access
            </div>
            <ChevronRightIcon class="h-4 w-4" />
          </button>
          <template #popper>
            <AvailabilityDetailsTabs
              v-if="dataField?.fieldContentType === 'SpaceAvailability'"
              :availability="dataField"
              :from-menu="true"
            />
            <InvestmentDetailsTabs
              v-else-if="dataField?.fieldContentType === 'Investment'"
              :investment="dataField"
              :from-menu="true"
            />
            <HuntLocation
              v-else-if="dataField?.fieldContentType === 'HuntGeographyIntent'"
              :location-field="dataField"
              :from-menu="true"
            />
            <DataFieldDetails
              v-else
              :data-field="dataField"
              :from-menu="true"
            />
          </template>
        </VDropdown>
        <a
          v-if="removable"
          v-close-popper
          @click.prevent="dismissConfirmation"
          href=""
          class="text-gray-700 group flex items-center p-2 text-xs hover:bg-gray-100 hover:text-gray-900"
          role="menuitem"
          tabindex="-1"
          id="menu-item-0"
        >
          <XMarkIcon
            class="mr-2 h-4 w-4 text-gray-400 group-hover:text-indigo-500"
          />
          Remove {{ removeLabel }}
        </a>
      </div>
      <div
        v-else-if="
          signedIn && !sharingStage && thirdPartyEditable && !adminView
        "
      >
        <div class="border-b border-gray-200">
          <span class="text-gray-700 block p-2 text-xs font-semibold"
            >Make changes</span
          >
        </div>
        <a
          v-if="editable"
          v-close-popper
          @click.prevent="suggestChange"
          v-tooltip="'Submit a correction, which can earn you revenue.'"
          href=""
          class="text-gray-700 group flex items-center p-2 text-xs hover:bg-gray-100 hover:text-gray-900"
          role="menuitem"
          tabindex="-1"
          id="menu-item-0"
          data-test="edit-data-field-button"
        >
          <WrenchIcon
            class="mr-2 h-4 w-4 text-gray-400 group-hover:text-indigo-500"
          />
          Edit
        </a>
        <VDropdown v-else>
          <button
            class="w-full text-gray-700 group flex items-center justify-between p-2 text-xs hover:bg-gray-100 hover:text-gray-900"
            role="menuitem"
            tabindex="-1"
            id="menu-item-0"
            data-test="data-field-details-button"
          >
            <div class="flex items-center">
              <WrenchIcon
                class="mr-2 h-4 w-4 text-gray-400 group-hover:text-indigo-500"
              />
              Details quick access
            </div>
            <ChevronRightIcon class="h-4 w-4" />
          </button>
          <template #popper>
            <AvailabilityDetailsTabs
              v-if="dataField?.fieldContentType === 'SpaceAvailability'"
              :availability="dataField"
              :from-menu="true"
            />
            <InvestmentDetailsTabs
              v-else-if="dataField?.fieldContentType === 'Investment'"
              :investment="dataField"
              :from-menu="true"
            />
            <HuntLocation
              v-else-if="dataField?.fieldContentType === 'HuntGeographyIntent'"
              :location-field="dataField"
              :from-menu="true"
            />
            <DataFieldDetails
              v-else
              :data-field="dataField"
              :from-menu="true"
            />
          </template>
        </VDropdown>
        <template v-if="removable">
          <a
            v-if="isSharingRecipient"
            v-close-popper
            @click.prevent="dismissConfirmation"
            href=""
            class="text-gray-700 group flex items-center p-2 text-xs hover:bg-gray-100 hover:text-gray-900"
            role="menuitem"
            tabindex="-1"
            id="menu-item-0"
          >
            <XMarkIcon
              class="mr-2 h-4 w-4 text-gray-400 group-hover:text-indigo-500"
            />
            Remove
          </a>
          <a
            v-else
            v-close-popper
            @click.prevent="dismissConfirmation"
            href=""
            class="text-gray-700 group flex items-center p-2 text-xs hover:bg-gray-100 hover:text-gray-900"
            role="menuitem"
            tabindex="-1"
            id="menu-item-0"
          >
            <XMarkIcon
              class="mr-2 h-4 w-4 text-gray-400 group-hover:text-indigo-500"
            />
            Remove {{ removeLabel }}
          </a>
        </template>
      </div>
      <VDropdown v-else-if="!editable">
        <button
          class="w-full text-gray-700 group flex items-center justify-between p-2 text-xs hover:bg-gray-100 hover:text-gray-900"
          role="menuitem"
          tabindex="-1"
          id="menu-item-0"
          data-test="data-field-details-button"
        >
          <div class="flex items-center">
            <WrenchIcon
              class="mr-2 h-4 w-4 text-gray-400 group-hover:text-indigo-500"
            />
            Details quick access
          </div>
          <ChevronRightIcon class="h-4 w-4" />
        </button>
        <template #popper>
          <AvailabilityDetailsTabs
            v-if="dataField?.fieldContentType === 'SpaceAvailability'"
            :availability="dataField"
            :from-menu="true"
          />
          <InvestmentDetailsTabs
            v-else-if="dataField?.fieldContentType === 'Investment'"
            :investment="dataField"
            :from-menu="true"
          />
          <HuntLocation
            v-else-if="dataField?.fieldContentType === 'HuntGeographyIntent'"
            :location-field="dataField"
            :from-menu="true"
          />
          <DataFieldDetails v-else :data-field="dataField" :from-menu="true" />
        </template>
      </VDropdown>
      <div v-if="dataField && signedIn && !sharingStage && !adminView">
        <div class="border-b border-gray-200">
          <span class="text-gray-700 block p-2 text-xs font-bold"
            >Safezone</span
          >
        </div>
        <button
          v-if="userLinkable"
          @click="linkContact"
          type="button"
          class="w-full text-gray-700 group flex items-center p-2 text-xs hover:bg-gray-100 hover:text-gray-900"
          data-test="link-contact-button"
        >
          <LinkIcon
            class="flex-shrink-0 mr-2 h-4 w-4 text-gray-400 group-hover:text-gray-500"
          />
          Link contact to my user account
        </button>
        <VDropdown v-if="allowsNotes" placement="left-start">
          <button
            type="button"
            class="w-full text-gray-700 group flex items-center p-2 text-xs hover:bg-gray-100 hover:text-gray-900"
            data-test="data-field-notes-button"
          >
            <PencilSquareIcon
              class="flex-shrink-0 mr-2 h-4 w-4 text-gray-400 group-hover:text-gray-500"
            />
            Notes
          </button>
          <template #popper>
            <div class="w-72 flex flex-col">
              <ContentNotes
                :content-data-field="dataField"
                :focus="true"
                :user-store="userStore"
                :layout-store="layoutStore"
                :change-group-store="changeGroupStore"
                :unlocker-store="unlockerStore"
                context="dataFieldContext"
              />
            </div>
          </template>
        </VDropdown>
        <VDropdown v-if="taskable" placement="left-start">
          <button
            type="button"
            class="w-full text-gray-700 group flex items-center p-2 text-xs hover:bg-gray-100 hover:text-gray-900"
            data-test="add-task-button"
          >
            <StarIcon
              class="mr-2 h-4 w-4 text-gray-400 group-hover:text-gray-500"
            />
            Create Task
          </button>
          <template #popper>
            <div class="w-64 flex flex-col">
              <div class="space-y-2">
                <h1 class="px-2 pt-2 text-xs font-semibold text-gray-700">
                  Choose a Task List
                </h1>
                <ul class="max-h-56 overflow-y-auto divide-y divide-gray-200">
                  <li v-for="list in effectiveTaskLists" :key="list.id">
                    <a
                      @click.prevent="addTask(list)"
                      v-close-popper
                      href=""
                      class="block hover:bg-gray-50"
                      :data-test="`${_.camelCase(list.name)}-list-item`"
                    >
                      <div
                        class="flex min-w-0 flex-1 items-center p-1 space-x-2"
                      >
                        <div
                          :class="`${taskListStore.colorFor(list)}`"
                          class="flex-shrink-0 rounded-full p-1.5"
                        >
                          <UserGroupIcon
                            v-if="list.shared"
                            class="h-3 w-3 text-white"
                            aria-hidden="true"
                          />
                          <ListBulletIcon
                            v-else
                            class="h-3 w-3 text-white"
                            aria-hidden="true"
                          />
                        </div>
                        <div class="min-w-0 flex-1">
                          <p class="truncate text-xs font-medium text-gray-700">
                            {{ list.name }}
                          </p>
                        </div>
                      </div>
                    </a>
                  </li>
                  <InfiniteLoading
                    v-if="signedIn && taskListPagy && taskListPagy.next"
                    @infinite="loadLists"
                  />
                </ul>
              </div>
            </div>
          </template>
        </VDropdown>
        <a
          v-if="relatedTaskIds.length > 0"
          v-close-popper
          @click.prevent="viewRelatedTasks"
          href=""
          class="text-gray-700 group flex items-center p-2 text-xs hover:bg-gray-100 hover:text-gray-900"
          role="menuitem"
          tabindex="-1"
          id="menu-item-0"
          data-test="view-tasks-linked-to-a-datapoint-button"
        >
          <DocumentCheckIcon
            class="mr-2 h-4 w-4 text-gray-400 group-hover:text-gray-500"
          />
          View My Related Tasks
        </a>
        <a
          v-close-popper
          v-if="safezone && licensed && !sharingStore.alreadyInStage(dataField)"
          @click.prevent="addToSharingStage"
          href=""
          class="text-gray-700 group flex items-center p-2 text-xs hover:bg-gray-100 hover:text-gray-900"
          role="menuitem"
          tabindex="-1"
          id="menu-item-0"
        >
          <svg
            class="mr-2 h-4 w-4 text-gray-400 group-hover:text-gray-500"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              d="M13 4.5a2.5 2.5 0 11.702 1.737L6.97 9.604a2.518 2.518 0 010 .792l6.733 3.367a2.5 2.5 0 11-.671 1.341l-6.733-3.367a2.5 2.5 0 110-3.475l6.733-3.366A2.52 2.52 0 0113 4.5z"
            />
          </svg>
          Add to Sharing Stage
        </a>
        <a
          v-close-popper
          v-else-if="
            safezone && licensed && sharingStore.alreadyInStage(dataField)
          "
          @click.prevent="sharingStore.removeFromDataFieldSharing([dataField])"
          href=""
          class="text-gray-700 group flex items-center p-2 text-xs hover:bg-gray-100 hover:text-gray-900"
          role="menuitem"
          tabindex="-1"
          id="menu-item-0"
        >
          <svg
            class="mr-2 h-4 w-4 text-red-400 group-hover:text-red-500"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              d="M13 4.5a2.5 2.5 0 11.702 1.737L6.97 9.604a2.518 2.518 0 010 .792l6.733 3.367a2.5 2.5 0 11-.671 1.341l-6.733-3.367a2.5 2.5 0 110-3.475l6.733-3.366A2.52 2.52 0 0113 4.5z"
            />
          </svg>
          Remove from Sharing Stage
        </a>
        <a
          v-if="safezone && !safezoneTab"
          v-close-popper
          @click.prevent="viewSafezone"
          href=""
          class="text-gray-700 group flex items-center p-2 text-xs hover:bg-gray-100 hover:text-gray-900"
          role="menuitem"
          tabindex="-1"
          id="menu-item-0"
        >
          <svg
            class="mr-2 h-4 w-4 text-gray-400 group-hover:text-gray-500"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
              clip-rule="evenodd"
            />
          </svg>
          View My Safezone
        </a>
        <a
          v-if="safezone && dataField.authored && declassifiable && !rejected"
          v-close-popper
          @click.prevent="openSourceConfirmation"
          href=""
          class="text-gray-700 group flex items-center p-2 text-xs hover:bg-gray-100 hover:text-gray-900"
          role="menuitem"
          tabindex="-1"
          id="menu-item-0"
          data-test="safezone-declassify-button"
        >
          <svg
            class="mr-2 h-4 w-4 text-gray-400 group-hover:text-gray-500"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
            <path
              fill-rule="evenodd"
              d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
              clip-rule="evenodd"
            />
          </svg>
          Declassify
        </a>
      </div>
      <DatafieldContextActions
        v-if="licensed || free"
        :data-field="dataField"
        :tasks="tasks"
        :analyze="analyze"
        :user-store="userStore"
        :layout-store="layoutStore"
        :change-group-store="changeGroupStore"
        :unlocker-store="unlockerStore"
      />
    </template>
    <VDropdown
      v-if="!sharingStage && isAdmin && dataField"
      placement="left-start"
    >
      <button
        class="w-full text-gray-700 group flex items-center justify-between p-2 text-xs font-semibold hover:bg-gray-100 hover:text-gray-900"
        data-test="admin-actions-menu-button"
      >
        <span>Admin actions</span>
        <ChevronRightIcon class="w-4 h-4 text-gray-700" />
      </button>
      <template #popper>
        <VDropdown placement="left-start">
          <button
            @click="fetchJobs"
            type="button"
            class="w-full text-gray-700 group flex items-center justify-between p-2 text-xs hover:bg-gray-100 hover:text-gray-900"
            data-test="link-preemption-button"
          >
            <div class="flex items-center">
              <LinkIcon
                class="mr-2 h-4 w-4 text-gray-400 group-hover:text-gray-500"
              />
              Easy Data Input Preemption
            </div>
            <ChevronRightIcon class="h-4 w-4" />
          </button>
          <template #popper>
            <div class="w-64 flex flex-col">
              <div class="space-y-2">
                <h1 class="px-2 pt-2 text-xs font-semibold text-gray-700">
                  Link to an Easy Data Input Job:
                </h1>
                <ul class="max-h-56 overflow-y-auto divide-y divide-gray-200">
                  <li v-for="job in easyDataInputJobs.data" :key="job.id">
                    <a
                      v-close-popper
                      v-tooltip="jobLabel(job)"
                      @click.prevent="savePreemption(job.id)"
                      href=""
                      class="block hover:bg-gray-50"
                    >
                      <div
                        class="flex min-w-0 flex-1 items-center p-1 space-x-2"
                      >
                        <div class="flex-shrink-0 rounded-full p-1.5">
                          <BoltIcon
                            class="h-4 w-4 text-gray-400 group-hover:text-gray-500"
                          />
                        </div>
                        <div class="min-w-0 flex-1">
                          <p class="truncate text-xs font-medium text-gray-700">
                            <template v-if="job.narrative"
                              >{{ job.narrative }}&middot;</template
                            >
                            <template v-if="job.files.length > 0"
                              >{{
                                job.files.map(({ name }) => name).join(", ")
                              }}&middot;</template
                            >
                            ({{ job.id }})
                          </p>
                        </div>
                      </div>
                    </a>
                  </li>
                  <InfiniteLoading
                    v-if="
                      isAdmin &&
                      easyDataInputJobsPagy &&
                      easyDataInputJobsPagy.next
                    "
                    @infinite="loadJobs"
                  />
                </ul>
              </div>
            </div>
          </template>
        </VDropdown>
        <a
          v-if="adminView && safezone"
          v-close-popper
          @click.prevent="adminDeclassify"
          href=""
          class="text-gray-700 group flex items-center p-2 text-xs hover:bg-gray-100 hover:text-gray-900"
          role="menuitem"
          tabindex="-1"
          id="menu-item-0"
          data-test="safezone-admin-declassify-button"
        >
          <svg
            class="mr-2 h-4 w-4 text-gray-400 group-hover:text-gray-500"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
            <path
              fill-rule="evenodd"
              d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
              clip-rule="evenodd"
            />
          </svg>
          Declassify
        </a>
        <a
          v-else-if="adminView && unpublished"
          v-close-popper
          @click.prevent="adminPublish"
          href=""
          class="text-gray-700 group flex items-center p-2 text-xs hover:bg-gray-100 hover:text-gray-900"
          role="menuitem"
          tabindex="-1"
          id="menu-item-0"
          data-test="safezone-admin-publish-button"
        >
          <ShieldExclamationIcon
            class="mr-2 h-4 w-4 text-gray-400 group-hover:text-gray-500"
          />
          Publish
        </a>
        <a
          v-if="approvable"
          @click.prevent="viewChangeGroup"
          href=""
          class="text-gray-700 group flex items-center p-2 text-xs hover:bg-gray-100 hover:text-gray-900"
          role="menuitem"
          tabindex="-1"
          id="menu-item-0"
        >
          <svg
            class="mr-2 h-4 w-4 text-gray-400 group-hover:text-gray-500"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
              clip-rule="evenodd"
            />
          </svg>
          View change group
        </a>
        <a
          v-if="removable"
          v-close-popper
          @click.prevent="deprecate"
          href=""
          v-tooltip="
            'Take this intel out of circulation, typically for quality reasons.'
          "
          class="text-gray-700 group flex items-center p-2 text-xs hover:bg-gray-100 hover:text-gray-900"
          role="menuitem"
          tabindex="-1"
          id="menu-item-0"
        >
          <svg
            class="mr-2 h-4 w-4 text-gray-400 group-hover:text-gray-500"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
            />
          </svg>
          Deprecate
        </a>
      </template>
    </VDropdown>
  </div>
</template>

<script setup>
import {
  XMarkIcon,
  WrenchIcon,
  HandThumbDownIcon,
  ListBulletIcon,
  UserGroupIcon,
  CodeBracketSquareIcon,
  ChevronRightIcon,
  GiftIcon,
  PaperAirplaneIcon,
  StarIcon,
  DocumentCheckIcon,
  CalculatorIcon,
  PencilSquareIcon,
  ShieldExclamationIcon,
  LinkIcon,
  QuestionMarkCircleIcon,
  BookmarkIcon,
  BoltIcon,
} from "@heroicons/vue/20/solid";
import DatafieldContextActions from "@/components/crowdsourcing/DataFieldContextActions.vue";
import CitationsMenu from "@/components/crowdsourcing/CitationsMenu.vue";
import UnlockSelector from "@/components/crowdsourcing/UnlockSelector.vue";
import ContentNotes from "@/components/crowdsourcing/ContentNotes.vue";
import DataFieldDetails from "@/components/crowdsourcing/DataFieldDetails.vue";
import AvailabilityDetailsTabs from "@/components/space-usage-builder/AvailabilityDetailsTabs.vue";
import InvestmentDetailsTabs from "@/components/deal-builder/InvestmentDetailsTabs.vue";
import HuntLocation from "@/components/crowdsourcing/HuntLocation.vue";
import { whatIsADataPoint } from "@/assets/documentation/articles";
import { useUserStore } from "@/stores/user";
import { useDocumentationStore } from "@/stores/documentation";
import { useCrowdsourcedChangeGroupStore } from "@/stores/crowdsourcedChangeGroup";
import { useSecondaryPanelStore } from "@/stores/secondaryPanel";
import { useSharingStore } from "@/stores/sharing";
import { useCitationStore } from "@/stores/citation";
import { useModalStore } from "@/stores/modal";
import { useTasksStore } from "@/stores/tasks";
import { useTaskListStore } from "@/stores/taskList";
import { useReminderStore } from "@/stores/reminder";
import { useGuestProfileStore } from "@/stores/guestProfile";
import { usePropertyDiagramStore } from "@/stores/propertyDiagram";
import { useDealBuilderStore } from "@/stores/dealBuilder";
import { useSpaceUsageBuilderStore } from "@/stores/spaceUsageBuilder";
import { useTimeTravelStore } from "@/stores/timeTravel";
import { useProveForValueStore } from "@/stores/proveForValue";
import { useDataFieldLoadingStore } from "@/stores/dataFieldLoadingManagement";
import { storeToRefs } from "pinia";
import api from "@/router/api";
import calculationOptionsFor from "@/components/analyze/calculations/options/availableOptions";
import { computed, nextTick, onMounted, ref } from "vue";
import moment from "moment";
import _ from "lodash";
import { useRoute, useRouter } from "vue-router";
import DataVisibilityButton from "@/components/crowdsourcing/DataVisibilityButton.vue";
import CalculationOptions from "@/components/analyze/calculations/CalculationOptions.vue";

const props = defineProps([
  "dataField",
  "timingFields",
  "fetchedAllFieldsData",
  "fullyLicensed",
  "calculationName",
  "calculationOptionData",
  "bundleFieldIds",
  "calculationContentType",
  "calculationContentIds",
  "calculationIsSurvey",
  "isInnerField",
  "safezoneTab",
  "sharingStage",
  "citationsStage",
  "tasks",
  "analyze",
  "alwaysPrivate",
  "adminView",
  "userStore",
  "modalStore",
  "unlockerStore",
  "tasksStore",
  "taskListStore",
  "reminderStore",
  "guestProfileStore",
  "propertyDiagramStore",
  "changeGroupStore",
  "documentationStore",
  "layoutStore",
  "parentComponentSaveFunction",
  "skipPostEditingPatch",
]);
const emit = defineEmits([
  "open-sourced",
  "admin-open-sourced",
  "suggest-change",
  "completed",
  "unlocked",
  "dismiss",
  "set-proof",
]);
const propertyDiagramStore =
  props.propertyDiagramStore || usePropertyDiagramStore();
const timeTravelStore = useTimeTravelStore();
const dealBuilderStore = useDealBuilderStore();
const spaceUsageBuilderStore = useSpaceUsageBuilderStore();
const proveForValueStore = useProveForValueStore();
const dataFieldLoadingStore = useDataFieldLoadingStore();

const nestedUnlockable = ref(false);
const relatedTaskIds = ref([]);
const sharingGrantor = ref(null);
const subsidyExpiresAt = computed(() =>
  _.get(sharingGrantor.value, "license.subsidyExpiresAt"),
);
const remainingSubsidy = computed(() => {
  if (subsidyExpiresAt.value) return moment(subsidyExpiresAt.value).fromNow();
  else return "";
});
const sharingStore = useSharingStore();
const citationStore = useCitationStore();
const modalStore = props.modalStore || useModalStore();
const { confirmationPayload } = storeToRefs(modalStore);
const userStore = props.userStore || useUserStore();
const { isAdmin, signedIn, reputable, linkable } = storeToRefs(userStore);
const changeGroupStore =
  props.changeGroupStore || useCrowdsourcedChangeGroupStore();
const { existingChangeGroupId, changeGroupId } = storeToRefs(changeGroupStore);
const secondaryPanelStore = useSecondaryPanelStore();
const { validationsActive } = storeToRefs(secondaryPanelStore);
const tasksStore = props.tasksStore || useTasksStore();
const {
  addingTask,
  selectedTask,
  effectiveTaskLists,
  taskLists,
  taskListPagy,
} = storeToRefs(tasksStore);
const taskListStore = props.taskListStore || useTaskListStore();
const reminderStore = props.reminderStore || useReminderStore();
const guestProfileStore = props.guestProfileStore || useGuestProfileStore();
const { guestTasks } = storeToRefs(guestProfileStore);
const documentationStore = props.documentationStore || useDocumentationStore();
const router = useRouter();
const route = useRoute();
const query = computed(() => route.query);

const easyDataInputJobs = ref({
  data: [],
  pagy: null,
});
const easyDataInputJobsPagy = computed(() => easyDataInputJobs.value.pagy);
const eligibleHorizontalTab = computed(() =>
  _.includes(
    ["Details", "Deals", "Spaces", "Validations"],
    _.get(query.value, "horizontalTab"),
  ),
);
const userLinkable = computed(
  () => contactId.value && !contactIsLinked.value && linkable.value,
);
const visibility = computed(() => {
  if (props.calculationName) {
    return "calculation";
  } else if (safezone.value) {
    return "safezone";
  } else {
    return "public";
  }
});
const lastUpdated = computed(() => {
  if (props.dataField) {
    return moment(props.dataField.updatedAt).fromNow();
  } else {
    return null;
  }
});
const allowsNotes = computed(() => {
  return (
    signedIn.value &&
    _.get(props.dataField, "fieldContentType") !== "ContentNote"
  );
});
const isOuterField = computed(
  () =>
    !!props.dataField &&
    _.isNull(_.get(props.dataField, "decoratingContentType")),
);
const propertyLocation = computed(() => {
  return (
    !!props.dataField &&
    _.get(props.dataField, "decoratingContentType") === "Property" &&
    _.get(props.dataField, "fieldContentType") === "ContentLocation"
  );
});
const requiredStandalone = computed(() => {
  return _.includes(
    [
      "min_area_per_person",
      "max_area_per_person",
      "density_percent_modifier",
      "min_area",
      "max_area",
      "min_headcount",
      "max_headcount",
      "target_date",
    ],
    field.value,
  );
});
const removeOnly = computed(() => {
  return _.includes(
    [
      "ContentPropertyUse",
      "HuntGeographyIntent",
      "GeographyRegion",
      "HuntSpaceRequirement",
      "SpaceRequirementPhase",
      "SpaceUsage",
      "Hunt",
      "FloorArea",
      "FloorAreaLayout",
      "LayoutPartition",
      "LandCovering",
      "LandCoveringLevel",
      "PropertyRight",
    ],
    field.value,
  );
});
const removable = computed(
  () => !propertyLocation.value && !requiredStandalone.value,
);
const editable = computed(
  () =>
    eligibleHorizontalTab.value &&
    !rejected.value &&
    !props.isInnerField &&
    !isOuterField.value &&
    !isCitation.value &&
    !removeOnly.value &&
    props.dataField?.fieldValueType !== "Boolean",
);
const isCitation = computed(
  () =>
    !!props.dataField &&
    (_.get(props.dataField, "decoratingContentType") === "DataFieldCitation" ||
      _.get(props.dataField, "fieldContentType") === "CitationChangeGroup"),
);
const declassifiable = computed(
  () =>
    (_.isBoolean(props.alwaysPrivate) && !props.alwaysPrivate) ||
    props.dataField?.declassifiable,
);
const canAddCitations = computed(() => {
  return (
    !isCitation.value &&
    !safezone.value &&
    thirdPartyEditable.value &&
    !isOuterField.value
  );
});
const field = computed(() => {
  return (
    _.get(props.dataField, "fieldContentType", null) ||
    _.get(props.dataField, "fieldName", null)
  );
});
const fieldTextColor = computed(() => {
  if (props.calculationName) {
    return "text-teal-600";
  } else if (safezone.value) {
    return "text-yellow-600";
  } else {
    return "text-indigo-600";
  }
});
const fieldUnderlineClasses = computed(() => {
  if (props.calculationName) {
    return "decoration-solid decoration-teal-500";
  } else if (safezone.value) {
    return "decoration-wavy decoration-yellow-500";
  } else {
    return "decoration-solid decoration-indigo-600";
  }
});
const platformState = computed(() => {
  return _.get(props.dataField, "state", null);
});
const approvable = computed(() => {
  return (
    !unstaked.value &&
    !!props.dataField &&
    _.get(props.dataField, "approvable", false)
  );
});
const safezone = computed(() => {
  return !!props.dataField && _.get(props.dataField, "safezone", true);
});
const rejected = computed(() => platformState.value === "rejected");
const unstaked = computed(() => _.get(props.dataField, "state") === "unstaked"); // field.value??
const unpublished = computed(
  () => _.get(props.dataField, "state") === "staked",
);
const published = computed(
  () => _.get(props.dataField, "state") === "proposed",
);
const licensed = computed(() => _.get(props.dataField, "licensed"));
const free = computed(() => _.get(props.dataField, "free"));
const isSharingRecipient = computed(
  () =>
    sharingGrantor.value?.sharedBy ||
    sharingGrantor.value?.easyDataInputReferral,
);
const thirdPartyEditable = computed(() => {
  return !!props.dataField && (licensed.value || free.value);
});
const removeLabel = computed(() => {
  if (
    props.isInnerField ||
    !props.dataField.decoratingContentType ||
    props.dataField.decoratingContentType === "DataFieldChangeGroup"
  ) {
    return "from Tower Hunt";
  } else {
    return `from ${props.dataField.decoratingContentType}`;
  }
});
const taskable = computed(() => {
  return (
    signedIn.value &&
    !props.tasks &&
    !props.adminView &&
    (licensed.value || free.value)
  );
});
const calculationDescriptions = [
  {
    name: "property_count",
    description:
      "Tower Hunt properties can include multiple parcels and buildings.",
  },
  {
    name: "building_count",
    description:
      "Related structures (at the diagram level) that are grouped into a single building are counted as 1.",
  },
  {
    name: "sizes_uses",
    description:
      "The sum of all floor areas. Depending upon the data available, can be denominated in square feet and/or units corresponding to the use.",
  },
  {
    name: "investment_volume",
    description:
      "The total dollar value of a collection of investments, favoring final valuations. Loan originations use loan amount. Portfolio values are used when all included investments are visible on the map.",
  },
  {
    name: "price_per_area",
    description:
      "A valuation divided by an area measure as of the investment date. Depending upon the data available, can be denominated in square feet and/or units corresponding to the use.",
  },
  {
    name: "implied_loan_term",
    description:
      "The number of years (rounded) from the loan origination date to the maturity date.",
  },
  {
    name: "implied_maturity",
    description:
      "The resulting date after adding the loan term (years) to the loan origination date.",
  },
  {
    name: "headcount_based_area",
    description:
      "A square footage range combining minimum and maximum headcount, density, and modifier inputs.",
  },
  {
    name: "sublease_expiration_date",
    description: "The expiration date of the upstream direct lease.",
  },
  {
    name: "in_place_rent",
    description: "The current base rent as of the timeline date.",
  },
  {
    name: "space_usage_term_months",
    description:
      "The number of months from the commencement date to the expiration date.",
  },
];

const calculationDescription = computed(
  () =>
    _.find(calculationDescriptions, { name: props.calculationName })
      ?.description,
);
const actionableCalculationOptions = computed(() => {
  return calculationOptionsFor(
    props.calculationName,
    props.calculationOptionData,
  );
});

onMounted(() => {});

async function fetchDownstreamData(isVisible) {
  if (isVisible) {
    proveForValueStore.resetProofComponent();

    await nextTick();
    fetchSharingGrantor();
    fetchRelatedTasks();
    emit("set-proof", field.value);
  }
}

async function fetchRelatedTasks() {
  if (taskable.value) {
    api
      .get(
        `data_field_tasks?crowdsourced_data_field_id=${props.dataField.localId}`,
      )
      .then((json) => {
        relatedTaskIds.value = json.data;
      });
  }
}

async function fetchSharingGrantor() {
  if (
    (safezone.value || unpublished.value || published.value) &&
    !props.dataField.authored
  ) {
    api
      .get(
        `data_field_sharing_grantors?crowdsourced_data_field_id=${props.dataField.localId}`,
      )
      .then((json) => {
        sharingGrantor.value = json.data;
      });
  }
}

function viewChangeGroup() {
  if (props.dataField.latestChangeGroupId) {
    existingChangeGroupId.value = props.dataField.latestChangeGroupId;
    validationsActive.value = true;

    router.push({
      name: route.name,
      query: {
        ...route.query,
        horizontalTab: "Validations",
        verticalTab: "Available",
      },
    });
  }
}

function viewSafezone() {
  router.push({
    name: route.name,
    query: {
      ...route.query,
      horizontalTab: "Me",
      verticalTab: "Safezone",
    },
  });
}
function viewRelatedTasks() {
  if (relatedTaskIds.value.length > 0) {
    router.push({
      name: route.name,
      query: {
        ...route.query,
        horizontalTab: "Me",
        verticalTab: "Tasks",
        relatedTaskIds: relatedTaskIds.value,
      },
    });
  }
}
function addToSharingStage() {
  sharingStore.addSharingDataFields([props.dataField]);
}
function addToCitationsStage() {
  citationStore.addCitationFields([props.dataField]);
}
function publish() {
  const payload = {
    ids: [props.dataField.latestChangeId],
  };

  api.post(`publish_datapoints`, payload).then((json) => {
    emit("completed", json);
  });
}
function openSourceConfirmation() {
  confirmationPayload.value = {
    title: "Declassify datapoint",
    message:
      "Declassifying this datapoint makes it visible to other Tower Hunt users. Are you sure?",
    affirmText: "Declassify",
    affirmCallback: openSource,
  };
}
async function openSource() {
  if (!props.alwaysPrivate) {
    const payload = {
      id: props.dataField.localId,
    };
    dataFieldLoadingStore.patchIds([props.dataField.localId]);

    api.post(`declassify_datapoint`, payload).then(() => {
      dealBuilderStore.clearDealBuilder(false);

      setTimeout(() => {
        dataFieldLoadingStore.dropId(props.dataField.localId);
        emit("open-sourced");
      }, 1000);
    });
  }
}
function adminDeclassify() {
  if (props.adminView) {
    const payload = {
      id: props.dataField.localId,
    };

    api.post(`admin/declassifications`, payload).then((json) => {
      emit("admin-open-sourced", json.data);
    });
  }
}
function adminPublish() {
  if (props.adminView) {
    const payload = {
      id: props.dataField.localId,
    };

    api.post(`admin/publishings`, payload).then((json) => {
      emit("admin-open-sourced", json.data);
    });
  }
}
function deprecate() {
  const changeGroupParam = changeGroupId.value
    ? `&change_group_id=${changeGroupId.value}`
    : "";

  api
    .post(
      `crowdsourced_data_field_deprecations/${props.dataField.localId}?is_inner=${props.isInnerField}${changeGroupParam}`,
    )
    .then((json) => {
      changeGroupStore.manageCrowdsourcedChangeGroup(json).then(() => {
        propertyDiagramStore.postEditingPatch(json);
        if (props.isInnerField) {
          dealBuilderStore.clearDealBuilder(false);
        } else {
          dealBuilderStore.postEditingPatch(json);
        }
        emit("completed", json);
      });
    });
}
function unlocked(payload) {
  emit("unlocked", payload);
}
function suggestChange() {
  emit("suggest-change");
}
function completed(payload) {
  emit("completed", payload);
}
function dismissConfirmation() {
  confirmationPayload.value = {
    title: "Remove datapoint",
    message: "Are you sure you want to remove this datapoint?",
    affirmText: "Remove",
    affirmCallback: dismiss,
  };
}
async function dismiss() {
  const successCallback = (json) => {
    if (!props.skipPostEditingPatch) {
      propertyDiagramStore.postEditingPatch(json);

      if (props.isInnerField) {
        dealBuilderStore.clearDealBuilder(false);
        spaceUsageBuilderStore.clearBuilder(false);
      } else {
        dealBuilderStore.postEditingPatch(json);
        spaceUsageBuilderStore.postEditingPatch(json);
      }
    }

    if (props.safezoneTab) {
      emit("dismiss");
    } else if (
      _.includes(
        ["SpaceAvailabilityGroup", "InvestmentGroup"],
        props.dataField.fieldContentType,
      )
    ) {
      emit("dismiss");
    } else if (
      !props.dataField.decoratingContentType &&
      props.dataField.fieldContentType === "SpaceAvailability"
    ) {
      spaceUsageBuilderStore.clearBuilder(false);
      spaceUsageBuilderStore.resetAvailabilities();
      timeTravelStore.triggerRefetch();
    } else {
      completed(json);
    }
  };

  if (isSharingRecipient.value) {
    const revokeResponse = await api.delete(
      `safezone_data_field_sharings/?token_id=${sharingGrantor.value.tokenId}&class_name=${sharingGrantor.value.tokenClassName}`,
    );

    if (revokeResponse?.data) {
      completed({ override: true });
    }
  } else {
    await changeGroupStore.dismissData({
      dataFieldId: props.dataField.localId,
      isInner: props.isInnerField,
      successCallback,
    });
  }
}
function addTask(list) {
  if (signedIn.value && list.accessTokenId) {
    const payload = {
      accessTokenId: list.accessTokenId,
      contentId: props.dataField.localId,
      contentType: "CrowdsourcedDataField",
    };

    api.post(`tasks`, payload).then((json) => {
      const newTask = json.data;
      reminderStore.mountExisting(newTask);
      tasksStore.fetchNearbyTasks();
      selectedTask.value = newTask;
      addingTask.value = true;
      router.push({
        name: route.name,
        query: {
          ...route.query,
          horizontalTab: "Me",
          verticalTab: "Tasks",
          relatedTaskIds: [newTask.id],
        },
      });
    });
  } else {
    console.log("create guest task", list);
    const payload = {
      taskListName: list.name,
      contentId: props.dataField.localId,
      contentType: "CrowdsourcedDataField",
      name: "New Task",
    };

    guestTasks.value.push(_.merge({}, payload, { id: moment().valueOf() }));
  }
}
function cleanUrl(url) {
  return url.replace("/api/v1/", "");
}
const loadLists = async ($state) => {
  if (signedIn.value && taskListPagy.value.next) {
    const endpoint = cleanUrl(taskListPagy.value.next_url);
    try {
      api.get(endpoint).then((json) => {
        const { data, pagy } = json.data;

        taskLists.value.data = _.concat(taskLists.value.data, data);
        taskLists.value.pagy = pagy;
        if (data.length < 20) $state.complete();
        else {
          $state.loaded();
        }
      });
    } catch (error) {
      $state.error();
    }
  } else {
    $state.complete();
  }
};

const contactId = computed(() => {
  if (props.dataField?.fieldContentType === "Contact") {
    return props.dataField.fieldContentId;
  } else if (
    props.dataField?.fieldContentType === "ContactCompanyInvolvement"
  ) {
    return props.dataField.fieldContent.id;
  } else {
    return null;
  }
});
const contactIsLinked = computed(() => {
  if (contactId.value) {
    return !!props.dataField.fieldContent.linkedUserId;
  } else {
    return false;
  }
});
async function linkContact() {
  if (contactId.value && !contactIsLinked.value) {
    userStore.linkContact(contactId.value);
  }
}
function getHelp() {
  documentationStore.viewArticle(whatIsADataPoint);
}

function jobLabel(job) {
  const narrative = `${job.narrative}` || "";
  const separator = job.narrative && job.files.length > 0 ? " - " : "";
  const files =
    job.files.length > 0
      ? `${job.files.map(({ name }) => name).join(", ")}`
      : "";
  return `ID: ${job.id}: ${narrative}${separator}${files}`;
}

function fetchJobs() {
  if (isAdmin.value) {
    api
      .get("admin/easy_data_input_jobs?packaging_status=reviewing")
      .then((result) => {
        easyDataInputJobs.value = result.data;
      });
  }
}

const loadJobs = async ($state) => {
  if (signedIn.value && easyDataInputJobsPagy.value.next) {
    const endpoint = cleanUrl(easyDataInputJobsPagy.value.next_url);
    try {
      api.get(endpoint).then((json) => {
        const { data, pagy } = json.data;

        easyDataInputJobs.value.data = _.concat(
          easyDataInputJobs.value.data,
          data,
        );
        easyDataInputJobs.value.pagy = pagy;
        if (data.length < 20) $state.complete();
        else {
          $state.loaded();
        }
      });
    } catch (error) {
      $state.error();
    }
  } else {
    $state.complete();
  }
};

async function savePreemption(jobId) {
  const requestPayload = {
    contentType: "CrowdsourcedDataField",
    contentId: props.dataField.localId,
  };

  await api.post(
    `admin/easy_data_input_jobs/${jobId}/easy_data_input_job_preemptions`,
    requestPayload,
  );
}
</script>
