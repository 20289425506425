<template>
  <div class="flex flex-col">
    <button
      @click="expanded = !expanded"
      type="button"
      class="flex items-center px-4 py-3 space-x-2 bg-indigo-50 border-y border-gray-300"
    >
      <ChevronDownIcon v-if="expanded" class="h-4 w-4 text-indigo-500" />
      <ChevronRightIcon v-else class="h-4 w-4 text-indigo-500" />
      <h1 class="text-lg leading-5 font-bold text-indigo-500 hover:underline">
        {{ subcategory.name }}
      </h1>
    </button>
    <ul v-if="expanded" class="px-4 py-3 space-y-2">
      <li v-for="article in subcategory.articles" :key="article.id">
        <a
          href=""
          @click.prevent="documentationStore.viewArticle(article)"
          class="text-sm font-medium text-indigo-600 hover:underline hover:text-indigo-800"
          >{{ article.name }}</a
        >
      </li>
    </ul>
  </div>
</template>

<script setup>
import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/vue/20/solid";
import { ref } from "vue";
import { useDocumentationStore } from "@/stores/documentation";

defineProps(["subcategory"]);

const documentationStore = useDocumentationStore();

const expanded = ref(false);
</script>
