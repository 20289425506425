<template>
  <div
    class="w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
    role="menu"
    aria-orientation="vertical"
    aria-labelledby="menu-button"
    tabindex="-1"
  >
    <div class="py-1" role="none">
      <a
        v-if="recipient.teamName"
        @click.prevent="viewTeam"
        v-close-popper
        href=""
        class="font-medium text-gray-700 hover:bg-gray-100 hover:text-gray-900 group flex items-center space-x-2 p-2 text-xs"
        role="menuitem"
        tabindex="-1"
        id="menu-item-0"
      >
        {{ recipient.name }} ({{ memberCount(recipient) }})
      </a>
      <div
        v-else
        class="font-medium text-gray-700 group flex items-center p-2 text-xs"
        role="menuitem"
        tabindex="-1"
        id="menu-item-0"
      >
        {{ recipient.name }}
      </div>
    </div>
    <div v-if="!menuDisabled" class="py-1" role="none">
      <a
        @click.prevent="revoke"
        v-close-popper
        href=""
        class="text-gray-700 hover:bg-gray-100 hover:text-gray-900 group flex items-center space-x-2 p-2 text-xs"
        role="menuitem"
        tabindex="-1"
        id="menu-item-0"
        :data-test="`sharing-recipient-${index}-revoke-access-button`"
      >
        <EyeSlashIcon class="w-4 h-4 text-gray-400 group-hover:text-gray-500" />
        <span>Remove access</span>
      </a>
    </div>
  </div>
</template>

<script setup>
import api from "@/router/api";
import { EyeSlashIcon } from "@heroicons/vue/20/solid";
import { useRoute, useRouter } from "vue-router";
import pluralize from "pluralize";
import _ from "lodash";

const props = defineProps(["recipient", "index", "menuDisabled"]);
const emit = defineEmits(["refetch-tokens"]);

async function revoke() {
  const endpoint = _.includes(
    ["TaskListAccessToken", "TaskListTeamAccessToken"],
    props.recipient.className
  )
    ? "task_list_sharings"
    : "safezone_data_field_sharings";
  api
    .delete(
      `${endpoint}/?token_id=${props.recipient.tokenId}&class_name=${props.recipient.className}`
    )
    .then(() => {
      emit("refetch-tokens");
    });
}

function memberCount(team) {
  return pluralize("Member", team.memberCount, true);
}

const route = useRoute();
const router = useRouter();

function viewTeam() {
  router.push({
    name: route.name,
    query: {
      ...route.query,
      horizontalTab: "Me",
      verticalTab: "Teams",
    },
  });
}
</script>
