import { ref } from "vue";
import { defineStore, acceptHMRUpdate } from "pinia";
import {
  StarIcon,
  NewspaperIcon,
  QuestionMarkCircleIcon,
  BoltIcon,
} from "@heroicons/vue/24/outline";
import { useRouter } from "vue-router";

export const useMobileNavigationStore = defineStore("mobileNavigation", () => {
  const selectedMobileTab = ref("News");

  const router = useRouter();
  const mobilePages = ref([
    {
      name: "News",
      href: "",
      handler: () => {
        router.push({ path: "/" });
        selectedMobileTab.value = "News";
      },
      icon: NewspaperIcon,
    },
    {
      name: "Tasks",
      href: "",
      handler: () => {
        router.push({ path: "/" });
        selectedMobileTab.value = "Tasks";
      },
      icon: StarIcon,
    },
    {
      name: "Add Data",
      href: "",
      handler: () => router.push({ name: "DataEasyButton" }),
      icon: BoltIcon,
    },
    {
      name: "Help",
      href: "",
      handler: () => router.push({ name: "HelpCenter" }),
      icon: QuestionMarkCircleIcon,
    },
  ]);

  return {
    selectedMobileTab,
    mobilePages,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useMobileNavigationStore, import.meta.hot),
  );
}
