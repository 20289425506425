<template>
  <div>
    <div class="relative">
      <div class="absolute inset-0 flex items-center" aria-hidden="true">
        <div class="w-full border-t border-gray-300" />
      </div>
      <div class="relative flex justify-center">
        <span class="bg-white px-2 text-xs text-gray-500">Legend</span>
      </div>
    </div>
    <div class="mt-4 text-xs font-semibold text-gray-900">
      Number of key data types present:
    </div>
    <div
      class="mt-2 min-w-0 flex-1 grid grid-cols-5 gap-1 max-h-20 overflow-y-auto"
    >
      <div
        v-for="(style, index) in layerStyles"
        :key="index"
        class="space-y-1.5 flex flex-col items-center"
      >
        <div
          class="h-4 w-4 rounded-md ring-1 ring-inset ring-black ring-opacity-0"
          :class="[style.color]"
        />
        <div
          class="px-0.5 md:flex md:justify-between md:space-x-2 2xl:space-x-0 2xl:block"
        >
          <div class="text-xs w-6 text-center font-medium text-gray-700">
            {{ style.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const layerStyles = [
  {
    name: "4",
    color: "bg-blue-500",
  },
  {
    name: "3",
    color: "bg-green-500",
  },
  {
    name: "2",
    color: "bg-yellow-500",
  },
  {
    name: "1",
    color: "bg-orange-500",
  },
  {
    name: "0",
    color: "bg-red-500",
  },
];
</script>
