<template>
  <div class="flex-grow">
    <div class="px-4 py-3">
      <MarkdownArticle :article="article" :tab-view="true" />
    </div>
  </div>
</template>

<script setup>
import { useDocumentationStore } from "@/stores/documentation";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import _ from "lodash";
import MarkdownArticle from "@/components/help-center/MarkdownArticle.vue";

const props = defineProps(["articleId"]);

const documentationStore = useDocumentationStore();
const { articles } = storeToRefs(documentationStore);

const article = computed(() => {
  return _.find(articles.value, function (articleObj) {
    return _.lowerCase(articleObj.name) === _.lowerCase(props.articleId);
  });
});
</script>
