<template>
  <main v-if="authenticateActive && !signedIn" class="h-full flex flex-1">
    <nav aria-label="Sidebar" class="shrink-0 p-2 bg-gray-100 overflow-y-auto">
      <div class="relative flex flex-col space-y-2">
        <a
          v-for="tab in tabs"
          :key="tab.name"
          @click.prevent="selectTab(tab)"
          :href="tab.href"
          v-tooltip="tab.name"
          :class="
            selectedTab.name === tab.name
              ? 'text-gray-700 bg-gray-300'
              : 'text-gray-500 hover:bg-gray-200'
          "
          class="shrink-0 inline-flex items-center justify-center h-8 w-8 rounded-lg"
        >
          <span class="sr-only">{{ tab.name }}</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            :class="selectedTab.name === tab.name ? '' : ''"
            class="h-5 w-5"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 24 24"
            v-html="pathsForIcon(tab.svgPaths)"
          ></svg>
        </a>
      </div>
    </nav>
    <div class="flex-grow overflow-x-hidden">
      <component :is="selectedTab.component" v-bind="selectedTabProperties" />
    </div>
  </main>
</template>

<script setup>
import { useUserStore } from "@/stores/user";
import { useMainMapStore } from "@/stores/mainMap";
import { useSecondaryPanelStore } from "@/stores/secondaryPanel";
import { ref, computed, onMounted, watch, markRaw } from "vue";
import { useRouter, useRoute } from "vue-router";
import pathsForIcon from "@/assets/pathsForIcon";
import _ from "lodash";
import ForgotPassword from "@/views/ForgotPassword.vue";
import RegisterVue from "@/views/RegisterVue.vue";
import SignIn from "@/views/SignIn.vue";
import { storeToRefs } from "pinia";

const userStore = useUserStore();
const { signedIn } = storeToRefs(userStore);
const secondaryPanelStore = useSecondaryPanelStore();
const { authenticateActive } = storeToRefs(secondaryPanelStore);
const mapStore = useMainMapStore();
const { mapSearch } = storeToRefs(mapStore);

const tabs = [
  {
    name: "Sign Up",
    tabQuery: "Register",
    href: "",
    svgPaths: [
      "M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z",
    ],
    component: markRaw(RegisterVue),
  },
  {
    name: "Sign In",
    tabQuery: "SignIn",
    href: "",
    svgPaths: [
      "M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z",
    ],
    component: markRaw(SignIn),
  },
  {
    name: "Forgot Password",
    tabQuery: "ForgotPassword",
    href: "",
    svgPaths: [
      "M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z",
    ],
    component: markRaw(ForgotPassword),
  },
];
const selectedTab = ref({
  name: "Sign Up",
  tabQuery: "Register",
  href: "",
  svgPaths: [
    "M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z",
  ],
  component: markRaw(RegisterVue),
});
const selectedTabProperties = {};

const router = useRouter();
const route = useRoute();
const query = computed(() => route.query);

function selectTab(tab) {
  if (tab) selectedTab.value = tab;
  else if (_.get(query.value, "horizontalTab") === "Authenticate") {
    selectedTab.value = _.find(tabs, {
      tabQuery: query.value.verticalTab,
    });
  }

  if (authenticateActive.value) {
    router.push({
      name: route.name,
      query: {
        ...route.query,
        verticalTab: selectedTab.value?.tabQuery || "Register",
      },
    });
  }
}

onMounted(() => {
  selectTab();

  mapStore.clearSearchMarker();
  mapSearch.value = null;
});

watch(query, () => {
  selectTab();
});
</script>
