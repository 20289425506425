/* global L */
import { mount } from "mount-vue-component";
import app from "@/entrypoints/application";
import LocationMarker from "@/components/maps/LocationMarker.vue";

export default function locationMarker({
  classes,
  tooltip,
  interactive,
  propertyId,
  landCoveringId,
  mapStore,
  propertyFieldsStore,
}) {
  let markerVueEl;

  const { el } = mount(LocationMarker, {
    props: {
      classes,
      tooltip,
      interactive,
      propertyId,
      landCoveringId,
      mapStore,
      propertyFieldsStore,
    },
    app,
  });

  markerVueEl = el;

  if (markerVueEl) {
    return L.divIcon({
      className: "workspace-marker",
      html: markerVueEl,
    });
  } else {
    return null;
  }
}
