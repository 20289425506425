<template>
  <div>
    <dl class="grid grid-cols-2 gap-3">
      <a
        v-for="item in stats"
        @click.prevent="item.clickHandler"
        href=""
        :key="item.name"
        class="relative overflow-hidden rounded-lg bg-white hover:bg-gray-50 p-3 shadow"
        :data-test="`${item.name}-tasks-shortcut`"
      >
        <!-- Extend touch target to entire panel -->
        <span class="absolute inset-0" aria-hidden="true" />
        <div class="flex items-center justify-between">
          <div :class="`${item.iconColor}`" class="rounded-md p-1.5">
            <component
              :is="item.icon"
              class="h-5 w-5 text-white"
              aria-hidden="true"
            />
          </div>
          <dd
            v-if="item.name === 'Nearby' && zoom < 14"
            class="mt-1 text-sm font-semibold tracking-tight text-gray-900"
          >
            Zoom in
          </dd>
          <dd
            v-else
            class="mt-1 text-2xl font-semibold tracking-tight text-gray-900"
          >
            {{ item.count }}
          </dd>
        </div>
        <dt class="mt-2 truncate text-sm font-medium text-gray-500">
          {{ item.name }}
        </dt>
      </a>
    </dl>
  </div>
</template>

<script setup>
import {
  CalendarIcon,
  CalendarDaysIcon,
  InboxIcon,
  CheckIcon,
  GlobeAmericasIcon,
} from "@heroicons/vue/24/solid";
import { useTasksStore } from "@/stores/tasks";
import { useUserStore } from "@/stores/user";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { useMainMapStore } from "@/stores/mainMap";
import { storeToRefs } from "pinia";
import { computed, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import _ from "lodash";

const tasksStore = useTasksStore();
const {
  todayCount,
  scheduledCount,
  allCount,
  nearbyCount,
  todayTasks,
  scheduledTasks,
  incompleteTasks,
} = storeToRefs(tasksStore);
const userStore = useUserStore();
const { signedIn } = storeToRefs(userStore);
const layoutStore = useWorkspaceLayoutStore();
const { isDesktop } = storeToRefs(layoutStore);
const mapStore = useMainMapStore();
const { mapBoundaryMeta, mapSearch } = storeToRefs(mapStore);

onMounted(() => tasksStore.fetchUserTaskViewsMeta());

const router = useRouter();
const route = useRoute();

const zoom = computed(() => {
  return (
    _.get(route.query, "zoom") ||
    _.get(mapSearch.value, "zoom") ||
    _.get(mapBoundaryMeta.value, "zoom")
  );
});

const stats = computed(() =>
  _.compact([
    {
      name: "Today",
      count: signedIn.value ? todayCount.value : todayTasks.value.length,
      icon: CalendarIcon,
      iconColor: "bg-blue-500",
      clickHandler: () => navigationTo("Today"),
    },
    {
      name: "Scheduled",
      count: signedIn.value
        ? scheduledCount.value
        : scheduledTasks.value.length,
      icon: CalendarDaysIcon,
      iconColor: "bg-red-500",
      clickHandler: () => navigationTo("Scheduled"),
    },
    signedIn.value && isDesktop.value
      ? {
          name: "Nearby",
          count: nearbyCount.value,
          icon: GlobeAmericasIcon,
          iconColor: "bg-emerald-500",
          clickHandler: () => navigationTo("Nearby"),
        }
      : null,
    {
      name: "All",
      count: signedIn.value ? allCount.value : incompleteTasks.value.length,
      icon: InboxIcon,
      iconColor: "bg-gray-700",
      clickHandler: () => navigationTo("All"),
    },
    {
      name: "Completed",
      // count: signedIn.value ? completedCount.value : completedTasks.value.length,
      count: "",
      icon: CheckIcon,
      iconColor: "bg-gray-500",
      clickHandler: () => navigationTo("Completed"),
    },
  ])
);

function navigationTo(view) {
  router.push({
    name: route.name,
    query: {
      ...route.query,
      taskList: undefined,
      taskIds: undefined,
      relatedTaskIds: undefined,
      taskView: view,
    },
  });
}
</script>
