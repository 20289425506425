<template>
  <Combobox @update:modelValue="onSelect">
    <div class="relative" v-shortkey="['/']" @shortkey="focusInput">
      <MagnifyingGlassIcon
        class="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400"
        aria-hidden="true"
      />
      <ComboboxInput
        v-observe-visibility="{ callback: focusInput, once: true }"
        id="help-autocomplete-input"
        class="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
        :placeholder="placeholder"
        @change="helpQuery = $event.target.value"
        @keydown.escape="helpQuery = ''"
      />
    </div>

    <ComboboxOptions
      v-if="filteredArticles.length > 0"
      static
      class="max-h-72 scroll-py-2 overflow-y-auto py-2 text-sm text-gray-800"
    >
      <ComboboxOption
        v-for="article in filteredArticles"
        :key="article.id"
        :value="article"
        as="template"
        v-slot="{ active }"
      >
        <li
          :class="[
            'cursor-default select-none px-4 py-2',
            active && 'bg-indigo-600 text-white',
          ]"
        >
          {{ article.name }}
        </li>
      </ComboboxOption>
    </ComboboxOptions>

    <p
      v-if="helpQuery !== '' && filteredArticles.length === 0"
      class="p-4 text-sm text-gray-500"
    >
      Sorry! We looked everywhere, but could not find {{ helpQuery }}. 💔 🔍
    </p>
  </Combobox>
</template>

<script setup>
import { useDocumentationStore } from "@/stores/documentation";
import { storeToRefs } from "pinia";
import { MagnifyingGlassIcon } from "@heroicons/vue/20/solid";
import {
  Combobox,
  ComboboxInput,
  ComboboxOptions,
  ComboboxOption,
} from "@headlessui/vue";

defineProps(["placeholder"]);

const documentationStore = useDocumentationStore();
const { helpQuery, filteredArticles } = storeToRefs(documentationStore);

function focusInput(isVisible) {
  if (isVisible) {
    setTimeout(() => {
      selectInput();
    }, 100);
  }
}
function selectInput() {
  document.getElementById(`help-autocomplete-input`).focus();
}

function onSelect(record) {
  documentationStore.viewArticle(record);
}
</script>
