<template>
  <div
    v-observe-visibility="{ callback: selectiveFocus, once: true }"
    class="rounded-md border-l-2 border-yellow-500 bg-yellow-100 p-1"
  >
    <div class="ml-2 py-1 pr-1 flex flex-col space-y-1">
      <div class="flex items-center justify-between">
        <div class="text-sm font-medium text-gray-700">{{ phaseLabel }}</div>
        <div class="flex items-center space-x-2">
          <div
            v-if="occupierSpaceStore.impliedPhaseArea(phaseKey)"
            class="font-medium text-gray-900"
          >
            {{ occupierSpaceStore.impliedPhaseArea(phaseKey) }}
          </div>
          <button
            v-if="phaseKey !== 0"
            @click="occupierSpaceStore.removePhase(phaseKey)"
            type="button"
            class="inline-flex items-center text-gray-700 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
          >
            <XMarkIcon class="h-4 w-4" />
          </button>
        </div>
      </div>
      <div class="grid grid-cols-2 gap-x-2 gap-y-2">
        <div v-if="selectedSizeType.name === 'Area'" class="">
          <label
            :for="`phase-${phaseKey}-min-area`"
            class="block text-sm font-medium leading-6 text-gray-500"
            >Min. SF</label
          >
          <div class="mt-1">
            <input
              v-model="minArea"
              type="number"
              :name="`phase-${phaseKey}-min-area`"
              :id="`phase-${phaseKey}-min-area`"
              class="block w-full rounded-md border-0 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-yellow-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        <div v-if="selectedSizeType.name === 'Area'" class="">
          <label
            :for="`phase-${phaseKey}-max-area`"
            class="block text-sm font-medium leading-6 text-gray-500"
            >Max. SF</label
          >
          <div class="mt-1">
            <input
              v-model="maxArea"
              type="number"
              :name="`phase-${phaseKey}-max-area`"
              :id="`phase-${phaseKey}-max-area`"
              class="block w-full rounded-md border-0 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-yellow-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        <div v-if="selectedSizeType.name === 'Headcount'" class="">
          <label
            :for="`phase-${phaseKey}-min-headcount`"
            class="block text-sm font-medium leading-6 text-gray-500"
            >Min. People</label
          >
          <div class="mt-1">
            <input
              v-model="minHeadcount"
              type="number"
              :name="`phase-${phaseKey}-min-headcount`"
              :id="`phase-${phaseKey}-min-headcount`"
              class="block w-full rounded-md border-0 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-yellow-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        <div v-if="selectedSizeType.name === 'Headcount'" class="">
          <label
            :for="`phase-${phaseKey}-max-headcount`"
            class="block text-sm font-medium leading-6 text-gray-500"
            >Max. People</label
          >
          <div class="mt-1">
            <input
              v-model="maxHeadcount"
              type="number"
              :name="`phase-${phaseKey}-max-headcount`"
              :id="`phase-${phaseKey}-max-headcount`"
              class="block w-full rounded-md border-0 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-yellow-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        <div v-if="phaseKey !== 0" class="col-span-2">
          <label
            for="target-date"
            class="block text-sm font-medium leading-6 text-gray-500"
            >Target Date</label
          >
          <div class="mt-1">
            <input
              v-model="targetDate"
              type="date"
              name="target-date"
              id="target-date"
              class="block w-full rounded-md border-0 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-yellow-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { XMarkIcon } from "@heroicons/vue/20/solid";
import { useOccupierSpaceDefinitionStore } from "@/stores/occupierSpaceDefinition";
import { storeToRefs } from "pinia";
import { computed } from "vue";

const props = defineProps(["phaseKey", "phase"]);

const occupierSpaceStore = useOccupierSpaceDefinitionStore();
const { selectedSizeType, impliedPhaseArea } = storeToRefs(occupierSpaceStore);

const phaseLabel = computed(() =>
  props.phaseKey === 0 ? "Initial" : `Phase ${props.phaseKey + 1}`,
);
const minArea = computed({
  get() {
    return occupierSpaceStore.getPhase(props.phaseKey)?.minArea;
  },
  set(n) {
    if (occupierSpaceStore.getPhase(props.phaseKey)) {
      occupierSpaceStore.getPhase(props.phaseKey).minArea = n;
    }
  },
});
const maxArea = computed({
  get() {
    return occupierSpaceStore.getPhase(props.phaseKey)?.maxArea;
  },
  set(n) {
    if (occupierSpaceStore.getPhase(props.phaseKey)) {
      occupierSpaceStore.getPhase(props.phaseKey).maxArea = n;
    }
  },
});
const minHeadcount = computed({
  get() {
    return occupierSpaceStore.getPhase(props.phaseKey)?.minHeadcount;
  },
  set(n) {
    if (occupierSpaceStore.getPhase(props.phaseKey)) {
      occupierSpaceStore.getPhase(props.phaseKey).minHeadcount = n;
    }
  },
});
const maxHeadcount = computed({
  get() {
    return occupierSpaceStore.getPhase(props.phaseKey)?.maxHeadcount;
  },
  set(n) {
    if (occupierSpaceStore.getPhase(props.phaseKey)) {
      occupierSpaceStore.getPhase(props.phaseKey).maxHeadcount = n;
    }
  },
});
const targetDate = computed({
  get() {
    return occupierSpaceStore.getPhase(props.phaseKey)?.targetDate;
  },
  set(n) {
    if (occupierSpaceStore.getPhase(props.phaseKey)) {
      occupierSpaceStore.getPhase(props.phaseKey).targetDate = n;
    }
  },
});

function selectiveFocus(isVisible) {
  if (isVisible && props.phaseKey !== 0) {
    setTimeout(() => {
      selectInput();
    }, 100);
  }
}
function selectInput() {
  if (selectedSizeType.value.name === "Area") {
    document.getElementById(`phase-${props.phaseKey}-min-area`)?.focus();
  } else {
    document.getElementById(`phase-${props.phaseKey}-min-headcount`)?.focus();
  }
}
</script>
