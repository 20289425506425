<template>
  <div class="mt-4 p-2">
    <div class="flex items-center">
      <div class="flex-auto">
        <h1 class="text-base font-semibold leading-6 text-gray-900">Hunts</h1>
        <p class="mt-2 text-sm text-gray-700">
          Research companies pursuing investment and/or occupier opportunities.
        </p>
      </div>
    </div>
    <div v-if="zoom >= 11" class="mt-4 flow-root">
      <div
        v-if="refreshingMainTimeline"
        class="mt-6 flex h-full w-full items-center justify-center"
      >
        <SquareLoader :loading="true" size="32px" color="#0d9488" />
      </div>
      <div
        v-else-if="paginatedRecords.length > 0"
        class="-my-2 overflow-x-auto"
      >
        <div class="inline-block min-w-full py-2 align-middle">
          <table class="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th
                  scope="col"
                  class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Hunter
                </th>
                <th
                  scope="col"
                  class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Contacts
                </th>
                <th
                  scope="col"
                  class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Target Market(s)
                </th>
                <th
                  scope="col"
                  class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Requirement(s)
                </th>
                <th
                  v-if="false"
                  scope="col"
                  class="relative whitespace-nowrap py-3.5 pl-3"
                >
                  <span class="sr-only">Edit</span>
                </th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200 bg-white">
              <HuntTableRow
                v-for="(huntDataField, index) in paginatedRecords"
                :key="`${huntDataField.fieldContentId}_${index}`"
                :hunt-outer-data-field="huntDataField"
              />
            </tbody>
            <InfiniteLoading
              v-if="loaded && !reachedEnd"
              @infinite="infiniteNext"
            />
          </table>
        </div>
      </div>
      <div
        v-else
        class="relative block w-full rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        <FunnelIcon class="mx-auto h-12 w-12 text-gray-400" />
        <span class="mt-2 block text-sm font-medium text-gray-900">
          No matching hunts
        </span>
      </div>
    </div>
    <section v-else class="mt-4">
      <div
        class="relative block w-full rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        <MagnifyingGlassPlusIcon class="mx-auto h-12 w-12 text-gray-400" />
        <span class="mt-2 block text-sm font-medium text-gray-900">
          Zoom in more
        </span>
      </div>
    </section>
  </div>
</template>

<script setup>
import SquareLoader from "vue-spinner/src/SquareLoader.vue";
import HuntTableRow from "@/components/analyze/HuntTableRow.vue";
import { MagnifyingGlassPlusIcon, FunnelIcon } from "@heroicons/vue/24/outline";
import { useTimeTravelStore } from "@/stores/timeTravel";
import { useAnalyzePanelStore } from "@/stores/analyzePanel";
import { useMainMapStore } from "@/stores/mainMap";
import { storeToRefs } from "pinia";
import { onMounted, ref, computed, watch } from "vue";
import _ from "lodash";

const timeTravelStore = useTimeTravelStore();
const { refreshingMainTimeline } = storeToRefs(timeTravelStore);
const mapStore = useMainMapStore();
const { zoom, nearbyHuntDataFields } = storeToRefs(mapStore);
const analyzePanelStore = useAnalyzePanelStore();
const { lastCheckedPolygonArea } = storeToRefs(analyzePanelStore);

const nearbyPageIncrement = 5;
const fetchedNearbyIndex = ref(0);
const loaded = ref(false);
const reachedEnd = ref(false);
const paginatedRecords = ref([]);

const filteredHuntFields = computed(() => {
  return nearbyHuntDataFields.value.filter(({ fieldContentId: huntId }) =>
    analyzePanelStore.combinedFilteredHuntIncluded(huntId),
  );
});

const huntCount = computed(() => _.size(filteredHuntFields.value));

watch(huntCount, () => {
  if (loaded.value) {
    debouncedReset();
  }
});

watch(lastCheckedPolygonArea, () => {
  if (loaded.value) {
    debouncedReset();
  }
});

onMounted(() => {
  if (fetchedNearbyIndex.value === 0) {
    nextNearbyPage("initial");
  }
});

function reset() {
  paginatedRecords.value = [];
  fetchedNearbyIndex.value = 0;
  reachedEnd.value = false;
  nextNearbyPage();
}

const debouncedReset = _.debounce(function () {
  reset();
}, 2000);

const infiniteNext = async ($state) => {
  if (!reachedEnd.value) {
    try {
      nextNearbyPage();
    } catch (error) {
      $state.error();
    }
  } else {
    $state.complete();
  }
};

function nextNearbyPage(loadState = null) {
  const arr = filteredHuntFields.value;
  const length = arr.length;
  const start = fetchedNearbyIndex.value;
  const end = _.min([length, fetchedNearbyIndex.value + nearbyPageIncrement]);
  const newSlice = _.slice(arr, start, end);

  newSlice.forEach((element) => {
    paginatedRecords.value.push(element);
  });

  if (paginatedRecords.value.length !== length) {
    fetchedNearbyIndex.value = fetchedNearbyIndex.value + nearbyPageIncrement;
  } else {
    reachedEnd.value = true;
  }

  if (loadState === "initial") loaded.value = true;
}
</script>
