<template>
  <div class="pb-4 shadow-lg w-80 rounded-lg ring-1 ring-black ring-opacity-5">
    <div
      class="px-4 py-2 flex items-center justify-between bg-gray-100 border-b border-gray-200"
    >
      <h2 class="leading-6 font-medium text-gray-900">Citations Stage</h2>
      <button
        v-close-popper
        @click="getHelp"
        type="button"
        v-tooltip="'Get help'"
        class="justify-self-end"
      >
        <QuestionMarkCircleIcon class="h-5 w-5 text-gray-700" />
      </button>
    </div>

    <div class="bg-white mx-auto max-w-2xl">
      <ul
        role="list"
        class="mb-2 divide-y divide-gray-200 max-h-80 overflow-y-auto"
      >
        <li
          v-for="field in citationFields"
          :key="field.localId"
          class="flex items-center justify-between px-4 py-2"
        >
          <div class="flex-auto">
            <DataField
              :data-field="field"
              :citations-stage="true"
              text-classes="text-sm font-medium"
            />
            <p
              v-if="field.decoratingContentType"
              class="pl-1 text-gray-500 text-xs"
            >
              {{ _.startCase(field.decoratingContentType) }}
            </p>
          </div>
          <div class="flex items-center space-x-2">
            <button
              @click="remove(field)"
              v-tooltip="'Remove from stage'"
              type="button"
              class="h-5 w-5 p-1 inline-flex justify-center items-center text-sm text-gray-500 hover:text-gray-700"
            >
              <i class="fas fa-times" />
            </button>
          </div>
        </li>
        <li
          v-if="citationsCount === 0"
          class="text-center p-4"
          data-test="citations-stage-empty-data-field-state"
        >
          <BookmarkIcon class="mx-auto h-12 w-12 text-gray-400" />

          <h3 class="mt-2 text-sm font-medium text-gray-900">Stage is empty</h3>
          <p class="mt-1 text-sm text-gray-500">
            Add datapoints, then link them to a citation.
          </p>
        </li>
      </ul>

      <div class="flex items-center space-x-2 px-4">
        <CitationForm
          v-if="citationsCount > 0"
          :change-ids="citationChangeIds"
          class="flex-grow flex items-center"
          @refetch="clearStage"
        >
          <template v-slot:button>
            <button
              type="button"
              class="flex-grow rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50"
              data-test="pending-validations-bulk-add-citation-button"
            >
              Add citation
            </button>
          </template>
        </CitationForm>
        <button
          v-if="citationsCount > 0"
          @click="clearStage"
          v-tooltip="'Clear stage'"
          type="button"
          class="inline-flex flex-shrink-0 items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          <svg
            class="h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M8.75 1A2.75 2.75 0 006 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 10.23 1.482l.149-.022.841 10.518A2.75 2.75 0 007.596 19h4.807a2.75 2.75 0 002.742-2.53l.841-10.52.149.023a.75.75 0 00.23-1.482A41.03 41.03 0 0014 4.193V3.75A2.75 2.75 0 0011.25 1h-2.5zM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4zM8.58 7.72a.75.75 0 00-1.5.06l.3 7.5a.75.75 0 101.5-.06l-.3-7.5zm4.34.06a.75.75 0 10-1.5-.06l-.3 7.5a.75.75 0 101.5.06l.3-7.5z"
              clip-rule="evenodd"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import CitationForm from "@/components/crowdsourcing/CitationForm.vue";
import DataField from "@/components/crowdsourcing/DataField.vue";
import { useCitationStore } from "@/stores/citation";
import { useDocumentationStore } from "@/stores/documentation";
import { storeToRefs } from "pinia";
import { QuestionMarkCircleIcon } from "@heroicons/vue/20/solid";
import { addACitation } from "@/assets/documentation/articles";
import { BookmarkIcon } from "@heroicons/vue/24/outline";
import _ from "lodash";

const citationStore = useCitationStore();
const { citationFields, citationsCount, citationChangeIds } =
  storeToRefs(citationStore);
const documentationStore = useDocumentationStore();
function getHelp() {
  documentationStore.viewArticle(addACitation);
}

function remove(field) {
  citationStore.removeCitationDataField([field]);
}

function clearStage() {
  citationStore.resetCitationType();
  citationStore.clearCitation();
  citationStore.resetFields();
}
</script>
