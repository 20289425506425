<template>
  <div v-if="loaded">
    <div v-if="editing" class="flex flex-col space-y-1">
      <div class="text-sm font-medium text-gray-500">
        {{ _.startCase(fieldName) }}
      </div>
      <input
        v-focus
        v-model="name"
        type="text"
        :name="`${contentType}-${fieldName}`"
        :id="`${contentType}-${fieldName}`"
        class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-sm border-gray-300 rounded-md"
        :data-test="`content-${fieldName}-input`"
      />
      <div class="flex items-center justify-end space-x-2">
        <button
          @click="cancel"
          type="button"
          class="inline-flex items-center p-1 border border-gray-300 rounded-full shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <XMarkIcon class="h-4 w-4" />
        </button>

        <DataVisibilityButton
          v-if="complete"
          :visibility="visibility"
          tooltip="Save"
          class="inline-flex"
        >
          <template v-slot:button>
            <button
              @click="save"
              :disabled="originatingData"
              type="button"
              :class="
                visibility === 'safezone'
                  ? 'bg-yellow-500 hover:bg-yellow-600 focus:ring-yellow-600'
                  : 'bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500'
              "
              class="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2"
              :data-test="`content-${fieldName}-save`"
            >
              <PulseLoader
                v-if="originatingData"
                :loading="true"
                size="3px"
                color="#f3f4f6"
              />
              <CheckIcon v-else class="h-4 w-4" />
            </button>
          </template>
        </DataVisibilityButton>
      </div>
    </div>

    <div v-show="dataField && !editing" class="col-span-1">
      <div class="flex justify-between">
        <dt class="text-sm font-medium text-gray-500">
          {{ _.startCase(fieldName) }}
        </dt>
        <a
          v-if="fieldName === 'street_address'"
          @click.prevent="editName"
          href=""
          class="text-sm font-medium text-indigo-600 hover:text-indigo-500"
          id="address-not-name"
          >Looking for name?</a
        >
      </div>
      <dd class="mt-1">
        <DataField
          :data-field="dataField"
          primary-text-path="fieldValue"
          text-classes="text-sm"
          :dismiss-on-save="true"
          @dismiss="dismiss"
          @suggest-change="suggestChange"
          @completed="completed"
        />
      </dd>
    </div>

    <div v-if="!dataField && !editing" class="flex items-center">
      <DataVisibilityButton :visibility="visibility">
        <template v-slot:button>
          <button
            @click="edit"
            :disabled="originatingData"
            type="button"
            :data-test="`content-${fieldName}-add-button`"
            class="group py-1 px-1.5 flex items-center justify-between rounded-full border border-gray-300 shadow-sm space-x-2 text-left hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <PulseLoader
              v-if="originatingData"
              :loading="true"
              size="3px"
              color="#f3f4f6"
            />
            <span v-else class="min-w-0 flex-1 flex items-center space-x-1">
              <span class="flex-shrink-0 flex items-center">
                <span
                  :class="
                    visibility === 'safezone'
                      ? 'bg-yellow-500'
                      : 'bg-indigo-600'
                  "
                  class="inline-flex items-center justify-center h-5 w-5 rounded-full"
                >
                  <PencilIcon class="h-3 w-3 text-white" />
                </span>
              </span>
              <span class="min-w-0 flex-1">
                <span class="text-sm font-medium text-gray-900 truncate"
                  >Add {{ _.startCase(fieldName) }}</span
                >
              </span>
            </span>
          </button>
        </template>
      </DataVisibilityButton>
    </div>
  </div>
</template>

<script setup>
import { XMarkIcon, CheckIcon } from "@heroicons/vue/20/solid";
import { PencilIcon } from "@heroicons/vue/24/outline";
import DataField from "@/components/crowdsourcing/DataField.vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { useCrowdsourcedChangeGroupStore } from "@/stores/crowdsourcedChangeGroup";
import { usePropertyDiagramStore } from "@/stores/propertyDiagram";
import { useCompanyDetailStore } from "@/stores/companyDetail";
import { useContactDetailStore } from "@/stores/contactDetail";
import { useUserAvailableBalancesChannelStore } from "@/stores/userAvailableBalancesChannel";
import api from "@/router/api";
import { computed, onMounted, ref, watch } from "vue";
import { storeToRefs } from "pinia";
import _ from "lodash";
import DataVisibilityButton from "@/components/crowdsourcing/DataVisibilityButton.vue";
import { useRoute, useRouter } from "vue-router";

const props = defineProps(["wrappingDataField", "fieldName"]);
const editing = ref(false);
const dismissOnSaveId = ref(null);
const name = ref("");
const dataField = ref(null);
const loaded = ref(false);

const changeGroupStore = useCrowdsourcedChangeGroupStore();
const { originatingData, changeGroupId } = storeToRefs(changeGroupStore);
const propertyDiagramStore = usePropertyDiagramStore();
const contactDetailStore = useContactDetailStore();
const companyDetailStore = useCompanyDetailStore();
const userAvailableBalancesChannelStore =
  useUserAvailableBalancesChannelStore();
const { userAvailableBalancesChannelDataQueue } = storeToRefs(
  userAvailableBalancesChannelStore,
);
const visibility = computed(() => {
  if (_.includes(["name", "street_address"], props.fieldName)) {
    const autoFree = _.includes(
      ["Property", "PropertyRight", "LandCovering", "FloorArea"],
      contentType.value,
    );
    const outerApproved =
      props.wrappingDataField.state === "approved" &&
      _.includes(["Company", "Contact"], contentType.value);

    if (autoFree || outerApproved) {
      return "public";
    } else {
      return "safezone";
    }
  } else if (_.includes(["ticker_symbol"], props.fieldName)) {
    return "public";
  } else {
    return "safezone";
  }
});

const contentType = computed(() => props.wrappingDataField.fieldContentType);
const contentId = computed(() => props.wrappingDataField.fieldContent.id);
const combinedId = computed(() => `${contentType.value}${contentId.value}`);
const complete = computed(() => {
  const filled = _.trim(name.value) !== "";
  const changed = hasFieldValue.value
    ? _.trim(name.value) !== _.trim(fieldValue.value)
    : true;
  return filled && changed;
});
const hasFieldValue = computed(
  () => fieldValue.value && _.trim(fieldValue.value) !== "",
);
const fieldValue = computed(() => _.get(dataField.value, "fieldValue"));

watch(combinedId, () => {
  fetchDataField();
});
watch(userAvailableBalancesChannelDataQueue, () => {
  const data = _.last(userAvailableBalancesChannelDataQueue.value);
  if (data.triggeredBy === "staked_changes") {
    fetchDataField();
  }
});

onMounted(() => {
  fetchDataField();
});

async function fetchDataField(emitPayload = {}) {
  api
    .get(
      `crowdsourced_data_fields/${contentType.value}/${contentId.value}?field_name=${props.fieldName}`,
    )
    .then((json) => {
      dataField.value = json.data;
      loaded.value = true;

      if (_.get(emitPayload, "replaceAfterFetch", false)) editing.value = true;
    });
}
function completed() {
  fetchDataField();
}
async function dismiss(
  id,
  successCallback = (json) => propertyDiagramStore.postEditingPatch(json),
) {
  if (!id) return;

  await changeGroupStore.dismissData({
    dataFieldId: id,
    successCallback: successCallback,
  });
}
function persist() {
  const payload = {
    name: name.value,
    nameType: props.fieldName,
  };
  return api.post(
    `content_names/${contentType.value}/${contentId.value}`,
    _.merge({}, payload, { changeGroupId: changeGroupId.value }),
  );
}
function afterPersist(json) {
  propertyDiagramStore.postEditingPatch(json);
  contactDetailStore.postEditingPatch(json);
  companyDetailStore.postEditingPatch(json, contentType.value === "Hunt");
  fetchDataField();
  cancel();
  // $store.dispatch("flash", "Saved!");
}
async function suggestChange(emitPayload) {
  if (_.get(emitPayload, "dismissOnSave", false)) {
    dismissOnSaveId.value = dataField.value.localId;
    name.value = fieldValue.value;
    editing.value = true;
  } else {
    await dismiss(dataField.value.localId, async () =>
      fetchDataField({ replaceAfterFetch: true }),
    );
  }
}
function edit() {
  editing.value = true;
}
function cancel() {
  name.value = "";
  dismissOnSaveId.value = null;
  editing.value = false;
}

async function save() {
  if (complete.value) {
    await dismiss(dismissOnSaveId.value);

    const apiRequestFunc = () => persist();
    const successCallback = (json) => afterPersist(json);
    const failureCallback = () => cancel();
    await changeGroupStore.originateData(
      apiRequestFunc,
      successCallback,
      failureCallback,
    );
  }
}

const router = useRouter();
const route = useRoute();
function editName() {
  router.push({
    name: route.name,
    query: {
      ...route.query,
      verticalTab: "Appearance",
    },
  });
}
</script>
