import FilterTab from "@/components/analyze/FilterTab.vue";
import LayerTab from "@/components/analyze/LayerTab.vue";
import { markRaw } from "vue";
import _ from "lodash";

export function initialSelectedTabFor(routeName) {
  if (routeName === "MainMap") {
    return _.get(rawTabs, "filter");
  } else {
    return _.get(rawTabs, "filter");
  }
}

export function tabsFor(routeName) {
  switch (routeName) {
    case "MainMap":
    case "CompanyShell":
    case "ContactShell":
      return [_.get(rawTabs, "filter"), _.get(rawTabs, "layer")];
    default:
      return [_.get(rawTabs, "filter")];
  }
}

export function tabPropertiesFor() {
  return {};
}

export const rawTabs = {
  filter: {
    name: "Filter",
    tabQuery: "Filter",
    href: "",
    svgPaths: [
      "M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 01-.659 1.591l-5.432 5.432a2.25 2.25 0 00-.659 1.591v2.927a2.25 2.25 0 01-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 00-.659-1.591L3.659 7.409A2.25 2.25 0 013 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0112 3z",
    ],
    component: markRaw(FilterTab),
  },
  layer: {
    name: "Layer",
    tabQuery: "Layer",
    href: "",
    svgPaths: [
      "M6.429 9.75 2.25 12l4.179 2.25m0-4.5 5.571 3 5.571-3m-11.142 0L2.25 7.5 12 2.25l9.75 5.25-4.179 2.25m0 0L21.75 12l-4.179 2.25m0 0 4.179 2.25L12 21.75 2.25 16.5l4.179-2.25m11.142 0-5.571 3-5.571-3",
    ],
    component: markRaw(LayerTab),
  },
};
