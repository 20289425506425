<template>
  <dl
    :class="[workspaceLayout === 'sideBySide' ? 'grid-cols-2' : 'grid-cols-4']"
    class="mt-2 mx-auto grid gap-px bg-gray-900/5"
  >
    <div
      v-for="stat in stats"
      :key="stat.name"
      class="gap-x-3 gap-y-2 bg-white p-3"
    >
      <div class="flex flex-col">
        <div class="flex items-baseline justify-between">
          <dt class="text-sm font-medium leading-6 text-gray-500">
            {{ stat.name }}
          </dt>
          <button
            v-if="stat.helpArticle"
            @click="documentationStore.viewArticle(stat.helpArticle)"
            type="button"
            v-tooltip="'Get help'"
          >
            <QuestionMarkCircleIcon class="h-5 w-5 text-gray-700" />
          </button>
        </div>
        <div class="flex items-end space-x-3">
          <dd
            :class="
              _.includes(['Available balance', 'Reputation score'], stat.name)
                ? 'text-4xl font-bold'
                : 'text-3xl font-medium'
            "
            class="tracking-tight text-gray-900"
          >
            {{ stat.value }}
          </dd>
          <div
            v-if="stat.secondaryValue || stat.tertiaryValue"
            class="flex flex-col"
          >
            <dd
              v-if="stat.secondaryValue"
              class="text-sm tracking-tight font-medium text-gray-600"
            >
              {{ stat.secondaryValue }}
            </dd>
            <dd
              v-if="stat.tertiaryValue"
              v-tooltip="
                stat.name === 'Reputation score' ? 'Originator/Validator' : ''
              "
              class="text-sm tracking-tight font-medium text-gray-600"
            >
              {{ stat.tertiaryValue }}
            </dd>
          </div>
        </div>
        <div
          v-if="displayable && stat.chartId"
          :id="stat.chartId"
          v-observe-visibility="{
            callback: (isVisible, entry) =>
              drawChart(
                isVisible,
                entry,
                stat.chartId,
                stat.chartData,
                stat.chartLayout,
              ),
            once: true,
          }"
          class="h-36 w-full"
        />
      </div>
    </div>
  </dl>
</template>

<script setup>
import Plotly from "plotly.js-dist-min";
import moment from "moment";
import { QuestionMarkCircleIcon } from "@heroicons/vue/20/solid";
import { ref, computed, watch, onMounted, nextTick } from "vue";
import { storeToRefs } from "pinia";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { useDocumentationStore } from "@/stores/documentation";
import { useUserAvailableBalancesChannelStore } from "@/stores/userAvailableBalancesChannel";
import { useUserStore } from "@/stores/user";
import { currencyAmount } from "@/assets/numberHelpers";
import { understandReputation } from "@/assets/documentation/articles/understandReputation";
import { howToEarnOnTowerHunt } from "@/assets/documentation/articles/howToEarnOnTowerHunt";
import { understandPayAsYouGoPricing } from "@/assets/documentation/articles/understandPayAsYouGoPricing";
import { typesOfPaidData } from "@/assets/documentation/articles/typesOfPaidData";
import pluralize from "pluralize";
import api from "@/router/api";
import _ from "lodash";

const props = defineProps(["timePeriod"]);

const documentationStore = useDocumentationStore();
const layoutStore = useWorkspaceLayoutStore();
const { workspaceLayout, workspaceResized } = storeToRefs(layoutStore);
const userStore = useUserStore();
const { availableBalance, reputation, reputable, correctPercentage } =
  storeToRefs(userStore);
const userAvailableBalancesChannelStore =
  useUserAvailableBalancesChannelStore();
const { userAvailableBalancesChannelDataQueue } = storeToRefs(
  userAvailableBalancesChannelStore,
);

const displayable = ref(false);
const unitPrice = 0.00001;
const platformFee = 0.3;
const dateFormat = computed(() => {
  // For more time formatting types, see: https://github.com/d3/d3-time-format/blob/master/README.md
  switch (props.timePeriod) {
    case "1d":
      return "%I:%M %p";
    case "7d":
    case "4w":
    case "3m":
    case "12m":
    default:
      return "%b %d";
  }
});
const statsData = ref({
  reputationMultipliers: {
    originatorStake: 0.0,
    validatorStake: 0.0,
    originatorReward: 0.0,
    validatorReward: 0.0,
  },
  revenueShares: [],
  dataUsage: [],
  taskUsage: [],
  contributions: [],
  validations: [],
  changes: [],
});
const reputationMultipliers = computed(
  () => statsData.value.reputationMultipliers,
);
const multipliersString = computed(() => {
  const { originatorStake, validatorStake, originatorReward, validatorReward } =
    reputationMultipliers.value;

  return `${_.round(originatorReward)}x/${_.round(
    validatorReward,
  )}x rewards, ${_.round(originatorStake)}x/${_.round(validatorStake)}x stakes`;
});
const revenueShares = computed(() => statsData.value.revenueShares);
const dataUsage = computed(() => statsData.value.dataUsage);
const taskUsage = computed(() => statsData.value.taskUsage);
const validations = computed(() => statsData.value.validations);
const changes = computed(() => statsData.value.changes);
const publishableChanges = computed(() =>
  changes.value.filter((record) => record.platformStatus === "staked"),
);
const publishableSummary = computed(() => {
  if (_.size(publishableChanges.value) > 0) {
    return pluralize(
      "publishable change",
      _.size(publishableChanges.value),
      true,
    );
  } else {
    return null;
  }
});
const pendingChanges = computed(() =>
  changes.value.filter((record) => record.validationOutcome === null),
);
const groupedPendingChanges = computed(() => {
  return _.groupBy(pendingChanges.value, dayStart);
});
const acceptedChanges = computed(() =>
  changes.value.filter((record) => record.validationOutcome === "accepted"),
);
const groupedAcceptedChanges = computed(() => {
  return _.groupBy(acceptedChanges.value, dayStart);
});
const rejectedChanges = computed(() =>
  changes.value.filter((record) => record.validationOutcome === "rejected"),
);
const groupedRejectedChanges = computed(() => {
  return _.groupBy(rejectedChanges.value, dayStart);
});
const pendingValidations = computed(() =>
  validations.value.filter((record) => record.result === null),
);
const groupedPendingValidations = computed(() => {
  return _.groupBy(pendingValidations.value, dayStart);
});
const correctValidations = computed(() =>
  validations.value.filter((record) => record.result === "correct"),
);
const groupedCorrectValidations = computed(() => {
  return _.groupBy(correctValidations.value, dayStart);
});
const incorrectValidations = computed(() =>
  validations.value.filter((record) => record.result === "incorrect"),
);
const groupedIncorrectValidations = computed(() => {
  return _.groupBy(incorrectValidations.value, dayStart);
});
const validationChartData = computed(() => {
  var trace1 = {
    x: _.keys(groupedAcceptedChanges.value),
    y: _.values(groupedAcceptedChanges.value).map((recordArr) =>
      _.size(recordArr),
    ),
    name: "Cx Accepted",
    marker: { color: "#818cf8" },
    type: "bar",
  };

  var trace2 = {
    x: _.keys(groupedCorrectValidations.value),
    y: _.values(groupedCorrectValidations.value).map((recordArr) =>
      _.size(recordArr),
    ),
    name: "Vx Consensus",
    marker: { color: "#fcd34d" },
    type: "bar",
  };

  var trace3 = {
    x: _.keys(groupedRejectedChanges.value),
    y: _.values(groupedRejectedChanges.value).map((recordArr) =>
      _.size(recordArr),
    ),
    name: "Cx Rejected",
    marker: { color: "#f87171" },
    type: "bar",
  };

  var trace4 = {
    x: _.keys(groupedIncorrectValidations.value),
    y: _.values(groupedIncorrectValidations.value).map((recordArr) =>
      _.size(recordArr),
    ),
    name: "Vx Minority",
    marker: { color: "#fb923c" },
    type: "bar",
  };

  return [trace1, trace2, trace3, trace4];
});

const paidEarnings = computed(() => {
  return revenueShares.value.filter((share) => {
    return !share.free && share.state === "paid";
  });
});
const groupedDistributedEarnings = computed(() => {
  return _.groupBy(paidEarnings.value, dayStart);
});
const unpaidEarnings = computed(() => {
  return revenueShares.value.filter((share) => {
    return !share.free && share.state === "unpaid";
  });
});
const groupedUndistributedEarnings = computed(() => {
  return _.groupBy(unpaidEarnings.value, dayStart);
});
const paidNetEarnings = computed(() => {
  return `$${_.round(toDollar(paidEarnings.value) * (1 - platformFee), 2)}`;
});
const unpaidNetEarnings = computed(() => {
  return `$${_.round(toDollar(unpaidEarnings.value) * (1 - platformFee), 2)}`;
});

const distributionChartData = computed(() => {
  var trace1 = {
    x: _.keys(groupedDistributedEarnings.value),
    y: _.values(groupedDistributedEarnings.value).map(toDollar),
    name: "Paid (gross)",
    marker: { color: "#34d399" },
    type: "bar",
  };

  var trace2 = {
    x: _.keys(groupedUndistributedEarnings.value),
    y: _.values(groupedUndistributedEarnings.value).map(toDollar),
    name: "Pending (gross)",
    marker: { color: "#a3a3a3" },
    type: "bar",
  };

  return [trace1, trace2];
});

const dollarValueEarnings = computed(() => {
  return toDollar(revenueShares.value);
});
const grossEarnings = computed(() => {
  const rewardedValidations = _.sum(
    _.values(groupedCorrectValidations.value).map((validations) => {
      return _.sumBy(validations, reward);
    }),
  );
  const rewardedContributions = _.sum(
    _.values(groupedAcceptedChanges.value).map((validations) => {
      return _.sumBy(validations, reward);
    }),
  );
  const combined =
    dollarValueEarnings.value + rewardedValidations + rewardedContributions;
  return `$${_.round(combined, 3)}`;
});
const freeGrossEarnings = computed(() =>
  revenueShares.value.filter((record) => record.free),
);
const groupedFreeEarnings = computed(() => {
  return _.groupBy(freeGrossEarnings.value, dayStart);
});
const paidGrossEarnings = computed(() =>
  revenueShares.value.filter((record) => !record.free),
);
const groupedPaidEarnings = computed(() => {
  return _.groupBy(paidGrossEarnings.value, dayStart);
});
const grossEarningsChartData = computed(() => {
  var trace1 = {
    x: _.keys(groupedFreeEarnings.value),
    y: _.values(groupedFreeEarnings.value).map(toDollar),
    name: "Free",
    marker: { color: "#a3a3a3" },
    type: "bar",
  };

  var trace2 = {
    x: _.keys(groupedCorrectValidations.value),
    y: _.values(groupedCorrectValidations.value).map((validations) => {
      return _.sumBy(validations, reward);
    }),
    name: "Vx Rewards",
    marker: { color: "#34d399" },
    type: "bar",
  };

  var trace3 = {
    x: _.keys(groupedAcceptedChanges.value),
    y: _.values(groupedAcceptedChanges.value).map((fieldChanges) => {
      return _.sumBy(fieldChanges, reward);
    }),
    name: "Cx Rewards",
    marker: { color: "#fcd34d" },
    type: "bar",
  };

  var trace4 = {
    x: _.keys(groupedPaidEarnings.value),
    y: _.values(groupedPaidEarnings.value).map(toDollar),
    name: "$$",
    marker: { color: "#818cf8" },
    type: "bar",
  };

  return [trace1, trace2, trace3, trace4];
});
const spending = computed(() => {
  const pendingValidations = _.sum(
    _.values(groupedPendingValidations.value).map((validations) => {
      return _.sumBy(validations, stake);
    }),
  );
  const pendingContributions = _.sum(
    _.values(groupedPendingChanges.value).map((validations) => {
      return _.sumBy(validations, stake);
    }),
  );
  const forfeitedValidations = _.sum(
    _.values(groupedIncorrectValidations.value).map((validations) => {
      return _.sumBy(validations, stake);
    }),
  );
  const forfeitedContributions = _.sum(
    _.values(groupedRejectedChanges.value).map((validations) => {
      return _.sumBy(validations, stake);
    }),
  );
  const combined =
    toDollar(dataUsage.value) +
    toDollar(taskUsage.value) +
    pendingContributions +
    pendingValidations +
    forfeitedValidations +
    forfeitedContributions;
  return `$${_.round(combined, 3)}`;
});
const groupedDataUsage = computed(() => {
  return _.groupBy(dataUsage.value, dayStart);
});
const groupedTaskUsage = computed(() => {
  return _.groupBy(taskUsage.value, dayStart);
});
const spendingChartData = computed(() => {
  var trace1 = {
    x: _.keys(groupedDataUsage.value),
    y: _.values(groupedDataUsage.value).map(toDollar),
    name: "Data",
    marker: { color: "#818cf8" },
    type: "bar",
  };

  var trace2 = {
    x: _.keys(groupedPendingValidations.value),
    y: _.values(groupedPendingValidations.value).map((validations) => {
      return _.sumBy(validations, stake);
    }),
    name: "Pending Vx Stakes",
    marker: { color: "#86efac" },
    type: "bar",
  };

  var trace3 = {
    x: _.keys(groupedIncorrectValidations.value),
    y: _.values(groupedIncorrectValidations.value).map((validations) => {
      return _.sumBy(validations, stake);
    }),
    name: "Vx Forfeits",
    marker: { color: "#fb923c" },
    type: "bar",
  };

  var trace4 = {
    x: _.keys(groupedPendingChanges.value),
    y: _.values(groupedPendingChanges.value).map((fieldChanges) => {
      return _.sumBy(fieldChanges, stake);
    }),
    name: "Pending Cx Stakes",
    marker: { color: "#f9a8d4" },
    type: "bar",
  };

  var trace5 = {
    x: _.keys(groupedRejectedChanges.value),
    y: _.values(groupedRejectedChanges.value).map((fieldChanges) => {
      return _.sumBy(fieldChanges, stake);
    }),
    name: "Cx Forfeits",
    marker: { color: "#f87171" },
    type: "bar",
  };

  var trace6 = {
    x: _.keys(groupedTaskUsage.value),
    y: _.values(groupedTaskUsage.value).map(toDollar),
    name: "Tasks",
    marker: { color: "#fcd34d" },
    type: "bar",
  };

  return [trace1, trace2, trace3, trace4, trace5, trace6];
});
const reputablePercentage = computed(() => {
  if (correctPercentage.value) {
    const formatted = currencyAmount(correctPercentage.value * 100, 0);

    if (formatted > 60) {
      return `Reputable (${formatted}%)`;
    } else {
      return `Low credibility (${formatted}%)`;
    }
  } else if (reputable.value) {
    return "Establishing reputation";
  } else {
    return "Low credibility";
  }
});
const stats = computed(() => {
  return [
    {
      name: "Available balance",
      value: `$${currencyAmount(availableBalance.value, 2)}`,
      secondaryValue: `${paidNetEarnings.value} paid (net)`,
      tertiaryValue: `${unpaidNetEarnings.value} pending (net)`,
      helpArticle: understandPayAsYouGoPricing,
      change: "+54.02%",
      changeType: "negative",
      chartId: "distributions-chart",
      chartData: distributionChartData.value,
      chartLayout: {
        yaxis: {
          tickformat: "$.2f", // For more formatting types, see: https://github.com/d3/d3-format/blob/master/README.md#locale_format
        },
        xaxis: {
          tickformat: dateFormat.value,
        },
        barmode: "stack",
        showlegend: true,
        legend: {
          orientation: "h",
          y: -0.2,
          font: {
            size: 10,
          },
        },
      },
    },
    {
      name: "Reputation score",
      value: currencyAmount(reputation.value, 1),
      secondaryValue: reputablePercentage.value,
      tertiaryValue: multipliersString.value,
      helpArticle: understandReputation,
      change: "+4.75%",
      changeType: "positive",
      chartId: "accuracy-chart",
      chartData: validationChartData.value,
      chartLayout: {
        barmode: "stack",
        showlegend: true,
        xaxis: {
          tickformat: dateFormat.value,
        },
        legend: {
          orientation: "h",
          y: -0.2,
          font: {
            size: 10,
          },
        },
      },
    },
    {
      name: "Gross earnings",
      value: grossEarnings.value,
      helpArticle: howToEarnOnTowerHunt,
      change: "-1.39%",
      changeType: "positive",
      chartId: "gross-earnings-chart",
      chartData: grossEarningsChartData.value,
      chartLayout: {
        yaxis: {
          tickformat: "$.2f", // For more formatting types, see: https://github.com/d3/d3-format/blob/master/README.md#locale_format
        },
        xaxis: {
          tickformat: dateFormat.value,
        },
        barmode: "stack",
        showlegend: true,
        legend: {
          orientation: "h",
          y: -0.2,
          font: {
            size: 10,
          },
        },
      },
    },
    {
      name: "Costs",
      value: spending.value,
      secondaryValue: publishableSummary.value,
      helpArticle: typesOfPaidData,
      change: "+54.02%",
      changeType: "negative",
      chartId: "costs-chart",
      chartData: spendingChartData.value,
      chartLayout: {
        yaxis: {
          tickformat: "$.2f", // For more formatting types, see: https://github.com/d3/d3-format/blob/master/README.md#locale_format
        },
        xaxis: {
          tickformat: dateFormat.value,
        },
        barmode: "stack",
        showlegend: true,
        legend: {
          orientation: "h",
          y: -0.2,
          font: {
            size: 10,
          },
        },
      },
    },
  ];
});

watch(
  () => props.timePeriod,
  () => {
    fetchStats();
  },
);
watch(workspaceLayout, () => {
  fetchStats();
});
watch(workspaceResized, () => {
  debouncedrefetchStats();
});
watch(userAvailableBalancesChannelDataQueue, () => {
  debouncedrefetchStats();
});

onMounted(() => {
  fetchStats();
});

const debouncedrefetchStats = _.debounce(function () {
  fetchStats();
}, 2000);

const debouncedDisplayable = _.debounce(function () {
  displayable.value = true;
}, 2000);

async function fetchStats() {
  displayable.value = false;
  const response = await api.get(
    `my_contribution_stats?time_period=${props.timePeriod}`,
  );

  if (response?.data) {
    statsData.value = response.data;
    await nextTick();
    debouncedDisplayable();
    workspaceResized.value = false;
  }
}

function drawChart(isVisible, entry, chartId, data, layout) {
  if (isVisible) {
    const appendedLayout = _.merge({}, layout, {
      font: {
        family: "Inter var, Sans-Serif",
      },
      margin: { l: 40, r: 5, t: 5, b: 10 },
    });
    Plotly.newPlot(chartId, data, appendedLayout, {
      responsive: true,
      displaylogo: false,
      displayModeBar: false,
    });
  }
}

function toDollar(shares) {
  return _.sumBy(shares, function (share) {
    return dollar(share);
  });
}
function dollar(record) {
  return _.toNumber(record.pricingUnits) * unitPrice;
}
function stake(record) {
  return _.toNumber(record.stakedPricingUnits) * unitPrice;
}
function reward(record) {
  return _.toNumber(record.rewardPricingUnits) * unitPrice;
}
function dayStart(record) {
  let interval = "day";

  switch (props.timePeriod) {
    case "1d":
      interval = "hour";
      break;
    case "7d":
      interval = "day";
      break;
    case "4w":
      interval = "day";
      break;
    case "3m":
      interval = "week";
      break;
    case "12m":
      interval = "month";
  }

  const time = moment(record.createdAt).local().startOf(interval).format();
  return time;
}
</script>
