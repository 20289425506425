<template>
  <div
    :class="isFocal ? 'bg-orange-100' : ''"
    class="p-2 flex items-center justify-between text-gray-500 text-sm"
  >
    <div class="flex items-center space-x-1">
      <span class="font-semibold" v-if="focalInvestmentId && !isFocal"
        >Existing</span
      >
      <span v-if="focalInvestmentId"
        >{{ detailedInvestmentLabel }} {{ transactionType }}</span
      >
      <span v-else>{{ selfLabel(investment) }}</span>
      <span class="font-semibold" v-if="isFocal">you are reviewing now</span>
    </div>
    <div class="flex items-center space-x-1">
      <span
        v-if="inPlay"
        class="inline-flex items-center justify-center h-3 w-3 rounded-full bg-green-500"
      />
      <span
        v-else-if="dead"
        v-tooltip="'Withdrawn'"
        class="inline-flex items-center justify-center h-5 w-5 text-red-600"
        style="font-size: 16px"
      >
        <i class="fas fa-skull-crossbones" />
      </span>
      <div v-if="latestValuationLabel">{{ latestValuationLabel }} &middot;</div>
      <div v-if="focalInvestmentId">
        {{ dateLabel }}
      </div>
      <div
        v-if="portfolioId"
        v-tooltip.top-end="'This investment is part of a portfolio.'"
        class="h-5 w-5 inline-flex justify-center items-center text-sm text-gray-500 hover:text-gray-700"
      >
        <Squares2X2Icon class="h-5 w-5" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { Squares2X2Icon } from "@heroicons/vue/20/solid";
import investmentStates from "@/components/deal-builder/investmentStates";
import selfLabel from "@/components/crowdsourcing/selfLabelDataField";
import { computed } from "vue";
import { currencyAmount } from "@/assets/numberHelpers";
import _ from "lodash";
import moment from "moment";

const props = defineProps([
  "investment",
  "focalInvestmentId",
  "investmentValuations",
]);

const isFocal = computed(
  () => props.investment.fieldContentId == props.focalInvestmentId,
);
const investmentFieldContent = computed(() => props.investment.fieldContent);
const assetTypeAlias = computed(() => {
  switch (investmentFieldContent.value.investmentType) {
    case "OwnershipInterest":
      return "Equity";
    case "Loan":
      return "Loan";
    default:
      return "Unknown Asset";
  }
});
const detailedInvestmentLabel = computed(() => {
  const loanSeniority = _.get(investmentFieldContent.value, "loanSeniority");

  if (loanSeniority) {
    return `${_.startCase(loanSeniority)} ${assetTypeAlias.value}`;
  } else {
    return assetTypeAlias.value;
  }
});
const transactionType = computed(
  () => investmentFieldContent.value.dealActionAlias,
);
const dateLabel = computed(() => {
  switch (investmentFieldContent.value.state) {
    case "withdrawn":
    case "live":
    case "under_contract":
      return _.find(investmentStates, {
        value: investmentFieldContent.value.state,
      }).statusLabel;
    case "closed":
    default: {
      if (investmentFieldContent.value.date) {
        return moment(investmentFieldContent.value.date).format("MMM YYYY");
      } else {
        return "Undated";
      }
    }
  }
});
const state = computed(() => {
  return _.get(investmentFieldContent.value, "state");
});
const inPlay = computed(() => {
  return state.value && !_.includes(["closed", "withdrawn"], state.value);
});
const dead = computed(() => {
  return state.value && state.value === "withdrawn";
});
const portfolioId = computed(() =>
  _.get(investmentFieldContent.value, "portfolioId"),
);
const latestValuationField = computed(() => {
  return props.investmentValuations[props.investment.fieldContentId];
});
const latestValuationLabel = computed(() => {
  if (latestValuationField.value) {
    return `$${currencyAmount(
      latestValuationField.value.fieldContent.value,
      0,
    )} (${latestValuationField.value.fieldContent.valueType})`;
  } else {
    return null;
  }
});
</script>
