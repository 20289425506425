<template>
  <div class="rounded-md border-l-2 border-teal-500 bg-teal-100 p-2">
    <div
      class="whitespace-pre-line py-2 pl-2 pr-2 text-xs font-medium text-gray-500"
    >
      {{ summaryString }}
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import moment from "moment";
import { currencyAmount } from "@/assets/numberHelpers";
import _ from "lodash";

const props = defineProps([
  "dataField",
  "dateFields",
  "perAreaSize",
  "scheduleDataField",
]);

const summaryString = computed(() => {
  if (props.scheduleDataField) {
    const blocks = _.get(
      props.scheduleDataField.fieldContent,
      "buildingBlocks",
    );
    return blocks
      .map(({ date, dateType, inputType, inputValue }) => {
        const formattedDate =
          dateType === "Actual"
            ? moment(date).format("MM/DD/YYYY")
            : `Month ${date}`;
        const formattedVal = `$${currencyAmount(inputValue, 2)}`;

        return `${formattedDate}: ${formattedVal} (${inputType})\n`;
      })
      .join("");
  } else {
    return null;
  }
});
</script>
