<template>
  <div class="w-full p-2">
    <TabGroup>
      <TabList
        class="mt-2 max-w-md mx-auto flex space-x-1 rounded-xl bg-indigo-900/20 p-1"
      >
        <Tab
          v-for="category in Object.keys(categories)"
          as="template"
          :key="category"
          v-slot="{ selected }"
        >
          <button
            :class="[
              'w-full rounded-lg py-2.5 text-sm font-medium leading-5',
              'ring-white/60 ring-offset-2 ring-offset-indigo-400 focus:outline-none focus:ring-2',
              selected
                ? 'bg-white text-indigo-700 shadow'
                : 'text-gray-500 hover:bg-white/[0.12] hover:text-gray-700',
            ]"
          >
            {{ category }}
          </button>
        </Tab>
      </TabList>

      <TabPanels class="mt-2 w-full">
        <TabPanel
          v-for="({ component, props }, idx) in Object.values(categories)"
          :key="idx"
          :class="['rounded-xl bg-white']"
        >
          <component :is="component" v-bind="props" />
        </TabPanel>
      </TabPanels>
    </TabGroup>
  </div>
</template>

<script setup>
import CompletedJobOpportunities from "@/components/crowdsourcing/contributions/CompletedJobOpportunities.vue";
import AvailableJobOpportunities from "@/components/crowdsourcing/contributions/AvailableJobOpportunities.vue";
import { ref, markRaw } from "vue";
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from "@headlessui/vue";

const categories = ref({
  Available: { component: markRaw(AvailableJobOpportunities), props: {} },
  Completed: { component: markRaw(CompletedJobOpportunities), props: {} },
});
</script>
