<template>
  <div class="h-full rounded-lg overflow-hidden">
    <CardBranding :card="card" />
    <div class="absolute inset-0">
      <div
        class="absolute inset-0 bg-gradient-to-r from-green-800 to-green-400 mix-blend-multiply"
      />
    </div>
    <div class="h-full relative px-4 py-16 flex flex-col justify-center">
      <h1 class="text-center text-4xl font-bold tracking-tight">
        <span class="block text-white"
          >{{ staleContributionsCount }} Unpublished</span
        >
        <span class="block text-green-200">Contributions</span>
      </h1>
      <p class="mx-auto mt-6 max-w-lg text-center text-2xl text-green-200">
        Costing you: ${{ _.round(staleContributionsRewards.stakes, 3) }}
      </p>
      <p class="flex items-center justify-center space-x-2">
        <span class="text-green-200"
          >Potential rewards: ${{
            _.round(staleContributionsRewards.rewards, 3)
          }}</span
        >
      </p>
      <div
        v-if="isDesktop && staleContributionsCount > 0"
        class="mx-auto mt-10 max-w-sm flex justify-center"
      >
        <div class="space-y-4">
          <a
            href=""
            @click.prevent="viewAvailableValidations"
            class="flex items-center justify-center rounded-md border border-transparent bg-white px-4 py-3 text-base font-medium text-green-600 shadow-sm hover:bg-green-50 sm:px-8"
            >Review unpublished</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import CardBranding from "@/components/collectible-cards/CardBranding.vue";
import api from "@/router/api";
import { ref, watch, onMounted } from "vue";
import _ from "lodash";
import { useRoute, useRouter } from "vue-router";
import { useSecondaryPanelStore } from "@/stores/secondaryPanel";
import { useAvailableValidationsChannelStore } from "@/stores/availableValidationsChannel";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { storeToRefs } from "pinia";

const props = defineProps([
  "card",
  "cardIndex",
  "stackIndex",
  "completedCardIds",
]);

const secondaryPanelStore = useSecondaryPanelStore();
const { validationsActive } = storeToRefs(secondaryPanelStore);
const availableValidationsChannelStore = useAvailableValidationsChannelStore();
const { availableValidationsChannelDataQueue } = storeToRefs(
  availableValidationsChannelStore,
);
const layoutStore = useWorkspaceLayoutStore();
const { isDesktop } = storeToRefs(layoutStore);

const staleContributionsCount = ref(0);
const staleContributionsRewards = ref({
  rewards: 0,
  stakes: 0,
});

watch(
  () => props.stackIndex,
  () => {
    if (staleContributionsCount.value === 0) fetchAvailableValidations();
  },
);
watch(availableValidationsChannelDataQueue, () => {
  fetchAvailableValidations();
});

onMounted(() => {
  fetchAvailableValidations();
});

async function fetchAvailableValidations() {
  return new Promise((resolve) => {
    if (
      props.stackIndex === props.cardIndex &&
      !_.includes(props.completedCardIds, props.card.id)
    ) {
      api.get(`stale_contributions_count`).then((json) => {
        staleContributionsCount.value = json.data;

        api.get("stale_contributions_rewards").then((json) => {
          staleContributionsRewards.value = json.data;
          resolve();
        });
      });
    } else {
      resolve();
    }
  });
}

const router = useRouter();
const route = useRoute();

function viewAvailableValidations() {
  if (isDesktop.value) {
    validationsActive.value = true;
    router.push({
      name: route.name,
      query: {
        ...route.query,
        horizontalTab: "Validations",
        verticalTab: "Unpublished",
      },
    });
  }
}
</script>
