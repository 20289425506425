<template>
  <div v-if="aliasesLoaded">
    <dt v-if="aliases.length > 0" class="text-sm font-medium text-gray-500">
      Aliases
    </dt>
    <dd v-for="aliasDataField in aliases" :key="aliasDataField.localId">
      <DataField
        :data-field="aliasDataField"
        primary-text-path="fieldContent.name"
        text-classes="text-sm"
        @completed="fetchAliases"
        @open-sourced="fetchAliases"
        @unlocked="fetchAliases"
        @dismiss="dismiss"
      />
    </dd>

    <ContentAliasCreate
      v-if="creatingAlias"
      v-bind="
        _.find(availableProps, {
          fieldName: 'ContentAlias',
        })
      "
      @completed="fetchAliases"
      @cancel="creatingAlias = false"
    />

    <div v-else class="mt-1 flex items-start">
      <DataVisibilityButton visibility="public">
        <template v-slot:button>
          <button
            @click="creatingAlias = true"
            type="button"
            data-test="create-alias-button"
            class="group py-1 px-1.5 flex items-center justify-between rounded-full border border-gray-300 shadow-sm space-x-2 text-left hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <span class="min-w-0 flex-1 flex items-center space-x-1">
              <span class="flex-shrink-0 flex items-center">
                <span
                  class="inline-flex items-center justify-center h-5 w-5 rounded-full bg-indigo-600"
                >
                  <PencilIcon class="h-3 w-3 text-white" />
                </span>
              </span>
              <span class="min-w-0 flex-1">
                <span class="text-sm font-medium text-gray-900 truncate"
                  >Add Alias</span
                >
              </span>
            </span>
          </button>
        </template>
      </DataVisibilityButton>
    </div>
  </div>
</template>

<script setup>
import { PencilIcon } from "@heroicons/vue/24/outline";
import ContentAliasCreate from "@/components/crowdsourcing/ContentAliasCreate.vue";
import DataField from "@/components/crowdsourcing/DataField.vue";
import { useCrowdsourcedChangeGroupStore } from "@/stores/crowdsourcedChangeGroup";
import api from "@/router/api";
import { ref, computed, watch, onMounted } from "vue";
import _ from "lodash";
import DataVisibilityButton from "@/components/crowdsourcing/DataVisibilityButton.vue";

const props = defineProps(["dataField", "optional"]);
const aliases = ref([]);
const aliasesLoaded = ref(false);
const creatingAlias = ref(false);
const dataFieldLocalId = computed(() => _.get(props.dataField, "localId"));
const changeGroupStore = useCrowdsourcedChangeGroupStore();

const availableProps = computed(() => {
  return [
    {
      fieldName: "ContentAlias",
      content: props.dataField?.fieldContent,
      contentType: props.dataField?.fieldContentType,
    },
  ];
});

watch(dataFieldLocalId, () => {
  if (props.dataField) fetchAliases();
});

onMounted(() => fetchAliases());

function fetchAliases() {
  creatingAlias.value = false;
  api
    .get(
      `crowdsourced_data_fields/${props.dataField.fieldContentType}/${props.dataField.fieldContentId}?field_name=ContentAlias`,
    )
    .then((json) => {
      aliases.value = json.data;
      aliasesLoaded.value = true;
    });
}
async function dismiss(id, successCallback = () => fetchAliases()) {
  if (!id) return;

  await changeGroupStore.dismissData({
    dataFieldId: id,
    successCallback: successCallback,
  });
}
</script>
