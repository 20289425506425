<template>
  <div v-if="loaded">
    <div v-if="proveForValue" class="flex items-center space-x-2">
      <RadioGroup v-model="proofValue" class="grid grid-cols-2 gap-2">
        <RadioGroupOption
          as="template"
          v-for="option in proofOptions"
          :key="option.name"
          :value="option.fieldValue"
          v-slot="{ active, checked }"
        >
          <div
            :class="[
              active ? 'ring-2 ring-indigo-600 ring-offset-2' : '',
              checked
                ? 'bg-indigo-600 text-white ring-0 hover:bg-indigo-500'
                : 'bg-white text-gray-900 ring-1 ring-gray-300 hover:bg-gray-50',
              !active && !checked ? 'ring-inset' : '',
              active && checked ? 'ring-2' : '',
              'cursor-pointer focus:outline-none flex flex-1 items-center justify-center text-center rounded-md p-2 text-xs font-semibold',
            ]"
          >
            {{ option.name }}
          </div>
        </RadioGroupOption>
      </RadioGroup>

      <template v-if="proofValue">
        <button
          @click="cancel"
          type="button"
          class="inline-flex flex-shrink-0 items-center p-1 border border-gray-300 rounded-full shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <XMarkIcon class="h-4 w-4" />
        </button>

        <DataVisibilityButton visibility="public" class="inline-flex">
          <template v-slot:button>
            <button
              @click="setBoolean(proofValue, null)"
              :disabled="originatingData"
              type="button"
              class="inline-flex flex-shrink-0 items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2"
              :data-test="`${fieldName}${proveForValue ? '-proof' : ''}-save`"
            >
              <PulseLoader
                v-if="originatingData"
                :loading="true"
                size="3px"
                color="#f3f4f6"
              />
              <SolidCheckIcon v-else class="h-4 w-4" />
            </button>
          </template>
        </DataVisibilityButton>
      </template>
    </div>

    <span
      v-else
      class="flex"
      :class="
        workspaceLayout === 'sideBySide'
          ? 'flex-col space-y-1'
          : 'items-center space-x-1'
      "
    >
      <div v-if="booleanDataField" class="flex items-center">
        <span
          v-if="booleanDataField?.fieldValue === 'true'"
          class="flex-shrink-0 relative h-5 w-5 flex items-center justify-center"
        >
          <svg
            class="h-full w-full text-yellow-600 group-hover:text-yellow-800"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
              clip-rule="evenodd"
            />
          </svg>
        </span>
        <div
          v-else-if="booleanDataField?.fieldValue === 'false'"
          class="flex-shrink-0 h-5 w-5 relative flex items-center justify-center"
          aria-hidden="true"
        >
          <div
            class="h-2 w-2 bg-gray-300 rounded-full group-hover:bg-gray-400"
          ></div>
        </div>
        <span
          v-else-if="unlockable"
          class="flex-shrink-0 relative h-5 w-5 flex items-center justify-center"
        >
          <svg
            class="h-full w-full text-gray-300 group-hover:text-gray-400"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
              clip-rule="evenodd"
            />
          </svg>
        </span>

        <DataField
          :data-field="booleanDataField"
          text-classes="text-sm font-medium"
          text-styles=""
          @open-sourced="openSourced"
          @unlocked="unlocked"
          @completed="completed"
          @set-proof="setProof"
        />
      </div>

      <div
        v-else
        class="flex"
        :class="
          workspaceLayout === 'sideBySide'
            ? 'flex-col space-y-2'
            : 'items-center space-x-2'
        "
      >
        <DataVisibilityButton visibility="safezone">
          <template v-slot:button>
            <button
              @click="setBoolean('true', null)"
              :disabled="originatingData"
              type="button"
              class="group py-1 px-1.5 w-full flex items-center justify-between rounded-full border border-gray-300 shadow-sm space-x-2 text-left hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <PulseLoader
                v-if="originatingData"
                :loading="true"
                size="3px"
                color="#f3f4f6"
              />
              <span v-else class="min-w-0 flex-1 flex items-center space-x-1">
                <span class="block flex-shrink-0">
                  <span
                    class="inline-flex items-center justify-center h-5 w-5 rounded-full bg-yellow-500"
                  >
                    <CheckIcon class="h-3 w-3 text-white" />
                  </span>
                </span>
                <span class="block min-w-0 flex-1">
                  <span
                    class="block text-sm font-medium text-gray-900 truncate"
                    >{{ fieldTrueLabel }}</span
                  >
                </span>
              </span>
            </button>
          </template>
        </DataVisibilityButton>
        <span
          v-if="workspaceLayout !== 'sideBySide'"
          class="text-sm font-medium text-gray-700"
          >&middot;</span
        >
        <DataVisibilityButton visibility="safezone">
          <template v-slot:button>
            <button
              @click="setBoolean('false', null)"
              :disabled="originatingData"
              type="button"
              class="group py-1 px-1.5 w-full flex items-center justify-between rounded-full border border-gray-300 shadow-sm space-x-2 text-left hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <PulseLoader
                v-if="originatingData"
                :loading="true"
                size="3px"
                color="#f3f4f6"
              />
              <span v-else class="min-w-0 flex-1 flex items-center space-x-1">
                <span class="block flex-shrink-0">
                  <span
                    class="inline-flex items-center justify-center h-5 w-5 rounded-full bg-yellow-500"
                  >
                    <NoSymbolIcon class="h-3 w-3 text-white" />
                  </span>
                </span>
                <span class="block min-w-0 flex-1">
                  <span
                    class="block text-sm font-medium text-gray-900 truncate"
                    >{{ fieldFalseLabel }}</span
                  >
                </span>
              </span>
            </button>
          </template>
        </DataVisibilityButton>
      </div>
    </span>
  </div>
</template>

<script setup>
import { RadioGroup, RadioGroupOption } from "@headlessui/vue";
import { CheckIcon, NoSymbolIcon } from "@heroicons/vue/24/outline";
import {
  CheckIcon as SolidCheckIcon,
  XMarkIcon,
} from "@heroicons/vue/20/solid";
import DataField from "@/components/crowdsourcing/DataField.vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { ref, computed, onMounted, watch } from "vue";
import { useCrowdsourcedChangeGroupStore } from "@/stores/crowdsourcedChangeGroup";
import { useProveForValueStore } from "@/stores/proveForValue";
import { useNotificationsStore } from "@/stores/notifications";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import api from "@/router/api";
import _ from "lodash";
import DataVisibilityButton from "@/components/crowdsourcing/DataVisibilityButton.vue";
import { storeToRefs } from "pinia";

const props = defineProps([
  "decoratingDataField",
  "fieldName",
  "fieldTrueLabel",
  "fieldFalseLabel",
  "proveForValue",
  "challengeDataField",
]);
const emit = defineEmits(["completed", "unlocked", "set-proof"]);

const notificationsStore = useNotificationsStore();
const proveForValueStore = useProveForValueStore();
const layoutStore = useWorkspaceLayoutStore();
const { workspaceLayout } = storeToRefs(layoutStore);
const crowdsourcedChangeGroupStore = useCrowdsourcedChangeGroupStore();
const { originatingData, changeGroupId } = storeToRefs(
  crowdsourcedChangeGroupStore,
);

const booleanDataField = ref(null);
const loaded = ref(false);
const proofValue = ref(null);
const proofOptions = computed(() => {
  return [
    { name: props.fieldTrueLabel, fieldValue: "true" },
    { name: props.fieldFalseLabel, fieldValue: "false" },
  ];
});

const unlockable = computed(() => {
  return _.get(booleanDataField.value, "price");
});

const contentType = computed(() => props.decoratingDataField.fieldContentType);
const contentId = computed(() => props.decoratingDataField.fieldContent.id);
const dataFieldLocalId = computed(() =>
  _.get(props.decoratingDataField, "localId"),
);

watch(dataFieldLocalId, () => {
  if (props.dataField) fetchBooleanField();
});

onMounted(() => {
  fetchBooleanField();
});

function setProof(fieldName) {
  emit("set-proof", fieldName);
}

async function fetchBooleanField(override = false) {
  loaded.value = false;

  if (props.proveForValue && !override) {
    loaded.value = true;
  } else {
    api
      .get(
        `crowdsourced_data_fields/${contentType.value}/${contentId.value}?field_name=${props.fieldName}`,
      )
      .then((json) => {
        booleanDataField.value = json.data;
        loaded.value = true;
      });
  }
}

function unlocked() {
  if (props.proveForValue) {
    console.log("unlocked proof");
    emit("unlocked");
  } else {
    console.log("unlocked boolean");
    fetchBooleanField(true);
  }
}
function completed() {
  fetchBooleanField();
  emit("completed");
}
function openSourced() {
  fetchBooleanField();
  emit("open-sourced");
}
function cancel() {
  proofValue.value = null;
}

async function setBoolean(fieldValue, newProofStatus) {
  const apiRequestFunc = () => persist(fieldValue, newProofStatus);
  const successCallback = (json) => afterPersist(json, fieldValue);
  const failureCallback = () => {};

  return crowdsourcedChangeGroupStore.originateData(
    apiRequestFunc,
    successCallback,
    failureCallback,
  );
}

async function persist(fieldValue, newProofStatus) {
  if (props.proveForValue && !newProofStatus) {
    let payload = {
      fieldValue,
      fieldValueType: "boolean",
      isInner: false,
    };
    const proofResponse = await proveForValueStore.submitProof(
      props.challengeDataField,
      payload,
    );

    return proofResponse;
  } else {
    const payload = {
      fieldName: props.fieldName,
      fieldValue,
    };

    let response = await api.post(
      `content_booleans/${contentType.value}/${contentId.value}`,
      _.merge({}, payload, { changeGroupId: changeGroupId.value }),
    );

    if (newProofStatus === "rejected") {
      const declassifyPayload = {
        id: response.data.dataField.localId,
      };

      const declassifiedResponse = await api.post(
        `declassify_datapoint`,
        declassifyPayload,
      );
      response.data.dataField = declassifiedResponse.data;
      notificationsStore.addNotification("proofRejected");
      unlocked();
    }

    return response;
  }
}

async function afterPersist(json, fieldValue) {
  if (json.data?.proofStatus === "rejected") {
    setBoolean(fieldValue, json.data.proofStatus);
  } else if (json.data?.proofStatus === "accepted") {
    notificationsStore.addNotification("proofAccepted");
    unlocked();
  } else {
    fetchBooleanField(true);
    completed();
  }
}
</script>
