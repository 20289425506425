<template>
  <div v-if="mounted" class="">
    <div class="p-2">
      <div
        :class="
          workspaceLayout === 'topAndBottom' ? 'grid-cols-6' : 'grid-cols-1'
        "
        class="grid gap-3"
      >
        <div
          :class="workspaceLayout === 'topAndBottom' ? 'col-span-3' : ''"
          class="divide-y divide-gray-100 overflow-hidden rounded-xl bg-white border border-gray-300 ring-1 ring-black ring-opacity-5"
        >
          <Combobox v-model="selectedRecord" nullable>
            <div
              class="relative"
              v-observe-visibility="{ callback: attachPlaceService }"
            >
              <MagnifyingGlassIcon
                class="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              <ComboboxInput
                id="content-location-search"
                class="h-12 w-full border-0 bg-transparent pl-11 pr-11 text-gray-800 placeholder-gray-400 focus:ring-0 text-sm"
                placeholder="Address lookup"
                @keydown.escape="clearSearch"
                @change="rawQuery = $event.target.value"
                :displayValue="(record) => record?.name || record?.description"
                data-test="content-location-search"
              />
              <button
                v-show="rawQuery !== ''"
                @click="clearSearch"
                type="button"
                class="absolute top-3.5 right-4"
                data-test="content-location-search-clear-button"
              >
                <XMarkIcon class="h-5 w-5 text-gray-700" aria-hidden="true" />
              </button>
              <div
                id="content-location-places-service-container"
                class="hidden"
              />
            </div>

            <ComboboxOptions
              class="max-h-80 scroll-py-10 scroll-pb-2 space-y-4 overflow-y-auto p-4 pb-2"
            >
              <li v-if="processedPlaces.length > 0">
                <h2 class="text-xs font-semibold text-gray-900">Places</h2>
                <ul class="-mx-4 mt-2 text-sm text-gray-700">
                  <ComboboxOption
                    v-for="(place, index) in processedPlaces"
                    :key="place.place_id"
                    :value="place"
                    as="template"
                    :data-test="`place-result-${index}`"
                    v-slot="{ active }"
                  >
                    <li
                      :class="[
                        'flex cursor-default select-none items-center px-4 py-2',
                        active && 'bg-indigo-600 text-white',
                      ]"
                    >
                      <MapPinIcon
                        :class="[
                          'h-6 w-6 flex-none',
                          active ? 'text-white' : 'text-gray-400',
                        ]"
                        aria-hidden="true"
                      />
                      <span class="ml-3 flex-auto truncate">{{
                        place.description
                      }}</span>
                    </li>
                  </ComboboxOption>
                </ul>
              </li>
            </ComboboxOptions>

            <div
              v-if="noResults"
              class="py-14 px-6 text-center text-sm sm:px-14"
            >
              <ExclamationTriangleIcon
                class="mx-auto h-6 w-6 text-gray-400"
                aria-hidden="true"
              />
              <p class="mt-4 font-semibold text-gray-900">No results found</p>
              <p class="mt-2 text-gray-500">
                We couldn’t find anything with that term. Please try again.
              </p>
            </div>
          </Combobox>
        </div>
        <button
          @click="resetLocation()"
          type="button"
          class="bg-white inline-flex self-end items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <XMarkIcon class="flex-shrink-0 -ml-2 mr-1 h-4 w-4 text-gray-400" />
          <span>Reset</span>
        </button>
      </div>

      <LocationMap
        v-if="hasLocation"
        :editable="true"
        :lat="lat"
        :lng="lng"
        :map-id="`editing`"
        @set-lat-lng="setLatLng"
        class="mt-3 h-64 w-full"
      />
    </div>
    <div class="p-2 flex justify-end">
      <button
        @click="cancel"
        type="button"
        class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Cancel
      </button>
      <DataVisibilityButton class="ml-3 inline-flex">
        <template v-slot:button>
          <button
            @click="save"
            :disabled="originatingData"
            type="button"
            class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <PulseLoader
              v-if="originatingData"
              :loading="true"
              size="3px"
              color="#f3f4f6"
            />
            <span v-else>Save</span>
          </button>
        </template>
      </DataVisibilityButton>
    </div>
  </div>
</template>

<script setup>
/* global google */
import { MagnifyingGlassIcon, XMarkIcon } from "@heroicons/vue/20/solid";
import { ExclamationTriangleIcon, MapPinIcon } from "@heroicons/vue/24/outline";
import {
  Combobox,
  ComboboxInput,
  ComboboxOptions,
  ComboboxOption,
} from "@headlessui/vue";
import _ from "lodash";
import { ref, computed, nextTick, watch, onMounted } from "vue";
import { useCrowdsourcedChangeGroupStore } from "@/stores/crowdsourcedChangeGroup";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { storeToRefs } from "pinia";
import api from "@/router/api";
import LocationMap from "@/components/maps/LocationMap.vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import DataVisibilityButton from "@/components/crowdsourcing/DataVisibilityButton.vue";

const props = defineProps([
  "decoratingDataField",
  "locationDataField",
  "joiningPropertyDataField",
]);
const emit = defineEmits(["completed", "cancel"]);
const layoutStore = useWorkspaceLayoutStore();
const { workspaceLayout } = storeToRefs(layoutStore);
const crowdsourcedChangeGroupStore = useCrowdsourcedChangeGroupStore();
const { originatingData, changeGroupId } = storeToRefs(
  crowdsourcedChangeGroupStore,
);

const lat = ref(null);
const lng = ref(null);
const mounted = ref(false);
const places = ref([]);
const processedPlaces = computed(() =>
  query.value === "" ||
  rawQuery.value.startsWith(">") ||
  rawQuery.value.startsWith("#")
    ? []
    : places.value,
);
const placeDetails = ref(null);
const placePredictions = ref(null);
const rawQuery = ref("");
const selectedRecord = ref(null);
const query = computed(() => rawQuery.value.toLowerCase().replace(/^[#>]/, ""));
const hasResults = computed(() => processedPlaces.value.length > 0);
const noResults = computed(
  () => query.value !== "" && rawQuery.value !== "?" && !hasResults.value,
);
function clearSearch() {
  rawQuery.value = "";
  selectedRecord.value = null;
  places.value = [];
}

const existingLocation = computed(() => {
  return _.get(
    props.locationDataField || props.joiningPropertyDataField,
    "fieldContent",
    null,
  );
});
const hasLocation = computed(() => {
  return lat.value && lng.value;
});

watch(selectedRecord, async () => {
  onSelect();
});

watch(query, async () => {
  if (query.value !== "") {
    debouncedFilterPlaces();
  }
});

onMounted(() => {
  mount();
});

function attachPlaceService(isVisible) {
  const mapDiv = document.getElementById(
    "content-location-places-service-container",
  );
  if (!placeDetails.value && isVisible && mapDiv) {
    setTimeout(async () => {
      const { PlacesService, AutocompleteService } =
        await google.maps.importLibrary("places");
      placeDetails.value = new PlacesService(mapDiv);
      placePredictions.value = new AutocompleteService();
    }, 250);
  }
}

async function mount() {
  if (existingLocation.value) {
    lat.value = existingLocation.value.lat;
    lng.value = existingLocation.value.lng;
  }

  mounted.value = true;

  await nextTick();
  document.getElementById("content-location-search").focus();
}

function cancel() {
  emit("cancel");
}
async function dismiss() {
  if (!props.locationDataField) return;

  await crowdsourcedChangeGroupStore.dismissData({
    dataFieldId: props.locationDataField.localId,
    successCallback: () => {},
  });
}

async function save() {
  await dismiss();

  const apiRequestFunc = () => persist();
  const successCallback = () => afterPersist();
  const failureCallback = () => cancel();

  await crowdsourcedChangeGroupStore.originateData(
    apiRequestFunc,
    successCallback,
    failureCallback,
  );
}

function persist() {
  let payload = {
    contentType: props.decoratingDataField.fieldContentType,
    contentToken: props.decoratingDataField.fieldContentId,
    changeGroupId: changeGroupId.value,
  };

  _.merge(payload, {
    lat: lat.value,
    lng: lng.value,
  });

  return api.post(`content_locations`, payload);
}

function afterPersist() {
  emit("completed");
}

function setLatLng({ newLat, newLng }) {
  lat.value = newLat;
  lng.value = newLng;
}

async function filterPlaces() {
  if (_.trim(query.value) !== "") {
    const location = new google.maps.LatLng(lat.value, lng.value);
    placePredictions.value.getQueryPredictions(
      {
        input: query.value,
        location,
        radius: 5000,
      },
      displaySuggestions,
    );
  }
}
const debouncedFilterPlaces = _.debounce(function () {
  filterPlaces();
}, 250);

const displaySuggestions = function (predictions, status) {
  if (status != google.maps.places.PlacesServiceStatus.OK || !predictions) {
    console.log(status);
    return;
  }

  places.value = predictions;
};

function handleDetails(place, status) {
  if (status == google.maps.places.PlacesServiceStatus.OK) {
    const newLat = place.geometry.location.lat();
    const newLng = place.geometry.location.lng();

    resetLocation();

    setTimeout(() => {
      lat.value = newLat;
      lng.value = newLng;
    }, 50);
  }
}

function resetLocation() {
  lat.value = null;
  lng.value = null;

  clearSearch();
  document.getElementById("content-location-search").focus();
}

async function onSelect() {
  if (!selectedRecord.value) return;

  if (selectedRecord.value.place_id) {
    var request = {
      placeId: selectedRecord.value.place_id,
      fields: ["name", "formatted_address", "address_components", "geometry"],
    };

    placeDetails.value.getDetails(request, handleDetails);
  }
}
</script>
