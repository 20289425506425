<template>
  <div class="h-full rounded-lg overflow-hidden">
    <CardBranding :card="card" />
    <div class="absolute inset-0">
      <div
        class="absolute inset-0 bg-gradient-to-r from-blue-600 to-sky-400 mix-blend-multiply"
      />
    </div>
    <div class="h-full relative px-4 py-16 flex flex-col justify-center">
      <h1 class="text-center text-4xl font-bold tracking-tight">
        <span class="block text-white">Shared</span>
        <span class="block text-sky-200">Task List</span>
      </h1>
      <p
        class="mx-auto mt-6 max-w-lg text-center text-lg font-bold text-sky-200"
      >
        {{ taskList?.name || "List Name" }}
      </p>
      <p class="mx-auto mt-2 max-w-lg text-center text-lg text-sky-200">
        Shared by {{ sharedByName }}
      </p>
      <div class="mx-auto mt-10 max-w-sm flex justify-center">
        <div class="space-y-4">
          <a
            href=""
            @click.prevent="viewTasks"
            class="flex items-center justify-center rounded-md border border-transparent bg-white px-4 py-3 text-base font-medium text-sky-700 shadow-sm hover:bg-sky-50 sm:px-8"
            >View Tasks</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import api from "@/router/api";
import CardBranding from "@/components/collectible-cards/CardBranding.vue";
import { ref, computed, watch, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import _ from "lodash";

const props = defineProps([
  "card",
  "cardIndex",
  "stackIndex",
  "completedCardIds",
]);

const sharedByName = computed(() =>
  props.card.taskName.replace("Task list shared by ", "")
);
const cardContents = ref([]);
const taskList = computed(() => _.head(cardContents.value)?.content);

watch(
  () => props.stackIndex,
  () => {
    if (!taskList.value) fetchTopCardTaskList();
  }
);

onMounted(() => {
  fetchTopCardTaskList();
});

function fetchTopCardTaskList() {
  if (
    props.stackIndex === props.cardIndex &&
    !_.includes(props.completedCardIds, props.card.id)
  ) {
    api
      .get(
        `collectible_cards/${props.card.collectibleCardId}/collectible_card_contents`
      )
      .then((json) => {
        cardContents.value = json.data;
      });
  }
}

const router = useRouter();
const route = useRoute();

function viewTasks() {
  router.push({
    name: route.name,
    query: {
      ...route.query,
      horizontalTab: "Me",
      verticalTab: "Tasks",
    },
  });
}
</script>
