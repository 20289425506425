import { ref } from "vue";
import { defineStore, acceptHMRUpdate } from "pinia";
import _ from "lodash";

export const useUserAvailableBalancesChannelStore = defineStore(
  "userAvailableBalancesChannel",
  () => {
    const UserAvailableBalancesChannel = ref(null);
    const userAvailableBalancesChannelDataQueue = ref([]);

    function pushAndTrim(data) {
      userAvailableBalancesChannelDataQueue.value.push(data);
      userAvailableBalancesChannelDataQueue.value = _.takeRight(
        userAvailableBalancesChannelDataQueue.value,
        3
      );
    }

    return {
      UserAvailableBalancesChannel,
      userAvailableBalancesChannelDataQueue,
      pushAndTrim,
    };
  }
);

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useUserAvailableBalancesChannelStore, import.meta.hot)
  );
}
