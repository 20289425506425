<template>
  <div
    class="flex flex-col divide-y divide-gray-200"
    :data-test="`availability-group-${
      availabilityGroup.id || availabilityGroup.placeholderId
    }-container`"
  >
    <div class="p-2 flex items-center justify-between text-sm">
      <div class="flex items-center space-x-1">
        <button
          @click.prevent="expanded = !expanded"
          type="button"
          class="h-5 w-5 inline-flex justify-center items-center text-sm text-gray-700 hover:text-gray-800"
        >
          <ChevronDownIcon class="h-5 w-5" />
        </button>

        <DataField
          v-if="availabilityGroupDataField"
          :data-field="availabilityGroupDataField"
          text-classes="text-sm font-medium"
          text-styles=""
          @completed="fetchAvailabilityGroupDataField"
          @dismiss="closeGroup"
        />

        <a
          v-else
          @click.prevent="expanded = !expanded"
          href=""
          class="text-gray-700 hover:text-gray-900 font-bold"
          >Grouping {{ order }}</a
        >

        <div
          v-tooltip="'Property diagram floor area outline color'"
          class="h-4 w-4 rounded-md ring-1 ring-inset ring-black ring-opacity-0"
          :class="[availabilityGroup.diagramLegendColor]"
        />
      </div>
      <div class="flex items-center text-gray-500 space-x-2">
        <div
          :data-test="`availability-group-${
            availabilityGroup.id || availabilityGroup.placeholderId
          }-asset-count`"
        >
          {{
            pluralize(
              "Space",
              spaceUsageBuilderStore.availabilitiesLength(availabilityGroup),
              true,
            )
          }}
        </div>
        <button
          v-if="
            spaceUsageBuilderStore.availabilitiesLength(availabilityGroup) >
              0 && !availabilityGroup.id
          "
          @click="saveGroup"
          type="button"
          :data-test="`save-availability-group-${availabilityGroup.placeholderId}-button`"
          :disabled="actionInProgress"
          class="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <PulseLoader
            v-if="actionInProgress"
            :loading="true"
            size="4px"
            color="#f3f4f6"
          />
          <template v-else>Save</template>
        </button>
        <button
          @click="closeGroup"
          type="button"
          v-tooltip="availabilityGroup.id ? 'Exit' : 'Cancel'"
          class="h-5 w-5 p-1 inline-flex justify-center items-center text-sm text-gray-500 hover:text-gray-700"
        >
          <i class="fas fa-times" />
        </button>
      </div>
    </div>
    <div v-if="expanded" class="flex flex-1">
      <!-- Narrow sidebar-->
      <nav
        aria-label="Sidebar"
        class="flex-shrink-0 p-2 bg-gray-100 overflow-y-auto"
      >
        <div class="relative flex flex-col space-y-2">
          <a
            v-for="tab in contextedTabs"
            @click.prevent="selectedTab = tab"
            :key="tab.name"
            :href="tab.href"
            v-tooltip="tab.name"
            :class="[
              selectedTab.name === tab.name
                ? 'text-gray-700 bg-gray-300'
                : 'text-gray-500 hover:bg-gray-200',
              'flex-shrink-0 inline-flex items-center justify-center h-8 w-8 rounded-lg',
            ]"
            class=""
            :data-test="`availability-group-${_.kebabCase(tab.name)}-nav`"
          >
            <span class="sr-only">{{ tab.name }}</span>
            <svg
              :class="selectedTab.name === tab.name ? '' : ''"
              class="h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
                :d="tab.svgPath"
              />
            </svg>
          </a>
        </div>
      </nav>

      <div class="flex-grow h-80 overflow-y-auto">
        <component :is="selectedTab.component" v-bind="selectedTabProperties" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ChevronDownIcon } from "@heroicons/vue/20/solid";
import AvailabilityGroupContractDetailsTabs from "@/components/space-usage-builder/AvailabilityGroupContractDetailsTabs.vue";
import AvailabilityGroupPlayers from "@/components/space-usage-builder/AvailabilityGroupPlayers.vue";
import AvailabilityGroupTiming from "@/components/space-usage-builder/AvailabilityGroupTiming.vue";
import AvailabilitiesList from "@/components/space-usage-builder/AvailabilitiesList.vue";
import DataField from "@/components/crowdsourcing/DataField.vue";
import ContentAppearance from "@/components/crowdsourcing/ContentAppearance.vue";
import ContentFiles from "@/components/crowdsourcing/ContentFiles.vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { useTimeTravelStore } from "@/stores/timeTravel";
import { useSpaceUsageBuilderStore } from "@/stores/spaceUsageBuilder";
import { ref, computed, onMounted, markRaw } from "vue";
import api from "@/router/api";
import pluralize from "pluralize";
import _ from "lodash";

const props = defineProps(["availabilityGroup", "order"]);

const spaceUsageBuilderStore = useSpaceUsageBuilderStore();
const timeTravelStore = useTimeTravelStore();

const availabilityGroupDataField = ref(null);
const existingGroupId = computed(() => {
  return _.get(props.availabilityGroup, "id");
});

const actionInProgress = ref(false);

const tabs = ref([
  {
    name: "Spaces",
    order: 1,
    href: "",
    svgPath:
      "m21 7.5-9-5.25L3 7.5m18 0-9 5.25m9-5.25v9l-9 5.25M3 7.5l9 5.25M3 7.5v9l9 5.25m0-9v9",
    component: markRaw(AvailabilitiesList),
  },
  {
    name: "Lease/License",
    href: "",
    svgPath:
      "M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z",
    component: markRaw(AvailabilityGroupContractDetailsTabs),
  },
  {
    name: "Players",
    order: 4,
    href: "",
    svgPath:
      "M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z",
    component: markRaw(AvailabilityGroupPlayers),
  },
  {
    name: "Timing",
    order: 5,
    href: "",
    svgPath: "M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z",
    component: markRaw(AvailabilityGroupTiming),
  },
  {
    name: "Files",
    order: 6,
    href: "",
    svgPath:
      "M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z",
    component: markRaw(ContentFiles),
  },
  {
    name: "Appearance",
    order: 7,
    href: "",
    svgPath:
      "M10 6H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V8a2 2 0 00-2-2h-5m-4 0V5a2 2 0 114 0v1m-4 0a2 2 0 104 0m-5 8a2 2 0 100-4 2 2 0 000 4zm0 0c1.306 0 2.417.835 2.83 2M9 14a3.001 3.001 0 00-2.83 2M15 11h3m-3 4h2",
    component: markRaw(ContentAppearance),
  },
]);
const selectedTab = ref({
  name: "Spaces",
  order: 1,
  href: "",
  svgPath:
    "m21 7.5-9-5.25L3 7.5m18 0-9 5.25m9-5.25v9l-9 5.25M3 7.5l9 5.25M3 7.5v9l9 5.25m0-9v9",
  component: markRaw(AvailabilitiesList),
});

const selectedTabProperties = computed(() => {
  return {
    existingGroupId: props.availabilityGroup.id,
    placeholderGroupId: props.availabilityGroup.placeholderId,
    fieldContent: props.availabilityGroup,
    fieldContentType: "SpaceAvailabilityGroup",
    dataField: {
      fieldContent: props.availabilityGroup,
      fieldContentType: "SpaceAvailabilityGroup",
    },
    context: "availability",
    optional: true,
  };
});

const expanded = computed({
  get() {
    return props.availabilityGroup.expanded;
  },
  set(bool) {
    spaceUsageBuilderStore.setAvailabilityGroupExpanded({
      groupId:
        props.availabilityGroup.id || props.availabilityGroup.placeholderId,
      expanded: bool,
    });
  },
});

const contextedTabs = computed(() => {
  if (props.availabilityGroup.id) {
    return tabs.value;
  } else {
    return tabs.value.filter((tab) => {
      return _.includes(["Spaces", "Players", "Timing"], tab.name);
    });
  }
});

onMounted(() => {
  if (existingGroupId.value) {
    fetchAvailabilities();
    fetchAvailabilityGroupDataField();
  }
});

function fetchAvailabilities() {
  spaceUsageBuilderStore.fetchAvailabilityGroupAvailabilities(
    props.availabilityGroup.id,
  );
}

async function fetchAvailabilityGroupDataField() {
  if (props.availabilityGroup.id) {
    const json = await api.get(
      `space_availability_groups/${props.availabilityGroup.id}`,
    );

    if (json?.data) {
      availabilityGroupDataField.value = json.data;
    }
  }
}

function saveGroup() {
  actionInProgress.value = true;
  spaceUsageBuilderStore.saveAvailabilityGroup({
    placeholderId: props.availabilityGroup.placeholderId,
    successCallback: async (json) => {
      actionInProgress.value = false;
      const closingGroupId = props.availabilityGroup.placeholderId;
      const newGroup = _.find(json.data.dataFields, {
        fieldContentType: "SpaceAvailabilityGroup",
      });

      timeTravelStore.triggerRefetch();

      setTimeout(() => {
        spaceUsageBuilderStore.refreshAvailabilityGroup({
          closingGroupId,
          groupId: newGroup?.fieldContent?.id,
          isExistingGroup: false,
        });
      }, 1000);
    },
  });
}

function closeGroup() {
  spaceUsageBuilderStore.removeAvailabilityGroup({
    groupId:
      props.availabilityGroup.id || props.availabilityGroup.placeholderId,
    existing: !!props.availabilityGroup.id,
  });
}
</script>
