/* global L */
import { ref, computed } from "vue";
import { defineStore, acceptHMRUpdate } from "pinia";
import locationMarker from "@/components/maps/locationMarker";
import api from "@/router/api";
import _ from "lodash";
import { useRoute } from "vue-router";

export const useMainMapStore = defineStore("mainMap", () => {
  const map = ref(null);
  const refreshing = ref(false);
  const fitting = ref(false);
  const placeDetails = ref(null);
  const placePredictions = ref(null);
  const minimapSize = ref("micro");
  const expandedMap = computed(() => minimapSize.value === "compact");
  const zoomBasedLayerSwitchOverride = ref(false);
  const mapAddingProperty = ref(false);
  const fetchingMapData = ref(false);
  const fetchingAddedPropertyAddress = ref(false);
  const mapAddedProperty = ref({
    marker: null,
    name: null,
    address: null,
    cityState: null,
    customAddress: false,
  });
  const mapSearch = ref(null);
  const hoveringSearchResults = ref(false);
  const searchControl = ref(null);
  const loaderControl = ref(null);
  const searchMarker = ref(null);
  const newOriginOverride = ref(false);
  const temporaryMapCenter = ref({
    lat: null,
    lng: null,
    zoom: null,
  });
  const mapBoundaryMeta = ref({
    centerLatLng: null,
    southwestLatLng: null,
    northeastLatLng: null,
    zoom: null,
  });
  const zoom = computed(() => mapBoundaryMeta.value.zoom);
  const centerLatLng = computed(() => mapBoundaryMeta.value.centerLatLng);
  const propertyMarkerPulseId = ref(null);
  const nearbyPropertyDataFields = ref({});
  const nearbyLandCoveringLocationDataFields = ref({});
  const nearbyParcelPolygonDataFields = ref({});
  const nearbyHuntDataFields = ref([]);
  const route = useRoute();
  const query = computed(() => route.query);
  const horizontalIsAnalyze = computed(
    () => _.get(query.value, "horizontalTab") === "Analyze",
  );
  const propertyZoomTrigger = computed(() => {
    return horizontalIsAnalyze.value ? 11 : 13;
  });

  function clearSearchMarker() {
    if (searchMarker.value) {
      map.value.removeLayer(searchMarker.value);
      searchMarker.value = null;
    }
  }

  function clearSearchControl() {
    if (searchControl.value) {
      map.value.removeControl(searchControl.value);
      searchControl.value = null;
    }
  }

  function clearLoaderControl() {
    if (loaderControl.value) {
      map.value.removeControl(loaderControl.value);
      loaderControl.value = null;
    }
  }

  function tapView() {
    let { lat, lng } = map.value.getCenter();
    lat += 0.0000000000005;
    lng += 0.0000000000005;
    const zoom = map.value.getZoom();
    map.value.setView([lat, lng], zoom);
  }

  async function lookupMapAddedPropertyAddress(mapStoreInstance) {
    return new Promise((resolve) => {
      let payload = {};

      if (mapAddedProperty.value.marker) {
        const currentCoordinates = mapAddedProperty.value.marker.getLatLng();
        payload.lat = currentCoordinates.lat;
        payload.lng = currentCoordinates.lng;
      } else if (mapSearch.value) {
        const { lat, lng } = mapSearch.value;
        payload.lat = lat;
        payload.lng = lng;

        clearSearchMarker();

        const markerIcon = locationMarker({
          classes: "h-5 w-5 bg-red-600 hover:bg-red-700 focus:ring-red-500",
          interactive: true,
          mapStore: mapStoreInstance,
        });
        mapAddedProperty.value.marker = L.marker([lat, lng], {
          icon: markerIcon,
        });
        mapAddedProperty.value.marker.addTo(map.value);
        mapAddedProperty.value.marker.pm.enable();
      }

      fetchingAddedPropertyAddress.value = true;

      api.post(`property_address_lookups`, payload).then(
        (json) => {
          if (json.data) {
            const { street_number, street, city, state } = json.data;
            const number = street_number ? `${street_number} ` : "";
            const cityState = `${city}, ${state}`;
            const rawAddress = `${number}${street}`;
            let simpleAddress;

            simpleAddress = rawAddress;

            if (!mapAddedProperty.value.customAddress) {
              setMapAddedPropertyName({
                name: simpleAddress,
                userCustomized: false,
              });
            }
            mapAddedProperty.value.address = rawAddress;
            mapAddedProperty.value.cityState = cityState;
          } else {
            if (!mapAddedProperty.value.customAddress) {
              setMapAddedPropertyName({
                name: "New Property",
                userCustomized: false,
              });
            }
            mapAddedProperty.value.address = "Unknown address";
            mapAddedProperty.value.cityState = "Unknown area";
          }

          if (!mapSearch.value) mapAddedProperty.value.marker.openPopup();
          fetchingAddedPropertyAddress.value = false;
          resolve();
        },
        () => {
          if (!mapAddedProperty.value.customAddress) {
            setMapAddedPropertyName({
              name: "New Property",
              userCustomized: false,
            });
          }
          if (!mapSearch.value) mapAddedProperty.value.marker.openPopup();
          mapAddedProperty.value.address = "Unknown address";
          mapAddedProperty.value.cityState = "Unknown area";
          fetchingAddedPropertyAddress.value = false;
          resolve();
        },
      );
    });
  }

  function clearMapAddedProperty() {
    mapAddedProperty.value = {
      marker: null,
      address: null,
      cityState: null,
      customAddress: false,
    };
  }

  function setMapAddedPropertyName({ name, userCustomized }) {
    mapAddedProperty.value.name = name;
    mapAddedProperty.value.customAddress = userCustomized;
  }

  function setNearbyDataField({ dataField, locationType }) {
    if (dataField) {
      let collection;
      switch (locationType) {
        case "Property":
          collection = nearbyPropertyDataFields.value;
          break;
        case "ContentLocation":
          collection = nearbyLandCoveringLocationDataFields.value;
          break;
        case "ContentPolygon":
          collection = nearbyParcelPolygonDataFields.value;
          break;
        default:
          console.log("setNearbyDataField: unknown locationType", locationType);
      }

      collection[dataField.fieldContentId] = dataField;
    }
  }

  return {
    map,
    refreshing,
    fitting,
    placeDetails,
    placePredictions,
    mapAddedProperty,
    mapSearch,
    hoveringSearchResults,
    temporaryMapCenter,
    mapBoundaryMeta,
    zoom,
    propertyZoomTrigger,
    centerLatLng,
    minimapSize,
    mapAddingProperty,
    fetchingMapData,
    fetchingAddedPropertyAddress,
    propertyMarkerPulseId,
    nearbyPropertyDataFields,
    nearbyLandCoveringLocationDataFields,
    nearbyParcelPolygonDataFields,
    nearbyHuntDataFields,
    searchMarker,
    searchControl,
    loaderControl,
    newOriginOverride,
    zoomBasedLayerSwitchOverride,
    expandedMap,
    horizontalIsAnalyze,
    clearMapAddedProperty,
    clearSearchMarker,
    clearSearchControl,
    clearLoaderControl,
    setMapAddedPropertyName,
    setNearbyDataField,
    lookupMapAddedPropertyAddress,
    tapView,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useMainMapStore, import.meta.hot));
}
