<template>
  <DataField
    calculation-name="headcount_based_area"
    :calculation-value="impliedPhaseArea"
    :bundle-field-ids="bundleFieldIds"
    :text-classes="textClasses"
    @unlocked="refetch"
  />
</template>

<script setup>
import DataField from "@/components/crowdsourcing/DataField.vue";
import { computed, onMounted } from "vue";
import { currencyAmount } from "@/assets/numberHelpers";
import _ from "lodash";

const props = defineProps([
  "requirementDataField",
  "phaseDataField",
  "textClasses",
]);
const emit = defineEmits(["refetch"]);

const assumptionsFields = computed(
  () => props.requirementDataField.fieldContent?.headcountAssumptions,
);
const minDensityField = computed(() =>
  _.find(assumptionsFields.value, { fieldName: "min_area_per_person" }),
);
const maxDensityField = computed(() =>
  _.find(assumptionsFields.value, { fieldName: "max_area_per_person" }),
);
const densityModifierField = computed(() =>
  _.find(assumptionsFields.value, { fieldName: "density_percent_modifier" }),
);
const inputs = computed(() => props.phaseDataField.fieldContent?.inputs);
const minHeadcountField = computed(() =>
  _.find(inputs.value, { fieldName: "min_headcount" }),
);
const maxHeadcountField = computed(() =>
  _.find(inputs.value, { fieldName: "max_headcount" }),
);
const calculationFields = computed(() => {
  return [
    minDensityField.value,
    maxDensityField.value,
    densityModifierField.value,
    minHeadcountField.value,
    maxHeadcountField.value,
  ];
});
const calculable = computed(() => {
  return calculationFields.value.every(({ fieldValue }) => !!fieldValue);
});
const impliedPhaseArea = computed(() => {
  if (calculable.value) {
    const toPercent = (n) => {
      return n / 100;
    };
    const lowEnd =
      _.toNumber(minDensityField.value.fieldValue) *
      _.toNumber(minHeadcountField.value.fieldValue) *
      (1 - toPercent(_.toNumber(densityModifierField.value.fieldValue)));
    const highEnd =
      _.toNumber(maxDensityField.value.fieldValue) *
      _.toNumber(maxHeadcountField.value.fieldValue) *
      (1 + toPercent(_.toNumber(densityModifierField.value.fieldValue)));

    return `${currencyAmount(lowEnd, 0, -3)}-${currencyAmount(
      highEnd,
      0,
      -3,
    )} SF`;
  } else {
    return null;
  }
});

const bundleFieldIds = computed(() => {
  return calculationFields.value.map(({ localId }) => localId);
});

onMounted(() => {});

function refetch() {
  emit("refetch");
}
</script>
