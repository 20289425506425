<template>
  <div class="relative transform overflow-hidden text-left shadow-xl">
    <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
      <div class="sm:flex sm:items-start">
        <div
          class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-sky-100 sm:mx-0 sm:h-10 sm:w-10"
        >
          <ExclamationTriangleIcon
            class="h-6 w-6 text-sky-600"
            aria-hidden="true"
          />
        </div>
        <div
          class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left"
          data-test="verify-email-prompt"
        >
          <h3 class="text-base font-semibold leading-6 text-gray-900">
            Verify your email
          </h3>
          <div class="mt-2">
            <p class="text-sm text-gray-500">
              To provide a safe and enjoyable experience for everyone on Tower
              Hunt, the use of certain features requires a verified email.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
      <button
        type="button"
        class="inline-flex w-full justify-center rounded-md bg-sky-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 sm:ml-3 sm:w-auto"
        @click="resendConfirmation"
      >
        Resend confirmation
      </button>
      <button
        type="button"
        class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
        @click="viewAccount"
        ref="cancelButtonRef"
      >
        Change email
      </button>
    </div>
  </div>
</template>

<script setup>
import { ExclamationTriangleIcon } from "@heroicons/vue/24/outline";
import { useUserStore } from "@/stores/user";
import { useModalStore } from "@/stores/modal";

const userStore = useUserStore();
const modalStore = useModalStore();

function resendConfirmation() {
  userStore.resendConfirmation();
  modalStore.closeModal();
}
function viewAccount() {
  userStore.viewAccount();
  modalStore.closeModal();
}
</script>
