<template>
  <li>
    <button
      v-if="interceptTask"
      @click="promptToSubscribe"
      class="px-1 py-0.5 rounded-md block text-left border-2 border-white text-sm font-medium text-gray-900 bg-gray-200 underline decoration-2 decoration-dashed decoration-gray-700"
    >
      <span>Subscribe to view</span>
    </button>
    <InlineReminderForm
      v-else-if="selectedTask && selectedTask.id === task.id"
    />
    <div
      v-else
      :class="
        highlighted ? 'bg-indigo-200 hover:bg-indigo-300' : 'hover:bg-gray-100'
      "
      class="block"
    >
      <div class="flex items-start p-2">
        <div class="flex min-w-0 flex-1 items-start">
          <button
            v-if="batchSelection"
            @click="toggleBatchSelected"
            type="button"
            :class="batchSelectionColor"
            class="flex-shrink-0 flex items-center justify-center h-6 w-6 rounded-full border"
            :data-test="`task-${task.id}-toggle-batch-selected`"
          >
            <CheckIcon v-if="batchSelected" class="h-4 w-4" />
          </button>
          <button
            v-else
            @click="toggleComplete"
            type="button"
            :class="completedColor"
            class="flex-shrink-0 flex items-center justify-center h-6 w-6 rounded-full border"
            :data-test="`task-${task.id}-toggle-complete`"
          >
            <div
              v-if="task.completedAt"
              :class="completedCenterColor"
              class="h-4 w-4 rounded-full"
            />
          </button>
          <div class="min-w-0 flex-1 px-4 space-y-0.5">
            <div @click="handleBodyClick" class="truncate text-sm space-x-1">
              <span
                v-if="task.priority !== 'none'"
                :class="priorityColor"
                class="font-semibold"
                >{{ displayPriority }}</span
              >
              <span class="font-medium text-gray-600">{{ task.name }}</span>
            </div>
            <p
              @click="handleBodyClick"
              class="flex items-center text-sm text-gray-500 whitespace-pre-line dont-break-out"
            >
              {{ task.note }}
            </p>
            <template v-if="nonListView">
              <p class="flex items-center text-xs">
                <span class="text-gray-500">{{ task.taskListName }}</span>
                <span
                  :class="
                    scheduledView || todayView ? dateColor : 'text-gray-500'
                  "
                  ><template
                    v-if="
                      task.dueDate &&
                      (completedView || context === 'todayPastDue')
                    "
                    >&nbsp;&dash;&nbsp;{{ displayDate }}</template
                  ><template
                    v-else-if="
                      task.dueDate && (scheduledView || context === 'today')
                    "
                    >&nbsp;&dash;&nbsp;{{ displayTime }}</template
                  ><template v-if="task.repeat !== 'never'"
                    >,&nbsp;{{ displayRepeat?.name }}</template
                  ></span
                >
              </p>
              <p
                v-if="completedView"
                class="flex items-center text-xs text-gray-500"
              >
                Completed: {{ completedDisplayDate }}
              </p>
            </template>
            <p
              v-else-if="task.dueDate"
              :class="dateColor"
              class="flex items-center text-sm"
            >
              {{ displayDate
              }}<template v-if="task.repeat !== 'never'"
                >,&nbsp;{{ displayRepeat?.name }}</template
              >
            </p>
            <TaskContent v-if="task.contentType" :task="task" />
          </div>
        </div>
      </div>
    </div>
  </li>
</template>

<script setup>
import { useTaskListStore } from "@/stores/taskList";
import { useTasksStore } from "@/stores/tasks";
import { useUserStore } from "@/stores/user";
import { useModalStore } from "@/stores/modal";
import { useUnlockerStore } from "@/stores/unlocker";
import { useReminderStore } from "@/stores/reminder";
import { storeToRefs } from "pinia";
import { ref, computed, onMounted, watch, onBeforeUnmount } from "vue";
import moment from "moment";
import api from "@/router/api";
import subscribeInterceptor from "@/components/crowdsourcing/subscribeInterceptor";
import _ from "lodash";
import InlineReminderForm from "@/components/tasks/InlineReminderForm.vue";
import TaskContent from "@/components/tasks/TaskContent.vue";
import { CheckIcon } from "@heroicons/vue/20/solid";

const props = defineProps([
  "task",
  "groupedList",
  "hasQueryTasks",
  "numericIds",
  "context",
]);
const emit = defineEmits(["completion-toggled", "refetch-for-repeat"]);

const reminderStore = useReminderStore();
const { repetitions } = storeToRefs(reminderStore);
const taskListStore = useTaskListStore();
const tasksStore = useTasksStore();
const { selectedTaskList, selectedTask, batchSelection, batchTasks } =
  storeToRefs(tasksStore);
const userStore = useUserStore();
const { signedIn } = storeToRefs(userStore);

const { resetRequired, upgradeSuccessful } = storeToRefs(useUnlockerStore());
const upgradeInProgress = ref(false);
const { modalPayload } = storeToRefs(useModalStore());
const interceptTask = computed(
  () => _.get(props.task, "meteringStatus") === "prompt_to_subscribe"
);

const completedView = computed(() => props.context === "completed");
const scheduledView = computed(() => props.context === "scheduled");
const todayView = computed(() =>
  _.includes(["today", "todayPastDue"], props.context)
);
const nonListView = computed(
  () => completedView.value || scheduledView.value || todayView.value
);
const momentDueDate = computed(() => {
  if (signedIn.value) {
    return moment.unix(props.task.dueDate);
  } else {
    return moment(props.task.dueDate);
  }
});
const momentCompletedDate = computed(() => {
  if (signedIn.value) {
    return moment.unix(props.task.completedAt);
  } else {
    return moment(props.task.completedAt);
  }
});
const highlighted = computed(() => {
  return props.hasQueryTasks && _.includes(props.numericIds, props.task.id);
});
const batchSelected = computed(() => {
  return _.intersectionBy(batchTasks.value, [props.task], "id").length > 0;
});
const displayPriority = computed(() => {
  switch (props.task.priority) {
    case "low":
      return "!";
    case "medium":
      return "!!";
    case "high":
      return "!!!";
    default:
      return "";
  }
});

const displayDate = computed(() => {
  return momentDueDate.value.format("M/D/YY, h:mm a");
});
const displayTime = computed(() => {
  return momentDueDate.value.format("h:mm a");
});
const displayRepeat = computed(() => {
  return _.find(repetitions.value, { apiValue: props.task.repeat });
});
const completedDisplayDate = computed(() => {
  return momentCompletedDate.value.format("M/D/YY, h:mm a");
});
const dateColor = computed(() => {
  if (momentDueDate.value.isBefore()) {
    return "text-red-500";
  } else {
    return "text-gray-500";
  }
});
const batchSelectionColor = computed(() => {
  if (batchSelected.value) {
    if (completedView.value) {
      return "bg-gray-500 border-gray-500 text-gray-50";
    } else if (scheduledView.value || todayView.value) {
      return "bg-indigo-500 border-indigo-500 text-gray-50";
    } else {
      return `${taskListStore.colorFor(
        props.groupedList || selectedTaskList.value,
        "borderColor"
      )} ${taskListStore.colorFor(
        props.groupedList || selectedTaskList.value,
        "bgColor"
      )} text-gray-50`;
    }
  } else {
    return "border-gray-500";
  }
});
const completedColor = computed(() => {
  if (nonListView.value) {
    return "border-gray-500";
  } else if (props.task.completedAt) {
    return `${taskListStore.colorFor(
      props.groupedList || selectedTaskList.value,
      "borderColor"
    )}`;
  } else {
    return "border-gray-500";
  }
});
const completedCenterColor = computed(() => {
  if (nonListView.value) {
    return "bg-gray-500";
  } else {
    return `${taskListStore.colorFor(
      props.groupedList || selectedTaskList.value,
      "bgColor"
    )}`;
  }
});
const priorityColor = computed(() => {
  if (completedView.value) {
    return "text-gray-500";
  } else if (scheduledView.value) {
    return "text-red-500";
  } else if (todayView.value) {
    return "text-blue-500";
  } else {
    return taskListStore.colorFor(
      props.groupedList || selectedTaskList.value,
      "textColor"
    );
  }
});

onMounted(() => {
  if (interceptTask.value && !modalPayload.value) {
    promptToSubscribe();
  }
});

function handleBodyClick() {
  if (batchSelection.value) {
    toggleBatchSelected();
  } else {
    edit();
  }
}
function toggleBatchSelected() {
  if (batchSelected.value) {
    batchTasks.value = _.differenceBy(batchTasks.value, [props.task], "id");
  } else {
    batchTasks.value = _.unionBy([props.task], batchTasks.value, "id");
  }
}
function toggleComplete() {
  if (signedIn.value) {
    if (props.task.completedAt) {
      api.delete(`task_completions/${props.task.id}`).then((json) => {
        tasksStore.patchTasks([json.data]);
        userStore.fetchAvailableCollectibleCardCount();
        emit("completion-toggled", json.data);
        if (props.task.repeat !== "never") emit("refetch-for-repeat");
      });
    } else {
      api.patch(`task_completions/${props.task.id}`).then((json) => {
        tasksStore.patchTasks([json.data]);
        userStore.fetchAvailableCollectibleCardCount();
        emit("completion-toggled", json.data);
        if (props.task.repeat !== "never") emit("refetch-for-repeat");
      });
    }
  } else {
    const newCompletedAt = props.task.completedAt ? null : moment().valueOf();
    const newTask = _.merge({}, props.task, { completedAt: newCompletedAt });
    tasksStore.patchTasks([newTask]);
    emit("completion-toggled", newTask);
  }
}
function edit() {
  reminderStore.mountExisting(props.task);
  selectedTask.value = props.task;
}

onBeforeUnmount(() => {
  if (upgradeInProgress.value) {
    upgradeSuccessful.value = false;
  }
});

watch(resetRequired, () => {
  if (upgradeInProgress.value) {
    resetRequired.value = false;
    upgradeInProgress.value = false;
  }
});

function promptToSubscribe() {
  if (interceptTask.value && !modalPayload.value) {
    upgradeInProgress.value = true;
    const successCallback = () => {
      if (upgradeSuccessful.value) {
        console.log("user subscribed");
        upgradeInProgress.value = false;
        upgradeSuccessful.value = false;
        emit("refetch-for-repeat");
      }
    };

    subscribeInterceptor({
      apiRequestFunc: null,
      successCallback,
      modalPayloadRef: modalPayload,
      upgradeSuccessfulRef: upgradeSuccessful,
      afterSubscribe: "successCallback",
      promptReason: "dataUsage",
      context: "licensing",
    });
  }
}
</script>
