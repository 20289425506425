<template>
  <section aria-labelledby="nearby-companies">
    <ContentHeader :help-article="nearbyCompanies" :no-margin="true">
      <template v-slot:title>Companies</template>
      <template
        v-if="!pagedInvolvements.pagy?.count || investmentLikeFilterable"
        v-slot:description
        >Discover key players in this area</template
      >
      <template v-else v-slot:description
        >Discover key players across
        {{ pluralize("event", pagedInvolvements.pagy?.count, true) }} in this
        area</template
      >
    </ContentHeader>

    <div class="p-2">
      <div
        v-if="zoom >= 13"
        :class="
          workspaceLayout === 'sideBySide'
            ? 'grid-cols-2'
            : 'grid-cols-6 grid-flow-row-dense'
        "
        class="grid gap-3"
      >
        <div
          v-if="!nearbyCompaniesLoaded"
          :class="
            workspaceLayout === 'sideBySide' ? 'col-span-2' : 'col-span-6'
          "
          class="flex h-full w-full items-center justify-center"
        >
          <SquareLoader :loading="true" size="32px" color="#0d9488" />
        </div>
        <NearbyCompany
          v-for="{
            companyId,
            involvements,
            hunts,
            huntAdvisories,
            propertyGrouped,
          } in groupedInvolvements"
          :key="companyId"
          :company-id="companyId"
          :involvements="involvements"
          :hunts="hunts"
          :hunt-advisories="huntAdvisories"
          :property-grouped="propertyGrouped"
        />
        <section
          v-if="
            nearbyCompaniesLoaded &&
            !loadingEvents &&
            _.size(groupedInvolvements) === 0 &&
            pagedInvolvements.pagy?.count > 0
          "
          :class="
            workspaceLayout === 'sideBySide' ? 'col-span-2' : 'col-span-6'
          "
        >
          <div
            class="relative block w-full rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <FunnelIcon class="mx-auto h-12 w-12 text-gray-400" />
            <span class="mt-2 block text-sm font-medium text-gray-900">
              No company events match your filters.
            </span>
          </div>
        </section>
        <section
          v-else-if="
            nearbyCompaniesLoaded &&
            !loadingEvents &&
            _.size(groupedInvolvements) === 0
          "
          :class="
            workspaceLayout === 'sideBySide' ? 'col-span-2' : 'col-span-6'
          "
        >
          <div
            class="relative block w-full rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <PuzzlePieceIcon class="mx-auto h-12 w-12 text-gray-400" />
            <span class="mt-2 block text-sm font-medium text-gray-900">
              Help us fill this area in. Contributors earn revenue.
            </span>
          </div>
        </section>
        <section
          v-else-if="
            nearbyCompaniesLoaded &&
            loadingEvents &&
            _.size(groupedInvolvements) === 0
          "
          :class="
            workspaceLayout === 'sideBySide' ? 'col-span-2' : 'col-span-6'
          "
        >
          <div
            class="relative block w-full rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <SquareLoader :loading="true" size="28px" color="#0d9488" />
            <span class="mt-2 block text-sm font-medium text-gray-900">
              Loading company events...
            </span>
          </div>
        </section>
        <section
          v-if="
            nearbyCompaniesLoaded &&
            pagedInvolvements.pagy &&
            pagedInvolvements.pagy.count
          "
          :class="
            workspaceLayout === 'sideBySide' ? 'col-span-2' : 'col-span-6'
          "
          class="flex flex-col justify-center space-y-3"
        >
          <div
            v-if="
              filteredInvestmentLikes.length === 0 &&
              filteredSpaceUsageAvailabilityLikes.length === 0
            "
          >
            <p class="text-center text-gray-800">
              Showing
              {{ " " }}
              <span class="font-medium">{{
                pagedInvolvements.data.length
              }}</span>
              {{ " " }}
              of
              {{ " " }}
              <span class="font-medium">{{
                pagedInvolvements.pagy.count
              }}</span>
              {{ " " }}
              events
            </p>
          </div>
          <div v-if="pagedInvolvements.pagy.next">
            <p v-if="loadingEvents" class="text-center text-sm text-gray-700">
              Loading more events...
            </p>
            <p v-else class="text-center text-sm text-gray-700">
              Scroll down to load more events
            </p>
          </div>
          <InfiniteLoading
            v-if="
              nearbyCompaniesLoaded &&
              pagedInvolvements.pagy &&
              pagedInvolvements.pagy.next
            "
            @infinite="loadCompanies"
          />
        </section>
      </div>
      <section v-else>
        <div
          class="relative block w-full rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          :class="
            workspaceLayout === 'sideBySide' ? 'col-span-2' : 'col-span-6'
          "
        >
          <MagnifyingGlassPlusIcon class="mx-auto h-12 w-12 text-gray-400" />
          <span class="mt-2 block text-sm font-medium text-gray-900">
            Zoom in more
          </span>
        </div>
      </section>
    </div>
  </section>
</template>

<script setup>
import ContentHeader from "@/components/main-layout/secondary-panel/ContentHeader.vue";
import SquareLoader from "vue-spinner/src/SquareLoader.vue";
import { nearbyCompanies } from "@/assets/documentation/articles/nearbyCompanies";
import { useNearbyCompaniesStore } from "@/stores/nearbyCompanies";
import { useMainMapStore } from "@/stores/mainMap";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { useAnalyzePanelStore } from "@/stores/analyzePanel";
import { storeToRefs } from "pinia";
import _ from "lodash";
import { onMounted, computed, ref, watch } from "vue";
import pluralize from "pluralize";
import NearbyCompany from "@/components/company-detail/NearbyCompany.vue";
import {
  FunnelIcon,
  MagnifyingGlassPlusIcon,
  PuzzlePieceIcon,
} from "@heroicons/vue/24/outline";
import api from "@/router/api";

const nearbyCompaniesStore = useNearbyCompaniesStore();
const {
  pagedInvolvements,
  rawFilteredInvolvements,
  groupedInvolvements,
  nearbyCompaniesLoaded,
} = storeToRefs(nearbyCompaniesStore);
const mapStore = useMainMapStore();
const { mapBoundaryMeta } = storeToRefs(mapStore);
const layoutStore = useWorkspaceLayoutStore();
const { workspaceLayout } = storeToRefs(layoutStore);
const analyzePanelStore = useAnalyzePanelStore();
const {
  filteredInvestmentLikes,
  filteredSpaceUsageAvailabilityLikes,
  surveyCompanyCountProxy,
  investmentLikeFilterable,
} = storeToRefs(analyzePanelStore);

const zoom = computed(() => mapBoundaryMeta.value.zoom);
const groupsCount = computed(() => _.size(groupedInvolvements.value));

const loadingEvents = ref(false);

watch(groupsCount, () => {
  setUnlockableCount();
});

onMounted(() => {
  nearbyCompaniesStore.fetchCompanies();
  setUnlockableCount();
});

function setUnlockableCount() {
  if (zoom.value >= 13 && nearbyCompaniesLoaded.value) {
    surveyCompanyCountProxy.value = groupsCount.value || 0;
  }
}

function cleanUrl(url) {
  return url.replace("/api/v1/", "");
}

const loadCompanies = async ($state) => {
  if (pagedInvolvements.value.pagy?.next) {
    loadingEvents.value = true;
    const endpoint = cleanUrl(pagedInvolvements.value.pagy?.next_url);
    try {
      api.post(endpoint).then((json) => {
        const { data, pagy } = json.data;

        pagedInvolvements.value.data = _.uniqBy(
          _.concat(pagedInvolvements.value.data, data),
          "localId",
        );
        pagedInvolvements.value.pagy = pagy;
        if (data.length < 5) {
          $state.complete();
          loadingEvents.value = false;
        } else {
          $state.loaded();
          loadingEvents.value = false;
        }
      });
    } catch (error) {
      $state.error();
      loadingEvents.value = false;
    }
  } else {
    $state.complete();
  }
};
</script>
