<template>
  <div v-if="canDisplayCitations">
    <h2 class="text-gray-700 font-semibold text-sm mb-2">Citations</h2>
    <div
      class="border border-gray-300 w-full flex items-stretch rounded-md overflow-hidden divide-x-2 divide-gray-300 mb-2"
    >
      <template v-if="sideBySide">
        <div
          v-if="
            (dataFieldChange && dataFieldChange.before) ||
            beforeFields?.length > 0
          "
          class="max-h-64 overflow-y-auto w-1/2"
        >
          <div
            class="flex items-center justify-between border-b border-gray-300 text-gray-900 py-1 px-2 text-sm font-medium"
          >
            <div data-test="change-diff-before-citations-count">
              {{ pluralize("Citation", beforeCitations.length, true) }}
            </div>
            <CitationForm
              v-if="canAddCitations"
              class="flex items-center"
              :data-field="dataFieldChange.before"
              @refetch="fetchCitations"
            >
              <template v-slot:button>
                <button
                  type="button"
                  class="flex items-center space-x-1 text-gray-700 p-2 text-xs hover:bg-gray-200 hover:text-gray-900"
                >
                  <PlusCircleIcon
                    class="h-4 w-4 text-gray-400 group-hover:text-gray-500"
                  />
                  <span>Add citation</span>
                </button>
              </template>
            </CitationForm>
          </div>
          <div class="divide-y divide-gray-300">
            <CitationDetails
              v-for="(citationField, index) in beforeCitations"
              class="w-full"
              :key="citationField.localId"
              :citation-field="citationField"
              :citation-scope="'validation'"
              :removable="true"
              :data-test="`citation-${index}`"
              @validated-citation="fetchCitations"
              @refetch="fetchCitations"
            />
          </div>
        </div>
        <div v-else class="max-h-64 overflow-y-auto w-1/2 bg-gray-100" />
      </template>

      <div
        :class="sideBySide ? 'w-1/2' : 'w-full'"
        class="max-h-64 overflow-y-auto"
        data-test="after-field-citations"
      >
        <div
          class="flex items-center justify-between border-b border-gray-300 text-gray-900 py-1 px-2 text-sm font-medium"
        >
          <div data-test="change-diff-after-citations-count">
            {{ pluralize("Citation", afterCitations.length, true) }}
          </div>
          <CitationForm
            v-if="canAddCitations"
            class="flex items-center"
            :data-field="dataField || dataFieldChange.after"
            @refetch="fetchCitations"
          >
            <template v-slot:button>
              <button
                type="button"
                class="flex items-center space-x-1 text-gray-700 p-2 text-xs hover:bg-gray-200 hover:text-gray-900"
              >
                <PlusCircleIcon
                  class="h-4 w-4 text-gray-400 group-hover:text-gray-500"
                />
                <span>Add citation</span>
              </button>
            </template>
          </CitationForm>
        </div>
        <div class="divide-y divide-gray-300">
          <CitationDetails
            v-for="(citationField, index) in afterCitations"
            class="w-full"
            :key="citationField.localId"
            :citation-field="citationField"
            :citation-scope="'validation'"
            :removable="true"
            :data-test="`citation-${index}`"
            @validated-citation="fetchCitations"
            @refetch="fetchCitations"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import CitationDetails from "@/components/crowdsourcing/CitationDetails.vue";
import CitationForm from "@/components/crowdsourcing/CitationForm.vue";
import { storeToRefs } from "pinia";
import { useUserStore } from "@/stores/user";
import { ref, computed, onMounted } from "vue";
import { PlusCircleIcon } from "@heroicons/vue/20/solid";
import pluralize from "pluralize";
import api from "@/router/api";
import _ from "lodash";

const props = defineProps([
  "dataFieldChange",
  "dataField",
  "outputFormat",
  "beforeFields",
  "afterFields",
]);
const userStore = useUserStore();
const { isAdmin } = storeToRefs(userStore);
const afterCitations = ref([]);
const beforeCitations = ref([]);
const sideBySide = computed(() => {
  return props.outputFormat === "side-by-side";
});

const isCitation = computed(() => {
  const changeContentType = _.get(
    props.dataFieldChange,
    "after.decoratingContentType",
  );
  const fieldContentType = _.get(props.dataField, "decoratingContentType");
  const comparisonType = fieldContentType || changeContentType;

  return _.includes(
    ["DataFieldCitation", "CitationChangeGroup"],
    comparisonType,
  );
});
const canDisplayCitations = computed(() => {
  return !isCitation.value;
});
const canAddCitations = computed(() => {
  return (
    !isAdmin.value &&
    !isCitation.value &&
    (props.dataFieldChange || props.dataField)
  );
});

onMounted(() => fetchCitations());

async function fetchCitations() {
  if (_.get(props.dataFieldChange, "before"))
    fetchCitationsFor(props.dataFieldChange.before, "before");

  if (props.dataField) fetchCitationsFor(props.dataField, "after");
  else if (_.get(props.dataFieldChange, "after"))
    fetchCitationsFor(props.dataFieldChange.after, "after");

  if (props.afterFields?.length > 0) {
    const afterChangeIds = props.afterFields.map(
      (dataField) => dataField.latestChangeId,
    );
    fetchChangeCitationsFor(afterChangeIds, "after");
  }
  if (props.beforeFields?.length > 0) {
    const beforeChangeIds = props.beforeFields.map(
      (dataField) => dataField.latestChangeId,
    );
    fetchChangeCitationsFor(beforeChangeIds, "before");
  }
}

async function fetchCitationsFor(dataField, phase) {
  const collection = phase === "before" ? beforeCitations : afterCitations;
  collection.value = [];
  api
    .get(`data_field_citations/${dataField.localId}?scope=validation`)
    .then((json) => {
      collection.value = json.data;
    });
}

async function fetchChangeCitationsFor(changeIds, phase) {
  const collection = phase === "before" ? beforeCitations : afterCitations;
  collection.value = [];
  api
    .post(`data_field_change_citations`, { scope: "validation", changeIds })
    .then((json) => {
      collection.value = json.data;
    });
}
</script>
