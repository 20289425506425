<template>
  <div class="pointer-events-none relative" :style="`z-index: 1;`">
    <div
      class="flex items-center justify-center absolute inset-0"
      :class="[
        internalName === 'Land Coverings (above-grade)'
          ? 'bg-gradient-to-t from-gray-50 to-sky-100'
          : 'bg-gradient-to-b from-stone-500 to-gray-50',
      ]"
    >
      <SquareLoader
        v-if="
          internalName === 'Land Coverings (above-grade)' &&
          (actionInProgress || fetchingDiagramData)
        "
        :loading="true"
        size="36px"
        color="#2563eb"
      />
      <h1
        v-else-if="
          emptyCanvas &&
          internalName === 'Land Coverings (above-grade)' &&
          diagramContentSelectionStore.propertyIsSelected(propertyId)
        "
        class="text-center text-3xl font-bold tracking-tight z-10"
      >
        <span class="block text-blue-400">Build a diagram.</span>
        <span class="block text-blue-600">Commands below.</span>
      </h1>
    </div>
    <DiagramBackdrop v-if="internalName === 'Land Coverings (above-grade)'" />
    <template v-if="!actionInProgress">
      <div
        v-for="(gridRow, index) in singleParcelLandCoveringGridRows"
        :key="`${index}-single-land-covering-row`"
        :class="`pointer-events-none w-full absolute grid ${
          internalName === 'Land Coverings (above-grade)' ? 'bottom-0' : 'top-0'
        }`"
        :style="`height: ${gridRow.gridHeight}; grid-template-columns: ${gridRow.gridColumnWidths}; z-index: ${gridRow.gridZIndex}`"
      >
        <div
          v-for="(gridBlock, i) in gridRow.gridBlocks"
          :key="`${i}-single-land-covering-block-${
            internalName === 'Land Coverings (above-grade)' ? 'above' : 'below'
          }`"
          :data-test="`${
            gridBlock.coveringDataField ? `${i}` : 'empty'
          }-single-land-covering-block-${
            internalName === 'Land Coverings (above-grade)' ? 'above' : 'below'
          }`"
          @mouseenter="propertyDiagramStore.bringForward(gridBlock)"
          @mouseleave="propertyDiagramStore.revertZ()"
          :class="
            gridBlock.coveringDataField
              ? `pointer-events-auto h-full flex flex-col mx-2 text-xs uppercase tracking-wide ${
                  internalName === 'Land Coverings (above-grade)'
                    ? 'rounded-t-lg'
                    : 'rounded-b-lg'
                } ${
                  diagramContentSelectionStore.propertyRightSelectionBackgroundStylingFor(
                    {
                      propertyId,
                      dataField: gridBlock,
                      internalName,
                    }
                  ) ||
                  `text-gray-500 font-medium border-l-2 border-r-2 ${
                    internalName === 'Land Coverings (above-grade)'
                      ? 'border-t-2'
                      : 'border-b-2'
                  } ${
                    hypothetical(gridBlock) ? 'border-dashed' : ''
                  } border-gray-300 bg-slate-100 hover:border-gray-400`
                }`
              : 'mx-2'
          "
        >
          <LandCoveringDiagram
            v-if="gridBlock.coveringDataField"
            :internal-name="internalName"
            :property-id="propertyId"
            :land-covering-data-field="gridBlock.coveringDataField"
          />
        </div>
      </div>
      <div
        v-for="(gridRow, index) in multiParcelLandCoveringGridRows"
        :key="`${index}-multi-land-covering-row`"
        :class="`pointer-events-none w-full absolute grid ${
          internalName === 'Land Coverings (above-grade)' ? 'bottom-0' : 'top-0'
        }`"
        :style="`height: ${gridRow.gridHeight}; grid-template-columns: ${gridRow.gridColumnWidths}; z-index: ${gridRow.gridZIndex}`"
      >
        <div
          v-for="(gridBlock, i) in gridRow.gridBlocks"
          :key="`${i}-multi-land-covering-block-${
            internalName === 'Land Coverings (above-grade)' ? 'above' : 'below'
          }`"
          :data-test="`${
            gridBlock.coveringDataField ? `${i}` : 'empty'
          }-multi-land-covering-block-${
            internalName === 'Land Coverings (above-grade)' ? 'above' : 'below'
          }`"
          @mouseenter="propertyDiagramStore.bringForward(gridBlock)"
          @mouseleave="propertyDiagramStore.revertZ()"
          :class="
            gridBlock.coveringDataField
              ? `pointer-events-auto h-full flex flex-col mx-2 text-xs uppercase tracking-wide ${
                  internalName === 'Land Coverings (above-grade)'
                    ? 'rounded-t-lg'
                    : 'rounded-b-lg'
                } ${
                  diagramContentSelectionStore.propertyRightSelectionBackgroundStylingFor(
                    {
                      propertyId,
                      dataField: gridBlock,
                      internalName,
                    }
                  ) ||
                  `text-gray-500 font-medium border-l-2 border-r-2 ${
                    internalName === 'Land Coverings (above-grade)'
                      ? 'border-t-2'
                      : 'border-b-2'
                  } ${
                    hypothetical(gridBlock) ? 'border-dashed' : ''
                  } border-gray-300 bg-slate-100 hover:border-gray-400`
                }`
              : 'mx-2'
          "
        >
          <LandCoveringDiagram
            v-if="gridBlock.coveringDataField"
            :internal-name="internalName"
            :property-id="propertyId"
            :land-covering-data-field="gridBlock.coveringDataField"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { storeToRefs } from "pinia";
import { usePropertyDiagramActionsStore } from "@/stores/propertyDiagramActions";
import { usePropertyDiagramContentSelectionStore } from "@/stores/propertyDiagramContentSelection";
import { usePropertyDiagramStore } from "@/stores/propertyDiagram";
import LandCoveringDiagram from "@/components/property-diagram/LandCoveringDiagram.vue";
import DiagramBackdrop from "@/components/property-diagram/DiagramBackdrop.vue";
import SquareLoader from "vue-spinner/src/SquareLoader.vue";
import _ from "lodash";

const props = defineProps(["internalName", "propertyId"]);
const propertyDiagramStore = usePropertyDiagramStore();
const { fetchingDiagramData } = storeToRefs(propertyDiagramStore);
const diagramContentSelectionStore = usePropertyDiagramContentSelectionStore();
const diagramActionsStore = usePropertyDiagramActionsStore();
const { actionInProgress } = storeToRefs(diagramActionsStore);

const singleParcelLandCoveringGridRows = computed(() => {
  return propertyDiagramStore.singleParcelGridRowsFor({
    internalRowName: props.internalName,
    propertyId: props.propertyId,
  });
});
const multiParcelLandCoveringGridRows = computed(() => {
  return propertyDiagramStore.multiParcelGridRowsFor({
    internalRowName: props.internalName,
    propertyId: props.propertyId,
  });
});
const emptyCanvas = computed(
  () =>
    _.isEmpty(singleParcelLandCoveringGridRows.value) &&
    _.isEmpty(multiParcelLandCoveringGridRows.value)
);
function hypothetical(gridBlock) {
  return diagramContentSelectionStore.hypothetical(
    _.get(gridBlock, "coveringDataField")
  );
}
</script>
