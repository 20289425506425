<template>
  <div class="overflow-hidden rounded-lg p-3 shadow bg-gray-100">
    <dt class="truncate text-sm font-medium text-gray-500">Properties</dt>
    <div v-if="refreshing && !propertyIdParam" class="mt-1 w-full flex">
      <SquareLoader :loading="true" size="24px" color="#9ca3af" />
    </div>
    <dd v-else class="mt-1">
      <DataField
        calculation-name="property_count"
        :calculation-value="
          zoom >= propertyZoomTrigger ? propertyCount : 'Zoom in'
        "
        :bundle-field-ids="bundleFieldIds"
        text-classes="text-xl font-semibold tracking-tight"
        :analyze="true"
      />
    </dd>
  </div>
</template>

<script setup>
import SquareLoader from "vue-spinner/src/SquareLoader.vue";
import DataField from "@/components/crowdsourcing/DataField.vue";
import { useMainMapStore } from "@/stores/mainMap";
import { usePropertyDiagramStore } from "@/stores/propertyDiagram";
import { useCompanyDetailStore } from "@/stores/companyDetail";
import { useContactDetailStore } from "@/stores/contactDetail";
import { useAnalyzePanelStore } from "@/stores/analyzePanel";
import { storeToRefs } from "pinia";
import { computed, onMounted, watch } from "vue";
import _ from "lodash";

const mapStore = useMainMapStore();
const { zoom, propertyZoomTrigger, refreshing } = storeToRefs(mapStore);
const companyDetailStore = useCompanyDetailStore();
const { companyIdParam, companyFetchedLocationDataFields } =
  storeToRefs(companyDetailStore);
const contactDetailStore = useContactDetailStore();
const { contactIdParam, contactFetchedLocationDataFields } =
  storeToRefs(contactDetailStore);
const propertyDiagramStore = usePropertyDiagramStore();
const { propertyIdParam, propertyDiagramSelectedPropertyId } =
  storeToRefs(propertyDiagramStore);
const analyzePanelStore = useAnalyzePanelStore();
const {
  unselectedPropertyDiagram,
  propertySelectedPropertyDiagram,
  contextedPropertiesArray,
  checkedCategories,
  combinedFilteredPropertyIds,
  surveySizeCountProxy,
} = storeToRefs(analyzePanelStore);
const fieldCollection = computed(() => {
  if (propertyIdParam.value) {
    if (unselectedPropertyDiagram.value) {
      return contextedPropertiesArray.value;
    } else {
      const diagramPropertyIds = _.compact([
        propertyDiagramSelectedPropertyId.value,
      ]);
      return contextedPropertiesArray.value.filter((df) =>
        _.includes(diagramPropertyIds, df.joiningContentId),
      );
    }
  } else if (companyIdParam.value) {
    return companyFetchedLocationDataFields.value;
  } else if (contactIdParam.value) {
    return contactFetchedLocationDataFields.value;
  } else {
    return contextedPropertiesArray.value;
  }
});
const fieldArray = computed(() => _.toArray(fieldCollection.value));
const propertyCount = computed(() => {
  if (checkedCategories.value.length > 0) {
    if (propertySelectedPropertyDiagram.value) {
      return 1;
    } else {
      const included = fieldArray.value.filter((dataField) => {
        return _.includes(
          combinedFilteredPropertyIds.value,
          dataField.joiningContentId,
        );
      });

      return included.length;
    }
  } else {
    return _.size(fieldCollection.value);
  }
});
const bundleFieldIds = computed(() => {
  return _.map(fieldCollection.value, function (field) {
    return field.localId;
  });
});

watch(propertyCount, () => {
  setUnlockableCount();
});

onMounted(() => {
  setUnlockableCount();
});

function setUnlockableCount() {
  if (zoom.value >= propertyZoomTrigger.value) {
    console.log("size count", propertyCount.value);
    surveySizeCountProxy.value = propertyCount.value || 0;
  }
}
</script>
