import moment from "moment";

export default class User {
  constructor(data) {
    this.host = data.host;
    this.cypress = data.cypress;
    this.id = data.id;
    this.token = data.token;
    this.admin = data.admin;
    this.digestConsent = data.digestConsent;
    this.signedIn = data.signedIn;
    this.productTier = data.productTier;
    this.billingFrequency = "monthly";
    this.userCreatedAt = data.userCreatedAt;
    this.sessionFetchedAt = data.lastActiveAt;
    this.emailVerificationState = data.emailVerificationState;
    this.activeEasyDataInputPayloadItem = data.activeEasyDataInputPayloadItem;
    this.linked = data.linked;
    this.linkedContactId = data.linkedContactId;
    this.linkRequestPending = data.linkRequestPending;
    this.signupMonthYear = moment(data.userCreatedAt).format("MMM YYYY");
    this.name = data.name;
    this.firstName = data.firstName;
    this.email = data.email;
    this.reputation = data.reputation;
    this.reputationLevel = data.reputationLevel;
    this.reputable = data.reputable;
    this.correctPercentage = data.correctPercentage;
    this.availableBalance = data.availableBalance;
    this.validationSkips = data.validationSkips;
    this.sessionGeographyIntents = 0;
  }
}
