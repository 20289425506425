<template>
  <div class="col-span-1">
    <CapitalStackLayer
      v-if="canDisplaySubordinateLayer && layerType !== 'OwnershipInterest'"
      layer-type="Loan"
      :property-id="propertyId"
      :deal-builder-asset-data-field="dealBuilderAssetDataField"
      :data-field="subordinateLayer"
      :loan-collateral-type="null"
      :future="future"
      :allows-senior-loan="false"
      :passed-foreclosing-senior="foreclosingSenior"
      :depth="depth - 1"
      @refetch-ownership-layer="emit('refetch-ownership-layer')"
    />
    <CapitalStackLayer
      v-if="creatingLoan.direction === 'subordinate'"
      layer-type="Loan"
      :property-id="propertyId"
      :deal-builder-asset-data-field="dealBuilderAssetDataField"
      :data-field="dataField"
      :loan-collateral-type="collateralType"
      :future="future"
      :allows-senior-loan="false"
      :preactivate-form="true"
      :insert-direction="creatingLoan"
      :depth="depth - 1"
      @container-cancel="cancelLoan"
      @refetch-subordinate-layer="refetch(true)"
      @refetch-ownership-layer="emit('refetch-ownership-layer')"
    />
    <li class="flex shadow-sm">
      <div
        :class="`flex-shrink-0 flex items-center justify-center w-3 ${layerColor} text-white text-sm font-medium`"
      />
      <ul
        :class="isEquity ? 'border-t' : 'pt-6'"
        class="relative pb-6 flex-1 flex items-center border-b border-r border-gray-200 bg-white"
      >
        <div
          v-if="canAddSubordinate && !preactivateForm"
          class="absolute -top-4 flex w-full justify-center"
          style="z-index: 2"
        >
          <span
            class="relative z-0 inline-flex shadow-sm rounded-md -space-x-px"
          >
            <button
              v-if="
                creatingLoan.direction === 'subordinate' ||
                creatingSeniorLoan ||
                preactivateForm
              "
              @click="cancelLoan"
              type="button"
              class="relative inline-flex items-center px-4 py-1.5 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-400 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
            >
              <span class="sr-only">Cancel</span>
              <XMarkIcon class="-ml-1.5 mr-1 h-5 w-5 text-gray-400" />
              <span>Cancel</span>
            </button>

            <PortfolioAddMenu
              v-else-if="portfoliosAllowed"
              insert-direction="subordinate"
              @add-to-portfolio="inputPortfolioLoan"
            />

            <DataVisibilityButton
              v-else
              visibility="safezone"
              class="relative inline-flex"
            >
              <template v-slot:button>
                <button
                  @click="inputLoan('subordinate')"
                  type="button"
                  :data-test="`${decoratingAndFieldKey(
                    dealBuilderAssetDataField,
                  )}-depth-${depth}-subordinate-loan-add-button`"
                  class="relative inline-flex items-center px-4 py-1.5 rounded-md border border-yellow-300 bg-white text-sm font-medium text-gray-400 hover:bg-yellow-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-yellow-500 focus:border-yellow-500"
                >
                  <span class="sr-only">Add loan</span>
                  <PlusIcon class="-ml-1.5 mr-1 h-5 w-5 text-yellow-400" />
                  <span>Loan</span>
                </button>
              </template>
            </DataVisibilityButton>
          </span>
        </div>

        <div class="flex-1 px-4 py-2 text-sm">
          <div class="flex items-center justify-between">
            <p class="text-gray-900 font-bold hover:text-gray-600">
              <span>{{ label }}</span>
              <span v-if="loanBeingAssumed">&nbsp;being assumed</span>
            </p>
            <VDropdown v-if="displayAssetActions">
              <button
                type="button"
                v-tooltip="'Deal actions menu'"
                :disabled="originatingData"
                class="inline-flex items-center shadow-sm p-1 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 duration-150"
                :data-test="`${decoratingAndFieldKey(
                  dealBuilderAssetDataField,
                )}-depth-${depth || 0}-deal-actions-menu`"
              >
                <PulseLoader
                  v-if="originatingData"
                  :loading="true"
                  size="3px"
                  color="#f3f4f6"
                />
                <ArrowsRightLeftIcon class="h-4 w-4" />
              </button>

              <template #popper>
                <DealActionDropdown
                  :deal-builder-asset-data-field="dealBuilderAssetDataField"
                  :local-asset-data-field="dataField"
                  :decorating-data-field="dataField"
                  :subordinate-layer="subordinateLayer"
                  :providers-count="capitalProviders.length"
                  :ownership-interest-ids="ownershipInterestIds"
                  :layer-type="layerType"
                  context="capital-stack"
                  :allows-senior-loan="allowsSeniorLoan"
                  :senior-loan="seniorLoan"
                  :nested="nested"
                  :depth="depth"
                  @refetch-stack="refetch(true)"
                />
              </template>
            </VDropdown>
          </div>
          <a
            v-if="!isEquity && viewingData && !existingOpenInvestment"
            href=""
            @click.prevent="showNested = !showNested"
            class="text-indigo-500 hover:text-indigo-700"
            :data-test="`${decoratingAndFieldKey(
              dealBuilderAssetDataField,
            )}-show-nested-stack-button`"
          >
            <template v-if="showNested">Hide nested stack</template>
            <template v-else>View nested stack</template>
          </a>
          <CapitalStackDiagram
            v-if="showNested"
            :property-id="propertyId"
            :data-field="dataField"
            :deal-builder-asset-data-field="dealBuilderAssetDataField"
            :future="future"
            :nested="true"
            class="mt-2"
          />

          <ol
            v-else
            role="ownership-interests"
            :class="workspaceLayout === 'topAndBottom' ? 'grid-cols-2' : ''"
            class="mt-4 grid grid-cols-1 gap-6"
          >
            <div v-if="existingOpenInvestment && future" class="text-center">
              <svg
                class="mx-auto h-8 w-8 text-gray-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"
                />
              </svg>
              <h3 class="mt-2 text-sm font-medium text-gray-900">
                Active/pending
              </h3>
              <p class="mt-1 text-sm text-gray-500">Edit the deal below.</p>
            </div>
            <template
              v-else-if="
                capitalProviders.length > 0 ||
                remainingOwnershipInterests.length > 0
              "
            >
              <InvolvedCompany
                v-for="ownershipInterestDataField in capitalProviders"
                :key="ownershipInterestDataField.fieldContentId"
                :deal-builder-asset-data-field="dealBuilderAssetDataField"
                :local-asset-data-field="dataField"
                :decorating-content-data-field="ownershipInterestDataField"
                :layer-type="layerType"
                :layer-color="layerColor"
                :providers-count="capitalProviders.length"
                :allows-senior-loan="allowsSeniorLoan"
                :future="future"
                :existing-investment-selected="existingInvestmentSelected"
                :fetch-milliseconds="fetchMilliseconds"
                context="capital-stack"
                @refetch-deal-builder="refetch(true)"
                @refetch="fetchInvolvedCompanies"
                @override-refetch="refetch(true)"
              />

              <InvolvedCompany
                v-for="ownershipInterestDataField in remainingOwnershipInterests"
                :key="ownershipInterestDataField.fieldContentId"
                :deal-builder-asset-data-field="dealBuilderAssetDataField"
                :local-asset-data-field="dataField"
                :decorating-content-data-field="ownershipInterestDataField"
                :layer-type="layerType"
                :layer-color="layerColor"
                :providers-count="capitalProviders.length"
                :allows-senior-loan="allowsSeniorLoan"
                :future="future"
                :fetch-milliseconds="fetchMilliseconds"
                context="future-capital-stack-placeholder"
              />
            </template>
            <div
              v-else-if="isEquity && before && existingInvestmentSelected"
              class="text-center"
            >
              <svg
                class="mx-auto h-8 w-8 text-gray-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12.066 11.2a1 1 0 000 1.6l5.334 4A1 1 0 0019 16V8a1 1 0 00-1.6-.8l-5.333 4zM4.066 11.2a1 1 0 000 1.6l5.334 4A1 1 0 0011 16V8a1 1 0 00-1.6-.8l-5.334 4z"
                />
              </svg>
              <h3 class="mt-2 text-sm font-medium text-gray-900">No Owners</h3>
              <p class="mt-1 text-sm text-gray-500">
                Rewind the timeline and add older deals.
              </p>
            </div>

            <CreateCapitalEvent
              v-if="canEdit && !existingInvestmentSelected"
              :layer-type="layerType"
              :property-id="propertyId"
              :deal-builder-asset-data-field="dealBuilderAssetDataField"
              :data-field="dataField"
              :loan-collateral-type="loanCollateralType"
              :loan-seniority="loanSeniority"
              :future="future"
              :depth="depth"
              :nested="nested"
              :preactivate="preactivateForm"
              :insert-direction="insertDirection"
              :portfolio-to-add="portfolioToAdd"
              @cancel="cancel"
              @refetch-layer="refetch(true)"
              @refetch-ownership-layer="emit('refetch-ownership-layer')"
            />
          </ol>
        </div>

        <div
          v-if="canAddSuperior && !preactivateForm"
          class="absolute -bottom-4 flex w-full justify-center"
          style="z-index: 2"
        >
          <span
            class="relative z-0 inline-flex shadow-sm rounded-md -space-x-px"
          >
            <button
              v-if="
                creatingLoan.direction === 'superior' ||
                creatingSeniorLoan ||
                preactivateForm
              "
              @click="cancelLoan"
              type="button"
              class="relative inline-flex items-center px-4 py-1.5 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-400 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
            >
              <span class="sr-only">Cancel</span>
              <XMarkIcon class="-ml-1.5 mr-1 h-5 w-5 text-gray-400" />
              <span>Cancel</span>
            </button>

            <PortfolioAddMenu
              v-else-if="portfoliosAllowed"
              insert-direction="superior"
              @add-to-portfolio="inputPortfolioLoan"
            />

            <DataVisibilityButton
              v-else
              visibility="safezone"
              class="relative inline-flex"
            >
              <template v-slot:button>
                <button
                  @click="inputLoan('superior')"
                  type="button"
                  :data-test="`${decoratingAndFieldKey(
                    dealBuilderAssetDataField,
                  )}-depth-${depth}-superior-loan-add-button`"
                  class="relative inline-flex items-center px-4 py-1.5 rounded-md border border-yellow-300 bg-white text-sm font-medium text-gray-400 hover:bg-yellow-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-yellow-500 focus:border-yellow-500"
                >
                  <span class="sr-only">Add loan</span>
                  <PlusIcon class="-ml-1.5 mr-1 h-5 w-5 text-yellow-400" />
                  <span>Loan</span>
                </button>
              </template>
            </DataVisibilityButton>
          </span>
        </div>
      </ul>
    </li>
    <CapitalStackLayer
      v-if="creatingLoan.direction === 'superior'"
      layer-type="Loan"
      :property-id="propertyId"
      :deal-builder-asset-data-field="dealBuilderAssetDataField"
      :data-field="dataField"
      :loan-collateral-type="collateralType"
      :future="future"
      :allows-senior-loan="false"
      :preactivate-form="true"
      :insert-direction="creatingLoan"
      :depth="depth + 1"
      @container-cancel="cancelLoan"
      @refetch-subordinate-layer="refetch(true)"
      @refetch-ownership-layer="emit('refetch-ownership-layer')"
    />
    <CapitalStackLayer
      v-if="canDisplaySuperiorLayer && layerType === 'OwnershipInterest'"
      layer-type="Loan"
      :property-id="propertyId"
      :deal-builder-asset-data-field="dealBuilderAssetDataField"
      :data-field="superiorLayer"
      :loan-collateral-type="null"
      :future="future"
      :allows-senior-loan="false"
      :passed-foreclosing-senior="foreclosingSenior"
      :depth="depth + 1"
      @refetch-ownership-layer="emit('refetch-ownership-layer')"
    />
  </div>
</template>

<script setup>
import {
  ArrowsRightLeftIcon,
  PlusIcon,
  XMarkIcon,
} from "@heroicons/vue/20/solid";
import CreateCapitalEvent from "@/components/deal-builder/CreateCapitalEvent.vue";
import CapitalStackDiagram from "@/components/deal-builder/CapitalStackDiagram.vue";
import DealActionDropdown from "@/components/deal-builder/DealActionDropdown.vue";
import PortfolioAddMenu from "@/components/deal-builder/PortfolioAddMenu.vue";
import InvolvedCompany from "@/components/crowdsourcing/InvolvedCompany.vue";
import DataVisibilityButton from "@/components/crowdsourcing/DataVisibilityButton.vue";
import { useDealBuilderStore } from "@/stores/dealBuilder";
import { useTimeTravelStore } from "@/stores/timeTravel";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { useCrowdsourcedChangeGroupStore } from "@/stores/crowdsourcedChangeGroup";
import { useProveForValueStore } from "@/stores/proveForValue";
import { ref, computed, watch, onMounted } from "vue";
import { storeToRefs } from "pinia";
import api from "@/router/api";
import decoratingAndFieldKey from "@/components/crowdsourcing/decoratingAndFieldKey";
import moment from "moment";
import _ from "lodash";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";

const props = defineProps([
  "layerType",
  "seniorLoan",
  "propertyId",
  "dealBuilderAssetDataField",
  "dataField",
  "loanCollateralType",
  "future",
  "nested",
  "allowsSeniorLoan",
  "creatingSeniorLoan",
  "passedForeclosingSenior",
  "preactivateForm",
  "insertDirection",
  "depth",
]);
const emit = defineEmits([
  "refetch-ownership-layer",
  "refetch-senior-loan",
  "refetch-subordinate-layer",
  "input-senior-loan",
  "container-cancel",
]);

const timeTravelStore = useTimeTravelStore();
const { asOfMilliseconds, dayBeforeAsOf } = storeToRefs(timeTravelStore);
const dealBuilderStore = useDealBuilderStore();
const { dealBuilder, crossInteraction, refetchDealBuilderEditor } =
  storeToRefs(dealBuilderStore);
const layoutStore = useWorkspaceLayoutStore();
const { workspaceLayout } = storeToRefs(layoutStore);
const changeGroupStore = useCrowdsourcedChangeGroupStore();
const { originatingData } = storeToRefs(changeGroupStore);
const proveForValueStore = useProveForValueStore();
const { proofRefetchTrigger } = storeToRefs(proveForValueStore);

const showNested = ref(false);
const creatingLoan = ref(false); // { direction, investmentGroup }
const capitalProviders = ref([]);
const superiorLayer = ref(null);
const superiorLayerLoaded = ref(false);
const subordinateLayer = ref(null);
const subordinateLayerLoaded = ref(false);
const subordinateLayerExclusionCheckRequired = ref(null);

const before = computed(() => {
  return !props.future;
});
const assetsLength = computed(() => {
  return _.size(dealBuilder.value.assets);
});
const investmentGroups = computed(() => {
  return _.get(dealBuilder.value, "investmentGroups", []);
});
const portfoliosAllowed = computed(() => {
  return (
    dealBuilder.value &&
    assetsLength.value > 1 &&
    investmentGroups.value.length > 0
  );
});
const portfolioToAdd = computed(() => {
  if (_.isObject(props.creatingSeniorLoan)) {
    return props.creatingSeniorLoan;
  } else {
    return null;
  }
});
const matchingAsset = computed(() => {
  return dealBuilderStore.matchingAssetObject(
    decoratingAndFieldKey(props.dealBuilderAssetDataField),
  );
});
const matchingAssetDatedTimelineFetchState = computed(() => {
  return _.get(matchingAsset.value, "datedTimelineFetched");
});
const existingInvestmentSelected = computed(() => {
  return (
    _.get(crossInteraction.value, "source") === "TimelineInvestment" &&
    _.get(crossInteraction.value, "assetKey") ===
      decoratingAndFieldKey(props.dealBuilderAssetDataField)
  );
});
const existingOpenInvestment = computed(() => {
  if (existingInvestmentSelected.value) {
    const asset = _.get(
      dealBuilder.value,
      `assets[${decoratingAndFieldKey(props.dealBuilderAssetDataField)}]`,
    );
    const assetInvestments = _.get(asset, `investments`, []);
    const investment = _.get(
      _.find(assetInvestments, {
        fieldContentId: _.get(crossInteraction.value, "combinedKey"),
      }),
      "fieldContent",
    );

    if (
      _.get(investment, "investmentType") === props.layerType &&
      _.get(investment, "state") !== "closed"
    ) {
      if (_.get(investment, "loanCollateral")) {
        return decoratingAndFieldKey(investment.loanCollateral) ===
          decoratingAndFieldKey(props.dataField)
          ? investment
          : null;
      } else {
        return investment || null;
      }
    } else {
      return null;
    }
  } else {
    return null;
  }
});
const existingOpenInvestmentId = computed(() => {
  return _.get(existingOpenInvestment.value, "id");
});
const fetchMilliseconds = computed(() => {
  return existingInvestmentSelected.value && before.value
    ? dayBeforeAsOf.value
    : asOfMilliseconds.value;
});
const foreclosureSubordinateKey = computed(() => {
  return _.get(dealBuilder.value, "foreclosureSubordinateKey");
});
const ownershipInterestIds = computed(() => {
  return capitalProviders.value.map((interest) => {
    return interest.fieldContentId;
  });
});
const remainingOwnershipInterests = computed(() => {
  if (
    props.future &&
    props.layerType === "OwnershipInterest" &&
    matchingAsset.value
  ) {
    if (_.get(futureDeal.value, "dealAction") === "foreclosureSellOutright") {
      return [];
    } else if (futureDeal.value) {
      const allInterests = matchingAsset.value.ownershipInterests;
      const remaining = _.reject(allInterests, function (interestDataField) {
        const isRetiring = _.includes(
          futureDeal.value.ownershipInterestIds,
          interestDataField.fieldContentId,
        );
        const isEntireInterestAction = _.includes(
          ["foreclosureSellOutright", "sellOutright", "sellEntireInterest"],
          futureDeal.value.dealAction,
        );
        const assetEntireInterestIds = dealBuilderStore.matchingAssetObject(
          assetKey.value,
        ).entireInterests;
        const isEntireInterestId = _.includes(
          assetEntireInterestIds,
          interestDataField.fieldContentId,
        );

        return isRetiring && (isEntireInterestAction || isEntireInterestId);
      });

      return _.differenceBy(
        remaining,
        capitalProviders.value,
        "fieldContentId",
      );
    } else if (relatedFutureRefinancing.value) {
      return _.differenceBy(
        matchingAsset.value.ownershipInterests,
        capitalProviders.value,
        "fieldContentId",
      );
    } else {
      return [];
    }
  } else {
    return [];
  }
});
const relatedFutureRefinancing = computed(() => {
  if (props.future && matchingInvestmentGroup.value) {
    return findMatchingGroupInvestment(matchingInvestmentGroup.value, true);
  } else if (props.future && matchingAsset.value) {
    return findMatchingAssetInvestment(matchingAsset.value, true);
  } else {
    return null;
  }
});
const matchingInvestmentGroup = computed(() => {
  return _.find(
    _.get(dealBuilder.value, "investmentGroups", []),
    function (group) {
      return _.find(group.investments, function (investment) {
        const matchingKey =
          !!investment.capitalStackTopLevelAsset &&
          assetKey.value ===
            decoratingAndFieldKey(investment.capitalStackTopLevelAsset);

        return matchingKey;
      });
    },
  );
});
const matchingStubbedInvestmentGroup = computed(() => {
  return (
    props.future &&
    _.find(_.get(dealBuilder.value, "investmentGroups", []), function (group) {
      return _.find(group.investments, function (investment) {
        const matchingKey =
          !!investment.capitalStackTopLevelAsset &&
          decoratingAndFieldKey(props.dealBuilderAssetDataField) ===
            decoratingAndFieldKey(investment.capitalStackTopLevelAsset);

        return matchingKey;
      });
    })
  );
});
const stubbedJuniorLoan = computed(() => {
  if (matchingStubbedInvestmentGroup.value) {
    return _.find(
      matchingStubbedInvestmentGroup.value.investments,
      function (investmentObj) {
        const isJunior = investmentObj.loanSeniority === "junior";
        let assetMatch = false;

        if (investmentObj.loanUpstreamDataField) {
          assetMatch =
            decoratingAndFieldKey(investmentObj.loanUpstreamDataField) ===
            decoratingAndFieldKey(props.dataField);
        }
        return isJunior && assetMatch;
      },
    );
  } else {
    return null;
  }
});
const stubbedDownstreamLoan = computed(() => {
  if (matchingStubbedInvestmentGroup.value) {
    return _.find(
      matchingStubbedInvestmentGroup.value.investments,
      function (investmentObj) {
        const isJunior = investmentObj.loanSeniority === "junior";
        let assetMatch = false;
        let stackMatch = false;

        if (investmentObj.loanUpstreamDataField) {
          assetMatch =
            decoratingAndFieldKey(investmentObj.loanUpstreamDataField) ===
            decoratingAndFieldKey(props.dataField);
          if (!assetMatch) {
            stackMatch =
              decoratingAndFieldKey(investmentObj.loanUpstreamDataField) !==
              decoratingAndFieldKey(investmentObj.capitalStackTopLevelAsset);
          }
        }
        return isJunior && stackMatch;
      },
    );
  } else {
    return null;
  }
});
const sameStackAsStubbedJuniorLoan = computed(() => {
  if (matchingStubbedInvestmentGroup.value) {
    return _.find(
      matchingStubbedInvestmentGroup.value.investments,
      function (investmentObj) {
        const isJunior = investmentObj.loanSeniority === "junior";
        let assetMatch = false;

        if (investmentObj.capitalStackTopLevelAsset?.localId) {
          assetMatch =
            investmentObj.capitalStackTopLevelAsset?.localId ===
            props.dealBuilderAssetDataField?.localId;
        }
        return isJunior && assetMatch && props.seniorLoan;
      },
    );
  } else {
    return null;
  }
});
const futureDeal = computed(() => {
  if (matchingInvestmentGroup.value) {
    return findMatchingGroupInvestment(matchingInvestmentGroup.value);
  } else {
    return null;
  }
});
const editingFuture = computed(() => {
  return props.future && futureDeal.value;
});
const canEdit = computed(() => {
  if (editingFuture.value) {
    switch (futureDeal.value.dealAction) {
      case "foreclosureSellOutright":
      case "sellOutright":
      case "sellEntireInterest":
      case "sellPartialInterest":
      case "sellMultipleInterests":
        return props.layerType === "OwnershipInterest";
      case "refinance":
      case "originateLoan":
        return props.layerType === "Loan";
      default:
        return false;
    }
  } else if (stubbedJuniorLoan.value) {
    return (
      props.layerType === "Loan" &&
      props.insertDirection?.direction ===
        stubbedJuniorLoan.value.insertDirection
    );
  } else if (relatedFutureRefinancing.value) {
    return props.layerType === "Loan";
  } else if (loanBeingAssumed.value) {
    return false;
  } else if (foreclosing.value && props.layerType === "Loan") {
    return false;
  } else {
    return capitalProviders.value.length === 0;
  }
});
const notSeniorLoan = computed(() => {
  return !isEquity.value && !props.seniorLoan;
});
const canAddSubordinate = computed(() => {
  const notEquity = !isEquity.value;

  return (
    before.value &&
    !existingInvestmentSelected.value &&
    notSeniorLoan.value &&
    notEquity &&
    !subordinateLayer.value
  );
});
const canAddSuperior = computed(() => {
  const equityWithoutSenior = isEquity.value && props.allowsSeniorLoan;
  const equityWithoutJunior = isEquity.value && !superiorLayer.value;

  return (
    before.value &&
    !existingInvestmentSelected.value &&
    (notSeniorLoan.value || equityWithoutSenior || equityWithoutJunior)
  );
});
const viewingData = computed(() => {
  return !props.preactivateForm && !stubbedJuniorLoan.value;
});
const displayAssetActions = computed(() => {
  return (
    !existingInvestmentSelected.value &&
    before.value &&
    viewingData.value &&
    (capitalProviders.value.length > 1 || !isEquity.value)
  );
});
const isEquity = computed(() => {
  return props.layerType === "OwnershipInterest";
});
const label = computed(() => {
  if (isEquity.value) {
    return "Equity";
  } else if (props.seniorLoan) {
    return `Senior Loan ${contentId.value}`;
  } else if (props.creatingSeniorLoan) {
    return "New Senior Loan";
  } else if (props.preactivateForm) {
    return "New Junior Loan";
  } else {
    return `Junior Loan ${contentId.value}`;
  }
});
const layerColor = computed(() => {
  if (isEquity.value) {
    return "bg-yellow-400";
  } else if (props.seniorLoan || props.creatingSeniorLoan) {
    return "bg-blue-600";
  } else {
    switch (props.depth) {
      case 7:
        return "bg-teal-700";
      case 6:
        return "bg-teal-600";
      case 5:
        return "bg-teal-500";
      case 4:
        return "bg-teal-400";
      case 3:
        return "bg-teal-300";
      default:
        return "bg-teal-200";
    }
  }
});
const collateralType = computed(() => {
  if (isEquity.value) {
    return "equity_entity";
  } else {
    return null;
  }
});
const loanSeniority = computed(() => {
  if (props.creatingSeniorLoan) {
    return "senior";
  } else {
    return "junior";
  }
});
const existingDealBuilderLoan = computed(() => {
  return dealBuilder.value && before.value && props.layerType === "Loan";
});
const foreclosingSenior = computed(() => {
  return (
    props.passedForeclosingSenior ||
    _.get(futureDeal.value, "seniorLoanForeclosure", false)
  );
});
const foreclosing = computed(() => {
  return (
    _.get(futureDeal.value, "dealAction") === "foreclosureSellOutright" ||
    (props.future && foreclosureLoanIds.value.length > 0)
  );
});
const foreclosureLoanIds = computed(() => {
  return _.get(matchingAsset.value, "foreclosureLoans", []);
});
const canDisplaySubordinateLayer = computed(() => {
  if (subordinateLayer.value) {
    const beingForeclosed = _.includes(
      foreclosureLoanIds.value,
      subordinateLayer.value.decoratingContentId,
    );

    return !beingForeclosed || before.value || stubbedJuniorLoan.value;
  } else {
    return false;
  }
});
const canDisplaySuperiorLayer = computed(() => {
  if (superiorLayer.value) {
    const beingForeclosed = _.includes(
      foreclosureLoanIds.value,
      superiorLayer.value.decoratingContentId,
    );

    return !beingForeclosed || before.value || stubbedJuniorLoan.value;
  } else {
    return false;
  }
});
const assumptionLoanIds = computed(() => {
  return _.get(matchingAsset.value, "assumptionLoans", []);
});
const loanBeingAssumed = computed(() => {
  const beingAssumed = _.includes(
    assumptionLoanIds.value,
    props.dataField.decoratingContentId,
  );

  return props.future && props.layerType === "Loan" && beingAssumed;
});
const visualSupportSeniorLoan = computed(() => {
  return (
    props.future &&
    props.layerType === "Loan" &&
    sameStackAsStubbedJuniorLoan.value
  );
});
const assetKey = computed(() => {
  return decoratingAndFieldKey(props.dataField);
});
const combinedKey = computed(() => {
  if (editingFuture.value || relatedFutureRefinancing.value) {
    const dealReference = futureDeal.value || relatedFutureRefinancing.value;

    if (
      dealReference.capitalStackTopLevelAsset &&
      dealReference.investmentFieldContent
    ) {
      const aKey = decoratingAndFieldKey(
        dealReference.capitalStackTopLevelAsset,
      );
      const investmentKey = decoratingAndFieldKey(
        dealReference.investmentFieldContent,
      );

      return `${aKey}_${investmentKey}`;
    } else {
      return assetKey.value;
    }
  } else {
    return assetKey.value;
  }
});
const subjectIsFieldContent = computed(() => {
  return _.includes(
    ["Property", "PropertyRight"],
    props.dataField.decoratingContentType,
  );
});
const contentId = computed(() => {
  if (subjectIsFieldContent.value) {
    return props.dataField.fieldContentId;
  } else {
    return props.dataField.decoratingContentId;
  }
});
const contentType = computed(() => {
  if (subjectIsFieldContent.value) {
    return props.dataField.fieldContentType;
  } else {
    return props.dataField.decoratingContentType;
  }
});

watch(foreclosingSenior, () => {
  if (foreclosingSenior.value) {
    if (superiorLayer.value) {
      console.log(
        "foreclosed senior wipes out",
        superiorLayer.value.decoratingContentId,
      );
      dealBuilderStore.addDealBuilderAssetForeclosureLoan({
        assetKey: decoratingAndFieldKey(props.dealBuilderAssetDataField),
        loanId: superiorLayer.value.decoratingContentId,
      });
    }

    if (subordinateLayer.value) {
      console.log(
        "foreclosed senior wipes out",
        subordinateLayer.value.decoratingContentId,
      );
      dealBuilderStore.addDealBuilderAssetForeclosureLoan({
        assetKey: decoratingAndFieldKey(props.dealBuilderAssetDataField),
        loanId: subordinateLayer.value.decoratingContentId,
      });
    }
  }
});
watch(
  () => props.allowsSeniorLoan,
  () => {
    if (stubbedJuniorLoan.value) {
      activateStubbedLoanCreation();
    }
  },
);
watch(foreclosureSubordinateKey, () => {
  foreclosureSubordinateTraversal();
});
watch(superiorLayerLoaded, () => {
  if (superiorLayer.value && foreclosingSenior.value) {
    console.log(
      "foreclosed senior wipes out superior",
      superiorLayer.value.decoratingContentId,
    );
    dealBuilderStore.addDealBuilderAssetForeclosureLoan({
      assetKey: decoratingAndFieldKey(props.dealBuilderAssetDataField),
      loanId: superiorLayer.value.decoratingContentId,
    });
  }
});
watch(subordinateLayerLoaded, () => {
  if (subordinateLayer.value && foreclosingSenior.value) {
    console.log(
      "foreclosed senior wipes out subordinate",
      subordinateLayer.value.decoratingContentId,
    );
    dealBuilderStore.addDealBuilderAssetForeclosureLoan({
      assetKey: decoratingAndFieldKey(props.dealBuilderAssetDataField),
      loanId: subordinateLayer.value.decoratingContentId,
    });
  }

  if (
    subordinateLayer.value &&
    foreclosureSubordinateKey.value &&
    existingDealBuilderLoan.value
  ) {
    foreclosureSubordinateTraversal();
  }
});
watch(assumptionLoanIds, () => {
  if (
    props.future &&
    assumptionLoanIds.value.length > 1 &&
    (loanBeingAssumed.value || props.layerType === "OwnershipInterest")
  ) {
    fetchNeighboringLayer();
  }
});
watch(existingInvestmentSelected, () => {
  if (existingInvestmentSelected.value) refetch();
});
watch(existingOpenInvestmentId, () => {
  if (!!existingOpenInvestment.value && props.future) {
    console.log("existing open investment refetch");
    refetch();
  }
});
watch(matchingAssetDatedTimelineFetchState, () => {
  if (subordinateLayerExclusionCheckRequired.value) {
    subordinateLayerExclusionCheck();
  }
});
watch(subordinateLayerExclusionCheckRequired, () => {
  if (
    subordinateLayerExclusionCheckRequired.value &&
    matchingAssetDatedTimelineFetchState.value
  ) {
    subordinateLayerExclusionCheck();
  }
});
watch(proofRefetchTrigger, (val) => {
  if (val === "capitalStack") {
    console.log("proof refetch trigger");
    fetchInvolvedCompanies({ override: "proof" });
  }
});

onMounted(() => {
  refetch();

  if (existingDealBuilderLoan.value) {
    dealBuilderStore.addDealBuilderAssetLoan({
      assetKey: decoratingAndFieldKey(props.dealBuilderAssetDataField),
      loanId: props.dataField.decoratingContentId,
    });
  }
});

function foreclosureSubordinateTraversal() {
  if (
    assetKey.value === foreclosureSubordinateKey.value &&
    subordinateLayerLoaded.value
  ) {
    const loanId = props.dataField.decoratingContentId;

    console.log("foreclosure subordinate traversal", loanId);
    dealBuilderStore.addDealBuilderAssetForeclosureLoan({
      assetKey: decoratingAndFieldKey(props.dealBuilderAssetDataField),
      loanId,
    });

    if (subordinateLayer.value) {
      dealBuilder.value.foreclosureSubordinateKey = decoratingAndFieldKey(
        subordinateLayer.value,
      );
    } else {
      dealBuilder.value.foreclosureSubordinateKey = {};
    }
  }
}
function findMatchingGroupInvestment(group, financing = false) {
  const dealActions =
    props.layerType === "OwnershipInterest"
      ? [
          "foreclosureSellOutright",
          "sellOutright",
          "sellEntireInterest",
          "sellPartialInterest",
          "sellMultipleInterests",
        ]
      : ["refinance", "originateLoan"];

  return _.find(group.investments, function (investment) {
    let matchingKey;
    const matchingAction =
      _.intersection(financing ? ["refinance", "originateLoan"] : dealActions, [
        investment.dealAction,
      ]).length > 0;

    if (investment.capitalStackTopLevelAsset) {
      matchingKey =
        assetKey.value ===
        decoratingAndFieldKey(investment.capitalStackTopLevelAsset);

      return matchingKey && matchingAction;
    } else {
      return false;
    }
  });
}
function findMatchingAssetInvestment(assetObject, financing = false) {
  const dealActions =
    props.layerType === "OwnershipInterest"
      ? [
          "foreclosureSellOutright",
          "sellOutright",
          "sellEntireInterest",
          "sellPartialInterest",
          "sellMultipleInterests",
        ]
      : ["refinance", "originateLoan"];

  return _.find(assetObject.investments, function (investment) {
    if (investment.fieldContent) {
      const matchingKey =
        assetKey.value === decoratingAndFieldKey(investment.fieldContent.asset);
      const matchingAction =
        _.intersection(financing ? ["refinance"] : dealActions, [
          investment.fieldContent.dealAction,
        ]).length > 0;

      return matchingKey && matchingAction;
    } else {
      return false;
    }
  });
}
function reset() {
  capitalProviders.value = [];
  superiorLayer.value = null;
  subordinateLayer.value = null;
}
function refetch(postCreationRefetch = false) {
  if (dealBuilder.value && postCreationRefetch) {
    console.log("refetch editor container");
    refetchDealBuilderEditor.value = decoratingAndFieldKey(
      props.dealBuilderAssetDataField,
    );
    timeTravelStore.triggerRefetch();
  } else if (existingOpenInvestment.value) {
    console.log(
      "existing investment refetch",
      existingOpenInvestment.value.state,
    );

    return;
  } else if (existingInvestmentSelected.value) {
    console.log(
      before.value ? "before" : "after",
      assetKey.value,
      "existing investment selected",
    );
    reset();
    fetchInvolvedCompanies({ override: true });

    if (!props.seniorLoan) {
      fetchNeighboringLayer();
    }
  } else if (before.value || foreclosing.value) {
    reset();

    if (viewingData.value || postCreationRefetch) {
      if (postCreationRefetch && !dealBuilder.value) {
        refetchDealBuilderEditor.value = true;
      }

      if (before.value || props.layerType === "Loan") {
        fetchInvolvedCompanies();

        if (props.seniorLoan || postCreationRefetch) {
          emit("refetch-senior-loan");
        }
      }

      if (before.value || (!props.seniorLoan && foreclosing.value)) {
        fetchNeighboringLayer();

        if (postCreationRefetch) {
          emit("refetch-subordinate-layer");
        }
      }
    }
  } else if (loanBeingAssumed.value || visualSupportSeniorLoan.value) {
    reset();
    fetchInvolvedCompanies();
  } else if (stubbedJuniorLoan.value || stubbedDownstreamLoan.value) {
    reset();

    if (!props.preactivateForm) {
      fetchInvolvedCompanies();
    }

    if (!props.seniorLoan && !props.insertDirection?.direction) {
      fetchNeighboringLayer();
    }

    if (!props.allowsSeniorLoan && stubbedJuniorLoan.value) {
      activateStubbedLoanCreation();
    }
  }
}
function activateStubbedLoanCreation() {
  if (
    props.future &&
    !props.preactivateForm &&
    !props.allowsSeniorLoan &&
    decoratingAndFieldKey(stubbedJuniorLoan.value.loanUpstreamDataField) ===
      decoratingAndFieldKey(props.dataField)
  ) {
    console.log("activate stubbed loan");
    inputLoan(stubbedJuniorLoan.value.insertDirection);
  }
}
async function fetchInvolvedCompanies(maybeOverride) {
  const fetchRequestKey = `ownership_interests_${contentType.value}_${contentId.value}`;
  if (contentType.value && contentId.value) {
    if (
      dealBuilderStore.alreadyFetched(fetchRequestKey) &&
      !maybeOverride?.override
    ) {
      capitalProviders.value =
        dealBuilderStore.alreadyFetchedFieldsFor(fetchRequestKey);
    } else {
      const providersResponse = await api.get(
        `ownership_interests/?content_type=${contentType.value}&content_id=${contentId.value}&as_of=${fetchMilliseconds.value}`,
      );
      capitalProviders.value = providersResponse.data;
      dealBuilderStore.interceptablePatch(
        providersResponse.data,
        fetchRequestKey,
      );
    }

    if (dealBuilder.value && props.layerType === "OwnershipInterest") {
      dealBuilderStore.setDealBuilderAssetOwnershipInterests({
        assetKey: assetKey.value,
        interests: capitalProviders.value,
      });
    }
  }
}
async function fetchNeighboringLayer() {
  const loanState = props.future ? "" : "&loan_state=active";
  const fetchRequestKey = `loans_${contentType.value}_${contentId.value}_${props.layerType}_junior${loanState}`;
  let loans = [];

  if (dealBuilderStore.alreadyFetched(fetchRequestKey)) {
    loans = dealBuilderStore.alreadyFetchedFieldsFor(fetchRequestKey);
  } else {
    const loansResponse = await api.get(
      `loans/?content_type=${contentType.value}&content_id=${contentId.value}&layer_type=${props.layerType}&seniority_type=junior&as_of=${fetchMilliseconds.value}${loanState}`,
    );
    loans = loansResponse.data;
    dealBuilderStore.interceptablePatch(loans, fetchRequestKey);
  }

  const loan = loans[0];
  const beingAssumed =
    loan && _.includes(assumptionLoanIds.value, loan.decoratingContentId);
  // console.log("neighboring fetch being assumed?", beingAssumed);
  if (
    props.future &&
    existingInvestmentSelected.value &&
    loan &&
    matchingAsset.value
  ) {
    subordinateLayerExclusionCheckRequired.value = loan;
  } else if (
    props.future &&
    assumptionLoanIds.value.length > 1 &&
    !beingAssumed
  ) {
    return;
  } else {
    setNeighboringLayer(loan);
  }
}
function setNeighboringLayer(loan) {
  if (isEquity.value) {
    superiorLayer.value = loan;
    superiorLayerLoaded.value = true;
  } else {
    subordinateLayer.value = loan;
    subordinateLayerLoaded.value = true;
  }
}
function subordinateLayerExclusionCheck() {
  const loanDataField = subordinateLayerExclusionCheckRequired.value;
  const matchingInvestment = _.find(
    matchingAsset.value.investments,
    function (investment) {
      if (investment.fieldContent) {
        return (
          decoratingAndFieldKey(loanDataField) ===
          decoratingAndFieldKey(investment.fieldContent.loanCollateral)
        );
      } else {
        return false;
      }
    },
  );

  console.log("loan exclusion check", loanDataField, matchingInvestment);

  if (matchingInvestment) {
    const isSelected =
      matchingInvestment.fieldContentId ===
      _.get(crossInteraction.value, "combinedKey");
    const isActive = !_.includes(
      ["closed", "withdrawn"],
      matchingInvestment.fieldContent.state,
    );
    const date = matchingInvestment.fieldContent.date;
    const isAfterNow = date
      ? moment(date).valueOf() > fetchMilliseconds.value
      : true;
    const shouldExclude = isActive && isAfterNow;

    console.log("criteria", isSelected, isActive, isAfterNow);

    if (isSelected || !shouldExclude) {
      console.log("check includable!");
      setNeighboringLayer(loanDataField);
    }
  } else {
    setNeighboringLayer(loanDataField);
  }

  subordinateLayerExclusionCheckRequired.value = null;
}
function inputPortfolioLoan({ investmentGroup, insertDirection }) {
  if (props.allowsSeniorLoan) {
    emit("input-senior-loan", { investmentGroup });
  } else if (investmentGroup === "new") {
    creatingLoan.value = { direction: insertDirection, investmentGroup };
  } else {
    creatingLoan.value = { direction: insertDirection, investmentGroup };
  }
}
function inputLoan(insertDirection) {
  if (props.allowsSeniorLoan) {
    emit("input-senior-loan");
  } else {
    creatingLoan.value = { direction: insertDirection, investmentGroup: null };
  }
}
function cancel() {
  creatingLoan.value = false;

  if (props.preactivateForm) {
    emit("container-cancel");
  }
}
function cancelLoan() {
  if (editingFuture.value) {
    removeInvestment("originateLoan");
  } else if (relatedFutureRefinancing.value) {
    const dealAction = _.get(
      relatedFutureRefinancing.value,
      "fieldContent.dealAction",
    );

    removeInvestment(dealAction);
  }

  creatingLoan.value = false;
  emit("container-cancel");
}
function removeInvestment(dealAction) {
  const loanClearingActions = [
    "foreclosureSellOutright",
    "sellOutright",
    "sellEntireInterest",
    "sellPartialInterest",
    "sellMultipleInterests",
  ];

  if (_.intersection(loanClearingActions, [dealAction]).length > 0) {
    dealBuilderStore.clearDealBuilderAssetManipulatedLoans({
      assetKey: assetKey.value,
    });
    dealBuilderStore.clearDealBuilderAssetIndividualOwnershipInterests({
      assetKey: assetKey.value,
    });
  }
  dealBuilderStore.removeDealBuilderInvestment({
    investmentKey: combinedKey.value,
    dealAction,
  });
}
</script>
