<template>
  <div class="h-full p-2 flex flex-col w-full space-y-4">
    <AvailabilityGroupsList
      v-if="spacesLength > 1 || availabilityGroups.length > 0"
    />
    <div class="flex items-center justify-between">
      <h2 class="text-gray-500 text-sm font-semibold uppercase tracking-wide">
        {{ spacesHeaderLabel }}
      </h2>
      <button
        v-if="canAddSpace"
        @click="addSpace"
        type="button"
        data-test="add-selected-space-to-space-usage-builder-button"
        class="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        <svg
          class="-ml-0.5 mr-2 h-4 w-4"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM14 11a1 1 0 011 1v1h1a1 1 0 110 2h-1v1a1 1 0 11-2 0v-1h-1a1 1 0 110-2h1v-1a1 1 0 011-1z"
          />
        </svg>
        Add selected
        {{
          selectedDataField.fieldContentType === "LandCovering"
            ? "building"
            : "space"
        }}
      </button>
    </div>
    <SpaceEditorContainer
      v-for="spaceObject in spaces"
      :key="spaceObject.dataField.localId"
      :space-object="spaceObject"
    />
    <div v-if="_.size(spaces) === 0" class="w-full">
      <div
        class="h-full flex items-center justify-center max-w-2xl mx-auto py-16 px-4"
        data-test="space-usage-builder-empty-state"
      >
        <div class="text-center">
          <h1
            class="text-base leading-6 font-semibold text-indigo-600 tracking-wide uppercase"
          >
            Space Hub
          </h1>
          <p class="mt-1 text-3xl leading-10 font-extrabold text-gray-900">
            Create and consume leasing and availability data
          </p>
          <p class="max-w-xl mt-5 mx-auto text-lg leading-7 text-gray-500">
            Add a space to begin.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import AvailabilityGroupsList from "@/components/space-usage-builder/AvailabilityGroupsList.vue";
import SpaceEditorContainer from "@/components/space-usage-builder/SpaceEditorContainer.vue";
import decoratingAndFieldKey from "@/components/crowdsourcing/decoratingAndFieldKey";
import pluralize from "pluralize";
import { useSpaceUsageBuilderStore } from "@/stores/spaceUsageBuilder";
import { usePropertyDiagramStore } from "@/stores/propertyDiagram";
import { useSecondaryPanelStore } from "@/stores/secondaryPanel";
import { storeToRefs } from "pinia";
import { ref, computed, watch, onMounted, nextTick } from "vue";
import api from "@/router/api";
import _ from "lodash";
import { useRoute, useRouter } from "vue-router";

const spaceUsageBuilderStore = useSpaceUsageBuilderStore();
const {
  spaces,
  locatedSpaces,
  unlocatedSpaces,
  availabilityGroups,
  spacesLength,
  spaceFieldIds,
} = storeToRefs(spaceUsageBuilderStore);
const propertyDiagramStore = usePropertyDiagramStore();
const { propertyDiagramSelected, propertyDiagramPropertyIds } =
  storeToRefs(propertyDiagramStore);
const secondaryPanelStore = useSecondaryPanelStore();
const selectedDataField = computed(() =>
  _.get(propertyDiagramSelected.value, "dataField"),
);

const spacesHeaderLabel = computed(() => {
  const spacesLabel = pluralize("Space", _.size(locatedSpaces.value), true);
  const buildingsLabel = pluralize(
    "Building",
    _.size(unlocatedSpaces.value),
    true,
  );

  return `${spacesLabel}, ${buildingsLabel}`;
});

const canAddSpace = computed(() => {
  if (selectedDataField.value) {
    const fieldContentKey = `${selectedDataField.value.fieldContentType}${selectedDataField.value.fieldContentId}`;
    const fullKeySelected = _.includes(
      _.keys(spaces.value),
      decoratingAndFieldKey(selectedDataField.value),
    );
    const fieldKeySelected = _.some(
      _.keys(spaces.value),
      function (fullKeyString) {
        return _.includes(fullKeyString, fieldContentKey);
      },
    );
    const alreadySelected = fullKeySelected || fieldKeySelected;
    const eligibleSelection = _.includes(
      ["FloorArea", "PropertyEnhancement", "LayoutPartition", "LandCovering"],
      selectedDataField.value.fieldContentType,
    );

    return eligibleSelection && !alreadySelected;
  } else {
    return false;
  }
});

onMounted(() => {
  setTimeout(() => {
    secondaryPanelStore.setSecondaryPanelWidth("auto 10px 800px");
  }, 250);
});

const router = useRouter();
const route = useRoute();

async function addSpace() {
  if (selectedDataField.value) {
    const spaceKey = decoratingAndFieldKey(selectedDataField.value);
    const spaceObject = {
      dataField: selectedDataField.value,
      expanded: true,
    };

    spaceUsageBuilderStore.addSpace({
      spaceObject,
      spaceKey,
    });
    await nextTick();
    router.push({
      name: route.name,
      query: {
        ...route.query,
        horizontalTab: "Spaces",
        verticalTab: undefined,
        spaceFieldIds: spaceFieldIds.value,
      },
    });
    spaceUsageBuilderStore.collapseAllSpacesExcept({ spaceKey });

    if (selectedDataField.value.joiningContentType === "Property") {
      if (
        !_.includes(
          propertyDiagramPropertyIds.value,
          selectedDataField.value.joiningContentId,
        )
      ) {
        propertyDiagramStore.addPropertyToDiagram(
          selectedDataField.value.joiningContentId,
        );
      }
    }
  }
}
</script>
