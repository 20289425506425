import _ from "lodash";

export function standaloneGroupingData(dataFieldChange) {
  const changeType = dataFieldChange.changeType;
  const beforeField = dataFieldChange.before;
  const afterField = dataFieldChange.after;
  const primaryField = changeType === "addition" ? afterField : beforeField;

  return {
    contentType: primaryField.decoratingContentType,
    contentId: primaryField.decoratingContentId,
  };
}

export function rollUpGroupingData(apiLookupKey) {
  const [keyType, joiningType, joiningId, decoratingType, name] = _.split(
    apiLookupKey,
    "-"
  );

  const keyData = {
    keyType,
    joiningType,
    joiningId,
    decoratingType,
    name: `${joiningType} ${joiningId}`,
    noIdName: `${joiningType}`,
    secondaryName: `${decoratingType} ${name}`,
    rawName: name,
  };

  return {
    contentType: keyData.joiningType,
    contentId: keyData.joiningId,
  };
}

export function hasManyGroupingData(apiLookupKey) {
  const [keyType] = _.split(apiLookupKey, "-");
  switch (keyType) {
    case "joiningContentFieldContent": {
      return {
        contentType: hasManyKeyData(apiLookupKey).joiningType,
        contentId: hasManyKeyData(apiLookupKey).joiningId,
      };
    }
    case "decoratingContentFieldContent":
    case "decoratingContentFieldContentWithSubType": {
      return {
        contentType: hasManyKeyData(apiLookupKey).decoratingType,
        contentId: hasManyKeyData(apiLookupKey).decoratingId,
      };
    }
    default: {
      console.log("NO GROUPING DATA?", apiLookupKey);
      return {
        contentType: null,
        contentId: null,
      };
    }
  }
}

function hasManyKeyData(apiLookupKey) {
  const [keyType, ...rest] = _.split(apiLookupKey, "-");

  switch (keyType) {
    case "joiningContentFieldContent": {
      const [joiningType, joiningId, fieldContentType] = rest;

      return {
        joiningType,
        joiningId,
        fieldContentType,
        name: `${joiningType} ${joiningId}`,
        noIdName: `${joiningType}`,
        secondaryName: `${fieldContentType}`,
      };
    }
    case "decoratingContentFieldContent": {
      const [decoratingType, decoratingId, fieldContentType] = rest;

      return {
        decoratingType,
        decoratingId,
        fieldContentType,
        name: `${decoratingType} ${decoratingId}`,
        noIdName: `${decoratingType}`,
        secondaryName: `${fieldContentType}`,
      };
    }
    case "decoratingContentFieldContentWithSubType": {
      const [
        decoratingType,
        decoratingId,
        fieldContentType,
        fieldContentSubType,
      ] = rest;

      return {
        decoratingType,
        decoratingId,
        fieldContentType,
        name: `${decoratingType} ${decoratingId}`,
        noIdName: `${decoratingType}`,
        secondaryName: `${fieldContentType} ${fieldContentSubType}`,
      };
    }
    default:
      return null;
  }
}
