<template>
  <div
    class="container mx-auto md:mt-12 mb-4 px-4 sm:px-8 lg:px-10 flex flex-col items-center"
  >
    <div :style="embedHeight" :id="file.id" class="h-full w-full" />
  </div>
</template>

<script setup>
/* global Dropbox */
import _ from "lodash";
import { ref, computed, onMounted, onBeforeUnmount } from "vue";

const props = defineProps(["file"]);
const debouncedHeightUpdate = ref(null);
const modalHeight = ref(null);
const embed = ref(null);

const embedHeight = computed(() => {
  if (modalHeight.value) {
    const heightRatio = modalHeight.value > 400 ? 0.9 : 0.7;

    return `height: ${modalHeight.value * heightRatio}px;`;
  } else {
    return "height: 200px";
  }
});

onMounted(() => {
  modalHeight.value = document.getElementById("xlModalVue").offsetHeight;

  debouncedHeightUpdate.value = _.debounce(() => {
    modalHeight.value = document.getElementById("xlModalVue").offsetHeight;
  }, 100);

  window.addEventListener("resize", debouncedHeightUpdate.value);

  const element = document.getElementById(props.file.id);
  var options = {
    // Shared link to Dropbox file
    link: props.file.link,
    file: {
      // Sets the zoom mode for embedded files. Defaults to 'best'.
      zoom: "fit", // or "fit"
    },
    folder: {
      // Sets the view mode for embedded folders. Defaults to 'list'.
      view: "list", // or "grid"
      headerSize: "normal", // or "small"
    },
  };

  if (element) {
    embed.value = Dropbox.embed(options, element);
  }
});
onBeforeUnmount(() => {
  window.removeEventListener("resize", debouncedHeightUpdate.value);

  if (embed.value) {
    console.log("unmount dropbox", embed.value);
    Dropbox.unmount(embed.value);
    console.log("embedder removed");
  }
});
</script>
