<template>
  <div
    class="border border-gray-700 h-6 w-full rounded-md overflow-hidden relative"
  >
    <div
      class="absolute w-full h-full flex items-center justify-center pointer-events-none"
      style="z-index: 3"
    >
      <div v-if="allCount > 0" class="text-sm text-white font-semibold">
        {{ acceptedCount + rejectedCount + skippedCount }} / {{ allCount }}
      </div>
    </div>
    <div class="absolute w-full flex pointer-events-none" style="z-index: 2">
      <div
        v-tooltip="'Accepted'"
        class="h-6 bg-green-500 pointer-events-auto"
        :style="`width: ${acceptedWidth}%;`"
      />
      <div
        v-tooltip="'Rejected'"
        class="h-6 bg-red-500 pointer-events-auto"
        :style="`width: ${rejectedWidth}%;`"
      />
      <div
        v-tooltip="'Skipped'"
        class="h-6 bg-blue-500 pointer-events-auto"
        :style="`width: ${skippedWidth}%;`"
      />
    </div>
    <div
      v-tooltip="'Changes needing review'"
      class="absolute w-full h-full bg-gray-400"
      style="z-index: 1"
    />
  </div>
</template>

<script setup>
import { computed } from "vue";
import _ from "lodash";

const props = defineProps([
  "acceptedChangeIds",
  "rejectedChangeIds",
  "skippedChangeIds",
  "allChangeIds",
]);
const acceptedCount = computed(() => props.acceptedChangeIds.length);
const rejectedCount = computed(() => props.rejectedChangeIds.length);
const skippedCount = computed(() => props.skippedChangeIds.length);
const allCount = computed(() => props.allChangeIds.length);
const acceptedWidth = computed(() => {
  const rawPercent = acceptedCount.value / allCount.value;

  return _.round(rawPercent * 100);
});
const rejectedWidth = computed(() => {
  const rawPercent = rejectedCount.value / allCount.value;

  return _.round(rawPercent * 100);
});
const skippedWidth = computed(() => {
  const rawPercent = skippedCount.value / allCount.value;

  return _.round(rawPercent * 100);
});
</script>
