<template>
  <div class="overflow-hidden rounded-lg p-3 shadow bg-gray-100">
    <dt class="truncate text-sm font-medium text-gray-500">Loans</dt>
    <div
      v-if="
        fetchingAnalyzePanelData ||
        refreshingMainTimeline ||
        fetchingLoanInvestmentFields
      "
      class="mt-1 w-full flex"
    >
      <SquareLoader :loading="true" size="24px" color="#9ca3af" />
    </div>
    <dd v-else class="mt-1 text-xl font-semibold tracking-tight text-gray-900">
      <DataField
        calculation-name="loan_count"
        :calculation-value="loansChecked ? loanCount : 'Get'"
        :bundle-field-ids="bundleFieldIds"
        text-classes="text-xl font-semibold tracking-tight"
        :analyze="true"
      />
    </dd>
  </div>
</template>

<script setup>
import SquareLoader from "vue-spinner/src/SquareLoader.vue";
import DataField from "@/components/crowdsourcing/DataField.vue";
import { useTimeTravelStore } from "@/stores/timeTravel";
import { useAnalyzePanelStore } from "@/stores/analyzePanel";
import { storeToRefs } from "pinia";
import { computed, onMounted } from "vue";
import _ from "lodash";

const analyzePanelStore = useAnalyzePanelStore();
const {
  fetchingAnalyzePanelData,
  fetchingLoanInvestmentFields,
  analyzeLoanFields,
  loansChecked,
  loanCount,
} = storeToRefs(analyzePanelStore);
const { refreshingMainTimeline } = storeToRefs(useTimeTravelStore());

const bundleFieldIds = computed(() => {
  return _.map(analyzeLoanFields.value, function (dataField) {
    const combined = _.concat(
      dataField?.localId ? [dataField.localId] : [],
      dataField?.fieldContent?.relatedFieldIds || [],
    );

    return _.uniq(combined);
  });
});

onMounted(() => {});
</script>
