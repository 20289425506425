<template>
  <form
    @submit.prevent="add"
    class="h-full flex flex-col divide-y divide-gray-200"
  >
    <div
      v-if="!isDesktop"
      class="p-2 bg-gray-50 flex items-center justify-around"
    >
      <a @click.prevent="cancel" href="" class="text-sm text-indigo-500">
        Cancel
      </a>
      <h3
        class="flex min-w-0 flex-1 items-center justify-center font-medium text-gray-900"
      >
        {{ selectedTask ? "Edit Task" : "New Task" }}
      </h3>
      <a
        @click.prevent="add"
        href=""
        class="flex items-center text-sm font-medium text-indigo-500"
        data-test="save-task-button"
      >
        Add
      </a>
    </div>

    <div class="p-2 flex-grow overflow-y-auto space-y-4">
      <div v-if="isDesktop">
        <h3 class="text-lg font-medium leading-6 text-gray-900">
          {{ selectedTask ? "Edit Task" : "New Task" }}
        </h3>
      </div>

      <fieldset>
        <div class="mt-1 -space-y-px rounded-md bg-white shadow-sm">
          <div>
            <label for="name" class="sr-only">Name</label>
            <input
              v-model="name"
              v-focus
              type="text"
              name="name"
              id="name"
              class="relative block w-full rounded-none rounded-t-md border-gray-300 bg-transparent focus:z-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              placeholder="Title"
              data-test="task-name-input"
            />
          </div>
          <div>
            <label for="note" class="sr-only">Note</label>
            <textarea
              v-model="note"
              rows="4"
              name="note"
              id="note"
              class="relative block w-full rounded-none rounded-b-md border-gray-300 bg-transparent focus:z-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              placeholder="Note"
              data-test="task-note-input"
            />
          </div>
        </div>
      </fieldset>

      <fieldset v-if="selectedTask?.contentType" class="space-y-2">
        <label
          for="linked-content"
          class="block text-sm font-medium text-gray-700"
          >Linked To</label
        >
        <TaskContent :task="selectedTask" />
      </fieldset>

      <TimingFieldSet />

      <fieldset>
        <PrioritySelect />
      </fieldset>

      <fieldset>
        <TaskListSelect />
      </fieldset>

      <button
        v-if="selectedTask"
        @click="deleteTask"
        type="button"
        :class="workspaceLayout === 'sideBySide' ? '' : 'sm:col-span-2'"
        class="w-full text-sm inline-flex justify-center rounded-md border border-red-300 shadow-sm px-4 py-2 bg-white font-medium text-red-700 hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
      >
        Delete Task
      </button>
    </div>

    <div v-if="isDesktop" class="p-2 bg-gray-50">
      <div class="flex justify-end">
        <button
          @click="cancel"
          type="button"
          class="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          Cancel
        </button>
        <button
          type="submit"
          class="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          data-test="save-task-button"
        >
          Save
        </button>
      </div>
    </div>
  </form>
</template>

<script setup>
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { useReminderStore } from "@/stores/reminder";
import { useMainMapStore } from "@/stores/mainMap";
import { useTasksStore } from "@/stores/tasks";
import { storeToRefs } from "pinia";
import TaskListSelect from "@/components/tasks/form-elements/TaskListSelect.vue";
import PrioritySelect from "@/components/tasks/form-elements/PrioritySelect.vue";
import TimingFieldSet from "@/components/tasks/form-elements/TimingFieldSet.vue";
import TaskContent from "@/components/tasks/TaskContent.vue";

const tasksStore = useTasksStore();
const { addingTask, selectedTask } = storeToRefs(tasksStore);
const layoutStore = useWorkspaceLayoutStore();
const { isDesktop, workspaceLayout } = storeToRefs(layoutStore);
const reminderStore = useReminderStore();
const { name, note } = storeToRefs(reminderStore);
const mapStore = useMainMapStore();

function cancel() {
  addingTask.value = false;
  selectedTask.value = null;
  reminderStore.reset();
}
function add() {
  reminderStore.createTask().then((json) => {
    if (json) {
      const task = json.data;
      tasksStore.patchTasks([task]);
      mapStore.tapView();
    }
    cancel();
  });
}
function deleteTask() {
  reminderStore.deleteTask().then(() => {
    tasksStore.removeTask(selectedTask.value);
    cancel();
  });
}
</script>
