<template>
  <VDropdown>
    <slot name="button"></slot>
    <template #popper>
      <div class="w-80">
        <div class="p-2">
          <div class="flex items-center space-x-1">
            <BookOpenIcon class="h-5 w-5 text-gray-900" />
            <label class="text-base font-medium text-gray-900"
              >Add a citation</label
            >
          </div>
          <p class="text-sm leading-5 text-gray-500">
            Upgrade the value of this data with a citation.
            <a
              href=""
              @click.prevent
              class="font-medium text-indigo-700 underline hover:text-indigo-600"
              >Learn more.</a
            >
          </p>
        </div>
        <fieldset class="p-2 w-full max-h-80 overflow-y-auto">
          <CitationTypeChooser />
          <RadioGroup
            v-if="hasVisibility && _.isNil(existingCitationId)"
            v-model="selectedVisibilityType"
          >
            <RadioGroupLabel class="text-sm font-medium text-gray-900"
              >Select visibility</RadioGroupLabel
            >

            <div class="mt-2 grid grid-cols-2 gap-x-4">
              <RadioGroupOption
                as="template"
                v-for="visibilityType in visibilityTypes"
                :key="visibilityType.id"
                :value="visibilityType"
                v-slot="{ checked, active }"
                :data-test="`citation-visibility-${visibilityType.apiValue}`"
              >
                <div
                  :class="[
                    checked ? 'border-transparent' : 'border-gray-300',
                    active ? 'border-indigo-500 ring-1 ring-indigo-500' : '',
                    'relative flex cursor-pointer rounded-lg border bg-white p-2 shadow-sm focus:outline-none',
                  ]"
                >
                  <span class="flex flex-1">
                    <span class="flex flex-col">
                      <RadioGroupLabel
                        as="span"
                        class="block text-xs font-medium text-gray-900"
                        >{{ visibilityType.title }}</RadioGroupLabel
                      >
                      <RadioGroupDescription
                        as="span"
                        class="mt-1 flex items-center text-xs text-gray-500"
                        >{{ visibilityType.description }}</RadioGroupDescription
                      >
                    </span>
                  </span>
                  <CheckCircleIcon
                    :class="[
                      !checked ? 'invisible' : '',
                      'h-4 w-4 text-indigo-600',
                    ]"
                    aria-hidden="true"
                  />
                  <span
                    :class="[
                      active ? 'border' : 'border-2',
                      checked ? 'border-indigo-500' : 'border-transparent',
                      'pointer-events-none absolute -inset-px rounded-md',
                    ]"
                    aria-hidden="true"
                  />
                </div>
              </RadioGroupOption>
            </div>
          </RadioGroup>

          <legend class="sr-only">Citation type</legend>
          <div class="space-y-2 w-full mt-4">
            <!-- Website -->
            <div v-if="selectedCitationTypeApiValue === 'website'">
              <label for="url" class="block text-xs font-medium text-gray-700"
                >Website</label
              >
              <div class="relative rounded-md shadow-sm mt-1">
                <div
                  class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
                >
                  <LinkIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                </div>
                <input
                  v-model="url"
                  type="url"
                  name="url"
                  id="url"
                  class="block w-full rounded-md border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 text-xs"
                  placeholder="https://www.example.com/some-page"
                  data-test="citation-url"
                />
              </div>

              <SwitchGroup
                v-if="!emptyUrl"
                as="div"
                class="mt-2 flex items-center justify-between"
              >
                <span class="flex flex-grow flex-col">
                  <SwitchLabel
                    as="span"
                    class="text-xs font-medium text-gray-900"
                    passive
                    >Paywalled</SwitchLabel
                  >
                  <SwitchDescription
                    as="span"
                    class="mt-1 text-xs text-gray-500"
                    >Site requires payment/subscription.</SwitchDescription
                  >
                </span>
                <Switch
                  v-model="paywalled"
                  :class="[
                    paywalled ? 'bg-indigo-600' : 'bg-gray-200',
                    'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2',
                  ]"
                >
                  <span
                    aria-hidden="true"
                    :class="[
                      paywalled ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                    ]"
                  />
                </Switch>
              </SwitchGroup>
            </div>

            <!-- File -->
            <div
              v-if="selectedCitationTypeApiValue === 'file'"
              class="space-y-1"
            >
              <label for="file" class="block text-xs font-medium text-gray-700"
                >File</label
              >
              <FileDragDrop
                v-if="_.isNull(citationUploadPayload)"
                local-commit="citation"
                :file-types="'image/*,' + all.join(',')"
                file-type-labels="XLS, PPT, DOC, PDF, PNG, JPG, GIF"
                :size-limit="100"
                :multiple="false"
                :full-width="true"
              />

              <div
                v-else
                class="flex items-center justify-between rounded-md border border-gray-200 py-3 pl-3 pr-4 text-xs"
              >
                <div class="flex w-0 flex-1 items-center">
                  <PaperClipIcon
                    class="h-5 w-5 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                  />
                  <span class="ml-2 w-0 flex-1 truncate">{{
                    citationUploadPayload.file.name
                  }}</span>
                </div>
                <div class="ml-4 flex flex-shrink-0">
                  <button
                    @click="clearFile"
                    type="button"
                    class="rounded-md bg-white font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    Remove
                  </button>
                </div>
              </div>
            </div>

            <!-- Existing Citation -->
            <div
              v-if="selectedCitationTypeApiValue === 'existing'"
              v-observe-visibility="{ callback: fetchExistingCitations }"
              class="w-72 flex flex-col justify-center"
            >
              <CitationSearch
                v-if="_.isNil(existingCitationId)"
                @set-citation="
                  ({ citationField }) => (existingCitation = citationField)
                "
              />
              <div
                v-if="_.isNil(existingCitationId)"
                class="mt-2 flex flex-col justify-center space-y-1"
              >
                <label
                  for="existing-citation"
                  class="mt-2 block text-xs font-medium text-gray-700"
                  >Existing Citation ({{
                    existingCitations?.length || "25"
                  }}
                  newest)</label
                >
                <button
                  v-for="(citationField, index) in existingCitations"
                  :key="citationField.localId"
                  @click="existingCitation = citationField"
                  class="flex rounded-md border border-gray-200 hover:border-gray-500 max-w-full overflow-hidden"
                  :data-test="`existing-citation-${index}`"
                >
                  <CitationDetails
                    class="w-full"
                    :citation-field="citationField"
                    :citation-scope="'visible'"
                    :removable="false"
                    :compact="true"
                  />
                </button>
              </div>

              <div
                v-else
                class="flex rounded-md border border-gray-200 hover:border-gray-500 max-w-full overflow-hidden"
              >
                <CitationDetails
                  class="w-full"
                  :citation-field="existingCitation"
                  :citation-scope="'visible'"
                  :removable="false"
                  :compact="true"
                />
              </div>
            </div>
          </div>

          <!-- Name -->
          <legend class="sr-only">Name</legend>
          <div v-if="hasVisibility" class="mt-4">
            <label for="name" class="block text-xs font-medium text-gray-700"
              >Name (optional)</label
            >
            <div class="mt-1">
              <input
                v-model="name"
                type="text"
                name="name"
                id="name"
                class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-xs"
                data-test="citation-name"
                :placeholder="
                  selectedCitationTypeApiValue === 'file'
                    ? 'Rent roll, lease, etc.'
                    : 'Example.com'
                "
              />
            </div>
          </div>

          <!-- Date -->
          <legend class="sr-only">Date</legend>
          <div v-if="hasVisibility" class="mt-4">
            <label for="date" class="block text-xs font-medium text-gray-700"
              >Date (optional)</label
            >
            <div class="mt-1 flex rounded-md shadow-sm">
              <div
                class="relative flex flex-grow items-stretch focus-within:z-10"
              >
                <div
                  class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
                >
                  <CalendarDaysIcon
                    class="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
                <input
                  v-model="date"
                  type="date"
                  name="date"
                  id="date"
                  class="block w-full rounded-none rounded-l-md border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 text-xs"
                />
              </div>
              <button
                @click="date = null"
                type="button"
                class="relative -ml-px inline-flex items-center space-x-2 rounded-r-md border border-gray-300 bg-gray-50 px-4 py-2 text-xs font-medium text-gray-700 hover:bg-gray-100 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
              >
                <XMarkIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                <span>Clear</span>
              </button>
            </div>
          </div>

          <!-- Description -->
          <legend class="sr-only">Description</legend>
          <div v-if="!emptyCitationType" class="mt-4 space-y-1">
            <label
              for="description"
              class="block text-xs font-medium text-gray-700"
              >Description (optional)</label
            >
            <textarea
              v-model="description"
              rows="3"
              name="description"
              id="description"
              placeholder="Direct viewers to the key info."
              class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-xs"
            />
          </div>
        </fieldset>
        <div class="bg-gray-50 p-2 flex justify-end items-center space-x-2">
          <button
            @click="clearCitation"
            type="button"
            class="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            Clear
          </button>
          <button
            @click="saveCitation"
            v-close-popper
            type="button"
            :disabled="emptyCitationType || fetchingUrlData || savingCitation"
            :class="
              emptyCitationType || fetchingUrlData || savingCitation
                ? 'cursor-not-allowed'
                : ''
            "
            class="inline-flex items-center rounded border border-transparent bg-indigo-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            data-test="save-new-citation-button"
          >
            <PulseLoader
              v-if="fetchingUrlData || savingCitation"
              :loading="true"
              size="4px"
              color="#f3f4f6"
            />
            <template v-else> Save </template>
          </button>
        </div>
      </div>
    </template>
  </VDropdown>
</template>

<script setup>
import { all } from "@/assets/documentFileTypes";
import { ref, computed, watch } from "vue";
import {
  RadioGroup,
  RadioGroupDescription,
  RadioGroupLabel,
  RadioGroupOption,
  Switch,
  SwitchDescription,
  SwitchGroup,
  SwitchLabel,
} from "@headlessui/vue";
import {
  BookOpenIcon,
  CalendarDaysIcon,
  CheckCircleIcon,
  LinkIcon,
  PaperClipIcon,
  XMarkIcon,
} from "@heroicons/vue/20/solid";
import { useCrowdsourcedChangeGroupStore } from "@/stores/crowdsourcedChangeGroup";
import { useCitationStore } from "@/stores/citation";
import { useUserStore } from "@/stores/user";
import { storeToRefs } from "pinia";
import CitationTypeChooser from "@/components/crowdsourcing/CitationTypeChooser.vue";
import CitationDetails from "@/components/crowdsourcing/CitationDetails.vue";
import CitationSearch from "@/components/crowdsourcing/CitationSearch.vue";
import FileDragDrop from "@/components/crowdsourcing/FileDragDrop.vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { cleanUrl } from "@/assets/cleanUrl";
import api from "@/router/api";
import iframelyApi from "@/router/iframelyApi";
import moment from "moment";
import _ from "lodash";

const iframely = window.iframely;
const props = defineProps(["changeIds", "dataField"]);
const emit = defineEmits(["refetch"]);

const parseUrl = ref(true);
const fetchingUrlData = ref(false);
const changeGroupStore = useCrowdsourcedChangeGroupStore();
const { changeGroupId } = storeToRefs(changeGroupStore);
const userStore = useUserStore();
const { emailUnverified } = storeToRefs(userStore);
const citationStore = useCitationStore();
const {
  savingCitation,
  visibilityTypes,
  selectedVisibilityType,
  hasVisibility,
  selectedCitationType,
  url,
  emptyUrl,
  paywalled,
  name,
  emptyName,
  description,
  emptyDescription,
  date,
  existingCitations,
  existingCitation,
  citationUploadPayload,
} = storeToRefs(citationStore);
const existingCitationId = computed(() =>
  _.get(existingCitation.value, "fieldContentId"),
);
const emptyCitationType = computed(() => !selectedCitationType.value);
const savePayload = computed(() => {
  return {
    changeGroupId: changeGroupId.value || null,
    existingCitationId: existingCitationId.value || null,
    changeIds: props.changeIds || [props.dataField.latestChangeId],
    visibility: selectedVisibilityType.value.apiValue,
    url: emptyUrl.value ? null : cleanUrl(url.value),
    paywalled: emptyUrl.value ? null : paywalled.value,
    file: _.get(citationUploadPayload.value, "file", null),
    name: emptyName.value ? null : name.value,
    description: emptyDescription.value ? null : description.value,
    date: moment(date.value).valueOf(),
  };
});
const selectedCitationTypeApiValue = computed(
  () => selectedCitationType.value?.apiValue,
);
const fetchPayload = computed(() => {
  return {
    changeIds: props.changeIds || [props.dataField.latestChangeId],
  };
});
const debouncedParseUrl = _.debounce(function () {
  if (!emptyUrl.value && iframely && parseUrl.value) {
    setTimeout(() => {
      iframely.load();
      fetchingUrlData.value = true;
      iframelyApi(cleanUrl(url.value)).then((data) => {
        const canonicalUrl = _.get(data, "meta.canonical");
        const urlSite = _.get(data, "meta.site");
        const urlDate = _.get(data, "meta.date");

        if (canonicalUrl) {
          parseUrl.value = false;
          url.value = canonicalUrl;
          setTimeout(() => {
            parseUrl.value = true;
          }, 250);
        }
        if (urlSite && !name.value) name.value = urlSite;
        if (urlDate) date.value = moment(urlDate).format("YYYY-MM-DD");
        fetchingUrlData.value = false;
      });
    }, 1000);

    iframely.on("cancel", function (url) {
      // console.log("iframely couldn't parse:", url);
    });
  }
}, 500);

watch(emptyUrl, () => {
  if (emptyUrl.value) {
    paywalled.value = null;
    clearMeta();
  }
});

watch(url, () => debouncedParseUrl());
watch(selectedCitationTypeApiValue, () => {
  citationStore.clearCitation();
});

async function fetchExistingCitations(isVisible) {
  if (isVisible && _.isNull(existingCitations.value)) {
    api.post(`recent_data_field_citations`, fetchPayload.value).then((json) => {
      existingCitations.value = json.data;
    });
  }
}

function clearFile() {
  citationUploadPayload.value = null;
  clearMeta();
}
function clearCitation() {
  citationStore.resetCitationType();
  citationStore.clearCitation();
}
function clearMeta() {
  citationStore.clearMeta();
}
async function saveCitation() {
  if (emailUnverified.value) {
    userStore.promptToVerify();
  } else {
    console.log(savePayload.value);
    savingCitation.value = true;
    api
      .post(`data_field_change_group_citations`, savePayload.value)
      .then((json) => {
        if (json.data.changeGroupId) {
          changeGroupStore.manageCrowdsourcedChangeGroup({
            data: {
              changeGroupId: json.data.changeGroupId,
            },
          });
        }
        clearCitation();
        existingCitations.value = null;
        savingCitation.value = false;
        emit("refetch");
      });
  }
}
</script>

<style>
.v-popper__inner > div {
  overflow: hidden !important;
}
</style>
