<template>
  <div class="h-full bg-indigo-50 rounded-lg overflow-hidden">
    <CardBranding :card="card" />
    <div class="flex-1 flex flex-col">
      <div class="bg-white px-8 py-6">
        <SquareLoader
          v-if="!loaded"
          :loading="true"
          size="20px"
          color="#4338ca"
        />
        <h3
          v-if="loaded"
          v-tooltip="namesAddresses ? `Includes ${namesAddresses}` : ''"
          class="text-center text-lg font-semibold text-gray-800"
          id="name"
        >
          {{ bundleDataField?.fieldContent?.name }}
        </h3>
        <h4
          v-if="loaded && bundleDataField?.fieldContentType === 'Property'"
          class="mt-1 text-center text-gray-500"
          id="location"
        >
          {{ cityStateFor(bundleDataField) }}
        </h4>
        <h4
          v-else-if="loaded && contactCompanies"
          class="mt-1 text-sm text-center text-gray-500"
          id="location"
        >
          {{ contactCompanies }}
        </h4>
      </div>
      <div
        class="flex-1 flex flex-col justify-between border-t-2 border-gray-100 p-6"
      >
        <SquareLoader
          v-if="!loaded"
          :loading="true"
          size="28px"
          color="#4338ca"
        />
        <template v-else>
          <div
            class="flex-grow text-center leading-6 font-semibold text-gray-800"
          >
            {{ costDescription
            }}<template v-if="bundleDataField?.fieldContentType === 'Property'"
              >:</template
            >
          </div>
          <ul
            v-if="bundleDataField?.fieldContentType === 'Property'"
            class="mt-4 space-y-2"
          >
            <li
              v-for="categoryObject in orderedCategories"
              :key="categoryObject.name"
              class="flex items-start"
            >
              <div class="flex-shrink-0">
                <ExclamationTriangleIcon
                  v-if="categoryObject.highComplexity"
                  v-tooltip="categoryObject.highComplexity"
                  class="h-6 w-6 text-yellow-500"
                />
                <CheckIcon v-else class="h-6 w-6 text-green-500" />
              </div>
              <p
                v-tooltip="categoryObject.description"
                class="ml-3 flex-grow text-base leading-6 font-medium text-gray-500"
              >
                {{ _.startCase(categoryObject.name) }}:
                {{ categoryObject.fields?.length || "Air rights" }}
              </p>
            </li>
          </ul>
          <div
            v-if="_.isEmpty(orderedCategories)"
            class="relative block w-full rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <PuzzlePieceIcon class="mx-auto h-12 w-12 text-gray-400" />
            <span class="mt-2 block text-sm font-medium text-gray-900">
              Help us populate this record. Contributors earn revenue.
            </span>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup>
import SquareLoader from "vue-spinner/src/SquareLoader.vue";
import CardBranding from "@/components/collectible-cards/CardBranding.vue";
import { bundlesFor } from "@/components/crowdsourcing/unlockableBundles";
import { dollarAmount } from "@/assets/numberHelpers";
import { useAnalyticsStore } from "@/stores/analytics";
import { storeToRefs } from "pinia";
import { usePropertyDiagramStore } from "@/stores/propertyDiagram";
import { useCompanyDetailStore } from "@/stores/companyDetail";
import { useContactDetailStore } from "@/stores/contactDetail";
import { computed, onMounted, ref, watch } from "vue";
import stateAbbreviationsUS from "@/assets/stateAbbreviationsUS";
import api from "@/router/api";
import _ from "lodash";
import { CheckIcon, ExclamationTriangleIcon } from "@heroicons/vue/20/solid";
import { PuzzlePieceIcon } from "@heroicons/vue/24/outline";

const props = defineProps(["card"]);
const analyticsStore = useAnalyticsStore();
const propertyDiagramStore = usePropertyDiagramStore();
const { propertyIdParam } = storeToRefs(propertyDiagramStore);
const companyDetailStore = useCompanyDetailStore();
const {
  companyIdParam,
  companyInvolvementsSize,
  companyInvolvements: companyStoreInvolvements,
} = storeToRefs(companyDetailStore);
const contactDetailStore = useContactDetailStore();
const {
  contactIdParam,
  contactInvolvementsSize,
  companyInvolvements: contactStoreInvolvements,
} = storeToRefs(contactDetailStore);

const ableToComposeMeta = computed(
  () => loaded.value && orderedCategories.value.length > 0,
);
const bundleDataField = ref(null);
const bundleDataFieldId = computed(() =>
  _.get(props.card, "bundleDataFieldId", false),
);
const airLayerPresent = computed(() => {
  return (
    bundleDataField.value?.fieldContent?.airLayerPropertyId ||
    bundleDataField.value?.fieldContent?.groundLayerPropertyId
  );
});
const allFieldsData = ref(null);
const bundlePricingData = ref({});
const bundles = computed(() => bundlesFor(bundleDataField.value));
const allFieldsBundle = computed(() => {
  if (propertyIdParam.value) {
    return bundlePricingData.value?.["all_fields"];
  } else if (companyIdParam.value || contactIdParam.value) {
    return allFieldsData.value;
  } else {
    return null;
  }
});
const costDescription = computed(() => {
  if (allFieldsBundle.value) {
    const freeCount = allFieldsBundle.value.freeCount;
    const paidCount = allFieldsBundle.value.licenseableCount;

    switch (bundleDataField.value?.fieldContentType) {
      case "Property": {
        if (freeCount > 0 && paidCount > 0) {
          return `${freeCount} free and ${paidCount} paid datapoints`;
        } else if (freeCount > 0) {
          return `${freeCount} free datapoints`;
        } else {
          return `${paidCount} paid datapoints`;
        }
      }
      case "Company":
        return `${paidCount} hunts, holdings (equity and debt), advisory assignments, and space usages`;
      case "Contact":
        return `${paidCount} current and past equity, debt, and advisory transactions`;
      default:
        return "";
    }
  } else {
    return "";
  }
});
const contactCompanies = computed(() => {
  if (
    ableToComposeMeta.value &&
    bundleDataField.value?.fieldContentType === "Contact"
  ) {
    return bundleDataField.value.fieldContent?.companyNames?.join(", ");
  } else {
    return null;
  }
});
const namesAddresses = computed(() => {
  if (ableToComposeMeta.value) {
    const rawFields = orderedCategories.value
      .filter((categoryObject) =>
        _.includes(["Naming and Branding", "Geolocation"], categoryObject.name),
      )
      .flatMap((categoryObject) => categoryObject.fields || []);
    const displayableFields = rawFields.filter(
      (field) =>
        _.includes(["name", "street_address"], field.fieldName) &&
        !!field.fieldValue &&
        field.fieldValue !== bundleDataField.value?.fieldContent?.name,
    );

    return _.uniq(displayableFields.map((field) => field.fieldValue)).join(
      ", ",
    );
  } else {
    return null;
  }
});

const capitalStackDescription = computed(() => {
  switch (bundleDataField.value?.fieldContentType) {
    case "Property":
      return "Research the companies, contacts, and investments that shape the equity and debt structure of the property.";
    case "Company":
      return "Research the company's holdings (equity and debt) and advisory assignments.";
    case "Contact":
      return "Research the contact's current and past equity, debt, and advisory track record.";
    default:
      return "Research companies, contacts, and investments";
  }
});

const rawCategories = computed(() => [
  {
    order: 1,
    name: "Capital Stack",
    description: capitalStackDescription.value,
  },
  {
    order: 2,
    name: "Leasing",
    description:
      "See the available space and leasing activity for this property.",
  },
  {
    order: 3,
    name: "Hunts",
    description: "Understand investment and/or occupier preferences.",
  },
  {
    order: 4,
    name: "Financial Performance",
    description:
      "Quantify income and expenses for specific parts of the property.",
  },
  {
    order: 5,
    name: "Property Rights",
    airLayerPresent: !!airLayerPresent.value,
    description: "See the different layers of control for this property.",
  },
  {
    order: 6,
    name: "Buildings and Heights",
    description: "See the physical structures and their relative sizes",
  },
  {
    order: 7,
    name: "Floors and Areas",
    description:
      "Understand the density, uses, and sizes of space at this property",
  },
  {
    order: 8,
    name: "Key Property Dates",
    description:
      "See how the physical structure of the property evolved over time.",
  },
  {
    order: 9,
    name: "Naming and Branding",
    description: "Learn what various parts of the property are called.",
  },
  {
    order: 10,
    name: "Geolocation",
    description: "See where specific parts of the property are located",
  },
  {
    order: 11,
    name: "Citations and Files",
    description:
      "Citations show where our data comes from. Files and photos deepen your understanding of the property.",
  },
]);
const dataCoverageCategories = computed(() => {
  const displayable = allFieldsBundle.value?.summaryMeta?.filter(
    (field) =>
      !!field.decoratingContentType &&
      !_.includes(["PropertyDiagramVisualOrdering"], field.fieldContentType) &&
      !_.includes(["vertical_level"], field.fieldName),
  );
  return _.groupBy(displayable, function (field) {
    if (field.fieldContentType) {
      switch (field.fieldContentType) {
        case "Hunt":
        case "HuntGeographyIntent":
        case "HuntSpaceRequirement":
        case "SpaceRequirementPhase":
          return "Hunts";
        case "CitationChangeGroup":
        case "CrowdsourcedFile":
          return "Citations and Files";
        case "CashflowSchedule": {
          switch (field.decoratingContentType) {
            case "SpaceUsage":
              return "Leasing";
            default:
              return "Financial Performance";
          }
        }
        case "SpaceAvailability":
        case "SpaceUsage":
          return "Leasing";
        case "CompanyInvolvement": {
          switch (field.decoratingContentType) {
            case "Hunt":
            case "HuntGeographyIntent":
              return "Hunts";
            case "SpaceAvailability":
            case "SpaceUsage":
              return "Leasing";
            default:
              return "Capital Stack";
          }
        }
        case "ContactCompanyInvolvement":
        case "OwnershipInterest":
        case "Valuation":
        case "Investment":
          return "Capital Stack";
        case "LandCovering":
        case "LandCoveringGrouping":
          return "Buildings and Heights";
        case "GeographyRegion": {
          switch (field.decoratingContentType) {
            case "HuntGeographyIntent":
              return "Hunts";
            default:
              return "Geolocation";
          }
        }
        case "ContentPolygon":
          return "Geolocation";
        case "ContentLocation":
          return "Geolocation";
        case "ContentAlias":
          return "Naming and Branding";
        case "FloorArea": {
          switch (field.decoratingContentType) {
            case "SpaceAvailability":
            case "SpaceUsage":
              return "Leasing";
            default:
              return "Floors and Areas";
          }
        }
        case "ContentPropertyUse": {
          switch (field.decoratingContentType) {
            case "Hunt":
            case "HuntSpaceRequirement":
              return "Hunts";
            default:
              return "Floors and Areas";
          }
        }
        case "LandCoveringLevel":
        case "FloorAreaDownwardVerticalOpening":
        case "LandCoveringDiagramVerticalGrouping":
          return "Floors and Areas";
        case "PropertyRight":
          return "Property Rights";
        default:
          return field.fieldContentType;
      }
    } else {
      switch (field.fieldName) {
        case "description":
          return "Citations and Files";
        case "name": {
          switch (field.decoratingContentType) {
            case "SpaceAvailabilityGroup":
            case "SpaceAvailability":
            case "SpaceUsage":
              return "Leasing";
            case "Hunt":
            case "HuntGeographyIntent":
              return "Hunts";
            case "Investment":
            case "InvestmentGroup":
              return "Capital Stack";
            default:
              return "Naming and Branding";
          }
        }
        case "street_address":
        case "land_area":
          return "Geolocation";
        case "height":
          return "Buildings and Heights";
        case "use_based_area":
        case "standardized_area":
        case "minimum_subdivision_standardized_area":
        case "horizontal_alignment":
        case "partition_type":
          return "Floors and Areas";
        case "value":
        case "value_type":
        case "origination_date":
        case "seniority_type":
        case "equity_type":
        case "opened_at":
        case "client_role":
        case "engaged_date":
        case "awarded_at":
        case "dropped_at":
        case "retired_at":
        case "retired_date":
        case "fully_funded_loan_amount":
        case "loan_term_years":
        case "loan_coupon_rate":
          return "Capital Stack";
        case "live_date":
        case "under_contract_date":
        case "closed_date": {
          switch (field.decoratingContentType) {
            case "SpaceAvailability":
              return "Leasing";
            case "Investment":
              return "Capital Stack";
            default:
              return "Key Dates";
          }
        }
        case "move_in_date":
        case "move_out_date":
          return "Leasing";
        case "executed_date":
        case "commenced_date":
        case "terminated_date":
        case "expired_date": {
          switch (field.decoratingContentType) {
            case "SpaceUsage":
              return "Leasing";
            default:
              return "Key Dates";
          }
        }
        case "created_date":
        case "destroyed_date":
        case "granted_date":
        case "used_date":
        case "hypothetical_date":
        case "construction_proposed_date":
        case "demolition_proposed_date":
        case "construction_approved_date":
        case "construction_permitted_date":
        case "construction_commenced_date":
        case "demolition_permitted_date":
        case "construction_completed_date":
        case "demolished_date":
          return "Key Property Dates";
        case "contract_state":
        case "occupancy_state":
          return "Leasing";
        case "state": {
          switch (field.decoratingContentType) {
            case "PropertyRight":
            case "LandCovering":
            case "FloorArea":
              return "Key Property Dates";
            case "Loan":
            case "Investment":
              return "Capital Stack";
            default:
              return "Key Dates";
          }
        }
        case "withdrawn_date": {
          switch (field.decoratingContentType) {
            case "PropertyRight":
            case "LandCovering":
            case "FloorArea":
              return "Key Property Dates";
            case "SpaceAvailability":
              return "Leasing";
            case "Investment":
              return "Capital Stack";
            default:
              return "Key Dates";
          }
        }
        case "active_date":
        case "fulfilled_date":
        case "ceased_date":
        case "target_date":
        case "target_occupancy_date":
        case "hunts_equity":
        case "hunts_debt":
        case "max_investment_size":
        case "min_investment_size":
        case "max_area":
        case "min_area":
        case "max_headcount":
        case "min_headcount":
        case "density_percent_modifier":
        case "max_area_per_person":
        case "min_area_per_person":
          return "Hunts";
        default:
          return field.fieldName;
      }
    }
  });
});
const orderedCategories = computed(() => {
  if (loaded.value && _.size(dataCoverageCategories.value) > 0) {
    const withFields = rawCategories.value.map((categoryObject) => {
      const matchingFields = _.get(
        dataCoverageCategories.value,
        categoryObject.name,
      );

      if (
        matchingFields?.length > 0 ||
        (categoryObject.name === "Property Rights" && airLayerPresent.value)
      ) {
        let highComplexity = null;

        if (categoryObject.name === "Property Rights") {
          const layeredControl = _.some(matchingFields, function (dataField) {
            return _.includes(
              ["leasehold", "condominium"],
              dataField.fieldContentSubType,
            );
          });

          if (layeredControl) {
            highComplexity =
              "We show you how control of the property is divided across multiple property right layers such as leaseholds and condominium units.";
          } else if (categoryObject.airLayerPresent) {
            highComplexity =
              "We show you how control of the property is divided between ground and air rights.";
          }
        }

        return _.merge({}, categoryObject, {
          fields: matchingFields,
          highComplexity,
        });
      } else {
        return null;
      }
    });

    return _.orderBy(_.compact(withFields), ["order", "desc"]);
  } else {
    return [];
  }
});

const loaded = ref(false);

watch(companyInvolvementsSize, () => {
  if (
    companyIdParam.value &&
    companyInvolvementsSize.value > 0 &&
    !allFieldsData.value
  ) {
    fetchBundleDataById();
  }
});

watch(contactInvolvementsSize, () => {
  if (
    contactIdParam.value &&
    contactInvolvementsSize.value > 0 &&
    !allFieldsData.value
  ) {
    fetchBundleDataById();
  }
});

watch(ableToComposeMeta, () => {
  if (ableToComposeMeta.value) composeMeta();
});

onMounted(async () => {
  await fetchDataField();
  await fetchBundles();

  for (const bundle of bundles.value) {
    await fetchBundlePricing(bundle);
  }
  loaded.value = true;
});

function composeMeta() {
  if (ableToComposeMeta.value) {
    const totalPrice = allFieldsBundle.value?.price;
    let includesBlurb = "";

    if (namesAddresses.value) {
      includesBlurb = `, which includes ${namesAddresses.value},`;
    } else if (contactCompanies.value) {
      includesBlurb = ` (${contactCompanies.value})`;
    }
    const introSentence = `Explore ${costDescription.value} for ${bundleDataField.value?.fieldContent?.name}${includesBlurb}.`;
    const costSentence = totalPrice
      ? `Tower Hunt lets you unlock just the datapoints you need. If you want everything we have for ${
          bundleDataField.value?.fieldContent?.name
        }, spend just ${dollarAmount(totalPrice, 2)}.`
      : `Tower Hunt lets you access everything we have for ${bundleDataField.value?.fieldContent?.name} for free.`;
    const coverageSummary = `Data categories include: ${orderedCategories.value
      .map((categoryObject) => {
        const datapointCount =
          categoryObject.fields?.length > 0
            ? `${categoryObject.fields?.length} datapoints`
            : "Air rights";
        return `${_.startCase(categoryObject.name)} (${datapointCount}) - ${
          categoryObject.highComplexity || categoryObject.description
        }`;
      })
      .join("; ")}`;
    let combined = `${analyticsStore.tagline}`;

    switch (bundleDataField.value?.fieldContentType) {
      case "Property":
        combined = `${analyticsStore.tagline} ${introSentence} ${costSentence} ${coverageSummary}`;
        break;
      case "Company":
      case "Contact":
        combined = `${analyticsStore.tagline} ${introSentence} ${costSentence}`;
        break;
      default:
    }

    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", combined);
  }
}

async function fetchDataField() {
  return new Promise((resolve) => {
    api
      .get(
        `data_field_by_id/${bundleDataFieldId.value}?scope=validation&template=validation`,
      )
      .then((json) => {
        bundleDataField.value = json.data;
        resolve();
      });
  });
}

async function fetchBundleDataById() {
  let bundleFieldIds = null;
  if (companyIdParam.value && companyStoreInvolvements.value?.length > 0) {
    bundleFieldIds = companyStoreInvolvements.value.map(
      (field) => field.localId,
    );
  } else if (
    contactIdParam.value &&
    contactStoreInvolvements.value?.length > 0
  ) {
    bundleFieldIds = contactStoreInvolvements.value.map(
      (field) => field.localId,
    );
  }

  if (bundleFieldIds) {
    const payload = {
      bundleFieldIds,
      bundle: "all_fields",
    };
    const response = await api.post(
      `crowdsourced_data_field_calculation_bundles`,
      payload,
    );

    if (response?.data) {
      allFieldsData.value = response.data;
    }
  }
}

async function fetchBundles() {
  if (companyIdParam.value || contactIdParam.value) {
    await fetchBundleDataById();
  } else {
    api
      .get(
        `crowdsourced_data_field_bundles/${bundleDataFieldId.value}?bundle=all_fields`,
      )
      .then((json) => {
        allFieldsData.value = json.data;
      });
  }
}

async function fetchBundlePricing(bundle) {
  if (propertyIdParam.value) {
    const response = await api.get(
      `crowdsourced_data_field_bundles/${bundleDataFieldId.value}?bundle=${bundle.apiParam}`,
    );

    bundlePricingData.value[bundle.apiParam] = response.data;
  }
}

function cityStateFor(propertyField) {
  const state = _.get(
    propertyField,
    "fieldContent.locationDataField.fieldContent.state",
  );
  const abbreviatedState = stateAbbreviationsUS[state];

  return `${_.get(
    propertyField,
    "fieldContent.locationDataField.fieldContent.city",
  )}, ${abbreviatedState || state}`;
}
</script>
