<template>
  <ul role="list" class="flex flex-col divide-y divide-gray-200">
    <SelectableTask
      v-for="(task, index) in visibleCollection"
      :key="task.id || index"
      :task="task"
      :has-query-tasks="hasQueryTasks"
      :numeric-ids="numericIds"
      :data-test="`task-list-task-${index}`"
      @refetch-for-repeat="tasksStore.fetchTasks(selectedTaskList.id)"
    />
    <InfiniteLoading v-if="signedIn && taskPagy?.next" @infinite="loadTasks" />
    <InlineReminderForm v-if="addingInlineTask" />
    <div @click="addInlineTask" class="flex-grow text-gray-50">Add</div>
  </ul>
</template>

<script setup>
import { useTasksStore } from "@/stores/tasks";
import { useUserStore } from "@/stores/user";
import { useReminderStore } from "@/stores/reminder";
import { storeToRefs } from "pinia";
import { computed, onMounted } from "vue";
import { useRoute } from "vue-router";
import api from "@/router/api";
import _ from "lodash";
import InlineReminderForm from "@/components/tasks/InlineReminderForm.vue";
import SelectableTask from "@/components/tasks/SelectableTask.vue";
import sortedTasksFor from "@/components/tasks/taskListSorting";

const reminderStore = useReminderStore();
const { list } = storeToRefs(reminderStore);
const tasksStore = useTasksStore();
const {
  addingInlineTask,
  effectiveTasks,
  incompleteTasks,
  selectedTaskList,
  selectedTask,
  batchSelection,
  listGroupedTaskPagyObjects,
} = storeToRefs(tasksStore);
const userStore = useUserStore();
const { signedIn } = storeToRefs(userStore);
const taskPagy = computed(
  () =>
    listGroupedTaskPagyObjects.value[
      selectedTaskList.value.accessTokenId ||
        _.camelCase(selectedTaskList.value.name)
    ]?.pagy
);
const all = computed(() => effectiveTasks.value.filter(selectedListFilter));
const incomplete = computed(() =>
  incompleteTasks.value.filter(selectedListFilter)
);

const visibleCollection = computed(() => {
  return sortedTasksFor({
    signedIn: signedIn.value,
    useStandaloneShowCompleted: false,
    taskListObject: selectedTaskList.value,
    allTasks: all.value,
    incompleteTasks: incomplete.value,
  });
});
const route = useRoute();
const query = computed(() => route.query);
const queryTaskIds = computed(() => _.get(query.value, "taskIds"));
const hasQueryTasks = computed(
  () => _.isArray(queryTaskIds.value) || queryTaskIds.value
);
const numericIds = computed(() => {
  if (hasQueryTasks.value) {
    if (_.isArray(queryTaskIds.value)) {
      return queryTaskIds.value.map((id) => _.toNumber(id));
    } else {
      return [_.toNumber(queryTaskIds.value)];
    }
  } else return [];
});

onMounted(() => {
  tasksStore.fetchTasks(selectedTaskList.value.id).then(async () => {
    setTimeout(() => {
      handleQueryTasks();
    }, 500);
  });
});

function handleQueryTasks() {
  if (signedIn.value) {
    if (hasQueryTasks.value) {
      const allTasksPresent = _.every(numericIds.value, function (cardId) {
        const matchingTask = _.find(all.value, {
          id: cardId,
        });

        return !!matchingTask;
      });

      if (!allTasksPresent) {
        api
          .post(`highlighted_tasks`, { taskIds: numericIds.value })
          .then((json) => {
            tasksStore.patchTasks(json.data);
          });
      }
    }
  }
}

function cleanUrl(url) {
  return url.replace("/api/v1/", "");
}
const loadTasks = async ($state) => {
  if (signedIn.value && taskPagy.value?.next) {
    const endpoint = cleanUrl(taskPagy.value.next_url);
    try {
      api.get(endpoint).then((json) => {
        const { data, pagy } = json.data;

        tasksStore.patchTasks(data, true);
        listGroupedTaskPagyObjects.value[
          selectedTaskList.value.accessTokenId ||
            _.camelCase(selectedTaskList.value.name)
        ].pagy = pagy;
        if (data.length < 25) $state.complete();
        else {
          $state.loaded();
        }
      });
    } catch (error) {
      $state.error();
    }
  } else {
    $state.complete();
  }
};

function selectedListFilter(task) {
  if (signedIn.value) {
    return task.accessTokenId === selectedTaskList.value.accessTokenId;
  } else {
    return task.taskListName === selectedTaskList.value.name;
  }
}
function addInlineTask() {
  if (!addingInlineTask.value && !selectedTask.value && !batchSelection.value) {
    addingInlineTask.value = true;
    list.value = selectedTaskList.value;
  }
}
</script>
