import { computed, ref } from "vue";
import { useUserStore } from "@/stores/user";
import { useSecondaryPanelStore } from "@/stores/secondaryPanel";
import { useModalStore } from "@/stores/modal";
import { useUnlockerStore } from "@/stores/unlocker";
import { defineStore, storeToRefs, acceptHMRUpdate } from "pinia";
import subscribeInterceptor from "@/components/crowdsourcing/subscribeInterceptor";
import api from "@/router/api";
import _ from "lodash";

export const useCrowdsourcedChangeGroupStore = defineStore(
  "changeGroup",
  () => {
    const crowdsourcedChangeGroup = ref(null);
    const existingChangeGroupId = ref(null);
    const originatingData = ref(false);
    const changeGroupId = computed(() =>
      _.get(crowdsourcedChangeGroup.value, "changeGroupId"),
    );
    const userStore = useUserStore();
    const { signedIn, emailUnverified } = storeToRefs(userStore);
    const secondaryPanelStore = useSecondaryPanelStore();
    const modalStore = useModalStore();
    const { modalPayload } = storeToRefs(modalStore);
    const unlockerStore = useUnlockerStore();
    const { upgradeSuccessful } = storeToRefs(unlockerStore);

    async function manageCrowdsourcedChangeGroup(json) {
      return new Promise((resolve) => {
        if (!crowdsourcedChangeGroup.value && json.data.changeGroupId) {
          crowdsourcedChangeGroup.value = {
            changeGroupId: json.data.changeGroupId,
          };

          setTimeout(() => {
            resolve();
          }, 250);
        } else {
          resolve();
        }
      });
    }

    async function dismissData({
      dataFieldId,
      byUser = true,
      isInner = false,
      successCallback = async (json) => {
        await manageCrowdsourcedChangeGroup(json);
      },
    }) {
      const failureCallback = async () => {
        console.log("Unstaked dismissal");
      };

      const apiRequestFunc = () =>
        api.post(`dismiss_data_field`, {
          dataFieldId,
          isInner,
          byUser,
          changeGroupId: changeGroupId.value,
        });

      const result = await originateData(
        apiRequestFunc,
        successCallback,
        failureCallback,
      );

      originatingData.value = false;

      return result;
    }

    async function originateData(
      apiRequestFunc,
      successCallback = async () => {},
      failureCallback = async () => {},
      mapSignedIn,
    ) {
      originatingData.value = true;

      if (emailUnverified.value) {
        userStore.promptToVerify();
        await failureCallback();
        originatingData.value = false;
      } else if (signedIn.value || mapSignedIn) {
        const responseHandler = async (json) => {
          const staked = _.includes(json.data.stakeStatus, "staked");
          if (staked || upgradeSuccessful.value) {
            await manageCrowdsourcedChangeGroup(json);
            await successCallback(json);
          } else if (json.data.stakeStatus === null) {
            console.log("null stakeStatus: dismissal of rejected field");
            await successCallback(json);
          } else if (json.data.proofStatus) {
            console.log("proof status", json.data.proofStatus);
            await successCallback(json);
          } else {
            await failureCallback(json);
          }
        };

        return subscribeInterceptor({
          apiRequestFunc,
          successCallback: responseHandler,
          failureCallback,
          modalPayloadRef: modalPayload,
          upgradeSuccessfulRef: upgradeSuccessful,
          afterSubscribe: "successCallback",
          context: "originating",
          originatingDataRef: originatingData,
        });
      } else {
        secondaryPanelStore.promptToRegister();
        await failureCallback();
        originatingData.value = false;
      }
    }

    return {
      changeGroupId,
      crowdsourcedChangeGroup,
      existingChangeGroupId,
      originatingData,
      manageCrowdsourcedChangeGroup,
      originateData,
      dismissData,
    };
  },
);

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useCrowdsourcedChangeGroupStore, import.meta.hot),
  );
}
