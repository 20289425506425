<template>
  <div class="flex flex-col space-y-2">
    <div class="text-sm font-medium text-gray-500">Add Property Use</div>
    <select
      v-model="selectedValue"
      id="dropdown-option"
      name="dropdown-option"
      :data-test="`property-use${proveForValue ? '-proof' : ''}-select`"
      class="block w-full shadow-sm pl-3 pr-10 py-2 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-sm rounded-md"
    >
      <option
        v-for="option in dropdownOptions"
        :key="option.value"
        :value="option.value"
      >
        {{ option.name }}
      </option>
    </select>
    <div class="flex items-center justify-end space-x-2">
      <button
        @click="cancel"
        type="button"
        class="inline-flex items-center p-1 border border-gray-300 rounded-full shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        <XMarkIcon class="h-4 w-4" />
      </button>

      <DataVisibilityButton :visibility="visibility" class="inline-flex">
        <template v-slot:button>
          <button
            @click="save(null)"
            :disabled="originatingData"
            type="button"
            :class="
              visibility === 'safezone'
                ? 'bg-yellow-500 hover:bg-yellow-600 focus:ring-yellow-600'
                : 'bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500'
            "
            class="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2"
            :data-test="`property-use${proveForValue ? '-proof' : ''}-save`"
          >
            <PulseLoader
              v-if="originatingData"
              :loading="true"
              size="3px"
              color="#f3f4f6"
            />
            <CheckIcon v-else class="h-4 w-4" />
          </button>
        </template>
      </DataVisibilityButton>
    </div>
  </div>
</template>

<script setup>
import { XMarkIcon, CheckIcon } from "@heroicons/vue/20/solid";
import api from "@/router/api";
import useTypes from "@/assets/useTypes";
import { ref, computed, onMounted } from "vue";
import { useCrowdsourcedChangeGroupStore } from "@/stores/crowdsourcedChangeGroup";
import { useNotificationsStore } from "@/stores/notifications";
import { usePropertyDiagramStore } from "@/stores/propertyDiagram";
import { useProveForValueStore } from "@/stores/proveForValue";
import { storeToRefs } from "pinia";
import _ from "lodash";
import DataVisibilityButton from "@/components/crowdsourcing/DataVisibilityButton.vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";

const props = defineProps([
  "content",
  "contentType",
  "challengeDataField",
  "proveForValue",
]);
const emit = defineEmits(["cancel", "completed", "unlocked"]);
const selectedValue = ref(null);

const notificationsStore = useNotificationsStore();
const proveForValueStore = useProveForValueStore();
const propertyDiagramStore = usePropertyDiagramStore();
const changeGroupStore = useCrowdsourcedChangeGroupStore();
const { originatingData, changeGroupId } = storeToRefs(changeGroupStore);

const visibility = computed(() =>
  props.proveForValue ? "public" : "safezone",
);
const dropdownOptions = computed(() => {
  switch (props.contentType) {
    case "FloorArea":
      return useTypesWithParking.value;
    case "Hunt":
    default:
      return useTypes;
  }
});
const useTypesWithParking = computed(() => {
  return _.concat(
    useTypes,
    {
      order: 13,
      name: "Common Area",
      value: "Common Area",
      frontEndKey: "commonArea",
      backEndKey: "common_area",
      measure: "SF",
      color: "amber",
    },
    {
      order: 14,
      name: "Mechanical",
      value: "Mechanical",
      frontEndKey: "mechanical",
      backEndKey: "mechanical",
      measure: "SF",
      color: "amber",
    },
    {
      order: 15,
      name: "Parking",
      value: "Parking",
      frontEndKey: "parking",
      backEndKey: "parking",
      measure: "Spaces",
      color: "sky",
    },
  );
});

onMounted(() => (selectedValue.value = _.head(dropdownOptions.value).value));

function cancel() {
  emit("cancel");
}
async function afterPersist(json) {
  if (json.data?.proofStatus === "rejected") {
    save(json.data.proofStatus);
  } else if (json.data?.proofStatus === "accepted") {
    notificationsStore.addNotification("proofAccepted");
    emit("unlocked");
  } else {
    propertyDiagramStore.postEditingPatch(json);
    emit("completed", { dataField: json.data.dataField });
  }
}
async function persist(newProofStatus) {
  if (props.proveForValue && !newProofStatus) {
    let payload = {
      fieldValue: selectedValue.value,
      fieldValueType: "string",
      isInner: false,
    };
    const proofResponse = await proveForValueStore.submitProof(
      props.challengeDataField,
      payload,
    );

    return proofResponse;
  } else {
    const payload = {
      changeGroupId: changeGroupId.value,
      useType: selectedValue.value,
    };
    let response = await api.post(
      `content_property_uses/${props.contentType}/${props.content.id}`,
      payload,
    );

    if (newProofStatus === "rejected") {
      const declassifyPayload = {
        id: response.data.dataField.localId,
      };

      const declassifiedResponse = await api.post(
        `declassify_datapoint`,
        declassifyPayload,
      );
      response.data.dataField = declassifiedResponse.data;
      notificationsStore.addNotification("proofRejected");
    }

    return response;
  }
}
function save(newProofStatus = null) {
  const apiRequestFunc = () => persist(newProofStatus);
  const successCallback = (json) => afterPersist(json);
  const failureCallback = () => cancel();

  return changeGroupStore.originateData(
    apiRequestFunc,
    successCallback,
    failureCallback,
  );
}
</script>
