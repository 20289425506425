<template>
  <div
    v-observe-visibility="{ callback: fetchPropertyRightDataField, once: true }"
  >
    <div v-if="propertyRightDataField" class="flex flex-col p-1">
      <div class="flex space-x-1">
        <div class="text-sm text-gray-700 font-bold">Land Parcel</div>
        <button @click="getHelp" type="button" v-tooltip="'Get help'">
          <QuestionMarkCircleIcon class="h-4 w-4 text-gray-700" />
        </button>
      </div>
      <DataField
        :data-field="propertyRightDataField"
        :user-store="userStore"
        :tasks-store="tasksStore"
        :task-list-store="taskListStore"
        :reminder-store="reminderStore"
        :guest-profile-store="guestProfileStore"
        :modal-store="modalStore"
        :unlocker-store="unlockerStore"
        :property-diagram-store="propertyDiagramStore"
        :layout-store="layoutStore"
        :change-group-store="changeGroupStore"
        :documentation-store="documentationStore"
        primary-text-path="fieldContent.name"
        text-classes="leading-5 font-medium"
        dropdown-placement="left-start"
        text-styles=""
        @completed="refetchMap"
      />
      <div class="mt-1 flex items-center space-x-2">
        <button
          v-if="alreadyInDiagram"
          @click.prevent="switchToDiagramProperty"
          v-tooltip.bottom="'Focus in diagram'"
          type="button"
          class="h-5 w-5 inline-flex justify-center items-center bg-white border border-gray-300 rounded-md text-xs text-gray-500 hover:text-gray-700"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-4 w-4"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M6.672 1.911a1 1 0 10-1.932.518l.259.966a1 1 0 001.932-.518l-.26-.966zM2.429 4.74a1 1 0 10-.517 1.932l.966.259a1 1 0 00.517-1.932l-.966-.26zm8.814-.569a1 1 0 00-1.415-1.414l-.707.707a1 1 0 101.415 1.415l.707-.708zm-7.071 7.072l.707-.707A1 1 0 003.465 9.12l-.708.707a1 1 0 001.415 1.415zm3.2-5.171a1 1 0 00-1.3 1.3l4 10a1 1 0 001.823.075l1.38-2.759 3.018 3.02a1 1 0 001.414-1.415l-3.019-3.02 2.76-1.379a1 1 0 00-.076-1.822l-10-4z"
              clip-rule="evenodd"
            />
          </svg>
        </button>
        <template v-else>
          <button
            v-if="propertyIdParam"
            @click.prevent="addPropertyToDiagram"
            v-tooltip.bottom="'Add to diagram'"
            type="button"
            class="h-5 w-5 inline-flex justify-center items-center bg-white border border-gray-300 rounded-md text-xs text-gray-500 hover:text-gray-700"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-4 w-4"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM14 11a1 1 0 011 1v1h1a1 1 0 110 2h-1v1a1 1 0 11-2 0v-1h-1a1 1 0 110-2h1v-1a1 1 0 011-1z"
              />
            </svg>
          </button>
          <button
            @click.prevent="propertyPage"
            v-tooltip.bottom="
              propertyIdParam ? 'View by itself' : 'View diagram'
            "
            type="button"
            class="h-5 w-5 inline-flex justify-center items-center bg-white border border-gray-300 rounded-md text-xs text-gray-500 hover:text-gray-700"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-4 w-4"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z"
              />
            </svg>
          </button>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup>
import { QuestionMarkCircleIcon } from "@heroicons/vue/20/solid";
import { storeToRefs } from "pinia";
import DataField from "@/components/crowdsourcing/DataField.vue";
import api from "@/router/api";
import { computed, ref, onMounted } from "vue";
import { mapMarkers } from "@/assets/documentation/articles/mapMarkers";
import _ from "lodash";

const props = defineProps([
  "mapStore",
  "propertyDiagramStore",
  "changeGroupStore",
  "modalStore",
  "unlockerStore",
  "userStore",
  "tasksStore",
  "taskListStore",
  "reminderStore",
  "guestProfileStore",
  "layoutStore",
  "secondaryPanelStore",
  "documentationStore",
  "router",
  "parcelDataField",
]);
const {
  propertyDiagramSelected,
  propertyDiagramSelectedPropertyId,
  propertyDiagramPropertyIds,
  propertyIdParam,
} = storeToRefs(props.propertyDiagramStore);
const propertyRightDataField = ref(null);
const propertyId = computed(() => {
  return _.get(propertyRightDataField.value, "joiningContentId");
});
const alreadyInDiagram = computed(() => {
  return _.includes(propertyDiagramPropertyIds.value, propertyId.value);
});

onMounted(() => {
  let modalStoreTemp = props.modalStore;
  let unlockerStoreTemp = props.unlockerStore;
  let userStoreTemp = props.userStore;
  let tasksStore = props.tasksStore;
  let taskListStoreTemp = props.taskListStore;
  let reminderStoreTemp = props.reminderStore;
  let guestProfileStoreTemp = props.guestProfileStore;
  let layoutStore = props.layoutStore;
  let propertyDiagramStoreTemp = props.propertyDiagramStore;
  let changeGroupStoreTemp = props.changeGroupStore;
});

function getHelp() {
  props.documentationStore.viewArticle(mapMarkers, "Land parcel markers");
}

function refetchMap() {
  props.mapStore.tapView();
}
function propertyPage() {
  props.secondaryPanelStore.closeValidations();
  setTimeout(() => {
    props.propertyDiagramStore.navigateToDiagram(propertyId.value, true);
  }, 100);
}

function switchToDiagramProperty() {
  if (propertyId.value) {
    propertyDiagramSelectedPropertyId.value = propertyId.value;
    propertyDiagramSelected.value = {
      dataField: propertyRightDataField.value,
      recordType: "PropertyRight",
    };
  }
}
function addPropertyToDiagram() {
  if (propertyIdParam) {
    props.propertyDiagramStore.addPropertyToDiagram(propertyId.value);
    props.propertyDiagramStore.clearSelect(propertyId.value);
  }
}

async function fetchPropertyRightDataField() {
  api
    .get(`property_rights/${props.parcelDataField.decoratingContentId}`)
    .then((json) => {
      propertyRightDataField.value = json.data;
    });
}
</script>

<style>
.leaflet-popup-content {
  margin: 0;
}
</style>
