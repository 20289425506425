<template>
  <div v-if="actionableItem">
    <h2 class="sr-only">Summary</h2>
    <div class="rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5">
      <dl class="flex flex-col">
        <div class="flex justify-between">
          <div class="flex-auto pl-4 pt-4">
            <dt class="text-sm/6 font-semibold text-gray-900">
              Item {{ actionableItem.id }}
            </dt>
            <dd class="mt-1 text-base font-semibold text-gray-900">
              {{ _.startCase(actionableItem.packagingStatus) }}
            </dd>
          </div>
          <div class="px-4 pt-4 flex flex-col space-y-1 items-end">
            <button
              v-if="!activeEasyDataInputPayloadItem || closeable"
              @click="closePanel"
              type="button"
              v-tooltip="'Close'"
              class="inline-flex items-center text-gray-700 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
            >
              <XMarkIcon class="h-5 w-5" />
            </button>
            <dt class="sr-only">Status</dt>
            <dd
              v-if="actionableItem.deadline"
              class="rounded-md flex items-center space-x-0.5 bg-green-50 px-2 py-1 text-xs font-medium text-green-600 ring-1 ring-inset ring-green-600/20"
            >
              <span>Due</span>
              <time
                :datetime="moment(actionableItem.deadline).format('YYYY-MM-DD')"
                >{{ moment(actionableItem.deadline).fromNow() }}</time
              >
            </dd>
          </div>
        </div>
        <div class="mt-4 grid" :class="isAdmin ? 'grid-cols-1' : 'grid-cols-2'">
          <div class="space-y-2 py-2 border-t border-gray-900/5 px-4 pt-4">
            <div v-if="isAdmin" class="flex w-full flex-none gap-x-4">
              <dt v-tooltip="'Participants'" class="flex-none">
                <span class="sr-only">Participants</span>
                <UserCircleIcon
                  class="h-6 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </dt>
              <dd class="text-sm/6 font-medium text-gray-900 space-x-1">
                <span v-tooltip="'Referrer'"
                  >{{
                    actionableItem.referrer?.name || "No referrer"
                  }}&nbsp;&rarr;</span
                >
                <span v-tooltip="'Inputter'">{{
                  actionableItem.inputter?.name || "No inputter"
                }}</span>
              </dd>
            </div>
            <div class="flex w-full flex-none gap-x-4">
              <dt v-tooltip="'Data value'" class="flex-none">
                <span class="sr-only">Data Value</span>
                <SparklesIcon
                  class="h-6 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </dt>
              <dd class="text-sm/6 text-gray-500">
                {{ actionableItem.dataValue }}
              </dd>
            </div>
            <div class="flex w-full flex-none gap-x-4">
              <dt v-tooltip="'Input complexity'" class="flex-none">
                <span class="sr-only">Input Complexity</span>
                <PuzzlePieceIcon
                  class="h-6 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </dt>
              <dd class="text-sm/6 text-gray-500">
                {{ actionableItem.inputComplexity }}
              </dd>
            </div>
            <div
              v-if="actionablePreemptions.length > 0"
              class="flex w-full flex-none gap-x-4"
            >
              <dt v-tooltip="'Pre-existing Datapoints'" class="flex-none">
                <span class="sr-only">Pre-existing Datapoints</span>
                <CheckIcon class="h-6 w-5 text-gray-400" aria-hidden="true" />
              </dt>
              <dd class="flex flex-1 items-center space-x-2">
                <div
                  v-for="preemption in actionablePreemptions"
                  :key="`preemption-${preemption.id}`"
                  class="rounded-md shadow-sm"
                >
                  <div class="">
                    <div class="flex justify-between p-1 bg-white">
                      <div class="flex flex-1">
                        <div v-if="preemption.content" class="space-y-1">
                          <DataField
                            :data-field="preemption.content"
                            :safezone-tab="true"
                            text-classes="text-sm font-medium"
                          />
                          <div class="text-gray-500 text-xs pl-1">
                            {{ fieldType(preemption) }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </dd>
            </div>
          </div>
          <div
            v-if="!isAdmin"
            class="py-2 flex flex-col space-y-2 text-xs border-t border-gray-900/5 px-4 pt-4"
          >
            <div
              v-html="linkifiedItemNarrative"
              class="font-medium text-gray-500 whitespace-pre-line dont-break-out"
            />
            <a
              v-for="file in actionableItem.files"
              :key="`file-${file.id}`"
              href=""
              @click.prevent="viewEmbed(file)"
              v-tooltip="file.name"
              class="flex items-center p-1"
            >
              <div class="font-medium text-indigo-600 hover:text-indigo-500">
                <PaperClipIcon
                  class="h-4 w-4 flex-shrink-0"
                  aria-hidden="true"
                />
              </div>
            </a>
          </div>
        </div>
      </dl>
      <div
        v-if="!localItem && !popUp"
        class="mt-4 flex items-center space-x-3 border-t border-gray-900/5 px-4 py-4"
      >
        <template v-if="isAdmin">
          <a
            @click.prevent
            href=""
            class="text-sm/6 font-semibold text-gray-900"
            >Action? <span aria-hidden="true">&rarr;</span></a
          >
        </template>
        <template v-else>
          <EasyDataActiveInputPayloadPauseSwitch label-color="text-amber-600" />
          <button
            @click="markComplete"
            type="button"
            class="rounded-md bg-amber-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-amber-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-amber-600"
          >
            Complete
          </button>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  PaperClipIcon,
  PuzzlePieceIcon,
  SparklesIcon,
  UserCircleIcon,
  XMarkIcon,
  CheckIcon,
} from "@heroicons/vue/20/solid";
import DataField from "@/components/crowdsourcing/DataField.vue";
import EasyDataActiveInputPayloadPauseSwitch from "@/components/users/EasyDataActiveInputPayloadPauseSwitch.vue";
import { ref, computed, watch, onMounted, nextTick } from "vue";
import { storeToRefs } from "pinia";
import { useUserStore } from "@/stores/user";
import { useUploadedFileStore } from "@/stores/uploadedFile";
import { useModalStore } from "@/stores/modal";
import { useEasyDataInputAdminStore } from "@/stores/easyDataInputAdmin";
import linkifyHtml from "linkify-html";
import api from "@/router/api";
import _ from "lodash";
import moment from "moment";

const props = defineProps(["localItem", "closeable", "popUp", "preemptions"]);
const emit = defineEmits(["close-panel"]);

const uploadedFileStore = useUploadedFileStore();
const modalStore = useModalStore();
const { confirmationPayload } = storeToRefs(modalStore);
const userStore = useUserStore();
const { activeEasyDataInputPayloadItem, isAdmin } = storeToRefs(userStore);
const easyDataInputAdminStore = useEasyDataInputAdminStore();
const { selectedItem } = storeToRefs(easyDataInputAdminStore);

const displayDatapointsTable = ref(true);
const actionableItem = computed(() => {
  if (isAdmin.value) {
    return selectedItem.value;
  } else {
    return props.localItem || activeEasyDataInputPayloadItem.value;
  }
});
const actionableItemId = computed(() => actionableItem.value?.id);
const actionablePreemptions = computed(
  () => props.preemptions || actionableItem.value?.preemptions || [],
);

const itemFields = ref([]);

const linkifiedItemNarrative = computed(() => {
  return linkifyHtml(
    `${actionableItem.value?.narrative || actionableItem.value?.id || ""}`,
    {
      defaultProtocol: "https",
      target: "_blank",
      className: "font-medium text-indigo-600 hover:text-indigo-500",
    },
  );
});

watch(actionableItemId, () => {
  if (actionableItem.value) {
    fetchFields();
  }
});

onMounted(() => {
  if (actionableItem.value) {
    fetchFields();
  }
});

async function fetchFields() {
  if (actionableItem.value) {
    displayDatapointsTable.value = false;
    const response = await api.get(
      `payload_item_data_fields/${actionableItem.value?.id}`,
    );

    if (response?.data) {
      itemFields.value = response.data;
      await nextTick();
      displayDatapointsTable.value = true;
    }
  }
}

function viewEmbed(file) {
  uploadedFileStore.viewEmbed(file);
}

function fieldType(preemption) {
  const field = preemption.content;
  if (field) {
    if (field.fieldContentType) {
      return _.startCase(field.fieldContentType);
    } else if (field?.fieldName) {
      return _.startCase(field.fieldName);
    } else {
      return "Unknown field type";
    }
  } else {
    return "Unknown content type";
  }
}

async function markComplete() {
  if (activeEasyDataInputPayloadItem.value) {
    confirmationPayload.value = {
      title: "Mark Job Complete",
      message:
        "This declares to Tower Hunt that you are finished adding the requested datapoints. You will not be able to link more datapoints to the job. Are you sure?",
      affirmText: "Complete",
      affirmCallback: async () => {
        const response = await api.patch(`payload_item_inputter_completions`);

        if (response?.data) {
          closePanel();
        }
      },
    };
  }
}

function closePanel() {
  if (isAdmin.value) {
    selectedItem.value = null;
  } else if (props.localItem) {
    emit("close-panel");
  }
}
</script>
