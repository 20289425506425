<template>
  <div class="h-full flex flex-col">
    <nav
      class="p-2 bg-gray-50 flex items-center justify-between"
      aria-label="Breadcrumb"
    >
      <div class="flex items-center space-x-2">
        <a @click.prevent="backToLists" href="" class="flex items-center">
          <ChevronLeftIcon
            class="h-5 w-5 flex-shrink-0 text-indigo-400"
            aria-hidden="true"
          />
          <span
            class="text-sm font-medium text-indigo-500 hover:text-indigo-700"
            >Lists</span
          >
        </a>
      </div>
      <h3
        v-if="!mainTitleVisible"
        class="flex min-w-0 flex-1 items-center justify-center font-medium text-gray-900"
      >
        <template v-if="batchSelection && batchTasks.length > 0">
          {{ batchTasks.length }} Selected
        </template>
        <template v-else-if="batchSelection"> Select Reminders </template>
        <template v-else> Scheduled </template>
      </h3>
      <div class="flex items-center space-x-3">
        <VDropdown v-if="!batchSelection" class="flex items-center">
          <button
            type="button"
            class="inline-flex justify-center p-0.5 items-center rounded-full border border-indigo-400 text-sm text-indigo-500 hover:text-indigo-700"
            data-test="task-list-options-button`"
          >
            <EllipsisHorizontalIcon class="h-4 w-4" />
          </button>

          <template #popper>
            <div
              class="w-64 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="menu-button"
              tabindex="-1"
            >
              <div class="py-1" role="none">
                <a
                  @click.prevent="batchSelection = true"
                  href=""
                  class="text-gray-700 hover:bg-gray-100 hover:text-gray-900 group flex items-center px-4 py-2 text-sm"
                  role="menuitem"
                  tabindex="-1"
                >
                  Select Reminders
                </a>
                <a
                  @click.prevent="toggleShowCompleted"
                  href=""
                  class="text-gray-700 hover:bg-gray-100 hover:text-gray-900 group flex items-center px-4 py-2 text-sm"
                  role="menuitem"
                  tabindex="-1"
                >
                  {{ scheduledViewShowCompleted ? "Hide" : "Show" }}
                  Completed
                </a>
              </div>
            </div>
          </template>
        </VDropdown>
        <a
          v-if="addingInlineTask || selectedTask"
          @click.prevent="add"
          href=""
          id="inline-reminder-form-done-button"
          class="flex items-center text-sm font-bold text-indigo-500"
        >
          Done
        </a>
        <a
          v-else-if="batchSelection"
          @click.prevent="resetBatch"
          href=""
          class="flex items-center text-sm font-bold text-indigo-500"
        >
          Done
        </a>
      </div>
    </nav>

    <div class="p-2 flex flex-col flex-grow overflow-y-auto">
      <div class="lg:flex lg:items-center lg:justify-between">
        <div class="min-w-0 flex-1">
          <h2
            v-observe-visibility="pageTitleVisibilityChanged"
            class="mt-2 text-xl font-bold leading-6 sm:truncate sm:text-2xl sm:tracking-tight text-red-500"
          >
            <template v-if="batchSelection && batchTasks.length > 0">
              {{ batchTasks.length }} Selected
            </template>
            <template v-else-if="batchSelection"> Select Reminders </template>
            <template v-else> Scheduled </template>
          </h2>
        </div>
      </div>

      <TaskBatchActions v-if="isDesktop && batchSelection" />
      <div class="mt-2 flex-grow space-y-2">
        <ul
          v-for="(timeObject, index) in scheduleTimes"
          :key="timeObject.name"
          role="list"
          class="flex flex-col space-y-1"
        >
          <h2
            v-if="timeObject.importance === 'major'"
            class="text-lg font-bold leading-5 sm:truncate sm:text-xl sm:tracking-tight text-gray-700"
          >
            {{ timeObject.name }}
          </h2>
          <h2
            v-else-if="timeObject.importance === 'submajor'"
            @click="addInlineTaskFor(timeObject)"
            class="text-lg font-bold leading-5 sm:truncate sm:text-xl sm:tracking-tight text-gray-500"
          >
            {{ timeObject.name }}
          </h2>
          <h3
            v-else
            @click="addInlineTaskFor(timeObject)"
            class="text-sm font-medium leading-4 sm:truncate sm:text-base sm:tracking-tight text-gray-500"
          >
            {{ timeObject.name }}
          </h3>
          <ul
            v-for="(tasks, dateLabel) in groupedTasksFor(timeObject, index)"
            :key="dateLabel"
            role="list"
            class="flex flex-col"
          >
            <h4
              v-if="
                timeObject.importance !== 'minor' && timeObject.name !== 'Today'
              "
              class="text-xs sm:truncate sm:text-sm sm:tracking-tight text-gray-500"
            >
              {{ dateLabel }}
            </h4>
            <SelectableTask
              v-for="task in tasks"
              :key="task.id"
              :task="task"
              context="scheduled"
              @completion-toggled="patch"
              @refetch-for-repeat="fetchTasks"
            />
            <InlineReminderForm
              v-if="
                addingInlineTask === dateLabel && dateLabel !== timeObject.name
              "
              :grouped-list="remindersList"
              :time-object="timeObject"
              @saved="patch"
            />
            <div
              v-else-if="timeObject.importance !== 'major' && !addingInlineTask"
              @click="addInlineTaskFor(timeObject, dateLabel)"
              class="block hover:bg-gray-100"
            >
              <div class="flex items-start p-2">
                <div class="flex min-w-0 flex-1 items-start">
                  <div
                    class="flex-shrink-0 flex items-center justify-center h-6 w-6 rounded-full border border-dashed border-gray-400"
                  />
                </div>
              </div>
            </div>
          </ul>
          <InlineReminderForm
            v-if="addingInlineTask === timeObject.name"
            :grouped-list="remindersList"
            :time-object="timeObject"
            @saved="patch"
          />
          <div
            v-else-if="
              timeObject.importance === 'major' &&
              timeObject.name !== 'Past Due' &&
              !addingInlineTask
            "
            @click="addInlineTaskFor(timeObject)"
            class="block hover:bg-gray-100"
          >
            <div class="flex items-start p-2">
              <div class="flex min-w-0 flex-1 items-start">
                <div
                  class="flex-shrink-0 flex items-center justify-center h-6 w-6 rounded-full border border-dashed border-gray-400"
                />
              </div>
            </div>
          </div>
        </ul>
        <InfiniteLoading
          v-if="signedIn && serverSideTasks.pagy?.next"
          @infinite="loadTasks"
        />
      </div>
    </div>

    <TaskBatchActions v-if="!isDesktop && batchSelection" />
  </div>
</template>

<script setup>
import {
  ChevronLeftIcon,
  EllipsisHorizontalIcon,
} from "@heroicons/vue/20/solid";
import { useTasksStore } from "@/stores/tasks";
import { useUserStore } from "@/stores/user";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { useReminderStore } from "@/stores/reminder";
import { ref, computed, onMounted } from "vue";
import { storeToRefs } from "pinia";
import TaskBatchActions from "@/components/tasks/TaskBatchActions.vue";
import InlineReminderForm from "@/components/tasks/InlineReminderForm.vue";
import SelectableTask from "@/components/tasks/SelectableTask.vue";
import { useRoute, useRouter } from "vue-router";
import api from "@/router/api";
import moment from "moment";
import scheduleTimesCollection from "@/components/tasks/scheduleTimes";
import _ from "lodash";

const tasksStore = useTasksStore();
const {
  addingInlineTask,
  batchSelection,
  batchTasks,
  selectedTask,
  effectiveTasks,
  effectiveTaskLists,
  scheduledViewShowCompleted,
} = storeToRefs(tasksStore);
const layoutStore = useWorkspaceLayoutStore();
const { isDesktop } = storeToRefs(layoutStore);
const userStore = useUserStore();
const { signedIn } = storeToRefs(userStore);
const reminderStore = useReminderStore();
const { list, usesTiming, date, time } = storeToRefs(reminderStore);

const serverSideTasks = ref({
  data: [],
  pagy: null,
});
const mainTitleVisible = ref(true);
const remindersList = computed(() =>
  _.find(effectiveTaskLists.value, { name: "Reminders" })
);
const scheduleTimes = computed(() => scheduleTimesCollection());

const scheduledTasks = computed(() => {
  let collection = [];
  if (signedIn.value) {
    collection = serverSideTasks.value.data;
  } else {
    collection = effectiveTasks.value;
  }

  return collection.filter((task) => {
    if (scheduledViewShowCompleted.value) {
      return !!task.dueDate;
    } else {
      return !!task.dueDate && !task.completedAt;
    }
  });
});
function groupedTasksFor(timeObject, index) {
  let includedTasks = [];

  if (index === 0) {
    includedTasks = scheduledTasks.value.filter((task) => {
      return momentDueDateFor(task).isBefore(moment(), "day");
    });
  } else {
    let startOfUnit;
    if (timeObject.importance === "major" && timeObject.unit === "month") {
      startOfUnit = timeObject.date.clone().startOf("day");
    } else {
      startOfUnit = timeObject.date.clone().startOf(timeObject.unit);
    }
    const endOfUnit = timeObject.date.clone().endOf(timeObject.unit);
    // console.log(timeObject.name, startOfUnit.format(), endOfUnit.format());
    includedTasks = scheduledTasks.value.filter((task) => {
      return momentDueDateFor(task).isBetween(
        startOfUnit,
        endOfUnit,
        undefined,
        "[]"
      );
    });
  }

  return _.groupBy(includedTasks, timingGrouper);
}

function timingGrouper(task) {
  const momentDate = momentDueDateFor(task);

  return momentDate.format("ddd MMM D");
}
function momentDueDateFor(task) {
  if (signedIn.value) {
    return moment.unix(task.dueDate);
  } else {
    return moment(task.dueDate);
  }
}

onMounted(() => fetchTasks());

async function fetchTasks() {
  if (signedIn.value) {
    api.get(`scheduled_tasks`).then((json) => {
      serverSideTasks.value = json.data;
    });
  }
}
function patch(task) {
  if (signedIn.value) {
    serverSideTasks.value.data = _.unionBy(
      [task],
      serverSideTasks.value.data,
      "id"
    );
  }
}
function cleanUrl(url) {
  return url.replace("/api/v1/", "");
}
const loadTasks = async ($state) => {
  if (signedIn.value && serverSideTasks.value.pagy?.next) {
    const endpoint = cleanUrl(serverSideTasks.value.pagy.next_url);
    try {
      api.post(endpoint).then((json) => {
        const { data, pagy } = json.data;

        serverSideTasks.value.data = _.unionBy(
          serverSideTasks.value.data,
          data,
          "id"
        );
        serverSideTasks.value.pagy = pagy;
        if (data.length < 25) $state.complete();
        else {
          $state.loaded();
        }
      });
    } catch (error) {
      $state.error();
    }
  } else {
    $state.complete();
  }
};

const router = useRouter();
const route = useRoute();

function clearTaskViewQuery() {
  router.push({
    name: route.name,
    query: {
      ...route.query,
      taskView: undefined,
    },
  });
}

function pageTitleVisibilityChanged(isVisible) {
  mainTitleVisible.value = isVisible;
}
function resetBatch() {
  tasksStore.resetBatch();
}
function backToLists() {
  tasksStore.backToLists();
  clearTaskViewQuery();
}
function toggleShowCompleted() {
  if (signedIn.value) {
    if (scheduledViewShowCompleted.value) {
      api
        .post(`user_task_views_meta`, {
          attributeName: "scheduled_tasks_view_show_completed",
          attributeValue: false,
        })
        .then(() => {
          scheduledViewShowCompleted.value = false;
          fetchTasks();
        });
    } else {
      api
        .post(`user_task_views_meta`, {
          attributeName: "scheduled_tasks_view_show_completed",
          attributeValue: true,
        })
        .then(() => {
          scheduledViewShowCompleted.value = true;
          fetchTasks();
        });
    }
  } else {
    scheduledViewShowCompleted.value = !scheduledViewShowCompleted.value;
  }
}
function add() {
  reminderStore.createTask().then(async (json) => {
    if (json) {
      const task = json.data;
      tasksStore.patchTasks([task]);
      patch(task);
    }
    reminderStore.reset();
    addingInlineTask.value = false;
    selectedTask.value = null;
  });
}
function addInlineTaskFor(timeObject, dateLabel = null) {
  if (!addingInlineTask.value && !selectedTask.value && !batchSelection.value) {
    addingInlineTask.value = dateLabel || timeObject.name;
    list.value = remindersList.value;
    usesTiming.value = true;
    date.value = timeObject.date.format("YYYY-MM-DD");
    time.value = timeObject.date.format("kk:mm");
  }
}
</script>
