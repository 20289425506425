<template>
  <nav class="flex flex-col" aria-label="Key investment dates">
    <dt class="text-sm font-medium text-gray-500">
      {{ editingState ? "Current Milestone" : "Milestones" }}
    </dt>
    <ol
      v-if="loaded && (state || unlockableState)"
      role="list"
      class="mt-2 space-y-4"
    >
      <ContentMilestoneDropdown
        v-if="editingState"
        :investment-group-id="investmentGroupId"
        :existing-state="state"
        :parent-selected-value="selectedValue"
        @set-selected-value="setSelectedValue"
        @unlocked="unlocked"
        @completed="completed"
        @cancel="cancel"
      />
      <template v-else>
        <InvestmentGroupKeyDate
          v-for="optionObj in dropdownOptions"
          :key="optionObj.value"
          :investment-group-id="investmentGroupId"
          :investment-state="state || unlockableState"
          :milestone-object="optionObj"
          :all-milestones="dropdownOptions"
          :timing-fields="timingFields"
          :timing-unlockable="timingUnlockable"
          @unlocked="unlocked"
          @completed="completed"
          @suggest-state-change="suggestStateChange"
        />
      </template>
    </ol>
    <div v-else-if="loaded && timingUnlocked" class="mt-4 text-center">
      <svg
        class="mx-auto h-8 w-8 text-gray-400"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
        />
      </svg>
      <h3 class="mt-2 text-sm font-medium text-gray-900">
        Multiple dates/states
      </h3>
      <p class="mt-1 text-sm text-gray-500">
        The investments don't all share the same state. Please edit via the
        asset list.
      </p>
    </div>
    <div
      v-else-if="loaded && _.size(timingFields) > 0"
      class="mt-4 text-center"
    >
      <svg
        class="mx-auto h-8 w-8 text-gray-400"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
        />
      </svg>
      <h3 class="mt-2 text-sm font-medium text-gray-900">
        Unlockable dates/states
      </h3>
      <p class="mt-1 text-sm text-gray-500">
        All timing datapoints need to be unlocked. Please check the asset list.
      </p>
    </div>
  </nav>
</template>

<script setup>
import ContentMilestoneDropdown from "@/components/crowdsourcing/ContentMilestoneDropdown.vue";
import InvestmentGroupKeyDate from "@/components/deal-builder/InvestmentGroupKeyDate.vue";
import { ref, computed, watch, onMounted } from "vue";
import _ from "lodash";
import api from "@/router/api";
import fullyUnlocked from "@/components/crowdsourcing/fullyUnlocked";
import milestones from "@/assets/contentMilestones";

const props = defineProps([
  "investmentGroupId",
  "timingFields",
  "timingUnlockable",
]);
const emit = defineEmits(["refetch", "unlocked"]);

const loaded = ref(false);
const state = ref(null);
const unlockableState = computed(() => {
  if (props.timingUnlockable) {
    const uniqueFields = _.uniqBy(props.timingFields, "fieldName");
    return _.head(uniqueFields.filter((field) => field.fieldName === "state"));
  } else {
    return null;
  }
});
const editingState = ref(false);
const selectedValue = ref(null);
const dropdownOptions = computed(() => {
  let matchingOptions = _.get(milestones, "Investment", []);

  return _.sortBy(matchingOptions, ["order"]);
});
const timingUnlocked = computed(() => {
  return _.size(props.timingFields) > 0 && fullyUnlocked(props.timingFields);
});

watch(
  () => props.investmentGroupId,
  (id, oldId) => {
    if (id !== oldId) {
      reset();
      fetchState();
    }
  },
);
onMounted(() => {
  fetchState();
});

function reset() {
  state.value = null;
  editingState.value = false;
  selectedValue.value = null;
}
function suggestStateChange() {
  setSelectedValue();
  editingState.value = true;
}
function setSelectedValue(newVal) {
  if (newVal) {
    selectedValue.value = newVal;
  } else if (state.value) {
    selectedValue.value = _.find(dropdownOptions.value, {
      value: state.value,
    }).value;
  } else {
    selectedValue.value = _.head(dropdownOptions.value).value;
  }
}
function unlocked(maybePayload) {
  emit("unlocked", maybePayload);
}
function completed(maybePayload) {
  if (maybePayload?.dataField) {
    cancel();
    // this.$store.dispatch("flash", "Saved!");
    fetchState(true);
  }
}
function cancel() {
  selectedValue.value = null;
  editingState.value = false;
}
function fetchState(refetch = false) {
  loaded.value = false;

  api.get(`investment_group_states/${props.investmentGroupId}`).then((json) => {
    state.value = json.data;
    loaded.value = true;

    if (refetch) {
      emit("refetch");
    }
  });
}
</script>
