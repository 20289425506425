<template>
  <div class="w-full">
    <DataField
      v-if="companyField"
      :data-field="companyField"
      primary-text-path="fieldContent.name"
      class="flex items-center"
      text-classes="font-medium"
      text-styles=""
      :analyze="true"
    />
    <div v-else class="flex items-center space-x-2">
      <div
        :class="`px-1 py-0.5 rounded-md inline-flex items-center space-x-1 text-left border-2 border-white font-medium text-gray-900 bg-gray-200 underline decoration-2 decoration-dashed decoration-gray-700`"
      >
        <LockClosedIcon class="h-3 w-3" />
        <span>Unlockable</span>
      </div>
      <UnlockBundles
        context="nearby-company"
        :override-records="involvements"
        override-label="Nearby companies"
        @unlocked="nearbyCompaniesStore.fetchCompanies()"
      />
    </div>
    <div class="mt-2 flex flex-wrap">
      <template v-if="propertyGrouped">
        <NearbyInvolvement
          v-for="(involvements, propertyId) in propertyGrouped"
          :key="propertyId"
          :involvement="_.head(involvements)"
          :grouped="involvements.length > 1" />
        <NearbyInvolvement
          v-for="involvement in hunts"
          :key="involvement.localId"
          :involvement="involvement" />
        <NearbyInvolvement
          v-for="involvement in huntAdvisories"
          :key="involvement.localId"
          :involvement="involvement"
      /></template>
      <template v-else>
        <NearbyInvolvement
          v-for="involvement in involvements"
          :key="involvement.localId"
          :involvement="involvement"
        />
      </template>
    </div>
  </div>
</template>

<script setup>
import DataField from "@/components/crowdsourcing/DataField.vue";
import UnlockBundles from "@/components/crowdsourcing/UnlockBundles.vue";
import NearbyInvolvement from "@/components/company-detail/NearbyInvolvement.vue";
import { computed, onMounted } from "vue";
import { useNearbyCompaniesStore } from "@/stores/nearbyCompanies";
import { LockClosedIcon } from "@heroicons/vue/20/solid";
import _ from "lodash";

const props = defineProps([
  "companyId",
  "involvements",
  "hunts",
  "huntAdvisories",
  "propertyGrouped",
]);

const nearbyCompaniesStore = useNearbyCompaniesStore();
const companyField = computed(() =>
  nearbyCompaniesStore.companyFieldFor(props.companyId),
);

onMounted(() => {
  nearbyCompaniesStore.fetchCompany(props.companyId);
});
</script>
