<template>
  <div
    class="flex flex-1 flex-col"
    :class="autoCardSize ? '' : 'shadow-lg'"
    :style="cardStyle"
  >
    <div v-if="displayable || stackIndex !== cardIndex" class="w-full h-full">
      <FlippableCardContainer
        :flipped="displayBack && !!cardBack"
        :is-top-card="cardIndex === stackIndex || preview"
        :card-back-component="cardBack"
        :card-back-props="cardContentProps"
        :card-front-component="cardFront"
        :card-front-props="cardContentProps"
        :context="context"
        @set-card-back="setCardBack"
        @discard="emit('discard')"
        @accept="emit('accept')"
      />
    </div>
  </div>
</template>

<script setup>
import ComingSoon from "@/components/ComingSoon.vue";
import FlippableCardContainer from "@/components/collectible-cards/FlippableCardContainer.vue";
import { ref, computed, markRaw } from "vue";
import PayloadItemInputOpportunity from "@/components/collectible-cards/categories/easy-data-input/PayloadItemInputOpportunity.vue";
import UserGuideWelcomeCard from "@/components/collectible-cards/categories/user-guide/UserGuideWelcomeCard.vue";
import MeteredPricingCard from "@/components/collectible-cards/categories/user-guide/MeteredPricingCard.vue";
import NoPerSeatLicenseCard from "@/components/collectible-cards/categories/user-guide/NoPerSeatLicenseCard.vue";
import RevenueSharingCard from "@/components/collectible-cards/categories/user-guide/RevenueSharingCard.vue";
import MixPublicPrivateCard from "@/components/collectible-cards/categories/user-guide/MixPublicPrivateCard.vue";
import DetailsYouDreamAboutCard from "@/components/collectible-cards/categories/user-guide/DetailsYouDreamAboutCard.vue";
import DataCoverageCard from "@/components/collectible-cards/categories/content-summary/DataCoverageCard.vue";
import TaskDueCard from "@/components/collectible-cards/categories/task-due/TaskDueCard.vue";
import AvailableValidationsCard from "@/components/collectible-cards/categories/validation-pipeline/AvailableValidationsCard.vue";
import TaskListSharingCard from "@/components/collectible-cards/categories/task-list/TaskListSharingCard.vue";
import NewTeamMembershipCard from "@/components/collectible-cards/categories/team-membership/NewTeamMembershipCard.vue";
import SafezoneSharingCard from "@/components/collectible-cards/categories/safezone/SafezoneSharingCard.vue";
import GeneralizedLocationCard from "@/components/collectible-cards/categories/news/GeneralizedLocationCard.vue";
import SuccessfulContributionsCard from "@/components/collectible-cards/categories/validation-pipeline/SuccessfulContributionsCard.vue";
import ValidationSubmissionsCard from "@/components/collectible-cards/categories/validation-pipeline/ValidationSubmissionsCard.vue";
import StaleUnpublishedContributionsCard from "@/components/collectible-cards/categories/validation-pipeline/StaleUnpublishedContributionsCard.vue";
import ContentStateTransitionAlertCard from "@/components/collectible-cards/categories/news/ContentStateTransitionAlertCard.vue";

const props = defineProps([
  "autoCardSize",
  "card",
  "displayable",
  "flipped",
  "licensed",
  "cardIndex",
  "stackIndex",
  "completedCardIds",
  "context",
  "preview",
  "generic",
  "timelineOverrideField",
]);
const emit = defineEmits(["accept", "discard", "flip"]);

const cardBackContent = ref(null);
const cardBack = computed(() => {
  if (cardBackContent.value) {
    return markRaw(cardBackContent.value);
  } else {
    return null;
  }
});
const displayBack = computed(() => {
  return props.flipped || props.licensed || props.preview;
});

const cardStyle = computed(() => {
  if (props.autoCardSize) {
    return "";
  } else {
    return "width: 330px; height: 475px;";
  }
});
const cardFront = computed(() => markRaw(cardContent.value));
const cardContent = computed(() => {
  switch (props.card.category) {
    case "marketing":
      switch (props.card.staticContentId) {
        case "welcome":
          return UserGuideWelcomeCard;
        case "metered_pricing":
          return MeteredPricingCard;
        case "no_per_seat_licensing":
          return NoPerSeatLicenseCard;
        case "revenue_sharing":
          return RevenueSharingCard;
        case "mix_public_private":
          return MixPublicPrivateCard;
        case "details_you_dream_about":
          return DetailsYouDreamAboutCard;
        default:
          return ComingSoon;
      }
    case "user_guide":
      switch (props.card.staticContentId) {
        default:
          return ComingSoon;
      }
    case "data_input_payload_item":
      return PayloadItemInputOpportunity;
    case "task_due":
      return TaskDueCard;
    case "content_state_transition_alert":
      return ContentStateTransitionAlertCard;
    case "safezone_sharing":
      return SafezoneSharingCard;
    case "task_list_sharing":
      return TaskListSharingCard;
    case "team_membership":
      return NewTeamMembershipCard;
    case "data_coverage":
      return DataCoverageCard;
    case "generalized_location_news":
      return GeneralizedLocationCard;
    case "validation_submission":
      return ValidationSubmissionsCard;
    case "successful_contribution":
      return SuccessfulContributionsCard;
    case "validation":
      switch (props.card.staticContentId) {
        case "available_validations":
          return AvailableValidationsCard;
        case "stale_contributions":
          return StaleUnpublishedContributionsCard;
        default:
          return ComingSoon;
      }
    default:
      return ComingSoon;
  }
});
const cardContentProps = computed(() => {
  return {
    card: props.card,
    cardIndex: props.cardIndex,
    stackIndex: props.stackIndex,
    completedCardIds: props.completedCardIds,
    displayBack: displayBack.value,
    generic: props.generic || false,
    context: props.context,
    timelineOverrideField: props.timelineOverrideField,
  };
});

function setCardBack(component) {
  cardBackContent.value = markRaw(component);

  if (props.preview) {
    emit("flip");
  }
}
</script>
