<template>
  <main class="h-full flex flex-col divide-y divide-gray-200">
    <div class="flex items-center justify-between p-2">
      <div class="flex items-center space-x-2">
        <a
          v-if="queryCategoryId || queryArticleId"
          href=""
          @click.prevent="returnToHelpTabShell"
        >
          <ChevronLeftIcon
            class="h-5 w-5 text-gray-900 transition ease-in-out delay-50 hover:scale-125 duration-100"
          />
        </a>
        <h1 class="text-xl leading-6 font-bold text-gray-900">Help</h1>
      </div>
      <div class="flex items-center space-x-1">
        <a
          v-if="false"
          href=""
          @click.prevent="documentationStore.viewArticle(keyboardShortcuts)"
          v-tooltip="'Keyboard shortcuts'"
          data-test="`keyboard-shortcuts-button`"
          class="p-1.5 inline-flex justify-center items-center text-lg text-gray-500 hover:text-gray-700"
        >
          <i class="fa-regular fa-keyboard" />
        </a>
        <a
          v-if="false"
          href=""
          @click.prevent="documentationStore.viewArticle(changeLog)"
          v-tooltip="'What\'s new'"
          data-test="`whats-new-button`"
          class="p-1.5 inline-flex justify-center items-center text-gray-500 hover:text-gray-700"
        >
          <GiftIcon class="h-5 w-5" aria-hidden="true" />
        </a>
      </div>
    </div>

    <HelpTabCategory v-if="queryCategoryId" :category-id="queryCategoryId" />
    <div v-else-if="queryArticleId === 'changelog'">
      Tower Hunt updates and changes
    </div>
    <HelpTabArticle v-else-if="queryArticleId" :article-id="queryArticleId" />
    <HelpTabShell v-else />
  </main>
</template>

<script setup>
import { ChevronLeftIcon, GiftIcon } from "@heroicons/vue/24/outline";
import { changeLog } from "@/assets/documentation/articles/changeLog";
import { keyboardShortcuts } from "@/assets/documentation/articles/keyboardShortcuts";
import { useDocumentationStore } from "@/stores/documentation";
import HelpTabArticle from "@/components/help-center/HelpTabArticle.vue";
import HelpTabCategory from "@/components/help-center/HelpTabCategory.vue";
import HelpTabShell from "@/components/help-center/HelpTabShell.vue";
import { storeToRefs } from "pinia";
import { useRoute, useRouter } from "vue-router";
import { computed } from "vue";
import _ from "lodash";

const documentationStore = useDocumentationStore();
const { articles, queryArticleId, queryCategoryId } =
  storeToRefs(documentationStore);
const router = useRouter();
const route = useRoute();

const categoryId = computed(() => {
  if (queryArticleId.value) {
    const article = _.find(articles.value, function (articleObj) {
      return _.lowerCase(articleObj.name) === _.lowerCase(queryArticleId.value);
    });
    return documentationStore.categoryFor(article)?.id;
  } else {
    return undefined;
  }
});

function returnToHelpTabShell() {
  router.push({
    name: route.name,
    query: {
      ...route.query,
      articleId: undefined,
      categoryId: categoryId.value,
    },
  });
}
</script>
