<template>
  <div class="h-full rounded-lg overflow-hidden">
    <CardBranding :card="card" />
    <div class="absolute inset-0">
      <div
        class="absolute inset-0 bg-gradient-to-r from-blue-800 to-sky-600 mix-blend-multiply"
      />
    </div>
    <div class="h-full relative px-4 py-16 flex flex-col justify-center">
      <h1 class="text-center text-4xl font-bold tracking-tight">
        <span class="block text-white">Task</span>
        <span class="block text-sky-200">Due</span>
      </h1>
      <p class="mx-auto mt-6 max-w-lg text-center text-lg text-sky-200">
        {{ task?.name || "Title" }}
      </p>
      <p
        v-if="task?.contentType"
        class="mt-6 flex items-center justify-center space-x-2"
      >
        <span class="text-sky-200">Linked to:</span>
        <TaskContent :task="task" />
      </p>
      <div v-if="task" class="mx-auto mt-10 max-w-sm flex justify-center">
        <div class="space-y-4">
          <a
            href=""
            @click.prevent="viewTask"
            class="flex items-center justify-center rounded-md border border-transparent bg-white px-4 py-3 text-base font-medium text-sky-700 shadow-sm hover:bg-sky-50 sm:px-8"
            >View task</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import CardBranding from "@/components/collectible-cards/CardBranding.vue";
import TaskContent from "@/components/tasks/TaskContent.vue";
import api from "@/router/api";
import { ref, computed, watch, onMounted } from "vue";
import _ from "lodash";
import { useRoute, useRouter } from "vue-router";
import { useMobileNavigationStore } from "@/stores/mobileNavigation";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { storeToRefs } from "pinia";

const props = defineProps([
  "card",
  "cardIndex",
  "stackIndex",
  "completedCardIds",
]);

const mobileNavigationStore = useMobileNavigationStore();
const { selectedMobileTab } = storeToRefs(mobileNavigationStore);
const layoutStore = useWorkspaceLayoutStore();
const { isDesktop } = storeToRefs(layoutStore);

const cardContents = ref([]);
const task = computed(() => _.head(cardContents.value)?.content);

watch(
  () => props.stackIndex,
  () => {
    if (!task.value) fetchTopCardTask();
  }
);

onMounted(() => {
  fetchTopCardTask();
});

function fetchTopCardTask() {
  if (
    props.stackIndex === props.cardIndex &&
    !_.includes(props.completedCardIds, props.card.id)
  ) {
    api
      .get(
        `collectible_cards/${props.card.collectibleCardId}/collectible_card_contents`
      )
      .then((json) => {
        cardContents.value = json.data;
      });
  }
}

const router = useRouter();
const route = useRoute();

function viewTask() {
  if (task.value) {
    router.push({
      name: route.name,
      query: {
        ...route.query,
        horizontalTab: "Me",
        verticalTab: "Tasks",
        relatedTaskIds: [task.value.id],
      },
    });
    if (!isDesktop.value) {
      selectedMobileTab.value = "Tasks";
    }
  }
}
</script>
