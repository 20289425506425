import { ref } from "vue";
import { defineStore, acceptHMRUpdate } from "pinia";
import _ from "lodash";

export const useTasksChannelStore = defineStore("tasksChannel", () => {
  const TasksChannel = ref(null);
  const tasksChannelDataQueue = ref([]);

  function pushAndTrim(data) {
    tasksChannelDataQueue.value.push(data);
    tasksChannelDataQueue.value = _.takeRight(tasksChannelDataQueue.value, 3);
  }

  return {
    TasksChannel,
    tasksChannelDataQueue,
    pushAndTrim,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useTasksChannelStore, import.meta.hot)
  );
}
