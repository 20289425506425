import IncludeAirRights from "@/components/analyze/calculations/options/IncludeAirRights.vue";
import PerAreaDenominator from "@/components/analyze/calculations/options/PerAreaDenominator.vue";
import ExcludedUses from "@/components/analyze/calculations/options/ExcludedUses.vue";
import IncludedValueTypes from "@/components/analyze/calculations/options/IncludedValueTypes.vue";
import pluralize from "pluralize";

export default function calculationOptionsFor(calculationName, optionsData) {
  const operableOptions = optionsData?.calculationOptions || optionsData;
  const excludedUsesCount =
    operableOptions?.excludedUses?.useTypes?.length || 0;
  const excludedUses = {
    label: pluralize("excluded use", excludedUsesCount, true),
    description: "Remove floor area with selected use(s) from the total.",
    component: ExcludedUses,
    orientation: "y",
  };
  const denominator = {
    label:
      excludedUsesCount > 0 ? "Denominator (see exclusions)" : "Denominator",
    description: "Select a size and unit type.",
    component: PerAreaDenominator,
    orientation: "y",
  };

  switch (calculationName) {
    case "sizes_uses":
      return [includeAirRights, excludedUses];
    case "investment_volume":
      return [includedValueTypes];
    case "price_per_area":
      return [includeAirRights, denominator, excludedUses];
    default:
      return [];
  }
}

const includeAirRights = {
  label: "Include air rights",
  description: "Add floor area from air rights to the total.",
  component: IncludeAirRights,
  orientation: "x",
};
const includedValueTypes = {
  label: "Included value types",
  description:
    "Each investment contributes one valuation (when available) to the volume calculation. Choose which value types are eligible.",
  component: IncludedValueTypes,
  orientation: "y",
};
