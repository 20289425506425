import _ from "lodash";

export default class Guest {
  constructor(data) {
    this.host = _.get(data, "host");
    this.cypress = _.get(data, "cypress");
    this.admin = false;
    this.signedIn = false;
    this.sessionFetchedAt = null;
  }
}
