<template>
  <div class="h-full flex flex-col">
    <div class="p-2 flex items-center justify-between">
      <div class="flex-auto">
        <div class="flex items-center space-x-2">
          <button
            @click="statsExpanded = !statsExpanded"
            type="button"
            v-tooltip="statsExpanded ? 'Hide metrics' : 'View metrics'"
            class="h-8 w-8 inline-flex justify-center items-center text-sm text-gray-700 hover:text-gray-900"
          >
            <ChevronDownIcon v-if="statsExpanded" class="h-8 w-8" />
            <ChevronRightIcon v-else class="h-8 w-8" />
          </button>
          <h1
            class="text-xl font-semibold text-gray-900"
            data-test="my-contributions-header"
          >
            Contributions Overview
          </h1>
        </div>
        <p v-if="statsExpanded" class="mt-2 text-sm text-gray-700">
          Track your earnings, reputation, and costs from across Tower Hunt.
        </p>
      </div>
    </div>
    <div class="p-2">
      <select
        v-if="statsExpanded"
        v-model="timePeriod"
        id="contributions_time_period"
        name="contributions_time_period"
        class="mt-4 block rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
      >
        <option
          v-for="period in timePeriods"
          :key="period.value"
          :value="period.value"
        >
          {{ period.label }}
        </option>
      </select>
    </div>
    <ContributionStats v-if="statsExpanded" :time-period="timePeriod" />
    <div class="p-2 mt-4 flex items-center justify-between">
      <div class="flex-auto">
        <h1
          class="text-xl font-semibold text-gray-900"
          data-test="my-contributions-header"
        >
          Contribution Details
        </h1>
        <p class="mt-2 text-sm text-gray-700">
          Choose a tab below to see itemized details about your activity.
        </p>
      </div>
    </div>
    <!-- Data selection -->
    <div class="mt-4">
      <div :class="[workspaceLayout === 'sideBySide' ? 'hidden' : '']">
        <label for="tabs" class="sr-only">Select a tab</label>
        <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
        <select
          v-model="selectedTab"
          id="contribution-data-tabs"
          name="contribution-data-tabs"
          class="block rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
        >
          <option v-for="tab in tabs" :key="tab.name" :value="tab">
            {{ tab.name }}
          </option>
        </select>
      </div>
      <div :class="[workspaceLayout === 'sideBySide' ? '' : 'hidden']">
        <div class="border-b border-gray-200">
          <nav class="-mb-px flex" aria-label="Tabs">
            <a
              v-for="tab in tabs"
              @click.prevent="selectedTab = tab"
              :key="tab.name"
              href=""
              :class="[
                selectedTab.name === tab.name
                  ? 'border-indigo-500 text-indigo-600 font-bold'
                  : 'font-medium border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                'w-1/4 border-b-2 py-3 px-1 text-center',
              ]"
              :aria-current="selectedTab.name === tab.name ? 'page' : undefined"
              >{{ tab.name }}</a
            >
          </nav>
        </div>
      </div>
    </div>
    <div id="my-contributions-data-component-container" class="flex-grow">
      <KeepAlive>
        <component :is="selectedTab.component" v-bind="{ timePeriod }" />
      </KeepAlive>
    </div>
  </div>
</template>

<script setup>
import ContributionStats from "@/components/crowdsourcing/contributions/ContributionStats.vue";
import ContributionsTable from "@/components/crowdsourcing/contributions/ContributionsTable.vue";
import ValidationsTable from "@/components/crowdsourcing/contributions/ValidationsTable.vue";
import CostsTable from "@/components/crowdsourcing/contributions/CostsTable.vue";
import MyDataInputJobs from "@/components/crowdsourcing/contributions/MyDataInputJobs.vue";
import { ref, markRaw } from "vue";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { storeToRefs } from "pinia";
import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/vue/20/solid";

const layoutStore = useWorkspaceLayoutStore();
const { workspaceLayout } = storeToRefs(layoutStore);

const statsExpanded = ref(false);

const timePeriod = ref("7d");
const timePeriods = [
  { label: "Today", value: "1d" },
  { label: "Last 7 days", value: "7d" },
  { label: "Last 4 weeks", value: "4w" },
  { label: "Last 3 months", value: "3m" },
  { label: "Last 12 months", value: "12m" },
];
const tabs = [
  { name: "Data Input Jobs", component: markRaw(MyDataInputJobs) },
  { name: "Costs", component: markRaw(CostsTable) },
  {
    name: "Earnings",
    component: markRaw(ContributionsTable),
  },
  { name: "Validations", component: markRaw(ValidationsTable) },
];
const selectedTab = ref({
  name: "Data Input Jobs",
  component: markRaw(MyDataInputJobs),
});
</script>
