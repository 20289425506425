<template>
  <li class="py-4" data-test="shared-payout-account-row">
    <div class="flex items-center space-x-4">
      <span
        v-tooltip="categoryHelp"
        class="flex-shrink-0 inline-flex items-center justify-center h-8 w-8 rounded-full"
        :class="account.chargesEnabled ? 'bg-green-400' : 'bg-orange-400'"
        :data-test="`${account.category}-shared-payout-account-icon`"
      >
        <BuildingOfficeIcon
          v-if="account.category === 'building'"
          class="h-4 w-4 text-white"
        />
        <UserGroupIcon
          v-else-if="account.category === 'team'"
          class="h-4 w-4 text-white"
        />
        <BriefcaseIcon
          v-else-if="account.category === 'company'"
          class="h-4 w-4 text-white"
        />
      </span>
      <div class="flex-1 min-w-0">
        <div v-if="editingName" class="flex items-center space-x-1">
          <div class="flex-1">
            <label for="name" class="sr-only">Account name</label>
            <input
              v-focus
              v-model="accountName"
              @keydown.enter="saveName"
              @keydown.escape="cancelName"
              type="text"
              name="name"
              id="name"
              class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-sm border-gray-300 rounded-md"
              placeholder="Account name"
              data-test="shared-payout-account-name"
            />
          </div>
          <button
            @click="cancelName"
            type="button"
            v-tooltip="'Cancel'"
            class="inline-flex items-center p-1 border border-gray-300 rounded-full shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
          >
            <XMarkIcon class="w-5 h-5 text-gray-700" />
          </button>
          <button
            @click="saveName"
            type="button"
            v-tooltip="saveHelp"
            :disabled="!nameComplete"
            :class="nameComplete ? '' : 'cursor-not-allowed'"
            class="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-400"
            data-test="shared-payout-account-name-save-button"
          >
            <PlusIcon class="w-5 h-5" />
          </button>
        </div>
        <a
          v-else
          href=""
          @click.prevent="editingName = true"
          class="text-sm font-medium flex items-center space-x-1 text-indigo-600 hover:text-indigo-500 truncate"
          data-test="shared-payout-account-name-button"
        >
          <span data-test="shared-payout-account-name-value">{{
            account.name || `Payout account`
          }}</span>
          <PencilIcon class="h-3 w-3" />
        </a>
        <p class="text-sm text-gray-500 truncate">
          {{ role }} &middot; {{ status }}
        </p>
      </div>
      <div v-if="!editingName" class="flex items-center space-x-2">
        <a
          href=""
          @click.prevent="clickHandler"
          class="inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50"
          data-test="shared-payout-account-setup-button"
        >
          {{ viewLabel }}
        </a>
        <SharedPayoutAccountMenu
          v-if="account.managed"
          :account="account"
          @refetch="refetch"
          @category="setCategory"
        />
      </div>
    </div>
  </li>
</template>

<script setup>
import { computed, ref } from "vue";
import {
  BriefcaseIcon,
  BuildingOfficeIcon,
  PlusIcon,
  PencilIcon,
  UserGroupIcon,
  XMarkIcon,
} from "@heroicons/vue/20/solid";
import SharedPayoutAccountMenu from "@/components/users/SharedPayoutAccountMenu.vue";
import api from "@/router/api";
import _ from "lodash";

const props = defineProps(["account"]);
const emit = defineEmits(["refetch"]);
const editingName = ref(false);
const accountName = ref("");
const nameComplete = computed(() => {
  return _.trim(accountName.value) !== "";
});
const categoryHelp = computed(() => {
  return _.capitalize(props.account.category);
});
const role = computed(() => {
  return props.account.managed ? "Manager" : "Member";
});
const viewLabel = computed(() => {
  return props.account.chargesEnabled ? "View" : "Setup";
});
const status = computed(() => {
  return props.account.chargesEnabled ? "Payable" : "Setup incomplete";
});
const saveHelp = computed(() => {
  return nameComplete.value ? "Save" : "Incomplete";
});

async function clickHandler() {
  api.get(`shared_payout_accounts/${props.account.id}`).then((json) => {
    if (json.data) {
      if (json.data.newTab) {
        window.open(json.data.url, "_blank");
      } else {
        window.location.href = json.data.url;
      }
    } else {
      // this.$store.dispatch("flash", "Could not fetch account link.");
    }
  });
}

async function saveName() {
  if (nameComplete.value) {
    api
      .patch(`shared_payout_accounts/${props.account.id}`, {
        name: accountName.value,
        category: props.account.category,
      })
      .then(() => {
        // this.$store.dispatch("flash", "Account name updated.");
        cancelName();
        refetch();
      });
  }
}
async function setCategory(category) {
  api
    .patch(`shared_payout_accounts/${props.account.id}`, {
      name: props.account.name,
      category,
    })
    .then(() => {
      // this.$store.dispatch("flash", "Account category updated.");
      refetch();
    });
}
function refetch() {
  emit("refetch");
}
function cancelName() {
  accountName.value = "";
  editingName.value = false;
}
</script>
