<template>
  <form @submit.prevent class="p-2 space-y-4 divide-y divide-gray-200">
    <div v-if="!isDesktop" class="flex items-center justify-around">
      <a @click.prevent="cancel" href="" class="text-sm text-indigo-500">
        Cancel
      </a>
      <h3
        class="flex min-w-0 flex-1 items-center justify-center font-medium text-gray-900"
      >
        Share List
      </h3>
      <a
        @click.prevent="share"
        href=""
        class="flex items-center text-sm font-medium text-indigo-500"
        data-test="save-task-list-button"
      >
        Share
      </a>
    </div>

    <div class="space-y-4">
      <div v-if="isDesktop">
        <h3 class="text-lg font-medium leading-6 text-gray-900">Share List</h3>
      </div>

      <div class="space-y-1">
        <label
          for="grant_task_list_access"
          class="flex justify-between text-sm font-medium text-gray-700"
        >
          <div class="flex">
            <span>Grant Access</span>
            <div class="flex-shrink-0">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                v-tooltip="
                  'Invite existing Tower Hunt teams and users, or bring on new users via email.'
                "
                class="ml-1 h-5 w-5 text-gray-700"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
          </div>
        </label>
        <p id="grant_task_list_access_helper" class="sr-only">
          Search by name or email address
        </p>
        <div
          class="flex flex-1 flex-wrap pb-2 items-center shadow-sm border focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
        >
          <ul v-if="combined.length > 0" class="mt-2 mx-2 space-x-1 space-y-2">
            <li
              v-for="(recipient, index) in combined"
              :key="recipient.token || recipient.id"
              class="inline-flex rounded-full items-center py-0.5 px-1 space-x-0.5 text-sm font-medium bg-indigo-100 text-indigo-700"
              :data-test="`task-list-sharing-recipient-${index}`"
            >
              {{ recipient.name }}
              <button
                @click="remove(recipient)"
                type="button"
                class="flex-shrink-0 h-4 w-4 rounded-full inline-flex items-center justify-center text-indigo-400 hover:bg-indigo-200 hover:text-indigo-500 focus:outline-none focus:bg-indigo-500 focus:text-white"
              >
                <span class="sr-only">Remove</span>
                <svg
                  class="h-2 w-2"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 8 8"
                >
                  <path
                    stroke-linecap="round"
                    stroke-width="1.5"
                    d="M1 1l6 6m0-6L1 7"
                  />
                </svg>
              </button>
            </li>
          </ul>
          <div class="relative flex-grow focus-within:z-10">
            <UserTeamAutocomplete
              :search-teams="true"
              :selections="combined"
              :remove-inline="true"
              input-key="task-list-sharing-form"
              :focus="true"
              :no-self="true"
              @add-user="addUser"
              @add-team="addTeam"
              @remove="remove"
            />
          </div>
        </div>
      </div>

      <div v-if="accessTokensLoaded && sharing.length > 0" class="space-y-1">
        <div class="text-sm font-medium text-gray-700">Shared With</div>
        <div class="flex justify-between items-center">
          <div class="flex items-center space-x-2 flex-shrink-0">
            <div class="flex items-center -space-x-1" style="width: 84px">
              <VMenu
                v-for="(recipient, index) in slicedSharingRecipients"
                :key="`${recipient.className}-${recipient.tokenId}`"
                class="flex-shrink-0"
              >
                <AvatarPhoto
                  :team-name="recipient.teamName"
                  :person="recipient"
                  border-class="ring-2 ring-white"
                  shape-size="h-6 w-6"
                  text-size="xs"
                  :data-test="`sharing-recipient-${index}-avatar`"
                />

                <template #popper>
                  <SharingRecipientActionMenu
                    :recipient="recipient"
                    :index="index"
                    @refetch-tokens="fetchAccessTokens"
                  />
                </template>
              </VMenu>
            </div>

            <VMenu v-if="sharingOverflow">
              <span class="flex-shrink-0 ml-1 text-xs leading-5 font-medium"
                >+{{ sharingOverflow }}</span
              >

              <template #popper>
                <div
                  class="w-64 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="menu-button"
                  tabindex="-1"
                >
                  <div class="py-1" role="none">
                    <VDropdown
                      v-for="(recipient, index) in overflowRecipients"
                      :key="recipient.id"
                      placement="left-start"
                    >
                      <a
                        @click.prevent
                        href=""
                        class="text-gray-700 hover:bg-gray-100 hover:text-gray-900 group flex items-center px-4 py-2 text-sm"
                        role="menuitem"
                        tabindex="-1"
                        id="menu-item-0"
                      >
                        {{ recipient.name }}
                      </a>

                      <template #popper>
                        <SharingRecipientActionMenu
                          :recipient="recipient"
                          :index="index"
                          @refetch-tokens="fetchAccessTokens"
                        />
                      </template>
                    </VDropdown>
                  </div>
                </div>
              </template>
            </VMenu>
          </div>

          <VDropdown v-if="sharing.length > 1" class="flex items-center">
            <button
              type="button"
              class="h-5 w-5 inline-flex justify-center items-center text-sm text-gray-500 hover:text-gray-700"
              :data-test="`task-list-sharing-extended-options-button`"
            >
              <EllipsisHorizontalIcon class="h-5 w-5" />
            </button>

            <template #popper>
              <div
                class="w-64 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="menu-button"
                tabindex="-1"
              >
                <div class="py-1" role="none">
                  <a
                    @click.prevent="revokeAll"
                    v-close-popper
                    href=""
                    class="text-gray-700 hover:bg-gray-100 hover:text-gray-900 group flex items-center px-4 py-2 text-sm"
                    role="menuitem"
                    tabindex="-1"
                    id="menu-item-0"
                    :data-test="`task-list-sharing-revoke-all-access-button`"
                  >
                    Remove all
                  </a>
                </div>
              </div>
            </template>
          </VDropdown>
        </div>
      </div>
      <div v-else-if="!accessTokensLoaded" class="flex justify-center">
        <GridLoader :loading="true" size="6px" color="#D4D4D8" />
      </div>
    </div>

    <div v-if="isDesktop" class="pt-5">
      <div class="flex justify-end">
        <button
          @click="cancel"
          type="button"
          class="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          Cancel
        </button>
        <button
          @click="share"
          type="button"
          class="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          data-test="save-task-list-button"
        >
          Share
        </button>
      </div>
    </div>
  </form>
</template>

<script setup>
import { EllipsisHorizontalIcon } from "@heroicons/vue/20/solid";
import GridLoader from "vue-spinner/src/GridLoader.vue";
import AvatarPhoto from "@/components/users/AvatarPhoto.vue";
import SharingRecipientActionMenu from "@/components/crowdsourcing/safezone/SharingRecipientActionMenu.vue";
import UserTeamAutocomplete from "@/components/contacts/UserTeamAutocomplete.vue";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { useTasksStore } from "@/stores/tasks";
import { useTaskListStore } from "@/stores/taskList";
import { storeToRefs } from "pinia";
import { ref, computed, onMounted } from "vue";
import api from "@/router/api";
import _ from "lodash";

const tasksStore = useTasksStore();
const { listGroupedTaskPagyObjects, sharingList, taskLists, selectedTaskList } =
  storeToRefs(tasksStore);
const taskListStore = useTaskListStore();
const { userRecipients, teamRecipients } = storeToRefs(taskListStore);
const layoutStore = useWorkspaceLayoutStore();
const { isDesktop } = storeToRefs(layoutStore);

const accessTokens = ref([]);
const accessTokensLoaded = ref(false);
const combined = computed(() => {
  const concatted = _.concat(userRecipients.value, teamRecipients.value);

  return _.orderBy(concatted, ["name"], ["asc"]);
});
const userTokens = computed(() => _.get(accessTokens.value, "users", []));
const teamTokens = computed(() => _.get(accessTokens.value, "teams", []));
const sharing = computed(() => {
  const combined = _.concat(teamTokens.value, userTokens.value);

  return combined.map((token) => {
    switch (token.className) {
      case "TaskListAccessToken":
        return _.merge({}, token.user, {
          tokenId: token.tokenId,
          className: token.className,
        });
      default:
        return token;
    }
  });
});
const slicedSharingRecipients = computed(() => {
  return _.slice(sharing.value, 0, 4);
});
const sharingOverflow = computed(() => {
  const overflow = sharing.value.length - 4;

  return overflow > 0 ? overflow : null;
});
const overflowRecipients = computed(() => _.drop(sharing.value, 4));

onMounted(() => {
  fetchAccessTokens();
});

function fetchAccessTokens() {
  api
    .get(`task_list_access_tokens/${selectedTaskList.value.id}`)
    .then((json) => {
      accessTokens.value = json.data;
      accessTokensLoaded.value = true;
    });
}

function cancel() {
  taskListStore.resetSharing();
  sharingList.value = false;
}
function addTeam(newTeam) {
  taskListStore.addTeam(newTeam);
}

function addUser(newUser) {
  taskListStore.addUser(newUser);
}

function remove(recipient) {
  if (recipient.className === "Team") {
    taskListStore.removeTeam(recipient);
  } else {
    taskListStore.removeUser(recipient);
  }
}
function share() {
  setTimeout(() => {
    taskListStore.shareList(selectedTaskList.value).then((json) => {
      if (json) {
        const list = json.data;
        if (selectedTaskList.value) {
          tasksStore.patchTaskList(list);
          selectedTaskList.value = list;
        } else {
          taskLists.value.data.push(list);
        }

        listGroupedTaskPagyObjects.value[
          list.accessTokenId || _.camelCase(list.name)
        ] = {
          data: [],
          pagy: null,
        };
      }
      cancel();
    });
  }, 125);
}
async function revokeAll() {
  api
    .delete(
      `task_list_sharings/?task_list_id=${selectedTaskList.value.id}&class_name=TaskList`
    )
    .then(() => {
      fetchAccessTokens();
    });
}
</script>
