<template>
  <div v-if="assumptionsFields.length > 0" class="flex flex-col space-y-2">
    <div class="text-sm font-medium text-gray-500">
      Area Conversion Assumptions
    </div>
    <div class="text-xs font-medium text-gray-500">
      Convert to SF via a density range plus a % modifier.
    </div>
    <div class="grid grid-cols-3 gap-x-2 gap-y-2 rounded-md bg-teal-100 p-2">
      <div v-tooltip="'Min SF per person'" class="">
        <label
          :for="`headcount-min-density`"
          class="block text-sm font-medium leading-6 text-gray-500"
          >Min. SF/P</label
        >
        <div class="mt-1">
          <EditableDataField
            field-name="min_area_per_person"
            :decorating-content-data-field="requirementDataField"
            :is-integer="true"
            :optional="false"
            :dismiss-on-save="true"
            text-classes="text-sm"
            input-classes="w-full"
            @completed="refetch"
            @open-sourced="refetch"
            @unlocked="refetch"
            @dismiss="refetch"
          />
        </div>
      </div>
      <div v-tooltip="'Max SF per person'" class="">
        <label
          :for="`headcount-max-density`"
          class="block text-sm font-medium leading-6 text-gray-500"
          >Max. SF/P</label
        >
        <div class="mt-1">
          <EditableDataField
            field-name="max_area_per_person"
            :decorating-content-data-field="requirementDataField"
            :is-integer="true"
            :optional="false"
            :dismiss-on-save="true"
            text-classes="text-sm"
            input-classes="w-full"
            @completed="refetch"
            @open-sourced="refetch"
            @unlocked="refetch"
            @dismiss="refetch"
          />
        </div>
      </div>
      <div v-tooltip="'Margin of Error (%)'" class="">
        <label
          :for="`headcount-min-modifier`"
          class="block text-sm font-medium leading-6 text-gray-500"
          >MoE %</label
        >
        <div class="mt-1">
          <EditableDataField
            field-name="density_percent_modifier"
            :decorating-content-data-field="requirementDataField"
            :is-float="true"
            :optional="false"
            :dismiss-on-save="true"
            text-classes="text-sm"
            input-classes="w-full"
            @completed="refetch"
            @open-sourced="refetch"
            @unlocked="refetch"
            @dismiss="refetch"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import EditableDataField from "@/components/crowdsourcing/EditableDataField.vue";

defineProps(["assumptionsFields", "requirementDataField"]);
const emit = defineEmits(["refetch"]);

function refetch() {
  emit("refetch");
}
</script>
