import { ref } from "vue";
import { defineStore, acceptHMRUpdate } from "pinia";
import _ from "lodash";

export const useDatabaseUpdatesChannelStore = defineStore(
  "databaseUpdatesChannel",
  () => {
    const DatabaseUpdatesChannel = ref(null);
    const databaseUpdatesChannelDataQueue = ref([]);

    function pushAndTrim(data) {
      databaseUpdatesChannelDataQueue.value.push(data);
      databaseUpdatesChannelDataQueue.value = _.takeRight(
        databaseUpdatesChannelDataQueue.value,
        3
      );
    }

    return {
      DatabaseUpdatesChannel,
      databaseUpdatesChannelDataQueue,
      pushAndTrim,
    };
  }
);

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useDatabaseUpdatesChannelStore, import.meta.hot)
  );
}
