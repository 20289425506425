import { ref } from "vue";
import { defineStore, acceptHMRUpdate } from "pinia";
import _ from "lodash";

export const useDataSharingsChannelStore = defineStore(
  "dataSharingsChannel",
  () => {
    const DataSharingsChannel = ref(null);
    const dataSharingsChannelDataQueue = ref([]);

    function pushAndTrim(data) {
      dataSharingsChannelDataQueue.value.push(data);
      dataSharingsChannelDataQueue.value = _.takeRight(
        dataSharingsChannelDataQueue.value,
        3
      );
    }

    return {
      DataSharingsChannel,
      dataSharingsChannelDataQueue,
      pushAndTrim,
    };
  }
);

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useDataSharingsChannelStore, import.meta.hot)
  );
}
