<template>
  <div>
    <div
      :id="`${context}-stacked-cards-block`"
      class="stackedcards stackedcards--animatable"
    >
      <div class="stackedcards-container">
        <CollectibleCardPaper
          v-for="(card, index) in cards"
          :key="card.id"
          :auto-card-size="false"
          :card="card"
          :flipped="flipped"
          :licensed="licensed"
          :displayable="displayable"
          :card-index="index"
          :stack-index="cardsCompleted"
          :completed-card-ids="completedCardIds"
          :context="context"
          @discard="onActionLeft"
          @accept="onActionRight"
        />
      </div>
      <div
        v-if="false"
        class="stackedcards--animatable stackedcards-overlay top bg-blue-400"
      >
        <LockOpenIcon class="h-20 w-20 text-white" />
      </div>
      <div
        class="stackedcards--animatable stackedcards-overlay right bg-green-300"
      >
        <StarIcon class="h-20 w-20 text-white" />
      </div>
      <div
        class="stackedcards--animatable stackedcards-overlay left bg-red-500"
      >
        <XMarkIcon class="h-20 w-20 text-white" />
      </div>
    </div>
    <div
      :class="cards && cards.length > 1 ? 'mt-8' : 'mt-3'"
      class="flex flex-col"
    >
      <div class="flex justify-center items-center">
        <button
          type="button"
          v-tooltip="'Discard'"
          class="flex-shrink-0 inline-flex items-center justify-center h-16 w-16 rounded-full bg-red-500 hover:bg-red-700"
          @click="onActionLeft"
          data-test="discard-collectible-card-button"
        >
          <XMarkIcon class="h-8 w-8 text-white" />
        </button>
        <UnlockBundles
          v-if="displayable && !flipped && (bundlesExist || unlockable)"
          context="card-container"
          :data-field="dataField"
          :bundles="bundles"
          :all-fields-data="allFieldsData"
          :user-store="userStore"
          :modal-store="modalStore"
          :unlocker-store="unlockerStore"
          placement="top"
          class="ml-4 flex-shrink-0"
          @unlocked="unlocked"
          @reset-displayable="resetDisplayable"
        >
          <template v-slot:button>
            <button
              type="button"
              v-tooltip="'Instant Unlock'"
              class="inline-flex items-center justify-center h-12 w-12 rounded-full bg-blue-400 hover:bg-blue-600"
              data-test="unlock-collectible-card-button"
            >
              <LockOpenIcon class="h-6 w-6 text-white" />
            </button>
          </template>
        </UnlockBundles>
        <button
          type="button"
          v-tooltip="'Save to Tasks'"
          class="ml-4 flex-shrink-0 inline-flex items-center justify-center h-16 w-16 rounded-full bg-green-300 hover:bg-green-500"
          @click="onActionRight"
          data-test="accept-collectible-card-button"
        >
          <StarIcon class="h-8 w-8 text-white" />
        </button>
      </div>
      <div v-if="signedIn" class="mt-2 flex items-center justify-center">
        <a
          href=""
          v-tooltip="'Discard all unread cards of this category'"
          @click.prevent="discardCategory"
          class="px-2 py-1 flex items-center space-x-1 text-xs underline"
          :class="
            isDesktop
              ? 'text-indigo-500 hover:text-indigo-700'
              : 'text-gray-500'
          "
          >Discard category</a
        >
        <a
          href=""
          @click.prevent="discardAll"
          class="px-2 py-1 flex items-center space-x-1 text-xs underline"
          :class="
            isDesktop
              ? 'text-indigo-500 hover:text-indigo-700'
              : 'text-gray-500'
          "
          >Discard all</a
        >
      </div>
      <div class="mt-2 flex items-center justify-center">
        <router-link
          :to="helpCenterPath"
          target="_blank"
          v-tooltip="'Get help'"
        >
          <QuestionMarkCircleIcon class="h-5 w-5 text-gray-700" />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script setup>
import { QuestionMarkCircleIcon } from "@heroicons/vue/20/solid";
import CollectibleCardPaper from "@/components/collectible-cards/CollectibleCardPaper.vue";
import UnlockBundles from "@/components/crowdsourcing/UnlockBundles.vue";
import moment from "moment";
import api from "@/router/api";
import {
  ref,
  computed,
  onMounted,
  onBeforeUnmount,
  watch,
  nextTick,
} from "vue";
import { useUserStore } from "@/stores/user";
import { useGuestProfileStore } from "@/stores/guestProfile";
import { useUnlockerStore } from "@/stores/unlocker";
import { useModalStore } from "@/stores/modal";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { useCardContainerStore } from "@/stores/collectibleCardContainer";
import { storeToRefs } from "pinia";
import { bundlesFor } from "@/components/crowdsourcing/unlockableBundles";

import { LockOpenIcon, StarIcon, XMarkIcon } from "@heroicons/vue/20/solid";
import _ from "lodash";

const props = defineProps(["context"]);

const helpCenterPath = computed(() => {
  return {
    name: "HelpArticle",
    params: { articleId: "understanding-the-newsfeed" },
  };
});

const userStore = useUserStore();
const { signedIn, availableCollectibleCardCount } = storeToRefs(userStore);
const layoutStore = useWorkspaceLayoutStore();
const { isDesktop } = storeToRefs(layoutStore);
const cardContainerStore = useCardContainerStore();
const { actioningCard } = storeToRefs(cardContainerStore);
const guestProfileStore = useGuestProfileStore();
const { geographyIntents, discoverySearches, collectibleCards, guestTasks } =
  storeToRefs(guestProfileStore);

const displayable = ref(true);
const reset = ref(false);
const checkUnlockable = ref(false);
const flipped = ref(false);
const licensed = computed(() => _.get(dataField.value, "licensed"));
async function resetDisplayable() {
  console.log("reset displayable");
  displayable.value = false;

  await nextTick();
  displayable.value = true;
}

const bundleDataFieldId = computed(() =>
  _.get(currentCard.value, "bundleDataFieldId", false),
);
const isConcealed = computed(
  () => _.get(currentCard.value, "category") === "generalized_location_news",
);
const shouldFlip = computed(() => {
  if (isConcealed.value) {
    switch (currentCard.value.taskName) {
      case "Leasing alert":
      case "Deal alert":
      case "Development project alert":
        return currentCard.value.unlocked;
      default:
        return false;
    }
  } else {
    return false;
  }
});
const dataField = ref(null);
const allFieldsData = ref(null);
const bundles = computed(() => bundlesFor(dataField.value));
const safezone = computed(() => _.get(dataField.value, "safezone"));
const priced = computed(() => {
  const rawPrice = _.get(dataField.value, "price");
  const price = rawPrice ? _.toNumber(rawPrice) : null;
  return price && price > 0;
});
const unlockable = computed(() => {
  if (!safezone.value) return unstaked.value || priced.value;
  else return priced.value;
});
const unstaked = computed(() => _.get(dataField.value, "state") === "unstaked");
const unlicensedCount = computed(
  () => _.get(allFieldsData.value, "unlicensedIds", []).length,
);
const bundlesExist = computed(
  () =>
    (unlockable.value && !unstaked.value) ||
    (bundles.value.length > 0 && unlicensedCount.value > 0),
);

const cardsCompleted = ref(0);
const cardStartTime = ref(null);
const stackedOptions = ref("Top"); //Change stacked cards view from 'Bottom', 'Top' or 'None'.
const rotate = ref(true); // Activate the elements' rotation for each move on stacked cards.
const items = ref(3); // Number of visible elements when the stacked options are bottom or top.
const elementsMargin = ref(10); // Define the distance of each element when the stacked options are bottom or top.
const useOverlays = ref(true); // Enable or disable the overlays for swipe elements.
const maxElements = ref(null); // Total of stacked cards on DOM.
const currentPosition = ref(0); // Keep the position of active stacked card.
const velocity = ref(0.3); // Minimum velocity allowed to trigger a swipe.
const topObj = ref(null); // Keep the swipe top properties.
const rightObj = ref(null); // Keep the swipe right properties.
const leftObj = ref(null); // Keep the swipe left properties.
const listElNodesObj = ref(null); // Keep the list of nodes from stacked cards.
const listElNodesWidth = ref(330); // Keep the stacked cards width.
const currentElementObj = ref(null); // Keep the stacked card element to swipe.
const stackedCardsObj = ref(null);
const isFirstTime = ref(true);
const elementHeight = ref(null);
const obj = ref(null);
const elTrans = ref(null);
const element = ref(null);
const startTime = ref(null);
const startX = ref(null);
const startY = ref(null);
const translateX = ref(null);
const translateY = ref(null);
const currentX = ref(null);
const currentY = ref(null);
const touchingElement = ref(false);
const timeTaken = ref(null);
const topOpacity = ref(null);
const rightOpacity = ref(null);
const leftOpacity = ref(null);
const cards = ref([]);
const completedCardIds = ref([]);
const currentCard = computed(() => {
  if (cards.value) {
    return cards.value[currentPosition.value];
  } else {
    return null;
  }
});

watch(availableCollectibleCardCount, async (val, oldVal) => {
  if (val !== oldVal && val > 0 && !actioningCard.value) {
    console.log("non-swipe, non-zero card count");
    checkForIncrement(true).then(() => (checkUnlockable.value = true));
  }
});
watch(checkUnlockable, () => {
  if (checkUnlockable.value && bundleDataFieldId.value) {
    fetchDataField().then(() => fetchBundles());
  } else checkUnlockable.value = false;
});
watch(shouldFlip, () => {
  if (shouldFlip.value) {
    flipCard();
  }
});

onMounted(() => {
  if (!signedIn.value) {
    completedCardIds.value = collectibleCards.value.map(
      (guestCard) => guestCard.id,
    );
  }

  fetchCards().then(async () => {
    await nextTick();
    setupStackedCards();
    setupTouchInteractions();
    cardStartTime.value = moment();
    checkUnlockable.value = true;
  });
});

function markCardUnlocked() {
  api.patch(`unlock_collectible_card/${currentCard.value.id}`);
}
function discardAll() {
  api.post(`discard_collectible_cards`).then(() => {
    userStore.fetchAvailableCollectibleCardCount().then(() => {
      concludeSwipe();
    });
  });
}
function discardCategory() {
  api
    .post(`discard_collectible_cards`, { categoryCardId: currentCard.value.id })
    .then(() => {
      reset.value = true;
      userStore.fetchAvailableCollectibleCardCount().then(() => {
        concludeSwipe();
      });
    });
}
async function fetchBundles() {
  api
    .get(
      `crowdsourced_data_field_bundles/${bundleDataFieldId.value}?bundle=all_fields`,
    )
    .then((json) => {
      allFieldsData.value = json.data;
      autoUnlock();
      checkUnlockable.value = false;
    });
}

async function fetchDataField() {
  return new Promise((resolve) => {
    api.get(`data_field_by_id/${bundleDataFieldId.value}`).then((json) => {
      dataField.value = json.data;
      resolve();
    });
  });
}

async function fetchCards() {
  return new Promise((resolve) => {
    const cardIds = cards.value.map((card) => card.id);

    const payload = {
      cardIds: reset.value ? [] : _.union(cardIds, completedCardIds.value),
      geographyIntents: geographyIntents.value,
      discoverySearches: discoverySearches.value,
      mobile: !isDesktop.value,
    };
    api.post(`my_collectible_cards`, payload).then((json) => {
      const newCards = _.unionBy(
        reset.value ? [] : cards.value,
        json.data,
        "id",
      );
      cards.value = _.orderBy(newCards, ["sortDate", "id"], ["desc", "desc"]);
      if (reset.value) {
        currentPosition.value = 0;
        reset.value = false;
      }
      resolve();
    });
  });
}

function setupStackedCards() {
  let blockEl = document.getElementById(`${props.context}-stacked-cards-block`);

  if (!blockEl) return;

  obj.value = blockEl;
  stackedCardsObj.value = obj.value.querySelector(".stackedcards-container");
  listElNodesObj.value = stackedCardsObj.value.children;

  // topObj.value = obj.value.querySelector(".stackedcards-overlay.top");
  rightObj.value = obj.value.querySelector(".stackedcards-overlay.right");
  leftObj.value = obj.value.querySelector(".stackedcards-overlay.left");

  countElements();
  currentElement();
  // this.listElNodesWidth = this.stackedCardsObj.offsetWidth; hardwire to 330px;
  currentElementObj.value = listElNodesObj.value[0];
  updateUi();

  addMargins();
  positionCards();
  setActiveCard();
  setOverlays();
}
function setupTouchInteractions() {
  if (obj.value) {
    //Touch events block
    element.value = obj.value;

    element.value.addEventListener("touchstart", gestureStart, false);
    element.value.addEventListener("touchmove", gestureMove, false);
    element.value.addEventListener("touchend", gestureEnd, false);
  }
}
function setOverlays() {
  if (useOverlays.value) {
    leftObj.value.style.transform =
      "translateX(0px) translateY(" +
      elTrans.value +
      "px) translateZ(0px) rotate(0deg)";
    leftObj.value.style.webkitTransform =
      "translateX(0px) translateY(" +
      elTrans.value +
      "px) translateZ(0px) rotate(0deg)";

    rightObj.value.style.transform =
      "translateX(0px) translateY(" +
      elTrans.value +
      "px) translateZ(0px) rotate(0deg)";
    rightObj.value.style.webkitTransform =
      "translateX(0px) translateY(" +
      elTrans.value +
      "px) translateZ(0px) rotate(0deg)";

    if (topObj.value) {
      topObj.value.style.transform =
        "translateX(0px) translateY(" +
        elTrans.value +
        "px) translateZ(0px) rotate(0deg)";
      topObj.value.style.webkitTransform =
        "translateX(0px) translateY(" +
        elTrans.value +
        "px) translateZ(0px) rotate(0deg)";
    }
  } else {
    leftObj.value.className = "";
    rightObj.value.className = "";
    if (topObj.value) topObj.value.className = "";

    leftObj.value.classList.add("stackedcards-overlay-hidden");
    rightObj.value.classList.add("stackedcards-overlay-hidden");
    if (topObj.value) topObj.value.classList.add("stackedcards-overlay-hidden");
  }
}
function setActiveCard() {
  if (listElNodesObj.value[currentPosition.value]) {
    listElNodesObj.value[currentPosition.value].classList.add(
      "stackedcards-active",
    );
  }
}
function positionCards() {
  for (let i = items.value; i < maxElements.value; i++) {
    listElNodesObj.value[i].style.zIndex = 0;
    listElNodesObj.value[i].style.opacity = 0;
    listElNodesObj.value[i].style.webkitTransform =
      "scale(" +
      (1 - items.value * 0.04) +
      ") translateX(0) translateY(" +
      elTrans.value +
      "px) translateZ(0)";
    listElNodesObj.value[i].style.transform =
      "scale(" +
      (1 - items.value * 0.04) +
      ") translateX(0) translateY(" +
      elTrans.value +
      "px) translateZ(0)";
  }
}
function addMargins() {
  let addMargin = elementsMargin.value * (items.value - 1) + "px";

  if (stackedOptions.value === "Top") {
    for (let i = items.value; i < maxElements.value; i++) {
      listElNodesObj.value[i].classList.add(
        "stackedcards-top",
        "stackedcards--animatable",
        "stackedcards-origin-top",
      );
    }

    elTrans.value = elementsMargin.value * (items.value - 1);

    stackedCardsObj.value.style.marginBottom = addMargin;
  } else if (stackedOptions.value === "Bottom") {
    for (let i = items.value; i < maxElements.value; i++) {
      listElNodesObj.value[i].classList.add(
        "stackedcards-bottom",
        "stackedcards--animatable",
        "stackedcards-origin-bottom",
      );
    }

    elTrans.value = 0;

    stackedCardsObj.value.style.marginBottom = addMargin;
  } else if (stackedOptions.value === "None") {
    for (let i = items.value; i < maxElements.value; i++) {
      listElNodesObj.value[i].classList.add(
        "stackedcards-none",
        "stackedcards--animatable",
      );
    }

    elTrans.value = 0;
  }
}
function backToMiddle() {
  removeNoTransition();
  transformUi(0, 0, 1, currentElementObj.value);

  if (useOverlays.value) {
    transformUi(0, 0, 0, leftObj.value);
    transformUi(0, 0, 0, rightObj.value);
    if (topObj.value) transformUi(0, 0, 0, topObj.value);
  }

  setZindex(5);
  resetZIndices();

  if (!(currentPosition.value >= maxElements.value)) {
    //roll back the opacity of second element
    if (currentPosition.value + 1 < maxElements.value) {
      listElNodesObj.value[currentPosition.value + 1].style.opacity = ".8";
    }
  }
}
function countElements() {
  maxElements.value = listElNodesObj.value.length;
  // maxElements.value = cards.value.length;
  if (items.value > maxElements.value) {
    items.value = maxElements.value;
  }
}
function currentElement() {
  currentElementObj.value = listElNodesObj.value[currentPosition.value]; //Keep the active card.
}
function onActionLeft() {
  // Functions to swipe left elements on logic external action.
  if (!(currentPosition.value >= maxElements.value)) {
    if (useOverlays.value) {
      leftObj.value.classList.remove("no-transition");
      if (topObj.value) topObj.value.classList.remove("no-transition");
      leftObj.value.style.zIndex = "8";
      transformUi(0, 0, 1, leftObj.value);
    }

    setTimeout(function () {
      onSwipeLeft();
      resetOverlayLeft();
    }, 300);
  }
}
function onActionRight() {
  // Functions to swipe right elements on logic external action.
  if (!(currentPosition.value >= maxElements.value)) {
    if (useOverlays.value) {
      rightObj.value.classList.remove("no-transition");
      if (topObj.value) topObj.value.classList.remove("no-transition");
      rightObj.value.style.zIndex = "8";
      transformUi(0, 0, 1, rightObj.value);
    }

    setTimeout(function () {
      onSwipeRight();
      resetOverlayRight();
    }, 300);
  }
}
function autoUnlock() {
  if (
    isConcealed.value &&
    !currentCard.value.unlocked &&
    unlicensedCount.value === 0
  ) {
    console.log("auto unlock");
    unlocked();
  }
}
function unlocked() {
  markCardUnlocked();
  onActionTop();
}
function onActionTop() {
  // Functions to swipe top elements on logic external action.
  if (!(currentPosition.value >= maxElements.value)) {
    if (topObj.value && useOverlays.value) {
      leftObj.value.classList.remove("no-transition");
      rightObj.value.classList.remove("no-transition");
      topObj.value.classList.remove("no-transition");
      topObj.value.style.zIndex = "8";
      transformUi(0, 0, 1, topObj.value);
    }

    setTimeout(function () {
      onSwipeTop();
      resetOverlays();
    }, 300); //wait animations end
  }
}
async function concludeSwipe() {
  displayable.value = false;
  flipped.value = false;
  dataField.value = null;
  allFieldsData.value = null;
  await nextTick();
  displayable.value = true;
  currentPosition.value++;
  updateUi();
  currentElement();
  setActiveHidden();
  cardsCompleted.value++;
  cardStartTime.value = moment();
  checkUnlockable.value = true;
  checkForCompletion();
}

function checkForCompletion() {
  if (cardsCompleted.value === cards.value.length) {
    setTimeout(function () {
      console.log("stack completed");
      actioningCard.value = false;
    }, 300);
  } else {
    console.log("stack incomplete");
    checkForIncrement();
  }
}

async function checkForIncrement(override = false) {
  return new Promise((resolve) => {
    const cardsRemaining = cards.value.length - cardsCompleted.value;

    if (cardsRemaining <= 3 || override) {
      console.log("check increment fetch");
      fetchCards().then(() => {
        if (override) {
          setupStackedCards();
        } else {
          let blockEl = document.getElementById(
            `${props.context}-stacked-cards-block`,
          );

          if (blockEl) {
            obj.value = blockEl;
            stackedCardsObj.value = obj.value.querySelector(
              ".stackedcards-container",
            );
            listElNodesObj.value = stackedCardsObj.value.children;
            countElements();
            updateUi();
            positionCards();
            setActiveCard();
            setOverlays();
          }
        }

        actioningCard.value = false;
        resolve();
      });
    } else {
      console.log("check increment skip");
      actioningCard.value = false;
      resolve();
    }
  });
}

function getCardReviewTime() {
  const reviewTime = moment().diff(cardStartTime.value, "seconds");

  return reviewTime;
}

function onSwipeLeft() {
  // Swipe active card to left.
  removeNoTransition();
  transformUi(-1000, 0, 0, currentElementObj.value);
  if (useOverlays.value) {
    transformUi(-1000, 0, 0, leftObj.value); //Move leftOverlay
    if (topObj.value) transformUi(-1000, 0, 0, topObj.value); //Move topOverlay
    resetOverlayLeft();
  }

  const reviewSeconds = getCardReviewTime();
  actioningCard.value = true;
  console.log("discard", currentCard.value.id);

  if (signedIn.value) {
    api
      .patch(
        `discard_collectible_card/${currentCard.value.id}?review_seconds=${reviewSeconds}`,
      )
      .then(() =>
        userStore.fetchAvailableCollectibleCardCount().then(() => {
          completedCardIds.value.push(currentCard.value.id);
          concludeSwipe();
        }),
      );
  } else {
    collectibleCards.value.push({
      id: currentCard.value.id,
      userReaction: "discarded",
      reviewSeconds: reviewSeconds,
    });
    availableCollectibleCardCount.value--;
    completedCardIds.value.push(currentCard.value.id);
    concludeSwipe();
  }
}
function onSwipeRight() {
  // Swipe active card to right.
  removeNoTransition();
  transformUi(1000, 0, 0, currentElementObj.value);
  if (useOverlays.value) {
    transformUi(1000, 0, 0, rightObj.value); //Move rightOverlay
    if (topObj.value) transformUi(1000, 0, 0, topObj.value); //Move topOverlay
    resetOverlayRight();
  }

  const reviewSeconds = getCardReviewTime();
  actioningCard.value = true;
  console.log("accept", currentCard.value.id);

  if (signedIn.value) {
    api
      .patch(
        `accept_collectible_card/${
          currentCard.value.id
        }?review_seconds=${getCardReviewTime()}`,
      )
      .then(() =>
        userStore.fetchAvailableCollectibleCardCount().then(() => {
          completedCardIds.value.push(currentCard.value.id);
          concludeSwipe();
        }),
      );
  } else {
    collectibleCards.value.push({
      id: currentCard.value.id,
      userReaction: "accepted",
      reviewSeconds: reviewSeconds,
    });
    const taskPayload = {
      id: moment().valueOf(),
      name: currentCard.value.taskName,
      note: null,
      dueDate: null,
      repeat: null,
      repeatEndDate: null,
      priority: null,
      taskListName: "News",
      completedAt: null,
      contentId: currentCard.value.id,
      contentType: "CollectibleCard",
    };
    guestTasks.value.push(taskPayload);
    availableCollectibleCardCount.value--;
    completedCardIds.value.push(currentCard.value.id);
    concludeSwipe();
  }
}

const modalStore = useModalStore();
const unlockerStore = useUnlockerStore();
const { resetRequired, upgradeSuccessful } = storeToRefs(unlockerStore);
const upgradeInProgress = ref(false);
const readyToUnlock = computed(() => {
  if (upgradeInProgress.value) {
    return upgradeSuccessful.value;
  } else {
    return true;
  }
});

onBeforeUnmount(() => {
  if (upgradeInProgress.value) {
    upgradeSuccessful.value = false;
  }
});

watch(resetRequired, () => {
  if (upgradeInProgress.value) {
    resetRequired.value = false;
    upgradeInProgress.value = false;
  }
});

function onSwipeTop() {
  if (topObj.value) {
    // Swipe active card to top.
    removeNoTransition();
    transformUi(0, -1000, 0, currentElementObj.value);
    if (useOverlays.value) {
      transformUi(0, -1000, 0, leftObj.value); //Move leftOverlay
      transformUi(0, -1000, 0, rightObj.value); //Move rightOverlay
      transformUi(0, -1000, 0, topObj.value); //Move topOverlay
      resetOverlays();
    }
  }

  flipCard();
}
async function flipCard() {
  if (readyToUnlock.value) {
    upgradeInProgress.value = false;
    upgradeSuccessful.value = false;
    flipped.value = true;
  }
}
function removeNoTransition() {
  //Remove transitions from all elements to be moved in each swipe movement to improve perfomance of stacked cards.
  if (listElNodesObj.value[currentPosition.value]) {
    if (useOverlays.value) {
      leftObj.value.classList.remove("no-transition");
      rightObj.value.classList.remove("no-transition");
      if (topObj.value) topObj.value.classList.remove("no-transition");
    }

    listElNodesObj.value[currentPosition.value].classList.remove(
      "no-transition",
    );
    listElNodesObj.value[currentPosition.value].style.zIndex = 6;
  }
}
function resetZIndices() {
  if (topObj.value) topObj.value.style.zIndex = "0";
  leftObj.value.style.zIndex = "0";
  rightObj.value.style.zIndex = "0";
}
function resetOverlayLeft() {
  // Move the overlay left to initial position.
  if (!(currentPosition.value >= maxElements.value)) {
    if (useOverlays.value) {
      setTimeout(function () {
        if (stackedOptions.value === "Top") {
          elTrans.value = elementsMargin.value * (items.value - 1);
        } else if (
          stackedOptions.value === "Bottom" ||
          stackedOptions.value === "None"
        ) {
          elTrans.value = 0;
        }

        if (!isFirstTime.value) {
          leftObj.value.classList.add("no-transition");
          if (topObj.value) topObj.value.classList.add("no-transition");
        }

        requestAnimationFrame(function () {
          leftObj.value.style.transform =
            "translateX(0) translateY(" + elTrans.value + "px) translateZ(0)";
          leftObj.value.style.webkitTransform =
            "translateX(0) translateY(" + elTrans.value + "px) translateZ(0)";
          leftObj.value.style.opacity = "0";

          if (topObj.value) {
            topObj.value.style.transform =
              "translateX(0) translateY(" + elTrans.value + "px) translateZ(0)";
            topObj.value.style.webkitTransform =
              "translateX(0) translateY(" + elTrans.value + "px) translateZ(0)";
            topObj.value.style.opacity = "0";
          }
        });

        resetZIndices();
      }, 300);

      isFirstTime.value = false;
    }
  }
}
function resetOverlayRight() {
  // Move the overlay right to initial position.
  if (!(currentPosition.value >= maxElements.value)) {
    if (useOverlays.value) {
      setTimeout(function () {
        if (stackedOptions.value === "Top") {
          +2;

          elTrans.value = elementsMargin.value * (items.value - 1);
        } else if (
          stackedOptions.value === "Bottom" ||
          stackedOptions.value === "None"
        ) {
          elTrans.value = 0;
        }

        if (!isFirstTime.value) {
          rightObj.value.classList.add("no-transition");
          if (topObj.value) topObj.value.classList.add("no-transition");
        }

        requestAnimationFrame(function () {
          rightObj.value.style.transform =
            "translateX(0) translateY(" + elTrans.value + "px) translateZ(0)";
          rightObj.value.style.webkitTransform =
            "translateX(0) translateY(" + elTrans.value + "px) translateZ(0)";
          rightObj.value.style.opacity = "0";

          if (topObj.value) {
            topObj.value.style.transform =
              "translateX(0) translateY(" + elTrans.value + "px) translateZ(0)";
            topObj.value.style.webkitTransform =
              "translateX(0) translateY(" + elTrans.value + "px) translateZ(0)";
            topObj.value.style.opacity = "0";
          }
        });

        resetZIndices();
      }, 300);

      isFirstTime.value = false;
    }
  }
}
function resetOverlays() {
  // Move the overlays to initial position.
  if (!(currentPosition.value >= maxElements.value)) {
    if (useOverlays.value) {
      setTimeout(function () {
        if (stackedOptions.value === "Top") {
          elTrans.value = elementsMargin.value * (items.value - 1);
        } else if (
          stackedOptions.value === "Bottom" ||
          stackedOptions.value === "None"
        ) {
          elTrans.value = 0;
        }

        if (!isFirstTime.value) {
          leftObj.value.classList.add("no-transition");
          rightObj.value.classList.add("no-transition");
          if (topObj.value) topObj.value.classList.add("no-transition");
        }

        requestAnimationFrame(function () {
          leftObj.value.style.transform =
            "translateX(0) translateY(" + elTrans.value + "px) translateZ(0)";
          leftObj.value.style.webkitTransform =
            "translateX(0) translateY(" + elTrans.value + "px) translateZ(0)";
          leftObj.value.style.opacity = "0";

          rightObj.value.style.transform =
            "translateX(0) translateY(" + elTrans.value + "px) translateZ(0)";
          rightObj.value.style.webkitTransform =
            "translateX(0) translateY(" + elTrans.value + "px) translateZ(0)";
          rightObj.value.style.opacity = "0";

          if (topObj.value) {
            topObj.value.style.transform =
              "translateX(0) translateY(" + elTrans.value + "px) translateZ(0)";
            topObj.value.style.webkitTransform =
              "translateX(0) translateY(" + elTrans.value + "px) translateZ(0)";
            topObj.value.style.opacity = "0";
          }
        });

        resetZIndices();
      }, 300); // wait for animations time

      isFirstTime.value = false;
    }
  }
}
function setActiveHidden() {
  if (!(currentPosition.value >= maxElements.value)) {
    listElNodesObj.value[currentPosition.value - 1].classList.remove(
      "stackedcards-active",
    );
    listElNodesObj.value[currentPosition.value - 1].classList.add(
      "stackedcards-hidden",
    );
    listElNodesObj.value[currentPosition.value].classList.add(
      "stackedcards-active",
    );
  }
}
function setZindex(zIndex) {
  //Set the new z-index for specific card.
  if (listElNodesObj.value[currentPosition.value]) {
    listElNodesObj.value[currentPosition.value].style.zIndex = zIndex;
  }
}
function transformUi(moveX, moveY, opacity, elementObj) {
  //Add translate X and Y to active card for each frame.
  requestAnimationFrame(function () {
    var element = elementObj;
    let rotateElement;

    // Function to generate rotate value
    function RotateRegulator(value) {
      if (value / 10 > 15) {
        return 15;
      } else if (value / 10 < -15) {
        return -15;
      }

      return value / 10;
    }

    if (rotate.value) {
      rotateElement = RotateRegulator(moveX);
    } else {
      rotateElement = 0;
    }

    if (stackedOptions.value === "Top") {
      elTrans.value = elementsMargin.value * (items.value - 1);
      if (element) {
        element.style.webkitTransform =
          "translateX(" +
          moveX +
          "px) translateY(" +
          (moveY + elTrans.value) +
          "px) translateZ(0) rotate(" +
          rotateElement +
          "deg)";
        element.style.transform =
          "translateX(" +
          moveX +
          "px) translateY(" +
          (moveY + elTrans.value) +
          "px) translateZ(0) rotate(" +
          rotateElement +
          "deg)";
        element.style.opacity = opacity;
      }
    } else if (
      stackedOptions.value === "Bottom" ||
      stackedOptions.value === "None"
    ) {
      if (element) {
        element.style.webkitTransform =
          "translateX(" +
          moveX +
          "px) translateY(" +
          moveY +
          "px) translateZ(0) rotate(" +
          rotateElement +
          "deg)";
        element.style.transform =
          "translateX(" +
          moveX +
          "px) translateY(" +
          moveY +
          "px) translateZ(0) rotate(" +
          rotateElement +
          "deg)";
        element.style.opacity = opacity;
      }
    }
  });
}
function updateUi() {
  //Action to update all elements on the DOM for each stacked card.
  requestAnimationFrame(function () {
    elTrans.value = 0;
    var elZindex = 5;
    var elScale = 1;
    var elOpac = 1;
    var elTransTop = items.value;
    var elTransInc = elementsMargin.value;

    for (
      let i = currentPosition.value;
      i < currentPosition.value + items.value;
      i++
    ) {
      if (listElNodesObj.value[i]) {
        if (stackedOptions.value === "Top") {
          listElNodesObj.value[i].classList.add(
            "stackedcards-top",
            "stackedcards--animatable",
            "stackedcards-origin-top",
          );

          if (useOverlays.value) {
            leftObj.value.classList.add("stackedcards-origin-top");
            rightObj.value.classList.add("stackedcards-origin-top");
            if (topObj.value)
              topObj.value.classList.add("stackedcards-origin-top");
          }

          elTrans.value = elTransInc * elTransTop;
          elTransTop--;
        } else if (stackedOptions.value === "Bottom") {
          listElNodesObj.value[i].classList.add(
            "stackedcards-bottom",
            "stackedcards--animatable",
            "stackedcards-origin-bottom",
          );

          if (useOverlays.value) {
            leftObj.value.classList.add("stackedcards-origin-bottom");
            rightObj.value.classList.add("stackedcards-origin-bottom");
            if (topObj.value)
              topObj.value.classList.add("stackedcards-origin-bottom");
          }

          elTrans.value = elTrans.value + elTransInc;
        } else if (stackedOptions.value === "None") {
          listElNodesObj.value[i].classList.add(
            "stackedcards-none",
            "stackedcards--animatable",
          );
          elTrans.value = elTrans.value + elTransInc;
        }

        listElNodesObj.value[i].style.transform =
          "scale(" +
          elScale +
          ") translateX(0) translateY(" +
          (elTrans.value - elTransInc) +
          "px) translateZ(0)";
        listElNodesObj.value[i].style.webkitTransform =
          "scale(" +
          elScale +
          ") translateX(0) translateY(" +
          (elTrans.value - elTransInc) +
          "px) translateZ(0)";
        listElNodesObj.value[i].style.opacity = elOpac;
        listElNodesObj.value[i].style.zIndex = elZindex;

        elScale = elScale - 0.04;
        elOpac = elOpac - 1 / items.value;
        elZindex--;
      }
    }
  });
}
function setOverlayOpacity() {
  topOpacity.value = ((translateY.value + elementHeight.value / 2) / 100) * -1;
  rightOpacity.value = translateX.value / 100;
  leftOpacity.value = (translateX.value / 100) * -1;

  if (topOpacity.value > 1) {
    topOpacity.value = 1;
  }

  if (rightOpacity.value > 1) {
    rightOpacity.value = 1;
  }

  if (leftOpacity.value > 1) {
    leftOpacity.value = 1;
  }
}
function gestureStart(evt) {
  startTime.value = new Date().getTime();

  startX.value = evt.changedTouches[0].clientX;
  startY.value = evt.changedTouches[0].clientY;

  currentX.value = startX.value;
  currentY.value = startY.value;

  setOverlayOpacity();

  touchingElement.value = true;
  if (!(currentPosition.value >= maxElements.value)) {
    if (listElNodesObj.value[currentPosition.value]) {
      listElNodesObj.value[currentPosition.value].classList.add(
        "no-transition",
      );
      setZindex(6);

      if (useOverlays.value) {
        leftObj.value.classList.add("no-transition");
        rightObj.value.classList.add("no-transition");
        if (topObj.value) topObj.value.classList.add("no-transition");
      }

      if (currentPosition.value + 1 < maxElements.value) {
        listElNodesObj.value[currentPosition.value + 1].style.opacity = "1";
      }

      elementHeight.value =
        listElNodesObj.value[currentPosition.value].offsetHeight / 3;
    }
  }
}
function gestureMove(evt) {
  currentX.value = evt.changedTouches[0].pageX;
  currentY.value = evt.changedTouches[0].pageY;

  translateX.value = currentX.value - startX.value;
  translateY.value = currentY.value - startY.value;

  setOverlayOpacity();

  if (!(currentPosition.value >= maxElements.value)) {
    evt.preventDefault();
    transformUi(translateX.value, translateY.value, 1, currentElementObj.value);

    if (useOverlays.value) {
      if (topObj.value) {
        transformUi(
          translateX.value,
          translateY.value,
          topOpacity.value,
          topObj.value,
        );
      }

      if (translateX.value < 0) {
        transformUi(
          translateX.value,
          translateY.value,
          leftOpacity.value,
          leftObj.value,
        );
        transformUi(0, 0, 0, rightObj.value);
      } else if (translateX.value > 0) {
        transformUi(
          translateX.value,
          translateY.value,
          rightOpacity.value,
          rightObj.value,
        );
        transformUi(0, 0, 0, leftObj.value);
      }

      if (useOverlays.value) {
        leftObj.value.style.zIndex = 8;
        rightObj.value.style.zIndex = 8;
        if (topObj.value) topObj.value.style.zIndex = 7;
      }
    }
  }
}
function gestureEnd() {
  if (!touchingElement.value) {
    return;
  }

  translateX.value = currentX.value - startX.value;
  translateY.value = currentY.value - startY.value;

  timeTaken.value = new Date().getTime() - startTime.value;

  touchingElement.value = false;

  if (!(currentPosition.value >= maxElements.value)) {
    if (
      translateY.value < elementHeight.value * -1 &&
      translateX.value > (listElNodesWidth.value / 2) * -1 &&
      translateX.value < listElNodesWidth.value / 2
    ) {
      //is Top?
      if (topObj.value) {
        if (
          translateY.value < elementHeight.value * -1 ||
          Math.abs(translateY.value) / timeTaken.value > velocity.value
        ) {
          // Did It Move To Top?
          onSwipeTop();
        } else {
          backToMiddle();
        }
      } else {
        backToMiddle();
      }
    } else {
      if (translateX.value < 0) {
        if (
          translateX.value < (listElNodesWidth.value / 2) * -1 ||
          Math.abs(translateX.value) / timeTaken.value > velocity.value
        ) {
          // Did It Move To Left?
          onSwipeLeft();
        } else {
          backToMiddle();
        }
      } else if (translateX.value > 0) {
        if (
          translateX.value > listElNodesWidth.value / 2 &&
          Math.abs(translateX.value) / timeTaken.value > velocity.value
        ) {
          // Did It Move To Right?
          onSwipeRight();
        } else {
          backToMiddle();
        }
      }
    }
  }
}
</script>

<style scoped>
/* Stacked Cards component css */
.no-transition {
  -webkit-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}

.stackedcards-overflow {
  overflow-y: hidden !important;
}

.stackedcards {
  position: relative;
}

.stackedcards * {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.stackedcards--animatable {
  -webkit-transition: all 400ms ease;
  -o-transition: all 400ms ease;
  transition: all 400ms ease;
}

.stackedcards .stackedcards-container > *,
.stackedcards-overlay {
  position: absolute;
  width: 100%; /* set 100% */
  height: 100%; /* set 100% */
  will-change: transform, opacity;
  top: 0;
  border-radius: 10px;
}

.stackedcards-overlay.left > div,
.stackedcards-overlay.right > div,
.stackedcards-overlay.top > div {
  width: 100%;
  height: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.stackedcards-overlay.left,
.stackedcards-overlay.right,
.stackedcards-overlay.top {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  left: 0;
  opacity: 0;
  top: 0;
  height: 100%;
}

.stackedcards-overlay.left:empty,
.stackedcards-overlay.right:empty,
.stackedcards-overlay.top:empty {
  display: none !important;
}

.stackedcards-overlay-hidden {
  display: none;
}

.stackedcards-origin-bottom {
  -webkit-transform-origin: bottom;
  -ms-transform-origin: bottom;
  transform-origin: bottom;
}

.stackedcards-origin-top {
  -webkit-transform-origin: top;
  -ms-transform-origin: top;
  transform-origin: top;
}

.stackedcards-bottom,
.stackedcards-top,
.stackedcards-none {
  height: 100%;
}

.stackedcards .stackedcards-container > :nth-child(1) {
  position: relative;
  display: block;
}
</style>
