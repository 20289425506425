<template>
  <div class="col-span-2">
    <dt class="text-sm font-medium text-gray-500">Prospects</dt>
    <dd
      v-if="fetchedProspects.length > 0"
      :class="
        workspaceLayout === 'sideBySide'
          ? 'grid-cols-2'
          : 'grid-cols-4 grid-flow-row-dense'
      "
      class="mt-1 grid gap-2"
    >
      <div
        v-for="companyId in fetchedProspects"
        :key="`group-prospect-${companyId}`"
        class="p-2 space-y-2 rounded-md border border-dashed border-gray-300"
      >
        <InvolvedCompany
          :can-award="canAward"
          :fetch-company-id="companyId"
          layer-type="Prospect"
          layer-color="bg-cyan-400"
          :providers-count="fetchedProspects.length"
          context="availability-group-players"
          client-role="prospect"
          :availability-group-id="existingGroupId"
          :fetch-milliseconds="fetchMilliseconds"
          :parent-component-save-function="persistCompanies"
          @override-refetch="fetchProspects"
          @refetch-group-availabilities="refetchGroupAvailabilities"
        />
        <div
          class="space-y-1"
          data-test="availability-group-prospect-advisors-container"
        >
          <dt class="text-sm font-medium text-gray-500">Advisors</dt>
          <ContentCompaniesContacts
            :fetch-company-id="companyId"
            :availability-group-id="existingGroupId"
            :fetch-milliseconds="fetchMilliseconds"
            context="prospect"
          />
        </div>
      </div>
    </dd>
    <div v-if="editingProspects" class="mt-1 flex flex-col space-y-1">
      <div
        class="border border-gray-300 rounded-lg shadow-sm focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500"
      >
        <CompanyContactAutocomplete
          label="Prospects"
          :companies="localProspects"
          :input-key="`availability-group-prospects`"
          @new-companies="setNewCompanies"
          @remove-company="removeCompany"
          @set-cross-interaction="setCrossInteraction"
        />
      </div>
      <div v-if="existingGroupId" class="flex justify-end space-x-2">
        <button
          @click="cancelProspectEditing"
          type="button"
          v-tooltip="'Cancel'"
          class="inline-flex items-center p-1 border border-gray-300 rounded-full shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <XMarkIcon class="h-4 w-4" />
        </button>

        <DataVisibilityButton visibility="safezone" class="inline-flex">
          <template v-slot:button>
            <button
              @click="saveProspects"
              :disabled="originatingData"
              type="button"
              :data-test="`availability-group-prospects-save`"
              class="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-yellow-500 hover:bg-yellow-600 focus:ring-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2"
            >
              <PulseLoader
                v-if="originatingData"
                :loading="true"
                size="3px"
                color="#f3f4f6"
              />
              <CheckIcon v-else class="h-4 w-4" />
            </button>
          </template>
        </DataVisibilityButton>
      </div>
    </div>
    <dd v-else-if="localProspects.length > 0" class="mt-1 flex flex-wrap">
      <li
        @click="editingProspects = true"
        v-for="company in localProspects"
        :key="company"
        v-tooltip="'Unsaved'"
        class="inline-flex rounded-full items-center my-1 mr-1 py-0.5 px-2.5 text-sm font-medium bg-indigo-100 text-indigo-700"
      >
        {{ company }}
      </li>
    </dd>
    <div v-else class="mt-2 flex items-center space-x-2">
      <DataVisibilityButton visibility="safezone">
        <template v-slot:button>
          <button
            @click="editingProspects = true"
            type="button"
            :data-test="`availability-group-prospects-add-button`"
            class="group py-1 px-1.5 flex items-center justify-between rounded-full border border-gray-300 shadow-sm space-x-2 text-left hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <span class="min-w-0 flex-1 flex items-center space-x-1">
              <span class="flex-shrink-0 flex items-center">
                <span
                  class="inline-flex items-center justify-center h-5 w-5 rounded-full bg-yellow-500"
                >
                  <BriefcaseIcon class="h-4 w-4 text-white" />
                </span>
              </span>
              <span class="min-w-0 flex-1">
                <span class="text-sm font-medium text-gray-900 truncate"
                  >Add companies</span
                >
              </span>
            </span>
          </button>
        </template>
      </DataVisibilityButton>
    </div>
  </div>
</template>

<script setup>
import { XMarkIcon, CheckIcon } from "@heroicons/vue/20/solid";
import { BriefcaseIcon } from "@heroicons/vue/24/outline";
import CompanyContactAutocomplete from "@/components/crowdsourcing/CompanyContactAutocomplete.vue";
import ContentCompaniesContacts from "@/components/crowdsourcing/ContentCompaniesContacts.vue";
import InvolvedCompany from "@/components/crowdsourcing/InvolvedCompany.vue";
import { useSpaceUsageBuilderStore } from "@/stores/spaceUsageBuilder";
import { useCrowdsourcedChangeGroupStore } from "@/stores/crowdsourcedChangeGroup";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { ref, computed, onMounted } from "vue";
import { storeToRefs } from "pinia";
import api from "@/router/api";
import moment from "moment";
import _ from "lodash";
import DataVisibilityButton from "@/components/crowdsourcing/DataVisibilityButton.vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";

const props = defineProps([
  "existingGroupId",
  "placeholderGroupId",
  "fetchMilliseconds",
]);

const spaceUsageBuilderStore = useSpaceUsageBuilderStore();
const { spaceUsageBuilder, availabilityGroups } = storeToRefs(
  spaceUsageBuilderStore,
);
const changeGroupStore = useCrowdsourcedChangeGroupStore();
const { originatingData, changeGroupId } = storeToRefs(changeGroupStore);
const layoutStore = useWorkspaceLayoutStore();
const { workspaceLayout } = storeToRefs(layoutStore);

const fetchedProspects = ref([]);
const editingProspects = ref(false);

const canAward = computed(() => {
  return groupState.value === "live";
});
const availabilityGroupId = computed(() => {
  return props.existingGroupId || props.placeholderGroupId;
});
const availabilityGroup = computed(() => {
  const finder = props.existingGroupId
    ? { id: props.existingGroupId }
    : { placeholderId: props.placeholderGroupId };

  return _.find(availabilityGroups.value, finder);
});
const availabilities = computed(() => {
  return _.get(availabilityGroup.value, "availabilities", {});
});
const groupState = computed(() => {
  if (groupDateState.value.states.length === 1) {
    return _.head(groupDateState.value.states);
  } else {
    return null;
  }
});
const groupDateState = computed(() => {
  const availabilities = _.map(
    _.get(availabilityGroup.value, "availabilities", []),
    function (groupAvailability) {
      return focalAvailabilityFor(groupAvailability);
    },
  );
  const ids = availabilities.map((i) => i.id);
  const dates = _.uniq(
    availabilities.map((i) => {
      if (i.date) {
        return moment(i.date).format("YYYYMMDD");
      } else {
        return null;
      }
    }),
  );
  const states = _.uniq(availabilities.map((i) => i.state));

  console.log(ids, dates, states);

  return { ids, dates, states };
});
const localProspects = computed(() => {
  const combinedProspects = _.flatMap(
    availabilities.value,
    function (availability) {
      return _.get(
        availability,
        "existingAvailability.prospects",
        availability.prospects,
      );
    },
  );

  return _.compact(
    _.uniq(combinedProspects, function (c) {
      return c.name;
    }),
  );
});

onMounted(() => {
  fetchProspects();
});

function focalAvailabilityFor(groupAvailability) {
  return _.get(groupAvailability, "existingAvailability") || groupAvailability;
}
function setNewCompanies(newCompanies) {
  const addedCompanies = _.differenceBy(
    newCompanies,
    localProspects.value,
    "name",
  );

  _.forEach(availabilities.value, function (availability) {
    addCompanies(addedCompanies, availability.temporaryId);
  });
}
function addCompanies(addedCompanies, combinedKey) {
  spaceUsageBuilderStore.addAvailabilityGroupAvailabilityPlayers({
    groupId: availabilityGroupId.value,
    availabilityCombinedKey: combinedKey,
    addedCompanies,
    path: "prospects",
    compareBy: function (c) {
      return `${c.name}`;
    },
  });
}
function removeCompany(company) {
  _.forEach(availabilities.value, function (availability) {
    spaceUsageBuilderStore.removeAvailabilityGroupAvailabilityPlayer({
      groupId: availabilityGroupId.value,
      availabilityCombinedKey: availability.temporaryId,
      toRemove: company,
      path: "prospects",
      compareBy: function (c) {
        return `${c.name}`;
      },
    });
  });
}
function setCrossInteraction() {}
function cancelProspectEditing() {
  editingProspects.value = false;
}
function clearProspectEditing() {
  localProspects.value.forEach((company) => {
    removeCompany(company);
  });
  cancelProspectEditing();
}
const persistCompanies = async (proofCompanies = null) => {
  const payload = {
    availabilityGroupId: props.existingGroupId,
    prospects: proofCompanies || localProspects.value,
    changeGroupId: changeGroupId.value,
  };

  const response = await api.post(`availability_group_prospects`, payload);

  console.log("group prospects persist", proofCompanies, payload, response);

  return response;
};
function saveProspects() {
  setTimeout(() => {
    if (props.existingGroupId) {
      const apiRequestFunc = () => persistCompanies();
      const successCallback = () => {
        clearProspectEditing();
        refetchGroupAvailabilities();
        fetchProspects();
        // Flash: "Prospects added to all availabilities in the portfolio."
      };
      const failureCallback = () => clearProspectEditing();

      return changeGroupStore.originateData(
        apiRequestFunc,
        successCallback,
        failureCallback,
      );
    }
  }, 125);
}
function fetchProspects() {
  if (props.existingGroupId) {
    api
      .get(
        `availability_group_prospects/?availability_group_id=${props.existingGroupId}`,
      )
      .then((json) => {
        fetchedProspects.value = json.data;
      });
  }
}
function refetchGroupAvailabilities() {
  spaceUsageBuilderStore.fetchAvailabilityGroupAvailabilities(
    props.existingGroupId,
  );
}
</script>
