<template>
  <tr
    v-if="singleLoanId"
    v-observe-visibility="{ callback: fetchSingleLoan, once: true }"
    class="bg-orange-50"
  >
    <td>
      <span class="sr-only">Empty</span>
    </td>
    <td
      v-if="loanDataField"
      @mouseenter="pulseProperty"
      @mouseleave="propertyMarkerPulseId = null"
      class="whitespace-nowrap py-2 pr-3 pl-4"
    >
      <DataField
        v-if="loanDataField"
        :data-field="loanDataField"
        text-classes="text-sm font-medium"
        dropdown-placement="left-start"
        text-styles=""
        :analyze="true"
        @completed="refetchAll"
        @unlocked="refetchAll"
        @open-sourced="refetchAll"
      />
    </td>
    <td v-else class="whitespace-nowrap px-2 py-2 text-xs text-gray-500">
      <span>Unlock</span>
    </td>
    <td class="whitespace-nowrap px-2 py-2 text-xs text-gray-500">
      <span class="sr-only">Empty</span>
    </td>
    <td class="whitespace-nowrap px-2 py-2 text-xs text-gray-500">
      <div class="grid grid-cols-2 gap-x-2">
        <PrincipalsDetail
          v-if="loanDataField?.fieldContent?.investment?.fieldContent"
          transaction-context="capMarkets"
          transaction-side="supply"
          display-context="table-row"
          :investment="loanDataField?.fieldContent?.investment?.fieldContent"
        />
        <PrincipalsDetail
          v-if="loanDataField?.fieldContent?.investment?.fieldContent"
          transaction-context="capMarkets"
          transaction-side="demand"
          display-context="table-row"
          :investment="loanDataField?.fieldContent?.investment?.fieldContent"
        />
      </div>
    </td>
    <td class="whitespace-nowrap px-2 py-2 text-xs text-gray-500">
      <DataField
        v-if="loanAmountDataField"
        :data-field="loanAmountDataField"
        text-classes="text-sm"
        :analyze="true"
      />
    </td>
    <td class="whitespace-nowrap px-2 py-2 text-xs text-gray-500">
      <BranchingCalcFieldChooser
        v-if="loanDataField"
        field-name="maturity_date"
        context="analyze"
        :decorating-content-data-field="loanDataField"
        :prove-for-value="null"
        @unlocked="refetchAll"
        @completed="refetchAll"
        @open-sourced="refetchAll"
      />
    </td>
  </tr>

  <tr v-else-if="multiPropertyPortfolioLoanPropertyId" class="bg-orange-50">
    <td>
      <span class="sr-only">Empty</span>
    </td>
    <td
      v-if="propertyDataField"
      @mouseenter="pulseProperty"
      @mouseleave="propertyMarkerPulseId = null"
      class="whitespace-nowrap py-2 pr-3 pl-4"
    >
      <DataField
        v-if="propertyDataField"
        :data-field="propertyDataField"
        text-classes="text-sm font-medium"
        dropdown-placement="left-start"
        text-styles=""
        :analyze="true"
        @completed="refetchAll"
        @unlocked="refetchAll"
        @open-sourced="refetchAll"
      />
    </td>
    <td v-else class="whitespace-nowrap px-2 py-2 text-xs text-gray-500">
      <span>Unlock</span>
    </td>
    <td class="whitespace-nowrap px-2 py-2 text-xs text-gray-500">
      <DataField
        v-if="locationDataField"
        :data-field="locationDataField"
        text-classes="text-sm"
        :analyze="true"
      />
      <template v-else>Unlock</template>
    </td>
    <td class="whitespace-nowrap px-2 py-2 text-xs text-gray-500">
      <span class="sr-only">Empty</span>
    </td>
    <td class="whitespace-nowrap px-2 py-2 text-xs text-gray-500">
      <span class="sr-only">Empty</span>
    </td>
  </tr>

  <tr
    v-if="record?.rowType === 'singlePropertyLoan'"
    v-observe-visibility="{ callback: fetchSinglePropertyLoans, once: true }"
  >
    <td
      class="relative whitespace-nowrap flex items-center py-2 px-3 text-sm font-medium"
    >
      <button
        @click="expanded = !expanded"
        type="button"
        v-tooltip="expanded ? 'Hide loans' : 'View loans'"
        class="h-5 w-5 inline-flex justify-center items-center text-sm text-gray-700 hover:text-gray-900"
      >
        <ChevronDownIcon class="h-5 w-5" />
      </button>
    </td>
    <td
      v-if="propertyDataField"
      @mouseenter="pulseProperty"
      @mouseleave="propertyMarkerPulseId = null"
      class="whitespace-nowrap py-2 pr-3"
    >
      <DataField
        v-if="propertyDataField"
        :data-field="propertyDataField"
        primary-text-path="fieldContent.name"
        text-classes="text-sm font-medium"
        dropdown-placement="left-start"
        text-styles=""
        :analyze="true"
        @completed="refetchAll"
        @unlocked="refetchAll"
        @open-sourced="refetchAll"
      />
    </td>
    <td v-else class="whitespace-nowrap px-2 py-2 text-xs text-gray-500">
      <span>Unlock loans below</span>
    </td>
    <td class="whitespace-nowrap px-2 py-2 text-xs text-gray-500">
      <DataField
        v-if="locationDataField"
        :data-field="locationDataField"
        text-classes="text-sm"
        :analyze="true"
      />
      <template v-else>Unlock</template>
    </td>
    <td
      @click="expanded = !expanded"
      class="whitespace-nowrap px-2 py-2 text-xs text-gray-500 cursor-pointer"
    >
      {{ expanded ? "See below" : "Expand to see" }}
    </td>
    <td
      @click="expanded = !expanded"
      class="whitespace-nowrap px-2 py-2 text-xs text-gray-500 cursor-pointer"
    >
      {{ expanded ? "See below" : "Expand to see" }}
    </td>
  </tr>

  <tr
    v-else-if="record?.rowType === 'singlePropertyPortfolioLoan'"
    v-observe-visibility="{
      callback: fetchSinglePropertyPortfolioLoans,
      once: true,
    }"
  >
    <td>
      <span class="sr-only">Empty</span>
    </td>
    <td
      v-if="investmentGroupDataField && propertyDataField"
      @mouseenter="pulseProperty"
      @mouseleave="propertyMarkerPulseId = null"
      class="whitespace-nowrap py-2 pr-3"
    >
      <DataField
        v-if="investmentGroupDataField"
        :data-field="investmentGroupDataField"
        :property-data-field="propertyDataField"
        text-classes="text-sm font-medium"
        dropdown-placement="left-start"
        text-styles=""
        :analyze="true"
        @completed="refetchAll"
        @unlocked="refetchAll"
        @open-sourced="refetchAll"
      />
    </td>
    <td
      v-else-if="investmentGroupDataField"
      class="whitespace-nowrap py-2 pr-3"
    >
      <DataField
        v-if="investmentGroupDataField"
        :data-field="investmentGroupDataField"
        :property-data-field="propertyDataField"
        text-classes="text-sm font-medium"
        dropdown-placement="left-start"
        text-styles=""
        :analyze="true"
        @completed="refetchAll"
        @unlocked="refetchAll"
        @open-sourced="refetchAll"
      />
    </td>
    <td v-else class="whitespace-nowrap px-2 py-2 text-xs text-gray-500">
      <span>Unlock Loan</span>
    </td>
    <td class="whitespace-nowrap px-2 py-2 text-xs text-gray-500">
      <DataField
        v-if="locationDataField"
        :data-field="locationDataField"
        text-classes="text-sm"
        :analyze="true"
      />
      <template v-else>Unlock</template>
    </td>
    <td>
      <span class="sr-only">Empty</span>
    </td>
    <td class="whitespace-nowrap px-2 py-2 text-xs text-gray-500">
      <DataField
        v-if="loanAmountDataField"
        :data-field="loanAmountDataField"
        text-classes="text-sm"
        :analyze="true"
      />
    </td>
    <td class="whitespace-nowrap px-2 py-2 text-xs text-gray-500">
      <BranchingCalcFieldChooser
        v-if="loanDataField"
        field-name="maturity_date"
        context="analyze"
        :decorating-content-data-field="loanDataField"
        :prove-for-value="null"
        @unlocked="refetchAll"
        @completed="refetchAll"
        @open-sourced="refetchAll"
      />
    </td>
  </tr>

  <tr
    v-else-if="record?.rowType === 'multiPropertyPortfolioLoan'"
    v-observe-visibility="{
      callback: fetchMultiPropertyPortfolioLoans,
      once: true,
    }"
  >
    <td
      class="relative whitespace-nowrap flex items-center py-2 px-3 text-sm font-medium"
    >
      <button
        @click="expanded = !expanded"
        type="button"
        v-tooltip="expanded ? 'Hide loans' : 'View loans'"
        class="h-5 w-5 inline-flex justify-center items-center text-sm text-gray-700 hover:text-gray-900"
      >
        <ChevronDownIcon class="h-5 w-5" />
      </button>
    </td>
    <td
      v-if="investmentGroupDataField && loanDataField"
      class="whitespace-nowrap py-2 pr-3"
    >
      <div class="flex items-center space-x-1">
        <ExclamationTriangleIcon
          v-if="offMapGroupInvestments"
          class="h-5 w-5 text-orange-500"
          v-tooltip="
            `Some portfolio investments are outside the ${
              geographyFilterPolygon ? 'polygon' : 'map'
            } boundaries`
          "
        />
        <DataField
          v-if="investmentGroupDataField"
          :data-field="investmentGroupDataField"
          text-classes="text-sm font-medium"
          dropdown-placement="left-start"
          text-styles=""
          :analyze="true"
          @completed="refetchAll"
          @unlocked="refetchAll"
          @open-sourced="refetchAll"
        />
      </div>
    </td>
    <td v-else class="whitespace-nowrap px-2 py-2 text-xs text-gray-500">
      <span>Loan...</span>
    </td>
    <td
      @click="expanded = !expanded"
      class="whitespace-nowrap px-2 py-2 text-xs text-gray-500 cursor-pointer"
    >
      {{ expanded ? "See below" : "Expand to see" }}
    </td>
    <td>
      <span class="sr-only">Empty</span>
    </td>
    <td class="whitespace-nowrap px-2 py-2 text-xs text-gray-500">
      <DataField
        v-if="loanAmountDataField"
        :data-field="loanAmountDataField"
        text-classes="text-sm"
        :analyze="true"
      />
    </td>
    <td class="whitespace-nowrap px-2 py-2 text-xs text-gray-500">
      <BranchingCalcFieldChooser
        v-if="loanDataField"
        field-name="maturity_date"
        context="analyze"
        :decorating-content-data-field="loanDataField"
        :prove-for-value="null"
        @unlocked="refetchAll"
        @completed="refetchAll"
        @open-sourced="refetchAll"
      />
    </td>
  </tr>

  <template v-if="record?.rowType === 'singlePropertyLoan' && expanded">
    <LoanTableRow
      v-for="(loanRecord, index) in record.loanStubs"
      :key="`Loan${loanRecord.loanId}-${index}`"
      :single-loan-id="loanRecord.loanId"
      :single-loan-property-id="record.propertyId"
    />
  </template>

  <template
    v-if="
      record?.rowType === 'multiPropertyPortfolioLoan' &&
      multiPropertyLoanPropertyDataFields.length > 0 &&
      expanded
    "
  >
    <LoanTableRow
      v-for="(propertyField, index) in multiPropertyLoanPropertyDataFields"
      :key="`Property${propertyField?.fieldContentId}-${index}`"
      :multi-property-portfolio-loan-property-id="propertyField?.fieldContentId"
    />
    <tr v-if="offMapGroupInvestments" class="bg-orange-50">
      <td colspan="100">
        <div class="flex items-center space-x-1 py-2">
          <span class="text-xs text-gray-600 font-semibold ml-14">
            {{
              pluralize(
                "investment",
                investmentGroupDataField?.fieldContent?.investmentIds.length -
                  withinMapGroupInvestmentIds.length,
                true,
              )
            }}
            outside {{ geographyFilterPolygon ? "polygon" : "map" }} boundaries
          </span>
          <a
            href=""
            @click.prevent="viewInvestmentGroup"
            class="text-xs text-indigo-700 font-semibold underline"
            >View portfolio</a
          >
        </div>
      </td>
    </tr>
  </template>
</template>

<script setup>
import DataField from "@/components/crowdsourcing/DataField.vue";
import PrincipalsDetail from "@/components/deal-builder/PrincipalsDetail.vue";
import { useMainMapStore } from "@/stores/mainMap";
import { useAnalyzePanelStore } from "@/stores/analyzePanel";
import { useTimeTravelStore } from "@/stores/timeTravel";
import { useDealBuilderStore } from "@/stores/dealBuilder";
import { storeToRefs } from "pinia";
import { computed, ref } from "vue";
import BranchingCalcFieldChooser from "@/components/analyze/calculations/BranchingCalcFieldChooser.vue";
import pluralize from "pluralize";
import _ from "lodash";
import {
  ChevronDownIcon,
  ExclamationTriangleIcon,
} from "@heroicons/vue/20/solid";

const props = defineProps([
  "record",
  "singleLoanId",
  "singleLoanPropertyId",
  "multiPropertyPortfolioLoanPropertyId",
]);
const mapStore = useMainMapStore();
const { propertyMarkerPulseId, nearbyPropertyDataFields } =
  storeToRefs(mapStore);
const analyzePanelStore = useAnalyzePanelStore();
const {
  analyzeLoanFields,
  portfolioAssetLoans,
  filteredPortfolioAssetLoans,
  filteredInvestmentLikes,
  portfolioInvestmentFields,
  geographyFilterPolygon,
  investmentLikeFilterable,
} = storeToRefs(analyzePanelStore);
const { datedInvestmentIds } = storeToRefs(useTimeTravelStore());
const dealBuilderStore = useDealBuilderStore();

const expanded = ref(true);

const propertyDataField = computed(() => {
  if (_.isNumber(props.record?.propertyId)) {
    return analyzeLoanFields.value[`Property${props.record.propertyId}`];
  } else if (_.isNumber(props.singleLoanPropertyId)) {
    return analyzeLoanFields.value[`Property${props.singleLoanPropertyId}`];
  } else if (_.isNumber(props.record?.loanStub?.propertyId)) {
    return analyzeLoanFields.value[
      `Property${props.record.loanStub.propertyId}`
    ];
  } else if (props.multiPropertyPortfolioLoanPropertyId) {
    return analyzeLoanFields.value[
      `Property${props.multiPropertyPortfolioLoanPropertyId}`
    ];
  } else {
    return null;
  }
});
const loanDataField = computed(() => {
  if (props.singleLoanId) {
    return analyzeLoanFields.value[`Loan${props.singleLoanId}`];
  } else if (props.record?.loanStub?.loanId) {
    return analyzeLoanFields.value[`Loan${props.record.loanStub.loanId}`];
  } else {
    return null;
  }
});
const investmentGroupDataField = computed(() => {
  if (props.record?.loanStub?.investmentGroupId) {
    return analyzeLoanFields.value[
      `InvestmentGroup${props.record.loanStub.investmentGroupId}`
    ];
  } else {
    return null;
  }
});
const locationFromMap = computed(() => {
  if (props.record?.propertyId) {
    return _.get(nearbyPropertyDataFields.value, props.record.propertyId);
  } else {
    return null;
  }
});
const locationDataField = computed(() => {
  if (props.record?.propertyId || propertyDataField.value) {
    if (locationFromMap.value) {
      return locationFromMap.value;
    } else if (propertyDataField.value) {
      return propertyDataField.value.fieldContent?.locationDataField;
    } else {
      return null;
    }
  } else {
    return null;
  }
});
const multiPropertyAssetLoans = computed(() => {
  if (
    props.record.rowType === "multiPropertyPortfolioLoan" &&
    _.size(filteredPortfolioAssetLoans.value) > 0 &&
    _.size(portfolioInvestmentFields.value) > 0
  ) {
    const investmentGroupId = props.record.loanStub.investmentGroupId;
    const investmentIds = portfolioInvestmentFields.value
      .filter(
        (dataFieldStub) =>
          dataFieldStub.investmentGroupId === investmentGroupId,
      )
      .map((dataFieldStub) => dataFieldStub.investmentId);
    return filteredPortfolioAssetLoans.value.filter((loanStub) =>
      _.includes(investmentIds, loanStub.investmentId),
    );
  } else {
    return [];
  }
});
const multiPropertyGroupLoans = computed(() => {
  if (
    props.record.rowType === "multiPropertyPortfolioLoan" &&
    props.record.loanStub.investmentGroupId &&
    investmentLikeFilterable.value &&
    _.size(portfolioInvestmentFields.value) > 0
  ) {
    let includedPortfolioInvestments = [];

    const valuationMatches = _.intersectionBy(
      [
        {
          id: props.record.loanStub.investmentGroupId,
          type: "InvestmentGroup",
        },
      ],
      filteredInvestmentLikes.value,
      function ({ id, type }) {
        return `${type}${id}`;
      },
    );
    if (_.size(valuationMatches) > 0) {
      includedPortfolioInvestments = portfolioInvestmentFields.value
        .filter(
          (dataFieldStub) =>
            dataFieldStub.investmentGroupId ===
            props.record.loanStub.investmentGroupId,
        )
        .map(({ investmentId }) => {
          return _.find(portfolioAssetLoans.value, { investmentId });
        });
    }

    return includedPortfolioInvestments;
  } else {
    return [];
  }
});
const multiPropertyPortfolioLoans = computed(() => {
  return _.uniqBy(
    _.concat(multiPropertyAssetLoans.value, multiPropertyGroupLoans.value),
    "propertyId",
  );
});
const multiPropertyLoanPropertyDataFields = computed(() => {
  if (_.size(multiPropertyPortfolioLoans.value) > 0) {
    return multiPropertyPortfolioLoans.value.map(({ propertyId }) => {
      return analyzeLoanFields.value[`Property${propertyId}`];
    });
  } else {
    return [];
  }
});
const withinMapGroupInvestmentIds = computed(() => {
  if (multiPropertyPortfolioLoans.value.length > 0) {
    return multiPropertyPortfolioLoans.value.filter((loanStub) => {
      return _.includes(datedInvestmentIds.value, loanStub.investmentId);
    });
  } else {
    return [];
  }
});
const offMapGroupInvestments = computed(() => {
  const portfolioInvestmentIds =
    investmentGroupDataField.value?.fieldContent?.investmentIds;

  return (
    portfolioInvestmentIds &&
    portfolioInvestmentIds.length > 0 &&
    multiPropertyPortfolioLoans.value.length !== portfolioInvestmentIds.length
  );
});

const loanAmountDataField = computed(() => {
  if (loanDataField.value?.fieldContentId) {
    const loanAmountFetchKey = `Loan${loanDataField.value?.fieldContentId}fully_funded_loan_amount`;

    return analyzeLoanFields.value[loanAmountFetchKey];
  } else {
    return null;
  }
});

async function fetchSinglePropertyLoans() {
  if (
    props.record.rowType === "singlePropertyLoan" &&
    _.isNumber(props.record.propertyId)
  ) {
    analyzePanelStore.fetchPropertyDataField(
      props.record.propertyId,
      "loanFields",
    );
  }
}

async function fetchSinglePropertyPortfolioLoans() {
  if (props.record.rowType === "singlePropertyPortfolioLoan") {
    if (_.isNumber(props.record.loanStub.propertyId)) {
      analyzePanelStore.fetchPropertyDataField(
        props.record.loanStub.propertyId,
        "loanFields",
      );
    }
    await analyzePanelStore.fetchLoanDataField(
      props.record.loanStub.loanId,
      "loanFields",
    );
    fetchLoanAmount();
    analyzePanelStore.fetchInvestmentGroupDataField(
      props.record.loanStub.investmentGroupId,
      `InvestmentGroup${props.record.loanStub.investmentGroupId}`,
      "loanFields",
    );
  }
}

async function fetchMultiPropertyPortfolioLoans() {
  if (props.record.rowType === "multiPropertyPortfolioLoan") {
    await analyzePanelStore.fetchLoanDataField(
      props.record.loanStub.loanId,
      "loanFields",
    );
    fetchLoanAmount();
    analyzePanelStore.fetchInvestmentGroupDataField(
      props.record.loanStub.investmentGroupId,
      `InvestmentGroup${props.record.loanStub.investmentGroupId}`,
      "loanFields",
    );

    for (const { propertyId } of multiPropertyPortfolioLoans.value) {
      if (_.isNumber(propertyId)) {
        analyzePanelStore.fetchPropertyDataField(propertyId, "loanFields");
      }
    }
  }
}

async function fetchSingleLoan() {
  if (props.singleLoanId) {
    await analyzePanelStore.fetchLoanDataField(props.singleLoanId);
    fetchLoanAmount();
  }
}

async function fetchLoanAmount() {
  if (loanDataField.value?.fieldContentId) {
    await analyzePanelStore.fetchLoanAmountFor(
      null,
      null,
      loanDataField.value?.fieldContentId,
      "loanFields",
    );
  }
}

function pulseProperty() {
  if (propertyDataField.value) {
    const pulseId =
      propertyDataField.value.fieldContent?.groundLayerPropertyId ||
      propertyDataField.value.fieldContentId;
    propertyMarkerPulseId.value = pulseId;
  }
}

function clearRow() {
  console.log("clear row", props.record.rowType);
}

function refetchAll() {
  console.log("loan row refetch all", props.record.rowType);
  clearRow();
  if (props.singleLoanId) {
    delete analyzeLoanFields.value[`Loan${props.singleLoanId}`];
    fetchSingleLoan();
  } else {
    switch (props.record.rowType) {
      case "multiPropertyPortfolioLoan":
        console.log("refetch multiPropertyPortfolio");
        break;
      case "singlePropertyPortfolioLoan":
        console.log("refetch singlePropertyPortfolio");
        break;
      case "singlePropertyLoan":
        delete analyzeLoanFields.value[`Property${props.record.propertyId}`];
        fetchSinglePropertyLoans();
        break;
    }
  }
}

function viewInvestmentGroup() {
  if (investmentGroupDataField.value) {
    dealBuilderStore.upsertDealBuilder({
      existingGroupId: investmentGroupDataField.value.fieldContentId,
    });
  }
}
</script>
