<template>
  <div class="bg-white overflow-hidden">
    <div class="p-2">
      <dl class="grid grid-cols-1 gap-x-4 gap-y-8">
        <div class="col-span-1">
          <dt class="text-sm font-medium text-gray-500">Space Needs</dt>
          <dd class="mt-2 space-y-2">
            <OccupierSpaceDefinitionCreate
              v-if="editing"
              :data-field="dataField"
              @refetch="refetch"
              @cancel="cancel"
            />

            <DataVisibilityButton v-else visibility="safezone">
              <template v-slot:button>
                <button
                  @click="edit"
                  :disabled="originatingData"
                  type="button"
                  class="group py-1 px-1.5 flex items-center justify-between rounded-full border border-gray-300 shadow-sm space-x-2 text-left hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  <PulseLoader
                    v-if="originatingData"
                    :loading="true"
                    size="3px"
                    color="#f3f4f6"
                  />
                  <span
                    v-else
                    class="min-w-0 flex-1 flex items-center space-x-1"
                  >
                    <span class="flex-shrink-0 flex items-center">
                      <span
                        class="inline-flex items-center justify-center h-5 w-5 rounded-full bg-yellow-500"
                      >
                        <PencilIcon class="h-3 w-3 text-white" />
                      </span>
                    </span>
                    <span class="min-w-0 flex-1">
                      <span class="text-sm font-medium text-gray-900 truncate"
                        >Add space requirement</span
                      >
                    </span>
                  </span>
                </button>
              </template>
            </DataVisibilityButton>

            <SquareLoader
              v-if="!requirementsLoaded"
              :loading="true"
              size="24px"
              color="#4338ca"
            />

            <HuntSpaceRequirement
              v-for="dataField in requirements"
              :key="dataField.localId"
              :data-field="dataField"
              @refetch="refetch"
            />
          </dd>
        </div>
      </dl>
    </div>
  </div>
</template>

<script setup>
import { PencilIcon } from "@heroicons/vue/24/outline";
import DataVisibilityButton from "@/components/crowdsourcing/DataVisibilityButton.vue";
import OccupierSpaceDefinitionCreate from "@/components/crowdsourcing/OccupierSpaceDefinitionCreate.vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import SquareLoader from "vue-spinner/src/SquareLoader.vue";
import { useCrowdsourcedChangeGroupStore } from "@/stores/crowdsourcedChangeGroup";
import { useOccupierSpaceDefinitionStore } from "@/stores/occupierSpaceDefinition";
import { ref, computed, watch, onMounted } from "vue";
import { storeToRefs } from "pinia";
import api from "@/router/api";
import _ from "lodash";
import HuntSpaceRequirement from "@/components/crowdsourcing/HuntSpaceRequirement.vue";

const props = defineProps(["dataField", "contentDataField", "context"]);
const emit = defineEmits(["refetch"]);

const changeGroupStore = useCrowdsourcedChangeGroupStore();
const { originatingData } = storeToRefs(changeGroupStore);
const occupierSpaceStore = useOccupierSpaceDefinitionStore();

const dataFieldLocalId = computed(() => _.get(props.dataField, "localId"));
const editing = ref(false);
const requirements = ref([]);
const requirementsLoaded = ref(false);

watch(dataFieldLocalId, () => {
  if (props.dataField) fetchRequirements();
});

onMounted(() => fetchRequirements());

function fetchRequirements() {
  api
    .get(
      `crowdsourced_data_fields/${props.dataField.fieldContentType}/${props.dataField.fieldContentId}?field_name=HuntSpaceRequirement`,
    )
    .then((json) => {
      requirements.value = json.data;
      requirementsLoaded.value = true;
    });
}

function edit() {
  editing.value = true;
}

function cancel() {
  editing.value = false;
  occupierSpaceStore.reset();
}

function refetch() {
  editing.value = false;
  fetchRequirements();
  // emit("refetch");
}
</script>
