<template>
  <div class="col-span-1">
    <dt class="text-sm font-medium text-gray-500">{{ roleLabel }}Advisors</dt>
    <dd v-if="fetchedAdvisors.length > 0" class="mt-1 space-y-2">
      <InvolvedCompany
        v-for="companyInvolvementDataField in fetchedAdvisors"
        :key="companyInvolvementDataField.fieldContentId"
        :decorating-content-data-field="companyInvolvementDataField"
        layer-type="Advisor"
        layer-color="bg-teal-400"
        :client-role="clientRole"
        :providers-count="fetchedAdvisors.length"
        :context="context"
        :fetch-milliseconds="fetchMilliseconds"
        :parent-component-save-function="persistCompanies"
        @refetch="fetchAdvisors"
        @override-refetch="fetchAdvisors"
      />
    </dd>
    <div v-if="editingAdvisors" class="mt-1 flex flex-col space-y-1">
      <div
        v-if="
          availability?.id &&
          existingSpaceProviders &&
          existingSpaceProviders.length > 0
        "
        class=""
      >
        <ExistingOwnerAdvisorButton
          v-for="ownershipInterestField in existingSpaceProviders"
          :key="ownershipInterestField.localId"
          :ownership-interest-field="ownershipInterestField"
          :involvement-role-names="['Leasing broker']"
          :availability-id="availability?.id"
          @refetch="fetchAdvisors"
        />
      </div>
      <div
        class="border border-gray-300 rounded-lg shadow-sm focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500"
      >
        <CompanyContactAutocomplete
          label="Advisors"
          :companies="allLocalAdvisors"
          :filtered-companies="filteredLocalAdvisors"
          :client-role="clientRole"
          :input-key="`investment-${clientRole}-advisors`"
          @new-companies="setNewCompanies"
          @remove-company="removeCompany"
          @set-cross-interaction="setCrossInteraction"
        />
      </div>
      <div
        v-if="investment?.id || availability?.id || dataField?.fieldContentId"
        class="flex justify-end space-x-2"
      >
        <button
          @click="cancelAdvisorEditing"
          type="button"
          class="inline-flex items-center p-1 border border-gray-300 rounded-full shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <XMarkIcon class="h-4 w-4" />
        </button>

        <DataVisibilityButton visibility="safezone" class="inline-flex">
          <template v-slot:button>
            <button
              @click="saveAdvisors"
              :disabled="originatingData"
              type="button"
              class="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-yellow-500 hover:bg-yellow-600 focus:ring-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2"
              :data-test="`investment-${clientRole}-advisors-save`"
            >
              <PulseLoader
                v-if="originatingData"
                :loading="true"
                size="3px"
                color="#f3f4f6"
              />
              <CheckIcon v-else class="h-4 w-4" />
            </button>
          </template>
        </DataVisibilityButton>
      </div>
    </div>
    <dd
      v-else-if="filteredLocalAdvisors.length > 0"
      class="mt-1 flex flex-wrap"
    >
      <li
        @click="editingAdvisors = true"
        v-for="company in filteredLocalAdvisors"
        :key="company.name"
        v-tooltip="'Unsaved'"
        class="inline-flex rounded-full items-center my-1 mr-1 py-0.5 px-2.5 text-sm font-medium bg-indigo-100 text-indigo-700"
      >
        {{ company.name }}
      </li>
    </dd>
    <div v-else-if="addable" class="mt-2 flex items-center space-x-2">
      <DataVisibilityButton visibility="safezone">
        <template v-slot:button>
          <button
            @click="editingAdvisors = true"
            type="button"
            :data-test="`add-${clientRole}-advisors-button`"
            class="group py-1 px-1.5 flex items-center justify-between rounded-full border border-gray-300 shadow-sm space-x-2 text-left hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <span class="min-w-0 flex-1 flex items-center space-x-1">
              <span class="flex-shrink-0 flex items-center">
                <span
                  class="inline-flex items-center justify-center h-5 w-5 rounded-full bg-yellow-500"
                >
                  <BriefcaseIcon class="h-4 w-4 text-white" />
                </span>
              </span>
              <span class="min-w-0 flex-1">
                <span class="text-sm font-medium text-gray-900 truncate"
                  >Add companies</span
                >
              </span>
            </span>
          </button>
        </template>
      </DataVisibilityButton>
    </div>
  </div>
</template>

<script setup>
import { XMarkIcon, CheckIcon } from "@heroicons/vue/20/solid";
import { BriefcaseIcon } from "@heroicons/vue/24/outline";
import CompanyContactAutocomplete from "@/components/crowdsourcing/CompanyContactAutocomplete.vue";
import InvolvedCompany from "@/components/crowdsourcing/InvolvedCompany.vue";
import { ref, computed, watch, onMounted } from "vue";
import { useCrowdsourcedChangeGroupStore } from "@/stores/crowdsourcedChangeGroup";
import { useDealBuilderStore } from "@/stores/dealBuilder";
import { useSpaceUsageBuilderStore } from "@/stores/spaceUsageBuilder";
import { useTimeTravelStore } from "@/stores/timeTravel";
import { storeToRefs } from "pinia";
import api from "@/router/api";
import decoratingAndFieldKey from "@/components/crowdsourcing/decoratingAndFieldKey";
import _ from "lodash";
import DataVisibilityButton from "@/components/crowdsourcing/DataVisibilityButton.vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import ExistingOwnerAdvisorButton from "@/components/space-usage-builder/ExistingOwnerAdvisorButton.vue";

const props = defineProps([
  "spaceObject",
  "availability",
  "assetObject",
  "investment",
  "clientRole",
  "context",
  "existingSpaceProviders",
  "fetchMilliseconds",
  "dataField",
]);

const spaceUsageBuilderStore = useSpaceUsageBuilderStore();
const {
  allFetchedFields: spaceUsageBuilderFetchedFields,
  patchableCompanySize: spaceUsageBuilderPatchableCompanySize,
  availabilityGroups,
} = storeToRefs(spaceUsageBuilderStore);
const dealBuilderStore = useDealBuilderStore();
const {
  dealBuilder,
  patchableCompanySize: dealBuilderPatchableCompanySize,
  allFetchedFields: dealBuilderFetchedFields,
} = storeToRefs(dealBuilderStore);
const changeGroupStore = useCrowdsourcedChangeGroupStore();
const { originatingData, changeGroupId } = storeToRefs(changeGroupStore);
const timeTravelStore = useTimeTravelStore();
const { asOfMilliseconds } = storeToRefs(timeTravelStore);

const fetchedAdvisors = ref([]);
const localAdvisors = ref([]);
const editingAdvisors = ref(false);

const addable = computed(() => props.context !== "table-row");

const fetchRequestKey = computed(() => {
  if (props.investment) {
    return `advisor_company_involvements_${props.investment?.id}_${props.clientRole}`;
  } else if (props.availability) {
    return `advisor_company_involvements_${props.availability?.id}_${props.clientRole}`;
  } else {
    return null;
  }
});
const roleLabel = computed(() => {
  switch (props.clientRole) {
    case "capitalConsumer":
      switch (
        focalInvestment.value.dealAction ||
        focalInvestment.value.investmentType
      ) {
        case "originateLoan":
        case "refinance":
        case "Loan":
          return "Borrower-side ";
        default:
          return "Sell-side ";
      }
    case "capitalProvider":
      switch (
        focalInvestment.value.dealAction ||
        focalInvestment.value.investmentType
      ) {
        case "originateLoan":
        case "refinance":
        case "Loan":
          return "Lender-side ";
        default:
          return "Buy-side ";
      }
    case "hunter":
      switch (props.dataField.fieldContentType) {
        case "Hunt":
          return "Hunt Partners & ";
        case "HuntGeographyIntent":
          return "Hunt Regional ";
        default:
          return "";
      }
    case "spaceProvider":
      return "Landlord ";
    case "spaceUser":
      return "Tenant ";
    default:
      return "";
  }
});
const allLocalAdvisors = computed(() => {
  if (focalInvestment.value) {
    return _.get(
      focalInvestment.value,
      "existingInvestment.advisors",
      _.get(focalInvestment.value, "advisors", []),
    );
  } else if (focalAvailability.value) {
    return _.get(
      focalAvailability.value,
      "existingInvestment.advisors",
      _.get(focalAvailability.value, "advisors", []),
    );
  } else if (props.dataField?.fieldContentId) {
    return localAdvisors.value;
  } else {
    return [];
  }
});
const filteredLocalAdvisors = computed(() => {
  return allLocalAdvisors.value.filter((advisorObject) => {
    return advisorObject.clientRole === props.clientRole;
  });
});
const focalInvestment = computed(() => {
  if (matchingInvestmentGroup.value) {
    return _.find(
      matchingInvestmentGroup.value.investments,
      function (investment) {
        const localInvestmentId =
          _.get(props.investment, "existingInvestment.id") ||
          _.get(props.investment, "id") ||
          _.get(props.investment, "temporaryId");

        return _.get(investment, "existingInvestment.id") === localInvestmentId;
      },
    );
  } else if (props.investment) {
    return props.investment;
  } else {
    return null;
  }
});
const focalAvailability = computed(() => {
  if (matchingAvailabilityGroup.value) {
    return _.find(
      matchingAvailabilityGroup.value.availabilities,
      function (availability) {
        const localAvailabilityId =
          _.get(props.availability, "existingAvailability.id") ||
          _.get(props.availability, "id") ||
          _.get(props.availability, "temporaryId");

        return (
          _.get(availability, "existingAvailability.id") === localAvailabilityId
        );
      },
    );
  } else if (props.availability) {
    return props.availability;
  } else {
    return null;
  }
});
const investmentId = computed(() => {
  return _.get(focalInvestment.value, "id");
});
const availabilityId = computed(() => {
  return _.get(focalAvailability.value, "id");
});
const groupId = computed(
  () =>
    _.get(props.investment, "investmentGroupId") ||
    _.get(props.investment, "portfolioId") ||
    _.get(props.availability, "availabilityGroupId") ||
    _.get(props.availability, "portfolioId"),
);
const investmentState = computed(() => {
  return _.get(focalInvestment.value, "state");
});
const availabilityState = computed(() => {
  return _.get(focalAvailability.value, "state");
});
const matchingInvestmentGroup = computed(() => {
  return _.find(
    _.get(dealBuilder.value, "investmentGroups", []),
    function (group) {
      return _.find(group.investments, function (investment) {
        const localInvestmentId =
          _.get(props.investment, "existingInvestment.id") ||
          _.get(props.investment, "id") ||
          _.get(props.investment, "temporaryId");

        return _.get(investment, "existingInvestment.id") === localInvestmentId;
      });
    },
  );
});
const matchingAvailabilityGroup = computed(() => {
  return _.find(availabilityGroups.value, function (group) {
    return _.find(group.availabilities, function (availability) {
      const localAvailabilityId =
        _.get(props.availability, "existingAvailability.id") ||
        _.get(props.availability, "id") ||
        _.get(props.availability, "temporaryId");

      return (
        group.id === groupId.value ||
        _.get(availability, "existingAvailability.id") === localAvailabilityId
      );
    });
  });
});
watch(dealBuilderPatchableCompanySize, () => {
  if (
    fetchedAdvisors.value.length > 0 &&
    dealBuilderPatchableCompanySize.value > 0
  ) {
    const updatedAdvisors = fetchedAdvisors.value.map((field) => {
      const matchingField = _.find(dealBuilderFetchedFields.value, {
        localId: field.localId,
      });

      return matchingField || field;
    });

    fetchedAdvisors.value = updatedAdvisors;
  }
});
watch(spaceUsageBuilderPatchableCompanySize, () => {
  if (
    fetchedAdvisors.value.length > 0 &&
    spaceUsageBuilderPatchableCompanySize.value > 0
  ) {
    const updatedAdvisors = fetchedAdvisors.value.map((field) => {
      const matchingField = _.find(spaceUsageBuilderFetchedFields.value, {
        localId: field.localId,
      });

      return matchingField || field;
    });

    fetchedAdvisors.value = updatedAdvisors;
  }
});
watch(investmentId, (id, oldId) => {
  if (id !== oldId) {
    reset();
    fetchAdvisors();
  }
});
watch(investmentState, (id, oldId) => {
  if (id !== oldId) {
    reset();
    fetchAdvisors();
  }
});
watch(availabilityId, (id, oldId) => {
  if (id !== oldId) {
    reset();
    fetchAdvisors();
  }
});
watch(availabilityState, (id, oldId) => {
  if (id !== oldId) {
    reset();
    fetchAdvisors();
  }
});
onMounted(() => {
  fetchAdvisors();
});

function reset() {
  fetchedAdvisors.value = [];
  editingAdvisors.value = false;
}
function setNewCompanies(newCompanies) {
  if (props.investment) {
    let newInvestment = _.merge({}, props.investment);

    newInvestment.advisors = newCompanies;
    updateStoreInvestment(newInvestment);
  } else if (props.availability) {
    let newAvailability = _.merge({}, props.availability);

    newAvailability.advisors = newCompanies;
    updateStoreAvailability(newAvailability);
  } else if (props.dataField?.fieldContentId) {
    localAdvisors.value = newCompanies;
  }
}
function removeCompany(advisorObject) {
  if (groupId.value) {
    if (props.investment) {
      const combinedKey = props.investment?.temporaryId || props.investment?.id;
      dealBuilderStore.removeInvestmentGroupInvestmentPlayer({
        groupId: groupId.value,
        investmentCombinedKey: combinedKey,
        toRemove: advisorObject,
        path: "advisors",
        compareBy: function (advisorObject) {
          return `${advisorObject.name}_${advisorObject.clientRole}`;
        },
      });
    } else if (props.availability) {
      const combinedKey =
        props.availability?.temporaryId || props.availability?.id;
      spaceUsageBuilderStore.removeAvailabilityGroupPlayer({
        groupId: groupId.value,
        availabilityCombinedKey: combinedKey,
        toRemove: advisorObject,
        path: "advisors",
        compareBy: function (advisorObject) {
          return `${advisorObject.name}_${advisorObject.clientRole}`;
        },
      });
    }
  } else if (props.investment) {
    const filteringName = `${advisorObject.name}_${advisorObject.clientRole}`;
    const newCompanies = props.investment?.advisors.filter((advObj) => {
      const comparisonName = `${advObj.name}_${advObj.clientRole}`;

      return comparisonName !== filteringName;
    });

    setNewCompanies(newCompanies);
  } else if (props.availability) {
    const filteringName = `${advisorObject.name}_${advisorObject.clientRole}`;
    const newCompanies = props.availability?.advisors.filter((advObj) => {
      const comparisonName = `${advObj.name}_${advObj.clientRole}`;

      return comparisonName !== filteringName;
    });

    setNewCompanies(newCompanies);
  } else {
    const filteringName = `${advisorObject.name}_${advisorObject.clientRole}`;
    const newCompanies = localAdvisors.value.filter((advObj) => {
      const comparisonName = `${advObj.name}_${advObj.clientRole}`;

      return comparisonName !== filteringName;
    });

    setNewCompanies(newCompanies);
  }
}
function updateStoreInvestment(newInvestment) {
  if (groupId.value) {
    console.log("has existing group id");
    dealBuilderStore.updateInvestmentGroupInvestment({
      groupId: groupId.value,
      newInvestment,
    });
  } else if (matchingInvestmentGroup.value) {
    console.log("has new group id");
    dealBuilderStore.updateInvestmentGroupInvestment({
      groupId:
        matchingInvestmentGroup.value.id ||
        matchingInvestmentGroup.value.placeholderId,
      newInvestment,
    });
  } else if (props.investment?.id && props.assetObject) {
    console.log("has investment id and asset");
    dealBuilderStore.updateDealBuilderAssetInvestments({
      assetKey: decoratingAndFieldKey(props.assetObject.dataField),
      investments: [newInvestment],
    });
  } else {
    console.log("nothing to update!");
  }
}
function updateStoreAvailability(newAvailability) {
  if (groupId.value) {
    console.log("has existing group id");
    spaceUsageBuilderStore.updateAvailabilityGroupAvailability({
      groupId: groupId.value,
      newAvailability,
    });
  } else if (matchingAvailabilityGroup.value) {
    console.log("has new group id");
    spaceUsageBuilderStore.updateAvailabilityGroupAvailability({
      groupId:
        matchingAvailabilityGroup.value.id ||
        matchingAvailabilityGroup.value.placeholderId,
      newAvailability,
    });
  } else if (props.investment?.id && props.assetObject) {
    console.log("has investment id and asset");
    spaceUsageBuilderStore.updateDealBuilderAssetInvestments({
      assetKey: decoratingAndFieldKey(props.assetObject.dataField),
      investments: [newAvailability],
    });
  } else if (props.availability?.id && props.spaceObject) {
    console.log("has availability id and space");
    spaceUsageBuilderStore.updateSpaceAvailabilities({
      spaceKey: decoratingAndFieldKey(props.spaceObject.dataField),
      availabilities: [newAvailability],
    });
  } else {
    console.log("nothing to update!");
  }
}
function setCrossInteraction() {}
function cancelAdvisorEditing() {
  editingAdvisors.value = false;
}
function clearAdvisorEditing() {
  if (groupId.value) {
    filteredLocalAdvisors.value.forEach((advisorObject) => {
      removeCompany(advisorObject);
    });
  } else if (props.investment) {
    let newInvestment = _.merge({}, props.investment);

    newInvestment.advisors = [];
    updateStoreInvestment(newInvestment);
  } else if (props.availability) {
    let newAvailability = _.merge({}, props.availability);

    newAvailability.advisors = [];
    updateStoreAvailability(newAvailability);
  } else {
    localAdvisors.value = [];
  }
  cancelAdvisorEditing();
}
const persistCompanies = async (proofCompanies = null) => {
  let contentType = props.dataField?.fieldContentType;
  if (props.investment) {
    contentType = "Investment";
  } else if (props.availability) {
    contentType = "SpaceAvailability";
  }
  const payload = {
    contentType,
    contentId:
      props.investment?.id ||
      props.availability?.id ||
      props.dataField?.fieldContentId,
    advisors: proofCompanies || filteredLocalAdvisors.value,
    changeGroupId: changeGroupId.value,
  };

  const response = await api.post(`advisor_company_involvements`, payload);

  console.log("advisors detail persist", proofCompanies, payload, response);

  return response;
};
async function saveAdvisors() {
  setTimeout(() => {
    if (
      props.investment?.id ||
      props.availability?.id ||
      props.dataField?.fieldContentId
    ) {
      const apiRequestFunc = () => persistCompanies();
      const successCallback = async (json) => {
        clearAdvisorEditing();
        if (props.investment) {
          await dealBuilderStore.postEditingPatch(json, fetchRequestKey.value);
        } else if (props.availability) {
          await spaceUsageBuilderStore.postEditingPatch(
            json,
            fetchRequestKey.value,
          );
        }
        fetchAdvisors();
      };
      const failureCallback = () => clearAdvisorEditing();

      return changeGroupStore.originateData(
        apiRequestFunc,
        successCallback,
        failureCallback,
      );
    }
  }, 125);
}
function fetchAdvisors(maybePayload) {
  if (props.investment?.id) {
    if (
      dealBuilderStore.alreadyFetched(fetchRequestKey.value) &&
      !maybePayload?.override
    ) {
      const alreadyFetched = dealBuilderStore.alreadyFetchedFieldsFor(
        fetchRequestKey.value,
      );

      fetchedAdvisors.value = alreadyFetched.filter((cdf) => {
        return cdf.fieldContentType === "CompanyInvolvement";
      });
    } else {
      reset();
      api
        .get(
          `advisor_company_involvements/?content_type=Investment&content_id=${props.investment?.id}&client_role=${props.clientRole}`,
        )
        .then((json) => {
          dealBuilderStore.interceptablePatch(json.data, fetchRequestKey.value);
          fetchedAdvisors.value = json.data;
        });
    }
  } else if (props.availability?.id) {
    if (
      spaceUsageBuilderStore.alreadyFetched(fetchRequestKey.value) &&
      !maybePayload?.override
    ) {
      const alreadyFetched = spaceUsageBuilderStore.alreadyFetchedFieldsFor(
        fetchRequestKey.value,
      );

      fetchedAdvisors.value = alreadyFetched.filter((cdf) => {
        return cdf.fieldContentType === "CompanyInvolvement";
      });
    } else {
      reset();
      api
        .get(
          `advisor_company_involvements/?content_type=SpaceAvailability&content_id=${props.availability?.id}&client_role=${props.clientRole}`,
        )
        .then((json) => {
          spaceUsageBuilderStore.interceptablePatch(
            json.data,
            fetchRequestKey.value,
          );
          fetchedAdvisors.value = json.data;
        });
    }
  } else if (props.dataField?.fieldContentId) {
    api
      .get(
        `advisor_company_involvements/?content_type=${props.dataField?.fieldContentType}&content_id=${props.dataField?.fieldContentId}&client_role=${props.clientRole}&as_of=${asOfMilliseconds.value}`,
      )
      .then((json) => {
        fetchedAdvisors.value = json.data;
      });
  } else {
    editingAdvisors.value = true;
  }
}
</script>
