<template>
  <div
    class="flex items-center justify-center gap-x-6 bg-gray-900 py-2.5 px-6 sm:px-3.5"
    data-test="unverified-email-banner"
    id="unverified-email-banner"
  >
    <p class="text-sm leading-6 text-white">
      Your email
      <strong class="font-semibold">{{ currentUser.email }}</strong> has not
      been confirmed.&nbsp;<template v-if="confirmationResent"
        >We're resending your confirmation. Please check your email.</template
      ><template v-else
        ><a
          href=""
          class="text-sm underline text-gray-400 hover:text-gray-300"
          @click.prevent="userStore.viewAccount()"
          data-test="unverified-email-banner-my-account-button"
          >Change email</a
        >
        or&nbsp;<a
          href=""
          class="text-sm underline text-gray-400 hover:text-gray-300"
          @click.prevent="userStore.resendConfirmation()"
          data-test="unverified-email-banner-my-account-button"
          >Resend confirmation</a
        ></template
      >
    </p>
  </div>
</template>

<script setup>
import { useUserStore } from "@/stores/user";
import { storeToRefs } from "pinia";

const userStore = useUserStore();
const { currentUser, confirmationResent } = storeToRefs(userStore);
</script>
