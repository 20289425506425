import PayloadGeographyEdit from "@/views/admin/PayloadGeographyEdit.vue";
import { computed, ref, markRaw, nextTick } from "vue";
import { defineStore, acceptHMRUpdate, storeToRefs } from "pinia";
import { useModalStore } from "@/stores/modal";
import api from "@/router/api";
import _ from "lodash";

export const useEasyDataInputAdminStore = defineStore(
  "easyDataInputAdmin",
  () => {
    const modalStore = useModalStore();
    const { modalPayload, confirmationPayload } = storeToRefs(modalStore);

    const placeholderId = ref(1);
    const selectedJob = ref(null);
    const detailedSelectedJob = computed(() =>
      _.find(selectedJobs.value, { id: selectedJob.value?.id }),
    );
    const selectedItem = ref(null);
    const detailedSelectedJobCRUD = computed(
      () => selectedJobCRUD.value[selectedJob.value?.id],
    );
    const selectedJobs = ref([]);
    const selectedJobCRUD = ref({});
    const globalUnsaved = computed(() => _.some(selectedJobs.value, unsaved));
    const itemGrabHovering = ref(null);

    const preemptions = computed({
      get() {
        return detailedSelectedJobCRUD.value?.preemptions;
      },
      set(newArr) {
        detailedSelectedJobCRUD.value.preemptions = newArr;
      },
    });

    const payloads = computed({
      get() {
        return detailedSelectedJobCRUD.value?.payloads;
      },
      set(newArr) {
        detailedSelectedJobCRUD.value.payloads = newArr;
      },
    });
    const payloadItems = computed({
      get() {
        return detailedSelectedJobCRUD.value?.payloadItems;
      },
      set(newArr) {
        detailedSelectedJobCRUD.value.payloadItems = newArr;
      },
    });

    async function fetchSelectedJob(override = false) {
      if (detailedSelectedJob.value && !override) return;
      if (!selectedJob.value) return;

      const response = await api.get(
        `admin/easy_data_input_jobs/${selectedJob.value.id}`,
      );

      if (response?.data) {
        patchJobs([response.data]);

        if (override && detailedSelectedJob.value) {
          const updatedJob = _.find(selectedJobs.value, {
            id: detailedSelectedJob.value.id,
          });
          selectedJob.value = null;
          await nextTick();
          selectedJob.value = updatedJob;
        }
      }
    }

    async function fetchPreemptions() {
      const response = await api.get(
        `admin/easy_data_input_jobs/${detailedSelectedJob.value.id}/easy_data_input_job_preemptions`,
      );

      if (response?.data) {
        mountPreemptions(response.data);
      }
    }

    async function fetchPayloads() {
      const response = await api.get(
        `admin/easy_data_input_jobs/${detailedSelectedJob.value.id}/easy_data_input_payloads`,
      );

      if (response?.data) {
        mountPayloads(response.data);
      }
    }

    function scopedItemsFor(id) {
      return payloadItems.value.filter(({ payloadId }) => id === payloadId);
    }

    function mountPreemptions(existingPreemptions) {
      preemptions.value = _.unionBy(
        existingPreemptions,
        preemptions.value,
        "id",
      );
    }

    function mountPayloads(existingPayloads) {
      payloads.value = _.unionBy(existingPayloads, payloads.value, "id");
      const combinedItems = existingPayloads.flatMap(({ items }) => items);
      payloadItems.value = _.unionBy(combinedItems, payloadItems.value, "id");
    }

    function addPayload() {
      const newId = `temp${placeholderId.value}`;
      const newPayload = {
        id: newId,
        title: null,
        geography: null,
        propertyUses: [],
      };
      payloads.value.push(newPayload);
      placeholderId.value++;
      addPayloadItem(newPayload);
    }

    function addPayloadItem({ id }, executionOrder = 0) {
      payloadItems.value.push({
        payloadId: id,
        id: `temp${placeholderId.value}`,
        referred: true,
        executionOrder,
        dataValue: "None",
        inputComplexity: "Low",
        narrative: null,
        files: [],
        existingFiles: [],
      });
      placeholderId.value++;
    }

    function addPreemption() {
      const newId = `temp${placeholderId.value}`;
      const newPreemption = {
        id: newId,
        contentType: null,
        contentId: null,
        content: null,
      };
      preemptions.value.push(newPreemption);

      placeholderId.value++;
    }

    function removePreemption(id) {
      confirmationPayload.value = {
        title: "Remove preemption",
        message:
          "Are you sure you want to unlink the existing Tower Hunt data?",
        affirmText: "Unlink",
        affirmCallback: () => {
          if (_.includes(id, "temp")) {
            hardRemovePreemption(id);
          } else {
            destroyPreemption(id);
          }
        },
      };
    }

    async function destroyPreemption(id) {
      await api.delete(
        `admin/easy_data_input_jobs/${detailedSelectedJob.value.id}/easy_data_input_job_preemptions/${id}`,
      );

      hardRemovePreemption(id);
    }
    function hardRemovePreemption(id) {
      _.remove(preemptions.value, function ({ id: preemptionId }) {
        return id === preemptionId;
      });
    }

    function removePayload(id) {
      const payload = _.find(payloads.value, { id });
      confirmationPayload.value = {
        title: `Remove payload ${payload.id}`,
        message:
          "Are you sure you want to remove the entire payload and all its items?",
        affirmText: "Remove",
        affirmCallback: () => {
          if (_.includes(id, "temp")) {
            hardRemovePayload(id);
          } else {
            destroyPayload(id);
          }
        },
      };
    }
    async function destroyPayload(id) {
      await api.delete(
        `admin/easy_data_input_jobs/${detailedSelectedJob.value.id}/easy_data_input_payloads/${id}`,
      );

      hardRemovePayload(id);
    }
    function hardRemovePayload(id) {
      _.remove(payloads.value, function ({ id: payloadId }) {
        return id === payloadId;
      });
      scopedItemsFor(id).forEach(removePayloadItem);
    }
    function removePayloadItem({ payloadId, executionOrder }) {
      _.remove(payloadItems.value, function (item) {
        return (
          payloadId === item.payloadId && executionOrder === item.executionOrder
        );
      });
    }

    function editLocation(payload, { lat, lng, regionName }) {
      modalPayload.value = {
        size: "base",
        theme: "light",
        component: markRaw(PayloadGeographyEdit),
        props: {
          payload,
          lat,
          lng,
          regionName,
        },
      };
    }

    async function savePreemption(id) {
      const preemption = _.find(preemptions.value, { id });
      const { contentType, contentId } = preemption;
      const requestPayload = {
        contentType,
        contentId,
      };

      const response = await api.post(
        `admin/easy_data_input_jobs/${detailedSelectedJob.value.id}/easy_data_input_job_preemptions`,
        requestPayload,
      );

      if (response?.data) {
        hardRemovePreemption(id);
        mountPreemptions([response.data]);
      }
    }

    async function appendPayloadItem(item) {
      const requestPayload = _.merge({}, item, {
        executionOrder: _.toNumber(item.executionOrder),
        existingFiles: item.existingFiles.map(({ id }) => id),
      });
      const response = await api.post(
        `admin/easy_data_input_payloads/${item.payloadId}/easy_data_input_payload_items`,
        requestPayload,
      );

      if (response?.data) {
        removePayloadItem(item);
        mountPayloads([response.data]);
      }
    }

    async function savePayload(id) {
      const payload = _.find(payloads.value, { id });
      const filteredItems = payloadItems.value.filter(
        ({ payloadId }) => payloadId === id,
      );
      const items = filteredItems.map((item) => {
        return _.merge({}, item, {
          executionOrder: _.toNumber(item.executionOrder),
          existingFiles: item.existingFiles.map(({ id }) => id),
        });
      });
      const geographyType = payload.geography?.geographyType;
      let geographyPayload = null;

      if (geographyType === "region") {
        geographyPayload = {
          regionId: payload.geography.geographyContent.id,
        };
      } else if (geographyType === "latLng") {
        geographyPayload = {
          latitude: payload.geography.geographyContent[0],
          longitude: payload.geography.geographyContent[1],
        };
      }
      const requestPayload = {
        payload: _.merge(
          {},
          {
            title: payload.title,
            propertyUses: payload.propertyUses,
          },
          geographyPayload,
        ),
        items,
      };

      const response = await api.post(
        `admin/easy_data_input_jobs/${detailedSelectedJob.value.id}/easy_data_input_payloads`,
        requestPayload,
      );

      if (response?.data) {
        hardRemovePayload(id);
        mountPayloads([response.data]);
      }
    }

    function itemGrabHover(itemId) {
      stopItemGrabHovering.cancel();
      itemGrabHovering.value = itemId;
    }

    const stopItemGrabHovering = _.debounce(function () {
      setTimeout(() => {
        itemGrabHovering.value = null;
      }, 250);
    }, 750);

    function patchJobs(jobs) {
      selectedJobs.value = _.unionBy(jobs, selectedJobs.value, "id");

      jobs.forEach(patchJobCRUD);
    }

    function patchJobCRUD(job) {
      if (selectedJobCRUD.value[job.id]) return;

      selectedJobCRUD.value[job.id] = {
        preemptions: [],
        payloads: [],
        payloadItems: [],
      };
    }

    function unsaved(job) {
      const baseCRUD = {
        preemptions: [],
        payloads: [],
        payloadItems: [],
      };
      const rawComparisonCRUD = selectedJobCRUD.value[job.id];
      const tempOnly = (obj) => _.includes(obj.id, "temp");

      if (rawComparisonCRUD) {
        const comparisonCRUD = {
          preemptions: rawComparisonCRUD.preemptions.filter(tempOnly),
          payloads: rawComparisonCRUD.payloads.filter(tempOnly),
          payloadItems: rawComparisonCRUD.payloadItems.filter(tempOnly),
        };
        const different =
          !!comparisonCRUD && !_.isEqual(baseCRUD, comparisonCRUD);

        return different;
      } else {
        return false;
      }
    }

    function resetCRUD() {
      placeholderId.value = 1;
      selectedJob.value = null;
      selectedJobCRUD.value = {};
    }

    function review() {
      confirmationPayload.value = {
        title: "Initiate Review?",
        message:
          "The contributor will be notified, so be sure you have capacity.",
        affirmText: "Review",
        affirmCallback: triggerReview,
      };
    }

    async function triggerReview() {
      const jobPayload = {
        packagingStatus: "reviewing",
      };
      const response = await api.patch(
        `admin/easy_data_input_jobs/${detailedSelectedJob.value.id}`,
        jobPayload,
      );

      if (response?.data) {
        patchJobs([response.data]);
      }
    }

    async function broadcastJob() {
      const executeBroadcast = async () => {
        await api.post(
          `admin/easy_data_input_job_broadcastings/${detailedSelectedJob.value.id}`,
        );
      };

      confirmationPayload.value = {
        title: "Broadcast payload items?",
        message:
          "Broadcasting publishes cards to Tower Hunt users. Please be sure you are ready to launch.",
        affirmText: "Broadcast",
        affirmCallback: executeBroadcast,
      };
    }

    async function broadcastItem(item) {
      const executeBroadcast = async () => {
        await api.post(
          `admin/easy_data_input_payload_item_broadcastings/${item.id}`,
        );
      };

      confirmationPayload.value = {
        title: "Broadcast payload items?",
        message:
          "Broadcasting publishes cards to Tower Hunt users. Please be sure you are ready to launch.",
        affirmText: "Broadcast",
        affirmCallback: executeBroadcast,
      };
    }

    async function closeJob() {
      const executeClosing = async () => {
        const response = await api.post(
          `admin/easy_data_input_job_closings/${detailedSelectedJob.value.id}`,
        );

        if (response?.data) {
          patchJobs([response.data]);
          fetchPayloads();
          fetchPreemptions();
        }
      };

      confirmationPayload.value = {
        title: "Close job?",
        message:
          "This will finalize the job and notify the contributor. Please be sure you have thoroughly reviewed the content.",
        affirmText: "Close",
        affirmCallback: executeClosing,
      };
    }

    return {
      placeholderId,
      selectedJob,
      detailedSelectedJob,
      selectedItem,
      detailedSelectedJobCRUD,
      selectedJobs,
      selectedJobCRUD,
      globalUnsaved,
      preemptions,
      payloads,
      payloadItems,
      stopItemGrabHovering,
      itemGrabHovering,
      fetchSelectedJob,
      fetchPayloads,
      fetchPreemptions,
      addPayload,
      removePayload,
      savePayload,
      appendPayloadItem,
      editLocation,
      review,
      mountPayloads,
      addPayloadItem,
      addPreemption,
      removePreemption,
      savePreemption,
      patchJobs,
      patchJobCRUD,
      unsaved,
      resetCRUD,
      itemGrabHover,
      broadcastJob,
      broadcastItem,
      closeJob,
    };
  },
);

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useEasyDataInputAdminStore, import.meta.hot),
  );
}
