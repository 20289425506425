<template>
  <div
    v-if="loaded"
    class="rounded-md bg-gray-50 px-6 py-5"
    :class="
      workspaceLayout === 'sideBySide'
        ? ''
        : 'flex items-center justify-between'
    "
  >
    <form
      @submit.prevent="submitCard"
      id="payment-form"
      class="space-y-3"
      data-test="payment-form"
    >
      <div id="payment-element">
        <!-- Elements will create form elements here -->
      </div>
      <div class="flex items-center justify-center space-x-3">
        <button
          id="submit"
          class="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          data-test="payment-method-submit-button"
        >
          Submit
        </button>
        <button
          v-if="cancellable"
          @click="$emit('cancel')"
          type="button"
          class="inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue active:text-gray-800 active:bg-gray-50"
        >
          Cancel
        </button>
      </div>
      <div
        v-if="errorMessage"
        id="error-message"
        class="text-sm text-red-500"
        data-test="payment-submit-error"
      >
        <!-- Display error message to your customers here -->
        {{ errorMessage }}
      </div>
    </form>
  </div>
</template>

<script setup>
/* global analytics, Stripe */
import { ref, computed, onMounted, watch, nextTick } from "vue";
import { useAuthStore } from "@/stores/auth";
import { useAnalyticsStore } from "@/stores/analytics";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { useUserStore } from "@/stores/user";
import api from "@/router/api";
import { storeToRefs } from "pinia";

defineProps(["cancellable"]);
const emit = defineEmits(["cancel", "refresh-cards"]);
const layoutStore = useWorkspaceLayoutStore();
const { workspaceLayout } = storeToRefs(layoutStore);
const userStore = useUserStore();
const { currentUser } = storeToRefs(userStore);

const stripeKey = ref(null);
const clientSecret = ref(null);
const stripe = ref(null);
const elements = ref(null);
const errorMessage = ref(null);

onMounted(async () => {
  api.get(`payment_api_key`).then((json) => {
    stripeKey.value = json.data;
    stripe.value = Stripe(stripeKey.value);
    api.post(`my_payment_methods`).then((json) => {
      clientSecret.value = json.data.clientSecret;
    });
  });
});

const loaded = computed(() => {
  return !!stripeKey.value && !!clientSecret.value;
});

watch(loaded, async () => {
  if (loaded.value) {
    const elementsOptions = {
      // https://stripe.com/docs/js/elements_object/create#stripe_elements-options
      clientSecret: clientSecret.value,
      fonts: [
        {
          cssSrc: "https://fonts.googleapis.com/css?family=Inter",
        },
      ],
    };
    elements.value = stripe.value.elements(elementsOptions);
    const paymentElement = elements.value.create("payment");
    await nextTick();
    paymentElement.mount("#payment-element");
  }
});

const authStore = useAuthStore();
const { adminMode } = storeToRefs(authStore);
const analyticsStore = useAnalyticsStore();

async function submitCard() {
  const { error } = await stripe.value.confirmSetup({
    //`Elements` instance that was used to create the Payment Element
    elements: elements.value,
    confirmParams: {
      return_url: `${currentUser.value.host}/?horizontalTab=Account&verticalTab=Settings`,
    },
    redirect: "if_required",
  });

  if (error) {
    // This point will only be reached if there is an immediate error when
    // confirming the payment. Show error to your customer (for example, payment
    // details incomplete)
    errorMessage.value = error.message;
  } else {
    // Your customer will be redirected to your `return_url`. For some payment
    // methods like iDEAL, your customer will be redirected to an intermediate
    // site first to authorize the payment, then redirected to the `return_url`.
    if (!!analytics && !adminMode.value) {
      analytics.track("Payment method added", {
        yearWeek: analyticsStore.yearWeek,
      });
    }
    emit("refresh-cards");
  }
}
</script>
