<template>
  <li
    class="col-span-1 flex items-center justify-between space-x-3"
    :class="workspaceLayout === 'topAndBottom' ? 'flex-1' : ''"
  >
    <div class="min-w-0 flex-1 flex items-center space-x-3">
      <div class="flex-shrink-0">
        <span
          :class="`inline-flex items-center justify-center h-8 w-8 rounded-full bg-${layerColor}`"
        >
          <UserIcon class="h-5 w-5 text-white" />
        </span>
      </div>
      <div class="min-w-0 flex-1">
        <DataField
          :data-field="decoratingContentDataField"
          :inner-field-name="datafieldInnerFieldName"
          text-classes="text-sm font-medium"
          primary-text-path="fieldContent.name"
          text-styles=""
          @completed="refetchAll"
          @unlocked="unlocked"
          @set-proof="setProof"
        />
        <div class="flex">
          <p class="text-sm font-medium text-gray-500 truncate">
            {{ _.capitalize(role) }}
          </p>
        </div>
      </div>
    </div>
    <div class="flex items-center space-x-1">
      <VDropdown
        v-if="
          context === 'capital-stack' && !future && !existingInvestmentSelected
        "
      >
        <button
          type="button"
          v-tooltip="'Deal actions menu'"
          class="inline-flex items-center shadow-sm p-1 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 duration-150"
        >
          <ArrowsRightLeftIcon class="h-4 w-4" />
        </button>

        <template #popper>
          <DealActionDropdown
            :deal-builder-asset-data-field="dealBuilderAssetDataField"
            :local-asset-data-field="localAssetDataField"
            :decorating-data-field="decoratingContentDataField"
            :providers-count="providersCount"
            :company-id="companyId"
            :existing-investment-id="existingInvestmentId"
            :ownership-interest-ids="ownershipInterestIds"
            :layer-type="layerType"
            :context="context"
            :allows-senior-loan="allowsSeniorLoan"
          />
        </template>
      </VDropdown>
    </div>
  </li>
</template>

<script setup>
import { UserIcon } from "@heroicons/vue/24/outline";
import DataField from "@/components/crowdsourcing/DataField.vue";
import DealActionDropdown from "@/components/deal-builder/DealActionDropdown.vue";
import ContactAutocomplete from "@/components/crowdsourcing/ContactAutocomplete.vue";
import { useDealBuilderStore } from "@/stores/dealBuilder";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { useProveForValueStore } from "@/stores/proveForValue";
import { ref, computed, onMounted } from "vue";
import { storeToRefs } from "pinia";
import api from "@/router/api";
import _ from "lodash";
import decoratingAndFieldKey from "@/components/crowdsourcing/decoratingAndFieldKey";
import { ArrowsRightLeftIcon } from "@heroicons/vue/20/solid";

const props = defineProps([
  "dealBuilderAssetDataField",
  "localAssetDataField",
  "decoratingContentDataField",
  "layerType",
  "layerColor",
  "providersCount",
  "allowsSeniorLoan",
  "context",
  "future",
  "existingInvestmentSelected",
]);
const emit = defineEmits(["refetch", "unlocked"]);

const dealBuilderStore = useDealBuilderStore();
const { dealBuilder } = storeToRefs(dealBuilderStore);
const layoutStore = useWorkspaceLayoutStore();
const { workspaceLayout } = storeToRefs(layoutStore);
const proveForValueStore = useProveForValueStore();

const contact = ref(null);

const directContactInvolvement = computed(() => {
  return _.includes(["AwardedOwnershipInterest", "Advisor"], props.layerType);
});
const focalContact = computed(() => {
  if (directContactInvolvement.value) {
    return props.decoratingContentDataField;
  } else {
    return contact.value;
  }
});
const companyId = computed(() => {
  return _.get(focalContact.value, "fieldContent.id");
});
const existingInvestmentId = computed(() => {
  return _.get(ownershipInterest.value, "investmentId");
});
const ownershipInterestIds = computed(() => {
  if (directContactInvolvement.value) {
    return [];
  } else {
    return [props.decoratingContentDataField.fieldContentId];
  }
});
const unlockable = computed(() => {
  return _.get(props.decoratingContentDataField, "price");
});
const ownershipInterest = computed(() => {
  return _.get(props.decoratingContentDataField, "fieldContent", null);
});
const companyName = computed(() => {
  return _.get(focalContact.value, "fieldContent.name");
});
const datafieldInnerFieldName = computed(() => {
  if (directContactInvolvement.value) {
    return null;
  } else {
    return "Contact";
  }
});
const role = computed(() => {
  if (props.layerType === "AwardedOwnershipInterest") {
    return "Under contract";
  } else if (props.layerType === "Loan") {
    return "lender";
  } else {
    return _.get(ownershipInterest.value, "equityType");
  }
});
const availableProps = computed(() => {
  return [
    {
      fieldName: "Contact",
      isInnerField: !!datafieldInnerFieldName.value,
      removeInline: true,
      focus: true,
    },
  ];
});

onMounted(() => {
  if (directContactInvolvement.value) {
    // NO OP
  } else {
    fetchInvolvedContacts();

    if (
      ownershipInterest.value &&
      dealBuilder.value &&
      props.context === "capital-stack"
    ) {
      fetchInvestment();
    }
  }
});

function setProof(fieldName) {
  const matchedProps = _.find(availableProps.value, { fieldName });

  if (matchedProps) {
    proveForValueStore.setProofComponent(ContactAutocomplete, matchedProps);
  }
}
function unlocked(maybePayload) {
  emit("unlocked", maybePayload);
}
function refetchAll() {
  emit("refetch");
}
function fetchInvolvedContacts(emitPayload = {}) {
  api
    .get(
      `crowdsourced_data_fields/${props.decoratingContentDataField.fieldContentType}/${props.decoratingContentDataField.fieldContentId}?field_name=Contact`,
    )
    .then((json) => {
      contact.value = _.head(json.data);
    });
}
function fetchInvestment() {
  api
    .get(`ownership_interest_investments/${ownershipInterest.value.id}`)
    .then((json) => {
      dealBuilderStore.updateDealBuilderAssetInvestments({
        assetKey: decoratingAndFieldKey(props.dealBuilderAssetDataField),
        investments: [json.data],
      });
    });
}
</script>
