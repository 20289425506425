<template>
  <div class="h-full flex flex-col">
    <nav
      class="p-2 bg-gray-50 flex items-center justify-between"
      aria-label="Breadcrumb"
    >
      <div class="flex items-center space-x-2">
        <a @click.prevent="backToLists" href="" class="flex items-center">
          <ChevronLeftIcon
            class="h-5 w-5 flex-shrink-0 text-indigo-400"
            aria-hidden="true"
          />
          <span
            class="text-sm font-medium text-indigo-500 hover:text-indigo-700"
            >Lists</span
          >
        </a>
      </div>
      <h3
        v-if="!mainTitleVisible"
        class="flex min-w-0 flex-1 items-center justify-center font-medium text-gray-900"
      >
        <template v-if="batchSelection && batchTasks.length > 0">
          {{ batchTasks.length }} Selected
        </template>
        <template v-else-if="batchSelection"> Select Reminders </template>
        <template v-else> Completed </template>
      </h3>
      <div class="flex items-center space-x-3">
        <VDropdown v-if="!batchSelection" class="flex items-center">
          <button
            type="button"
            class="inline-flex justify-center p-0.5 items-center rounded-full border border-indigo-400 text-sm text-indigo-500 hover:text-indigo-700"
            data-test="task-list-options-button`"
          >
            <EllipsisHorizontalIcon class="h-4 w-4" />
          </button>

          <template #popper>
            <div
              class="w-64 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="menu-button"
              tabindex="-1"
            >
              <div class="py-1" role="none">
                <a
                  @click.prevent="batchSelection = true"
                  href=""
                  class="text-gray-700 hover:bg-gray-100 hover:text-gray-900 group flex items-center px-4 py-2 text-sm"
                  role="menuitem"
                  tabindex="-1"
                >
                  Select Reminders
                </a>
              </div>
            </div>
          </template>
        </VDropdown>
        <a
          v-if="batchSelection"
          @click.prevent="resetBatch"
          href=""
          class="flex items-center text-sm font-bold text-indigo-500"
        >
          Done
        </a>
      </div>
    </nav>

    <div class="p-2 flex flex-col flex-grow overflow-y-auto">
      <div class="lg:flex lg:items-center lg:justify-between">
        <div class="min-w-0 flex-1">
          <h2
            v-observe-visibility="pageTitleVisibilityChanged"
            class="mt-2 text-xl font-bold leading-6 sm:truncate sm:text-2xl sm:tracking-tight text-gray-700"
          >
            <template v-if="batchSelection && batchTasks.length > 0">
              {{ batchTasks.length }} Selected
            </template>
            <template v-else-if="batchSelection"> Select Reminders </template>
            <template v-else> Completed </template>
          </h2>
          <div class="mt-2 flex items-center space-x-2">
            <div class="flex items-center text-sm text-gray-500">
              {{ completedLength }} completed
            </div>
            <div class="text-gray-500 text-sm">&middot;</div>
            <VDropdown :disabled="batchSelection || nothingCompleted" class="">
              <a
                @click.prevent
                href=""
                :class="
                  batchSelection || nothingCompleted
                    ? 'text-gray-200'
                    : 'text-indigo-500'
                "
                class="flex items-center text-sm"
              >
                Clear
              </a>
              <template #popper>
                <div
                  class="w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="menu-button"
                  tabindex="-1"
                >
                  <div class="py-1" role="none">
                    <div
                      class="font-medium text-gray-500 group flex items-center p-2 text-xs"
                      role="menuitem"
                      tabindex="-1"
                      id="menu-item-0"
                    >
                      Completed Reminders
                    </div>
                  </div>
                  <div class="py-1" role="none">
                    <a
                      @click.prevent="clearCompleted()"
                      href=""
                      class="text-gray-700 hover:bg-gray-100 hover:text-gray-900 group flex items-center space-x-2 p-2 text-sm"
                      role="menuitem"
                      tabindex="-1"
                      id="menu-item-0"
                      data-test="`clear-all-completed-tasks-button`"
                    >
                      <span>All Completed</span>
                    </a>
                  </div>
                </div>
              </template>
            </VDropdown>
          </div>
        </div>
      </div>

      <TaskBatchActions v-if="isDesktop && batchSelection" />
      <div class="mt-2 flex-grow space-y-2">
        <ul
          v-for="(tasks, timeGroupLabel) in timeGroupedTasks"
          :key="timeGroupLabel"
          role="list"
          class="flex flex-col divide-y divide-gray-200"
        >
          <h2
            class="text-lg font-bold leading-5 sm:truncate sm:text-xl sm:tracking-tight text-gray-700"
          >
            {{ timeGroupLabel }}
          </h2>
          <SelectableTask
            v-for="task in tasks"
            :key="task.id"
            :task="task"
            context="completed"
            @completion-toggled="drop(task)"
          />
        </ul>
        <InfiniteLoading
          v-if="signedIn && serverSideTasks.pagy?.next"
          @infinite="loadTasks"
        />
      </div>
    </div>

    <TaskBatchActions v-if="!isDesktop && batchSelection" />
  </div>
</template>

<script setup>
import {
  ChevronLeftIcon,
  EllipsisHorizontalIcon,
} from "@heroicons/vue/20/solid";
import { useTasksStore } from "@/stores/tasks";
import { useUserStore } from "@/stores/user";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { ref, computed, onMounted } from "vue";
import { storeToRefs } from "pinia";
import TaskBatchActions from "@/components/tasks/TaskBatchActions.vue";
import SelectableTask from "@/components/tasks/SelectableTask.vue";
import { useRoute, useRouter } from "vue-router";
import api from "@/router/api";
import moment from "moment";
import _ from "lodash";

const tasksStore = useTasksStore();
const { batchSelection, batchTasks, effectiveTasks, completedCount } =
  storeToRefs(tasksStore);
const layoutStore = useWorkspaceLayoutStore();
const { isDesktop } = storeToRefs(layoutStore);
const userStore = useUserStore();
const { signedIn } = storeToRefs(userStore);

const serverSideTasks = ref({
  data: [],
  pagy: null,
});
const mainTitleVisible = ref(true);
const nothingCompleted = computed(() => completedTasks.value.length === 0);

const completedTasks = computed(() => {
  let collection = [];
  if (signedIn.value) {
    collection = serverSideTasks.value.data;
  } else {
    collection = effectiveTasks.value;
  }

  return collection.filter((task) => {
    return !!task.completedAt;
  });
});
const completedLength = computed(() => {
  return signedIn.value ? completedCount.value : completedTasks.value.length;
});
const timeGroupedTasks = computed(() => {
  return _.groupBy(completedTasks.value, completedTimingGrouper);
});

const router = useRouter();
const route = useRoute();

function completedTimingGrouper(task) {
  const momentDate = momentDateFor(task);
  const isToday = momentDate.isSame(moment(), "day");
  const monthFilterMoment = moment().subtract(1, "month").startOf("day");
  const isLast30Days = momentDate.isAfter(monthFilterMoment);

  if (isToday) return "Today";
  else if (isLast30Days) return "Previous 30 Days";
  else return momentDate.format("MMMM YYYY");
}
function momentDateFor(task) {
  if (signedIn.value) {
    return moment.unix(task.completedAt);
  } else {
    return moment(task.completedAt);
  }
}

onMounted(() => fetchTasks());

async function fetchTasks() {
  if (signedIn.value) {
    api.get(`completed_tasks`).then((json) => {
      serverSideTasks.value = json.data;
    });
  }
}
function drop(task) {
  if (signedIn.value) {
    serverSideTasks.value.data = _.differenceBy(
      serverSideTasks.value.data,
      [task],
      "id"
    );
  }
}
function cleanUrl(url) {
  return url.replace("/api/v1/", "");
}
const loadTasks = async ($state) => {
  if (signedIn.value && serverSideTasks.value.pagy?.next) {
    const endpoint = cleanUrl(serverSideTasks.value.pagy.next_url);
    try {
      api.post(endpoint).then((json) => {
        const { data, pagy } = json.data;

        serverSideTasks.value.data = _.unionBy(
          serverSideTasks.value.data,
          data,
          "id"
        );
        serverSideTasks.value.pagy = pagy;
        if (data.length < 25) $state.complete();
        else {
          $state.loaded();
        }
      });
    } catch (error) {
      $state.error();
    }
  } else {
    $state.complete();
  }
};

function clearTaskViewQuery() {
  router.push({
    name: route.name,
    query: {
      ...route.query,
      taskView: undefined,
    },
  });
}

function pageTitleVisibilityChanged(isVisible) {
  mainTitleVisible.value = isVisible;
}
function resetBatch() {
  tasksStore.resetBatch();
}
function backToLists() {
  tasksStore.backToLists();
  clearTaskViewQuery();
}
function clearCompleted() {
  if (signedIn.value) {
    api.post(`completed_task_deletions?threshold=all`).then(() => {
      tasksStore.deleteCompletedTasks("all");
      fetchTasks();
    });
  } else {
    tasksStore.deleteCompletedTasks("all");
  }
}
</script>
