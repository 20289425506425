import { ref } from "vue";
import { defineStore, acceptHMRUpdate } from "pinia";
import _ from "lodash";

export const useUserValidationSkipsChannelStore = defineStore(
  "userValidationSkipsChannel",
  () => {
    const UserValidationSkipsChannel = ref(null);
    const userValidationSkipsChannelDataQueue = ref([]);

    function pushAndTrim(data) {
      userValidationSkipsChannelDataQueue.value.push(data);
      userValidationSkipsChannelDataQueue.value = _.takeRight(
        userValidationSkipsChannelDataQueue.value,
        3
      );
    }

    return {
      UserValidationSkipsChannel,
      userValidationSkipsChannelDataQueue,
      pushAndTrim,
    };
  }
);

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useUserValidationSkipsChannelStore, import.meta.hot)
  );
}
