<template>
  <section aria-labelledby="hunt-locations">
    <ContentHeader>
      <template v-slot:title>Investment Criteria</template>
      <template v-if="context !== 'table-row'" v-slot:description
        >What type of real estate investments are being hunted?</template
      >
    </ContentHeader>

    <div
      :class="
        workspaceLayout === 'topAndBottom'
          ? 'flex items-start space-x-4'
          : 'grid grid-cols-1 gap-3'
      "
      class="p-2 mt-2"
    >
      <ContentPropertyUses
        :data-field="dataField"
        :optional="true"
        :inline="true"
        @positive-uses="hasUses = true"
      />
      <HuntInvestmentType v-if="hasUses" :data-field="dataField" />
      <HuntInvestmentSize v-if="hasUses" :data-field="dataField" />
    </div>
  </section>
</template>

<script setup>
import ContentHeader from "@/components/main-layout/secondary-panel/ContentHeader.vue";
import ContentPropertyUses from "@/components/crowdsourcing/ContentPropertyUses.vue";
import HuntInvestmentType from "@/components/crowdsourcing/HuntInvestmentType.vue";
import HuntInvestmentSize from "@/components/crowdsourcing/HuntInvestmentSize.vue";
import { ref } from "vue";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { storeToRefs } from "pinia";

defineProps(["dataField", "context"]);

const layoutStore = useWorkspaceLayoutStore();
const { workspaceLayout } = storeToRefs(layoutStore);

const hasUses = ref(false);
</script>
