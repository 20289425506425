<template>
  <div>
    <div v-if="loaded && items.data.length > 0">
      <ul
        role="list"
        class="grid gap-6"
        :class="
          workspaceLayout === 'sideBySide' ? 'grid-cols-2' : 'grid-cols-5'
        "
      >
        <PayloadItemInputOpportunity
          v-for="card in items.data"
          :key="card.id"
          class="col-span-1"
          :card="card"
          :static="true"
        />
      </ul>
      <InfiniteLoading
        v-if="items.pagy && items.pagy.next"
        @infinite="loadItems"
      />
    </div>
    <div
      v-else
      class="flex flex-col text-center items-center justify-center max-w-lg mx-auto"
      data-test="available-job-opportunities-empty-state"
    >
      <GiftIcon class="mt-8 mx-auto h-12 w-12 text-gray-400" />
      <h3 class="mt-2 text-sm font-medium text-gray-900">
        No Data Input Opportunities
      </h3>
      <p class="mt-1 text-sm text-gray-500">
        Opportunities to add data to Tower Hunt will appear here. Opportunities
        are filtered by location and user reputation, so the best way to find
        them is to be active in your markets. Inputters earn revenue!
      </p>
      <DataInputEasyButton
        v-if="!activeEasyDataInputPayloadItem"
        class="mt-3"
      />
    </div>
  </div>
</template>

<script setup>
import { GiftIcon } from "@heroicons/vue/24/outline";
import DataInputEasyButton from "@/components/DataInputEasyButton.vue";
import PayloadItemInputOpportunity from "@/components/collectible-cards/categories/easy-data-input/PayloadItemInputOpportunity.vue";
import { useUserStore } from "@/stores/user";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { storeToRefs } from "pinia";
import { ref, onMounted } from "vue";
import api from "@/router/api";
import _ from "lodash";

const userStore = useUserStore();
const { activeEasyDataInputPayloadItem } = storeToRefs(userStore);
const layoutStore = useWorkspaceLayoutStore();
const { workspaceLayout } = storeToRefs(layoutStore);

const loaded = ref(false);
const items = ref({
  data: [],
  pagy: null,
});

onMounted(() => {
  fetchItems();
});

async function fetchItems(paginationUrl = null) {
  items.value = ref({
    data: [],
    pagy: null,
  });

  api.get(paginationUrl || `easy_data_input_job_opportunities`).then((json) => {
    items.value = json.data;
    loaded.value = true;
  });
}

function cleanUrl(url) {
  return url.replace("/api/v1/", "");
}
const loadItems = async ($state) => {
  if (items.value.pagy?.next) {
    const endpoint = cleanUrl(items.value.pagy?.next_url);
    try {
      api.get(endpoint).then((json) => {
        const { data, pagy } = json.data;

        items.value.data = _.concat(items.value.data, data);
        items.value.pagy = pagy;
        if (data.length < 10) $state.complete();
        else {
          $state.loaded();
        }
      });
    } catch (error) {
      $state.error();
    }
  } else {
    $state.complete();
  }
};
</script>
