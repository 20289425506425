<template>
  <div class="flex items-center flex-wrap space-x-2">
    <VMenu :disabled="!card">
      <div
        class="px-1 py-0.5 rounded-md flex items-center space-x-1 text-left border-2 border-white text-sm font-medium text-gray-900 bg-gray-200"
      >
        <ArrowRightOnRectangleIcon class="h-4 w-4" />
        <p>Card</p>
      </div>
      <template #popper>
        <CollectibleCardPaper
          :auto-card-size="false"
          :preview="previewable"
          :card="card"
          :flipped="flipped"
          :licensed="licensed"
          :displayable="true"
          @flip="flipped = !flipped"
        />

        <div
          v-if="!flipped && (bundlesExist || unlockable)"
          class="mt-2 flex flex-col"
        >
          <div class="flex justify-center items-center">
            <UnlockBundles
              context="card-container"
              :data-field="dataField"
              :bundles="bundles"
              :all-fields-data="allFieldsData"
              :user-store="userStore"
              :modal-store="modalStore"
              :unlocker-store="unlockerStore"
              placement="top"
              class="ml-4 flex-shrink-0"
              @unlocked="unlocked"
            >
              <template v-slot:button>
                <button
                  type="button"
                  v-tooltip="'Instant Unlock'"
                  class="inline-flex items-center justify-center h-12 w-12 rounded-full bg-blue-400 hover:bg-blue-600"
                  data-test="unlock-collectible-card-button"
                >
                  <LockOpenIcon class="h-6 w-6 text-white" />
                </button>
              </template>
            </UnlockBundles>
          </div>
        </div>
      </template>
    </VMenu>
  </div>
</template>

<script setup>
import {
  ArrowRightOnRectangleIcon,
  LockOpenIcon,
} from "@heroicons/vue/20/solid";
import { onMounted, ref, computed } from "vue";
import { useUserStore } from "@/stores/user";
import { useUnlockerStore } from "@/stores/unlocker";
import { useModalStore } from "@/stores/modal";
import CollectibleCardPaper from "@/components/collectible-cards/CollectibleCardPaper.vue";
import UnlockBundles from "@/components/crowdsourcing/UnlockBundles.vue";
import { bundlesFor } from "@/components/crowdsourcing/unlockableBundles";
import api from "@/router/api";
import _ from "lodash";

const props = defineProps(["task"]);

const userStore = useUserStore();
const modalStore = useModalStore();
const unlockerStore = useUnlockerStore();

const card = ref(null);
const dataField = ref(null);
const allFieldsData = ref(null);
const bundles = computed(() => bundlesFor(dataField.value));
const safezone = computed(() => _.get(dataField.value, "safezone"));
const priced = computed(() => {
  const rawPrice = _.get(dataField.value, "price");
  const price = rawPrice ? _.toNumber(rawPrice) : null;
  return price && price > 0;
});
const unlockable = computed(() => {
  if (!safezone.value) return unstaked.value || priced.value;
  else return priced.value;
});
const unstaked = computed(() => _.get(dataField.value, "state") === "unstaked");
const unlicensedCount = computed(
  () => _.get(allFieldsData.value, "unlicensedIds", []).length,
);
const bundlesExist = computed(
  () =>
    (unlockable.value && !unstaked.value) ||
    (bundles.value.length > 0 && unlicensedCount.value > 0),
);

const flipped = ref(false);
const licensed = computed(() => _.get(dataField.value, "licensed"));
const previewable = computed(() => {
  if (card.value) {
    return (
      card.value.category === "generalized_location_news" && card.value.unlocked
    );
  } else {
    return false;
  }
});
const potentialUnlock = computed(
  () =>
    card.value &&
    card.value.category === "generalized_location_news" &&
    !card.value.unlocked,
);
const bundleDataFieldId = computed(() =>
  _.get(card.value, "bundleDataFieldId", false),
);

onMounted(() => fetchTaskContent());

async function fetchTaskContent() {
  const contentResponse = await api.get(
    `task_content/${props.task.contentType}/${props.task.contentId}`,
  );

  if (contentResponse?.data) {
    card.value = contentResponse.data;

    if (potentialUnlock.value && !dataField.value && !allFieldsData.value) {
      await fetchDataField();
      await fetchBundles();
    }
  }
}

async function fetchBundles() {
  api
    .get(
      `crowdsourced_data_field_bundles/${bundleDataFieldId.value}?bundle=all_fields`,
    )
    .then((json) => {
      allFieldsData.value = json.data;
    });
}

async function fetchDataField() {
  return new Promise((resolve) => {
    api.get(`data_field_by_id/${bundleDataFieldId.value}`).then((json) => {
      dataField.value = json.data;
      resolve();
    });
  });
}

function unlocked() {
  markCardUnlocked();
}
async function markCardUnlocked() {
  if (card.value) {
    const response = await api.patch(
      `unlock_collectible_card/${card.value.id}`,
    );

    if (response === "success") {
      card.value = null;
      dataField.value = null;
      allFieldsData.value = null;
      fetchTaskContent();
    }
  }
}
</script>
