<template>
  <div class="bg-white overflow-hidden divide-y divide-gray-200">
    <div
      v-if="expandable"
      class="flex items-center justify-between p-2 bg-gray-100"
    >
      <div class="text-gray-700 text-xs font-semibold uppercase tracking-wide">
        Timeline Space Usage Activity
      </div>
      <div class="flex items-center space-x-2">
        <button
          @click="expanded = !expanded"
          v-tooltip="expanded ? 'Minimize' : 'Expand'"
          type="button"
          class="h-5 w-5 p-1 inline-flex justify-center items-center text-sm text-gray-500 hover:text-gray-700"
        >
          <i v-if="expanded" class="fas fa-compress" />
          <i v-else class="fas fa-expand" />
        </button>
      </div>
    </div>
    <template v-if="expanded">
      <AvailabilityDetail
        v-for="(availability, index) in availabilities"
        :key="index"
        :availability="availability"
        :space-object="spaceObject"
        :availability-group-id="availabilityGroupId"
      />
    </template>
  </div>
</template>

<script setup>
import AvailabilityDetail from "@/components/space-usage-builder/AvailabilityDetail.vue";
import { useSpaceUsageBuilderStore } from "@/stores/spaceUsageBuilder";
import { useTimeTravelStore } from "@/stores/timeTravel";
import { storeToRefs } from "pinia";
import { ref, computed, watch } from "vue";
import decoratingAndFieldKey from "@/components/crowdsourcing/decoratingAndFieldKey";
import _ from "lodash";
import moment from "moment";

const props = defineProps([
  "spaceObject",
  "existingGroupId",
  "placeholderGroupId",
]);

const timeTravelStore = useTimeTravelStore();
const { asOfDate } = storeToRefs(timeTravelStore);
const spaceUsageBuilderStore = useSpaceUsageBuilderStore();
const { spaceUsageBuilder } = storeToRefs(spaceUsageBuilderStore);

const expanded = ref(true);

const expandable = computed(() => {
  return !availabilityGroup.value;
});
const availabilityGroupId = computed(() => {
  return props.existingGroupId || props.placeholderGroupId;
});
const availabilityGroup = computed(() => {
  const finder = props.existingGroupId
    ? { id: props.existingGroupId }
    : { placeholderId: props.placeholderGroupId };

  return _.find(
    _.get(spaceUsageBuilder.value, "availabilityGroups", []),
    finder,
  );
});
const groupAvailabilities = computed(() => {
  let availabilities = [];

  if (availabilityGroup.value) {
    _.forEach(
      _.get(availabilityGroup.value, "availabilities", {}),
      function (value) {
        availabilities.push(
          _.merge({}, value, {
            dealAction: _.get(availabilityGroup.value, "dealAction"),
          }),
        );
      },
    );
  } else if (props.spaceObject?.dataField) {
    const spaceKey = decoratingAndFieldKey(props.spaceObject?.dataField);

    spaceUsageBuilder.value.availabilityGroups.forEach((group) => {
      _.forEach(group.availabilities, function (value) {
        const availabilitySpaceKey = value.topLevelSpace
          ? decoratingAndFieldKey(value.topLevelSpace)
          : null;

        if (
          !value.existingAvailability &&
          availabilitySpaceKey &&
          spaceKey === availabilitySpaceKey
        ) {
          availabilities.push(value);
        }
      });
    });
  }

  return availabilities;
});
const combinedAvailabilities = computed(() => {
  return _.concat(
    _.get(props.spaceObject, "availabilities", []),
    groupAvailabilities.value,
  );
});
const allAvailabilitiesDated = computed(() => {
  return _.every(combinedAvailabilities.value, function (availability) {
    return !!_.get(actionableAvailabilityFor(availability), "date");
  });
});
const availabilities = computed(() => {
  if (allAvailabilitiesDated.value) {
    return _.orderBy(
      combinedAvailabilities.value,
      [
        function (availability) {
          return moment(
            _.get(actionableAvailabilityFor(availability), "date"),
          ).valueOf();
        },
        function (availability) {
          return _.get(actionableAvailabilityFor(availability), "id");
        },
      ],
      ["desc", "asc"],
    );
  } else {
    return _.orderBy(
      combinedAvailabilities.value,
      [
        function (availability) {
          return _.get(actionableAvailabilityFor(availability), "id");
        },
      ],
      ["asc"],
    );
  }
});

watch(asOfDate, (date, oldDate) => {
  if (oldDate && date !== oldDate) {
    spaceUsageBuilderStore.resetAvailabilities();
  }
});

function actionableAvailabilityFor(availability) {
  const dataFieldAvailabilityFieldContent = _.get(availability, "fieldContent");
  const spaceUsageBuilderExistingAvailability = _.get(
    availability,
    "existingAvailability",
  );

  return (
    dataFieldAvailabilityFieldContent ||
    spaceUsageBuilderExistingAvailability ||
    availability
  );
}
</script>
