<template>
  <main v-if="validationsActive && selectedTab" class="h-full flex flex-1">
    <nav aria-label="Sidebar" class="shrink-0 p-2 bg-gray-100 overflow-y-auto">
      <div class="relative flex flex-col space-y-2">
        <a
          v-for="tab in tabs"
          :key="tab.name"
          @click.prevent="selectTab(tab)"
          :href="tab.href"
          v-tooltip="tab.name"
          :class="
            selectedTab.name === tab.name
              ? 'text-gray-700 bg-gray-300'
              : 'text-gray-500 hover:bg-gray-200'
          "
          class="shrink-0 inline-flex items-center justify-center h-8 w-8 rounded-lg"
          :data-test="`${_.kebabCase(tab.tabQuery)}-nav`"
        >
          <span class="sr-only">{{ tab.name }}</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            :class="selectedTab.name === tab.name ? '' : ''"
            class="h-5 w-5"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 24 24"
            v-html="pathsForIcon(tab.svgPaths)"
          ></svg>
        </a>
      </div>
    </nav>
    <div class="flex-grow overflow-x-hidden">
      <component :is="selectedTab.component" v-bind="selectedTabProperties" />
    </div>
  </main>
</template>

<script setup>
import { useSecondaryPanelStore } from "@/stores/secondaryPanel";
import { ref, computed, onMounted, watch, markRaw } from "vue";
import { useRouter, useRoute } from "vue-router";
import _ from "lodash";
import AvailableValidations from "@/components/crowdsourcing/AvailableValidations.vue";
import PendingValidations from "@/components/crowdsourcing/PendingValidations.vue";
import UnpublishedValidations from "@/components/crowdsourcing/UnpublishedValidations.vue";
import { storeToRefs } from "pinia";
import pathsForIcon from "@/assets/pathsForIcon";

const secondaryPanelStore = useSecondaryPanelStore();
const { validationsActive } = storeToRefs(secondaryPanelStore);

const tabs = [
  {
    name: "Available",
    tabQuery: "Available",
    href: "",
    svgPaths: [
      "M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z",
    ],
    component: markRaw(AvailableValidations),
  },
  {
    name: "Unpublished",
    tabQuery: "Unpublished",
    href: "",
    svgPaths: [
      "M12 9v3.75m0-10.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.75c0 5.592 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.57-.598-3.75h-.152c-3.196 0-6.1-1.249-8.25-3.286zm0 13.036h.008v.008H12v-.008z",
    ],
    component: markRaw(UnpublishedValidations),
  },
  {
    name: "In Validation",
    tabQuery: "InValidation",
    href: "",
    svgPaths: [
      "M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 0 1-.825-.242m9.345-8.334a2.126 2.126 0 0 0-.476-.095 48.64 48.64 0 0 0-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0 0 11.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155",
    ],
    component: markRaw(PendingValidations),
  },
];
const selectedTab = ref(null);
const selectedTabProperties = {};

const router = useRouter();
const route = useRoute();
const query = computed(() => route.query);

function selectTab(tab) {
  const oldTabQuery = _.get(selectedTab.value, "tabQuery");

  if (tab) selectedTab.value = tab;
  else if (_.get(query.value, "horizontalTab") === "Validations") {
    const foundTab = _.find(tabs, {
      tabQuery: query.value.verticalTab || "Available",
    });
    const defaultTab = _.find(tabs, {
      tabQuery: "Available",
    });
    selectedTab.value = foundTab || defaultTab;
  }

  const newTabQuery = _.get(selectedTab.value, "tabQuery");
  if (
    validationsActive.value &&
    selectedTab.value &&
    newTabQuery !== oldTabQuery
  ) {
    router.push({
      name: route.name,
      query: {
        ...route.query,
        verticalTab: selectedTab.value.tabQuery,
      },
    });
  }
}

onMounted(() => {
  secondaryPanelStore.setSecondaryPanelWidth("auto 10px 800px");
  selectTab();
});

watch(query, () => {
  selectTab();
});
</script>
