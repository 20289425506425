<template>
  <div class="flex flex-col space-y-1">
    <div class="flex flex-wrap items-center space-x-1">
      <template v-if="fetchedOffers.length > 0">
        <DataField
          v-for="dataField in fetchedOffers"
          :key="`offer-${dataField.localId}`"
          :data-field="dataField"
          primary-text-path="fieldContentType"
          text-classes="text-sm font-medium"
          @completed="fetchOffers"
        />
      </template>
      <template v-else-if="groupFetchedOffers.length > 0">
        <DataField
          v-for="dataField in groupFetchedOffers"
          :key="`offer-${dataField.localId}`"
          :data-field="dataField"
          primary-text-path="fieldContentType"
          text-classes="text-sm font-medium"
          @completed="fetchOffers"
        />
      </template>
    </div>

    <div class="flex items-center">
      <div v-if="creatingOffer" class="flex items-center space-x-1">
        <div class="relative rounded-md shadow-sm">
          <div
            class="absolute inset-y-0 left-0 pl-2 flex items-center pointer-events-none"
          >
            <span class="text-gray-500 sm:text-sm">$</span>
          </div>
          <CurrencyInput
            v-focus
            v-model="dollarValue"
            :options="currencyInputOptions"
            name="price"
            id="price"
            data-test="investment-offer-dollar-value-input"
            class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-5 pr-1 text-sm border-gray-300 rounded-md"
            placeholder="Optional"
          />
        </div>
        <button
          @click="cancel"
          type="button"
          class="inline-flex items-center p-1 border border-gray-300 rounded-full shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <XMarkIcon class="h-4 w-4" />
        </button>

        <DataVisibilityButton visibility="safezone" class="inline-flex">
          <template v-slot:button>
            <button
              @click="save"
              :disabled="originatingData"
              type="button"
              data-test="investment-offer-save"
              class="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-yellow-500 hover:bg-yellow-600 focus:ring-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2"
            >
              <PulseLoader
                v-if="originatingData"
                :loading="true"
                size="3px"
                color="#f3f4f6"
              />
              <CheckIcon v-else class="h-4 w-4" />
            </button>
          </template>
        </DataVisibilityButton>
      </div>

      <div v-else class="flex items-center">
        <DataVisibilityButton visibility="safezone" :tooltip="tooltip">
          <template v-slot:button>
            <button
              @click="createOffer"
              type="button"
              data-test="add-investment-offer-button"
              class="group py-0.5 px-1 flex items-center justify-between rounded-full border border-gray-300 shadow-sm space-x-2 text-left hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <span class="min-w-0 flex-1 flex items-center space-x-1">
                <span class="flex-shrink-0 flex items-center">
                  <span
                    class="inline-flex items-center justify-center h-5 w-5 rounded-full bg-yellow-500"
                  >
                    <CurrencyDollarIcon class="h-4 w-4 text-white" />
                  </span>
                </span>
                <span class="min-w-0 flex-1">
                  <span class="text-xs font-medium text-gray-900 truncate"
                    >Add a bid</span
                  >
                </span>
              </span>
            </button>
          </template>
        </DataVisibilityButton>
      </div>
    </div>
  </div>
</template>

<script setup>
import { XMarkIcon, CheckIcon } from "@heroicons/vue/20/solid";
import { CurrencyDollarIcon } from "@heroicons/vue/24/outline";
import CurrencyInput from "@/components/crowdsourcing/CurrencyInput.vue";
import DataField from "@/components/crowdsourcing/DataField.vue";
import { useCrowdsourcedChangeGroupStore } from "@/stores/crowdsourcedChangeGroup";
import { useDealBuilderStore } from "@/stores/dealBuilder";
import { computed, onMounted, ref } from "vue";
import { storeToRefs } from "pinia";
import api from "@/router/api";
import _ from "lodash";
import DataVisibilityButton from "@/components/crowdsourcing/DataVisibilityButton.vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";

const props = defineProps([
  "companyInvolvementDataField",
  "context",
  "layerType",
  "rawCompanyId",
  "investmentGroupId",
]);

const changeGroupStore = useCrowdsourcedChangeGroupStore();
const { originatingData, changeGroupId } = storeToRefs(changeGroupStore);
const dealBuilderStore = useDealBuilderStore();
const { dealBuilder } = storeToRefs(dealBuilderStore);

const fetchedOffers = ref([]);
const groupFetchedOffers = ref([]);
const creatingOffer = ref(false);
const dollarValue = ref(null);
const currencyInputOptions = ref({
  currency: "USD",
  currencyDisplay: "hidden",
  hideCurrencySymbolOnFocus: true,
  hideGroupingSeparatorOnFocus: false,
  hideNegligibleDecimalDigitsOnFocus: false,
  autoDecimalDigits: false,
  autoSign: true,
  useGrouping: true,
  accountingSign: false,
});

const investmentId = computed(() => {
  return _.get(props.companyInvolvementDataField, "decoratingContentId");
});
const validDollar = computed(() => {
  return _.toNumber(dollarValue.value) > 10000;
});
const tooltip = computed(() => {
  if (props.context === "investment-group-players") {
    return "Offers you add here are for the whole portfolio.";
  } else {
    return "";
  }
});
const fetchRequestKey = computed(() => {
  if (props.investmentGroupId) {
    return `investment_group_investment_offers_${props.investmentGroupId}_${props.rawCompanyId}&client_role=prospect`;
  } else {
    return `crowdsourced_data_fields_CompanyInvolvement_${props.companyInvolvementDataField.fieldContentId}?field_name=InvestmentOffer`;
  }
});

onMounted(() => fetchOffers());

function createOffer() {
  creatingOffer.value = true;
}
function cancel() {
  creatingOffer.value = false;
  dollarValue.value = null;
}
function reset() {
  cancel();
  fetchedOffers.value = [];
  fetchOffers();
}
function clearValuationFetchRequests() {
  let dropKey = null;

  if (props.investmentGroupId) {
    dropKey = `content_valuations_InvestmentGroup_${props.investmentGroupId}`;
  } else {
    dropKey = `content_valuations_Investment_${investmentId.value}`;
  }

  console.log("drop valuation key", dropKey);

  dealBuilderStore.dropFetchRequest(dropKey);
}
async function afterPersist(json) {
  await dealBuilderStore.postEditingPatch(json, fetchRequestKey.value);
  clearValuationFetchRequests();
  reset();
  // this.$store.dispatch("flash", "Saved!");
}
function persist() {
  let payload = {
    dollarValue: validDollar.value ? dollarValue.value : null,
    investmentId: investmentId.value,
    companyInvolvementId: _.get(
      props.companyInvolvementDataField,
      "fieldContentId",
    ),
    changeGroupId: changeGroupId.value,
  };

  if (props.investmentGroupId) {
    payload.investmentGroupId = props.investmentGroupId;
    payload.rawCompanyId = props.rawCompanyId;

    return api.post(`investment_group_investment_offers`, payload);
  } else {
    return api.post(`investment_offers`, payload);
  }
}
function save() {
  const apiRequestFunc = () => persist();
  const successCallback = (json) => afterPersist(json);
  const failureCallback = () => cancel();

  return changeGroupStore.originateData(
    apiRequestFunc,
    successCallback,
    failureCallback,
  );
}

function fetchOffers() {
  if (props.investmentGroupId) {
    console.log("fetch group offers");
    if (props.rawCompanyId && _.isNumber(props.rawCompanyId)) {
      if (dealBuilderStore.alreadyFetched(fetchRequestKey.value)) {
        groupFetchedOffers.value = dealBuilderStore.alreadyFetchedFieldsFor(
          fetchRequestKey.value,
        );
      } else {
        api
          .get(
            `investment_group_investment_offers/?investment_group_id=${props.investmentGroupId}&raw_company_id=${props.rawCompanyId}&client_role=prospect`,
          )
          .then((json) => {
            dealBuilderStore.interceptablePatch(
              json.data,
              fetchRequestKey.value,
            );
            groupFetchedOffers.value = json.data;
          });
      }
    }
  } else {
    if (dealBuilderStore.alreadyFetched(fetchRequestKey.value)) {
      fetchedOffers.value = dealBuilderStore.alreadyFetchedFieldsFor(
        fetchRequestKey.value,
      );
    } else {
      api
        .get(
          `crowdsourced_data_fields/CompanyInvolvement/${props.companyInvolvementDataField.fieldContentId}?field_name=InvestmentOffer`,
        )
        .then((json) => {
          dealBuilderStore.interceptablePatch(json.data, fetchRequestKey.value);
          fetchedOffers.value = json.data;
        });
    }
  }
}
</script>
