<template>
  <div class="h-full flex flex-col">
    <div class="p-2 bg-gray-50 flex items-center justify-around">
      <a @click.prevent="cancel" href="" class="text-sm text-indigo-500">
        Cancel
      </a>
      <h3
        class="flex min-w-0 flex-1 items-center justify-center font-medium text-gray-900"
      >
        Move Reminders
      </h3>
      <a
        @click.prevent="move"
        href=""
        :class="
          !!taskBatchMoveToList
            ? 'text-indigo-500 font-medium'
            : 'text-gray-500 cursor-not-allowed'
        "
        class="flex items-center text-sm"
      >
        Move
      </a>
    </div>
    <div class="p-2 mt-2 overflow-y-auto bg-white shadow rounded-md">
      <ul role="list" class="divide-y divide-gray-200">
        <li v-for="list in effectiveTaskLists" :key="list.id">
          <a
            @click.prevent="select(list)"
            href=""
            class="block hover:bg-gray-50"
            :class="disabled(list) ? 'cursor-not-allowed' : ''"
          >
            <div class="flex items-center p-2">
              <div class="flex min-w-0 flex-1 items-center">
                <div
                  :class="
                    disabled(list)
                      ? 'bg-gray-300'
                      : `${taskListStore.colorFor(list)}`
                  "
                  class="rounded-full p-1.5"
                >
                  <ListBulletIcon
                    class="h-5 w-5 text-white"
                    aria-hidden="true"
                  />
                </div>
                <div class="min-w-0 flex-1 px-4">
                  <p
                    :class="disabled(list) ? 'text-gray-300' : `text-gray-700`"
                    class="truncate text-sm font-medium"
                  >
                    {{ list.name }}
                  </p>
                </div>
              </div>
              <CheckIcon
                v-if="selected(list)"
                class="h-5 w-5 text-indigo-500"
                aria-hidden="true"
              />
            </div>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import { ListBulletIcon, CheckIcon } from "@heroicons/vue/20/solid";
import { useTasksStore } from "@/stores/tasks";
import { useTaskListStore } from "@/stores/taskList";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import _ from "lodash";

const taskListStore = useTaskListStore();
const tasksStore = useTasksStore();
const { effectiveTaskLists, batchTasks, taskBatchMove, taskBatchMoveToList } =
  storeToRefs(tasksStore);
const nameCounts = computed(() => _.countBy(batchTasks.value, "accessTokenId"));
const homogeneousBatch = computed(() => {
  return batchTasks.value.length > 0 && _.size(nameCounts.value) === 1;
});

function selected(list) {
  return list.name === _.get(taskBatchMoveToList.value, "name", null);
}
function disabled(list) {
  if (homogeneousBatch.value) {
    const names = _.keys(nameCounts.value);
    const disabledListName = _.head(names);

    return list.name === disabledListName;
  } else {
    return false;
  }
}
function select(list) {
  if (!disabled(list)) {
    taskBatchMoveToList.value = list;
  }
}
function cancel() {
  taskBatchMoveToList.value = null;
  taskBatchMove.value = false;
}
function move() {
  if (taskBatchMoveToList.value) tasksStore.batchMove();
}
</script>
