import { currencyBelowThreshold } from "@/assets/numberHelpers";
import sizeUseOutputs from "@/components/analyze/calculations/sizesUses";
import _ from "lodash";

export default function pricePerAreaCalc(calculationData, calculationOptions) {
  // VALUE
  const valueDataFields = calculationData?.valueDataFields;
  const numericDollarValues = valueDataFields.map((df) => {
    return _.toNumber(df.fieldValue);
  });
  const totalValue = _.sum(numericDollarValues);

  // SIZE & USE
  const sizeUseInputs = {
    sizesUses: calculationData,
  };
  const sizeUseData = sizeUseOutputs(null, sizeUseInputs, calculationOptions);
  const topLevelArea = sizeUseData?.numericStandardizedArea;

  const sizeRankedUses = _.orderBy(
    sizeUseData.useTypes,
    ["numericStandardizedArea"],
    ["desc"],
  );

  // OPTIONS
  const denominator = calculationOptions.pricePerArea?.denominator;

  let perArea, perAreaString;

  switch (denominator) {
    case "topLevel":
      if (topLevelArea > 0) {
        perArea = totalValue / topLevelArea;

        perAreaString = `$${currencyBelowThreshold(perArea)}/SF`;
      }
      break;
    case null:
    case undefined:
      break;
    default: {
      const denominatorData = _.split(denominator, "_");
      const denominatorUseType = _.head(denominatorData);
      const denominatorUnit = _.last(denominatorData);
      const useObject = _.find(sizeRankedUses, { name: denominatorUseType });
      const area =
        denominatorUnit === "standardized"
          ? useObject?.numericStandardizedArea
          : useObject?.numericUseBasedArea;

      if (area > 0) {
        perArea = totalValue / area;
        perAreaString =
          denominatorUnit === "standardized"
            ? currencyBelowThreshold(perArea, 1_000_000, 1)
            : currencyBelowThreshold(perArea, 1_000, 1);
        const unit =
          denominatorUnit === "standardized" ? "SF" : useObject?.perAreaName;

        perAreaString = `$${perAreaString}/${unit}`;
      }
      break;
    }
  }

  // console.log(
  //   "price per area calc",
  //   denominator,
  //   totalValue,
  //   topLevelArea,
  //   perArea,
  //   perAreaString,
  //   sizeUseData,
  //   calculationData,
  //   calculationOptions,
  // );

  return {
    perArea,
    perAreaString,
    topLevelAreaString: sizeUseData?.standardizedArea,
    sizeRankedUses,
    topLevelWarnings: sizeUseData.warnings,
    calculationOptions,
  };
}
