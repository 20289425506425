<template>
  <div class="relative overflow-hidden">
    <div class="absolute inset-0">
      <div
        class="absolute inset-0 bg-gradient-to-r from-blue-800 to-purple-700 mix-blend-multiply"
      />
    </div>
    <div class="relative px-4 py-16">
      <h1 class="text-center text-4xl font-bold tracking-tight">
        <span class="block text-white">Unlockables</span>
        <span class="block text-purple-200">present</span>
      </h1>
      <p class="mx-auto mt-6 max-w-lg text-center text-lg text-purple-200">
        There is more data to see here if you unlock it. There are no fixed
        monthly costs on Tower Hunt. You pay only when you access data.
      </p>
      <div class="mx-auto mt-10 max-w-sm flex justify-center">
        <div class="space-y-4">
          <a
            href=""
            @click.prevent="modalStore.closeModal"
            class="flex items-center justify-center rounded-md border border-transparent bg-white px-4 py-3 text-base font-medium text-purple-700 shadow-sm hover:bg-purple-50 sm:px-8"
            data-test="prompt-to-subscribe-acknowledge-button"
            >Got it</a
          >
          <a
            href=""
            @click.prevent="getHelp"
            class="flex items-center justify-center rounded-md border border-white px-4 py-3 text-base font-medium text-white hover:text-purple-100 hover:border-purple-100 shadow-sm sm:px-8"
            data-test="prompt-to-subscribe-acknowledge-button"
            >Learn more</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useModalStore } from "@/stores/modal";
import { useDocumentationStore } from "@/stores/documentation";
import { unlockDatapoints } from "@/assets/documentation/articles/unlockDatapoints";
import { nextTick } from "vue";

const modalStore = useModalStore();
const documentationStore = useDocumentationStore();

async function getHelp() {
  documentationStore.viewArticle(unlockDatapoints);
  await nextTick();
  modalStore.closeModal();
}
</script>
