<template>
  <div class="">
    <div class="p-2 flex items-center justify-between">
      <h1 class="font-semibold text-gray-900">
        {{
          fieldContentType === "InvestmentGroup" ? "Portfolio " : "Asset "
        }}Valuations
      </h1>
      <div v-if="creatingValuation" class="flex items-center space-x-1">
        <ValuationField
          :dollar-value="dollarValue"
          :dollar-value-type="dollarValueType"
          :state="state"
          :poppable="false"
          :deal-action="dealAction"
          @new-dollar-value="setNewDollarValue"
          @new-dollar-value-type="setNewDollarValueType"
        />
        <button
          @click="cancel"
          type="button"
          class="inline-flex items-center p-1 border border-gray-300 rounded-full shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <XMarkIcon class="h-4 w-4" />
        </button>

        <DataVisibilityButton visibility="safezone" class="inline-flex">
          <template v-slot:button>
            <button
              @click="save"
              :disabled="originatingData"
              type="button"
              class="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-yellow-500 hover:bg-yellow-600 focus:ring-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2"
              data-test="valuations-detail-save-valuation-button"
            >
              <PulseLoader
                v-if="originatingData"
                :loading="true"
                size="3px"
                color="#f3f4f6"
              />
              <CheckIcon v-else class="h-4 w-4" />
            </button>
          </template>
        </DataVisibilityButton>
      </div>
      <div
        v-else-if="fieldContent.id || fieldContent.fieldContentId"
        class="flex items-center"
      >
        <DataVisibilityButton visibility="safezone">
          <template v-slot:button>
            <button
              @click="createValuation"
              :disabled="originatingData"
              type="button"
              data-test="valuations-detail-add-valuation-button"
              class="group py-1 px-1.5 flex items-center justify-between rounded-full border border-gray-300 shadow-sm space-x-2 text-left hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <PulseLoader
                v-if="originatingData"
                :loading="true"
                size="3px"
                color="#f3f4f6"
              />
              <span v-else class="min-w-0 flex-1 flex items-center space-x-1">
                <span class="flex-shrink-0 flex items-center">
                  <span
                    class="inline-flex items-center justify-center h-5 w-5 rounded-full bg-yellow-500"
                  >
                    <PencilIcon class="h-3 w-3 text-white" />
                  </span>
                </span>
                <span class="min-w-0 flex-1">
                  <span class="text-sm font-medium text-gray-900 truncate"
                    >Add a valuation</span
                  >
                </span>
              </span>
            </button>
          </template>
        </DataVisibilityButton>
      </div>
    </div>
    <div class="flex flex-col">
      <ViewPortfolioAlert
        v-if="fieldContent.hasPortfolioValuations"
        content-type="Valuation"
        @open-portfolio="emit('open-portfolio')"
      />
      <div v-if="loaded && valuations.length > 0" class="overflow-x-auto">
        <div class="inline-block min-w-full py-2 align-middle">
          <div class="shadow ring-1 ring-black ring-opacity-5">
            <table class="min-w-full divide-y divide-gray-300">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="relative whitespace-nowrap py-3.5 pl-4 pr-3"
                  >
                    <span class="sr-only">Expand</span>
                  </th>
                  <th
                    scope="col"
                    class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Type
                  </th>
                  <th
                    scope="col"
                    class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Value
                  </th>
                  <th
                    scope="col"
                    class="whitespace-nowrap py-3.5 pl-3 pr-4 text-left text-sm font-semibold text-gray-900"
                  >
                    $/Unit
                  </th>
                </tr>
              </thead>
              <ValuationSyntheticTableRow
                v-for="valuation in valuations"
                :key="valuation.localId"
                :valuation="valuation"
                :field-content-type="fieldContentType"
                :field-content="fieldContent"
                @refetch="refetch"
              />
            </table>
          </div>
        </div>
      </div>
      <div
        v-else-if="fieldContent.id || fieldContent.fieldContentId"
        class="mt-4 pb-4 text-center"
      >
        <svg
          class="mx-auto h-8 w-8 text-gray-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
        <h3 class="mt-2 text-sm font-medium text-gray-900">No valuations</h3>
        <p class="mt-1 text-sm text-gray-500">
          Add one to track key investment metrics.
        </p>
      </div>
      <div v-else class="mt-4 pb-4 text-center">
        <svg
          class="mx-auto h-8 w-8 text-gray-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
        <h3 class="mt-2 text-sm font-medium text-gray-900">
          Unsaved investment
        </h3>
        <p class="mt-1 text-sm text-gray-500">
          Once you save, valuations will appear here.
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { XMarkIcon, CheckIcon } from "@heroicons/vue/20/solid";
import { PencilIcon } from "@heroicons/vue/24/outline";
import ValuationField from "@/components/deal-builder/ValuationField.vue";
import ValuationSyntheticTableRow from "@/components/deal-builder/ValuationSyntheticTableRow.vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { ref, computed, onMounted } from "vue";
import { storeToRefs } from "pinia";
import { useCrowdsourcedChangeGroupStore } from "@/stores/crowdsourcedChangeGroup";
import { useDealBuilderStore } from "@/stores/dealBuilder";
import api from "@/router/api";
import _ from "lodash";
import DataVisibilityButton from "@/components/crowdsourcing/DataVisibilityButton.vue";
import ViewPortfolioAlert from "./ViewPortfolioAlert.vue";

const props = defineProps(["fieldContent", "fieldContentType", "hasPortfolio"]);
const emit = defineEmits(["open-portfolio"]);
const changeGroupStore = useCrowdsourcedChangeGroupStore();
const { originatingData, changeGroupId } = storeToRefs(changeGroupStore);
const dealBuilderStore = useDealBuilderStore();

const creatingValuation = ref(false);
const dollarValue = ref(null);
const dollarValueType = ref(null);
const valuations = ref([]);
const loaded = ref(false);

const dealAction = computed(() => {
  return _.get(props.fieldContent, "dealAction");
});
const state = computed(() => {
  return null;
});
const validDollar = computed(() => {
  return dollarValueType.value && _.toNumber(dollarValue.value) > 10000;
});

onMounted(() => {
  refetch("all");
});

const fetchRequestKey = computed(
  () => `content_valuations_${props.fieldContentType}_${props.fieldContent.id}`,
);

function refetch(scope = "valuations") {
  fetchValuations();
  if (scope === "all") {
    dealBuilderStore.fetchDealBuilderCalculationData({
      contentType: props.fieldContentType,
      contentId: props.fieldContent.id || props.fieldContent.fieldContentId,
      override: true,
    });
  }
}

async function fetchValuations() {
  if (props.fieldContent.id) {
    loaded.value = false;

    if (dealBuilderStore.alreadyFetched(fetchRequestKey.value)) {
      const valuationFields = dealBuilderStore.alreadyFetchedFieldsFor(
        fetchRequestKey.value,
      );
      valuations.value = _.sortBy(valuationFields, ["fieldContent.sortOrder"]);
    } else {
      const valuationsResponse = await api.get(
        `content_valuations/${props.fieldContentType}/${props.fieldContent.id}`,
      );
      dealBuilderStore.interceptablePatch(
        valuationsResponse.data,
        fetchRequestKey.value,
      );
      valuations.value = _.sortBy(valuationsResponse.data, [
        "fieldContent.sortOrder",
      ]);
    }
    loaded.value = true;
  }
}
function createValuation() {
  creatingValuation.value = true;
}
function setNewDollarValue(newValue) {
  dollarValue.value = newValue;
}
function setNewDollarValueType(newValueType) {
  dollarValueType.value = newValueType;
}
function cancel() {
  creatingValuation.value = false;
  dollarValue.value = null;
  dollarValueType.value = null;
}
async function save() {
  if (validDollar.value && props.fieldContent.id) {
    const payload = {
      contentType: props.fieldContentType,
      contentToken: props.fieldContent.id,
      dollarValue: dollarValue.value,
      dollarValueType: dollarValueType.value,
      changeGroupId: changeGroupId.value,
    };

    const apiRequestFunc = () =>
      api.post(
        `content_valuations/${props.fieldContentType}/${props.fieldContent.id}`,
        payload,
      );
    const successCallback = async (json) => {
      cancel();
      await dealBuilderStore.postEditingPatch(json, fetchRequestKey.value);
      fetchValuations();
    };
    const failureCallback = () => cancel();

    await changeGroupStore.originateData(
      apiRequestFunc,
      successCallback,
      failureCallback,
    );
  }
}
</script>
