<template>
  <div class="col-span-2" :data-test="`${contentType}-${contentId}-prospects`">
    <dt class="text-sm font-medium text-gray-500">
      <template v-if="!availability">Bidders&nbsp;&amp;&nbsp;</template
      >Prospects
    </dt>
    <dd
      v-if="fetchedProspects.length > 0"
      :class="
        workspaceLayout === 'topAndBottom'
          ? 'grid-cols-4 grid-flow-row-dense'
          : 'grid-cols-2'
      "
      class="mt-1 grid gap-2"
    >
      <div
        v-for="companyInvolvementDataField in fetchedProspects"
        :key="companyInvolvementDataField.fieldContentId"
        class="p-2 space-y-2 rounded-md border border-dashed border-gray-300"
      >
        <InvolvedCompany
          :can-award="canAward"
          :decorating-content-data-field="companyInvolvementDataField"
          layer-type="Prospect"
          layer-color="bg-cyan-400"
          :providers-count="fetchedProspects.length"
          :context="`${_.lowerCase(contentType)}-players`"
          :fetch-milliseconds="fetchMilliseconds"
          :investment-id="investmentId"
          :availability-id="availabilityId"
          :parent-component-save-function="persistCompanies"
          @refetch="fetchProspects"
          @override-refetch="fetchProspects"
          @refetch-investment="refetchInvestment"
          @refetch-availability="refetchAvailability"
        />
        <div class="space-y-1">
          <dt class="text-sm font-medium text-gray-500">
            Advisors<template v-if="!availability"
              >&nbsp;&amp; Capital Sources</template
            >
          </dt>
          <ContentCompaniesContacts
            :data-field="companyInvolvementDataField"
            :investment-id="investmentId"
            :availability-id="availabilityId"
            :fetch-milliseconds="fetchMilliseconds"
            context="prospect"
          />
        </div>
      </div>
    </dd>
    <div v-if="editingProspects" class="mt-1 flex flex-col space-y-1">
      <div
        class="border border-gray-300 rounded-lg shadow-sm focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500"
      >
        <CompanyContactAutocomplete
          label="Prospects"
          :companies="localProspects"
          :input-key="`${_.lowerCase(contentType)}-prospects`"
          @new-companies="setNewCompanies"
          @remove-company="removeCompany"
          @set-cross-interaction="setCrossInteraction"
        />
      </div>
      <div
        v-if="investment?.id || availability?.id"
        class="flex justify-end space-x-2"
      >
        <button
          @click="cancelProspectEditing"
          type="button"
          class="inline-flex items-center p-1 border border-gray-300 rounded-full shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <XMarkIcon class="h-4 w-4" />
        </button>

        <DataVisibilityButton visibility="safezone" class="inline-flex">
          <template v-slot:button>
            <button
              @click="saveProspects"
              :disabled="originatingData"
              type="button"
              data-test="investment-prospects-save"
              class="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-yellow-500 hover:bg-yellow-600 focus:ring-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2"
            >
              <PulseLoader
                v-if="originatingData"
                :loading="true"
                size="3px"
                color="#f3f4f6"
              />
              <CheckIcon v-else class="h-4 w-4" />
            </button>
          </template>
        </DataVisibilityButton>
      </div>
    </div>
    <dd v-else-if="localProspects.length > 0" class="mt-1 flex flex-wrap">
      <li
        @click="editingProspects = true"
        v-for="company in localProspects"
        :key="company.name"
        v-tooltip="'Unsaved'"
        class="inline-flex rounded-full items-center my-1 mr-1 py-0.5 px-2.5 text-sm font-medium bg-indigo-100 text-indigo-700"
      >
        {{ company.name }}
      </li>
    </dd>
    <div v-else class="mt-2 flex items-center space-x-2">
      <DataVisibilityButton visibility="safezone">
        <template v-slot:button>
          <button
            @click="editingProspects = true"
            type="button"
            data-test="add-prospects-button"
            class="group py-1 px-1.5 flex items-center justify-between rounded-full border border-gray-300 shadow-sm space-x-2 text-left hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <span class="min-w-0 flex-1 flex items-center space-x-1">
              <span class="flex-shrink-0 flex items-center">
                <span
                  class="inline-flex items-center justify-center h-5 w-5 rounded-full bg-yellow-500"
                >
                  <BriefcaseIcon class="h-4 w-4 text-white" />
                </span>
              </span>
              <span class="min-w-0 flex-1">
                <span class="text-sm font-medium text-gray-900 truncate"
                  >Add companies</span
                >
              </span>
            </span>
          </button>
        </template>
      </DataVisibilityButton>
    </div>
  </div>
</template>

<script setup>
import { XMarkIcon, CheckIcon } from "@heroicons/vue/20/solid";
import { BriefcaseIcon } from "@heroicons/vue/24/outline";
import CompanyContactAutocomplete from "@/components/crowdsourcing/CompanyContactAutocomplete.vue";
import ContentCompaniesContacts from "@/components/crowdsourcing/ContentCompaniesContacts.vue";
import InvolvedCompany from "@/components/crowdsourcing/InvolvedCompany.vue";
import { ref, computed, watch, onMounted } from "vue";
import { useCrowdsourcedChangeGroupStore } from "@/stores/crowdsourcedChangeGroup";
import { useDealBuilderStore } from "@/stores/dealBuilder";
import { useSpaceUsageBuilderStore } from "@/stores/spaceUsageBuilder";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { storeToRefs } from "pinia";
import api from "@/router/api";
import decoratingAndFieldKey from "@/components/crowdsourcing/decoratingAndFieldKey";
import _ from "lodash";
import DataVisibilityButton from "@/components/crowdsourcing/DataVisibilityButton.vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";

const props = defineProps([
  "assetObject",
  "investment",
  "spaceObject",
  "availability",
  "fetchMilliseconds",
]);
const emit = defineEmits(["refetch"]);

const dealBuilderStore = useDealBuilderStore();
const {
  dealBuilder,
  patchableCompanySize: dealBuilderPatchableCompanySize,
  allFetchedFields: dealBuilderFetchedFields,
} = storeToRefs(dealBuilderStore);
const spaceUsageBuilderStore = useSpaceUsageBuilderStore();
const {
  allFetchedFields: spaceUsageBuilderFetchedFields,
  patchableCompanySize: spaceUsageBuilderPatchableCompanySize,
  availabilityGroups,
} = storeToRefs(spaceUsageBuilderStore);
const changeGroupStore = useCrowdsourcedChangeGroupStore();
const { originatingData, changeGroupId } = storeToRefs(changeGroupStore);
const layoutStore = useWorkspaceLayoutStore();
const { workspaceLayout } = storeToRefs(layoutStore);

const fetchedProspects = ref([]);
const editingProspects = ref(false);

const canAward = computed(() => {
  if (props.investment) {
    return _.get(focalInvestment.value, "state") === "live";
  } else if (props.availability) {
    return _.get(focalAvailability.value, "state") === "live";
  } else {
    return false;
  }
});
const localProspects = computed(() => {
  if (props.investment) {
    return _.get(
      focalInvestment.value,
      "existingInvestment.prospects",
      _.get(focalInvestment.value, "prospects", []),
    );
  } else if (props.availability) {
    return _.get(
      focalAvailability.value,
      "existingAvailability.prospects",
      _.get(focalAvailability.value, "prospects", []),
    );
  } else {
    return [];
  }
});
const focalInvestment = computed(() => {
  if (matchingInvestmentGroup.value) {
    return _.find(
      matchingInvestmentGroup.value.investments,
      function (investment) {
        const localInvestmentId =
          _.get(props.investment, "existingInvestment.id") ||
          _.get(props.investment, "id") ||
          _.get(props.investment, "temporaryId");

        return _.get(investment, "existingInvestment.id") === localInvestmentId;
      },
    );
  } else {
    return props.investment;
  }
});
const focalAvailability = computed(() => {
  if (matchingAvailabilityGroup.value) {
    return _.find(
      matchingAvailabilityGroup.value.availabilities,
      function (availability) {
        const localAvailabilityId =
          _.get(props.availability, "existingAvailability.id") ||
          _.get(props.availability, "id") ||
          _.get(props.availability, "temporaryId");

        return (
          _.get(availability, "existingAvailability.id") === localAvailabilityId
        );
      },
    );
  } else if (props.availability) {
    return props.availability;
  } else {
    return null;
  }
});
const contentType = computed(() => {
  if (props.assetObject) {
    return "Investment";
  } else if (props.spaceObject || props.availability) {
    return "SpaceAvailability";
  } else {
    return "Unknown content type";
  }
});
const contentId = computed(() => {
  if (props.assetObject) {
    return investmentId.value;
  } else if (props.spaceObject || props.availability) {
    return availabilityId.value;
  } else {
    return "Unknown content id";
  }
});
const investmentId = computed(() => {
  return _.get(focalInvestment.value, "id");
});
const availabilityId = computed(() => {
  return _.get(focalAvailability.value, "id");
});
const groupId = computed(
  () =>
    _.get(props.investment, "investmentGroupId") ||
    _.get(props.investment, "portfolioId") ||
    _.get(props.availability, "availabilityGroupId") ||
    _.get(props.availability, "portfolioId"),
);
const matchingInvestmentGroup = computed(() => {
  return _.find(
    _.get(dealBuilder.value, "investmentGroups", []),
    function (group) {
      return _.find(group.investments, function (investment) {
        const localInvestmentId =
          _.get(props.investment, "existingInvestment.id") ||
          _.get(props.investment, "id") ||
          _.get(props.investment, "temporaryId");

        return _.get(investment, "existingInvestment.id") === localInvestmentId;
      });
    },
  );
});
const matchingAvailabilityGroup = computed(() => {
  return _.find(availabilityGroups.value, function (group) {
    return _.find(group.availabilities, function (availability) {
      const localAvailabilityId =
        _.get(props.availability, "existingAvailability.id") ||
        _.get(props.availability, "id") ||
        _.get(props.availability, "temporaryId");

      return (
        group.id === groupId.value ||
        _.get(availability, "existingAvailability.id") === localAvailabilityId
      );
    });
  });
});
const fetchRequestKey = computed(
  () => `prospect_company_involvements_${contentId.value}`,
);

watch(dealBuilderPatchableCompanySize, () => {
  if (
    fetchedProspects.value.length > 0 &&
    dealBuilderPatchableCompanySize.value > 0
  ) {
    const updatedProspects = fetchedProspects.value.map((field) => {
      const matchingField = _.find(dealBuilderFetchedFields.value, {
        localId: field.localId,
      });

      return matchingField || field;
    });

    fetchedProspects.value = updatedProspects;
  }
});
watch(spaceUsageBuilderPatchableCompanySize, () => {
  if (
    fetchedProspects.value.length > 0 &&
    spaceUsageBuilderPatchableCompanySize.value > 0
  ) {
    const updatedProspects = fetchedProspects.value.map((field) => {
      const matchingField = _.find(spaceUsageBuilderFetchedFields.value, {
        localId: field.localId,
      });

      return matchingField || field;
    });

    fetchedProspects.value = updatedProspects;
  }
});
watch(investmentId, (id, oldId) => {
  if (id !== oldId) {
    reset();
    fetchProspects();
  }
});
watch(availabilityId, (id, oldId) => {
  if (id !== oldId) {
    reset();
    fetchProspects();
  }
});
onMounted(() => {
  fetchProspects();
});

function reset() {
  fetchedProspects.value = [];
  editingProspects.value = false;
}
function setNewCompanies(newCompanies) {
  if (props.investment) {
    let newInvestment = _.merge({}, props.investment);

    newInvestment.prospects = newCompanies;
    updateStoreInvestment(newInvestment);
  } else if (props.availability) {
    let newAvailability = _.merge({}, props.availability);

    newAvailability.prospects = newCompanies;
    updateStoreAvailability(newAvailability);
  }
}
function removeCompany(company) {
  if (groupId.value) {
    if (props.investment) {
      const combinedKey = props.investment.temporaryId || props.investment.id;
      dealBuilderStore.removeInvestmentGroupInvestmentPlayer({
        groupId: groupId.value,
        investmentCombinedKey: combinedKey,
        toRemove: company,
        path: "prospects",
        compareBy: function (c) {
          return `${c.name}`;
        },
      });
    } else if (props.availability) {
      const combinedKey =
        props.availability?.temporaryId || props.availability?.id;
      spaceUsageBuilderStore.removeAvailabilityGroupPlayer({
        groupId: groupId.value,
        availabilityCombinedKey: combinedKey,
        toRemove: company,
        path: "prospects",
        compareBy: function (c) {
          return `${c.name}`;
        },
      });
    }
  } else if (props.investment) {
    const newCompanies = props.investment.prospects.filter(
      (c) => c.name !== company.name,
    );

    setNewCompanies(newCompanies);
  } else if (props.availability) {
    const newCompanies = props.availability.prospects.filter(
      (c) => c.name !== company.name,
    );

    setNewCompanies(newCompanies);
  }
}
function updateStoreInvestment(newInvestment) {
  if (groupId.value) {
    console.log("has existing group id");
    dealBuilderStore.updateInvestmentGroupInvestment({
      groupId: groupId.value,
      newInvestment,
    });
  } else if (matchingInvestmentGroup.value) {
    console.log("has new group id");
    dealBuilderStore.updateInvestmentGroupInvestment({
      groupId:
        matchingInvestmentGroup.value.id ||
        matchingInvestmentGroup.value.placeholderId,
      newInvestment,
    });
  } else if (props.investment?.id && props.assetObject) {
    console.log("has investment id and asset");
    dealBuilderStore.updateDealBuilderAssetInvestments({
      assetKey: decoratingAndFieldKey(props.assetObject.dataField),
      investments: [newInvestment],
    });
  } else {
    console.log("nothing to update!");
  }
}
function updateStoreAvailability(newAvailability) {
  if (groupId.value) {
    console.log("has existing group id");
    spaceUsageBuilderStore.updateAvailabilityGroupAvailability({
      groupId: groupId.value,
      newAvailability,
    });
  } else if (matchingAvailabilityGroup.value) {
    console.log("has new group id");
    spaceUsageBuilderStore.updateAvailabilityGroupAvailability({
      groupId:
        matchingAvailabilityGroup.value.id ||
        matchingAvailabilityGroup.value.placeholderId,
      newAvailability,
    });
  } else if (props.availability?.id && props.spaceObject) {
    console.log("has availability id and space");
    spaceUsageBuilderStore.updateSpaceAvailabilities({
      spaceKey: decoratingAndFieldKey(props.spaceObject.dataField),
      availabilities: [newAvailability],
    });
  } else {
    console.log("nothing to update!");
  }
}
function setCrossInteraction() {}
function cancelProspectEditing() {
  editingProspects.value = false;
}
function clearProspectEditing() {
  if (groupId.value) {
    localProspects.value.forEach((company) => {
      removeCompany(company);
    });
  } else if (props.investment) {
    let newInvestment = _.merge({}, props.investment);

    newInvestment.prospects = [];
    updateStoreInvestment(newInvestment);
  } else if (props.availability) {
    let newAvailability = _.merge({}, props.availability);

    newAvailability.prospects = [];
    updateStoreAvailability(newAvailability);
  }
  cancelProspectEditing();
}
const persistCompanies = async (proofCompanies = null) => {
  let contentType = null;
  if (props.investment) {
    contentType = "Investment";
  } else if (props.availability) {
    contentType = "SpaceAvailability";
  }
  const payload = {
    contentType,
    contentId: props.investment?.id || props.availability?.id,
    prospects: proofCompanies || localProspects.value,
    changeGroupId: changeGroupId.value,
  };

  const response = await api.post(`prospect_company_involvements`, payload);

  console.log("prospects detail persist", proofCompanies, payload, response);

  return response;
};
async function saveProspects() {
  setTimeout(() => {
    const apiRequestFunc = () => persistCompanies();
    const successCallback = async (json) => {
      clearProspectEditing();
      if (props.investment) {
        await dealBuilderStore.postEditingPatch(json, fetchRequestKey.value);
      } else if (props.availability) {
        await spaceUsageBuilderStore.postEditingPatch(
          json,
          fetchRequestKey.value,
        );
      }
      fetchProspects();
    };
    const failureCallback = () => clearProspectEditing();

    return changeGroupStore.originateData(
      apiRequestFunc,
      successCallback,
      failureCallback,
    );
  }, 125);
}
function fetchProspects(maybePayload) {
  if (props.investment?.id) {
    if (
      dealBuilderStore.alreadyFetched(fetchRequestKey.value) &&
      !maybePayload?.override
    ) {
      const alreadyFetched = dealBuilderStore.alreadyFetchedFieldsFor(
        fetchRequestKey.value,
      );

      fetchedProspects.value = alreadyFetched.filter((cdf) => {
        return cdf.fieldContentType === "CompanyInvolvement";
      });
    } else {
      api
        .get(
          `prospect_company_involvements/?content_type=Investment&content_id=${props.investment?.id}`,
        )
        .then((json) => {
          dealBuilderStore.interceptablePatch(json.data, fetchRequestKey.value);
          fetchedProspects.value = json.data;
        });
    }
  } else if (props.availability?.id) {
    if (
      spaceUsageBuilderStore.alreadyFetched(fetchRequestKey.value) &&
      !maybePayload?.override
    ) {
      const alreadyFetched = spaceUsageBuilderStore.alreadyFetchedFieldsFor(
        fetchRequestKey.value,
      );

      fetchedProspects.value = alreadyFetched.filter((cdf) => {
        return cdf.fieldContentType === "CompanyInvolvement";
      });
    } else {
      api
        .get(
          `prospect_company_involvements/?content_type=SpaceAvailability&content_id=${props.availability?.id}`,
        )
        .then((json) => {
          spaceUsageBuilderStore.interceptablePatch(
            json.data,
            fetchRequestKey.value,
          );
          fetchedProspects.value = json.data;
        });
    }
  }
}
function refetchInvestment() {
  api.get(`investments/${props.investment.id}`).then((json) => {
    updateStoreInvestment(json.data);
    emit("refetch");
  });
}
function refetchAvailability() {
  api.get(`space_availabilities/${props.availability.id}`).then((json) => {
    updateStoreAvailability(json.data);
    emit("refetch");
  });
}
</script>
