<template>
  <div class="flex items-center space-x-2">
    <button
      @click="saveState"
      type="button"
      class="group py-1 px-1.5 w-full flex items-center justify-between rounded-full border border-gray-300 shadow-sm space-x-2 text-left hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
    >
      <span class="min-w-0 flex-1 flex items-center space-x-1">
        <span class="block flex-shrink-0">
          <span
            class="inline-flex items-center justify-center h-5 w-5 rounded-full bg-gray-500"
          >
            <!-- Heroicon name: outline/link -->
            <svg
              class="h-3 w-3 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </span>
        </span>
        <span class="block min-w-0 flex-1">
          <span class="block text-sm font-medium text-gray-900 truncate"
            >Set to {{ stateLabel }}</span
          >
        </span>
      </span>
    </button>
  </div>
</template>

<script setup>
import _ from "lodash";
import { computed } from "vue";

const props = defineProps([
  "availabilityGroupId",
  "availabilityStateSnakeCaseProgressionValue",
  "allMilestones",
]);
const stateLabel = computed(() => {
  const state = _.find(props.allMilestones, {
    value: props.availabilityStateSnakeCaseProgressionValue,
  });

  return state ? state.statusLabel : "Unknown state";
});

function saveState() {
  console.log("save availability group state");
}
</script>
