<template>
  <section aria-labelledby="investment-type">
    <dt class="mb-2 text-sm font-medium text-gray-500">Investment Types</dt>
    <div v-if="dataField" role="list" class="mt-2 space-y-4">
      <ContentBoolean
        v-for="investmentType in investmentTypes"
        v-bind="investmentType"
        :key="investmentType.fieldName"
        @set-proof="setProof"
      />
    </div>
  </section>
</template>

<script setup>
import ContentBoolean from "./ContentBoolean.vue";
import { useProveForValueStore } from "@/stores/proveForValue";
import { computed } from "vue";
import _ from "lodash";

const props = defineProps(["dataField"]);

const proveForValueStore = useProveForValueStore();

const investmentTypes = computed(() => {
  return [
    {
      fieldName: "hunts_equity",
      fieldTrueLabel: "Acquires Equity Interests",
      fieldFalseLabel: "Avoids Equity",
      decoratingDataField: props.dataField,
    },
    {
      fieldName: "hunts_debt",
      fieldTrueLabel: "Provides Financing",
      fieldFalseLabel: "Avoids Financing",
      decoratingDataField: props.dataField,
    },
  ];
});

function setProof(fieldName) {
  const matchedProps = _.find(investmentTypes.value, { fieldName });

  if (matchedProps) {
    proveForValueStore.setProofComponent(ContentBoolean, matchedProps);
  }
}
</script>
