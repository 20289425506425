<template>
  <div
    class="inline-flex w-full bg-white rounded-md border-0 pl-8 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-xs/6 relative flex-1 flex-wrap items-center"
  >
    <label :for="`${inputKey}-autocomplete-input`" class="sr-only"
      >Existing Job Files</label
    >
    <div class="flex flex-wrap">
      <li
        v-for="file in displayFiles"
        :key="file.name"
        class="inline-flex rounded-full items-center my-1 mr-1 py-0.5 pl-2.5 pr-1 text-xs font-medium bg-indigo-100 text-indigo-700"
      >
        {{ file.name }}
        <button
          @click="remove(file)"
          type="button"
          class="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-indigo-400 hover:bg-indigo-200 hover:text-indigo-500 focus:outline-none focus:bg-indigo-500 focus:text-white"
        >
          <span class="sr-only">Remove file</span>
          <svg
            class="h-2 w-2"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 8 8"
          >
            <path
              stroke-linecap="round"
              stroke-width="1.5"
              d="M1 1l6 6m0-6L1 7"
            />
          </svg>
        </button>
      </li>
      <div class="">
        <Combobox v-model="selectedFile">
          <ComboboxInput
            v-observe-visibility="{ callback: focusInput, once: true }"
            :id="`${inputKey}-autocomplete-input`"
            class="text-gray-900 placeholder:text-gray-400 text-xs border-0 bg-transparent focus:ring-0"
            @keydown="handleKeyDown"
            @blur="handleBlur"
            @change="query = $event.target.value"
            placeholder="Existing file(s)"
            :displayValue="(record) => record?.name"
            :data-test="`${inputKey}-autocomplete-input`"
          />
          <ComboboxOptions
            class="absolute w-full max-h-48 bg-white scroll-py-10 scroll-pb-2 space-y-2 overflow-y-auto z-30"
          >
            <ComboboxOption
              v-for="(file, index) in filteredFiles"
              :key="file.name"
              :value="file"
              as="template"
              :data-test="`${inputKey}-autocomplete-result-${index}`"
              v-slot="{ active }"
            >
              <li
                :class="[
                  'flex text-xs cursor-default select-none items-center p-2',
                  active && 'bg-indigo-600 text-white',
                ]"
              >
                {{ file.name }}
              </li>
            </ComboboxOption>
          </ComboboxOptions>
        </Combobox>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch } from "vue";
import {
  Combobox,
  ComboboxInput,
  ComboboxOptions,
  ComboboxOption,
} from "@headlessui/vue";
import { storeToRefs } from "pinia";
import { useEasyDataInputAdminStore } from "@/stores/easyDataInputAdmin";
import _ from "lodash";

const props = defineProps([
  "inputKey",
  "focus",
  "selections",
  "removeInline",
  "inputClasses",
]);

const emit = defineEmits(["new-files", "remove"]);

const easyDataInputAdminStore = useEasyDataInputAdminStore();
const { detailedSelectedJob } = storeToRefs(easyDataInputAdminStore);

const existingFiles = computed(() => detailedSelectedJob.value.files);

const selectedFile = ref(null);
const query = ref("");
function clearInput(refocus = true) {
  setTimeout(() => {
    selectedFile.value = null;
    query.value = "";
    files.value = [];

    const inputEl = document.getElementById(
      `${props.inputKey}-autocomplete-input`,
    );
    if (inputEl) inputEl.value = "";
    if (refocus) selectInput();
  }, 50);
}

const files = ref([]);
const filteredFiles = computed(() => {
  const filtered = files.value;
  const ordered = _.orderBy(filtered, ["name"], ["asc"]);
  return _.uniqBy(ordered, "name");
});

const displayFiles = computed(() => {
  return props.selections;
});

watch(selectedFile, async () => {
  onSelect();
});

watch(query, async () => {
  if (_.trim(query.value) !== "") {
    filterUses();
  }
});

function focusInput(isVisible) {
  if (isVisible && props.focus) {
    setTimeout(() => {
      selectInput();
    }, 100);
  }
}
function selectInput() {
  document.getElementById(`${props.inputKey}-autocomplete-input`).focus();
}

function filterUses() {
  if (query.value.length > 0) {
    files.value = existingFiles.value.filter(({ name }) => {
      return name.toLowerCase().includes(query.value.toLowerCase());
    });
  } else {
    files.value = [];
  }
}

async function onSelect(refocus = true) {
  const output = selectedFile.value ? selectedFile.value : query.value;
  const selection = output === "" || output === null ? null : output;

  if (selection) {
    let placeholder = {
      name: null,
      id: null,
    };
    placeholder.name = selection.name;
    placeholder.id = selection.signedId;

    emit("new-files", _.unionBy(props.selections, [placeholder], "name"));
  }
  clearInput(refocus);
}

function handleBlur() {
  setTimeout(() => {
    if (filteredFiles.value.length === 0) {
      const input = query.value;
      const typedVal = input === "" || input === null ? null : input;

      if (typedVal) {
        onSelect();
      } else {
        clearInput(false);
      }
    }
  }, 75);
}
function handleKeyDown(e) {
  const lowerQuery =
    query.value === "" || query.value === null ? null : _.toLower(query.value);

  if (lowerQuery && ["Tab", ","].includes(e.key)) {
    onSelect(false);
  } else if (
    lowerQuery &&
    filteredFiles.value.length === 0 &&
    ["Enter"].includes(e.key)
  ) {
    onSelect();
  } else if (
    !lowerQuery &&
    displayFiles.value.length > 0 &&
    ["Backspace"].includes(e.key) &&
    props.removeInline
  ) {
    remove(_.last(displayFiles.value));
  }
}

function remove(record) {
  emit("remove", record);
  setTimeout(() => {
    selectInput();
  }, 50);
}
</script>
