<template>
  <div class="rounded-md border border-gray-300 p-2">
    <div
      v-observe-visibility="{ callback: populateForm, once: true }"
      class="text-sm font-semibold text-gray-600"
    >
      New Cashflow Schedule ({{ selectedCashflowType?.name }})
    </div>

    <CashflowTypeChooser class="mt-2" />

    <SteppingCashflowCreate
      v-if="scheduleDisplayable && selectedCashflowType?.value === 'stepping'"
      :data-field="dataField"
      :date-fields="dateFields"
      :per-area-size="perAreaSize"
      @refetch="emit('refetch')"
      @cancel="emit('cancel')"
    />

    <TenantImprovementCashflowCreate
      v-else-if="
        scheduleDisplayable &&
        selectedCashflowType?.value === 'tenantImprovementAllowance'
      "
      :data-field="dataField"
      :date-fields="dateFields"
      :per-area-size="perAreaSize"
      @refetch="emit('refetch')"
      @cancel="emit('cancel')"
    />
  </div>
</template>

<script setup>
import CashflowTypeChooser from "@/components/crowdsourcing/CashflowTypeChooser.vue";
import SteppingCashflowCreate from "@/components/crowdsourcing/SteppingCashflowCreate.vue";
import TenantImprovementCashflowCreate from "@/components/crowdsourcing/TenantImprovementCashflowCreate.vue";
import { useCashflowDefinitionStore } from "@/stores/cashflowDefinition";
import { storeToRefs } from "pinia";
import { nextTick } from "vue";

defineProps(["dataField", "dateFields", "perAreaSize"]);
const emit = defineEmits(["refetch", "cancel"]);

const cashflowDefinitionStore = useCashflowDefinitionStore();
const { scheduleDisplayable, selectedCashflowType } = storeToRefs(
  cashflowDefinitionStore,
);

async function populateForm(isVisible) {
  if (isVisible) {
    cashflowDefinitionStore.reset();

    await nextTick();

    const firstRadioOptionEl = document.querySelectorAll('[role="radio"]')?.[0];

    if (firstRadioOptionEl?.getAttribute("name") === "stepping") {
      firstRadioOptionEl?.click();
    }
  }
}
</script>
