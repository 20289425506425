<template>
  <VDropdown placement="bottom-end">
    <DataVisibilityButton visibility="safezone" class="inline-flex">
      <template v-slot:button>
        <button
          type="button"
          data-test="file-storage-host-dropdown-button"
          class="inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-sm font-medium text-white bg-yellow-500 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-400"
          id="menu-button"
          aria-expanded="true"
          aria-haspopup="true"
        >
          <!-- Heroicon name: solid/plus -->
          <svg
            class="-ml-1 mr-2 h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
              clip-rule="evenodd"
            />
          </svg>
          <span>Add files...</span>
          <!-- Heroicon name: solid/chevron-down -->
          <svg
            class="-mr-1 ml-2 h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clip-rule="evenodd"
            />
          </svg>
        </button>
      </template>
    </DataVisibilityButton>

    <template #popper>
      <div
        class="w-72 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="menu-button"
        tabindex="-1"
      >
        <div class="py-1" role="none">
          <a
            v-close-popper
            @click.prevent="emailVerifyIntercept(dropboxChooser)"
            href=""
            class="text-gray-700 hover:bg-gray-100 hover:text-gray-900 group flex items-center px-4 py-2 text-sm"
            role="menuitem"
            tabindex="-1"
            id="menu-item-1"
          >
            Choose from Dropbox
          </a>
          <a
            v-if="googleAuthLoaded"
            v-close-popper
            @click.prevent="emailVerifyIntercept(googleDriveChooser)"
            href=""
            class="text-gray-700 hover:bg-gray-100 hover:text-gray-900 group flex items-center px-4 py-2 text-sm"
            role="menuitem"
            tabindex="-1"
            id="menu-item-1"
          >
            Choose from Google Drive
          </a>
          <a
            v-close-popper
            @click.prevent="emailVerifyIntercept(oneDriveSharePointChooser)"
            href=""
            class="text-gray-700 hover:bg-gray-100 hover:text-gray-900 group flex items-center px-4 py-2 text-sm"
            role="menuitem"
            tabindex="-1"
            id="menu-item-1"
          >
            Choose from OneDrive and SharePoint
          </a>
          <a
            v-close-popper
            @click.prevent="emailVerifyIntercept(localUpload)"
            href=""
            class="text-gray-700 hover:bg-gray-100 hover:text-gray-900 group flex items-center px-4 py-2 text-sm"
            role="menuitem"
            tabindex="-1"
            id="menu-item-0"
            data-test="file-storage-local-upload"
          >
            Upload from your computer
          </a>
          <VDropdown>
            <a
              @click.prevent
              href=""
              class="text-gray-700 hover:bg-gray-100 hover:text-gray-900 group flex items-center px-4 py-2 text-sm"
              role="menuitem"
              tabindex="-1"
              id="menu-item-0"
            >
              Paste a link
            </a>
            <template #popper>
              <div class="w-80">
                <fieldset class="p-2 space-y-2 w-full max-h-64 overflow-y-auto">
                  <div>
                    <label
                      for="url"
                      class="block text-xs font-medium text-gray-700"
                      >Website</label
                    >
                    <div class="relative rounded-md shadow-sm mt-1">
                      <div
                        class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
                      >
                        <LinkIcon
                          class="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </div>
                      <input
                        v-observe-visibility="{ callback: focusUrlInput }"
                        v-model="url"
                        type="url"
                        name="url"
                        id="url"
                        class="block w-full rounded-md border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 text-xs"
                        placeholder="https://www.example.com/some-page"
                        data-test="citation-url"
                      />
                    </div>
                  </div>
                  <FileDisplay
                    v-if="displayUrl"
                    :file="{ link: displayUrl }"
                    class="h-48 w-full"
                  />
                </fieldset>
                <div
                  class="bg-gray-50 p-2 flex justify-end items-center space-x-2"
                >
                  <button
                    @click="url = null"
                    type="button"
                    class="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    Clear
                  </button>
                  <button
                    @click="emailVerifyIntercept(pasteUrl)"
                    v-close-popper
                    type="button"
                    :disabled="emptyUrl"
                    :class="emptyUrl ? 'cursor-not-allowed' : ''"
                    class="inline-flex items-center rounded border border-transparent bg-yellow-500 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-yellow-400 focus:ring-offset-2"
                    data-test="save-new-citation-button"
                  >
                    Save
                  </button>
                </div>
              </div>
            </template>
          </VDropdown>
        </div>
      </div>
    </template>
  </VDropdown>
</template>

<script setup>
import { LinkIcon } from "@heroicons/vue/20/solid";
import { ref, computed, watch } from "vue";
import { cleanUrl } from "@/assets/cleanUrl";
import iframelyApi from "@/router/iframelyApi";
import { useUserStore } from "@/stores/user";
import { storeToRefs } from "pinia";
import FileDisplay from "@/components/crowdsourcing/FileDisplay.vue";
import _ from "lodash";
import DataVisibilityButton from "@/components/crowdsourcing/DataVisibilityButton.vue";

const iframely = window.iframely;
defineProps(["googleAuthLoaded"]);
const emit = defineEmits([
  "local-upload",
  "dropbox-chooser",
  "google-drive-chooser",
  "one-drive-share-point-chooser",
  "paste-url",
]);

const userStore = useUserStore();
const { emailUnverified } = storeToRefs(userStore);
const urlName = ref("");
const url = ref(null);
const displayUrl = ref(null);
const emptyUrl = computed(() => {
  return _.isNull(url.value) || _.trim(url.value) === "";
});

function emailVerifyIntercept(callback) {
  if (emailUnverified.value) {
    userStore.promptToVerify();
  } else {
    callback();
  }
}

function localUpload() {
  emit("local-upload");
}
function dropboxChooser() {
  emit("dropbox-chooser");
}
function googleDriveChooser() {
  emit("google-drive-chooser");
}
function oneDriveSharePointChooser() {
  emit("one-drive-share-point-chooser");
}
function pasteUrl() {
  emit("paste-url", { url: url.value, name: urlName.value });
  url.value = null;
}
function focusUrlInput(isVisible) {
  if (isVisible) {
    setTimeout(() => {
      selectInput();
    }, 100);
  }
}
function selectInput() {
  document.getElementById(`url`).focus();
}

const parseUrl = ref(true);
const debouncedParseUrl = _.debounce(function () {
  if (!emptyUrl.value && iframely && parseUrl.value) {
    setTimeout(() => {
      iframely.load();
      iframelyApi(cleanUrl(url.value)).then((data) => {
        const canonicalUrl = _.get(data, "meta.canonical");
        const urlSite = _.get(data, "meta.site");

        if (canonicalUrl) {
          parseUrl.value = false;
          url.value = canonicalUrl;
          setTimeout(() => {
            parseUrl.value = true;
            displayUrl.value = url.value;
          }, 250);
        } else {
          displayUrl.value = url.value;
        }
        if (urlSite) urlName.value = urlSite;
        else urlName.value = url.value;
      });
    }, 500);

    iframely.on("cancel", function (url) {
      // console.log("iframely couldn't parse:", url);
    });
  }
}, 500);

watch(emptyUrl, () => {
  if (emptyUrl.value) {
    urlName.value = "";
    displayUrl.value = null;
  }
});

watch(url, () => debouncedParseUrl());
</script>

<style>
.v-popper__inner > div {
  overflow: hidden !important;
}
</style>
