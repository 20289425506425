<template>
  <section aria-labelledby="investment-type">
    <dt class="mb-2 text-sm font-medium text-gray-500">Investment Size</dt>
    <div
      class="grid gap-2"
      :class="workspaceLayout === 'sideBySide' ? 'grid-cols-1' : 'grid-cols-2'"
    >
      <div class="">
        <label class="block text-sm font-medium leading-6 text-gray-500"
          >Min $</label
        >
        <div class="mt-1">
          <EditableDataField
            v-bind="
              _.find(availableProps, {
                fieldName: 'min_investment_size',
              })
            "
            @set-proof="setProof"
          />
        </div>
      </div>
      <div class="">
        <label class="block text-sm font-medium leading-6 text-gray-500"
          >Max $</label
        >
        <div class="mt-1">
          <EditableDataField
            v-bind="
              _.find(availableProps, {
                fieldName: 'max_investment_size',
              })
            "
            @set-proof="setProof"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import EditableDataField from "@/components/crowdsourcing/EditableDataField.vue";
import { useProveForValueStore } from "@/stores/proveForValue";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import _ from "lodash";

const props = defineProps(["dataField"]);

const layoutStore = useWorkspaceLayoutStore();
const { workspaceLayout } = storeToRefs(layoutStore);
const proveForValueStore = useProveForValueStore();

const availableProps = computed(() => {
  return [
    {
      fieldName: "min_investment_size",
      textClasses: "text-sm",
      inputClasses: "w-full",
      decoratingContentDataField: props.dataField,
      isCurrency: true,
      optional: true,
      dismissOnSave: true,
    },
    {
      fieldName: "max_investment_size",
      textClasses: "text-sm",
      inputClasses: "w-full",
      decoratingContentDataField: props.dataField,
      isCurrency: true,
      optional: true,
      dismissOnSave: true,
    },
  ];
});

function setProof(fieldName) {
  const matchedProps = _.find(availableProps.value, { fieldName });

  if (matchedProps) {
    proveForValueStore.setProofComponent(EditableDataField, matchedProps);
  }
}
</script>
