<template>
  <section aria-labelledby="hunt-locations">
    <ContentHeader>
      <template v-slot:title>Target Markets</template>
      <template v-slot:description
        >Specify the potential locations for this hunt.</template
      >
    </ContentHeader>

    <div class="p-2">
      <template v-if="polygonsLoaded && coloredPolygons.length > 0">
        <div class="">
          <h3 class="leading-5 font-medium text-gray-900">Map Preview</h3>
          <p class="mt-1 text-sm text-gray-500">
            View all of the target markets at once.
          </p>
        </div>
        <ParcelMap
          v-if="mappablePolygons.length > 0"
          :map-id="`hunt-${hunt.id}-combined-parcels`"
          :polygons="mappablePolygons"
          class="my-3 h-56 w-full"
        />
        <section v-else class="my-3">
          <div
            class="relative block w-full rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <LockClosedIcon class="mx-auto h-8 w-8 text-gray-400" />
            <span class="mt-2 block text-sm font-medium text-gray-900">
              Unlock markets to view map
            </span>
          </div>
        </section>
      </template>
      <div class="">
        <h3 class="leading-5 font-medium text-gray-900">Markets List</h3>
        <p class="mt-1 text-sm text-gray-500">
          Define new market locations and location-specific contacts.
        </p>
      </div>
      <div
        class="mt-3 bg-white overflow-hidden rounded-md border border-gray-300 divide-y divide-gray-200"
      >
        <div class="flex items-center justify-between p-2 bg-gray-100">
          <div
            class="text-gray-700 text-xs font-semibold uppercase tracking-wide"
          >
            Markets
          </div>
          <div class="flex items-center space-x-2">
            <VDropdown placement="left" @hide="cancel">
              <DataVisibilityButton visibility="safezone" class="inline-flex">
                <template v-slot:button>
                  <button
                    type="button"
                    class="inline-flex items-center space-x-1 px-2 py-1 border border-transparent rounded shadow-sm bg-yellow-500 hover:bg-yellow-600 focus:ring-yellow-600 text-white focus:outline-none focus:ring-2 focus:ring-offset-2"
                  >
                    <PulseLoader
                      v-if="originatingData"
                      :loading="true"
                      size="3px"
                      color="#f3f4f6"
                    />
                    <template v-else>
                      <PlusIcon class="h-3 w-3" />
                      <span class="text-xs">Add</span>
                    </template>
                  </button>
                </template>
              </DataVisibilityButton>

              <template #popper>
                <div class="p-2 flex flex-col space-y-1 w-48">
                  <div class="text-xs font-medium text-gray-500">Name</div>
                  <input
                    v-observe-visibility="{ callback: focusNameInput }"
                    v-model="newLocationName"
                    type="text"
                    name="new-hunt-location-name"
                    id="new-hunt-location-name"
                    class="shadow-sm focus:ring-yellow-500 focus:border-yellow-500 block w-full text-xs border-gray-300 rounded-md"
                    :data-test="`new-hunt-location-name-input`"
                  />
                  <div class="flex items-center justify-end space-x-2">
                    <button
                      @click="cancel"
                      type="button"
                      class="inline-flex items-center p-1 border border-gray-300 rounded-full shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
                    >
                      <XMarkIcon class="h-4 w-4" />
                    </button>

                    <DataVisibilityButton
                      v-if="complete"
                      visibility="safezone"
                      tooltip="Save"
                      class="inline-flex"
                    >
                      <template v-slot:button>
                        <button
                          @click="createLocation"
                          :disabled="originatingData"
                          type="button"
                          class="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-yellow-500 hover:bg-yellow-600 focus:ring-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2"
                          :data-test="`new-hunt-location-save`"
                        >
                          <PulseLoader
                            v-if="originatingData"
                            :loading="true"
                            size="3px"
                            color="#f3f4f6"
                          />
                          <CheckIcon v-else class="h-4 w-4" />
                        </button>
                      </template>
                    </DataVisibilityButton>
                  </div>
                </div>
              </template>
            </VDropdown>
          </div>
        </div>
        <HuntLocation
          v-for="(locationField, index) in locations"
          :key="index"
          :hunt="hunt"
          :location-field="locationField"
          :location-color="marketColor(locationField)"
          :decorating-content-data-field="dataField"
          @refetch="refetch(true)"
        />
      </div>
    </div>
  </section>
</template>

<script setup>
import ContentHeader from "@/components/main-layout/secondary-panel/ContentHeader.vue";
import DataVisibilityButton from "@/components/crowdsourcing/DataVisibilityButton.vue";
import HuntLocation from "@/components/crowdsourcing/HuntLocation.vue";
import ParcelMap from "@/components/maps/ParcelMap.vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { useCompanyDetailStore } from "@/stores/companyDetail";
import { useCrowdsourcedChangeGroupStore } from "@/stores/crowdsourcedChangeGroup";
import { useTimeTravelStore } from "@/stores/timeTravel";
import api from "@/router/api";
import _ from "lodash";
import { storeToRefs } from "pinia";
import { computed, onMounted, ref } from "vue";
import {
  PlusIcon,
  XMarkIcon,
  CheckIcon,
  LockClosedIcon,
} from "@heroicons/vue/20/solid";

const props = defineProps(["dataField"]);

const changeGroupStore = useCrowdsourcedChangeGroupStore();
const { originatingData, changeGroupId } = storeToRefs(changeGroupStore);
const companyDetailStore = useCompanyDetailStore();
const { companyFetchedPolygonDataFields, companyMapFitted } =
  storeToRefs(companyDetailStore);
const timeTravelStore = useTimeTravelStore();

const hunt = computed(() => props.dataField.fieldContent);
const polygons = ref([]);
const coloredPolygons = ref([]);
const mappablePolygons = computed(() =>
  coloredPolygons.value.filter(
    (dataField) => !!dataField.fieldContent?.coordinates,
  ),
);
const polygonsLoaded = ref(false);
const locationsLoaded = ref(false);
const locations = ref([]);
const newLocationName = ref("");
const complete = computed(() => _.trim(newLocationName.value) !== "");
const focalContentType = computed(() => {
  if (
    props.dataField.decoratingContentType === "Hunt" &&
    props.dataField.fieldContentType === "CompanyInvolvement"
  ) {
    return props.dataField.decoratingContentType;
  } else {
    return props.dataField.fieldContentType;
  }
});
const focalContentId = computed(() => {
  if (
    props.dataField.decoratingContentType === "Hunt" &&
    props.dataField.fieldContentType === "CompanyInvolvement"
  ) {
    return props.dataField.decoratingContentId;
  } else {
    return props.dataField.fieldContentId;
  }
});

onMounted(() => {
  refetch();
});

async function refetch(includeUpstream = false) {
  await fetchLocations();
  await fetchCombinedPolygons();

  if (includeUpstream) {
    companyFetchedPolygonDataFields.value = [];
    companyMapFitted.value = false;
    companyDetailStore.refetchDetails();
    timeTravelStore.triggerRefetch();
  }
}

function focusNameInput(isVisible) {
  if (isVisible) {
    setTimeout(() => {
      focusNameInputElement();
    }, 100);
  }
}
function focusNameInputElement() {
  document.getElementById("new-hunt-location-name").focus();
}

async function fetchCombinedPolygons() {
  polygonsLoaded.value = false;
  const response = await api.get(
    `crowdsourced_data_fields/${focalContentType.value}/${focalContentId.value}?field_name=GeographyRegion&is_joining=true`,
  );

  if (response?.data) {
    polygons.value = response.data;
    coloredPolygons.value = polygons.value.map((dataField) => {
      return _.merge({}, dataField, { color: polygonColor(dataField) });
    });
    polygonsLoaded.value = true;
  }
}

async function fetchLocations() {
  locationsLoaded.value = false;
  const response = await api.get(
    `crowdsourced_data_fields/${focalContentType.value}/${focalContentId.value}?field_name=HuntGeographyIntent`,
  );
  if (response?.data) {
    locations.value = response.data;
    locationsLoaded.value = true;
  }
}

function patchLocation(dataField) {
  const newLocations = _.unionBy([dataField], locations.value, "localId");

  locations.value = newLocations;
}

function cancel() {
  newLocationName.value = "";
}

function persist() {
  const payload = {
    huntId: focalContentId.value,
    name: newLocationName.value,
  };
  return api.post(
    `hunt_geography_intents`,
    _.merge({}, payload, { changeGroupId: changeGroupId.value }),
  );
}
function afterPersist(json) {
  patchLocation(json.data.dataField);
  cancel();
  focusNameInputElement();
}
async function createLocation() {
  if (complete.value) {
    const apiRequestFunc = () => persist();
    const successCallback = (json) => afterPersist(json);
    const failureCallback = () => cancel();
    await changeGroupStore.originateData(
      apiRequestFunc,
      successCallback,
      failureCallback,
    );
  }
}

const marketPolygonColors = [
  "#c084fc", // purple-400
  "#fb7185", // rose-400
  "#60a5fa", // blue-400
  "#2dd4bf", // teal-400
  "#a3e635", // lime-400
  "#fb923c", // orange-400
];

function marketColor(locationField) {
  if (!locationsLoaded.value) return;

  const colorIndex = _.findIndex(locations.value, {
    fieldContentId: locationField.fieldContentId,
  });
  const modIndex = colorIndex % marketPolygonColors.length;
  return marketPolygonColors[modIndex];
}
function polygonColor(polygonField) {
  if (!locationsLoaded.value) return;

  const colorIndex = _.findIndex(locations.value, {
    fieldContentId: polygonField.decoratingContentId,
  });
  const modIndex = colorIndex % marketPolygonColors.length;
  return marketPolygonColors[modIndex];
}
</script>
