<template>
  <section class="h-full w-full flex flex-col overflow-hidden">
    <ContentHeader :no-margin="true">
      <template v-slot:title>Layer</template>
      <template v-slot:description
        >Apply styles and colors to map data.</template
      >
    </ContentHeader>

    <section class="flex-grow overflow-y-auto p-2">
      <RadioGroup v-model="selectedLayer">
        <RadioGroupLabel class="text-base font-semibold leading-6 text-gray-900"
          >Select a layer</RadioGroupLabel
        >

        <div
          class="mt-4 grid gap-y-6 gap-x-4"
          :class="
            workspaceLayout === 'sideBySide'
              ? 'grid-cols-2'
              : 'grid-cols-4 grid-flow-row-dense '
          "
        >
          <RadioGroupOption
            as="template"
            v-for="layer in layers"
            :key="layer.id"
            :value="layer"
            v-slot="{ active, checked }"
          >
            <div
              :class="[
                active
                  ? 'border-indigo-600 ring-2 ring-indigo-600'
                  : 'border-gray-300',
                'relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none',
              ]"
            >
              <span class="flex flex-1">
                <span class="flex flex-col">
                  <RadioGroupLabel
                    as="span"
                    class="block text-sm font-medium text-gray-900"
                    >{{ layer.title }}</RadioGroupLabel
                  >
                  <RadioGroupDescription
                    as="span"
                    class="mt-1 flex items-center text-sm text-gray-500"
                    >{{ layer.description }}</RadioGroupDescription
                  >
                  <RadioGroupDescription as="div" class="mt-4"
                    ><component v-if="layer.component" :is="layer.component" />
                  </RadioGroupDescription>
                </span>
              </span>
              <CheckCircleIcon
                :class="[
                  !checked ? 'invisible' : '',
                  'h-5 w-5 text-indigo-600',
                ]"
                aria-hidden="true"
              />
              <span
                :class="[
                  active ? 'border' : 'border-2',
                  checked ? 'border-indigo-600' : 'border-transparent',
                  'pointer-events-none absolute -inset-px rounded-lg',
                ]"
                aria-hidden="true"
              />
            </div>
          </RadioGroupOption>
        </div>
      </RadioGroup>
    </section>
  </section>
</template>

<script setup>
import ContentHeader from "@/components/main-layout/secondary-panel/ContentHeader.vue";
import { useAnalyzePanelStore } from "@/stores/analyzePanel";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { storeToRefs } from "pinia";
import {
  RadioGroup,
  RadioGroupDescription,
  RadioGroupLabel,
  RadioGroupOption,
} from "@headlessui/vue";
import { CheckCircleIcon } from "@heroicons/vue/20/solid";

const layoutStore = useWorkspaceLayoutStore();
const { workspaceLayout } = storeToRefs(layoutStore);
const analyzePanelStore = useAnalyzePanelStore();
const { selectedLayer, layers } = storeToRefs(analyzePanelStore);
</script>
