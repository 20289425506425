<template>
  <div class="h-full flex flex-col">
    <div class="p-2 bg-gray-50 flex flex-grow flex-col gap-3 overflow-y-auto">
      <div class="flex items-center justify-between">
        <div class="flex flex-grow items-center">
          <div class="w-full">
            <label for="task-search-input" class="sr-only">Search</label>
            <div class="relative">
              <div
                class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
              >
                <MagnifyingGlassIcon
                  class="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
              <input
                v-model="rawSearchQuery"
                v-observe-visibility="{ callback: focusInput, once: true }"
                id="task-search-input"
                name="search"
                class="block w-full rounded-md border border-gray-300 bg-white py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:border-indigo-500 focus:text-gray-900 focus:placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
                placeholder="Search"
                type="search"
              />
            </div>
          </div>
        </div>
        <div v-if="isDesktop" class="ml-2 flex flex-shrink-0">
          <button
            @click.prevent="newReminder"
            type="button"
            class="relative inline-flex items-center rounded-md border border-gray-300 bg-white p-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            <PlusCircleIcon
              class="-ml-1 mr-1.5 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            <span>New Task</span>
          </button>
        </div>
        <div class="ml-2 flex flex-shrink-0">
          <router-link
            :to="helpCenterPath"
            target="_blank"
            v-tooltip="'Get help'"
            class="relative inline-flex items-center rounded-md border border-gray-300 bg-white p-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            <QuestionMarkCircleIcon class="h-5 w-5 text-gray-400" />
          </router-link>
        </div>
      </div>
      <TaskShortcuts />
      <TaskLists />
    </div>
    <div
      v-if="!isDesktop"
      class="p-2 bg-gray-50 flex items-center justify-between"
    >
      <a
        @click.prevent="newReminder"
        href=""
        class="flex items-center text-sm font-medium text-indigo-500"
      >
        <PlusCircleIcon
          class="mr-1.5 h-5 w-5 flex-shrink-0 text-indigo-400"
          aria-hidden="true"
        />
        New Task
      </a>
      <a
        @click.prevent="newList"
        href=""
        class="text-sm text-indigo-500"
        data-test="create-task-list-button"
      >
        Add List
      </a>
    </div>
  </div>
</template>

<script setup>
import { QuestionMarkCircleIcon } from "@heroicons/vue/20/solid";
import { MagnifyingGlassIcon } from "@heroicons/vue/20/solid";
import { PlusCircleIcon } from "@heroicons/vue/24/solid";
import TaskLists from "@/components/tasks/TaskLists.vue";
import TaskShortcuts from "@/components/tasks/TaskShortcuts.vue";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { useTasksStore } from "@/stores/tasks";
import { storeToRefs } from "pinia";
import { computed } from "vue";

const tasksStore = useTasksStore();
const { rawSearchQuery, addingTask, addingList } = storeToRefs(tasksStore);
const layoutStore = useWorkspaceLayoutStore();
const { isDesktop } = storeToRefs(layoutStore);

const helpCenterPath = computed(() => {
  return {
    name: "HelpArticle",
    params: { articleId: "stay-organized-with-tasks" },
  };
});

function newReminder() {
  addingTask.value = true;
}
function newList() {
  addingList.value = true;
}

function focusInput(isVisible) {
  if (isVisible && isDesktop.value && false) {
    setTimeout(() => {
      selectInput();
    }, 100);
  }
}

function selectInput() {
  document.getElementById(`task-search-input`).focus();
}
</script>
