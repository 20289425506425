<template>
  <VMenu no-auto-focus theme="quick-menu" placement="bottom-end">
    <slot name="button"></slot>
    <template #popper>
      <div class="w-52">
        <div
          v-if="tooltip || helpArticle"
          class="flex items-center justify-between space-x-1 p-2 text-sm text-gray-800 font-bold"
        >
          <div v-if="tooltip" class="text-sm text-gray-800 font-bold">
            {{ tooltip }}
          </div>
          <button
            v-if="helpArticle"
            @click="getHelp"
            type="button"
            v-tooltip="'Get help'"
          >
            <QuestionMarkCircleIcon class="h-4 w-4 text-gray-700" />
          </button>
        </div>
        <div v-if="visibility !== 'none'" class="bg-gray-100 p-2 space-y-1">
          <div class="flex items-center space-x-2">
            <LockClosedIcon
              v-if="visibility === 'safezone'"
              :class="visibilityColor"
              class="flex-shrink-0 h-4 w-4"
            />
            <EyeSlashIcon
              v-else-if="visibility === 'calculation'"
              :class="visibilityColor"
              class="flex-shrink-0 h-4 w-4"
            />
            <EyeIcon
              v-else
              :class="visibilityColor"
              class="flex-shrink-0 h-4 w-4"
            />
            <h1 class="text-xs text-gray-600 font-medium">Visibility:</h1>
          </div>
          <div class="text-xs text-gray-600">{{ visibilityLabel }}</div>
        </div>
      </div>
    </template>
  </VMenu>
</template>

<script setup>
import { useDocumentationStore } from "@/stores/documentation";
import {
  EyeIcon,
  EyeSlashIcon,
  LockClosedIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/vue/20/solid";
import { computed } from "vue";

const props = defineProps(["tooltip", "visibility", "helpArticle"]);
const documentationStore = useDocumentationStore();

const visibilityColor = computed(() => {
  if (props.visibility === "calculation") {
    return "text-teal-600";
  } else if (props.visibility === "safezone") {
    return "text-yellow-600";
  } else {
    return "text-indigo-600";
  }
});
const visibilityLabel = computed(() => {
  if (props.visibility === "calculation") {
    return "Calculation, inputs drawn from data you have access to";
  } else if (props.visibility === "safezone") {
    return "Safezone, only accessible by you and people you share with";
  } else {
    return "Open source data, accessible to others (subject to validation)";
  }
});

function getHelp() {
  if (props.helpArticle) {
    documentationStore.viewArticle(props.helpArticle);
  }
}
</script>
