<template>
  <li v-if="note">
    <div class="w-full flex space-x-3">
      <div v-if="note.user" class="flex-shrink-0">
        <ContactDataField :user="note.user" />
      </div>
      <div class="flex-grow">
        <div class="text-sm">
          <a @click.prevent href="" class="font-medium text-gray-700">{{
            note.user?.name || "Author"
          }}</a>
        </div>
        <div class="mt-1">
          <div v-if="editing" class="flex flex-col space-y-1">
            <div class="text-sm font-medium text-gray-500">Note</div>
            <textarea
              v-observe-visibility="{ callback: initialFocus, once: true }"
              v-model="localNote"
              id="content-note-editing-text-area"
              name="comment"
              style="height: 4rem"
              class="shadow-sm w-full focus:ring-indigo-500 focus:border-indigo-500 text-sm border border-gray-300 rounded-md"
              placeholder="Add a note"
              data-test="content-note-editing-text-area"
            ></textarea>
            <div class="flex items-center justify-end space-x-2">
              <button
                @click="cancel"
                type="button"
                class="inline-flex items-center p-1 border border-gray-300 rounded-full shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                id="content-note-cancel-button"
              >
                <XMarkIcon class="h-4 w-4" />
              </button>

              <DataVisibilityButton
                v-if="complete"
                visibility="safezone"
                tooltip="Save"
                class="inline-flex"
              >
                <template v-slot:button>
                  <button
                    @click="save"
                    :disabled="originatingData"
                    type="button"
                    class="inline-flex items-center p-1 border border-transparent bg-yellow-500 hover:bg-yellow-600 focus:ring-yellow-600 rounded-full shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2"
                    data-test="content-note-save"
                  >
                    <PulseLoader
                      v-if="originatingData"
                      :loading="true"
                      size="3px"
                      color="#f3f4f6"
                    />
                    <CheckIcon v-else class="h-4 w-4" />
                  </button>
                </template>
              </DataVisibilityButton>
            </div>
          </div>
          <DataField
            v-else-if="note"
            :data-field="noteDataField"
            primary-text-path="fieldContent.noteText"
            text-classes="text-sm"
            :always-private="true"
            :layout-store="layoutStore"
            :change-group-store="changeGroupStore"
            :user-store="userStore"
            :unlocker-store="unlockerStore"
            @dismiss="dismiss"
            @suggest-change="suggestChange"
            @unlocked="unlocked"
            @completed="completed"
          />
        </div>
        <div v-if="note.user" class="mt-2 flex flex-col space-y-1">
          <span
            v-if="displayContext"
            class="text-xs text-gray-500 font-medium"
            >{{ noteDataField.fieldContent.contextSummary }}</span
          >
          <span class="flex-shrink-0 text-xs text-gray-400">
            <template v-if="isRecent">{{
              moment(note.updatedAt).fromNow()
            }}</template>
            <template v-else>{{
              moment(note.updatedAt).format("MMM D, YYYY")
            }}</template>
          </span>
        </div>
      </div>
    </div>
  </li>
</template>

<script setup>
import ContactDataField from "@/components/crowdsourcing/ContactDataField.vue";
import { XMarkIcon, CheckIcon } from "@heroicons/vue/20/solid";
import DataField from "@/components/crowdsourcing/DataField.vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { useCrowdsourcedChangeGroupStore } from "@/stores/crowdsourcedChangeGroup";
import DataVisibilityButton from "@/components/crowdsourcing/DataVisibilityButton.vue";
import { ref, computed } from "vue";
import { storeToRefs } from "pinia";
import _ from "lodash";
import api from "@/router/api";
import moment from "moment";
import VueScrollTo from "vue-scrollto";

const props = defineProps([
  "noteDataField",
  "contentDataField",
  "layoutStore",
  "changeGroupStore",
  "userStore",
  "unlockerStore",
  "displayContext",
]);
const emit = defineEmits(["unlocked", "completed", "update-note"]);

const changeGroupStore =
  props.changeGroupStore || useCrowdsourcedChangeGroupStore();
const { originatingData, changeGroupId } = storeToRefs(changeGroupStore);
const editing = ref(false);
const dismissOnSaveId = ref(null);
const localNote = ref("");

const note = computed(() => {
  return _.get(props.noteDataField, "fieldContent");
});
const complete = computed(() => {
  return (
    _.trim(localNote.value) !== "" &&
    _.trim(localNote.value) !== _.trim(note.value?.noteText)
  );
});
const isRecent = computed(() => {
  return moment(note.value.updatedAt).add(1, "month").isAfter();
});
async function suggestChange() {
  setTimeout(() => {
    dismissOnSaveId.value = props.noteDataField.localId;
    localNote.value = note.value?.noteText;
    editing.value = true;
  }, 250);
}
function completed() {
  emit("completed");
}
function unlocked() {
  emit("unlocked");
  emit("completed");
}

async function save() {
  if (complete.value) {
    await dismiss(dismissOnSaveId.value);

    const apiRequestFunc = () => persist();
    const successCallback = (json) => afterPersist(json);
    const failureCallback = () => cancel();
    await changeGroupStore.originateData(
      apiRequestFunc,
      successCallback,
      failureCallback
    );
  }
}
async function dismiss(id, successCallback = () => {}) {
  if (!id) return;

  await changeGroupStore.dismissData({
    dataFieldId: id,
    successCallback: successCallback,
  });
}
function persist() {
  const { decoratingContentType, decoratingContentId } = props.noteDataField;
  const payload = {
    text: localNote.value,
    standaloneFieldName: _.get(props.contentDataField, "fieldName", null),
  };
  return api.post(
    `content_notes/${decoratingContentType}/${decoratingContentId}`,
    _.merge({}, payload, { changeGroupId: changeGroupId.value })
  );
}
function afterPersist(json) {
  emit("update-note", {
    newNoteField: json.data?.dataField,
    dismissedFieldId: dismissOnSaveId.value,
  });
  cancel();
  // $store.dispatch("flash", "Saved!");
}
function cancel() {
  localNote.value = "";
  dismissOnSaveId.value = null;
  editing.value = false;
}

function initialFocus(isVisible) {
  if (isVisible) {
    setTimeout(() => {
      selectInput();
      scrollToNoteBottom();
    }, 100);
  }
}
function selectInput() {
  document.getElementById("content-note-editing-text-area")?.focus();
}
function scrollToNoteBottom() {
  setTimeout(() => {
    VueScrollTo.scrollTo("#content-note-editing-text-area", 100, {
      container: "#content-notes-container",
    });
  }, 100);
}
</script>
