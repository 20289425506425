<template>
  <div>
    <div class="block hover:bg-gray-50">
      <div class="flex items-start p-2">
        <div class="flex min-w-0 flex-1 items-start">
          <div
            class="flex-shrink-0 flex items-center justify-center h-6 w-6 rounded-full border border-gray-500"
          />
          <form @submit.prevent class="min-w-0 flex-1 px-4">
            <input
              ref="inlineReminderFormNameInput"
              id="inline-reminder-form-name-input"
              v-model="name"
              v-focus
              @keydown.escape="cancel"
              @keydown.enter="add"
              type="text"
              name="name"
              class="block w-full p-0 border-0 border-transparent bg-gray-50 focus:border-indigo-600 focus:ring-0 text-sm"
              placeholder="Name"
            />
            <textarea
              v-if="isDesktop"
              ref="inlineReminderFormNoteInput"
              id="inline-reminder-form-note-input"
              v-model="note"
              rows="4"
              @keydown.escape="cancel"
              @keydown.enter.exact.prevent
              @keyup.enter.exact="add"
              name="note"
              class="mt-2 block w-full p-0 border-0 border-transparent bg-gray-50 focus:border-indigo-600 focus:ring-0 text-xs"
              placeholder="Add Note"
            />
            <textarea
              v-else
              ref="inlineReminderFormNoteInput"
              id="inline-reminder-form-note-input"
              v-model="note"
              rows="4"
              name="note"
              class="mt-2 block w-full p-0 border-0 border-transparent bg-gray-50 focus:border-indigo-600 focus:ring-0 text-xs"
              placeholder="Add Note"
            />
          </form>
        </div>
        <button
          @click="viewFullTaskForm"
          v-tooltip="'View Task Details'"
          id="inline-reminder-form-details-button"
          class="h-6 w-6"
        >
          <InformationCircleIcon
            class="h-5 w-5 text-blue-400 pointer-events-none"
            aria-hidden="true"
          />
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { InformationCircleIcon } from "@heroicons/vue/24/outline";
import { useReminderStore } from "@/stores/reminder";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { useTasksStore } from "@/stores/tasks";
import { storeToRefs } from "pinia";
import { nextTick, onMounted, onUnmounted } from "vue";
import _ from "lodash";

const props = defineProps(["groupedList", "timeObject"]);
const emit = defineEmits(["saved"]);
const tasksStore = useTasksStore();
const { addingTask, addingInlineTask, selectedTaskList, selectedTask } =
  storeToRefs(tasksStore);
const reminderStore = useReminderStore();
const { name, note, list, usesTiming, date, time } = storeToRefs(reminderStore);
const layoutStore = useWorkspaceLayoutStore();
const { isDesktop } = storeToRefs(layoutStore);

onMounted(async () => {
  setTimeout(() => {
    document.addEventListener("click", handleOutsideClick);
    document.addEventListener("touchstart", handleOutsideClick);
  }, 500);
});

onUnmounted(() => {
  document.removeEventListener("click", handleOutsideClick);
  document.removeEventListener("touchstart", handleOutsideClick);
});

function handleOutsideClick(evt) {
  evt.stopPropagation();
  const targetId = evt.target.id;
  const isInside = _.includes(
    [
      "inline-reminder-form-name-input",
      "inline-reminder-form-note-input",
      "inline-reminder-form-details-button",
      "inline-reminder-form-done-button",
    ],
    targetId
  );
  if (!isInside) {
    onClose();
  }
}

function onClose() {
  if (addingInlineTask.value) {
    addingInlineTask.value = false;
  } else {
    selectedTask.value = null;
  }
  reminderStore.reset();
}

function viewFullTaskForm() {
  addingTask.value = true;
  addingInlineTask.value = false;
}
function cancel() {
  if (selectedTask.value) selectedTask.value = null;
  else addingInlineTask.value = false;

  reminderStore.reset();
}
function add() {
  reminderStore.createTask().then(async (json) => {
    if (json) {
      const task = json.data;
      tasksStore.patchTasks([task]);
      emit("saved", task);
    }

    reminderStore.reset();
    await nextTick();

    if (addingInlineTask.value) {
      list.value = props.groupedList || selectedTaskList.value;

      if (props.timeObject) {
        usesTiming.value = true;
        date.value = props.timeObject.date.format("YYYY-MM-DD");
        time.value = props.timeObject.date.format("kk:mm");
      }
      document.getElementById("inline-reminder-form-name-input")?.focus();
    } else {
      selectedTask.value = null;
    }
  });
}
</script>
