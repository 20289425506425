<template>
  <VDropdown v-if="investmentGroups.length > 0">
    <button
      type="button"
      class="relative inline-flex items-center px-4 py-1.5 rounded-md border border-yellow-300 bg-white text-sm font-medium text-gray-400 hover:bg-yellow-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-yellow-500 focus:border-yellow-500"
    >
      <span class="sr-only">Add loan</span>
      <PlusIcon class="-ml-1.5 mr-1 h-5 w-5 text-yellow-400" />
      <span>Loan</span>
    </button>

    <template #popper>
      <div
        class="w-64 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="menu-button"
        tabindex="-1"
      >
        <div class="py-1" role="none">
          <a
            v-for="(group, index) in investmentGroups"
            :key="index"
            @click.prevent="addToPortfolio(group)"
            href=""
            class="text-gray-700 hover:bg-gray-100 hover:text-gray-900 group flex items-center px-4 py-2 text-sm"
            role="menuitem"
            tabindex="-1"
            id="menu-item-1"
          >
            Add to{{
              _.includes(group.placeholderId, "temp") ? " Draft " : " "
            }}Portfolio{{
              _.includes(group.placeholderId, "temp")
                ? ""
                : group.placeholderId
            }}:
            {{ pluralize("Asset", investmentsLength(group), true) }}
            <template v-if="group.dealAction"
              >&nbsp;({{ group.dealAction }})</template
            >
          </a>
          <a
            @click.prevent="addToPortfolio('new')"
            href=""
            class="text-gray-700 hover:bg-gray-100 hover:text-gray-900 group flex items-center px-4 py-2 text-sm"
            role="menuitem"
            tabindex="-1"
            id="menu-item-0"
          >
            New Portfolio
          </a>
        </div>
      </div>
    </template>
  </VDropdown>
</template>

<script setup>
import { PlusIcon } from "@heroicons/vue/20/solid";
import _ from "lodash";
import { useDealBuilderStore } from "@/stores/dealBuilder";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import pluralize from "pluralize";

const props = defineProps(["insertDirection"]);
const emit = defineEmits(["add-to-portfolio"]);

const dealBuilderStore = useDealBuilderStore();
const { dealBuilder } = storeToRefs(dealBuilderStore);

const investmentGroups = computed(() => {
  return _.get(dealBuilder.value, "investmentGroups", []);
});
function investmentsLength(investmentGroup) {
  return _.size(investmentGroup.investments);
}
function addToPortfolio(investmentGroup) {
  emit("add-to-portfolio", {
    investmentGroup,
    insertDirection: props.insertDirection,
  });
}
</script>
