import { computed, ref } from "vue";
import { defineStore, acceptHMRUpdate } from "pinia";

export const useGuestProfileStore = defineStore("guestProfile", () => {
  const geographyIntents = ref([]);
  const discoverySearches = ref([]);
  const discoveredContent = ref([]);
  const collectibleCards = ref([]);
  const guestTasks = ref([]);
  const guestTaskLists = ref([]);
  const acceptedCards = computed(() => {
    return collectibleCards.value.filter(
      (card) => card.userReaction === "accepted"
    ).length;
  });
  const completedCardIds = computed(() => {
    return collectibleCards.value.map((card) => card.id);
  });

  return {
    geographyIntents,
    discoverySearches,
    discoveredContent,
    collectibleCards,
    acceptedCards,
    completedCardIds,
    guestTasks,
    guestTaskLists,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useGuestProfileStore, import.meta.hot)
  );
}
