<template>
  <div class="rounded-md border-l-2 border-teal-500 bg-teal-100 p-2 space-y-2">
    <div class="bg-teal-100">
      <div class="block">
        <div class="flex items-center border-b border-gray-200">
          <div class="flex flex-1 text-sm font-medium text-gray-700">
            {{ _.startCase(mode) }} Time Series{{ timeSeriesSuffix }}
          </div>
          <div class="ml-6 flex items-center rounded-lg bg-teal-200 p-0.5">
            <button
              @click="mode = 'monthly'"
              v-tooltip="'Monthly'"
              type="button"
              class="rounded-md p-1.5 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
              :class="
                mode === 'monthly'
                  ? 'bg-teal-600 shadow-sm text-white'
                  : 'hover:bg-teal-600 hover:shadow-sm text-gray-400'
              "
            >
              <Squares2X2Icon class="h-5 w-5" aria-hidden="true" />
              <span class="sr-only">Use list view</span>
            </button>
            <button
              @click="mode = 'annual'"
              v-tooltip="'Annual'"
              type="button"
              class="ml-0.5 rounded-md p-1.5 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
              :class="
                mode === 'annual'
                  ? 'bg-teal-600 shadow-sm text-white'
                  : 'hover:bg-teal-600 hover:shadow-sm text-gray-400'
              "
            >
              <StopIcon class="h-5 w-5" aria-hidden="true" />
              <span class="sr-only">Use grid view</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-teal-100 overflow-x-auto">
      <table
        class="min-w-full border-separate border-spacing-0 divide-y bg-teal-100 divide-gray-300"
      >
        <thead>
          <tr>
            <th
              v-for="(period, index) in processedOutputs"
              :key="`${index}-${period.date}`"
              scope="col"
              class="sticky top-0 z-10 bg-teal-100 py-1.5 pl-2 pr-2 text-left text-xs font-semibold text-gray-700"
            >
              <template v-if="_.isNumber(period.date)">{{
                period.date
              }}</template>
              <template v-else>{{
                moment(period.date).format("MM/YYYY")
              }}</template>
            </th>
          </tr>
        </thead>
        <tbody class="bg-teal-100">
          <tr class="even:bg-teal-50">
            <td
              v-for="(period, index) in processedOutputs"
              :key="`${index}-${period.date}`"
              class="whitespace-nowrap py-2 pl-2 pr-2 text-xs font-medium text-gray-500"
            >
              ${{ currencyAmount(period.amount, 0) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import moment from "moment";
import { currencyAmount } from "@/assets/numberHelpers";
import api from "@/router/api";
import _ from "lodash";
import { Squares2X2Icon, StopIcon } from "@heroicons/vue/20/solid";

const props = defineProps([
  "dataField",
  "dateFields",
  "perAreaSize",
  "scheduleDataField",
]);

const mode = ref("monthly");
const fetchedTimeSeries = ref([]);

const existingCommencementDate = computed(
  () => props.dateFields.commenced_date?.fieldDate,
);
const timeSeriesSuffix = computed(() => {
  if (mode.value === "annual") {
    if (existingCommencementDate.value) {
      return " (Year Ending)";
    } else {
      return " (Schedule Year)";
    }
  } else {
    return "";
  }
});

const processedOutputs = computed(() =>
  mode.value === "monthly" ? fullOutputs.value : annualFullOutputs.value,
);

const fullOutputs = computed(() =>
  _.concat(emptyCommencedOutputs.value, outputs.value),
);
const annualFullOutputs = computed(() => {
  const grouped = _.groupBy(fullOutputs.value, function ({ date, dateType }) {
    switch (dateType) {
      case "Actual":
        return moment(date).utc().year();
      case "Relative": {
        if (existingCommencementDate.value) {
          return moment(date).utc().year();
        } else {
          return _.ceil(date / 12);
        }
      }
      default:
        return null;
    }
  });

  return _.map(grouped, function (periods, year) {
    return {
      date: _.toNumber(year) < 1000 ? _.toNumber(year) : `${year}-12-01`,
      amount: _.sum(periods.map(({ amount }) => amount)),
      annual: true,
    };
  });
});

const emptyCommencedOutputs = computed(() => {
  if (props.scheduleDataField?.fieldContent?.scheduleType === "stepping") {
    const commencedMoment = existingCommencementDate.value
      ? moment(existingCommencementDate.value)
      : null;
    const initialPeriod = _.head(outputs.value);
    const initialDate = initialPeriod?.date;

    let initialDateMoment = null;
    let monthsDuration = null;

    if (commencedMoment && initialDate) {
      switch (initialPeriod.dateType) {
        case "Actual":
          initialDateMoment = moment(initialDate);
          monthsDuration = periodMonthDuration(
            commencedMoment,
            initialDateMoment,
          );
          break;
        case "Relative":
          monthsDuration = initialDate === 1 ? 0 : initialDate - 1;
      }

      return _.times(monthsDuration, function (i) {
        return {
          date: commencedMoment.clone().add(i, "months").toISOString(),
          dateType: "Actual",
          amount: 0.0,
        };
      });
    } else if (initialDate && initialPeriod.dateType === "Relative") {
      monthsDuration = initialDate === 1 ? 0 : initialDate - 1;

      return _.times(monthsDuration, function (i) {
        return {
          date: i + 1,
          dateType: "Relative",
          amount: 0.0,
        };
      });
    } else {
      return [];
    }
  } else {
    return [];
  }
});

const outputs = computed(() => {
  if (props.scheduleDataField) {
    return fetchedTimeSeries.value.map((period) => {
      if (existingCommencementDate.value && period.dateType === "Relative") {
        let offset = 0;
        let amount = period.amount;

        switch (props.scheduleDataField.fieldContent?.scheduleType) {
          case "stepping":
            offset = period.date - 1;
            break;
          case "tenantImprovementAllowance":
            {
              offset = period.date;

              if (period.amountType === "annualPerArea" && props.perAreaSize) {
                amount = period.amount * props.perAreaSize;
              } else if (period.amountType !== "annual") {
                amount = null;
              }
            }
            break;
        }
        return _.merge({}, period, {
          amount,
          date: moment(existingCommencementDate.value)
            .add(offset, "months")
            .toISOString(),
          dateType: "Actual",
        });
      } else {
        return period;
      }
    });
  } else {
    return [];
  }
});

onMounted(() => {
  fetchTimeSeries();
});

async function fetchTimeSeries() {
  const timeSeriesResponse = await api.get(
    `cashflow_time_series/${props.scheduleDataField.fieldContentId}`,
  );

  if (timeSeriesResponse?.data) {
    fetchedTimeSeries.value = timeSeriesResponse.data;
  }
}

function periodMonthDuration(currentDateMoment, finalDateMoment) {
  if (currentDateMoment && finalDateMoment) {
    const adjustedFinalMoment =
      finalDateMoment.date() === 1
        ? finalDateMoment
        : finalDateMoment.endOf("month").add(1, "day");
    return adjustedFinalMoment.diff(currentDateMoment, "months");
  } else {
    return null;
  }
}
</script>
