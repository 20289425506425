<template>
  <div v-if="costs.pagy" class="mt-4 flex flex-col">
    <div v-if="costs.data.length > 0" class="-my-2 -mx-4 overflow-x-auto">
      <div class="inline-block min-w-full py-2 align-middle px-4">
        <div
          class="relative overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-lg"
        >
          <table class="min-w-full table-fixed divide-y divide-gray-300">
            <thead class="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  class="pl-4 py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
                >
                  Record
                </th>
                <th
                  scope="col"
                  class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Id
                </th>
                <th
                  scope="col"
                  class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Date
                </th>
                <th
                  scope="col"
                  class="pl-3 pr-4 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Cost
                </th>
              </tr>
            </thead>
            <tbody
              v-if="rowsDisplayable"
              class="divide-y divide-gray-200 bg-white"
            >
              <CostsTableRow
                v-for="(record, index) in costs.data"
                :key="record.id"
                :record="record"
                :data-test="`costs-table-row-${index}`"
              />
            </tbody>
            <PaginationFooter
              v-if="costs.pagy.prev || costs.pagy.next"
              :pagination-meta="costs.pagy"
              :table-meta="{ colspan: 4 }"
              @previous="fetchCosts"
              @next="fetchCosts"
              @page="fetchCosts"
              @infinite-fetch="loadCosts"
            />
          </table>
        </div>
      </div>
    </div>
    <div v-else class="text-center" data-test="my-contributions-empty-state">
      <CurrencyDollarIcon class="mt-8 mx-auto h-12 w-12 text-gray-400" />
      <h3 class="mt-2 text-sm font-medium text-gray-900">No spending</h3>
      <p class="mt-1 text-sm text-gray-500">
        Spending activity will appear here.
      </p>
    </div>
  </div>
</template>

<script setup>
import CostsTableRow from "@/components/crowdsourcing/contributions/CostsTableRow.vue";
import PaginationFooter from "@/components/PaginationFooter.vue";
import { CurrencyDollarIcon } from "@heroicons/vue/24/outline";
import { ref, watch, onMounted } from "vue";
import api from "@/router/api";
import _ from "lodash";

const props = defineProps(["timePeriod"]);

const costs = ref({
  data: [],
  pagy: null,
});
const rowsDisplayable = ref(false);

watch(
  () => props.timePeriod,
  () => {
    fetchCosts();
  },
);

onMounted(() => {
  fetchCosts();
});

async function fetchCosts(paginationUrl = null) {
  costs.value = ref({
    data: [],
    pagy: null,
  });
  rowsDisplayable.value = false;

  api
    .get(paginationUrl || `my_costs?time_period=${props.timePeriod}`)
    .then((json) => {
      costs.value = json.data;
      rowsDisplayable.value = true;
    });
}

function cleanUrl(url) {
  return url.replace("/api/v1/", "");
}
const loadCosts = async ($state) => {
  if (costs.value.pagy?.next) {
    const endpoint = cleanUrl(costs.value.pagy?.next_url);
    try {
      api.get(endpoint).then((json) => {
        const { data, pagy } = json.data;

        costs.value.data = _.concat(costs.value.data, data);
        costs.value.pagy = pagy;
        if (data.length < 10) $state.complete();
        else {
          $state.loaded();
        }
      });
    } catch (error) {
      $state.error();
    }
  } else {
    $state.complete();
  }
};
</script>
