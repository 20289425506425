<template>
  <div
    v-if="context === 'shell'"
    class="max-w-3xl mx-auto flex flex-col flex-1 justify-between my-8"
  >
    <div class="px-6 text-indigo-100 text-xl font-medium text-center">
      Welcome to Tower Hunt! Below is a summary of what we know about
      <span class="text-indigo-300 text-2xl font-semibold">{{
        dataField.fieldContent?.name
      }}</span
      >. To see all the details, please visit from a desktop browser.
    </div>
    <HighlightCardContainer
      v-if="!loaded || displayable"
      :data-field="dataField"
      @loading-complete="setLoadingComplete"
    />
  </div>

  <section v-else aria-labelledby="highlight">
    <ContentHeader>
      <template v-slot:title>Highlight</template>
      <template v-slot:description
        >A summary of important information.</template
      >
    </ContentHeader>

    <ContentBody>
      <HighlightCardContainer
        v-if="!loaded || displayable"
        :data-field="dataField"
        @loading-complete="setLoadingComplete"
      />
      <section v-else>
        <div
          class="relative block w-full rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <CubeTransparentIcon class="mx-auto h-12 w-12 text-gray-400" />
          <span class="mt-2 block text-sm font-medium text-gray-900">
            We don't have a highlight for this yet.
          </span>
        </div>
      </section>
    </ContentBody>
  </section>
</template>

<script setup>
import HighlightCardContainer from "@/components/collectible-cards/HighlightCardContainer.vue";
import { ref } from "vue";
import ContentBody from "@/components/main-layout/secondary-panel/ContentBody.vue";
import ContentHeader from "@/components/main-layout/secondary-panel/ContentHeader.vue";
import { CubeTransparentIcon } from "@heroicons/vue/24/outline";

defineProps(["dataField", "context"]);

const loaded = ref(false);
const displayable = ref(true);

function setLoadingComplete({ displayableBool }) {
  loaded.value = true;
  displayable.value = displayableBool;
}
</script>
