<template>
  <SwitchGroup as="div" class="flex items-center">
    <Switch
      v-model="referred"
      :class="[
        referred ? 'bg-amber-600' : 'bg-gray-200',
        'relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-amber-600 focus:ring-offset-2',
      ]"
    >
      <span class="sr-only">Data referred by contributor?</span>
      <span
        :class="[
          referred ? 'translate-x-5' : 'translate-x-0',
          'pointer-events-none relative inline-block h-4 w-4 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
        ]"
      >
        <span
          :class="[
            referred
              ? 'opacity-0 duration-100 ease-out'
              : 'opacity-100 duration-200 ease-in',
            'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity',
          ]"
          aria-hidden="true"
        >
          <svg class="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
            <path
              d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
        <span
          :class="[
            referred
              ? 'opacity-100 duration-200 ease-in'
              : 'opacity-0 duration-100 ease-out',
            'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity',
          ]"
          aria-hidden="true"
        >
          <svg
            class="h-3 w-3 text-amber-600"
            fill="currentColor"
            viewBox="0 0 12 12"
          >
            <path
              d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z"
            />
          </svg>
        </span>
      </span>
    </Switch>
    <SwitchLabel as="span" class="ml-3 text-xs">
      <span class="font-medium text-gray-900">Referred?</span>
    </SwitchLabel>
  </SwitchGroup>
</template>

<script setup>
import { computed } from "vue";
import { Switch, SwitchGroup, SwitchLabel } from "@headlessui/vue";
import { storeToRefs } from "pinia";
import { useEasyDataInputAdminStore } from "@/stores/easyDataInputAdmin";
import _ from "lodash";

const props = defineProps(["item"]);

const easyDataInputAdminStore = useEasyDataInputAdminStore();
const { detailedSelectedJobCRUD } = storeToRefs(easyDataInputAdminStore);

const writableItem = computed(() =>
  _.find(payloadItems.value, { id: props.item.id }),
);
const referred = computed({
  get() {
    return writableItem.value.referred;
  },
  set(bool) {
    writableItem.value.referred = bool;
  },
});

const payloadItems = computed({
  get() {
    return detailedSelectedJobCRUD.value?.payloadItems;
  },
  set(newArr) {
    detailedSelectedJobCRUD.value.payloadItems = newArr;
  },
});
</script>
