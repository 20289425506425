<template>
  <VDropdown placement="left-start">
    <slot name="button">
      <a
        href=""
        @click.prevent
        class="flex items-center text-gray-700 p-2 text-xs hover:bg-gray-100 hover:text-gray-900"
        role="menuitem"
        tabindex="-1"
        :data-test="`calculation-options-menu-button`"
      >
        <AdjustmentsHorizontalIcon
          class="mr-2 h-4 w-4 text-gray-400 group-hover:text-gray-500"
        />
        Calculation Options
      </a>
    </slot>

    <template #popper>
      <div
        class="w-64 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none"
        role="menu"
        aria-orientation="vertical"
        tabindex="-1"
      >
        <div
          class="py-1 max-h-64 overflow-y-auto divide-y divide-gray-300"
          role="none"
        >
          <div
            v-for="(option, index) in actionableOptions"
            :key="_.camelCase(option.label)"
            v-tooltip="option.description"
            class="w-full p-2"
            :class="[
              option.orientation === 'x'
                ? 'flex items-center justify-between space-x-2'
                : 'flex flex-col space-y-2',
            ]"
            role="menuitem"
            tabindex="-1"
            :data-test="`calculation-option-${index}-button`"
          >
            <span class="text-sm text-gray-800">{{ option.label }}</span>
            <component
              :is="option.component"
              v-bind="{ optionData: calculationOptionData }"
            />
          </div>
        </div>
      </div>
    </template>
  </VDropdown>
</template>

<script setup>
import { AdjustmentsHorizontalIcon } from "@heroicons/vue/20/solid";
import { computed } from "vue";
import _ from "lodash";
import calculationOptionsFor from "@/components/analyze/calculations/options/availableOptions";

const props = defineProps(["calculationName", "calculationOptionData"]);

const actionableOptions = computed(() => {
  return calculationOptionsFor(
    props.calculationName,
    props.calculationOptionData,
  );
});
</script>
