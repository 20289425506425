<template>
  <div
    class="w-56 bg-white divide-y divide-gray-100 focus:outline-none"
    role="menu"
    aria-orientation="vertical"
    aria-labelledby="menu-button"
    tabindex="-1"
  >
    <div class="py-1" role="none">
      <a
        @click.prevent="terminateAdvisor"
        href=""
        class="text-gray-700 group flex items-center px-4 py-2 text-sm hover:bg-gray-100 hover:text-gray-900"
        role="menuitem"
        tabindex="-1"
        id="menu-item-4"
        data-test="terminate-advisor-button"
      >
        <NoSymbolIcon
          class="flex-shrink-0 mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
        />
        Terminate advisor
      </a>
    </div>
  </div>
</template>

<script setup>
import { useCrowdsourcedChangeGroupStore } from "@/stores/crowdsourcedChangeGroup";
import { useTimeTravelStore } from "@/stores/timeTravel";
import { ref, computed, onMounted } from "vue";
import { storeToRefs } from "pinia";
import moment from "moment";
import api from "@/router/api";
import { NoSymbolIcon } from "@heroicons/vue/20/solid";
import _ from "lodash";

const props = defineProps(["decoratingDataField", "restricted"]);
const emit = defineEmits(["refetch-investment", "already-terminated"]);

const crowdsourcedChangeGroupStore = useCrowdsourcedChangeGroupStore();
const { originatingData, changeGroupId } = storeToRefs(
  crowdsourcedChangeGroupStore,
);
const timeTravelStore = useTimeTravelStore();
const { asOfMilliseconds } = storeToRefs(timeTravelStore);

const terminatedDateField = ref(null);
const contentType = computed(() => props.decoratingDataField.fieldContentType);
const contentId = computed(() => props.decoratingDataField.fieldContentId);

onMounted(() => {
  fetchDateField();
});

function fetchDateField() {
  api
    .get(
      `crowdsourced_data_fields/${contentType.value}/${contentId.value}?field_name=terminated_date`,
    )
    .then((json) => {
      terminatedDateField.value = json.data;

      if (terminatedDateField.value) emit("already-terminated");
    });
}

async function terminateAdvisor() {
  if (!originatingData.value && !terminatedDateField.value) {
    const payload = {
      date: moment(asOfMilliseconds.value).valueOf(),
      changeGroupId: changeGroupId.value,
    };
    const apiRequestFunc = () =>
      api.post(
        `content_dates/${contentType.value}/${contentId.value}?field_name=terminated_date`,
        payload,
      );
    const successCallback = () => afterPersistDate();
    const failureCallback = () => {};
    return crowdsourcedChangeGroupStore.originateData(
      apiRequestFunc,
      successCallback,
      failureCallback,
    );
  }
}

async function afterPersistDate() {
  finalizeChange();
}
async function finalizeChange() {
  const huntAdvisor = _.includes(
    ["HuntGeographyIntent", "Hunt"],
    props.decoratingDataField?.decoratingContentType,
  );

  if (huntAdvisor) {
    emit("already-terminated");
  } else {
    emit("refetch-investment");
  }
}
</script>
