import { markRaw } from "vue";
import SubscribeVue from "@/components/users/SubscribeVue.vue";

export default async function subscribeInterceptor({
  apiRequestFunc,
  successCallback,
  failureCallback = null,
  modalPayloadRef,
  upgradeSuccessfulRef,
  context = null,
  promptReason = "stakeShortfall",
  afterSubscribe = null,
  originatingDataRef = null,
}) {
  const makeRequest = async (checkUpgrade = false) => {
    if (checkUpgrade && !upgradeSuccessfulRef.value) {
      console.log("modal closed but user not subscribed");
    } else {
      const response = await executeRequestWithModal();

      return response;
    }
  };
  const postSubscribeAction = (json = null) => {
    switch (afterSubscribe) {
      case "apiRequest":
        return () => makeRequest(true);
      case "successCallback":
        return () => successCallback(json);
      default:
        return null;
    }
  };
  const executeRequestWithModal = async () => {
    try {
      const json = await apiRequestFunc();
      const subscribeStatus =
        json.data.stakeStatus || json.data.licensingStatus;

      if (subscribeStatus === "prompt_to_subscribe") {
        openModal(json);

        if (json.data.dataField) {
          const response = await successCallback(json);

          return response;
        }
      } else {
        const response = await successCallback(json);

        return response;
      }
    } catch (error) {
      console.error(error);
      if (failureCallback) {
        await failureCallback();
      }
    } finally {
      if (originatingDataRef) {
        originatingDataRef.value = false;
      }
    }
  };
  const openModal = (json) => {
    if (originatingDataRef) {
      originatingDataRef.value = false;
    }
    modalPayloadRef.value = {
      size: "base",
      theme: "light",
      component: markRaw(SubscribeVue),
      props: {
        newPlan: "tracker",
        billingFrequency: "monthly",
        promptReason,
        context,
      },
      afterClose: postSubscribeAction(json),
      afterCloseDestination: null,
    };
  };

  if (apiRequestFunc) {
    const maybeResponse = await makeRequest();

    return maybeResponse;
  } else openModal();
}
