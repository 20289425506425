<template>
  <CloudIcon
    v-for="(icon, i) in icons"
    :key="i"
    :class="`absolute ${randomized(topOffets)} ${randomized(
      xOffsets
    )} ${randomized(iconSizes)} text-white`"
    style="z-index: 0"
  />
</template>

<script setup>
import { CloudIcon } from "@heroicons/vue/24/solid";
import _ from "lodash";
import { ref } from "vue";

const icons = ref(_.range(_.random(3, 7)));

const iconSizes = ["w-12", "w-14", "w-16", "w-20", "w-24"];
const topOffets = ["top-4", "top-8", "top-12", "top-16"];
const xOffsets = [
  "left-[10%]",
  "left-1/2",
  "left-1/3",
  "left-2/3",
  "left-1/4",
  "left-3/4",
];

function randomized(arr) {
  return arr[_.random(0, arr.length - 1)];
}
</script>
