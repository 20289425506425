<template>
  <div class="h-full rounded-lg overflow-hidden">
    <CardBranding :card="card" />
    <div class="absolute inset-0">
      <div
        class="absolute inset-0 bg-gradient-to-r from-blue-600 to-sky-400 mix-blend-multiply"
      />
    </div>
    <div class="h-full relative px-4 py-16 flex flex-col justify-center">
      <h1 class="text-center text-4xl font-bold tracking-tight">
        <span class="block text-white">Team</span>
        <span class="block text-sky-200">Joined</span>
      </h1>
      <p class="mx-auto mt-6 max-w-lg text-center text-lg text-sky-200">
        {{ card.taskName }}
      </p>
      <div class="mx-auto mt-10 max-w-sm flex justify-center">
        <div class="space-y-4">
          <a
            href=""
            @click.prevent="viewTeams"
            class="flex items-center justify-center rounded-md border border-transparent bg-white px-4 py-3 text-base font-medium text-sky-700 shadow-sm hover:bg-sky-50 sm:px-8"
            >View Teams</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import CardBranding from "@/components/collectible-cards/CardBranding.vue";
import { useRoute, useRouter } from "vue-router";

defineProps(["card"]);

const router = useRouter();
const route = useRoute();

function viewTeams() {
  router.push({
    name: route.name,
    query: {
      ...route.query,
      horizontalTab: "Me",
      verticalTab: "Teams",
    },
  });
}
</script>
