<template>
  <div
    class="flex items-center justify-center gap-x-6 bg-indigo-600 py-2.5 px-3.5"
    id="welcome-banner"
  >
    <p class="text-sm leading-6 text-white">
      <a @click.prevent="logIn('Register')" href="" class="space-x-1">
        <strong class="font-semibold">Welcome to the new Tower Hunt!</strong>
        <span>&middot;</span>
        You'll need a brand new account.<span aria-hidden="true">&rarr;</span>
      </a>
    </p>
  </div>
</template>

<script setup>
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { useSecondaryPanelStore } from "@/stores/secondaryPanel";
import { storeToRefs } from "pinia";
import { useRouter, useRoute } from "vue-router";

const layoutStore = useWorkspaceLayoutStore();
const { isDesktop } = storeToRefs(layoutStore);
const secondaryPanelStore = useSecondaryPanelStore();

const router = useRouter();
const route = useRoute();

function logIn(destination) {
  if (isDesktop.value) {
    secondaryPanelStore.setAuthenticate({ name: destination });
    router.push({
      name: route.name,
      query: {
        ...route.query,
        horizontalTab: "Authenticate",
        verticalTab: destination,
      },
    });
  } else {
    router.push({ name: destination });
  }
}
</script>
