<template>
  <main v-if="tabs && selectedTab" class="h-full flex flex-1">
    <!-- Narrow sidebar-->
    <nav
      aria-label="Sidebar"
      class="flex-shrink-0 p-2 bg-gray-100 overflow-y-auto"
    >
      <div class="relative flex flex-col space-y-2">
        <a
          v-for="tab in tabs"
          :key="tab.name"
          @click.prevent="selectTab(tab)"
          :href="tab.href"
          v-tooltip="tab.name"
          :class="[
            selectedTab.name === tab.name
              ? 'text-gray-700 bg-gray-300'
              : 'text-gray-500 hover:bg-gray-200',
            'flex-shrink-0 inline-flex items-center justify-center h-8 w-8 rounded-lg',
          ]"
          :data-test="`${_.kebabCase(tab.tabQuery)}-nav`"
        >
          <span class="sr-only">{{ tab.name }}</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            :class="selectedTab.name === tab.name ? '' : ''"
            class="h-5 w-5"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 24 24"
            v-html="pathsForIcon(tab.svgPaths)"
          ></svg>
        </a>
      </div>
    </nav>

    <div class="flex-grow overflow-x-hidden">
      <component :is="selectedTab.component" v-bind="selectedTabProperties" />
    </div>
  </main>
</template>

<script setup>
import {
  initialSelectedTabFor,
  tabPropertiesFor,
  tabsFor,
} from "@/components/contacts/contactsTabs";
import { usePropertyDiagramStore } from "@/stores/propertyDiagram";
import { storeToRefs } from "pinia";
import { useRoute, useRouter } from "vue-router";
import { computed, ref, onMounted, watch, nextTick } from "vue";
import pathsForIcon from "@/assets/pathsForIcon";
import _ from "lodash";

const props = defineProps(["dataField"]);

const propertyDiagramStore = usePropertyDiagramStore();
const { propertyIdParam } = storeToRefs(propertyDiagramStore);

const router = useRouter();
const route = useRoute();
const routeName = computed(() => route.name);
const query = computed(() => route.query);
const verticalTab = computed(() => _.get(query.value, "verticalTab"));
const hasVerticalTab = computed(() => !!verticalTab.value);

const tabs = ref(tabsFor(props.dataField));
const refreshingTabs = ref(false);
const selectedTab = ref(null);
const dataFieldLocalId = computed(() => _.get(props.dataField, "localId"));
const selectedTabProperties = computed(() => tabPropertiesFor(props.dataField));
const horizontalIsContacts = computed(
  () => _.get(query.value, "horizontalTab") === "Contacts"
);

onMounted(() => {
  refreshTabs();
});

watch(dataFieldLocalId, () => {
  refreshTabs();
});
watch(routeName, () => {
  refreshTabs();
});
watch(horizontalIsContacts, () => {
  if (horizontalIsContacts.value) refreshTabs();
});
watch(verticalTab, () => {
  if (hasVerticalTab.value && !refreshingTabs.value) refreshTabs();
});
watch(propertyIdParam, () => {
  if (!propertyIdParam.value) {
    console.log("no property id");
  }
});

async function refreshTabs() {
  refreshingTabs.value = true;
  tabs.value = tabsFor(props.dataField);
  selectTab().then(async () => {
    await nextTick();
    refreshingTabs.value = false;
  });
}

async function selectTab(tab) {
  const oldTabQuery = _.get(selectedTab.value, "tabQuery");
  const hasVerticalTab = !!_.get(query.value, "verticalTab");

  return new Promise((resolve) => {
    if (tab) selectedTab.value = tab;
    else if (horizontalIsContacts.value) {
      if (hasVerticalTab) {
        selectedTab.value =
          _.find(tabs.value, {
            tabQuery: query.value.verticalTab,
          }) || initialSelectedTabFor(props.dataField);
      } else {
        selectedTab.value = initialSelectedTabFor(props.dataField);
      }
    }

    const newTabQuery = _.get(selectedTab.value, "tabQuery");

    if (selectedTab.value && newTabQuery !== oldTabQuery) {
      router.push({
        name: route.name,
        query: {
          ...route.query,
          verticalTab: selectedTab.value.tabQuery,
        },
      });
    }

    resolve();
  });
}
</script>
