<template>
  <section aria-labelledby="location">
    <ContentHeader>
      <template v-slot:title>Location</template>
      <template v-slot:description
        >Show exactly where this {{ dataField.fieldContentType }} is in the
        world.</template
      >
    </ContentHeader>

    <ContentBody>
      <ContentName
        field-name="street_address"
        :wrapping-data-field="dataField"
      />
      <ContentLocationEdit
        v-if="editing"
        :decorating-data-field="dataField"
        :location-data-field="locationDataField"
        :joining-property-data-field="joiningPropertyLocationDataField"
        @completed="completed"
        @cancel="cancel"
      />
      <div v-else-if="contentLocation" class="">
        <div class="">
          <div class="mb-1 text-sm font-medium text-gray-500">Location</div>
          <DataField
            :data-field="locationDataField"
            text-classes="text-sm"
            @suggest-change="suggestChange"
            @completed="completed"
          />
          <LocationMap
            :editable="false"
            :data-field-id="locationDataField.localId"
            :lat="contentLocation.lat"
            :lng="contentLocation.lng"
            :map-id="`${locationDataField.localId}`"
            class="mt-5 h-64 w-full"
          />
        </div>
      </div>

      <div v-else class="flex items-center">
        <DataVisibilityButton>
          <template v-slot:button>
            <button
              @click="editing = true"
              type="button"
              class="group py-1 px-1.5 flex items-center justify-between rounded-full border border-gray-300 shadow-sm space-x-2 text-left hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <span class="min-w-0 flex-1 flex items-center space-x-1">
                <span class="flex-shrink-0 flex items-center">
                  <span
                    class="inline-flex items-center justify-center h-5 w-5 rounded-full bg-indigo-600"
                  >
                    <PencilIcon class="h-3 w-3 text-white" />
                  </span>
                </span>
                <span class="min-w-0 flex-1">
                  <span class="text-sm font-medium text-gray-900 truncate"
                    >Add {{ dataField.fieldContentType }} location</span
                  >
                </span>
              </span>
            </button>
          </template>
        </DataVisibilityButton>
      </div>
    </ContentBody>
  </section>
</template>

<script setup>
import { ref, computed, watch, onMounted } from "vue";
import { PencilIcon } from "@heroicons/vue/24/outline";
import ContentBody from "@/components/main-layout/secondary-panel/ContentBody.vue";
import ContentHeader from "@/components/main-layout/secondary-panel/ContentHeader.vue";
import ContentLocationEdit from "@/components/crowdsourcing/ContentLocationEdit.vue";
import ContentName from "@/components/crowdsourcing/ContentName.vue";
import LocationMap from "@/components/maps/LocationMap.vue";
import DataField from "@/components/crowdsourcing/DataField.vue";
import api from "@/router/api";
import decoratingAndFieldKey from "@/components/crowdsourcing/decoratingAndFieldKey";
import _ from "lodash";
import DataVisibilityButton from "@/components/crowdsourcing/DataVisibilityButton.vue";

const props = defineProps(["dataField"]);
const editing = ref(false);
const locationDataField = ref(null);
const joiningPropertyLocationDataField = ref(null);
const loaded = ref(false);

const content = computed(() => {
  return props.dataField.fieldContent;
});
const contentLocation = computed(() => {
  return _.get(locationDataField.value, "fieldContent", null);
});
const combinedId = computed(() => {
  return decoratingAndFieldKey(props.dataField);
});
const joiningPropertyId = computed(() => {
  if (props.dataField.joiningContentType === "Property") {
    return props.dataField.joiningContentId;
  } else {
    return null;
  }
});

watch(combinedId, () => {
  fetchDataField();
});
watch(loaded, () => {
  if (loaded.value && !locationDataField.value && joiningPropertyId.value) {
    fetchJoiningPropertyDataField();
  }
});

onMounted(() => fetchDataField());

function fetchDataField() {
  api
    .get(
      `crowdsourced_data_fields/${props.dataField.fieldContentType}/${content.value.id}?field_name=ContentLocation`,
    )
    .then((json) => {
      loaded.value = false;
      locationDataField.value = null;

      setTimeout(() => {
        locationDataField.value = json.data;
      }, 50);
      loaded.value = true;
    });
}
function fetchJoiningPropertyDataField() {
  api
    .get(
      `crowdsourced_data_fields/Property/${joiningPropertyId.value}?field_name=ContentLocation`,
    )
    .then((json) => {
      joiningPropertyLocationDataField.value = null;

      setTimeout(() => {
        joiningPropertyLocationDataField.value = json.data;
      }, 50);
    });
}
function completed() {
  editing.value = false;
  fetchDataField();
}
function suggestChange() {
  editing.value = true;
}
function cancel() {
  editing.value = false;
}
</script>
