<template>
  <div>
    <div v-if="loaded && items.data.length > 0">
      <div class="grid" :class="selectedItem ? 'grid-cols-3' : 'grid-cols-1'">
        <div :class="selectedItem ? 'col-span-2' : ''">
          <ul role="list" class="grid gap-6" :class="itemGridClasses">
            <DataInputJobExistingPayloadItem
              v-for="item in items.data"
              :key="item.id"
              :item="item"
              class="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow"
              @select-item="setSelectedItem"
            />
          </ul>
          <InfiniteLoading
            v-if="items.pagy && items.pagy.next"
            @infinite="loadItems"
          />
        </div>
        <SelectedInputPayloadItemDetails
          v-if="selectedItem"
          :closeable="true"
          :local-item="selectedItem"
          @close-panel="selectedItem = null"
        />
      </div>
    </div>
    <div
      v-else
      class="flex flex-col text-center items-center justify-center max-w-lg mx-auto"
      data-test="completed-job-opportunities-empty-state"
    >
      <CheckIcon class="mt-8 mx-auto h-12 w-12 text-gray-400" />
      <h3 class="mt-2 text-sm font-medium text-gray-900">No Completed Jobs</h3>
      <p class="mt-1 text-sm text-gray-500">
        Your completed jobs will appear here.
      </p>
    </div>
  </div>
</template>

<script setup>
import DataInputJobExistingPayloadItem from "@/views/admin/DataInputJobExistingPayloadItem.vue";
import SelectedInputPayloadItemDetails from "@/components/crowdsourcing/contributions/SelectedInputPayloadItemDetails.vue";
import { CheckIcon } from "@heroicons/vue/24/outline";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { storeToRefs } from "pinia";
import { ref, computed, onMounted } from "vue";
import api from "@/router/api";
import _ from "lodash";

const layoutStore = useWorkspaceLayoutStore();
const { workspaceLayout } = storeToRefs(layoutStore);

const loaded = ref(false);
const selectedItem = ref(null);
const items = ref({
  data: [],
  pagy: null,
});

const itemGridClasses = computed(() => {
  if (selectedItem.value) {
    return workspaceLayout.value === "sideBySide"
      ? "grid-cols-1"
      : "grid-cols-3";
  } else {
    return workspaceLayout.value === "sideBySide"
      ? "grid-cols-2"
      : "grid-cols-5";
  }
});

onMounted(() => {
  fetchItems();
});

async function fetchItems(paginationUrl = null) {
  items.value = ref({
    data: [],
    pagy: null,
  });

  api.get(paginationUrl || `completed_payload_item_inputters`).then((json) => {
    items.value = json.data;
    loaded.value = true;
  });
}

function cleanUrl(url) {
  return url.replace("/api/v1/", "");
}
const loadItems = async ($state) => {
  if (items.value.pagy?.next) {
    const endpoint = cleanUrl(items.value.pagy?.next_url);
    try {
      api.get(endpoint).then((json) => {
        const { data, pagy } = json.data;

        items.value.data = _.concat(items.value.data, data);
        items.value.pagy = pagy;
        if (data.length < 10) $state.complete();
        else {
          $state.loaded();
        }
      });
    } catch (error) {
      $state.error();
    }
  } else {
    $state.complete();
  }
};

function setSelectedItem(item) {
  selectedItem.value = item;
}
</script>
