<template>
  <div class="h-full flex flex-col">
    <div
      class="p-2 bg-gray-50 flex flex-col flex-shrink-0 gap-3 overflow-y-auto"
    >
      <div class="flex items-center justify-between">
        <div class="flex flex-grow items-center">
          <div class="w-full">
            <label for="task-search-input" class="sr-only">Search</label>
            <div class="relative">
              <div
                class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
              >
                <MagnifyingGlassIcon
                  class="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
              <div
                v-if="relatedTaskNumericIds.length > 0"
                class="block w-full rounded-md border border-gray-300 bg-white py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:border-indigo-500 focus:text-gray-900 focus:placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
              >
                <div
                  class="inline-flex rounded-full items-center my-1 mr-1 py-0.5 pl-2.5 pr-1 text-sm font-medium bg-indigo-100 text-indigo-700"
                >
                  Related tasks
                  <button
                    @click="cancel"
                    type="button"
                    class="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-indigo-400 hover:bg-indigo-200 hover:text-indigo-500 focus:outline-none focus:bg-indigo-500 focus:text-white"
                  >
                    <span class="sr-only">Clear related task search</span>
                    <XMarkIcon class="h-3 w-3" />
                  </button>
                </div>
              </div>
              <input
                v-else
                v-model="rawSearchQuery"
                v-focus
                id="task-search-input"
                name="search"
                class="block w-full rounded-md border border-gray-300 bg-white py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:border-indigo-500 focus:text-gray-900 focus:placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
                placeholder="Search"
                type="search"
              />
            </div>
          </div>
        </div>
        <a @click.prevent="cancel" href="" class="ml-3 text-sm text-indigo-500">
          Cancel
        </a>
      </div>
    </div>

    <div class="p-2 flex flex-col space-y-2 flex-grow overflow-y-auto">
      <div class="lg:flex lg:items-center lg:justify-between">
        <div class="min-w-0 flex-1">
          <div class="flex items-center space-x-2">
            <div class="flex items-center text-sm text-gray-500">
              {{ completedTasks.length }} completed
            </div>
            <div class="text-gray-500 text-sm">&middot;</div>
            <VDropdown :disabled="nothingCompleted" class="">
              <a
                @click.prevent
                href=""
                :class="nothingCompleted ? 'text-gray-200' : 'text-indigo-500'"
                class="flex items-center text-sm"
              >
                Clear
              </a>
              <template #popper>
                <div
                  class="w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="menu-button"
                  tabindex="-1"
                >
                  <div class="py-1" role="none">
                    <div
                      class="font-medium text-gray-500 group flex items-center p-2 text-xs"
                      role="menuitem"
                      tabindex="-1"
                      id="menu-item-0"
                    >
                      Completed Reminders
                    </div>
                  </div>
                  <div class="py-1" role="none">
                    <a
                      @click.prevent="clearCompleted()"
                      href=""
                      class="text-gray-700 hover:bg-gray-100 hover:text-gray-900 group flex items-center space-x-2 p-2 text-sm"
                      role="menuitem"
                      tabindex="-1"
                      id="menu-item-0"
                      data-test="`clear-all-completed-tasks-button`"
                    >
                      <span>All Completed</span>
                    </a>
                  </div>
                </div>
              </template>
            </VDropdown>
          </div>
        </div>
        <a
          @click.prevent="showCompleted = !showCompleted"
          href=""
          class="ml-2 text-sm text-indigo-500"
        >
          {{ showCompleted ? "Hide" : "Show" }}
        </a>
      </div>

      <ul
        v-for="(tasks, accessTokenId) in listGroupedTasks"
        :key="accessTokenId"
        role="list"
        class="flex flex-col divide-y divide-gray-200"
      >
        <h2
          :class="[taskListStore.colorFor(listFor(accessTokenId), 'textColor')]"
          class="text-lg font-bold leading-5 sm:truncate sm:text-xl sm:tracking-tight"
        >
          {{ listFor(accessTokenId).name }}
        </h2>
        <SearchResultTask
          v-for="task in tasks"
          :key="task.id"
          :task="task"
          :grouped-list="listFor(accessTokenId)"
          @patch-server-side-tasks="patchServerSideTasks"
          @subscribe-refetch="debouncedFilterTasks"
        />
      </ul>
      <InfiniteLoading
        v-if="
          signedIn &&
          serverSideTasks.pagy &&
          serverSideTasks.pagy.next &&
          infiniteReady
        "
        @infinite="loadTasks"
      />
    </div>
  </div>
</template>

<script setup>
import SearchResultTask from "@/components/tasks/SearchResultTask.vue";
import { MagnifyingGlassIcon, XMarkIcon } from "@heroicons/vue/20/solid";
import { useUserStore } from "@/stores/user";
import { useTasksStore } from "@/stores/tasks";
import { useTaskListStore } from "@/stores/taskList";
import { storeToRefs } from "pinia";
import { ref, computed, watch, onMounted } from "vue";
import api from "@/router/api";
import _ from "lodash";

const infiniteReady = ref(false);
const showCompleted = ref(false);
const userStore = useUserStore();
const { signedIn } = storeToRefs(userStore);
const taskListStore = useTaskListStore();
const tasksStore = useTasksStore();
const {
  completedLength,
  rawSearchQuery,
  relatedTaskNumericIds,
  effectiveTasks,
  effectiveTaskLists,
} = storeToRefs(tasksStore);
const nothingCompleted = computed(() => completedTasks.value.length === 0);
const serverSideTasks = ref({
  data: [],
  pagy: null,
});
const filterable = computed(
  () =>
    _.trim(rawSearchQuery.value) !== "" ||
    relatedTaskNumericIds.value.length > 0
);

watch(completedLength, async () => {
  filterTasks();
});
watch(relatedTaskNumericIds, async () => {
  if (signedIn.value && filterable.value) {
    debouncedFilterTasks();
  }
});
watch(rawSearchQuery, async () => {
  if (signedIn.value && filterable.value) {
    debouncedFilterTasks();
  }
});

const debouncedFilterTasks = _.debounce(function () {
  filterTasks();
}, 250);
const request = computed(() => _.toLower(rawSearchQuery.value));

onMounted(() => {
  if (signedIn.value && filterable.value) debouncedFilterTasks();
});

function filterTasks() {
  if (
    rawSearchQuery.value.length > 2 ||
    relatedTaskNumericIds.value.length > 0
  ) {
    infiniteReady.value = false;

    api
      .post(`task_searches`, {
        query: request.value,
        relatedTaskIds: relatedTaskNumericIds.value,
      })
      .then(
        (json) => {
          serverSideTasks.value = json.data;
          setTimeout(() => {
            infiniteReady.value = true;
          }, 500);
        },
        (failure) => {
          console.log(failure);
        }
      );
  } else {
    serverSideTasks.value = {
      data: [],
      pagy: null,
    };
  }
}

const filteredTasks = computed(() =>
  serverSideTasks.value.data.length > 0
    ? serverSideTasks.value.data
    : effectiveTasks.value.filter((task) => {
        if (task.name) {
          return task.name
            .toLowerCase()
            .includes(rawSearchQuery.value.toLowerCase());
        } else {
          return false;
        }
      })
);
const visibleTasks = computed(() =>
  showCompleted.value ? filteredTasks.value : incompleteTasks.value
);
const listGroupedTasks = computed(() => {
  return _.groupBy(visibleTasks.value, function (task) {
    return task.accessTokenId || task.taskListName;
  });
});
const incompleteTasks = computed(() =>
  filteredTasks.value.filter((task) => {
    return !task.completedAt;
  })
);
const completedTasks = computed(() =>
  filteredTasks.value.filter((task) => {
    return !!task.completedAt;
  })
);

function patchServerSideTasks(task) {
  const updatedTasks = serverSideTasks.value.data.map((existingTask) => {
    if (existingTask.id === task.id) {
      console.log("task search patch server side", task);
      return task;
    } else {
      return existingTask;
    }
  });

  serverSideTasks.value.data = updatedTasks;
}

function listFor(accessTokenId) {
  return _.find(effectiveTaskLists.value, function (comparableList) {
    if (signedIn.value) {
      return comparableList.accessTokenId == accessTokenId;
    } else {
      return comparableList.name == accessTokenId;
    }
  });
}
function cancel() {
  rawSearchQuery.value = "";
  tasksStore.backToLists();
}
function clearCompleted() {
  const taskIds = completedTasks.value.map((task) => task.id);

  if (signedIn.value) {
    api.post(`completed_task_deletions`, { taskIds }).then(() => {
      tasksStore.deleteCompletedTasks("all", null, taskIds);
    });
  } else {
    tasksStore.deleteCompletedTasks("all", null, taskIds);
  }
}

function cleanUrl(url) {
  return url.replace("/api/v1/", "");
}
const loadTasks = async ($state) => {
  if (signedIn.value && serverSideTasks.value.pagy?.next) {
    const endpoint = cleanUrl(serverSideTasks.value.pagy.next_url);
    try {
      api
        .post(endpoint, {
          query: request.value,
        })
        .then((json) => {
          const { data, pagy } = json.data;

          serverSideTasks.value.data = _.unionBy(
            serverSideTasks.value.data,
            data,
            "id"
          );
          serverSideTasks.value.pagy = pagy;
          if (data.length < 25) $state.complete();
          else {
            $state.loaded();
          }
        });
    } catch (error) {
      $state.error();
    }
  } else {
    $state.complete();
  }
};
</script>
