import { ref } from "vue";
import { defineStore, acceptHMRUpdate } from "pinia";

export const useUnlockerStore = defineStore("unlocker", () => {
  const resetRequired = ref(false);
  const upgradeSuccessful = ref(false);

  return { resetRequired, upgradeSuccessful };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUnlockerStore, import.meta.hot));
}
