<template>
  <div
    class="flex flex-col divide-y divide-gray-200"
    :data-test="`Investment-${focalInvestment.id}-container`"
  >
    <div class="p-2 flex items-center justify-between text-gray-500 text-sm">
      <div
        v-if="maskedPortfolioInvestment && investment.groupInvestmentDataField"
        class="flex items-center space-x-1"
      >
        <DataField
          :data-field="investment.groupInvestmentDataField"
          primary-text-path="fieldContent.dealAction"
          text-classes="text-sm font-medium"
          text-styles=""
          @open-sourced="refetchGroupInvestments"
          @unlocked="refetchGroupInvestments"
          @completed="refetchGroupInvestments"
          @dismiss="refetchGroupInvestments"
        />
      </div>
      <template v-else>
        <div class="flex items-center space-x-1">
          <button
            @click.prevent="tabsExpanded = !tabsExpanded"
            type="button"
            :data-test="`Investment-${focalInvestment.id}-details-header-collapse-icon`"
            class="h-5 w-5 inline-flex justify-center items-center text-sm text-gray-700 hover:text-gray-800"
          >
            <ChevronDownIcon class="h-5 w-5" />
          </button>
          <DataField
            v-if="investment.groupInvestmentDataField"
            :data-field="investment.groupInvestmentDataField"
            :primary-text-prop-override="investmentLabel"
            text-classes="text-sm font-medium"
            text-styles=""
            :skip-post-editing-patch="true"
            @open-sourced="refetchGroupInvestments"
            @unlocked="refetchGroupInvestments"
            @completed="refreshGroup"
            @dismiss="refreshGroup"
          />
          <DataField
            v-else-if="dataFieldInvestmentFieldContent"
            :data-field="investment"
            :primary-text-prop-override="detailedInvestmentLabel"
            text-classes="text-sm font-medium"
            text-styles=""
            @open-sourced="refetchDealBuilder"
            @unlocked="refetchDealBuilder"
            @completed="refetchDealBuilder"
            @dismiss="refetchDealBuilder"
          />
          <a
            v-else
            @click.prevent="tabsExpanded = !tabsExpanded"
            href=""
            class="hover:text-gray-700 font-semibold"
            >{{ investmentLabel }}</a
          >
        </div>
        <div class="flex items-center space-x-1">
          <button
            v-if="canFocusTimeline"
            @click="setTimeline"
            type="button"
            v-tooltip="'Focus in timeline'"
            class="h-5 w-5 inline-flex justify-center items-center text-sm text-gray-500 hover:text-gray-700"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                clip-rule="evenodd"
              />
            </svg>
          </button>
          <span
            v-if="inPlay"
            class="inline-flex items-center justify-center h-3 w-3 rounded-full bg-green-500"
          />
          <span
            v-else-if="dead"
            v-tooltip="'Withdrawn'"
            :data-test="`Investment-${focalInvestment.id}-dead-deal-icon`"
            class="inline-flex items-center justify-center h-5 w-5 text-red-600"
            style="font-size: 16px"
          >
            <i class="fas fa-skull-crossbones" />
          </span>
          <a
            v-if="canFocusTimeline"
            @click.prevent="setTimeline"
            href=""
            v-tooltip="'Focus in timeline'"
            :data-test="`Investment-${focalInvestment.id}-details-header-date-label`"
            class="text-gray-500 hover:text-gray-700"
            >{{ dateLabel }}</a
          >
          <div
            v-else
            :data-test="`Investment-${focalInvestment.id}-details-header-date-label`"
          >
            {{ dateLabel }}
          </div>
          <button
            v-if="canAddToGroup"
            @click="saveToExistingGroup"
            type="button"
            class="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Add
          </button>
          <button
            v-else-if="existingPortfolio"
            @click="openPortfolio"
            type="button"
            v-tooltip.top-end="
              'This investment is part of a portfolio. Click to view the portfolio.'
            "
            class="h-5 w-5 inline-flex justify-center items-center text-sm text-gray-500 hover:text-gray-700"
          >
            <Squares2X2Icon class="h-5 w-5" />
          </button>
          <template v-if="portfoliosAllowed">
            <VDropdown v-if="investmentGroups.length > 0">
              <button
                type="button"
                v-tooltip.top-end="'Add to portfolio...'"
                class="h-5 w-5 inline-flex justify-center items-center text-sm text-gray-500 hover:text-gray-700"
              >
                <SquaresPlusIcon class="h-5 w-5" />
              </button>

              <template #popper>
                <div
                  class="w-64 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="menu-button"
                  tabindex="-1"
                >
                  <div class="py-1" role="none">
                    <a
                      v-for="(group, index) in investmentGroups"
                      :key="index"
                      @click.prevent="addToPortfolio(group)"
                      href=""
                      class="text-gray-700 hover:bg-gray-100 hover:text-gray-900 group flex items-center px-4 py-2 text-sm"
                      role="menuitem"
                      tabindex="-1"
                      id="menu-item-1"
                    >
                      Add to{{
                        _.includes(group.placeholderId, "temp")
                          ? " Draft "
                          : " "
                      }}Portfolio{{
                        _.includes(group.placeholderId, "temp")
                          ? ""
                          : group.placeholderId
                      }}:
                      {{ pluralize("Asset", investmentsLength(group), true) }}
                      <template v-if="group.dealAction"
                        >&nbsp;({{ group.dealAction }})</template
                      >
                    </a>
                    <a
                      @click.prevent="newPortfolio"
                      href=""
                      class="text-gray-700 hover:bg-gray-100 hover:text-gray-900 group flex items-center px-4 py-2 text-sm"
                      role="menuitem"
                      tabindex="-1"
                      id="menu-item-0"
                    >
                      New Portfolio
                    </a>
                  </div>
                </div>
              </template>
            </VDropdown>

            <button
              v-else
              type="button"
              @click="newPortfolio"
              v-tooltip.top-end="'Add to new portfolio'"
              class="h-5 w-5 inline-flex justify-center items-center text-sm text-gray-500 hover:text-gray-700"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM14 11a1 1 0 011 1v1h1a1 1 0 110 2h-1v1a1 1 0 11-2 0v-1h-1a1 1 0 110-2h1v-1a1 1 0 011-1z"
                />
              </svg>
            </button>
          </template>
          <button
            v-else-if="!alreadyInDiagram"
            @click="addAssetToDealBuilder"
            type="button"
            v-tooltip="'Open asset in Deal Builder'"
            class="h-5 w-5 inline-flex justify-center items-center text-sm text-gray-700 hover:text-gray-800"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM14 11a1 1 0 011 1v1h1a1 1 0 110 2h-1v1a1 1 0 11-2 0v-1h-1a1 1 0 110-2h1v-1a1 1 0 011-1z"
              />
            </svg>
          </button>
          <button
            v-if="discardable"
            @click="removeInvestmentConfirmation"
            type="button"
            v-tooltip="'Discard'"
            :data-test="`discard-investment-${focalInvestment.id}-from-group-button`"
            class="h-5 w-5 p-1 inline-flex justify-center items-center text-sm text-gray-500 hover:text-gray-700"
          >
            <i class="fas fa-times" />
          </button>
        </div>
      </template>
    </div>
    <InvestmentDetailsTabs
      :asset-object="assetObject"
      :investment="investment"
      :investment-group-id="investmentGroupId"
      :expanded-override="tabsExpanded"
    />
  </div>
</template>

<script setup>
import moment from "moment";
import DataField from "@/components/crowdsourcing/DataField.vue";
import InvestmentDetailsTabs from "@/components/deal-builder/InvestmentDetailsTabs.vue";
import { useCrowdsourcedChangeGroupStore } from "@/stores/crowdsourcedChangeGroup";
import { useDataLicensesChannelStore } from "@/stores/dataLicensesChannel";
import { usePropertyDiagramStore } from "@/stores/propertyDiagram";
import { usePropertyFieldsStore } from "@/stores/propertyFields";
import { useDealBuilderStore } from "@/stores/dealBuilder";
import { useModalStore } from "@/stores/modal";
import { useTimeTravelStore } from "@/stores/timeTravel";
import { storeToRefs } from "pinia";
import { ref, computed, watch, nextTick, onMounted } from "vue";
import VueScrollTo from "vue-scrollto";
import _ from "lodash";
import api from "@/router/api";
import pluralize from "pluralize";
import decoratingAndFieldKey from "@/components/crowdsourcing/decoratingAndFieldKey";
import propertyAssetLabel from "@/assets/investmentLabel";
import investmentStates from "@/components/deal-builder/investmentStates";
import { useRoute, useRouter } from "vue-router";
import {
  ChevronDownIcon,
  Squares2X2Icon,
  SquaresPlusIcon,
} from "@heroicons/vue/20/solid";

const props = defineProps(["assetObject", "investment", "investmentGroupId"]);

const dealBuilderStore = useDealBuilderStore();
const { dealBuilder, refetchDealBuilderEditor } = storeToRefs(dealBuilderStore);
const propertyDiagramStore = usePropertyDiagramStore();
const { propertyDiagramPropertyIds, selectedTimelineEvent } =
  storeToRefs(propertyDiagramStore);
const timeTravelStore = useTimeTravelStore();
const { timeTravelTo } = storeToRefs(timeTravelStore);
const changeGroupStore = useCrowdsourcedChangeGroupStore();
const { changeGroupId } = storeToRefs(changeGroupStore);
const dataLicensesChannelStore = useDataLicensesChannelStore();
const { dataLicensesChannelDataQueue } = storeToRefs(dataLicensesChannelStore);
const propertyFieldsStore = usePropertyFieldsStore();
const modalStore = useModalStore();
const { confirmationPayload } = storeToRefs(modalStore);

const tabsExpanded = ref(false);
const propertyDataField = computed(() => {
  return propertyFieldsStore.getPropertyField(propertyId.value);
});

const assets = computed(() => {
  return _.get(dealBuilder.value, "assets", {});
});
const investmentGroups = computed(() => {
  return _.get(dealBuilder.value, "investmentGroups", []);
});
const dataFieldInvestmentFieldContent = computed(() => {
  return _.get(props.investment, "fieldContent");
});
const focalInvestment = computed(() => {
  const dealBuilderExistingInvestment = _.get(
    props.investment,
    "existingInvestment",
  );

  return (
    dataFieldInvestmentFieldContent.value ||
    dealBuilderExistingInvestment ||
    props.investment
  );
});
const state = computed(() => {
  return _.get(focalInvestment.value, "state");
});
const propertyId = computed(() => {
  return (
    _.get(focalInvestment.value, "topLevelPropertyId") ||
    _.get(
      focalInvestment.value,
      "capitalStackTopLevelAsset.joiningContentId",
    ) ||
    _.get(
      focalInvestment.value,
      "capitalStackTopLevelAsset.fieldContent.propertyId",
    )
  );
});
const inPlay = computed(() => {
  return state.value && !_.includes(["closed", "withdrawn"], state.value);
});
const dead = computed(() => {
  return state.value && state.value === "withdrawn";
});
const portfolioId = computed(() => {
  return (
    _.get(focalInvestment.value, "portfolioId") ||
    _.get(matchingInvestmentGroup.value, "placeholderId")
  );
});
const existingPortfolio = computed(
  () => !props.investmentGroupId && portfolioId.value,
);
const portfolioDealAction = computed(() => {
  return _.get(focalInvestment.value, "portfolioDealAction");
});
const portfoliosAllowed = computed(() => {
  return (
    !props.investmentGroupId &&
    existingInvestmentId.value &&
    _.isNil(portfolioId.value)
  );
});
const canAddToGroup = computed(() => {
  return (
    props.investmentGroupId &&
    existingInvestmentId.value &&
    !_.get(focalInvestment.value, "portfolioId") &&
    _.get(matchingInvestmentGroup.value, "id")
  );
});
const maskedPortfolioInvestment = computed(() => {
  return props.investmentGroupId && focalInvestment.value?.masked;
});
const alreadyInDiagram = computed(() => {
  return _.find(assets.value, function (asset, assetKey) {
    const dataField = _.get(
      focalInvestment.value,
      "asset",
      focalInvestment.value.capitalStackTopLevelAsset,
    );

    if (dataField) {
      return assetKey === decoratingAndFieldKey(dataField);
    } else {
      return false;
    }
  });
});
const discardable = computed(() => {
  if (props.investmentGroupId) {
    return true;
  } else {
    return !existingInvestmentId.value;
  }
});
const canFocusTimeline = computed(() => {
  return (
    !props.investmentGroupId &&
    existingInvestmentId.value &&
    _.get(focalInvestment.value, "date")
  );
});
const existingInvestmentId = computed(() => {
  return (
    _.get(focalInvestment.value, "id") ||
    _.get(focalInvestment.value, "fieldContentId")
  );
});
const dealBuilderCombinedKey = computed(() => {
  if (
    focalInvestment.value.capitalStackTopLevelAsset &&
    focalInvestment.value.investmentFieldContent
  ) {
    const assetKey = decoratingAndFieldKey(
      focalInvestment.value.capitalStackTopLevelAsset,
    );
    const investmentKey = decoratingAndFieldKey(
      focalInvestment.value.investmentFieldContent,
    );

    return `${assetKey}_${investmentKey}`;
  } else {
    return _.get(props.investment, "temporaryId");
  }
});
const loanSeniority = computed(() => {
  return (
    _.get(focalInvestment.value, "loanSeniority") ||
    _.get(props.investment, "existingInvestment.loanSeniority")
  );
});
const asset = computed(
  () =>
    _.get(focalInvestment.value, "asset") ||
    _.get(focalInvestment.value, "capitalStackTopLevelAsset"),
);
const assetLabel = computed(() => {
  return propertyAssetLabel(
    asset.value,
    propertyDataField.value,
    loanSeniority.value,
  );
});
const assetTypeAlias = computed(() => {
  switch (focalInvestment.value.investmentType) {
    case "OwnershipInterest": {
      if (focalInvestment.value.assetType === "Loan") {
        if (props.assetObject?.dataField?.decoratingContentType === "Loan") {
          return "Equity";
        } else {
          return "Loan";
        }
      } else {
        return "Equity";
      }
    }
    case "Loan":
      return "Loan";
    default:
      return "Unknown Asset";
  }
});
const investmentLabel = computed(() => {
  if (assetLabel.value) {
    return assetLabel.value;
  } else {
    return `${assetTypeAlias.value} ${focalInvestment.value.dealActionAlias}`;
  }
});
const detailedInvestmentLabel = computed(() => {
  if (loanSeniority.value) {
    return `${_.startCase(loanSeniority.value)} ${assetTypeAlias.value} ${
      focalInvestment.value.dealActionAlias
    }`;
  } else {
    return `${assetTypeAlias.value} ${focalInvestment.value.dealActionAlias}`;
  }
});
const dateLabel = computed(() => {
  switch (focalInvestment.value.state) {
    case "withdrawn":
    case "live":
    case "under_contract":
      return _.find(investmentStates, {
        value: focalInvestment.value.state,
      }).statusLabel;
    case "closed":
    default: {
      if (focalInvestment.value.date) {
        return moment(focalInvestment.value.date).format("MMM YYYY");
      } else {
        return "Undated";
      }
    }
  }
});
const ownershipInterestIds = computed(() => {
  return props.assetObject.ownershipInterests.map(
    (dataField) => dataField.fieldContentId,
  );
});
const matchingInvestmentGroup = computed(() => {
  return _.find(investmentGroups.value, function (group) {
    return _.find(group.investments, function (investment) {
      const localInvestmentId =
        _.get(props.investment, "existingInvestment.id") ||
        _.get(props.investment, "id") ||
        _.get(props.investment, "temporaryId");

      return (
        group.id === portfolioId.value ||
        _.get(investment, "existingInvestment.id") === localInvestmentId
      );
    });
  });
});

watch(existingInvestmentId, () => {
  debouncedInvestmentFetch();
});
watch(dataFieldInvestmentFieldContent, () => {
  debouncedInvestmentFetch();
});
watch(dataLicensesChannelDataQueue, () => {
  const data = _.last(dataLicensesChannelDataQueue.value);
  console.log("investment detail licenses channel watcher", data);

  if (data.dataFieldIds && props.investment?.groupInvestmentDataField) {
    refetchGroupInvestments();
  }
});
watch(propertyId, (val, oldVal) => {
  if (val !== oldVal) {
    fetchPropertyDataField();
  }
});
onMounted(() => {
  fetchPropertyDataField();
  debouncedInvestmentFetch();
});

function addAssetToDealBuilder() {
  const dataField = _.get(focalInvestment.value, "asset");

  if (dataField) {
    const assetKey = decoratingAndFieldKey(dataField);
    const assetObject = {
      dataField,
      investments: [],
      expanded: true,
      ownershipInterests: [],
    };

    dealBuilderStore.addDealBuilderAsset({
      assetObject,
      assetKey,
    });
    dealBuilderStore.collapseAllAssetsExcept({ assetKey });

    if (dataField.joiningContentType === "Property") {
      if (
        !_.includes(
          propertyDiagramPropertyIds.value,
          dataField.joiningContentId,
        )
      ) {
        propertyDiagramStore.addPropertyToDiagram(dataField.joiningContentId);
      }
    }
  }
}
function refetchDealBuilder() {
  const refetchKey = decoratingAndFieldKey(props.assetObject.dataField);

  dealBuilderStore.clearDealBuilder(false);
  dealBuilderStore.resetDealBuilderInvestments();
  refetchDealBuilderEditor.value = refetchKey;
  timeTravelStore.triggerRefetch();
}
function removeInvestmentConfirmation() {
  if (
    existingInvestmentId.value &&
    _.get(focalInvestment.value, "portfolioId")
  ) {
    confirmationPayload.value = {
      title: "Remove investment",
      message:
        "Are you sure you want to remove this investment from the portfolio?",
      affirmText: "Remove",
      affirmCallback: removeInvestment,
    };
  } else {
    removeInvestmentLocally();
  }
}
async function removeInvestment() {
  removeInvestmentFromDatabase().then(() => {
    refreshGroup();
  });
}
function refreshGroup() {
  dealBuilderStore.refreshInvestmentGroup({
    groupId: props.investmentGroupId,
    dealAction: _.get(matchingInvestmentGroup.value, "dealAction"),
    isExistingGroup: !!_.get(matchingInvestmentGroup.value, "id"),
  });
}
async function removeInvestmentFromDatabase() {
  const apiRequestFunc = () =>
    api.delete(`investment_group_investments/${existingInvestmentId.value}`);
  const successCallback = () => {
    // this.$store.dispatch("flash", "Investment dismissed from portfolio.");
  };
  const failureCallback = () => {};
  await changeGroupStore.originateData(
    apiRequestFunc,
    successCallback,
    failureCallback,
  );
}
function removeInvestmentLocally() {
  clearInvestmentMetadata();
  dealBuilderStore.removeDealBuilderInvestment({
    investmentKey: dealBuilderCombinedKey.value,
    dealAction: focalInvestment.value.dealAction,
  });
}
function clearInvestmentMetadata() {
  const loanClearingActions = [
    "foreclosureSellOutright",
    "sellOutright",
    "sellEntireInterest",
    "sellPartialInterest",
    "sellMultipleInterests",
  ];
  const shouldClearLoans =
    _.intersection(loanClearingActions, [focalInvestment.value.dealAction])
      .length > 0;

  if (shouldClearLoans && focalInvestment.value.capitalStackTopLevelAsset) {
    dealBuilderStore.clearDealBuilderAssetManipulatedLoans({
      assetKey: decoratingAndFieldKey(
        focalInvestment.value.capitalStackTopLevelAsset,
      ),
    });
    dealBuilderStore.clearDealBuilderAssetIndividualOwnershipInterests({
      assetKey: decoratingAndFieldKey(
        focalInvestment.value.capitalStackTopLevelAsset,
      ),
    });
  }
}
function investmentsLength(investmentGroup) {
  return _.size(investmentGroup.investments);
}
function newPortfolio() {
  dealBuilderStore.addDealBuilderInvestmentGroup({
    existingInvestment: focalInvestment.value,
    assetDataField: props.assetObject.dataField,
    investmentFieldContent: focalInvestment.value.asset,
    ownershipInterestIds: ownershipInterestIds.value,
    dealAction: focalInvestment.value.dealAction,
  });

  if (matchingInvestmentGroup.value) {
    dealBuilderStore.collapsePortfolioAssets({
      groupId:
        matchingInvestmentGroup.value.id ||
        matchingInvestmentGroup.value.placeholderId,
    });
  }
}
async function saveToExistingGroup() {
  const payload = {
    investmentGroupId: matchingInvestmentGroup.value.id,
    investmentId: existingInvestmentId.value,
    changeGroupId: changeGroupId.value,
  };

  const apiRequestFunc = () =>
    api.post(`investment_group_investments`, payload);
  const successCallback = () => {
    refreshGroup();
  };
  const failureCallback = () => {};
  await changeGroupStore.originateData(
    apiRequestFunc,
    successCallback,
    failureCallback,
  );
}
function addToPortfolio(investmentGroup) {
  dealBuilderStore.addDealBuilderInvestmentToGroup({
    existingInvestment: focalInvestment.value,
    assetDataField: props.assetObject.dataField,
    investmentFieldContent: focalInvestment.value.asset,
    ownershipInterestIds: ownershipInterestIds.value,
    investmentGroupPlaceholderId: investmentGroup.placeholderId,
  });
  dealBuilderStore.collapsePortfolioAssets({
    groupId: investmentGroup.id || investmentGroup.placeholderId,
  });
  dealBuilderStore.setDealBuilderInvestmentGroupExpanded({
    groupId: investmentGroup.id || investmentGroup.placeholderId,
    expanded: true,
  });
}
function focusInvestmentGroup(investmentGroup) {
  dealBuilderStore.collapsePortfolioAssets({
    groupId: investmentGroup.id || investmentGroup.placeholderId,
  });
  dealBuilderStore.setDealBuilderInvestmentGroupExpanded({
    groupId: investmentGroup.id || investmentGroup.placeholderId,
    expanded: true,
  });
}
async function openPortfolio() {
  if (matchingInvestmentGroup.value) {
    focusInvestmentGroup(matchingInvestmentGroup.value);
  } else {
    dealBuilderStore.addDealBuilderInvestmentGroup({
      existingGroupId: portfolioId.value,
      dealAction: portfolioDealAction.value,
    });
  }

  // dealBuilderStore.collapseAllAssetsExcept({ assetKey: null });
  await nextTick();
  VueScrollTo.scrollTo("#investment-portfolios-list", 100, {
    container: "#lists-panel-container",
  });
}
function fetchPropertyDataField() {
  if (
    props.investmentGroupId &&
    propertyId.value &&
    !maskedPortfolioInvestment.value
  ) {
    propertyFieldsStore.fetchPropertyDataField(propertyId.value);
  }
}

const debouncedInvestmentFetch = _.debounce(function () {
  fetchInvestment();
}, 1000);

function fetchInvestment() {
  const fetchRequestKey = `investments_${existingInvestmentId.value}`;

  if (
    existingInvestmentId.value &&
    !dataFieldInvestmentFieldContent.value &&
    props.assetObject?.dataField
  ) {
    if (dealBuilderStore.alreadyFetched(fetchRequestKey)) {
      dealBuilderStore.updateDealBuilderAssetInvestments({
        assetKey: decoratingAndFieldKey(props.assetObject.dataField),
        investments: dealBuilderStore.alreadyFetchedFieldsFor(fetchRequestKey),
      });
    } else {
      api.get(`investments/${existingInvestmentId.value}`).then((json) => {
        dealBuilderStore.interceptablePatch([json.data], fetchRequestKey);
        dealBuilderStore.updateDealBuilderAssetInvestments({
          assetKey: decoratingAndFieldKey(props.assetObject.dataField),
          investments: [json.data],
        });
      });
    }
  }
}

const router = useRouter();
const route = useRoute();

function setTimeline() {
  if (focalInvestment.value.date) {
    timeTravelTo.value = focalInvestment.value.date;

    selectedTimelineEvent.value = {
      eventType: "Investment",
      event: focalInvestment.value,
    };

    router.push({
      name: route.name,
      query: {
        ...route.query,
        asOf: moment(focalInvestment.value.date).endOf("month").valueOf(),
      },
    });
  }
}

async function refetchGroupInvestments() {
  await dealBuilderStore.fetchInvestmentGroupInvestments(
    props.investmentGroupId,
  );
  fetchPropertyDataField();
}
</script>
