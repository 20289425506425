<template>
  <a
    @click.prevent="logIn('SignIn')"
    href=""
    class="hidden md:inline-flex whitespace-nowrap text-sm leading-4 p-2 font-medium rounded-md"
    :class="secondaryButtonStyling"
    data-test="sign-in-button"
    >Sign in</a
  >
  <a
    @click.prevent="logIn('Register')"
    href=""
    class="hidden md:inline-flex whitespace-nowrap rounded-md p-2 text-sm leading-4 font-medium"
    :class="primaryButtonStyling"
    data-test="collectible-cards-sign-up-button"
    >Sign up</a
  >
  <VDropdown class="md:hidden">
    <button
      class="inline-flex items-center justify-center rounded-md p-2"
      :class="menuButtonStyling"
    >
      <span class="sr-only">Open menu</span>
      <Bars3Icon class="h-6 w-6" aria-hidden="true" />
    </button>

    <template #popper>
      <div
        class="divide-y-2 divide-gray-50 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5"
      >
        <div class="px-5 pt-5 pb-6">
          <div class="flex items-center justify-between">
            <div>
              <caption class="sr-only">
                Tower Hunt
              </caption>
              <img
                class="h-10 w-auto"
                src="https://assets.towerhunt.com/site/Transparent_T0.png"
                alt="Tower Hunt"
                data-test="th-logo"
              />
            </div>
            <div class="-mr-2">
              <button
                v-close-popper
                class="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
              >
                <span class="sr-only">Close menu</span>
                <XMarkIcon class="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
          </div>
          <div class="mt-6">
            <nav class="grid gap-y-8">
              <a
                v-for="page in mobilePages"
                :key="page.name"
                @click.prevent="page.handler"
                :href="page.href"
                class="-m-3 flex items-center rounded-md p-3 hover:bg-gray-50"
              >
                <component
                  :is="page.icon"
                  class="h-6 w-6 flex-shrink-0 text-indigo-600"
                  aria-hidden="true"
                />
                <span class="ml-3 text-base font-medium text-gray-900">{{
                  page.name
                }}</span>
              </a>
            </nav>
          </div>
        </div>
        <div class="space-y-6 py-6 px-5">
          <div>
            <a
              @click.prevent="logIn('Register')"
              href=""
              class="flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700"
              >Sign up</a
            >
            <p class="mt-6 text-center text-base font-medium text-gray-500">
              Existing customer?
              {{ " " }}
              <a
                @click.prevent="logIn('SignIn')"
                href=""
                class="text-indigo-600 hover:text-indigo-500"
                >Sign in</a
              >
            </p>
          </div>
        </div>
      </div>
    </template>
  </VDropdown>
</template>

<script setup>
import { Bars3Icon, XMarkIcon } from "@heroicons/vue/24/outline";
import { useRouter, useRoute } from "vue-router";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { useSecondaryPanelStore } from "@/stores/secondaryPanel";
import { useMobileNavigationStore } from "@/stores/mobileNavigation";
import { useDocumentationStore } from "@/stores/documentation";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import _ from "lodash";

const layoutStore = useWorkspaceLayoutStore();
const { isDesktop } = storeToRefs(layoutStore);
const secondaryPanelStore = useSecondaryPanelStore();
const mobileNavigationStore = useMobileNavigationStore();
const { mobilePages } = storeToRefs(mobileNavigationStore);
const documentationStore = useDocumentationStore();
const { helpViews } = storeToRefs(documentationStore);
const router = useRouter();
const route = useRoute();
const lightRoute = computed(() => {
  return helpViews.value || legalViews.value;
});
const legalViews = computed(() =>
  _.includes(
    ["TermsOfService", "PrivacyPolicy", "LegalCenter", "LegalShell"],
    route.name,
  ),
);

const primaryButtonStyling = computed(() => {
  if (lightRoute.value) {
    return "border border-transparent bg-indigo-700 text-white hover:bg-indigo-900";
  } else {
    return "border border-transparent bg-white text-gray-600 hover:bg-gray-50";
  }
});
const secondaryButtonStyling = computed(() => {
  if (lightRoute.value) {
    return "border border-gray-500 bg-white text-gray-500 hover:bg-gray-50";
  } else {
    return "border border-transparent bg-gray-700 text-gray-300 hover:bg-opacity-75";
  }
});
const menuButtonStyling = computed(() => {
  if (lightRoute.value) {
    return "border border-gray-300 bg-white text-gray-500 hover:bg-opacity-75 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-500";
  } else {
    return "border border-transparent bg-gray-700 text-gray-300 hover:bg-opacity-75 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-700";
  }
});

function logIn(destination) {
  if (
    isDesktop.value &&
    !_.includes(
      [
        "HelpCenter",
        "HelpCategories",
        "HelpArticle",
        "WhyTowerHunt",
        "TermsOfService",
        "PrivacyPolicy",
        "LegalCenter",
        "LegalShell",
      ],
      route.name,
    )
  ) {
    secondaryPanelStore.setAuthenticate({ name: destination });
    router.push({
      name: route.name,
      query: {
        ...route.query,
        horizontalTab: "Authenticate",
        verticalTab: destination,
      },
    });
  } else {
    router.push({ name: destination });
  }
}
</script>
