import { ref } from "vue";
import { defineStore, acceptHMRUpdate } from "pinia";
import _ from "lodash";

export const useAvailableValidationsChannelStore = defineStore(
  "availableValidationsChannel",
  () => {
    const AvailableValidationsChannel = ref(null);
    const availableValidationsChannelDataQueue = ref([]);
    const activeValidationChangeGroupId = ref(null);

    function pushAndTrim(data) {
      availableValidationsChannelDataQueue.value.push(data);
      availableValidationsChannelDataQueue.value = _.takeRight(
        availableValidationsChannelDataQueue.value,
        3
      );
    }

    return {
      activeValidationChangeGroupId,
      AvailableValidationsChannel,
      availableValidationsChannelDataQueue,
      pushAndTrim,
    };
  }
);

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useAvailableValidationsChannelStore, import.meta.hot)
  );
}
