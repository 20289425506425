import { markRaw, ref } from "vue";
import { useUserStore } from "@/stores/user";
import { useModalStore } from "@/stores/modal";
import { useUnlockerStore } from "@/stores/unlocker";
import { useCrowdsourcedChangeGroupStore } from "@/stores/crowdsourcedChangeGroup";
import { useSecondaryPanelStore } from "@/stores/secondaryPanel";
import { defineStore, acceptHMRUpdate, storeToRefs } from "pinia";
import api from "@/router/api";
import subscribeInterceptor from "@/components/crowdsourcing/subscribeInterceptor";

export const useProveForValueStore = defineStore("proveForValue", () => {
  const secondaryPanelStore = useSecondaryPanelStore();
  const userStore = useUserStore();
  const { signedIn, emailUnverified } = storeToRefs(userStore);
  const changeGroupStore = useCrowdsourcedChangeGroupStore();
  const { originatingData } = storeToRefs(changeGroupStore);

  const proofComponent = ref(null);
  const proofProps = ref({});
  const proofRefetchTrigger = ref(null);

  function setRefetchTrigger(newVal) {
    proofRefetchTrigger.value = newVal;

    setTimeout(() => {
      proofRefetchTrigger.value = null;
    }, 1000);
  }

  function setProofComponent(component, props) {
    resetProofComponent();
    proofComponent.value = markRaw(component);
    proofProps.value = props;
  }

  function resetProofComponent() {
    proofComponent.value = null;
    proofProps.value = {};
  }

  const modalStore = useModalStore();
  const { modalPayload } = storeToRefs(modalStore);
  const unlockerStore = useUnlockerStore();
  const { upgradeSuccessful } = storeToRefs(unlockerStore);

  async function submitProof(challengeDataField, payload) {
    if (emailUnverified.value) {
      originatingData.value = false;
      userStore.promptToVerify();
      return;
    } else if (challengeDataField && payload) {
      if (signedIn.value) {
        const successCallback = (json) => {
          return json;
        };

        const response = await subscribeInterceptor({
          apiRequestFunc: () =>
            api.post(
              `data_field_provings/${challengeDataField.localId}`,
              payload,
            ),
          successCallback,
          modalPayloadRef: modalPayload,
          upgradeSuccessfulRef: upgradeSuccessful,
          afterSubscribe: "apiRequest",
          context: "validating",
        });

        return response;
      } else {
        originatingData.value = false;
        secondaryPanelStore.promptToRegister();
        return;
      }
    } else {
      return;
    }
  }

  return {
    proofComponent,
    proofProps,
    proofRefetchTrigger,
    submitProof,
    setProofComponent,
    resetProofComponent,
    setRefetchTrigger,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useProveForValueStore, import.meta.hot),
  );
}
