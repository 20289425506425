<template>
  <div
    @click="emit('clicked')"
    :data-test="testKey"
    :class="
      dataField
        ? `flex flex-col flex-grow items-start justify-start truncate pointer-events-auto my-0.5 mx-2 rounded-lg ${outerStyling}`
        : 'mx-2 my-0.5'
    "
  >
    <VMenu
      v-if="dataField"
      :disabled="editingMode"
      class="w-full h-2"
      theme="diagram-popup"
    >
      <div class="h-full w-full" />
      <template #popper>
        <DataFieldInfoPopup :data-field="dataField" />
      </template>
    </VMenu>
    <div
      v-if="dataField && floorAreas.length > 0"
      class="w-full h-full flex flex-col divide-y-2 divide-white"
    >
      <VMenu
        :disabled="editingMode"
        theme="diagram-popup"
        v-for="(floorDataField, index) in floorAreas"
        :key="`air-right-floor-area-${index}`"
        :test-key="`air-right-floor-area-${index}`"
      >
        <NoLevelFloorAreaBlock
          :floor-area-data-field="floorDataField"
          :color-style="
            floorAreaColorStyle(floorDataField, propertyDiagramSelected)
          "
          :clickable="true"
          @clicked="floorlessClickHandler"
          @refetch="emit('refetch')"
        />
        <template #popper>
          <DataFieldInfoPopup :data-field="floorDataField" />
        </template>
      </VMenu>
    </div>
  </div>
</template>

<script setup>
import NoLevelFloorAreaBlock from "@/components/property-diagram/NoLevelFloorAreaBlock.vue";
import DataFieldInfoPopup from "@/components/crowdsourcing/DataFieldInfoPopup.vue";
import { floorAreaColorStyle } from "@/components/property-diagram/floorAreaColorStyles";
import { usePropertyDiagramStore } from "@/stores/propertyDiagram";
import { usePropertyDiagramContentSelectionStore } from "@/stores/propertyDiagramContentSelection";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import _ from "lodash";

const props = defineProps([
  "dataField",
  "floorAreas",
  "backgroundStyle",
  "testKey",
]);
const emit = defineEmits(["clicked", "refetch"]);
const propertyDiagramStore = usePropertyDiagramStore();
const { propertyDiagramSelected, editingMode } =
  storeToRefs(propertyDiagramStore);
const diagramContentSelectionStore = usePropertyDiagramContentSelectionStore();

const airRight = computed(() => {
  return _.get(props.dataField, "fieldContent", null);
});
const outerStyling = computed(() => {
  return (
    props.backgroundStyle ||
    "text-gray-500 font-medium border-2 border-dashed border-gray-300 bg-cyan-300 hover:border-gray-400"
  );
});

function floorlessClickHandler({ dataField, recordType }) {
  diagramContentSelectionStore.singleClickSelect({
    propertyId: props.propertyId,
    data: _.merge(
      {},
      { dataField, recordType },
      {
        propertyRight: airRight.value,
      },
    ),
  });
}
</script>
