<template>
  <div>
    <label
      v-if="query !== '' && fetchedCitations.length > 0"
      class="mt-2 block text-xs font-medium text-gray-700"
      >{{ pluralize("Result", fetchedCitations?.length, true) }}</label
    >
    <div
      class="divide-y divide-gray-100 overflow-hidden rounded-lg bg-white ring-1 ring-black ring-opacity-5"
    >
      <Combobox @update:modelValue="onSelect">
        <div class="relative">
          <MagnifyingGlassIcon
            class="pointer-events-none absolute left-4 top-3 h-4 w-4 text-gray-400"
            aria-hidden="true"
          />
          <ComboboxInput
            v-observe-visibility="{ callback: focusInput, once: true }"
            :id="`citations-search`"
            class="h-10 w-full border-0 border-gray-200 bg-transparent pl-11 pr-4 text-gray-900 placeholder:text-gray-500 focus:ring-0 text-xs"
            placeholder="Search your citations..."
            @change="query = $event.target.value"
          />
        </div>

        <ComboboxOptions
          v-if="fetchedCitations.length > 0"
          static
          class="max-h-72 scroll-py-2 overflow-y-auto py-2 text-xs text-gray-800"
        >
          <ComboboxOption
            v-for="citationField in fetchedCitations"
            :key="citationField.localId"
            :value="citationField"
            as="template"
            v-slot="{ active }"
          >
            <li
              :class="['cursor-default select-none', active && 'bg-gray-200']"
            >
              <CitationDetails
                class="w-full"
                :citation-field="citationField"
                :citation-scope="'visible'"
                :removable="false"
                :compact="true"
              />
            </li>
          </ComboboxOption>
        </ComboboxOptions>

        <p
          v-if="query !== '' && fetchedCitations.length === 0"
          class="p-4 text-xs text-gray-500"
        >
          No citations found.
        </p>
      </Combobox>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from "vue";
import { MagnifyingGlassIcon } from "@heroicons/vue/20/solid";
import {
  Combobox,
  ComboboxInput,
  ComboboxOptions,
  ComboboxOption,
} from "@headlessui/vue";
import CitationDetails from "@/components/crowdsourcing/CitationDetails.vue";
import api from "@/router/api";
import _ from "lodash";
import moment from "moment";
import pluralize from "pluralize";

const emit = defineEmits(["set-citation"]);

const citationFetchTime = ref(null);
const fetchedCitations = ref([]);
const query = ref("");

watch(query, async () => {
  if (_.trim(query.value) !== "") {
    debouncedFilterCitations();
  }
});

function focusInput(isVisible) {
  if (isVisible) {
    setTimeout(() => {
      selectInput();
    }, 100);
  }
}

const debouncedFilterCitations = _.debounce(function () {
  filterCitations();
}, 250);

function filterCitations() {
  if (query.value.length > 0) {
    const fetchTime = moment().valueOf();
    citationFetchTime.value = fetchTime;
    const request =
      query.value === "" || query.value === null
        ? null
        : _.toLower(query.value);

    api.post(`citation_searches`, { query: request }).then(
      (json) => {
        if (fetchTime === citationFetchTime.value) {
          fetchedCitations.value = json.data;
          citationFetchTime.value = null;
        }
      },
      (failure) => {
        console.log(failure);
        // this.$store.dispatch("flash", "Invalid search");
      },
    );
  } else {
    fetchedCitations.value = [];
  }
}

function onSelect(citationField) {
  emit("set-citation", { citationField });
}

function selectInput() {
  document.getElementById(`citations-search`)?.focus();
}
</script>
