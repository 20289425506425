<template>
  <VMenu>
    <button
      type="button"
      @click="handleClick"
      @mouseenter="handleHover"
      @mouseleave="emit('clear-marker-hover')"
      :data-test="`${context}-timeline-event-${event.fieldContentType}-${event.fieldContentId}`"
      :class="`flex-shrink-0 rounded-full w-3 h-3 ${markerColor}`"
    />
    <template #popper>
      <div class="p-1 rounded-md bg-gray-300/75">
        <HighlightCardContainer
          v-if="event && cardDisplayable"
          :data-field="event"
          :context="`${context}-timeline`"
          @loading-complete="handleFallback"
        />
        <div v-else class="text-xs font-semibold">
          {{ date ? "" : "Undated" }}
          {{
            _.startCase(
              event.fieldContentSubType ||
                event.fieldContentType ||
                event.decoratingContentType,
            )
          }}
          {{ dateStateLabel }}
        </div>
      </div>
    </template>
  </VMenu>
</template>

<script setup>
import HighlightCardContainer from "@/components/collectible-cards/HighlightCardContainer.vue";
import _ from "lodash";
import moment from "moment";
import { computed, ref } from "vue";

const props = defineProps(["event", "markerColor", "context", "date"]);
const emit = defineEmits([
  "clear-marker-hover",
  "debounce-pulse",
  "fallback-click",
]);

const cardDisplayable = ref(true);
const dateStateLabel = computed(() => {
  if (
    props.event.decoratingContentType &&
    props.event.fieldName &&
    props.date
  ) {
    if (props.event.fieldName === "loan_term_years") {
      return `Implied Maturity ${moment(props.date).format("MMM YYYY")}`;
    } else {
      return `${_.startCase(
        _.replace(props.event.fieldName, "_date", ""),
      )} ${moment(props.date).format("MMM YYYY")}`;
    }
  } else if (props.event.fieldContentType === "Loan") {
    return `Retired ${moment(props.date).format("MMM YYYY")}`;
  } else {
    return "";
  }
});

function handleHover() {
  if (props.event?.localId) emit("debounce-pulse");
}

function handleFallback({ displayableBool }) {
  cardDisplayable.value = displayableBool;
}

function handleClick() {
  if (!cardDisplayable.value) emit("fallback-click");
}
</script>
