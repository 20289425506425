<template>
  <div :class="{ 'min-w-80': !compact }" class="flex flex-col items-center">
    <h3
      v-if="heading"
      class="my-6 mx-4 text-center text-xl sm:text-3xl leading-9 font-bold text-gray-900"
    >
      {{ heading }}
    </h3>
    <div
      @drop.prevent="handleDragDropFiles"
      @dragover.prevent="hover"
      class="relative overflow-x-hidden flex justify-center border-2 border-gray-300 border-dashed rounded-md"
      :class="{
        'p-2': compact,
        'p-4': !compact,
        'border-indigo-500': dragging,
        'border-3': dragging,
        'w-full': fullWidth,
        'w-4/5 max-w-lg': !fullWidth,
        'mb-8': marginBottom,
      }"
      data-test="upload-files-drag-drop"
    >
      <div
        class="absolute top-0 left-0 bg-indigo-100 h-full overflow-hidden direct-upload__progress"
        :style="progressWidth"
      />
      <div class="z-30 w-full text-center">
        <button
          @click="launchFilePicker"
          :class="{ 'h-6 w-6': compact, 'h-12 w-12': !compact }"
          class="mx-auto text-gray-400 hover:text-gray-500"
        >
          <svg
            class=""
            :class="{ 'text-indigo-500': dragging }"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 48 48"
          >
            <path
              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
        <p v-if="!compact" class="mt-1 text-sm text-gray-600">
          <button
            @click="launchFilePicker"
            type="button"
            class="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline"
            data-test="file-picker-button"
          >
            Upload <template v-if="multiple">files</template
            ><template v-else>a file</template>
          </button>
          or drag and drop
        </p>
        <p v-if="!compact" class="mt-1 text-xs text-gray-500">
          {{ fileTypeLabels }} up to {{ fileSizeLimit }}MB<template
            v-if="multiple"
          >
            each</template
          >
        </p>
        <div
          v-if="!compact"
          class="mt-4 flex items-center space-x-1 font-medium text-xs text-red-400"
        >
          <div class="flex-shrink-0">
            <ExclamationTriangleIcon
              class="h-5 w-5 text-red-400"
              aria-hidden="true"
            />
          </div>
          <span> Only upload files that you have permission to use. </span>
        </div>
        <p
          v-if="!multiple && singleFileError"
          class="mt-4 text-xs text-red-400"
        >
          The file you tried to upload was too large. Please upload a file
          smaller than {{ fileSizeLimit }}MB.
        </p>
        <dd
          v-else-if="multiple && displayableFiles.length > 0"
          :class="{ 'mt-4': !compact }"
          class="text-xs text-left leading-5 text-gray-900 w-full"
        >
          <ul class="border border-gray-200 rounded-md">
            <li
              v-for="(file, index) in displayableFiles"
              :key="file.name"
              class="flex items-center justify-between text-xs"
              :class="{
                'pl-3 pr-4 py-3 leading-5': !compact,
                'border-t': index !== 0,
                'border-gray-200': index !== 0,
              }"
            >
              <div class="w-0 flex-1 flex items-center">
                <svg
                  class="flex-shrink-0 h-5 w-5"
                  :class="{
                    'text-gray-400': !file.errorMessage,
                    'text-red-400': file.errorMessage,
                  }"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <span
                  v-if="file.errorMessage"
                  class="ml-2 flex-1 w-0 truncate text-red-600"
                  >{{ file.name }} {{ file.errorMessage }}</span
                >
                <span v-else class="ml-2 flex-1 w-0 truncate">
                  {{ file.name }}
                </span>
              </div>
              <div
                :class="{ 'ml-1': compact, 'ml-4': !compact }"
                class="flex-shrink-0 flex items-center"
              >
                <div v-if="!compact && !file.errorMessage">
                  <span
                    class="font-medium text-green-400"
                    :data-test="`uploaded-file-${index}`"
                  >
                    Uploaded
                  </span>
                </div>
                <button
                  v-if="localCommit === 'dataInputJobPayloadItem'"
                  @click="remove(file)"
                  class="h-4 w-4 text-red-800"
                >
                  <TrashIcon v-tooltip="'Remove'" />
                </button>
              </div>
            </li>
          </ul>
        </dd>
      </div>
    </div>
    <input
      ref="filesInput"
      type="file"
      :multiple="multiple"
      :accept="fileTypes"
      :data-direct-upload-url="fileUploadUrl"
      class="hidden"
      name="Files"
      @change="handleInputFiles"
      data-test="upload-files-input"
    />
  </div>
</template>

<script setup>
import { ExclamationTriangleIcon, TrashIcon } from "@heroicons/vue/20/solid";
import Uploader from "@/components/crowdsourcing/uploader";
import api from "@/router/api";
import { computed, ref, watch } from "vue";
import { useModalStore } from "@/stores/modal";
import { useUserStore } from "@/stores/user";
import { useCitationStore } from "@/stores/citation";
import { useEasyDataInputStore } from "@/stores/easyDataInput";
import { useEasyDataInputAdminStore } from "@/stores/easyDataInputAdmin";
import { useUploadedFileStore } from "@/stores/uploadedFile";
import _ from "lodash";
import { storeToRefs } from "pinia";

const props = defineProps([
  "endpoint",
  "localCommit",
  "directWrite",
  "fileTypes",
  "fileTypeLabels",
  "sizeLimit",
  "multiple",
  "heading",
  "fullWidth",
  "marginBottom",
  "flashMessage",
  "afterAction",
  "compact",
]);
const files = ref([]);
const displayableFiles = computed(() => {
  if (
    props.localCommit === "dataInputJobPayloadItem" &&
    _.isObject(props.directWrite) &&
    _.isArray(props.directWrite.files)
  ) {
    const payloadItems = detailedSelectedJobCRUD.value?.payloadItems;
    const writableItem = _.find(payloadItems, {
      id: props.directWrite.id,
    });

    return writableItem.files;
  } else {
    return files.value;
  }
});
const filesInput = ref(null);
const uploadedFiles = ref([]);
const pendingFileCount = ref(0);
const fileUploadUrl = "/rails/active_storage/direct_uploads";
const dragging = ref(false);
const uploading = ref(false);
const uploadingProgress = ref(null);
const uploadingTotal = ref(null);
const modalStore = useModalStore();
const userStore = useUserStore();
const { emailUnverified } = storeToRefs(userStore);
const citationStore = useCitationStore();
const { citationUploadPayload, name } = storeToRefs(citationStore);
const easyDataInputStore = useEasyDataInputStore();
const { contributionUploadPayload } = storeToRefs(easyDataInputStore);
const easyDataInputAdminStore = useEasyDataInputAdminStore();
const { detailedSelectedJobCRUD } = storeToRefs(easyDataInputAdminStore);

const fileNames = computed(() => uploadedFiles.value.map((f) => f.name));
const fileSizeLimit = computed(() => props.sizeLimit || 10);
const readyToPersist = computed(() => {
  return (
    filesToPersist.value.length > 0 &&
    pendingFileCount.value === 0 &&
    filesToPersist.value.every((file) => !file.errorMessage)
  );
});
const progressWidth = computed(() => {
  if (uploading.value && uploadingTotal.value) {
    const progress = (uploadingProgress.value / uploadingTotal.value) * 100;

    return `width: ${progress}%;`;
  } else {
    return "";
  }
});
const filesToPersist = computed(() =>
  _.differenceBy(files.value, uploadedFiles.value, "signedId"),
);
const singleFileError = computed(
  () => files.value.length === 1 && files.value[0].errorMessage,
);

watch(filesToPersist, async () => {
  if (readyToPersist.value) {
    persistFiles();
  }
});

function hover(e) {
  e.dataTransfer.dropEffect = "move";
  dragging.value = true;
}
function launchFilePicker() {
  console.log("launch picker");
  filesInput.value.click();
}
function handleDragDropFiles(e) {
  if (emailUnverified.value) {
    userStore.promptToVerify();
  } else {
    dragging.value = false;
    let droppedFiles = e.dataTransfer.files;

    if (!droppedFiles) return;
    const toLoad = [...droppedFiles];

    pendingFileCount.value = toLoad.length;
    toLoad.forEach(loadFiles);
  }
}
function handleInputFiles() {
  if (emailUnverified.value) {
    userStore.promptToVerify();
  } else {
    const toLoad = Array.from(filesInput.value.files);

    pendingFileCount.value = toLoad.length;
    toLoad.forEach(loadFiles);
    filesInput.value.value = null;
  }
}
function loadFiles(file, index) {
  if (_.includes(fileNames, file.name)) return;

  if (props.multiple) {
    processFile(file);
  } else if (index === 0) {
    processFile(file);
  }
}
function processFile(file) {
  const fileSize = (file.size / 1024 / 1024).toFixed(4); // MB

  if (fileSize > fileSizeLimit.value) {
    files.value.push(
      Object.assign(
        {},
        {
          name: file.name,
          size: file.size,
          signedId: null,
          errorMessage: `is > ${fileSizeLimit.value}MB.`,
        },
      ),
    );
    pendingFileCount.value--;
    setTimeout(() => {
      remove(file);
    }, 3500);
  } else {
    uploadFile(file);
  }
}
function uploadFile(file) {
  const uploader = new Uploader(
    file,
    fileUploadUrl,
    files,
    pendingFileCount,
    uploading,
    uploadingProgress,
    uploadingTotal,
  );
  const reader = new FileReader();

  reader.addEventListener("load", () => {
    // console.log("loaded");
  });

  reader.readAsDataURL(file);

  uploader.directUpload();
}
function persistFiles() {
  let payload;

  if (props.multiple) {
    payload = {
      files: filesToPersist.value.map((f) => {
        return {
          signedId: f.signedId,
          name: f.name,
          size: f.size,
        };
      }),
    };
  } else {
    payload = {
      file: {
        signedId: files.value[0].signedId,
        name: files.value[0].name,
        size: files.value[0].size,
      },
    };
  }

  console.log("persist files", payload);

  const uploadedFileStore = useUploadedFileStore();
  const { completedUploadPayload } = storeToRefs(uploadedFileStore);

  if (props.endpoint) {
    api.patch(props.endpoint, payload).then((json) => {
      // $store.dispatch("flash", flashMessage);

      if (props.afterAction) {
        let afterActionPayload = props.afterAction.payloadKey ? {} : json.data;

        if (props.afterAction.payloadKey) {
          afterActionPayload[props.afterAction.payloadKey] = json.data;
        }

        completedUploadPayload.value = afterActionPayload;
      }

      files.value.forEach((file) => {
        if (_.find(uploadedFiles, { signedId: file.signedId })) {
          return;
        } else {
          uploadedFiles.value.push(file);
        }
      });

      if (!props.multiple && props.heading) {
        modalStore.closeModal();
      }
    });
  } else if (props.localCommit) {
    switch (props.localCommit) {
      case "easyDataInput":
        contributionUploadPayload.value = payload;
        break;
      case "citation":
        citationUploadPayload.value = payload;
        name.value = _.get(payload, "file.name");
        break;
      case "ContentFiles":
        completedUploadPayload.value = payload;
        break;
      case "dataInputJobPayloadItem":
        {
          if (
            _.isObject(props.directWrite) &&
            _.isArray(props.directWrite.files)
          ) {
            const payloadItems = detailedSelectedJobCRUD.value?.payloadItems;
            const writableItem = _.find(payloadItems, {
              id: props.directWrite.id,
            });

            if (writableItem) {
              writableItem.files = _.unionBy(
                payload.files,
                writableItem.files,
                "signedId",
              );
            }
          }
        }
        break;
      default:
        // $store.commit(localCommit, payload);
        // $store.dispatch("flash", flashMessage);
        console.log("unknown local commit");
    }

    files.value.forEach((file) => {
      if (_.find(uploadedFiles, { signedId: file.signedId })) {
        return;
      } else {
        uploadedFiles.value.push(file);
      }
    });

    if (!props.multiple) {
      modalStore.closeModal();
    }
  }
}
function remove(file) {
  const newFiles = files.value.filter((f) => f.name !== file.name);

  files.value = newFiles;

  attemptDirectRemove(file);
}

function attemptDirectRemove(file) {
  if (
    props.localCommit === "dataInputJobPayloadItem" &&
    _.isObject(props.directWrite) &&
    _.isArray(props.directWrite.files)
  ) {
    const payloadItems = detailedSelectedJobCRUD.value?.payloadItems;
    const writableItem = _.find(payloadItems, {
      id: props.directWrite.id,
    });

    if (writableItem) {
      const newFiles = writableItem.files.filter((f) => f.name !== file.name);

      writableItem.files = newFiles;
    }
  }
}
</script>

<style lang="css" scoped>
.direct-upload__progress {
  transition:
    width 120ms ease-out,
    opacity 60ms 60ms ease-in;
  transform: translate3d(0, 0, 0);
}
</style>
