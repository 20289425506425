<template>
  <div class="h-full relative rounded-lg overflow-hidden">
    <CardBranding :card="card" style="z-index: 9999" />
    <div class="absolute inset-0">
      <div
        class="absolute inset-0 bg-gradient-to-r from-amber-500 to-rose-400 mix-blend-multiply"
      />
    </div>
    <div class="h-full relative flex flex-col">
      <div
        v-if="payloadItem"
        :id="`location-map-${card.id}-${payloadItem.id}`"
        class="w-full"
        style="height: 237.5px"
      />
      <div v-else class="w-full bg-rose-500" style="height: 237.5px" />
      <div
        class="mx-auto mt-6 max-w-sm flex flex-col align-center justify-center"
      >
        <h1
          class="flex items-center justify-center text-center text-2xl font-bold tracking-tight space-x-1"
        >
          <span class="text-white">Data Input</span>
          <span class="text-rose-200">Opportunity</span>
        </h1>
      </div>
      <p
        v-if="payloadItem"
        class="mx-auto mt-2 max-w-lg text-center font-semibold text-lg text-rose-100"
      >
        {{ revenueSplitSummary }}
      </p>
      <p
        v-if="payloadItem"
        class="mx-auto max-w-lg text-center text-lg text-rose-200"
      >
        {{ payloadItem.inputComplexity }} Complexity
      </p>
      <p
        v-if="payload"
        class="mx-auto max-w-lg text-center text-lg text-rose-200"
      >
        {{ payload.uses.map(({ name }) => name).join(", ") }}
      </p>
      <div class="mx-auto my-4 max-w-sm flex justify-center">
        <div class="space-y-4">
          <div
            v-if="userCompleted"
            href=""
            class="flex items-center justify-center rounded-md border border-white px-4 py-3 text-base font-medium text-white shadow-sm hover:border-rose-50 hover:text-rose-50 sm:px-8"
          >
            Completed!
          </div>
          <a
            v-else-if="!activeEasyDataInputPayloadItem"
            href=""
            @click.prevent="accept"
            class="flex items-center justify-center rounded-md border border-transparent bg-white px-4 py-3 text-base font-medium text-rose-700 shadow-sm hover:bg-rose-50 sm:px-8"
            >Accept</a
          >
          <a
            v-else-if="activeEasyDataInputPayloadItem?.id === payloadItem?.id"
            href=""
            @click.prevent="viewContributions"
            class="flex items-center justify-center rounded-md border border-white px-4 py-3 text-base font-medium text-white shadow-sm hover:border-rose-50 hover:text-rose-50 sm:px-8"
          >
            Active!
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
/* global L */
import mapStyles from "@/components/maps/mapStyles";
import api from "@/router/api";
import CardBranding from "@/components/collectible-cards/CardBranding.vue";
import { ref, computed, watch, onMounted, nextTick } from "vue";
import { storeToRefs } from "pinia";
import { useRouter, useRoute } from "vue-router";
import { useUserStore } from "@/stores/user";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { useNotificationsStore } from "@/stores/notifications";
import _ from "lodash";

const props = defineProps([
  "card",
  "cardIndex",
  "stackIndex",
  "completedCardIds",
  "static",
]);
const emit = defineEmits(["accept"]);

const userStore = useUserStore();
const { activeEasyDataInputPayloadItem } = storeToRefs(userStore);
const layoutStore = useWorkspaceLayoutStore();
const { workspaceLayout, workspaceResized } = storeToRefs(layoutStore);
const notificationsStore = useNotificationsStore();

const cardContents = ref([]);
const payloadItem = computed(() => _.head(cardContents.value));
const payload = computed(() => payloadItem.value?.payload);
const userCompleted = computed(() => payloadItem.value?.userCompleted);

const map = ref(null);
const featureGroup = ref(L.featureGroup());
const baseLayers = ref({
  Base: L.gridLayer.googleMutant({
    type: "roadmap",
    styles: mapStyles.styles,
  }),
});
const concealedCenter = ref({
  lat: null,
  lng: null,
});
const revenueSplitSummary = computed(() => {
  if (payloadItem.value) {
    switch (payloadItem.value.estimatedSplit) {
      case 0:
        return "Keep 100%";
      default: {
        const take = 1 - payloadItem.value.estimatedSplit;
        return `Keep ~${_.round(take * 100)}%`;
      }
    }
  } else {
    return null;
  }
});
const actionableCard = computed(
  () =>
    props.stackIndex === props.cardIndex &&
    !_.includes(props.completedCardIds, props.card.id),
);

watch(workspaceLayout, () => {
  if (map.value) {
    setTimeout(function () {
      map.value.invalidateSize();
    }, 100);
  }
});

watch(workspaceResized, () => {
  if (map.value && workspaceResized.value) {
    setTimeout(function () {
      map.value.invalidateSize();
    }, 100);
  }
});

watch(
  () => props.stackIndex,
  () => {
    if (!payloadItem.value) fetchTopCardPayloadItem();
    if (cardContents.value.length > 0 && actionableCard.value) {
      mapHandler();
    }
  },
);

onMounted(() => {
  fetchTopCardPayloadItem();
});

async function fetchTopCardPayloadItem() {
  if (props.static || actionableCard.value) {
    const response = await api.get(
      `collectible_cards/${props.card.collectibleCardId}/collectible_card_contents?template=payload_item_card`,
    );

    if (response?.data) {
      cardContents.value = response.data;

      mapHandler();
    } else if (cardContents.value.length > 0) {
      mapHandler();
    }
  }
}

async function mapHandler() {
  concealedCenter.value.lat = null;
  concealedCenter.value.lng = null;
  featureGroup.value.eachLayer((layer) => {
    map.value.removeLayer(layer);
    featureGroup.value.removeLayer(layer);
  });

  if (payload.value?.cardGeography?.concealedLatitude) {
    concealedCenter.value.lat = payload.value.cardGeography.concealedLatitude;
    concealedCenter.value.lng = payload.value.cardGeography.concealedLongitude;
  }

  await nextTick();
  mountMap();
}

async function mountMap() {
  if (!map.value) {
    let blockEl = document.getElementById(
      `location-map-${props.card.id}-${payloadItem.value.id}`,
    );

    if (!blockEl) return;

    map.value = L.map(`location-map-${props.card.id}-${payloadItem.value.id}`, {
      center: [42.361145, -71.057083],
      zoom: 12,
      scrollWheelZoom: false,
      zoomControl: false,
      dragging: false,
      boxZoom: false,
      doubleClickZoom: false,
      zoomAnimation: false,
      fadeAnimation: true,
      markerZoomAnimation: true,
    });

    baseLayers.value["Base"].addTo(map.value);

    if (concealedCenter.value.lat && concealedCenter.value.lng) {
      generalizedCircle();
    } else if (payload.value?.cardGeography?.coordinates) {
      addPolygon();
    }

    await nextTick();

    map.value.fitBounds(featureGroup.value.getBounds(), { maxZoom: 11 });
  }
}

function generalizedCircle() {
  const mapCircle = L.circle(
    [concealedCenter.value.lat, concealedCenter.value.lng],
    {
      radius: 1500,
      color: "#fb7185",
      interactive: false,
    },
  );

  mapCircle.addTo(map.value);
  featureGroup.value.addLayer(mapCircle);
}

function addPolygon() {
  const mapPolygon = L.polygon(payload.value.cardGeography.coordinates, {
    interactive: false,
    color: "#fb7185",
    stroke: true,
    fill: true,
  });

  mapPolygon.addTo(map.value);
  featureGroup.value.addLayer(mapPolygon);
}

async function accept() {
  if (payloadItem.value) {
    const response = await api.post(
      `easy_data_input_payload_items/${payloadItem.value.id}/payload_item_inputters`,
    );

    if (response?.data) {
      notificationsStore.addNotification("payloadItemAccepted");
      emit("accept");
      viewContributions();
    }
  }
}

const router = useRouter();
const route = useRoute();

function viewContributions() {
  if (!props.static) {
    router.push({
      name: route.name,
      query: {
        ...route.query,
        horizontalTab: "Me",
        verticalTab: "Contributions",
      },
    });
  }
}
</script>
