<template>
  <li class="overflow-hidden rounded-xl border border-gray-200">
    <div
      class="flex items-center gap-x-2 border-b border-gray-900/5 bg-gray-50 p-3"
    >
      <div
        v-tooltip="
          `Wave ${item.executionOrder + 1}, Status: ${_.startCase(
            item.packagingStatus,
          )}`
        "
        :class="statusColor"
        class="h-12 w-12 flex items-center justify-center font-semibold text-2xl rounded-lg ring-1 ring-gray-900/10"
      >
        <span>{{ item.executionOrder + 1 }}</span>
      </div>
      <dt v-tooltip="'Data value'" class="flex-none">
        <span class="sr-only">Data Value</span>
        <SparklesIcon class="h-6 w-5 text-gray-700" aria-hidden="true" />
      </dt>
      <dd class="text-sm/6 text-gray-900">
        {{ item.dataValue }}
      </dd>
      <dt v-tooltip="'Input complexity'" class="flex-none">
        <span class="sr-only">Input Complexity</span>
        <PuzzlePieceIcon class="h-6 w-5 text-gray-700" aria-hidden="true" />
      </dt>
      <dd class="text-sm/6 text-gray-900">
        {{ item.inputComplexity }}
      </dd>
      <Menu
        v-if="isAdmin || item.packagingStatus !== 'draft'"
        as="div"
        class="relative ml-auto"
      >
        <MenuButton class="-m-2 block p-2 text-gray-400 hover:text-gray-500">
          <span class="sr-only">Open options</span>
          <EllipsisHorizontalIcon class="h-5 w-5" aria-hidden="true" />
        </MenuButton>
        <transition
          enter-active-class="transition ease-out duration-100"
          enter-from-class="transform opacity-0 scale-95"
          enter-to-class="transform opacity-100 scale-100"
          leave-active-class="transition ease-in duration-75"
          leave-from-class="transform opacity-100 scale-100"
          leave-to-class="transform opacity-0 scale-95"
        >
          <MenuItems
            class="absolute right-0 z-10 mt-0.5 w-40 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none"
          >
            <MenuItem
              v-if="item.packagingStatus !== 'draft'"
              v-slot="{ active }"
            >
              <a
                href=""
                @click.prevent="select"
                :class="[
                  active ? 'bg-gray-50 outline-none' : '',
                  'block px-3 py-1 text-sm/6 text-gray-900',
                ]"
                >View<span class="sr-only">, {{ item.id }}</span></a
              >
            </MenuItem>
            <MenuItem v-if="isAdmin && false" v-slot="{ active }">
              <a
                href=""
                @click.prevent
                :class="[
                  active ? 'bg-gray-50 outline-none' : '',
                  'block px-3 py-1 text-sm/6 text-gray-900',
                ]"
                >Edit<span class="sr-only">, {{ item.id }}</span></a
              >
            </MenuItem>
            <MenuItem
              v-if="
                isAdmin &&
                item.packagingStatus === 'draft' &&
                detailedSelectedJob.packagingStatus === 'live'
              "
              v-slot="{ active }"
            >
              <a
                href=""
                @click.prevent="broadcast(item)"
                :class="[
                  active ? 'bg-gray-50 outline-none' : '',
                  'block px-3 py-1 text-sm/6 text-gray-900',
                ]"
                >Broadcast<span class="sr-only">, {{ item.id }}</span></a
              >
            </MenuItem>
            <MenuItem
              v-if="isAdmin && !_.includes(['finalized'], item.packagingStatus)"
              v-slot="{ active }"
            >
              <a
                href=""
                @click.prevent="waveItemInsert(item)"
                :class="[
                  active ? 'bg-gray-50 outline-none' : '',
                  'block px-3 py-1 text-sm/6 text-gray-900',
                ]"
                >New Wave {{ item.executionOrder + 1 }} Item<span
                  class="sr-only"
                  >, {{ item.id }}</span
                ></a
              >
            </MenuItem>
          </MenuItems>
        </transition>
      </Menu>
    </div>
    <dl class="-my-3 divide-y divide-gray-100 p-3 text-sm/6">
      <div v-if="item.deadline" class="flex justify-between gap-x-6 py-3">
        <dt class="text-gray-500">Due</dt>
        <dd class="text-green-700 font-semibold">
          <time :datetime="moment(item.deadline).format('YYYY-MM-DD')">{{
            moment(item.deadline).fromNow()
          }}</time>
        </dd>
      </div>
      <div v-else class="flex justify-between gap-x-4 py-3">
        <dt class="text-gray-500">Created</dt>
        <dd class="text-gray-700">
          <time :datetime="moment(item.createdAt).format('YYYY-MM-DD')">{{
            moment(item.createdAt).fromNow()
          }}</time>
        </dd>
      </div>
      <div class="flex justify-between gap-x-6 py-3">
        <dt class="text-gray-500">Content</dt>
        <dd class="flex flex-col space-y-2 text-xs">
          <div
            v-html="linkifiedItemNarrative"
            class="font-medium text-gray-900 whitespace-pre-line dont-break-out"
          />
          <a
            v-for="file in item.files"
            :key="`file-${file.id}`"
            href=""
            @click.prevent="viewEmbed(file)"
            v-tooltip="file.name"
            class="flex items-center p-2"
          >
            <div class="font-medium text-indigo-600 hover:text-indigo-500">
              <PaperClipIcon class="h-4 w-4 flex-shrink-0" aria-hidden="true" />
            </div>
          </a>
        </dd>
      </div>
    </dl>
  </li>
</template>

<script setup>
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import {
  EllipsisHorizontalIcon,
  PaperClipIcon,
  PuzzlePieceIcon,
  SparklesIcon,
} from "@heroicons/vue/20/solid";
import { useEasyDataInputAdminStore } from "@/stores/easyDataInputAdmin";
import { useUploadedFileStore } from "@/stores/uploadedFile";
import { useUserStore } from "@/stores/user";
import { computed } from "vue";
import { storeToRefs } from "pinia";
import moment from "moment";

import linkifyHtml from "linkify-html";
import _ from "lodash";

const props = defineProps(["item"]);
const emit = defineEmits(["select-item"]);

const userStore = useUserStore();
const { isAdmin } = storeToRefs(userStore);
const easyDataInputAdminStore = useEasyDataInputAdminStore();
const { selectedItem, detailedSelectedJob } = storeToRefs(
  easyDataInputAdminStore,
);
const uploadedFileStore = useUploadedFileStore();

const statusColor = computed(() => {
  switch (props.item.packagingStatus) {
    case "draft":
      return "bg-yellow-500";
    case "live":
      return "bg-lime-500";
    case "inputter_accepted":
      return "bg-emerald-500";
    case "inputter_completed":
      return "bg-sky-500";
    case "finalized":
      return "bg-violet-500";
    default:
      return "bg-gray-500";
  }
});

const linkifiedItemNarrative = computed(() => {
  return linkifyHtml(`${props.item.narrative || props.item.id}`, {
    defaultProtocol: "https",
    target: "_blank",
    className: "font-medium text-indigo-600 hover:text-indigo-500",
  });
});

function viewEmbed(file) {
  uploadedFileStore.viewEmbed(file);
}

function select() {
  if (isAdmin.value) {
    selectedItem.value = props.item;
  } else {
    emit("select-item", props.item);
  }
}

function waveItemInsert(item) {
  easyDataInputAdminStore.addPayloadItem(
    { id: item.payloadId },
    item.executionOrder,
  );
}

function broadcast(item) {
  easyDataInputAdminStore.broadcastItem(item);
}
</script>
