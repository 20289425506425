<template>
  <div v-if="loaded">
    <h3 class="text-base leading-6 font-medium text-gray-900">
      <template v-if="cards.length > 0 && selectable"
        >Choose a payment method</template
      >
      <template v-else-if="cards.length > 0 && !selectable"
        >Your payment methods</template
      >
      <template v-else>Add a payment method</template>
    </h3>
    <div
      v-if="capturingPayment"
      class="mt-2 max-w-xl text-sm leading-5 text-gray-500"
    >
      <p>
        <template v-if="capturingPayment === 'later'">
          You will not be charged now.
        </template>
      </p>
    </div>
    <div class="mt-3 space-y-2">
      <div
        v-for="record in cards"
        :key="record.id"
        class="rounded-md bg-gray-50 px-6 py-5"
        :class="
          workspaceLayout === 'sideBySide'
            ? ''
            : 'flex items-start justify-between'
        "
      >
        <div
          class=""
          :class="workspaceLayout === 'sideBySide' ? '' : 'flex items-start'"
        >
          <svg
            v-if="unknownCard(record.card.brand)"
            class="h-8 w-auto text-gray-400"
            :class="workspaceLayout === 'sideBySide' ? '' : 'flex-shrink-0 h-6'"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <title>{{ record.card.brand }}</title>
            <path d="M4 4a2 2 0 00-2 2v1h16V6a2 2 0 00-2-2H4z" />
            <path
              fill-rule="evenodd"
              d="M18 9H2v5a2 2 0 002 2h12a2 2 0 002-2V9zM4 13a1 1 0 011-1h1a1 1 0 110 2H5a1 1 0 01-1-1zm5-1a1 1 0 100 2h1a1 1 0 100-2H9z"
              clip-rule="evenodd"
            />
          </svg>
          <img
            v-else
            class="h-8 w-auto text-gray-600"
            :class="workspaceLayout === 'sideBySide' ? '' : 'flex-shrink-0 h-6'"
            :src="`https://assets.towerhunt.com/site/${brandLogoFor(
              record.card.brand
            )}0.png`"
            :alt="record.card.brand"
          />
          <div
            class=""
            :class="workspaceLayout === 'sideBySide' ? 'mt-3' : 'mt-0 ml-4'"
          >
            <div
              class="text-sm leading-5 font-medium text-gray-900"
              data-test="card-last4"
            >
              Ending with {{ record.card.last4 }}
            </div>
            <div
              class="mt-1 text-sm leading-5 text-gray-600"
              :class="
                workspaceLayout === 'sideBySide' ? '' : 'flex items-center'
              "
            >
              <div data-test="card-expiry">
                Expires {{ record.card.exp_month }}/{{ record.card.exp_year }}
              </div>
              <template v-if="record.id === defaultPaymentMethod">
                <span
                  class=""
                  :class="
                    workspaceLayout === 'sideBySide' ? 'hidden' : 'mx-2 inline'
                  "
                  aria-hidden="true"
                >
                  &middot;
                </span>
                <div
                  class=""
                  :class="workspaceLayout === 'sideBySide' ? 'mt-1' : ''"
                >
                  <span
                    class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium leading-4 bg-indigo-100 text-indigo-800"
                  >
                    Default
                  </span>
                </div>
              </template>
            </div>
          </div>
        </div>
        <div
          v-if="selectable"
          class=""
          :class="
            workspaceLayout === 'sideBySide' ? 'mt-4' : 'ml-6 flex-shrink-0'
          "
        >
          <span class="inline-flex rounded-md shadow-sm">
            <button
              @click="select(record)"
              type="button"
              class="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:ring-indigo active:bg-indigo-700"
              :data-test="`choose-payment-method-${record.card.last4}`"
            >
              Choose
            </button>
          </span>
        </div>
      </div>
      <PaymentMethod
        v-if="cards.length === 0 || addingNew"
        :cancellable="cards.length > 0"
        :when="capturingPayment"
        @refresh-cards="refreshCards"
        @cancel="addingNew = false"
      />
      <div class="flex justify-end space-x-2">
        <span
          v-if="capturingPayment && cards.length > 0 && !addingNew"
          class="inline-flex rounded-md shadow-sm"
        >
          <button
            @click="addingNew = true"
            type="button"
            v-tooltip="'Add another credit card'"
            class="inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue active:text-gray-800 active:bg-gray-50"
          >
            Add
          </button>
        </span>
        <span
          v-else-if="!capturingPayment && cards.length > 0"
          class="inline-flex rounded-md shadow-sm"
        >
          <button
            @click="visitCustomerPortal"
            type="button"
            v-tooltip="'Manage payment methods'"
            class="inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue active:text-gray-800 active:bg-gray-50"
          >
            Manage
          </button>
        </span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import PaymentMethod from "@/components/users/PaymentMethod.vue";
import _ from "lodash";
import api from "@/router/api";
import { storeToRefs } from "pinia";

defineProps(["selectable", "capturingPayment"]);
const emit = defineEmits(["card-selected"]);
const layoutStore = useWorkspaceLayoutStore();
const { workspaceLayout } = storeToRefs(layoutStore);

const cards = ref([]);
const defaultPaymentMethod = ref(null);
const loaded = ref(false);
const addingNew = ref(false);

onMounted(async () => {
  refreshCards();
});

async function refreshCards() {
  api.get(`my_payment_methods`).then((json) => {
    cards.value = json.data.cards;
    defaultPaymentMethod.value = json.data.defaultPaymentMethod;
    addingNew.value = false;
    loaded.value = true;
  });
}

function select(card) {
  emit("card-selected", card);
}

function trimmedLower(str) {
  return str.toLowerCase().replace(/\s/g, "");
}

function unknownCard(brand) {
  return !_.includes(
    ["americanexpress", "discover", "mastercard", "visa"],
    trimmedLower(brand)
  );
}
function brandLogoFor(brand) {
  return trimmedLower(brand);
}
async function visitCustomerPortal() {
  api.post(`customer_payment_portals`).then((json) => {
    const portalPath = json.data.url;

    window.open(portalPath, "_blank");
  });
}
</script>
