<template>
  <section v-if="loaded" aria-labelledby="files-heading">
    <div class="">
      <div
        :class="
          files.length > 0 || folders.length > 0 || citations.length > 0
            ? 'mb-12'
            : ''
        "
        class="bg-white p-2"
      >
        <div
          class="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap"
        >
          <div class="ml-4 mt-4">
            <h3 class="text-lg leading-6 font-medium text-gray-900">Files</h3>
            <p class="mt-1 text-sm text-gray-500">
              Be more productive with this {{ contentType }} with files and
              links.
            </p>
          </div>
          <div
            v-if="
              files.length > 0 || folders.length > 0 || citations.length > 0
            "
            class="ml-4 mt-4 flex-shrink-0"
          >
            <FileStorageHostDropdown
              v-if="signedIn"
              :google-auth-loaded="googleAuthApiLoaded"
              @local-upload="localUpload"
              @dropbox-chooser="dropboxChooser"
              @google-drive-chooser="googleDriveChooser"
              @one-drive-share-point-chooser="oneDriveSharePointChooser"
              @paste-url="handleUrl"
            />
          </div>
        </div>

        <div v-if="files.length > 0" class="mt-6">
          <h2 class="text-gray-900 text-xs font-medium uppercase tracking-wide">
            Individual Files
          </h2>
          <ul class="mt-3 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2">
            <ContentFile
              v-for="file in files"
              :key="file.id"
              :data-field="file"
              @unlocked="fetchFiles"
              @completed="fetchFiles"
            />
          </ul>
        </div>

        <div v-if="folders.length > 0" class="mt-6">
          <h2 class="text-gray-900 text-xs font-medium uppercase tracking-wide">
            Folders
          </h2>
          <ul
            v-if="supported"
            class="mt-3 grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 sm:gap-x-6"
          >
            <div
              v-for="folder in folders"
              :key="folder.fieldContent.id"
              class="flex flex-col text-center sm:col-span-2"
            >
              <li
                :id="folder.fieldContent.id"
                class="h-96 bg-gray-100 relative"
              >
                <iframe
                  v-if="useIframe(folder.fieldContent)"
                  :src="folder.fieldContent.link"
                  class="h-full w-full rounded-md border border-gray-200"
                />
              </li>
              <div class="relative mt-1 flex justify-between items-center">
                <DataField
                  :data-field="folder"
                  :always-private="true"
                  primary-text-path="fieldContent.name"
                  class="mt-2 flex items-center"
                  text-classes="text-sm font-medium"
                  text-styles=""
                  @unlocked="fetchFiles"
                  @completed="fetchFiles"
                />
              </div>
            </div>
          </ul>
          <ul
            v-else
            class="mt-3 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2"
          >
            <div v-for="folder in folders" :key="folder.fieldContent.id">
              <DataField
                :data-field="folder"
                :always-private="true"
                primary-text-path="fieldContent.name"
                class="mt-2 flex items-center"
                text-classes="text-sm font-medium"
                text-styles=""
                @unlocked="fetchFiles"
                @completed="fetchFiles"
              />
              <li
                v-if="folder.fieldContent"
                class="relative col-span-1 flex shadow-sm rounded-md"
              >
                <div
                  @click="viewEmbed(folder.fieldContent)"
                  class="flex-shrink-0 flex items-center justify-center w-16 bg-gray-200 text-white text-sm rounded-l-md cursor-pointer"
                >
                  <svg
                    v-if="folder.fieldContent.hostName === 'dropbox'"
                    v-tooltip="'View with Dropbox'"
                    class="h-4 w-4 text-gray-800"
                    fill="currentColor"
                    viewBox="0 0 528 512"
                  >
                    <path
                      d="M264.4 116.3l-132 84.3 132 84.3-132 84.3L0 284.1l132.3-84.3L0 116.3 132.3 32l132.1 84.3zM131.6 395.7l132-84.3 132 84.3-132 84.3-132-84.3zm132.8-111.6l132-84.3-132-83.6L395.7 32 528 116.3l-132.3 84.3L528 284.8l-132.3 84.3-131.3-85z"
                    />
                  </svg>
                  <svg
                    v-else-if="folder.fieldContent.hostName === 'google'"
                    v-tooltip="'View with Google Drive'"
                    class="h-4 w-4 text-gray-800"
                    fill="currentColor"
                    viewBox="0 0 512 512"
                  >
                    <path
                      d="M339 314.9L175.4 32h161.2l163.6 282.9H339zm-137.5 23.6L120.9 480h310.5L512 338.5H201.5zM154.1 67.4L0 338.5 80.6 480 237 208.8 154.1 67.4z"
                    />
                  </svg>
                  <svg
                    v-else-if="folder.fieldContent.hostName === 'microsoft'"
                    v-tooltip="'View with OneDrive or SharePoint'"
                    class="h-4 w-4 text-gray-800"
                    fill="currentColor"
                    viewBox="0 0 448 512"
                  >
                    <path
                      d="M0 32h214.6v214.6H0V32zm233.4 0H448v214.6H233.4V32zM0 265.4h214.6V480H0V265.4zm233.4 0H448V480H233.4V265.4z"
                    />
                  </svg>
                </div>
                <div
                  class="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate"
                >
                  <div class="flex-1 px-4 py-2 text-sm truncate">
                    <a
                      href=""
                      @click.prevent="viewEmbed(folder.fieldContent)"
                      v-tooltip="folder.fieldContent.name"
                      class="text-gray-900 font-medium hover:text-gray-600"
                      >{{ $filters.gutMiddle(folder.fieldContent.name, 20) }}</a
                    >
                    <p class="flex items-center text-gray-500">
                      <span>Folder</span>
                    </p>
                  </div>
                </div>
              </li>
            </div>
          </ul>
        </div>

        <div v-if="citations.length > 0" class="mt-6">
          <h2 class="text-gray-900 text-xs font-medium uppercase tracking-wide">
            Citations
          </h2>
          <ul class="mt-3 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2">
            <CitationDetails
              v-for="(citationField, index) in citations"
              class="w-full rounded-md border border-gray-400"
              :key="citationField.localId"
              :citation-field="citationField"
              :citation-scope="'visible'"
              :removable="true"
              :compact="true"
              :data-test="`citation-${index}`"
              @refetch="fetchFiles"
            />
          </ul>
        </div>

        <div
          v-if="
            files.length === 0 && folders.length === 0 && citations.length === 0
          "
          class="mt-6 text-center"
        >
          <svg
            class="mx-auto h-12 w-12 text-gray-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              vector-effect="non-scaling-stroke"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
            />
          </svg>
          <h3 class="mt-2 text-sm font-medium text-gray-900">No files</h3>
          <template v-if="supported && signedIn">
            <p class="mt-1 text-sm text-gray-500">
              Get started by choosing some folders and/or files.
            </p>
            <div class="mt-6">
              <FileStorageHostDropdown
                :google-auth-loaded="googleAuthApiLoaded"
                @local-upload="localUpload"
                @dropbox-chooser="dropboxChooser"
                @google-drive-chooser="googleDriveChooser"
                @one-drive-share-point-chooser="oneDriveSharePointChooser"
                @paste-url="handleUrl"
              />
            </div>
          </template>
          <template v-else-if="!supported">
            <p class="mt-1 text-sm text-gray-500">
              We're unable to let you choose files on this browser.
            </p>
          </template>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { all } from "@/assets/documentFileTypes";
import { ref, computed, markRaw, watch, onMounted, onBeforeUnmount } from "vue";
import { useCrowdsourcedChangeGroupStore } from "@/stores/crowdsourcedChangeGroup";
import { useModalStore } from "@/stores/modal";
import { useUserStore } from "@/stores/user";
import { useUploadedFileStore } from "@/stores/uploadedFile";
import { storeToRefs } from "pinia";
import CitationDetails from "@/components/crowdsourcing/CitationDetails.vue";
import ContentFile from "@/components/crowdsourcing/ContentFile.vue";
import DataField from "@/components/crowdsourcing/DataField.vue";
import DropboxEmbedViewer from "@/components/DropboxEmbedViewer.vue";
import FileDragDrop from "@/components/crowdsourcing/FileDragDrop.vue";
import FileStorageHostDropdown from "@/components/crowdsourcing/FileStorageHostDropdown.vue";
import IFrameEmbedViewer from "@/components/IFrameEmbedViewer.vue";
import api from "@/router/api";
import _ from "lodash";
/* global Dropbox */
/* global gapi */
/* global google */
/* global OneDrive */

const props = defineProps(["dataField"]);
const loaded = ref(false);
const supported = ref(Dropbox.isBrowserSupported());
const googleAuthApiLoaded = ref(false);
const googlePickerApiLoaded = ref(false);
const googleOAuthToken = ref(null);
const googleOAuthTokenClient = ref(null);
const embeds = ref([]);
const files = ref([]);
const folders = ref([]);
const citations = ref([]);
const apiKeys = ref({});

const changeGroupStore = useCrowdsourcedChangeGroupStore();
const { changeGroupId } = storeToRefs(changeGroupStore);
const uploadedFileStore = useUploadedFileStore();
const { completedUploadPayload } = storeToRefs(uploadedFileStore);
const modalStore = useModalStore();
const { modalPayload } = storeToRefs(modalStore);
const userStore = useUserStore();
const { signedIn, emailUnverified } = storeToRefs(userStore);
const contentType = computed(() => props.dataField.fieldContentType);
const contentId = computed(() => props.dataField.fieldContent.id);
const redirectUrl = computed(() => {
  const urlRoot =
    location.protocol +
    "//" +
    location.hostname +
    (location.port ? ":" + location.port : "");

  return `${urlRoot}`;
});

watch(contentId, () => {
  fetchFiles();
});
watch(completedUploadPayload, () => {
  if (completedUploadPayload.value) {
    retrieveFromTemporaryAccess();
  }
});

onMounted(async () => {
  await fetchApiKeys();

  if (signedIn.value) loadGoogleApiPicker();
  fetchFiles();
});
onBeforeUnmount(() => {
  clearEmbeds();
});

function retrieveFromTemporaryAccess() {
  if (_.get(completedUploadPayload.value, "files", []).length > 0) {
    linkFilesToContent(completedUploadPayload.value.files, "tower_hunt");
    completedUploadPayload.value = null;
  }
}
function handleUrl(payload) {
  linkFilesToContent(payload, "url");
}
function fetchApiKeys() {
  return new Promise((resolve) => {
    if (signedIn.value) {
      api.get(`file_storage_api_keys`).then((json) => {
        apiKeys.value = json.data;
        resolve();
      });
    } else {
      resolve();
    }
  });
}
function fetchFiles() {
  clearEmbeds();
  setTimeout(() => {
    api
      .get(`crowdsourced_files/${contentType.value}/${contentId.value}`)
      .then((json) => {
        const fetchedFiles = json.data.files;
        const fetchedFolders = json.data.folders;
        const fetchedCitations = json.data.citations;

        files.value = fetchedFiles;
        folders.value = fetchedFolders;
        citations.value = fetchedCitations;
        loaded.value = true;

        setTimeout(() => {
          embedFolders();
        }, 50);
      });
  }, 500);
}
function loadGoogleApiPicker() {
  gapi.load("auth", onAuthApiLoad);
  gapi.load("picker", onPickerApiLoad);
}
function onAuthApiLoad() {
  googleAuthApiLoaded.value = true;
}
function onPickerApiLoad() {
  googlePickerApiLoaded.value = true;
}
function googleAuth() {
  var scope = "https://www.googleapis.com/auth/drive.file";

  googleOAuthTokenClient.value = google.accounts.oauth2.initTokenClient({
    client_id: apiKeys.value.googleClient,
    scope,
    callback: handleAuthResult,
  });

  if (googleOAuthToken.value === null) {
    // Prompt the user to select a Google Account and ask for consent to share their data
    // when establishing a new session.
    googleOAuthTokenClient.value.requestAccessToken({ prompt: "consent" });
  } else {
    // Skip display of account chooser and consent dialog for an existing session.
    googleOAuthTokenClient.value.requestAccessToken({ prompt: "" });
  }
}
function handleAuthResult(authResult) {
  if (authResult && !authResult.error) {
    googleOAuthToken.value = authResult.access_token;
    createPicker();
  }
}
function createPicker() {
  if (googlePickerApiLoaded.value && googleOAuthToken.value) {
    var docsView = new google.picker.DocsView()
      .setIncludeFolders(true)
      .setSelectFolderEnabled(true);

    var picker = new google.picker.PickerBuilder()
      .enableFeature(google.picker.Feature.NAV_HIDDEN)
      .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
      .setAppId(apiKeys.value.googleApp)
      .setOAuthToken(googleOAuthToken.value)
      .addView(docsView)
      .setDeveloperKey(apiKeys.value.googleApi)
      .setCallback(pickerCallback)
      .build();

    picker.setVisible(true);
  }
}
function pickerCallback(data) {
  if (data.action == google.picker.Action.PICKED) {
    linkFilesToContent(data.docs, "google");
  }
}
function localUpload() {
  modalPayload.value = {
    size: "base",
    theme: "light",
    component: markRaw(FileDragDrop),
    props: {
      endpoint: null,
      localCommit: "ContentFiles",
      fileTypes: "image/*," + all.join(","),
      fileTypeLabels: "XLS, PPT, DOC, PDF, PNG, JPG, GIF",
      sizeLimit: 100,
      multiple: true,
      heading: "Upload file(s)",
      marginBottom: true,
      flashMessage: "Files saved successfully",
    },
  };
}
function useIframe(file) {
  return file.hostName === "google" || file.hostName === "microsoft";
}
function oneDriveSharePointChooser() {
  var odOptions = {
    clientId: apiKeys.value.azureApp,
    action: "share",
    multiSelect: true,
    viewType: "all",
    advanced: {
      redirectUri: redirectUrl.value,
      createLinkParameters: { type: "embed", scope: "anonymous" },
    },
    success: function (filesToUpload) {
      console.log("microsoft", filesToUpload);
      linkFilesToContent(filesToUpload, "microsoft");
    },
    cancel: function () {
      // self.$store.dispatch("flash", "Nothing selected");
    },
    error: function (error) {
      console.log(error);
    },
  };

  OneDrive.open(odOptions);
}
function googleDriveChooser() {
  googleAuth();
}
function dropboxChooser() {
  const options = {
    // Required. Called when a user selects an item in the Chooser.
    success: function (files) {
      linkFilesToContent(files, "dropbox");
    },

    // Optional. Called when the user closes the dialog without selecting a file
    // and does not include any parameters.
    cancel: function () {
      // self.$store.dispatch("flash", "Nothing selected");
    },

    // Optional. "preview" (default) is a preview link to the document for sharing,
    // "direct" is an expiring link to download the contents of the file. For more
    // information about link types, see Link types below.
    linkType: "preview", // or "direct"

    // Optional. A value of false (default) limits selection to a single file, while
    // true enables multiple file selection.
    multiselect: true, // or true

    // Optional. A value of false (default) limits selection to files,
    // while true allows the user to select both folders and files.
    // You cannot specify `linkType: "direct"` when using `folderselect: true`.
    folderselect: true, // or true
  };

  Dropbox.choose(options);
}
function linkFilesToContent(filesToLink, hostName = "tower_hunt") {
  if (emailUnverified.value) {
    userStore.promptToVerify();
  } else {
    let typedFiles;

    switch (hostName) {
      case "dropbox":
        typedFiles = filesToLink.map((file) => {
          return _.merge(file, { hostName });
        });
        break;
      case "google":
        typedFiles = filesToLink.map((file) => {
          return {
            hostName,
            id: file.id,
            name: file.name,
            link: file.embedUrl,
            thumbnailLink: null,
            isDir: file.type === "folder",
          };
        });
        break;
      case "microsoft":
        typedFiles = filesToLink.value.map((file) => {
          return {
            hostName,
            id: file.id,
            name: file.name,
            link: _.get(file, "permissions[0].link.webUrl", null),
            thumbnailLink: _.get(file, "thumbnails[0].medium.url", null),
            isDir: _.isNumber(_.get(file, "folder.childCount", false)),
          };
        });
        break;
      case "tower_hunt":
        typedFiles = filesToLink.map((file) => {
          return {
            hostName,
            id: file.signedId,
            name: file.name,
            link: null,
            thumbnailLink: null,
            isDir: false,
          };
        });
        break;
      case "url":
        {
          const { url, name } = filesToLink;
          typedFiles = [
            {
              hostName,
              id: null,
              name,
              link: url,
              thumbnailLink: null,
              isDir: false,
            },
          ];
        }
        break;
      default:
        typedFiles = [];
    }

    api
      .post(`crowdsourced_files`, {
        dataFieldLocalId: props.dataField.localId,
        contentType: contentType.value,
        contentToken: contentId.value,
        files: typedFiles,
        changeGroupId: changeGroupId.value,
      })
      .then((json) => {
        changeGroupStore.manageCrowdsourcedChangeGroup(json).then(() => {
          fetchFiles();
          // this.$store.dispatch("flash", "Files linked successfully");
        });
      });
  }
}
function viewEmbed(file) {
  let component = null;

  switch (file.hostName) {
    case "dropbox":
      component = DropboxEmbedViewer;
      break;
    case "google":
    case "microsoft":
      component = IFrameEmbedViewer;
      break;
    case "tower_hunt":
    default:
      uploadedFileStore.viewEmbed(file);
  }

  if (component) {
    modalPayload.value = {
      size: "xl",
      theme: "dark",
      component: markRaw(component),
      props: { file, document: file },
    };
  }
}
function embedFolders() {
  if (supported.value) {
    clearEmbeds();
    folders.value.forEach((folderDataField) => {
      const folder = folderDataField.fieldContent;
      if (folder.hostName === "dropbox") {
        const element = document.getElementById(folder.id);
        var options = {
          // Shared link to Dropbox file
          link: folder.link,
          file: {
            // Sets the zoom mode for embedded files. Defaults to 'best'.
            zoom: "best", // or "fit"
          },
          folder: {
            // Sets the view mode for embedded folders. Defaults to 'list'.
            view: "list", // or "grid"
            headerSize: "small", // or "small"
          },
        };

        if (element) {
          const embed = Dropbox.embed(options, element);

          embeds.value.push({ embed, id: folder.id });
        }
      }
    });
  }
}
function clearEmbeds() {
  if (embeds.value.length > 0) {
    embeds.value.forEach((embed) => {
      Dropbox.unmount(embed.embed);
    });
    embeds.value = [];
  }
}
</script>
