<template>
  <VDropdown placement="bottom-end">
    <button
      class="max-w-xs flex items-center text-sm rounded-full focus:outline-none focus:ring"
      data-test="user-menu-button"
    >
      <AvatarPhoto :person="currentUser" shape-size="h-8 w-8" text-size="md" />
    </button>

    <template #popper>
      <div
        :class="isDesktop ? 'pb-1' : 'pt-2'"
        class="overflow-hidden rounded-md bg-white ring-1 ring-black ring-opacity-5"
      >
        <template v-if="isDesktop">
          <a
            v-close-popper
            href=""
            class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
            @click.prevent="viewAccount"
            data-test="my-account-button"
          >
            My Account
          </a>
          <a
            v-if="currentUser?.linkedContactId"
            v-close-popper
            href=""
            class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
            @click.prevent="viewLinkedContact"
            data-test="linked-contact-button"
          >
            Linked contact profile
          </a>
          <router-link
            v-if="currentUser.admin"
            v-close-popper
            :to="{
              name: 'AdminStats',
              query: { horizontalTab: 'Me', verticalTab: 'Tasks' },
            }"
            class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
            data-test="admin-dashboard-button"
          >
            Admin mode
          </router-link>
        </template>
        <template v-else>
          <a
            v-for="page in mobilePages"
            :key="page.name"
            @click.prevent="page.handler"
            :href="page.href"
            class="-m-3 flex items-center rounded-md px-4 py-2 hover:bg-gray-50"
          >
            <component
              :is="page.icon"
              class="h-6 w-6 flex-shrink-0 text-indigo-600"
              aria-hidden="true"
            />
            <span class="ml-3 text-sm font-medium text-gray-900">{{
              page.name
            }}</span>
          </a>
        </template>
        <a
          v-close-popper
          @click.prevent="signOut"
          href=""
          class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
          data-test="sign-out-button"
        >
          Sign out
        </a>
      </div>
    </template>
  </VDropdown>
</template>

<script setup>
import { useUserStore } from "@/stores/user";
import { useAuthStore } from "@/stores/auth";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { useMobileNavigationStore } from "@/stores/mobileNavigation";
import { storeToRefs } from "pinia";
import AvatarPhoto from "@/components/users/AvatarPhoto.vue";
import { useRoute, useRouter } from "vue-router";
import _ from "lodash";

const authStore = useAuthStore();
const userStore = useUserStore();
const { currentUser } = storeToRefs(userStore);
const layoutStore = useWorkspaceLayoutStore();
const { isDesktop } = storeToRefs(layoutStore);
const mobileNavigationStore = useMobileNavigationStore();
const { mobilePages } = storeToRefs(mobileNavigationStore);

const router = useRouter();
const route = useRoute();

function viewLinkedContact() {
  if (currentUser.value?.linkedContactId) {
    router.push({
      name: "ContactShell",
      params: { contactId: currentUser.value.linkedContactId },
      query: {
        ...route.query,
        propertyIds: undefined,
      },
    });
  }
}

function viewAccount() {
  if (
    !_.includes(
      [
        "HelpCenter",
        "HelpCategories",
        "HelpArticle",
        "TermsOfService",
        "PrivacyPolicy",
        "LegalCenter",
        "LegalShell",
      ],
      route.name,
    )
  ) {
    router.push({
      name: route.name,
      query: {
        horizontalTab: "Account",
        verticalTab: "Settings",
      },
    });
  } else {
    router.push({
      name: "MainMap",
      query: {
        horizontalTab: "Account",
        verticalTab: "Settings",
      },
    });
  }
}
function signOut() {
  authStore.signOut();
}
</script>
