<template>
  <div class="bg-white overflow-hidden">
    <div class="p-2">
      <dl class="grid grid-cols-2 gap-x-4 gap-y-8">
        <PrincipalsDetail
          transaction-context="capMarkets"
          transaction-side="supply"
          display-context="details-panel"
          :asset-object="assetObject"
          :investment="investment"
        />
        <PrincipalsDetail
          transaction-context="capMarkets"
          transaction-side="demand"
          display-context="details-panel"
          :asset-object="assetObject"
          :investment="investment"
        />
        <AdvisorsDetail
          :assetObject="assetObject"
          :investment="investment"
          :fetch-milliseconds="fetchMilliseconds(investment, asOfMilliseconds)"
          clientRole="capitalConsumer"
          context="investment-players"
        />
        <AdvisorsDetail
          :assetObject="assetObject"
          :investment="investment"
          :fetch-milliseconds="fetchMilliseconds(investment, asOfMilliseconds)"
          clientRole="capitalProvider"
          context="investment-players"
        />
        <!-- TODO: REFACTOR REFETCHING TO TRIGGER IN PRINCIPALSDETAIL -->
        <ProspectsDetail
          v-if="
            focalInvestment(investment, dealBuilder)?.existingInvestment?.id ||
            focalInvestment(investment, dealBuilder)?.id
          "
          :assetObject="assetObject"
          :investment="investment"
          :fetch-milliseconds="fetchMilliseconds(investment, asOfMilliseconds)"
          @refetch="fetchInvolvedCompanies"
          @override-refetch="fetchInvolvedCompanies"
        />
      </dl>
    </div>
  </div>
</template>

<script setup>
import AdvisorsDetail from "@/components/deal-builder/AdvisorsDetail.vue";
import ProspectsDetail from "@/components/deal-builder/ProspectsDetail.vue";
import PrincipalsDetail from "@/components/deal-builder/PrincipalsDetail.vue";
import { useDealBuilderStore } from "@/stores/dealBuilder";
import { useTimeTravelStore } from "@/stores/timeTravel";
import { focalInvestment } from "@/assets/investmentHelpers";
import { fetchMilliseconds } from "@/assets/transactionHelpers";
import { storeToRefs } from "pinia";

defineProps(["assetObject", "investment"]);

const dealBuilderStore = useDealBuilderStore();
const { dealBuilder } = storeToRefs(dealBuilderStore);
const timeTravelStore = useTimeTravelStore();
const { asOfMilliseconds } = storeToRefs(timeTravelStore);

async function fetchInvolvedCompanies() {
  console.log("trigger inside of principals detail");
}
</script>
