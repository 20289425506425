<template>
  <div class="space-y-4" :class="isAdmin ? '' : 'p-2'">
    <SelectedInputPayloadItemSummary
      :local-item="localItem"
      :closeable="closeable"
      :preemptions="preemptions"
      @close-panel="closePanel"
    />

    <!-- Item datafields -->
    <div v-if="actionableItem">
      <h2 class="text-sm/6 font-semibold text-gray-900">Datapoints</h2>
      <SelectedPayloadItemTable
        v-if="displayDatapointsTable"
        :payload-item="actionableItem"
      />
    </div>

    <!-- Activity Feed -->
    <div v-if="false && actionableItem">
      <h2 class="text-sm/6 font-semibold text-gray-900">Activity</h2>
      <ul role="list" class="mt-4 space-y-4">
        <li
          v-for="(activityItem, activityItemIdx) in activity"
          :key="activityItem.id"
          class="relative flex gap-x-4"
        >
          <div
            :class="[
              activityItemIdx === activity.length - 1 ? 'h-4' : '-bottom-4',
              'absolute left-0 top-0 flex w-4 justify-center',
            ]"
          >
            <div class="w-px bg-gray-200" />
          </div>
          <template v-if="activityItem.type === 'commented'">
            <img
              :src="activityItem.person.imageUrl"
              alt=""
              class="relative mt-3 h-4 w-4 flex-none rounded-full bg-gray-50"
            />
            <div
              class="flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200"
            >
              <div class="flex justify-between gap-x-4">
                <div class="py-0.5 text-xs/5 text-gray-500">
                  <span class="font-medium text-gray-900">{{
                    activityItem.person.name
                  }}</span>
                  commented
                </div>
                <time
                  :datetime="activityItem.dateTime"
                  class="flex-none py-0.5 text-xs/5 text-gray-500"
                  >{{ activityItem.date }}</time
                >
              </div>
              <p class="text-sm/6 text-gray-500">
                {{ activityItem.comment }}
              </p>
            </div>
          </template>
          <template v-else>
            <div
              class="relative flex h-4 w-4 flex-none items-center justify-center bg-white"
            >
              <CheckCircleIcon
                v-if="activityItem.type === 'paid'"
                class="h-4 w-4 text-indigo-600"
                aria-hidden="true"
              />
              <div
                v-else
                class="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300"
              />
            </div>
            <p class="flex-auto py-0.5 text-xs/5 text-gray-500">
              <span class="font-medium text-gray-900">{{
                activityItem.person.name
              }}</span>
              {{ activityItem.type }} the invoice.
            </p>
            <time
              :datetime="activityItem.dateTime"
              class="flex-none py-0.5 text-xs/5 text-gray-500"
              >{{ activityItem.date }}</time
            >
          </template>
        </li>
      </ul>

      <!-- New comment form -->
      <div v-if="false" class="mt-4 flex gap-x-3">
        <img
          src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
          alt=""
          class="h-4 w-4 flex-none rounded-full bg-gray-50"
        />
        <form @submit.prevent class="relative flex-auto">
          <div
            class="overflow-hidden rounded-lg pb-12 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600"
          >
            <label for="comment" class="sr-only">Add your comment</label>
            <textarea
              rows="2"
              name="comment"
              id="comment"
              class="block w-full resize-none border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm/6"
              placeholder="Add your comment..."
            />
          </div>

          <div
            class="absolute inset-x-0 bottom-0 flex justify-between py-2 pl-3 pr-2"
          >
            <div class="flex items-center space-x-5">
              <div class="flex items-center">
                <button
                  type="button"
                  class="-m-2.5 flex h-10 w-10 items-center justify-center rounded-full text-gray-400 hover:text-gray-500"
                >
                  <PaperClipIcon class="h-5 w-5" aria-hidden="true" />
                  <span class="sr-only">Attach a file</span>
                </button>
              </div>
            </div>
            <button
              type="submit"
              class="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              Comment
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script setup>
import { PaperClipIcon, CheckCircleIcon } from "@heroicons/vue/20/solid";
import SelectedPayloadItemTable from "@/components/crowdsourcing/contributions/SelectedPayloadItemTable.vue";
import { ref, computed, watch, onMounted, nextTick } from "vue";
import { storeToRefs } from "pinia";
import { useUserStore } from "@/stores/user";
import { useEasyDataInputAdminStore } from "@/stores/easyDataInputAdmin";
import api from "@/router/api";
import SelectedInputPayloadItemSummary from "./SelectedInputPayloadItemSummary.vue";

const props = defineProps(["localItem", "closeable", "preemptions"]);
const emit = defineEmits(["close-panel"]);

const userStore = useUserStore();
const { activeEasyDataInputPayloadItem, isAdmin } = storeToRefs(userStore);
const easyDataInputAdminStore = useEasyDataInputAdminStore();
const { selectedItem } = storeToRefs(easyDataInputAdminStore);

const displayDatapointsTable = ref(true);
const actionableItem = computed(() => {
  if (isAdmin.value) {
    return selectedItem.value;
  } else {
    return props.localItem || activeEasyDataInputPayloadItem.value;
  }
});
const actionableItemId = computed(() => actionableItem.value?.id);

const itemFields = ref([]);

const activity = [
  {
    id: 1,
    type: "created",
    person: { name: "Chelsea Hagon" },
    date: "7d ago",
    dateTime: "2023-01-23T10:32",
  },
  {
    id: 2,
    type: "edited",
    person: { name: "Chelsea Hagon" },
    date: "6d ago",
    dateTime: "2023-01-23T11:03",
  },
  {
    id: 3,
    type: "sent",
    person: { name: "Chelsea Hagon" },
    date: "6d ago",
    dateTime: "2023-01-23T11:24",
  },
  {
    id: 4,
    type: "commented",
    person: {
      name: "Chelsea Hagon",
      imageUrl:
        "https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
    comment:
      "Called client, they reassured me the invoice would be paid by the 25th.",
    date: "3d ago",
    dateTime: "2023-01-23T15:56",
  },
  {
    id: 5,
    type: "viewed",
    person: { name: "Alex Curren" },
    date: "2d ago",
    dateTime: "2023-01-24T09:12",
  },
  {
    id: 6,
    type: "paid",
    person: { name: "Alex Curren" },
    date: "1d ago",
    dateTime: "2023-01-24T09:20",
  },
];

watch(actionableItemId, () => {
  if (actionableItem.value) {
    fetchFields();
  }
});

onMounted(() => {
  if (actionableItem.value) {
    fetchFields();
  }
});

async function fetchFields() {
  if (actionableItem.value) {
    displayDatapointsTable.value = false;
    const response = await api.get(
      `payload_item_data_fields/${actionableItem.value?.id}`,
    );

    if (response?.data) {
      itemFields.value = response.data;
      await nextTick();
      displayDatapointsTable.value = true;
    }
  }
}

function closePanel() {
  if (isAdmin.value) {
    selectedItem.value = null;
  } else if (props.localItem) {
    emit("close-panel");
  }
}
</script>
