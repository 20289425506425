<template>
  <div v-if="stats.length > 0" class="mt-4 p-2">
    <div class="flex items-center">
      <div class="flex-auto">
        <h1 class="text-base font-semibold leading-6 text-gray-900">
          Summary Metrics
        </h1>
        <p class="mt-2 text-sm text-gray-700">
          Use these calculated datapoints for context.
        </p>
      </div>
    </div>

    <div
      :class="
        workspaceLayout === 'sideBySide'
          ? 'grid-cols-2'
          : 'grid-cols-5 grid-flow-row-dense'
      "
      class="mt-6 grid gap-3"
    >
      <component
        v-for="(item, index) in stats"
        :key="index"
        :is="item.component"
      />
    </div>
  </div>
</template>

<script setup>
import BuildingCount from "@/components/analyze/calculations/BuildingCount.vue";
import InvestmentCount from "@/components/analyze/calculations/InvestmentCount.vue";
import LoanCount from "@/components/analyze/calculations/LoanCount.vue";
import PropertyCount from "@/components/analyze/calculations/PropertyCount.vue";
import SizesUses from "@/components/analyze/calculations/SizesUses.vue";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { storeToRefs } from "pinia";
import { useAnalyzePanelStore } from "@/stores/analyzePanel";
import { computed, markRaw } from "vue";
import { useRoute } from "vue-router";
import _ from "lodash";
import InvestmentVolume from "@/components/analyze/calculations/InvestmentVolume.vue";
import SpaceAvailabilityCount from "./calculations/SpaceAvailabilityCount.vue";

const layoutStore = useWorkspaceLayoutStore();
const { workspaceLayout } = storeToRefs(layoutStore);

const analyzePanelStore = useAnalyzePanelStore();
const {
  unselectedPropertyDiagram,
  propertySelectedPropertyDiagram,
  unselectedCompanyDetail,
  unselectedContactDetail,
  countable,
  sizeable,
  sizesCategory,
  investmentsChecked,
  spaceAvailabilitiesChecked,
  spaceUsagesChecked,
  loansChecked,
} = storeToRefs(analyzePanelStore);

const route = useRoute();
const routeName = computed(() => route.name);

const stats = computed(() => {
  const propertyCountComponent =
    sizesCategory.value.checked &&
    (routeName.value === "MainMap" ||
      propertySelectedPropertyDiagram.value ||
      unselectedPropertyDiagram.value ||
      unselectedCompanyDetail.value ||
      unselectedContactDetail.value)
      ? {
          component: markRaw(PropertyCount),
        }
      : null;
  const buildingCountComponent =
    sizesCategory.value.checked &&
    (routeName.value === "MainMap" || countable.value)
      ? {
          component: markRaw(BuildingCount),
        }
      : null;
  const sizeUseComponent =
    (sizesCategory.value.checked ||
      spaceAvailabilitiesChecked.value ||
      spaceUsagesChecked.value) &&
    (routeName.value === "MainMap" || sizeable.value)
      ? {
          component: markRaw(SizesUses),
        }
      : null;
  const spaceAvailabilityCountComponent = spaceAvailabilitiesChecked.value
    ? { component: markRaw(SpaceAvailabilityCount) }
    : null;
  const investmentCountComponent =
    investmentsChecked.value &&
    (routeName.value === "MainMap" ||
      unselectedCompanyDetail.value ||
      unselectedContactDetail.value)
      ? { component: markRaw(InvestmentCount) }
      : null;
  const investmentVolumeComponent =
    investmentsChecked.value &&
    (routeName.value === "MainMap" ||
      unselectedCompanyDetail.value ||
      unselectedContactDetail.value)
      ? { component: markRaw(InvestmentVolume) }
      : null;
  const loanCountComponent =
    loansChecked.value &&
    (routeName.value === "MainMap" ||
      unselectedCompanyDetail.value ||
      unselectedContactDetail.value)
      ? { component: markRaw(LoanCount) }
      : null;
  return _.compact([
    spaceAvailabilityCountComponent,
    investmentCountComponent,
    investmentVolumeComponent,
    loanCountComponent,
    propertyCountComponent,
    buildingCountComponent,
    sizeUseComponent,
  ]);
});
</script>
