<template>
  <div class="flex flex-shrink-0 items-center space-x-2">
    <DataVisibilityButton
      v-if="!creatingContact"
      visibility="safezone"
      :tooltip="addContactTooltip"
      class="mr-1 flex-shrink-0 inline-flex"
    >
      <template v-slot:button>
        <button
          @click="addContact"
          type="button"
          :data-test="`${dataField?.decoratingContentType}-${dataField?.decoratingContentId}-company-${dataField?.fieldContentId}-add-contacts-button`"
          class="mr-1 flex-shrink-0 bg-white inline-flex h-6 w-6 items-center justify-center rounded-full border-2 border-dashed border-yellow-300 text-yellow-400 hover:text-yellow-500 hover:border-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
        >
          <span class="sr-only">Add contact</span>
          <UserPlusIcon class="h-4 w-4" />
        </button>
      </template>
    </DataVisibilityButton>

    <div class="flex flex-shrink-0 -space-x-0.5" style="width: 84px">
      <ContactDataField
        v-for="contactDataField in contacts"
        :key="`content-${dataField.fieldContentId}-contact-${contactDataField.localId}`"
        :data-field="contactDataField"
        :input-key="`contact-${context}s`"
        @completed="refetchAll"
        @unlocked="refetchAll"
      />
    </div>
  </div>

  <div v-if="creatingContact" class="mt-1 flex flex-col space-y-1">
    <div
      class="border border-gray-300 rounded-lg shadow-sm focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500"
    >
      <ContactAutocomplete
        :label="`${context}s`"
        :selections="addedContacts"
        :input-key="`contact-${context}s`"
        :focus="true"
        :remove-inline="true"
        @new-contacts="setNewContacts"
        @remove="removeContact"
      />
    </div>
    <div class="flex items-center justify-end space-x-2">
      <button
        @click="clearContactEditing"
        type="button"
        class="inline-flex items-center p-1 border border-gray-300 rounded-full shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        <XMarkIcon class="h-4 w-4" />
      </button>

      <DataVisibilityButton visibility="safezone" class="inline-flex">
        <template v-slot:button>
          <button
            @click="saveContacts"
            :disabled="originatingData"
            type="button"
            class="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-yellow-500 hover:bg-yellow-600 focus:ring-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2"
            :data-test="`contact-${context}s-save`"
          >
            <PulseLoader
              v-if="originatingData"
              :loading="true"
              size="3px"
              color="#f3f4f6"
            />
            <CheckIcon v-else class="h-4 w-4" />
          </button>
        </template>
      </DataVisibilityButton>
    </div>
  </div>
</template>

<script setup>
import { UserPlusIcon, XMarkIcon, CheckIcon } from "@heroicons/vue/20/solid";
import ContactDataField from "@/components/crowdsourcing/ContactDataField.vue";
import ContactAutocomplete from "@/components/crowdsourcing/ContactAutocomplete.vue";
import DataVisibilityButton from "@/components/crowdsourcing/DataVisibilityButton.vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { useCrowdsourcedChangeGroupStore } from "@/stores/crowdsourcedChangeGroup";
import { storeToRefs } from "pinia";
import { ref, computed, watch, onMounted } from "vue";
import api from "@/router/api";
import _ from "lodash";

const props = defineProps(["dataField", "contentDataField", "context"]);

const changeGroupStore = useCrowdsourcedChangeGroupStore();
const { originatingData, changeGroupId } = storeToRefs(changeGroupStore);

const creatingContact = ref(false);
const addedContacts = ref([]);
const contacts = ref([]);

const decoratingContent = computed(() => {
  if (
    props.dataField.fieldContentType === "Hunt" &&
    props.dataField.decoratingContentType === "CompanyInvolvement"
  ) {
    return {
      contentId: props.dataField.decoratingContentId,
      contentType: props.dataField.decoratingContentType,
    };
  } else if (props.dataField.fieldContentType === "HuntGeographyIntent") {
    return {
      contentId: props.dataField.fieldContentId,
      contentType: props.dataField.fieldContentType,
    };
  } else {
    return null;
  }
});
const combinedId = computed(
  () => `${props.dataField.fieldContentType}${props.dataField.fieldContentId}`,
);
const addContactTooltip = computed(() => {
  if (_.includes(["Hunt"], props.dataField.fieldContentType)) {
    return "Add contacts who oversee this pursuit.";
  } else if (
    _.includes(["HuntGeographyIntent"], props.dataField.fieldContentType)
  ) {
    return "Add contacts who manage this specific market.";
  } else {
    return "Add contacts.";
  }
});

const companyInvolvementId = computed(() => {
  if (props.dataField.fieldContentType === "HuntGeographyIntent") {
    return props.contentDataField.decoratingContentId;
  } else {
    return null;
  }
});

watch(combinedId, () => {
  fetchInvolvedContacts();
});

onMounted(() => {
  fetchInvolvedContacts();
});

function refetchAll() {
  fetchInvolvedContacts();
}
function addContact() {
  creatingContact.value = true;
}
function setNewContacts(newContacts) {
  addedContacts.value = newContacts;
}
function removeContact(contact) {
  const filteredContacts = _.differenceBy(
    addedContacts.value,
    [contact],
    "name",
  );

  setNewContacts(filteredContacts);
}
function clearContactEditing() {
  creatingContact.value = false;
  addedContacts.value = [];
}
async function contactCreated(json) {
  console.log(json?.data);
  clearContactEditing();
  fetchInvolvedContacts();
}
async function fetchInvolvedContacts() {
  if (decoratingContent.value) {
    api
      .get(
        `crowdsourced_data_fields/${decoratingContent.value.contentType}/${decoratingContent.value.contentId}?field_name=ContactCompanyInvolvement`,
      )
      .then(async (json) => {
        contacts.value = json.data;
      });
  }
}

function saveContacts() {
  setTimeout(() => {
    saveInvolvementContacts();
  }, 125);
}
function saveInvolvementContacts() {
  const apiRequestFunc = () => persistInvolvementContacts();
  const successCallback = (json) => contactCreated(json);
  const failureCallback = () => clearContactEditing();

  return changeGroupStore.originateData(
    apiRequestFunc,
    successCallback,
    failureCallback,
  );
}
const persistInvolvementContacts = async (proofContacts = null) => {
  if (decoratingContent.value) {
    const payload = {
      contentType: decoratingContent.value.contentType,
      contentId: decoratingContent.value.contentId,
      contacts: proofContacts || addedContacts.value,
      companyInvolvementId: companyInvolvementId.value,
      changeGroupId: changeGroupId.value,
    };
    const response = await api.post(`contacts`, payload);

    return response;
  }
};
</script>
