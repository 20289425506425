<template>
  <div v-if="displayHeadcountAssumptions" class="flex flex-col space-y-2">
    <div class="text-sm font-medium text-gray-500">
      Area Conversion Assumptions
    </div>
    <div class="text-xs font-medium text-gray-500">
      Convert to SF via a density range plus a % modifier.
    </div>
    <div class="grid grid-cols-3 gap-x-2 gap-y-2 rounded-md bg-teal-100 p-2">
      <div v-tooltip="'Min SF per person'" class="">
        <label
          :for="`headcount-min-density`"
          class="block text-sm font-medium leading-6 text-gray-500"
          >Min. SF/P</label
        >
        <div class="mt-1">
          <input
            v-model="minDensity"
            type="number"
            :name="`headcount-min-density`"
            :id="`headcount-min-density`"
            class="block w-full rounded-md border-0 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6"
          />
        </div>
      </div>
      <div v-tooltip="'Max SF per person'" class="">
        <label
          :for="`headcount-max-density`"
          class="block text-sm font-medium leading-6 text-gray-500"
          >Max. SF/P</label
        >
        <div class="mt-1">
          <input
            v-model="maxDensity"
            type="number"
            :name="`headcount-max-density`"
            :id="`headcount-max-density`"
            class="block w-full rounded-md border-0 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6"
          />
        </div>
      </div>
      <div v-tooltip="'Margin of Error (%)'" class="">
        <label
          :for="`headcount-density-percent-modifier`"
          class="block text-sm font-medium leading-6 text-gray-500"
          >MoE %</label
        >
        <div class="relative mt-1 rounded-md">
          <input
            v-model="densityPercentModifier"
            type="number"
            :name="`headcount-density-percent-modifier`"
            :id="`headcount-density-percent-modifier`"
            class="block w-full rounded-md border-0 py-1.5 pr-8 text-gray-500 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6"
          />
          <div
            class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
          >
            <span
              class="text-gray-500 sm:text-sm"
              id="density-percent-modifier-percent"
              >%</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useOccupierSpaceDefinitionStore } from "@/stores/occupierSpaceDefinition";
import { computed } from "vue";

const occupierSpaceStore = useOccupierSpaceDefinitionStore();
const { displayHeadcountAssumptions, headCountToAreaAssumptions } =
  storeToRefs(occupierSpaceStore);

const minDensity = computed({
  get() {
    return headCountToAreaAssumptions.value.minAreaPerPerson;
  },
  set(n) {
    headCountToAreaAssumptions.value.minAreaPerPerson = n;
  },
});
const maxDensity = computed({
  get() {
    return headCountToAreaAssumptions.value.maxAreaPerPerson;
  },
  set(n) {
    headCountToAreaAssumptions.value.maxAreaPerPerson = n;
  },
});
const densityPercentModifier = computed({
  get() {
    return headCountToAreaAssumptions.value.densityPercentModifier;
  },
  set(n) {
    headCountToAreaAssumptions.value.densityPercentModifier = n;
  },
});
</script>
