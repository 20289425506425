<template>
  <div
    v-observe-visibility="{ callback: selectiveFocus, once: true }"
    class="rounded-md border-l-2 p-1"
    :class="
      validStep
        ? 'border-yellow-500 bg-yellow-100'
        : 'border-red-500 bg-red-100'
    "
  >
    <div class="ml-2 py-1 pr-1 flex flex-col space-y-1">
      <div class="flex items-center justify-between">
        <div class="" />
        <div class="flex items-center space-x-2">
          <button
            v-if="periodKey !== 0"
            @click="cashflowDefinitionStore.removeScheduleItem(periodKey)"
            type="button"
            class="inline-flex items-center text-gray-700 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
          >
            <XMarkIcon class="h-4 w-4" />
          </button>
        </div>
      </div>
      <div class="grid grid-cols-3 gap-2">
        <div>
          <label
            :for="`period-${periodKey}-period-date`"
            class="block text-sm font-medium leading-6 text-gray-900"
            >Date</label
          >
          <div class="relative mt-1 rounded-md shadow-sm">
            <div class="absolute inset-y-0 left-0 flex items-center">
              <label
                :for="`period-${periodKey}-period-date-type`"
                class="sr-only"
                >Date Type</label
              >
              <select
                @change="setDateType($event)"
                :id="`period-${periodKey}-period-date-type`"
                :name="`period-${periodKey}-period-date-type`"
                :autocomplete="`period-${periodKey}-period-date-type`"
                class="h-full rounded-md border-0 bg-transparent py-0 pl-3 pr-7 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
              >
                <option value="Relative">Relative</option>
                <option value="Actual">Actual</option>
              </select>
            </div>
            <input
              v-if="dateType === 'Actual'"
              v-model="date"
              type="date"
              :name="`period-${periodKey}-period-date`"
              :id="`period-${periodKey}-period-date`"
              class="block w-full rounded-md border-0 py-1.5 pl-24 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
              :class="
                validDate ? 'focus:ring-yellow-600' : 'focus:ring-red-600'
              "
            />
            <template v-else>
              <div
                class="pointer-events-none absolute inset-y-0 left-24 flex items-center pl-1"
              >
                <span class="text-gray-500 sm:text-sm">Mth</span>
              </div>
              <input
                v-model="date"
                type="number"
                min="1"
                step="1"
                :name="`period-${periodKey}-period-date`"
                :id="`period-${periodKey}-period-date`"
                class="block w-full rounded-md border-0 py-1.5 pl-36 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                :class="
                  validDate ? 'focus:ring-yellow-600' : 'focus:ring-red-600'
                "
                placeholder="Month number"
              />
            </template>
          </div>
        </div>
        <div class="">
          <label
            :for="`period-${periodKey}-input-type`"
            class="block text-sm font-medium leading-6 text-gray-500"
            >Type</label
          >
          <div class="mt-1">
            <select
              v-model="inputType"
              :name="`period-${periodKey}-input-type`"
              :id="`period-${periodKey}-input-type`"
              class="block w-full shadow-sm pl-3 pr-10 py-2 text-gray-500 border-gray-300 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 text-sm rounded-md"
            >
              <option
                v-for="option in inputTypes"
                :key="option.value"
                :value="option.value"
              >
                {{ option.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="">
          <label
            :for="`period-${periodKey}-cashflow-amount`"
            class="block text-sm font-medium leading-6 text-gray-500"
            >{{
              inputType === "annualPercentageIncrease" ? "%" : "$"
            }}
            Amount</label
          >
          <div class="mt-1">
            <input
              v-model="inputValue"
              type="number"
              :name="`period-${periodKey}-cashflow-amount`"
              :id="`period-${periodKey}-cashflow-amount`"
              class="block w-full rounded-md border-0 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
              :class="
                validInput ? 'focus:ring-yellow-600' : 'focus:ring-red-600'
              "
            />
          </div>
        </div>
        <p
          v-if="validationHelp"
          class="col-span-3 text-sm text-red-600"
          id="step-error"
        >
          {{ validationHelp }}
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { XMarkIcon } from "@heroicons/vue/20/solid";
import { useCashflowDefinitionStore } from "@/stores/cashflowDefinition";
import { computed, watch } from "vue";
import _ from "lodash";
import dateValid from "@/assets/dateValid";
import moment from "moment";

const props = defineProps(["periodKey", "period", "dateFields", "perAreaSize"]);

const cashflowDefinitionStore = useCashflowDefinitionStore();

const inputValue = computed({
  get() {
    return cashflowDefinitionStore.getScheduleItem(props.periodKey)?.inputValue;
  },
  set(n) {
    if (cashflowDefinitionStore.getScheduleItem(props.periodKey)) {
      cashflowDefinitionStore.getScheduleItem(props.periodKey).inputValue = n;
    }
  },
});
const inputType = computed({
  get() {
    return cashflowDefinitionStore.getScheduleItem(props.periodKey)?.inputType;
  },
  set(n) {
    if (cashflowDefinitionStore.getScheduleItem(props.periodKey)) {
      cashflowDefinitionStore.getScheduleItem(props.periodKey).inputType = n;
    }
  },
});
const dateType = computed({
  get() {
    return cashflowDefinitionStore.getScheduleItem(props.periodKey)?.dateType;
  },
  set(newType) {
    if (cashflowDefinitionStore.getScheduleItem(props.periodKey)) {
      cashflowDefinitionStore.getScheduleItem(props.periodKey).dateType =
        newType;
    }
  },
});
function setDateType(event) {
  const newDateType = event.target.value;

  if (newDateType === "Relative" && props.periodKey === 0) {
    date.value = 1;
  } else {
    date.value = null;
  }

  dateType.value = newDateType;
}
const date = computed({
  get() {
    return cashflowDefinitionStore.getScheduleItem(props.periodKey)?.date;
  },
  set(n) {
    if (cashflowDefinitionStore.getScheduleItem(props.periodKey)) {
      cashflowDefinitionStore.getScheduleItem(props.periodKey).date = n;
    }
  },
});
const previousDate = computed(() => {
  if (props.periodKey === 0) {
    return props.dateFields.commenced_date?.fieldDate
      ? moment(props.dateFields.commenced_date.fieldDate)
      : null;
  } else {
    return cashflowDefinitionStore.getScheduleItem(props.periodKey - 1)?.date;
  }
});
const dateTooEarly = computed(() => {
  if (props.periodKey === 0) {
    return moment(previousDate.value).isAfter(moment(date.value), "day");
  } else if (previousDate.value) {
    return moment(previousDate.value).isSameOrAfter(moment(date.value), "day");
  } else {
    return true;
  }
});
const validDate = computed(() => {
  if (dateType.value === "Actual") {
    return dateValid(date.value) && !dateTooEarly.value;
  } else if (dateType.value === "Relative") {
    return !!date.value && date.value > 0;
  } else {
    return false;
  }
});
const validInput = computed(() => {
  if (inputValue.value) {
    switch (inputType.value) {
      case "annualPerArea":
      case "monthlyPerArea":
        return inputValue.value <= 10_000;
      case "annual":
      case "monthly":
        return inputValue.value >= 100;
      case "annualDollarIncrease":
        return inputValue.value <= 100;
      default:
        return true;
    }
  } else {
    return false;
  }
});
const validStep = computed(() => validDate.value && validInput.value);
const validationHelp = computed(() => {
  if (validStep.value) {
    return null;
  } else if (!validDate.value) {
    if (dateType.value === "Relative") {
      return "Date must be a month number";
    } else if (dateTooEarly.value) {
      if (props.periodKey === 0 && props.dateFields.commenced_date?.fieldDate) {
        return "Date must be later than the LCD";
      } else {
        return "Date must be later than the preceding step's date";
      }
    } else {
      return "Date invalid";
    }
  } else if (!validInput.value) {
    if (inputValue.value) {
      switch (inputType.value) {
        case "annualPerArea":
        case "monthlyPerArea":
          return "Amount should be less than $10,000";
        case "annual":
        case "monthly":
          return "Amount should be greater than $100";
        case "annualDollarIncrease":
          return "Amount should be less than $100";
        default:
          return "Unknown amount error";
      }
    } else {
      return "Please enter a dollar amount";
    }
  } else {
    return "Unknown error";
  }
});
const inputTypes = computed(() =>
  _.compact([
    { name: "$/Yr", value: "annual" },
    props.perAreaSize ? { name: "$/SF/Yr", value: "annualPerArea" } : null,
    props.periodKey > 0
      ? { name: "$ Bump/Yr", value: "annualDollarIncrease" }
      : null,
    props.periodKey > 0
      ? { name: "% Bump/Yr", value: "annualPercentageIncrease" }
      : null,
    { name: "$/Mth", value: "monthly" },
    props.perAreaSize ? { name: "$/SF/Mth", value: "monthlyPerArea" } : null,
  ]),
);

watch(validStep, () => {
  if (cashflowDefinitionStore.getScheduleItem(props.periodKey)) {
    cashflowDefinitionStore.getScheduleItem(props.periodKey).valid =
      validStep.value;
  }
});

function selectiveFocus(isVisible) {
  if (isVisible) {
    setTimeout(() => {
      selectInput();
    }, 100);
  }
}
function selectInput() {
  document
    .getElementById(`period-${props.periodKey}-period-date-type`)
    ?.focus();
}
</script>
