<template>
  <div class="bg-white overflow-hidden divide-y divide-gray-200">
    <div class="flex items-center justify-between p-2 bg-gray-100">
      <div class="text-gray-700 text-xs font-semibold uppercase tracking-wide">
        Unlocated Spaces
      </div>
      <div class="flex items-center space-x-2">
        <button
          @click="expanded = !expanded"
          v-tooltip="expanded ? 'Minimize' : 'Expand'"
          type="button"
          class="h-5 w-5 p-1 inline-flex justify-center items-center text-sm text-gray-500 hover:text-gray-700"
        >
          <i v-if="expanded" class="fas fa-compress" />
          <i v-else class="fas fa-expand" />
        </button>
      </div>
    </div>
    <template v-if="expanded">
      <UnlocatedSpaceDetail
        v-for="(space, index) in spaces"
        :key="index"
        :space="space"
        :space-object="spaceObject"
        @refetch="refetch"
      />
    </template>
  </div>
</template>

<script setup>
import UnlocatedSpaceDetail from "@/components/space-usage-builder/UnlocatedSpaceDetail.vue";
import { useSpaceUsageBuilderStore } from "@/stores/spaceUsageBuilder";
import { usePropertyDiagramStore } from "@/stores/propertyDiagram";
import { useTimeTravelStore } from "@/stores/timeTravel";
import { storeToRefs } from "pinia";
import { ref, computed, watch, onMounted, nextTick } from "vue";
import decoratingAndFieldKey from "@/components/crowdsourcing/decoratingAndFieldKey";
import api from "@/router/api";
import _ from "lodash";

const props = defineProps(["spaceObject"]);

const timeTravelStore = useTimeTravelStore();
const { asOfDate, asOfMilliseconds } = storeToRefs(timeTravelStore);
const propertyDiagramStore = usePropertyDiagramStore();
const { propertyIdParam, editingMode } = storeToRefs(propertyDiagramStore);
const spaceUsageBuilderStore = useSpaceUsageBuilderStore();
const { availabilityPatchableTimelineEvents } = storeToRefs(
  spaceUsageBuilderStore,
);

const expanded = ref(true);

const spaces = computed(() => {
  return _.get(props.spaceObject, "unlocatedSpaces", []);
});
const landCoveringId = computed(
  () => props.spaceObject.dataField.fieldContentId,
);
const spaceKey = computed(() =>
  decoratingAndFieldKey(props.spaceObject.dataField),
);

watch(asOfDate, (date, oldDate) => {
  if (oldDate && date !== oldDate) {
    spaceUsageBuilderStore.resetAvailabilities();
  }
});

watch(
  availabilityPatchableTimelineEvents,
  async () => {
    const landCoveringTimelineEvents =
      availabilityPatchableTimelineEvents.value.filter(({ dataField }) => {
        return (
          dataField.fieldContentSubType === "imprecise_space_usage" &&
          dataField.decoratingContentType === "LandCovering" &&
          dataField.decoratingContentId === landCoveringId.value
        );
      });

    if (landCoveringTimelineEvents.length > 0) {
      // console.log("WATCHER: refetch unlocated spaces");
      spaceUsageBuilderStore.clearSpaceUnlocatedSpaces({
        spaceKey: spaceKey.value,
      });
      await nextTick();
      fetchSpaces();
    }
  },
  { deep: true },
);

onMounted(async () => {
  fetchSpaces();
});

async function refetch() {
  // console.log("EMITTED refetch unlocated spaces");
  spaceUsageBuilderStore.clearSpaceUnlocatedSpaces({
    spaceKey: spaceKey.value,
  });
  await nextTick();
  fetchSpaces();
  if (propertyIdParam.value) editingMode.value = false;
}

async function fetchSpaces() {
  const spacesResponse = await api.get(
    `land_covering_floor_areas/?land_covering_id=${landCoveringId.value}&as_of=${asOfMilliseconds.value}&sub_type=imprecise_space_usage`,
  );

  if (spacesResponse?.data) {
    spaceUsageBuilderStore.updateSpaceUnlocatedSpaces({
      spaceKey: spaceKey.value,
      unlocatedSpaces: spacesResponse.data,
    });
  }
}
</script>
