<template>
  <div class="bg-white overflow-y-auto h-full">
    <div class="p-2">
      <InvestmentGroupKeyDates
        v-if="existingGroupId"
        :investment-group-id="existingGroupId"
        :timing-fields="timingFields"
        :timing-unlockable="groupTimingUnlockable"
        @unlocked="refetchTimingFieldData"
        @refetch="refetchGroupInvestments"
      />
      <dl v-else class="grid grid-cols-1 gap-x-4 gap-y-8">
        <div class="col-span-1">
          <dt class="text-sm font-medium text-gray-500">Portfolio State</dt>
          <dd
            v-if="homogeneousDateState"
            class="mt-1 flex items-center space-x-2"
          >
            <DateStateField
              event-type="portfolioCapitalEvent"
              :data-field="{ investmentGroupId }"
              :state="state"
              :date="date"
              :compact="false"
              @new-date="setNewDate"
              @new-state="setNewState"
            />
            <button
              v-if="existingGroupId && edited"
              @click="saveDateState"
              type="button"
              v-tooltip="'Save'"
              class="flex-shrink-0 inline-flex justify-center items-center w-6 h-6 border border-transparent rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <CheckIcon class="h-4 w-4" />
            </button>
          </dd>
          <dd v-else class="mt-4 text-center">
            <svg
              class="mx-auto h-8 w-8 text-gray-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
              />
            </svg>
            <h3 class="mt-2 text-sm font-medium text-gray-900">
              Multiple dates/states
            </h3>
            <p class="mt-1 text-sm text-gray-500">
              The investments don't all share the same state. Please edit via
              the asset list.
            </p>
          </dd>
        </div>
      </dl>
    </div>
  </div>
</template>

<script setup>
import { CheckIcon } from "@heroicons/vue/20/solid";
import moment from "moment";
import DateStateField from "@/components/crowdsourcing/DateStateField.vue";
import InvestmentGroupKeyDates from "@/components/deal-builder/InvestmentGroupKeyDates.vue";
import { useDealBuilderStore } from "@/stores/dealBuilder";
import { useCrowdsourcedChangeGroupStore } from "@/stores/crowdsourcedChangeGroup";
import { storeToRefs } from "pinia";
import { ref, computed, onMounted } from "vue";
import _ from "lodash";
import api from "@/router/api";

const props = defineProps(["existingGroupId", "placeholderGroupId"]);

const dealBuilderStore = useDealBuilderStore();
const { dealBuilder } = storeToRefs(dealBuilderStore);
const changeGroupStore = useCrowdsourcedChangeGroupStore();
const { changeGroupId } = storeToRefs(changeGroupStore);

const edited = ref(false);
const groupTimingUnlockable = ref(false);

const homogeneousDateState = computed(() => {
  return (
    groupDateState.value.dates.length <= 1 &&
    groupDateState.value.states.length <= 1
  );
});
const groupDateState = computed(() => {
  const investments = _.map(
    _.get(investmentGroup.value, "investments", []),
    function (groupInvestment) {
      return focalInvestmentFor(groupInvestment);
    },
  );
  const uniqDates = _.uniq(
    investments.map((i) => {
      if (i.date) {
        return moment(i.date).format("YYYYMMDD");
      } else {
        return null;
      }
    }),
  );
  const dates = _.compact(uniqDates);
  const states = _.compact(_.uniq(investments.map((i) => i.state)));

  return { dates, states };
});
const date = computed({
  get() {
    if (groupDateState.value.dates.length === 1) {
      let unparsed = _.head(groupDateState.value.dates);

      return moment(unparsed).toDate();
    } else {
      return null;
    }
  },
  set(newVal) {
    _.forEach(investments.value, function (investment) {
      let newInvestment = _.merge({}, focalInvestmentFor(investment));

      newInvestment.date = newVal;
      updateStoreInvestment(newInvestment, "date");
    });
    edited.value = true;
  },
});
const state = computed({
  get() {
    if (groupDateState.value.states.length === 1) {
      return _.head(groupDateState.value.states);
    } else {
      return null;
    }
  },
  set(newVal) {
    _.forEach(investments.value, function (investment) {
      let newInvestment = _.merge({}, focalInvestmentFor(investment));

      newInvestment.state = newVal;
      updateStoreInvestment(newInvestment, "state");
    });
    edited.value = true;
  },
});
const investmentGroupId = computed(() => {
  return props.existingGroupId || props.placeholderGroupId;
});
const investmentGroup = computed(() => {
  const finder = props.existingGroupId
    ? { id: props.existingGroupId }
    : { placeholderId: props.placeholderGroupId };

  return _.find(_.get(dealBuilder.value, "investmentGroups", []), finder);
});
const timingFields = computed(() => {
  return _.get(investmentGroup.value, "timingFields", []);
});
const investments = computed(() => {
  return _.get(investmentGroup.value, "investments", {});
});

onMounted(() => {
  refetchTimingFieldData();
});

function refetchTimingFieldData() {
  if (props.existingGroupId) {
    dealBuilderStore.fetchGroupTimingFields(props.existingGroupId);
    checkTimingUnlockability();
  }
}

function focalInvestmentFor(groupInvestment) {
  return _.get(groupInvestment, "existingInvestment") || groupInvestment;
}
function setNewDate(newDate) {
  date.value = newDate;
}
function setNewState(newState) {
  state.value = newState;
}
function updateStoreInvestment(newInvestment, fieldName = null) {
  dealBuilderStore.updateInvestmentGroupInvestment({
    groupId: investmentGroupId.value,
    newInvestment,
    fieldName,
  });
}
async function saveDateState() {
  if (props.existingGroupId) {
    const payload = {
      investmentGroupId: props.existingGroupId,
      date: date.value,
      state: state.value,
      changeGroupId: changeGroupId.value,
    };

    const apiRequestFunc = () => api.post(`investment_group_states`, payload);
    const successCallback = () => {
      refetchGroupInvestments();
      // Flash: Date and state updated for all investments in the portfolio.
      edited.value = false;
    };
    const failureCallback = () => {};
    await changeGroupStore.originateData(
      apiRequestFunc,
      successCallback,
      failureCallback,
    );
  }
}
function refetchGroupInvestments() {
  dealBuilderStore.refreshInvestmentGroup({
    groupId: props.existingGroupId,
    dealAction: investmentGroup.value?.dealAction,
    isExistingGroup: true,
  });
}
async function checkTimingUnlockability() {
  const maybeJson = await dealBuilderStore.checkGroupTimingUnlockability(
    props.existingGroupId,
  );

  if (maybeJson?.data) {
    groupTimingUnlockable.value = maybeJson.data;
  }
}
</script>
