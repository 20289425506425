<template>
  <GridLoader
    v-if="!loaded || submittingSubscription"
    :loading="!loaded || submittingSubscription"
    size="7px"
    color="#5850ec"
  />
  <button
    v-else
    @click="authorizeTeamSubscribe"
    type="button"
    class="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
    :disabled="!loaded || submittingSubscription"
    data-test="authorize-team-subscription-button"
  >
    Authorize and save
  </button>
</template>

<script setup>
import GridLoader from "vue-spinner/src/GridLoader.vue";
import { computed, onBeforeUnmount, onMounted, ref } from "vue";
import User from "@/stores/models/user";
import { useUserStore } from "@/stores/user";
import { useAuthStore } from "@/stores/auth";
import { useUnlockerStore } from "@/stores/unlocker";
import { storeToRefs } from "pinia";
import api from "@/router/api";
import _ from "lodash";
/* global Stripe */

const props = defineProps(["teamId"]);
const emit = defineEmits(["saved", "payment-method-failed"]);
const userStore = useUserStore();
const { currentUser } = storeToRefs(userStore);
const authStore = useAuthStore();
const stripe = ref(null);
const completed = ref(false);
const submittingSubscription = ref(false);
const subscription = ref(null);
const payeeCardToken = ref(null);
const clientSecret = ref(null);
const unlockerStore = useUnlockerStore();
const { resetRequired, upgradeSuccessful } = storeToRefs(unlockerStore);
const loaded = computed(() => {
  return !!stripe.value;
});

onMounted(() => {
  if (Stripe) {
    api.get(`payment_api_key`).then((json) => {
      const stripeKey = json.data;

      stripe.value = Stripe(stripeKey);
    });
  }
});

onBeforeUnmount(() => {
  if (completed.value) {
    authStore
      .fetchUser()
      .then((fetchedUser) => (currentUser.value = new User(fetchedUser.data)));
  } else {
    resetRequired.value = true;
  }
});

async function authorizeTeamSubscribe() {
  const payload = {
    teamId: props.teamId,
    plan: "tracker",
    billingFrequency: "monthly",
  };

  submittingSubscription.value = true;
  api.post(`team_subscriptions`, payload).then((json) => {
    subscription.value = json.data.subscription;
    payeeCardToken.value = json.data.cardToken;

    if (subscription.value.status === "active") {
      // $store.dispatch("flash", `You successfully subscribed to ${props.newPlan}!`);
      activateSubscription();

      return;
    }

    let paymentIntent = subscription.value.latest_invoice.payment_intent;

    if (
      paymentIntent.status === "requires_action" ||
      paymentIntent.status === "requires_payment_method"
    ) {
      clientSecret.value = paymentIntent.client_secret;

      if (paymentIntent.status === "requires_action") {
        submittingSubscription.value = false;
        confirmPayment();
      }

      if (paymentIntent.status === "requires_payment_method") {
        // TODO send user back to payment methods
        emit("payment-method-failed");
      }
    }
  });
}

function confirmPayment() {
  submittingSubscription.value = true;
  stripe.value
    .confirmCardPayment(clientSecret.value, {
      payment_method: payeeCardToken.value,
    })
    .then(
      (data) => {
        if (data.error) {
          console.log(data.error.message);
          // $store.dispatch("flash", data.error.message);
          emit("payment-method-failed");
        } else {
          // $store.dispatch("flash", "Payment successful!");
          activateSubscription();
        }
      },
      () => {
        emit("payment-method-failed");
        submittingSubscription.value = false;
      }
    );
}

function activateSubscription() {
  const payload = {
    teamId: props.teamId,
    plan: "tracker",
    billingFrequency: "monthly",
    subscriptionId: _.get(subscription.value, "id", null),
  };

  api.patch(`team_subscriptions`, payload).then(() => {
    submittingSubscription.value = false;
    upgradeSuccessful.value = true;
    completed.value = true;
    emit("saved");
  });
}
</script>
