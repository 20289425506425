<template>
  <Listbox as="div" v-model="selected">
    <ListboxLabel class="block text-xs/4 font-medium text-gray-900"
      >Input Complexity</ListboxLabel
    >
    <div class="relative mt-1">
      <ListboxButton
        class="relative w-full cursor-default rounded-md bg-white py-1 pl-2 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-amber-600 sm:text-xs/4"
      >
        <span class="flex items-center">
          <span
            :aria-label="selected.online ? 'Online' : 'Offline'"
            :class="[
              selected.color,
              'inline-block h-2 w-2 flex-shrink-0 rounded-full',
            ]"
          />
          <span class="ml-3 block truncate">{{ selected.name }}</span>
        </span>
        <span
          class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
        >
          <ChevronUpDownIcon class="h-4 w-4 text-gray-400" aria-hidden="true" />
        </span>
      </ListboxButton>

      <transition
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <ListboxOptions
          class="absolute z-50 mt-1 max-h-48 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-xs"
        >
          <ListboxOption
            as="template"
            v-for="level in inputComplexityLevels"
            :key="level.id"
            :value="level"
            v-slot="{ active, selected }"
          >
            <li
              :class="[
                active ? 'bg-amber-600 text-white' : 'text-gray-900',
                'relative cursor-default select-none py-1 pl-2 pr-9',
              ]"
            >
              <div class="flex items-center">
                <span
                  :class="[
                    level.color,
                    'inline-block h-2 w-2 flex-shrink-0 rounded-full',
                  ]"
                  aria-hidden="true"
                />
                <div class="flex flex-col">
                  <span
                    :class="[
                      selected ? 'font-semibold' : 'font-normal',
                      'ml-3 block truncate',
                    ]"
                  >
                    {{ level.name }}
                  </span>
                  <span
                    :class="[
                      selected ? 'font-semibold' : 'font-normal',
                      'ml-3 text-[10px] block truncate',
                    ]"
                  >
                    {{ level.description }}
                  </span>
                </div>
              </div>

              <span
                v-if="selected"
                :class="[
                  active ? 'text-white' : 'text-amber-600',
                  'absolute inset-y-0 right-0 flex items-center pr-4',
                ]"
              >
                <CheckIcon class="h-4 w-4" aria-hidden="true" />
              </span>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>

<script setup>
import { ref, computed, watch } from "vue";
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/vue";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/vue/20/solid";
import { storeToRefs } from "pinia";
import { useEasyDataInputAdminStore } from "@/stores/easyDataInputAdmin";
import _ from "lodash";

const props = defineProps(["item"]);

const easyDataInputAdminStore = useEasyDataInputAdminStore();
const { detailedSelectedJobCRUD } = storeToRefs(easyDataInputAdminStore);

const writableItem = computed(() =>
  _.find(payloadItems.value, { id: props.item.id }),
);
const inputComplexity = computed({
  get() {
    return writableItem.value.inputComplexity;
  },
  set(str) {
    writableItem.value.inputComplexity = str;
  },
});

const payloadItems = computed({
  get() {
    return detailedSelectedJobCRUD.value?.payloadItems;
  },
  set(newArr) {
    detailedSelectedJobCRUD.value.payloadItems = newArr;
  },
});

const inputComplexityLevels = [
  {
    id: 1,
    name: "Low",
    description: "Singletons, autocompletes",
    color: "bg-green-400",
  },
  {
    id: 2,
    name: "Medium",
    description: "Multi-physical, Deals",
    color: "bg-amber-400",
  },
  {
    id: 3,
    name: "High",
    description: "Groups, Air Rights, Diagram Art",
    color: "bg-red-400",
  },
];

const selected = ref(inputComplexityLevels[0]);

watch(
  selected,
  () => {
    inputComplexity.value = selected.value.name;
  },
  { deep: true },
);
</script>
