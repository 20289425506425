<template>
  <div class="bg-white overflow-y-auto h-full">
    <div class="p-2">
      <AvailabilityGroupKeyDates
        v-if="existingGroupId"
        :availability-group-id="existingGroupId"
        :timing-fields="timingFields"
        :timing-unlockable="groupTimingUnlockable"
        :context="context"
        @unlocked="refetchTimingFieldData"
        @refetch="refetchGroupAvailabilities"
      />
      <dl v-else class="grid grid-cols-1 gap-x-4 gap-y-8">
        <div class="col-span-1">
          <dt class="text-sm font-medium text-gray-500">Group State</dt>
          <dd
            v-if="homogeneousDateState"
            class="mt-1 flex items-center space-x-2 mr-2"
          >
            <DateStateField
              event-type="portfolioSpaceAvailability"
              :data-field="{ availabilityGroupId }"
              :state="state"
              :date="date"
              :compact="false"
              @new-date="setNewDate"
              @new-state="setNewState"
            />

            <DateStateField
              v-if="state === 'closed'"
              event-type="spaceAvailabilityCommenced"
              :data-field="{ availabilityGroupId }"
              :state="state"
              :date="commencedDate"
              :compact="false"
              @new-date="(newDate) => (commencedDate = newDate)"
              @new-state="setNewState"
            />

            <DateStateField
              v-if="state === 'closed'"
              event-type="spaceAvailabilityExpired"
              :data-field="{ availabilityGroupId }"
              :state="state"
              :date="subleaseExpirationDate || expiredDate"
              :commenced-date="commencedDate"
              :upstream-space-usage-id="availabilityGroupUpstreamUsageIds"
              :compact="false"
              @new-date="(newDate) => (expiredDate = newDate)"
              @use-sublease-expiration-date="
                (newDate) => (subleaseExpirationDate = newDate)
              "
              @new-state="setNewState"
            />

            <div v-if="dateLabel" class="rounded-md bg-teal-100 p-2">
              <div class="flex">
                <div class="flex-shrink-0">
                  <InformationCircleIcon
                    class="h-4 w-4 text-teal-600"
                    aria-hidden="true"
                  />
                </div>
                <div class="ml-2 flex-1 flex">
                  <p class="text-xs text-teal-700">{{ dateLabel }}</p>
                </div>
              </div>
            </div>

            <button
              v-if="existingGroupId && edited"
              @click="saveDateState"
              type="button"
              v-tooltip="'Save'"
              class="flex-shrink-0 inline-flex justify-center items-center w-6 h-6 border border-transparent rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <CheckIcon class="h-4 w-4" />
            </button>
          </dd>
          <dd v-else class="mt-4 text-center">
            <svg
              class="mx-auto h-8 w-8 text-gray-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
              />
            </svg>
            <h3 class="mt-2 text-sm font-medium text-gray-900">
              Multiple dates/states
            </h3>
            <p class="mt-1 text-sm text-gray-500">
              The available spaces don't all share the same state. Please edit
              via the space list.
            </p>
          </dd>
        </div>
      </dl>
    </div>
  </div>
</template>

<script setup>
import { CheckIcon, InformationCircleIcon } from "@heroicons/vue/20/solid";
import moment from "moment";
import DateStateField from "@/components/crowdsourcing/DateStateField.vue";
import AvailabilityGroupKeyDates from "@/components/space-usage-builder/AvailabilityGroupKeyDates.vue";
import { useSpaceUsageBuilderStore } from "@/stores/spaceUsageBuilder";
import { useCrowdsourcedChangeGroupStore } from "@/stores/crowdsourcedChangeGroup";
import { storeToRefs } from "pinia";
import { ref, computed, onMounted, watch } from "vue";
import _ from "lodash";
import api from "@/router/api";
import leaseTermEditingLabel from "@/assets/leaseTermEditingLabel";

const props = defineProps(["existingGroupId", "placeholderGroupId", "context"]);

const spaceUsageBuilderStore = useSpaceUsageBuilderStore();
const { spaceUsageBuilder } = storeToRefs(spaceUsageBuilderStore);
const changeGroupStore = useCrowdsourcedChangeGroupStore();
const { changeGroupId } = storeToRefs(changeGroupStore);

const edited = ref(false);
const groupTimingUnlockable = ref(false);
const subleaseExpirationDate = ref(null);

const availabilityGroupUpstreamUsageIds = computed(() => {
  if (availabilityGroup.value && groupHasUpstreamIds.value) {
    return _.map(
      availabilityGroup.value.availabilities,
      function (tempAvailability) {
        return tempAvailability.upstreamSpaceUsageId;
      },
    );
  } else {
    return null;
  }
});
const groupHasUpstreamIds = computed(() => {
  if (availabilityGroup.value) {
    return _.every(
      availabilityGroup.value.availabilities,
      function (tempAvailability) {
        return tempAvailability.upstreamSpaceUsageId;
      },
    );
  } else {
    return false;
  }
});

const homogeneousDateState = computed(() => {
  return (
    groupDateState.value.dates.length <= 1 &&
    groupDateState.value.states.length <= 1
  );
});
const groupDateState = computed(() => {
  const availabilities = _.map(
    _.get(availabilityGroup.value, "availabilities", []),
    function (groupAvailability) {
      return focalAvailabilityFor(groupAvailability);
    },
  );
  const uniqDates = _.uniq(
    availabilities.map((i) => {
      if (i.date) {
        return moment(i.date).format("YYYYMMDD");
      } else {
        return null;
      }
    }),
  );
  const dates = _.compact(uniqDates);
  const uniqCommencedDates = _.uniq(
    availabilities.map((i) => {
      if (i.commencedDate) {
        return moment(i.commencedDate).format("YYYYMMDD");
      } else {
        return null;
      }
    }),
  );
  const commencedDates = _.compact(uniqCommencedDates);
  const uniqExpiredDates = _.uniq(
    availabilities.map((i) => {
      if (i.expiredDate) {
        return moment(i.expiredDate).format("YYYYMMDD");
      } else {
        return null;
      }
    }),
  );
  const expiredDates = _.compact(uniqExpiredDates);
  const states = _.compact(_.uniq(availabilities.map((i) => i.state)));

  return { dates, commencedDates, expiredDates, states };
});
const date = computed({
  get() {
    if (groupDateState.value.dates.length === 1) {
      let unparsed = _.head(groupDateState.value.dates);

      return moment(unparsed).toDate();
    } else {
      return null;
    }
  },
  set(newVal) {
    _.forEach(availabilities.value, function (availability) {
      let newAvailability = _.merge({}, focalAvailabilityFor(availability));

      newAvailability.date = newVal;
      updateStoreAvailability(newAvailability, "date");
    });
    edited.value = true;
  },
});
const commencedDate = computed({
  get() {
    if (groupDateState.value.commencedDates.length === 1) {
      let unparsed = _.head(groupDateState.value.commencedDates);

      return moment(unparsed).toDate();
    } else {
      return null;
    }
  },
  set(newVal) {
    _.forEach(availabilities.value, function (availability) {
      let newAvailability = _.merge({}, focalAvailabilityFor(availability));

      newAvailability.commencedDate = newVal;
      updateStoreAvailability(newAvailability, "commencedDate");
    });
    edited.value = true;
  },
});
const expiredDate = computed({
  get() {
    if (groupDateState.value.expiredDates.length === 1) {
      let unparsed = _.head(groupDateState.value.expiredDates);

      return moment(unparsed).toDate();
    } else {
      return null;
    }
  },
  set(newVal) {
    _.forEach(availabilities.value, function (availability) {
      let newAvailability = _.merge({}, focalAvailabilityFor(availability));

      newAvailability.expiredDate = newVal;
      updateStoreAvailability(newAvailability, "expiredDate");
    });
    edited.value = true;
  },
});
const state = computed({
  get() {
    if (groupDateState.value.states.length === 1) {
      return _.head(groupDateState.value.states);
    } else {
      return null;
    }
  },
  set(newVal) {
    _.forEach(availabilities.value, function (availability) {
      let newAvailability = _.merge({}, focalAvailabilityFor(availability));

      newAvailability.state = newVal;
      updateStoreAvailability(newAvailability, "state");
    });
    edited.value = true;
  },
});
const availabilityGroupId = computed(() => {
  return props.existingGroupId || props.placeholderGroupId;
});
const availabilityGroup = computed(() => {
  const finder = props.existingGroupId
    ? { id: props.existingGroupId }
    : { placeholderId: props.placeholderGroupId };

  return _.find(
    _.get(spaceUsageBuilder.value, "availabilityGroups", []),
    finder,
  );
});
const timingFields = computed(() => {
  switch (props.context) {
    case "contract":
      return _.get(availabilityGroup.value, "contractTimingFields", []);
    case "occupancy":
      return _.get(availabilityGroup.value, "occupancyTimingFields", []);
    default:
      return _.get(availabilityGroup.value, "timingFields", []);
  }
});
const availabilities = computed(() => {
  return _.get(availabilityGroup.value, "availabilities", {});
});
const validContractDates = computed(() => {
  if (commencedDate.value && expiredDate.value) {
    return moment(expiredDate.value).isAfter(commencedDate.value);
  } else {
    return (
      commencedDate.value ||
      expiredDate.value ||
      (!commencedDate.value && !expiredDate.value)
    );
  }
});
const dateLabel = computed(() => {
  return leaseTermEditingLabel(
    state.value,
    date.value,
    commencedDate.value,
    expiredDate.value,
    subleaseExpirationDate.value,
    validContractDates.value,
  );
});

watch(
  () => props.context,
  (s, oldS) => {
    if (s !== oldS) {
      refetchTimingFieldData();
    }
  },
);

onMounted(() => {
  refetchTimingFieldData();
});

function refetchTimingFieldData() {
  if (props.existingGroupId) {
    spaceUsageBuilderStore.fetchGroupTimingFields(
      props.existingGroupId,
      props.context,
    );
    checkTimingUnlockability();
  }
}

function focalAvailabilityFor(groupAvailability) {
  return _.get(groupAvailability, "existingAvailability") || groupAvailability;
}
function setNewDate(newDate) {
  date.value = newDate;
}
function setNewState(newState) {
  state.value = newState;
}
function updateStoreAvailability(newAvailability, fieldName = null) {
  spaceUsageBuilderStore.updateAvailabilityGroupAvailability({
    groupId: availabilityGroupId.value,
    newAvailability,
    fieldName,
  });
}
async function saveDateState() {
  if (props.existingGroupId) {
    const payload = {
      availabilityGroupId: props.existingGroupId,
      date: date.value,
      state: state.value,
      changeGroupId: changeGroupId.value,
    };

    const apiRequestFunc = () => api.post(`availability_group_states`, payload);
    const successCallback = () => {
      refetchGroupAvailabilities();
      // Flash: Date and state updated for all availabilities in the portfolio.
      edited.value = false;
    };
    const failureCallback = () => {};
    await changeGroupStore.originateData(
      apiRequestFunc,
      successCallback,
      failureCallback,
    );
  }
}
function refetchGroupAvailabilities() {
  spaceUsageBuilderStore.refreshAvailabilityGroup({
    groupId: props.existingGroupId,
    isExistingGroup: true,
  });
}
async function checkTimingUnlockability() {
  const maybeJson = await spaceUsageBuilderStore.checkGroupTimingUnlockability(
    props.existingGroupId,
    props.context,
  );

  if (maybeJson?.data) {
    groupTimingUnlockable.value = maybeJson.data;
  }
}
</script>
