<template>
  <div class="rounded-md border-l-2 border-gray-500 bg-gray-100 p-1">
    <div class="ml-2 py-1 pr-1 flex flex-col space-y-1">
      <div class="flex items-center justify-between">
        <DataField
          :data-field="phaseDataField"
          text-classes="text-sm"
          @completed="refetch"
          @open-sourced="refetch"
          @unlocked="refetch"
          @dismiss="refetch"
        />
        <HeadcountBasedArea
          v-if="minHeadcountField"
          :requirement-data-field="requirementDataField"
          :phase-data-field="phaseDataField"
          text-classes="text-xs font-medium"
          @refetch="refetch"
        />
      </div>
      <div class="grid grid-cols-2 gap-x-2 gap-y-2">
        <div v-if="minAreaField" class="">
          <label class="block text-sm font-medium leading-6 text-gray-500"
            >Min. SF</label
          >
          <div class="mt-1">
            <EditableDataField
              v-bind="
                _.find(availableProps, {
                  fieldName: 'min_area',
                })
              "
              @set-proof="setProof"
              @completed="refetch"
              @open-sourced="refetch"
              @unlocked="refetch"
              @dismiss="refetch"
            />
          </div>
        </div>
        <div v-if="maxAreaField" class="">
          <label class="block text-sm font-medium leading-6 text-gray-500"
            >Max. SF</label
          >
          <div class="mt-1">
            <EditableDataField
              v-bind="
                _.find(availableProps, {
                  fieldName: 'max_area',
                })
              "
              @set-proof="setProof"
              @completed="refetch"
              @open-sourced="refetch"
              @unlocked="refetch"
              @dismiss="refetch"
            />
          </div>
        </div>
        <div v-if="minHeadcountField" class="">
          <label class="block text-sm font-medium leading-6 text-gray-500"
            >Min. People</label
          >
          <div class="mt-1">
            <EditableDataField
              v-bind="
                _.find(availableProps, {
                  fieldName: 'min_headcount',
                })
              "
              @set-proof="setProof"
              @completed="refetch"
              @open-sourced="refetch"
              @unlocked="refetch"
              @dismiss="refetch"
            />
          </div>
        </div>
        <div v-if="maxHeadcountField" class="">
          <label class="block text-sm font-medium leading-6 text-gray-500"
            >Max. People</label
          >
          <div class="mt-1">
            <EditableDataField
              v-bind="
                _.find(availableProps, {
                  fieldName: 'max_headcount',
                })
              "
              @set-proof="setProof"
              @completed="refetch"
              @open-sourced="refetch"
              @unlocked="refetch"
              @dismiss="refetch"
            />
          </div>
        </div>
        <div v-if="targetDateField" class="col-span-2">
          <label class="block text-sm font-medium leading-6 text-gray-500"
            >Target Date</label
          >
          <div class="mt-1">
            <EditableDataField
              v-bind="
                _.find(availableProps, {
                  fieldName: 'target_date',
                })
              "
              @set-proof="setProof"
              @completed="refetch"
              @open-sourced="refetch"
              @unlocked="refetch"
              @dismiss="refetch"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import DataField from "@/components/crowdsourcing/DataField.vue";
import EditableDataField from "@/components/crowdsourcing/EditableDataField.vue";
import HeadcountBasedArea from "@/components/analyze/calculations/HeadcountBasedArea.vue";
import { useProveForValueStore } from "@/stores/proveForValue";
import { computed } from "vue";
import _ from "lodash";

const props = defineProps(["phaseDataField", "requirementDataField"]);
const emit = defineEmits(["refetch"]);

const proveForValueStore = useProveForValueStore();

const inputs = computed(() => props.phaseDataField.fieldContent?.inputs);
const minAreaField = computed(() =>
  _.find(inputs.value, { fieldName: "min_area" }),
);
const maxAreaField = computed(() =>
  _.find(inputs.value, { fieldName: "max_area" }),
);
const minHeadcountField = computed(() =>
  _.find(inputs.value, { fieldName: "min_headcount" }),
);
const maxHeadcountField = computed(() =>
  _.find(inputs.value, { fieldName: "max_headcount" }),
);
const targetDateField = computed(() =>
  _.find(inputs.value, { fieldName: "target_date" }),
);
function refetch() {
  emit("refetch");
}

const availableProps = computed(() => {
  return [
    {
      fieldName: "min_area",
      textClasses: "text-sm",
      inputClasses: "w-full",
      decoratingContentDataField: props.phaseDataField,
      isInteger: true,
      optional: false,
      dismissOnSave: true,
    },
    {
      fieldName: "max_area",
      textClasses: "text-sm",
      inputClasses: "w-full",
      decoratingContentDataField: props.phaseDataField,
      isInteger: true,
      optional: false,
      dismissOnSave: true,
    },
    {
      fieldName: "min_headcount",
      textClasses: "text-sm",
      inputClasses: "w-full",
      decoratingContentDataField: props.phaseDataField,
      isInteger: true,
      optional: false,
      dismissOnSave: true,
    },
    {
      fieldName: "max_headcount",
      textClasses: "text-sm",
      inputClasses: "w-full",
      decoratingContentDataField: props.phaseDataField,
      isInteger: true,
      optional: false,
      dismissOnSave: true,
    },
    {
      fieldName: "target_date",
      textClasses: "text-sm",
      inputClasses: "w-full",
      decoratingContentDataField: props.phaseDataField,
      isDate: true,
      optional: false,
      dismissOnSave: true,
    },
  ];
});

function setProof(fieldName) {
  const matchedProps = _.find(availableProps.value, { fieldName });

  if (matchedProps) {
    proveForValueStore.setProofComponent(EditableDataField, matchedProps);
  }
}
</script>
