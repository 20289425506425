<template>
  <div class="flex items-center">
    <ContactDataField v-if="isContactField" :data-field="field" />
    <DataField
      v-else-if="field"
      :data-field="field"
      primary-text-path="fieldValue"
      secondary-text-path="fieldContent.name"
      text-classes="text-sm"
      :tasks="true"
      :data-test="`task-${task.id}-attached-data-field`"
    />
    <CollectibleCardPreview v-else-if="isCard" :task="task" />
  </div>
</template>

<script setup>
import { computed, ref, onMounted } from "vue";
import ContactDataField from "@/components/crowdsourcing/ContactDataField.vue";
import DataField from "@/components/crowdsourcing/DataField.vue";
import _ from "lodash";
import api from "@/router/api";
import CollectibleCardPreview from "@/components/tasks/CollectibleCardPreview.vue";

const props = defineProps(["task"]);
const field = ref(null);
const isCard = computed(() => {
  return _.includes(
    ["CollectibleCard", "UserCollectibleCard"],
    props.task.contentType,
  );
});
const isDataField = computed(
  () => props.task.contentType === "CrowdsourcedDataField",
);
const isContactField = computed(
  () => _.get(field.value, "fieldContentType") === "ContactCompanyInvolvement",
);

onMounted(() => {
  fetchDataField();
});

async function fetchDataField() {
  return new Promise((resolve) => {
    if (isDataField.value) {
      api.get(`data_field_by_id/${props.task.contentId}`).then((json) => {
        field.value = json.data;
        resolve();
      });
    } else {
      resolve();
    }
  });
}
</script>
