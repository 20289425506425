<template>
  <div class="flex flex-col space-y-1">
    <div class="flex flex-wrap items-center space-x-1">
      <template v-if="fetchedRoles.length > 0">
        <DataField
          v-for="roleDataField in fetchedRoles"
          :key="`involvement-role-${roleDataField.localId}`"
          :data-field="roleDataField"
          primary-text-path="fieldContent.name"
          text-classes="text-sm"
          @completed="fetchRoles"
          @unlocked="unlocked"
          @open-sourced="fetchRoles"
          @set-proof="setProof"
        />
      </template>
      <template
        v-else-if="uniqueFetchedRoles && _.some(uniqueFetchedRoles, _.isObject)"
      >
        <DataField
          v-for="roleDataField in uniqueFetchedRoles"
          :key="`involvement-role-${roleDataField.localId}`"
          :data-field="roleDataField"
          primary-text-path="fieldContent.name"
          text-classes="text-sm"
          @completed="fetchRoles"
          @unlocked="unlocked"
          @open-sourced="fetchRoles"
          @set-proof="setProof"
        />
      </template>
      <template v-else-if="uniqueFetchedRoles">
        <li
          v-for="(roleName, index) in uniqueFetchedRoles"
          :key="roleName"
          v-tooltip="'Remove at the asset level'"
          :data-test="`group-role-${index}`"
          class="inline-flex rounded-full items-center py-0.5 px-2 text-sm font-medium bg-gray-100 text-gray-700"
        >
          {{ roleName }}
        </li>
      </template>
    </div>

    <div class="flex items-center">
      <ContentInvolvementRoleCreate
        v-if="creatingRole"
        v-bind="
          _.find(availableProps, {
            fieldName: 'InvolvementRole',
          })
        "
        @completed="roleCreated"
        @cancel="cancel"
      />

      <div v-else class="flex items-center">
        <DataVisibilityButton :tooltip="tooltip" visibility="safezone">
          <template v-slot:button>
            <button
              @click="createRole"
              type="button"
              :data-test="`${contentDataField?.fieldContentType}-${contentDataField?.fieldContentId}-add-involvement-role-button`"
              class="group py-0.5 px-1 flex items-center justify-between rounded-full border border-gray-300 shadow-sm space-x-2 text-left hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <span class="min-w-0 flex-1 flex items-center space-x-1">
                <span class="flex-shrink-0 flex items-center">
                  <span
                    class="inline-flex items-center justify-center h-5 w-5 rounded-full bg-yellow-500"
                  >
                    <TagIcon class="h-4 w-4 text-white" />
                  </span>
                </span>
                <span class="min-w-0 flex-1">
                  <span class="text-xs font-medium text-gray-900 truncate"
                    >Add a role</span
                  >
                </span>
              </span>
            </button>
          </template>
        </DataVisibilityButton>
      </div>
    </div>
  </div>
</template>

<script setup>
import { TagIcon } from "@heroicons/vue/24/outline";
import DataField from "@/components/crowdsourcing/DataField.vue";
import ContentInvolvementRoleCreate from "@/components/crowdsourcing/ContentInvolvementRoleCreate.vue";
import DataVisibilityButton from "@/components/crowdsourcing/DataVisibilityButton.vue";
import { useDealBuilderStore } from "@/stores/dealBuilder";
import { useSpaceUsageBuilderStore } from "@/stores/spaceUsageBuilder";
import { useProveForValueStore } from "@/stores/proveForValue";
import api from "@/router/api";
import { computed, onMounted, ref } from "vue";
import _ from "lodash";

const props = defineProps([
  "contentDataField",
  "context",
  "layerType",
  "rawCompanyId",
  "investmentGroupId",
  "availabilityGroupId",
  "availabilityId",
  "clientRole",
]);

const dealBuilderStore = useDealBuilderStore();
const spaceUsageBuilderStore = useSpaceUsageBuilderStore();
const proveForValueStore = useProveForValueStore();

const fetchedRoles = ref([]);
const groupFetchedRoles = ref([]);
const creatingRole = ref(false);
const selectedValue = ref(null);
const availableProps = computed(() => {
  return [
    {
      fieldName: "InvolvementRole",
      contentDataField: props.contentDataField,
      context: props.context,
      layerType: props.layerType,
      rawCompanyId: props.rawCompanyId,
      investmentGroupId: props.investmentGroupId,
      availabilityGroupId: props.availabilityGroupId,
      availabilityId: props.availabilityId,
      clientRole: props.clientRole,
      fetchRequestKey: fetchRequestKey.value,
    },
  ];
});

const fetchRequestKey = computed(() => {
  if (props.investmentGroupId) {
    return `investment_group_involvement_roles_${props.investmentGroupId}_${props.rawCompanyId}_${props.clientRole}`;
  } else if (props.availabilityGroupId) {
    return `availability_group_involvement_roles_${props.availabilityGroupId}_${props.rawCompanyId}_${props.clientRole}`;
  } else {
    return `crowdsourced_data_fields_${props.contentDataField.fieldContentType}_${props.contentDataField.fieldContentId}?field_name=InvolvementRole`;
  }
});

const uniqueFetchedRoles = computed(() => {
  return _.uniqBy(groupFetchedRoles.value, function (df) {
    return df.fieldContent.name;
  }).map((df) => {
    if (df.price) {
      return df;
    } else {
      return df.fieldContent.name;
    }
  });
});
const tooltip = computed(() => {
  if (
    _.includes(
      ["investment-group-players", "investment-group-prospect-advisor-partner"],
      props.context,
    )
  ) {
    return "Deal-related company roles. Roles you add here will be applied throughout the portfolio.";
  } else if (
    _.includes(
      ["investment-players", "prospect-advisor-partner"],
      props.context,
    )
  ) {
    return "Deal-related company roles.";
  } else {
    return "Day-to-day operational company roles.";
  }
});

onMounted(() => fetchRoles());

function setProof(fieldName) {
  const matchedProps = _.find(availableProps.value, { fieldName });

  if (matchedProps) {
    proveForValueStore.setProofComponent(
      ContentInvolvementRoleCreate,
      matchedProps,
    );
  }
}
function roleCreated(payload) {
  const dataField = payload?.dataField;
  creatingRole.value = false;
  if (dataField) {
    patchRole(dataField);
  } else {
    fetchRoles();
  }
}
function patchRole(dataField) {
  const newRoles = _.unionBy([dataField], fetchedRoles.value, "localId");

  fetchedRoles.value = newRoles;
}

function unlocked() {
  fetchRoles(true);
}
function createRole() {
  creatingRole.value = true;
}
function cancel() {
  creatingRole.value = false;
  selectedValue.value = null;
}

function fetchRoles(override = false) {
  if (props.investmentGroupId) {
    console.log("fetch group roles");
    if (props.rawCompanyId) {
      if (_.isNumber(props.rawCompanyId)) {
        api
          .get(
            `investment_group_involvement_roles/?investment_group_id=${props.investmentGroupId}&raw_company_id=${props.rawCompanyId}&client_role=${props.clientRole}`,
          )
          .then((json) => {
            dealBuilderStore.interceptablePatch(
              json.data,
              fetchRequestKey.value,
            );
            groupFetchedRoles.value = json.data;
          });
      } else {
        api
          .get(
            `investment_group_involvement_roles/?investment_group_id=${props.investmentGroupId}&field_id=${props.rawCompanyId.localId}&client_role=${props.clientRole}`,
          )
          .then((json) => {
            dealBuilderStore.interceptablePatch(
              json.data,
              fetchRequestKey.value,
            );
            groupFetchedRoles.value = json.data;
          });
      }
    }
  } else if (props.availabilityGroupId) {
    console.log("fetch group roles");
    if (props.rawCompanyId) {
      if (_.isNumber(props.rawCompanyId)) {
        api
          .get(
            `availability_group_involvement_roles/?availability_group_id=${props.availabilityGroupId}&raw_company_id=${props.rawCompanyId}&client_role=${props.clientRole}`,
          )
          .then((json) => {
            spaceUsageBuilderStore.interceptablePatch(
              json.data,
              fetchRequestKey.value,
            );
            groupFetchedRoles.value = json.data;
          });
      } else {
        api
          .get(
            `availability_group_involvement_roles/?availability_group_id=${props.availabilityGroupId}&field_id=${props.rawCompanyId.localId}&client_role=${props.clientRole}`,
          )
          .then((json) => {
            spaceUsageBuilderStore.interceptablePatch(
              json.data,
              fetchRequestKey.value,
            );
            groupFetchedRoles.value = json.data;
          });
      }
    }
  } else {
    if (dealBuilderStore.alreadyFetched(fetchRequestKey.value) && !override) {
      fetchedRoles.value = dealBuilderStore.alreadyFetchedFieldsFor(
        fetchRequestKey.value,
      );
    } else {
      api
        .get(
          `crowdsourced_data_fields/${props.contentDataField.fieldContentType}/${props.contentDataField.fieldContentId}?field_name=InvolvementRole`,
        )
        .then((json) => {
          dealBuilderStore.interceptablePatch(json.data, fetchRequestKey.value);
          fetchedRoles.value = json.data;
        });
    }
  }
}
</script>
