<template>
  <div v-if="selectedTab" class="flex">
    <!-- Narrow sidebar-->
    <nav
      aria-label="Sidebar"
      class="flex-shrink-0 p-2 bg-gray-100 overflow-y-auto"
    >
      <div class="relative flex flex-col space-y-2">
        <a
          v-for="tab in portfolioFilteredTabs"
          :key="tab.name"
          @click.prevent="selectedTab = tab"
          :href="tab.href"
          v-tooltip="tab.name"
          :class="[
            selectedTab.name === tab.name
              ? 'text-gray-700 bg-gray-300'
              : 'text-gray-500 hover:bg-gray-200',
            'flex-shrink-0 inline-flex items-center justify-center h-8 w-8 rounded-lg',
          ]"
          class=""
          :data-test="`${_.kebabCase(tab.name)}-nav`"
        >
          <span class="sr-only">{{ tab.name }}</span>
          <svg
            :class="selectedTab.name === tab.name ? '' : ''"
            class="h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1"
              :d="tab.svgPath"
            />
          </svg>
        </a>
      </div>
    </nav>

    <div class="flex-grow">
      <component :is="selectedTab.component" v-bind="selectedTabProperties" />
    </div>
  </div>
</template>

<script setup>
import ContentAppearance from "@/components/crowdsourcing/ContentAppearance.vue";
import ContentFiles from "@/components/crowdsourcing/ContentFiles.vue";
import ContentTiming from "@/components/crowdsourcing/ContentTiming.vue";
import { computed, markRaw, onMounted, ref } from "vue";
import _ from "lodash";

const props = defineProps(["dataField"]);

const tabs = ref([
  {
    name: "Timing",
    href: "",
    svgPath: "M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z",
    component: markRaw(ContentTiming),
  },
  {
    name: "Files",
    href: "",
    svgPath:
      "M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z",
    component: markRaw(ContentFiles),
  },
  {
    name: "Appearance",
    href: "",
    svgPath:
      "M10 6H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V8a2 2 0 00-2-2h-5m-4 0V5a2 2 0 114 0v1m-4 0a2 2 0 104 0m-5 8a2 2 0 100-4 2 2 0 000 4zm0 0c1.306 0 2.417.835 2.83 2M9 14a3.001 3.001 0 00-2.83 2M15 11h3m-3 4h2",
    component: markRaw(ContentAppearance),
  },
]);
const selectedTab = ref(null);
const portfolioLoan = computed(
  () => props.dataField.fieldContent.category === "portfolio",
);
const portfolioFilteredTabs = computed(() => {
  if (portfolioLoan.value) {
    return tabs.value.filter((tab) => {
      return tab.name !== "Timing";
    });
  } else {
    return tabs.value;
  }
});
const selectedTabProperties = computed(() => {
  return {
    dataField: props.dataField,
    contentDataField: props.dataField,
    focus: false,
    context: "loanDetail",
  };
});

onMounted(() => {
  if (portfolioLoan.value) {
    selectedTab.value = {
      name: "Files",
      href: "",
      svgPath:
        "M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z",
      component: markRaw(ContentFiles),
    };
  } else {
    selectedTab.value = {
      name: "Timing",
      href: "",
      svgPath: "M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z",
      component: markRaw(ContentTiming),
    };
  }
});
</script>
