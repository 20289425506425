<template>
  <div class="bg-white overflow-hidden divide-y divide-gray-200">
    <div
      v-if="expandable"
      class="flex items-center justify-between p-2 bg-gray-100"
    >
      <div class="text-gray-700 text-xs font-semibold uppercase tracking-wide">
        Timeline Investments
      </div>
      <div class="flex items-center space-x-2">
        <button
          @click="expanded = !expanded"
          v-tooltip="expanded ? 'Minimize' : 'Expand'"
          type="button"
          class="h-5 w-5 p-1 inline-flex justify-center items-center text-sm text-gray-500 hover:text-gray-700"
        >
          <i v-if="expanded" class="fas fa-compress" />
          <i v-else class="fas fa-expand" />
        </button>
      </div>
    </div>
    <template v-if="expanded">
      <InvestmentDetail
        v-for="(investment, index) in investments"
        :key="index"
        :investment="investment"
        :asset-object="assetObject"
        :investment-group-id="investmentGroupId"
      />
    </template>
  </div>
</template>

<script setup>
import moment from "moment";
import InvestmentDetail from "@/components/deal-builder/InvestmentDetail.vue";
import { useTimeTravelStore } from "@/stores/timeTravel";
import { useDealBuilderStore } from "@/stores/dealBuilder";
import { storeToRefs } from "pinia";
import { ref, computed, watch } from "vue";
import decoratingAndFieldKey from "@/components/crowdsourcing/decoratingAndFieldKey";
import _ from "lodash";

const props = defineProps([
  "assetObject",
  "existingGroupId",
  "placeholderGroupId",
]);

const timeTravelStore = useTimeTravelStore();
const { asOfDate } = storeToRefs(timeTravelStore);
const dealBuilderStore = useDealBuilderStore();
const { dealBuilder } = storeToRefs(dealBuilderStore);

const expanded = ref(true);

const expandable = computed(() => {
  return !investmentGroup.value;
});
const investmentGroupId = computed(() => {
  return props.existingGroupId || props.placeholderGroupId;
});
const investmentGroup = computed(() => {
  const finder = props.existingGroupId
    ? { id: props.existingGroupId }
    : { placeholderId: props.placeholderGroupId };

  return _.find(_.get(dealBuilder.value, "investmentGroups", []), finder);
});
const portfolioInvestments = computed(() => {
  let investments = [];

  if (investmentGroup.value) {
    _.forEach(
      _.get(investmentGroup.value, "investments", {}),
      function (value) {
        investments.push(
          _.merge({}, value, {
            dealAction: _.get(investmentGroup.value, "dealAction"),
          }),
        );
      },
    );
  } else if (props.assetObject?.dataField) {
    const assetKey = decoratingAndFieldKey(props.assetObject?.dataField);

    dealBuilder.value.investmentGroups.forEach((group) => {
      const { dealAction } = group;

      _.forEach(group.investments, function (value) {
        const investmentAssetKey = value.capitalStackTopLevelAsset
          ? decoratingAndFieldKey(value.capitalStackTopLevelAsset)
          : null;

        if (
          !value.existingInvestment &&
          investmentAssetKey &&
          assetKey === investmentAssetKey
        ) {
          investments.push(_.merge({}, value, { dealAction }));
        }
      });
    });
  }

  return investments;
});
const combinedInvestments = computed(() => {
  return _.concat(
    _.get(props.assetObject, "investments", []),
    portfolioInvestments.value,
  );
});
const allInvestmentsDated = computed(() => {
  return _.every(combinedInvestments.value, function (investment) {
    return !!_.get(actionableInvestmentFor(investment), "date");
  });
});
const investments = computed(() => {
  if (allInvestmentsDated.value) {
    return _.orderBy(
      combinedInvestments.value,
      [
        function (investment) {
          return moment(
            _.get(actionableInvestmentFor(investment), "date"),
          ).valueOf();
        },
        function (investment) {
          return _.get(actionableInvestmentFor(investment), "id");
        },
      ],
      ["desc", "asc"],
    );
  } else {
    return _.orderBy(
      combinedInvestments.value,
      [
        function (investment) {
          return _.get(actionableInvestmentFor(investment), "id");
        },
      ],
      ["asc"],
    );
  }
});

watch(asOfDate, (date, oldDate) => {
  if (oldDate && date !== oldDate) {
    dealBuilderStore.resetDealBuilderInvestments();
  }
});

function actionableInvestmentFor(investment) {
  const dataFieldInvestmentFieldContent = _.get(investment, "fieldContent");
  const dealBuilderExistingInvestment = _.get(investment, "existingInvestment");

  return (
    dataFieldInvestmentFieldContent ||
    dealBuilderExistingInvestment ||
    investment
  );
}
</script>
