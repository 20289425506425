<template>
  <div class="flex-shrink-0">
    <img
      v-if="user"
      :class="reputationColor"
      class="p-1 inline-block h-10 w-10"
      src="https://assets.towerhunt.com/site/Transparent_White_T0.png"
      alt="Tower Hunt logo"
    />

    <span
      v-else
      class="inline-block h-10 w-10 overflow-hidden rounded-full bg-gray-100"
    >
      <svg
        class="h-full w-full text-gray-300"
        fill="currentColor"
        viewBox="0 0 24 24"
      >
        <path
          d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"
        />
      </svg>
    </span>
  </div>
</template>

<script setup>
import api from "@/router/api";
import { computed, onMounted, ref } from "vue";
import _ from "lodash";

const props = defineProps(["changeId"]);
const user = ref(null);
const reputationColor = computed(() => {
  switch (_.get(user.value, "reputationLevel")) {
    case "bronze":
      return "bg-gradient-to-r from-yellow-500 to-orange-700 text-white hover:to-orange-800";
    case "silver":
      return "bg-gradient-to-r from-slate-200 to-gray-400 text-gray-900 hover:to-gray-500";
    case "gold":
      return "bg-gradient-to-r from-yellow-300 to-yellow-500 text-white hover:to-yellow-600";
    default:
      return "text-white bg-gray-800 hover:bg-gray-700";
  }
});

onMounted(() => {
  fetchUser();
});

async function fetchUser() {
  api
    .get(`data_field_change_users?change_id=${props.changeId}`)
    .then((json) => {
      user.value = json.data;
    });
}
</script>
