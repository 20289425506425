import { ref, computed } from "vue";
import { defineStore, acceptHMRUpdate } from "pinia";
import _ from "lodash";

export const useSharingStore = defineStore("sharing", () => {
  const sharingDataFields = ref([]);
  const userRecipients = ref([]);
  const teamRecipients = ref([]);
  const stagedCount = computed(() => {
    return sharingDataFields.value.length;
  });

  function alreadyInStage(dataField) {
    return _.find(sharingDataFields.value, { localId: dataField.localId });
  }
  function addSharingDataFields(dataFields) {
    const newFields = _.unionBy(dataFields, sharingDataFields.value, "localId");

    sharingDataFields.value = newFields;
  }
  function removeFromDataFieldSharing(dataFields) {
    const newFields = _.differenceBy(
      sharingDataFields.value,
      dataFields,
      "localId",
    );

    sharingDataFields.value = newFields;
  }
  function addUser(user) {
    const newUsers = _.unionBy([user], userRecipients.value, "name");

    userRecipients.value = newUsers;
  }
  function removeUser(user) {
    const newUsers = _.differenceBy(userRecipients.value, [user], "name");

    userRecipients.value = newUsers;
  }
  function addTeam(team) {
    const newTeams = _.unionBy([team], teamRecipients.value, "name");

    teamRecipients.value = newTeams;
  }
  function removeTeam(team) {
    const newTeams = _.differenceBy(teamRecipients.value, [team], "name");

    teamRecipients.value = newTeams;
  }
  function setRecipientPayStructure({ option, recipient }) {
    if (recipient.className === "Team") {
      const oldTeam = _.find(teamRecipients.value, {
        id: recipient.id,
      });
      const newTeam = _.merge({}, oldTeam, { payStructure: option });
      const newTeams = _.unionBy([newTeam], teamRecipients.value, "name");

      teamRecipients.value = newTeams;
    } else {
      const finder = recipient.id
        ? { id: recipient.id }
        : { email: recipient.email };

      const oldPerson = _.find(userRecipients.value, finder);
      const newPerson = _.merge({}, oldPerson, { payStructure: option });
      const newContacts = _.unionBy([newPerson], userRecipients.value, "name");

      userRecipients.value = newContacts;
    }
  }
  function reset() {
    sharingDataFields.value = [];
    userRecipients.value = [];
    teamRecipients.value = [];
  }

  return {
    sharingDataFields,
    userRecipients,
    teamRecipients,
    stagedCount,
    alreadyInStage,
    addSharingDataFields,
    addUser,
    addTeam,
    removeUser,
    removeTeam,
    removeFromDataFieldSharing,
    setRecipientPayStructure,
    reset,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSharingStore, import.meta.hot));
}
