<template>
  <div :class="workspaceLayout === 'topAndBottom' ? 'flex-1' : ''">
    <form v-if="creating" @submit.prevent class="relative">
      <div
        class="flex items-center justify-between"
        aria-orientation="horizontal"
        role="tablist"
      >
        <label for="investment" class="sr-only">New investment</label>

        <router-link
          :to="helpCenterPath"
          target="_blank"
          v-tooltip="'Get help'"
        >
          <QuestionMarkCircleIcon class="h-5 w-5 text-gray-700" />
        </router-link>

        <div class="flex items-center space-x-5">
          <div class="flex items-center">
            <button
              @click="cancel"
              type="button"
              class="-m-2.5 w-10 h-10 rounded-full inline-flex items-center justify-center text-gray-400 hover:text-gray-500"
            >
              <span class="sr-only">Cancel</span>
              <XMarkIcon class="h-5 w-5" />
            </button>
          </div>
        </div>
      </div>

      <div
        class="mt-1 border border-gray-300 rounded-lg shadow-sm focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500"
      >
        <CompanyContactAutocomplete
          :input-key="combinedKey"
          label="Capital providers"
          :companies="companies"
          @new-companies="setNewCompanies"
          @remove-company="removeCompany"
          @set-cross-interaction="setCrossInteraction"
        />

        <!-- Spacer element to match the height of the toolbar -->
        <div class="py-2" aria-hidden="true">
          <!-- Matches height of button in toolbar (1px border + 36px content height) -->
          <div class="py-px">
            <div class="h-9"></div>
          </div>
        </div>
      </div>

      <div
        class="absolute bottom-0 inset-x-0 pl-3 pr-2 py-2 flex justify-between"
      >
        <div class="flex items-center space-x-2">
          <DateStateField
            event-type="capitalEvent"
            :data-field="dataField"
            :state="state"
            :date="date"
            :compact="true"
            @new-date="setNewDate"
            @new-state="setNewState"
            @set-cross-interaction="setCrossInteraction"
          />

          <ValuationField
            :dollar-value="dollarValue"
            :dollar-value-type="dollarValueType"
            :state="state"
            :poppable="true"
            :deal-action="investmentType"
            @new-dollar-value="setNewDollarValue"
            @new-dollar-value-type="setNewDollarValueType"
          />
        </div>
        <div class="flex-shrink-0">
          <DataVisibilityButton
            :tooltip="
              canPersist ? null : 'Company or date/status required to save'
            "
            :visibility="canPersist ? 'safezone' : 'none'"
            class="inline-flex"
          >
            <template v-slot:button>
              <button
                @click="persist"
                :disabled="originatingData || !canPersist"
                type="button"
                :data-test="`${combinedKey}-save-capital-event-button`"
                :class="
                  canPersist
                    ? 'bg-yellow-500 hover:bg-yellow-600 focus:ring-yellow-600'
                    : 'bg-gray-300 hover:bg-gray-400 focus:ring-gray-500'
                "
                class="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2"
              >
                <PulseLoader
                  v-if="originatingData"
                  :loading="true"
                  size="3px"
                  color="#f3f4f6"
                />
                <span v-else>Save</span>
              </button>
            </template>
          </DataVisibilityButton>
        </div>
      </div>
    </form>

    <div v-else class="flex flex-col space-y-2">
      <ol v-if="canLink" class="flex flex-col space-y-2">
        <InvestmentLinkingControl
          v-for="investment in linkableInvestments"
          :key="investment.id"
          :investment="investment"
          @linking="setLinking"
          @perform-link="persist"
        />
      </ol>

      <DataVisibilityButton v-if="!linking" visibility="safezone">
        <template v-slot:button>
          <button
            @click="beginCreating"
            type="button"
            :data-test="`${combinedKey}-add-player-button`"
            class="group p-1.5 w-full flex items-center justify-between rounded-full border border-gray-300 shadow-sm space-x-2 text-left hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <span class="min-w-0 flex-1 flex items-center space-x-1">
              <span class="flex-shrink-0 flex items-center">
                <span
                  class="inline-flex items-center justify-center h-6 w-6 rounded-full bg-yellow-500"
                >
                  <PencilIcon class="h-4 w-4 text-white" />
                </span>
              </span>
              <span class="min-w-0 flex-1">
                <span class="text-sm font-medium text-gray-900 truncate"
                  >Add {{ actorLabel }}</span
                >
              </span>
            </span>
            <span
              class="flex-shrink-0 h-7 w-7 inline-flex items-center justify-center"
            >
              <PlusIcon
                class="h-5 w-5 text-gray-400 group-hover:text-gray-500"
              />
            </span>
          </button>
        </template>
      </DataVisibilityButton>

      <button
        v-if="canLink && !linking"
        @click="removeInvestment"
        type="button"
        class="inline-flex items-center p-2 border border-gray-300 text-sm leading-4 font-medium rounded-full shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Cancel
      </button>
    </div>
  </div>
</template>

<script setup>
import {
  QuestionMarkCircleIcon,
  PlusIcon,
  XMarkIcon,
} from "@heroicons/vue/20/solid";
import { PencilIcon } from "@heroicons/vue/24/outline";
import moment from "moment";
import DateStateField from "@/components/crowdsourcing/DateStateField.vue";
import InvestmentLinkingControl from "@/components/deal-builder/InvestmentLinkingControl.vue";
import CompanyContactAutocomplete from "@/components/crowdsourcing/CompanyContactAutocomplete.vue";
import ValuationField from "@/components/deal-builder/ValuationField.vue";
import DataVisibilityButton from "@/components/crowdsourcing/DataVisibilityButton.vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { useCrowdsourcedChangeGroupStore } from "@/stores/crowdsourcedChangeGroup";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { useDealBuilderStore } from "@/stores/dealBuilder";
import { useTimeTravelStore } from "@/stores/timeTravel";
import { storeToRefs } from "pinia";
import { ref, computed, watch, onMounted, nextTick } from "vue";
import api from "@/router/api";
import decoratingAndFieldKey from "@/components/crowdsourcing/decoratingAndFieldKey";
import _ from "lodash";
import { useRoute } from "vue-router";

const props = defineProps([
  "layerType",
  "propertyId",
  "dealBuilderAssetDataField",
  "dataField",
  "loanCollateralType",
  "loanSeniority",
  "future",
  "nested",
  "depth",
  "preactivate",
  "insertDirection", // { direction, investmentGroup }
  "portfolioToAdd",
]);
const emit = defineEmits(["cancel", "refetch-layer"]);

const layoutStore = useWorkspaceLayoutStore();
const { workspaceLayout } = storeToRefs(layoutStore);
const dealBuilderStore = useDealBuilderStore();
const { dealBuilder, crossInteraction } = storeToRefs(dealBuilderStore);
const timeTravelStore = useTimeTravelStore();
const { asOfDate } = storeToRefs(timeTravelStore);
const changeGroupStore = useCrowdsourcedChangeGroupStore();
const { originatingData, changeGroupId } = storeToRefs(changeGroupStore);

const creating = ref(false);
const linking = ref(null);
const localCompanies = ref([]);
const selectedDateMenuOption = ref(null);
const localDate = ref(null);
const localState = ref(null);
const dollarMenuOpen = ref(false);
const localDollarValue = ref(null);
const localDollarValueType = ref(null);

const before = computed(() => {
  return !props.future;
});
const route = useRoute();
const query = computed(() => route.query);
const horizontalIsDeals = computed(
  () => _.get(query.value, "horizontalTab") === "Deals",
);
const helpCenterPath = computed(() => {
  return {
    name: "HelpArticle",
    params: { articleId: "understand-the-capital-stack" },
  };
});
const canLink = computed(() => {
  return props.future && isEquity.value && linkableInvestments.value.length > 0;
});
const matchingAsset = computed(() =>
  dealBuilderStore.matchingAssetObject(assetKey.value),
);
const linkableInvestments = computed(() => {
  if (matchingAsset.value) {
    return matchingAsset.value.investments.filter((investment) => {
      const investmentContent = investment.fieldContent || investment;
      const matchingType = investmentContent.investmentType === props.layerType;
      const eligibleDate = moment(investmentContent.date).isAfter(
        moment(asOfDate.value),
      );

      return matchingType && eligibleDate;
    });
  } else {
    return [];
  }
});
const beforeSideDealBuilderLoan = computed(() => {
  return horizontalIsDeals.value && before.value && props.layerType === "Loan";
});
const isEquity = computed(() => {
  return props.layerType === "OwnershipInterest";
});
const companies = computed({
  get() {
    if (editingFuture.value) {
      return futureDeal.value.capitalProviders;
    } else {
      return localCompanies.value;
    }
  },
  set(newVal) {
    if (editingFuture.value) {
      let newInvestment = _.merge({}, futureDeal.value);

      newInvestment.capitalProviders = newVal;
      updateStoreInvestment(newInvestment);
    } else {
      localCompanies.value = newVal;
    }
  },
});
const date = computed({
  get() {
    if (editingFuture.value) {
      return futureDeal.value.date;
    } else {
      return localDate.value;
    }
  },
  set(newVal) {
    if (editingFuture.value) {
      let newInvestment = _.merge({}, futureDeal.value);

      newInvestment.date = newVal;
      updateStoreInvestment(newInvestment);
    } else {
      localDate.value = newVal;
    }
  },
});
const state = computed({
  get() {
    if (editingFuture.value) {
      return futureDeal.value.state;
    } else {
      return localState.value;
    }
  },
  set(newVal) {
    if (editingFuture.value) {
      let newInvestment = _.merge({}, futureDeal.value);

      newInvestment.state = newVal;
      updateStoreInvestment(newInvestment);
    } else {
      localState.value = newVal;
    }
  },
});
const dollarValue = computed({
  get() {
    if (editingFuture.value) {
      return futureDeal.value.dollarValue;
    } else {
      return localDollarValue.value;
    }
  },
  set(newVal) {
    if (editingFuture.value) {
      let newInvestment = _.merge({}, futureDeal.value);

      newInvestment.dollarValue = newVal;
      updateStoreInvestment(newInvestment);
    } else {
      localDollarValue.value = newVal;
    }
  },
});
const dollarValueType = computed({
  get() {
    if (editingFuture.value) {
      return futureDeal.value.dollarValueType;
    } else {
      return localDollarValueType.value;
    }
  },
  set(newVal) {
    if (editingFuture.value) {
      let newInvestment = _.merge({}, futureDeal.value);

      newInvestment.dollarValueType = newVal;
      updateStoreInvestment(newInvestment);
    } else {
      localDollarValueType.value = newVal;
    }
  },
});
const matchingInvestmentGroup = computed(() => {
  return _.find(
    _.get(dealBuilder.value, "investmentGroups", []),
    findMatchingGroupInvestment,
  );
});
const futureDeal = computed(() => {
  if (matchingInvestmentGroup.value) {
    return findMatchingGroupInvestment(matchingInvestmentGroup.value);
  } else {
    return null;
  }
});
const editingFuture = computed(() => {
  return props.future && futureDeal.value;
});
const assetKey = computed(() => {
  return decoratingAndFieldKey(props.dataField);
});
const combinedKey = computed(() => {
  if (editingFuture.value) {
    const assetKey = decoratingAndFieldKey(
      futureDeal.value.capitalStackTopLevelAsset,
    );
    const investmentKey = decoratingAndFieldKey(
      futureDeal.value.investmentFieldContent,
    );

    return `${assetKey}_${investmentKey}`;
  } else {
    return assetKey.value;
  }
});
const subjectIsFieldContent = computed(() => {
  return _.includes(
    ["Property", "PropertyRight"],
    props.dataField.decoratingContentType,
  );
});
const contentId = computed(() => {
  if (subjectIsFieldContent.value) {
    return props.dataField.fieldContentId;
  } else {
    return props.dataField.decoratingContentId;
  }
});
const contentType = computed(() => {
  if (subjectIsFieldContent.value) {
    return props.dataField.fieldContentType;
  } else {
    return props.dataField.decoratingContentType;
  }
});
const actorLabel = computed(() => {
  if (props.layerType === "OwnershipInterest") {
    return "owner";
  } else {
    return "lender";
  }
});
const validDollar = computed(() => {
  return dollarValueType.value && _.toNumber(dollarValue.value) > 10000;
});
const dataFieldBeingSubordinated = computed(() => {
  const shouldProvide =
    props.insertDirection?.direction === "superior" &&
    props.loanCollateralType !== "equity_entity";

  if (shouldProvide) {
    return _.get(props.dataField, "localId");
  } else {
    return null;
  }
});
const investmentType = computed(() => {
  return _.get(futureDeal.value, "dealAction", impliedDealAction.value);
});
const impliedDealAction = computed(() => {
  if (isEquity.value) {
    return "sellOutright";
  } else {
    return "originateLoan";
  }
});
const hasCompanies = computed(() => persistPayload.value.companies.length > 0);
const hasState = computed(() => !!persistPayload.value.state);
const canPersist = computed(() => {
  return hasCompanies.value || hasState.value || linking.value;
});
const persistPayload = computed(() => {
  const entireInterestIds = _.get(
    dealBuilderStore.matchingAssetObject(assetKey.value),
    "entireInterests",
    [],
  ).map((id) => {
    return { id };
  });
  const partialInterestIds = _.get(
    dealBuilderStore.matchingAssetObject(assetKey.value),
    "partialInterests",
    [],
  ).map((id) => {
    return { id };
  });
  const assumptionLoanIds = _.get(
    dealBuilderStore.matchingAssetObject(assetKey.value),
    "assumptionLoans",
    [],
  ).map((id) => {
    return { id };
  });
  const foreclosureLoanIds = _.get(
    dealBuilderStore.matchingAssetObject(assetKey.value),
    "foreclosureLoans",
    [],
  ).map((id) => {
    return { id };
  });

  return {
    layerType: props.layerType,
    linkToInvestmentId: linking.value,
    existingDataFieldId: dataFieldBeingSubordinated.value,
    contentId: contentId.value,
    contentType: contentType.value,
    collateralType: props.loanCollateralType,
    seniority: props.loanSeniority,
    companies: companies.value,
    advisors: _.get(futureDeal.value, "advisors", []),
    entireInterestIds,
    partialInterestIds,
    investmentType: investmentType.value,
    foreclosureLoanIds,
    assumptionLoanIds,
    date: date.value,
    state: state.value,
    dollarValue: validDollar.value ? dollarValue.value : null,
    dollarValueType: validDollar.value ? dollarValueType.value : null,
    changeGroupId: changeGroupId.value,
  };
});

watch(canLink, (linkable, oldLinkable) => {
  if (!oldLinkable && linkable && creating.value) {
    creating.value = false;
  }
});
onMounted(() => {
  if (props.preactivate || (editingFuture.value && !canLink.value)) {
    beginCreating();

    if (beforeSideDealBuilderLoan.value) {
      sendToDealBuilder();
    }
  }
});

function setLinking(id = null) {
  linking.value = id;
}
function clearMetaData(dealAction) {
  const loanClearingActions = [
    "foreclosureSellOutright",
    "sellOutright",
    "sellEntireInterest",
    "sellPartialInterest",
    "sellMultipleInterests",
  ];

  if (_.intersection(loanClearingActions, [dealAction]).length > 0) {
    dealBuilderStore.clearDealBuilderAssetManipulatedLoans({
      assetKey: assetKey.value,
    });
    dealBuilderStore.clearDealBuilderAssetIndividualOwnershipInterests({
      assetKey: assetKey.value,
    });
  }
}
function removeInvestment() {
  clearMetaData(futureDeal.value.dealAction);
  dealBuilderStore.removeDealBuilderInvestment({
    investmentKey: combinedKey.value,
    dealAction: futureDeal.value.dealAction,
  });
}
function removeCompany(company) {
  if (editingFuture.value) {
    const groupId = _.get(matchingInvestmentGroup.value, "placeholderId");

    dealBuilderStore.removeInvestmentGroupInvestmentPlayer({
      groupId,
      investmentCombinedKey: combinedKey.value,
      toRemove: company,
      path: "capitalProviders",
    });
  } else {
    const filteredCompanies = _.difference(companies.value, [company]);

    setNewCompanies(filteredCompanies);
  }
}
function updateStoreInvestment(newInvestment) {
  const groupId = _.get(matchingInvestmentGroup.value, "placeholderId");

  dealBuilderStore.updateInvestmentGroupInvestment({
    groupId,
    newInvestment,
  });
}
function findMatchingGroupInvestment(group) {
  const assetKey = decoratingAndFieldKey(props.dataField);
  const dealActions =
    props.layerType === "OwnershipInterest"
      ? [
          "foreclosureSellOutright",
          "sellOutright",
          "sellEntireInterest",
          "sellPartialInterest",
          "sellMultipleInterests",
        ]
      : ["refinance", "originateLoan"];

  return _.find(group.investments, function (investment) {
    if (investment.capitalStackTopLevelAsset) {
      const matchValue =
        investment.investmentFieldContent.fieldContentType ===
        "OwnershipInterest"
          ? investment.capitalStackTopLevelAsset
          : investment.investmentFieldContent;
      const matchingKey = assetKey === decoratingAndFieldKey(matchValue);
      const matchingAction =
        _.intersection(dealActions, [investment.dealAction]).length > 0;

      return matchingKey && matchingAction;
    } else {
      return false;
    }
  });
}
function setCrossInteraction({ focus }) {
  if (editingFuture.value) {
    const newCrossInteraction = {
      dealAction: futureDeal.value.dealAction,
      assetKey: decoratingAndFieldKey(
        futureDeal.value.capitalStackTopLevelAsset,
      ),
      combinedKey: combinedKey.value,
      focus,
      source: "CreateCapitalEvent",
    };

    crossInteraction.value = newCrossInteraction;
  }
}
function setNewCompanies(newCompanies) {
  companies.value = newCompanies;
}
function setNewDollarValue(newValue) {
  dollarValue.value = newValue;
}
function setNewDollarValueType(newValueType) {
  dollarValueType.value = newValueType;
}
function setNewDate(newDate) {
  date.value = newDate;
}
function setNewState(newState) {
  if (
    !editingFuture.value &&
    _.includes(["live", "under_contract"], newState)
  ) {
    sendToDealBuilder(newState);
  } else {
    state.value = newState;
  }
}
function sendToDealBuilder(newState = null) {
  let stubbed = stubbedDeal(newState);
  const addablePortfolio =
    props.portfolioToAdd || props.insertDirection?.investmentGroup;
  if (addablePortfolio && addablePortfolio !== "new") {
    dealBuilderStore.addDealBuilderInvestmentToGroup({
      existingInvestment: null,
      stubbedDeal: stubbed,
      assetDataField: props.dataField,
      investmentFieldContent: props.dataField,
      ownershipInterestIds: [],
      investmentGroupPlaceholderId: addablePortfolio.placeholderId,
    });
    dealBuilderStore.collapsePortfolioAssets({
      groupId: addablePortfolio.id || addablePortfolio.placeholderId,
    });
    dealBuilderStore.setDealBuilderInvestmentGroupExpanded({
      groupId: addablePortfolio.id || addablePortfolio.placeholderId,
      expanded: true,
    });
  } else {
    const dealAction = isEquity.value ? "sellOutright" : "originateLoan";
    dealBuilderStore.prepopulateDealBuilder({
      dealAction,
      stubbedDeal: stubbed,
      futureDeal: futureDeal.value,
      matchingInvestmentGroup: matchingInvestmentGroup.value,
      dealBuilderAssetDataField: props.dealBuilderAssetDataField,
      localAssetDataField: props.dataField,
      decoratingDataField: props.dataField,
      nestedCapitalStack: props.nested,
    });
  }

  setTimeout(() => {
    cancel();
  }, 50);
}
function stubbedDeal(newState = null) {
  return {
    capitalProviders: companies.value,
    advisors: [],
    date: date.value,
    state: newState,
    dollarValue: persistPayload.value.dollarValue,
    dollarValueType: persistPayload.value.dollarValueType,
    insertDirection: props.insertDirection?.direction,
    loanCollateralType: props.loanCollateralType,
    loanSeniority: props.loanSeniority,
    loanUpstreamDataField: props.dataField,
    depth: "new",
  };
}
function beginCreating() {
  creating.value = true;
}
function cancel() {
  creating.value = false;
  companies.value = [];
  date.value = null;
  state.value = null;
  selectedDateMenuOption.value = null;
  dollarMenuOpen.value = false;
  dollarValue.value = null;
  dollarValueType.value = null;
  crossInteraction.value = null;
  setLinking();

  if (editingFuture.value && !canLink.value) {
    removeInvestment();
  } else if (props.future && canLink.value) {
    removeInvestment();
  }
  emit("cancel");
}
function persist() {
  if (canPersist.value) {
    setTimeout(() => {
      const dealAction = persistPayload.value.investmentType;
      const apiRequestFunc = () =>
        api.post(`investments`, persistPayload.value);
      const successCallback = async (json) => {
        await dealBuilderStore.postEditingPatch(json);
        clearMetaData(dealAction);
        dealBuilderStore.clearDealBuilder(false);
        if (!dealBuilder.value && persistPayload.value?.existingDataFieldId) {
          emit("refetch-ownership-layer");
        } else {
          emit("refetch-layer");
        }
        await nextTick();
        cancel();
      };
      const failureCallback = () => cancel();
      return changeGroupStore.originateData(
        apiRequestFunc,
        successCallback,
        failureCallback,
      );
    }, 125);
  }
}
</script>
