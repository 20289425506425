<template>
  <div class="border-l-4 border-yellow-400 bg-yellow-50 p-4">
    <div class="flex">
      <div class="flex-shrink-0">
        <ExclamationTriangleIcon
          class="h-5 w-5 text-yellow-400"
          aria-hidden="true"
        />
      </div>
      <div class="ml-3">
        <p class="text-sm text-yellow-700">
          {{ alertText }}&nbsp;
          <a
            href=""
            @click.prevent="emit('open-portfolio')"
            class="font-medium text-yellow-700 underline hover:text-yellow-600"
            >View the portfolio</a
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ExclamationTriangleIcon } from "@heroicons/vue/20/solid";
import { computed } from "vue";

const props = defineProps(["contentType"]);
const emit = defineEmits(["open-portfolio"]);

const alertText = computed(() => {
  switch (props.contentType) {
    case "Loan":
    case "Valuation":
      return `${props.contentType} metrics are being modeled at the portfolio level.`;
    default:
      return "These metrics are being modeled at the portfolio level.";
  }
});
</script>
