<template>
  <button
    @mousedown="holdKey"
    @mouseup="releaseKey"
    type="button"
    :disabled="disabled"
    v-tooltip="tooltip"
    :class="
      _.concat(
        [
          acceptButton ? 'hold-rest-yes' : '',
          rejectButton ? 'hold-rest-no' : '',
          label === 'Skip' ? 'hold-rest-skip' : '',
          holdingKey ? 'holding-text holding' : '',
          disabled ? 'text-gray-500 cursor-not-allowed' : enabledButtonClass,
        ],
        buttonShapeClasses,
      )
    "
    class="-ml-px hold-rest-text hold-rest relative inline-flex items-center px-3 py-2 border border-gray-300 text-sm font-medium focus:z-10 focus:outline-none focus:ring-1"
    :data-test="`${testPrefix || 'solo'}-validation-accept-button-${
      testIndex || 0
    }`"
  >
    <component
      v-if="!actionInProgress"
      :is="iconComponent"
      :class="[
        holdingKey ? 'holding-text' : '',
        compact && disabled ? 'text-gray-500' : enabledInnerClass,
      ]"
      class="hold-rest-text h-5 w-5"
    />
    <PulseLoader
      v-if="actionInProgress"
      :loading="true"
      size="4px"
      :color="pulseColor"
    />
    <span v-else :class="compact ? 'sr-only' : ''">{{ label }}</span>
  </button>
</template>

<script setup>
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { computed, onBeforeUnmount, onMounted, ref, watch } from "vue";
import _ from "lodash";

const props = defineProps([
  "label",
  "actionCallback",
  "iconComponent",
  "tooltip",
  "compact",
  "disabled",
  "actionInProgress",
  "enabledButtonClass",
  "enabledInnerClass",
  "buttonShapeClasses",
  "pulseColor",
  "testPrefix",
  "testIndex",
]);

const keyHoldTimer = ref(null);
const holdingKey = ref(false);
const acceptButton = computed(() =>
  _.includes(["Accept", "Approve"], props.label),
);
const rejectButton = computed(() =>
  _.includes(["Reject", "Reject all"], props.label),
);

watch(holdingKey, () => {
  switch (holdingKey.value) {
    case "Approve":
    case "Accept":
    case "Reject":
    case "Reject all":
    case "Skip":
      {
        if (!props.disabled && !props.actionInProgress) {
          clearTimer();
          keyHoldTimer.value = setTimeout(props.actionCallback, 1000);
        }
      }
      break;
    default:
      clearTimer();
      break;
  }
});

onMounted(() => {
  clearTimeout(keyHoldTimer.value);
});

onBeforeUnmount(() => {
  clearTimer();
});

function clearTimer() {
  if (keyHoldTimer.value) {
    clearTimeout(keyHoldTimer.value);
  }
}

function holdKey() {
  if (!holdingKey.value) {
    console.log("holding key");
    holdingKey.value = props.label;
  }
}

function releaseKey() {
  console.log("releasing key");
  holdingKey.value = false;
}
</script>

<style scoped>
.hold-rest-yes {
  background: linear-gradient(to right, #22c55e 50%, white 50%);
}
.hold-rest-no {
  background: linear-gradient(to right, #ef4444 50%, white 50%);
}
.hold-rest-skip {
  background: linear-gradient(to right, #3b82f6 50%, white 50%);
}

.hold-rest {
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all 1s ease-out;
}

.holding {
  background-position: left bottom;
}

.hold-rest-text {
  transition: all 1.1s ease-out;
}

.holding-text {
  color: white;
}
</style>
