<template>
  <button
    v-if="location"
    @click="centerOnMap"
    type="button"
    v-tooltip="'Center on map'"
    class="h-5 w-5 inline-flex justify-center items-center text-sm text-gray-700 hover:text-gray-800"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="h-5 w-5"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        fill-rule="evenodd"
        d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
        clip-rule="evenodd"
      />
    </svg>
  </button>
</template>

<script setup>
import api from "@/router/api";
import { useMainMapStore } from "@/stores/mainMap";
import { storeToRefs } from "pinia";
import { ref, computed, onMounted } from "vue";
import _ from "lodash";

const props = defineProps(["propertyId"]);

const mapStore = useMainMapStore();
const { temporaryMapCenter, newOriginOverride } = storeToRefs(mapStore);

const locationDataField = ref(null);
const location = computed(() => {
  return _.get(locationDataField.value, "fieldContent");
});

onMounted(() => {
  fetchPropertyLocation();
});

function fetchPropertyLocation() {
  if (props.propertyId) {
    api
      .get(
        `crowdsourced_data_fields/Property/${props.propertyId}?field_name=ContentLocation`
      )
      .then((json) => {
        locationDataField.value = json.data;
      });
  }
}
function centerOnMap() {
  newOriginOverride.value = true;
  temporaryMapCenter.value = {
    lat: _.get(location.value, "lat"),
    lng: _.get(location.value, "lng"),
    zoom: 16.5,
  };
}
</script>
