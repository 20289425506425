<template>
  <div>
    <div v-if="statusCheckComplete">
      <component
        v-if="chargesEnabled && destinationComponent"
        :is="destinationComponent"
        v-bind="destinationProps"
      />
      <div v-else class="">
        <h3 class="text-base leading-6 font-medium text-gray-900">
          Setup Personal Payouts
        </h3>
        <div
          class="mt-2"
          :class="
            workspaceLayout === 'sideBySide'
              ? ''
              : 'flex items-start justify-between'
          "
        >
          <div class="max-w-xl text-sm leading-5 text-gray-500">
            <p>
              This one-time onboarding process allows you to receive payouts to
              a personal account. Once complete, you'll be able to earn revenue
              across the platform.
            </p>
          </div>
          <div
            class=""
            :class="
              workspaceLayout === 'sideBySide'
                ? 'mt-5'
                : 'ml-6 flex-shrink-0 flex items-center'
            "
          >
            <span class="inline-flex rounded-md shadow-sm">
              <a
                :href="onboardingUrl"
                class="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:ring-indigo active:bg-indigo-700"
                data-test="payouts-onboarding-button"
              >
                Let's go
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="">
      <div class="flex justify-center">
        <grid-loader
          :loading="!statusCheckComplete"
          size="7px"
          color="#5850ec"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { useModalStore } from "@/stores/modal";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { storeToRefs } from "pinia";
import { computed, onMounted, ref, watch } from "vue";
import GridLoader from "vue-spinner/src/GridLoader.vue";
import api from "@/router/api";
import _ from "lodash";

const parentProps = defineProps({
  value: {
    type: Object,
    default: null,
  },
});
const emit = defineEmits(["connected-account-checked"]);

const layoutStore = useWorkspaceLayoutStore();
const { workspaceLayout } = storeToRefs(layoutStore);
const chargesEnabled = ref(false);
const onboardingUrl = ref(null);
const statusCheckComplete = ref(false);
const destinationComponent = computed(() => {
  return _.get(parentProps.value, "component", null);
});
const destinationProps = computed(() => {
  return _.get(parentProps.value, "props", null);
});
const returnUrl = computed(() => {
  return _.get(destinationProps.value, "returnUrl", null);
});

watch(returnUrl, async () => {
  console.log("return url");
  if (!statusCheckComplete.value && returnUrl.value) checkConnectedAccount();
});

onMounted(async () => {
  if (returnUrl.value) checkConnectedAccount();
  // TODO: Handle refresh_url
});

const modalStore = useModalStore();

async function checkConnectedAccount() {
  api
    .post(`payment_connected_accounts`, {
      returnUrl: returnUrl.value,
    })
    .then((json) => {
      chargesEnabled.value = json.data.chargesEnabled;
      onboardingUrl.value = json.data.url;
      statusCheckComplete.value = true;
      emit("connected-account-checked");

      if (_.get(destinationProps, "closeOnSuccess", false) && chargesEnabled) {
        // $store.commit(
        //   "setTemporaryAccess",
        //   destinationProps.value.afterClosePayload
        // );
        modalStore.closeModal();
      }
    });
}
</script>
