<template>
  <div
    class="container mx-auto md:mt-12 mb-4 px-4 sm:px-8 lg:px-10 flex flex-col items-center"
    data-test="file-viewer"
  >
    <div class="flex justify-center md:mb-2">
      <div class="flex items-center font-medium text-gray-300">
        <span>{{ file.name }}</span>
      </div>
    </div>

    <div :style="fileHeight" class="mt-4 mb-2 w-full flex flex-col flex-1">
      <FileDisplay :file="file" class="w-full" />
    </div>
  </div>
</template>

<script setup>
import { computed, onBeforeUnmount, onMounted, ref } from "vue";
import _ from "lodash";
import FileDisplay from "@/components/crowdsourcing/FileDisplay.vue";

defineProps(["file"]);
let debouncedHeightUpdate = null;
const modalHeight = ref(null);
const fileHeight = computed(() => {
  if (modalHeight.value) {
    const heightRatio = modalHeight.value > 400 ? 0.7 : 0.5;

    return `height: ${modalHeight.value * heightRatio}px;`;
  } else {
    return "height: 200px";
  }
});

onMounted(() => {
  modalHeight.value = document.getElementById("xlModalVue").offsetHeight;

  debouncedHeightUpdate = _.debounce(() => {
    modalHeight.value = document.getElementById("xlModalVue").offsetHeight;
  }, 100);

  window.addEventListener("resize", debouncedHeightUpdate);
});
onBeforeUnmount(() => {
  window.removeEventListener("resize", debouncedHeightUpdate);
});
</script>
