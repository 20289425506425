import { ref, computed } from "vue";
import { defineStore, acceptHMRUpdate, storeToRefs } from "pinia";
import { useUserStore } from "@/stores/user";
import { useGuestProfileStore } from "@/stores/guestProfile";
import { useTasksStore } from "@/stores/tasks";
import api from "@/router/api";
import _ from "lodash";

export const useTaskListStore = defineStore("taskList", () => {
  const tasksStore = useTasksStore();
  const guestProfileStore = useGuestProfileStore();
  const { guestTaskLists } = storeToRefs(guestProfileStore);
  const userStore = useUserStore();
  const { signedIn } = storeToRefs(userStore);

  const userRecipients = ref([]);
  const teamRecipients = ref([]);
  const sharingPayload = computed(() => {
    return {
      userRecipients: userRecipients.value.map((person) => {
        if (person.className === "RawEmail") {
          return {
            email: person.email,
            className: "RawEmail",
          };
        } else {
          return person;
        }
      }),
      teamRecipients: teamRecipients.value,
    };
  });
  function addUser(user) {
    const newUsers = _.unionBy([user], userRecipients.value, "name");

    userRecipients.value = newUsers;
  }
  function removeUser(user) {
    const newUsers = _.differenceBy(userRecipients.value, [user], "name");

    userRecipients.value = newUsers;
  }
  function addTeam(team) {
    const newTeams = _.unionBy([team], teamRecipients.value, "name");

    teamRecipients.value = newTeams;
  }
  function removeTeam(team) {
    const newTeams = _.differenceBy(teamRecipients.value, [team], "name");

    teamRecipients.value = newTeams;
  }

  const colors = ref([
    {
      name: "Red",
      textColor: "text-red-500",
      borderColor: "border-red-500",
      bgColor: "bg-red-500",
      selectedColor: "ring-red-500",
      shadowColor: "shadow-red-500",
    },
    {
      name: "Orange",
      textColor: "text-orange-500",
      borderColor: "border-orange-500",
      bgColor: "bg-orange-500",
      selectedColor: "ring-orange-500",
      shadowColor: "shadow-orange-500",
    },
    {
      name: "Yellow",
      textColor: "text-yellow-500",
      borderColor: "border-yellow-500",
      bgColor: "bg-yellow-500",
      selectedColor: "ring-yellow-500",
      shadowColor: "shadow-yellow-500",
    },
    {
      name: "Green",
      textColor: "text-green-500",
      borderColor: "border-green-500",
      bgColor: "bg-green-500",
      selectedColor: "ring-green-500",
      shadowColor: "shadow-green-500",
    },
    {
      name: "Blue",
      textColor: "text-blue-500",
      borderColor: "border-blue-500",
      bgColor: "bg-blue-500",
      selectedColor: "ring-blue-500",
      shadowColor: "shadow-blue-500",
    },
    {
      name: "Purple",
      textColor: "text-purple-500",
      borderColor: "border-purple-500",
      bgColor: "bg-purple-500",
      selectedColor: "ring-purple-500",
      shadowColor: "shadow-purple-500",
    },
    {
      name: "Pink",
      textColor: "text-pink-500",
      borderColor: "border-pink-500",
      bgColor: "bg-pink-500",
      selectedColor: "ring-pink-500",
      shadowColor: "shadow-pink-500",
    },
    {
      name: "Brown",
      textColor: "text-stone-600",
      borderColor: "border-stone-600",
      bgColor: "bg-stone-600",
      selectedColor: "ring-stone-600",
      shadowColor: "shadow-stone-600",
    },
  ]);
  const defaultDisplayOptions = ref({
    showCompleted: true,
    sortBy: "manual",
    sortDirection: null,
  });

  const name = ref("");
  const selectedColor = ref(colors.value[0]);

  function colorFor(list, colorType = "bgColor") {
    const color = _.find(colors.value, { name: list.color });

    return _.get(color, colorType);
  }

  function mountExisting(list) {
    name.value = list.name;
    selectedColor.value = _.find(colors.value, { name: list.color });
  }

  function createList(selectedList) {
    const payload = {
      name: name.value,
      color: selectedColor.value.name,
    };

    if (selectedList) {
      // UPDATE
      if (signedIn.value) {
        return api.patch(`task_lists/${selectedList.id}`, payload);
      } else {
        return new Promise((resolve) => {
          const newTaskList = _.merge({}, selectedList, payload);
          tasksStore.patchTaskList(newTaskList);
          resolve();
        });
      }
    } else {
      // CREATE
      if (signedIn.value) {
        return api.post(`task_lists`, payload);
      } else {
        return new Promise((resolve) => {
          guestTaskLists.value.push(
            _.merge({}, payload, defaultDisplayOptions.value)
          );
          resolve();
        });
      }
    }
  }

  async function shareList(selectedList) {
    const payload = _.merge({}, sharingPayload.value, {
      taskListId: selectedList.id,
    });
    api.post(`task_list_sharings`, payload);
  }

  function resetSharing() {
    userRecipients.value = [];
    teamRecipients.value = [];
  }

  function reset() {
    name.value = "";
    selectedColor.value = colors.value[0];
    resetSharing();
  }

  return {
    name,
    selectedColor,
    colors,
    defaultDisplayOptions,
    userRecipients,
    teamRecipients,
    addTeam,
    addUser,
    removeTeam,
    removeUser,
    createList,
    shareList,
    mountExisting,
    colorFor,
    reset,
    resetSharing,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useTaskListStore, import.meta.hot));
}
