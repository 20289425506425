<template>
  <div
    class="w-64 bg-white divide-y divide-gray-100 focus:outline-none"
    role="menu"
    aria-orientation="vertical"
    aria-labelledby="menu-button"
    tabindex="-1"
  >
    <div class="p-2">
      <div class="flex items-center space-x-1">
        <label class="text-sm font-medium text-gray-900">Which premises?</label>
      </div>
      <p class="text-xs leading-5 text-gray-500">
        This (sub)tenant controls multiple spaces.
        <a
          href=""
          @click.prevent
          class="font-medium text-indigo-700 underline hover:text-indigo-600"
          >Learn more.</a
        >
      </p>
    </div>
    <div class="py-1" role="none">
      <a
        @click.prevent="emit('single-space-action')"
        href=""
        class="text-gray-700 group flex items-center p-2 text-xs hover:bg-gray-100 hover:text-gray-900"
        role="menuitem"
        tabindex="-1"
        id="menu-item-4"
      >
        <StopIcon
          class="flex-shrink-0 mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500"
        />
        Just this space
      </a>
      <a
        @click.prevent="emit('space-group-action')"
        href=""
        class="text-gray-700 group flex items-center p-2 text-xs hover:bg-gray-100 hover:text-gray-900"
        role="menuitem"
        tabindex="-1"
        id="menu-item-4"
      >
        <Square3Stack3DIcon
          class="flex-shrink-0 mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500"
        />
        The entire premises
      </a>
    </div>
  </div>
</template>

<script setup>
import { StopIcon, Square3Stack3DIcon } from "@heroicons/vue/20/solid";

const emit = defineEmits(["single-space-action", "space-group-action"]);
</script>
