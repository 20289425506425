<template>
  <tr v-if="field" :class="selected ? 'bg-gray-50' : ''">
    <td class="relative w-12 px-4">
      <div
        v-if="selected"
        class="absolute inset-y-0 left-0 w-0.5 bg-indigo-600"
      ></div>

      <input
        @click="toggleSelected"
        type="checkbox"
        :checked="selected"
        :value="selected"
        class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
      />
    </td>
    <td class="py-4 pl-1 pr-3 text-gray-500 space-y-1">
      <DataField
        v-if="propertyDataField"
        :data-field="propertyDataField"
        primary-text-path="fieldContent.name"
        text-classes="text-sm font-medium"
        dropdown-placement="left-start"
        text-styles=""
      />
      <a
        @click.prevent="selectDecorating"
        href=""
        class="text-xs text-indigo-600 hover:text-indigo-900"
        >{{ _.truncate(validationContextMetaData?.summary, { length: 50 }) }}</a
      >
      <div class="text-xs text-gray-500">
        {{ fieldDecoratingType }}
      </div>
    </td>
    <td class="flex flex-col px-3 py-4">
      <DataField
        :data-field="field"
        :safezone-tab="true"
        text-classes="text-sm font-medium"
        :dropdown-placement="
          workspaceLayout === 'sideBySide' ? 'left-start' : null
        "
        @open-sourced="openSourced"
        @dismiss="dismissed"
      />
      <div class="flex space-x-1">
        <button
          v-if="!authored || !field.declassifiable"
          @click="unselectClassifiedOnly"
        >
          <LockClosedIcon
            v-tooltip="'Cannot declassify'"
            class="h-4 w-4 text-green-400"
          />
        </button>
        <div class="text-gray-500 text-xs pl-1">
          {{ fieldType }} &middot;
          {{ moment(field?.createdAt).format("MM/DD/YYYY, h:mm a") }}
        </div>
      </div>
    </td>
    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
      <a
        @click.prevent="selectGroup"
        href=""
        class="text-indigo-600 hover:text-indigo-900"
        >{{ field.latestChangeGroupId }}</a
      >
    </td>
    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
      <div class="flex justify-between items-center">
        <div class="flex items-center space-x-2 flex-shrink-0">
          <template v-if="licensed">
            <button
              v-if="staged"
              @click="removeFromSharingStage"
              v-tooltip="'Remove from Sharing Stage'"
              type="button"
              class="mr-1 flex-shrink-0 bg-orange-100 inline-flex h-6 w-6 items-center justify-center rounded-full border-2 border-dashed border-orange-200 text-orange-400 hover:text-orange-500 hover:border-orange-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              :data-test="`safezone-table-row-${index}-share-button`"
            >
              <span class="sr-only">Share</span>
              <MinusIcon class="h-4 w-4" />
            </button>
            <button
              v-else
              @click="addToSharingStage"
              v-tooltip="'Add to Sharing Stage'"
              type="button"
              class="mr-1 flex-shrink-0 bg-white inline-flex h-6 w-6 items-center justify-center rounded-full border-2 border-dashed border-gray-200 text-gray-400 hover:text-orange-500 hover:border-orange-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              :data-test="`safezone-table-row-${index}-share-button`"
            >
              <span class="sr-only">Share</span>
              <PlusIcon class="h-4 w-4" />
            </button>
          </template>
          <button
            v-else
            v-tooltip="'Unlock to share'"
            type="button"
            class="mr-1 flex-shrink-0 bg-white inline-flex h-6 w-6 items-center justify-center rounded-full border-2 border-dashed border-gray-200 text-gray-400 hover:text-pink-500 hover:border-pink-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 cursor-not-allowed"
            :data-test="`safezone-table-row-${index}-disabled-share-button`"
          >
            <span class="sr-only">Share</span>
            <!-- Heroicon name: solid/plus -->
            <svg
              class="h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                clip-rule="evenodd"
              />
            </svg>
          </button>

          <div class="flex items-center -space-x-1" style="width: 84px">
            <VMenu
              v-for="(recipient, index) in slicedSharingRecipients"
              :key="`${recipient.className}-${recipient.tokenId}`"
              :disabled="recipient.easyDataInputReferral"
              class="flex-shrink-0"
            >
              <AvatarPhoto
                :team-name="recipient.teamName"
                :person="recipient"
                border-class="ring-2 ring-white"
                shape-size="h-6 w-6"
                text-size="xs"
                :data-test="`sharing-recipient-${index}-avatar`"
                class="cursor-pointer"
              />

              <template #popper>
                <SharingRecipientActionMenu
                  :recipient="recipient"
                  :index="index"
                  :menu-disabled="!authored || recipient.easyDataInputReferral"
                  @refetch-tokens="fetchAccessTokens"
                />
              </template>
            </VMenu>
          </div>

          <VMenu v-if="sharingOverflow">
            <span class="flex-shrink-0 ml-1 text-xs leading-5 font-medium"
              >+{{ sharingOverflow }}</span
            >

            <template #popper>
              <div
                class="w-64 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="menu-button"
                tabindex="-1"
              >
                <div class="py-1" role="none">
                  <VDropdown
                    v-for="(recipient, index) in overflowRecipients"
                    :key="recipient.id"
                    :disabled="!authored"
                    placement="left-start"
                  >
                    <a
                      @click.prevent
                      href=""
                      class="text-gray-700 hover:bg-gray-100 hover:text-gray-900 group flex items-center px-4 py-2 text-sm"
                      role="menuitem"
                      tabindex="-1"
                      id="menu-item-0"
                    >
                      {{ recipient.name }}
                    </a>

                    <template #popper>
                      <SharingRecipientActionMenu
                        :recipient="recipient"
                        :index="index"
                        @refetch-tokens="fetchAccessTokens"
                      />
                    </template>
                  </VDropdown>
                </div>
              </div>
            </template>
          </VMenu>
        </div>

        <VDropdown v-if="sharing.length > 1" class="flex items-center">
          <button
            type="button"
            class="h-5 w-5 inline-flex justify-center items-center text-sm text-gray-500 hover:text-gray-700"
            :data-test="`safezone-table-row-${index}-extended-options-button`"
          >
            <EllipsisHorizontalIcon class="h-5 w-5" />
          </button>

          <template #popper>
            <div
              class="w-64 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="menu-button"
              tabindex="-1"
            >
              <div class="py-1" role="none">
                <a
                  @click.prevent="revokeAll"
                  v-close-popper
                  href=""
                  class="text-gray-700 hover:bg-gray-100 hover:text-gray-900 group flex items-center px-4 py-2 text-sm"
                  role="menuitem"
                  tabindex="-1"
                  id="menu-item-0"
                  :data-test="`safezone-table-row-${index}-revoke-all-access-button`"
                >
                  Remove all
                </a>
              </div>
            </div>
          </template>
        </VDropdown>
      </div>
    </td>
  </tr>
</template>

<script setup>
import moment from "moment";
import AvatarPhoto from "@/components/users/AvatarPhoto.vue";
import DataField from "@/components/crowdsourcing/DataField.vue";
import SharingRecipientActionMenu from "@/components/crowdsourcing/safezone/SharingRecipientActionMenu.vue";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { useSharingStore } from "@/stores/sharing";
import { useDataLicensesChannelStore } from "@/stores/dataLicensesChannel";
import { useDataSharingsChannelStore } from "@/stores/dataSharingsChannel";
import { usePropertyFieldsStore } from "@/stores/propertyFields";
import api from "@/router/api";
import { computed, onMounted, ref, watch } from "vue";
import { storeToRefs } from "pinia";
import _ from "lodash";
import {
  EllipsisHorizontalIcon,
  LockClosedIcon,
  MinusIcon,
  PlusIcon,
} from "@heroicons/vue/20/solid";

const props = defineProps(["dataFieldId", "selected", "index"]);
const emit = defineEmits([
  "attach-field",
  "open-sourced",
  "dismissed",
  "unselect",
  "select",
  "select-decorating",
  "select-group",
]);

const field = ref(null);
const propertyId = ref(null);
const validationContextMetaData = ref(null);
const accessTokens = ref([]);

const layoutStore = useWorkspaceLayoutStore();
const { workspaceLayout } = storeToRefs(layoutStore);
const sharingStore = useSharingStore();
const { sharingDataFields } = storeToRefs(sharingStore);
const dataLicensesChannelStore = useDataLicensesChannelStore();
const { dataLicensesChannelDataQueue } = storeToRefs(dataLicensesChannelStore);
const dataSharingsChannelStore = useDataSharingsChannelStore();
const { dataSharingsChannelDataQueue } = storeToRefs(dataSharingsChannelStore);
const propertyFieldsStore = usePropertyFieldsStore();

const propertyDataField = computed(() => {
  if (propertyId.value) {
    return propertyFieldsStore.getPropertyField(propertyId.value);
  } else {
    return null;
  }
});

const fieldType = computed(() => {
  if (field.value?.fieldContentType) {
    return _.startCase(field.value.fieldContentType);
  } else if (field.value?.fieldName) {
    return _.startCase(field.value.fieldName);
  } else {
    return "Unknown field type";
  }
});
const fieldDecoratingType = computed(() => {
  if (field.value?.decoratingContentType) {
    return _.startCase(field.value.decoratingContentType);
  } else {
    return "";
  }
});
const authored = computed(() => {
  return _.get(field.value, "authored");
});
const licensed = computed(() => {
  return !unlockable.value;
});
const staged = computed(
  () =>
    !!_.find(sharingDataFields.value, function (datafield) {
      return datafield?.localId === field.value?.localId;
    }),
);
const unlockable = computed(() => {
  const rawPrice = _.get(field.value, "price");
  const price = rawPrice ? _.toNumber(rawPrice) : null;

  return price && price > 0;
});
const userTokens = computed(() => _.get(accessTokens.value, "users", []));
const teamTokens = computed(() => _.get(accessTokens.value, "teams", []));
const sharing = computed(() => {
  const combined = _.concat(teamTokens.value, userTokens.value);

  return combined.map((token) => {
    switch (token.className) {
      case "CrowdsourcedDataAccessToken":
        return _.merge({}, token.user || {}, {
          tokenId: token.id,
          className: token.className,
          easyDataInputReferral: token.easyDataInputReferral,
        });
      default:
        return token;
    }
  });
});
const slicedSharingRecipients = computed(() => {
  return _.slice(sharing.value, 0, 4);
});
const sharingOverflow = computed(() => {
  const overflow = sharing.value.length - 4;

  return overflow > 0 ? overflow : null;
});
const overflowRecipients = computed(() => _.drop(sharing.value, 4));

watch(dataLicensesChannelDataQueue, () => {
  const data = _.last(dataLicensesChannelDataQueue.value);

  if (data.dataFieldIds && _.includes(data.dataFieldIds, props.dataFieldId)) {
    // field.value = null;
    fetchDataField();
  }
});

watch(dataSharingsChannelDataQueue, () => {
  const data = _.last(dataSharingsChannelDataQueue.value);

  if (data.dataFieldIds && _.includes(data.dataFieldIds, props.dataFieldId)) {
    accessTokens.value = [];
    fetchAccessTokens();
  }
});

onMounted(() => {
  fetchDataField();
  fetchTopLevelContent();
  fetchAccessTokens();
});

function fetchDataField() {
  api.get(`safezone_data_fields/${props.dataFieldId}`).then((json) => {
    field.value = json.data;
    emit("attach-field", json.data);

    if (field.value.decoratingContentType) {
      api
        .get(
          `validation_contexts/${field.value.decoratingContentType}/${field.value.decoratingContentId}?scope=unmasked`,
        )
        .then((json) => {
          validationContextMetaData.value = json.data;
        });
    }
  });
}
async function fetchTopLevelContent() {
  api.get(`data_field_top_level_content/${props.dataFieldId}`).then((json) => {
    if (json.data?.fieldContentType === "Property") {
      propertyFieldsStore.patchPropertyDataFields([json.data]);
      propertyId.value = json.data?.fieldContentId;
    } else if (
      json.data?.fieldContent?.propertyId ||
      json.data?.fieldContent?.topLevelPropertyId
    ) {
      propertyId.value =
        json.data?.fieldContent?.propertyId ||
        json.data?.fieldContent?.topLevelPropertyId;
      propertyFieldsStore.fetchPropertyDataField(propertyId.value);
    }
  });
}
function fetchAccessTokens() {
  api.get(`safezone_data_access_tokens/${props.dataFieldId}`).then((json) => {
    accessTokens.value = json.data;
  });
}
function addToSharingStage() {
  sharingStore.addSharingDataFields([field.value]);
}
function removeFromSharingStage() {
  sharingStore.removeFromDataFieldSharing([field.value]);
}
function openSourced() {
  emit("open-sourced");
}
function dismissed() {
  emit("dismissed");
}
function toggleSelected() {
  if (props.selected) {
    emit("unselect");
  } else {
    emit("select");
  }
}
function unselectClassifiedOnly() {
  emit("unselect-classified-only");
}
function selectDecorating() {
  emit("select-decorating", {
    contentType: field.value.decoratingContentType,
    contentId: field.value.decoratingContentId,
  });
}
function selectGroup() {
  emit("select-group", {
    groupId: field.value.latestChangeGroupId,
  });
}

async function revokeAll() {
  api
    .delete(
      `safezone_data_field_sharings/?data_field_id=${props.dataFieldId}&class_name=CrowdsourcedDataField`,
    )
    .then(() => {
      fetchAccessTokens();
    });
}
</script>
