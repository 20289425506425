import { computed, ref } from "vue";
import { defineStore, acceptHMRUpdate } from "pinia";
import { useRoute } from "vue-router";

export const useAdminModeStore = defineStore("adminMode", () => {
  const route = useRoute();
  const isAdminDashboard = computed(() => route.name === "ValidationsAdmin");
  const linkingUserId = ref(null);

  return {
    isAdminDashboard,
    linkingUserId,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAdminModeStore, import.meta.hot));
}
