<template>
  <div class="bg-white overflow-hidden">
    <div class="p-2">
      <dl class="grid grid-cols-2 gap-x-4 gap-y-8">
        <PrincipalsDetail
          transaction-context="leasing"
          transaction-side="supply"
          display-context="details-panel"
          :space-object="spaceObject"
          :availability="availability"
          @set-space-providers="setSpaceProviders"
        />
        <PrincipalsDetail
          transaction-context="leasing"
          transaction-side="demand"
          display-context="details-panel"
          :space-object="spaceObject"
          :availability="availability"
        />
        <AdvisorsDetail
          :spaceObject="spaceObject"
          :availability="availability"
          :fetch-milliseconds="
            fetchMilliseconds(availability, asOfMilliseconds)
          "
          clientRole="spaceProvider"
          :existing-space-providers="spaceProviders"
          context="availability-players"
        />
        <AdvisorsDetail
          :spaceObject="spaceObject"
          :availability="availability"
          :fetch-milliseconds="
            fetchMilliseconds(availability, asOfMilliseconds)
          "
          clientRole="spaceUser"
          context="availability-players"
        />
        <!-- TODO: REFACTOR REFETCHING TO TRIGGER IN PRINCIPALSDETAIL -->
        <ProspectsDetail
          v-if="
            focalAvailability(availability, spaceUsageBuilder)
              ?.existingAvailability?.id ||
            focalAvailability(availability, spaceUsageBuilder)?.id
          "
          :spaceObject="spaceObject"
          :availability="availability"
          :fetch-milliseconds="
            fetchMilliseconds(availability, asOfMilliseconds)
          "
          @refetch="fetchInvolvedCompanies"
          @override-refetch="fetchInvolvedCompanies"
        />
      </dl>
    </div>
  </div>
</template>

<script setup>
import AdvisorsDetail from "@/components/deal-builder/AdvisorsDetail.vue";
import ProspectsDetail from "@/components/deal-builder/ProspectsDetail.vue";
import PrincipalsDetail from "@/components/deal-builder/PrincipalsDetail.vue";
import { useSpaceUsageBuilderStore } from "@/stores/spaceUsageBuilder";
import { useTimeTravelStore } from "@/stores/timeTravel";
import { focalAvailability } from "@/assets/availabilityHelpers";
import { fetchMilliseconds } from "@/assets/transactionHelpers";
import { storeToRefs } from "pinia";
import { ref } from "vue";

defineProps(["spaceObject", "availability"]);

const spaceUsageBuilderStore = useSpaceUsageBuilderStore();
const { spaceUsageBuilder } = storeToRefs(spaceUsageBuilderStore);
const timeTravelStore = useTimeTravelStore();
const { asOfMilliseconds } = storeToRefs(timeTravelStore);

const spaceProviders = ref([]);

async function fetchInvolvedCompanies() {
  console.log("trigger inside of principals detail");
}

function setSpaceProviders(newProviders) {
  spaceProviders.value = newProviders;
}
</script>
