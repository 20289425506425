<template>
  <div v-if="attributions.pagy" class="mt-4 flex flex-col">
    <div
      v-if="attributions.data.length > 0"
      class="-my-2 -mx-4 overflow-x-auto"
    >
      <div class="inline-block min-w-full py-2 align-middle px-4">
        <div
          class="relative overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-lg"
        >
          <!-- Selected row actions, only show when rows are selected. -->
          <div
            v-if="selectionLength > 0 && selectionIsMonetizeable"
            class="absolute top-0 left-12 flex h-12 items-center space-x-3 bg-gray-50"
          >
            <PayoutSelector
              :bulk-ids="activeSelection"
              :shared-payout-accounts="sharedPayoutAccounts"
              @refetch="fetchContributions"
            />
          </div>

          <table class="min-w-full table-fixed divide-y divide-gray-300">
            <thead class="bg-gray-50">
              <tr>
                <th scope="col" class="relative w-12 px-4">
                  <input
                    @click="toggleOverall"
                    id="contributions-overall"
                    ref="contributionsOverall"
                    type="checkbox"
                    class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                    data-test="my-contributions-toggle-overall-selection-button"
                  />
                </th>
                <th
                  scope="col"
                  class="py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
                >
                  Field
                </th>
                <th
                  scope="col"
                  class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Role
                </th>
                <th
                  scope="col"
                  class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Belongs To
                </th>
                <th
                  scope="col"
                  class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Group
                </th>
                <th
                  scope="col"
                  class="pl-3 pr-4 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Earnings
                </th>
              </tr>
            </thead>
            <tbody
              v-if="rowsDisplayable"
              class="divide-y divide-gray-200 bg-white"
            >
              <ContributionTableRow
                v-for="(attribution, index) in attributions.data"
                :key="attribution.id"
                :attribution="attribution"
                :shared-payout-accounts="sharedPayoutAccounts"
                :selected="selected(attribution.id)"
                :index="index"
                :data-test="`contribution-table-row-${index}`"
                @unselect="unselect(attribution.id)"
                @select="select(attribution.id)"
                @attach-field="attachField"
                @select-decorating="selectDecorating"
                @select-group="selectGroup"
                @open-sourced="fetchContributions"
                @dismissed="drop(attribution)"
              />
            </tbody>
            <PaginationFooter
              v-if="attributions.pagy.prev || attributions.pagy.next"
              :pagination-meta="attributions.pagy"
              :table-meta="{ colspan: 6 }"
              @previous="fetchContributions"
              @next="fetchContributions"
              @page="fetchContributions"
              @infinite-fetch="loadContributions"
            />
          </table>
        </div>
      </div>
    </div>
    <div v-else class="text-center" data-test="my-contributions-empty-state">
      <PencilSquareIcon class="mt-8 mx-auto h-12 w-12 text-gray-400" />
      <h3 class="mt-2 text-sm font-medium text-gray-900">Get involved</h3>
      <p class="mt-1 text-sm text-gray-500">
        Data you create and/or validate will appear here.
      </p>
    </div>
  </div>
</template>

<script setup>
import ContributionTableRow from "@/components/crowdsourcing/contributions/ContributionTableRow.vue";
import PayoutSelector from "@/components/crowdsourcing/contributions/PayoutSelector.vue";
import PaginationFooter from "@/components/PaginationFooter.vue";
import { PencilSquareIcon } from "@heroicons/vue/24/outline";
import { ref, computed, watch, onMounted } from "vue";
import api from "@/router/api";
import _ from "lodash";

const props = defineProps(["timePeriod"]);

const attributions = ref({
  data: [],
  pagy: null,
});
const activeSelection = ref([]);
const rowsDisplayable = ref(false);
const sharedPayoutAccounts = ref([]);

const selectionLength = computed(() => {
  return activeSelection.value.length;
});
const allSelected = computed(() => {
  return (
    attributions.value.pagy &&
    attributions.value.data.length === selectionLength.value
  );
});
const indeterminate = computed(() => {
  return selectionLength.value > 0 && !allSelected.value;
});
const selectedAttributions = computed(() => {
  return activeSelection.value.map((id) => {
    return _.find(attributions.value.data, {
      dataFieldId: id,
    });
  });
});
const selectionIsMonetizeable = computed(() => {
  return _.some(selectedAttributions.value, function (attribution) {
    const free = _.get(attribution, "dataField.free");

    return !_.isUndefined(free) && !free;
  });
});

watch(selectionLength, () => {
  updateOverallCheckbox();
});
watch(
  () => props.timePeriod,
  () => {
    fetchContributions();
  },
);

onMounted(() => {
  fetchPayoutAccounts().then(() => {
    fetchContributions();
  });
});

async function fetchContributions(paginationUrl = null) {
  attributions.value = ref({
    data: [],
    pagy: null,
  });
  activeSelection.value = [];
  rowsDisplayable.value = false;

  api
    .get(paginationUrl || `my_contributions?time_period=${props.timePeriod}`)
    .then((json) => {
      attributions.value = json.data;
      rowsDisplayable.value = true;
    });
}

function cleanUrl(url) {
  return url.replace("/api/v1/", "");
}
const loadContributions = async ($state) => {
  if (attributions.value.pagy?.next) {
    const endpoint = cleanUrl(attributions.value.pagy?.next_url);
    try {
      api.get(endpoint).then((json) => {
        const { data, pagy } = json.data;

        attributions.value.data = _.concat(attributions.value.data, data);
        attributions.value.pagy = pagy;
        if (data.length < 10) $state.complete();
        else {
          $state.loaded();
        }
      });
    } catch (error) {
      $state.error();
    }
  } else {
    $state.complete();
  }
};

async function fetchPayoutAccounts() {
  return new Promise((resolve) => {
    if (sharedPayoutAccounts.value.length === 0) {
      api.get(`shared_payout_accounts?teams=true`).then((json) => {
        sharedPayoutAccounts.value = json.data;
        resolve();
      });
    } else {
      resolve();
    }
  });
}
function drop(attribution) {
  const newChanges = _.differenceBy(
    attributions.value.data,
    [attribution],
    "id",
  );

  attributions.value.data = newChanges;
}
function selected(id) {
  return _.includes(activeSelection.value, id);
}
function unselect(id) {
  const newSelection = _.difference(activeSelection.value, [id]);

  activeSelection.value = newSelection;
}
function select(id) {
  const newSelection = _.union(activeSelection.value, [id]);

  activeSelection.value = newSelection;
}
function attachField(dataField) {
  const oldAttribution = _.find(attributions.value.data, {
    dataFieldId: dataField.localId,
  });

  if (oldAttribution) {
    const newAttribution = _.merge({}, oldAttribution, { dataField });
    const newAttributions = attributions.value.data.map((attr) => {
      if (attr.dataFieldId === newAttribution.dataFieldId) {
        return newAttribution;
      } else {
        return attr;
      }
    });

    attributions.value.data = newAttributions;
  }
}
function selectDecorating({ contentType, contentId }) {
  const filtered = attributions.value.data.filter((c) => {
    return (
      _.get(c, "dataField.decoratingContentType") === contentType &&
      _.get(c, "dataField.decoratingContentId") === contentId
    );
  });

  activeSelection.value = filtered.map((c) => c.id);
}
function selectGroup({ groupId }) {
  const filtered = attributions.value.data.filter((c) => {
    return _.get(c, "dataField.latestChangeGroupId") === groupId;
  });

  activeSelection.value = filtered.map((c) => c.id);
}
function toggleOverall() {
  if (indeterminate.value || allSelected.value) {
    activeSelection.value = [];
  } else {
    activeSelection.value = attributions.value.data.map((c) => c.id);
  }
}
function updateOverallCheckbox() {
  const overall = document.querySelector("#contributions-overall");

  if (!overall) {
    return;
  }

  if (selectionLength.value === 0) {
    overall.checked = false;
    overall.indeterminate = false;
  } else if (allSelected.value) {
    overall.checked = true;
    overall.indeterminate = false;
  } else {
    overall.checked = false;
    overall.indeterminate = true;
  }
}
</script>
