<template>
  <form
    @submit.prevent="updateProductTier"
    data-test="subscription-form"
    class="flex flex-col space-y-2"
  >
    <h3 class="text-base leading-6 font-medium text-gray-900">
      Your Tower Hunt Plan
    </h3>
    <RadioGroup v-model="selected">
      <RadioGroupLabel class="sr-only"> Pricing plans </RadioGroupLabel>
      <div class="relative -space-y-px rounded-md bg-white">
        <RadioGroupOption
          as="template"
          v-for="(plan, planIdx) in plans"
          :key="plan.name"
          :value="plan"
          :data-test="`subscription-${plan.apiValue}-option`"
          v-slot="{ checked, active }"
        >
          <div
            :aria-checked="checked"
            :class="[
              workspaceLayout === 'sideBySide'
                ? 'flex flex-col'
                : 'pl-4 pr-6 grid grid-cols-3',
              planIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
              planIdx === plans.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
              checked ? 'bg-indigo-50 border-indigo-200' : 'border-gray-200',
              'p-4 relative border cursor-pointer focus:outline-none',
            ]"
          >
            <span class="flex items-center">
              <span
                :class="[
                  checked
                    ? 'bg-indigo-600 border-transparent'
                    : 'bg-white border-gray-300',
                  active ? 'ring-2 ring-offset-2 ring-indigo-500' : '',
                  'h-4 w-4 rounded-full border flex items-center justify-center',
                ]"
                aria-hidden="true"
              >
                <span class="rounded-full bg-white w-1.5 h-1.5" />
              </span>
              <RadioGroupLabel
                as="span"
                :class="[
                  checked ? 'text-indigo-900' : 'text-gray-900',
                  'ml-3 font-semibold',
                ]"
                >{{ plan.name }}</RadioGroupLabel
              >
            </span>
            <RadioGroupDescription
              as="span"
              :class="
                workspaceLayout === 'sideBySide'
                  ? 'ml-6 pl-1'
                  : 'ml-0 pl-0 text-center'
              "
              class="text-sm"
            >
              <span
                :class="[
                  checked ? 'text-indigo-900' : 'text-gray-900',
                  'font-medium',
                ]"
                >{{ plan.price }}</span
              >
            </RadioGroupDescription>
            <RadioGroupDescription
              as="span"
              :class="[
                checked ? 'text-indigo-700' : 'text-gray-500',
                workspaceLayout === 'sideBySide'
                  ? 'ml-6 pl-1'
                  : 'ml-0 pl-0 text-right',
                'text-sm',
              ]"
              >{{ plan.description }}</RadioGroupDescription
            >
          </div>
        </RadioGroupOption>
      </div>
    </RadioGroup>
    <div v-show="!planAlreadySet" class="text-right">
      <button
        type="submit"
        class="bg-indigo-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600"
        data-test="subscription-submit-button"
      >
        Save
      </button>
    </div>
  </form>
</template>

<script setup>
import { computed, ref, markRaw, onMounted, watch } from "vue";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { useUserStore } from "@/stores/user";
import { storeToRefs } from "pinia";
import {
  RadioGroup,
  RadioGroupDescription,
  RadioGroupLabel,
  RadioGroupOption,
} from "@headlessui/vue";
import SubscribeVue from "@/components/users/SubscribeVue.vue";
import { useModalStore } from "@/stores/modal";
import _ from "lodash";

const layoutStore = useWorkspaceLayoutStore();
const { workspaceLayout } = storeToRefs(layoutStore);
const userStore = useUserStore();
const { currentUser } = storeToRefs(userStore);
const modalStore = useModalStore();
const { modalPayload } = storeToRefs(modalStore);

const plans = [
  {
    name: "Tracker",
    apiValue: "gatherer",
    price: "Free",
    description: "Browse public data",
  },
  {
    name: "Hunter",
    apiValue: "tracker",
    price: "Pay-as-you-go pricing",
    description: "Safezone and paid data",
  },
];

const billingFrequency = ref("monthly");
const selected = ref(plans[0]);
const existingProductTier = computed(() => currentUser.value.productTier);
const productTier = computed(() => selected.value.apiValue);
const unchangedTier = computed(
  () => productTier.value === existingProductTier.value
);
const unchangedFrequency = computed(
  () =>
    productTier.value !== "hunter" ||
    billingFrequency.value === currentUser.value.billingFrequency
);
const planAlreadySet = computed(
  () => unchangedTier.value && unchangedFrequency.value
);

watch(existingProductTier, () => {
  syncSelected();
});

onMounted(() => {
  syncSelected();
});

function syncSelected() {
  if (currentUser.value) {
    selected.value = _.find(plans, {
      apiValue: existingProductTier.value,
    });
    billingFrequency.value = currentUser.value.billingFrequency;
  }
}

function updateProductTier() {
  if (planAlreadySet.value) {
    // this.$store.dispatch("flash", "Product tier is already set.");
  } else {
    modalPayload.value = {
      size: "base",
      theme: "light",
      component: markRaw(SubscribeVue),
      props: {
        newPlan: productTier.value,
        billingFrequency: billingFrequency.value,
      },
    };
  }
}
</script>
