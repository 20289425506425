<template>
  <div class="pointer-events-none relative" :style="`z-index: 1;`">
    <div
      v-for="(gridRow, index) in singleParcelLandCoveringGridRows"
      :key="`${index}-single-land-covering-unit-right-row`"
      :class="`pointer-events-none w-full h-full absolute grid top-0`"
      :style="`grid-template-columns: ${gridRow.gridColumnWidths}; z-index: ${gridRow.gridZIndex}`"
    >
      <VMenu
        theme="diagram-popup"
        :disabled="editingMode"
        v-for="(gridBlock, i) in gridRow.gridBlocks"
        :key="`${i}-single-land-covering-unit-right-block`"
        :test-key="`${
          gridBlock.coveringDataField ? `${i}` : 'empty'
        }-single-land-covering-unit-right-block`"
        class="h-full w-full"
      >
        <PropertyRightGridBlock
          :property-id="propertyId"
          :data-field="unitRightFor(gridBlock.coveringDataField)"
          :enhancements="
            enhancementsFor(unitRightFor(gridBlock.coveringDataField))
          "
          right-type="Condominium or Co-op"
          :background-style="
            diagramContentSelectionStore.propertyRightSelectionBackgroundStylingFor(
              {
                propertyId,
                dataField: unitRightFor(gridBlock.coveringDataField),
              },
            )
          "
          @clicked="
            diagramContentSelectionStore.feeSimpleColumnClickHandler({
              propertyId,
              dataField: unitRightFor(gridBlock.coveringDataField),
            })
          "
          @property-enhancement-click="
            diagramContentSelectionStore.singleClickSelect
          "
        />
        <template #popper>
          <DataFieldInfoPopup
            :data-field="unitRightFor(gridBlock.coveringDataField)"
          />
        </template>
      </VMenu>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { storeToRefs } from "pinia";
import { usePropertyDiagramContentSelectionStore } from "@/stores/propertyDiagramContentSelection";
import { usePropertyDiagramStore } from "@/stores/propertyDiagram";
import getFieldChildrenFromCollection from "@/assets/getFieldChildrenFromCollection";
import PropertyRightGridBlock from "@/components/property-diagram/PropertyRightGridBlock.vue";
import DataFieldInfoPopup from "@/components/crowdsourcing/DataFieldInfoPopup.vue";
import _ from "lodash";

const props = defineProps(["internalName", "propertyId"]);
const propertyDiagramStore = usePropertyDiagramStore();
const {
  landCoveringUnits,
  renderableGridRows,
  propertyEnhancements,
  editingMode,
} = storeToRefs(propertyDiagramStore);
const diagramContentSelectionStore = usePropertyDiagramContentSelectionStore();

const rowObject = computed(() =>
  _.find(renderableGridRows.value, { internalName: props.internalName }),
);
const unitDataFields = computed(() => {
  return _.get(rowObject.value, "dataFields", []);
});
const singleParcelLandCoveringGridRows = computed(() => {
  return propertyDiagramStore.singleParcelGridRowsFor({
    internalRowName: "Land Coverings (above-grade)",
    propertyId: props.propertyId,
  });
});

function unitRightFor(landCoveringDataField) {
  if (landCoveringDataField) {
    const landCoveringUnit = _.find(landCoveringUnits.value, {
      fieldContentId: landCoveringDataField.fieldContentId,
    });

    if (landCoveringUnit) {
      return _.find(unitDataFields.value, {
        fieldContentId: landCoveringUnit.decoratingContentId,
      });
    } else return null;
  } else {
    return null;
  }
}
function enhancementsFor(focalDataField) {
  return getFieldChildrenFromCollection(
    focalDataField,
    propertyEnhancements.value,
  );
}
</script>
