<template>
  <div class="flex-shrink-0">
    <label id="dollar-menu-label" class="sr-only">Dollar value</label>
    <VDropdown
      v-if="poppable"
      @apply-show="popperOpenHandler"
      @apply-hide="focusButton"
    >
      <button
        type="button"
        @keydown.escape="abortDollarMenu"
        v-tooltip="
          workspaceLayout === 'topAndBottom' ? '' : dollarStatePillLabel
        "
        id="valuation-field-button"
        data-test="investment-valuation-field-button"
        class="relative inline-flex items-center rounded-full py-2 px-2 bg-gray-50 text-sm font-medium text-gray-500 whitespace-nowrap hover:bg-gray-100"
        aria-haspopup="dollar-menu"
        aria-expanded="true"
        aria-labelledby="dollar-menu-label"
      >
        <svg
          :class="[
            workspaceLayout === 'topAndBottom' ? '-ml-1' : '',
            !!dollarValue ? 'text-gray-500' : 'text-gray-300',
            `flex-shrink-0 h-5 w-5`,
          ]"
          class=""
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            d="M8.433 7.418c.155-.103.346-.196.567-.267v1.698a2.305 2.305 0 01-.567-.267C8.07 8.34 8 8.114 8 8c0-.114.07-.34.433-.582zM11 12.849v-1.698c.22.071.412.164.567.267.364.243.433.468.433.582 0 .114-.07.34-.433.582a2.305 2.305 0 01-.567.267z"
          />
          <path
            fill-rule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a1 1 0 10-2 0v.092a4.535 4.535 0 00-1.676.662C6.602 6.234 6 7.009 6 8c0 .99.602 1.765 1.324 2.246.48.32 1.054.545 1.676.662v1.941c-.391-.127-.68-.317-.843-.504a1 1 0 10-1.51 1.31c.562.649 1.413 1.076 2.353 1.253V15a1 1 0 102 0v-.092a4.535 4.535 0 001.676-.662C13.398 13.766 14 12.991 14 12c0-.99-.602-1.765-1.324-2.246A4.535 4.535 0 0011 9.092V7.151c.391.127.68.317.843.504a1 1 0 101.511-1.31c-.563-.649-1.413-1.076-2.354-1.253V5z"
            clip-rule="evenodd"
          />
        </svg>
        <span
          :class="[
            workspaceLayout === 'topAndBottom'
              ? 'ml-2 block'
              : 'hidden truncate',
            !!dollarValue ? 'text-gray-900' : '',
          ]"
        >
          {{ dollarStatePillLabel }}
        </span>
        <template v-if="validDollar">
          <i
            v-if="dollarValueType === 'Final'"
            class="ml-2 text-gray-500 fas fa-handshake text-sm"
          />
          <i
            v-else-if="dollarValueType === 'Guidance'"
            class="ml-2 text-gray-500 fas fa-bullhorn text-sm"
          />
          <i
            v-else-if="dollarValueType === 'Internal'"
            class="ml-2 text-gray-500 fas fa-ghost text-sm"
          />
        </template>
      </button>

      <template #popper>
        <form @submit.prevent class="p-1">
          <label for="price" class="block text-sm font-medium text-gray-700">{{
            valuationTypeLabel
          }}</label>
          <div class="mt-1 relative rounded-md shadow-sm">
            <div
              class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
            >
              <span class="text-gray-500 sm:text-sm">$</span>
            </div>
            <CurrencyInput
              v-model="localDollarValue"
              :options="currencyInputOptions"
              name="price"
              id="price"
              data-test="investment-dollar-value-input"
              class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-24 text-sm border-gray-300 rounded-md"
              placeholder=""
            />
            <div class="absolute inset-y-0 right-0 flex items-center">
              <label for="currency" class="sr-only">Currency</label>
              <select
                v-model="localDollarValueType"
                @keydown.tab.prevent="focusButton"
                id="currency"
                data-test="investment-dollar-value-type-select"
                name="currency"
                class="focus:ring-indigo-500 focus:border-indigo-500 h-full py-0 pl-2 pr-7 border-transparent bg-transparent text-gray-500 text-sm rounded-md"
              >
                <option v-for="option in dollarValueTypes" :key="option">
                  {{ option }}
                </option>
              </select>
            </div>
          </div>
        </form>
      </template>
    </VDropdown>

    <form v-else @submit.prevent>
      <div class="mt-1 relative rounded-md shadow-sm">
        <div
          class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
        >
          <span class="text-gray-500 sm:text-sm">$</span>
        </div>
        <CurrencyInput
          v-focus
          v-model="localDollarValue"
          :options="currencyInputOptions"
          name="price"
          id="price"
          data-test="investment-dollar-value-input"
          class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-24 text-sm border-gray-300 rounded-md"
          placeholder=""
        />
        <div class="absolute inset-y-0 right-0 flex items-center">
          <label for="currency" class="sr-only">Currency</label>
          <select
            v-model="localDollarValueType"
            id="currency"
            data-test="investment-dollar-value-type-select"
            name="currency"
            class="focus:ring-indigo-500 focus:border-indigo-500 h-full py-0 pl-2 pr-7 border-transparent bg-transparent text-gray-500 text-sm rounded-md"
          >
            <option v-for="option in dollarValueTypes" :key="option">
              {{ option }}
            </option>
          </select>
        </div>
      </div>
    </form>
  </div>
</template>

<script setup>
import CurrencyInput from "@/components/crowdsourcing/CurrencyInput.vue";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { storeToRefs } from "pinia";
import { ref, computed, watch, onMounted } from "vue";
import { formattedDollarAmount } from "@/assets/numberHelpers";
import _ from "lodash";

const props = defineProps([
  "dollarValue",
  "dollarValueType",
  "state",
  "poppable",
  "dealAction",
]);
const emit = defineEmits(["new-dollar-value", "new-dollar-value-type"]);

const layoutStore = useWorkspaceLayoutStore();
const { workspaceLayout } = storeToRefs(layoutStore);

const localDollarValue = ref(null);
const localDollarValueType = ref(null);
const currencyInputOptions = ref({
  currency: "USD",
  currencyDisplay: "hidden",
  hideCurrencySymbolOnFocus: true,
  hideGroupingSeparatorOnFocus: false,
  hideNegligibleDecimalDigitsOnFocus: false,
  autoDecimalDigits: false,
  autoSign: true,
  useGrouping: true,
  accountingSign: false,
});

const valuationTypeLabel = computed(() => {
  if (_.includes(["refinance", "originateLoan"], props.dealAction)) {
    return "Loan amount";
  } else {
    return "Asset value";
  }
});
const dollarStatePillLabel = computed(() => {
  if (validDollar.value) {
    const number = formattedDollarAmount(_.toNumber(props.dollarValue));

    return `${number} ${props.dollarValueType}`;
  } else {
    return "Dollar value";
  }
});
const validDollar = computed(() => {
  return props.dollarValueType && _.toNumber(props.dollarValue) > 10000;
});
const dollarValueTypes = computed(() => {
  switch (props.state) {
    case "live":
      return ["Guidance", "Internal"];
    case "under_contract":
    case "closed":
    default:
      return ["Final", "Guidance", "Internal"];
  }
});

watch(localDollarValue, (val, oldVal) => {
  if (oldVal && val !== oldVal) {
    emit("new-dollar-value", localDollarValue.value);
  }
});
watch(localDollarValueType, (val, oldVal) => {
  if (val !== oldVal) {
    emit("new-dollar-value-type", localDollarValueType.value);
  }
});
onMounted(() => {
  localDollarValue.value = props.dollarValue;
  localDollarValueType.value = props.dollarValueType;

  if (!props.poppable && !props.dollarValueType) {
    prepopulateDollarValueType();
  }
});

function focusButton() {
  document.getElementById("valuation-field-button").focus();
}
function popperOpenHandler() {
  if (!props.dollarValueType) {
    prepopulateDollarValueType();
  }

  setTimeout(() => {
    document.getElementById("price").focus();
  }, 100);
}
function prepopulateDollarValueType() {
  switch (props.state) {
    case "live":
      localDollarValueType.value = "Guidance";
      break;
    case "under_contract":
    case "closed":
    default:
      localDollarValueType.value = "Final";
      break;
  }
}
function abortDollarMenu() {
  localDollarValue.value = null;
  localDollarValueType.value = null;
}
</script>
