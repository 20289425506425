import { computed, nextTick, ref } from "vue";
import { defineStore, acceptHMRUpdate } from "pinia";
import _ from "lodash";

export const useCitationStore = defineStore("citation", () => {
  const savingCitation = ref(false);
  const displayCitationTypes = ref(true);
  const citationTypes = ref([
    {
      id: 1,
      title: "Website",
      description: "Provide a link.",
      apiValue: "website",
    },
    {
      id: 2,
      title: "File",
      description: "Upload a file.",
      apiValue: "file",
    },
    {
      id: 3,
      title: "Existing Citation",
      description: "Re-use a citation.",
      apiValue: "existing",
    },
  ]);
  const selectedCitationType = ref(undefined);
  const visibilityTypes = ref([
    {
      id: 1,
      title: "Public",
      description: "Users can view and validate.",
      apiValue: "open",
    },
    {
      id: 2,
      title: "Confidential",
      description: "Tower Hunt validates.",
      apiValue: "confidential",
    },
  ]);
  const selectedVisibilityType = ref(visibilityTypes.value[0]);
  const existingCitations = ref(null);
  const existingCitation = ref(null);
  const url = ref(null);
  const paywalled = ref(false);
  const citationUploadPayload = ref(null);
  const emptyUrl = computed(() => {
    return _.isNull(url.value) || _.trim(url.value) === "";
  });
  const name = ref(null);
  const emptyName = computed(() => {
    return _.isNull(name.value) || _.trim(name.value) === "";
  });
  const description = ref(null);
  const emptyDescription = computed(() => {
    return _.isNull(description.value) || _.trim(description.value) === "";
  });
  const date = ref(null);

  async function resetCitationType() {
    displayCitationTypes.value = false;
    await nextTick();

    selectedCitationType.value = undefined;
    displayCitationTypes.value = true;
  }

  async function clearCitation() {
    selectedVisibilityType.value = visibilityTypes.value[0];
    existingCitation.value = null;
    url.value = null;
    citationUploadPayload.value = null;
    paywalled.value = false;
    name.value = null;
    description.value = null;
    date.value = null;
  }
  function clearMeta() {
    name.value = null;
    date.value = null;
    description.value = null;
  }

  const hasVisibility = computed(() => {
    return _.includes(
      ["website", "file"],
      selectedCitationType.value?.apiValue,
    );
  });

  const citationFields = ref([]);
  const citationsCount = computed(() => {
    return citationFields.value.length;
  });
  const citationChangeIds = computed(() => {
    return citationFields.value.map((dataField) => dataField.latestChangeId);
  });
  function alreadyInStage(dataField) {
    return _.find(citationFields.value, { localId: dataField.localId });
  }
  function addCitationFields(dataFields) {
    const newFields = _.unionBy(dataFields, citationFields.value, "localId");

    citationFields.value = newFields;
  }
  function removeCitationDataField(dataFields) {
    const newFields = _.differenceBy(
      citationFields.value,
      dataFields,
      "localId",
    );

    citationFields.value = newFields;
  }
  function resetFields() {
    citationFields.value = [];
  }

  return {
    savingCitation,
    displayCitationTypes,
    citationTypes,
    visibilityTypes,
    selectedVisibilityType,
    hasVisibility,
    selectedCitationType,
    url,
    emptyUrl,
    paywalled,
    citationUploadPayload,
    name,
    emptyName,
    description,
    emptyDescription,
    date,
    existingCitations,
    existingCitation,
    citationFields,
    citationsCount,
    citationChangeIds,
    resetCitationType,
    clearCitation,
    alreadyInStage,
    addCitationFields,
    removeCitationDataField,
    clearMeta,
    resetFields,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCitationStore, import.meta.hot));
}
