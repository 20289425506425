<template>
  <div
    :class="
      workspaceLayout === 'topAndBottom'
        ? 'grid-cols-2 grid-flow-row-dense'
        : 'grid-cols-1'
    "
    class="p-2 mt-2 grid gap-3"
  >
    <slot></slot>
  </div>
</template>

<script setup>
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { storeToRefs } from "pinia";

const layoutStore = useWorkspaceLayoutStore();
const { workspaceLayout } = storeToRefs(layoutStore);
</script>
