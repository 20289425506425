<template>
  <component
    v-if="promptReason && promptOpen"
    :is="promptComponent"
    @acknowledge="promptOpen = false"
    data-test="prompt-to-subscribe-cta"
  />
  <div v-else class="px-4 py-5 sm:p-6">
    <div v-if="!confirmationFailed" class="flex justify-center">
      <GridLoader
        v-if="!loaded || submitting"
        :loading="!loaded || submitting"
        size="10px"
        color="#5850ec"
      />
    </div>

    <template v-if="confirmationFailed">
      <div
        v-if="downgrading"
        v-observe-visibility="{ callback: trackPurchaseFunnel }"
        class="sm:flex sm:items-center sm:justify-between"
      >
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Downgrade to Gatherer Tier
          </h3>
          <div class="mt-2 max-w-2xl text-sm leading-5 text-gray-500">
            <p>
              You will continue to have access to
              {{ _.capitalize(existingPlan) }} tier until the end of the current
              billing period. Data you unlock will be invoiced at the end of the
              billing period.
            </p>
          </div>
        </div>
        <div
          class="mt-5 sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center"
        >
          <GridLoader
            v-if="submitting"
            :loading="submitting"
            size="6px"
            color="#5850ec"
          />
          <span v-else class="inline-flex rounded-md shadow-sm">
            <button
              @click="downgrade"
              type="button"
              :disabled="submitting"
              class="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:ring-indigo active:bg-indigo-700"
              data-test="downgrade-subscription-button"
            >
              Downgrade
            </button>
          </span>
        </div>
      </div>

      <div
        v-else-if="selectedCard"
        v-observe-visibility="{ callback: trackPurchaseFunnel }"
        class="sm:flex sm:items-center sm:justify-between"
      >
        <template v-if="isMetered">
          <div>
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              Authorize Hunter Tier
            </h3>
            <div class="mt-2 max-w-2xl text-sm leading-5 text-gray-500">
              <p>
                <template v-if="creditBalance > 0"
                  >If my credit balance of ${{
                    currencyAmount(creditBalance, 2)
                  }}
                  runs out, </template
                >I authorize Tower Hunt to charge my card at the end of each
                billing period for my data usage during that period.
              </p>
            </div>
          </div>
        </template>

        <template v-else>
          <div>
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              Subscribe to {{ _.capitalize(newPlan) }} Tier
            </h3>
            <div class="mt-2 max-w-2xl text-sm leading-5 text-gray-500">
              <p>
                <template v-if="creditBalance > 0"
                  >If my credit balance of ${{
                    currencyAmount(creditBalance, 2)
                  }}
                  runs out, </template
                >I authorize Tower Hunt to charge my card
                <strong class="font-semibold"
                  ><span
                    >${{ currencyAmount(price, 2)
                    }}<span class="font-normal">{{
                      billingFrequency === "monthly" ? "/mo" : "/yr"
                    }}</span></span
                  ></strong
                >
                to license this tier.
                <router-link
                  to="/product/premium-content"
                  target="_blank"
                  class="ml-1 font-medium text-indigo-600 hover:text-indigo-500"
                  >Learn more &rarr;</router-link
                >
              </p>
              <p>
                I also authorize Tower Hunt to charge my card at the end of each
                billing period for my data usage during that period.
              </p>
            </div>
          </div>
        </template>

        <div
          class="mt-5 sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center"
        >
          <GridLoader
            v-if="submitting"
            :loading="submitting"
            size="6px"
            color="#5850ec"
          />
          <span v-else class="inline-flex rounded-md shadow-sm">
            <button
              @click="createSubscription"
              type="button"
              :disabled="submitting"
              class="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:ring-indigo active:bg-indigo-700"
              data-test="authorize-subscription-button"
            >
              {{ isMetered ? "Authorize" : "Subscribe" }}
            </button>
          </span>
        </div>
      </div>

      <PaymentMethods
        v-else
        :selectable="true"
        capturing-payment="now"
        @card-selected="selectCard"
      />
    </template>
  </div>
</template>

<script setup>
import PaymentMethods from "@/components/users/PaymentMethods.vue";
import GridLoader from "vue-spinner/src/GridLoader.vue";
import api from "@/router/api";
import { computed, onBeforeUnmount, onMounted, ref } from "vue";
import { useUserStore } from "@/stores/user";
import { storeToRefs } from "pinia";
import User from "@/stores/models/user";
import { useModalStore } from "@/stores/modal";
import { useAuthStore } from "@/stores/auth";
import { useAnalyticsStore } from "@/stores/analytics";
import { useUnlockerStore } from "@/stores/unlocker";
import { currencyAmount } from "@/assets/numberHelpers";
import _ from "lodash";
import ContentCreatorPrompt from "@/components/users/subscribe-prompts/ContentCreatorPrompt.vue";
import ContentValidatorPrompt from "@/components/users/subscribe-prompts/ContentValidatorPrompt.vue";
import DataUsagePrompt from "@/components/users/subscribe-prompts/DataUsagePrompt.vue";
/* global analytics */
/* global Stripe */

const props = defineProps([
  "newPlan",
  "billingFrequency",
  "context",
  "promptReason",
]);
const promptOpen = ref(false);
const submitting = ref(false);
const completed = ref(false);
const clientSecret = ref(null);
const subscription = ref(null);
const paymentMethod = ref(null);
const stripe = ref(null);
const confirmationFailed = ref(false);
const selectedCard = ref(null);
const modalStore = useModalStore();
const userStore = useUserStore();
const { adminMode, currentUser } = storeToRefs(userStore);
const authStore = useAuthStore();
const analyticsStore = useAnalyticsStore();
const unlockerStore = useUnlockerStore();
const { resetRequired, upgradeSuccessful } = storeToRefs(unlockerStore);
const { yearWeek } = storeToRefs(analyticsStore);
const promptComponent = computed(() => {
  switch (props.promptReason) {
    case "stakeShortfall":
      switch (props.context) {
        case "originating":
          return ContentCreatorPrompt;
        case "validating":
          return ContentValidatorPrompt;
        case "licensing":
          return DataUsagePrompt;
        default:
          return null;
      }
    case "dataUsage":
      return DataUsagePrompt;
    default:
      return null;
  }
});
const existingPlan = computed(() => {
  return _.get(currentUser, "productTier", "gatherer");
});
const loaded = computed(() => {
  if (downgrading.value) {
    return true;
  } else if (isSubscription.value) {
    return !!stripe.value;
  } else {
    return !!stripe.value && !!clientSecret.value;
  }
});
const isMetered = computed(() => {
  return props.newPlan === "tracker";
});
const isSubscription = computed(() => {
  return props.newPlan === "hunter";
});
const downgrading = computed(() => {
  return props.newPlan === "gatherer";
});
const price = computed(() => {
  if (props.newPlan === "hunter") {
    return props.billingFrequency === "monthly" ? 19.99 : 240;
  } else {
    return 0;
  }
});
const creditBalance = computed(() => {
  return currentUser.value.availableBalance;
});

onMounted(() => {
  if (props.promptReason) {
    console.log("promptReason", props.promptReason, props.context);
    promptOpen.value = true;
  }

  if (Stripe) {
    api.get(`payment_api_key`).then((json) => {
      const stripeKey = json.data;

      stripe.value = Stripe(stripeKey);
      if (downgrading.value) {
        confirmationFailed.value = true;
      } else if (isMetered.value) {
        confirmationFailed.value = true;
      } else if (isSubscription.value) {
        confirmationFailed.value = true;
      }
    });
  } else {
    modalStore.closeModal();
  }
});

onBeforeUnmount(() => {
  if (completed.value) {
    authStore
      .fetchUser()
      .then((fetchedUser) => (currentUser.value = new User(fetchedUser.data)));
  } else {
    resetRequired.value = true;
  }
});

function trackPurchaseFunnel(isVisible) {
  if (!adminMode && !!analytics && isVisible) {
    analytics.track("Selected payment method for subscription tier", {
      yearWeek: yearWeek.value,
      newPlan: props.newPlan,
    });
  }
}
function downgrade() {
  api.delete(`user_subscriptions`).then(() => {
    if (!adminMode && !!analytics) {
      analytics.track("Downgraded to Gatherer (free)", {
        yearWeek: yearWeek.value,
      });
    }

    submitting.value = false;
    completed.value = true;
    modalStore.closeModal();
  });
}
function createSubscription() {
  const payload = {
    paymentMethod: paymentMethod.value,
    plan: props.newPlan,
    billingFrequency: props.billingFrequency,
  };

  submitting.value = true;
  api.post(`user_subscriptions`, payload).then((json) => {
    subscription.value = json.data.subscription;

    if (subscription.value.status === "active") {
      // $store.dispatch("flash", `You successfully subscribed to ${props.newPlan}!`);
      confirmationFailed.value = false;
      activateSubscription();

      return;
    }

    let paymentIntent = subscription.value.latest_invoice.payment_intent;

    if (
      paymentIntent.status === "requires_action" ||
      paymentIntent.status === "requires_payment_method"
    ) {
      clientSecret.value = paymentIntent.client_secret;

      if (paymentIntent.status === "requires_action") {
        submitting.value = false;
        confirmPayment();
      }

      if (paymentIntent.status === "requires_payment_method") {
        selectedCard.value = null;
        confirmationFailed.value = true;
        submitting.value = false;
      }
    }
  });
}
function confirmPayment() {
  submitting.value = true;
  stripe.value
    .confirmCardPayment(clientSecret.value, {
      payment_method: paymentMethod.value,
    })
    .then(
      (data) => {
        if (data.error) {
          console.log(data.error.message);
          // $store.dispatch("flash", data.error.message);
          confirmationFailed.value = true;
          selectedCard.value = null;
        } else {
          // $store.dispatch("flash", "Payment successful!");
          confirmationFailed.value = false;
          activateSubscription();
        }
      },
      () => {
        submitting.value = false;
      }
    );
}
function selectCard(record) {
  paymentMethod.value = record.id;
  selectedCard.value = record;
}
function activateSubscription() {
  const payload = {
    paymentMethod: paymentMethod.value,
    plan: props.newPlan,
    billingFrequency: props.billingFrequency,
    subscriptionId: _.get(subscription.value, "id", null),
  };

  api.patch(`user_subscriptions`, payload).then(() => {
    submitting.value = false;
    upgradeSuccessful.value = true;
    completed.value = true;
    modalStore.closeModal();
  });
}
</script>

<style>
.stripe-card {
  width: 300px;
  padding: 3px;
  display: inline-block;
  border: 1px solid transparent;
}
.stripe-card.complete {
  border-color: #0e9f6e;
  border-radius: 3px;
}

.payment-form {
  display: flex;
}
</style>
