import { computed, nextTick, ref, watch } from "vue";
import { defineStore, acceptHMRUpdate } from "pinia";
import { currencyAmount } from "@/assets/numberHelpers";
import _ from "lodash";
import useTypes from "@/assets/useTypes";
import dateValid from "@/assets/dateValid";
import moment from "moment";

export const useOccupierSpaceDefinitionStore = defineStore(
  "occupierSpaceDefinition",
  () => {
    const selectedUse = ref(null);
    const officeUse = computed(() => _.find(useTypes, { name: "Office" }));
    const actionableUses = computed(() => {
      return useTypes.filter(({ value }) =>
        _.includes(["Office", "Retail", "Industrial", "R&D"], value),
      );
    });
    const displaySizeTypes = computed(() =>
      _.includes(["Office", "R&D"], selectedUse.value),
    );

    const sizeTypeDisplayable = ref(true);
    const selectedSizeType = ref({
      order: 1,
      name: "Area",
      value: "area",
      description: "Square feet",
    });
    const sizeTypes = ref([
      {
        order: 1,
        name: "Area",
        value: "area",
        description: "Square feet",
      },
      {
        order: 2,
        name: "Headcount",
        value: "headCount",
        description: "Converts to SF",
      },
    ]);
    const headCountToAreaAssumptions = ref({
      minAreaPerPerson: 200,
      maxAreaPerPerson: 230,
      densityPercentModifier: 5,
    });
    const displayHeadcountAssumptions = computed(
      () => selectedSizeType.value.name === "Headcount",
    );

    const phases = ref({
      0: {
        minHeadcount: null,
        maxHeadcount: null,
        minArea: null,
        maxArea: null,
      },
    });
    function impliedPhaseArea(key) {
      const phase = getPhase(key);
      if (
        selectedSizeType.value.name === "Headcount" &&
        headCountToAreaAssumptions.value.minAreaPerPerson &&
        phase.minHeadcount &&
        headCountToAreaAssumptions.value.maxAreaPerPerson &&
        phase.maxHeadcount &&
        headCountToAreaAssumptions.value.densityPercentModifier &&
        headCountToAreaAssumptions.value.minAreaPerPerson <
          headCountToAreaAssumptions.value.maxAreaPerPerson &&
        phase.minHeadcount < phase.maxHeadcount &&
        headCountToAreaAssumptions.value.densityPercentModifier > 0
      ) {
        const toPercent = (n) => {
          return n / 100;
        };
        const lowEnd =
          headCountToAreaAssumptions.value.minAreaPerPerson *
          phase.minHeadcount *
          (1 -
            toPercent(headCountToAreaAssumptions.value.densityPercentModifier));
        const highEnd =
          headCountToAreaAssumptions.value.maxAreaPerPerson *
          phase.maxHeadcount *
          (1 +
            toPercent(headCountToAreaAssumptions.value.densityPercentModifier));

        return `${currencyAmount(lowEnd, 0, -3)}-${currencyAmount(
          highEnd,
          0,
          -3,
        )} SF`;
      } else {
        return null;
      }
    }
    function getPhase(key) {
      return phases.value[key];
    }
    const maxPhase = computed(() => {
      const numKeys = _.keys(phases.value).map(_.toNumber);

      return _.max(numKeys);
    });
    function addPhase() {
      if (_.isNumber(maxPhase.value)) {
        const newPhaseKey = maxPhase.value + 1;
        phases.value[newPhaseKey] = {
          minHeadcount: null,
          maxHeadcount: null,
          minArea: null,
          maxArea: null,
          targetDate: null,
        };
      }
    }
    function removePhase(key) {
      delete phases.value[key];
    }
    function validPhase(phase, phaseKey) {
      const dateCheck =
        _.toNumber(phaseKey) === 0 ? true : dateValid(phase.targetDate);
      if (selectedSizeType.value.name === "Area") {
        return (
          phase.minArea &&
          phase.maxArea &&
          phase.minArea < phase.maxArea &&
          dateCheck
        );
      } else {
        return (
          phase.minHeadcount &&
          phase.maxHeadcount &&
          phase.minHeadcount < phase.maxHeadcount &&
          dateCheck
        );
      }
    }
    const validAssumptions = computed(() => {
      if (selectedSizeType.value.name === "Headcount") {
        return (
          headCountToAreaAssumptions.value.minAreaPerPerson &&
          headCountToAreaAssumptions.value.maxAreaPerPerson &&
          headCountToAreaAssumptions.value.densityPercentModifier &&
          headCountToAreaAssumptions.value.minAreaPerPerson <
            headCountToAreaAssumptions.value.maxAreaPerPerson &&
          headCountToAreaAssumptions.value.densityPercentModifier > 0
        );
      } else {
        return true;
      }
    });
    const complete = computed(() => {
      return (
        selectedUse.value &&
        _.every(phases.value, validPhase) &&
        validAssumptions.value
      );
    });

    watch(selectedSizeType, (val, oldVal) => {
      if (val?.name !== oldVal?.name) {
        resetPhases();
      }
    });

    async function resetPhases() {
      sizeTypeDisplayable.value = false;
      phases.value = {
        0: {
          minHeadcount: null,
          maxHeadcount: null,
          minArea: null,
          maxArea: null,
        },
      };
      headCountToAreaAssumptions.value = {
        minAreaPerPerson: 200,
        maxAreaPerPerson: 230,
        densityPercentModifier: 5,
      };
      await nextTick();
      sizeTypeDisplayable.value = true;
    }

    function reset() {
      selectedUse.value = officeUse.value?.value || _.head(useTypes).value;
      selectedSizeType.value = {
        order: 1,
        name: "Area",
        value: "area",
        description: "Square feet",
      };
      resetPhases();
    }

    const savePayload = computed(() => {
      if (complete.value) {
        const structuredPhases = _.map(
          phases.value,
          function (phase, phaseKey) {
            const formattedDate = phase.targetDate
              ? moment(phase.targetDate).valueOf()
              : null;
            return _.merge({}, phase, {
              targetDate: formattedDate,
              phaseNumber: _.toNumber(phaseKey),
            });
          },
        );
        return {
          useType: selectedUse.value,
          phases: structuredPhases,
          assumptions: displayHeadcountAssumptions.value
            ? headCountToAreaAssumptions.value
            : null,
        };
      } else {
        return null;
      }
    });

    return {
      addPhase,
      removePhase,
      getPhase,
      reset,
      selectedUse,
      actionableUses,
      displaySizeTypes,
      selectedSizeType,
      sizeTypeDisplayable,
      sizeTypes,
      phases,
      impliedPhaseArea,
      complete,
      savePayload,
      headCountToAreaAssumptions,
      displayHeadcountAssumptions,
    };
  },
);

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useOccupierSpaceDefinitionStore, import.meta.hot),
  );
}
