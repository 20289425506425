<template>
  <div class="relative overflow-hidden">
    <div class="absolute inset-0">
      <div
        class="absolute inset-0 bg-gradient-to-r from-blue-800 to-cyan-700 mix-blend-multiply"
      />
    </div>
    <div class="relative px-4 py-16">
      <h1 class="text-center text-4xl font-bold tracking-tight">
        <span class="block text-white">Pay only for</span>
        <span class="block text-cyan-200">what you use</span>
      </h1>
      <p class="mx-auto mt-6 max-w-lg text-center text-lg text-cyan-200">
        There are no fixed monthly costs on Tower Hunt. You pay only when you
        access data. This forces Tower Hunt to keep delivering. We think that's
        how it should be.
      </p>
      <div class="mx-auto mt-10 max-w-sm flex justify-center">
        <div class="space-y-4">
          <a
            href=""
            @click.prevent="emit('acknowledge')"
            class="flex items-center justify-center rounded-md border border-transparent bg-white px-4 py-3 text-base font-medium text-cyan-700 shadow-sm hover:bg-cyan-50 sm:px-8"
            data-test="prompt-to-subscribe-acknowledge-button"
            >Authorize</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const emit = defineEmits(["acknowledge"]);
</script>
