<template>
  <section
    aria-labelledby="notes-title"
    class="divide-y divide-gray-200"
    :class="[context === 'detailsTab' ? 'h-full flex flex-col' : '']"
  >
    <div
      class="p-2"
      :class="[
        context === 'detailsTab'
          ? 'flex items-center justify-between'
          : 'space-y-2',
      ]"
    >
      <h2
        id="notes-title"
        :class="[
          context === 'detailsTab'
            ? 'leading-6 font-medium text-gray-900'
            : 'text-sm font-medium text-gray-900',
        ]"
      >
        Notes
      </h2>
      <VDropdown class="">
        <button
          v-tooltip="'Note filtering'"
          class="relative cursor-default rounded-md bg-gray-50 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600"
          :class="[
            context === 'detailsTab'
              ? 'text-sm leading-6 py-1.5 pl-3 pr-10'
              : 'text-xs leading-5 py-1 pl-2 pr-6',
          ]"
        >
          <span class="block truncate">{{ scope.label }}</span>
          <span
            class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
          >
            <ChevronUpDownIcon
              class="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </span>
        </button>
        <template #popper>
          <div class="w-48 flex flex-col">
            <div class="">
              <h1 class="p-2 text-xs text-gray-500">Note filtering</h1>
              <ul class="divide-y divide-gray-200">
                <li
                  v-for="filteringScope in availableScopes"
                  :key="filteringScope.apiValue"
                >
                  <a
                    @click.prevent="setScope(filteringScope)"
                    v-close-popper
                    href=""
                    class="p-2 block hover:bg-gray-50"
                  >
                    <div class="flex items-center">
                      <p class="truncate text-sm font-medium text-gray-700">
                        {{ filteringScope.label }}
                      </p>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </template>
      </VDropdown>
    </div>
    <div :class="[context === 'detailsTab' ? 'flex-grow' : '']">
      <div
        :id="`${context}-content-notes-container`"
        class="p-2 overflow-y-auto"
        :class="[context === 'detailsTab' ? 'h-0 min-h-full' : 'max-h-56']"
      >
        <ul
          role="list"
          :class="[context === 'detailsTab' ? 'space-y-6' : 'space-y-4']"
        >
          <ContentNote
            v-for="noteDataField in sortedNotes"
            :key="noteDataField.localId"
            :display-context="scopeApiValue === 'outer_field'"
            :note-data-field="noteDataField"
            :content-data-field="contentDataField"
            :user-store="userStore"
            :layout-store="layoutStore"
            :change-group-store="changeGroupStore"
            :unlocker-store="unlockerStore"
            @update-note="updateNote"
            @completed="fetchNotes"
            @unlocked="fetchNotes"
          />
        </ul>
        <section v-if="_.size(sortedNotes) === 0">
          <div
            class="relative block w-full rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <PencilIcon class="mx-auto h-12 w-12 text-gray-400" />
            <span class="mt-2 block text-sm font-medium text-gray-900">
              No notes yet. Write one below.
            </span>
          </div>
        </section>
        <div :id="`${context}-content-notes-bottom`" />
      </div>
    </div>
    <div class="bg-gray-50 p-2 flex">
      <div class="min-w-0 flex-1">
        <form @submit.prevent="createNote" class="space-y-2">
          <div>
            <label for="comment" class="sr-only">Notes</label>
            <textarea
              v-observe-visibility="{ callback: initialFocus, once: true }"
              v-model="text"
              id="content-notes-text-area"
              name="comment"
              style="height: 4rem"
              class="shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 text-sm border border-gray-300 rounded-md"
              :placeholder="`Add a note to ${fieldType}`"
              data-test="data-field-new-note-text-area"
            ></textarea>
          </div>
          <div class="flex items-center justify-end">
            <DataVisibilityButton visibility="safezone" class="inline-flex">
              <template v-slot:button>
                <button
                  type="submit"
                  :disabled="originatingData"
                  class="inline-flex items-center justify-center px-2 py-1 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-yellow-500 hover:bg-yellow-600 focus:ring-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2"
                  data-test="data-field-new-note-save-button"
                >
                  <PulseLoader
                    v-if="originatingData"
                    :loading="true"
                    size="3px"
                    color="#f3f4f6"
                  />
                  <span v-else>Save note</span>
                </button>
              </template>
            </DataVisibilityButton>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<script setup>
import ContentNote from "@/components/crowdsourcing/ContentNote.vue";
import { useCrowdsourcedChangeGroupStore } from "@/stores/crowdsourcedChangeGroup";
import { useUserStore } from "@/stores/user";
import { computed, onMounted, ref, watch } from "vue";
import { storeToRefs } from "pinia";
import { ChevronUpDownIcon } from "@heroicons/vue/20/solid";
import api from "@/router/api";
import _ from "lodash";
import DataVisibilityButton from "@/components/crowdsourcing/DataVisibilityButton.vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import VueScrollTo from "vue-scrollto";
import { PencilIcon } from "@heroicons/vue/24/outline";

const props = defineProps([
  "contentDataField",
  "contact",
  "user",
  "focus",
  "userStore",
  "changeGroupStore",
  "layoutStore",
  "unlockerStore",
  "context",
]);

const changeGroupStore =
  props.changeGroupStore || useCrowdsourcedChangeGroupStore();
const { originatingData, changeGroupId } = storeToRefs(changeGroupStore);
const { signedIn } = storeToRefs(props.userStore || useUserStore());

const notes = ref([]);
const text = ref(null);
const fieldType = computed(() => {
  return _.startCase(
    props.contentDataField.fieldContentSubType ||
      props.contentDataField.fieldContentType,
  );
});
const scope = ref({
  apiValue: "outer_field",
  label: "All Related Datapoints",
});
const scopeApiValue = computed(() => scope.value?.apiValue);
const availableScopes = computed(() => {
  return [
    {
      apiValue: "outer_field",
      label: "All Related Datapoints",
    },
    {
      apiValue: "explicit_field",
      label: `${fieldType.value} Only`,
    },
  ];
});

const sortedNotes = computed(() => {
  return _.orderBy(notes.value, ["updatedAt"], ["asc"]);
});
const complete = computed(() => {
  return _.trim(text.value) !== "";
});
const contentType = computed(() => {
  if (props.user) {
    return "User";
  } else if (props.contact) {
    return "Contact";
  } else if (props.contentDataField.fieldContentType) {
    return props.contentDataField.fieldContentType;
  } else {
    return props.contentDataField.decoratingContentType;
  }
});
const contentId = computed(() => {
  return (
    _.get(props.contentDataField, "fieldContentId") ||
    _.get(props.contact, "id") ||
    _.get(props.user, "id") ||
    _.get(props.contentDataField, "decoratingContentId")
  );
});
watch(scopeApiValue, () => {
  fetchNotes();
});

onMounted(() => {
  let unlockerStoreTemp = props.unlockerStore;
  let layoutStoreTemp = props.layoutStore;
  let changeGroupStoreTemp = props.changeGroupStore;

  setScope();
  fetchNotes();
});

function setScope(newScope = null) {
  if (newScope) {
    scope.value = newScope;
  } else if (props.context === "detailsTab") {
    scope.value = {
      apiValue: "outer_field",
      label: "All Related Datapoints",
    };
  } else {
    scope.value = {
      apiValue: "explicit_field",
      label: `Just this ${fieldType.value}`,
    };
  }
}

function fetchNotes() {
  notes.value = [];
  const standaloneFieldName = _.get(props.contentDataField, "fieldName", null);
  const fieldId = _.get(props.contentDataField, "localId", null);
  const subTypeParam = standaloneFieldName
    ? `&field_sub_type=${standaloneFieldName}`
    : "";
  api
    .get(
      `content_notes/${contentType.value}/${contentId.value}?field_name=ContentNote&scope=${scope.value.apiValue}&field_id=${fieldId}${subTypeParam}`,
    )
    .then((json) => {
      notes.value = json.data;
      scrollToNoteBottom();
    });
}
function updateNote({ newNoteField, dismissedFieldId }) {
  const newNotes = notes.value.filter((noteField) => {
    return noteField.localId !== dismissedFieldId;
  });

  notes.value = _.unionBy([newNoteField], newNotes, "localId");
  scrollToNoteBottom();
}
function scrollToNoteBottom() {
  setTimeout(() => {
    VueScrollTo.scrollTo(`#${props.context}-content-notes-bottom`, 100, {
      container: `#${props.context}-content-notes-container`,
    });
  }, 100);
}
function persist() {
  const payload = {
    text: text.value,
    standaloneFieldName: _.get(props.contentDataField, "fieldName", null),
  };
  return api.post(
    `content_notes/${contentType.value}/${contentId.value}`,
    _.merge({}, payload, { changeGroupId: changeGroupId.value }),
  );
}

function afterPersist(json) {
  const newNote = json.data.dataField;
  const newNotes = _.unionBy([newNote], notes.value, "localId");

  notes.value = newNotes;
  cancel();
  scrollToNoteBottom();
}

function cancel() {
  text.value = null;
  selectInput();
}

function initialFocus(isVisible) {
  if (isVisible && props.focus) {
    setTimeout(() => {
      selectInput();
    }, 100);
  }
}

function selectInput() {
  document.getElementById("content-notes-text-area")?.focus();
}

function createNote() {
  const apiRequestFunc = () => persist();
  const successCallback = (json) => afterPersist(json);
  const failureCallback = () => cancel();

  if (complete.value && !originatingData.value) {
    return changeGroupStore.originateData(
      apiRequestFunc,
      successCallback,
      failureCallback,
      signedIn.value,
    );
  }
}
</script>
