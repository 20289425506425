<template>
  <div class="bg-white overflow-y-auto h-full p-2">
    <ContentTiming
      v-if="availability.id"
      :data-field="dataField"
      @unlocked="refetchDealBuilder"
    />
    <dl v-else-if="tempDataField" class="grid grid-cols-2 gap-x-4 gap-y-8">
      <div class="col-span-1">
        <dt class="text-sm font-medium text-gray-500">State</dt>
        <dd class="mt-1 space-y-2">
          <DateStateField
            event-type="availabilityTiming"
            :data-field="tempDataField"
            :state="state"
            :date="date"
            :compact="false"
            @new-date="setNewDate"
            @new-state="setNewState"
          />
        </dd>
      </div>
    </dl>
  </div>
</template>

<script setup>
import ContentTiming from "@/components/crowdsourcing/ContentTiming.vue";
import DateStateField from "@/components/crowdsourcing/DateStateField.vue";
import { useSpaceUsageBuilderStore } from "@/stores/spaceUsageBuilder";
import { useTimeTravelStore } from "@/stores/timeTravel";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import _ from "lodash";
import decoratingAndFieldKey from "@/components/crowdsourcing/decoratingAndFieldKey";

const props = defineProps(["spaceObject", "dataField"]);

const spaceUsageBuilderStore = useSpaceUsageBuilderStore();
const { spaceUsageBuilder, refetchSpaceUsageBuilderEditor } = storeToRefs(
  spaceUsageBuilderStore,
);
const timeTravelStore = useTimeTravelStore();

const availability = computed(() => _.get(props.dataField, "fieldContent"));

const tempDataField = computed(() => {
  if (
    availability.value?.topLevelSpace &&
    availability.value?.availabilityFieldContent
  ) {
    const spaceKey = decoratingAndFieldKey(availability.value.topLevelSpace);
    const availabilityKey = decoratingAndFieldKey(
      availability.value.availabilityFieldContent,
    );

    return { temporaryId: `${spaceKey}_${availabilityKey}` };
  } else {
    return null;
  }
});
const date = computed({
  get() {
    return availability.value.date;
  },
  set(newVal) {
    let newAvailability = _.merge({}, availability.value);

    newAvailability.date = newVal;
    updateStoreAvailability(newAvailability, "date");
  },
});
const state = computed({
  get() {
    return availability.value.state;
  },
  set(newVal) {
    let newAvailability = _.merge({}, availability.value);

    newAvailability.state = newVal;
    updateStoreAvailability(newAvailability, "state");
  },
});
const matchingAvailabilityGroup = computed(() => {
  return _.find(
    _.get(spaceUsageBuilder.value, "availabilityGroups", []),
    function (group) {
      return _.find(
        group.availabilities,
        function (groupAvailability, combinedKey) {
          const localCombinedKey =
            props.dataField?.temporaryId ||
            props.dataField?.fieldContent?.temporaryId;
          if (_.get(availability.value, "existingAvailability.id")) {
            return (
              _.get(groupAvailability, "existingAvailability.id") ===
              availability.value.existingAvailability.id
            );
          } else if (_.get(availability.value, "id")) {
            return _.get(groupAvailability, "id") === availability.value.id;
          } else if (_.get(availability.value, "availabilityGroupId")) {
            return (
              _.get(groupAvailability, "availabilityGroupId") ===
              availability.value.availabilityGroupId
            );
          } else if (_.get(availability.value, "temporaryId")) {
            return (
              _.get(groupAvailability, "temporaryId") ===
              availability.value.temporaryId
            );
          } else if (localCombinedKey) {
            return combinedKey === localCombinedKey;
          } else {
            return false;
          }
        },
      );
    },
  );
});

function setNewDate(newDate) {
  console.log("current date", date.value, "new date", newDate);
  date.value = newDate;
}
function setNewState(newState) {
  console.log("current state", state.value, "new state", newState);
  state.value = newState;
}
function updateStoreAvailability(newAvailability, fieldName = null) {
  if (matchingAvailabilityGroup.value) {
    console.log("has matching group");
    const existingGroupId = _.get(matchingAvailabilityGroup.value, "id");
    const placeholderGroupId = _.get(
      matchingAvailabilityGroup.value,
      "placeholderId",
    );

    spaceUsageBuilderStore.updateAvailabilityGroupAvailability({
      groupId: existingGroupId || placeholderGroupId,
      newAvailability,
      fieldName,
    });
  } else if (availability.value.id && props.spaceObject) {
    console.log("has availability id and space");
    spaceUsageBuilderStore.updateSpaceAvailabilities({
      spaceKey: decoratingAndFieldKey(props.spaceObject.dataField),
      availabilities: [newAvailability],
    });
  } else {
    console.log("AVAILABILITY TIMING nothing to update!");
  }
}
function refetchDealBuilder() {
  if (props.spaceObject?.dataField) {
    refetchSpaceUsageBuilderEditor.value = decoratingAndFieldKey(
      props.spaceObject.dataField,
    );
    timeTravelStore.triggerRefetch();
  }
}
</script>
