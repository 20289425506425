<template>
  <section aria-labelledby="sizes">
    <ContentHeader>
      <template v-slot:title>Sizes</template>
      <template v-slot:description
        >Add different floor area size measurements depending upon the
        use(s).</template
      >
    </ContentHeader>

    <ContentBody>
      <ContentNumber v-bind="standardizedProps" @set-proof="setProof" />
      <ContentNumber
        v-if="subdivisionAllowed"
        v-bind="minimumSubdivisionProps"
      />
      <ContentNumber v-bind="useBasedProps" @set-proof="setProof" />
    </ContentBody>
  </section>
</template>

<script setup>
import ContentBody from "@/components/main-layout/secondary-panel/ContentBody.vue";
import ContentHeader from "@/components/main-layout/secondary-panel/ContentHeader.vue";
import ContentNumber from "@/components/crowdsourcing/ContentNumber.vue";
import { useProveForValueStore } from "@/stores/proveForValue";
import { computed } from "vue";
import _ from "lodash";

const props = defineProps(["dataField"]);

const proveForValueStore = useProveForValueStore();

const floorArea = computed(() => {
  return props.dataField.fieldContent;
});
const unit = computed(() => {
  return _.get(floorArea.value, "unit", null);
});
const useBasedAllowed = computed(() => {
  return unit.value && unit.value !== "SF";
});
const subdivisionAllowed = computed(() => {
  return true;
});

const standardizedProps = computed(() => {
  return {
    wrappingDataField: props.dataField,
    fieldName: "standardized_area",
    numberType: "integer",
    min: 1,
    max: "",
    step: 1000,
    label: "square feet",
    leadingAddOn: null,
    trailingAddOn: "SF",
    description: "Standardized area.",
  };
});

const minimumSubdivisionProps = computed(() => {
  return {
    wrappingDataField: props.dataField,
    fieldName: "minimum_subdivision_standardized_area",
    numberType: "integer",
    min: 1,
    max: "",
    step: 1000,
    label: "minimum subdivision",
    leadingAddOn: null,
    trailingAddOn: "SF",
    description: "The smallest possible subdivision.",
  };
});

const useBasedProps = computed(() => {
  return {
    wrappingDataField: props.dataField,
    fieldName: "use_based_area",
    numberType: "integer",
    min: 1,
    max: "",
    step: 100,
    label: "units",
    leadingAddOn: null,
    trailingAddOn: unit.value,
    description: "Use-specific unit measurement.",
    disabled: !useBasedAllowed.value,
    disabledDescription: "Requires all uses to share the same unit.",
  };
});

function setProof(fieldName) {
  let fieldProps = null;

  switch (fieldName) {
    case "standardized_area":
      fieldProps = standardizedProps.value;
      break;
    case "use_based_area":
      fieldProps = useBasedProps.value;
      break;
  }

  if (fieldProps) {
    proveForValueStore.setProofComponent(ContentNumber, fieldProps);
  }
}
</script>
