<template>
  <div class="w-full flex items-center space-x-1">
    <div v-if="selectedOption" class="flex items-center space-x-1">
      <DataField
        v-if="selectedOption.calculationOption"
        :calculation-name="selectedOption.calculationName"
        :calculation-value="selectedOption.calculationValue"
        :bundle-field-ids="selectedOption.bundleFieldIds"
        text-classes="text-sm font-medium"
        text-styles=""
        :analyze="context === 'analyze'"
        @unlocked="reset"
        @completed="reset"
      />
      <DataField
        v-else
        :data-field="selectedOption.dataField"
        text-classes="text-sm font-medium"
        text-styles=""
        :analyze="context === 'analyze'"
        @dismiss="reset"
        @suggest-change="emit('suggest-change')"
        @unlocked="reset"
        @completed="reset"
        @open-sourced="reset"
        @set-proof="emit('set-proof')"
      />
      <VDropdown>
        <slot name="button">
          <button
            type="button"
            class="rounded flex items-center space-x-1 bg-white p-1 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            <div class="text-xs text-gray-700">
              {{ selectedOption.abbreviatedFieldName }}
            </div>
            <ChevronDownIcon class="w-3 h-3 text-gray-700" />
          </button>
        </slot>
        <template #popper>
          <div class="flex flex-col">
            <div class="space-y-2">
              <h1 class="px-2 pt-2 text-xs font-semibold text-gray-700">
                Choose a datapoint
              </h1>
              <ul class="max-h-56 overflow-y-auto divide-y divide-gray-200">
                <li
                  v-for="option in branchingOptions"
                  :key="option.localId"
                  class="px-2 py-1"
                >
                  <a
                    v-if="option.triggerEdit"
                    @click.prevent="emit('edit')"
                    v-close-popper
                    href=""
                    class="block hover:bg-gray-50"
                  >
                    <div class="truncate text-xs font-medium text-gray-700">
                      {{ option.fieldName }}
                    </div>
                  </a>
                  <a
                    v-else
                    @click.prevent="selectedOption = option"
                    v-close-popper
                    href=""
                    class="block hover:bg-gray-50"
                  >
                    <div class="truncate text-xs font-medium text-gray-700">
                      {{ option.fieldName }}
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </template>
      </VDropdown>
    </div>
    <DataField
      v-else-if="calculationOption"
      :calculation-name="
        selectedOption?.calculationName ||
        loanTermCalcOption?.calculationName ||
        loanMaturityCalcOption?.calculationName
      "
      :calculation-value="
        selectedOption?.calculationValue ||
        loanTermCalcOption?.calculationValue ||
        loanMaturityCalcOption?.calculationValue
      "
      :bundle-field-ids="
        selectedOption?.bundleFieldIds ||
        loanTermCalcOption?.bundleFieldIds ||
        loanMaturityCalcOption?.bundleFieldIds
      "
      text-classes="text-sm font-medium"
      text-styles=""
      :analyze="context === 'analyze'"
      @unlocked="reset"
      @completed="reset"
    />
    <DataField
      v-else-if="fetchedDataField"
      :data-field="fetchedDataField"
      text-classes="text-sm"
      :analyze="context === 'analyze'"
      @dismiss="reset"
      @suggest-change="emit('suggest-change')"
      @unlocked="reset"
      @completed="reset"
      @open-sourced="reset"
      @set-proof="emit('set-proof')"
    />
    <DataVisibilityButton
      v-else-if="!proveForValue && context !== 'analyze'"
      visibility="safezone"
      class="w-full flex"
    >
      <template v-slot:button>
        <button
          @click="emit('edit')"
          type="button"
          class="w-full flex items-center hover:bg-gray-50"
          :data-test="`${fieldName}-create`"
        >
          <span class="flex-shrink-0 flex items-center">
            <span
              class="inline-flex items-center justify-center h-5 w-5 rounded-full bg-yellow-500"
            >
              <PencilIcon class="h-3 w-3 text-white" />
            </span>
          </span>
        </button>
      </template>
    </DataVisibilityButton>
    <div class="text-xs text-gray-500" v-else>No data</div>
  </div>
</template>

<script setup>
import DataField from "@/components/crowdsourcing/DataField.vue";
import DataVisibilityButton from "@/components/crowdsourcing/DataVisibilityButton.vue";
import { useDealBuilderStore } from "@/stores/dealBuilder";
import { useBranchingCalculationFieldsStore } from "@/stores/branchingCalculationFields";
import { useAnalyzePanelStore } from "@/stores/analyzePanel";
import { storeToRefs } from "pinia";
import { PencilIcon } from "@heroicons/vue/24/outline";
import { ChevronDownIcon } from "@heroicons/vue/20/solid";
import { ref, computed, watch, onMounted } from "vue";
import moment from "moment";
import pluralize from "pluralize";
import api from "@/router/api";
import _ from "lodash";

const props = defineProps([
  "fieldName",
  "context",
  "decoratingContentDataField",
  "proveForValue",
  "optional",
]);
const emit = defineEmits([
  "edit",
  "completed",
  "unlocked",
  "suggest-change",
  "open-sourced",
  "set-proof",
]);

const dealBuilderStore = useDealBuilderStore();
const analyzePanelStore = useAnalyzePanelStore();
const { analyzeLoanFields } = storeToRefs(analyzePanelStore);
const branchingCalculationFieldsStore = useBranchingCalculationFieldsStore();

const loaded = ref(false);
const selectedOption = ref(null);
const fetchedDataField = ref(null);
const relatedLoanOriginationDataFields = ref(null);
const relatedLoanOriginationDataField = computed(() => {
  if (_.isArray(relatedLoanOriginationDataFields.value)) {
    return _.head(relatedLoanOriginationDataFields.value); // portfolio loan
  } else {
    return relatedLoanOriginationDataFields.value;
  }
});
const relatedLoanOriginationDataFieldIds = computed(() => {
  if (_.isArray(relatedLoanOriginationDataFields.value)) {
    return relatedLoanOriginationDataFields.value.map(
      (dataField) => dataField.localId,
    ); // portfolio loan
  } else {
    return relatedLoanOriginationDataFields.value?.localId;
  }
});
const calculationOption = computed(() => {
  return (
    selectedOption.value?.calculationOption ||
    (!selectedOption.value && loanTermCalcOption.value?.calculationOption) ||
    (!selectedOption.value && loanMaturityCalcOption.value?.calculationOption)
  );
});
const loanTermCalcOption = computed(() => {
  if (
    props.fieldName === "loan_term_years" &&
    relatedLoanOriginationDataField.value &&
    relatedLoanDataField.value
  ) {
    return {
      fieldName: "Implied loan term",
      abbreviatedFieldName: "Calc",
      calculationOption: true,
      calculationName: "implied_loan_term",
      calculationValue: relatedLoanImpliedTermYears.value
        ? pluralize("Year", relatedLoanImpliedTermYears.value, true)
        : null,
      bundleFieldIds: _.compact(
        _.flatten([
          relatedLoanOriginationDataFieldIds.value,
          relatedLoanDataField.value?.localId,
        ]),
      ),
    };
  } else {
    return null;
  }
});
const loanMaturityCalcOption = computed(() => {
  if (
    props.fieldName === "maturity_date" &&
    relatedLoanOriginationDataField.value &&
    relatedLoanDataField.value
  ) {
    return {
      fieldName: "Implied maturity",
      abbreviatedFieldName: "Calc",
      calculationOption: true,
      calculationName: "implied_maturity",
      calculationValue: relatedLoanImpliedMaturityDate.value,
      bundleFieldIds: _.compact(
        _.flatten([
          relatedLoanOriginationDataFieldIds.value,
          relatedLoanDataField.value?.localId,
        ]),
      ),
    };
  } else {
    return null;
  }
});
const branchingOptions = computed(() => {
  if (props.fieldName === "loan_term_years") {
    if (fetchedDataField.value && relatedLoanImpliedTermYears.value) {
      return [
        loanTermCalcOption.value,
        {
          fieldName: "Loan term datapoint",
          abbreviatedFieldName: "Datapoint",
          calculationOption: false,
          dataField: fetchedDataField.value,
        },
      ];
    } else {
      return [];
    }
  } else if (props.fieldName === "maturity_date") {
    if (fetchedDataField.value && relatedLoanImpliedMaturityDate.value) {
      return [
        {
          fieldName: "Loan maturity datapoint",
          abbreviatedFieldName: "Datapoint",
          calculationOption: false,
          dataField: fetchedDataField.value,
        },
        loanMaturityCalcOption.value,
      ];
    } else if (
      relatedLoanOriginationDataField.value &&
      relatedLoanDataField.value
    ) {
      return _.compact([
        loanMaturityCalcOption.value,
        props.context === "analyze"
          ? null
          : {
              fieldName: "Add loan maturity date",
              abbreviatedFieldName: "Add",
              calculationOption: false,
              triggerEdit: true,
            },
      ]);
    } else {
      return [];
    }
  } else {
    return [];
  }
});

const decoratingContentType = computed(() => {
  return _.get(props.decoratingContentDataField, "fieldContentType");
});
const decoratingContentId = computed(() => {
  return _.get(props.decoratingContentDataField, "fieldContentId");
});
const combinedId = computed(() => {
  return `${decoratingContentType.value}${decoratingContentId.value}`;
});
const branchingContentCollectionFetchKey = computed(() => {
  return {
    contentType: decoratingContentType.value,
    contentId: decoratingContentId.value,
    context: props.context,
  };
});
const fetchRequestKey = computed(
  () =>
    `crowdsourced_data_fields_${decoratingContentType.value}_${decoratingContentId.value}?field_name=${props.fieldName}`,
);
const relatedLoanDataFieldFetchRequestKey = computed(() => {
  if (props.fieldName === "loan_term_years") {
    return `crowdsourced_data_fields_${decoratingContentType.value}_${decoratingContentId.value}?field_name=maturity_date`;
  } else if (props.fieldName === "maturity_date") {
    return `crowdsourced_data_fields_${decoratingContentType.value}_${decoratingContentId.value}?field_name=loan_term_years`;
  } else {
    return null;
  }
});
const relatedLoanDataFieldAnalyzeFetchRequestKey = computed(() => {
  if (props.fieldName === "loan_term_years" && props.context === "analyze") {
    return `Loan${decoratingContentId.value}maturity_date`;
  } else if (
    props.fieldName === "maturity_date" &&
    props.context === "analyze"
  ) {
    return `Loan${decoratingContentId.value}loan_term_years`;
  } else {
    return null;
  }
});
const relatedLoanDataField = computed(() => {
  if (
    relatedLoanDataFieldFetchRequestKey.value &&
    dealBuilderStore.alreadyFetched(relatedLoanDataFieldFetchRequestKey.value)
  ) {
    return _.head(
      dealBuilderStore.alreadyFetchedFieldsFor(
        relatedLoanDataFieldFetchRequestKey.value,
      ),
    );
  } else if (
    relatedLoanDataFieldAnalyzeFetchRequestKey.value &&
    analyzeLoanFields.value[relatedLoanDataFieldAnalyzeFetchRequestKey.value]
  ) {
    return analyzeLoanFields.value[
      relatedLoanDataFieldAnalyzeFetchRequestKey.value
    ];
  } else {
    return null;
  }
});
const relatedLoanImpliedTermYears = computed(() => {
  if (
    relatedLoanOriginationDataField.value?.fieldValue &&
    relatedLoanDataField.value?.fieldValue &&
    props.fieldName === "loan_term_years"
  ) {
    const origination = moment(
      relatedLoanOriginationDataField.value.fieldValue,
    );
    const maturity = moment(relatedLoanDataField.value?.fieldValue);
    return maturity.diff(origination, "years");
  } else {
    return null;
  }
});
const relatedLoanImpliedMaturityDate = computed(() => {
  if (
    relatedLoanOriginationDataField.value?.fieldValue &&
    relatedLoanDataField.value?.fieldValue &&
    props.fieldName === "maturity_date"
  ) {
    const origination = moment(
      relatedLoanOriginationDataField.value.fieldValue,
    );
    const years = _.toNumber(relatedLoanDataField.value.fieldValue);
    return origination.add(years, "years").format("MMM D, YYYY");
  } else {
    return null;
  }
});
const storeTermYearsSelection = computed(() => {
  if (props.fieldName === "maturity_date") {
    return branchingCalculationFieldsStore.getContent(
      branchingContentCollectionFetchKey.value,
    )?.termYearsSelection;
  } else {
    return null;
  }
});
const storeMaturityDateSelection = computed(() => {
  if (props.fieldName === "loan_term_years") {
    return branchingCalculationFieldsStore.getContent(
      branchingContentCollectionFetchKey.value,
    )?.maturityDateSelection;
  } else {
    return null;
  }
});

watch(storeTermYearsSelection, () => {
  if (
    storeTermYearsSelection.value === "Calc" &&
    selectedOption.value?.abbreviatedFieldName === "Calc"
  ) {
    setSelectedByType("Datapoint");
  } else if (
    storeTermYearsSelection.value === "Datapoint" &&
    selectedOption.value?.abbreviatedFieldName === "Datapoint"
  ) {
    setSelectedByType("Calc");
  }
});
watch(storeMaturityDateSelection, () => {
  if (
    storeMaturityDateSelection.value === "Calc" &&
    selectedOption.value?.abbreviatedFieldName === "Calc"
  ) {
    setSelectedByType("Datapoint");
  } else if (
    storeMaturityDateSelection.value === "Datapoint" &&
    selectedOption.value?.abbreviatedFieldName === "Datapoint"
  ) {
    setSelectedByType("Calc");
  }
});

watch(
  selectedOption,
  () => {
    let branchingCollectionObject = branchingCalculationFieldsStore.getContent(
      branchingContentCollectionFetchKey.value,
    );

    if (branchingCollectionObject) {
      switch (props.fieldName) {
        case "loan_term_years": {
          branchingCollectionObject.termYearsSelection =
            selectedOption.value?.abbreviatedFieldName;
          break;
        }
        case "maturity_date": {
          branchingCollectionObject.maturityDateSelection =
            selectedOption.value?.abbreviatedFieldName;
          break;
        }
      }

      branchingCalculationFieldsStore.patchContentCollection(
        branchingCollectionObject,
      );
    }
  },
  { deep: true },
);
watch(
  branchingOptions,
  () => {
    if (_.size(branchingOptions.value) > 0) {
      selectedOption.value = _.head(branchingOptions.value);
    }
  },
  { deep: true },
);
watch(combinedId, () => {
  fetchDataField();

  if (props.context === "analyze") {
    fetchAnalyzeRelatedDataField();
  }
});
watch(relatedLoanDataField, () => {
  if (relatedLoanDataField.value) {
    fetchLoanOriginationDate();
  }
});

onMounted(() => {
  fetchDataField();

  if (relatedLoanDataField.value) {
    fetchLoanOriginationDate();
  }
  if (props.context === "analyze") {
    fetchAnalyzeRelatedDataField();
  }
});

function setSelectedByType(fieldType) {
  if (selectedOption.value && _.size(branchingOptions.value) > 0) {
    selectedOption.value = _.find(branchingOptions.value, {
      abbreviatedFieldName: fieldType,
    });
  }
}

async function reset() {
  emit("completed");
}

async function fetchLoanOriginationDate() {
  if (!relatedLoanOriginationDataField.value) {
    const fieldResponse = await api.get(
      `crowdsourced_data_fields/${decoratingContentType.value}/${decoratingContentId.value}?field_name=origination_date`,
    );
    relatedLoanOriginationDataFields.value = fieldResponse.data;
  }
}

async function fetchAnalyzeRelatedDataField() {
  if (props.context === "analyze" && !relatedLoanDataField.value) {
    let endpoint = null;

    if (props.fieldName === "loan_term_years") {
      endpoint = `crowdsourced_data_fields/${decoratingContentType.value}/${decoratingContentId.value}?field_name=maturity_date`;
    } else if (props.fieldName === "maturity_date") {
      endpoint = `crowdsourced_data_fields/${decoratingContentType.value}/${decoratingContentId.value}?field_name=loan_term_years`;
    }

    if (endpoint) {
      const fieldResponse = await api.get(endpoint);
      analyzeLoanFields.value[
        relatedLoanDataFieldAnalyzeFetchRequestKey.value
      ] = fieldResponse.data;
    }
  }
}

async function fetchDataField(emitPayload = {}, override = false) {
  loaded.value = false;

  if (props.proveForValue && !override) {
    loaded.value = true;
  } else if (
    dealBuilderStore.alreadyFetched(fetchRequestKey.value) &&
    !override
  ) {
    fetchedDataField.value = _.head(
      dealBuilderStore.alreadyFetchedFieldsFor(fetchRequestKey.value),
    );
    loaded.value = true;
  } else {
    const fieldResponse = await api.get(
      `crowdsourced_data_fields/${decoratingContentType.value}/${decoratingContentId.value}?field_name=${props.fieldName}`,
    );

    if (fieldResponse.data) {
      if (props.context === "analyze") {
        analyzeLoanFields.value[
          `Loan${decoratingContentId.value}${props.fieldName}`
        ];
      } else {
        dealBuilderStore.interceptablePatch(
          [fieldResponse.data],
          fetchRequestKey.value,
        );
      }
    }

    fetchedDataField.value = fieldResponse.data;
    loaded.value = true;
  }

  if (
    (!fetchedDataField.value && !props.optional) ||
    _.get(emitPayload, "replaceAfterFetch", false)
  ) {
    emit("edit");
  }
}
</script>
