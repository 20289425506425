<template>
  <div
    class="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200"
  >
    <div
      id="investment-portfolios-list"
      @click="expanded = !expanded"
      class="p-2 font-semibold uppercase tracking-wide"
      :class="
        investmentGroups.length > 0
          ? 'bg-green-400 text-gray-900'
          : 'bg-gray-100 text-gray-900'
      "
    >
      Portfolios List
    </div>
    <template v-if="expanded">
      <InvestmentGroup
        v-for="(investmentGroup, index) in investmentGroups"
        :key="index"
        :investment-group="investmentGroup"
        :order="index + 1"
      />
      <div
        v-if="investmentGroups.length === 0"
        class="p-2 text-gray-500 text-sm"
      >
        Begin building a deal below...
      </div>
    </template>
  </div>
</template>

<script setup>
import InvestmentGroup from "@/components/deal-builder/InvestmentGroup.vue";
import { useDealBuilderStore } from "@/stores/dealBuilder";
import { useTimeTravelStore } from "@/stores/timeTravel";
import { storeToRefs } from "pinia";
import { ref, computed, watch } from "vue";

const dealBuilderStore = useDealBuilderStore();
const { dealBuilder } = storeToRefs(dealBuilderStore);
const timeTravelStore = useTimeTravelStore();
const { asOfDate } = storeToRefs(timeTravelStore);

const expanded = ref(true);

const investmentGroups = computed(() => {
  return dealBuilder.value.investmentGroups;
});

watch(asOfDate, (date, oldDate) => {
  if (oldDate && date !== oldDate) {
    dealBuilder.value.investmentGroups = [];
  }
});
</script>
