import { defineStore, acceptHMRUpdate } from "pinia";
import moment from "moment";

export const useAnalyticsStore = defineStore("analytics", () => {
  const yearWeek = `${moment().year()}-${moment().isoWeek()}`;
  const tagline =
    "Tower Hunt is a pay-as-you-go commercial real estate database hand-crafted by local experts.";

  return { yearWeek, tagline };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAnalyticsStore, import.meta.hot));
}
