<template>
  <div class="h-full rounded-lg overflow-hidden">
    <CardBranding :card="card" />
    <div class="absolute inset-0">
      <div
        class="absolute inset-0 bg-gradient-to-r from-emerald-800 to-teal-600 mix-blend-multiply"
      />
    </div>
    <div class="h-full relative px-4 py-16 flex flex-col justify-center">
      <h1 class="text-center text-4xl font-bold tracking-tight">
        <span class="block text-white"
          >{{ availableValidationsCount }} Available</span
        >
        <span class="block text-teal-200">Validations</span>
      </h1>
      <p class="mx-auto mt-6 max-w-lg text-center text-2xl text-teal-200">
        ${{ _.round(availableValidationsRewards, 3) }}
      </p>
      <p class="flex items-center justify-center space-x-2">
        <span class="text-teal-200">of potential rewards</span>
      </p>
      <div
        v-if="isDesktop && availableValidationsCount > 0"
        class="mx-auto mt-10 max-w-sm flex justify-center"
      >
        <div class="space-y-4">
          <a
            href=""
            @click.prevent="viewAvailableValidations"
            class="flex items-center justify-center rounded-md border border-transparent bg-white px-4 py-3 text-base font-medium text-teal-700 shadow-sm hover:bg-teal-50 sm:px-8"
            >Start validating</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import CardBranding from "@/components/collectible-cards/CardBranding.vue";
import api from "@/router/api";
import { ref, watch, onMounted } from "vue";
import _ from "lodash";
import { useRoute, useRouter } from "vue-router";
import { useSecondaryPanelStore } from "@/stores/secondaryPanel";
import { useAvailableValidationsChannelStore } from "@/stores/availableValidationsChannel";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { storeToRefs } from "pinia";

const props = defineProps([
  "card",
  "cardIndex",
  "stackIndex",
  "completedCardIds",
]);

const secondaryPanelStore = useSecondaryPanelStore();
const { validationsActive } = storeToRefs(secondaryPanelStore);
const availableValidationsChannelStore = useAvailableValidationsChannelStore();
const { availableValidationsChannelDataQueue } = storeToRefs(
  availableValidationsChannelStore,
);
const layoutStore = useWorkspaceLayoutStore();
const { isDesktop } = storeToRefs(layoutStore);

const availableValidationsCount = ref(0);
const availableValidationsRewards = ref(0);

watch(
  () => props.stackIndex,
  () => {
    if (availableValidationsCount.value === 0) fetchAvailableValidations();
  },
);
watch(availableValidationsChannelDataQueue, () => {
  fetchAvailableValidations();
});

onMounted(() => {
  fetchAvailableValidations();
});

async function fetchAvailableValidations() {
  return new Promise((resolve) => {
    if (
      props.stackIndex === props.cardIndex &&
      !_.includes(props.completedCardIds, props.card.id)
    ) {
      api.get(`available_validations_count`).then((json) => {
        availableValidationsCount.value = json.data;

        api.get("available_validations_rewards").then((json) => {
          availableValidationsRewards.value = json.data;
          resolve();
        });
      });
    } else {
      resolve();
    }
  });
}

const router = useRouter();
const route = useRoute();

function viewAvailableValidations() {
  if (isDesktop.value) {
    validationsActive.value = true;
    router.push({
      name: route.name,
      query: {
        ...route.query,
        horizontalTab: "Validations",
        verticalTab: "Available",
      },
    });
  }
}
</script>
