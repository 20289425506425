<template>
  <VDropdown>
    <button
      class="w-8 h-8 bg-white inline-flex items-center justify-center text-gray-400 rounded-full bg-transparent hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      :data-test="`team-${team.id}-menu-button`"
    >
      <span class="sr-only">Open options</span>
      <!-- Heroicon name: solid/dots-vertical -->
      <svg
        class="w-5 h-5"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        aria-hidden="true"
      >
        <path
          d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z"
        />
      </svg>
    </button>

    <template #popper>
      <div
        class="w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none"
        role="menu"
        aria-orientation="vertical"
        tabindex="-1"
      >
        <div v-if="isManager" class="py-1" role="none">
          <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" -->
          <a
            v-if="isManager"
            href=""
            @click.prevent="editTeam"
            v-close-popper
            class="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 hover:text-gray-900"
            role="menuitem"
            tabindex="-1"
            :data-test="`manage-team-${team.id}-button`"
            >Manage</a
          >
        </div>
        <div v-else class="py-1" role="none">
          <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" -->
          <a
            href=""
            @click.prevent="viewDetails"
            v-close-popper
            class="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 hover:text-gray-900"
            role="menuitem"
            tabindex="-1"
            :data-test="`view-team-${team.id}-details-button`"
            >View Details</a
          >
        </div>
        <div class="py-1" role="none">
          <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" -->
          <a
            v-if="team.role === 'Primary owner'"
            href=""
            @click.prevent="deleteTeam"
            v-close-popper
            class="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 hover:text-gray-900"
            role="menuitem"
            tabindex="-1"
            :data-test="`delete-team-${team.id}-button`"
            >Delete</a
          >
          <a
            v-if="team.canLeave"
            href=""
            @click.prevent="leaveTeam"
            v-close-popper
            class="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 hover:text-gray-900"
            role="menuitem"
            tabindex="-1"
            :data-test="`leave-team-${team.id}-button`"
            >Leave</a
          >
        </div>
      </div>
    </template>
  </VDropdown>
</template>

<script setup>
import api from "@/router/api";
import { computed } from "vue";
import _ from "lodash";

const props = defineProps(["team"]);
const emit = defineEmits(["edit", "refetch", "view-details"]);
const isManager = computed(() =>
  _.includes(["Primary owner", "Billing member"], props.team.role)
);

function editTeam() {
  emit("edit");
}
function viewDetails() {
  emit("view-details");
}
function deleteTeam() {
  api.delete(`teams/${props.team.id}`).then(() => {
    // this.$store.dispatch("flash", "Team deleted");
    emit("refetch");
  });
}
function leaveTeam() {
  api
    .delete(
      `teams/${props.team.id}/team_memberships/${props.team.membershipId}`
    )
    .then(() => {
      // this.$store.dispatch("flash", `You left ${props.team.name}`);
      emit("refetch");
    });
}
</script>
