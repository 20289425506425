<template>
  <div :class="[noMargin ? '' : 'mb-3']" class="p-2 border-b border-gray-200">
    <div class="flex items-start justify-between">
      <div class="flex flex-col space-y-2">
        <h3
          class="flex items-center space-x-2 leading-6 font-medium text-gray-900"
        >
          <slot name="title"></slot>
        </h3>
        <h4 class="max-w-4xl text-sm text-gray-500">
          <slot name="description"></slot>
        </h4>
      </div>
      <div class="flex items-center space-x-2">
        <slot name="secondary"></slot>
        <router-link
          v-if="helpArticle"
          :to="helpCenterPath"
          target="_blank"
          v-tooltip="'Get help'"
        >
          <QuestionMarkCircleIcon class="h-5 w-5 text-gray-700" />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script setup>
import { QuestionMarkCircleIcon } from "@heroicons/vue/20/solid";
import { computed } from "vue";
import _ from "lodash";

const props = defineProps(["helpArticle", "helpSection", "noMargin"]);
const helpCenterPath = computed(() => {
  const sectionHash = props.helpSection
    ? { hash: `#${_.kebabCase(props.sectionName)}` }
    : {};
  return _.merge(
    {},
    {
      name: "HelpArticle",
      params: { articleId: _.kebabCase(props.helpArticle.name) },
    },
    sectionHash,
  );
});
</script>
