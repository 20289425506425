import { defineStore, acceptHMRUpdate } from "pinia";
import { ref } from "vue";
import _ from "lodash";

export const useBranchingCalculationFieldsStore = defineStore(
  "branchingCalculationFields",
  () => {
    const wrappingContentCollection = ref([]);

    function getContent({ contentType, contentId, context }) {
      return _.find(wrappingContentCollection.value, function (content) {
        return (
          content.contentType === contentType &&
          content.contentId === contentId &&
          content.context === context
        );
      });
    }

    function patchContentCollection(contentObject) {
      wrappingContentCollection.value = _.unionBy(
        [contentObject],
        wrappingContentCollection.value,
        function ({ contentType, contentId, context }) {
          return `${contentType}${contentId}_${context}`;
        },
      );
    }

    function dropContent(contentObject) {
      wrappingContentCollection.value = _.differenceBy(
        wrappingContentCollection.value,
        [contentObject],
        function ({ contentType, contentId, context }) {
          return `${contentType}${contentId}_${context}`;
        },
      );
    }

    return {
      wrappingContentCollection,
      getContent,
      patchContentCollection,
      dropContent,
    };
  },
);

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useBranchingCalculationFieldsStore, import.meta.hot),
  );
}
