<template>
  <button
    @click="viewHelp"
    type="button"
    class="relative inline-flex flex-col justify-center px-3 rounded-r-md text-sm font-medium text-gray-700 hover:text-gray-900 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
    data-test="help-button"
  >
    <div class="flex items-center">
      <QuestionMarkCircleIcon
        v-tooltip="'Help'"
        class="-ml-1 h-6 w-6 text-gray-400"
      />
    </div>
  </button>
</template>

<script setup>
import { QuestionMarkCircleIcon } from "@heroicons/vue/20/solid";
import { useRoute, useRouter } from "vue-router";

const router = useRouter();
const route = useRoute();

function viewHelp() {
  router.push({
    name: route.name,
    query: {
      ...route.query,
      horizontalTab: "Help",
      verticalTab: undefined,
    },
  });
}
</script>
