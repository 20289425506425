<template>
  <div class="h-full rounded-lg overflow-hidden">
    <CardBranding :card="card" />
    <div class="absolute inset-0">
      <div
        class="absolute inset-0 bg-gradient-to-r from-purple-800 to-indigo-600 mix-blend-multiply"
      />
    </div>
    <div class="h-full relative px-4 py-16 flex flex-col justify-center">
      <h1 class="text-center text-4xl font-bold tracking-tight">
        <span class="block text-white"
          >{{ successfulContributions?.attributions }} Successful</span
        >
        <span class="block text-indigo-200">Contributions</span>
      </h1>
      <template v-if="successfulContributions">
        <p class="mx-auto mt-6 max-w-lg text-center text-2xl text-indigo-200">
          ${{ _.round(successfulContributions.rewards, 3) }}
        </p>
        <p class="flex items-center justify-center space-x-2">
          <span class="text-indigo-200">you've earned (so far)!</span>
        </p>
      </template>
      <div v-if="isDesktop" class="mx-auto mt-10 max-w-sm flex justify-center">
        <div class="space-y-4">
          <a
            href=""
            @click.prevent="viewContributions"
            class="flex items-center justify-center rounded-md border border-transparent bg-white px-4 py-3 text-base font-medium text-indigo-700 shadow-sm hover:bg-indigo-50 sm:px-8"
            >Track earnings</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import CardBranding from "@/components/collectible-cards/CardBranding.vue";
import api from "@/router/api";
import { ref, computed, watch, onMounted } from "vue";
import _ from "lodash";
import { useRoute, useRouter } from "vue-router";
import { useCollectibleCardsChannelStore } from "@/stores/collectibleCardsChannel";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { storeToRefs } from "pinia";

const props = defineProps([
  "card",
  "cardIndex",
  "stackIndex",
  "completedCardIds",
]);

const layoutStore = useWorkspaceLayoutStore();
const { isDesktop } = storeToRefs(layoutStore);
const collectibleCardsChannelStore = useCollectibleCardsChannelStore();
const { collectibleCardsChannelDataQueue } = storeToRefs(
  collectibleCardsChannelStore,
);

const successfulContributions = ref(null);
const cardContents = ref([]);
const changeGroup = computed(() => _.head(cardContents.value)?.content);

watch(
  () => props.stackIndex,
  () => {
    if (successfulContributions.value === null) fetchTopCardChangeGroup();
  },
);
watch(collectibleCardsChannelDataQueue, () => {
  debouncedFetchContributions();
});

onMounted(() => {
  fetchTopCardChangeGroup();
});

const debouncedFetchContributions = _.debounce(function () {
  fetchContributions();
}, 5000);

function fetchTopCardChangeGroup() {
  if (
    props.stackIndex === props.cardIndex &&
    !_.includes(props.completedCardIds, props.card.id)
  ) {
    api
      .get(
        `collectible_cards/${props.card.collectibleCardId}/collectible_card_contents`,
      )
      .then((json) => {
        cardContents.value = json.data;
        fetchContributions();
      });
  }
}

async function fetchContributions() {
  return new Promise((resolve) => {
    if (
      changeGroup.value &&
      props.stackIndex === props.cardIndex &&
      !_.includes(props.completedCardIds, props.card.id)
    ) {
      api
        .get(`successful_contributions/${changeGroup.value.id}`)
        .then((json) => {
          successfulContributions.value = json.data;

          resolve();
        });
    } else {
      resolve();
    }
  });
}

const router = useRouter();
const route = useRoute();

function viewContributions() {
  if (isDesktop.value) {
    router.push({
      name: route.name,
      query: {
        ...route.query,
        horizontalTab: "Me",
        verticalTab: "Contributions",
      },
    });
  }
}
</script>
