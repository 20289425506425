<template>
  <div
    class="flex flex-1 flex-wrap items-center"
    :class="
      proveForValue
        ? 'border border-gray-300 rounded-lg shadow-sm focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500'
        : ''
    "
  >
    <label for="contact_name" class="sr-only">{{ label }}</label>
    <div
      v-if="displayContacts.length > 0"
      class="flex flex-wrap pl-2 pt-1 pb-1 pr-1"
    >
      <li
        v-for="contact in displayContacts"
        :key="contact.name"
        class="inline-flex rounded-full items-center my-1 mr-1 py-0.5 pl-2.5 pr-1 text-sm font-medium bg-yellow-100 text-yellow-700"
      >
        {{ contact.name }}
        <button
          @click="remove(contact)"
          type="button"
          class="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-yellow-400 hover:bg-yellow-200 hover:text-yellow-500 focus:outline-none focus:bg-yellow-500 focus:text-white"
        >
          <span class="sr-only">Remove contact</span>
          <svg
            class="h-2 w-2"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 8 8"
          >
            <path
              stroke-linecap="round"
              stroke-width="1.5"
              d="M1 1l6 6m0-6L1 7"
            />
          </svg>
        </button>
      </li>
    </div>
    <div class="flex flex-col">
      <Combobox v-model="selectedContact" nullable>
        <ComboboxInput
          v-observe-visibility="{ callback: focusInput, once: true }"
          :id="`${inputKey}-autocomplete-input`"
          class="text-gray-800 placeholder-gray-400 text-sm"
          :class="inputClasses || 'border-0 bg-transparent focus:ring-0 p-2'"
          @keydown="handleKeyDown"
          @blur="handleBlur"
          @change="query = $event.target.value"
          :placeholder="proveForValue ? 'Contact name' : 'Contact name(s)'"
          :displayValue="(record) => record?.name"
          :data-test="`${inputKey}${
            proveForValue ? '-proof' : ''
          }-autocomplete-input`"
        />
        <ComboboxOptions
          class="max-h-80 scroll-py-10 scroll-pb-2 space-y-2 overflow-y-auto"
        >
          <ComboboxOption
            v-for="(contact, index) in filteredFetchedContacts"
            :key="contact.localId"
            :value="contact"
            as="template"
            :data-test="`${inputKey}-autocomplete-result-${index}`"
            v-slot="{ active }"
          >
            <li
              :class="[
                'flex text-sm cursor-default select-none items-center p-2',
                active && 'bg-indigo-600 text-white',
              ]"
            >
              {{ contact.recordLabel
              }}<template v-if="contact.fieldValue !== contact.recordLabel"
                >: {{ contact.fieldValue }}</template
              >
            </li>
          </ComboboxOption>
        </ComboboxOptions>
      </Combobox>
    </div>
  </div>
  <div
    v-if="proveForValue && proofContact.length > 0"
    class="mt-1 flex justify-end space-x-2"
  >
    <button
      @click="remove(_.head(proofContact))"
      type="button"
      class="inline-flex items-center p-1 border border-gray-300 rounded-full shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
    >
      <XMarkIcon class="h-4 w-4" />
    </button>

    <DataVisibilityButton visibility="public" class="inline-flex">
      <template v-slot:button>
        <button
          @click="submitProof"
          :disabled="originatingData"
          type="button"
          :data-test="`contact-proof-submit`"
          class="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2"
        >
          <PulseLoader
            v-if="originatingData"
            :loading="true"
            size="3px"
            color="#f3f4f6"
          />
          <CheckIcon v-else class="h-4 w-4" />
        </button>
      </template>
    </DataVisibilityButton>
  </div>
</template>

<script setup>
import { ref, computed, watch } from "vue";
import {
  Combobox,
  ComboboxInput,
  ComboboxOptions,
  ComboboxOption,
} from "@headlessui/vue";
import { XMarkIcon, CheckIcon } from "@heroicons/vue/20/solid";
import DataVisibilityButton from "@/components/crowdsourcing/DataVisibilityButton.vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { useCrowdsourcedChangeGroupStore } from "@/stores/crowdsourcedChangeGroup";
import { useNotificationsStore } from "@/stores/notifications";
import { useProveForValueStore } from "@/stores/proveForValue";
import { storeToRefs } from "pinia";
import api from "@/router/api";
import _ from "lodash";
import moment from "moment";

const props = defineProps([
  "inputKey",
  "label",
  "focus",
  "searchTeams",
  "selections",
  "removeInline",
  "inputClasses",
  "challengeDataField",
  "proveForValue",
  "investmentGroupId",
  "availabilityGroupId",
  "fieldName",
  "isInnerField",
  "parentComponentSaveFunction",
  "admin",
]);

const emit = defineEmits(["new-contacts", "remove", "unlocked", "completed"]);

const changeGroupStore = useCrowdsourcedChangeGroupStore();
const { originatingData } = storeToRefs(changeGroupStore);
const notificationsStore = useNotificationsStore();
const proveForValueStore = useProveForValueStore();

const contactFetchTime = ref(null);
const selectedContact = ref(null);
const query = ref("");
function clearInput(refocus = true) {
  setTimeout(() => {
    selectedContact.value = null;
    query.value = "";
    contacts.value = [];

    const inputEl = document.getElementById(
      `${props.inputKey}-autocomplete-input`,
    );
    if (inputEl) inputEl.value = "";
    if (refocus) selectInput();
  }, 50);
}

const contacts = ref([]);
const filteredFetchedContacts = computed(() => {
  const filtered = contacts.value.filter(
    (dataFieldSearchResult) => dataFieldSearchResult.recordType === "Contact",
  );
  const ordered = _.orderBy(
    filtered,
    [nameFirstResults, sortedRecordType],
    ["desc", "desc"],
  );
  return _.uniqBy(ordered, "recordId");
});
function nameFirstResults(result) {
  if (result.fieldName === "name") {
    return 8;
  } else {
    return 1;
  }
}
function sortedRecordType(result) {
  if (result.fieldType === "ContentAlias") {
    return 11;
  } else {
    return 1;
  }
}

const proofContact = ref([]);
const displayContacts = computed(() => {
  if (props.proveForValue) {
    return proofContact.value;
  } else {
    return props.selections;
  }
});

watch(selectedContact, async () => {
  onSelect();
});

watch(query, async () => {
  if (_.trim(query.value) !== "") {
    debouncedFilterContacts();
  }
});

const debouncedFilterContacts = _.debounce(function () {
  filterContacts();
}, 250);

function focusInput(isVisible) {
  if (isVisible && props.focus) {
    setTimeout(() => {
      selectInput();
    }, 100);
  }
}
function selectInput() {
  document.getElementById(`${props.inputKey}-autocomplete-input`).focus();
}

function filterContacts() {
  if (query.value.length > 0) {
    const fetchTime = moment().valueOf();
    contactFetchTime.value = fetchTime;
    const endpoint = props.admin
      ? `admin/contact_searches`
      : `contact_searches`;
    const request =
      query.value === "" || query.value === null
        ? null
        : _.toLower(query.value);

    api
      .post(endpoint, {
        query: request,
      })
      .then(
        (json) => {
          if (fetchTime === contactFetchTime.value) {
            contacts.value = json.data;
            contactFetchTime.value = null;
          }
        },
        (failure) => {
          console.log(failure);
          // this.$store.dispatch("flash", "Invalid search");
        },
      );
  } else {
    contacts.value = [];
  }
}

async function onSelect(refocus = true) {
  const output = selectedContact.value ? selectedContact.value : query.value;
  const selection = output === "" || output === null ? null : output;

  if (selection) {
    let placeholder = {
      name: null,
      id: null,
    };
    if (selectedContact.value) {
      placeholder.name =
        selection.fieldValue !== selection.recordLabel
          ? `${selection.recordLabel}: ${selection.fieldValue}`
          : selection.recordLabel;
      placeholder.id = selection.recordId;
    } else {
      placeholder.name = selection;
    }

    if (props.proveForValue) {
      proofContact.value = [placeholder];
    } else {
      emit("new-contacts", _.unionBy(props.selections, [placeholder], "name"));
    }
  }
  clearInput(refocus);
}

function handleBlur() {
  setTimeout(() => {
    if (filteredFetchedContacts.value.length === 0) {
      const input = query.value;
      const typedVal = input === "" || input === null ? null : input;

      if (typedVal) {
        onSelect();
      } else {
        clearInput(false);
      }
    }
  }, 75);
}
function handleKeyDown(e) {
  const lowerQuery =
    query.value === "" || query.value === null ? null : _.toLower(query.value);

  if (lowerQuery && ["Tab", ","].includes(e.key)) {
    onSelect(false);
  } else if (
    lowerQuery &&
    filteredFetchedContacts.value.length === 0 &&
    ["Enter"].includes(e.key)
  ) {
    onSelect();
  } else if (
    !lowerQuery &&
    displayContacts.value.length > 0 &&
    ["Backspace"].includes(e.key) &&
    props.removeInline
  ) {
    remove(_.last(displayContacts.value));
  }
}

function remove(record) {
  if (props.proveForValue) {
    proofContact.value = [];
  } else {
    emit("remove", record);
  }
  setTimeout(() => {
    selectInput();
  }, 50);
}

async function submitProof() {
  originatingData.value = true;
  const contact = _.head(proofContact.value);

  let payload = {
    isInner: props.isInnerField,
    investmentGroupId: props.investmentGroupId,
    availabilityGroupId: props.availabilityGroupId,
  };

  if (contact?.id) {
    payload.fieldValue = contact.id;
    payload.fieldValueType = "id";
  } else {
    payload.fieldValue = contact.name;
    payload.fieldValueType = "string";
  }
  const proofResponse = await proveForValueStore.submitProof(
    props.challengeDataField,
    payload,
  );

  if (proofResponse.data?.proofStatus === "rejected") {
    console.log("rejected");
    await persistRejectedProofData();
  } else if (proofResponse.data?.proofStatus === "accepted") {
    console.log("accepted");
    notificationsStore.addNotification("proofAccepted");
    emit("unlocked", { override: "ContactCompanyInvolvement" });
  }
  originatingData.value = false;
}

async function persistRejectedProofData() {
  console.log("persist rejected proof");
  const apiRequestFunc = () =>
    props.parentComponentSaveFunction(proofContact.value);
  const successCallback = async (json) => {
    remove(_.last(displayContacts.value));
    if (json?.data?.dataField) {
      const declassifyPayload = {
        id: json.data.dataField.localId,
      };

      const declassifiedResponse = await api.post(
        `declassify_datapoint`,
        declassifyPayload,
      );
      json.data.dataField = declassifiedResponse.data;
    } else if (json?.data?.dataFields) {
      const declassifyPayload = {
        ids: json.data.dataFields.map((field) => field.localId),
      };
      await api.post(`declassify_datapoints`, declassifyPayload);
    }
    notificationsStore.addNotification("proofRejected");
    emit("completed", { override: "ContactCompanyInvolvement" });
  };
  const failureCallback = () => remove(_.head(proofContact.value));

  return changeGroupStore.originateData(
    apiRequestFunc,
    successCallback,
    failureCallback,
  );
}
</script>
