<template>
  <div class="px-4 py-5 sm:p-6 text-center">
    <LinkIcon class="mx-auto h-12 w-12 text-gray-400" />
    <h3 class="mt-2 text-sm font-medium text-gray-900">No preview</h3>
    <p class="mt-1 text-sm text-gray-500">
      The linked content can't be previewed.
    </p>
    <div class="mt-6">
      <button
        @click="viewUrl"
        type="button"
        v-tooltip="url"
        class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        <ArrowTopRightOnSquareIcon class="-ml-1 mr-2 h-5 w-5" />
        <span>View link</span>
      </button>
    </div>
  </div>
</template>

<script setup>
import { LinkIcon } from "@heroicons/vue/24/outline";
import { ArrowTopRightOnSquareIcon } from "@heroicons/vue/20/solid";
const props = defineProps(["url"]);

function viewUrl() {
  window.open(props.url, "_blank");
}
</script>
