<template>
  <div class="relative overflow-hidden">
    <div class="absolute inset-0">
      <div
        class="absolute inset-0 bg-gradient-to-r from-purple-800 to-indigo-700 mix-blend-multiply"
      />
    </div>
    <div class="relative px-4 py-16">
      <h1 class="text-center text-4xl font-bold tracking-tight">
        <span class="block text-white">It takes money</span>
        <span class="block text-indigo-200">to make money</span>
      </h1>
      <p class="mx-auto mt-6 max-w-lg text-center text-lg text-indigo-200">
        You must put data credits at risk while your data is being validated.
        When your data is accepted, you receive your credits back plus a reward.
      </p>
      <div class="mx-auto mt-10 max-w-sm flex justify-center">
        <div class="space-y-4">
          <a
            href=""
            @click.prevent="emit('acknowledge')"
            class="flex items-center justify-center rounded-md border border-transparent bg-white px-4 py-3 text-base font-medium text-indigo-700 shadow-sm hover:bg-indigo-50 sm:px-8"
            data-test="prompt-to-subscribe-acknowledge-button"
            >Fund credits</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const emit = defineEmits(["acknowledge"]);
</script>
