<template>
  <Combobox v-model="selectedRecipient" nullable>
    <ComboboxInput
      v-observe-visibility="{ callback: focusInput, once: true }"
      :id="`${inputKey}-autocomplete-input`"
      class="w-full text-gray-800 placeholder-gray-400 text-sm"
      :class="inputClasses || 'border-0 bg-transparent focus:ring-0 pb-0'"
      @keydown="handleKeyDown"
      @blur="handleBlur"
      @change="query = $event.target.value"
      :placeholder="searchTeams ? 'Team or user name(s)' : 'User name(s)'"
      :displayValue="(record) => record?.name"
      :data-test="`${inputKey}-autocomplete-input`"
    />
    <ComboboxOptions
      class="max-h-80 scroll-py-10 scroll-pb-2 space-y-2 overflow-y-auto"
    >
      <ComboboxOption
        v-for="(recipient, index) in recipients"
        :key="recipient.id"
        :value="recipient"
        as="template"
        :data-test="`${inputKey}-autocomplete-result-${index}`"
        v-slot="{ active }"
      >
        <li
          :class="[
            'flex text-sm cursor-default select-none items-center p-2',
            active && 'bg-indigo-600 text-white',
          ]"
        >
          {{ recipient.name }}
        </li>
      </ComboboxOption>
    </ComboboxOptions>
  </Combobox>
</template>

<script setup>
import { ref, watch } from "vue";
import {
  Combobox,
  ComboboxInput,
  ComboboxOptions,
  ComboboxOption,
} from "@headlessui/vue";
import { useUserStore } from "@/stores/user";
import { storeToRefs } from "pinia";
import api from "@/router/api";
import _ from "lodash";
import moment from "moment";

const props = defineProps([
  "inputKey",
  "focus",
  "searchTeams",
  "selections",
  "removeInline",
  "inputClasses",
  "noSelf",
]);
const emit = defineEmits(["add-user", "add-team", "remove"]);
const teamFetchTime = ref(null);
const selectedRecipient = ref(null);
const query = ref("");
const userStore = useUserStore();
const { currentUser } = storeToRefs(userStore);
function clearInput() {
  setTimeout(() => {
    selectedRecipient.value = null;
    query.value = "";
    recipients.value = [];
    document.getElementById(`${props.inputKey}-autocomplete-input`).value = "";
    selectInput();
  }, 50);
}

const recipients = ref([]);

watch(selectedRecipient, async () => {
  onSelect();
});

watch(query, async () => {
  if (_.trim(query.value) !== "") {
    debouncedFilterRecipients();
  }
});

const debouncedFilterRecipients = _.debounce(function () {
  filterRecipients();
}, 250);

function focusInput(isVisible) {
  if (isVisible && props.focus) {
    setTimeout(() => {
      selectInput();
    }, 100);
  }
}
function selectInput() {
  document.getElementById(`${props.inputKey}-autocomplete-input`).focus();
}

function filterRecipients() {
  if (query.value.length > 0) {
    const fetchTime = moment().valueOf();
    teamFetchTime.value = fetchTime;
    const request =
      query.value === "" || query.value === null
        ? null
        : _.toLower(query.value);

    api
      .post(`sharing_recipient_searches?team=${props.searchTeams}`, {
        query: request,
      })
      .then(
        (json) => {
          if (fetchTime === teamFetchTime.value) {
            recipients.value = filteredUsers(json.data);
            teamFetchTime.value = null;
          }
        },
        (failure) => {
          console.log(failure);
          // this.$store.dispatch("flash", "Invalid search");
        },
      );
  } else {
    recipients.value = [];
  }
}

function filteredUsers(searchResponse) {
  if (props.noSelf && currentUser.value?.id) {
    const filtered = _.reject(searchResponse, function (result) {
      return result.className === "User" && result.id === currentUser.value.id;
    });

    return filtered;
  } else {
    return searchResponse;
  }
}

async function onSelect() {
  if (selectedRecipient.value && selectedRecipient.value.className === "Team") {
    const newTeam = {
      id: selectedRecipient.value.id,
      name: selectedRecipient.value.name,
      className: selectedRecipient.value.className,
    };
    emit("add-team", newTeam);
    clearInput();
  } else if (
    selectedRecipient.value &&
    selectedRecipient.value.className === "User"
  ) {
    emit("add-user", selectedRecipient.value);
    clearInput();
  } else {
    handleRawEmail();
  }
}

function handleRawEmail() {
  const lowerQuery = _.toLower(query.value);
  const output = {
    name: lowerQuery,
    email: lowerQuery,
    id: Date.now(),
    className: "RawEmail",
  };
  const validAddition = userStore.emailRegex.test(lowerQuery);

  if (validAddition) {
    emit("add-user", output);
  } else {
    // this.$store.dispatch(
    //   "flash",
    //   "Sorry, this doesn't look like a valid team, person, or email address."
    // );
  }
  clearInput();
}

function handleBlur() {
  setTimeout(() => {
    if (recipients.value.length === 0) {
      const validAddition = userStore.emailRegex.test(query.value);

      if (validAddition) {
        handleRawEmail();
      }
    }
  }, 75);
}
function handleKeyDown(e) {
  const lowerQuery =
    query.value === "" || query.value === null ? null : _.toLower(query.value);
  const validAddition = userStore.emailRegex.test(lowerQuery);

  if (validAddition && ["Tab", ",", " "].includes(e.key)) {
    handleRawEmail();
  } else if (
    !lowerQuery &&
    props.selections.length > 0 &&
    ["Backspace"].includes(e.key) &&
    props.removeInline
  ) {
    remove(_.last(props.selections));
  } else if (
    validAddition &&
    recipients.value.length === 0 &&
    ["Enter"].includes(e.key)
  ) {
    handleRawEmail();
  }
}

function remove(record) {
  emit("remove", record);
  setTimeout(() => {
    selectInput();
  }, 50);
}
</script>
