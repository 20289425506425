<template>
  <tr v-if="field" :class="selected ? 'bg-gray-50' : ''">
    <td class="relative w-12 px-4 py-2">
      <div
        v-if="selected"
        class="absolute inset-y-0 left-0 w-0.5 bg-indigo-600"
      ></div>

      <input
        @click="toggleSelected"
        type="checkbox"
        :checked="selected"
        :value="selected"
        class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
      />
    </td>
    <td class="py-2 pr-3">
      <DataField
        v-if="propertyDataField"
        :data-field="propertyDataField"
        primary-text-path="fieldContent.name"
        text-classes="text-sm font-medium"
        dropdown-placement="left-start"
        text-styles=""
      />
      <a
        @click.prevent="selectDecorating"
        href=""
        class="text-xs text-indigo-600 hover:text-indigo-900"
        >{{ validationContextMetaData?.summary }}</a
      >
    </td>
    <td class="px-3 py-2 text-gray-500 space-y-1">
      <DataField
        :data-field="field"
        :safezone-tab="true"
        self-label-output-format="rentRollName"
        text-classes="text-sm font-medium"
        :dropdown-placement="
          workspaceLayout === 'sideBySide' ? 'left-start' : null
        "
        @dismiss="dismissed"
      />
      <div class="text-xs text-gray-500">
        {{ fieldType }} &middot;
        {{ moment(field?.createdAt).format("MM/DD/YYYY, h:mm a") }}
      </div>
    </td>
    <td class="whitespace-nowrap px-3 py-2 text-sm space-y-1">
      <div class="flex font-medium items-center text-emerald-500">
        <span v-tooltip="'Your share of the revenue from this datapoint'"
          >{{ revenueSplit }}%</span
        >
      </div>
    </td>
  </tr>
</template>

<script setup>
import DataField from "@/components/crowdsourcing/DataField.vue";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { useDataLicensesChannelStore } from "@/stores/dataLicensesChannel";
import { usePropertyFieldsStore } from "@/stores/propertyFields";
import { useUserStore } from "@/stores/user";
import api from "@/router/api";
import moment from "moment";
import { computed, onMounted, ref, watch } from "vue";
import { storeToRefs } from "pinia";
import _ from "lodash";

const props = defineProps(["field", "selected", "index"]);
const emit = defineEmits([
  "open-sourced",
  "dismissed",
  "unselect",
  "select",
  "select-decorating",
]);

const propertyId = ref(null);
const validationContextMetaData = ref(null);

const userStore = useUserStore();
const { activeEasyDataInputPayloadItem } = storeToRefs(userStore);
const layoutStore = useWorkspaceLayoutStore();
const { workspaceLayout } = storeToRefs(layoutStore);
const dataLicensesChannelStore = useDataLicensesChannelStore();
const { dataLicensesChannelDataQueue } = storeToRefs(dataLicensesChannelStore);
const propertyFieldsStore = usePropertyFieldsStore();

const propertyDataField = computed(() => {
  if (propertyId.value) {
    return propertyFieldsStore.getPropertyField(propertyId.value);
  } else {
    return null;
  }
});
const fieldType = computed(() => {
  if (props.field?.fieldContentType) {
    return _.startCase(props.field.fieldContentType);
  } else if (props.field?.fieldName) {
    return _.startCase(props.field.fieldName);
  } else {
    return "Unknown field type";
  }
});
const revenueSplit = computed(() => {
  if (props.field.easyDataInputReferrerSplit) {
    const roleBasedSplit = activeEasyDataInputPayloadItem.value
      ? 1 - props.field.easyDataInputReferrerSplit
      : props.field.easyDataInputReferrerSplit;
    return _.round(roleBasedSplit * 100);
  } else {
    return "100";
  }
});

const dataFieldId = computed(() => props.field.localId);

watch(dataLicensesChannelDataQueue, () => {
  const data = _.last(dataLicensesChannelDataQueue.value);

  if (data.dataFieldIds && _.includes(data.dataFieldIds, dataFieldId.value)) {
    fetchValidationContext();
  }
});

onMounted(() => {
  fetchValidationContext();
  fetchTopLevelContent();
});

function fetchValidationContext() {
  if (props.field) {
    api
      .get(
        `validation_contexts/${props.field.decoratingContentType}/${props.field.decoratingContentId}?scope=validation`,
      )
      .then((json) => {
        validationContextMetaData.value = json.data;
      });
  }
}
async function fetchTopLevelContent() {
  api.get(`data_field_top_level_content/${dataFieldId.value}`).then((json) => {
    if (json.data?.fieldContentType === "Property") {
      propertyFieldsStore.patchPropertyDataFields([json.data]);
      propertyId.value = json.data?.fieldContentId;
    } else if (
      json.data?.fieldContent?.propertyId ||
      json.data?.fieldContent?.topLevelPropertyId
    ) {
      propertyId.value =
        json.data?.fieldContent?.propertyId ||
        json.data?.fieldContent?.topLevelPropertyId;
      propertyFieldsStore.fetchPropertyDataField(propertyId.value);
    }
  });
}
function dismissed() {
  emit("dismissed");
}
function toggleSelected() {
  if (props.selected) {
    emit("unselect");
  } else {
    emit("select");
  }
}
function selectDecorating() {
  emit("select-decorating", {
    contentType: props.field.decoratingContentType,
    contentId: props.field.decoratingContentId,
  });
}
</script>
