<template>
  <section aria-labelledby="polygons">
    <ContentHeader>
      <template v-slot:title>Map Regions</template>
      <template v-slot:description
        >What geographic regions are being targeted?</template
      >
    </ContentHeader>

    <ContentBody v-if="polygonsLoaded">
      <div
        v-for="polygonDataField in polygons"
        :key="polygonDataField.localId"
        class="p-2 rounded-md border border-gray-300 space-y-3"
      >
        <DataField
          :data-field="polygonDataField"
          text-classes="text-sm"
          @suggest-change="suggestChange"
          @completed="completed"
        />
        <ParcelMap
          v-if="polygonDataField.fieldContent?.coordinates"
          :map-id="polygonDataField.localId"
          :data-field-id="polygonDataField.localId"
          :polygon="_.get(polygonDataField, 'fieldContent', null)"
          :nearby="true"
          class="mt-5 h-48 w-full"
        />
        <section v-else class="mt-5">
          <div
            class="relative block w-full rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <LockClosedIcon class="mx-auto h-8 w-8 text-gray-400" />
            <span class="mt-2 block text-sm font-medium text-gray-900">
              Unlock to view map
            </span>
          </div>
        </section>
      </div>

      <ParcelLocationEdit
        v-if="editing"
        :decorating-data-field="dataField"
        @completed="completed"
        @cancel="cancel"
      />

      <div v-else class="flex items-start">
        <DataVisibilityButton visibility="safezone">
          <template v-slot:button>
            <button
              @click="editing = true"
              type="button"
              class="group py-1 px-1.5 flex items-center justify-between rounded-full border border-gray-300 shadow-sm space-x-2 text-left hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-600"
            >
              <span class="min-w-0 flex-1 flex items-center space-x-1">
                <span class="flex-shrink-0 flex items-center">
                  <span
                    class="inline-flex items-center justify-center h-5 w-5 rounded-full bg-yellow-500"
                  >
                    <i class="text-sm text-white fas fa-draw-polygon"></i>
                  </span>
                </span>
                <span class="min-w-0 flex-1">
                  <span class="text-sm font-medium text-gray-900 truncate"
                    >Add region boundaries</span
                  >
                </span>
              </span>
            </button>
          </template>
        </DataVisibilityButton>
      </div>
    </ContentBody>
  </section>
</template>

<script setup>
import DataField from "@/components/crowdsourcing/DataField.vue";
import ContentBody from "@/components/main-layout/secondary-panel/ContentBody.vue";
import ContentHeader from "@/components/main-layout/secondary-panel/ContentHeader.vue";
import ParcelLocationEdit from "@/components/crowdsourcing/ParcelLocationEdit.vue";
import ParcelMap from "@/components/maps/ParcelMap.vue";
import api from "@/router/api";
import _ from "lodash";
import { ref, onMounted, computed } from "vue";
import DataVisibilityButton from "@/components/crowdsourcing/DataVisibilityButton.vue";
import { LockClosedIcon } from "@heroicons/vue/20/solid";

const props = defineProps(["dataField", "optional"]);
const emit = defineEmits(["refetch"]);
const editing = ref(false);
const polygons = ref([]);
const polygonsLoaded = ref(false);
const focalContentType = computed(() => {
  if (
    props.dataField.decoratingContentType === "HuntGeographyIntent" &&
    props.dataField.fieldContentType === "CompanyInvolvement"
  ) {
    return props.dataField.decoratingContentType;
  } else {
    return props.dataField.fieldContentType;
  }
});
const focalContentId = computed(() => {
  if (
    props.dataField.decoratingContentType === "HuntGeographyIntent" &&
    props.dataField.fieldContentType === "CompanyInvolvement"
  ) {
    return props.dataField.decoratingContentId;
  } else {
    return props.dataField.fieldContentId;
  }
});

onMounted(() => fetchPolygons());

function completed() {
  editing.value = false;
  fetchPolygons();
  emit("refetch");
}
function cancel() {
  editing.value = false;
}
function suggestChange() {}

function fetchPolygons() {
  const fieldName =
    focalContentType.value === "HuntGeographyIntent"
      ? "GeographyRegion"
      : "ContentPolygon";
  api
    .get(
      `crowdsourced_data_fields/${focalContentType.value}/${focalContentId.value}?field_name=${fieldName}`,
    )
    .then((json) => {
      polygons.value = json.data;
      polygonsLoaded.value = true;
    });
}
</script>
