<template>
  <TransitionRoot appear :show="!!size" as="template">
    <Dialog as="div" @close="modalStore.closeModal" class="relative z-[999999]">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black bg-opacity-25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full justify-center p-4 text-center">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              :id="`${size}ModalVue`"
              class="rounded-2xl text-left align-middle shadow-xl transition-all transform overflow-auto"
              :class="`${modalSizeClasses} ${backgroundColor}`"
            >
              <div
                class="hidden sm:block absolute top-0 right-0 pt-3 pr-3 z-10"
              >
                <button
                  @click="modalStore.closeModal"
                  type="button"
                  class="text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500"
                  data-test="close-modal-button"
                >
                  <XMarkIcon class="h-6 w-6" />
                </button>
              </div>
              <div v-if="component">
                <component :is="markRaw(component)" v-bind="props" />
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
} from "@headlessui/vue";
import { XMarkIcon } from "@heroicons/vue/20/solid";
import { useModalStore } from "@/stores/modal";
import { storeToRefs } from "pinia";
import { computed, markRaw } from "vue";

const modalStore = useModalStore();
const { size, theme, component, props } = storeToRefs(modalStore);
const backgroundColor = computed(() =>
  theme.value === "light" ? "bg-white" : "bg-gray-900",
);
const modalSizeClasses = computed(() => {
  switch (size.value) {
    case "xl":
      return "self-stretch w-4/5";
    default:
      return "self-center w-full max-w-md";
  }
});
</script>
