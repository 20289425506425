<template>
  <div class="space-y-1">
    <div v-if="hasUsers" class="flex items-center justify-between">
      <div class="flex items-center space-x-2">
        <BriefcaseIcon
          v-tooltip="'Space Users'"
          class="h-5 w-5 text-gray-400"
        />
        <div class="flex items-center space-x-1">
          <DataField
            v-for="user in spaceUsers"
            :key="user.localId"
            :data-field="user"
            text-classes="text-sm font-medium"
            text-styles=""
            @unlocked="refetch"
            @completed="refetch"
            @open-sourced="refetch"
          />
        </div>
      </div>
      <VDropdown>
        <button
          type="button"
          v-tooltip="'Space actions menu'"
          :disabled="originatingData"
          :data-test="`${dataField.fieldContentType}-${dataField.fieldContentId}-space-actions-menu`"
          class="inline-flex items-center shadow-sm p-1 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 duration-150"
        >
          <PulseLoader
            v-if="originatingData"
            :loading="true"
            size="3px"
            color="#374151"
          />
          <ArrowsRightLeftIcon v-else class="h-4 w-4" />
        </button>

        <template #popper>
          <SpaceUsageActionDropdown
            :top-level-space-data-field="dataField"
            :decorating-data-field="headUser"
            :existing-availability-id="existingAvailabilityId"
            :availability-group-id="availabilityGroupId"
            context="floor-area-pop-up"
            :restricted="unlockable"
            @refetch-availability="refetch"
          />
        </template>
      </VDropdown>
    </div>
    <div v-if="hasUsages && hasExpirations" class="flex items-center space-x-2">
      <CalendarIcon
        v-tooltip="'Expiration Date'"
        class="h-5 w-5 text-gray-400"
      />
      <div class="flex items-center space-x-1">
        <DataField
          v-for="(spaceUsage, i) in spaceUsages"
          :key="i"
          :data-field="spaceUsage.expirationField"
          text-classes="text-sm font-medium"
          text-styles=""
          @unlocked="refetch"
          @completed="refetch"
          @open-sourced="refetch"
        />
      </div>
    </div>
    <div v-if="hasUsages && hasBaseRents" class="flex items-center space-x-2">
      <DocumentCurrencyDollarIcon
        v-tooltip="'In-Place Rent'"
        class="h-5 w-5 text-gray-400"
      />
      <div class="flex items-center space-x-1">
        <DataField
          v-for="(spaceUsage, i) in spaceUsages"
          :key="i"
          calculation-name="in_place_rent"
          :calculation-value="inPlaceRentFor(spaceUsage)"
          :bundle-field-ids="inPlaceRentFieldIdsFor(spaceUsage)"
          text-classes="text-sm font-medium"
          text-styles=""
          @unlocked="refetch"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import DataField from "@/components/crowdsourcing/DataField.vue";
import SpaceUsageActionDropdown from "@/components/space-usage-builder/SpaceUsageActionDropdown.vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { useCrowdsourcedChangeGroupStore } from "@/stores/crowdsourcedChangeGroup";
import { useTimeTravelStore } from "@/stores/timeTravel";
import {
  BriefcaseIcon,
  CalendarIcon,
  ArrowsRightLeftIcon,
  DocumentCurrencyDollarIcon,
} from "@heroicons/vue/20/solid";
import { computed } from "vue";
import { storeToRefs } from "pinia";
import {
  inPlaceRentFields,
  inPlaceRent,
} from "@/components/analyze/calculations/inPlaceRent";
import { currencyAmount } from "@/assets/numberHelpers";
import _ from "lodash";

const props = defineProps(["dataField", "spaceUsers", "spaceUsages"]);
const emit = defineEmits(["refetch"]);

const changeGroupStore = useCrowdsourcedChangeGroupStore();
const { originatingData } = storeToRefs(changeGroupStore);
const timeTravelStore = useTimeTravelStore();
const { timeTravelTo } = storeToRefs(timeTravelStore);

const hasUsers = computed(
  () => props.spaceUsers && props.spaceUsers.length > 0,
);
const hasUsages = computed(
  () => props.spaceUsages && props.spaceUsages.length > 0,
);
const hasExpirations = computed(() =>
  _.some(props.spaceUsages, function (usage) {
    return usage.expirationField;
  }),
);
const hasBaseRents = computed(() =>
  _.some(props.spaceUsages, function (usage) {
    return usage.baseRentSchedule;
  }),
);
const headUser = computed(() => {
  if (hasUsers.value) {
    return _.head(props.spaceUsers);
  } else {
    return null;
  }
});
const existingAvailabilityId = computed(() => {
  if (hasUsers.value) {
    const ids = props.spaceUsers.map(
      (dataField) => dataField.fieldContent?.availabilityId,
    );

    return _.head(_.compact(ids));
  } else {
    return null;
  }
});
const availabilityGroupId = computed(() => {
  if (hasUsers.value) {
    const ids = props.spaceUsers.map(
      (dataField) => dataField.fieldContent?.availabilityGroupId,
    );

    return _.head(_.compact(ids));
  } else {
    return null;
  }
});
const unlockable = computed(() => {
  if (hasUsers.value) {
    return _.some(props.spaceUsers, function (dataField) {
      const safezone = _.get(dataField, "safezone");
      const unstaked = _.get(dataField, "state") === "unstaked";
      const rawPrice = _.get(dataField, "price");
      const price = rawPrice ? _.toNumber(rawPrice) : null;

      return (unstaked && !safezone) || (price && price > 0);
    });
  } else {
    return false;
  }
});

function refetch() {
  emit("refetch");
}

function inPlaceRentFor(spaceUsage) {
  const numeric = inPlaceRent(spaceUsage, timeTravelTo.value);

  return _.isNumber(numeric) ? `$${currencyAmount(numeric, 2)}/SF` : null;
}

function inPlaceRentFieldIdsFor(spaceUsage) {
  const { baseRentScheduleField, expirationField, perAreaSizeField } =
    inPlaceRentFields(spaceUsage);
  const ids = _.compact([
    baseRentScheduleField,
    expirationField,
    perAreaSizeField,
  ]).map(({ localId }) => localId);

  return ids;
}
</script>
