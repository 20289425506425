<template>
  <div
    class="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200"
  >
    <div
      id="availability-groups-list"
      @click="expanded = !expanded"
      class="p-2 font-semibold uppercase tracking-wide"
      :class="
        availabilityGroups.length > 0
          ? 'bg-green-400 text-gray-900'
          : 'bg-gray-100 text-gray-900'
      "
    >
      Space Groupings List
    </div>
    <template v-if="expanded">
      <AvailabilityGroup
        v-for="(availabilityGroup, index) in availabilityGroups"
        :key="index"
        :availability-group="availabilityGroup"
        :order="index + 1"
      />
      <div
        v-if="availabilityGroups.length === 0"
        class="p-2 text-gray-500 text-sm"
      >
        Begin adding availabilities or deals below...
      </div>
    </template>
  </div>
</template>

<script setup>
import AvailabilityGroup from "@/components/space-usage-builder/AvailabilityGroup.vue";
import { useSpaceUsageBuilderStore } from "@/stores/spaceUsageBuilder";
import { useTimeTravelStore } from "@/stores/timeTravel";
import { storeToRefs } from "pinia";
import { ref, watch } from "vue";

const spaceUsageBuilderStore = useSpaceUsageBuilderStore();
const { spaceUsageBuilder, availabilityGroups } = storeToRefs(
  spaceUsageBuilderStore,
);
const timeTravelStore = useTimeTravelStore();
const { asOfDate } = storeToRefs(timeTravelStore);

const expanded = ref(true);

watch(asOfDate, (date, oldDate) => {
  if (oldDate && date !== oldDate) {
    spaceUsageBuilder.value.availabilityGroups = [];
  }
});
</script>
