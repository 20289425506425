<template>
  <!-- SUPPLY SIDE -->
  <template
    v-if="transactionContext === 'capMarkets' && transactionSide === 'supply'"
  >
    <div
      :data-test="`Investment-${investmentId(
        investment,
        dealBuilder,
      )}-capital-consumers`"
      class="col-span-1"
    >
      <dt class="text-sm font-medium text-gray-500">
        {{ supplySideLabel }}
      </dt>
      <dd v-if="capitalConsumers.length > 0" class="mt-1 space-y-2">
        <InvolvedCompany
          v-for="ownershipInterestDataField in capitalConsumers"
          :key="ownershipInterestDataField.fieldContentId"
          :decorating-content-data-field="ownershipInterestDataField"
          layer-type="OwnershipInterest"
          layer-color="bg-yellow-400"
          :providers-count="capitalConsumers.length"
          client-role="internalDispositions"
          :must-be-closed="mustBeClosed(investment)"
          context="investment-players"
          :fetch-milliseconds="fetchMilliseconds(investment, asOfMilliseconds)"
          :investment-id="investmentId(investment, dealBuilder)"
          :parent-component-save-function="persistCapitalConsumers"
          @refetch="fetchInvolvedCompanies"
          @override-refetch="fetchInvolvedCompanies"
        />
      </dd>
      <div
        v-else-if="addable && investment?.date && investment?.state"
        class="text-center"
      >
        <svg
          class="mx-auto h-8 w-8 text-gray-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M12.066 11.2a1 1 0 000 1.6l5.334 4A1 1 0 0019 16V8a1 1 0 00-1.6-.8l-5.333 4zM4.066 11.2a1 1 0 000 1.6l5.334 4A1 1 0 0011 16V8a1 1 0 00-1.6-.8l-5.334 4z"
          />
        </svg>
        <h3 class="mt-2 text-sm font-medium text-gray-900">
          No {{ supplySideLabel }}
        </h3>
        <p class="mt-1 text-sm text-gray-500">
          Rewind the timeline and add older deals.
        </p>
        <div v-if="canLink" class="mt-2 flex flex-col">
          <p class="text-sm text-gray-500 mb-2">Or, link to an older deal:</p>
          <ol class="flex flex-col space-y-2">
            <InvestmentLinkingControl
              v-for="investment in linkableInvestments"
              :key="investment?.id"
              :investment="investment"
              @linking="setLinking"
              @perform-link="linkInvestment"
            />
          </ol>
        </div>
      </div>
      <div v-else-if="addable" class="text-center">
        <svg
          class="mx-auto h-8 w-8 text-gray-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
        <h3 class="mt-2 text-sm font-medium text-gray-900">Timing unknown</h3>
        <p class="mt-1 text-sm text-gray-500">
          This investment needs a date and status.
        </p>
      </div>
    </div>
  </template>

  <template
    v-else-if="transactionContext === 'leasing' && transactionSide === 'supply'"
  >
    <div
      :data-test="`Availability-${availabilityId(
        availability,
        spaceUsageBuilder,
      )}-space-providers`"
      class="col-span-1"
    >
      <dt class="text-sm font-medium text-gray-500">
        {{ supplySideLabel }}
      </dt>
      <dd v-if="spaceProviders.length > 0" class="mt-1 space-y-2">
        <template
          v-if="
            spaceField(availability)?.fieldContentType &&
            _.includes(
              ['SpaceUsage', 'LayoutPartition'],
              spaceField(availability)?.fieldContentType,
            )
          "
        >
          <InvolvedCompany
            v-for="spaceUserDataField in spaceProviders"
            :key="spaceUserDataField.fieldContentId"
            :top-level-space-data-field="
              topLevelSpaceDataField(spaceObject, availability)
            "
            :decorating-content-data-field="spaceUserDataField"
            client-role="internalLeasing"
            context="space-usage-stack"
            :fetch-milliseconds="
              fetchMilliseconds(availability, asOfMilliseconds)
            "
            :availability-id="availabilityId(availability, spaceUsageBuilder)"
            @refetch="fetchInvolvedCompanies"
            @override-refetch="fetchInvolvedCompanies"
          />
        </template>
        <template v-else>
          <InvolvedCompany
            v-for="ownershipInterestDataField in spaceProviders"
            :key="ownershipInterestDataField.fieldContentId"
            :decorating-content-data-field="ownershipInterestDataField"
            layer-type="OwnershipInterest"
            layer-color="bg-yellow-400"
            :providers-count="spaceProviders.length"
            client-role="internalLeasing"
            :must-be-closed="mustBeClosed(availability)"
            context="availability-players"
            :fetch-milliseconds="
              fetchMilliseconds(availability, asOfMilliseconds)
            "
            :availability-id="availabilityId(availability, spaceUsageBuilder)"
            :parent-component-save-function="persistSpaceProviders"
            @refetch="fetchInvolvedCompanies"
            @override-refetch="fetchInvolvedCompanies"
          />
        </template>
      </dd>
      <div
        v-else-if="addable && availability.date && availability.state"
        class="text-center"
      >
        <svg
          class="mx-auto h-8 w-8 text-gray-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M12.066 11.2a1 1 0 000 1.6l5.334 4A1 1 0 0019 16V8a1 1 0 00-1.6-.8l-5.333 4zM4.066 11.2a1 1 0 000 1.6l5.334 4A1 1 0 0011 16V8a1 1 0 00-1.6-.8l-5.334 4z"
          />
        </svg>
        <h3 class="mt-2 text-sm font-medium text-gray-900">
          No {{ supplySideLabel }}
        </h3>
        <p class="mt-1 text-sm text-gray-500">Add an owner in the Deal Hub.</p>
      </div>
      <div v-else-if="addable" class="text-center">
        <svg
          class="mx-auto h-8 w-8 text-gray-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
        <h3 class="mt-2 text-sm font-medium text-gray-900">Timing unknown</h3>
        <p class="mt-1 text-sm text-gray-500">
          This availability needs a date and status.
        </p>
      </div>
    </div>
  </template>

  <!-- DEMAND SIDE -->
  <template
    v-if="transactionContext === 'capMarkets' && transactionSide === 'demand'"
  >
    <div
      :data-test="`Investment-${investmentId(
        investment,
        dealBuilder,
      )}-capital-providers`"
      class="col-span-1"
    >
      <dt class="flex items-center justify-between">
        <p class="text-sm font-medium text-gray-500">
          {{ demandSideLabel }}
        </p>
        <VDropdown
          v-if="displayInvestmentAwardedDropAction(investment, dealBuilder)"
        >
          <button
            type="button"
            v-tooltip="'Deal actions menu'"
            :disabled="originatingData"
            :data-test="`Investment-${investmentId(
              investment,
              dealBuilder,
            )}-players-deal-actions-menu`"
            class="inline-flex items-center shadow-sm p-1 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 duration-150"
          >
            <PulseLoader
              v-if="originatingData"
              :loading="true"
              size="3px"
              color="#f3f4f6"
            /><ArrowsRightLeftIcon v-else class="h-4 w-4" />
          </button>

          <template #popper>
            <DealActionDropdown
              :deal-builder-asset-data-field="assetObject.dataField"
              :local-asset-data-field="{}"
              :decorating-data-field="{}"
              :existing-investment-id="investmentId(investment, dealBuilder)"
              :providers-count="awardedCapitalProviders.length"
              layer-type="AwardedOwnershipInterest"
              context="awarded-capital-providers"
              @refetch-investment="refetchInvestment"
            />
          </template>
        </VDropdown>
      </dt>
      <dd v-if="capitalProviders.length > 0" class="mt-1 space-y-2">
        <InvolvedCompany
          v-for="ownershipInterestDataField in capitalProviders"
          :key="ownershipInterestDataField.fieldContentId"
          :decorating-content-data-field="ownershipInterestDataField"
          layer-type="OwnershipInterest"
          layer-color="bg-yellow-400"
          :providers-count="capitalProviders.length"
          client-role="internalAcquisitions"
          context="investment-players"
          :fetch-milliseconds="fetchMilliseconds(investment, asOfMilliseconds)"
          :investment-id="investmentId(investment, dealBuilder)"
          :parent-component-save-function="persistCapitalProviders"
          @refetch="fetchInvolvedCompanies"
          @override-refetch="fetchInvolvedCompanies"
        />
      </dd>
      <dd v-if="awardedCapitalProviders.length > 0" class="mt-1 space-y-2">
        <InvolvedCompany
          v-for="companyInvolvementDataField in awardedCapitalProviders"
          :key="companyInvolvementDataField.fieldContentId"
          :decorating-content-data-field="companyInvolvementDataField"
          layer-type="AwardedOwnershipInterest"
          layer-color="bg-orange-400"
          client-role="internalAcquisitions"
          context="investment-players"
          :fetch-milliseconds="fetchMilliseconds(investment, asOfMilliseconds)"
          :investment-id="investmentId(investment, dealBuilder)"
          :parent-component-save-function="persistCapitalProviders"
          @refetch="fetchInvolvedCompanies"
          @override-refetch="fetchInvolvedCompanies"
        />
      </dd>
      <div v-if="editingDemand" class="mt-1 flex flex-col space-y-1">
        <div
          class="border border-gray-300 rounded-lg shadow-sm focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500"
        >
          <CompanyContactAutocomplete
            label="Capital providers"
            :companies="localCapitalProviders(investment, dealBuilder)"
            :input-key="`investment-${investmentId(investment, dealBuilder)}`"
            @new-companies="setNewCompanies"
            @remove-company="removeCompany"
            @set-cross-interaction="setCrossInteraction"
          />
        </div>
        <div v-if="investment?.id" class="flex justify-end space-x-2">
          <button
            @click="cancelDemandEditing"
            type="button"
            class="inline-flex items-center p-1 border border-gray-300 rounded-full shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <XMarkIcon class="h-4 w-4" />
          </button>

          <DataVisibilityButton visibility="safezone" class="inline-flex">
            <template v-slot:button>
              <button
                @click="saveProviders"
                :disabled="originatingData"
                type="button"
                class="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-yellow-500 hover:bg-yellow-600 focus:ring-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2"
                data-test="investment-players-provider-save"
              >
                <PulseLoader
                  v-if="originatingData"
                  :loading="true"
                  size="3px"
                  color="#f3f4f6"
                />
                <CheckIcon v-else class="h-4 w-4" />
              </button>
            </template>
          </DataVisibilityButton>
        </div>
      </div>
      <dd
        v-else-if="localCapitalProviders(investment, dealBuilder).length > 0"
        class="mt-1 flex flex-wrap"
      >
        <li
          @click="editingDemand = true"
          v-for="company in localCapitalProviders(investment, dealBuilder)"
          :key="company"
          v-tooltip="'Unsaved'"
          class="inline-flex rounded-full items-center my-1 mr-1 py-0.5 px-2.5 text-sm font-medium bg-indigo-100 text-indigo-700"
        >
          {{ company.name || company }}
        </li>
      </dd>
      <div v-else-if="addable" class="mt-2 flex items-center space-x-2">
        <DataVisibilityButton visibility="safezone">
          <template v-slot:button>
            <button
              @click="editingDemand = true"
              type="button"
              class="group py-1 px-1.5 flex items-center justify-between rounded-full border border-gray-300 shadow-sm space-x-2 text-left hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <span class="min-w-0 flex-1 flex items-center space-x-1">
                <span class="flex-shrink-0 flex items-center">
                  <span
                    class="inline-flex items-center justify-center h-5 w-5 rounded-full bg-yellow-500"
                  >
                    <BriefcaseIcon class="h-4 w-4 text-white" />
                  </span>
                </span>
                <span class="min-w-0 flex-1">
                  <span class="text-sm font-medium text-gray-900 truncate"
                    >Add companies</span
                  >
                </span>
              </span>
            </button>
          </template>
        </DataVisibilityButton>
      </div>
    </div>
  </template>

  <template
    v-else-if="transactionContext === 'leasing' && transactionSide === 'demand'"
  >
    <div
      :data-test="`Availability-${availabilityId(
        availability,
        spaceUsageBuilder,
      )}-space-users`"
      class="col-span-1"
    >
      <dt class="flex items-center justify-between">
        <p class="text-sm font-medium text-gray-500">
          {{ demandSideLabel }}
        </p>
        <VDropdown
          v-if="displaySpaceAwardedDropAction(availability, spaceUsageBuilder)"
        >
          <button
            type="button"
            v-tooltip="'Space actions menu'"
            :disabled="originatingData"
            :data-test="`Availability-${availabilityId(
              availability,
              spaceUsageBuilder,
            )}-players-space-actions-menu`"
            class="inline-flex items-center shadow-sm p-1 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 duration-150"
          >
            <PulseLoader
              v-if="originatingData"
              :loading="true"
              size="3px"
              color="#f3f4f6"
            /><ArrowsRightLeftIcon v-else class="h-4 w-4" />
          </button>

          <template #popper>
            <SpaceUsageActionDropdown
              :top-level-space-data-field="
                topLevelSpaceDataField(spaceObject, availability)
              "
              :decorating-data-field="{}"
              :existing-availability-id="
                availabilityId(availability, spaceUsageBuilder)
              "
              layer-type="AwardedSpaceUser"
              context="awarded-space-users"
              @refetch-availability="refetchAvailability"
            />
          </template>
        </VDropdown>
      </dt>
      <dd v-if="spaceUsers.length > 0" class="mt-1 space-y-2">
        <InvolvedCompany
          v-for="userInvolvementDataField in spaceUsers"
          :key="userInvolvementDataField.fieldContentId"
          :top-level-space-data-field="
            topLevelSpaceDataField(spaceObject, availability)
          "
          :decorating-content-data-field="userInvolvementDataField"
          context="space-usage-stack"
          client-role="internalRealEstate"
          :fetch-milliseconds="
            fetchMilliseconds(availability, asOfMilliseconds)
          "
          :availability-id="availabilityId(availability, spaceUsageBuilder)"
          @refetch="fetchInvolvedCompanies"
          @override-refetch="fetchInvolvedCompanies"
        />
      </dd>
      <dd v-if="awardedSpaceUsers.length > 0" class="mt-1 space-y-2">
        <InvolvedCompany
          v-for="companyInvolvementDataField in awardedSpaceUsers"
          :key="companyInvolvementDataField.fieldContentId"
          :decorating-content-data-field="companyInvolvementDataField"
          layer-type="AwardedOwnershipInterest"
          layer-color="bg-orange-400"
          client-role="internalRealEstate"
          context="availability-players"
          :fetch-milliseconds="
            fetchMilliseconds(availability, asOfMilliseconds)
          "
          :availability-id="availabilityId(availability, spaceUsageBuilder)"
          :parent-component-save-function="persistSpaceUsers"
          @refetch="fetchInvolvedCompanies"
          @override-refetch="fetchInvolvedCompanies"
        />
      </dd>
      <div v-if="editingDemand" class="mt-1 flex flex-col space-y-1">
        <div
          class="border border-gray-300 rounded-lg shadow-sm focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500"
        >
          <CompanyContactAutocomplete
            label="Space users"
            :companies="localSpaceUsers(availability, spaceUsageBuilder)"
            :input-key="`availability-${availabilityId(
              availability,
              spaceUsageBuilder,
            )}`"
            @new-companies="setNewCompanies"
            @remove-company="removeCompany"
            @set-cross-interaction="setCrossInteraction"
          />
        </div>
        <div v-if="availability?.id" class="flex justify-end space-x-2">
          <button
            @click="cancelDemandEditing"
            type="button"
            class="inline-flex items-center p-1 border border-gray-300 rounded-full shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <XMarkIcon class="h-4 w-4" />
          </button>

          <DataVisibilityButton visibility="safezone" class="inline-flex">
            <template v-slot:button>
              <button
                @click="saveProviders"
                :disabled="originatingData"
                type="button"
                class="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-yellow-500 hover:bg-yellow-600 focus:ring-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2"
                data-test="availability-players-provider-save"
              >
                <PulseLoader
                  v-if="originatingData"
                  :loading="true"
                  size="3px"
                  color="#f3f4f6"
                />
                <CheckIcon v-else class="h-4 w-4" />
              </button>
            </template>
          </DataVisibilityButton>
        </div>
      </div>
      <dd
        v-else-if="localSpaceUsers(availability, spaceUsageBuilder).length > 0"
        class="mt-1 flex flex-wrap"
      >
        <li
          @click="editingDemand = true"
          v-for="company in localSpaceUsers(availability, spaceUsageBuilder)"
          :key="company"
          v-tooltip="'Unsaved'"
          class="inline-flex rounded-full items-center my-1 mr-1 py-0.5 px-2.5 text-sm font-medium bg-indigo-100 text-indigo-700"
        >
          {{ company.name || company }}
        </li>
      </dd>
      <div v-else-if="addable" class="mt-2 flex items-center space-x-2">
        <DataVisibilityButton visibility="safezone">
          <template v-slot:button>
            <button
              @click="editingDemand = true"
              type="button"
              class="group py-1 px-1.5 flex items-center justify-between rounded-full border border-gray-300 shadow-sm space-x-2 text-left hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <span class="min-w-0 flex-1 flex items-center space-x-1">
                <span class="flex-shrink-0 flex items-center">
                  <span
                    class="inline-flex items-center justify-center h-5 w-5 rounded-full bg-yellow-500"
                  >
                    <BriefcaseIcon class="h-4 w-4 text-white" />
                  </span>
                </span>
                <span class="min-w-0 flex-1">
                  <span class="text-sm font-medium text-gray-900 truncate"
                    >Add companies</span
                  >
                </span>
              </span>
            </button>
          </template>
        </DataVisibilityButton>
      </div>
    </div>
  </template>
</template>

<script setup>
import {
  XMarkIcon,
  CheckIcon,
  ArrowsRightLeftIcon,
} from "@heroicons/vue/20/solid";
import { BriefcaseIcon } from "@heroicons/vue/24/outline";
import InvolvedCompany from "@/components/crowdsourcing/InvolvedCompany.vue";
import DealActionDropdown from "@/components/deal-builder/DealActionDropdown.vue";
import SpaceUsageActionDropdown from "@/components/space-usage-builder/SpaceUsageActionDropdown.vue";
import DataVisibilityButton from "@/components/crowdsourcing/DataVisibilityButton.vue";
import CompanyContactAutocomplete from "@/components/crowdsourcing/CompanyContactAutocomplete.vue";
import InvestmentLinkingControl from "@/components/deal-builder/InvestmentLinkingControl.vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { useDealBuilderStore } from "@/stores/dealBuilder";
import { useSpaceUsageBuilderStore } from "@/stores/spaceUsageBuilder";
import { useTimeTravelStore } from "@/stores/timeTravel";
import { useCrowdsourcedChangeGroupStore } from "@/stores/crowdsourcedChangeGroup";
import { ref, computed, watch, onMounted } from "vue";
import decoratingAndFieldKey from "@/components/crowdsourcing/decoratingAndFieldKey";
import api from "@/router/api";
import moment from "moment";
import _ from "lodash";
import { storeToRefs } from "pinia";
import {
  investmentId,
  focalInvestment,
  localCapitalProviders,
  providersFetchRequestKey,
  awardedProvidersFetchRequestKey,
  displayInvestmentAwardedDropAction,
} from "@/assets/investmentHelpers";
import {
  spaceField,
  availabilityId,
  topLevelSpaceDataField,
  localSpaceUsers,
  usersFetchRequestKey,
  awardedUsersFetchRequestKey,
  displaySpaceAwardedDropAction,
} from "@/assets/availabilityHelpers";
import {
  awardable,
  mustBeClosed,
  groupId,
  fetchMilliseconds,
} from "@/assets/transactionHelpers";

const props = defineProps([
  "displayContext",
  "transactionContext",
  "transactionSide",
  "assetObject",
  "investment",
  "investmentGroupId",
  "spaceObject",
  "availability",
  "availabilityGroupId",
]);
const emit = defineEmits(["set-space-providers"]);

const dealBuilderStore = useDealBuilderStore();
const { dealBuilder } = storeToRefs(dealBuilderStore);
const spaceUsageBuilderStore = useSpaceUsageBuilderStore();
const { spaceUsageBuilder } = storeToRefs(spaceUsageBuilderStore);
const timeTravelStore = useTimeTravelStore();
const { asOfMilliseconds } = storeToRefs(timeTravelStore);
const changeGroupStore = useCrowdsourcedChangeGroupStore();
const { originatingData, changeGroupId } = storeToRefs(changeGroupStore);

const linking = ref(null);
const editingDemand = ref(false);
const capitalConsumers = ref([]);
const capitalProviders = ref([]);
const awardedCapitalProviders = ref([]);
const spaceProviders = ref([]);
const spaceUsers = ref([]);
const awardedSpaceUsers = ref([]);

const addable = computed(() => props.displayContext !== "table-row");

const persistCapitalConsumers = async (proofCompanies = null) => {
  const payload = {
    matchableRetiredOwnershipInterestFieldId: _.head(capitalConsumers.value)
      ?.localId,
    companies: proofCompanies,
    changeGroupId: changeGroupId.value,
  };

  const response = await api.post(`retired_ownership_interests`, payload);

  console.log(
    "investment players consumers persist",
    proofCompanies,
    payload,
    response,
  );

  return response;
};
const persistCapitalProviders = async (proofCompanies = null) => {
  const payload = {
    investmentId: props.investment?.id,
    companies:
      proofCompanies ||
      localCapitalProviders(props.investment, dealBuilder.value),
    changeGroupId: changeGroupId.value,
  };

  const response = await api.post(`investment_ownership_interests`, payload);

  console.log(
    "investment players providers persist",
    proofCompanies,
    payload,
    response,
  );

  return response;
};

const persistSpaceProviders = async (proofCompanies = null) => {
  const payload = {
    matchableRetiredOwnershipInterestFieldId: _.head(spaceProviders.value)
      ?.localId,
    companies: proofCompanies,
    changeGroupId: changeGroupId.value,
  };

  const response = await api.post(`retired_ownership_interests`, payload);

  console.log(
    "availability players consumers persist",
    proofCompanies,
    payload,
    response,
  );

  return response;
};
const persistSpaceUsers = async (proofCompanies = null) => {
  const payload = {
    availabilityId: props.availability.id,
    companies:
      proofCompanies ||
      localSpaceUsers(props.availability, spaceUsageBuilder.value),
    changeGroupId: changeGroupId.value,
  };

  const response = await api.post(`availability_space_users`, payload);

  console.log(
    "availability players providers persist",
    proofCompanies,
    payload,
    response,
  );

  return response;
};

const supplySideLabel = computed(() => {
  switch (props.transactionContext) {
    case "capMarkets":
      switch (
        focalInvestment(props.investment, dealBuilder.value)?.dealAction ||
        focalInvestment(props.investment, dealBuilder.value)?.investmentType
      ) {
        case "originateLoan":
        case "refinance":
        case "Loan":
          return "Borrowers";
        case "foreclosureSellOutright":
          return "Borrowers (foreclosure)";
        default:
          return "Sellers";
      }
    case "leasing":
      if (spaceField(props.availability)?.fieldContentType === "SpaceUsage") {
        return "Sublandlord";
      } else if (
        spaceField(props.availability)?.fieldContentType === "LayoutPartition"
      ) {
        return "Licensor";
      } else {
        return "Landlord";
      }
    default:
      return null;
  }
});
const demandSideLabel = computed(() => {
  switch (props.transactionContext) {
    case "capMarkets":
      switch (
        focalInvestment(props.investment, dealBuilder.value)?.dealAction ||
        focalInvestment(props.investment, dealBuilder.value)?.investmentType
      ) {
        case "originateLoan":
        case "refinance":
        case "Loan":
          return "Lenders";
        case "foreclosureSellOutright":
          return "Buyers (foreclosure)";
        default:
          return "Buyers";
      }
    case "leasing":
      if (spaceField(props.availability)?.fieldContentType === "SpaceUsage") {
        return "Subtenant";
      } else if (
        spaceField(props.availability)?.fieldContentType === "LayoutPartition"
      ) {
        return "Licensee";
      } else {
        return "Tenant";
      }
    default:
      return null;
  }
});
const canLink = computed(() => {
  return props.investment?.id && linkableInvestments.value.length > 0;
});
const linkableInvestments = computed(() => {
  return _.get(props.assetObject, "investments", []).filter((investment) => {
    const investmentContent = investment.fieldContent || investment;
    const matchingType =
      investmentContent.investmentType ===
      _.get(
        focalInvestment(props.investment, dealBuilder.value),
        "investmentType",
      );
    const eligibleDate =
      !investmentContent.date ||
      moment(investmentContent.date).isBefore(
        moment(
          _.get(focalInvestment(props.investment, dealBuilder.value), "date"),
        ),
      );

    return matchingType && eligibleDate;
  });
});

watch(
  () => investmentId(props.investment, dealBuilder.value),
  (id, oldId) => {
    if (id !== oldId) {
      reset();
      debouncedFetchCompanies();
    }
  },
);

watch(
  () => availabilityId(props.availability, spaceUsageBuilder.value),
  (id, oldId) => {
    if (id !== oldId) {
      reset();
      debouncedFetchCompanies();
    }
  },
);

onMounted(() => {
  debouncedFetchCompanies();
});

const debouncedFetchCompanies = _.debounce(function () {
  fetchInvolvedCompanies();
}, 500);

function setNewCompanies(newCompanies) {
  if (props.transactionContext === "capMarkets") {
    let newInvestment = _.merge({}, props.investment);

    newInvestment.capitalProviders = newCompanies;
    dealBuilderStore.updateStoreInvestment({
      assetObject: props.assetObject,
      existingInvestment: props.investment,
      newInvestment,
    });
  } else if (props.transactionContext === "leasing") {
    let newAvailability = _.merge({}, props.availability);

    newAvailability.spaceUsers = newCompanies;
    spaceUsageBuilderStore.updateStoreAvailability({
      spaceObject: props.spaceObject,
      existingAvailability: props.availability,
      newAvailability,
    });
  }
}
function removeCompany(company) {
  if (groupId(props.investment || props.availability)) {
    if (props.transactionContext === "capMarkets") {
      const combinedKey = props.investment?.temporaryId || props.investment?.id;
      dealBuilderStore.removeInvestmentGroupInvestmentPlayer({
        groupId: groupId(props.investment),
        investmentCombinedKey: combinedKey,
        toRemove: company,
        path: "capitalProviders",
        compareBy: function (c) {
          return `${c.name}`;
        },
      });
    } else if (props.transactionContext === "leasing") {
      const combinedKey =
        props.availability.temporaryId || props.availability.id;
      spaceUsageBuilderStore.removeAvailabilityGroupAvailabilityPlayer({
        groupId: groupId(props.availability),
        availabilityCombinedKey: combinedKey,
        toRemove: company,
        path: "spaceUsers",
        compareBy: function (c) {
          return `${c.name}`;
        },
      });
    }
  } else {
    let newCompanies = [];
    if (props.transactionContext === "capMarkets") {
      newCompanies = props.investment?.capitalProviders.filter(
        (c) => c.name !== company.name,
      );
    } else if (props.transactionContext === "leasing") {
      newCompanies = props.availability.spaceUsers.filter(
        (c) => c.name !== company.name,
      );
    }

    setNewCompanies(newCompanies);
  }
}

async function refetchInvestment() {
  await dealBuilderStore.refetchInvestment({
    assetObject: props.assetObject,
    existingInvestment: props.investment,
  });
  reset();
  debouncedFetchCompanies();
}

async function refetchAvailability() {
  await spaceUsageBuilderStore.refetchAvailability({
    spaceObject: props.spaceObject,
    existingAvailability: props.availability,
  });
  reset();
  debouncedFetchCompanies();
}

function clearDemandEditing() {
  if (groupId(props.investment || props.availability)) {
    if (props.transactionContext === "capMarkets") {
      localCapitalProviders(props.investment, dealBuilder.value).forEach(
        (company) => {
          removeCompany(company);
        },
      );
    } else if (props.transactionContext === "leasing") {
      localSpaceUsers(props.availability, spaceUsageBuilder.value).forEach(
        (company) => {
          removeCompany(company);
        },
      );
    }
  } else {
    if (props.transactionContext === "capMarkets") {
      let newInvestment = _.merge({}, props.investment);

      newInvestment.capitalProviders = [];
      dealBuilderStore.updateStoreInvestment({
        assetObject: props.assetObject,
        existingInvestment: props.investment,
        newInvestment: newInvestment,
      });
    } else if (props.transactionContext === "leasing") {
      let newAvailability = _.merge({}, props.availability);

      newAvailability.spaceUsers = [];
      spaceUsageBuilderStore.updateStoreAvailability({
        spaceObject: props.spaceObject,
        existingAvailability: props.availability,
        newAvailability,
      });
    }
  }
  cancelDemandEditing();
}

function cancelDemandEditing() {
  editingDemand.value = false;
}

function reset() {
  linking.value = null;
  capitalConsumers.value = [];
  capitalProviders.value = [];
  awardedCapitalProviders.value = [];
  spaceProviders.value = [];
  spaceUsers.value = [];
  awardedSpaceUsers.value = [];
  editingDemand.value = false;
}

function setLinking(id = null) {
  linking.value = id;
}

function setCrossInteraction() {}

async function linkInvestment() {
  await dealBuilderStore.linkInvestment(props.investment, linking.value);
}

async function saveProviders() {
  setTimeout(async () => {
    if (props.investment?.id) {
      const apiRequestFunc = () => persistCapitalProviders();
      const successCallback = async (json) => {
        clearDemandEditing();
        const requestKey = awardable(props.investment)
          ? awardedProvidersFetchRequestKey(props.investment)
          : providersFetchRequestKey(props.investment);
        await dealBuilderStore.postEditingPatch(json, requestKey);
        fetchInvolvedCompanies();
      };
      const failureCallback = () => clearDemandEditing();
      await changeGroupStore.originateData(
        apiRequestFunc,
        successCallback,
        failureCallback,
      );
    } else if (props.availability.id) {
      const apiRequestFunc = () => persistSpaceUsers();
      const successCallback = async (json) => {
        clearDemandEditing();
        const requestKey = awardable(props.availability)
          ? awardedUsersFetchRequestKey(props.availability)
          : usersFetchRequestKey(props.availability);
        await spaceUsageBuilderStore.postEditingPatch(json, requestKey);
        fetchInvolvedCompanies({ override: true });
      };
      const failureCallback = () => clearDemandEditing();
      await changeGroupStore.originateData(
        apiRequestFunc,
        successCallback,
        failureCallback,
      );
    }
  }, 125);
}

async function fetchInvolvedCompanies(maybePayload) {
  switch (props.transactionContext) {
    case "capMarkets":
      {
        const response = await dealBuilderStore.fetchPrincipalsFor({
          assetObject: props.assetObject,
          investment: props.investment,
          maybePayload,
        });

        capitalConsumers.value = response.capitalConsumers || [];
        capitalProviders.value = response.capitalProviders || [];
        awardedCapitalProviders.value = response.awardedCapitalProviders || [];

        if (response.emitMessage === "editingDemand") {
          editingDemand.value = true;
        }
      }
      break;
    case "leasing":
      {
        const response = await spaceUsageBuilderStore.fetchLeasingCompaniesFor({
          spaceObject: props.spaceObject,
          availability: props.availability,
          maybePayload,
        });

        spaceProviders.value = response.spaceProviders || [];
        spaceUsers.value = response.spaceUsers || [];
        awardedSpaceUsers.value = response.awardedSpaceUsers || [];

        if (response.emitMessage === "editingDemand") {
          editingDemand.value = true;
        }

        if (
          props.transactionSide === "supply" &&
          props.displayContext === "details-panel"
        ) {
          emit("set-space-providers", spaceProviders.value);
        }
      }
      break;
    default:
      console.log("unknown principal type", props.transactionContext);
  }
}
</script>
