<template>
  <div>
    <button
      v-if="interceptTask"
      @click="promptToSubscribe"
      class="px-1 py-0.5 rounded-md block text-left border-2 border-white text-sm font-medium text-gray-900 bg-gray-200 underline decoration-2 decoration-dashed decoration-gray-700"
    >
      <span>Subscribe to view</span>
    </button>
    <InlineReminderForm
      v-else-if="selectedTask && selectedTask.id === task.id"
      @saved="patchServerSideTasks"
    />
    <div v-else class="block hover:bg-gray-100">
      <div class="flex items-start p-2">
        <div class="flex min-w-0 flex-1 items-start">
          <button
            @click="toggleComplete(task)"
            type="button"
            :class="
              task.completedAt
                ? `${taskListStore.colorFor(groupedList, 'borderColor')}`
                : 'border-gray-500'
            "
            class="flex-shrink-0 flex items-center justify-center h-6 w-6 rounded-full border"
            :data-test="`task-${task.id}-search-result-complete-button`"
          >
            <div
              v-if="task.completedAt"
              :class="`${taskListStore.colorFor(groupedList, 'bgColor')}`"
              class="h-4 w-4 rounded-full"
            />
          </button>
          <div class="min-w-0 flex-1 px-4 space-y-0.5">
            <div
              @click="handleBodyClick(task)"
              class="truncate text-sm space-x-1"
            >
              <span
                v-if="task.priority !== 'none'"
                :class="taskListStore.colorFor(groupedList, 'textColor')"
                class="font-semibold"
                >{{ displayPriorityFor(task) }}</span
              >
              <span class="font-medium text-gray-600">{{ task.name }}</span>
            </div>
            <p
              @click="handleBodyClick(task)"
              class="flex items-center text-sm text-gray-500 whitespace-pre-line dont-break-out"
            >
              {{ task.note }}
            </p>
            <p
              v-if="task.dueDate"
              :class="dateColorFor(task)"
              class="flex items-center text-sm"
            >
              {{ displayDateFor(task)
              }}<template v-if="task.repeat !== 'never'"
                >,&nbsp;{{ displayRepeatFor(task)?.name }}</template
              >
            </p>
            <TaskContent v-if="task.contentType" :task="task" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useTaskListStore } from "@/stores/taskList";
import { useTasksStore } from "@/stores/tasks";
import { useUserStore } from "@/stores/user";
import { useModalStore } from "@/stores/modal";
import { useUnlockerStore } from "@/stores/unlocker";
import { useReminderStore } from "@/stores/reminder";
import { storeToRefs } from "pinia";
import { ref, computed, onMounted, watch, onBeforeUnmount } from "vue";
import moment from "moment";
import api from "@/router/api";
import subscribeInterceptor from "@/components/crowdsourcing/subscribeInterceptor";
import _ from "lodash";
import InlineReminderForm from "@/components/tasks/InlineReminderForm.vue";
import TaskContent from "@/components/tasks/TaskContent.vue";

const props = defineProps(["task", "groupedList"]);
const emit = defineEmits(["patch-server-side-tasks", "subscribe-refetch"]);
const reminderStore = useReminderStore();
const { repetitions } = storeToRefs(reminderStore);
const taskListStore = useTaskListStore();
const tasksStore = useTasksStore();
const { selectedTask } = storeToRefs(tasksStore);
const userStore = useUserStore();
const { signedIn } = storeToRefs(userStore);

const { resetRequired, upgradeSuccessful } = storeToRefs(useUnlockerStore());
const upgradeInProgress = ref(false);
const { modalPayload } = storeToRefs(useModalStore());
const interceptTask = computed(
  () => _.get(props.task, "meteringStatus") === "prompt_to_subscribe"
);

onMounted(() => {
  if (interceptTask.value && !modalPayload.value) {
    promptToSubscribe();
  }
});

function handleBodyClick(task) {
  edit(task);
}
function displayPriorityFor(task) {
  switch (task.priority) {
    case "low":
      return "!";
    case "medium":
      return "!!";
    case "high":
      return "!!!";
    default:
      return "";
  }
}
function momentDateFor(task) {
  if (signedIn.value) {
    return moment.unix(task.dueDate);
  } else {
    return moment(task.dueDate);
  }
}
function displayDateFor(task) {
  return momentDateFor(task).format("M/D/YY, h:mm a");
}
function displayRepeatFor(task) {
  return _.find(repetitions.value, { apiValue: task.repeat });
}
function dateColorFor(task) {
  if (momentDateFor(task).isBefore()) {
    return "text-red-500";
  } else {
    return "text-gray-500";
  }
}

function toggleComplete(task) {
  if (signedIn.value) {
    if (task.completedAt) {
      api.delete(`task_completions/${task.id}`).then((json) => {
        tasksStore.patchTasks([json.data]);
        userStore.fetchAvailableCollectibleCardCount();
      });
    } else {
      api.patch(`task_completions/${task.id}`).then((json) => {
        tasksStore.patchTasks([json.data]);
        userStore.fetchAvailableCollectibleCardCount();
      });
    }
  } else {
    const newCompletedAt = task.completedAt ? null : moment().valueOf();
    const newTask = _.merge({}, task, { completedAt: newCompletedAt });
    tasksStore.patchTasks([newTask]);
  }
}
function edit(task) {
  reminderStore.mountExisting(task);
  selectedTask.value = task;
}
function patchServerSideTasks(task) {
  emit("patch-server-side-tasks", task);
}

onBeforeUnmount(() => {
  if (upgradeInProgress.value) {
    upgradeSuccessful.value = false;
  }
});

watch(resetRequired, () => {
  if (upgradeInProgress.value) {
    resetRequired.value = false;
    upgradeInProgress.value = false;
  }
});

function promptToSubscribe() {
  if (interceptTask.value && !modalPayload.value) {
    upgradeInProgress.value = true;
    const successCallback = () => {
      if (upgradeSuccessful.value) {
        console.log("user subscribed");
        upgradeInProgress.value = false;
        upgradeSuccessful.value = false;
        emit("subscribe-refetch");
      }
    };

    subscribeInterceptor({
      apiRequestFunc: null,
      successCallback,
      modalPayloadRef: modalPayload,
      upgradeSuccessfulRef: upgradeSuccessful,
      afterSubscribe: "successCallback",
      promptReason: "dataUsage",
      context: "licensing",
    });
  }
}
</script>
