import { ref, computed, nextTick } from "vue";
import { defineStore, acceptHMRUpdate, storeToRefs } from "pinia";
import { usePropertyDiagramStore } from "@/stores/propertyDiagram";
import { useSpaceUsageBuilderStore } from "@/stores/spaceUsageBuilder";
import { useTimeTravelStore } from "@/stores/timeTravel";
import { usePropertyDiagramContentSelectionStore } from "@/stores/propertyDiagramContentSelection";
import { useCrowdsourcedChangeGroupStore } from "@/stores/crowdsourcedChangeGroup";
import api from "@/router/api";
import decoratingAndFieldKey from "@/components/crowdsourcing/decoratingAndFieldKey";
import _ from "lodash";

export const usePropertyDiagramActionsStore = defineStore(
  "propertyDiagramActions",
  () => {
    const actionInProgress = ref(false);
    const focalAction = ref(null);
    const propertyDiagramStore = usePropertyDiagramStore();
    const {
      editingMode,
      propertyDiagramActionTrigger,
      propertyDiagramEditAction,
      propertyDiagramSelected,
      propertySelectedRecordDataField,
      propertyDiagramSelectedPropertyId,
      propertyDiagramAdding,
      propertyDiagramAdded,
      propertyDiagramLastAdded,
      selectedPropertyIsAirLayer,
      propertyDataField,
      propertyDiagramPropertyIds,
    } = storeToRefs(propertyDiagramStore);
    const diagramContentSelectionStore =
      usePropertyDiagramContentSelectionStore();
    const spaceUsageBuilderStore = useSpaceUsageBuilderStore();
    const changeGroupStore = useCrowdsourcedChangeGroupStore();
    const { changeGroupId } = storeToRefs(changeGroupStore);
    const timeTravelStore = useTimeTravelStore();
    const { asOfMilliseconds } = storeToRefs(timeTravelStore);

    const editActionTempValue = computed(() => {
      return _.get(propertyDiagramEditAction.value, "tempValue", null);
    });
    const editActionModifier = computed(() => {
      return _.get(propertyDiagramEditAction.value, "modifier", null);
    });
    const resetModifier = computed(() => {
      return editActionModifier.value === "reset";
    });
    const saveModifier = computed(() => {
      return editActionModifier.value === "save";
    });
    const editActionType = computed(() => {
      return _.get(propertyDiagramEditAction.value, "action", null);
    });
    const additionOption = computed(() => {
      if (additionOptions.value.length > 0) {
        return _.head(additionOptions.value).id;
      } else {
        return null;
      }
    });
    const additionOptions = computed(() => {
      switch (propertyDiagramAdding.value.additionType) {
        case "landCovering":
          return landCoveringAdditionOptions.value;
        case "floor area":
          return floorAreaAdditionOptions.value;
        default:
          return [];
      }
    });
    const landCoveringAdditionOptions = computed(() => {
      // return this.landCoverings
      return []
        .filter((df) => {
          return _.get(df, "fieldContent.propertyRightIds", []).length === 0;
        })
        .map((df) => {
          return {
            id: _.get(df, "fieldContentId", null),
            name: _.get(df, "fieldContent.name", null),
          };
        });
    });
    const floorAreaAdditionOptions = computed(() => {
      // return this.floorAreas
      return []
        .filter((df) => {
          return (
            _.get(df, "fieldContent.propertyRightIds", []).length === 0 &&
            _.get(df, "fieldContent.landCoveringIds", []).length === 0 &&
            _.get(df, "fieldContent.landCoveringLevelIds", []).length === 0
          );
        })
        .map((df) => {
          return {
            id: _.get(df, "fieldContentId", null),
            name: _.get(df, "fieldContent.useTypes", []).join(", "),
          };
        });
    });

    function actionHandler() {
      if (propertyDiagramActionTrigger.value) {
        switch (propertyDiagramActionTrigger.value.action) {
          case "addFeeSimple":
            createRight("Fee simple");
            break;
          case "addGroundLease":
            addGroundLease();
            break;
          case "insertReciprocalEasement":
            insertReciprocalEasement();
            break;
          case "insertGroundLease":
            addGroundLease();
            break;
          case "insertSubleasehold":
            addGroundLease();
            break;
          case "insertFeeSimple":
            insertFeeSimple();
            break;
          case "insertAirRights":
            insertAirRights();
            break;
          case "addBuilding":
            addUnpopulated("landCovering", "Building");
            break;
          case "addSurfaceStructure":
            addUnpopulated("landCovering", "Surface Structure");
            break;
          case "addFloorArea":
            addUnpopulated("floor area");
            break;
          case "addPropertyEnhancement":
            addUnpopulated("property feature");
            break;
          case "batchEdit":
            addUnpopulated(null, "batch edit");
            break;
          case "saveBatchEdit":
            saveBatchEdit();
            break;
          case "addFeeSimpleBuilding":
            addPrepopulated("landCovering", "Building");
            break;
          case "addFeeSimpleSurfaceStructure":
            addPrepopulated("landCovering", "Surface Structure");
            break;
          case "addAirRightsFloorArea":
            addPrepopulated("floor area");
            break;
          case "addLandCoveringFloorArea":
            addPrepopulated("floor area");
            break;
          case "addLandCoveringLevelFloorArea":
            addPrepopulated("floor area");
            break;
          case "prepopulateLandCoveringGrouping":
            addPrepopulated("landCoveringGrouping");
            break;
          case "shiftLandCoveringLeft":
          case "shiftLandCoveringRight":
          case "addLandCoveringLevels":
          case "addVerticalGrouping":
          case "relocateLandCovering":
            setEditAction(
              propertyDiagramActionTrigger.value.action,
              "landCovering",
            );
            break;
          case "relocateCondoCoop":
          case "shiftFeeSimpleLeft":
          case "shiftFeeSimpleRight":
          case "shiftAirRightsLeft":
          case "shiftAirRightsRight":
            setEditAction(
              propertyDiagramActionTrigger.value.action,
              "propertyRight",
            );
            break;
          case "raiseGroundLevel":
          case "lowerGroundLevel":
          case "insertLandCoveringLevels":
            setEditAction(
              propertyDiagramActionTrigger.value.action,
              "landCoveringLevel",
            );
            break;
          case "addLandCoveringGrouping":
          case "relocateFloorArea":
          case "addVerticalOpenings":
            setEditAction(
              propertyDiagramActionTrigger.value.action,
              "floorArea",
            );
            break;
          case "verticalReorder":
            setEditAction(
              propertyDiagramActionTrigger.value.action,
              "landCovering",
              "landCovering",
            );
            break;
          case "floorAreaHorizontalReorder":
            floorAreaHorizontalReorder();
            break;
          case "landCoveringLevelHorizontalReorder":
            landCoveringLevelHorizontalReorder();
            break;
          case "horizontalAlign":
            horizontalAlign();
            break;
          case "splitFloorArea":
            splitFloorArea();
            break;
          case "addLandCoveringCondo":
            addLandCoveringCondoCoop("Condo");
            break;
          case "addLandCoveringCoop":
            addLandCoveringCondoCoop("Coop");
            break;
          case "addFloorAreaCondo":
            addFloorAreaCondoCoop("Condo");
            break;
          case "addFloorAreaCoop":
            addFloorAreaCondoCoop("Coop");
            break;
          case "addSpaceLayout":
            addLayout();
            break;
          case "saveHorizontalAlignment":
            saveHorizontalAlignment(propertyDiagramActionTrigger.value.payload);
            break;
          case "saveFloorAreaSplit":
            saveFloorAreaSplit(propertyDiagramActionTrigger.value.payload);
            break;
          case "saveLandCoveringLevels":
            saveLandCoveringLevels(propertyDiagramActionTrigger.value.payload);
            break;
          case "patchLandCoveringLevels":
            patchLandCoveringLevels(propertyDiagramActionTrigger.value.payload);
            break;
          case "cancelDiagramAdding":
            focalAction.value = null;
            clearAdding();
            break;
          case "placeDiagramCovering":
            placeCovering();
            break;
          case "saveEditAction":
            saveAction();
            break;
          default:
            console.log(
              "set up action for",
              propertyDiagramActionTrigger.value.action,
            );
        }
      }
    }

    function cancel(propertyId = null) {
      focalAction.value = null;
      clearAdding();
      if (
        propertyDiagramPropertyIds.value.length > 1 &&
        propertySelectedRecordDataField.value?.fieldContentType ===
          "Property" &&
        propertyDataField.value?.fieldContentId === propertyId
      ) {
        propertyDiagramStore.clearSelect(null);
      } else {
        propertyDiagramStore.clearSelect(propertyId);
      }
    }

    function clearEditActionModifier() {
      propertyDiagramEditAction.value = _.merge(
        {},
        propertyDiagramEditAction.value,
        { modifier: null },
      );
    }

    function clearAdding() {
      propertyDiagramAdding.value.propertyId = null;
      propertyDiagramAdding.value.additionType = null;
      propertyDiagramAdding.value.additionSubType = null;
      propertyDiagramAdding.value.existingId = null;
      propertyDiagramAdded.value = [];
      propertyDiagramLastAdded.value = null;
      propertyDiagramStore.clearEditAction();
    }

    function addUnpopulated(recordType, additionSubType = null) {
      clearAdding();
      propertyDiagramAdding.value.additionType = recordType;
      propertyDiagramAdding.value.additionSubType = additionSubType;
      propertyDiagramAdding.value.propertyId =
        propertyDiagramSelectedPropertyId.value;
      propertyDiagramActionTrigger.value = null;
    }

    function addPrepopulated(recordType, additionSubType = null) {
      propertyDiagramAdding.value.additionType = recordType;
      propertyDiagramAdding.value.additionSubType = additionSubType;
      propertyDiagramAdding.value.propertyId =
        propertyDiagramSelectedPropertyId.value;
      propertyDiagramLastAdded.value = _.get(
        propertyDiagramSelected.value,
        "dataField",
        null,
      );
      propertyDiagramAdded.value = diagramContentSelectionStore.fieldToAdd(
        _.get(propertyDiagramSelected.value, "dataField", null),
      );
      propertyDiagramActionTrigger.value = null;
    }
    function addLayout() {
      propertyDiagramEditAction.value = {
        action: `addLayout`,
        floorArea: _.get(propertyDiagramSelected.value, "dataField", null),
        modifier: null,
      };
      propertyDiagramLastAdded.value = _.get(
        propertyDiagramSelected.value,
        "dataField",
        null,
      );
      propertyDiagramAdded.value = diagramContentSelectionStore.fieldToAdd(
        _.get(propertyDiagramSelected.value, "dataField", null),
      );
      propertyDiagramActionTrigger.value = null;
    }
    function addLandCoveringCondoCoop(type) {
      const landCoveringDataField = _.get(
        propertyDiagramSelected.value,
        "dataField",
        null,
      );
      propertyDiagramEditAction.value = {
        action: `add${type}`,
        landCovering: landCoveringDataField,
        modifier: null,
      };
      propertyDiagramAdding.value.additionType = "landCovering";
      propertyDiagramAdding.value.additionSubType = _.get(
        landCoveringDataField,
        "fieldContent.type",
        null,
      );
      propertyDiagramAdding.value.propertyId =
        propertyDiagramSelectedPropertyId.value;
      propertyDiagramLastAdded.value = null;
      propertyDiagramAdded.value = diagramContentSelectionStore.fieldToAdd(
        _.get(propertyDiagramSelected.value, "dataField", null),
      );
      propertyDiagramAdding.value.additionType = null; // why?
      propertyDiagramActionTrigger.value = null;
    }
    function addFloorAreaCondoCoop(type) {
      const allLandCoverings = propertyDiagramStore.subTypedDataFields([
        "building",
        "surface_structure",
      ]);
      const landCoveringDataField = _.find(allLandCoverings, function (df) {
        const selectionBuildingId = _.get(
          propertyDiagramSelected.value,
          "landCovering.id",
          null,
        );

        return df.fieldContentId === selectionBuildingId;
      });
      propertyDiagramEditAction.value = {
        action: `add${type}`,
        landCovering: landCoveringDataField,
        floorArea: _.get(propertyDiagramSelected.value, "dataField", null),
        modifier: null,
      };
      propertyDiagramAdding.value.additionType = "floor area";
      propertyDiagramAdding.value.propertyId =
        propertyDiagramSelectedPropertyId.value;
      propertyDiagramLastAdded.value = _.get(
        propertyDiagramSelected.value,
        "dataField",
        null,
      );
      propertyDiagramAdded.value = diagramContentSelectionStore.fieldToAdd(
        _.get(propertyDiagramSelected.value, "dataField", null),
      );
      propertyDiagramAdding.value.additionType = null; // why?
      propertyDiagramActionTrigger.value = null;
    }
    function floorAreaHorizontalReorder() {
      let actionPayload = {
        action: "horizontalReorder",
        landCovering: _.get(
          propertyDiagramSelected.value,
          "landCovering",
          null,
        ),
        landCoveringLevel: _.get(
          propertyDiagramSelected.value,
          "landCoveringLevel",
          null,
        ),
        modifier: null,
      };

      propertyDiagramEditAction.value = actionPayload;
    }
    function landCoveringLevelHorizontalReorder() {
      let actionPayload = {
        action: "horizontalReorder",
        landCovering: _.get(
          propertyDiagramSelected.value,
          "landCovering",
          null,
        ),
        landCoveringLevel: _.get(
          propertyDiagramSelected.value,
          "dataField",
          null,
        ),
        modifier: null,
      };

      propertyDiagramEditAction.value = actionPayload;
    }
    function horizontalAlign() {
      let actionPayload = {
        action: "horizontalAlign",
        landCovering: _.get(
          propertyDiagramSelected.value,
          "landCovering",
          null,
        ),
        landCoveringLevel: _.get(
          propertyDiagramSelected.value,
          "dataField",
          null,
        ),
        modifier: null,
      };

      propertyDiagramEditAction.value = actionPayload;
    }
    function splitFloorArea() {
      let actionPayload = {
        action: "splitFloorArea",
        floorArea: _.get(propertyDiagramSelected.value, "dataField", null),
        modifier: null,
      };

      propertyDiagramEditAction.value = actionPayload;
    }
    function setEditAction(action, dataFieldKey, dataFieldPath = "dataField") {
      let actionPayload = {
        action,
        modifier: null,
      };
      actionPayload[dataFieldKey] = _.get(
        propertyDiagramSelected.value,
        dataFieldPath,
        null,
      );

      propertyDiagramEditAction.value = actionPayload;
    }

    async function placeCovering() {
      if (propertyDiagramAdded.value.length > 0) {
        const reversionPropertyId = propertyDiagramSelectedPropertyId.value;
        switch (propertyDiagramAdding.value.additionType) {
          case "property feature":
            await Promise.all([fillPropertyEnhancements()]);
            break;
          case "landCoveringGrouping":
            await Promise.all([createLandCoveringGrouping()]);
            break;
          default:
            await Promise.all([coverPropertyRights(), fillLandCoverings()]);
        }

        cancel(reversionPropertyId);
      }
    }

    async function saveBatchEdit() {
      switch (propertyDiagramActionTrigger.value.payload.batchEditType) {
        case "addAvailabilityGroup":
          batchAddAvailabilityGroup();
          break;
        case "combineFloorArea":
          batchCombineFloorArea();
          break;
        default:
          persistBatchEdit();
          break;
      }
    }

    async function batchCombineFloorArea() {
      const reversionPropertyId = propertyDiagramSelectedPropertyId.value;
      const payload = {
        reabsorbDate: propertyDiagramActionTrigger.value.payload.reabsorbDate,
        floorAreaDataFieldIds: propertyDiagramAdded.value.map(
          ({ localId }) => localId,
        ),
        changeGroupId: changeGroupId.value,
      };

      originateData(() => api.patch(`floor_area_combinations`, payload)).then(
        () => {
          cancel(reversionPropertyId);
          timeTravelStore.triggerRefetch();
        },
      );
    }

    async function batchAddAvailabilityGroup() {
      const reversionPropertyId = propertyDiagramSelectedPropertyId.value;
      let availabilityGroup = null;
      let initialSpace = null;
      let i = 0;

      for (const df of propertyDiagramAdded.value) {
        if (i === 0) {
          await spaceUsageBuilderStore.upsertSpaceUsageBuilder({
            spaceDataField: df,
          });
          availabilityGroup = spaceUsageBuilderStore.addAvailabilityGroup({
            spaceDataField: df,
            availabilityFieldContent: df,
          });
          initialSpace = df;
        } else if (availabilityGroup) {
          spaceUsageBuilderStore.addAvailabilityToGroup({
            existingAvailability: null,
            spaceDataField: df,
            availabilityFieldContent: df,
            availabilityGroupPlaceholderId: availabilityGroup.placeholderId,
          });
        }

        i++;
      }

      if (availabilityGroup) {
        spaceUsageBuilderStore.collapsePortfolioSpaces({
          groupId: availabilityGroup.id || availabilityGroup.placeholderId,
        });
        spaceUsageBuilderStore.setAvailabilityGroupExpanded({
          groupId: availabilityGroup.id || availabilityGroup.placeholderId,
          expanded: true,
        });
        await nextTick();

        spaceUsageBuilderStore.removeSpace(decoratingAndFieldKey(initialSpace));
      }

      cancel(reversionPropertyId);
    }

    function persistBatchEdit() {
      const reversionPropertyId = propertyDiagramSelectedPropertyId.value;
      const payload = {
        propertyId: propertyDiagramSelectedPropertyId.value,
        containers: propertyDiagramAdded.value.map((df) => {
          return {
            id: _.get(df, "fieldContentId", null),
            contentType: _.get(df, "fieldContentType", null),
          };
        }),
        batchEditType: propertyDiagramActionTrigger.value.payload.batchEditType,
        batchEditValue:
          propertyDiagramActionTrigger.value.payload.batchEditValue,
        changeGroupId: changeGroupId.value,
      };

      originateData(() => api.post(`datafield_batch_edits`, payload)).then(
        () => {
          cancel(reversionPropertyId);
        },
      );
    }

    function coverPropertyRights() {
      const propertyRightIds = _.compact(
        propertyDiagramAdded.value
          .filter((df) => {
            return _.get(df, "fieldContentType", null) === "PropertyRight";
          })
          .map((df) => _.get(df, "fieldContentId", null)),
      );
      const payload = {
        propertyId: propertyDiagramSelectedPropertyId.value,
        propertyRights: propertyRightIds.map((id) => {
          return { id };
        }),
        coveringContentType: propertyDiagramAdding.value.additionType,
        coveringContentSubType: propertyDiagramAdding.value.additionSubType,
        coveringContentId: additionOption.value,
        changeGroupId: changeGroupId.value,
      };

      return new Promise((resolve) => {
        if (propertyRightIds.length > 0) {
          originateData(() =>
            api.post(`property_right_coverings`, payload),
          ).then(() => {
            resolve();
          });
        } else {
          resolve();
        }
      });
    }

    function fillLandCoverings() {
      const landCoveringIds = _.compact(
        propertyDiagramAdded.value
          .filter((df) => {
            return _.get(df, "fieldContentType", null) === "LandCovering";
          })
          .map((df) => _.get(df, "fieldContentId", null)),
      );
      const landCoveringLevelIds = _.compact(
        propertyDiagramAdded.value
          .filter((df) => {
            return _.get(df, "fieldContentType", null) === "LandCoveringLevel";
          })
          .map((df) => _.get(df, "fieldContentId", null)),
      );
      const payload = {
        propertyId: propertyDiagramSelectedPropertyId.value,
        landCoverings: landCoveringIds.map((id) => {
          return { id };
        }),
        landCoveringLevels: landCoveringLevelIds.map((id) => {
          return { id };
        }),
        floorAreaId: additionOption.value,
        changeGroupId: changeGroupId.value,
      };

      return new Promise((resolve) => {
        if (landCoveringIds.length > 0 || landCoveringLevelIds.length > 0) {
          originateData(() =>
            api.post(`land_covering_floor_areas`, payload),
          ).then(() => {
            resolve();
          });
        } else {
          resolve();
        }
      });
    }

    function fillPropertyEnhancements() {
      const payload = {
        propertyId: propertyDiagramSelectedPropertyId.value,
        containers: propertyDiagramAdded.value.map((df) => {
          return {
            id: _.get(df, "fieldContentId", null),
            contentType: _.get(df, "fieldContentType", null),
          };
        }),
        changeGroupId: changeGroupId.value,
      };

      return new Promise((resolve) => {
        originateData(() => api.post(`property_enhancements`, payload)).then(
          () => {
            resolve();
          },
        );
      });
    }

    function createRight(propertyRightType) {
      const payload = {
        parentContentId: propertyDiagramSelectedPropertyId.value,
        parentContentType: "Property",
        propertyRightType,
        changeGroupId: changeGroupId.value,
        asOf: asOfMilliseconds.value,
      };

      originateData(() => api.post(`property_rights`, payload)).then(() => {});
    }
    function insertFeeSimple() {
      const payload = {
        parentContentId: propertyDiagramSelected.value.dataField.fieldContentId,
        parentContentType: "PropertyRight",
        propertyRightType: "Fee simple",
        changeGroupId: changeGroupId.value,
        asOf: asOfMilliseconds.value,
      };

      originateData(() => api.post(`property_rights`, payload));
    }
    function insertAirRights() {
      const payload = {
        parentContentId:
          propertyDiagramSelected.value.dataField.decoratingContentId,
        parentContentType: "PropertyRight",
        propertyRightType: "Unused Development Rights",
        changeGroupId: changeGroupId.value,
        asOf: asOfMilliseconds.value,
      };

      originateData(() => api.post(`property_rights`, payload));
    }
    function addGroundLease() {
      const payload = {
        parentContentId: propertyDiagramSelected.value.dataField.fieldContentId,
        parentContentType: "PropertyRight",
        propertyRightType: "Leasehold",
        changeGroupId: changeGroupId.value,
        asOf: asOfMilliseconds.value,
        propertyLayer: selectedPropertyIsAirLayer.value ? "air" : "ground",
      };

      originateData(() => api.post(`property_rights`, payload));
    }

    function insertReciprocalEasement() {
      const payload = {
        parentContentId: propertyDiagramSelectedPropertyId.value,
        parentContentType: "Property",
        propertyRightType: "Reciprocal Easement Agreement",
        subordinateFeeId:
          propertyDiagramSelected.value.dataField.fieldContentId,
        subordinateFeeDataFieldId:
          propertyDiagramSelected.value.dataField.localId,
        changeGroupId: changeGroupId.value,
        asOf: asOfMilliseconds.value,
      };

      originateData(() => api.post(`property_rights`, payload));
    }

    function saveFloorAreaSplit(payload) {
      const reversionPropertyId = propertyDiagramSelectedPropertyId.value;
      const combinedPayload = _.merge({}, payload, {
        floorAreaDataFieldId: _.get(
          propertyDiagramSelected.value,
          "dataField.localId",
          null,
        ),
        changeGroupId: changeGroupId.value,
      });

      originateData(() => api.patch(`floor_area_splits`, combinedPayload)).then(
        () => {
          cancel(reversionPropertyId);
          timeTravelStore.triggerRefetch();
        },
      );
    }

    function saveHorizontalAlignment(payload) {
      const combinedPayload = _.merge({}, payload, {
        landCoveringLevelId: _.get(
          propertyDiagramSelected.value,
          "dataField.fieldContentId",
          null,
        ),
        changeGroupId: changeGroupId.value,
      });

      originateData(() =>
        api.post(`land_covering_level_horizontal_alignments`, combinedPayload),
      ).then(() => {});
    }
    function saveLandCoveringLevels(payload) {
      const combinedPayload = _.merge({}, payload, {
        landCoveringId: _.get(
          propertyDiagramSelected.value,
          "dataField.fieldContentId",
          null,
        ),
        changeGroupId: changeGroupId.value,
      });

      originateData(() =>
        api.post(`land_covering_levels`, combinedPayload),
      ).then(() => {
        completed();
      });
    }
    function patchLandCoveringLevels(payload) {
      if (payload.levelsAbove + payload.levelsBelow > 0) {
        const currentLandCoveringLevelId = _.get(
          propertyDiagramSelected.value,
          "dataField.fieldContentId",
          null,
        );
        const combinedPayload = _.merge({}, payload, {
          currentLandCoveringLevelId,
          changeGroupId: changeGroupId.value,
        });

        originateData(() =>
          api.patch(
            `land_covering_levels/${currentLandCoveringLevelId}`,
            combinedPayload,
          ),
        ).then(() => {
          completed();
        });
      }
    }
    function saveAction() {
      const action = propertyDiagramEditAction.value.action;

      if (action === "relocateFloorArea") {
        saveRelocatedFloorArea();
      } else if (action === "relocateLandCovering") {
        saveRelocatedLandCovering();
      } else if (action === "relocateCondoCoop") {
        saveRelocatedInternalPropertyRight();
      } else if (action === "addLandCoveringGrouping") {
        createLandCoveringGrouping();
      } else if (action === "addVerticalGrouping") {
        createLandCoveringDiagramVerticalGrouping();
      } else if (action === "addVerticalOpenings") {
        createFloorAreaDownwardVerticalOpenings();
      } else if (action === "addLayout") {
        addFloorAreaLayout();
      } else if (_.includes(["addCondo", "addCoop"], action)) {
        saveCondoCoop();
      } else if (_.includes(["raiseGroundLevel", "lowerGroundLevel"], action)) {
        saveGroundLevelShift();
      } else if (
        _.includes(["shiftLandCoveringLeft", "shiftLandCoveringRight"], action)
      ) {
        saveLandCoveringShift();
      } else if (
        _.includes(["shiftFeeSimpleLeft", "shiftFeeSimpleRight"], action)
      ) {
        saveFeeSimpleShift();
      } else if (
        _.includes(["shiftAirRightsLeft", "shiftAirRightsRight"], action)
      ) {
        saveAirRightsShift();
      } else {
        propertyDiagramEditAction.value = _.merge(
          {},
          propertyDiagramEditAction.value,
          { modifier: "save" },
        );
      }
    }
    function completed() {
      propertyDiagramStore.clearSelect(propertyDiagramSelectedPropertyId.value);
    }
    function saveLandCoveringShift() {
      const payload = {
        containingContentId: _.get(
          propertyDiagramSelected.value,
          "dataField.decoratingContentId",
          null,
        ),
        containingContentType: "PropertyRight",
        orderables: reorderLandCoverings(),
        orderableType: "LandCovering",
        changeGroupId: changeGroupId.value,
      };

      originateData(() =>
        api.post(`land_covering_horizontal_shifts`, payload),
      ).then(() => {
        completed();
      });
    }
    function reorderLandCoverings() {
      const feeId = _.get(
        propertyDiagramSelected.value,
        "dataField.decoratingContentId",
        null,
      );
      const landCoveringId = _.get(
        propertyDiagramSelected.value,
        "dataField.fieldContentId",
        null,
      );
      const orderedLandCoveringsWrapper = _.find(
        propertyDiagramStore.feeSingleParcelLandCoveringOrderings(
          propertyDiagramSelectedPropertyId.value,
        ),
        { feeId },
      );
      const selectedLandCoveringOrder = _.find(
        orderedLandCoveringsWrapper.landCoverings,
        {
          coveringId: landCoveringId,
        },
      ).order;

      switch (propertyDiagramEditAction.value.action) {
        case "shiftLandCoveringLeft": {
          return orderedLandCoveringsWrapper.landCoverings.map(
            (landCoveringWithMeta) => {
              let newOrder;

              if (landCoveringWithMeta.order === selectedLandCoveringOrder) {
                newOrder = landCoveringWithMeta.order - 1;
              } else if (
                landCoveringWithMeta.order ===
                selectedLandCoveringOrder - 1
              ) {
                newOrder = landCoveringWithMeta.order + 1;
              } else {
                newOrder = landCoveringWithMeta.order;
              }

              return {
                id: landCoveringWithMeta.coveringId,
                order: newOrder,
                orderableType: "LandCovering",
              };
            },
          );
        }
        case "shiftLandCoveringRight": {
          return orderedLandCoveringsWrapper.landCoverings.map(
            (landCoveringWithMeta) => {
              let newOrder;

              if (landCoveringWithMeta.order === selectedLandCoveringOrder) {
                newOrder = landCoveringWithMeta.order + 1;
              } else if (
                landCoveringWithMeta.order ===
                selectedLandCoveringOrder + 1
              ) {
                newOrder = landCoveringWithMeta.order - 1;
              } else {
                newOrder = landCoveringWithMeta.order;
              }

              return {
                id: landCoveringWithMeta.coveringId,
                order: newOrder,
                orderableType: "LandCovering",
              };
            },
          );
        }
        default:
          return [];
      }
    }
    function reorderAirRights() {
      const selectedAirRightsOrder = _.get(
        propertyDiagramSelected.value,
        "dataField.order",
        null,
      );
      const airLayerPropertyId = propertyDiagramSelectedPropertyId.value;

      switch (propertyDiagramEditAction.value.action) {
        case "shiftAirRightsLeft": {
          return propertyDiagramStore
            .orderedAirRights(airLayerPropertyId)
            .map((df) => {
              const hasMultiParcelLandCoverings = _.includes(
                propertyDiagramStore.feeSimplesWithMultiParcelLandCoverings(
                  airLayerPropertyId,
                ),
                df.fieldContentId,
              );
              let newOrder;

              if (hasMultiParcelLandCoverings) {
                newOrder = null;
              } else if (df.order === selectedAirRightsOrder) {
                newOrder = df.order - 1;
              } else if (df.order === selectedAirRightsOrder - 1) {
                newOrder = df.order + 1;
              } else {
                newOrder = df.order;
              }

              return {
                id: df.fieldContentId,
                order: newOrder,
                orderableType: "PropertyRight",
              };
            });
        }
        case "shiftAirRightsRight": {
          return propertyDiagramStore
            .orderedAirRights(airLayerPropertyId)
            .map((df) => {
              const hasMultiParcelLandCoverings = _.includes(
                propertyDiagramStore.feeSimplesWithMultiParcelLandCoverings(
                  airLayerPropertyId,
                ),
                df.fieldContentId,
              );
              let newOrder;

              if (hasMultiParcelLandCoverings) {
                newOrder = null;
              } else if (df.order === selectedAirRightsOrder) {
                newOrder = df.order + 1;
              } else if (df.order === selectedAirRightsOrder + 1) {
                newOrder = df.order - 1;
              } else {
                newOrder = df.order;
              }

              return {
                id: df.fieldContentId,
                order: newOrder,
                orderableType: "PropertyRight",
              };
            });
        }
        default:
          return [];
      }
    }
    function reorderFeeSimples() {
      const selectedFeeSimpleOrder = _.get(
        propertyDiagramSelected.value,
        "dataField.order",
        null,
      );
      const loneEasement = propertyDiagramStore.hasLoneEasement(
        propertyDiagramSelectedPropertyId.value,
      );

      switch (propertyDiagramEditAction.value.action) {
        case "shiftFeeSimpleLeft": {
          return propertyDiagramStore
            .orderedFeeSimples(propertyDiagramSelectedPropertyId.value)
            .map((df) => {
              const hasReciprocalEasement = !!_.get(
                df,
                "fieldContent.superiorId",
                null,
              );
              const hasMultiParcelLandCoverings = _.includes(
                propertyDiagramStore.feeSimplesWithMultiParcelLandCoverings(
                  propertyDiagramSelectedPropertyId.value,
                ),
                df.fieldContentId,
              );
              let newOrder;

              if (
                (hasReciprocalEasement && !loneEasement) ||
                hasMultiParcelLandCoverings
              ) {
                newOrder = null;
              } else if (df.order === selectedFeeSimpleOrder) {
                newOrder = df.order - 1;
              } else if (df.order === selectedFeeSimpleOrder - 1) {
                newOrder = df.order + 1;
              } else {
                newOrder = df.order;
              }

              return {
                id: df.fieldContentId,
                order: newOrder,
                orderableType: "PropertyRight",
              };
            });
        }
        case "shiftFeeSimpleRight": {
          return propertyDiagramStore
            .orderedFeeSimples(propertyDiagramSelectedPropertyId.value)
            .map((df) => {
              const hasReciprocalEasement = !!_.get(
                df,
                "fieldContent.superiorId",
                null,
              );
              const hasMultiParcelLandCoverings = _.includes(
                propertyDiagramStore.feeSimplesWithMultiParcelLandCoverings(
                  propertyDiagramSelectedPropertyId.value,
                ),
                df.fieldContentId,
              );
              let newOrder;

              if (
                (hasReciprocalEasement && !loneEasement) ||
                hasMultiParcelLandCoverings
              ) {
                newOrder = null;
              } else if (df.order === selectedFeeSimpleOrder) {
                newOrder = df.order + 1;
              } else if (df.order === selectedFeeSimpleOrder + 1) {
                newOrder = df.order - 1;
              } else {
                newOrder = df.order;
              }

              return {
                id: df.fieldContentId,
                order: newOrder,
                orderableType: "PropertyRight",
              };
            });
        }
        default:
          return [];
      }
    }
    function saveFeeSimpleShift() {
      const payload = {
        containingContentId:
          propertyDiagramSelected.value.dataField.decoratingContentId,
        containingContentType:
          propertyDiagramSelected.value.dataField.decoratingContentType,
        orderables: reorderFeeSimples(),
        orderableType: "PropertyRight",
        changeGroupId: changeGroupId.value,
      };

      originateData(() =>
        api.post(`fee_simple_horizontal_shifts`, payload),
      ).then(() => {
        completed();
      });
    }
    function saveAirRightsShift() {
      const containingFeeId =
        propertyDiagramSelected.value.dataField.decoratingContentId;
      const payload = {
        containingContentId: containingFeeId,
        containingContentType: "PropertyRight",
        orderables: reorderAirRights(),
        orderableType: "PropertyRight",
        changeGroupId: changeGroupId.value,
      };

      originateData(() =>
        api.post(`air_rights_horizontal_shifts`, payload),
      ).then(() => {
        completed();
      });
    }
    function saveGroundLevelShift() {
      const payload = {
        direction:
          propertyDiagramEditAction.value.action === "raiseGroundLevel"
            ? "up"
            : "down",
        landCoveringId: propertyDiagramSelected.value.landCovering.id,
        changeGroupId: changeGroupId.value,
      };

      originateData(() => api.post(`ground_level_shifts`, payload)).then(() => {
        completed();
      });
    }
    function addFloorAreaLayout() {
      const reversionPropertyId = propertyDiagramSelectedPropertyId.value;
      const floorAreaIds = _.compact(
        propertyDiagramAdded.value
          .filter((df) => {
            return _.get(df, "fieldContentType", null) === "FloorArea";
          })
          .map((df) => _.get(df, "fieldContentId", null)),
      );

      const payload = {
        propertyId: propertyDiagramSelectedPropertyId.value,
        floorAreas: floorAreaIds.map((id) => {
          return { id };
        }),
        changeGroupId: changeGroupId.value,
      };

      originateData(() => api.post(`floor_area_layouts`, payload)).then(() => {
        cancel(reversionPropertyId);
      });
    }
    function saveCondoCoop() {
      console.log("save condo coop");
      // SINGLE PARCEL ONLY
      const rawFloorAreaIds = _.compact(
        propertyDiagramAdded.value
          .filter((df) => {
            return _.get(df, "fieldContentType", null) === "FloorArea";
          })
          .map((df) => _.get(df, "fieldContentId", null)),
      );
      const floorAreaIds = _.uniq(rawFloorAreaIds);
      const landCoveringIds = _.compact(
        propertyDiagramAdded.value
          .filter((df) => {
            return _.get(df, "fieldContentType", null) === "LandCovering";
          })
          .map((df) => _.get(df, "fieldContentId", null)),
      );
      const propertyRightType = _.includes(
        ["addCondo"],
        propertyDiagramEditAction.value.action,
      )
        ? "Condominium"
        : "Co-op";
      const reversionPropertyId = propertyDiagramSelectedPropertyId.value;

      const payload = {
        propertyId: propertyDiagramSelectedPropertyId.value,
        propertyRightType,
        landCoverings: landCoveringIds.map((id) => {
          return { id };
        }),
        floorAreas: floorAreaIds.map((id) => {
          return { id };
        }),
        changeGroupId: changeGroupId.value,
      };

      originateData(() => api.post(`unit_based_property_rights`, payload)).then(
        () => {
          cancel(reversionPropertyId);
        },
      );
    }
    function saveRelocatedFloorArea() {
      const landCoveringLevelIds = _.compact(
        propertyDiagramAdded.value
          .filter((df) => {
            return _.get(df, "fieldContentType", null) === "LandCoveringLevel";
          })
          .map((df) => _.get(df, "fieldContentId", null)),
      );
      const landCoveringIds = _.compact(
        propertyDiagramAdded.value
          .filter((df) => {
            return _.get(df, "fieldContentType", null) === "LandCovering";
          })
          .map((df) => _.get(df, "fieldContentId", null)),
      );

      const payload = {
        landCoveringLevels: landCoveringLevelIds.map((id) => {
          return { id };
        }),
        landCoverings: landCoveringIds.map((id) => {
          return { id };
        }),
        floorAreaId: propertyDiagramEditAction.value.floorArea.fieldContentId,
        changeGroupId: changeGroupId.value,
      };

      originateData(() => api.patch(`floor_area_relocations`, payload)).then(
        () => {
          completed();
        },
      );
    }
    function saveRelocatedLandCovering() {
      const propertyRightIds = _.compact(
        propertyDiagramAdded.value
          .filter((df) => {
            return _.get(df, "fieldContentType", null) === "PropertyRight";
          })
          .map((df) => _.get(df, "fieldContentId", null)),
      );

      const payload = {
        propertyRights: propertyRightIds.map((id) => {
          return { id };
        }),
        landCoveringId:
          propertyDiagramEditAction.value.landCovering.fieldContentId,
        changeGroupId: changeGroupId.value,
      };

      originateData(() => api.patch(`land_covering_relocations`, payload)).then(
        () => {
          completed();
        },
      );
    }
    function saveRelocatedInternalPropertyRight() {
      const reversionPropertyId = propertyDiagramSelectedPropertyId.value;
      const floorAreaIds = _.compact(
        propertyDiagramAdded.value
          .filter((df) => {
            return _.get(df, "fieldContentType", null) === "FloorArea";
          })
          .map((df) => _.get(df, "fieldContentId", null)),
      );

      const payload = {
        floorAreas: floorAreaIds.map((id) => {
          return { id };
        }),
        propertyRightId:
          propertyDiagramEditAction.value.propertyRight.fieldContentId,
        changeGroupId: changeGroupId.value,
      };

      originateData(() =>
        api.patch(`internal_property_right_relocations`, payload),
      ).then(() => {
        cancel(reversionPropertyId);
      });
    }
    function createFloorAreaDownwardVerticalOpenings() {
      const reversionPropertyId = propertyDiagramSelectedPropertyId.value;
      const landCoveringLevelIds = _.compact(
        propertyDiagramAdded.value
          .filter((df) => {
            return _.get(df, "fieldContentType", null) === "LandCoveringLevel";
          })
          .map((df) => _.get(df, "fieldContentId", null)),
      );

      const payload = {
        landCoveringLevels: landCoveringLevelIds.map((id) => {
          return { id };
        }),
        floorAreaId: propertyDiagramEditAction.value.floorArea.fieldContentId,
        changeGroupId: changeGroupId.value,
      };

      originateData(() =>
        api.post(`floor_area_downward_vertical_openings`, payload),
      ).then(() => {
        cancel(reversionPropertyId);
      });
    }
    function createLandCoveringGrouping() {
      const reversionPropertyId = propertyDiagramSelectedPropertyId.value;
      const landCoveringIds = _.compact(
        propertyDiagramAdded.value
          .filter((df) => {
            return _.get(df, "fieldContentType", null) === "LandCovering";
          })
          .map((df) => _.get(df, "fieldContentId", null)),
      );

      const payload = {
        propertyId: propertyDiagramSelectedPropertyId.value,
        landCoverings: landCoveringIds.map((id) => {
          return { id };
        }),
        changeGroupId: changeGroupId.value,
      };

      return new Promise((resolve) => {
        if (landCoveringIds.length > 0) {
          originateData(() =>
            api.post(`land_covering_groupings`, payload),
          ).then(() => {
            cancel(reversionPropertyId);
            resolve();
          });
        } else {
          resolve();
        }
      });
    }
    function createLandCoveringDiagramVerticalGrouping() {
      const reversionPropertyId = propertyDiagramSelectedPropertyId.value;
      const landCoveringLevelIds = _.compact(
        propertyDiagramAdded.value
          .filter((df) => {
            return _.get(df, "fieldContentType", null) === "LandCoveringLevel";
          })
          .map((df) => _.get(df, "fieldContentId", null)),
      );

      const payload = {
        landCoveringLevels: landCoveringLevelIds.map((id) => {
          return { id };
        }),
        landCoveringId:
          propertyDiagramEditAction.value.landCovering.fieldContentId,
        changeGroupId: changeGroupId.value,
      };

      originateData(() =>
        api.post(`land_covering_diagram_vertical_groupings`, payload),
      ).then(() => {
        cancel(reversionPropertyId);
      });
    }

    function originateData(apiRequestFunc) {
      if (editingMode.value) {
        const reversionPropertyId = propertyDiagramSelectedPropertyId.value;
        const successCallback = async (json) => {
          propertyDiagramStore.postEditingPatch(json);
          actionInProgress.value = false;
          focalAction.value = null;
        };
        const failureCallback = async () => {
          propertyDiagramStore.clearSelect(reversionPropertyId);
          actionInProgress.value = false;
          focalAction.value = null;
        };

        actionInProgress.value = true;

        return changeGroupStore.originateData(
          apiRequestFunc,
          successCallback,
          failureCallback,
        );
      } else {
        return false;
      }
    }

    return {
      actionInProgress,
      focalAction,
      editActionTempValue,
      editActionModifier,
      editActionType,
      resetModifier,
      saveModifier,
      actionHandler,
      clearEditActionModifier,
      originateData,
      cancel,
    };
  },
);

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(usePropertyDiagramActionsStore, import.meta.hot),
  );
}
