<template>
  <fieldset>
    <legend class="sr-only">Excluded Uses</legend>
    <div class="space-y-2">
      <div
        v-for="use in useTypesWithParking"
        :key="use.backEndKey"
        class="relative flex items-start"
      >
        <div class="flex h-6 items-center">
          <input
            :id="use.backEndKey"
            :aria-describedby="use.name"
            :name="use.backEndKey"
            :value="use.value"
            type="checkbox"
            v-model="selectedUses"
            class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
          />
        </div>
        <div class="ml-3 text-sm leading-6">
          <label
            :for="use.backEndKey"
            class="text-xs font-medium text-gray-900"
            >{{ use.name }}</label
          >
          {{ " " }}
          <span id="use-unit" class="text-xs text-gray-500"
            ><span class="sr-only">{{ use.measure }} </span
            >{{ use.measure }}</span
          >
        </div>
      </div>
    </div>
  </fieldset>
</template>

<script setup>
import { useAnalyzePanelStore } from "@/stores/analyzePanel";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import useTypes from "@/assets/useTypes";
import _ from "lodash";

defineProps(["optionData"]);

const analyzePanelStore = useAnalyzePanelStore();
const { calculationOptions } = storeToRefs(analyzePanelStore);

const selectedUses = computed({
  get() {
    return calculationOptions.value.excludedUses.useTypes;
  },
  set(newUses) {
    calculationOptions.value.excludedUses.useTypes = newUses;
  },
});

const useTypesWithParking = computed(() => {
  return _.concat(
    useTypes,
    {
      order: 13,
      name: "Common Area",
      value: "Common Area",
      frontEndKey: "commonArea",
      backEndKey: "common_area",
      measure: "SF",
      color: "amber",
    },
    {
      order: 14,
      name: "Mechanical",
      value: "Mechanical",
      frontEndKey: "mechanical",
      backEndKey: "mechanical",
      measure: "SF",
      color: "amber",
    },
    {
      order: 15,
      name: "Parking",
      value: "Parking",
      frontEndKey: "parking",
      backEndKey: "parking",
      measure: "Spaces",
      color: "sky",
    },
  );
});
</script>
