<template>
  <div
    v-observe-visibility="{ callback: selectiveFocus, once: true }"
    class="rounded-md border-l-2 p-1"
    :class="
      validStep
        ? 'border-yellow-500 bg-yellow-100'
        : 'border-red-500 bg-red-100'
    "
  >
    <div class="ml-2 py-1 pr-1 flex flex-col space-y-1">
      <div class="grid grid-cols-3 gap-2">
        <div>
          <label
            :for="`tenant-improvement-allowance-date`"
            class="block text-sm font-medium leading-6 text-gray-900"
            >Date</label
          >
          <div class="relative mt-1 rounded-md shadow-sm">
            <div class="absolute inset-y-0 left-0 flex items-center">
              <label
                :for="`tenant-improvement-allowance-date-type`"
                class="sr-only"
                >Date Type</label
              >
              <select
                @change="setDateType($event)"
                :id="`tenant-improvement-allowance-date-type`"
                :name="`tenant-improvement-allowance-date-type`"
                :autocomplete="`tenant-improvement-allowance-date-type`"
                class="h-full rounded-md border-0 bg-transparent py-0 pl-3 pr-7 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
              >
                <option value="Relative">Relative</option>
                <option value="Actual">Actual</option>
              </select>
            </div>
            <input
              v-if="dateType === 'Actual'"
              v-model="date"
              type="date"
              :name="`tenant-improvement-allowance-date`"
              :id="`tenant-improvement-allowance-date`"
              class="block w-full rounded-md border-0 py-1.5 pl-24 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
              :class="
                validDate ? 'focus:ring-yellow-600' : 'focus:ring-red-600'
              "
            />
            <template v-else>
              <div
                class="pointer-events-none absolute inset-y-0 left-24 flex items-center pl-1"
              >
                <span class="text-gray-500 sm:text-sm">Mth</span>
              </div>
              <input
                v-model="date"
                type="number"
                min="-36"
                step="1"
                :name="`tenant-improvement-allowance-date`"
                :id="`tenant-improvement-allowance-date`"
                class="block w-full rounded-md border-0 py-1.5 pl-36 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                :class="
                  validDate ? 'focus:ring-yellow-600' : 'focus:ring-red-600'
                "
                placeholder="Month number"
              />
            </template>
          </div>
        </div>
        <div class="">
          <label
            :for="`tenant-improvement-allowance-input-type`"
            class="block text-sm font-medium leading-6 text-gray-500"
            >Type</label
          >
          <div class="mt-1">
            <select
              v-model="inputType"
              :name="`tenant-improvement-allowance-input-type`"
              :id="`tenant-improvement-allowance-input-type`"
              class="block w-full shadow-sm pl-3 pr-10 py-2 text-gray-500 border-gray-300 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 text-sm rounded-md"
            >
              <option
                v-for="option in inputTypes"
                :key="option.value"
                :value="option.value"
              >
                {{ option.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="">
          <label
            :for="`tenant-improvement-allowance-amount`"
            class="block text-sm font-medium leading-6 text-gray-500"
            >{{
              inputType === "annualPercentageIncrease" ? "%" : "$"
            }}
            Amount</label
          >
          <div class="mt-1">
            <input
              v-model="inputValue"
              type="number"
              :name="`tenant-improvement-allowance-amount`"
              :id="`tenant-improvement-allowance-amount`"
              class="block w-full rounded-md border-0 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
              :class="
                validInput ? 'focus:ring-yellow-600' : 'focus:ring-red-600'
              "
            />
          </div>
        </div>
        <p
          v-if="validationHelp"
          class="col-span-3 text-sm text-red-600"
          id="step-error"
        >
          {{ validationHelp }}
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useCashflowDefinitionStore } from "@/stores/cashflowDefinition";
import { computed, watch } from "vue";
import _ from "lodash";
import dateValid from "@/assets/dateValid";
import moment from "moment";
import { storeToRefs } from "pinia";

const props = defineProps(["dateFields", "perAreaSize"]);

const cashflowDefinitionStore = useCashflowDefinitionStore();
const { tenantImprovementAllowance } = storeToRefs(cashflowDefinitionStore);

const inputValue = computed({
  get() {
    return tenantImprovementAllowance.value?.inputValue;
  },
  set(n) {
    if (tenantImprovementAllowance.value) {
      tenantImprovementAllowance.value.inputValue = n;
    }
  },
});
const inputType = computed({
  get() {
    return tenantImprovementAllowance.value?.inputType;
  },
  set(n) {
    if (tenantImprovementAllowance.value) {
      tenantImprovementAllowance.value.inputType = n;
    }
  },
});
const dateType = computed({
  get() {
    return tenantImprovementAllowance.value?.dateType;
  },
  set(newType) {
    if (tenantImprovementAllowance.value) {
      tenantImprovementAllowance.value.dateType = newType;
    }
  },
});
function setDateType(event) {
  const newDateType = event.target.value;

  if (newDateType === "Relative") {
    date.value = 1;
  } else {
    date.value = null;
  }

  dateType.value = newDateType;
}
const date = computed({
  get() {
    return tenantImprovementAllowance.value?.date;
  },
  set(n) {
    if (tenantImprovementAllowance.value) {
      tenantImprovementAllowance.value.date = n;
    }
  },
});
const previousDate = computed(() => {
  return props.dateFields.commenced_date?.fieldDate
    ? moment(props.dateFields.commenced_date.fieldDate)
    : null;
});
const dateTooEarly = computed(() => {
  return moment(previousDate.value).isAfter(moment(date.value), "day");
});
const validDate = computed(() => {
  if (dateType.value === "Actual") {
    return dateValid(date.value) && !dateTooEarly.value;
  } else if (dateType.value === "Relative") {
    return _.isNumber(date.value) && date.value >= -36;
  } else {
    return false;
  }
});
const validInput = computed(() => {
  if (inputValue.value) {
    switch (inputType.value) {
      case "annualPerArea":
        return inputValue.value <= 10_000;
      case "annual":
        return inputValue.value >= 100;
      default:
        return true;
    }
  } else {
    return false;
  }
});
const validStep = computed(() => validDate.value && validInput.value);
const validationHelp = computed(() => {
  if (validStep.value) {
    return null;
  } else if (!validDate.value) {
    if (dateType.value === "Relative") {
      return "Date must be a month number";
    } else if (dateTooEarly.value) {
      return "Date must be later than the LCD";
    } else {
      return "Date invalid";
    }
  } else if (!validInput.value) {
    if (inputValue.value) {
      switch (inputType.value) {
        case "annualPerArea":
          return "Amount should be less than $10,000";
        case "annual":
          return "Amount should be greater than $100";
        default:
          return "Unknown amount error";
      }
    } else {
      return "Please enter a dollar amount";
    }
  } else {
    return "Unknown error";
  }
});
const inputTypes = computed(() =>
  _.compact([
    { name: "$/Yr", value: "annual" },
    props.perAreaSize ? { name: "$/SF/Yr", value: "annualPerArea" } : null,
  ]),
);

watch(validStep, () => {
  if (tenantImprovementAllowance.value) {
    tenantImprovementAllowance.value.valid = validStep.value;
  }
});

function selectiveFocus(isVisible) {
  if (isVisible) {
    setTimeout(() => {
      selectInput();
    }, 100);
  }
}
function selectInput() {
  document.getElementById(`tenant-improvement-allowance-date-type`)?.focus();
}
</script>
