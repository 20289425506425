<template>
  <section aria-labelledby="capital_stack">
    <ContentHeader :help-article="understandTheCapitalStack">
      <template v-slot:title>
        <span>Capital Stack</span>
        <span class="flex items-center">
          <CalendarDaysIcon
            class="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400"
          />
          <p>
            as of
            <time :datetime="asOfDate">{{
              moment(asOfDate).format("MMM YYYY")
            }}</time>
          </p>
        </span>
      </template>
      <template v-slot:description
        >Layers of funding ordered by their rights to the cash flow.</template
      >
    </ContentHeader>

    <!-- Current capital stack -->
    <CapitalStackDiagram
      :property-id="dataField.decoratingContentId"
      :data-field="dataField"
      class="px-2"
    />
  </section>
</template>

<script setup>
import { CalendarDaysIcon } from "@heroicons/vue/20/solid";
import ContentHeader from "@/components/main-layout/secondary-panel/ContentHeader.vue";
import CapitalStackDiagram from "@/components/deal-builder/CapitalStackDiagram.vue";
import { useTimeTravelStore } from "@/stores/timeTravel";
import { understandTheCapitalStack } from "@/assets/documentation/articles/understandTheCapitalStack";
import { storeToRefs } from "pinia";
import moment from "moment";

defineProps(["dataField"]);

const timeTravelStore = useTimeTravelStore();
const { asOfDate } = storeToRefs(timeTravelStore);
</script>
