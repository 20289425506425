import _ from "lodash";

export default function fullyUnlocked(fields) {
  if (fields.length === 0) {
    return true;
  } else {
    return _.every(fields, function (field) {
      return !unlockable(field);
    });
  }
}

function unlockable(field) {
  const rawPrice = _.get(field, "price");
  const price = rawPrice ? _.toNumber(rawPrice) : null;

  return (unstaked(field) && !safezone(field)) || (price && price > 0);
}
function unstaked(field) {
  return _.get(field, "state") === "unstaked";
}
function safezone(field) {
  return _.get(field, "safezone");
}
