<template>
  <div class="mt-4 p-2">
    <div class="flex items-center">
      <div class="flex-auto">
        <h1 class="text-base font-semibold leading-6 text-gray-900">
          Properties & Buildings
        </h1>
        <p class="mt-2 text-sm text-gray-700">
          Expand multi-building property rows to see related buildings.
        </p>
      </div>
    </div>
    <div v-if="zoom >= propertyZoomTrigger" class="mt-4 flow-root">
      <div
        v-if="refreshing"
        class="mt-6 flex h-full w-full items-center justify-center"
      >
        <SquareLoader :loading="true" size="32px" color="#0d9488" />
      </div>
      <div
        v-else-if="paginatedNearbyProperties.length > 0"
        class="-my-2 overflow-x-auto"
      >
        <div class="inline-block min-w-full py-2 align-middle">
          <table class="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th scope="col" class="relative whitespace-nowrap p-3">
                  <span class="sr-only">Expand</span>
                </th>
                <th
                  scope="col"
                  class="whitespace-nowrap py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
                >
                  Name
                </th>
                <th
                  scope="col"
                  class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Area / Status
                </th>
                <th
                  v-if="false"
                  scope="col"
                  class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Built
                </th>
                <th
                  scope="col"
                  class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Size
                </th>
                <th
                  v-if="false"
                  scope="col"
                  class="relative whitespace-nowrap py-3.5 pl-3"
                >
                  <span class="sr-only">Edit</span>
                </th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-300 bg-white">
              <PropertyTableRow
                v-for="dataField in paginatedNearbyProperties"
                :key="dataField.localId"
                :location-data-field="dataField"
                :include-air-rights="includeAirRights"
              />
            </tbody>
            <InfiniteLoading
              v-if="loaded && !reachedEnd"
              @infinite="infiniteNext"
            />
          </table>
        </div>
      </div>
      <div
        v-else
        class="relative block w-full rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        <FunnelIcon class="mx-auto h-12 w-12 text-gray-400" />
        <span class="mt-2 block text-sm font-medium text-gray-900">
          No matching properties
        </span>
      </div>
    </div>
    <section v-else class="mt-4">
      <div
        class="relative block w-full rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        <MagnifyingGlassPlusIcon class="mx-auto h-12 w-12 text-gray-400" />
        <span class="mt-2 block text-sm font-medium text-gray-900">
          Zoom in more
        </span>
      </div>
    </section>
  </div>
</template>

<script setup>
import SquareLoader from "vue-spinner/src/SquareLoader.vue";
import PropertyTableRow from "@/components/analyze/PropertyTableRow.vue";
import { MagnifyingGlassPlusIcon, FunnelIcon } from "@heroicons/vue/24/outline";
import { useMainMapStore } from "@/stores/mainMap";
import { useAnalyzePanelStore } from "@/stores/analyzePanel";
import { storeToRefs } from "pinia";
import { computed, onMounted, ref, watch } from "vue";
import _ from "lodash";

const analyzePanelStore = useAnalyzePanelStore();
const {
  checkedCategories,
  combinedFilteredPropertyIds,
  lastCheckedPolygonArea,
  calculationOptions,
  contextedPropertiesArray,
} = storeToRefs(analyzePanelStore);
const mapStore = useMainMapStore();
const { zoom, propertyZoomTrigger, refreshing } = storeToRefs(mapStore);

const includeAirRights = computed(
  () => calculationOptions.value.sizesUses.includeAirRights,
);
const nearbyPageIncrement = 10;
const fetchedNearbyIndex = ref(0);
const loaded = ref(false);
const reachedEnd = ref(false);
const paginatedNearbyProperties = ref([]);
const nearbyFilteredProperties = computed(() => {
  if (checkedCategories.value.length > 0) {
    return contextedPropertiesArray.value.filter((dataField) => {
      return _.includes(
        combinedFilteredPropertyIds.value,
        dataField.joiningContentId,
      );
    });
  } else {
    return contextedPropertiesArray.value;
  }
});

watch(refreshing, (bool, oldBool) => {
  if (loaded.value && !bool && oldBool) {
    debouncedReset();
  }
});

watch(lastCheckedPolygonArea, () => {
  if (loaded.value) {
    debouncedReset();
  }
});

watch(
  combinedFilteredPropertyIds,
  () => {
    if (checkedCategories.value.length > 0 && loaded.value) {
      debouncedReset();
    }
  },
  { deep: true },
);

onMounted(() => {
  if (fetchedNearbyIndex.value === 0) {
    nextNearbyPage("initial");
  }
});

function reset() {
  paginatedNearbyProperties.value = [];
  fetchedNearbyIndex.value = 0;
  reachedEnd.value = false;
  nextNearbyPage();
}

const debouncedReset = _.debounce(function () {
  reset();
}, 2000);

const infiniteNext = async ($state) => {
  if (!reachedEnd.value) {
    try {
      nextNearbyPage();
    } catch (error) {
      $state.error();
    }
  } else {
    $state.complete();
  }
};

function nextNearbyPage(loadState = null) {
  const arr = nearbyFilteredProperties.value;
  const length = arr.length;
  const start = fetchedNearbyIndex.value;
  const end = _.min([length, fetchedNearbyIndex.value + nearbyPageIncrement]);
  const newSlice = _.slice(arr, start, end);

  newSlice.forEach((element) => {
    paginatedNearbyProperties.value.push(element);
  });

  if (paginatedNearbyProperties.value.length !== length) {
    fetchedNearbyIndex.value = fetchedNearbyIndex.value + nearbyPageIncrement;
  } else {
    reachedEnd.value = true;
  }

  if (loadState === "initial") loaded.value = true;
}
</script>
