<template>
  <div
    :data-test="`${decoratingAndFieldKey(
      dataField,
    )}-space-usage-stack-diagram-container`"
  >
    <div
      :class="
        workspaceLayout === 'topAndBottom'
          ? 'grid grid-cols-2 gap-3 grid-flow-row-dense'
          : ''
      "
      class=""
    >
      <button
        v-if="!horizontalIsSpaces"
        @click="buildSpaces"
        type="button"
        :class="workspaceLayout === 'topAndBottom' ? 'col-span-2' : ''"
        class="w-full text-sm inline-flex justify-center rounded-md border border-indigo-300 shadow-sm px-4 py-2 bg-white font-medium text-indigo-700 hover:bg-indigo-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        View Space Hub
      </button>
    </div>
    <ol
      v-if="refreshed"
      role="space_usage_stack"
      :class="hasButton ? 'mt-6' : ''"
      class="grid grid-cols-1"
    >
      <SpaceUsageLayer
        :property-id="propertyId"
        :top-level-space-data-field="topLevelSpaceDataField || dataField"
        :data-field="dataField"
        :future="future"
        :depth="6"
      />
    </ol>
  </div>
</template>

<script setup>
import SpaceUsageLayer from "@/components/space-usage-builder/SpaceUsageLayer.vue";
import { useTimeTravelStore } from "@/stores/timeTravel";
import { useSpaceUsageBuilderStore } from "@/stores/spaceUsageBuilder";
import { usePropertyDiagramStore } from "@/stores/propertyDiagram";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { storeToRefs } from "pinia";
import { ref, computed, watch, nextTick } from "vue";
import decoratingAndFieldKey from "@/components/crowdsourcing/decoratingAndFieldKey";
import _ from "lodash";
import { useRoute, useRouter } from "vue-router";

const props = defineProps([
  "propertyId",
  "dataField",
  "topLevelSpaceDataField",
  "future",
]);

const layoutStore = useWorkspaceLayoutStore();
const { workspaceLayout } = storeToRefs(layoutStore);
const timeTravelStore = useTimeTravelStore();
const { asOfDate } = storeToRefs(timeTravelStore);
const propertyDiagramStore = usePropertyDiagramStore();
const { propertyDiagramPropertyIds } = storeToRefs(propertyDiagramStore);
const spaceUsageBuilderStore = useSpaceUsageBuilderStore();
const { spaceUsageBuilder, spaceFieldIds } = storeToRefs(
  spaceUsageBuilderStore,
);

const refreshed = ref(true);
const hasButton = computed(() => {
  return !spaceUsageBuilder.value;
});

watch(asOfDate, (date, oldDate) => {
  if (oldDate && date !== oldDate) {
    spaceUsageBuilderStore.clearBuilder(false);
    refetchDiagram();
  }
});

async function refetchDiagram() {
  console.log("refetch space diagram");
  refreshed.value = false;

  await nextTick();

  refreshed.value = true;
}

const router = useRouter();
const route = useRoute();
const query = computed(() => route.query);
const horizontalIsSpaces = computed(
  () => _.get(query.value, "horizontalTab") === "Spaces",
);

async function buildSpaces() {
  spaceUsageBuilderStore.upsertSpaceUsageBuilder({
    spaceDataField: props.dataField,
  });
  await nextTick();
  router.push({
    name: route.name,
    query: {
      ...route.query,
      horizontalTab: "Spaces",
      verticalTab: undefined,
      spaceFieldIds: spaceFieldIds.value,
    },
  });

  if (
    props.dataField.joiningContentType === "Property" ||
    props.dataField.fieldContent?.propertyId
  ) {
    const propertyId =
      props.dataField.joiningContentType === "Property"
        ? props.dataField.joiningContentId
        : props.dataField.fieldContent?.propertyId;
    if (!_.includes(propertyDiagramPropertyIds.value, propertyId)) {
      propertyDiagramStore.addPropertyToDiagram(propertyId);
    }
  }
}
</script>
