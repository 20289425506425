<template>
  <div
    :id="`controlling-asset-${assetKey}-contacts`"
    class="bg-white overflow-visible shadow rounded-lg divide-y divide-gray-200"
  >
    <div
      :class="expanded ? 'rounded-t-lg' : 'rounded-lg'"
      class="flex items-center justify-between overflow-hidden bg-yellow-300 p-2"
    >
      <div class="text-gray-700 font-semibold uppercase tracking-wide">
        {{ headerLabel }}
      </div>
      <div class="flex items-center space-x-2">
        <button
          @click="expanded = !expanded"
          v-tooltip.left="expanded ? 'Minimize' : 'Expand'"
          type="button"
          class="h-5 w-5 p-1 inline-flex justify-center items-center text-sm text-gray-700 hover:text-gray-800"
        >
          <i v-if="expanded" class="fas fa-compress" />
          <i v-else class="fas fa-expand" />
        </button>
      </div>
    </div>

    <template v-if="expanded">
      <div
        class="bg-gray-100 p-2 text-gray-700 text-xs font-semibold uppercase tracking-wide"
      >
        Owners
      </div>
      <div v-if="owners.length > 0" class="space-y-2">
        <div
          v-for="ownershipInterestDataField in owners"
          :key="ownershipInterestDataField.fieldContentId"
          class="p-2 space-y-2"
        >
          <InvolvedCompany
            :deal-builder-asset-data-field="dataField"
            :local-asset-data-field="dataField"
            :decorating-content-data-field="ownershipInterestDataField"
            layer-type="OwnershipInterest"
            layer-color="bg-yellow-400"
            :providers-count="owners.length"
            context="controlling-asset"
            :fetch-milliseconds="asOfMilliseconds"
            :parent-component-save-function="
              (proofCompanies = null) =>
                persistCompanies(proofCompanies, ownershipInterestDataField)
            "
            @refetch="refetch"
            @override-refetch="refetch"
          />
          <div class="space-y-1">
            <dt class="text-sm font-medium text-gray-500">Advisors</dt>
            <ContentCompaniesContacts
              :data-field="ownershipInterestDataField"
              context="controlling-asset"
              :fetch-milliseconds="asOfMilliseconds"
            />
          </div>
        </div>
      </div>
      <div v-else class="p-2">
        <CreateCapitalEvent
          layer-type="OwnershipInterest"
          :property-id="propertyId"
          :deal-builder-asset-data-field="dataField"
          :data-field="dataField"
          :future="false"
          :nested="false"
          :preactivate="false"
          @refetch-layer="fetchOwnershipInterests(true)"
        />
      </div>
    </template>
  </div>
</template>

<script setup>
import ContentCompaniesContacts from "@/components/crowdsourcing/ContentCompaniesContacts.vue";
import CreateCapitalEvent from "@/components/deal-builder/CreateCapitalEvent.vue";
import InvolvedCompany from "@/components/crowdsourcing/InvolvedCompany.vue";
import api from "@/router/api";
import propertyAssetLabel from "@/assets/investmentLabel";
import _ from "lodash";
import { ref, computed, watch, onMounted } from "vue";
import decoratingAndFieldKey from "@/components/crowdsourcing/decoratingAndFieldKey";
import { useTimeTravelStore } from "@/stores/timeTravel";
import { useDealBuilderStore } from "@/stores/dealBuilder";
import { usePropertyFieldsStore } from "@/stores/propertyFields";
import { useCrowdsourcedChangeGroupStore } from "@/stores/crowdsourcedChangeGroup";
import { storeToRefs } from "pinia";
import moment from "moment";

const props = defineProps(["dataField"]);
const timeTravelStore = useTimeTravelStore();
const { asOfDate, asOfMilliseconds } = storeToRefs(timeTravelStore);
const dealBuilderStore = useDealBuilderStore();
const { refetchDealBuilderEditor } = storeToRefs(dealBuilderStore);
const changeGroupStore = useCrowdsourcedChangeGroupStore();
const { changeGroupId } = storeToRefs(changeGroupStore);
const propertyFieldsStore = usePropertyFieldsStore();

const expanded = ref(true);
const owners = ref([]);

const subjectIsFieldContent = computed(() => {
  return _.includes(
    ["Property", "PropertyRight"],
    props.dataField.decoratingContentType,
  );
});
const contentId = computed(() => {
  if (subjectIsFieldContent.value) {
    return props.dataField.fieldContentId;
  } else {
    return props.dataField.decoratingContentId;
  }
});
const contentType = computed(() => {
  if (subjectIsFieldContent.value) {
    return props.dataField.fieldContentType;
  } else {
    return props.dataField.decoratingContentType;
  }
});
const assetKey = computed(() => {
  return decoratingAndFieldKey(props.dataField);
});
const headerLabel = computed(() => {
  return propertyAssetLabel(props.dataField, propertyDataField.value);
});
const propertyId = computed(() => {
  if (_.get(props.dataField, "joiningContentType") === "Property") {
    return _.get(props.dataField, "joiningContentId");
  } else {
    return _.get(props.dataField, "decoratingContentId");
  }
});
const propertyDataField = computed(() =>
  propertyFieldsStore.getPropertyField(propertyId.value),
);

watch(asOfDate, (date, oldDate) => {
  if (oldDate && date !== oldDate) {
    fetchOwnershipInterests();
  }
});
onMounted(() => {
  if (propertyId.value) {
    fetchPropertyDataField();
  }

  fetchOwnershipInterests();
});

async function fetchPropertyDataField() {
  propertyFieldsStore.fetchPropertyDataField(propertyId.value);
}

function refetch(maybePayload) {
  console.log("controlling asset refetch", maybePayload);
  owners.value = [];
  if (maybePayload?.override) {
    fetchOwnershipInterests(true);
  } else {
    fetchOwnershipInterests();
  }
}

const persistCompanies = async (
  proofCompanies = null,
  ownershipInterestDataField,
) => {
  const payload = {
    investmentId: ownershipInterestDataField.fieldContent?.investmentId,
    companies: proofCompanies || [],
    changeGroupId: changeGroupId.value,
  };

  const response = await api.post(`investment_ownership_interests`, payload);

  console.log("controlling asset persist", proofCompanies, payload, response);

  return response;
};

function fetchOwnershipInterests(postCreationRefetch = false) {
  if (postCreationRefetch) {
    refetchDealBuilderEditor.value = true;
  }

  api
    .get(
      `ownership_interests/?content_type=${contentType.value}&content_id=${contentId.value}&as_of=${asOfMilliseconds.value}`,
    )
    .then((json) => {
      owners.value = json.data;
    });
}
</script>
