<template>
  <div class="py-2">
    <h3 class="font-medium leading-6 text-gray-900">Change name</h3>
    <div class="mt-1 max-w-xl text-sm text-gray-500">
      <p>Change your name.</p>
    </div>
    <form
      @submit.prevent="updateName"
      class="mt-3"
      :class="
        workspaceLayout === 'sideBySide' ? '' : 'flex items-center space-x-2'
      "
      data-test="update-name-form"
    >
      <div
        class="w-full"
        :class="workspaceLayout === 'sideBySide' ? '' : 'max-w-xs'"
      >
        <label for="name" class="sr-only">Name</label>
        <input
          v-focus
          v-model="newName"
          type="text"
          name="name"
          id="name"
          class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm"
          placeholder="Your name"
          data-test="update-name"
        />
      </div>
      <div class="flex items-center space-x-2">
        <button
          @click="$emit('cancel')"
          type="button"
          class="inline-flex items-center justify-center rounded-md border border-gray-300 bg-white p-2 font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 text-sm"
          :class="
            workspaceLayout === 'sideBySide' ? 'mt-3 w-full' : 'mt-0 w-auto'
          "
        >
          Cancel
        </button>
        <button
          type="submit"
          class="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 p-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          :class="
            workspaceLayout === 'sideBySide' ? 'mt-3 w-full' : 'mt-0 w-auto'
          "
        >
          Submit
        </button>
      </div>
    </form>
  </div>
</template>

<script setup>
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { useAuthStore } from "@/stores/auth";
import { useUserStore } from "@/stores/user";
import { useNotificationsStore } from "@/stores/notifications";
import User from "@/stores/models/user";

import { ref } from "vue";
import { storeToRefs } from "pinia";
import api from "@/router/api";

const emit = defineEmits(["cancel"]);
const layoutStore = useWorkspaceLayoutStore();
const { workspaceLayout } = storeToRefs(layoutStore);
const authStore = useAuthStore();
const userStore = useUserStore();
const { currentUser } = storeToRefs(userStore);
const notificationsStore = useNotificationsStore();

const newName = ref("");

async function updateName() {
  const payload = {
    newName: newName.value,
  };
  api.patch("user_names", payload).then(
    () => {
      notificationsStore.addNotification("nameUpdated");
      authStore.fetchUser().then((fetchedUser) => {
        if (fetchedUser.data.signedIn) {
          currentUser.value = new User(fetchedUser.data);
        }
        emit("cancel");
      });
    },
    async (failure) => {
      console.log(failure);
    },
  );
}
</script>
