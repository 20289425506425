<template>
  <div>
    <span
      v-if="adminApprovable"
      class="relative z-0 -mt-2 p-2 self-center inline-flex rounded-md"
    >
      <template v-if="!hasConflicts">
        <HoldableButton
          v-if="focalChange && !validationLockout"
          label="Approve"
          :action-callback="approve"
          :icon-component="markRaw(CheckIcon)"
          :tooltip="compact ? 'Approve all' : ''"
          :compact="compact"
          :disabled="buttonLocked"
          :action-in-progress="actionInProgress"
          enabled-button-class="text-green-700 hover:bg-green-50 focus:ring-green-500 focus:border-green-500"
          enabled-inner-class="text-green-700"
          :button-shape-classes="['rounded-l-md']"
          pulse-color="#15803d"
          :test-prefix="testPrefix"
          :test-index="testIndex"
        />
        <ClickableButton
          v-else
          label="Approve"
          :action-callback="approve"
          :icon-component="markRaw(CheckIcon)"
          :tooltip="compact ? 'Approve all' : ''"
          :compact="compact"
          :disabled="buttonLocked"
          :action-in-progress="actionInProgress"
          enabled-button-class="text-green-700 hover:bg-green-50 focus:ring-green-500 focus:border-green-500"
          enabled-inner-class="text-green-700"
          :button-shape-classes="['rounded-l-md']"
          pulse-color="#15803d"
          :test-prefix="testPrefix"
          :test-index="testIndex"
        />
      </template>

      <HoldableButton
        v-if="focalChange && !validationLockout"
        :label="hasConflicts ? 'Reject all' : 'Reject'"
        :action-callback="disapprove"
        :icon-component="markRaw(XMarkIcon)"
        :tooltip="compact ? 'Reject all' : ''"
        :compact="compact"
        :disabled="buttonLocked"
        :action-in-progress="actionInProgress"
        enabled-button-class="text-red-700 hover:bg-red-50 focus:ring-red-500 focus:border-red-500"
        enabled-inner-class="text-red-700"
        :button-shape-classes="[hasConflicts ? 'rounded-md' : 'rounded-r-md']"
        pulse-color="#b91c1c"
        :test-prefix="testPrefix"
        :test-index="testIndex"
      />
      <ClickableButton
        v-else
        :label="hasConflicts ? 'Reject all' : 'Reject'"
        :action-callback="disapprove"
        :icon-component="markRaw(XMarkIcon)"
        :tooltip="compact ? 'Reject all' : ''"
        :compact="compact"
        :disabled="buttonLocked"
        :action-in-progress="actionInProgress"
        enabled-button-class="text-red-700 hover:bg-red-50 focus:ring-red-500 focus:border-red-500"
        enabled-inner-class="text-red-700"
        :button-shape-classes="[hasConflicts ? 'rounded-md' : 'rounded-r-md']"
        pulse-color="#b91c1c"
        :test-prefix="testPrefix"
        :test-index="testIndex"
      />
    </span>
    <span
      v-else
      class="relative z-0 -mt-2 p-2 self-center inline-flex rounded-md"
    >
      <template v-if="!hasConflicts">
        <HoldableButton
          v-if="focalChange && !validationLockout"
          label="Accept"
          :action-callback="accept"
          :icon-component="markRaw(CheckIcon)"
          :tooltip="compact ? 'Accept all' : ''"
          :compact="compact"
          :disabled="buttonLocked"
          :action-in-progress="actionInProgress"
          enabled-button-class="text-green-700 hover:bg-green-50 focus:ring-green-500 focus:border-green-500"
          enabled-inner-class="text-green-700"
          :button-shape-classes="['rounded-l-md']"
          pulse-color="#15803d"
          :test-prefix="testPrefix"
          :test-index="testIndex"
        />
        <ClickableButton
          v-else
          label="Accept"
          :action-callback="accept"
          :icon-component="markRaw(CheckIcon)"
          :tooltip="compact ? 'Accept all' : ''"
          :compact="compact"
          :disabled="buttonLocked"
          :action-in-progress="actionInProgress"
          enabled-button-class="text-green-700 hover:bg-green-50 focus:ring-green-500 focus:border-green-500"
          enabled-inner-class="text-green-700"
          :button-shape-classes="['rounded-l-md']"
          pulse-color="#15803d"
          :test-prefix="testPrefix"
          :test-index="testIndex"
        />
      </template>

      <HoldableButton
        v-if="focalChange && !validationLockout"
        :label="hasConflicts ? 'Reject all' : 'Reject'"
        :action-callback="reject"
        :icon-component="markRaw(XMarkIcon)"
        :tooltip="compact ? 'Reject all' : ''"
        :compact="compact"
        :disabled="buttonLocked"
        :action-in-progress="actionInProgress"
        enabled-button-class="text-red-700 hover:bg-red-50 focus:ring-red-500 focus:border-red-500"
        enabled-inner-class="text-red-700"
        :button-shape-classes="[
          hasConflicts ? 'rounded-l-md' : '',
          canSkip ? '' : 'rounded-r-md',
        ]"
        pulse-color="#b91c1c"
        :test-prefix="testPrefix"
        :test-index="testIndex"
      />
      <ClickableButton
        v-else
        :label="hasConflicts ? 'Reject all' : 'Reject'"
        :action-callback="reject"
        :icon-component="markRaw(XMarkIcon)"
        :tooltip="compact ? 'Reject all' : ''"
        :compact="compact"
        :disabled="buttonLocked"
        :action-in-progress="actionInProgress"
        enabled-button-class="text-red-700 hover:bg-red-50 focus:ring-red-500 focus:border-red-500"
        enabled-inner-class="text-red-700"
        :button-shape-classes="[
          hasConflicts ? 'rounded-l-md' : '',
          canSkip ? '' : 'rounded-r-md',
        ]"
        pulse-color="#b91c1c"
        :test-prefix="testPrefix"
        :test-index="testIndex"
      />
      <template v-if="canSkip">
        <HoldableButton
          v-if="focalChange && !validationLockout"
          label="Skip"
          :action-callback="skip"
          :icon-component="markRaw(ChevronDoubleRightIcon)"
          :tooltip="compact ? 'Skip all' : ''"
          :compact="compact"
          :disabled="buttonLocked"
          :action-in-progress="actionInProgress"
          enabled-button-class="text-blue-700 hover:bg-blue-50 focus:ring-blue-500 focus:border-blue-500"
          enabled-inner-class="text-blue-700"
          :button-shape-classes="['rounded-r-md']"
          pulse-color="#1d4ed8"
          :test-prefix="testPrefix"
          :test-index="testIndex"
        />
        <ClickableButton
          v-else
          label="Skip"
          :action-callback="skip"
          :icon-component="markRaw(ChevronDoubleRightIcon)"
          :tooltip="compact ? 'Skip all' : ''"
          :compact="compact"
          :disabled="buttonLocked"
          :action-in-progress="actionInProgress"
          enabled-button-class="text-blue-700 hover:bg-blue-50 focus:ring-blue-500 focus:border-blue-500"
          enabled-inner-class="text-blue-700"
          :button-shape-classes="['rounded-r-md']"
          pulse-color="#1d4ed8"
          :test-prefix="testPrefix"
          :test-index="testIndex"
        />
      </template>
    </span>
  </div>
</template>

<script setup>
import ClickableButton from "@/components/crowdsourcing/validation-actions/ClickableButton.vue";
import HoldableButton from "@/components/crowdsourcing/validation-actions/HoldableButton.vue";
import { useUserStore } from "@/stores/user";
import { useDealBuilderStore } from "@/stores/dealBuilder";
import { storeToRefs } from "pinia";
import { computed, onMounted, ref, watch, markRaw } from "vue";
import {
  XMarkIcon,
  CheckIcon,
  ChevronDoubleRightIcon,
} from "@heroicons/vue/20/solid";

const userStore = useUserStore();
const { validationSkips, isAdmin, emailUnverified } = storeToRefs(userStore);
const dealBuilderStore = useDealBuilderStore();
const props = defineProps([
  "actionInProgress",
  "adminApprovable",
  "hasConflicts",
  "compact",
  "disabled",
  "changeCount",
  "testPrefix",
  "testIndex",
  "focalChange",
]);
const emit = defineEmits(["accept", "approve", "reject", "disapprove", "skip"]);
const validationLockout = ref(true);

const buttonLocked = computed(
  () => props.actionInProgress || (props.compact && props.disabled),
);

const canSkip = computed(() => {
  if (isAdmin.value) {
    return true;
  } else if (props.changeCount && props.changeCount > 1) {
    return validationSkips.value >= props.changeCount;
  } else {
    return validationSkips.value > 0;
  }
});

watch(
  () => props.focalChange,
  (val, oldVal) => {
    if (val && !oldVal) {
      resetValidationLockout();
    }
  },
);

onMounted(() => {
  resetValidationLockout();
});

function resetValidationLockout() {
  if (props.focalChange) {
    validationLockout.value = true;
    setTimeout(() => {
      validationLockout.value = false;
    }, 500);
  }
}

function accept() {
  if (emailUnverified.value) {
    userStore.promptToVerify();
  } else if (!buttonLocked.value) {
    emit("accept");
    dealBuilderStore.clearDealBuilder();
  }
}
function approve() {
  if (emailUnverified.value) {
    userStore.promptToVerify();
  } else if (props.adminApprovable) {
    emit("approve");
    dealBuilderStore.clearDealBuilder();
  }
}
function reject() {
  if (emailUnverified.value) {
    userStore.promptToVerify();
  } else if (!buttonLocked.value) {
    emit("reject");
    dealBuilderStore.clearDealBuilder();
  }
}
function disapprove() {
  if (emailUnverified.value) {
    userStore.promptToVerify();
  } else if (props.adminApprovable) {
    emit("disapprove");
    dealBuilderStore.clearDealBuilder();
  }
}
function skip() {
  if (emailUnverified.value) {
    userStore.promptToVerify();
  } else if (!buttonLocked.value) {
    emit("skip");
  }
}
</script>

<style scoped>
.hold-rest-yes {
  background: linear-gradient(to right, #22c55e 50%, white 50%);
}
.hold-rest-no {
  background: linear-gradient(to right, #ef4444 50%, white 50%);
}
.hold-rest-skip {
  background: linear-gradient(to right, #3b82f6 50%, white 50%);
}

.hold-rest {
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all 1s ease-out;
}

.holding {
  background-position: left bottom;
}

.hold-rest-text {
  transition: all 1.1s ease-out;
}

.holding-text {
  color: white;
}
</style>
