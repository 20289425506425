import { defineStore, acceptHMRUpdate } from "pinia";
import { computed, nextTick, ref, watch } from "vue";
import _ from "lodash";
import moment from "moment";

export const useCashflowDefinitionStore = defineStore(
  "cashflowDefinition",
  () => {
    const scheduleDisplayable = ref(true);
    const cashflowSchedule = ref({
      0: {
        date: null,
        dateType: "Relative",
        inputType: "annual",
        inputValue: null,
        dataSource: "input",
        valid: false,
      },
    });
    const tenantImprovementAllowance = ref({
      date: null,
      dateType: "Relative",
      inputType: "annual",
      inputValue: null,
      dataSource: "input",
      valid: false,
    });
    const processedCashflowSchedule = ref([]);

    const selectedCashflowType = ref({
      order: 1,
      name: "Periodic stepping",
      value: "stepping",
      description: "ex. Rents",
      frequency: "multiple",
    });
    const cashflowTypes = ref([
      {
        order: 1,
        name: "Periodic stepping",
        value: "stepping",
        description: "ex. Rents",
        frequency: "multiple",
        disabled: false,
      },
      {
        order: 2,
        name: "Tenant improvement allowance",
        value: "tenantImprovementAllowance",
        description: "Link to buildouts",
        frequency: "single",
        disabled: false,
      },
      {
        order: 3,
        name: "Time series",
        value: "timeSeries",
        description: "Raw monthly inputs",
        frequency: "multiple",
        disabled: true,
      },
      {
        order: 4,
        name: "One-time",
        value: "single",
        description: "Date and amount",
        frequency: "single",
        disabled: true,
      },
    ]);

    const steppingScheduleComplete = computed(() => {
      return _.every(processedCashflowSchedule.value, validScheduleItem);
    });
    const tenantImprovementAllowanceComplete = computed(() => {
      return tenantImprovementAllowance.value.valid;
    });
    function validScheduleItem(item) {
      return item.valid;
    }

    const savePayload = computed(() => {
      switch (selectedCashflowType.value?.value) {
        case "stepping": {
          if (steppingScheduleComplete.value) {
            const structuredItems = _.map(
              processedCashflowSchedule.value,
              function (item, itemKey) {
                let formattedDate = item.date;

                if (item.dateType === "Actual") {
                  formattedDate = item.date
                    ? moment(item.date).valueOf()
                    : null;
                }
                return _.merge({}, item, {
                  date: formattedDate,
                  itemNumber: _.toNumber(itemKey),
                });
              },
            );
            return {
              scheduleItems: structuredItems,
            };
          } else {
            return null;
          }
        }
        case "tenantImprovementAllowance": {
          if (tenantImprovementAllowanceComplete.value) {
            let formattedDate = tenantImprovementAllowanceComplete.value.date;

            if (
              tenantImprovementAllowanceComplete.value.dateType === "Actual"
            ) {
              formattedDate = tenantImprovementAllowanceComplete.value.date
                ? moment(
                    tenantImprovementAllowanceComplete.value.date,
                  ).valueOf()
                : null;
            }

            return {
              scheduleItems: [
                _.merge({}, tenantImprovementAllowance.value, {
                  date: formattedDate,
                  itemNumber: 0,
                }),
              ],
            };
          } else {
            return null;
          }
        }
        default:
          return null;
      }
    });

    watch(selectedCashflowType, (val, oldVal) => {
      if (val?.name !== oldVal?.name) {
        resetSchedule();
      }
    });

    function getScheduleItem(key) {
      return cashflowSchedule.value[key];
    }
    const maxScheduleItem = computed(() => {
      const numKeys = _.keys(cashflowSchedule.value).map(_.toNumber);

      return _.max(numKeys);
    });
    function addScheduleItem() {
      if (_.isNumber(maxScheduleItem.value)) {
        const newScheduleItemKey = maxScheduleItem.value + 1;
        cashflowSchedule.value[newScheduleItemKey] = {
          date: null,
          dateType: "Relative",
          inputType: "annual",
          inputValue: null,
          dataSource: "input",
          valid: false,
        };
      }
    }
    function removeScheduleItem(key) {
      delete cashflowSchedule.value[key];
    }

    async function resetSchedule() {
      scheduleDisplayable.value = false;
      cashflowSchedule.value = {
        0: {
          date: null,
          dateType: "Relative",
          inputType: "annual",
          inputValue: null,
          dataSource: "input",
          valid: false,
        },
      };
      tenantImprovementAllowance.value = {
        date: null,
        dateType: "Relative",
        inputType: "annual",
        inputValue: null,
        dataSource: "input",
        valid: false,
      };
      processedCashflowSchedule.value = [];
      await nextTick();
      scheduleDisplayable.value = true;
    }

    function reset() {
      selectedCashflowType.value = {
        order: 1,
        name: "Periodic stepping",
        value: "stepping",
        description: "ex. Rents",
        frequency: "multiple",
      };
      resetSchedule();
    }

    return {
      scheduleDisplayable,
      cashflowSchedule,
      tenantImprovementAllowance,
      processedCashflowSchedule,
      savePayload,
      selectedCashflowType,
      cashflowTypes,
      getScheduleItem,
      addScheduleItem,
      removeScheduleItem,
      resetSchedule,
      reset,
    };
  },
);

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useCashflowDefinitionStore, import.meta.hot),
  );
}
