<template>
  <div class="col-span-1">
    <SpaceUsageLayer
      v-if="canDisplaySubordinateLayer"
      :property-id="propertyId"
      :top-level-space-data-field="topLevelSpaceDataField"
      :data-field="subordinateLayer"
      :upstream-space-usage-id="fetchedUpstreamSpaceUsageId"
      :future="future"
      :depth="depth - 1"
      @refetch-ownership-layer="emit('refetch-ownership-layer')"
    />
    <SpaceUsageLayer
      v-if="creatingSublease.direction === 'subordinate'"
      :property-id="propertyId"
      :top-level-space-data-field="topLevelSpaceDataField"
      :data-field="dataField"
      :upstream-space-usage-id="fetchedUpstreamSpaceUsageId"
      :future="future"
      :depth="depth - 1"
      :preactivate-form="true"
      :insert-direction="creatingSublease"
      @container-cancel="cancelSublease"
      @refetch-subordinate-layer="refetch(true)"
      @refetch-ownership-layer="emit('refetch-ownership-layer')"
    />
    <li class="flex shadow-sm">
      <div
        :class="`flex-shrink-0 flex items-center justify-center w-3 ${layerColor} text-white text-sm font-medium`"
      />
      <ul
        class="relative pb-6 flex-1 flex items-center border border-gray-200 bg-white"
      >
        <div
          v-if="canAddSublease && !preactivateForm"
          class="absolute -top-4 flex w-full justify-center"
          style="z-index: 2"
        >
          <span
            class="relative z-0 inline-flex shadow-sm rounded-md -space-x-px"
          >
            <button
              v-if="
                creatingSublease.direction === 'subordinate' || preactivateForm
              "
              @click="cancelSublease"
              type="button"
              class="relative inline-flex items-center px-4 py-1.5 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-400 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
            >
              <span class="sr-only">Cancel</span>
              <XMarkIcon class="-ml-1.5 mr-1 h-5 w-5 text-gray-400" />
              <span>Cancel</span>
            </button>

            <SpaceGroupAddMenu
              v-else-if="portfoliosAllowed"
              insert-direction="subordinate"
              @add-to-portfolio="inputPortfolioSublease"
            />

            <DataVisibilityButton
              v-else
              visibility="safezone"
              class="relative inline-flex"
            >
              <template v-slot:button>
                <button
                  @click="inputSublease('subordinate')"
                  type="button"
                  :data-test="`${decoratingAndFieldKey(
                    topLevelSpaceDataField,
                  )}-depth-${depth}-sublease-add-button`"
                  class="relative inline-flex items-center px-4 py-1.5 rounded-md border border-yellow-300 bg-white text-sm font-medium text-gray-400 hover:bg-yellow-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-yellow-500 focus:border-yellow-500"
                >
                  <span class="sr-only">Add sublease</span>
                  <PlusIcon class="-ml-1.5 mr-1 h-5 w-5 text-yellow-400" />
                  <span>Sublease</span>
                </button>
              </template>
            </DataVisibilityButton>
          </span>
        </div>
        <div class="flex-1 px-4 py-2 text-sm">
          <div class="flex items-center justify-between">
            <p class="text-gray-900 font-bold hover:text-gray-600">
              <span>{{ label }}</span>
            </p>
          </div>
          <ol
            role="ownership-interests"
            :class="workspaceLayout === 'topAndBottom' ? 'grid-cols-2' : ''"
            class="mt-4 grid grid-cols-1 gap-6"
          >
            <div v-if="existingOpenAvailability && future" class="text-center">
              <svg
                class="mx-auto h-8 w-8 text-gray-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"
                />
              </svg>
              <h3 class="mt-2 text-sm font-medium text-gray-900">
                Active/pending
              </h3>
              <p class="mt-1 text-sm text-gray-500">
                Edit the availability below.
              </p>
            </div>
            <template
              v-else-if="
                spaceUsers.length > 0 || remainingSpaceUsers.length > 0
              "
            >
              <InvolvedCompany
                v-for="spaceUser in spaceUsers"
                :key="spaceUser.fieldContentId"
                :top-level-space-data-field="topLevelSpaceDataField"
                :immediate-space-data-field="dataField"
                :decorating-content-data-field="spaceUser"
                :future="future"
                :existing-open-availability="existingOpenAvailability"
                :fetch-milliseconds="fetchMilliseconds"
                context="space-usage-stack"
                @refetch-space-usage-builder="refetch(true)"
                @refetch="fetchInvolvedCompanies"
                @override-refetch="refetch(true)"
              />

              <InvolvedCompany
                v-for="ownershipInterestDataField in remainingSpaceUsers"
                :key="ownershipInterestDataField.fieldContentId"
                :top-level-space-data-field="topLevelSpaceDataField"
                :immediate-space-data-field="dataField"
                :decorating-content-data-field="ownershipInterestDataField"
                :future="future"
                :fetch-milliseconds="fetchMilliseconds"
                context="future-space-usage-stack-placeholder"
              />
            </template>
            <div
              v-else-if="before && existingOpenAvailability"
              class="text-center"
            >
              <svg
                class="mx-auto h-8 w-8 text-gray-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12.066 11.2a1 1 0 000 1.6l5.334 4A1 1 0 0019 16V8a1 1 0 00-1.6-.8l-5.333 4zM4.066 11.2a1 1 0 000 1.6l5.334 4A1 1 0 0011 16V8a1 1 0 00-1.6-.8l-5.334 4z"
                />
              </svg>
              <h3 class="mt-2 text-sm font-medium text-gray-900">
                No Space Users
              </h3>
              <p class="mt-1 text-sm text-gray-500">
                Rewind the timeline and add older deals.
              </p>
            </div>

            <CreateSpaceAvailability
              v-if="canEdit && !existingOpenAvailability"
              :property-id="propertyId"
              :top-level-space-data-field="topLevelSpaceDataField"
              :data-field="dataField"
              :upstream-space-usage-id="upstreamSpaceUsageId"
              :future="future"
              :depth="depth"
              :preactivate="preactivateForm"
              :insert-direction="insertDirection"
              :portfolio-to-add="portfolioToAdd"
              @cancel="cancel"
              @refetch-layer="refetch(true)"
            />
          </ol>
        </div>

        <div
          v-if="canAddSuperior && !preactivateForm"
          class="absolute -bottom-4 flex w-full justify-center"
          style="z-index: 2"
        >
          <span
            class="relative z-0 inline-flex shadow-sm rounded-md -space-x-px"
          >
            <button
              v-if="
                creatingSublease.direction === 'superior' || preactivateForm
              "
              @click="cancelSublease"
              type="button"
              class="relative inline-flex items-center px-4 py-1.5 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-400 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
            >
              <span class="sr-only">Cancel</span>
              <XMarkIcon class="-ml-1.5 mr-1 h-5 w-5 text-gray-400" />
              <span>Cancel</span>
            </button>

            <SpaceGroupAddMenu
              v-else-if="portfoliosAllowed"
              insert-direction="superior"
              @add-to-portfolio="inputPortfolioSublease"
            />

            <DataVisibilityButton
              v-else
              visibility="safezone"
              class="relative inline-flex"
            >
              <template v-slot:button>
                <button
                  @click="inputSublease('superior')"
                  type="button"
                  :data-test="`${decoratingAndFieldKey(
                    topLevelSpaceDataField,
                  )}-depth-${depth}-superior-sublease-add-button`"
                  class="relative inline-flex items-center px-4 py-1.5 rounded-md border border-yellow-300 bg-white text-sm font-medium text-gray-400 hover:bg-yellow-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-yellow-500 focus:border-yellow-500"
                >
                  <span class="sr-only">Add sublease</span>
                  <PlusIcon class="-ml-1.5 mr-1 h-5 w-5 text-yellow-400" />
                  <span>Sublease</span>
                </button>
              </template>
            </DataVisibilityButton>
          </span>
        </div>
      </ul>
    </li>
    <SpaceUsageLayer
      v-if="creatingSublease.direction === 'superior'"
      :property-id="propertyId"
      :top-level-space-data-field="topLevelSpaceDataField"
      :data-field="dataField"
      :future="future"
      :depth="depth + 1"
      :preactivate-form="true"
      :insert-direction="creatingSublease"
      @container-cancel="cancelSublease"
      @refetch-subordinate-layer="refetch(true)"
      @refetch-ownership-layer="emit('refetch-ownership-layer')"
    />
  </div>
</template>

<script setup>
import { PlusIcon, XMarkIcon } from "@heroicons/vue/20/solid";
import InvolvedCompany from "@/components/crowdsourcing/InvolvedCompany.vue";
import CreateSpaceAvailability from "@/components/space-usage-builder/CreateSpaceAvailability.vue";
import SpaceGroupAddMenu from "@/components/space-usage-builder/SpaceGroupAddMenu.vue";
import DataVisibilityButton from "@/components/crowdsourcing/DataVisibilityButton.vue";
import { useSpaceUsageBuilderStore } from "@/stores/spaceUsageBuilder";
import { useTimeTravelStore } from "@/stores/timeTravel";
import { useProveForValueStore } from "@/stores/proveForValue";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { storeToRefs } from "pinia";
import { ref, computed, watch, onMounted } from "vue";
import decoratingAndFieldKey from "@/components/crowdsourcing/decoratingAndFieldKey";
import _ from "lodash";
import api from "@/router/api";

const props = defineProps([
  "propertyId",
  "topLevelSpaceDataField",
  "dataField",
  "upstreamSpaceUsageId",
  "future",
  "depth",
  "preactivateForm",
  "insertDirection",
]);
const emit = defineEmits(["container-cancel"]);

const spaceUsageBuilderStore = useSpaceUsageBuilderStore();
const { spaceUsageBuilder, spaces, availabilityGroups, crossInteraction } =
  storeToRefs(spaceUsageBuilderStore);
const timeTravelStore = useTimeTravelStore();
const { asOfMilliseconds, dayBeforeAsOf } = storeToRefs(timeTravelStore);
const layoutStore = useWorkspaceLayoutStore();
const { workspaceLayout } = storeToRefs(layoutStore);
const proveForValueStore = useProveForValueStore();
const { proofRefetchTrigger } = storeToRefs(proveForValueStore);

const spaceUsers = ref([]);
const creatingSublease = ref(false); // { direction, investmentGroup }
const subordinateLayer = ref(null);
const subordinateLayerLoaded = ref(false);

const spaceKey = computed(() => {
  return decoratingAndFieldKey(props.dataField);
});
const contentId = computed(() => {
  return props.dataField.fieldContentId;
});
const contentType = computed(() => {
  return props.dataField.fieldContentType;
});
const before = computed(() => {
  return !props.future;
});
const remainingSpaceUsers = computed(() => {
  return [];
});
const fetchedUpstreamSpaceUsageId = computed(() => {
  const rawIds = spaceUsers.value.map((dataField) => {
    return dataField.fieldContent?.spaceUsageId;
  });
  const uniqIds = _.uniq(_.compact(rawIds));
  return _.head(uniqIds);
});
const existingAvailabilitySelected = computed(() => {
  return (
    _.get(crossInteraction.value, "source") === "TimelineInvestment" &&
    _.get(crossInteraction.value, "spaceKey") ===
      decoratingAndFieldKey(props.topLevelSpaceDataField)
  );
});
const existingOpenAvailability = computed(() => {
  if (existingAvailabilitySelected.value) {
    const space = _.get(
      spaceUsageBuilder.value,
      `spaces[${decoratingAndFieldKey(props.topLevelSpaceDataField)}]`,
    );
    const spaceAvailabilities = _.get(space, `availabilities`, []);
    const availability = _.get(
      _.find(spaceAvailabilities, {
        fieldContentId: _.get(crossInteraction.value, "combinedKey"),
      }),
      "fieldContent",
    );

    if (_.get(availability, "state") !== "closed") {
      return availability || null;
    } else {
      return null;
    }
  } else {
    return null;
  }
});
const fetchMilliseconds = computed(() => {
  return existingOpenAvailability.value && !props.future
    ? dayBeforeAsOf.value
    : asOfMilliseconds.value;
});
const matchingAvailabilityGroup = computed(() => {
  return _.find(availabilityGroups.value, findMatchingGroupAvailability);
});
const matchingStubbedAvailabilityGroup = computed(() => {
  return (
    props.future &&
    _.find(availabilityGroups.value, function (group) {
      return _.find(group.availabilities, function (availability) {
        const matchingKey =
          !!availability.topLevelSpace &&
          decoratingAndFieldKey(props.topLevelSpaceDataField) ===
            decoratingAndFieldKey(availability.topLevelSpace);

        return matchingKey;
      });
    })
  );
});
const stubbedSublease = computed(() => {
  if (matchingStubbedAvailabilityGroup.value) {
    return _.find(
      matchingStubbedAvailabilityGroup.value.availabilities,
      function (availObj) {
        let assetMatch = false;

        if (availObj.upstreamDataField && availObj.insertDirection) {
          if (availObj.insertDirection === "subordinate") {
            assetMatch =
              decoratingAndFieldKey(availObj.upstreamDataField) ===
              decoratingAndFieldKey(props.dataField);
          } else if (
            availObj.insertDirection === "superior" &&
            props.depth !== 6
          ) {
            console.log("superior stub?");
            assetMatch =
              decoratingAndFieldKey(availObj.upstreamDataField) ===
              decoratingAndFieldKey(props.dataField);
          }
        }
        return assetMatch;
      },
    );
  } else {
    return null;
  }
});
const futureDeal = computed(() => {
  if (matchingAvailabilityGroup.value) {
    return findMatchingGroupAvailability(matchingAvailabilityGroup.value);
  } else {
    return null;
  }
});
const spacesLength = computed(() => {
  return _.size(spaces.value);
});
const portfoliosAllowed = computed(() => {
  return (
    spaceUsageBuilder.value &&
    spacesLength.value > 1 &&
    availabilityGroups.value.length > 0
  );
});
const portfolioToAdd = computed(() => {
  return null;
});
const canAddSublease = computed(() => {
  if (before.value && !existingAvailabilitySelected.value) {
    return subordinateLayerLoaded.value && !subordinateLayer.value;
  } else {
    return false;
  }
});
const canAddSuperior = computed(() => {
  return before.value && !existingAvailabilitySelected.value && !isDirect.value;
});
const canDisplaySubordinateLayer = computed(() => {
  if (subordinateLayer.value) {
    if (props.future || !subordinateAvailability.value) {
      return true;
    } else {
      return subordinateAvailability.value?.fieldContent?.state === "closed";
    }
  } else {
    return false;
  }
});
const subordinateAvailability = computed(() => {
  if (subordinateLayer.value) {
    const matchingSpace = spaces.value[spaceKey.value];

    if (matchingSpace) {
      return _.find(matchingSpace.availabilities, {
        fieldContentId: subordinateLayer.value.decoratingContentId,
      });
    } else {
      return null;
    }
  } else {
    return null;
  }
});
const editingFuture = computed(() => {
  return props.future && futureDeal.value;
});
const canEdit = computed(() => {
  if (stubbedSublease.value) {
    return (
      !isDirect.value &&
      props.insertDirection?.direction === stubbedSublease.value.insertDirection
    );
  } else if (editingFuture.value) {
    return !editingFuture.value.insertDirection;
  } else {
    return spaceUsers.value.length === 0;
  }
});
const isDirect = computed(() => {
  return props.depth === 6;
});
const label = computed(() => {
  if (props.preactivateForm) {
    return "New Sublease";
  } else if (isDirect.value) {
    return "Lease";
  } else {
    return `Sublease ${contentId.value}`;
  }
});
const layerColor = computed(() => {
  if (isDirect.value) {
    return "bg-blue-400";
  } else {
    switch (props.depth) {
      case 5:
        return "bg-cyan-400";
      case 4:
        return "bg-cyan-300";
      case 3:
        return "bg-cyan-200";
      default:
        return "bg-cyan-100";
    }
  }
});

watch(proofRefetchTrigger, (val) => {
  if (val === "spaceUsageStack") {
    console.log("proof refetch trigger");
    fetchInvolvedCompanies({ override: "proof" });
  }
});

onMounted(() => {
  refetch();
});

function findMatchingGroupAvailability(group) {
  const spaceKey = decoratingAndFieldKey(props.dataField);

  return _.find(group.availabilities, function (availability) {
    if (availability.topLevelSpace) {
      return spaceKey === decoratingAndFieldKey(availability.topLevelSpace);
    } else {
      return false;
    }
  });
}

async function refetch(postCreationRefetch = false) {
  if (postCreationRefetch) {
    timeTravelStore.triggerRefetch();
  } else {
    reset();
    await fetchInvolvedCompanies({ override: true });

    if (stubbedSublease.value) {
      if (!props.insertDirection?.direction) {
        fetchNeighboringLayer();
      }
      activateStubbedSubleaseCreation();
    } else {
      fetchNeighboringLayer();
    }
  }
}
function activateStubbedSubleaseCreation() {
  if (
    props.future &&
    !props.preactivateForm &&
    decoratingAndFieldKey(stubbedSublease.value.upstreamDataField) ===
      decoratingAndFieldKey(props.dataField) &&
    stubbedSublease.value.insertDirection
  ) {
    console.log("activate stubbed loan");
    inputSublease(stubbedSublease.value.insertDirection);
  }
}
function reset() {
  spaceUsers.value = [];
  subordinateLayer.value = null;
}
async function fetchInvolvedCompanies(maybeOverride) {
  const fetchRequestKey = `space_users_${contentType.value}_${contentId.value}`;
  if (contentType.value && contentId.value && !props.preactivateForm) {
    if (
      spaceUsageBuilderStore.alreadyFetched(fetchRequestKey) &&
      !maybeOverride?.override
    ) {
      spaceUsers.value =
        spaceUsageBuilderStore.alreadyFetchedFieldsFor(fetchRequestKey);
    } else {
      const usersResponse = await api.get(
        `space_users/?content_type=${contentType.value}&content_id=${contentId.value}&as_of=${fetchMilliseconds.value}`,
      );
      spaceUsers.value = usersResponse.data;
      spaceUsageBuilderStore.interceptablePatch(
        usersResponse.data,
        fetchRequestKey,
      );
    }

    if (spaceUsageBuilder.value) {
      spaceUsageBuilderStore.setSpaceUsers({
        spaceKey: spaceKey.value,
        spaceUsers: spaceUsers.value,
      });
    }
  }
}
async function fetchNeighboringLayer() {
  const usageId =
    fetchedUpstreamSpaceUsageId.value || props.upstreamSpaceUsageId;

  if (usageId) {
    const usageState = props.future ? "" : "&space_usage_state=active";
    const fetchRequestKey = `space_usage_availabilities_SpaceUsage_${usageId}${usageState}`;
    let availabilities = [];

    if (spaceUsageBuilderStore.alreadyFetched(fetchRequestKey)) {
      availabilities =
        spaceUsageBuilderStore.alreadyFetchedFieldsFor(fetchRequestKey);
    } else {
      const availabilitiesResponse = await api.get(
        `space_usage_availabilities/?space_usage_id=${usageId}&as_of=${fetchMilliseconds.value}${usageState}`,
      );
      availabilities = availabilitiesResponse.data;
      spaceUsageBuilderStore.interceptablePatch(
        availabilities,
        fetchRequestKey,
      );
    }

    const availability = availabilities[0];
    setNeighboringLayer(availability);
  } else {
    subordinateLayerLoaded.value;
  }
}
function setNeighboringLayer(availability) {
  subordinateLayer.value = availability;
  subordinateLayerLoaded.value = true;
}

function inputPortfolioSublease({ availabilityGroup, insertDirection }) {
  console.log("add to portfolio", availabilityGroup);
  if (availabilityGroup === "new") {
    creatingSublease.value = { direction: insertDirection, availabilityGroup };
  } else {
    creatingSublease.value = { direction: insertDirection, availabilityGroup };
  }
}
function inputSublease(insertDirection) {
  creatingSublease.value = {
    direction: insertDirection,
    availabilityGroup: null,
  };
}
function cancel() {
  creatingSublease.value = false;

  if (props.preactivateForm) {
    emit("container-cancel");
  }
}
function cancelSublease() {
  if (editingFuture.value) {
    spaceUsageBuilderStore.removeSpaceAvailability({
      spaceKey: spaceKey.value,
    });
  }

  creatingSublease.value = false;
  emit("container-cancel");
}
</script>
