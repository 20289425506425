import _ from "lodash";
export const todayViewSortOptions = [
  {
    name: "Due Date",
    apiValue: "due_date",
    directions: [
      { name: "Earliest First", apiValue: "asc" },
      { name: "Latest First", apiValue: "desc" },
    ],
  },
  {
    name: "Creation Date",
    apiValue: "sort_date",
    directions: [
      { name: "Oldest First", apiValue: "asc" },
      { name: "Newest First", apiValue: "desc" },
    ],
  },
  {
    name: "Priority",
    apiValue: "priority",
    directions: [
      { name: "Highest First", apiValue: "asc" },
      { name: "Lowest First", apiValue: "desc" },
    ],
  },
  {
    name: "Title",
    apiValue: "name",
    directions: [
      { name: "Ascending", apiValue: "asc" },
      { name: "Descending", apiValue: "desc" },
    ],
  },
];

export const standaloneListSortOptions = _.concat(
  [
    {
      name: "Manual",
      apiValue: "manual",
      directions: [],
    },
  ],
  todayViewSortOptions
);
