<template>
  <div class="pb-4 shadow-lg w-80 rounded-lg ring-1 ring-black ring-opacity-5">
    <h2 class="sr-only">Sharing Stage</h2>
    <SharingAddressBar />

    <form @submit.prevent="attemptShare" class="bg-white mx-auto max-w-2xl">
      <ul
        role="list"
        class="mb-2 divide-y divide-gray-200 max-h-80 overflow-y-auto"
      >
        <li
          v-for="field in sharingDataFields"
          :key="field.localId"
          class="flex items-center justify-between px-4 py-2"
        >
          <div class="flex-auto">
            <DataField
              :data-field="field"
              :sharing-stage="true"
              text-classes="text-sm font-medium"
            />
            <p
              v-if="field.decoratingContentType"
              class="pl-1 text-gray-500 text-xs"
            >
              {{ _.startCase(field.decoratingContentType) }}
            </p>
          </div>
          <div class="flex items-center space-x-2">
            <button
              @click="remove(field)"
              v-tooltip="'Remove from stage'"
              type="button"
              class="h-5 w-5 p-1 inline-flex justify-center items-center text-sm text-gray-500 hover:text-gray-700"
            >
              <i class="fas fa-times" />
            </button>
          </div>
        </li>
        <li
          v-if="sharingDataFields.length === 0"
          class="text-center p-4"
          data-test="sharing-stage-empty-data-field-state"
        >
          <svg
            class="mx-auto h-12 w-12 text-gray-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
            />
          </svg>

          <h3 class="mt-2 text-sm font-medium text-gray-900">Stage is empty</h3>
          <p class="mt-1 text-sm text-gray-500">
            Add safezone data, then share it.
          </p>
        </li>
      </ul>

      <div class="flex items-center space-x-2 px-4">
        <button
          type="submit"
          class="flex-grow rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50"
          data-test="sharing-stage-submit-button"
        >
          <PulseLoader
            v-if="actionPending"
            :loading="true"
            size="6px"
            color="#f3f4f6"
          />
          <template v-else>
            Share<template v-if="hasSubsidy"> and pay</template>
          </template>
        </button>
        <button
          v-if="hasRecipients || sharingDataFields.length > 0"
          @click="clearSharing"
          v-tooltip="'Discard'"
          type="button"
          class="inline-flex flex-shrink-0 items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          <svg
            class="h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M8.75 1A2.75 2.75 0 006 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 10.23 1.482l.149-.022.841 10.518A2.75 2.75 0 007.596 19h4.807a2.75 2.75 0 002.742-2.53l.841-10.52.149.023a.75.75 0 00.23-1.482A41.03 41.03 0 0014 4.193V3.75A2.75 2.75 0 0011.25 1h-2.5zM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4zM8.58 7.72a.75.75 0 00-1.5.06l.3 7.5a.75.75 0 101.5-.06l-.3-7.5zm4.34.06a.75.75 0 10-1.5-.06l-.3 7.5a.75.75 0 101.5.06l.3-7.5z"
              clip-rule="evenodd"
            />
          </svg>
        </button>
      </div>

      <div class="px-4 mt-4 flex items-center justify-between">
        <a
          v-close-popper
          @click.prevent="viewSafezone"
          href=""
          class="text-sm font-medium text-indigo-600 hover:text-indigo-500"
          >View Safezone</a
        >
        <button
          v-close-popper
          @click="getHelp"
          type="button"
          v-tooltip="'Get help'"
          class="justify-self-end"
        >
          <QuestionMarkCircleIcon class="h-5 w-5 text-gray-700" />
        </button>
      </div>
    </form>
  </div>
</template>

<script setup>
import DataField from "@/components/crowdsourcing/DataField.vue";
import SharingAddressBar from "@/components/crowdsourcing/safezone/SharingAddressBar.vue";
import SubscribeVue from "@/components/users/SubscribeVue.vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { useUserStore } from "@/stores/user";
import { useSharingStore } from "@/stores/sharing";
import { useModalStore } from "@/stores/modal";
import { useDocumentationStore } from "@/stores/documentation";
import api from "@/router/api";
import { storeToRefs } from "pinia";
import { ref, computed, markRaw } from "vue";
import _ from "lodash";
import { useRoute, useRouter } from "vue-router";
import { QuestionMarkCircleIcon } from "@heroicons/vue/20/solid";
import { shareDatapoints } from "@/assets/documentation/articles";

const userStore = useUserStore();
const { currentUser, availableBalance } = storeToRefs(userStore);
const sharingStore = useSharingStore();
const { sharingDataFields, userRecipients, teamRecipients } =
  storeToRefs(sharingStore);
const documentationStore = useDocumentationStore();
function getHelp() {
  documentationStore.viewArticle(shareDatapoints);
}

const actionPending = ref(false);

const productTier = computed(() => currentUser.value.productTier);
const sharingPayload = computed(() => {
  return {
    ids: sharingDataFields.value.map((field) => field.localId),
    userRecipients: userRecipients.value.map((person) => {
      if (person.className === "RawEmail") {
        return {
          email: person.email,
          className: "RawEmail",
          payStructure: person.payStructure,
        };
      } else {
        return person;
      }
    }),
    teamRecipients: teamRecipients.value,
  };
});
const combined = computed(() => {
  const concatted = _.concat(userRecipients.value, teamRecipients.value);

  return _.sortBy(concatted, ["name"]);
});
const hasRecipients = computed(() => {
  return combined.value.length > 0;
});
const hasSubsidy = computed(() => {
  return (
    hasRecipients.value &&
    combined.value.some((recipient) => {
      return recipient.payStructure.value !== "recipient";
    })
  );
});
const complete = computed(() => {
  return hasRecipients.value && sharingDataFields.value.length > 0;
});

const router = useRouter();
const route = useRoute();

function viewSafezone() {
  router.push({
    name: route.name,
    query: {
      ...route.query,
      horizontalTab: "Me",
      verticalTab: "Safezone",
    },
  });
}

function remove(field) {
  sharingStore.removeFromDataFieldSharing([field]);
}
function attemptShare() {
  setTimeout(() => {
    if (complete.value) {
      const needsUpgrade =
        availableBalance.value < 0.1 && productTier.value === "gatherer";
      if (hasSubsidy.value && needsUpgrade) {
        upgrade();
      } else {
        share();
      }
    } else if (hasRecipients.value) {
      // this.$store.dispatch("flash", "Please add data to share");
    } else {
      // this.$store.dispatch("flash", "Please add recipients");
    }
  }, 125);
}
function share() {
  actionPending.value = true;
  api.post(`safezone_data_field_sharings`, sharingPayload.value).then(
    () => {
      // this.$store.dispatch("flash", "Safezone data shared!");
      clearSharing();
      actionPending.value = false;
    },
    () => {
      // this.$store.dispatch("flash", "Sharing attempt unsuccessful");
      actionPending.value = false;
    },
  );
}

const modalStore = useModalStore();
const { modalPayload } = storeToRefs(modalStore);

function upgrade() {
  modalPayload.value = {
    size: "base",
    theme: "light",
    component: markRaw(SubscribeVue),
    props: {
      newPlan: "tracker",
      billingFrequency: "monthly",
      promptReason: null,
      context: null,
    },
    afterClose: share,
    afterCloseDestination: null,
  };
}
function clearSharing() {
  sharingStore.reset();
  document.getElementById(`sharing-stage-autocomplete-input`).focus();
}
</script>
