<template>
  <div>
    <div class="relative my-1">
      <div class="absolute inset-0 flex items-center" aria-hidden="true">
        <div class="w-full border-t border-gray-300" />
      </div>
      <div class="relative flex justify-center">
        <span class="font-semibold bg-white px-2 text-xs text-gray-500"
          >Or, input correctly and earn $</span
        >
      </div>
    </div>
    <component
      :is="proofComponent"
      v-bind="inputProps"
      @completed="completed"
      @unlocked="unlocked"
    />
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useProveForValueStore } from "@/stores/proveForValue";
import { storeToRefs } from "pinia";
import _ from "lodash";

const props = defineProps([
  "dataField",
  "userStore",
  "modalStore",
  "unlockerStore",
  "parentComponentSaveFunction",
]);
const emit = defineEmits(["completed", "unlocked"]);

const proveForValueStore = useProveForValueStore();
const { proofComponent, proofProps } = storeToRefs(proveForValueStore);

const inputProps = computed(() => {
  return _.merge(
    {},
    {
      challengeDataField: props.dataField,
      proveForValue: true,
      parentComponentSaveFunction: props.parentComponentSaveFunction,
    },
    proofProps.value,
  );
});

function unlocked(payload) {
  console.log("prove for value unlocked");
  emit("unlocked", payload);
}
function completed(payload) {
  console.log("prove for value completed");
  emit("completed", payload);
}
</script>
