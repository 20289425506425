<template>
  <div class="flex-grow p-2 space-y-6 bg-gray-100">
    <div class="mt-3">
      <h3 class="text-sm font-semibold text-gray-600">Find answers quickly</h3>
      <div
        class="mt-3 divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-md ring-1 ring-black ring-opacity-5"
      >
        <HelpAutocomplete :placeholder="'How can we help?'" />
      </div>
    </div>
    <div>
      <div class="flex items-center space-x-1">
        <LifebuoyIcon class="h-4 w-4 text-gray-600" />
        <h3 class="text-sm font-semibold text-gray-600">Explore help topics</h3>
      </div>
      <ul class="mt-3 flex flex-col space-y-2">
        <a
          v-for="topic in topics"
          :key="topic.id"
          href=""
          @click.prevent="documentationStore.viewArticle(topic)"
          ><button
            type="button"
            class="w-full inline-flex items-center gap-x-2 rounded-md bg-white px-5 py-3 text-sm font-semibold text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            <component
              :is="topic.icon"
              class="-ml-0.5 h-5 w-5"
              :class="topic.iconColor"
              aria-hidden="true"
            />
            {{ topic.name }}
          </button></a
        >
      </ul>
    </div>
    <div>
      <div class="flex items-center space-x-1">
        <QuestionMarkCircleIcon class="h-4 w-4 text-gray-600" />
        <h3 class="text-sm font-semibold text-gray-600">Help categories</h3>
      </div>
      <ul class="mt-3 pl-5 flex flex-col space-y-2">
        <a
          v-for="category in visibleCategories"
          :key="category.id"
          href=""
          @click.prevent="documentationStore.viewCategory(category)"
          class="text-sm font-medium text-indigo-600 hover:text-indigo-900 hover:font-semibold"
          >{{ category.name }}&nbsp;&rarr;</a
        >
      </ul>
    </div>
  </div>

  <div class="flex items-center justify-end p-2">
    <a
      href="mailto:team@towerhunt.com"
      target="_blank"
      rel="noopener noreferrer"
      class="hidden md:inline-flex whitespace-nowrap border border-gray-500 bg-white rounded-md p-2 text-sm leading-4 font-medium text-gray-500 hover:bg-gray-50"
      data-test="collectible-cards-sign-up-button"
      >Contact Us</a
    >
  </div>
</template>

<script setup>
import { LifebuoyIcon } from "@heroicons/vue/24/outline";
import { useDocumentationStore } from "@/stores/documentation";
import { storeToRefs } from "pinia";
import HelpAutocomplete from "@/components/help-center/HelpAutocomplete.vue";
import { QuestionMarkCircleIcon } from "@heroicons/vue/20/solid";

const documentationStore = useDocumentationStore();
const { visibleCategories, topics } = storeToRefs(documentationStore);
</script>
