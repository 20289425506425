<template>
  <div class="bg-white overflow-y-auto h-full p-2">
    <ContentTiming
      v-if="investment.id"
      :data-field="dataField"
      @unlocked="refetchDealBuilder"
    />
    <dl v-else-if="tempDataField" class="grid grid-cols-2 gap-x-4 gap-y-8">
      <div class="col-span-1">
        <dt class="text-sm font-medium text-gray-500">State</dt>
        <dd class="mt-1 space-y-2">
          <DateStateField
            event-type="investmentTiming"
            :data-field="tempDataField"
            :state="state"
            :date="date"
            :compact="false"
            @new-date="setNewDate"
            @new-state="setNewState"
          />
        </dd>
      </div>
    </dl>
  </div>
</template>

<script setup>
import ContentTiming from "@/components/crowdsourcing/ContentTiming.vue";
import DateStateField from "@/components/crowdsourcing/DateStateField.vue";
import { useDealBuilderStore } from "@/stores/dealBuilder";
import { useTimeTravelStore } from "@/stores/timeTravel";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import _ from "lodash";
import decoratingAndFieldKey from "@/components/crowdsourcing/decoratingAndFieldKey";

const props = defineProps(["assetObject", "dataField"]);

const dealBuilderStore = useDealBuilderStore();
const { dealBuilder, refetchDealBuilderEditor } = storeToRefs(dealBuilderStore);
const timeTravelStore = useTimeTravelStore();

const investment = computed(() => _.get(props.dataField, "fieldContent"));

const tempDataField = computed(() => {
  if (
    investment.value?.capitalStackTopLevelAsset &&
    investment.value?.investmentFieldContent
  ) {
    const assetKey = decoratingAndFieldKey(
      investment.value.capitalStackTopLevelAsset,
    );
    const investmentKey = decoratingAndFieldKey(
      investment.value.investmentFieldContent,
    );

    return { temporaryId: `${assetKey}_${investmentKey}` };
  } else {
    return null;
  }
});
const date = computed({
  get() {
    return investment.value.date;
  },
  set(newVal) {
    let newInvestment = _.merge({}, investment.value);

    newInvestment.date = newVal;
    updateStoreInvestment(newInvestment, "date");
  },
});
const state = computed({
  get() {
    return investment.value.state;
  },
  set(newVal) {
    let newInvestment = _.merge({}, investment.value);

    newInvestment.state = newVal;
    updateStoreInvestment(newInvestment, "state");
  },
});
const matchingInvestmentGroup = computed(() => {
  return _.find(
    _.get(dealBuilder.value, "investmentGroups", []),
    function (group) {
      return _.find(group.investments, function (groupInvestment, combinedKey) {
        const localCombinedKey =
          props.dataField?.temporaryId ||
          props.dataField?.fieldContent?.temporaryId;
        if (_.get(investment.value, "existingInvestment.id")) {
          return (
            _.get(groupInvestment, "existingInvestment.id") ===
            investment.value.existingInvestment.id
          );
        } else if (_.get(investment.value, "id")) {
          return _.get(groupInvestment, "id") === investment.value.id;
        } else if (_.get(investment.value, "investmentGroupId")) {
          return (
            _.get(groupInvestment, "investmentGroupId") ===
            investment.value.investmentGroupId
          );
        } else if (_.get(investment.value, "temporaryId")) {
          return (
            _.get(groupInvestment, "temporaryId") ===
            investment.value.temporaryId
          );
        } else if (localCombinedKey) {
          return combinedKey === localCombinedKey;
        } else {
          return false;
        }
      });
    },
  );
});

function setNewDate(newDate) {
  console.log("current date", date.value, "new date", newDate);
  date.value = newDate;
}
function setNewState(newState) {
  console.log("current state", state.value, "new state", newState);
  state.value = newState;
}
function updateStoreInvestment(newInvestment, fieldName = null) {
  if (matchingInvestmentGroup.value) {
    console.log("has matching group");
    const existingGroupId = _.get(matchingInvestmentGroup.value, "id");
    const placeholderGroupId = _.get(
      matchingInvestmentGroup.value,
      "placeholderId",
    );

    dealBuilderStore.updateInvestmentGroupInvestment({
      groupId: existingGroupId || placeholderGroupId,
      newInvestment,
      fieldName,
    });
  } else if (investment.value.id && props.assetObject) {
    console.log("has investment id and asset");
    dealBuilderStore.updateDealBuilderAssetInvestments({
      assetKey: decoratingAndFieldKey(props.assetObject.dataField),
      investments: [newInvestment],
    });
  } else {
    console.log("INVESTMENT TIMING nothing to update!");
  }
}
function refetchDealBuilder() {
  if (props.assetObject?.dataField) {
    refetchDealBuilderEditor.value = decoratingAndFieldKey(
      props.assetObject.dataField,
    );
    timeTravelStore.triggerRefetch();
  }
}
</script>
