<template>
  <section aria-labelledby="uses">
    <ContentHeader>
      <template v-slot:title>Rooms</template>
      <template v-slot:description
        >Add rooms based upon the floor area use(s).</template
      >
    </ContentHeader>

    <ContentBody v-if="fetchedFloorArea">
      <LayoutPartitionCreate
        v-if="creatingRooms"
        class=""
        :floor-area="fetchedFloorArea"
        :layout-data-field="dataField"
        @completed="roomsCreated"
        @cancel="creatingRooms = false"
      />

      <div v-else class="flex items-start">
        <DataVisibilityButton visibility="safezone">
          <template v-slot:button>
            <button
              @click="creatingRooms = true"
              type="button"
              data-test="add-layout-rooms-button"
              class="group py-1 px-1.5 flex items-center justify-between rounded-full border border-gray-300 shadow-sm space-x-2 text-left hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <span class="min-w-0 flex-1 flex items-center space-x-1">
                <span class="flex-shrink-0 flex items-center">
                  <span
                    class="inline-flex items-center justify-center h-5 w-5 rounded-full bg-yellow-500"
                  >
                    <PencilIcon class="h-3 w-3 text-white" />
                  </span>
                </span>
                <span class="min-w-0 flex-1">
                  <span class="text-sm font-medium text-gray-900 truncate"
                    >Add rooms</span
                  >
                </span>
              </span>
            </button>
          </template>
        </DataVisibilityButton>
      </div>

      <FloorAreaLayoutPartitionGroup
        v-for="(roomFields, roomType) in groupedRooms"
        :key="roomType"
        :room-type="roomType"
        :room-fields="roomFields"
        @refetch="fetchRooms"
      />
    </ContentBody>
  </section>
</template>

<script setup>
import { ref, computed, watch, onMounted } from "vue";
import { PencilIcon } from "@heroicons/vue/24/outline";
import ContentBody from "@/components/main-layout/secondary-panel/ContentBody.vue";
import ContentHeader from "@/components/main-layout/secondary-panel/ContentHeader.vue";
import LayoutPartitionCreate from "@/components/crowdsourcing/LayoutPartitionCreate.vue";
import api from "@/router/api";
import _ from "lodash";
import DataVisibilityButton from "@/components/crowdsourcing/DataVisibilityButton.vue";
import FloorAreaLayoutPartitionGroup from "@/components/crowdsourcing/FloorAreaLayoutPartitionGroup.vue";

const props = defineProps(["dataField"]);

const rooms = ref([]);
const roomsLoaded = ref(false);
const fetchedFloorArea = ref(null);
const creatingRooms = ref(false);

const dataFieldLocalId = computed(() => {
  return _.get(props.dataField, "localId");
});
const groupedRooms = computed(() => {
  return _.groupBy(rooms.value, function (df) {
    return df.fieldContent?.type || "Unlockable";
  });
});
watch(dataFieldLocalId, () => {
  if (props.dataField) {
    fetchRooms();
    fetchFloorArea();
  }
});
onMounted(() => {
  fetchRooms();
  fetchFloorArea();
});
function fetchFloorArea() {
  api.get(`floor_areas/${props.dataField.decoratingContentId}`).then((json) => {
    fetchedFloorArea.value = json.data;
  });
}
function roomsCreated({ dataFields }) {
  creatingRooms.value = false;
  if (dataFields) {
    patchRooms(dataFields);
  } else {
    fetchRooms();
  }
}
function patchRooms(dataFields) {
  const newRooms = _.unionBy(dataFields, rooms.value, "localId");

  rooms.value = newRooms;
}
function fetchRooms() {
  api
    .get(
      `crowdsourced_data_fields/FloorAreaLayout/${props.dataField.fieldContentId}?field_name=LayoutPartition`,
    )
    .then((json) => {
      rooms.value = json.data;
      roomsLoaded.value = true;
    });
}
</script>
