<template>
  <div class="">
    <div class="p-2 flex items-center justify-between">
      <h1 class="font-semibold text-gray-900">
        {{
          fieldContentType === "InvestmentGroup" ? "Portfolio " : "Asset "
        }}Loan
      </h1>
    </div>
    <div class="flex flex-col">
      <ViewPortfolioAlert
        v-if="hasPortfolioLoan"
        content-type="Loan"
        @open-portfolio="emit('open-portfolio')"
      />
      <div v-if="loaded && loans.length > 0" class="overflow-x-auto">
        <div class="inline-block min-w-full py-2 align-middle">
          <div class="shadow ring-1 ring-black ring-opacity-5">
            <table class="min-w-full divide-y divide-gray-300">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="relative whitespace-nowrap py-3.5 pl-4 pr-3"
                  >
                    <span class="sr-only">Expand</span>
                  </th>
                  <th
                    scope="col"
                    class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Amount
                  </th>
                  <th
                    scope="col"
                    class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Term
                  </th>
                  <th
                    scope="col"
                    class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Maturity Date
                  </th>
                  <th
                    scope="col"
                    class="whitespace-nowrap py-3.5 pl-3 pr-4 text-left text-sm font-semibold text-gray-900"
                  >
                    Rate
                  </th>
                </tr>
              </thead>
              <LoanSyntheticTableRow
                v-for="loan in loans"
                :key="loan.fieldContentId"
                :loan="loan"
                :field-content-type="fieldContentType"
                :field-content="fieldContent"
                @refetch="fetchLoans"
              />
            </table>
          </div>
        </div>
      </div>
      <div v-else-if="!fieldContent.id" class="mt-4 pb-4 text-center">
        <svg
          class="mx-auto h-8 w-8 text-gray-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
        <h3 class="mt-2 text-sm font-medium text-gray-900">
          Unsaved {{ fieldContentType }}
        </h3>
        <p class="mt-1 text-sm text-gray-500">
          Once you save, loan details may be added here.
        </p>
      </div>
      <!-- EMPTY STATE -->
      <div
        v-else-if="
          loaded && fieldContentType === 'InvestmentGroup' && singleSeniority
        "
        class="text-center"
      >
        <ReceiptPercentIcon class="mx-auto h-12 w-12 text-gray-400" />
        <h3 class="mt-2 text-sm font-medium text-gray-900">
          No portfolio loan
        </h3>
        <p class="mt-1 text-sm text-gray-500">
          Treat the portfolio assets as collateral for a single loan.
        </p>
        <div class="mt-6">
          <DataVisibilityButton visibility="safezone" class="inline-flex">
            <template v-slot:button>
              <button
                @click="newPortfolioLoan"
                :disabled="originatingData"
                type="button"
                data-test="add-new-portfolio-loan-button"
                class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-500 hover:bg-yellow-600 focus:ring-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2"
              >
                <PulseLoader
                  v-if="originatingData"
                  :loading="true"
                  size="3px"
                  color="#f3f4f6"
                />
                <span v-else class="min-w-0 flex-1 flex items-center space-x-1">
                  <PlusIcon class="-ml-1 mr-2 h-5 w-5" />
                  Add Portfolio Loan
                </span>
              </button>
            </template>
          </DataVisibilityButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import LoanSyntheticTableRow from "@/components/deal-builder/LoanSyntheticTableRow.vue";
import DataVisibilityButton from "@/components/crowdsourcing/DataVisibilityButton.vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { useDealBuilderStore } from "@/stores/dealBuilder";
import { useCrowdsourcedChangeGroupStore } from "@/stores/crowdsourcedChangeGroup";
import { ref, onMounted, computed, nextTick } from "vue";
import { storeToRefs } from "pinia";
import { PlusIcon } from "@heroicons/vue/20/solid";
import { ReceiptPercentIcon } from "@heroicons/vue/24/outline";
import api from "@/router/api";
import _ from "lodash";
import ViewPortfolioAlert from "./ViewPortfolioAlert.vue";

const props = defineProps(["fieldContent", "fieldContentType", "hasPortfolio"]);
const emit = defineEmits(["open-portfolio"]);
const changeGroupStore = useCrowdsourcedChangeGroupStore();
const { originatingData, changeGroupId } = storeToRefs(changeGroupStore);

const loans = ref([]);
const loaded = ref(false);

const dealBuilderStore = useDealBuilderStore();

const singleSeniority = computed(() => {
  if (props.fieldContentType === "InvestmentGroup") {
    const seniorities = _.map(
      props.fieldContent.investments,
      function (invObject) {
        return invObject.existingInvestment.loanSeniority;
      },
    );
    const compacted = _.compact(seniorities);

    return _.uniq(compacted).length === 1;
  } else {
    return false;
  }
});
const fetchRequestKey = computed(
  () => `content_loans_${props.fieldContentType}_${props.fieldContent.id}`,
);
const hasPortfolioLoan = computed(() => {
  const portfolioLoanExists = _.some(loans.value, function (dataField) {
    return dataField.fieldContent.portfolioLoanId;
  });
  return props.hasPortfolio && portfolioLoanExists;
});

onMounted(() => fetchLoans());

async function fetchLoans() {
  if (props.fieldContent.id) {
    loaded.value = false;

    await nextTick();

    if (dealBuilderStore.alreadyFetched(fetchRequestKey.value)) {
      loans.value = dealBuilderStore.alreadyFetchedFieldsFor(
        fetchRequestKey.value,
      );
    } else {
      const loansResponse = await api.get(
        `content_loans/${props.fieldContentType}/${props.fieldContent.id}`,
      );

      dealBuilderStore.interceptablePatch(
        loansResponse.data,
        fetchRequestKey.value,
      );
      loans.value = loansResponse.data;
    }

    loaded.value = true;
  }
}

async function newPortfolioLoan() {
  if (singleSeniority.value) {
    const payload = {
      changeGroupId: changeGroupId.value,
    };
    const apiRequestFunc = () =>
      api.post(
        `content_loans/${props.fieldContentType}/${props.fieldContent.id}`,
        payload,
      );
    const successCallback = async (json) => {
      await dealBuilderStore.postEditingPatch(json, fetchRequestKey.value);
      fetchLoans();
    };
    const failureCallback = () => {};

    await changeGroupStore.originateData(
      apiRequestFunc,
      successCallback,
      failureCallback,
    );
  }
}
</script>
