<template>
  <section
    aria-labelledby="companies"
    :class="singleColumn ? 'grid-cols-1' : 'grid-cols-2 grid-flow-row-dense'"
    class="grid gap-3"
  >
    <InvolvedCompany
      v-for="companyId in rawGroupCompanies"
      :key="`group-company-${companyId}`"
      :fetch-company-id="companyId"
      layer-type="Advisor"
      layer-color="bg-teal-400"
      :investment-group-id="investmentGroupId"
      :availability-group-id="availabilityGroupId"
      :context="attachingContext"
      :client-role="attachingClientRole"
      :fetch-milliseconds="fetchMilliseconds"
      :parent-component-save-function="parentComponentSaveFunction"
      @refetch="fetchInvolvedCompanies"
      @override-refetch="fetchInvolvedCompanies"
    />

    <InvolvedCompany
      v-for="companyInvolvementDataField in companies"
      :key="`company-${companyInvolvementDataField.fieldContentId}`"
      :decorating-content-data-field="companyInvolvementDataField"
      layer-type="Advisor"
      layer-color="bg-teal-400"
      :context="attachingContext"
      :investment-id="investmentId"
      :availability-id="availabilityId"
      :fetch-milliseconds="fetchMilliseconds"
      :parent-component-save-function="parentComponentSaveFunction"
      @refetch="fetchInvolvedCompanies"
      @override-refetch="fetchInvolvedCompanies"
    />

    <StandaloneContact
      v-for="contactDataField in contacts"
      :key="`contact-${contactDataField.fieldContentId}`"
      :decorating-content-data-field="contactDataField"
      layer-type="Advisor"
      layer-color="bg-cyan-400"
      :context="attachingContext"
      @refetch="fetchInvolvedContacts"
    />

    <div v-if="creatingCompany" class="mt-1 flex flex-col space-y-1">
      <div
        class="border border-gray-300 rounded-lg shadow-sm focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500"
      >
        <CompanyContactAutocomplete
          :input-key="`${context}-advisor`"
          label="Advisors"
          :companies="addedCompanies"
          @new-companies="setNewCompanies"
          @remove-company="removeCompany"
        />
      </div>
      <div class="flex items-center justify-end space-x-2">
        <button
          @click="clearCompanyEditing"
          type="button"
          class="inline-flex items-center p-1 border border-gray-300 rounded-full shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <XMarkIcon class="h-4 w-4" />
        </button>

        <DataVisibilityButton visibility="safezone" class="inline-flex">
          <template v-slot:button>
            <button
              @click="saveCompanies"
              :disabled="originatingData"
              type="button"
              class="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-yellow-500 hover:bg-yellow-600 focus:ring-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2"
              :data-test="`${context}-advisor-companies-save`"
            >
              <PulseLoader
                v-if="originatingData"
                :loading="true"
                size="3px"
                color="#f3f4f6"
              />
              <CheckIcon v-else class="h-4 w-4" />
            </button>
          </template>
        </DataVisibilityButton>
      </div>
    </div>

    <div v-if="creatingContact" class="mt-1 flex flex-col space-y-1">
      <div
        class="border border-gray-300 rounded-lg shadow-sm focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500"
      >
        <ContactAutocomplete
          label="Advisors"
          :selections="addedContacts"
          :input-key="`${context}-advisor`"
          :focus="true"
          :remove-inline="true"
          @new-contacts="setNewContacts"
          @remove="removeContact"
        />
      </div>
      <div class="flex items-center justify-end space-x-2">
        <button
          @click="clearContactEditing"
          type="button"
          class="inline-flex items-center p-1 border border-gray-300 rounded-full shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <XMarkIcon class="h-4 w-4" />
        </button>

        <DataVisibilityButton visibility="safezone" class="inline-flex">
          <template v-slot:button>
            <button
              @click="saveContacts"
              :disabled="originatingData"
              type="button"
              class="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-yellow-500 hover:bg-yellow-600 focus:ring-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2"
              :data-test="`${context}-advisor-contacts-save`"
            >
              <PulseLoader
                v-if="originatingData"
                :loading="true"
                size="3px"
                color="#f3f4f6"
              />
              <CheckIcon v-else class="h-4 w-4" />
            </button>
          </template>
        </DataVisibilityButton>
      </div>
    </div>

    <div v-if="!creating" class="flex items-center">
      <DataVisibilityButton visibility="safezone">
        <template v-slot:button>
          <button
            @click="creatingCompany = true"
            type="button"
            :data-test="`add-${context}-advisor-companies`"
            class="group py-0.5 px-1 flex items-center justify-between rounded-full border border-gray-300 shadow-sm space-x-2 text-left hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <span class="min-w-0 flex-1 flex items-center space-x-1">
              <span class="flex-shrink-0 flex items-center">
                <span
                  class="inline-flex items-center justify-center h-5 w-5 rounded-full bg-yellow-500"
                >
                  <BriefcaseIcon class="h-4 w-4 text-white" />
                </span>
              </span>
              <span class="min-w-0 flex-1">
                <span class="text-xs font-medium text-gray-900 truncate"
                  >Add a company</span
                >
              </span>
            </span>
          </button>
        </template>
      </DataVisibilityButton>
    </div>

    <div
      v-if="!creating && standaloneContactEligibleContext"
      class="flex items-center"
    >
      <DataVisibilityButton visibility="safezone">
        <template v-slot:button>
          <button
            @click="creatingContact = true"
            type="button"
            :data-test="`add-${context}-advisor-contacts`"
            class="group py-1 px-1.5 flex items-center justify-between rounded-full border border-gray-300 shadow-sm space-x-2 text-left hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <span class="min-w-0 flex-1 flex items-center space-x-1">
              <span class="flex-shrink-0 flex items-center">
                <span
                  class="inline-flex items-center justify-center h-5 w-5 rounded-full bg-yellow-500"
                >
                  <PencilIcon class="h-3 w-3 text-white" />
                </span>
              </span>
              <span class="min-w-0 flex-1">
                <span class="text-sm font-medium text-gray-900 truncate"
                  >Add a standalone contact</span
                >
              </span>
            </span>
          </button>
        </template>
      </DataVisibilityButton>
    </div>
  </section>
</template>

<script setup>
import { BriefcaseIcon, PencilIcon } from "@heroicons/vue/24/outline";
import { XMarkIcon, CheckIcon } from "@heroicons/vue/20/solid";
import CompanyContactAutocomplete from "@/components/crowdsourcing/CompanyContactAutocomplete.vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import ContactAutocomplete from "@/components/crowdsourcing/ContactAutocomplete.vue";
import InvolvedCompany from "@/components/crowdsourcing/InvolvedCompany.vue";
import StandaloneContact from "@/components/crowdsourcing/StandaloneContact.vue";
import { useCrowdsourcedChangeGroupStore } from "@/stores/crowdsourcedChangeGroup";
import { useDealBuilderStore } from "@/stores/dealBuilder";
import { useSpaceUsageBuilderStore } from "@/stores/spaceUsageBuilder";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { useTimeTravelStore } from "@/stores/timeTravel";
import { ref, computed, watch, onMounted } from "vue";
import { storeToRefs } from "pinia";
import api from "@/router/api";
import _ from "lodash";
import DataVisibilityButton from "@/components/crowdsourcing/DataVisibilityButton.vue";

const props = defineProps([
  "dataField",
  "optional",
  "context",
  "fetchMilliseconds",
  "fetchCompanyId",
  "investmentGroupId",
  "investmentId",
  "availabilityId",
  "availabilityGroupId",
]);

const layoutStore = useWorkspaceLayoutStore();
const { workspaceLayout } = storeToRefs(layoutStore);
const changeGroupStore = useCrowdsourcedChangeGroupStore();
const { originatingData, changeGroupId } = storeToRefs(changeGroupStore);
const dealBuilderStore = useDealBuilderStore();
const spaceUsageBuilderStore = useSpaceUsageBuilderStore();
const timeTravelStore = useTimeTravelStore();
const { asOfMilliseconds } = storeToRefs(timeTravelStore);

const addedCompanies = ref([]);
const addedContacts = ref([]);
const rawGroupCompanies = ref([]);
const companies = ref([]);
const contacts = ref([]);
const creatingCompany = ref(false);
const creatingContact = ref(false);

const singleColumn = computed(() => {
  return (
    workspaceLayout.value === "sideBySide" ||
    _.includes(["prospect"], props.context)
  );
});
const contentId = computed(() => {
  return _.get(content.value, "token") || _.get(content.value, "id");
});
const content = computed(() => {
  return _.get(props.dataField, "fieldContent");
});
const contentType = computed(() => {
  return _.get(props.dataField, "fieldContentType");
});
const combinedId = computed(() => {
  return `${contentType.value}${contentId.value}`;
});
const creating = computed(() => {
  return creatingCompany.value || creatingContact.value;
});
const fieldSubType = computed(() => {
  switch (props.context) {
    case "controlling-asset":
      return "operational_advisor";
    case "prospect":
      return "prospect_advisor_partner";
    default:
      return null;
  }
});
const standaloneContactEligibleContext = computed(() => {
  return !_.includes(["controlling-asset", "prospect"], props.context);
});
const attachingContext = computed(() => {
  if (_.includes(["prospect"], props.context)) {
    if (props.investmentGroupId) {
      return "investment-group-prospect-advisor-partner";
    } else if (props.availabilityGroupId) {
      return "availability-group-prospect-advisor-partner";
    } else {
      return "prospect-advisor-partner";
    }
  } else {
    return "contacts";
  }
});
const attachingClientRole = computed(() => {
  if (_.includes(["prospect"], props.context)) {
    if (props.investmentGroupId || props.availabilityGroupId) {
      return "prospectAdvisorPartner";
    } else {
      return null;
    }
  } else {
    return null;
  }
});

watch(asOfMilliseconds, async () => {
  if (props.context === "controlling-asset") {
    dealBuilderStore.clearDealBuilder(false);
    fetchAll();
  }
});
watch(combinedId, () => {
  fetchAll();
});
onMounted(() => {
  fetchAll();
});

function fetchAll() {
  fetchInvolvedCompanies();
  fetchInvolvedContacts();
}
function setNewCompanies(newCompanies) {
  addedCompanies.value = newCompanies;
}
function removeCompany(company) {
  const filteredCompanies = _.difference(addedCompanies.value, [company]);

  setNewCompanies(filteredCompanies);
}
function clearCompanyEditing() {
  creatingCompany.value = false;
  addedCompanies.value = [];
}
function setNewContacts(newContacts) {
  addedContacts.value = newContacts;
}
function removeContact(contact) {
  const filteredContacts = _.differenceBy(
    addedContacts.value,
    [contact],
    "name",
  );

  setNewContacts(filteredContacts);
}
function clearContactEditing() {
  creatingContact.value = false;
  addedContacts.value = [];
}
async function companyCreated(json) {
  clearCompanyEditing();
  if (props.availabilityId || props.availabilityGroupId) {
    await spaceUsageBuilderStore.postEditingPatch(
      json,
      companyFetchRequestKey.value,
    );
  } else {
    await dealBuilderStore.postEditingPatch(json, companyFetchRequestKey.value);
  }

  if (json?.data && fieldSubType.value === "operational_advisor") {
    const newInvolvementFields = json.data.dataFields.filter(
      ({ fieldContentSubType }) =>
        fieldContentSubType === "operational_advisor",
    );
    companies.value = _.unionBy(
      newInvolvementFields,
      companies.value,
      "localId",
    );
  } else {
    fetchInvolvedCompanies();
  }
}
async function contactCreated(json) {
  creatingContact.value = false;
  await dealBuilderStore.postEditingPatch(json, contactFetchRequestKey.value);
  fetchInvolvedContacts();
}

const subTypeKey = computed(() => {
  return fieldSubType.value ? `&field_sub_type=${fieldSubType.value}` : "";
});
const companyFetchRequestKey = computed(() => {
  if (fieldSubType.value === "operational_advisor") {
    return `operational_advisors_${contentType.value}_${contentId.value}`;
  } else {
    return `crowdsourced_data_fields_${contentType.value}_${contentId.value}?field_name=CompanyInvolvement${subTypeKey.value}`;
  }
});

async function fetchInvolvedCompanies(emitPayload = {}) {
  console.log("content companies fetch involved companies", emitPayload);
  if (props.investmentGroupId) {
    if (props.fetchCompanyId) {
      console.log(
        "fetch prospect advisors for company id",
        props.fetchCompanyId,
      );
      if (_.isNumber(props.fetchCompanyId)) {
        api
          .get(
            `investment_group_prospect_advisor_companies/?investment_group_id=${props.investmentGroupId}&raw_company_id=${props.fetchCompanyId}`,
          )
          .then((json) => {
            rawGroupCompanies.value = json.data;
          });
      } else {
        api
          .get(
            `investment_group_prospect_advisor_companies/?investment_group_id=${props.investmentGroupId}&field_id=${props.fetchCompanyId.localId}`,
          )
          .then((json) => {
            rawGroupCompanies.value = json.data;
          });
      }
    } else {
      console.log("no company id to fetch");
    }
  } else if (props.availabilityGroupId) {
    if (props.fetchCompanyId) {
      console.log(
        "fetch prospect advisors for company id",
        props.fetchCompanyId,
      );
      if (_.isNumber(props.fetchCompanyId)) {
        api
          .get(
            `availability_group_prospect_advisor_companies/?availability_group_id=${props.availabilityGroupId}&raw_company_id=${props.fetchCompanyId}`,
          )
          .then((json) => {
            rawGroupCompanies.value = json.data;
          });
      } else {
        api
          .get(
            `availability_group_prospect_advisor_companies/?availability_group_id=${props.availabilityGroupId}&field_id=${props.fetchCompanyId.localId}`,
          )
          .then((json) => {
            rawGroupCompanies.value = json.data;
          });
      }
    } else {
      console.log("no company id to fetch");
    }
  } else {
    if (
      props.investmentId &&
      dealBuilderStore.alreadyFetched(companyFetchRequestKey.value) &&
      !emitPayload?.override
    ) {
      const alreadyFetched = dealBuilderStore.alreadyFetchedFieldsFor(
        companyFetchRequestKey.value,
      );

      companies.value = alreadyFetched.filter((cdf) => {
        return cdf.fieldContentType === "CompanyInvolvement";
      });
    } else if (
      props.availabilityId &&
      spaceUsageBuilderStore.alreadyFetched(companyFetchRequestKey.value) &&
      !emitPayload?.override
    ) {
      const alreadyFetched = spaceUsageBuilderStore.alreadyFetchedFieldsFor(
        companyFetchRequestKey.value,
      );

      companies.value = alreadyFetched.filter((cdf) => {
        return cdf.fieldContentType === "CompanyInvolvement";
      });
    } else {
      const endpoint =
        fieldSubType.value === "operational_advisor"
          ? `operational_advisors/${contentType.value}/${contentId.value}?as_of=${asOfMilliseconds.value}`
          : `crowdsourced_data_fields/${contentType.value}/${contentId.value}?field_name=CompanyInvolvement${subTypeKey.value}`;
      const companiesResponse = await api.get(endpoint);
      companies.value = companiesResponse.data;

      if (props.availabilityId) {
        spaceUsageBuilderStore.interceptablePatch(
          companiesResponse.data,
          companyFetchRequestKey.value,
        );
      } else if (props.investmentId) {
        dealBuilderStore.interceptablePatch(
          companiesResponse.data,
          companyFetchRequestKey.value,
        );
      }
    }

    if (_.get(emitPayload, "replaceAfterFetch", false)) {
      creatingCompany.value = true;
    }
  }
}

const contactFetchRequestKey = computed(
  () =>
    `crowdsourced_data_fields_${contentType.value}_${contentId.value}?field_name=Contact`,
);

async function fetchInvolvedContacts(emitPayload = {}) {
  if (props.investmentGroupId || props.availabilityGroupId) {
    if (props.fetchCompanyId) {
      console.log(
        "fetch prospect advisor standalone contacts for company id",
        props.fetchCompanyId,
      );
    } else {
      console.log("no company id to fetch");
    }
  } else {
    if (
      dealBuilderStore.alreadyFetched(contactFetchRequestKey.value) &&
      !emitPayload?.override
    ) {
      contacts.value = dealBuilderStore.alreadyFetchedFieldsFor(
        contactFetchRequestKey.value,
      );
    } else {
      const contactsResponse = await api.get(
        `crowdsourced_data_fields/${contentType.value}/${contentId.value}?field_name=Contact`,
      );

      contacts.value = contactsResponse.data;
      dealBuilderStore.interceptablePatch(
        contactsResponse.data,
        contactFetchRequestKey.value,
      );
    }

    if (_.get(emitPayload, "replaceAfterFetch", false)) {
      creatingContact.value = true;
    }
  }
}
function saveCompanies() {
  setTimeout(() => {
    if (props.investmentGroupId) {
      saveInvestmentGroupCompanies();
    } else if (props.availabilityGroupId) {
      saveAvailabilityGroupCompanies();
    } else {
      saveStandaloneCompanies();
    }
  }, 125);
}
const parentComponentSaveFunction = async (proofCompanies = null) => {
  let response = null;

  if (props.investmentGroupId || props.availabilityGroupId) {
    response = await persistGroupCompanies(proofCompanies);
  } else {
    response = await persistStandaloneCompanies(proofCompanies);
  }

  return response;
};
const persistGroupCompanies = async (proofCompanies = null) => {
  const payload = {
    investmentGroupId: props.investmentGroupId,
    availabilityGroupId: props.availabilityGroupId,
    companies: proofCompanies || addedCompanies.value,
    fieldSubType: fieldSubType.value,
    rawCompanyId: props.fetchCompanyId,
    changeGroupId: changeGroupId.value,
  };

  let response = null;

  if (props.investmentGroupId) {
    response = await api.post(
      `investment_group_prospect_advisor_companies`,
      payload,
    );
  } else if (props.availabilityGroupId) {
    response = await api.post(
      `availability_group_prospect_advisor_companies`,
      payload,
    );
  }

  console.log("group companies persist", proofCompanies, payload, response);

  return response;
};
function saveInvestmentGroupCompanies() {
  if (props.investmentGroupId && props.fetchCompanyId) {
    const apiRequestFunc = () => persistGroupCompanies();
    const successCallback = (json) => companyCreated(json);
    const failureCallback = () => clearCompanyEditing();

    return changeGroupStore.originateData(
      apiRequestFunc,
      successCallback,
      failureCallback,
    );
  }
}
function saveAvailabilityGroupCompanies() {
  if (props.availabilityGroupId && props.fetchCompanyId) {
    const apiRequestFunc = () => persistGroupCompanies();
    const successCallback = (json) => companyCreated(json);
    const failureCallback = () => clearCompanyEditing();

    return changeGroupStore.originateData(
      apiRequestFunc,
      successCallback,
      failureCallback,
    );
  }
}
const persistStandaloneCompanies = async (proofCompanies = null) => {
  const payload = {
    contentType: contentType.value,
    contentId: content.value.token || content.value.id,
    companies: proofCompanies || addedCompanies.value,
    fieldSubType: fieldSubType.value,
    changeGroupId: changeGroupId.value,
  };

  const response = await api.post(`company_involvements`, payload);

  console.log(
    "standalone companies persist",
    proofCompanies,
    payload,
    response,
  );

  return response;
};
function saveStandaloneCompanies() {
  const apiRequestFunc = () => persistStandaloneCompanies();
  const successCallback = (json) => companyCreated(json);
  const failureCallback = () => clearCompanyEditing();

  return changeGroupStore.originateData(
    apiRequestFunc,
    successCallback,
    failureCallback,
  );
}
function saveContacts() {
  if (props.investmentGroupId) {
    saveInvestmentGroupContacts();
  } else if (props.availabilityGroupId) {
    saveAvailabilityGroupContacts();
  } else {
    saveStandaloneContacts();
  }
}
function saveStandaloneContacts() {
  const payload = {
    contentType: contentType.value,
    contentId: content.value.token || content.value.id,
    contacts: addedContacts.value,
    changeGroupId: changeGroupId.value,
  };

  const apiRequestFunc = () => api.post(`contacts`, payload);
  const successCallback = (json) => contactCreated(json);
  const failureCallback = () => clearContactEditing();

  return changeGroupStore.originateData(
    apiRequestFunc,
    successCallback,
    failureCallback,
  );
}
function saveInvestmentGroupContacts() {
  if (props.investmentGroupId && props.fetchCompanyId) {
    const payload = {
      investmentGroupId: props.investmentGroupId,
      contacts: addedContacts.value,
      rawCompanyId: props.fetchCompanyId,
      changeGroupId: changeGroupId.value,
    };

    const apiRequestFunc = () =>
      api.post(`investment_group_prospect_advisor_contacts`, payload);
    const successCallback = (json) => contactCreated(json);
    const failureCallback = () => clearContactEditing();

    return changeGroupStore.originateData(
      apiRequestFunc,
      successCallback,
      failureCallback,
    );
  }
}
function saveAvailabilityGroupContacts() {
  if (props.availabilityGroupId && props.fetchCompanyId) {
    const payload = {
      availabilityGroupId: props.availabilityGroupId,
      contacts: addedContacts.value,
      rawCompanyId: props.fetchCompanyId,
      changeGroupId: changeGroupId.value,
    };

    const apiRequestFunc = () =>
      api.post(`availability_group_prospect_advisor_contacts`, payload);
    const successCallback = (json) => contactCreated(json);
    const failureCallback = () => clearContactEditing();

    return changeGroupStore.originateData(
      apiRequestFunc,
      successCallback,
      failureCallback,
    );
  }
}
</script>
