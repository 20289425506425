import ComingSoon from "@/components/ComingSoon.vue";
import OperationalControllingAssetContacts from "@/components/contacts/OperationalControllingAssetContacts.vue";
import { markRaw } from "vue";
import _ from "lodash";

export function initialSelectedTabFor(dataField) {
  switch (_.get(dataField, "fieldContentType")) {
    default:
      return _.get(rawTabs, "comingSoon");
  }
}

export function tabsFor(dataField) {
  switch (_.get(dataField, "fieldContentType")) {
    case "PropertyRight":
    case "LandCovering":
    case "FloorArea":
      return [_.get(rawTabs, "diagramSelected"), _.get(rawTabs, "comingSoon")];
    default: {
      return [_.get(rawTabs, "comingSoon")];
    }
  }
}

export function tabPropertiesFor(dataField = null) {
  return {
    dataField,
    context: "contacts-hub",
  };
}

export const rawTabs = {
  diagramSelected: {
    name: "Property Diagram",
    tabQuery: "PropertyDiagram",
    href: "",
    svgPaths: [
      "M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10",
    ],
    component: markRaw(OperationalControllingAssetContacts),
  },
  comingSoon: {
    name: "Coming Soon",
    tabQuery: "ComingSoon",
    href: "",
    svgPaths: [
      "M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z",
    ],
    component: markRaw(ComingSoon),
  },
};
