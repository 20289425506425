<template>
  <tbody class="divide-y divide-gray-200 bg-white">
    <tr>
      <td
        class="relative whitespace-nowrap flex items-center py-2 pl-4 pr-3 text-sm font-medium"
      >
        <button
          @click.prevent="expanded = !expanded"
          type="button"
          class="h-5 w-5 inline-flex justify-center items-center text-sm text-gray-700 hover:text-gray-900"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clip-rule="evenodd"
            />
          </svg>
        </button>
      </td>
      <td class="whitespace-nowrap px-2 py-2">
        <EditableDataField
          v-bind="
            _.find(availableProps, {
              fieldName: 'fully_funded_loan_amount',
            })
          "
          @open-sourced="refetchLoans"
          @completed="refetchLoans"
          @dismiss="refetchLoans"
          @set-proof="setProof"
        />
      </td>
      <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
        <EditableDataField
          v-bind="
            _.find(availableProps, {
              fieldName: 'loan_term_years',
            })
          "
          @open-sourced="refetchLoans"
          @completed="refetchLoans"
          @dismiss="refetchLoans"
          @set-proof="setProof"
        />
      </td>
      <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
        <EditableDataField
          v-bind="
            _.find(availableProps, {
              fieldName: 'maturity_date',
            })
          "
          @open-sourced="refetchLoans"
          @completed="refetchLoans"
          @dismiss="refetchLoans"
          @set-proof="setProof"
        />
      </td>
      <td class="whitespace-nowrap py-2 pl-3 pr-4 text-sm text-gray-500">
        <EditableDataField
          v-bind="
            _.find(availableProps, {
              fieldName: 'loan_coupon_rate',
            })
          "
          @open-sourced="refetchLoans"
          @completed="refetchLoans"
          @dismiss="refetchLoans"
          @set-proof="setProof"
        />
      </td>
    </tr>

    <tr v-if="expanded">
      <td colspan="100">
        <LoanDetail :data-field="loan" />
      </td>
    </tr>
  </tbody>
</template>

<script setup>
import EditableDataField from "@/components/crowdsourcing/EditableDataField.vue";
import { useProveForValueStore } from "@/stores/proveForValue";
import { useBranchingCalculationFieldsStore } from "@/stores/branchingCalculationFields";
import { computed, onBeforeUnmount, onMounted, ref } from "vue";
import _ from "lodash";
import LoanDetail from "@/components/deal-builder/LoanDetail.vue";

const props = defineProps(["loan", "fieldContentType", "fieldContent"]);
const emit = defineEmits(["refetch"]);

const branchingCalculationFieldsStore = useBranchingCalculationFieldsStore();
const proveForValueStore = useProveForValueStore();

const expanded = ref(false);
const availableProps = computed(() => {
  return [
    {
      fieldName: "fully_funded_loan_amount",
      textClasses: "text-sm font-medium",
      decoratingContentDataField: props.loan,
      isCurrency: true,
      optional: true,
      dismissOnSave: true,
    },
    {
      fieldName: "loan_term_years",
      textClasses: "text-sm font-medium",
      decoratingContentDataField: props.loan,
      isCurrency: false,
      isInteger: true,
      optional: true,
      dismissOnSave: true,
    },
    {
      fieldName: "maturity_date",
      textClasses: "text-sm font-medium",
      decoratingContentDataField: props.loan,
      isCurrency: false,
      isDate: true,
      optional: true,
      dismissOnSave: true,
    },
    {
      fieldName: "loan_coupon_rate",
      textClasses: "text-sm font-medium",
      decoratingContentDataField: props.loan,
      isCurrency: false,
      isFloat: true,
      optional: true,
      dismissOnSave: true,
    },
  ];
});
const branchingCollectionObject = ref({
  contentType: "Loan",
  contentId: props.loan.fieldContentId,
  context: "loan_table_row",
  termYearsSelection: null,
  maturityDateSelection: null,
});

onMounted(() => {
  branchingCalculationFieldsStore.patchContentCollection(
    branchingCollectionObject.value,
  );
});

onBeforeUnmount(() => {
  branchingCalculationFieldsStore.dropContent(branchingCollectionObject.value);
});

function refetchLoans() {
  emit("refetch");
}

function setProof(fieldName) {
  const matchedProps = _.find(availableProps.value, { fieldName });

  if (matchedProps) {
    proveForValueStore.setProofComponent(EditableDataField, matchedProps);
  }
}
</script>
