import _ from "lodash";

export function bundlesFor(dataField) {
  switch (_.get(dataField, "fieldContentType")) {
    case "Property":
    case "PropertyRight":
    case "LandCovering":
      return [
        _.get(rawBundles, "everything"),
        _.get(rawBundles, "physicalLayout"),
        _.get(rawBundles, "capitalMarketsComp"),
        _.get(rawBundles, "leaseComp"),
        _.get(rawBundles, "players"),
        _.get(rawBundles, "financialPerformance"),
      ];
    case "LandCoveringLevel":
    case "LandCoveringDiagramVerticalGrouping":
    case "FloorAreaLayout":
      return [
        _.get(rawBundles, "everything"),
        _.get(rawBundles, "physicalLayout"),
      ];
    case "FloorArea":
    case "LayoutPartition":
    case "PropertyEnhancement":
      return [
        _.get(rawBundles, "everything"),
        _.get(rawBundles, "physicalLayout"),
        _.get(rawBundles, "leaseComp"),
        _.get(rawBundles, "financialPerformance"),
      ];
    case "Investment":
    case "Loan":
    case "InvestmentGroup":
      return [
        _.get(rawBundles, "everything"),
        _.get(rawBundles, "capitalMarketsComp"),
        _.get(rawBundles, "players"),
      ];
    case "SpaceAvailability":
    case "SpaceAvailabilityGroup":
    case "SpaceUsage":
      return [
        _.get(rawBundles, "everything"),
        _.get(rawBundles, "leaseComp"),
        _.get(rawBundles, "players"),
        _.get(rawBundles, "financialPerformance"),
      ];
    case "Company":
    case "Contact":
    case "Hunt":
      return [
        _.get(rawBundles, "everything"),
        _.get(rawBundles, "capitalMarketsComp"),
        _.get(rawBundles, "leaseComp"),
        _.get(rawBundles, "players"),
      ];
    case "CompanyInvolvement":
    case "HuntSpaceRequirement":
    case "HuntGeographyIntent":
      return [_.get(rawBundles, "everything")];
    default:
      return [];
  }
}

export const rawBundles = {
  everything: {
    name: "All fields",
    description: "Everything we have.",
    apiParam: "all_fields",
  },
  physicalLayout: {
    name: "Physical info",
    description: "Fill in the property diagram.",
    apiParam: "physical_layout",
  },
  capitalMarketsComp: {
    name: "Cap. Mkts Comp",
    description: "Fill in the capital markets details.",
    apiParam: "capital_markets_comp",
  },
  leaseComp: {
    name: "Lease Comp",
    description: "Fill in the leasing details.",
    apiParam: "lease_comp",
  },
  players: {
    name: "Players",
    description: "Fill in companies and contacts.",
    apiParam: "players",
  },
  financialPerformance: {
    name: "Financials",
    description: "Understand income and expenses.",
    apiParam: "financial_performance",
  },
};
