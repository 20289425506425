<template>
  <div>
    <div v-if="editing" class="flex flex-col space-y-1">
      <textarea
        v-focus
        v-model="text"
        name="contact-info"
        id="contact-info"
        rows="3"
        class="shadow-sm text-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 border border-gray-300 rounded-md"
      ></textarea>
      <div class="flex items-center justify-end space-x-2">
        <button
          @click="cancel"
          type="button"
          class="inline-flex items-center p-1 border border-gray-300 rounded-full shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <XMarkIcon class="h-4 w-4" />
        </button>

        <DataVisibilityButton
          v-if="complete"
          visibility="safezone"
          class="inline-flex"
        >
          <template v-slot:button>
            <button
              @click="save"
              :disabled="originatingData"
              type="button"
              class="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-yellow-500 hover:bg-yellow-600 focus:ring-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2"
              data-test="content-name-save"
            >
              <PulseLoader
                v-if="originatingData"
                :loading="true"
                size="3px"
                color="#f3f4f6"
              />
              <CheckIcon v-else class="h-4 w-4" />
            </button>
          </template>
        </DataVisibilityButton>
      </div>
    </div>

    <DataField
      v-show="fetchedInfo && !editing"
      :data-field="fetchedInfo"
      primary-text-path="fieldContent.contactInfo"
      text-classes="text-sm"
      :always-private="true"
      @dismiss="dismiss"
      @suggest-change="suggestChange"
      @completed="fetchInfo"
    />

    <div v-if="!user && !fetchedInfo && !editing" class="flex items-center">
      <DataVisibilityButton visibility="safezone">
        <template v-slot:button>
          <button
            @click="editing = true"
            type="button"
            class="group py-1 px-1.5 flex items-center justify-between rounded-full border border-gray-300 shadow-sm space-x-2 text-left hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <span class="min-w-0 flex-1 flex items-center space-x-1">
              <span class="flex-shrink-0 flex items-center">
                <span
                  class="inline-flex items-center justify-center h-5 w-5 rounded-full bg-yellow-500"
                >
                  <PencilIcon class="h-3 w-3 text-white" />
                </span>
              </span>
              <span class="min-w-0 flex-1">
                <span class="text-sm font-medium text-gray-900 truncate"
                  >Add contact info</span
                >
              </span>
            </span>
          </button>
        </template>
      </DataVisibilityButton>
    </div>

    <div v-if="user" class="flex items-center">
      <DataVisibilityButton visibility="safezone">
        <template v-slot:button>
          <button
            type="button"
            class="group py-1 px-1.5 flex items-center justify-between rounded-full border border-gray-300 shadow-sm space-x-2 text-left hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <span class="min-w-0 flex-1 flex items-center space-x-1">
              <span class="flex-shrink-0 flex items-center">
                <span
                  class="inline-flex items-center justify-center h-5 w-5 rounded-full bg-yellow-500"
                >
                  <PencilIcon class="h-3 w-3 text-white" />
                </span>
              </span>
              <span class="min-w-0 flex-1">
                <span class="text-sm font-medium text-gray-900 truncate"
                  >Coming Soon: User info</span
                >
              </span>
            </span>
          </button>
        </template>
      </DataVisibilityButton>
    </div>
  </div>
</template>

<script setup>
import { XMarkIcon, CheckIcon } from "@heroicons/vue/20/solid";
import { PencilIcon } from "@heroicons/vue/24/outline";
import DataField from "@/components/crowdsourcing/DataField.vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { useCrowdsourcedChangeGroupStore } from "@/stores/crowdsourcedChangeGroup";
import { ref, computed, onMounted } from "vue";
import { storeToRefs } from "pinia";
import api from "@/router/api";
import _ from "lodash";
import DataVisibilityButton from "@/components/crowdsourcing/DataVisibilityButton.vue";

const props = defineProps(["contactDataField", "contact", "user"]);

const changeGroupStore = useCrowdsourcedChangeGroupStore();
const { originatingData, changeGroupId } = storeToRefs(changeGroupStore);

const fetchedInfo = ref(null);
const editing = ref(false);
const dismissOnSaveId = ref(null);
const text = ref(null);

const complete = computed(() => {
  const filled = _.trim(text.value) !== "";
  const changed = hasFieldValue.value
    ? _.trim(text.value) !== _.trim(fieldValue.value)
    : true;
  return filled && changed;
});
const hasFieldValue = computed(
  () => fieldValue.value && _.trim(fieldValue.value) !== ""
);
const contactId = computed(() => {
  return (
    _.get(props.contactDataField, "fieldContent.id") ||
    _.get(props.contact, "id")
  );
});
const fieldValue = computed(() => {
  return _.get(fetchedInfo.value, "fieldValue", null);
});

onMounted(() => {
  fetchInfo();
});

function cancel() {
  editing.value = false;
  text.value = null;
}
function reset() {
  cancel();
  fetchedInfo.value = null;
  fetchInfo();
}
function afterPersist() {
  reset();
  // this.$store.dispatch("flash", "Saved!");
}

async function dismiss(id, successCallback = () => {}) {
  if (!id) return;

  await changeGroupStore.dismissData({
    dataFieldId: id,
    successCallback: successCallback,
  });
}

function suggestChange() {
  dismissOnSaveId.value = fetchedInfo.value.localId;
  text.value = fieldValue.value;
  editing.value = true;
}

async function save() {
  await dismiss(dismissOnSaveId.value);

  const apiRequestFunc = () => persist();
  const successCallback = () => afterPersist();
  const failureCallback = () => cancel();

  if (complete.value) {
    await changeGroupStore.originateData(
      apiRequestFunc,
      successCallback,
      failureCallback
    );
  }
}
function persist() {
  let payload = {
    text: text.value,
    contactId: contactId.value,
    changeGroupId: changeGroupId.value,
  };
  return api.post(
    `contact_text_informations`,
    _.merge({}, payload, { changeGroupId: changeGroupId.value })
  );
}
function fetchInfo() {
  if (contactId.value) {
    api
      .get(
        `crowdsourced_data_fields/Contact/${contactId.value}?field_name=contact_info`
      )
      .then((json) => {
        fetchedInfo.value = json.data;
      });
  }
}
</script>
