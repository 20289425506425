import { markRaw } from "vue";
import ProofAccepted from "@/components/notifications/ProofAccepted.vue";
import ProofRejected from "@/components/notifications/ProofRejected.vue";
import EmailVerified from "@/components/notifications/EmailVerified.vue";
import UnlockSuccessful from "@/components/notifications/UnlockSuccessful.vue";
import NameUpdated from "@/components/notifications/NameUpdated.vue";
import EasyDataContributed from "@/components/notifications/EasyDataContributed.vue";
import DigestConsentUpdated from "@/components/notifications/DigestConsentUpdated.vue";
import PasswordResetSuccessful from "@/components/notifications/PasswordResetSuccessful.vue";
import PasswordResetSubmitted from "@/components/notifications/PasswordResetSubmitted.vue";
import AnErrorOccurred from "@/components/notifications/AnErrorOccurred.vue";
import PermissionDenied from "@/components/notifications/PermissionDenied.vue";
import AccountAlreadyExists from "@/components/notifications/AccountAlreadyExists.vue";
import LinkRequestSubmitted from "@/components/notifications/LinkRequestSubmitted.vue";
import EmailResetSubmitted from "@/components/notifications/EmailResetSubmitted.vue";
import AirGroundDiagramOpened from "@/components/notifications/AirGroundDiagramOpened.vue";

export default [
  {
    id: "proofAccepted",
    component: markRaw(ProofAccepted),
    props: {},
  },
  {
    id: "proofRejected",
    component: markRaw(ProofRejected),
    props: {},
  },
  {
    id: "emailVerified",
    component: markRaw(EmailVerified),
    props: {},
  },
  {
    id: "linkRequestSubmitted",
    component: markRaw(LinkRequestSubmitted),
    props: {},
  },
  {
    id: "passwordResetSubmitted",
    component: markRaw(PasswordResetSubmitted),
    props: {},
  },
  {
    id: "passwordResetSuccessful",
    component: markRaw(PasswordResetSuccessful),
    props: {},
  },
  {
    id: "emailResetSubmitted",
    component: markRaw(EmailResetSubmitted),
    props: {},
  },
  {
    id: "nameUpdated",
    component: markRaw(NameUpdated),
    props: {},
  },
  {
    id: "digestConsentUpdated",
    component: markRaw(DigestConsentUpdated),
    props: {},
  },
  {
    id: "anErrorOccurred",
    component: markRaw(AnErrorOccurred),
    props: {},
  },
  {
    id: "permissionDenied",
    component: markRaw(PermissionDenied),
    props: {},
  },
  {
    id: "accountAlreadyExists",
    component: markRaw(AccountAlreadyExists),
    props: {},
  },
  {
    id: "unlockSuccessful",
    component: markRaw(UnlockSuccessful),
    props: {},
  },
  {
    id: "airGroundDiagramOpened",
    component: markRaw(AirGroundDiagramOpened),
    props: {},
  },
  {
    id: "easyDataContributed",
    component: markRaw(EasyDataContributed),
    props: {},
  },
];
