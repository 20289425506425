<template>
  <section aria-labelledby="teams">
    <ContentHeader v-if="creating">
      <template v-slot:title>Team Settings</template>
      <template v-if="teamId" v-slot:description>Manage your team.</template>
      <template v-else v-slot:description>Create your team.</template>
    </ContentHeader>

    <ContentHeader v-else-if="inspecting">
      <template v-slot:title>Team Details</template>
      <template v-slot:description
        >View membership and safezone sharing info.</template
      >
    </ContentHeader>

    <ContentHeader v-else>
      <template v-slot:title>My Teams</template>
      <template v-slot:description>Work and earn together with teams.</template>
    </ContentHeader>

    <div>
      <TeamForm
        v-if="creating"
        :team-id="teamId"
        @cancel="notCreating"
        @saved="notCreating"
      />
      <TeamDetails
        v-else-if="inspecting"
        :team-id="teamId"
        @cancel="notInspecting"
        @saved="notInspecting"
      />
      <TeamIndex
        v-else
        @create-team="creating = true"
        @edit-team="editTeam"
        @view-team-details="viewDetails"
      />
    </div>
  </section>
</template>

<script setup>
import ContentHeader from "@/components/main-layout/secondary-panel/ContentHeader.vue";
import { ref } from "vue";
import TeamForm from "@/components/users/teams/TeamForm.vue";
import TeamIndex from "@/components/users/teams/TeamIndex.vue";
import TeamDetails from "@/components/users/teams/TeamDetails.vue";

const creating = ref(false);
const inspecting = ref(false);
const teamId = ref(null);

function notCreating() {
  creating.value = false;
  teamId.value = null;
}
function notInspecting() {
  inspecting.value = false;
  teamId.value = null;
}
function editTeam(id) {
  teamId.value = id;
  creating.value = true;
}
function viewDetails(id) {
  teamId.value = id;
  inspecting.value = true;
}
</script>
