<template>
  <div class="flex items-center space-x-2">
    <input
      v-observe-visibility="{ callback: focusInput, once: true }"
      v-model="editedDate"
      type="date"
      name="date"
      :data-test="`${dateFieldSnakeCaseName}-input`"
      :id="`${dateFieldSnakeCaseName}-input`"
      class="w-full rounded-full border-gray-300 pl-2 focus:border-indigo-500 focus:ring-indigo-500 text-xs"
    />
    <template v-if="editedDate || existingDate">
      <div class="flex items-center justify-end space-x-2">
        <button
          @click="cancelDate"
          v-tooltip="`Cancel`"
          type="button"
          class="inline-flex items-center p-1 border border-gray-300 rounded-full shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <XMarkIcon class="h-4 w-4" />
        </button>

        <DataVisibilityButton
          :visibility="visibility"
          :tooltip="
            proveForValue
              ? null
              : `Set ${milestoneObject.statusLabel || 'Unknown state'} date`
          "
          class="inline-flex"
        >
          <template v-slot:button>
            <button
              v-if="editedDate"
              @click="save(null)"
              :disabled="originatingData"
              type="button"
              :class="
                visibility === 'safezone'
                  ? 'bg-yellow-500 hover:bg-yellow-600 focus:ring-yellow-600'
                  : 'bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500'
              "
              class="inline-flex flex-shrink-0 items-center p-1 border border-transparent rounded-full shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2"
              :data-test="`${dateFieldSnakeCaseName}-save-button`"
            >
              <PulseLoader
                v-if="originatingData"
                :loading="true"
                size="3px"
                color="#f3f4f6"
              />
              <CheckIcon v-else class="h-4 w-4" />
            </button>
          </template>
        </DataVisibilityButton>
      </div>
    </template>
  </div>
</template>

<script setup>
import { CheckIcon } from "@heroicons/vue/24/outline";
import { XMarkIcon } from "@heroicons/vue/20/solid";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import DataVisibilityButton from "@/components/crowdsourcing/DataVisibilityButton.vue";
import { useNotificationsStore } from "@/stores/notifications";
import { useProveForValueStore } from "@/stores/proveForValue";
import { useCrowdsourcedChangeGroupStore } from "@/stores/crowdsourcedChangeGroup";
import { usePropertyDiagramStore } from "@/stores/propertyDiagram";
import { useDealBuilderStore } from "@/stores/dealBuilder";
import { ref, computed } from "vue";
import { storeToRefs } from "pinia";
import moment from "moment";
import api from "@/router/api";
import _ from "lodash";

const props = defineProps([
  "focus",
  "decoratingDataField",
  "milestoneObject",
  "existingDate",
  "dateDismissalId",
  "fetchRequestKey",
  "proveForValue",
  "challengeDataField",
  "fieldNameModifier",
]);
const emit = defineEmits(["cancel", "completed", "unlocked"]);

const propertyDiagramStore = usePropertyDiagramStore();
const crowdsourcedChangeGroupStore = useCrowdsourcedChangeGroupStore();
const { originatingData, changeGroupId } = storeToRefs(
  crowdsourcedChangeGroupStore,
);
const dealBuilderStore = useDealBuilderStore();
const notificationsStore = useNotificationsStore();
const proveForValueStore = useProveForValueStore();

const editedDate = ref(null);

const visibility = computed(() =>
  props.proveForValue ? "public" : "safezone",
);
const contentType = computed(() => props.decoratingDataField.fieldContentType);
const contentId = computed(() => props.decoratingDataField.fieldContent.id);
const snakeCaseProgressionValue = computed(
  () => props.milestoneObject.dateValue || props.milestoneObject.value,
);
const dateFieldSnakeCaseName = computed(() => {
  return `${snakeCaseProgressionValue.value}_date`;
});

function focusInput(isVisible) {
  if (isVisible && props.focus) {
    setTimeout(() => {
      selectInput();
    }, 100);
  }
}
function selectInput() {
  const el = document.getElementById(`${dateFieldSnakeCaseName.value}-input`);

  if (el) {
    el.focus();
  }
}

function cancelDate() {
  editedDate.value = null;
  emit("cancel");
}

function save(newProofStatus = null) {
  const apiRequestFunc = () => persist(newProofStatus);
  const successCallback = (json) => afterPersist(json);
  const failureCallback = () => cancelDate();
  return crowdsourcedChangeGroupStore.originateData(
    apiRequestFunc,
    successCallback,
    failureCallback,
  );
}

async function persist(newProofStatus) {
  console.log(
    "content date edit persist",
    props.challengeDataField,
    props.proveForValue,
    newProofStatus,
  );
  if (props.proveForValue && !newProofStatus) {
    let payload = {
      fieldValue: moment(editedDate.value).valueOf(),
      fieldValueType: "date",
    };
    const proofResponse = await proveForValueStore.submitProof(
      props.challengeDataField,
      payload,
    );

    return proofResponse;
  } else {
    let payload = {
      date: moment(editedDate.value).valueOf(),
      changeGroupId: changeGroupId.value,
    };

    if (props.fieldNameModifier !== "state") {
      payload.fieldNameModifier = props.fieldNameModifier;
    }

    const response = await api.post(
      `content_dates/${contentType.value}/${contentId.value}?field_name=${dateFieldSnakeCaseName.value}`,
      payload,
    );

    if (newProofStatus === "rejected") {
      const declassifyPayload = {
        ids: response.data.dataFields.map((field) => field.localId),
      };
      await api.post(`declassify_datapoints`, declassifyPayload);

      notificationsStore.addNotification("proofRejected");
    }

    return response;
  }
}

async function afterPersist(json) {
  if (json.data?.proofStatus === "rejected") {
    await crowdsourcedChangeGroupStore.dismissData({
      dataFieldId: props.challengeDataField.localId,
      successCallback: () => {},
    });
    save(json.data.proofStatus);
  } else if (json.data?.proofStatus === "accepted") {
    notificationsStore.addNotification("proofAccepted");
    emit("unlocked");
  } else {
    if (props.dateDismissalId) {
      await dismiss(props.dateDismissalId);
    }
    emit("completed", _.merge({}, json, { override: "date" }));
    // this.$store.dispatch("flash", "Saved!");
  }
}

async function dismiss(id) {
  await crowdsourcedChangeGroupStore.dismissData({
    dataFieldId: id,
    byUser: false,
    successCallback: async (json) => {
      propertyDiagramStore.postEditingPatch(json);
      await dealBuilderStore.postEditingPatch(json, props.fetchRequestKey);
    },
  });
}
</script>
