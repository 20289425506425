<template>
  <div
    v-if="dataField || overrideLabel || calculationName || analyze"
    :style="cssProps"
    data-test="field-metered-unlock-slider"
  >
    <span v-if="!signedIn">
      <a
        v-if="testEnvironment"
        href=""
        @click.prevent="joinNow"
        class="font-medium text-indigo-600 hover:text-indigo-500"
        >Join</a
      >
      <SlideUnlock
        v-else
        :height="heightOverride || 20"
        :circle="true"
        :text="`Unlock: $${pricingText}`"
        :name="slideName"
        success-text="Join!"
        @completed="joinNow"
      />
    </span>
    <span v-else-if="!safezone && unstaked">
      <a
        v-if="testEnvironment"
        href=""
        @click.prevent="unlock({ staking: true })"
        class="font-medium text-indigo-600 hover:text-indigo-500"
        >Stake</a
      >
      <SlideUnlock
        v-else
        ref="stakeUnlocker"
        :height="heightOverride || 20"
        :circle="true"
        :text="`Stake data credits`"
        :name="slideName"
        success-text="Staking..."
        @completed="unlock({ staking: true })"
      />
    </span>
    <span v-else-if="productTier === 'gatherer'">
      <a
        v-if="testEnvironment"
        href=""
        @click.prevent="unlock({ staking: false })"
        class="font-medium text-indigo-600 hover:text-indigo-500"
        data-test="field-unlock-link"
        >Upgrade</a
      >
      <SlideUnlock
        v-else
        ref="upgradeUnlocker"
        :height="heightOverride || 20"
        :circle="true"
        :text="`Unlock: $${pricingText}`"
        :name="slideName"
        success-text="Upgrading..."
        @completed="unlock({ staking: false })"
      />
    </span>
    <span v-else>
      <a
        v-if="testEnvironment"
        href=""
        @click.prevent="unlock({ staking: false })"
        class="font-medium text-indigo-600 hover:text-indigo-500"
        data-test="field-unlock-link"
        >License</a
      >
      <SlideUnlock
        v-else
        :height="heightOverride || 20"
        :circle="true"
        :text="`Unlock: $${pricingText}`"
        :name="slideName"
        success-text="Unlocking..."
        @completed="unlock({ staking: false })"
      />
    </span>
  </div>
</template>

<script setup>
import SlideUnlock from "vue-slide-unlock";
import subscribeInterceptor from "@/components/crowdsourcing/subscribeInterceptor";
import api from "@/router/api";
import { useUserStore } from "@/stores/user";
import { storeToRefs } from "pinia";
import { useModalStore } from "@/stores/modal";
import { computed, onBeforeUnmount, onMounted, ref, watch } from "vue";
import { usePropertyDiagramStore } from "@/stores/propertyDiagram";
import { useTimeTravelStore } from "@/stores/timeTravel";
import { useNotificationsStore } from "@/stores/notifications";
import { usePropertyDiagramActionsStore } from "@/stores/propertyDiagramActions";
import { useCompanyDetailStore } from "@/stores/companyDetail";
import { useContactDetailStore } from "@/stores/contactDetail";
import { useSecondaryPanelStore } from "@/stores/secondaryPanel";
import { useDealBuilderStore } from "@/stores/dealBuilder";
import { useAnalyzePanelStore } from "@/stores/analyzePanel";
import _ from "lodash";
import { useUnlockerStore } from "@/stores/unlocker";

const props = defineProps([
  "context",
  "dataField",
  "timingFields",
  "calculationName",
  "userStore",
  "modalStore",
  "unlockerStore",
  "bundleApiParam",
  "bundlePricingData",
  "heightOverride",
  "iconOverride",
  "overrideRecords",
  "overrideLabel",
  "analyze",
]);
const emit = defineEmits(["reset-displayable", "unlocked"]);
const notificationsStore = useNotificationsStore();
const userStore = props.userStore || useUserStore();
const { currentUser, signedIn } = storeToRefs(userStore);
const modalStore = props.modalStore || useModalStore();
const { modalPayload } = storeToRefs(modalStore);
const secondaryPanelStore = useSecondaryPanelStore();
const propertyDiagramStore = usePropertyDiagramStore();
const dealBuilderStore = useDealBuilderStore();
const companyDetailStore = useCompanyDetailStore();
const {
  companyIdParam,
  companySelectedRecordDataField,
  companyAllFetchedFields,
} = storeToRefs(companyDetailStore);
const contactDetailStore = useContactDetailStore();
const {
  contactIdParam,
  contactSelectedRecordDataField,
  contactAllFetchedFields,
} = storeToRefs(contactDetailStore);
const timeTravelStore = useTimeTravelStore();
const diagramActionsStore = usePropertyDiagramActionsStore();
const { actionInProgress } = storeToRefs(diagramActionsStore);
const unlockerStore = props.unlockerStore || useUnlockerStore();
const { resetRequired, upgradeSuccessful } = storeToRefs(unlockerStore);
const analyzePanelStore = useAnalyzePanelStore();
const {
  analyzeInvestmentFields,
  analyzeLoanFields,
  analyzeHuntFields,
  analyzeSpaceAvailabilityFields,
  analyzeSpaceUsageFields,
  contentCalculationData,
} = storeToRefs(analyzePanelStore);
const upgradeInProgress = ref(false);

const slideName = computed(() => {
  const contentName =
    props.bundleApiParam ||
    props.overrideLabel ||
    _.startCase(props.calculationName) ||
    _.toString(props.dataField.localId);

  return `${props.context}-${contentName}`;
});
const testEnvironment = computed(() => currentUser.value.cypress === "yes");
const safezone = computed(() => _.get(props.dataField, "safezone"));
const unstaked = computed(() => _.get(props.dataField, "state") === "unstaked");
const productTier = computed(() => {
  return currentUser.value.productTier;
});
const matchingTimingFields = computed(() => {
  if (props.timingFields) {
    return props.timingFields.filter((field) => {
      return field.fieldName === props.dataField.fieldName;
    });
  } else {
    return [];
  }
});
const companyMaskedFields = computed(() =>
  companyAllFetchedFields.value.filter(
    (field) =>
      field.fieldContentType === "CompanyInvolvement" && !field.unmasked,
  ),
);
const contactMaskedFields = computed(() =>
  contactAllFetchedFields.value.filter(
    (field) =>
      field.fieldContentType === "CompanyInvolvement" && !field.unmasked,
  ),
);
const detailPageOverride = computed(() => {
  if (
    companyIdParam.value &&
    companySelectedRecordDataField.value?.fieldContentType === "Company" &&
    _.size(companyMaskedFields.value) > 0
  ) {
    return companyMaskedFields.value.map((field) => field.price);
  } else if (
    contactIdParam.value &&
    contactSelectedRecordDataField.value?.fieldContentType === "Contact" &&
    _.size(contactMaskedFields.value) > 0
  ) {
    return companyMaskedFields.value.map((field) => field.localId);
  } else {
    return null;
  }
});
const customOverride = computed(() => {
  if (props.overrideLabel) {
    return props.overrideRecords.map((field) => field.price);
  } else if (matchingTimingFields.value.length > 0) {
    return matchingTimingFields.value.map((field) => field.price);
  } else {
    return null;
  }
});

const pricingText = computed(() => {
  if (props.bundlePricingData) {
    return _.round(_.get(props.bundlePricingData, "price", 0), 3);
  } else if (_.size(detailPageOverride.value) > 0) {
    return _.round(
      _.sumBy(detailPageOverride.value, function (str) {
        return _.toNumber(str);
      }),
      3,
    );
  } else if (_.size(customOverride.value) > 0) {
    return _.round(
      _.sumBy(customOverride.value, function (str) {
        return _.toNumber(str);
      }),
      3,
    );
  } else {
    return _.get(props.dataField, "price");
  }
});
const cssProps = computed(() => {
  if (props.iconOverride) {
    return `--icon-font-size: ${props.iconOverride}px`;
  } else {
    return `--icon-font-size: 1.5em`;
  }
});

onMounted(() => {
  let safezoneTemp = safezone.value;
  let unstakedTemp = unstaked.value;
  let modalStoreTemp = props.modalStore;
  let unlockerStoreTemp = props.unlockerStore;
});

onBeforeUnmount(() => {
  if (upgradeInProgress.value) {
    upgradeSuccessful.value = false;
  }
});

watch(resetRequired, () => {
  if (upgradeInProgress.value) {
    resetRequired.value = false;
    upgradeInProgress.value = false;
  }
});

function joinNow() {
  secondaryPanelStore.promptToRegister();
  emit("reset-displayable");
}

function clearSelection() {
  if (window.getSelection) {
    window.getSelection().removeAllRanges();
  } else if (document.selection) {
    document.selection.empty();
  }
}

async function unlock({ staking = false }) {
  let payload = {};

  if (!staking) {
    payload.dataFieldId = props.dataField?.localId;

    if (props.bundlePricingData) {
      payload.bundle = props.bundleApiParam;
      payload.bundleDataFieldIds = props.bundlePricingData.unlicensedIds;
    } else if (detailPageOverride.value) {
      payload.bundle = "detail_page";
      payload.bundleDataFieldIds = companyMaskedFields.value.map(
        (field) => field.localId,
      );
    } else if (props.overrideLabel) {
      payload.bundle = props.overrideLabel;
      payload.bundleDataFieldIds = props.overrideRecords.map(
        (field) => field.localId,
      );
    } else if (props.timingFields) {
      payload.bundle = "investment_group_timing";
      payload.bundleDataFieldIds = matchingTimingFields.value.map(
        (field) => field.localId,
      );
    }
  }

  actionInProgress.value = true;

  const apiRequestFunc = staking
    ? null
    : () => api.post(`crowdsourced_data_licenses`, payload);
  const licensingCallback = (json) => {
    const licensed = _.includes(json.data.licensingStatus, "licensed");
    console.log("data licensed success callback", licensed);
    if (licensed) {
      console.log("licensed");
      propertyDiagramStore.postEditingPatch(json);
      companyDetailStore.postEditingPatch(json, true);
      contactDetailStore.postEditingPatch(json, true);
      dealBuilderStore.postEditingPatch(json);
      userStore.refreshActiveInputPayloadItem();
      if (analyzeInvestmentFields.value) {
        analyzeInvestmentFields.value = {};
        analyzeLoanFields.value = {};
        analyzeSpaceUsageFields.value = {};
        analyzeSpaceAvailabilityFields.value = {};
        analyzeHuntFields.value = {};
        contentCalculationData.value = {};
      }
      timeTravelStore.triggerRefetch();
      actionInProgress.value = false;
      notificationsStore.addNotification("unlockSuccessful");
      emit("unlocked");
      clearSelection();
    } else if (upgradeSuccessful.value) {
      console.log("user subscribed");
      upgradeInProgress.value = false;
      upgradeSuccessful.value = false;
      actionInProgress.value = false;
      unlock({ staking: false });
      clearSelection();
    } else {
      actionInProgress.value = false;
      emit("reset-displayable");
      clearSelection();
    }
  };

  const successCallback = staking ? () => emit("unlocked") : licensingCallback;

  subscribeInterceptor({
    apiRequestFunc,
    successCallback,
    modalPayloadRef: modalPayload,
    upgradeSuccessfulRef: upgradeSuccessful,
    afterSubscribe: "successCallback",
    promptReason: staking ? "stakeShortfall" : "dataUsage",
    context: staking ? "originating" : "licensing",
  });
}
</script>

<style scoped>
:deep(.slideunlock) {
  --su-size-padding: 3px !important;
}
:deep(.slideunlock .slideunlock-text) {
  --su-size-text: 12px !important;
}
</style>
