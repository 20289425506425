<template>
  <div
    :id="scrollId"
    :class="isCurrent ? 'border-4 border-orange-500' : 'border border-gray-400'"
    class="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200"
  >
    <div
      :class="isCurrent ? 'bg-orange-300' : 'bg-gray-200'"
      class="p-2 flex items-center justify-between text-gray-500"
    >
      <div class="flex items-center space-x-1">
        <button
          @click.prevent="toggleExpanded"
          type="button"
          class="h-5 w-5 inline-flex justify-center items-center text-sm text-gray-700 hover:text-gray-800"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clip-rule="evenodd"
            />
          </svg>
        </button>
        <div class="">
          <p class="text-gray-700 font-bold">
            <a @click.prevent="toggleExpanded" href="">{{
              _.startCase(secondaryDataAlias)
            }}</a>
          </p>
        </div>
      </div>
      <div
        v-if="adminApprovable || validationInput"
        class="flex items-center space-x-2"
      >
        <CheckIcon
          v-if="validationInput === 'Accepted'"
          class="h-4 w-4 text-green-500"
        />
        <XMarkIcon
          v-if="validationInput === 'Rejected'"
          class="h-4 w-4 text-red-500"
        />
        <ChevronDoubleRightIcon
          v-if="validationInput === 'Skipped'"
          class="h-4 w-4 text-blue-500"
        />
        <div v-if="validationInput" :class="validationColor">
          {{ validationInput }}
        </div>
        <AdminValidationVoteSummary
          v-if="adminApprovable && validationSubmissions && !validationInput"
          :validation-submissions="validationSubmissions"
        />
      </div>
    </div>
    <div v-if="expanded && validationData" class="flex flex-col">
      <div class="px-2">
        <h2 class="text-gray-700 font-semibold text-sm mt-2">Differences</h2>
        <code-diff
          :old-string="oldString"
          :new-string="newString"
          :file-name="fieldName"
          :no-diff-line-feed="false"
          diff-style="char"
          language="plaintext"
          :output-format="outputFormat"
        />
      </div>

      <div class="bg-gray-200 border-t border-gray-400">
        <ValidationGuidance
          class="px-2 pt-2"
          :typed-test-index="scrollId"
          :meta-key="validationData.metaKey"
          :change-type="
            validationData.after.length > 0 ? 'addition' : 'removal'
          "
        />

        <ValidationActions
          class="flex flex-col pt-2"
          :focal-change="isCurrent"
          :action-in-progress="actionInProgress"
          :admin-approvable="adminApprovable"
          :change-count="changeIds.length"
          :test-index="typedTestIndex"
          @approve="approveAll"
          @disapprove="disapproveAll"
          @accept="acceptAll"
          @reject="rejectAll"
          @skip="skipAll"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import ValidationActions from "@/components/crowdsourcing/ValidationActions.vue";
import api from "@/router/api";
import { ref, computed, onMounted, watch } from "vue";
import { useUserStore } from "@/stores/user";
import { useModalStore } from "@/stores/modal";
import { useUnlockerStore } from "@/stores/unlocker";
import subscribeInterceptor from "@/components/crowdsourcing/subscribeInterceptor";
import { storeToRefs } from "pinia";
import {
  CheckIcon,
  ChevronDoubleRightIcon,
  XMarkIcon,
} from "@heroicons/vue/20/solid";
import _ from "lodash";
import dataFieldAliases from "@/assets/dataFieldAliases";
import VueScrollTo from "vue-scrollto";
import ValidationGuidance from "./ValidationGuidance.vue";
import AdminValidationVoteSummary from "@/components/crowdsourcing/AdminValidationVoteSummary.vue";

const props = defineProps([
  "validationData",
  "changeGroupId",
  "apiLookupKey",
  "changeIds",
  "outputFormat",
  "testIndex",
  "groupIndex",
  "changeIndex",
  "nextUp",
  "validatedChangeIds",
]);
const emit = defineEmits(["validation-submitted", "viewed"]);
const expanded = ref(false);
const viewed = ref(false);
const validationSubmissions = ref([]);
const validationInput = ref(null);
const actionInProgress = ref(false);
const validationContextMetaData = ref(null);
const userStore = useUserStore();
const { isAdmin } = storeToRefs(userStore);

const validationColor = computed(() => {
  switch (validationInput.value) {
    case "Accepted":
      return "text-green-500";
    case "Rejected":
      return "text-red-500";
    case "Skipped":
      return "text-blue-500";
    default:
      return "";
  }
});
const isCurrent = computed(() => {
  if (
    _.isNumber(props.nextUp.groupIndex) &&
    _.isNumber(props.nextUp.changeIndex)
  ) {
    return (
      props.nextUp.groupIndex === props.groupIndex &&
      props.nextUp.changeIndex === props.changeIndex
    );
  } else {
    return false;
  }
});
const isNext = computed(() => {
  if (
    _.isNumber(props.nextUp.groupIndex) &&
    _.isNumber(props.nextUp.changeIndex)
  ) {
    return props.nextUp.groupIndex === props.groupIndex;
  } else {
    return false;
  }
});
const scrollId = computed(
  () => `group-${props.groupIndex}-change-${props.changeIndex}`,
);
const typedTestIndex = computed(() => `roll-up-${props.testIndex}`);
const adminApprovable = computed(() => {
  return isAdmin.value;
});
const keyData = computed(() => {
  const [keyType, joiningType, joiningId, decoratingType, name] = _.split(
    props.apiLookupKey,
    "-",
  );

  return {
    keyType,
    joiningType,
    joiningId,
    decoratingType,
    name: `${joiningType} ${joiningId}`,
    noIdName: `${joiningType}`,
    secondaryName: `${decoratingType} ${name}`,
    rawName: name,
  };
});
const secondaryDataAlias = computed(() => {
  return (
    _.get(dataFieldAliases, `${keyData.value.rawName}.singular`) ||
    `${keyData.value.rawName}`
  );
});
const needsOrdering = computed(() => {
  if (_.get(keyData, "secondaryName")) {
    return _.includes(
      ["LandCoveringLevel vertical_level"],
      keyData.value.secondaryName,
    );
  } else {
    return false;
  }
});
const isTopLevel = computed(() => {
  return (
    `${_.get(topLevelField.value, "fieldContentType")} ${_.get(
      topLevelField.value,
      "fieldContentId",
    )}` === keyData.value.name
  );
});
const fieldName = computed(() => {
  return `${keyData.value.joiningType} ${keyData.value.joiningId} ${keyData.value.decoratingType} ${keyData.value.name}`;
});
const oldString = computed(() => {
  if (isNew.value) {
    return "";
  } else {
    const beforeValues = _.get(props.validationData, "before", []);
    const sorted = needsOrdering.value
      ? orderRawValues(beforeValues)
      : beforeValues;

    return sorted.join("\n");
  }
});
const newString = computed(() => {
  const afterValues = _.get(props.validationData, "after", []);
  const sorted = needsOrdering.value
    ? orderRawValues(afterValues)
    : afterValues;

  return sorted.join("\n");
});
const isNew = computed(() => {
  return !_.get(props.validationData, "before");
});
const topLevelField = computed(() => {
  return _.get(props.validationData, "topLevelContent");
});

watch(
  () => props.validatedChangeIds,
  () => {
    if (_.intersection(props.validatedChangeIds, props.changeIds).length > 0) {
      expanded.value = false;
    }
  },
  { deep: true },
);

watch(isCurrent, () => {
  if (isCurrent.value) {
    if (props.nextUp.changeIndex === props.changeIndex) {
      debouncedScroll();
    }
  }
});

watch(
  isNext,
  () => {
    if (isNext.value) {
      expand();
      if (props.nextUp.changeIndex === props.changeIndex) {
        debouncedScroll();
      }
    }
  },
  { deep: true },
);

onMounted(() => {
  if (isNext.value) expand();
  if (keyData.value && !isTopLevel.value) {
    fetchValidationContext();
  }

  if (adminApprovable.value) {
    fetchValidationSubmissions();
  }
});

const debouncedScroll = _.debounce(
  function () {
    if (isCurrent.value) {
      VueScrollTo.scrollTo(`#${scrollId.value}`, 500, {
        container: "#changes-container",
        offset: -80,
      });
    }
  },
  500,
  { leading: true },
);

function toggleExpanded() {
  if (expanded.value) {
    expanded.value = false;
  } else {
    expand();
  }
}

function expand() {
  expanded.value = true;
  if (!viewed.value) {
    emit("viewed", {
      changeIds: props.changeIds,
    });
  }
  viewed.value = true;
}

function orderRawValues(values) {
  switch (keyData.value.secondaryName) {
    case "LandCoveringLevel vertical_level": {
      const numeric = values.map((str) => _.toNumber(str));

      return _.orderBy(numeric, [], ["desc"]);
    }
    default:
      return values;
  }
}
function fetchValidationContext() {
  let contentType = keyData.value?.joiningType;
  let contentId = keyData.value?.joiningId;

  const fetchable =
    contentType &&
    _.trim(contentType) !== "" &&
    contentId &&
    _.trim(contentId) !== "";

  if (fetchable) {
    api.get(`validation_contexts/${contentType}/${contentId}`).then((json) => {
      validationContextMetaData.value = json.data;
    });
  }
}

const modalStore = useModalStore();
const { modalPayload } = storeToRefs(modalStore);
const unlockerStore = useUnlockerStore();
const { upgradeSuccessful } = storeToRefs(unlockerStore);

function acceptAll() {
  const payload = {
    changeIds: props.changeIds.map((id) => {
      return { id };
    }),
  };
  const successCallback = () => {
    expanded.value = false;
    validationInput.value = "Accepted";
    emit("validation-submitted", {
      changeIds: props.changeIds,
      validationInput: validationInput.value,
    });
    actionInProgress.value = false;
  };

  actionInProgress.value = true;

  subscribeInterceptor({
    apiRequestFunc: () => api.post(`validation_accept_changes`, payload),
    successCallback,
    modalPayloadRef: modalPayload,
    upgradeSuccessfulRef: upgradeSuccessful,
    afterSubscribe: "apiRequest",
    context: "validating",
  });
}
function approveAll() {
  if (adminApprovable.value) {
    const payload = {
      changeIds: props.changeIds.map((id) => {
        return { id };
      }),
      adminOverride: "override",
    };

    actionInProgress.value = true;

    api.post(`validation_accept_changes`, payload).then(() => {
      expanded.value = false;
      validationInput.value = "Accepted";
      emit("validation-submitted", {
        changeIds: props.changeIds,
        validationInput: validationInput.value,
      });
      actionInProgress.value = false;
    });
  }
}
function rejectAll() {
  const payload = {
    changeIds: props.changeIds.map((id) => {
      return { id };
    }),
  };
  const successCallback = () => {
    expanded.value = false;
    validationInput.value = "Rejected";
    emit("validation-submitted", {
      changeIds: props.changeIds,
      validationInput: validationInput.value,
    });
    actionInProgress.value = false;
  };

  actionInProgress.value = true;

  subscribeInterceptor({
    apiRequestFunc: () => api.post(`validation_reject_changes`, payload),
    successCallback,
    modalPayloadRef: modalPayload,
    upgradeSuccessfulRef: upgradeSuccessful,
    afterSubscribe: "apiRequest",
    context: "validating",
  });
}
function disapproveAll() {
  if (adminApprovable.value) {
    const payload = {
      changeIds: props.changeIds.map((id) => {
        return { id };
      }),
      adminOverride: "override",
    };

    actionInProgress.value = true;

    api.post(`validation_reject_changes`, payload).then(() => {
      expanded.value = false;
      validationInput.value = "Rejected";
      emit("validation-submitted", {
        changeIds: props.changeIds,
        validationInput: validationInput.value,
      });
      actionInProgress.value = false;
    });
  }
}
function skipAll() {
  const payload = {
    changeIds: props.changeIds.map((id) => {
      return { id };
    }),
  };

  actionInProgress.value = true;

  api.post(`validation_skip_changes`, payload).then(() => {
    expanded.value = false;
    validationInput.value = "Skipped";
    emit("validation-submitted", {
      changeIds: props.changeIds,
      validationInput: validationInput.value,
    });
    actionInProgress.value = false;
  });
}

async function fetchValidationSubmissions() {
  const payload = {
    changeIds: props.changeIds,
  };

  const response = await api.post(
    `data_field_change_validation_submissions`,
    payload,
  );

  if (response?.data) {
    validationSubmissions.value = response.data;
  }
}
</script>
