<template>
  <div
    @click.stop="clicked"
    :data-test="testKey"
    :class="`p-2 w-full h-full pointer-events-auto ${colorStyle}`"
  >
    <div class="w-full h-full flex flex-col">
      <div class="text-xs uppercase tracking-wide" />
      <div v-if="!reordering" class="w-full flex flex-grow justify-between">
        <div
          v-if="
            floorAreaDataField.internalPropertyRight ||
            !floorAreaDataField.fieldContent?.standardizedArea ||
            floorAreaDataField.fieldContent
              ?.minimumSubdivisionStandardizedArea ||
            floorAreaDataField.fieldContent?.isAvailable ||
            floorAreaDataField.fieldContent?.isVacating ||
            floorAreaDataField.fieldContent?.isDark ||
            floorAreaDataField.fieldContent?.isHunting
          "
          class="flex space-x-1"
        >
          <button
            v-if="!floorAreaDataField.fieldContent?.standardizedArea"
            @click="viewFloorAreaSizes"
            v-tooltip="'Floor area has no standardized area (SF)'"
            class="h-3 w-3 text-red-600"
          >
            <i class="fa-solid fa-ruler-horizontal" />
          </button>
          <button
            v-if="
              floorAreaDataField.fieldContent
                ?.minimumSubdivisionStandardizedArea
            "
            @click="viewFloorAreaSizes"
            v-tooltip="'Subdividable'"
            class="m-0.5 h-3 w-3 text-[.9rem] text-blue-700"
          >
            <i class="fa-solid fa-scissors" />
          </button>
          <button
            v-if="floorAreaDataField.fieldContent?.isDark"
            @click="upsertSpaceUsageBuilder"
            v-tooltip="'Space is dark'"
            class="m-0.5 inline-flex items-center justify-center h-4 w-4"
          >
            <LightBulbIcon class="h-4 w-4 text-gray-800" />
          </button>
          <button
            v-else-if="floorAreaDataField.fieldContent?.isVacating"
            @click="upsertSpaceUsageBuilder"
            v-tooltip="'User is vacating'"
            class="m-0.5 inline-flex items-center justify-center h-4 w-4"
          >
            <TruckIcon class="h-4 w-4 text-red-600" />
          </button>
          <button
            v-if="floorAreaDataField.fieldContent?.isAvailable"
            @click="upsertSpaceUsageBuilder"
            v-tooltip="'Available'"
            class="inline-flex items-center justify-center h-3 w-3 rounded-full bg-green-500"
          />
          <button
            v-if="floorAreaDataField.fieldContent?.isHunting"
            @click="upsertSpaceUsageBuilder"
            v-tooltip="'Tenant in the Market'"
            class="h-3 w-3 text-[.9rem] text-red-600"
          >
            <i class="fas fa-crosshairs" />
          </button>
          <div
            v-if="floorAreaDataField.internalPropertyRight"
            :data-test="`${testKey}-${floorAreaDataField.internalPropertyRight.fieldContent.type}`"
          >
            <CubeIcon
              v-observe-visibility="{
                callback: (isVisible, entry) =>
                  internalRightTimelineSelectionHandler(
                    isVisible,
                    entry,
                    floorAreaDataField.internalPropertyRight,
                  ),
                once: true,
              }"
              @click.stop="
                emit(
                  'internal-property-right-click',
                  floorAreaDataField.internalPropertyRight,
                )
              "
              v-tooltip="
                `${floorAreaDataField.internalPropertyRight.fieldContent.type} ${floorAreaDataField.internalPropertyRight.fieldContentId}`
              "
              :class="`h-3 w-3 rounded-sm ${internalPropertyRightBackgroundStyling}`"
            />
          </div>
        </div>
        <div v-else />
        <ul class="flex space-x-1">
          <VMenu
            theme="diagram-popup"
            :disabled="editingMode"
            v-for="enhancement in enhancements"
            :key="enhancement.localId"
            :data-test="`${testKey}-enhancement-${enhancement.fieldContentId}`"
            class="h-3 w-3"
          >
            <SparklesIcon
              @click.stop="emit('property-enhancement-click', enhancement)"
              v-observe-visibility="{
                callback: (isVisible, entry) =>
                  propertyEnhancementTimelineSelectionHandler(
                    isVisible,
                    entry,
                    enhancement,
                  ),
                once: true,
              }"
              :class="`h-3 w-3 rounded-sm ${propertyEnhancementColorStyle(
                enhancement,
                propertyDiagramSelected,
              )}`"
            />
            <template #popper>
              <DataFieldInfoPopup :data-field="enhancement" />
            </template>
          </VMenu>
        </ul>
      </div>
      <div v-if="!reordering" class="w-full flex justify-between">
        <ul class="p-0.5 flex space-x-1">
          <VMenu
            theme="diagram-popup"
            :disabled="editingMode"
            v-for="layout in floorAreaLayouts"
            :key="layout.localId"
            :class="`h-3 w-3 rounded-sm ${floorAreaLayoutColorStyle(
              layout,
              propertyDiagramSelected,
            )}`"
          >
            <QrCodeIcon @click.stop="emit('floor-area-layout-click', layout)" />
            <template #popper>
              <DataFieldInfoPopup :data-field="layout" />
            </template>
          </VMenu>
        </ul>
        <div />
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  CubeIcon,
  QrCodeIcon,
  SparklesIcon,
  TruckIcon,
} from "@heroicons/vue/20/solid";
import DataFieldInfoPopup from "@/components/crowdsourcing/DataFieldInfoPopup.vue";
import floorAreaLayoutColorStyle from "@/components/property-diagram/floorAreaLayoutColorStyles";
import propertyEnhancementColorStyle from "@/components/property-diagram/propertyEnhancementColorStyles";
import { usePropertyDiagramStore } from "@/stores/propertyDiagram";
import { useDealBuilderStore } from "@/stores/dealBuilder";
import { useSpaceUsageBuilderStore } from "@/stores/spaceUsageBuilder";
import { storeToRefs } from "pinia";
import { onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { nextTick } from "vue";
import { LightBulbIcon } from "@heroicons/vue/24/outline";

const props = defineProps([
  "floorAreaDataField",
  "floorAreaLayouts",
  "colorStyle",
  "internalPropertyRightBackgroundStyling",
  "enhancements",
  "clickable",
  "reordering",
  "testKey",
]);
const emit = defineEmits([
  "clicked",
  "refetch",
  "property-enhancement-click",
  "floor-area-layout-click",
  "internal-property-right-click",
]);
const propertyDiagramStore = usePropertyDiagramStore();
const { propertyDiagramSelected, selectedTimelineEvent, editingMode } =
  storeToRefs(propertyDiagramStore);
const dealBuilderStore = useDealBuilderStore();
const spaceUsageBuilderStore = useSpaceUsageBuilderStore();

onMounted(() => timelineSelectionHandler());

function clicked() {
  if (props.clickable) {
    emit("clicked", {
      dataField: props.floorAreaDataField,
      recordType: "FloorArea",
    });
  }
}

function timelineSelectionHandler() {
  if (!selectedTimelineEvent.value) return;
  if (!props.floorAreaDataField) return;

  const timelineContentType =
    selectedTimelineEvent.value.dataField.decoratingContentType;
  const timelineContentId =
    selectedTimelineEvent.value.dataField.decoratingContentId;

  if (
    timelineContentType === "FloorArea" &&
    timelineContentId === props.floorAreaDataField.fieldContentId
  ) {
    clicked();
  }
}

function propertyEnhancementTimelineSelectionHandler(
  isVisible,
  entry,
  enhancement,
) {
  if (!isVisible) return;
  if (!selectedTimelineEvent.value) return;

  const timelineContentType =
    selectedTimelineEvent.value.dataField.decoratingContentType;
  const timelineContentId =
    selectedTimelineEvent.value.dataField.decoratingContentId;

  if (
    timelineContentType === "PropertyEnhancement" &&
    timelineContentId === enhancement.fieldContentId
  ) {
    emit("property-enhancement-click", enhancement);
  }
}

function internalRightTimelineSelectionHandler(
  isVisible,
  entry,
  internalRight,
) {
  if (!isVisible) return;
  if (!selectedTimelineEvent.value) return;

  if (selectedTimelineEvent.value.eventType === "Investment") {
    dealBuilderStore.loadPropertyRightTimelineInvestment({
      propertyRightDataField: internalRight,
    });
  } else {
    const timelineContentType =
      selectedTimelineEvent.value.dataField.decoratingContentType;
    const timelineContentId =
      selectedTimelineEvent.value.dataField.decoratingContentId;

    if (
      timelineContentType === "PropertyRight" &&
      timelineContentId === internalRight.fieldContentId
    ) {
      emit("internal-property-right-click", internalRight);
    }
  }
}

function upsertSpaceUsageBuilder() {
  spaceUsageBuilderStore.upsertSpaceUsageBuilder({
    spaceDataField: props.floorAreaDataField,
  });
}

const router = useRouter();
const route = useRoute();

async function viewFloorAreaSizes() {
  propertyDiagramSelected.value = { dataField: props.floorAreaDataField };

  await nextTick();

  router.push({
    name: route.name,
    query: {
      ...route.query,
      horizontalTab: "Details",
      verticalTab: "Sizes",
    },
  });
}
</script>
