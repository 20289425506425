<template>
  <div v-if="loaded">
    <div v-if="editing || proveForValue" class="flex flex-col space-y-1">
      <div class="text-sm font-medium text-gray-500">
        {{ _.startCase(_.toLower(label)) }}
      </div>
      <div class="relative rounded-md shadow-sm">
        <div
          v-if="leadingAddOn"
          class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
        >
          <span class="text-gray-500 text-sm">
            {{ leadingAddOn }}
          </span>
        </div>
        <input
          v-focus
          v-model="number"
          type="number"
          :min="min"
          :max="max"
          :step="step"
          :name="`${fieldName}`"
          :id="`${fieldName}`"
          :class="{
            'pl-7': leadingAddOn,
            'pr-16': trailingAddOn,
            'w-48': proveForValue,
          }"
          class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-sm border-gray-300 rounded-md"
          :data-test="`${fieldName}${proveForValue ? '-proof' : ''}-input`"
        />
        <div
          v-if="trailingAddOn"
          class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
        >
          <span class="text-gray-500 text-sm">
            {{ trailingAddOn }}
          </span>
        </div>
      </div>
      <div class="flex items-center justify-end space-x-2">
        <button
          @click="cancel"
          type="button"
          class="inline-flex items-center p-1 border border-gray-300 rounded-full shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <XMarkIcon class="h-4 w-4" />
        </button>

        <DataVisibilityButton
          v-if="complete"
          :visibility="visibility"
          tooltip="Save"
          class="inline-flex"
        >
          <template v-slot:button>
            <button
              v-if="complete"
              @click="save(null)"
              :disabled="originatingData"
              type="button"
              :class="
                visibility === 'safezone'
                  ? 'bg-yellow-500 hover:bg-yellow-600 focus:ring-yellow-600'
                  : 'bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500'
              "
              class="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2"
              :data-test="`${fieldName}${proveForValue ? '-proof' : ''}-save`"
            >
              <PulseLoader
                v-if="originatingData"
                :loading="true"
                size="3px"
                color="#f3f4f6"
              />
              <CheckIcon v-else class="h-4 w-4" />
            </button>
          </template>
        </DataVisibilityButton>
      </div>
    </div>

    <div v-show="dataField && !editing && !proveForValue" class="col-span-1">
      <dt class="text-sm font-medium text-gray-500">
        {{ _.startCase(_.toLower(label)) }}
      </dt>
      <dd class="mt-1">
        <DataField
          :data-field="dataField"
          :proof-wrapping-data-field="wrappingDataField"
          text-classes="text-sm"
          :dismiss-on-save="true"
          @dismiss="dismiss"
          @suggest-change="suggestChange"
          @unlocked="unlocked"
          @completed="completed"
          @set-proof="setProof"
        />
      </dd>
    </div>

    <div
      v-if="!dataField && !editing && disabled && !proveForValue"
      class="flex items-center"
    >
      <button
        :disabled="true"
        type="button"
        v-tooltip="disabledDescription"
        :data-test="`add-${fieldName}-button-disabled`"
        class="group py-1 px-1.5 flex items-center justify-between rounded-full border border-gray-300 shadow-sm space-x-2 text-left hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 cursor-not-allowed"
      >
        <span class="min-w-0 flex-1 flex items-center space-x-1">
          <span class="flex-shrink-0 flex items-center">
            <span
              class="inline-flex items-center justify-center h-5 w-5 rounded-full bg-gray-500"
            >
              <NoSymbolIcon class="h-3 w-3 text-white" />
            </span>
          </span>
          <span class="min-w-0 flex-1">
            <span class="text-sm font-medium text-gray-900 truncate"
              >Add {{ label }}</span
            >
          </span>
        </span>
      </button>
    </div>

    <div
      v-else-if="!dataField && !editing && !proveForValue"
      class="flex items-center"
    >
      <DataVisibilityButton :visibility="visibility" :tooltip="description">
        <template v-slot:button>
          <button
            @click="edit"
            type="button"
            :data-test="`add-${fieldName}-button`"
            class="group py-1 px-1.5 flex items-center justify-between rounded-full border border-gray-300 shadow-sm space-x-2 text-left hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <span class="min-w-0 flex-1 flex items-center space-x-1">
              <span class="flex-shrink-0 flex items-center">
                <span
                  :class="
                    visibility === 'safezone'
                      ? 'bg-yellow-500'
                      : 'bg-indigo-600'
                  "
                  class="inline-flex items-center justify-center h-5 w-5 rounded-full"
                >
                  <PencilIcon class="h-3 w-3 text-white" />
                </span>
              </span>
              <span class="min-w-0 flex-1">
                <span class="text-sm font-medium text-gray-900 truncate"
                  >Add {{ label }}</span
                >
              </span>
            </span>
          </button>
        </template>
      </DataVisibilityButton>
    </div>
  </div>
</template>

<script setup>
import { XMarkIcon, CheckIcon } from "@heroicons/vue/20/solid";
import { NoSymbolIcon, PencilIcon } from "@heroicons/vue/24/outline";
import DataField from "@/components/crowdsourcing/DataField.vue";
import { useCrowdsourcedChangeGroupStore } from "@/stores/crowdsourcedChangeGroup";
import { usePropertyDiagramStore } from "@/stores/propertyDiagram";
import { useProveForValueStore } from "@/stores/proveForValue";
import { useNotificationsStore } from "@/stores/notifications";
import { useTimeTravelStore } from "@/stores/timeTravel";
import { useUserAvailableBalancesChannelStore } from "@/stores/userAvailableBalancesChannel";
import api from "@/router/api";
import { computed, onMounted, ref, watch } from "vue";
import { storeToRefs } from "pinia";
import _ from "lodash";
import DataVisibilityButton from "@/components/crowdsourcing/DataVisibilityButton.vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";

const props = defineProps([
  "wrappingDataField",
  "fieldName",
  "numberType",
  "precision",
  "min",
  "max",
  "step",
  "label",
  "leadingAddOn",
  "trailingAddOn",
  "description",
  "disabled",
  "disabledDescription",
  "proveForValue",
  "challengeDataField",
]);
const emit = defineEmits(["set-proof", "unlocked"]);
const editing = ref(false);
const dismissOnSaveId = ref(null);
const number = ref("");
const dataField = ref(null);
const loaded = ref(false);

const notificationsStore = useNotificationsStore();
const proveForValueStore = useProveForValueStore();
const changeGroupStore = useCrowdsourcedChangeGroupStore();
const { originatingData, changeGroupId } = storeToRefs(changeGroupStore);
const propertyDiagramStore = usePropertyDiagramStore();
const { asOfMilliseconds } = storeToRefs(useTimeTravelStore());
const userAvailableBalancesChannelStore =
  useUserAvailableBalancesChannelStore();
const { userAvailableBalancesChannelDataQueue } = storeToRefs(
  userAvailableBalancesChannelStore,
);
const visibility = computed(() =>
  props.proveForValue || _.includes(["land_area"], props.fieldName)
    ? "public"
    : "safezone",
);

const contentType = computed(() => props.wrappingDataField.fieldContentType);
const contentId = computed(() => props.wrappingDataField.fieldContent.id);
const combinedId = computed(() => {
  if (props.wrappingDataField) {
    return null;
  } else {
    return `${contentType.value}${contentId.value}`;
  }
});
const complete = computed(() => {
  const filled = _.trim(number.value) !== "";
  const changed = hasFieldValue.value
    ? _.trim(number.value) !== _.trim(fieldValue.value)
    : true;
  return filled && changed;
});
const hasFieldValue = computed(
  () => fieldValue.value && _.trim(fieldValue.value) !== "",
);
const fieldValue = computed(() => _.get(dataField.value, "fieldValue"));

watch(combinedId, () => {
  fetchDataField();
});
watch(userAvailableBalancesChannelDataQueue, () => {
  const data = _.last(userAvailableBalancesChannelDataQueue.value);
  if (data.triggeredBy === "staked_changes") {
    fetchDataField();
  }
});

onMounted(() => {
  fetchDataField();
});

function setProof(fieldName) {
  emit("set-proof", fieldName);
}

async function fetchDataField(emitPayload = {}, override = false) {
  loaded.value = false;

  if (props.proveForValue && !override) {
    loaded.value = true;
  } else {
    api
      .get(
        `crowdsourced_data_fields/${contentType.value}/${contentId.value}?field_name=${props.fieldName}`,
      )
      .then((json) => {
        dataField.value = json.data;
        loaded.value = true;

        if (_.get(emitPayload, "replaceAfterFetch", false))
          editing.value = true;
        if (override) {
          propertyDiagramStore.refetchDiagramDataField(
            props.wrappingDataField.localId,
          );
        }
      });
  }
}
function unlocked() {
  if (props.proveForValue) {
    console.log("unlocked proof");
    emit("unlocked");
  } else {
    console.log("unlocked displayable number");
    fetchDataField({}, true);
  }
}
function completed() {
  fetchDataField();
}
async function dismiss(
  id,
  successCallback = (json) => propertyDiagramStore.postEditingPatch(json),
) {
  await changeGroupStore.dismissData({
    dataFieldId: id,
    successCallback,
  });
}

async function persist(newProofStatus) {
  if (props.proveForValue && !newProofStatus) {
    let payload = {
      fieldValue: number.value,
      fieldValueType: props.numberType,
      isInner: false,
    };
    const proofResponse = await proveForValueStore.submitProof(
      props.challengeDataField,
      payload,
    );

    return proofResponse;
  } else {
    let endpoint;
    let endpointQueryParams = "";

    switch (props.fieldName) {
      case "land_area":
        endpoint = "property_land_areas";
        break;
      case "standardized_area":
        endpoint = "standardized_floor_areas";
        break;
      case "minimum_subdivision_standardized_area":
        endpoint = "standardized_floor_areas";
        endpointQueryParams = "?sub_type=minimum_subdivision";
        break;
      case "use_based_area":
        endpoint = "use_based_floor_areas";
        break;
      case "height": {
        switch (contentType.value) {
          case "LandCovering":
            endpoint = "land_covering_heights";
            break;
          case "LandCoveringLevel":
            endpoint = "land_covering_level_heights";
            break;
        }
        break;
      }
    }

    if (endpoint) {
      let payload = {
        number: number.value,
        numberType: props.numberType,
      };
      let response = await api.post(
        `${endpoint}/${contentType.value}/${contentId.value}${endpointQueryParams}`,
        _.merge({}, payload, {
          changeGroupId: changeGroupId.value,
          asOf: asOfMilliseconds.value,
        }),
      );

      if (newProofStatus === "rejected") {
        const declassifyPayload = {
          id: response.data.dataField.localId,
        };

        const declassifiedResponse = await api.post(
          `declassify_datapoint`,
          declassifyPayload,
        );
        response.data.dataField = declassifiedResponse.data;
        notificationsStore.addNotification("proofRejected");
      }

      return response;
    }
  }
}
function afterPersist(json) {
  if (json.data?.proofStatus === "rejected") {
    dismissOnSaveId.value = props.challengeDataField.localId;
    save(json.data.proofStatus);
  } else if (json.data?.proofStatus === "accepted") {
    notificationsStore.addNotification("proofAccepted");
    unlocked();
    cancel();
  } else {
    propertyDiagramStore.postEditingPatch(json);
    fetchDataField();
    cancel();
    // $store.dispatch("flash", "Saved!");
  }
}
async function suggestChange(emitPayload) {
  if (_.get(emitPayload, "dismissOnSave", false)) {
    dismissOnSaveId.value = dataField.value.localId;
    number.value = fieldValue.value;
    editing.value = true;
  } else {
    await dismiss(dataField.value.localId, () =>
      fetchDataField({ replaceAfterFetch: true }),
    );
  }
}
function edit() {
  if (fieldValue.value) {
    dismissOnSaveId.value = dataField.value.localId;
    number.value = fieldValue.value;
  } else {
    number.value = "";
  }
  editing.value = true;
}
function cancel() {
  number.value = "";
  dismissOnSaveId.value = null;
  editing.value = false;
}

async function save(newProofStatus = null) {
  if (dismissOnSaveId.value) await dismiss(dismissOnSaveId.value);

  const apiRequestFunc = () => persist(newProofStatus);
  const successCallback = (json) => afterPersist(json);
  const failureCallback = () => cancel();

  if (complete.value) {
    return changeGroupStore.originateData(
      apiRequestFunc,
      successCallback,
      failureCallback,
    );
  }
}
</script>
