<template>
  <div
    class="hidden lg:flex items-center rounded-md ring-1 ring-gray-200 shadow-sm"
  >
    <button
      v-shortkey="['ctrl', 'alt', 's']"
      @shortkey="updateLayout('sideBySide')"
      @click="updateLayout('sideBySide')"
      type="button"
      v-tooltip="'View side-by-side'"
      class="group focus:outline-none focus-visible:ring-2 rounded-md focus-visible:ring-indigo-500"
      data-test="side-by-side-button"
    >
      <span class="sr-only">Switch to vertical split layout</span>
      <svg
        width="42"
        height="36"
        viewBox="-8 -7 42 36"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke="currentColor"
        :class="selected('sideBySide')"
      >
        <path d="M12 3h9a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2h-9" fill="none"></path>
        <path
          d="M3 17V5a2 2 0 0 1 2-2h7a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H5a2 2 0 0 1-2-2Z"
          fill="none"
        ></path>
      </svg>
    </button>
    <button
      v-shortkey="['ctrl', 'alt', 't']"
      @shortkey="updateLayout('topAndBottom')"
      @click="updateLayout('topAndBottom')"
      type="button"
      v-tooltip="'View top-and-bottom'"
      class="group focus:outline-none focus-visible:ring-2 rounded-md focus-visible:ring-gray-400"
      data-test="top-and-bottom-button"
    >
      <span class="sr-only">Switch to horizontal split layout</span>
      <svg
        width="42"
        height="36"
        viewBox="-8 -7 42 36"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke="currentColor"
        :class="selected('topAndBottom')"
      >
        <path d="M23 11V3H3v8h20Z" stroke-width="0" fill="none"></path>
        <path
          d="M23 17V5a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2ZM22 11H4"
          fill="none"
        ></path>
      </svg>
    </button>
    <button
      v-shortkey="['ctrl', 'alt', 'f']"
      @shortkey="updateLayout('fullScreen')"
      @click="updateLayout('fullScreen')"
      type="button"
      v-tooltip="'View full-screen'"
      class="group focus:outline-none focus-visible:ring-2 rounded-md focus-visible:ring-gray-400"
      data-test="full-screen-button"
    >
      <span class="sr-only">Switch to full-screen layout</span>
      <svg
        width="42"
        height="36"
        viewBox="-8 -7 42 36"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke="currentColor"
        :class="selected('fullScreen')"
      >
        <path
          d="M23 17V5a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2Z"
          fill="none"
        ></path>
      </svg>
    </button>
  </div>
</template>

<script setup>
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { storeToRefs } from "pinia";
import { useRoute, useRouter } from "vue-router";
const layoutStore = useWorkspaceLayoutStore();
const { workspaceLayout } = storeToRefs(layoutStore);

function selected(layout) {
  if (workspaceLayout.value === layout) {
    return "text-indigo-500";
  } else {
    return "text-gray-300 hover:text-gray-400";
  }
}
function updateLayout(newLayout) {
  workspaceLayout.value = newLayout;

  if (newLayout === "fullScreen") clearTabQueries();
}

const route = useRoute();
const router = useRouter();

function clearTabQueries() {
  router.push({
    name: route.name,
    query: {
      ...route.query,
      horizontalTab: undefined,
      verticalTab: undefined,
    },
  });
}
</script>
