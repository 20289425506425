<template>
  <div
    v-observe-visibility="{ callback: fetchGroupMetadata, once: true }"
    class="w-64 bg-white divide-y divide-gray-100 focus:outline-none"
    role="menu"
    aria-orientation="vertical"
    aria-labelledby="menu-button"
    tabindex="-1"
  >
    <div v-if="canViewExistingInvestment" class="py-1" role="none">
      <a
        @click.prevent="viewExistingInvestment"
        href=""
        class="text-gray-700 group flex items-center p-2 text-xs hover:bg-gray-100 hover:text-gray-900"
        role="menuitem"
        tabindex="-1"
        id="menu-item-4"
        data-test="view-source-investment-button"
      >
        <svg
          class="flex-shrink-0 mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            d="M8.433 7.418c.155-.103.346-.196.567-.267v1.698a2.305 2.305 0 01-.567-.267C8.07 8.34 8 8.114 8 8c0-.114.07-.34.433-.582zM11 12.849v-1.698c.22.071.412.164.567.267.364.243.433.468.433.582 0 .114-.07.34-.433.582a2.305 2.305 0 01-.567.267z"
          />
          <path
            fill-rule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a1 1 0 10-2 0v.092a4.535 4.535 0 00-1.676.662C6.602 6.234 6 7.009 6 8c0 .99.602 1.765 1.324 2.246.48.32 1.054.545 1.676.662v1.941c-.391-.127-.68-.317-.843-.504a1 1 0 10-1.51 1.31c.562.649 1.413 1.076 2.353 1.253V15a1 1 0 102 0v-.092a4.535 4.535 0 001.676-.662C13.398 13.766 14 12.991 14 12c0-.99-.602-1.765-1.324-2.246A4.535 4.535 0 0011 9.092V7.151c.391.127.68.317.843.504a1 1 0 101.511-1.31c-.563-.649-1.413-1.076-2.354-1.253V5z"
            clip-rule="evenodd"
          />
        </svg>
        View source investment
      </a>
    </div>
    <template v-if="!restricted">
      <div v-if="layerType === 'Prospect'" class="py-1" role="none">
        <VDropdown
          v-if="prospectHuntIds.length > 0 || prospectHunts.data.length > 0"
          placement="left-start"
        >
          <button
            @click="fetchProspectHunts"
            type="button"
            class="w-full text-gray-700 group flex items-center justify-between p-2 text-xs hover:bg-gray-100 hover:text-gray-900"
            data-test="add-task-button"
          >
            <div class="flex items-center">
              <div
                class="inline-flex items-center justify-center flex-shrink-0 text-[1rem] mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500"
              >
                <i class="fas fa-crosshairs" />
              </div>
              View related Hunts
            </div>
            <ChevronRightIcon class="h-4 w-4" />
          </button>
          <template #popper>
            <div class="w-64 flex flex-col">
              <div class="space-y-2">
                <h1 class="px-2 pt-2 text-xs font-semibold text-gray-700">
                  View a Hunt linked to this prospect:
                </h1>
                <ul class="max-h-56 overflow-y-auto divide-y divide-gray-200">
                  <li v-for="hunt in prospectHunts.data" :key="hunt.id">
                    <a
                      @click.prevent="viewHunt(hunt.id)"
                      href=""
                      class="block hover:bg-gray-50"
                    >
                      <div
                        class="flex min-w-0 flex-1 items-center p-1 space-x-2"
                      >
                        <div class="flex-shrink-0 rounded-full p-1.5">
                          <div
                            class="inline-flex items-center justify-center flex-shrink-0 text-[1rem] h-4 w-4 text-gray-400 group-hover:text-gray-500"
                          >
                            <i class="fas fa-crosshairs" />
                          </div>
                        </div>
                        <div class="min-w-0 flex-1">
                          <p class="truncate text-xs font-medium text-gray-700">
                            {{
                              hunt.name === "Hunt"
                                ? `Hunt ${hunt.id}`
                                : hunt.name
                            }}
                          </p>
                        </div>
                      </div>
                    </a>
                  </li>
                  <InfiniteLoading
                    v-if="
                      signedIn && prospectHuntsPagy && prospectHuntsPagy.next
                    "
                    @infinite="loadProspectHunts"
                  />
                </ul>
              </div>
            </div>
          </template>
        </VDropdown>
        <VDropdown
          v-if="
            companyHuntIds.length > 0 || prospectEligibleHunts.data.length > 0
          "
          placement="left-start"
        >
          <button
            @click="fetchProspectEligibleHunts"
            type="button"
            class="w-full text-gray-700 group flex items-center justify-between p-2 text-xs hover:bg-gray-100 hover:text-gray-900"
            data-test="add-task-button"
          >
            <div class="flex items-center">
              <LinkIcon
                class="mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500"
              />
              Link to active Hunt
            </div>
            <ChevronRightIcon class="h-4 w-4" />
          </button>
          <template #popper>
            <div class="w-64 flex flex-col">
              <div class="space-y-2">
                <h1 class="px-2 pt-2 text-xs font-semibold text-gray-700">
                  Select a Hunt<template v-if="companyName">
                    for {{ companyName }}:</template
                  >
                </h1>
                <ul class="max-h-56 overflow-y-auto divide-y divide-gray-200">
                  <li v-for="hunt in prospectEligibleHunts.data" :key="hunt.id">
                    <a
                      v-if="!standaloneCompanyId && !investmentGroupId"
                      @click.prevent="prospectHuntConfirmation(hunt.id)"
                      href=""
                      class="block hover:bg-gray-50"
                    >
                      <div
                        class="flex min-w-0 flex-1 items-center p-1 space-x-2"
                      >
                        <div class="flex-shrink-0 rounded-full p-1.5">
                          <div
                            class="inline-flex items-center justify-center flex-shrink-0 text-[1rem] h-4 w-4 text-gray-400 group-hover:text-gray-500"
                          >
                            <i class="fas fa-crosshairs" />
                          </div>
                        </div>
                        <div class="min-w-0 flex flex-1">
                          <span
                            class="truncate text-xs font-medium text-gray-700"
                          >
                            {{
                              hunt.name === "Hunt"
                                ? `Hunt ${hunt.id}`
                                : hunt.name
                            }}
                          </span>
                        </div>
                      </div>
                    </a>
                    <a
                      v-else-if="companyId"
                      @click.prevent="prospectHuntGroupConfirmation(hunt.id)"
                      href=""
                      class="block hover:bg-gray-50"
                    >
                      <div
                        class="flex min-w-0 flex-1 items-center p-1 space-x-2"
                      >
                        <div class="flex-shrink-0 rounded-full p-1.5">
                          <div
                            class="inline-flex items-center justify-center flex-shrink-0 text-[1rem] h-4 w-4 text-gray-400 group-hover:text-gray-500"
                          >
                            <i class="fas fa-crosshairs" />
                          </div>
                        </div>
                        <div class="min-w-0 flex flex-1">
                          <span
                            class="truncate text-xs font-medium text-gray-700"
                          >
                            {{
                              hunt.name === "Hunt"
                                ? `Hunt ${hunt.id}`
                                : hunt.name
                            }}
                          </span>
                        </div>
                      </div>
                    </a>
                    <VDropdown v-else placement="right">
                      <button
                        class="w-full text-gray-700 group flex items-center justify-between p-2 text-xs hover:bg-gray-50 hover:text-gray-900"
                      >
                        <div
                          class="flex min-w-0 flex-1 items-center p-1 space-x-2"
                        >
                          <div class="flex-shrink-0 rounded-full p-1.5">
                            <div
                              class="inline-flex items-center justify-center flex-shrink-0 text-[1rem] h-4 w-4 text-gray-400 group-hover:text-gray-500"
                            >
                              <i class="fas fa-crosshairs" />
                            </div>
                          </div>
                          <div class="min-w-0 flex flex-1">
                            <span
                              class="truncate text-xs font-medium text-gray-700"
                            >
                              {{
                                hunt.name === "Hunt"
                                  ? `Hunt ${hunt.id}`
                                  : hunt.name
                              }}
                            </span>
                          </div>
                        </div>
                        <ChevronRightIcon class="h-4 w-4" />
                      </button>
                      <template #popper>
                        <DealActionGroupSelector
                          @single-space-action="
                            prospectHuntConfirmation(hunt.id)
                          "
                          @space-group-action="
                            prospectHuntGroupConfirmation(hunt.id)
                          "
                        />
                      </template>
                    </VDropdown>
                  </li>
                  <InfiniteLoading
                    v-if="
                      signedIn &&
                      prospectEligibleHuntsPagy &&
                      prospectEligibleHuntsPagy.next
                    "
                    @infinite="loadProspectEligibleHunts"
                  />
                </ul>
              </div>
            </div>
          </template>
        </VDropdown>

        <button
          @click.prevent="awardExistingInvestment"
          :disabled="originatingData"
          class="text-gray-700 group flex items-center p-2 text-xs hover:bg-gray-100 hover:text-gray-900"
          role="menuitem"
          tabindex="-1"
          id="menu-item-4"
          data-test="award-existing-investment-button"
        >
          <svg
            class="flex-shrink-0 mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              d="M8.433 7.418c.155-.103.346-.196.567-.267v1.698a2.305 2.305 0 01-.567-.267C8.07 8.34 8 8.114 8 8c0-.114.07-.34.433-.582zM11 12.849v-1.698c.22.071.412.164.567.267.364.243.433.468.433.582 0 .114-.07.34-.433.582a2.305 2.305 0 01-.567.267z"
            />
            <path
              fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a1 1 0 10-2 0v.092a4.535 4.535 0 00-1.676.662C6.602 6.234 6 7.009 6 8c0 .99.602 1.765 1.324 2.246.48.32 1.054.545 1.676.662v1.941c-.391-.127-.68-.317-.843-.504a1 1 0 10-1.51 1.31c.562.649 1.413 1.076 2.353 1.253V15a1 1 0 102 0v-.092a4.535 4.535 0 001.676-.662C13.398 13.766 14 12.991 14 12c0-.99-.602-1.765-1.324-2.246A4.535 4.535 0 0011 9.092V7.151c.391.127.68.317.843.504a1 1 0 101.511-1.31c-.563-.649-1.413-1.076-2.354-1.253V5z"
              clip-rule="evenodd"
            />
          </svg>
          Move to under contract
        </button>
      </div>
      <div
        v-if="context === 'awarded-capital-providers'"
        class="py-1"
        role="none"
      >
        <button
          @click.prevent="dropUnderContract"
          :disabled="originatingData"
          class="text-gray-700 group flex items-center p-2 text-xs hover:bg-gray-100 hover:text-gray-900"
          role="menuitem"
          tabindex="-1"
          id="menu-item-4"
          data-test="drop-under-contract-investment-button"
        >
          <svg
            class="flex-shrink-0 mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M13.477 14.89A6 6 0 015.11 6.524l8.367 8.368zm1.414-1.414L6.524 5.11a6 6 0 018.367 8.367zM18 10a8 8 0 11-16 0 8 8 0 0116 0z"
              clip-rule="evenodd"
            />
          </svg>
          Move back to available
        </button>
      </div>
      <template v-if="dealActionsDisplayable">
        <div v-if="portfoliosAllowed" class="py-1" role="none">
          <a
            v-for="(group, index) in investmentGroups"
            :key="index"
            @click.prevent="addToPortfolio(group)"
            href=""
            class="text-gray-700 group flex items-center p-2 text-xs hover:bg-gray-100 hover:text-gray-900"
            role="menuitem"
            tabindex="-1"
            id="menu-item-4"
          >
            <svg
              class="flex-shrink-0 mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM14 11a1 1 0 011 1v1h1a1 1 0 110 2h-1v1a1 1 0 11-2 0v-1h-1a1 1 0 110-2h1v-1a1 1 0 011-1z"
              />
            </svg>
            Add to{{
              _.includes(group.placeholderId, "temp") ? " Draft " : " "
            }}Portfolio{{
              _.includes(group.placeholderId, "temp")
                ? ""
                : group.placeholderId
            }}:
            {{ pluralize("Asset", investmentsLength(group), true) }}
            <template v-if="group.dealAction"
              >&nbsp;({{ group.dealAction }})</template
            >
          </a>
        </div>
        <div
          v-if="canSellOutright || isOwnershipInterest"
          class="py-1"
          role="none"
        >
          <a
            v-if="canSellOutright"
            @click.prevent="prepopulateDealBuilder('sellOutright')"
            href=""
            class="text-gray-700 group flex items-center p-2 text-xs hover:bg-gray-100 hover:text-gray-900"
            role="menuitem"
            tabindex="-1"
            id="menu-item-0"
            data-test="sell-outright-button"
          >
            <svg
              class="mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                d="M8 5a1 1 0 100 2h5.586l-1.293 1.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L13.586 5H8zM12 15a1 1 0 100-2H6.414l1.293-1.293a1 1 0 10-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L6.414 15H12z"
              />
            </svg>
            Sell
          </a>
          <a
            v-else-if="jointOwner"
            @click.prevent="prepopulateDealBuilder('sellEntireInterest')"
            href=""
            class="text-gray-700 group flex items-center p-2 text-xs hover:bg-gray-100 hover:text-gray-900"
            role="menuitem"
            tabindex="-1"
            id="menu-item-1"
            data-test="sell-entire-interest-button"
            v-close-popper
          >
            <svg
              class="mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                d="M8 5a1 1 0 100 2h5.586l-1.293 1.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L13.586 5H8zM12 15a1 1 0 100-2H6.414l1.293-1.293a1 1 0 10-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L6.414 15H12z"
              />
            </svg>
            Sell interest
          </a>
          <a
            v-if="isOwnershipInterest"
            @click.prevent="prepopulateDealBuilder('sellPartialInterest')"
            href=""
            class="text-gray-700 group flex items-center p-2 text-xs hover:bg-gray-100 hover:text-gray-900"
            role="menuitem"
            tabindex="-1"
            id="menu-item-1"
            data-test="sell-partial-interest-button"
            v-close-popper
          >
            <svg
              class="mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z" />
              <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z" />
            </svg>
            Sell partial interest
          </a>
          <a
            v-if="canRefinance"
            @click.prevent="prepopulateDealBuilder('refinance')"
            href=""
            class="text-gray-700 group flex items-center p-2 text-xs hover:bg-gray-100 hover:text-gray-900"
            role="menuitem"
            tabindex="-1"
            id="menu-item-0"
            data-test="refinance-loan-button"
          >
            <svg
              class="mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z"
                clip-rule="evenodd"
              />
            </svg>
            Refinance
          </a>
        </div>
        <div v-if="isLoan && !isOwnershipInterest" class="py-1" role="none">
          <button
            @click.prevent="retireLoan"
            :disabled="originatingData"
            class="text-gray-700 group flex items-center p-2 text-xs hover:bg-gray-100 hover:text-gray-900"
            role="menuitem"
            tabindex="-1"
            id="menu-item-4"
            data-test="retire-loan-button"
          >
            <svg
              class="mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M4 4a2 2 0 00-2 2v4a2 2 0 002 2V6h10a2 2 0 00-2-2H4zm2 6a2 2 0 012-2h8a2 2 0 012 2v4a2 2 0 01-2 2H8a2 2 0 01-2-2v-4zm6 4a2 2 0 100-4 2 2 0 000 4z"
                clip-rule="evenodd"
              />
            </svg>
            Retire / Defease
          </button>
          <a
            @click.prevent="foreclose"
            href=""
            class="text-gray-700 group flex items-center p-2 text-xs hover:bg-gray-100 hover:text-gray-900"
            role="menuitem"
            tabindex="-1"
            id="menu-item-4"
            data-test="foreclose-loan-button"
          >
            <svg
              class="mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M18 8a6 6 0 01-7.743 5.743L10 14l-1 1-1 1H6v2H2v-4l4.257-4.257A6 6 0 1118 8zm-6-4a1 1 0 100 2 2 2 0 012 2 1 1 0 102 0 4 4 0 00-4-4z"
                clip-rule="evenodd"
              />
            </svg>
            Foreclose
          </a>
          <a
            v-if="equitySaleBeingCreated"
            @click.prevent="assumeLoan"
            href=""
            class="text-gray-700 group flex items-center p-2 text-xs hover:bg-gray-100 hover:text-gray-900"
            role="menuitem"
            tabindex="-1"
            id="menu-item-5"
            data-test="assume-loan-button"
          >
            <svg
              class="mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                clip-rule="evenodd"
              />
            </svg>
            Assume loan
          </a>
        </div>
      </template>
    </template>
  </div>
</template>

<script setup>
import DealActionGroupSelector from "@/components/deal-builder/DealActionGroupSelector.vue";
import { useDealBuilderStore } from "@/stores/dealBuilder";
import { useCrowdsourcedChangeGroupStore } from "@/stores/crowdsourcedChangeGroup";
import { useTimeTravelStore } from "@/stores/timeTravel";
import { useModalStore } from "@/stores/modal";
import { useUserStore } from "@/stores/user";
import { ref, computed } from "vue";
import { storeToRefs } from "pinia";
import _ from "lodash";
import api from "@/router/api";
import pluralize from "pluralize";
import decoratingAndFieldKey from "@/components/crowdsourcing/decoratingAndFieldKey";
import { LinkIcon, ChevronRightIcon } from "@heroicons/vue/20/solid";
import { useRoute, useRouter } from "vue-router";

const props = defineProps([
  "dealBuilderAssetDataField",
  "localAssetDataField",
  "decoratingDataField",
  "subordinateLayer",
  "providersCount",
  "existingInvestmentId",
  "investmentGroupId",
  "companyId",
  "ownershipInterestIds",
  "layerType",
  "context",
  "allowsSeniorLoan",
  "seniorLoan",
  "nested",
  "depth",
  "restricted",
]);
const emit = defineEmits([
  "refetch-investment",
  "refetch-group-investments",
  "refetch-stack",
]);

const dealBuilderStore = useDealBuilderStore();
const { dealBuilder, crossInteraction, assetFieldIds } =
  storeToRefs(dealBuilderStore);
const crowdsourcedChangeGroupStore = useCrowdsourcedChangeGroupStore();
const { originatingData, changeGroupId } = storeToRefs(
  crowdsourcedChangeGroupStore,
);
const userStore = useUserStore();
const { signedIn } = storeToRefs(userStore);
const timeTravelStore = useTimeTravelStore();
const { asOfMilliseconds } = storeToRefs(timeTravelStore);
const modalStore = useModalStore();
const { confirmationPayload } = storeToRefs(modalStore);

const prospectHunts = ref({
  data: [],
  pagy: null,
});
const prospectHuntsPagy = computed(() => prospectHunts.value.pagy);
const prospectEligibleHunts = ref({
  data: [],
  pagy: null,
});
const prospectEligibleHuntsPagy = computed(
  () => prospectEligibleHunts.value.pagy,
);

const dealActionsDisplayable = computed(() => {
  return !_.includes(
    [
      "controlling-asset",
      "investment-players",
      "investment-group-players",
      "awarded-capital-providers",
    ],
    props.context,
  );
});
const canViewExistingInvestment = computed(() => {
  return (
    props.existingInvestmentId &&
    _.includes(["controlling-asset", "capital-stack"], props.context)
  );
});
const canSellOutright = computed(() => {
  return multiOwnerAsset.value || soloOwner.value;
});
const soloOwner = computed(() => {
  return isOwnershipInterest.value && props.providersCount === 1;
});
const jointOwner = computed(() => {
  return isOwnershipInterest.value && props.providersCount > 1;
});
const multiOwnerAsset = computed(() => {
  return props.providersCount > 1 && !isOwnershipInterest.value;
});
const canRefinance = computed(() => {
  return (
    !props.allowsSeniorLoan && canSellOutright.value && isEquityPosition.value
  );
});
const isEquityPosition = computed(() => {
  return props.layerType === "OwnershipInterest";
});
const isOwnershipInterest = computed(() => {
  return props.decoratingDataField.fieldContentType === "OwnershipInterest";
});
const isLoan = computed(() => {
  return props.decoratingDataField.decoratingContentType === "Loan";
});
const equitySaleBeingCreated = computed(() => {
  return _.get(futureDeal.value, "dealAction") === "sellOutright";
});
const assetsLength = computed(() => {
  return _.size(dealBuilder.value.assets);
});
const investmentGroups = computed(() => {
  return _.get(dealBuilder.value, "investmentGroups", []);
});
const portfoliosAllowed = computed(() => {
  return (
    dealBuilder.value &&
    assetsLength.value > 1 &&
    investmentGroups.value.length > 0
  );
});
const assetKey = computed(() => {
  return decoratingAndFieldKey(props.dealBuilderAssetDataField);
});
const nestedCapitalStack = computed(() => {
  return decoratingAndFieldKey(props.localAssetDataField) !== assetKey.value;
});
const matchingInvestmentGroup = computed(() => {
  return _.find(
    _.get(dealBuilder.value, "investmentGroups", []),
    findMatchingGroupInvestment,
  );
});
const futureDeal = computed(() => {
  if (matchingInvestmentGroup.value) {
    return findMatchingGroupInvestment(matchingInvestmentGroup.value);
  } else {
    return null;
  }
});
const companyName = computed(
  () => props.decoratingDataField?.fieldContent?.name,
);
const standaloneCompanyId = computed(() => {
  if (
    props.companyId &&
    props.companyId === props.decoratingDataField?.fieldContent?.companyId
  ) {
    return props.companyId;
  } else {
    return null;
  }
});
const companyHuntIds = computed(
  () => props.decoratingDataField?.fieldContent?.huntIds || [],
);
const prospectHuntIds = computed(
  () => props.decoratingDataField?.fieldContent?.prospectHuntIds || [],
);

function findMatchingGroupInvestment(group) {
  return _.find(group.investments, function (investment) {
    if (investment.capitalStackTopLevelAsset) {
      return (
        assetKey.value ===
        decoratingAndFieldKey(investment.capitalStackTopLevelAsset)
      );
    } else {
      return false;
    }
  });
}

const router = useRouter();
const route = useRoute();

function beginDealBuilder({
  dealAction = null,
  seniorLoanForeclosure = false,
  assetDataField = null,
  investmentFieldContent = null,
}) {
  dealBuilderStore.newDealBuilder({
    assetDataField: assetDataField || props.dealBuilderAssetDataField,
    investmentFieldContent,
    ownershipInterestIds: props.ownershipInterestIds,
    dealAction,
    seniorLoanForeclosure,
  });
  router.push({
    name: route.name,
    query: {
      ...route.query,
      horizontalTab: "Deals",
      verticalTab: undefined,
      assetFieldIds: assetFieldIds.value,
    },
  });
}
function prepopulateDealBuilder(dealAction) {
  dealBuilderStore.prepopulateDealBuilder({
    dealAction,
    depth: props.depth,
    futureDeal: futureDeal.value,
    matchingInvestmentGroup: matchingInvestmentGroup.value,
    dealBuilderAssetDataField: props.dealBuilderAssetDataField,
    localAssetDataField: props.localAssetDataField,
    decoratingDataField: props.decoratingDataField,
    ownershipInterestIds: props.ownershipInterestIds,
    nestedCapitalStack: nestedCapitalStack.value,
  });
}
function investmentsLength(investmentGroup) {
  return _.size(investmentGroup.investments);
}
function addToPortfolio(investmentGroup) {
  if (investmentGroup.dealAction === "foreclosureSellOutright") {
    const loanId = props.decoratingDataField.decoratingContentId;
    const seniorLoanForeclosure =
      loanId === _.get(props.seniorLoan, "decoratingContentId");
    dealBuilderStore.addDealBuilderInvestmentToGroup({
      assetDataField: props.dealBuilderAssetDataField,
      investmentFieldContent: props.dealBuilderAssetDataField,
      ownershipInterestIds: [],
      investmentGroupPlaceholderId: investmentGroup.placeholderId,
      seniorLoanForeclosure,
    });
    dealBuilderStore.addDealBuilderAssetForeclosureLoan({
      assetKey: assetKey.value,
      loanId,
    });
    if (props.subordinateLayer) {
      dealBuilder.value.foreclosureSubordinateKey = decoratingAndFieldKey(
        props.subordinateLayer,
      );
    }

    dealBuilderStore.collapsePortfolioAssets({
      groupId: investmentGroup.id || investmentGroup.placeholderId,
    });
    dealBuilderStore.setDealBuilderInvestmentGroupExpanded({
      groupId: investmentGroup.id || investmentGroup.placeholderId,
      expanded: true,
    });
  } else {
    dealBuilderStore.addDealBuilderInvestmentToGroup({
      assetDataField: props.localAssetDataField,
      investmentFieldContent: props.decoratingDataField,
      ownershipInterestIds: props.ownershipInterestIds,
      investmentGroupPlaceholderId: investmentGroup.placeholderId,
    });
    dealBuilderStore.collapsePortfolioAssets({
      groupId: investmentGroup.id || investmentGroup.placeholderId,
    });
    dealBuilderStore.setDealBuilderInvestmentGroupExpanded({
      groupId: investmentGroup.id || investmentGroup.placeholderId,
      expanded: true,
    });

    if (investmentGroup.dealAction === "sellPartialInterest") {
      sellPartialInterest();
    } else if (investmentGroup.dealAction === "sellEntireInterest") {
      sellEntireInterest();
    }
  }
}
function sellEntireInterest() {
  const entireInterestId = props.decoratingDataField.fieldContentId;

  dealBuilderStore.addDealBuilderAssetEntireInterest({
    assetKey: assetKey.value,
    entireInterestId,
  });
}
function sellPartialInterest() {
  const partialInterestId = props.decoratingDataField.fieldContentId;

  dealBuilderStore.addDealBuilderAssetPartialInterest({
    assetKey: assetKey.value,
    partialInterestId,
  });
}
function assumeLoan() {
  const loanId = props.decoratingDataField.decoratingContentId;

  dealBuilderStore.addDealBuilderAssetAssumptionLoan({
    assetKey: assetKey.value,
    loanId,
  });
}
function foreclose() {
  const loanId = props.decoratingDataField.decoratingContentId;
  const seniorLoanForeclosure =
    loanId === _.get(props.seniorLoan, "decoratingContentId");

  topLevelSale(seniorLoanForeclosure);

  dealBuilderStore.addDealBuilderAssetForeclosureLoan({
    assetKey: assetKey.value,
    loanId,
  });

  if (props.subordinateLayer) {
    dealBuilder.value.foreclosureSubordinateKey = decoratingAndFieldKey(
      props.subordinateLayer,
    );
  }
}
function topLevelSale(seniorLoanForeclosure = false) {
  if (dealBuilder.value) {
    dealBuilderStore.addDealBuilderInvestmentGroup({
      assetDataField: props.dealBuilderAssetDataField,
      investmentFieldContent: props.dealBuilderAssetDataField,
      ownershipInterestIds: [],
      dealAction: "foreclosureSellOutright",
      seniorLoanForeclosure,
    });
  } else {
    beginDealBuilder({
      dealAction: "foreclosureSellOutright",
      seniorLoanForeclosure,
      assetDataField: props.dealBuilderAssetDataField,
      investmentFieldContent: props.dealBuilderAssetDataField,
    });
  }
}
function viewExistingInvestment() {
  if (!dealBuilder.value) {
    beginDealBuilder({});
  }

  crossInteraction.value = {
    dealAction: null,
    assetKey: decoratingAndFieldKey(props.dealBuilderAssetDataField),
    combinedKey: props.existingInvestmentId,
    focus: "Players",
    source: "InvolvedCompanyMenu",
  };
}
async function awardExistingInvestment() {
  if (!originatingData.value) {
    if (props.investmentGroupId) {
      dealBuilderStore.clearDealBuilder(false);
      await dealBuilderStore.awardGroupInvestment({
        investmentGroupId: props.investmentGroupId,
        companyId: props.companyId,
      });
      emit("refetch-group-investments");
    } else {
      dealBuilderStore.clearDealBuilder(false);
      await dealBuilderStore.awardInvestment({
        investmentId: props.existingInvestmentId,
        companyInvolvementId: props.decoratingDataField.fieldContentId,
      });
      emit("refetch-investment");
    }
  }
}
async function retireLoan() {
  if (!originatingData.value) {
    const { decoratingContentId, decoratingContentType } =
      props.localAssetDataField;

    if (decoratingContentType === "Loan") {
      await dealBuilderStore.retireLoan({
        loanId: decoratingContentId,
      });
    }
  }
}
async function dropUnderContract() {
  if (!originatingData.value) {
    if (props.investmentGroupId) {
      dealBuilderStore.clearDealBuilder(false);
      await dealBuilderStore.dropAwardedGroupInvestment({
        investmentGroupId: props.investmentGroupId,
      });
      emit("refetch-group-investments");
    } else {
      dealBuilderStore.clearDealBuilder(false);
      await dealBuilderStore.dropAwardedInvestment({
        investmentId: props.existingInvestmentId,
      });
      emit("refetch-investment");
    }
  }
}

function cleanUrl(url) {
  return url.replace("/api/v1/", "");
}

async function fetchProspectHunts() {
  if (prospectHuntIds.value) {
    const payload = {
      huntIds: prospectHuntIds.value,
    };
    const huntsResponse = await api.post(`company_hunts`, payload);

    prospectHunts.value = huntsResponse?.data;
  }
}

const loadProspectHunts = async ($state) => {
  if (signedIn.value && prospectHuntsPagy.value.next) {
    const endpoint = cleanUrl(prospectHuntsPagy.value.next_url);
    try {
      api.get(endpoint).then((json) => {
        const { data, pagy } = json.data;

        prospectHunts.value.data = _.concat(prospectHunts.value.data, data);
        prospectHunts.value.pagy = pagy;
        if (data.length < 20) $state.complete();
        else {
          $state.loaded();
        }
      });
    } catch (error) {
      $state.error();
    }
  } else {
    $state.complete();
  }
};

async function fetchProspectEligibleHunts() {
  if (companyHuntIds.value) {
    // TODO: Filter by investment property use, location, size, etc.
    const payload = {
      huntIds: companyHuntIds.value,
      huntType: "investor",
      asOf: asOfMilliseconds.value,
    };
    const huntsResponse = await api.post(`company_hunts`, payload);

    prospectEligibleHunts.value = huntsResponse?.data;
  }
}

const loadProspectEligibleHunts = async ($state) => {
  if (signedIn.value && prospectEligibleHuntsPagy.value.next) {
    const endpoint = cleanUrl(prospectEligibleHuntsPagy.value.next_url);
    try {
      api.get(endpoint).then((json) => {
        const { data, pagy } = json.data;

        prospectEligibleHunts.value.data = _.concat(
          prospectEligibleHunts.value.data,
          data,
        );
        prospectEligibleHunts.value.pagy = pagy;
        if (data.length < 20) $state.complete();
        else {
          $state.loaded();
        }
      });
    } catch (error) {
      $state.error();
    }
  } else {
    $state.complete();
  }
};

async function viewHunt(huntId) {
  if (props.decoratingDataField.fieldContent?.companyId) {
    router.push({
      name: "CompanyShell",
      params: { companyId: props.decoratingDataField.fieldContent.companyId },
      query: {
        ...route.query,
        propertyIds: undefined,
        huntId,
      },
    });
  }
}

function prospectHuntConfirmation(huntId) {
  confirmationPayload.value = {
    title: "Link to Active Hunt (Single Deal)",
    message:
      "Do you want to link this deal to an existing (Safezone) Hunt for this company?",
    affirmText: "Create",
    affirmCallback: () => {
      linkHunt({
        linkType: "prospectHunt",
        huntId,
        investmentId: props.existingInvestmentId,
        investmentGroupId: null,
      });
    },
  };
}

function prospectHuntGroupConfirmation(huntId) {
  confirmationPayload.value = {
    title: "Link to Active Hunt (Portfolio)",
    message:
      "Do you want to link the entire portfolio (all deals) to an existing (Safezone) Hunt for this company?",
    affirmText: "Create",
    affirmCallback: () => {
      linkHunt({
        linkType: "prospectHunt",
        huntId,
        investmentId: null,
        investmentGroupId: props.investmentGroupId,
      });
    },
  };
}

async function linkHunt({ linkType, huntId, investmentId, investmentGroupId }) {
  if (!originatingData.value) {
    const persistPayload = {
      investmentId,
      investmentGroupId,
      huntId,
      companyId:
        linkType === "prospectHunt" && investmentGroupId
          ? props.companyId
          : null,
      companyInvolvementId:
        linkType === "prospectHunt" && investmentId
          ? props.decoratingDataField.fieldContentId
          : null,
      actionType: linkType,
      changeGroupId: changeGroupId.value,
    };

    const apiRequestFunc = () => api.post(`investment_actions`, persistPayload);
    const successCallback = () => {
      timeTravelStore.triggerRefetch();
    };
    const failureCallback = () => {};

    crowdsourcedChangeGroupStore.originateData(
      apiRequestFunc,
      successCallback,
      failureCallback,
    );
  }
}

async function fetchGroupMetadata(isVisible) {
  if (isVisible && props.investmentGroupId && props.companyId) {
    const allPayload = {
      companyId: props.companyId,
      huntType: "investor",
    };
    const prospectsPayload = {
      companyId: props.companyId,
      huntType: "investor",
      asOf: asOfMilliseconds.value,
    };
    api.post(`company_hunts`, allPayload).then((json) => {
      prospectHunts.value = json.data;
    });
    api.post(`company_hunts`, prospectsPayload).then((json) => {
      prospectEligibleHunts.value = json.data;
    });
  }
}
</script>
