<template>
  <div class="">
    <div class="mt-2 flex flex-col space-y-2">
      <div class="text-sm font-medium text-gray-500">Define Steps</div>
      <CashflowDefinitionStep
        v-for="(period, periodKey) in cashflowSchedule"
        :key="periodKey"
        :period-key="_.toNumber(periodKey)"
        :period="period"
        :date-fields="dateFields"
        :per-area-size="perAreaSize"
      />
      <button
        @click="cashflowDefinitionStore.addScheduleItem()"
        :disabled="originatingData"
        type="button"
        class="group py-1 px-1.5 flex items-center justify-between rounded-full border border-gray-300 shadow-sm space-x-2 text-left hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
      >
        <PulseLoader
          v-if="originatingData"
          :loading="true"
          size="3px"
          color="#f3f4f6"
        />
        <span v-else class="min-w-0 flex-1 flex items-center space-x-1">
          <span class="flex-shrink-0 flex items-center">
            <span
              class="inline-flex items-center justify-center h-5 w-5 rounded-full bg-yellow-600"
            >
              <PlusIcon class="h-3 w-3 text-white" />
            </span>
          </span>
          <span class="min-w-0 flex-1">
            <span class="text-sm font-medium text-gray-900 truncate"
              >Add step</span
            >
          </span>
        </span>
      </button>
    </div>

    <div class="flex flex-col mt-6">
      <SteppedCashflowPreviewTable
        :data-field="dataField"
        :date-fields="dateFields"
        :per-area-size="perAreaSize"
      />
    </div>

    <div class="mt-2 flex items-center justify-between">
      <div
        v-if="commencedDate || expiredDate"
        class="flex items-center space-x-1 text-sm font-semibold text-gray-700"
      >
        <span v-if="commencedDate">LCD: {{ commencedDate }}</span>
        <span v-if="commencedDate && expiredDate">&middot;</span>
        <span v-if="expiredDate">LXD: {{ expiredDate }}</span>
      </div>
      <div v-else />
      <div class="flex items-center space-x-2">
        <button
          @click="emit('cancel')"
          type="button"
          class="inline-flex items-center p-1 border border-gray-300 rounded-full shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
        >
          <XMarkIcon class="h-4 w-4" />
        </button>
        <DataVisibilityButton
          v-if="savePayload"
          visibility="safezone"
          tooltip="Save"
          class="inline-flex"
        >
          <template v-slot:button>
            <button
              @click="save"
              :disabled="originatingData"
              type="button"
              class="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm bg-yellow-500 hover:bg-yellow-600 focus:ring-yellow-600 text-white focus:outline-none focus:ring-2 focus:ring-offset-2"
            >
              <PulseLoader
                v-if="originatingData"
                :loading="true"
                size="3px"
                color="#f3f4f6"
              />
              <CheckIcon v-else class="h-4 w-4" />
            </button>
          </template>
        </DataVisibilityButton>
      </div>
    </div>
  </div>
</template>

<script setup>
import { XMarkIcon, CheckIcon } from "@heroicons/vue/20/solid";
import { PlusIcon } from "@heroicons/vue/24/outline";
import CashflowDefinitionStep from "@/components/crowdsourcing/CashflowDefinitionStep.vue";
import SteppedCashflowPreviewTable from "@/components/crowdsourcing/SteppedCashflowPreviewTable.vue";
import DataVisibilityButton from "@/components/crowdsourcing/DataVisibilityButton.vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { useCashflowDefinitionStore } from "@/stores/cashflowDefinition";
import { useCrowdsourcedChangeGroupStore } from "@/stores/crowdsourcedChangeGroup";
import { useTimeTravelStore } from "@/stores/timeTravel";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import api from "@/router/api";
import _ from "lodash";
import moment from "moment";

const props = defineProps(["dataField", "dateFields", "perAreaSize"]);
const emit = defineEmits(["refetch", "cancel"]);

const cashflowDefinitionStore = useCashflowDefinitionStore();
const { cashflowSchedule, savePayload } = storeToRefs(cashflowDefinitionStore);
const changeGroupStore = useCrowdsourcedChangeGroupStore();
const { originatingData, changeGroupId } = storeToRefs(changeGroupStore);
const timeTravelStore = useTimeTravelStore();

const commencedDate = computed(() => {
  if (props.dateFields.commenced_date?.fieldDate) {
    return formattedDate("commenced_date");
  } else {
    return null;
  }
});
const expiredDate = computed(() => {
  if (props.dateFields.expired_date?.fieldDate) {
    return formattedDate("expired_date");
  } else {
    return null;
  }
});

function formattedDate(dateName) {
  return moment(props.dateFields[dateName].fieldDate).format("MMM D, YYYY");
}

function cancel() {
  cashflowDefinitionStore.reset();
  emit("cancel");
}

function persist() {
  return api.post(
    `cashflow_schedules`,
    _.merge({}, savePayload.value, {
      contentType: props.dataField.fieldContentType,
      contentId: props.dataField.fieldContentId,
      name: "Base Rent Schedule",
      scheduleType: "stepping",
      reportingGroup: "base_rent",
      changeGroupId: changeGroupId.value,
    }),
  );
}
function afterPersist() {
  cashflowDefinitionStore.reset();
  // timeTravelStore.triggerRefetch();
  emit("refetch");
}
async function save() {
  if (savePayload.value) {
    const apiRequestFunc = () => persist();
    const successCallback = (json) => afterPersist(json);
    const failureCallback = () => cancel();
    await changeGroupStore.originateData(
      apiRequestFunc,
      successCallback,
      failureCallback,
    );
  }
}
</script>
