<template>
  <div class="h-full rounded-lg overflow-hidden">
    <CardBranding :card="card" />
    <div class="absolute inset-0">
      <div
        class="absolute inset-0 bg-gradient-to-r from-gray-700 to-gray-400 mix-blend-multiply"
      />
    </div>
    <div
      v-if="validationSubmissions"
      class="h-full relative px-4 py-16 flex flex-col justify-center"
    >
      <template v-if="validationSubmissions.role === 'originator'">
        <h1 class="text-center text-4xl font-bold tracking-tight">
          <span class="block text-white">{{
            pluralize("Vote", validationSubmissions.votes, true)
          }}</span>
          <span class="block text-gray-200">On Your Edits</span>
        </h1>
        <div class="mt-6 mx-auto max-w-lg text-center text-xl text-green-300">
          {{ validationSubmissions.voteSummary.accepted }}
        </div>
        <div class="mx-auto max-w-lg text-center text-xl text-red-300">
          {{ validationSubmissions.voteSummary.rejected }}
        </div>
        <div class="mx-auto max-w-lg text-center text-xl text-blue-300">
          {{ validationSubmissions.voteSummary.skipped }}
        </div>
        <div class="flex items-center justify-center space-x-2">
          <span class="text-gray-200">(so far)</span>
        </div>
      </template>
      <template v-else>
        <h1 class="text-center text-4xl font-bold tracking-tight">
          <span class="block text-white">Votes</span>
          <span class="block text-gray-200">Coming In</span>
        </h1>
        <div class="mt-6 mx-auto max-w-lg text-center text-xl text-gray-200">
          For a batch of changes you voted on
        </div>
      </template>
      <div v-if="isDesktop" class="mx-auto mt-10 max-w-sm flex justify-center">
        <div class="space-y-4">
          <a
            href=""
            @click.prevent="viewContributions"
            class="flex items-center justify-center rounded-md border border-transparent bg-white px-4 py-3 text-base font-medium text-gray-500 shadow-sm hover:bg-gray-50 sm:px-8"
            >Track contributions</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import CardBranding from "@/components/collectible-cards/CardBranding.vue";
import api from "@/router/api";
import pluralize from "pluralize";
import { ref, computed, watch, onMounted } from "vue";
import _ from "lodash";
import { useRoute, useRouter } from "vue-router";
import { useCollectibleCardsChannelStore } from "@/stores/collectibleCardsChannel";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { storeToRefs } from "pinia";

const props = defineProps([
  "card",
  "cardIndex",
  "stackIndex",
  "completedCardIds",
]);

const layoutStore = useWorkspaceLayoutStore();
const { isDesktop } = storeToRefs(layoutStore);
const collectibleCardsChannelStore = useCollectibleCardsChannelStore();
const { collectibleCardsChannelDataQueue } = storeToRefs(
  collectibleCardsChannelStore,
);

const validationSubmissions = ref(null);
const cardContents = ref([]);
const changeGroup = computed(() => _.head(cardContents.value)?.content);

watch(
  () => props.stackIndex,
  () => {
    if (validationSubmissions.value === null) fetchTopCardChangeGroup();
  },
);
watch(collectibleCardsChannelDataQueue, () => {
  fetchAvailableValidations();
});

onMounted(() => {
  fetchTopCardChangeGroup();
});

function fetchTopCardChangeGroup() {
  if (
    props.stackIndex === props.cardIndex &&
    !_.includes(props.completedCardIds, props.card.id)
  ) {
    api
      .get(
        `collectible_cards/${props.card.collectibleCardId}/collectible_card_contents`,
      )
      .then((json) => {
        cardContents.value = json.data;
        fetchAvailableValidations();
      });
  }
}

async function fetchAvailableValidations() {
  return new Promise((resolve) => {
    if (
      changeGroup.value &&
      props.stackIndex === props.cardIndex &&
      !_.includes(props.completedCardIds, props.card.id)
    ) {
      api.get(`voting_summary/${changeGroup.value.id}`).then((json) => {
        validationSubmissions.value = json.data;

        resolve();
      });
    } else {
      resolve();
    }
  });
}

const router = useRouter();
const route = useRoute();

function viewContributions() {
  if (isDesktop.value) {
    router.push({
      name: route.name,
      query: {
        ...route.query,
        horizontalTab: "Me",
        verticalTab: "Contributions",
      },
    });
  }
}
</script>
