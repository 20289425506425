<template>
  <div class="flex-grow">
    <div class="px-4 py-3">
      <h1 class="text-lg leading-5 font-bold text-gray-900">
        {{ category.shortName || category.name }}
      </h1>
    </div>
    <CollapsibleHelpTabSubCategory
      v-for="(subcategory, index) in category.subcategories"
      :key="index"
      :subcategory="subcategory"
    />
  </div>
</template>

<script setup>
import { useDocumentationStore } from "@/stores/documentation";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import _ from "lodash";
import CollapsibleHelpTabSubCategory from "@/components/help-center/CollapsibleHelpTabSubCategory.vue";

const props = defineProps(["categoryId"]);

const documentationStore = useDocumentationStore();
const { categories } = storeToRefs(documentationStore);

const category = computed(() => {
  return _.find(categories.value, { id: _.toNumber(props.categoryId) });
});
</script>
