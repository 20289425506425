<template>
  <div
    class="flex flex-col divide-y divide-gray-200"
    :data-test="`Unlocated-Space-${space.fieldContentId}-container`"
  >
    <div
      :class="[
        relocating ? 'bg-indigo-200 hover:bg-indigo-300' : '',
        spaceSelected
          ? 'bg-orange-200 hover:bg-orange-300'
          : 'hover:bg-gray-50',
      ]"
      class="p-2 flex items-center justify-between text-gray-500 text-sm"
    >
      <div class="flex items-center space-x-1">
        <button
          @click.prevent="expanded = !expanded"
          type="button"
          :data-test="`Unlocated-Space-${space.fieldContentId}-details-header-collapse-icon`"
          class="h-5 w-5 inline-flex justify-center items-center text-sm text-gray-700 hover:text-gray-800"
        >
          <ChevronDownIcon class="h-5 w-5" />
        </button>
        <button
          v-if="spaceSelected"
          @click="
            spaceUsageBuilderStore.setSelectedUnlocatedSpace({
              spaceKey,
              selectedSpace: null,
            })
          "
          type="button"
          v-tooltip="'Unselect this space'"
          class="h-5 w-5 inline-flex justify-center items-center text-sm text-gray-700 hover:text-gray-800"
        >
          <NoSymbolIcon class="h-5 w-5" />
        </button>
        <button
          v-else
          @click="selectSpace"
          type="button"
          v-tooltip="'Focus on this space'"
          class="h-5 w-5 inline-flex justify-center items-center text-sm text-gray-700 hover:text-gray-800"
        >
          <MagnifyingGlassIcon class="h-5 w-5" />
        </button>
        <DataField
          :data-field="space"
          text-classes="text-sm font-medium"
          text-styles=""
          @open-sourced="refetchBuilder"
          @unlocked="refetchBuilder"
          @completed="refetchBuilder"
          @dismiss="refetchBuilder"
        />
      </div>
      <div class="flex items-center space-x-1">
        <template v-if="propertyIdParam && relocating">
          <DataVisibilityButton
            v-if="
              propertyDiagramAdded.length > 0 &&
              propertyDiagramEditAction?.floorArea?.fieldContentId
            "
            tooltip="Move this floor area to a building level"
            class="self-end inline-flex"
          >
            <template v-slot:button>
              <button
                @click="saveRelocatedFloorArea"
                type="button"
                :disabled="actionInProgress"
                data-test="save-relocated-floor-area-button"
                class="inline-flex items-center px-2 py-1 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <PulseLoader
                  v-if="actionInProgress"
                  :loading="true"
                  size="6px"
                  color="#f3f4f6"
                />
                <template v-else>Save</template>
              </button>
            </template>
          </DataVisibilityButton>
          <span v-else class="text-xs text-indigo-600 font-semibold"
            >Select a diagram building level, or:</span
          >
          <button
            @click="cancelFloorAreaRelocation"
            type="button"
            class="rounded bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            Cancel
          </button>
        </template>
        <DataVisibilityButton
          v-else-if="propertyIdParam"
          tooltip="Move this floor area to a building level"
          class="self-end inline-flex"
        >
          <template v-slot:button>
            <button
              @click="relocateFloorArea"
              type="button"
              :disabled="actionInProgress"
              data-test="relocate-unlocated-floor-area-button"
              class="inline-flex items-center px-2 py-1 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <PulseLoader
                v-if="actionInProgress"
                :loading="true"
                size="6px"
                color="#f3f4f6"
              />
              <template v-else>Relocate</template>
            </button>
          </template>
        </DataVisibilityButton>
      </div>
    </div>

    <div v-if="expanded" class="flex flex-1">
      <!-- Narrow sidebar-->
      <nav
        aria-label="Sidebar"
        class="flex-shrink-0 p-2 bg-gray-100 overflow-y-auto"
      >
        <div class="relative flex flex-col space-y-2">
          <a
            v-for="tab in tabs"
            :key="tab.name"
            @click.prevent="selectedTab = tab"
            :href="tab.href"
            v-tooltip="tab.name"
            :class="[
              selectedTab.name === tab.name
                ? 'text-gray-700 bg-gray-300'
                : 'text-gray-500 hover:bg-gray-200',
              'flex-shrink-0 inline-flex items-center justify-center h-8 w-8 rounded-lg',
            ]"
            class=""
            :data-test="`${_.kebabCase(tab.name)}-nav`"
          >
            <span class="sr-only">{{ tab.name }}</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              :class="selectedTab.name === tab.name ? '' : ''"
              class="h-5 w-5"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 24 24"
              v-html="pathsForIcon(tab.svgPaths)"
            ></svg>
          </a>
        </div>
      </nav>

      <div class="flex-grow">
        <component :is="selectedTab.component" v-bind="selectedTabProperties" />
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  ChevronDownIcon,
  MagnifyingGlassIcon,
  NoSymbolIcon,
} from "@heroicons/vue/20/solid";
import DataField from "@/components/crowdsourcing/DataField.vue";
import DataVisibilityButton from "@/components/crowdsourcing/DataVisibilityButton.vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { useSpaceUsageBuilderStore } from "@/stores/spaceUsageBuilder";
import { usePropertyDiagramStore } from "@/stores/propertyDiagram";
import { usePropertyDiagramActionsStore } from "@/stores/propertyDiagramActions";
import { useTimeTravelStore } from "@/stores/timeTravel";
import {
  initialSelectedTabFor,
  tabsFor,
} from "@/components/crowdsourcing/dataFieldDetailsTabs";
import pathsForIcon from "@/assets/pathsForIcon";
import { storeToRefs } from "pinia";
import { ref, computed, watch, nextTick } from "vue";
import centerConsoleActions from "@/components/crowdsourcing/centerConsoleActions";
import decoratingAndFieldKey from "@/components/crowdsourcing/decoratingAndFieldKey";
import _ from "lodash";

const props = defineProps(["space", "spaceObject"]);
const emit = defineEmits(["refetch"]);

const diagramActionsStore = usePropertyDiagramActionsStore();
const { actionInProgress, focalAction } = storeToRefs(diagramActionsStore);
const propertyDiagramStore = usePropertyDiagramStore();
const {
  propertyIdParam,
  editingMode,
  propertyDiagramAdded,
  propertyDiagramSelected,
  propertyDiagramSelectedPropertyId,
  propertyDiagramActionTrigger,
  propertyDiagramEditAction,
  floorAreaDataFields,
} = storeToRefs(propertyDiagramStore);
const spaceUsageBuilderStore = useSpaceUsageBuilderStore();
const { refetchSpaceUsageBuilderEditor } = storeToRefs(spaceUsageBuilderStore);
const timeTravelStore = useTimeTravelStore();

const relocating = ref(false);
const tabs = ref(tabsFor(props.space));
const selectedTab = ref(initialSelectedTabFor(props.space));
const selectedTabProperties = computed(() => {
  return {
    dataField: props.space,
    contentDataField: props.space,
    focus: false,
    context: "space-usage-stack",
  };
});
const relocateFloorAreaButtonData = computed(() =>
  _.get(centerConsoleActions, "relocateFloorArea"),
);
const saveActionButtonData = computed(() =>
  _.get(centerConsoleActions, "saveEditAction"),
);

const expanded = ref(false);
const spaceKey = computed(() =>
  decoratingAndFieldKey(props.spaceObject.dataField),
);
const spaceSelected = computed(
  () =>
    props.spaceObject.selectedUnlocatedSpace?.fieldContentId ===
    props.space.fieldContentId,
);

watch(
  propertyDiagramSelected,
  async (val, oldVal) => {
    if (oldVal && !val && relocating.value && propertyIdParam.value) {
      // console.log("relocation completed");
      const relocatedFloorAreaDataField = _.find(floorAreaDataFields.value, {
        fieldContentId: props.space.fieldContentId,
        decoratingContentType: "LandCoveringLevel",
        deprecatedAt: null,
      });
      if (relocatedFloorAreaDataField) {
        // console.log("upsert newly relocated space");
        spaceUsageBuilderStore.upsertSpaceUsageBuilder({
          spaceDataField: relocatedFloorAreaDataField,
          collapseAll: false,
        });
      }
      relocating.value = false;
      await nextTick();
      emit("refetch");
    }
  },
  { deep: true },
);

function selectSpace() {
  spaceUsageBuilderStore.setSelectedUnlocatedSpace({
    spaceKey: spaceKey.value,
    selectedSpace: props.space,
  });
}

function refetchBuilder() {
  spaceUsageBuilderStore.clearBuilder(false);
  spaceUsageBuilderStore.resetAvailabilities();
  refetchSpaceUsageBuilderEditor.value = spaceKey.value;
  timeTravelStore.triggerRefetch();
}

function saveRelocatedFloorArea() {
  if (
    propertyIdParam.value &&
    propertyDiagramEditAction.value?.floorArea?.fieldContentId
  ) {
    focalAction.value = saveActionButtonData.value;
    propertyDiagramActionTrigger.value = {
      propertyId: propertyDiagramSelectedPropertyId.value,
      action: saveActionButtonData.value.directAction,
    };
  }
}

function relocateFloorArea() {
  if (propertyIdParam.value) {
    relocating.value = true;
    editingMode.value = true;
    propertyDiagramSelected.value = {
      recordType: "FloorArea",
      dataField: props.space,
    };
    focalAction.value = relocateFloorAreaButtonData.value;
    propertyDiagramActionTrigger.value = {
      propertyId: propertyDiagramSelectedPropertyId.value,
      action: relocateFloorAreaButtonData.value.directAction,
    };
  }
}

function cancelFloorAreaRelocation() {
  diagramActionsStore.cancel(propertyDiagramSelectedPropertyId.value);
  editingMode.value = false;
  relocating.value = false;
}
</script>
