<template>
  <VMenu :disabled="!canDisplayMenu">
    <button
      @click.exact="markerSingleClick"
      @click.ctrl.exact="markerCtrlClick"
      @mouseenter="debounceMicroMapFetch(event)"
      v-tooltip="canDisplayMenu ? '' : `${propertyName}`"
      type="button"
      :class="[
        `inline-flex flex-shrink-0 items-center justify-center ${markerSize} ${borderSize} ${borderColor} rounded-md shadow-sm text-white ${markerColor} focus:ring-2 focus:outline-none focus:ring-offset-2`,
        timelinePulse ? 'timeline-pulse' : '',
        discoveryPulse ? 'discovery-pulse' : '',
      ]"
      :data-test="`property-marker-${locationDataField.decoratingContentId}`"
    >
      <div
        v-if="diagramSelected"
        :class="`${pulseMarkerSize} bg-transparent flex-shrink-0`"
        :style="`box-shadow: 0 0 10px 10px ${markerPulseColor}; animation: pulsate 1.5s ease-out; animation-iteration-count: infinite; animation-delay: 2s;`"
      />
    </button>

    <template #popper>
      <div
        v-observe-visibility="{ callback: fetchPropertyDataField, once: true }"
        class="flex flex-col p-1"
      >
        <div class="flex space-x-1">
          <div class="text-sm text-gray-700 font-bold">Property</div>
          <button @click="getHelp" type="button" v-tooltip="'Get help'">
            <QuestionMarkCircleIcon class="h-4 w-4 text-gray-700" />
          </button>
        </div>
        <DataField
          v-if="propertyDataField"
          :data-field="propertyDataField"
          :user-store="userStore"
          :tasks-store="tasksStore"
          :task-list-store="taskListStore"
          :reminder-store="reminderStore"
          :guest-profile-store="guestProfileStore"
          :property-diagram-store="propertyDiagramStore"
          :change-group-store="changeGroupStore"
          :modal-store="modalStore"
          :unlocker-store="unlockerStore"
          :layout-store="layoutStore"
          :documentation-store="documentationStore"
          primary-text-path="fieldContent.name"
          text-classes="leading-5 font-medium"
          dropdown-placement="left-start"
          text-styles=""
          @completed="refetchMap"
        />
        <div v-if="staked" class="mt-1 flex items-center space-x-2">
          <button
            v-if="alreadyInDiagram"
            @click.prevent="switchToDiagramProperty"
            v-tooltip.bottom="'Focus in diagram'"
            type="button"
            class="h-5 w-5 inline-flex justify-center items-center bg-white border border-gray-300 rounded-md text-xs text-gray-500 hover:text-gray-700"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-4 w-4"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M6.672 1.911a1 1 0 10-1.932.518l.259.966a1 1 0 001.932-.518l-.26-.966zM2.429 4.74a1 1 0 10-.517 1.932l.966.259a1 1 0 00.517-1.932l-.966-.26zm8.814-.569a1 1 0 00-1.415-1.414l-.707.707a1 1 0 101.415 1.415l.707-.708zm-7.071 7.072l.707-.707A1 1 0 003.465 9.12l-.708.707a1 1 0 001.415 1.415zm3.2-5.171a1 1 0 00-1.3 1.3l4 10a1 1 0 001.823.075l1.38-2.759 3.018 3.02a1 1 0 001.414-1.415l-3.019-3.02 2.76-1.379a1 1 0 00-.076-1.822l-10-4z"
                clip-rule="evenodd"
              />
            </svg>
          </button>
          <template v-else>
            <button
              v-if="propertyIdParam"
              @click.prevent="addPropertyToDiagram"
              v-tooltip.bottom="'Add to diagram'"
              type="button"
              class="h-5 w-5 inline-flex justify-center items-center bg-white border border-gray-300 rounded-md text-xs text-gray-500 hover:text-gray-700"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-4 w-4"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM14 11a1 1 0 011 1v1h1a1 1 0 110 2h-1v1a1 1 0 11-2 0v-1h-1a1 1 0 110-2h1v-1a1 1 0 011-1z"
                />
              </svg>
            </button>
            <button
              @click.prevent="propertyPage"
              v-tooltip.bottom="
                propertyIdParam ? 'View by itself' : 'View diagram'
              "
              type="button"
              class="h-5 w-5 inline-flex justify-center items-center bg-white border border-gray-300 rounded-md text-xs text-gray-500 hover:text-gray-700"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-4 w-4"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z"
                />
              </svg>
            </button>
          </template>
        </div>
        <div
          v-if="selectedLayer?.title === 'Data Coverage'"
          class="mt-2 flex-auto"
        >
          <div class="flex items-center gap-x-2">
            <h4 class="flex-none text-sm font-semibold leading-6 text-gray-600">
              Data coverage
            </h4>
            <div class="h-px flex-auto bg-gray-100" />
          </div>
          <ul
            role="list"
            class="grid gap-2 text-sm leading-4 text-gray-600 grid-cols-2"
          >
            <li
              v-for="dataType in dataCoverageTypes"
              :key="dataType.name"
              class="mt-2 text-xs flex items-center gap-x-1"
            >
              <CheckIcon
                v-if="dataType.value"
                class="h-5 w-4 flex-none text-green-600"
                aria-hidden="true"
              />
              <XMarkIcon
                v-else
                class="h-5 w-4 flex-none text-red-600"
                aria-hidden="true"
              />
              {{ dataType.name }}
            </li>
          </ul>
        </div>
      </div>
    </template>
  </VMenu>
</template>

<script setup>
import {
  CheckIcon,
  QuestionMarkCircleIcon,
  XMarkIcon,
} from "@heroicons/vue/20/solid";
import { storeToRefs } from "pinia";
import DataField from "@/components/crowdsourcing/DataField.vue";
import { computed, onMounted, ref, watch } from "vue";
import subscribeInterceptor from "@/components/crowdsourcing/subscribeInterceptor";
import { mapMarkers } from "@/assets/documentation/articles/mapMarkers";
import moment from "moment";
import _ from "lodash";

const props = defineProps([
  "mapStore",
  "propertyFieldsStore",
  "modalStore",
  "unlockerStore",
  "userStore",
  "tasksStore",
  "taskListStore",
  "reminderStore",
  "guestProfileStore",
  "propertyDiagramStore",
  "changeGroupStore",
  "layoutStore",
  "secondaryPanelStore",
  "documentationStore",
  "analyzePanelStore",
  "location",
  "locationDataField",
  "playerMinimap",
]);
const { map, minimapSize, propertyMarkerPulseId, horizontalIsAnalyze } =
  storeToRefs(props.mapStore);
const { signedIn } = storeToRefs(props.userStore);
const { discoveredContent } = storeToRefs(props.guestProfileStore);
const {
  propertyDiagramSelectedPropertyId,
  propertyDiagramPropertyIds,
  propertyIdParam,
} = storeToRefs(props.propertyDiagramStore);
const {
  selectedLayer,
  investmentsChecked,
  loansChecked,
  spaceAvailabilitiesChecked,
  spaceUsagesChecked,
  checkedCategories,
  datedInvestmentPropertyIds,
  datedAvailabilityPropertyIds,
  combinedFilteredPropertyIds,
} = storeToRefs(props.analyzePanelStore);

const { propertyTaskPriorities } = storeToRefs(props.tasksStore);
const propertyDataField = computed(() => {
  return props.propertyFieldsStore.getPropertyField(
    props.locationDataField.decoratingContentId,
  );
});

const timelinePulse = ref(false);
const discoveryPulse = ref(false);
const dataCoverageTypes = computed(() => {
  return [
    {
      name: "Investments",
      value: props.location.hasInvestments,
    },
    {
      name: "Milestones",
      value: props.location.hasMilestones,
    },
    {
      name: "Property Uses",
      value: props.location.hasUses,
    },
    {
      name: "Sizes",
      value: props.location.hasSizes,
    },
  ];
});
const fieldState = computed(() => _.get(propertyDataField.value, "state"));
const rejected = computed(
  () => propertyDataField.value && fieldState.value === "rejected",
);
const staked = computed(
  () => propertyDataField.value && fieldState.value !== "unstaked",
);
const airLayerPropertyId = computed(() => {
  return props.locationDataField.fieldContent.airLayerPropertyId;
});
const propertyName = computed(() => {
  return _.get(
    propertyDataField.value,
    "fieldContent.name",
    props.location.location,
  );
});
const taskPriority = computed(() => {
  return _.find(propertyTaskPriorities.value, {
    propertyId: props.locationDataField.decoratingContentId,
  })?.priority;
});
const diagramSelected = computed(() => {
  return (
    propertyDiagramSelectedPropertyId.value ==
      props.locationDataField.decoratingContentId ||
    (propertyDiagramSelectedPropertyId.value &&
      airLayerPropertyId.value &&
      propertyDiagramSelectedPropertyId.value === airLayerPropertyId.value)
  );
});
const alreadyInDiagram = computed(() => {
  return (
    _.includes(
      propertyDiagramPropertyIds.value,
      props.locationDataField.decoratingContentId,
    ) || _.includes(propertyDiagramPropertyIds.value, airLayerPropertyId.value)
  );
});
const excludedInvestment = computed(() => {
  return (
    (investmentsChecked.value || loansChecked.value) &&
    !_.includes(
      datedInvestmentPropertyIds.value,
      props.locationDataField.decoratingContentId,
    )
  );
});
const excludedAvailability = computed(() => {
  return (
    (spaceAvailabilitiesChecked.value || spaceUsagesChecked.value) &&
    !_.includes(
      datedAvailabilityPropertyIds.value,
      props.locationDataField.decoratingContentId,
    )
  );
});
const excludedProperty = computed(() => {
  return (
    checkedCategories.value.length > 0 &&
    !_.includes(
      combinedFilteredPropertyIds.value,
      props.locationDataField.decoratingContentId,
    )
  );
});
const markerPulseColor = computed(() => {
  if (diagramSelected.value) {
    return `#FDBA74`;
  } else {
    return `#4F46E5`;
  }
});
const borderSize = computed(() => {
  if (map.value?.getZoom() > 14) {
    return "border-4";
  } else if (map.value?.getZoom() > 11) {
    return "border-[3px]";
  } else {
    return "border-2";
  }
});
const borderColor = computed(() => {
  switch (taskPriority.value) {
    case "high":
      return "border-red-500";
    case "medium":
      return "border-amber-500";
    case "low":
      return "border-green-500";
    default:
      return "border-gray-300";
  }
});
const dataCoverageColor = computed(() => {
  if (selectedLayer.value?.title === "Data Coverage") {
    const { hasInvestments, hasMilestones, hasUses, hasSizes } = props.location;
    const presentData = [hasInvestments, hasMilestones, hasUses, hasSizes].map(
      (bool) => {
        if (bool) {
          return 1;
        } else {
          return 0;
        }
      },
    );

    switch (_.sum(presentData)) {
      case 4:
        return `bg-blue-500 hover:bg-blue-600 focus:ring-blue-400`;
      case 3:
        return `bg-green-500 hover:bg-green-600 focus:ring-green-400`;
      case 2:
        return `bg-yellow-500 hover:bg-yellow-600 focus:ring-yellow-400`;
      case 1:
        return `bg-orange-500 hover:bg-orange-600 focus:ring-orange-400`;
      default:
        return `bg-red-500 hover:bg-red-600 focus:ring-red-400`;
    }
  } else {
    return null;
  }
});
const markerColor = computed(() => {
  if (diagramSelected.value) {
    return `bg-orange-400 hover:bg-orange-500 focus:ring-orange-300`;
  } else if (alreadyInDiagram.value) {
    return `bg-yellow-300 hover:bg-yellow-400 focus:ring-yellow-200`;
  } else if (
    horizontalIsAnalyze.value &&
    ((excludedInvestment.value && excludedAvailability.value) ||
      excludedProperty.value)
  ) {
    return `bg-gray-400 hover:bg-gray-500 focus:ring-gray-300`;
  } else if (dataCoverageColor.value) {
    return dataCoverageColor.value;
  } else if (staked.value && rejected.value) {
    return `bg-pink-600 hover:bg-pink-700 focus:ring-pink-500`;
  } else if (staked.value) {
    return `bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500`;
  } else if (propertyDataField.value) {
    return `bg-pink-600 hover:bg-pink-700 focus:ring-pink-500`;
  } else {
    return `bg-gray-800 hover:bg-gray-900 focus:ring-gray-700`;
  }
});
const canDisplayMenu = computed(() => {
  return !propertyIdParam.value || minimapSize.value === "compact";
});
const markerSize = computed(() => {
  if (map.value?.getZoom() > 15) {
    return "h-5 w-5";
  } else if (map.value?.getZoom() > 14) {
    return "h-4 w-4";
  } else if (map.value?.getZoom() > 12 || props.playerMinimap) {
    return "h-3 w-3";
  } else if (map.value?.getZoom() > 11) {
    return "h-2.5 w-2.5";
  } else {
    return "h-2 w-2";
  }
});
const pulseMarkerSize = computed(() => {
  if (map.value?.getZoom() > 15) {
    return "h-4 w-4";
  } else if (map.value?.getZoom() > 14) {
    return "h-3 w-3";
  } else if (map.value?.getZoom() > 12 || props.playerMinimap) {
    return "h-2 w-2";
  } else if (map.value?.getZoom() > 11) {
    return "h-1.5 w-1.5";
  } else {
    return "h-1 w-1";
  }
});

watch(propertyMarkerPulseId, (val, oldVal) => {
  if (
    !propertyIdParam.value &&
    val == props.locationDataField.decoratingContentId
  ) {
    timelinePulse.value = true;

    setTimeout(() => {
      timelinePulse.value = null;
    }, 1600);
  }
});

onMounted(() => {
  let modalStoreTemp = props.modalStore;
  let unlockerStoreTemp = props.unlockerStore;
  let userStoreTemp = props.userStore;
  let tasksStoreTemp = props.tasksStore;
  let taskListStoreTemp = props.taskListStore;
  let reminderStoreTemp = props.reminderStore;
  let guestProfileStoreTemp = props.guestProfileStore;
  let propertyDiagramStoreTemp = props.propertyDiagramStore;
  let changeGroupStoreTemp = props.changeGroupStore;
  let analyzePanelStoreTemp = props.analyzePanelStore;

  pulseDiscovery();
});

const { modalPayload } = storeToRefs(props.modalStore);
const { upgradeSuccessful } = storeToRefs(props.unlockerStore);

function getHelp() {
  props.documentationStore.viewArticle(mapMarkers, "Property markers");
}

function refetchMap() {
  props.mapStore.tapView();
}
function promptToSubscribe() {
  subscribeInterceptor({
    modalPayloadRef: modalPayload,
    upgradeSuccessfulRef: upgradeSuccessful,
    context: "originating",
  });
}
function markerSingleClick() {
  if (staked.value) {
    if (propertyIdParam.value) {
      if (alreadyInDiagram.value) {
        switchToDiagramProperty();
      } else {
        propertyPage();
      }
    } else {
      propertyPage();
    }
  } else if (propertyDataField.value) {
    promptToSubscribe();
  }
}
function markerCtrlClick() {
  if (staked.value && !canDisplayMenu.value) {
    addPropertyToDiagram();
  }
}
function propertyPage() {
  props.secondaryPanelStore.closeValidations();
  setTimeout(() => {
    props.propertyDiagramStore.navigateToDiagram(
      props.locationDataField.decoratingContentId,
      true,
    );
  }, 100);
}
function switchToDiagramProperty() {
  if (propertyIdParam.value) {
    propertyDiagramSelectedPropertyId.value =
      props.locationDataField.decoratingContentId;
  }
}
function addPropertyToDiagram() {
  if (propertyIdParam.value) {
    props.propertyDiagramStore.addPropertyToDiagram(
      props.locationDataField.decoratingContentId,
    );
    props.propertyDiagramStore.clearSelect(
      props.locationDataField.decoratingContentId,
    );
  }
}
const debounceMicroMapFetch = _.debounce(microMapFetch, 1000, {
  leading: true,
});
function microMapFetch() {
  if (!canDisplayMenu.value && !propertyDataField.value) {
    fetchPropertyDataField();
  }
}
async function fetchPropertyDataField() {
  await props.propertyFieldsStore.fetchPropertyDataField(
    props.locationDataField.decoratingContentId,
  );

  pulseDiscovery();
}

function pulseDiscovery() {
  const pulse = () => {
    discoveryPulse.value = true;

    setTimeout(() => {
      discoveryPulse.value = null;
    }, 3100);
  };
  const discoveredAt = _.get(
    props.locationDataField,
    "fieldContent.discovered",
    null,
  );

  if (!signedIn.value) {
    const discoveryId = `${props.locationDataField.decoratingContentType}${props.locationDataField.decoratingContentId}`;

    if (_.includes(discoveredContent.value, discoveryId)) {
      return;
    } else if (map.value?.getZoom() >= 16.5) {
      pulse();
      discoveredContent.value = _.union([discoveryId], discoveredContent.value);
    }
  } else if (discoveredAt) {
    if (moment(discoveredAt).isAfter(moment().subtract(7, "seconds"))) {
      pulse();
    } else {
      return;
    }
  } else if (map.value?.getZoom() >= 16.5) {
    pulse();
  }
}
</script>

<style scoped>
.timeline-pulse {
  animation: timeline-pulse 1.5s;
  box-shadow: 0 0 0 8em transparent;
}
@keyframes timeline-pulse {
  0% {
    box-shadow: 0 0 0 0 #f59e0b;
  }
}
.discovery-pulse {
  animation: discovery-pulse 3s;
  box-shadow: 0 0 0 8em transparent;
}
@keyframes discovery-pulse {
  0% {
    box-shadow: 0 0 0 0 #ec4899;
  }
}
</style>
