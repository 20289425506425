<template>
  <div :id="`location-map-${mapId}`" />
</template>

<script setup>
/* global L */
import locationMarker from "@/components/maps/locationMarker";
import mapStyles from "@/components/maps/mapStyles";
import { watch, onMounted, ref } from "vue";
import { storeToRefs } from "pinia";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { useMainMapStore } from "@/stores/mainMap";
import { usePropertyFieldsStore } from "@/stores/propertyFields";
import { useModalStore } from "@/stores/modal";
import { useUnlockerStore } from "@/stores/unlocker";
import { useTasksStore } from "@/stores/tasks";
import { useTaskListStore } from "@/stores/taskList";
import { useReminderStore } from "@/stores/reminder";
import { useGuestProfileStore } from "@/stores/guestProfile";
import { useTimeTravelStore } from "@/stores/timeTravel";
import { useCrowdsourcedChangeGroupStore } from "@/stores/crowdsourcedChangeGroup";
import { usePropertyDiagramStore } from "@/stores/propertyDiagram";
import { useDocumentationStore } from "@/stores/documentation";
import { useAvailableValidationsChannelStore } from "@/stores/availableValidationsChannel";
import { fetchNearbyValidationProperties } from "@/components/maps/fetchBoundingBoxProperties";
import _ from "lodash";

const props = defineProps(["mapId", "lat", "lng", "editable", "nearby"]);
const emit = defineEmits(["set-lat-lng"]);
const refreshing = ref(false);
const map = ref(null);
const marker = ref(null);
const propertyFeatureGroup = ref(L.featureGroup());
const landCoveringFeatureGroup = ref(L.featureGroup());
const baseLayers = ref({
  Base: L.gridLayer.googleMutant({
    type: "roadmap",
    styles: mapStyles.styles,
  }),
  Satellite: L.gridLayer.googleMutant({
    type: "satellite",
    styles: [],
  }),
  Hybrid: L.gridLayer.googleMutant({
    type: "hybrid",
    styles: [],
  }),
  Transit: L.gridLayer.googleMutant({
    type: "roadmap",
    styles: [],
  }),
});
const mapStore = useMainMapStore();
const layoutStore = useWorkspaceLayoutStore();
const { isDesktop, workspaceLayout, workspaceResized } =
  storeToRefs(layoutStore);
const timeTravelStore = useTimeTravelStore();
const { asOfMilliseconds } = storeToRefs(timeTravelStore);
const modalStore = useModalStore();
const unlockerStore = useUnlockerStore();
const documentationStore = useDocumentationStore();
const tasksStore = useTasksStore();
const taskListStore = useTaskListStore();
const reminderStore = useReminderStore();
const guestProfileStore = useGuestProfileStore();
const propertyDiagramStore = usePropertyDiagramStore();
const propertyFieldsStore = usePropertyFieldsStore();
const crowdsourcedChangeGroupStore = useCrowdsourcedChangeGroupStore();
const availableValidationsChannelStore = useAvailableValidationsChannelStore();
const { activeValidationChangeGroupId } = storeToRefs(
  availableValidationsChannelStore,
);
const zoom = 16.5;
const center = [props.lat, props.lng];

watch(workspaceLayout, () => {
  if (map.value) {
    setTimeout(function () {
      map.value.invalidateSize();
    }, 100);
  }
});

watch(workspaceResized, () => {
  if (map.value && workspaceResized.value) {
    setTimeout(function () {
      map.value.invalidateSize();
    }, 100);
  }
});

onMounted(() => {
  map.value = L.map(`location-map-${props.mapId}`, {
    fullscreenControl: true,
    scrollWheelZoom: false,
    zoomAnimation: false,
    fadeAnimation: true,
    zoomSnap: 0.25,
    zoomDelta: 0.25,
    keyboardPanDelta: 40,
    markerZoomAnimation: true,
  }).setView(center, zoom);

  baseLayers.value["Transit"].addGoogleLayer("TransitLayer");
  baseLayers.value["Satellite"].addTo(map.value);

  map.value.on("fullscreenchange", function () {
    if (map.value.isFullscreen()) {
      console.log("enable scroll zoom");
      map.value.scrollWheelZoom.enable();
    } else {
      console.log("disable scroll zoom");
      map.value.scrollWheelZoom.disable();
      map.value.setView(center, zoom);
    }
  });

  map.value.on("moveend", function () {
    if (!refreshing.value) {
      debouncedRefreshAllLayers();
    }
  });

  propertyFeatureGroup.value.addTo(map.value);
  landCoveringFeatureGroup.value.addTo(map.value);

  L.control
    .layers(
      baseLayers.value,
      {},
      {
        collapsed: true,
      },
    )
    .addTo(map.value);

  addMarker({ editable: props.editable });

  if (!refreshing.value) {
    debouncedRefreshAllLayers();
  }
});

const debouncedRefreshAllLayers = _.debounce(function () {
  refreshAllLayers();
}, 2000);

async function refreshAllLayers() {
  if (props.nearby) {
    refreshing.value = true;
    clearMapLayers();
    await fetchNearby();
    refreshing.value = false;
  }
}

async function fetchNearby() {
  return new Promise((resolve) => {
    if (isDesktop.value && props.nearby) {
      fetchNearbyValidationProperties({
        nearbyType: props.nearby,
        map: map.value,
        modalStore,
        propertyFieldsStore,
        unlockerStore,
        tasksStore,
        taskListStore,
        reminderStore,
        guestProfileStore,
        propertyDiagramStore,
        documentationStore,
        changeGroupStore: crowdsourcedChangeGroupStore,
        layoutStore,
        propertyFeatureGroup: propertyFeatureGroup.value,
        landCoveringFeatureGroup: landCoveringFeatureGroup.value,
        asOfMilliseconds: asOfMilliseconds.value,
        activeChangeGroupId: activeValidationChangeGroupId.value,
      }).then(() => {
        resolve();
      });
    } else {
      resolve();
    }
  });
}

function clearMapLayers() {
  [propertyFeatureGroup.value, landCoveringFeatureGroup.value].forEach(
    (group) => group.clearLayers(),
  );
}

function addMarker({ editable = false }) {
  const markerIcon = locationMarker({
    classes: "h-5 w-5 bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500",
    interactive: true,
    mapStore,
    propertyFieldsStore,
    tooltip: props.nearby ? `Validation ${_.startCase(props.nearby)}` : "",
  });
  marker.value = L.marker(center, {
    draggable: editable,
    icon: markerIcon,
    zIndexOffset: 1000,
  }).addTo(map.value);

  if (editable) {
    marker.value.bindTooltip("Drag me!").openTooltip();

    marker.value.on("pm:edit", () => {
      const currentCoordinates = marker.value.getLatLng();

      emit("set-lat-lng", {
        newLat: currentCoordinates.lat,
        newLng: currentCoordinates.lng,
      });
    });
  }
}
</script>
