import { ref } from "vue";
import { defineStore, acceptHMRUpdate } from "pinia";
import _ from "lodash";

export const useCollectibleCardsChannelStore = defineStore(
  "collectibleCardsChannel",
  () => {
    const CollectibleCardsChannel = ref(null);
    const collectibleCardsChannelDataQueue = ref([]);
    const pulseAvailableCards = ref(false);
    const pulseIntervalId = ref(null);

    function pushAndTrim(data) {
      collectibleCardsChannelDataQueue.value.push(data);
      collectibleCardsChannelDataQueue.value = _.takeRight(
        collectibleCardsChannelDataQueue.value,
        3
      );
    }

    function resetPulse() {
      clearPulse();
      beginPulse();
    }

    function clearPulse() {
      pulseAvailableCards.value = false;
      if (pulseIntervalId.value) {
        clearInterval(pulseIntervalId.value);
        pulseIntervalId.value = null;
      }
    }

    function beginPulse() {
      if (!pulseIntervalId.value) {
        pulseIntervalId.value = setInterval(() => {
          logoCardPulse();
        }, 2100);
      }
    }

    function logoCardPulse() {
      pulseAvailableCards.value = true;

      setTimeout(() => {
        pulseAvailableCards.value = false;
      }, 1600);
    }

    return {
      CollectibleCardsChannel,
      collectibleCardsChannelDataQueue,
      pulseAvailableCards,
      pushAndTrim,
      clearPulse,
      beginPulse,
      resetPulse,
    };
  }
);

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useCollectibleCardsChannelStore, import.meta.hot)
  );
}
