<template>
  <tr v-if="record">
    <td class="pl-4 whitespace-nowrap py-2 pr-3">{{ alias }}</td>
    <td class="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
      {{ record.dataFieldId || record.id }}
    </td>
    <td class="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
      {{ moment(record.createdAt).format("MMM D YYYY, h:mm a") }}
    </td>
    <td class="whitespace-nowrap px-3 py-2 text-sm space-y-1">
      {{ status }}
    </td>
  </tr>
</template>

<script setup>
import moment from "moment";
import _ from "lodash";
import { computed } from "vue";

const props = defineProps(["record"]);

const alias = computed(() => {
  switch (props.record.recordType) {
    case "Validation":
      return "Validation";
    case "Contribution":
      return "Contribution";
    default:
      return props.record.recordType;
  }
});
const status = computed(() => {
  switch (props.record.recordType) {
    case "Validation":
      return props.record.result
        ? _.startCase(props.record.result)
        : _.startCase(props.record.validationInput);
    case "Contribution":
      if (props.record.platformStatus === "staked") {
        return "Publishable";
      } else if (props.record.validationOutcome) {
        return _.startCase(props.record.validationOutcome);
      } else {
        return "Pending";
      }
    default:
      return "Pending";
  }
});
</script>
