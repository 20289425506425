<template>
  <div v-if="expanded || expandedOverride" class="flex flex-1">
    <!-- Narrow sidebar-->
    <nav
      aria-label="Sidebar"
      class="flex-shrink-0 p-2 bg-gray-100 overflow-y-auto"
    >
      <div class="relative flex flex-col space-y-2">
        <a
          v-for="tab in contextedTabs"
          :key="tab.name"
          @click.prevent="selectedTab = tab"
          :href="tab.href"
          v-tooltip="tab.name"
          :class="[
            selectedTab.name === tab.name
              ? 'text-gray-700 bg-gray-300'
              : 'text-gray-500 hover:bg-gray-200',
            'flex-shrink-0 inline-flex items-center justify-center h-8 w-8 rounded-lg',
          ]"
          class=""
          :data-test="`${_.kebabCase(tab.name)}-nav`"
        >
          <span class="sr-only">{{ tab.name }}</span>
          <svg
            :class="selectedTab.name === tab.name ? '' : ''"
            class="h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1"
              :d="tab.svgPath"
            />
          </svg>
        </a>
      </div>
    </nav>

    <div
      class="flex-grow overflow-y-auto"
      :class="fromMenu ? 'max-h-[350px]' : ''"
    >
      <component
        :is="selectedTab.component"
        v-bind="selectedTabProperties"
        @open-portfolio="openPortfolio"
      />
    </div>
  </div>
</template>

<script setup>
import ContentFiles from "@/components/crowdsourcing/ContentFiles.vue";
import AvailabilityTiming from "@/components/space-usage-builder/AvailabilityTiming.vue";
import AvailabilityPlayers from "@/components/space-usage-builder/AvailabilityPlayers.vue";
import AvailabilityGroupTab from "@/components/space-usage-builder/AvailabilityGroupTab.vue";
import SpaceUsageDetail from "@/components/space-usage-builder/SpaceUsageDetail.vue";
import { useSpaceUsageBuilderStore } from "@/stores/spaceUsageBuilder";
import { storeToRefs } from "pinia";
import { ref, computed, nextTick, markRaw, watch, onMounted } from "vue";
import decoratingAndFieldKey from "@/components/crowdsourcing/decoratingAndFieldKey";
import investmentStates from "@/components/deal-builder/investmentStates";
import VueScrollTo from "vue-scrollto";
import _ from "lodash";
import moment from "moment";

const props = defineProps([
  "spaceObject",
  "availability",
  "availabilityGroupId",
  "expandedOverride",
  "fromMenu",
]);

const spaceUsageBuilderStore = useSpaceUsageBuilderStore();
const { availabilityGroups, crossInteraction } = storeToRefs(
  spaceUsageBuilderStore,
);

const tabs = ref([
  {
    name: "Lease/License",
    href: "",
    svgPath:
      "M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z",
    component: markRaw(SpaceUsageDetail),
  },
  {
    name: "Players",
    href: "",
    svgPath:
      "M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z",
    component: markRaw(AvailabilityPlayers),
  },
  {
    name: "Timing",
    href: "",
    svgPath: "M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z",
    component: markRaw(AvailabilityTiming),
  },
  {
    name: "Files",
    href: "",
    svgPath:
      "M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z",
    component: markRaw(ContentFiles),
  },
  {
    name: "Group",
    href: "",
    svgPath:
      "M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z",
    component: markRaw(AvailabilityGroupTab),
  },
]);
const selectedTab = ref({
  name: "Players",
  href: "",
  svgPath:
    "M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z",
  component: markRaw(AvailabilityPlayers),
});
const groupFilteredTabs = computed(() => {
  if (props.availabilityGroupId) {
    return tabs.value.filter((tab) => {
      return tab.name !== "Group";
    });
  } else {
    return tabs.value;
  }
});
const contextedTabs = computed(() => {
  if (existingAvailabilityId.value) {
    return groupFilteredTabs.value;
  } else {
    return groupFilteredTabs.value.filter((tab) => {
      return _.includes(["Players", "Timing"], tab.name);
    });
  }
});
const selectedTabProperties = computed(() => {
  return {
    availability: focalAvailability.value,
    spaceObject: props.spaceObject,
    fieldContent: focalAvailability.value,
    fieldContentType: "SpaceAvailability",
    dataField: {
      fieldContent: focalAvailability.value,
      fieldContentType: "SpaceAvailability",
    },
    hasPortfolio: existingPortfolio.value,
    optional: true,
  };
});

const expanded = ref(false);

const dataFieldAvailabilityFieldContent = computed(() => {
  return _.get(props.availability, "fieldContent");
});
const focalAvailability = computed(() => {
  const spaceUsageBuilderExistingAvailability = _.get(
    props.availability,
    "existingAvailability",
  );

  return (
    dataFieldAvailabilityFieldContent.value ||
    spaceUsageBuilderExistingAvailability ||
    props.availability
  );
});
const availabilityId = computed(() => {
  return _.get(focalAvailability.value, "id", builderCombinedKey.value);
});
const builderCombinedKey = computed(() => {
  if (
    focalAvailability.value.topLevelSpace &&
    focalAvailability.value.availabilityFieldContent
  ) {
    const spaceKey = decoratingAndFieldKey(
      focalAvailability.value.topLevelSpace,
    );
    const availabilityKey = decoratingAndFieldKey(
      focalAvailability.value.availabilityFieldContent,
    );

    return `${spaceKey}_${availabilityKey}`;
  } else {
    return _.get(props.availability, "temporaryId");
  }
});
const existingAvailabilityId = computed(() => {
  return (
    _.get(focalAvailability.value, "id") ||
    _.get(focalAvailability.value, "fieldContentId")
  );
});

const dateLabel = computed(() => {
  switch (focalAvailability.value.state) {
    case "withdrawn":
    case "live":
    case "under_contract":
      return _.find(investmentStates, {
        value: focalAvailability.value.state,
      }).statusLabel;
    case "closed":
    default: {
      if (focalAvailability.value.date) {
        return moment(focalAvailability.value.date).format("MMM YYYY");
      } else {
        return "Undated";
      }
    }
  }
});

const portfolioId = computed(() => {
  return (
    _.get(focalAvailability.value, "portfolioId") ||
    _.get(matchingAvailabilityGroup.value, "placeholderId")
  );
});
const existingPortfolio = computed(
  () => !props.availabilityGroupId && portfolioId.value,
);
const matchingAvailabilityGroup = computed(() => {
  return _.find(availabilityGroups.value, function (group) {
    return _.find(group.availabilities, function (availability) {
      const localAvailabilityId =
        _.get(props.availability, "existingAvailability.id") ||
        _.get(props.availability, "id") ||
        _.get(props.availability, "temporaryId");

      return (
        group.id === portfolioId.value ||
        _.get(availability, "existingAvailability.id") === localAvailabilityId
      );
    });
  });
});

watch(crossInteraction, () => {
  expandWhenInteracting();
});
watch(dateLabel, () => {
  expandWhenInteracting();
});
watch(availabilityId, (id, oldId) => {
  if (id !== oldId) {
    expandWhenInteracting();
  }
});

onMounted(() => {
  expandWhenInteracting();
});

function expandWhenInteracting() {
  if (availabilityId.value && crossInteraction.value) {
    const { combinedKey, focus, source } = crossInteraction.value;
    const shouldActivate = _.includes(
      ["TimelineAvailability", "InvolvedCompanyMenu", "ContentStateAlertCard"],
      source,
    );

    if (shouldActivate && combinedKey === availabilityId.value) {
      console.log("expand when interacting", availabilityId.value);
      expanded.value = true;
      const newTab = _.find(contextedTabs.value, { name: focus });

      if (newTab) {
        selectedTab.value = newTab;

        if (
          !_.includes(
            [
              "TimelineAvailability",
              "InvolvedCompanyMenu",
              "ContentStateAlertCard",
            ],
            source,
          )
        ) {
          setTimeout(() => {
            crossInteraction.value = null;
          }, 750);
        }
      }
    } else {
      expanded.value = false;
    }
  } else if (props.fromMenu) {
    expanded.value = true;
  }
}

function focusAvailabilityGroup(availabilityGroup) {
  spaceUsageBuilderStore.collapsePortfolioSpaces({
    groupId: availabilityGroup.id || availabilityGroup.placeholderId,
  });
  spaceUsageBuilderStore.setAvailabilityGroupExpanded({
    groupId: availabilityGroup.id || availabilityGroup.placeholderId,
    expanded: true,
  });
}
async function openPortfolio() {
  if (matchingAvailabilityGroup.value) {
    focusAvailabilityGroup(matchingAvailabilityGroup.value);
  } else {
    spaceUsageBuilderStore.addAvailabilityGroup({
      existingGroupId: portfolioId.value,
    });
  }

  // spaceUsageBuilderStore.collapseAllSpacesExcept({ spaceKey: null });
  await nextTick();
  VueScrollTo.scrollTo("#availability-groups-list", 100, {
    container: "#lists-panel-container",
  });
}
</script>
