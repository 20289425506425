<template>
  <div class="space-y-1">
    <div class="flex items-center space-x-2">
      <button
        @click="expanded = !expanded"
        type="button"
        v-tooltip="expanded ? 'Hide rooms' : 'View rooms'"
        class="h-5 w-5 inline-flex justify-center items-center text-sm text-gray-700 hover:text-gray-900"
      >
        <ChevronDownIcon class="h-5 w-5" />
      </button>
      <h2 class="text-sm font-medium text-gray-500">
        {{ pluralize(roomType, roomFields.length, true) }}
      </h2>
    </div>
    <div v-if="expanded" class="ml-6 flex flex-col space-y-0.5">
      <div
        v-for="roomDataField in roomFields"
        :key="roomDataField.localId"
        class="flex items-center justify-between"
      >
        <DataField
          :data-field="roomDataField"
          self-label-output-format="partitionGroup"
          text-classes="text-sm"
          @completed="emit('refetch')"
        />
        <button
          v-if="!alreadyInDiagram(roomDataField)"
          @click="addSpaceToBuilder(roomDataField)"
          type="button"
          v-tooltip="'Open space in Space Hub'"
          class="h-5 w-5 inline-flex justify-center items-center text-sm text-gray-700 hover:text-gray-800"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM14 11a1 1 0 011 1v1h1a1 1 0 110 2h-1v1a1 1 0 11-2 0v-1h-1a1 1 0 110-2h1v-1a1 1 0 011-1z"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ChevronDownIcon } from "@heroicons/vue/20/solid";
import { useSpaceUsageBuilderStore } from "@/stores/spaceUsageBuilder";
import { usePropertyDiagramStore } from "@/stores/propertyDiagram";
import { ref } from "vue";
import { storeToRefs } from "pinia";
import DataField from "@/components/crowdsourcing/DataField.vue";
import decoratingAndFieldKey from "@/components/crowdsourcing/decoratingAndFieldKey";
import pluralize from "pluralize";
import _ from "lodash";

defineProps(["roomType", "roomFields"]);
const emit = defineEmits(["refetch"]);

const spaceUsageBuilderStore = useSpaceUsageBuilderStore();
const { spaces } = storeToRefs(spaceUsageBuilderStore);
const propertyDiagramStore = usePropertyDiagramStore();
const { propertyDiagramPropertyIds } = storeToRefs(propertyDiagramStore);

const expanded = ref(false);

function alreadyInDiagram(roomDataField) {
  return _.find(spaces.value, function (space) {
    const key = `${space.dataField.fieldContentType}${space.dataField.fieldContentId}`;
    const fieldKey = `${roomDataField.fieldContentType}${roomDataField.fieldContentId}`;
    return key === fieldKey;
  });
}

function addSpaceToBuilder(roomDataField) {
  spaceUsageBuilderStore.upsertSpaceUsageBuilder({
    spaceDataField: roomDataField,
  });
}
</script>
