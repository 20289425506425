<template>
  <main
    v-if="tabs && selectedTab"
    class="h-full w-full flex flex-col overflow-hidden"
  >
    <div
      v-if="routeName !== 'MainMap'"
      class="p-2 flex items-center space-x-2 bg-indigo-600 text-white"
    >
      <template v-if="unselectedPropertyDiagram">
        <DataField
          v-for="dataField in propertyDiagramPropertyDataFields"
          :key="dataField.localId"
          :data-field="dataField"
          text-classes="font-semibold"
          text-styles=""
        />
      </template>
      <template v-else>
        <DataField
          :data-field="focalDataField || propertySelectedRecordDataField"
          primary-text-path="fieldContent.name"
          text-classes="font-semibold"
          text-styles=""
        />
        <template v-if="displaysPropertyDataField">
          <span>at</span>
          <DataField
            :data-field="propertyDataField"
            primary-text-path="fieldContent.name"
            text-classes="font-semibold"
            text-styles=""
          />
        </template>
        <template v-else-if="displaysCompanyDataField">
          <span>held by</span>
          <DataField
            :data-field="companyDataField"
            primary-text-path="fieldContent.name"
            text-classes="font-semibold"
            text-styles=""
          />
        </template>
        <template v-else-if="displaysContactDataField">
          <span>worked by</span>
          <DataField
            :data-field="contactDataField"
            primary-text-path="fieldContent.name"
            text-classes="font-semibold"
            text-styles=""
          />
        </template>
      </template>
    </div>
    <!-- Narrow sidebar-->
    <div class="flex flex-grow flex-1 overflow-hidden">
      <nav aria-label="Sidebar" class="h-full flex-shrink-0 p-2 bg-gray-100">
        <div class="relative flex flex-col space-y-2">
          <a
            v-for="tab in tabs"
            :key="tab.name"
            @click.prevent="selectTab(tab)"
            :href="tab.href"
            v-tooltip="tab.name"
            :class="[
              selectedTab.name === tab.name
                ? 'text-gray-700 bg-gray-300'
                : 'text-gray-500 hover:bg-gray-200',
              'flex-shrink-0 inline-flex items-center justify-center h-8 w-8 rounded-lg',
            ]"
            :data-test="`${_.kebabCase(tab.tabQuery)}-nav`"
          >
            <span class="sr-only">{{ tab.name }}</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              :class="selectedTab.name === tab.name ? '' : ''"
              class="h-5 w-5"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 24 24"
              v-html="pathsForIcon(tab.svgPaths)"
            ></svg>
          </a>
        </div>
      </nav>

      <div class="flex-grow overflow-hidden">
        <component :is="selectedTab.component" v-bind="selectedTabProperties" />
      </div>
    </div>
  </main>
</template>

<script setup>
import DataField from "@/components/crowdsourcing/DataField.vue";
import {
  initialSelectedTabFor,
  tabPropertiesFor,
  tabsFor,
} from "@/components/main-layout/secondary-panel/analyzeTabs";
import { useRoute, useRouter } from "vue-router";
import { computed, ref, onMounted, watch, nextTick } from "vue";
import { usePropertyDiagramStore } from "@/stores/propertyDiagram";
import { useCompanyDetailStore } from "@/stores/companyDetail";
import { useContactDetailStore } from "@/stores/contactDetail";
import { useAnalyzePanelStore } from "@/stores/analyzePanel";
import { useSecondaryPanelStore } from "@/stores/secondaryPanel";
import { storeToRefs } from "pinia";
import pathsForIcon from "@/assets/pathsForIcon";
import _ from "lodash";

const propertyDiagramStore = usePropertyDiagramStore();
const {
  propertyIdParam,
  propertyDataField,
  propertySelectedRecordDataField,
  propertyDiagramPropertyDataFields,
} = storeToRefs(propertyDiagramStore);
const companyDetailStore = useCompanyDetailStore();
const { companyIdParam, companySelectedRecordDataField, companyDataField } =
  storeToRefs(companyDetailStore);
const contactDetailStore = useContactDetailStore();
const { contactIdParam, contactSelectedRecordDataField, contactDataField } =
  storeToRefs(contactDetailStore);
const analyzePanelStore = useAnalyzePanelStore();
const { focalDataField, unselectedPropertyDiagram } =
  storeToRefs(analyzePanelStore);
const secondaryPanelStore = useSecondaryPanelStore();

const router = useRouter();
const route = useRoute();
const routeName = computed(() => route.name);
const query = computed(() => route.query);
const verticalTab = computed(() => _.get(query.value, "verticalTab"));
const hasVerticalTab = computed(() => !!verticalTab.value);

const tabs = ref(tabsFor(routeName.value));
const refreshingTabs = ref(false);
const selectedTab = ref(null);
const selectedTabProperties = computed(() => tabPropertiesFor(routeName.value));
const horizontalIsAnalyze = computed(
  () => _.get(query.value, "horizontalTab") === "Analyze",
);
const displaysPropertyDataField = computed(() => {
  return (
    propertyIdParam.value &&
    propertySelectedRecordDataField.value?.localId !==
      propertyDataField.value?.localId
  );
});
const displaysCompanyDataField = computed(() => {
  return (
    companyIdParam.value &&
    companySelectedRecordDataField.value?.localId !==
      focalDataField.value?.localId
  );
});
const displaysContactDataField = computed(() => {
  return (
    contactIdParam.value &&
    contactSelectedRecordDataField.value?.localId !==
      focalDataField.value?.localId
  );
});

onMounted(() => {
  if (routeName.value === "MainMap")
    secondaryPanelStore.setSecondaryPanelWidth("auto 10px 800px");
  refreshTabs();
});

watch(routeName, () => {
  if (routeName.value === "MainMap")
    secondaryPanelStore.setSecondaryPanelWidth("auto 10px 800px");
  refreshTabs();
});
watch(horizontalIsAnalyze, () => {
  if (horizontalIsAnalyze.value) refreshTabs();
});
watch(verticalTab, () => {
  if (hasVerticalTab.value && !refreshingTabs.value) refreshTabs();
});

async function refreshTabs() {
  refreshingTabs.value = true;
  tabs.value = tabsFor(routeName.value);
  selectTab().then(async () => {
    await nextTick();
    refreshingTabs.value = false;
  });
}

async function selectTab(tab) {
  const oldTabQuery = _.get(selectedTab.value, "tabQuery");
  const hasVerticalTab = !!_.get(query.value, "verticalTab");

  return new Promise((resolve) => {
    if (tab) selectedTab.value = tab;
    else if (horizontalIsAnalyze.value) {
      if (hasVerticalTab) {
        selectedTab.value =
          _.find(tabs.value, {
            tabQuery: query.value.verticalTab,
          }) || initialSelectedTabFor(routeName.value);
      } else {
        selectedTab.value = initialSelectedTabFor(routeName.value);
      }
    }

    const newTabQuery = _.get(selectedTab.value, "tabQuery");

    if (selectedTab.value && newTabQuery !== oldTabQuery) {
      router.push({
        name: route.name,
        query: {
          ...route.query,
          verticalTab: selectedTab.value.tabQuery,
        },
      });
    }

    resolve();
  });
}
</script>
