<template>
  <div
    v-if="!signedIn"
    :class="isDesktop ? 'h-full p-4' : 'min-h-screen py-12 px-6'"
    class="flex items-center justify-center"
  >
    <div :class="isDesktop ? 'max-w-sm' : 'max-w-md'" class="w-full">
      <div>
        <router-link to="/">
          <img
            :class="isDesktop ? 'h-12' : 'h-16'"
            class="mx-auto w-auto"
            src="https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png"
            alt="Tower Hunt"
          />
        </router-link>
        <h2
          :class="isDesktop ? 'text-xl leading-6' : 'text-3xl leading-9'"
          class="mt-6 text-center font-extrabold text-gray-900"
        >
          Reset your password
        </h2>
      </div>
      <form
        class="mt-8"
        @submit.prevent="resetPassword"
        data-test="forgot-password-form"
      >
        <input type="hidden" name="remember" value="true" />
        <div class="rounded-md shadow-sm">
          <div>
            <input
              v-focus
              v-model="session.email"
              aria-label="Email address"
              name="email"
              type="email"
              required
              class="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm sm:leading-5"
              placeholder="Email address"
              data-test="forgot-password-email"
            />
          </div>
        </div>

        <div class="mt-6">
          <button
            type="submit"
            class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:ring-indigo active:bg-indigo-700"
          >
            <span class="absolute left-0 inset-y pl-3">
              <svg
                class="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  fill-rule="evenodd"
                  d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </span>
            Reset password
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup>
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { useSecondaryPanelStore } from "@/stores/secondaryPanel";
import { useNotificationsStore } from "@/stores/notifications";
import { useUserStore } from "@/stores/user";
import { ref, watch, onMounted } from "vue";
import { storeToRefs } from "pinia";
import { useRouter, useRoute } from "vue-router";
import api from "@/router/api";

const layoutStore = useWorkspaceLayoutStore();
const { isDesktop } = storeToRefs(layoutStore);
const userStore = useUserStore();
const { signedIn } = storeToRefs(userStore);
const secondaryPanelStore = useSecondaryPanelStore();
const notificationsStore = useNotificationsStore();

const session = ref({
  email: "",
});

const router = useRouter();
const route = useRoute();

watch(isDesktop, () => {
  if (isDesktop.value) {
    router.push({
      name: route.name,
      query: {
        ...route.query,
        horizontalTab: "Authenticate",
        verticalTab: "ForgotPassword",
      },
    });
    secondaryPanelStore.setAuthenticate({ name: "ForgotPassword" });
  } else {
    router.push({ name: "ForgotPassword" });
  }
});

onMounted(() => {
  if (signedIn.value) {
    if (isDesktop.value && route.name !== "ForgotPassword") {
      secondaryPanelStore.setAuthenticate();
      secondaryPanelStore.revertPanel();
    } else {
      router.push({ name: "MainMap" });
    }
  }
});

async function resetPassword() {
  api.post("password_resets", session.value).then(
    () => {
      notificationsStore.addNotification("passwordResetSubmitted");

      if (isDesktop.value) {
        secondaryPanelStore.setAuthenticate();
        secondaryPanelStore.revertPanel();
      } else {
        router.push({ name: "MainMap" });
      }
    },
    (failure) => {
      notificationsStore.addNotification("anErrorOccurred");
      secondaryPanelStore.setAuthenticate();
      secondaryPanelStore.revertPanel();
      console.log(failure);
    }
  );
}
</script>
