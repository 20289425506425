<template>
  <div
    v-if="
      displayVisual &&
      (visualField || displayableInvestments || displayablePortfolioInvestments)
    "
    class="mt-2"
  >
    <h2 class="text-gray-700 font-semibold text-sm mt-2">
      Visual / Deduplication Support
    </h2>
    <div class="w-full flex flex-col">
      <div
        v-if="fieldName === 'CitationChangeGroup'"
        class="w-full flex items-center divide-x-2 divide-white mb-2"
      >
        <template v-if="sideBySide">
          <div class="h-36 w-1/2 bg-gray-100" />
        </template>

        <CitationDetails
          :citation-field="dataField"
          :embedded-diff="true"
          :removable="false"
          :citation-scope="'validation'"
          :class="sideBySide ? 'w-1/2' : 'w-full'"
          class="h-36 self-start"
        />
      </div>
      <div
        v-if="fileField"
        class="w-full flex items-center divide-x-2 divide-white mb-2"
      >
        <template v-if="sideBySide">
          <div class="h-56 w-1/2 bg-gray-100" />
        </template>

        <FileDisplay
          :file="fileContent"
          :class="sideBySide ? 'w-1/2' : 'w-full'"
          class="h-56 overflow-y-auto"
        />
      </div>
      <div
        v-if="_.includes(['ContentPolygon', 'GeographyRegion'], fieldName)"
        class="w-full flex items-center divide-x-2 divide-white mb-2"
      >
        <template v-if="sideBySide">
          <ParcelMap
            v-if="dataFieldChange?.before"
            :map-id="`${dataFieldChange.id}-${dataFieldChange.before.localId}-${afterId}`"
            :polygon="dataFieldChange.before.fieldContent"
            class="h-56 w-1/2"
          />
          <div v-else class="h-56 w-1/2 bg-gray-100" />
        </template>

        <ParcelMap
          v-if="dataFieldChange"
          :map-id="`${dataFieldChange.id}-${afterId}`"
          :polygon="afterContent"
          :class="sideBySide ? 'w-1/2' : 'w-full'"
          class="h-56"
        />
        <ParcelMap
          v-else
          :map-id="`${dataField.localId}-${afterId}`"
          :polygon="afterContent"
          :class="sideBySide ? 'w-1/2' : 'w-full'"
          class="h-56"
        />
      </div>

      <div
        v-else-if="fieldName === 'ContentLocation'"
        class="w-full flex items-center divide-x-2 divide-white mb-2"
      >
        <template v-if="sideBySide">
          <LocationMap
            v-if="dataFieldChange.before"
            :editable="false"
            :nearby="dataFieldChange.before.decoratingContentType"
            :map-id="`${dataFieldChange.id}-${dataFieldChange.before.localId}-${afterId}`"
            :lat="dataFieldChange.before.fieldContent.lat"
            :lng="dataFieldChange.before.fieldContent.lng"
            class="h-56 w-1/2"
          />
          <div v-else class="h-56 w-1/2 bg-gray-100" />
        </template>

        <LocationMap
          :editable="false"
          :nearby="dataFieldChange.after.decoratingContentType"
          :map-id="`${dataFieldChange.id}-${afterId}`"
          :lat="afterContent.lat"
          :lng="afterContent.lng"
          :class="sideBySide ? 'w-1/2' : 'w-full'"
          class="h-56"
          data-test="content-location-visual-diff"
        />
      </div>

      <div
        v-if="displayableInvestments"
        class="w-full flex items-center divide-x-2 divide-white mb-2"
      >
        <PotentialDuplicateInvestments
          :potential-duplicate-investments="likeAssetInvestments"
          :focal-investment-id="relatedInvestmentId"
          :investment-valuations="investmentValuations"
        />
      </div>

      <div
        v-else-if="displayablePortfolioInvestments"
        class="w-full flex items-center divide-x-2 divide-white mb-2"
      >
        <PotentialDuplicateInvestments
          :potential-duplicate-investments="potentialDuplicateInvestments"
          :focal-investment-id="relatedInvestmentId"
          :investment-valuations="investmentValuations"
        />
      </div>

      <div
        v-if="
          fieldName === 'ContactCompanyInvolvement' && _.isArray(afterFields)
        "
        class="w-full flex items-center divide-x-2 divide-white mb-2"
      >
        <ContactCompanyNames :contact-involvement-fields="afterFields" />
      </div>
    </div>
  </div>
</template>

<script setup>
import CitationDetails from "@/components/crowdsourcing/CitationDetails.vue";
import ParcelMap from "@/components/maps/ParcelMap.vue";
import LocationMap from "@/components/maps/LocationMap.vue";
import PotentialDuplicateInvestments from "@/components/crowdsourcing/PotentialDuplicateInvestments.vue";
import { computed, nextTick, onMounted, ref, watch } from "vue";
import _ from "lodash";
import FileDisplay from "@/components/crowdsourcing/FileDisplay.vue";
import api from "@/router/api";
import ContactCompanyNames from "@/components/crowdsourcing/ContactCompanyNames.vue";

const props = defineProps([
  "fieldName",
  "dataFieldChange",
  "dataField",
  "afterFields",
  "outputFormat",
  "investmentRelations",
  "changeInvestments",
  "relatedInvestments",
  "investmentValuations",
]);
const emit = defineEmits(["set-investment-relation"]);
const displayVisual = ref(true);
const displayableInvestments = computed(() => {
  if (likeAssetInvestments.value) {
    const appropriate = investmentValuation.value
      ? true
      : likeAssetInvestments.value.length > 1;
    return appropriate;
  } else {
    return false;
  }
});
const displayablePortfolioInvestments = computed(
  () =>
    investmentAssetType.value === "InvestmentGroup" &&
    potentialDuplicateInvestments.value &&
    potentialDuplicateInvestments.value.length > 0,
);
const investmentValuation = computed(() => {
  const decoratingContentTypeMatch =
    afterDecoratingContentType.value === "Valuation" ||
    beforeDecoratingContentType.value === "Valuation";

  return decoratingContentTypeMatch && topLevelContentType.value === "Property";
});
const investmentAssetType = computed(() => {
  if (investmentValuation.value) {
    return (
      afterDecoratingContentType.value || beforeDecoratingContentType.value
    );
  } else {
    return (
      topLevelContentType.value ||
      afterDecoratingContentType.value ||
      beforeDecoratingContentType.value
    );
  }
});
const investmentAssetId = computed(() => {
  if (investmentValuation.value) {
    return afterDecoratingContentId.value || beforeDecoratingContentId.value;
  } else {
    return (
      topLevelContentId.value ||
      afterDecoratingContentId.value ||
      beforeDecoratingContentId.value
    );
  }
});
const investmentCombinedKey = computed(
  () => `${investmentAssetType.value}-${investmentAssetId.value}`,
);
const potentialDuplicateInvestments = computed(() => {
  if (relatedAsset.value) {
    return props.relatedInvestments?.[relatedAsset.value];
  } else {
    return null;
  }
});
const relatedInvestmentId = computed(() => {
  if (investmentRelation.value) {
    return props.changeInvestments?.[investmentCombinedKey.value];
  } else {
    return null;
  }
});
const relatedAsset = computed(() => {
  if (investmentRelation.value) {
    return props.investmentRelations?.[investmentCombinedKey.value];
  } else {
    return null;
  }
});
const investmentRelation = computed(() => {
  return _.includes(
    [
      "InvestmentGroup",
      "Investment",
      "Valuation",
      "OwnershipInterest",
      "CompanyInvolvement",
    ],
    investmentAssetType.value,
  );
});
const focalInvestment = computed(() => {
  if (relatedInvestmentId.value) {
    return _.find(potentialDuplicateInvestments.value, {
      fieldContentId: relatedInvestmentId.value,
    });
  } else {
    return null;
  }
});

const likeAssetInvestments = computed(() => {
  if (focalInvestment.value) {
    return potentialDuplicateInvestments.value.filter((i) => {
      return (
        i.fieldContent?.investmentType ===
        focalInvestment.value.fieldContent?.investmentType
      );
    });
  } else {
    return null;
  }
});

const sideBySide = computed(() => {
  return props.outputFormat === "side-by-side";
});
const visualField = computed(() => {
  if (props.fieldName) {
    return _.includes(
      [
        "CitationChangeGroup",
        "ContentPolygon",
        "GeographyRegion",
        "ContentLocation",
        "CrowdsourcedFile",
        "ContactCompanyInvolvement",
        "url",
      ],
      props.fieldName,
    );
  } else {
    return false;
  }
});
const fileField = computed(() => {
  if (props.fieldName) {
    return _.includes(["CrowdsourcedFile", "url"], props.fieldName);
  } else {
    return false;
  }
});
const topLevelContent = computed(() => {
  return _.get(props.dataFieldChange, "topLevelContent");
});
const topLevelContentType = computed(
  () => topLevelContent.value?.fieldContentType,
);
const topLevelContentId = computed(() => topLevelContent.value?.fieldContentId);
const beforeDecoratingContentId = computed(() => {
  return _.get(props.dataFieldChange, "before.decoratingContentId");
});
const beforeDecoratingContentType = computed(() => {
  return _.get(props.dataFieldChange, "before.decoratingContentType");
});
const afterDecoratingContentId = computed(() => {
  return (
    _.get(props.dataField, "decoratingContentId") ||
    _.get(props.dataFieldChange, "after.decoratingContentId")
  );
});
const afterDecoratingContentType = computed(() => {
  return (
    _.get(props.dataField, "decoratingContentType") ||
    _.get(props.dataFieldChange, "after.decoratingContentType")
  );
});
const afterId = computed(() => {
  return (
    _.get(props.dataField, "localId") ||
    _.get(props.dataFieldChange, "after.localId")
  );
});
const afterContent = computed(() => {
  return (
    _.get(props.dataField, "fieldContent") ||
    _.get(props.dataFieldChange, "after.fieldContent")
  );
});
const afterStandaloneValue = computed(() => {
  return (
    _.get(props.dataField, "fieldValue") ||
    _.get(props.dataFieldChange, "after.fieldValue")
  );
});
const fileContent = computed(() => {
  if (props.fieldName === "url") {
    return {
      link: afterStandaloneValue.value,
    };
  } else {
    return afterContent.value;
  }
});

watch(sideBySide, async () => {
  displayVisual.value = false;

  await nextTick();
  displayVisual.value = true;
});

onMounted(() => {
  if (investmentRelation.value) {
    fetchDeduplicationInvestments();
  }
});

async function fetchDeduplicationInvestments() {
  if (investmentRelation.value && !relatedAsset.value) {
    console.log(
      "fetching duplication investments",
      investmentCombinedKey.value,
    );

    if (investmentAssetType.value === "InvestmentGroup") {
      emit("set-investment-relation", {
        relatedContentKey: investmentCombinedKey.value,
        assetId: investmentAssetId.value,
        assetType: investmentAssetType.value,
        investmentId: null,
      });
    } else {
      const response = await api.get(
        `investment_assets/${investmentAssetType.value}/${investmentAssetId.value}`,
      );

      if (response?.data) {
        const { assetId, assetType, investmentId } = response.data;

        emit("set-investment-relation", {
          relatedContentKey: investmentCombinedKey.value,
          assetId,
          assetType,
          investmentId,
        });
      }
    }
  }
}
</script>
