<template>
  <li>
    <span class="flex items-center space-x-1">
      <template v-if="investmentState && statusLabel">
        <span
          v-if="progressionStyle === 'completed'"
          class="flex-shrink-0 relative h-5 w-5 flex items-center justify-center"
        >
          <svg
            class="h-full w-full text-gray-400 group-hover:text-gray-500"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
              clip-rule="evenodd"
            />
          </svg>
        </span>
        <span
          v-if="progressionStyle === 'current'"
          class="flex-shrink-0 relative h-5 w-5 flex items-center justify-center"
        >
          <svg
            class="h-full w-full text-indigo-600 group-hover:text-indigo-800"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
              clip-rule="evenodd"
            />
          </svg>
        </span>
        <div
          v-else-if="progressionStyle === 'upcoming'"
          class="flex-shrink-0 h-5 w-5 relative flex items-center justify-center"
          aria-hidden="true"
        >
          <div
            class="h-2 w-2 bg-gray-300 rounded-full group-hover:bg-gray-400"
          ></div>
        </div>
        <span
          v-else-if="progressionStyle === 'locked'"
          class="flex-shrink-0 relative h-5 w-5 flex items-center justify-center"
        >
          <svg
            class="h-full w-full text-gray-300 group-hover:text-gray-400"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
              clip-rule="evenodd"
            />
          </svg>
        </span>

        <DataField
          v-if="timingUnlockable && investmentState?.price"
          :data-field="investmentState"
          :timing-fields="timingFields"
          standalone-field-name="state"
          :dropdown-range-value="snakeCaseProgressionValue"
          text-classes="text-sm font-medium"
          text-styles=""
          @unlocked="unlocked"
          @completed="completed"
          @suggest-change="suggestStateChange"
          @set-proof="setProof"
        />

        <button
          v-else
          @click="suggestStateChange"
          type="button"
          :data-test="`investment-group-${snakeCaseProgressionValue}-edit-state-button`"
          class="inline-flex rounded-full items-center py-0.5 px-2 text-sm font-medium bg-gray-100 text-gray-700"
        >
          {{ statusLabel }}
        </button>
      </template>

      <InvestmentGroupStateField
        v-else
        :investment-group-id="investmentGroupId"
        :investment-state-snake-case-progression-value="
          snakeCaseProgressionValue
        "
        :all-milestones="allMilestones"
        @completed="completed"
      />

      <DataField
        v-if="unlockableDate && !date"
        :data-field="unlockableDate"
        :timing-fields="timingFields"
        standalone-field-name="date"
        text-classes="text-sm font-medium"
        @unlocked="unlocked"
        @completed="fetchDate"
        @suggest-change="suggestDateChange"
        @set-proof="setProof"
      />
      <template v-else-if="date && !editingDate">
        <svg
          class="flex-shrink-0 h-5 w-5 text-gray-400"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fill-rule="evenodd"
            d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
            clip-rule="evenodd"
          />
        </svg>
        <button
          @click="suggestDateChange"
          type="button"
          :data-test="`investment-group-${snakeCaseProgressionValue}-edit-date-button`"
          class="inline-flex rounded-full items-center py-0.5 px-2 text-sm font-medium bg-gray-100 text-gray-700"
        >
          {{ formattedDate }}
        </button>
      </template>
      <InvestmentGroupDateField
        v-else
        :investment-group-id="investmentGroupId"
        :investment-state-snake-case-progression-value="
          snakeCaseProgressionValue
        "
        :existing-date="date"
        :milestone-object="milestoneObject"
        @completed="updateDateField"
        @cancel="cancelDateEdit"
      />
    </span>
  </li>
</template>

<script setup>
import InvestmentGroupDateField from "@/components/deal-builder/InvestmentGroupDateField.vue";
import InvestmentGroupStateField from "@/components/deal-builder/InvestmentGroupStateField.vue";
import ContentMilestoneDropdown from "@/components/crowdsourcing/ContentMilestoneDropdown.vue";
import DataField from "@/components/crowdsourcing/DataField.vue";
import { useProveForValueStore } from "@/stores/proveForValue";
import { ref, computed, onMounted } from "vue";
import moment from "moment";
import api from "@/router/api";
import _ from "lodash";

const props = defineProps([
  "investmentGroupId",
  "investmentState",
  "milestoneObject",
  "allMilestones",
  "timingFields",
  "timingUnlockable",
]);
const emit = defineEmits(["suggest-state-change", "completed", "unlocked"]);

const proveForValueStore = useProveForValueStore();

const date = ref(null);
const formattedDate = computed(() => {
  if (_.isObject(date.value)) {
    return moment(date.value.fieldValue).format("MMM D, YYYY");
  } else if (_.isString(date.value)) {
    return moment(date.value).format("MMM D, YYYY");
  } else {
    return null;
  }
});
const unlockableDate = computed(() => {
  if (props.timingUnlockable) {
    const uniqueFields = _.uniqBy(props.timingFields, "fieldName");
    const dateField = _.find(uniqueFields, {
      fieldName: `${snakeCaseProgressionValue.value}_date`,
    });
    return dateField;
  } else {
    return null;
  }
});
const progressionStyle = ref("locked");
const statusLabel = ref(null);
const editingDate = ref(false);

const snakeCaseProgressionValue = computed(() => props.milestoneObject.value);

const dateProofProps = computed(() => {
  return {
    fieldName: "date",
    focus: true,
    milestoneObject: props.milestoneObject,
    existingDate: unlockableDate.value,
    investmentGroupId: props.investmentGroupId,
  };
});
const stateProofProps = computed(() => {
  return {
    fieldName: "state",
    existingState: props.investmentState,
    investmentGroupId: props.investmentGroupId,
  };
});

onMounted(() => {
  setProgressionStyle();
  setStatusLabel();
  fetchDate();
});

function setProof(fieldName) {
  let matchedProps = {};

  if (fieldName === "state") {
    matchedProps = stateProofProps.value;
  } else {
    matchedProps = dateProofProps.value;
  }

  if (matchedProps && proofComponentFor(fieldName)) {
    proveForValueStore.setProofComponent(
      proofComponentFor(fieldName),
      matchedProps,
    );
  }
}

function proofComponentFor(fieldName) {
  switch (fieldName) {
    case "state":
      return ContentMilestoneDropdown;
    default:
      return InvestmentGroupDateField;
  }
}

function setStatusLabel() {
  const targetState = _.find(props.allMilestones, {
    value: snakeCaseProgressionValue.value,
  });

  statusLabel.value = targetState.statusLabel;
}
function setProgressionStyle() {
  const currentState = _.find(props.allMilestones, {
    value: props.investmentState,
  });
  const valuesExist = currentState && props.milestoneObject;

  if (valuesExist) {
    const currentOrder = currentState.order;
    const progressionOrder = props.milestoneObject.order;
    const completed = progressionOrder < currentOrder;
    const current = progressionOrder === currentOrder;
    const upcoming = progressionOrder > currentOrder;
    const skipped =
      completed && props.milestoneObject.value === "withdrawn" && !date.value;

    if (skipped) {
      progressionStyle.value = "upcoming";
    } else if (completed) {
      progressionStyle.value = "completed";
    } else if (current) {
      progressionStyle.value = "current";
    } else if (upcoming) {
      progressionStyle.value = "upcoming";
    }
  }
}
function updateDateField({ dataFields }) {
  if (dataFields) {
    editingDate.value = false;
    const dateDataField = _.find(dataFields, {
      fieldValueType: "Date",
      deprecatedAt: null,
    });
    date.value = dateDataField;
    completed();
  } else if (!props.investmentState) {
    completed();
  }
}
function fetchDate() {
  api
    .get(
      `investment_group_dates/${props.investmentGroupId}?field_name=${snakeCaseProgressionValue.value}`,
    )
    .then((json) => {
      date.value = json.data;
    });
}
function suggestDateChange() {
  editingDate.value = true;
}
function suggestStateChange() {
  emit("suggest-state-change");
}
function cancelDateEdit() {
  editingDate.value = false;
}
function completed(maybePayload) {
  if (maybePayload?.dataField) {
    emit("completed", { dataField: maybePayload.dataField });
    fetchDate();
  } else {
    console.log("no payload?");
    emit("completed");
  }
}
function unlocked(maybePayload) {
  emit("unlocked", maybePayload);
  fetchDate();
}
</script>
