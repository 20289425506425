<template>
  <form @submit.prevent class="p-2 space-y-4 divide-y divide-gray-200">
    <div v-if="!isDesktop" class="flex items-center justify-around">
      <a @click.prevent="cancel" href="" class="text-sm text-indigo-500">
        Cancel
      </a>
      <h3
        class="flex min-w-0 flex-1 items-center justify-center font-medium text-gray-900"
      >
        New List
      </h3>
      <a
        @click.prevent="add"
        href=""
        class="flex items-center text-sm font-medium text-indigo-500"
        data-test="save-task-list-button"
      >
        {{ selectedTaskList ? "Done" : "Add" }}
      </a>
    </div>

    <div class="space-y-4">
      <div v-if="isDesktop">
        <h3 class="text-lg font-medium leading-6 text-gray-900">
          {{ selectedTaskList?.accessTokenId ? "Edit List" : "New List" }}
        </h3>
      </div>

      <fieldset>
        <div>
          <div class="flex items-center space-x-2">
            <div
              :class="`${selectedColor.shadowColor} ${selectedColor.bgColor}`"
              class="shadow-md rounded-full p-1.5"
            >
              <ListBulletIcon class="h-5 w-5 text-white" aria-hidden="true" />
            </div>
            <input
              v-model="name"
              v-focus
              @keydown.enter="add"
              type="text"
              name="name"
              id="name"
              class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              placeholder="List Name"
              data-test="task-list-name-input"
            />
          </div>
        </div>
      </fieldset>

      <fieldset v-if="colors">
        <RadioGroup v-model="selectedColor">
          <RadioGroupLabel class="block text-sm font-medium text-gray-700"
            >Choose a list color</RadioGroupLabel
          >
          <div class="mt-4 flex items-center space-x-3">
            <RadioGroupOption
              as="template"
              v-for="color in colors"
              :key="color.name"
              :value="color"
              v-slot="{ active, checked }"
              @keydown.enter="add"
            >
              <div
                :class="[
                  color.selectedColor,
                  active && checked ? 'ring ring-offset-1' : '',
                  !active && checked ? 'ring-2' : '',
                  '-m-0.5 relative p-0.5 rounded-full flex items-center justify-center cursor-pointer focus:outline-none',
                ]"
              >
                <RadioGroupLabel as="span" class="sr-only">{{
                  color.name
                }}</RadioGroupLabel>
                <span
                  aria-hidden="true"
                  :class="[color.bgColor, 'h-8 w-8 rounded-full']"
                />
              </div>
            </RadioGroupOption>
          </div>
        </RadioGroup>
      </fieldset>
    </div>

    <div v-if="isDesktop" class="pt-5">
      <div class="flex justify-end">
        <button
          @click="cancel"
          type="button"
          class="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          Cancel
        </button>
        <button
          @click="add"
          type="button"
          class="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          data-test="save-task-list-button"
        >
          {{ selectedTaskList ? "Done" : "Add" }}
        </button>
      </div>
    </div>
  </form>
</template>

<script setup>
import { ListBulletIcon } from "@heroicons/vue/20/solid";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { useTasksStore } from "@/stores/tasks";
import { useTaskListStore } from "@/stores/taskList";
import { storeToRefs } from "pinia";
import { RadioGroup, RadioGroupLabel, RadioGroupOption } from "@headlessui/vue";
import _ from "lodash";

const tasksStore = useTasksStore();
const { listGroupedTaskPagyObjects, addingList, taskLists, selectedTaskList } =
  storeToRefs(tasksStore);
const taskListStore = useTaskListStore();
const { name, selectedColor, colors } = storeToRefs(taskListStore);
const layoutStore = useWorkspaceLayoutStore();
const { isDesktop } = storeToRefs(layoutStore);

function cancel() {
  addingList.value = false;
  name.value = "";
  selectedColor.value = colors.value[0];
}
function add() {
  taskListStore.createList(selectedTaskList.value).then((json) => {
    if (json) {
      const list = json.data;
      if (selectedTaskList.value) {
        tasksStore.patchTaskList(list);
        selectedTaskList.value = list;
      } else {
        taskLists.value.data.push(list);
      }

      listGroupedTaskPagyObjects.value[
        list.accessTokenId || _.camelCase(list.name)
      ] = {
        data: [],
        pagy: null,
      };
    }
    cancel();
  });
}
</script>
