<template>
  <div
    :data-test="`${decoratingAndFieldKey(
      dataField,
    )}-capital-stack-diagram-container`"
  >
    <div
      :class="
        workspaceLayout === 'topAndBottom'
          ? 'grid grid-cols-2 gap-3 grid-flow-row-dense'
          : ''
      "
      class=""
    >
      <button
        v-if="dealBuilder && nested"
        @click="addAssetToDealBuilder"
        type="button"
        :class="workspaceLayout === 'topAndBottom' ? 'col-span-2' : ''"
        class="w-full text-sm inline-flex justify-center rounded-md border border-indigo-300 shadow-sm px-4 py-2 bg-white font-medium text-indigo-700 hover:bg-indigo-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Open in Deal Builder
      </button>
      <button
        v-else-if="!dealBuilder"
        @click="buildDeal"
        type="button"
        :class="workspaceLayout === 'topAndBottom' ? 'col-span-2' : ''"
        class="w-full text-sm inline-flex justify-center rounded-md border border-indigo-300 shadow-sm px-4 py-2 bg-white font-medium text-indigo-700 hover:bg-indigo-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        View &amp; Add Deals
      </button>
    </div>
    <ol
      v-if="refreshed"
      role="capital_stack"
      :class="hasButton ? 'mt-3' : ''"
      class="grid grid-cols-1"
    >
      <CapitalStackLayer
        layer-type="OwnershipInterest"
        :property-id="propertyId"
        :deal-builder-asset-data-field="dealBuilderAssetDataField || dataField"
        :data-field="dataField"
        :future="future"
        :nested="nested"
        :allows-senior-loan="allowsSeniorLoan"
        :creating-senior-loan="creatingSeniorLoan"
        :depth="6"
        @input-senior-loan="inputSeniorLoan"
        @container-cancel="cancelSeniorLoan"
        @refetch-ownership-layer="refetchDiagram"
      />
      <CapitalStackLayer
        v-if="creatingSeniorLoan"
        layer-type="Loan"
        :property-id="propertyId"
        :deal-builder-asset-data-field="dealBuilderAssetDataField || dataField"
        :data-field="dataField"
        :future="future"
        :nested="nested"
        :allows-senior-loan="false"
        :creating-senior-loan="creatingSeniorLoan"
        :preactivate-form="true"
        @container-cancel="cancelSeniorLoan"
        @refetch-senior-loan="fetchSeniorLoan"
      />
      <CapitalStackLayer
        v-else-if="seniorLoan"
        layer-type="Loan"
        :senior-loan="seniorLoan"
        :property-id="propertyId"
        :deal-builder-asset-data-field="dealBuilderAssetDataField || dataField"
        :data-field="seniorLoan"
        :future="future"
        :nested="nested"
        :allows-senior-loan="false"
        :creating-senior-loan="false"
        :loan-collateral-type="null"
      />
    </ol>
  </div>
</template>

<script setup>
import CapitalStackLayer from "@/components/deal-builder/CapitalStackLayer.vue";
import { useTimeTravelStore } from "@/stores/timeTravel";
import { useDealBuilderStore } from "@/stores/dealBuilder";
import { usePropertyDiagramStore } from "@/stores/propertyDiagram";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { useDatabaseUpdatesChannelStore } from "@/stores/databaseUpdatesChannel";
import { useDataLicensesChannelStore } from "@/stores/dataLicensesChannel";
import { useDataSharingsChannelStore } from "@/stores/dataSharingsChannel";
import { storeToRefs } from "pinia";
import { ref, computed, watch, onMounted, nextTick } from "vue";
import api from "@/router/api";
import decoratingAndFieldKey from "@/components/crowdsourcing/decoratingAndFieldKey";
import _ from "lodash";
import { useRoute, useRouter } from "vue-router";

const props = defineProps([
  "propertyId",
  "dataField",
  "dealBuilderAssetDataField",
  "future",
  "nested",
]);

const layoutStore = useWorkspaceLayoutStore();
const { workspaceLayout } = storeToRefs(layoutStore);
const timeTravelStore = useTimeTravelStore();
const { asOfDate, asOfMilliseconds, dayBeforeAsOf } =
  storeToRefs(timeTravelStore);
const dealBuilderStore = useDealBuilderStore();
const { dealBuilder, crossInteraction, assetFieldIds } =
  storeToRefs(dealBuilderStore);
const propertyDiagramStore = usePropertyDiagramStore();
const { propertyDiagramPropertyIds } = storeToRefs(propertyDiagramStore);
const databaseUpdatesChannelStore = useDatabaseUpdatesChannelStore();
const { databaseUpdatesChannelDataQueue } = storeToRefs(
  databaseUpdatesChannelStore,
);
const dataLicensesChannelStore = useDataLicensesChannelStore();
const { dataLicensesChannelDataQueue } = storeToRefs(dataLicensesChannelStore);
const dataSharingsChannelStore = useDataSharingsChannelStore();
const { dataSharingsChannelDataQueue } = storeToRefs(dataSharingsChannelStore);

const creatingSeniorLoan = ref(false);
const seniorLoan = ref(null);
const refreshed = ref(true);

const hasButton = computed(() => {
  return (dealBuilder.value && props.nested) || !dealBuilder.value;
});
const dataFieldLocalId = computed(() => {
  return _.get(props.dataField, "localId");
});
const existingInvestmentSelected = computed(() => {
  return (
    _.get(crossInteraction.value, "source") === "TimelineInvestment" &&
    _.get(crossInteraction.value, "assetKey") === assetKey.value
  );
});
const existingInvestment = computed(() => {
  const asset = _.get(dealBuilder.value, `assets[${assetKey.value}]`);
  const assetInvestments = _.get(asset, `investments`, []);
  const investment = _.find(assetInvestments, {
    id: _.get(crossInteraction.value, "combinedKey"),
  });

  return investment;
});
const fetchMilliseconds = computed(() => {
  return existingInvestmentSelected.value && !props.future
    ? dayBeforeAsOf.value
    : asOfMilliseconds.value;
});
const subjectIsFieldContent = computed(() => {
  return _.includes(
    ["Property", "PropertyRight"],
    props.dataField.decoratingContentType,
  );
});
const contentId = computed(() => {
  if (subjectIsFieldContent.value) {
    return props.dataField.fieldContentId;
  } else {
    return props.dataField.decoratingContentId;
  }
});
const contentType = computed(() => {
  if (subjectIsFieldContent.value) {
    return props.dataField.fieldContentType;
  } else {
    return props.dataField.decoratingContentType;
  }
});
const allowsSeniorLoan = computed(() => {
  return !seniorLoan.value;
});
const assetKey = computed(() => {
  return decoratingAndFieldKey(props.dataField);
});
const futureEquityDeal = computed(() => {
  return findMatchingGroupInvestment("equity");
});
const futureDebtDeal = computed(() => {
  return findMatchingGroupInvestment("debt");
});
const stubbedJuniorLoan = computed(() => {
  return (
    props.future && _.get(futureDebtDeal.value, "loanSeniority") === "junior"
  );
});
const shouldCreateSeniorLoan = computed(() => {
  const loanSeniority = _.get(futureDebtDeal.value, "loanSeniority");
  const nonJunior = loanSeniority && loanSeniority !== "junior";
  const refi = _.get(futureDebtDeal.value, "dealAction") === "refinance";

  return props.future && (nonJunior || refi);
});
const futureInvestmentRequiringSeniorLoanFetch = computed(() => {
  return (
    futureLoanAssumption.value ||
    futureForeclosure.value ||
    stubbedJuniorLoan.value
  );
});
const futureLoanAssumption = computed(() => {
  const assumptionLoanIds = _.get(
    dealBuilderStore.matchingAssetObject(assetKey.value),
    "assumptionLoans",
    [],
  );
  const futureDraftDealAction = _.get(futureEquityDeal.value, "dealAction");
  const existingInv = _.get(existingInvestment.value, "dealAction");
  const matchingDealAction =
    futureDraftDealAction === "sellOutright" || existingInv === "sellOutright";

  return props.future && matchingDealAction && assumptionLoanIds.length > 0;
});
const foreclosureLoanIds = computed(() => {
  return _.get(
    dealBuilderStore.matchingAssetObject(assetKey.value),
    "foreclosureLoans",
    [],
  );
});
const futureForeclosure = computed(() => {
  const futureDraftDealAction = _.get(futureEquityDeal.value, "dealAction");
  const existingInv = _.get(existingInvestment.value, "dealAction");
  const matchingDealAction =
    futureDraftDealAction === "foreclosureSellOutright" ||
    existingInv === "foreclosureSellOutright";

  return props.future && matchingDealAction;
});

watch(databaseUpdatesChannelDataQueue, () => {
  const data = _.last(databaseUpdatesChannelDataQueue.value);

  if (data.dataFieldIds) {
    refetchDiagram();
  }
});
watch(dataLicensesChannelDataQueue, () => {
  const data = _.last(dataLicensesChannelDataQueue.value);
  console.log("cap stack diagram licenses channel watcher", data);

  if (data.dataFieldIds && data.context !== "provings_controller") {
    refetchDiagram();
  }
});

watch(dataSharingsChannelDataQueue, () => {
  const data = _.last(dataSharingsChannelDataQueue.value);

  if (data.dataFieldIds) {
    refetchDiagram();
  }
});

watch(dataFieldLocalId, () => {
  if (props.dataField) {
    refetchDiagram();
  }
});
watch(asOfDate, (date, oldDate) => {
  if (oldDate && date !== oldDate) {
    dealBuilderStore.clearDealBuilder(false);
    refetchDiagram();
  }
});
watch(futureLoanAssumption, () => {
  fetchSeniorLoan();
});
watch(futureForeclosure, () => {
  fetchSeniorLoan();
});
watch(futureDebtDeal, (deal, oldDeal) => {
  if (oldDeal && !futureDebtDeal.value) {
    creatingSeniorLoan.value = false;
  }
});

onMounted(() => {
  if (existingInvestmentSelected.value) {
    console.log("stack diagram existing investment fetch");
    fetchSeniorLoan();
  } else if (shouldCreateSeniorLoan.value) {
    console.log("stack diagram eligible future deal input");
    inputSeniorLoan();
  } else {
    fetchSeniorLoan();
  }
});

function refetchDiagram() {
  console.log("refetch diagram");
  refreshed.value = false;
  seniorLoan.value = null;
  fetchSeniorLoan();
}
function findMatchingGroupInvestment(assetType = "equity") {
  let matchingInvestment = null;
  const dealActions =
    assetType === "equity"
      ? [
          "foreclosureSellOutright",
          "sellOutright",
          "sellEntireInterest",
          "sellPartialInterest",
          "sellMultipleInterests",
        ]
      : ["refinance", "originateLoan"];

  _.get(dealBuilder.value, "investmentGroups", []).forEach((group) => {
    const matchingAction = _.some(group.investments, function (investment) {
      return _.intersection(dealActions, [investment.dealAction]).length > 0;
    });

    if (matchingAction && !matchingInvestment) {
      matchingInvestment = _.find(
        group.investments,
        function (investment, key) {
          const assetKeys = _.split(key, "_");
          const matchingKey = assetKeys[0] === assetKey.value;
          const matchingAction =
            _.intersection(dealActions, [investment.dealAction]).length > 0;

          return matchingKey && matchingAction;
        },
      );
    }
  });

  return matchingInvestment;
}
function inputSeniorLoan(maybePayload) {
  const investmentGroup = maybePayload?.investmentGroup;

  if ((props.future && !futureDebtDeal.value) || investmentGroup === "new") {
    dealBuilderStore.addDealBuilderInvestmentGroup({
      assetDataField: props.dataField,
      investmentFieldContent: props.dataField,
      ownershipInterestIds: [],
      dealAction: "originateLoan",
      loanSeniority: "senior",
    });
  }

  if (
    investmentGroup !== "new" &&
    (investmentGroup?.id || investmentGroup?.placeholderId)
  ) {
    creatingSeniorLoan.value = investmentGroup;
  } else if (investmentGroup !== "new") {
    creatingSeniorLoan.value = true;
  }
}
function cancelSeniorLoan() {
  creatingSeniorLoan.value = false;
}
async function fetchSeniorLoan() {
  if (
    existingInvestmentSelected.value ||
    !props.future ||
    futureInvestmentRequiringSeniorLoanFetch.value
  ) {
    const loanState = props.future ? "" : "&loan_state=active";
    const fetchRequestKey = `loans_${contentType.value}_${contentId.value}_${props.layerType}_senior${loanState}`;
    let loans = [];

    if (dealBuilderStore.alreadyFetched(fetchRequestKey)) {
      loans = dealBuilderStore.alreadyFetchedFieldsFor(fetchRequestKey);
    } else {
      const loansResponse = await api.get(
        `loans/?content_type=${contentType.value}&content_id=${contentId.value}&seniority_type=senior&as_of=${fetchMilliseconds.value}${loanState}`,
      );
      loans = loansResponse.data;
      dealBuilderStore.interceptablePatch(loans, fetchRequestKey);
    }

    const potentialSenior = _.find(loans, {
      fieldContentSubType: null,
    });
    const beingForeclosed = potentialSenior
      ? _.includes(
          foreclosureLoanIds.value,
          potentialSenior.decoratingContentId,
        )
      : false;
    const excludable = beingForeclosed && props.future;

    seniorLoan.value = excludable ? null : potentialSenior;
    refreshed.value = true;
  }
}

const router = useRouter();
const route = useRoute();

async function buildDeal() {
  dealBuilderStore.newDealBuilder({ assetDataField: props.dataField });
  await nextTick();
  router.push({
    name: route.name,
    query: {
      ...route.query,
      horizontalTab: "Deals",
      verticalTab: undefined,
      assetFieldIds: assetFieldIds.value,
    },
  });

  if (props.dataField.joiningContentType === "Property") {
    if (
      !_.includes(
        propertyDiagramPropertyIds.value,
        props.dataField.joiningContentId,
      )
    ) {
      propertyDiagramStore.addPropertyToDiagram(
        props.dataField.joiningContentId,
      );
    }
  }
}
function addAssetToDealBuilder() {
  const assetKey = decoratingAndFieldKey(props.dataField);
  const assetObject = {
    dataField: props.dataField,
    investments: [],
    expanded: true,
    ownershipInterests: [],
  };

  dealBuilderStore.addDealBuilderAsset({ assetObject, assetKey });
  dealBuilderStore.setDealBuilderAssetExpanded({
    assetKey: decoratingAndFieldKey(props.dealBuilderAssetDataField),
    expanded: false,
  });
}
</script>
