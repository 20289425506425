<template>
  <div class="bg-white overflow-hidden">
    <div class="p-2">
      <dl
        class="grid gap-x-4 gap-y-8"
        :class="
          workspaceLayout === 'topAndBottom' ? 'grid-cols-2' : 'grid-cols-1'
        "
      >
        <div class="col-span-1">
          <dt class="text-sm font-medium text-gray-500">
            Space Usages fulfilling this Hunt:
          </dt>
          <dd class="mt-2 space-y-2">
            <SquareLoader
              v-if="!fulfillingUsagesLoaded"
              :loading="true"
              size="24px"
              color="#4338ca"
            />

            <DataField
              v-for="dataField in fulfillingSpaceUsages"
              :key="dataField.localId"
              :data-field="dataField"
              text-classes="text-sm"
              @completed="refetch"
              @open-sourced="refetch"
              @unlocked="refetch"
              @dismiss="refetch"
            />

            <section
              v-if="
                fulfillingUsagesLoaded && fulfillingSpaceUsages.length === 0
              "
            >
              <div
                class="relative block w-full rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <CheckBadgeIcon class="mx-auto h-12 w-12 text-gray-400" />
                <span class="mt-2 block text-sm font-medium text-gray-900">
                  Link space usages resulting from this Hunt to indicate the
                  space user is no longer in the market.
                </span>
              </div>
            </section>
          </dd>
        </div>

        <div class="col-span-1">
          <dt class="text-sm font-medium text-gray-500">
            Space Usages triggering this Hunt:
          </dt>
          <dd class="mt-2 space-y-2">
            <SquareLoader
              v-if="!triggeringUsagesLoaded"
              :loading="true"
              size="24px"
              color="#4338ca"
            />

            <DataField
              v-for="dataField in triggeringSpaceUsages"
              :key="dataField.localId"
              :data-field="dataField"
              text-classes="text-sm"
              @completed="refetch"
              @open-sourced="refetch"
              @unlocked="refetch"
              @dismiss="refetch"
            />

            <section
              v-if="
                triggeringUsagesLoaded && triggeringSpaceUsages.length === 0
              "
            >
              <div
                class="relative block w-full rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <TruckIcon class="mx-auto h-12 w-12 text-gray-400" />
                <span class="mt-2 block text-sm font-medium text-gray-900">
                  Link existing space usages to this Hunt to indicate a
                  potential relocation.
                </span>
              </div>
            </section>
          </dd>
        </div>
      </dl>
    </div>
  </div>
</template>

<script setup>
import DataField from "@/components/crowdsourcing/DataField.vue";
import SquareLoader from "vue-spinner/src/SquareLoader.vue";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { storeToRefs } from "pinia";
import { ref, computed, watch, onMounted } from "vue";
import api from "@/router/api";
import _ from "lodash";
import { CheckBadgeIcon, TruckIcon } from "@heroicons/vue/24/outline";

const props = defineProps(["dataField", "contentDataField", "context"]);
const emit = defineEmits(["refetch"]);

const layoutStore = useWorkspaceLayoutStore();
const { workspaceLayout } = storeToRefs(layoutStore);

const dataFieldLocalId = computed(() => _.get(props.dataField, "localId"));
const triggeringSpaceUsages = ref([]);
const triggeringUsagesLoaded = ref(false);
const fulfillingSpaceUsages = ref([]);
const fulfillingUsagesLoaded = ref(false);

watch(dataFieldLocalId, () => {
  if (props.dataField) fetchSpaceUsages();
});

onMounted(() => fetchSpaceUsages());

function fetchSpaceUsages() {
  fetchFulfillingSpaceUsages();
  fetchTriggeringSpaceUsages();
}

function fetchFulfillingSpaceUsages() {
  api
    .get(
      `crowdsourced_data_fields/${props.dataField.fieldContentType}/${props.dataField.fieldContentId}?field_name=SpaceUsage&field_sub_type=hunt_fulfiller&template=details_page`,
    )
    .then((json) => {
      fulfillingSpaceUsages.value = json.data;
      fulfillingUsagesLoaded.value = true;
    });
}

function fetchTriggeringSpaceUsages() {
  api
    .get(
      `crowdsourced_data_fields/${props.dataField.fieldContentType}/${props.dataField.fieldContentId}?field_name=SpaceUsage&field_sub_type=hunt_trigger&template=details_page`,
    )
    .then((json) => {
      triggeringSpaceUsages.value = json.data;
      triggeringUsagesLoaded.value = true;
    });
}

function refetch() {
  fetchSpaceUsages();
  // emit("refetch");
}
</script>
