<template>
  <div class="bg-white overflow-hidden">
    <div class="p-2">
      <dl class="grid grid-cols-1 gap-x-4 gap-y-8">
        <div class="col-span-1">
          <dt class="text-sm font-medium text-gray-500">
            Target Occupancy Date
          </dt>
          <dd class="mt-2">
            <EditableDataField
              v-bind="
                _.find(availableProps, {
                  fieldName: 'target_occupancy_date',
                })
              "
              @set-proof="setProof"
              @completed="refetch"
              @dismiss="refetch"
              @open-sourced="refetch"
            />
          </dd>
        </div>
      </dl>
    </div>
  </div>
</template>

<script setup>
import EditableDataField from "@/components/crowdsourcing/EditableDataField.vue";
import { useProveForValueStore } from "@/stores/proveForValue";
import { computed } from "vue";
import _ from "lodash";

const props = defineProps(["dataField", "contentDataField", "context"]);
const emit = defineEmits(["refetch"]);

const proveForValueStore = useProveForValueStore();

const availableProps = computed(() => {
  return [
    {
      fieldName: "target_occupancy_date",
      textClasses: "text-sm",
      decoratingContentDataField: props.dataField,
      isDate: true,
      optional: true,
      dismissOnSave: true,
    },
  ];
});

function setProof(fieldName) {
  const matchedProps = _.find(availableProps.value, { fieldName });

  if (matchedProps) {
    proveForValueStore.setProofComponent(EditableDataField, matchedProps);
  }
}

function refetch() {
  emit("refetch");
}
</script>
