<template>
  <SwitchGroup as="div" class="flex pt-6">
    <SwitchLabel
      as="dt"
      class="text-sm flex items-center space-x-1 pr-6 font-medium text-gray-500"
      :class="workspaceLayout === 'sideBySide' ? '' : 'w-64'"
      passive
      ><EnvelopeIcon class="h-4 w-4 text-gray-500" />
      <span
        v-tooltip="
          'Turn on to receive a summary of unread newsfeed cards every Monday morning'
        "
        >Weekly News update</span
      ></SwitchLabel
    >
    <dd class="flex flex-auto items-center justify-end">
      <Switch
        v-model="digestConsent"
        :class="[
          digestConsent ? 'bg-indigo-600' : 'bg-gray-200',
          'flex w-8 cursor-pointer rounded-full p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600',
        ]"
      >
        <span
          aria-hidden="true"
          :class="[
            digestConsent ? 'translate-x-3.5' : 'translate-x-0',
            'h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-gray-900/5 transition duration-200 ease-in-out',
          ]"
        />
      </Switch>
    </dd>
  </SwitchGroup>
</template>

<script setup>
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { useUserStore } from "@/stores/user";
import { Switch, SwitchGroup, SwitchLabel } from "@headlessui/vue";

import { storeToRefs } from "pinia";
import { EnvelopeIcon } from "@heroicons/vue/24/outline";

const layoutStore = useWorkspaceLayoutStore();
const { workspaceLayout } = storeToRefs(layoutStore);
const userStore = useUserStore();
const { digestConsent } = storeToRefs(userStore);
</script>
