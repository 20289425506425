import { defineStore, acceptHMRUpdate } from "pinia";
import { ref } from "vue";
import api from "@/router/api";
import _ from "lodash";

export const usePropertyFieldsStore = defineStore("propertyFields", () => {
  const propertyFields = ref([]);

  async function fetchPropertyDataField(propertyId) {
    const existingField = getPropertyField(propertyId);

    if (existingField) return existingField;
    else {
      api.get(`properties/${propertyId}`).then((json) => {
        patchPropertyDataFields([json.data]);
      });
    }
  }

  function patchPropertyDataFields(newFields) {
    const filtered = _.compact(newFields).filter(
      (dataField) => dataField.fieldContentType === "Property",
    );
    propertyFields.value = _.unionBy(
      filtered,
      propertyFields.value,
      "fieldContentId",
    );
  }

  function getPropertyField(id) {
    return _.find(propertyFields.value, { fieldContentId: id });
  }

  return {
    propertyFields,
    fetchPropertyDataField,
    getPropertyField,
    patchPropertyDataFields,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(usePropertyFieldsStore, import.meta.hot),
  );
}
