<template>
  <section class="overflow-hidden">
    <ContentHeader :help-article="publicDatapointFlow">
      <template v-slot:title>My Unpublished Contributions</template>
      <template v-slot:description>Data that you're working on.</template>
    </ContentHeader>
    <div v-if="changes.pagy" class="mt-4 px-2 flex flex-col">
      <div v-if="reputable === false" class="rounded-md bg-red-50 p-3 mb-4">
        <div class="flex">
          <div class="flex-shrink-0">
            <XCircleIcon class="h-5 w-5 text-red-400" aria-hidden="true" />
          </div>
          <div class="ml-3 flex-1 md:flex md:justify-between">
            <p class="text-sm text-red-700">
              Your reputation is preventing you from publishing.
            </p>
            <p class="mt-3 text-sm md:ml-6 md:mt-0">
              <a
                href=""
                @click.prevent="viewContributions"
                class="whitespace-nowrap font-medium text-red-700 hover:text-red-600"
              >
                Details
                <span aria-hidden="true"> &rarr;</span>
              </a>
            </p>
          </div>
        </div>
      </div>

      <div v-if="changes.data.length > 0" class="-my-2 -mx-4 overflow-x-auto">
        <div class="inline-block min-w-full py-2 align-middle px-4">
          <div
            class="relative overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-lg"
          >
            <!-- Selected row actions, only show when rows are selected. -->
            <div
              v-if="
                (reputable && includesStaked) ||
                includesUnstaked ||
                selectionLength > 0
              "
              class="absolute top-0 left-12 flex h-12 items-center space-x-3 bg-gray-50"
            >
              <button
                v-if="includesUnstaked"
                @click="stake"
                :disabled="actionInProgress"
                type="button"
                class="inline-flex items-center rounded border border-transparent bg-pink-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-pink-500 focus:ring-offset-2"
                data-test="pending-validations-stake-button"
              >
                <PulseLoader
                  v-if="actionInProgress"
                  :loading="true"
                  size="4px"
                  color="#f9fafb"
                />
                <span v-else>Stake credits</span>
              </button>
              <button
                v-if="selectionLength > 0 && includesStaked && reputable"
                @click="publish"
                :disabled="actionInProgress"
                type="button"
                class="inline-flex items-center rounded border border-transparent bg-emerald-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2"
                data-test="pending-validations-publish-button"
              >
                <PulseLoader
                  v-if="actionInProgress"
                  :loading="true"
                  size="4px"
                  color="#f9fafb"
                />
                <span v-else>Publish</span>
              </button>
              <CitationForm
                v-if="selectionLength > 0 && !includesCitations"
                :change-ids="activeSelection"
                class="flex items-center"
                @refetch="fetchChanges"
              >
                <template v-slot:button>
                  <button
                    type="button"
                    class="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30"
                    data-test="pending-validations-bulk-add-citation-button"
                  >
                    Add citation
                  </button>
                </template>
              </CitationForm>
              <button
                v-if="selectionLength > 0 && !includesCitations"
                @click="addToCitationStage"
                type="button"
                class="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30"
                data-test="pending-validations-bulk-add-citation-button"
              >
                Add to Citation Stage
              </button>
            </div>

            <table class="min-w-full table-fixed divide-y divide-gray-300">
              <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="relative w-12 px-4">
                    <input
                      @click="toggleOverall"
                      id="pending-validations-overall"
                      ref="pendingValidationsOverall"
                      type="checkbox"
                      class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                      data-test="pending-validations-toggle-overall-selection-button"
                    />
                  </th>
                  <th
                    scope="col"
                    class="py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
                  >
                    Belongs To
                  </th>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Datapoint
                  </th>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Group
                  </th>
                  <th
                    scope="col"
                    class="pl-3 pr-4 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Status
                  </th>
                </tr>
              </thead>
              <tbody
                v-if="rowsDisplayable"
                class="divide-y divide-gray-200 bg-white"
                data-test="unpublished-changes-table-body"
              >
                <PendingChangeTableRow
                  v-for="(change, index) in changes.data"
                  :key="change.id"
                  :change-id="change.id"
                  :change-type="change.changeType"
                  :data-field-id="change.dataFieldId"
                  :selected="selected(change.id)"
                  :index="index"
                  :data-test="`unpublished-change-table-row-${index}`"
                  @unselect="unselect(change.id)"
                  @select="select(change.id)"
                  @attach-field="attachField"
                  @select-decorating="selectDecorating"
                  @select-group="selectGroup"
                  @select-publishable="selectPublishable"
                  @dismissed="drop(change)"
                />
              </tbody>
              <PaginationFooter
                v-if="changes.pagy.prev || changes.pagy.next"
                :pagination-meta="changes.pagy"
                :table-meta="{ colspan: 5 }"
                @previous="fetchChanges"
                @next="fetchChanges"
                @page="fetchChanges"
                @infinite-fetch="loadChanges"
              />
            </table>
          </div>
        </div>
      </div>
      <div
        v-else
        class="text-center"
        data-test="unpublished-changes-empty-state"
      >
        <ShieldExclamationIcon class="mx-auto h-12 w-12 text-gray-400" />
        <h3 class="mt-2 text-sm font-medium text-gray-900">No data</h3>
        <p class="mt-1 text-sm text-gray-500">
          Your unpublished changes will appear here.
        </p>
      </div>
    </div>
  </section>
</template>

<script setup>
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import ContentHeader from "@/components/main-layout/secondary-panel/ContentHeader.vue";
import CitationForm from "@/components/crowdsourcing/CitationForm.vue";
import PendingChangeTableRow from "@/components/crowdsourcing/PendingChangeTableRow.vue";
import SubscribeVue from "@/components/users/SubscribeVue.vue";
import _ from "lodash";
import { onMounted, ref, computed, watch, markRaw } from "vue";
import { usePendingValidationsChannelStore } from "@/stores/pendingValidationsChannel";
import { usePropertyDiagramStore } from "@/stores/propertyDiagram";
import { useDealBuilderStore } from "@/stores/dealBuilder";
import { useCitationStore } from "@/stores/citation";
import { storeToRefs } from "pinia";
import { useModalStore } from "@/stores/modal";
import { useUserStore } from "@/stores/user";
import api from "@/router/api";
import { XCircleIcon } from "@heroicons/vue/20/solid";
import { ShieldExclamationIcon } from "@heroicons/vue/24/outline";
import PaginationFooter from "@/components/PaginationFooter.vue";
import { publicDatapointFlow } from "@/assets/documentation/articles/publicDatapointFlow";
import { useRoute, useRouter } from "vue-router";

const userStore = useUserStore();
const { reputable } = storeToRefs(userStore);
const propertyDiagramStore = usePropertyDiagramStore();
const { propertyIdParam } = storeToRefs(propertyDiagramStore);
const dealBuilderStore = useDealBuilderStore();
const { dealBuilder } = storeToRefs(dealBuilderStore);
const citationStore = useCitationStore();

const changes = ref({
  data: [],
  pagy: null,
});
const activeSelection = ref([]);
const rowsDisplayable = ref(false);
const actionInProgress = ref(false);

const refetchNeeded = ref(false);
const selectionLength = computed(() => {
  return activeSelection.value.length;
});
const allSelected = computed(() => {
  return (
    changes.value.pagy && changes.value.data.length === selectionLength.value
  );
});
const indeterminate = computed(() => {
  return selectionLength.value > 0 && !allSelected.value;
});
const includesUnstaked = computed(() => {
  return _.some(changes.value.data, function (change) {
    return _.get(change, "dataField.state") === "unstaked";
  });
});
const includesStaked = computed(() => {
  return _.some(changes.value.data, function (change) {
    return _.get(change, "dataField.state") === "staked";
  });
});
const includesCitations = computed(() => {
  const fields = activeSelection.value.map((changeId) => {
    const change = _.find(changes.value.data, {
      id: changeId,
    });

    return change?.dataField;
  });
  return _.some(_.compact(fields), function (field) {
    return (
      field.decoratingContentType === "DataFieldCitation" ||
      field.fieldContentType === "CitationChangeGroup"
    );
  });
});

const modalStore = useModalStore();
const { modalPayload } = storeToRefs(modalStore);
const pendingValidationsChannelStore = usePendingValidationsChannelStore();
const { pendingValidationsChannelDataQueue } = storeToRefs(
  pendingValidationsChannelStore,
);

watch(selectionLength, () => {
  updateOverallCheckbox();

  if (selectionLength.value === 0 && refetchNeeded.value) {
    refetchNeeded.value = false;
    fetchChanges();
  }
});

watch(pendingValidationsChannelDataQueue, () => {
  if (selectionLength.value === 0) {
    fetchChanges();
  } else {
    refetchNeeded.value = true;
  }
});

onMounted(() => {
  fetchChanges();
});

const router = useRouter();
const route = useRoute();

function viewContributions() {
  router.push({
    name: route.name,
    query: {
      ...route.query,
      horizontalTab: "Me",
      verticalTab: "Contributions",
    },
  });
}

async function fetchChanges(paginationUrl = null) {
  changes.value = {
    data: [],
    pagy: null,
  };
  activeSelection.value = [];
  rowsDisplayable.value = false;

  api.get(paginationUrl || `unpublished_validations`).then((json) => {
    changes.value = json.data;
    rowsDisplayable.value = true;
    actionInProgress.value = false;
  });
}

function cleanUrl(url) {
  return url.replace("/api/v1/", "");
}
const loadChanges = async ($state) => {
  if (changes.value.pagy?.next) {
    const endpoint = cleanUrl(changes.value.pagy?.next_url);
    try {
      api.get(endpoint).then((json) => {
        const { data, pagy } = json.data;

        changes.value.data = _.uniqBy(_.concat(changes.value.data, data), "id");
        changes.value.pagy = pagy;
        if (data.length < 10) $state.complete();
        else {
          $state.loaded();
        }
      });
    } catch (error) {
      $state.error();
    }
  } else {
    $state.complete();
  }
};

function stake() {
  if (!actionInProgress.value) {
    actionInProgress.value = true;
    modalPayload.value = {
      size: "base",
      theme: "light",
      component: markRaw(SubscribeVue),
      props: {
        newPlan: "tracker",
        billingFrequency: "monthly",
        promptReason: "stakeShortfall",
        context: "originating",
      },
      afterClose: fetchChanges,
      afterCloseDestination: null,
    };
  }
}

function refreshStores(maybeJson) {
  if (maybeJson) {
    if (propertyIdParam.value) {
      propertyDiagramStore.postEditingPatch(maybeJson);
    } else if (dealBuilder.value) {
      dealBuilderStore.postEditingPatch(maybeJson);
    }
  } else {
    if (dealBuilder.value) {
      dealBuilderStore.clearDealBuilder(false);
    }
  }
}

function publish() {
  if (!actionInProgress.value) {
    const payload = {
      ids: activeSelection.value,
    };

    actionInProgress.value = true;

    api.post(`publish_datapoints`, payload).then((json) => {
      if (json.data) {
        refreshStores(json);
      }
      changes.value = {
        data: [],
        pagy: null,
      };
      activeSelection.value = [];
      fetchChanges();
    });
  }
}

function drop(change) {
  const newChanges = _.differenceBy(changes.value.data, [change], "id");

  changes.value.data = newChanges;
}
function selected(id) {
  return _.includes(activeSelection.value, id);
}
function unselect(id) {
  const newSelection = _.difference(activeSelection.value, [id]);

  activeSelection.value = newSelection;
}
function select(id) {
  const newSelection = _.union(activeSelection.value, [id]);

  activeSelection.value = newSelection;
}
function attachField(dataField) {
  const oldChange = _.find(changes.value.data, {
    dataFieldId: dataField.localId,
  });

  if (oldChange) {
    const newChange = _.merge({}, oldChange, { dataField });
    const newChanges = changes.value.data.map((change) => {
      if (change.dataFieldId === newChange.dataFieldId) {
        return newChange;
      } else {
        return change;
      }
    });

    changes.value.data = newChanges;
  }
}
function selectDecorating({ contentType, contentId }) {
  const filtered = changes.value.data.filter((c) => {
    return (
      _.get(c, "dataField.decoratingContentType") === contentType &&
      _.get(c, "dataField.decoratingContentId") === contentId
    );
  });

  activeSelection.value = filtered.map((c) => c.id);
}
function selectGroup({ groupId }) {
  const filtered = changes.value.data.filter((c) => {
    return _.get(c, "dataField.latestChangeGroupId") === groupId;
  });

  activeSelection.value = filtered.map((c) => c.id);
}
function selectPublishable() {
  const filtered = changes.value.data.filter((c) => {
    return _.get(c, "dataField.state") === "staked";
  });

  activeSelection.value = filtered.map((c) => c.id);
}
function toggleOverall() {
  if (indeterminate.value || allSelected.value) {
    activeSelection.value = [];
  } else {
    activeSelection.value = changes.value.data.map((c) => c.id);
  }
}
function updateOverallCheckbox() {
  const overall = document.querySelector("#pending-validations-overall");

  if (!overall) {
    return;
  }

  if (selectionLength.value === 0) {
    overall.checked = false;
    overall.indeterminate = false;
  } else if (allSelected.value) {
    overall.checked = true;
    overall.indeterminate = false;
  } else {
    overall.checked = false;
    overall.indeterminate = true;
  }
}
function addToCitationStage() {
  const dataFields = activeSelection.value.map((id) => {
    return _.find(changes.value.data, { id })?.dataField;
  });

  citationStore.addCitationFields(_.compact(dataFields));
  activeSelection.value = [];
}
</script>
