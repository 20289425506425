<template>
  <div class="">
    <div class="mt-2 flex flex-col space-y-2">
      <div class="text-sm font-medium text-gray-500">Cost and Timing</div>
      <TenantImprovementAllowanceDefinition
        :date-fields="dateFields"
        :per-area-size="perAreaSize"
      />
    </div>

    <div class="mt-2 flex items-center justify-between">
      <div
        v-if="commencedDate || allowanceDate"
        class="flex items-center space-x-1 text-sm font-semibold text-gray-700"
      >
        <span v-if="commencedDate">LCD: {{ commencedDate }}</span>
        <span v-if="commencedDate && allowanceDate">&middot;</span>
        <span v-if="allowanceDate">TIA Timing: {{ allowanceDate }}</span>
        <span v-if="(commencedDate || allowanceDate) && allowanceAmount"
          >&middot;</span
        >
        <span v-if="allowanceAmount">TIA Cost: {{ allowanceAmount }}</span>
      </div>
      <div v-else />
      <div class="flex items-center space-x-2">
        <button
          @click="emit('cancel')"
          type="button"
          class="inline-flex items-center p-1 border border-gray-300 rounded-full shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
        >
          <XMarkIcon class="h-4 w-4" />
        </button>
        <DataVisibilityButton
          v-if="savePayload"
          visibility="safezone"
          tooltip="Save"
          class="inline-flex"
        >
          <template v-slot:button>
            <button
              @click="save"
              :disabled="originatingData"
              type="button"
              class="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm bg-yellow-500 hover:bg-yellow-600 focus:ring-yellow-600 text-white focus:outline-none focus:ring-2 focus:ring-offset-2"
            >
              <PulseLoader
                v-if="originatingData"
                :loading="true"
                size="3px"
                color="#f3f4f6"
              />
              <CheckIcon v-else class="h-4 w-4" />
            </button>
          </template>
        </DataVisibilityButton>
      </div>
    </div>
  </div>
</template>

<script setup>
import { XMarkIcon, CheckIcon } from "@heroicons/vue/20/solid";
import DataVisibilityButton from "@/components/crowdsourcing/DataVisibilityButton.vue";
import TenantImprovementAllowanceDefinition from "@/components/crowdsourcing/TenantImprovementAllowanceDefinition.vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { useCashflowDefinitionStore } from "@/stores/cashflowDefinition";
import { useCrowdsourcedChangeGroupStore } from "@/stores/crowdsourcedChangeGroup";
import { useTimeTravelStore } from "@/stores/timeTravel";
import { currencyAmount } from "@/assets/numberHelpers";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import api from "@/router/api";
import _ from "lodash";
import moment from "moment";

const props = defineProps(["dataField", "dateFields", "perAreaSize"]);
const emit = defineEmits(["refetch", "cancel"]);

const cashflowDefinitionStore = useCashflowDefinitionStore();
const { savePayload, tenantImprovementAllowance } = storeToRefs(
  cashflowDefinitionStore,
);
const changeGroupStore = useCrowdsourcedChangeGroupStore();
const { originatingData, changeGroupId } = storeToRefs(changeGroupStore);
const timeTravelStore = useTimeTravelStore();

const commencedDate = computed(() => {
  if (props.dateFields.commenced_date?.fieldDate) {
    return formattedDate("commenced_date");
  } else {
    return null;
  }
});
const allowanceDate = computed(() => {
  if (!_.isNil(tenantImprovementAllowance.value?.date)) {
    switch (tenantImprovementAllowance.value.dateType) {
      case "Relative": {
        if (commencedDate.value) {
          return moment(props.dateFields["commenced_date"].fieldDate)
            .add(tenantImprovementAllowance.value.date, "months")
            .format("MMM D, YYYY");
        } else {
          return `Month ${tenantImprovementAllowance.value.date} (from LCD)`;
        }
      }
      case "Actual":
        return moment(tenantImprovementAllowance.value.date).format(
          "MMM D, YYYY",
        );
      default:
        return null;
    }
  } else {
    return null;
  }
});
const allowanceAmount = computed(() => {
  if (!_.isNil(tenantImprovementAllowance.value?.inputValue)) {
    switch (tenantImprovementAllowance.value.inputType) {
      case "annual":
        return `+/- $${currencyAmount(
          tenantImprovementAllowance.value.inputValue,
          0,
          -4,
        )}`;
      case "annualPerArea": {
        if (props.perAreaSize) {
          return `+/- $${currencyAmount(
            tenantImprovementAllowance.value.inputValue * props.perAreaSize,
            0,
            -4,
          )}`;
        } else {
          return `$${currencyAmount(
            tenantImprovementAllowance.value.inputValue,
            0,
          )}/SF`;
        }
      }
      default:
        return null;
    }
  } else {
    return null;
  }
});

function formattedDate(dateName) {
  return moment(props.dateFields[dateName].fieldDate).format("MMM D, YYYY");
}

function cancel() {
  cashflowDefinitionStore.reset();
  emit("cancel");
}

function persist() {
  return api.post(
    `cashflow_schedules`,
    _.merge({}, savePayload.value, {
      contentType: props.dataField.fieldContentType,
      contentId: props.dataField.fieldContentId,
      name: "Tenant Improvement Allowance",
      scheduleType: "tenantImprovementAllowance",
      reportingGroup: "capital_expenditures",
      changeGroupId: changeGroupId.value,
    }),
  );
}
function afterPersist() {
  cashflowDefinitionStore.reset();
  // timeTravelStore.triggerRefetch();
  emit("refetch");
}
async function save() {
  if (savePayload.value) {
    const apiRequestFunc = () => persist();
    const successCallback = (json) => afterPersist(json);
    const failureCallback = () => cancel();
    await changeGroupStore.originateData(
      apiRequestFunc,
      successCallback,
      failureCallback,
    );
  }
}
</script>
