<template>
  <div class="p-2 bg-white overflow-y-auto h-full">
    <div v-if="hasPortfolio" class="flex flex-col">
      <dt class="text-sm font-medium text-gray-500">
        Transaction belongs to Portfolio {{ portfolioId }}
      </dt>
      <dd class="mt-2 space-y-2">
        <button
          @click="openPortfolio"
          type="button"
          data-test="open-portfolio-button"
          class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <PlusIcon class="-ml-1 mr-2 h-5 w-5" />
          Open Portfolio
        </button>
      </dd>
    </div>

    <!-- EMPTY STATE -->
    <div v-else class="text-center">
      <svg
        class="mx-auto h-12 w-12 text-gray-400"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          vector-effect="non-scaling-stroke"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
        />
      </svg>
      <h3 class="mt-2 text-sm font-medium text-gray-900">No portfolio</h3>
      <p class="mt-1 text-sm text-gray-500">
        Create a new portfolio containing this and other deals.
      </p>
      <div class="mt-6">
        <VDropdown v-if="investmentGroups.length > 0">
          <button
            type="button"
            data-test="select-portfolio-add-button"
            class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <PlusIcon class="-ml-1 mr-2 h-5 w-5" />
            Add to...
          </button>

          <template #popper>
            <div
              class="w-64 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="menu-button"
              tabindex="-1"
            >
              <div class="py-1" role="none">
                <a
                  v-for="(group, index) in investmentGroups"
                  :key="index"
                  @click.prevent="addToPortfolio(group)"
                  href=""
                  class="text-gray-700 hover:bg-gray-100 hover:text-gray-900 group flex items-center px-4 py-2 text-sm"
                  role="menuitem"
                  tabindex="-1"
                  id="menu-item-1"
                  :data-test="`add-to-portfolio-${group.placeholderId}-button`"
                >
                  Add to{{
                    _.includes(group.placeholderId, "temp") ? " Draft " : " "
                  }}Portfolio{{
                    _.includes(group.placeholderId, "temp")
                      ? ""
                      : group.placeholderId
                  }}:
                  {{ pluralize("Asset", investmentsLength(group), true) }}
                  <template v-if="group.dealAction"
                    >&nbsp;({{ group.dealAction }})</template
                  >
                </a>
                <a
                  @click.prevent="newPortfolio"
                  href=""
                  class="text-gray-700 hover:bg-gray-100 hover:text-gray-900 group flex items-center px-4 py-2 text-sm"
                  role="menuitem"
                  tabindex="-1"
                  id="menu-item-0"
                  data-test="add-new-portfolio-button"
                >
                  New Portfolio
                </a>
              </div>
            </div>
          </template>
        </VDropdown>

        <DataVisibilityButton v-else visibility="safezone" class="inline-flex">
          <template v-slot:button>
            <button
              @click="newPortfolio"
              type="button"
              data-test="add-new-portfolio-button"
              class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-500 hover:bg-yellow-600 focus:ring-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2"
            >
              <PlusIcon class="-ml-1 mr-2 h-5 w-5" />
              New Portfolio
            </button>
          </template>
        </DataVisibilityButton>
      </div>
    </div>
  </div>
</template>

<script setup>
import { PlusIcon } from "@heroicons/vue/20/solid";
import { useDealBuilderStore } from "@/stores/dealBuilder";
import { storeToRefs } from "pinia";
import { computed, nextTick } from "vue";
import _ from "lodash";
import VueScrollTo from "vue-scrollto";
import pluralize from "pluralize";
import DataVisibilityButton from "@/components/crowdsourcing/DataVisibilityButton.vue";

const props = defineProps(["assetObject", "investment"]);

const dealBuilderStore = useDealBuilderStore();
const { dealBuilder } = storeToRefs(dealBuilderStore);

const investmentGroups = computed(() => {
  return _.get(dealBuilder.value, "investmentGroups", []);
});
const ownershipInterestIds = computed(() => {
  return props.assetObject.ownershipInterests.map(
    (dataField) => dataField.fieldContentId,
  );
});
const hasPortfolio = computed(() => {
  return !!portfolioId.value;
});
const portfolioId = computed(() => {
  return (
    _.get(props.investment, "portfolioId") ||
    _.get(props.investment, "existingInvestment.portfolioId") ||
    _.get(matchingInvestmentGroup.value, "placeholderId")
  );
});
const portfolioDealAction = computed(() => {
  return (
    _.get(props.investment, "portfolioDealAction") ||
    _.get(props.investment, "existingInvestment.portfolioDealAction")
  );
});
const matchingInvestmentGroup = computed(() => {
  return _.find(
    _.get(dealBuilder.value, "investmentGroups", []),
    function (group) {
      return _.find(group.investments, function (investment) {
        const localInvestmentId =
          _.get(props.investment, "existingInvestment.id") ||
          _.get(props.investment, "id") ||
          _.get(props.investment, "temporaryId");

        return _.get(investment, "existingInvestment.id") === localInvestmentId;
      });
    },
  );
});

function investmentsLength(investmentGroup) {
  return _.size(investmentGroup.investments);
}
function newPortfolio() {
  dealBuilderStore.addDealBuilderInvestmentGroup({
    existingInvestment: props.investment,
    assetDataField: props.assetObject.dataField,
    investmentFieldContent: props.investment.asset,
    ownershipInterestIds: ownershipInterestIds.value,
    dealAction: props.investment.dealAction,
  });

  if (matchingInvestmentGroup.value) {
    dealBuilderStore.collapsePortfolioAssets({
      groupId:
        matchingInvestmentGroup.value.id ||
        matchingInvestmentGroup.value.placeholderId,
    });
  }
}
function addToPortfolio(investmentGroup) {
  dealBuilderStore.addDealBuilderInvestmentToGroup({
    existingGroupId: investmentGroup.id,
    existingInvestment: props.investment,
    assetDataField: props.assetObject.dataField,
    investmentFieldContent: props.investment.asset,
    ownershipInterestIds: ownershipInterestIds.value,
    investmentGroupPlaceholderId: investmentGroup.placeholderId,
  });
  focusInvestmentGroup(investmentGroup);
}
function focusInvestmentGroup(investmentGroup) {
  dealBuilderStore.collapsePortfolioAssets({
    groupId: investmentGroup.id || investmentGroup.placeholderId,
  });
  dealBuilderStore.setDealBuilderInvestmentGroupExpanded({
    groupId: investmentGroup.id || investmentGroup.placeholderId,
    expanded: true,
  });
}
async function openPortfolio() {
  if (matchingInvestmentGroup.value) {
    focusInvestmentGroup(matchingInvestmentGroup.value);
  } else {
    dealBuilderStore.addDealBuilderInvestmentGroup({
      existingGroupId: portfolioId.value,
      dealAction: portfolioDealAction.value,
    });
  }

  dealBuilderStore.collapseAllAssetsExcept({ assetKey: null });
  await nextTick();
  VueScrollTo.scrollTo("#investment-portfolios-list", 100, {
    container: "#lists-panel-container",
  });
}
</script>
