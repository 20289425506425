<template>
  <div
    name="floor-area-unit"
    class="flex w-full h-full"
    :class="floorAreaBackgroundStyling"
  >
    <div class="w-full h-full flex flex-col">
      <div class="text-xs uppercase tracking-wide" />
      <div class="w-full flex flex-grow justify-between">
        <div
          class="flex space-x-1"
          v-if="
            innerContentShouldDisplay &&
            (floorArea.internalPropertyRight ||
              !floorArea.standardizedArea ||
              floorArea.minimumSubdivisionStandardizedArea ||
              floorArea.dataField.fieldContent?.isAvailable ||
              floorArea.dataField.fieldContent?.isVacating ||
              floorArea.dataField.fieldContent?.isDark ||
              floorArea.dataField.fieldContent?.isHunting)
          "
        >
          <button
            v-if="!floorArea.standardizedArea"
            @click="viewFloorAreaSizes"
            v-tooltip="'Floor area has no standardized area (SF)'"
            class="m-0.5 h-3 w-3 text-red-700"
          >
            <i class="fa-solid fa-ruler-horizontal" />
          </button>
          <button
            v-if="floorArea.minimumSubdivisionStandardizedArea"
            @click="viewFloorAreaSizes"
            v-tooltip="'Subdividable'"
            class="m-0.5 h-3 w-3 text-[.9rem] text-blue-700"
          >
            <i class="fa-solid fa-scissors" />
          </button>
          <button
            v-if="floorArea.dataField.fieldContent?.isDark"
            @click="upsertSpaceUsageBuilder"
            v-tooltip="'Space is dark'"
            class="m-0.5 inline-flex items-center justify-center h-4 w-4"
          >
            <LightBulbIcon class="h-4 w-4 text-gray-800" />
          </button>
          <button
            v-else-if="floorArea.dataField.fieldContent?.isVacating"
            @click="upsertSpaceUsageBuilder"
            v-tooltip="'User is vacating'"
            class="m-0.5 inline-flex items-center justify-center h-4 w-4"
          >
            <TruckIcon class="h-4 w-4 text-red-600" />
          </button>
          <button
            v-if="floorArea.dataField.fieldContent?.isAvailable"
            @click="upsertSpaceUsageBuilder"
            v-tooltip="'Available'"
            class="m-0.5 inline-flex items-center justify-center h-3 w-3 rounded-full bg-green-500"
          />
          <button
            v-if="floorArea.dataField.fieldContent?.isHunting"
            @click="upsertSpaceUsageBuilder"
            v-tooltip="'Tenant in the Market'"
            class="m-0.5 h-3 w-3 text-[.9rem] text-red-600"
          >
            <i class="fas fa-crosshairs" />
          </button>
          <div
            v-if="floorArea.internalPropertyRight"
            :data-test="`${testKey}-${floorArea.internalPropertyRight.fieldContent.type}`"
          >
            <CubeIcon
              v-observe-visibility="{
                callback: (isVisible, entry) =>
                  internalRightTimelineSelectionHandler(
                    isVisible,
                    entry,
                    floorArea.internalPropertyRight,
                  ),
                once: true,
              }"
              @click.stop="
                emit(
                  'internal-property-right-click',
                  floorArea.internalPropertyRight,
                )
              "
              v-tooltip="
                `${floorArea.internalPropertyRight.fieldContent.type} ${floorArea.internalPropertyRight.fieldContentId}`
              "
              :class="`h-3 w-3 rounded-sm ${internalPropertyRightBackgroundStyling}`"
            />
          </div>
        </div>
        <ul class="flex space-x-1">
          <VMenu
            theme="diagram-popup"
            :disabled="editingMode"
            v-for="enhancement in enhancements"
            :key="enhancement.localId"
            :data-test="`${testKey}-enhancement-${enhancement.fieldContentId}`"
            class="h-3 w-3"
          >
            <SparklesIcon
              @click.stop="emit('property-enhancement-click', enhancement)"
              v-observe-visibility="{
                callback: (isVisible, entry) =>
                  propertyEnhancementTimelineSelectionHandler(
                    isVisible,
                    entry,
                    enhancement,
                  ),
                once: true,
              }"
              :class="`h-3 w-3 rounded-sm ${propertyEnhancementColorStyle(
                enhancement,
                propertyDiagramSelected,
              )}`"
            />
            <template #popper>
              <DataFieldInfoPopup :data-field="enhancement" />
            </template>
          </VMenu>
        </ul>
      </div>
      <div class="w-full flex justify-between">
        <ul class="p-0.5 flex space-x-1">
          <VMenu
            theme="diagram-popup"
            :disabled="editingMode"
            v-for="layout in floorAreaLayouts"
            :key="layout.localId"
            :data-test="`${testKey}-layout-${layout.fieldContentId}`"
          >
            <QrCodeIcon
              @click.stop="emit('floor-area-layout-click', layout)"
              :class="`h-3 w-3 rounded-sm ${floorAreaLayoutColorStyle(
                layout,
                propertyDiagramSelected,
              )}`"
            />
            <template #popper>
              <DataFieldInfoPopup :data-field="layout" />
            </template>
          </VMenu>
        </ul>
        <div
          :data-test="verticallyOpen ? 'vertically-open-floor-area-icon' : ''"
        >
          <ChevronDoubleDownIcon
            v-if="verticallyOpen"
            v-tooltip="'Open to space below'"
            class="`h-3 w-3 rounded-sm text-gray-800"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import DataFieldInfoPopup from "@/components/crowdsourcing/DataFieldInfoPopup.vue";
import floorAreaLayoutColorStyle from "@/components/property-diagram/floorAreaLayoutColorStyles";
import propertyEnhancementColorStyle from "@/components/property-diagram/propertyEnhancementColorStyles";
import { usePropertyDiagramStore } from "@/stores/propertyDiagram";
import { useDealBuilderStore } from "@/stores/dealBuilder";
import { useSpaceUsageBuilderStore } from "@/stores/spaceUsageBuilder";
import { storeToRefs } from "pinia";
import {
  ChevronDoubleDownIcon,
  CubeIcon,
  QrCodeIcon,
  SparklesIcon,
  TruckIcon,
} from "@heroicons/vue/20/solid";
import { useRoute, useRouter } from "vue-router";
import { nextTick } from "vue";
import { LightBulbIcon } from "@heroicons/vue/24/outline";

const props = defineProps([
  "floorArea",
  "innerContentShouldDisplay",
  "floorAreaLayouts",
  "enhancements",
  "floorAreaBackgroundStyling",
  "internalPropertyRightBackgroundStyling",
  "verticallyOpen",
  "testKey",
]);
const emit = defineEmits([
  "clicked",
  "refetch",
  "property-enhancement-click",
  "floor-area-layout-click",
  "internal-property-right-click",
]);
const propertyDiagramStore = usePropertyDiagramStore();
const { propertyDiagramSelected, selectedTimelineEvent, editingMode } =
  storeToRefs(propertyDiagramStore);
const dealBuilderStore = useDealBuilderStore();
const spaceUsageBuilderStore = useSpaceUsageBuilderStore();

function propertyEnhancementTimelineSelectionHandler(
  isVisible,
  entry,
  enhancement,
) {
  if (!isVisible) return;
  if (!selectedTimelineEvent.value) return;

  const timelineContentType =
    selectedTimelineEvent.value.dataField.decoratingContentType;
  const timelineContentId =
    selectedTimelineEvent.value.dataField.decoratingContentId;

  if (
    timelineContentType === "PropertyEnhancement" &&
    timelineContentId === enhancement.fieldContentId
  ) {
    emit("property-enhancement-click", enhancement);
  }
}

function internalRightTimelineSelectionHandler(
  isVisible,
  entry,
  internalRight,
) {
  if (!isVisible) return;
  if (!selectedTimelineEvent.value) return;

  if (selectedTimelineEvent.value.eventType === "Investment") {
    dealBuilderStore.loadPropertyRightTimelineInvestment({
      propertyRightDataField: internalRight,
    });
  } else {
    const timelineContentType =
      selectedTimelineEvent.value.dataField.decoratingContentType;
    const timelineContentId =
      selectedTimelineEvent.value.dataField.decoratingContentId;

    if (
      timelineContentType === "PropertyRight" &&
      timelineContentId === internalRight.fieldContentId
    ) {
      emit("internal-property-right-click", internalRight);
    }
  }
}

function upsertSpaceUsageBuilder() {
  spaceUsageBuilderStore.upsertSpaceUsageBuilder({
    spaceDataField: props.floorArea.dataField,
  });
}

const router = useRouter();
const route = useRoute();

async function viewFloorAreaSizes() {
  propertyDiagramSelected.value = props.floorArea;

  await nextTick();

  router.push({
    name: route.name,
    query: {
      ...route.query,
      horizontalTab: "Details",
      verticalTab: "Sizes",
    },
  });
}
</script>
