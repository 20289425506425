import { computed, ref, watch, nextTick, markRaw } from "vue";
import { useCrowdsourcedChangeGroupStore } from "@/stores/crowdsourcedChangeGroup";
import { useTimeTravelStore } from "@/stores/timeTravel";
import { usePropertyDiagramStore } from "@/stores/propertyDiagram";
import { useModalStore } from "@/stores/modal";
import { useUnlockerStore } from "@/stores/unlocker";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { useNotificationsStore } from "@/stores/notifications";
import { defineStore, storeToRefs, acceptHMRUpdate } from "pinia";
import {
  localCapitalProviders,
  matchingInvestmentGroup,
  providersFetchRequestKey,
  awardedProvidersFetchRequestKey,
} from "@/assets/investmentHelpers";
import {
  awardable,
  mustBeClosed,
  unknownSupplySide,
  contentType,
  contentId,
  existingContentType,
  existingContentId,
  groupId,
  fetchMilliseconds,
} from "@/assets/transactionHelpers";
import decoratingAndFieldKey from "@/components/crowdsourcing/decoratingAndFieldKey";
import unlockableInnerContent from "@/components/crowdsourcing/unlockableInnerContent";
import subscribeInterceptor from "@/components/crowdsourcing/subscribeInterceptor";
import UnlicensedContentWarning from "@/components/users/subscribe-prompts/UnlicensedContentWarning.vue";
import api from "@/router/api";
import moment from "moment";
import _ from "lodash";
import VueScrollTo from "vue-scrollto";
import { useRoute, useRouter } from "vue-router";

export const useDealBuilderStore = defineStore("dealBuilder", () => {
  const notificationsStore = useNotificationsStore();
  const changeGroupStore = useCrowdsourcedChangeGroupStore();
  const { changeGroupId } = storeToRefs(changeGroupStore);
  const timeTravelStore = useTimeTravelStore();
  const { asOfMilliseconds, timeTravelTo } = storeToRefs(timeTravelStore);
  const propertyDiagramStore = usePropertyDiagramStore();
  const { selectedTimelineEvent, propertyIdParam, propertyDiagramPropertyIds } =
    storeToRefs(propertyDiagramStore);
  const layoutStore = useWorkspaceLayoutStore();
  const { workspaceLayout } = storeToRefs(layoutStore);
  const upgradeInProgress = ref(false);
  const { resetRequired, upgradeSuccessful } = storeToRefs(useUnlockerStore());
  const { modalPayload } = storeToRefs(useModalStore());
  const fetchingDealBuilderData = ref(false);
  const dealBuilderPropertyDataFields = ref([]);
  const allFetchedFields = ref([]);
  const fetchRequests = ref([]);
  const investmentPatchableTimelineEvents = ref([]);
  const patchableCompanyInvolvementFieldIds = ref([]);

  const dealBuilderCalculationData = ref({});
  const dealBuilder = ref(null);
  const assetFieldIds = computed(() => {
    if (dealBuilder.value) {
      return _.map(dealBuilder.value.assets, function (assetObject) {
        return assetObject.dataField.localId;
      });
    } else {
      return [];
    }
  });
  const refetchDealBuilderEditor = ref(null);
  const contactInvolvementFields = computed(() => {
    return allFetchedFields.value.filter((cdf) => {
      return cdf.fieldContentType === "ContactCompanyInvolvement";
    });
  });
  const patchableCompanySize = computed(
    () => patchableCompanyInvolvementFieldIds.value.length,
  );
  const contactFieldSize = computed(
    () => contactInvolvementFields.value.length,
  );
  const crossInteraction = computed({
    get() {
      return _.get(dealBuilder.value, "crossInteraction");
    },
    set(val) {
      if (dealBuilder.value) dealBuilder.value.crossInteraction = val;
    },
  });
  const investmentGroups = computed(() => {
    return _.get(dealBuilder.value, "investmentGroups", []);
  });

  watch(resetRequired, () => {
    if (upgradeInProgress.value) {
      resetRequired.value = false;
      upgradeInProgress.value = false;
    }
  });

  const fetchedKeys = computed(() =>
    fetchRequests.value.map((request) => request.key),
  );

  // CALCULATION DATA

  const fetchedCalculationKeys = computed(() =>
    _.keys(dealBuilderCalculationData.value),
  );

  async function fetchDealBuilderCalculationData({
    contentType,
    contentId,
    override = false,
  }) {
    const contentKey = `${contentType}${contentId}`;
    if (alreadyFetchedCalculations(contentKey) && !override) {
      console.log("already fetched", contentKey);
    } else if (contentType && contentId) {
      try {
        const payload = {
          contentType,
          contentIds: [contentId],
          calculationName: "sizes_uses",
          bundle: "calculation",
          asOf: asOfMilliseconds.value,
        };
        fetchingDealBuilderData.value = true;
        const response = await api.post(
          `crowdsourced_data_field_calculation_bundles`,
          payload,
        );

        if (response?.data) {
          dealBuilderCalculationData.value[contentKey] = {
            allFieldsData: response.data,
            sizesUses: structuredCalculationDataFor(
              "sizes_uses",
              response.data.calculationFields,
            ),
          };
        }
      } catch (error) {
        console.error(error);
        notificationsStore.addNotification("anErrorOccurred");
      } finally {
        fetchingDealBuilderData.value = false;
      }
    }
  }

  function structuredCalculationDataFor(calculationName, rawFields) {
    switch (calculationName) {
      case "sizes_uses": {
        let tempState = {
          interceptableFields: [],
          floorAreaDataFields: [],
          propertyUseDataFields: [],
          standardizedAreaDataFields: [],
          useBasedAreaDataFields: [],
        };

        const interceptableFields = rawFields.filter(
          (df) => df.meteringStatus === "prompt_to_subscribe",
        );
        const floorAreaDataFields = rawFields.filter(
          (df) => df.fieldContentType === "FloorArea",
        );
        const propertyUseDataFields = rawFields.filter(
          (df) => df.fieldContentType === "ContentPropertyUse",
        );
        const standardizedAreaDataFields = rawFields.filter(
          (df) => df.fieldName === "standardized_area",
        );
        const useBasedAreaDataFields = rawFields.filter(
          (df) => df.fieldName === "use_based_area",
        );

        tempState.interceptableFields = interceptableFields;
        tempState.floorAreaDataFields = floorAreaDataFields;
        tempState.propertyUseDataFields = propertyUseDataFields;
        tempState.standardizedAreaDataFields = standardizedAreaDataFields;
        tempState.useBasedAreaDataFields = useBasedAreaDataFields;

        return tempState;
      }
    }
  }

  function alreadyFetchedCalculations(contentKey) {
    return _.includes(fetchedCalculationKeys.value, contentKey);
  }

  function calculationDataByKey(contentKey) {
    return _.get(dealBuilderCalculationData.value, `[${contentKey}]`, {});
  }

  // DEAL BUILDER DATA

  function fetchPropertyDataField(propertyId) {
    fetchingDealBuilderData.value = true;
    api.get(`properties/${propertyId}`).then((json) => {
      fetchingDealBuilderData.value = false;
      patchPropertyDataFields([json.data]);
    });
  }
  function alreadyFetchedFieldsFor(fetchRequestKey) {
    const request = _.find(fetchRequests.value, { key: fetchRequestKey });

    return allFetchedFields.value.filter((field) =>
      _.includes(request?.fieldIds || [], field.localId),
    );
  }
  function alreadyFetched(fetchRequestKey) {
    return _.includes(fetchedKeys.value, fetchRequestKey);
  }
  function registerFetchRequest(dataFields, fetchRequestKey) {
    const payload = {
      key: fetchRequestKey,
      fieldIds: dataFields.map((field) => field.localId),
    };

    fetchRequests.value = _.unionBy([payload], fetchRequests.value, "key");
  }
  function updateFetchRequests(dataFields, fetchRequestKey) {
    const stillPresentFields = alreadyFetchedFieldsFor(fetchRequestKey);
    // console.log(
    //   "still present",
    //   fetchRequestKey,
    //   stillPresentFields.map((field) => field.localId)
    // );
    const updatedFields = _.unionBy(dataFields, stillPresentFields, "localId");
    // console.log(
    //   "updated",
    //   fetchRequestKey,
    //   updatedFields.map((field) => field.localId)
    // );

    registerFetchRequest(updatedFields, fetchRequestKey);
  }
  function dropFetchRequest(dropKey) {
    fetchRequests.value = fetchRequests.value.filter(
      ({ key }) => key !== dropKey,
    );
  }
  function cleanUpRequests() {
    fetchRequests.value.forEach(({ key }) => updateFetchRequests([], key));
  }

  function clearDealBuilder(clearBuilder = true, clearCalculationData = true) {
    // console.log("clear deal builder. wipe builder?", clearBuilder);
    if (clearBuilder) dealBuilder.value = null;
    if (clearCalculationData) dealBuilderCalculationData.value = {};

    allFetchedFields.value = [];
    dealBuilderPropertyDataFields.value = [];
    fetchRequests.value = [];
    investmentPatchableTimelineEvents.value = [];
    clearSelectedEvent();
    clearMetaData();
  }

  function clearSelectedEvent() {
    selectedTimelineEvent.value = null;
  }

  function clearMetaData() {
    if (dealBuilder.value?.assets) {
      _.forEach(dealBuilder.value.assets, function (assetObject, assetKey) {
        clearDealBuilderAssetManipulatedLoans({ assetKey });
        clearDealBuilderAssetIndividualOwnershipInterests({ assetKey });
      });
    }
  }

  function interceptableField(dataField) {
    return _.get(dataField, "meteringStatus") === "prompt_to_subscribe";
  }
  async function refreshInvestmentGroup({
    closingGroupId,
    groupId,
    dealAction,
    isExistingGroup,
  }) {
    if (groupId && dealAction) {
      removeDealBuilderInvestmentGroup({
        groupId: closingGroupId || groupId,
        existing: isExistingGroup,
      });
      clearDealBuilder(false);
      refetchDealBuilderEditor.value = true;

      await nextTick();

      console.log("add group again");

      addDealBuilderInvestmentGroup({
        existingGroupId: groupId,
        dealAction,
      });
    }
  }
  async function refetchDataField(dataFieldLocalId) {
    fetchingDealBuilderData.value = true;
    api.get(`data_field_by_id/${dataFieldLocalId}`).then((json) => {
      fetchingDealBuilderData.value = false;
      interceptablePatch([json.data]);
    });
  }
  function interceptablePatch(dataFields, fetchRequestKey = null) {
    if (_.some(dataFields, interceptableField)) {
      promptToSubscribe();
    } else {
      patchDiagramFields(dataFields);
      if (fetchRequestKey) registerFetchRequest(dataFields, fetchRequestKey);
    }
  }
  function unlockWarn() {
    return new Promise((resolve) => {
      if (!modalPayload.value) {
        modalPayload.value = {
          size: "base",
          theme: "light",
          component: markRaw(UnlicensedContentWarning),
          props: {},
        };
        resolve();
      } else {
        resolve();
      }
    });
  }
  function promptToSubscribe() {
    return new Promise((resolve) => {
      if (!modalPayload.value) {
        upgradeInProgress.value = true;
        const successCallback = () => {
          if (upgradeSuccessful.value) {
            console.log("user subscribed");
            upgradeInProgress.value = false;
            upgradeSuccessful.value = false;
            // fetchDiagramData();
            resolve();
          }
        };

        subscribeInterceptor({
          apiRequestFunc: null,
          successCallback,
          modalPayloadRef: modalPayload,
          upgradeSuccessfulRef: upgradeSuccessful,
          afterSubscribe: "successCallback",
          promptReason: "dataUsage",
          context: "licensing",
        });
      } else {
        resolve();
      }
    });
  }
  async function postEditingPatch(json, fetchRequestKey = null) {
    let newData = null;
    if (json.data.dataField) newData = [json.data.dataField];
    else if (json.data.dataFields) newData = json.data.dataFields;

    if (newData) patchDiagramFields(newData, fetchRequestKey);
    if (json.data.timelineEvents) {
      investmentPatchableTimelineEvents.value = json.data.timelineEvents;
    }
  }

  function patchPropertyDataFields(newFields) {
    dealBuilderPropertyDataFields.value = _.unionBy(
      newFields,
      dealBuilderPropertyDataFields.value,
      "localId",
    );
  }

  function patchDiagramFields(newFields, fetchRequestKey = null) {
    return new Promise((resolve) => {
      dropInterceptFields();
      allFetchedFields.value = _.unionBy(
        newFields,
        allFetchedFields.value,
        "localId",
      );
      dropDeprecatedFields();
      if (fetchRequestKey) {
        const remainingFields = _.intersectionBy(
          newFields,
          allFetchedFields.value,
          "localId",
        );
        updateFetchRequests(remainingFields, fetchRequestKey);
      }
      patchCompanyInvolvements(newFields);

      if (_.some(newFields, unlockableInnerContent)) {
        unlockWarn();
      }

      resolve();
    });
  }
  function patchCompanyInvolvements(newFields) {
    // decoratingContentType: 'OwnershipInterest', fieldContentType: 'CompanyInvolvement'
    const involvementFields = newFields.filter(
      (field) => field.fieldContentType === "CompanyInvolvement",
    );
    const fieldIds = involvementFields.map((field) => field.localId);

    patchableCompanyInvolvementFieldIds.value = _.union(
      fieldIds,
      patchableCompanyInvolvementFieldIds.value,
    );
  }
  function dropInterceptFields() {
    const strippedFields = allFetchedFields.value.filter((dataField) => {
      return !dataField.meteringStatus;
    });
    allFetchedFields.value = strippedFields;
    cleanUpRequests();
  }
  function dropDeprecatedFields() {
    const strippedFields = allFetchedFields.value.filter((dataField) => {
      return !dataField.deprecatedAt && !dataField.dismissed;
    });
    allFetchedFields.value = strippedFields;
    cleanUpRequests();
  }

  function resetDealBuilderInvestments() {
    _.forEach(dealBuilder.value.assets, function (assetObject) {
      assetObject.investments = [];
    });
  }

  async function upsertDealBuilder({
    assetDataField = null,
    existingGroupId = null,
    timelineInvestmentId = null,
    timeTravelDate = undefined,
    focus = "Valuations",
  }) {
    let assetKey = null;
    let newCrossInteraction = null;
    if (timelineInvestmentId) {
      newCrossInteraction = {
        dealAction: null,
        assetKey,
        combinedKey: timelineInvestmentId,
        focus,
        source: "TimelineInvestment",
      };
    }
    let asOf = undefined;

    if (timeTravelDate) {
      asOf = moment(timeTravelDate).valueOf();
      timeTravelTo.value = timeTravelDate;
    }

    if (dealBuilder.value) {
      if (assetDataField) {
        assetKey = decoratingAndFieldKey(assetDataField);
        const assetObject = {
          dataField: assetDataField,
          investments: [],
          expanded: true,
          ownershipInterests: [],
        };

        crossInteraction.value = newCrossInteraction;
        addDealBuilderAsset({
          assetObject,
          assetKey,
        });
      }
    } else {
      if (assetDataField) {
        assetKey = decoratingAndFieldKey(assetDataField);
      }

      newDealBuilder({
        assetDataField,
        crossInteraction: newCrossInteraction,
      });
    }

    if (existingGroupId) {
      addDealBuilderInvestmentGroup({
        existingGroupId,
      });
    }

    await nextTick();
    router.push({
      name: route.name,
      query: {
        ...route.query,
        horizontalTab: "Deals",
        verticalTab: undefined,
        assetFieldIds: assetFieldIds.value,
        asOf,
      },
    });

    collapseAllAssetsExcept({ assetKey });

    if (
      assetDataField?.joiningContentType === "Property" &&
      propertyIdParam.value
    ) {
      if (
        !_.includes(
          propertyDiagramPropertyIds.value,
          assetDataField.joiningContentId,
        )
      ) {
        propertyDiagramStore.addPropertyToDiagram(
          assetDataField.joiningContentId,
        );
      }
    }

    if (workspaceLayout.value === "fullScreen") {
      layoutStore.matchLayoutToScreen();
    }
  }

  function newDealBuilder({
    assetDataField = null,
    investmentFieldContent = null,
    crossInteraction = null,
    ownershipInterestIds = [],
    dealAction = null,
    seniorLoanForeclosure = false,
    depth,
    stubbedDeal = null,
  }) {
    let assetKey = null;
    let assets = {};

    if (assetDataField) {
      assetKey = decoratingAndFieldKey(assetDataField);
      assets[assetKey] = {
        dataField: assetDataField,
        investments: [],
        expanded: true,
        datedTimelineFetched: false,
        undatedTimelineFetched: false,
        ownershipInterests: [],
        loans: [],
        assumptionLoans: [],
        foreclosureLoans: [],
        entireInterests: [],
        partialInterests: [],
      };
    }

    let investmentGroups = [];
    let currentInvestmentGroupPlaceholderId = 1000;

    if (investmentFieldContent && dealAction) {
      let investments = {};
      const investmentKey = decoratingAndFieldKey(investmentFieldContent);
      const combinedKey = `${assetKey}_${investmentKey}`;
      let newInvestment = {
        investmentGroupId: `temp${currentInvestmentGroupPlaceholderId}`,
        temporaryId: combinedKey,
        capitalStackTopLevelAsset: assetDataField,
        investmentFieldContent,
        ownershipInterestIds,
        capitalProviders: [],
        advisors: [],
        dealAction,
        date: null,
        state: null,
        dollarValue: null,
        dollarValueType: null,
        seniorLoanForeclosure,
        depth,
      };

      if (stubbedDeal) {
        investments[combinedKey] = _.merge({}, newInvestment, stubbedDeal);
      } else {
        investments[combinedKey] = newInvestment;
      }

      const initialGroup = {
        placeholderId: `temp${currentInvestmentGroupPlaceholderId}`,
        dealAction,
        investments,
      };

      investmentGroups.push(initialGroup);
      currentInvestmentGroupPlaceholderId++;
    }
    const initialStructure = {
      currentInvestmentGroupPlaceholderId,
      assets,
      investmentGroups,
      crossInteraction,
      foreclosureSubordinateKey: null,
    };

    dealBuilder.value = initialStructure;
  }

  function addDealBuilderInvestmentToGroup({
    existingGroupId = null,
    existingInvestment = undefined,
    stubbedDeal = null,
    groupInvestmentDataField = undefined,
    assetDataField = null,
    investmentFieldContent = null,
    investmentGroupPlaceholderId = null,
    ownershipInterestIds = [],
    seniorLoanForeclosure = false,
  }) {
    let group = null;

    if (existingGroupId) {
      group = _.find(dealBuilder.value.investmentGroups, {
        id: existingGroupId,
      });
    } else if (investmentGroupPlaceholderId) {
      group = _.find(dealBuilder.value.investmentGroups, {
        placeholderId: investmentGroupPlaceholderId,
      });
    }

    if (group) {
      const impliedLoanSeniority =
        group.dealAction === "refinance" ? { loanSeniority: "senior" } : {};
      let combinedKey;

      if (assetDataField && investmentFieldContent) {
        const assetKey = decoratingAndFieldKey(assetDataField);
        const investmentKey = decoratingAndFieldKey(investmentFieldContent);

        combinedKey = `${assetKey}_${investmentKey}`;
      } else if (existingInvestment) {
        combinedKey = existingInvestment.id;
      }

      const newInvestment = _.merge(
        {},
        {
          existingInvestment,
          groupInvestmentDataField,
          investmentGroupId: existingGroupId || investmentGroupPlaceholderId,
          temporaryId: combinedKey,
          capitalStackTopLevelAsset: assetDataField,
          investmentFieldContent,
          ownershipInterestIds,
          capitalProviders: [],
          advisors: [],
          dealAction: group.dealAction,
          date: null,
          state: null,
          dollarValue: null,
          dollarValueType: null,
          seniorLoanForeclosure,
        },
        impliedLoanSeniority,
      );

      if (stubbedDeal) {
        group.investments[combinedKey] = _.merge(
          {},
          newInvestment,
          stubbedDeal,
        );
      } else if (existingInvestment) {
        group.investments[combinedKey] = _.merge(
          {},
          newInvestment,
          existingInvestment,
        );
      } else {
        group.investments[combinedKey] = newInvestment;
      }

      if (existingGroupId && existingInvestment && !group.dealAction) {
        group.dealAction = existingInvestment.portfolioDealAction;
      }
    }
  }

  function removeDealBuilderAsset(assetKey) {
    delete dealBuilder.value.assets[assetKey];

    router.push({
      name: route.name,
      query: {
        ...route.query,
        horizontalTab: "Deals",
        verticalTab: undefined,
        assetFieldIds: assetFieldIds.value,
      },
    });
  }

  function removeDealBuilderInvestment({ investmentKey, dealAction }) {
    dealBuilder.value.investmentGroups.forEach((group) => {
      const investment = _.get(group, `investments[${investmentKey}]`);

      if (
        investment &&
        (investment.dealAction === dealAction || investment.existingInvestment)
      ) {
        delete group.investments[investmentKey];
      }
    });

    const remainingGroups = dealBuilder.value.investmentGroups.filter(
      (group) => {
        return _.size(group.investments) > 0;
      },
    );

    dealBuilder.value.investmentGroups = remainingGroups;
  }
  function removeDealBuilderInvestmentGroup({ groupId, existing = true }) {
    let group, differenceKey;

    if (existing) {
      group = _.find(dealBuilder.value.investmentGroups, {
        id: groupId,
      });
      differenceKey = "id";
    } else {
      group = _.find(dealBuilder.value.investmentGroups, {
        placeholderId: groupId,
      });
      differenceKey = "placeholderId";
    }

    const filteredGroups = _.differenceBy(
      dealBuilder.value.investmentGroups,
      [group],
      differenceKey,
    );

    dealBuilder.value.investmentGroups = filteredGroups;
  }

  function addInvestmentGroupInvestmentPlayers({
    groupId,
    investmentCombinedKey,
    addedCompanies,
    path,
    compareBy = _.identity,
  }) {
    if (groupId) {
      const existingGroup = _.find(dealBuilder.value.investmentGroups, {
        id: groupId,
      });
      const placeholderGroup = _.find(dealBuilder.value.investmentGroups, {
        placeholderId: groupId,
      });
      const group = existingGroup || placeholderGroup;
      const investment = _.get(group, `investments[${investmentCombinedKey}]`);
      let newProviders;

      if (investment.existingInvestment) {
        newProviders = _.unionBy(
          _.get(investment, `existingInvestment.${path}`, []),
          addedCompanies,
          compareBy,
        );

        group.investments[investmentCombinedKey].existingInvestment[path] =
          newProviders;
      } else {
        newProviders = _.unionBy(
          _.get(investment, path, []),
          addedCompanies,
          compareBy,
        );

        group.investments[investmentCombinedKey][path] = newProviders;
      }
    }
  }
  function removeInvestmentGroupInvestmentPlayer({
    groupId,
    investmentCombinedKey,
    toRemove,
    path,
    compareBy = _.identity,
  }) {
    if (groupId) {
      const existingGroup = _.find(dealBuilder.value.investmentGroups, {
        id: groupId,
      });
      const placeholderGroup = _.find(dealBuilder.value.investmentGroups, {
        placeholderId: groupId,
      });
      const group = existingGroup || placeholderGroup;
      const investment = _.get(group, `investments[${investmentCombinedKey}]`);
      let filteredProviders;

      if (investment.existingInvestment) {
        filteredProviders = _.differenceBy(
          _.get(investment, `existingInvestment.${path}`, []),
          [toRemove],
          compareBy,
        );

        group.investments[investmentCombinedKey].existingInvestment[path] =
          filteredProviders;
      } else {
        filteredProviders = _.differenceBy(
          _.get(investment, path, []),
          [toRemove],
          compareBy,
        );

        group.investments[investmentCombinedKey][path] = filteredProviders;
      }
    }
  }

  function updateInvestmentGroupInvestment({
    groupId,
    newInvestment,
    fieldName = null,
  }) {
    if (groupId) {
      const existingGroup = _.find(dealBuilder.value.investmentGroups, {
        id: groupId,
      });
      const placeholderGroup = _.find(dealBuilder.value.investmentGroups, {
        placeholderId: groupId,
      });
      const group = existingGroup || placeholderGroup;
      let combinedKey = null;

      if (
        newInvestment.capitalStackTopLevelAsset &&
        newInvestment.investmentFieldContent &&
        group
      ) {
        const assetKey = decoratingAndFieldKey(
          newInvestment.capitalStackTopLevelAsset,
        );
        const investmentKey = decoratingAndFieldKey(
          newInvestment.investmentFieldContent,
        );

        combinedKey = `${assetKey}_${investmentKey}`;

        if (fieldName) {
          group.investments[combinedKey][fieldName] = newInvestment[fieldName];
        } else {
          group.investments[combinedKey] = newInvestment;
        }
      } else if (newInvestment.id) {
        // console.log("existing investment");
        const groupInvestment = _.find(
          group.investments,
          function (investment) {
            return investment.existingInvestment.id === newInvestment.id;
          },
        );

        console.log(newInvestment);
        console.log(groupInvestment);

        combinedKey = groupInvestment.temporaryId;

        if (fieldName) {
          group.investments[combinedKey].existingInvestment[fieldName] =
            newInvestment[fieldName];
        } else {
          group.investments[combinedKey].existingInvestment = newInvestment;
        }
      }
    }
  }
  function addTimingFieldsToInvestmentGroup({ groupId, newFields }) {
    if (groupId) {
      const existingGroup = _.find(dealBuilder.value.investmentGroups, {
        id: groupId,
      });
      const placeholderGroup = _.find(dealBuilder.value.investmentGroups, {
        placeholderId: groupId,
      });
      const group = existingGroup || placeholderGroup;

      group.timingFields = _.unionBy(newFields, group.timingFields, "localId");
    }
  }

  async function fetchGroupTimingFields(groupId) {
    const response = await api.get(`investment_group_timing_fields/${groupId}`);

    addTimingFieldsToInvestmentGroup({ groupId, newFields: response.data });
  }

  async function checkGroupTimingUnlockability(groupId) {
    const response = await api.get(
      `investment_group_timing_unlockability/${groupId}`,
    );

    return response;
  }

  function addDealBuilderInvestmentGroup({
    existingGroupId = null,
    existingInvestment = undefined,
    assetDataField = null,
    investmentFieldContent = null,
    ownershipInterestIds = [],
    dealAction = null,
    depth,
    loanSeniority = null,
    loanCollateralType = null,
    seniorLoanForeclosure = false,
    stubbedDeal = null,
  }) {
    const impliedLoanSeniority =
      dealAction === "refinance" ? "senior" : loanSeniority;
    const newGroup = {
      id: existingGroupId,
      placeholderId: `temp${dealBuilder.value.currentInvestmentGroupPlaceholderId}`,
      dealAction,
      investments: {},
      timingFields: [],
      expanded: !!existingInvestment,
    };

    if (!existingGroupId) {
      const assetKey = decoratingAndFieldKey(assetDataField);
      const investmentKey = decoratingAndFieldKey(investmentFieldContent);
      const combinedKey = `${assetKey}_${investmentKey}`;
      let newInvestment = {
        existingInvestment,
        investmentGroupId:
          existingGroupId ||
          `temp${dealBuilder.value.currentInvestmentGroupPlaceholderId}`,
        temporaryId: combinedKey,
        capitalStackTopLevelAsset: assetDataField,
        investmentFieldContent,
        ownershipInterestIds,
        capitalProviders: [],
        advisors: [],
        dealAction,
        date: null,
        state: null,
        dollarValue: null,
        dollarValueType: null,
        loanCollateralType,
        loanSeniority: impliedLoanSeniority,
        seniorLoanForeclosure,
        depth,
      };

      if (stubbedDeal) {
        newGroup.investments[combinedKey] = _.merge(
          {},
          newInvestment,
          stubbedDeal,
        );
      } else {
        newGroup.investments[combinedKey] = newInvestment;
      }
    }

    if (existingGroupId) {
      const newGroups = _.unionBy(
        dealBuilder.value.investmentGroups,
        [newGroup],
        function (group) {
          return group.id || group.placeholderId;
        },
      );

      dealBuilder.value.investmentGroups = newGroups;
    } else {
      dealBuilder.value.investmentGroups.push(newGroup);
    }
    dealBuilder.value.currentInvestmentGroupPlaceholderId++;
  }

  async function saveInvestmentGroup({ placeholderId, successCallback }) {
    const equityActions = [
      "sellOutright",
      "sellEntireInterest",
      "sellPartialInterest",
      "sellMultipleInterests",
      "foreclosureSellOutright",
    ];

    function persist(investment) {
      const focalInvestment = investment.existingInvestment || investment;
      const investmentType = focalInvestment.dealAction;
      let layerType = "OwnershipInterest";

      if (investment.existingInvestment) {
        layerType = investment.existingInvestment.investmentType;
      } else if (focalInvestment.dealAction) {
        layerType =
          _.intersection(equityActions, [focalInvestment.dealAction]).length > 0
            ? "OwnershipInterest"
            : "Loan";
      }

      const validDollar =
        focalInvestment.dollarValueType &&
        _.toNumber(focalInvestment.dollarValue) > 10000;

      let assetObject = null;
      let contentId = null;
      let contentType = null;
      let entireInterestIds = [];
      let partialInterestIds = [];
      let assumptionLoanIds = [];
      let foreclosureLoanIds = [];

      if (!investment.existingInvestment) {
        assetObject = matchingAssetObject(
          decoratingAndFieldKey(investment.capitalStackTopLevelAsset),
        );
        entireInterestIds = _.get(assetObject, "entireInterests", []).map(
          (id) => {
            return { id };
          },
        );
        partialInterestIds = _.get(assetObject, "partialInterests", []).map(
          (id) => {
            return { id };
          },
        );
        assumptionLoanIds = _.get(assetObject, "assumptionLoans", []).map(
          (id) => {
            return { id };
          },
        );
        foreclosureLoanIds = _.get(assetObject, "foreclosureLoans", []).map(
          (id) => {
            return { id };
          },
        );

        switch (focalInvestment.investmentFieldContent.decoratingContentType) {
          case "Property":
          case "PropertyRight":
            {
              if (
                focalInvestment.investmentFieldContent.fieldContentType ===
                "OwnershipInterest"
              ) {
                console.log(
                  "investment group setting content id/type from ownership interest",
                  focalInvestment.investmentFieldContent,
                );
                contentId =
                  focalInvestment.investmentFieldContent.decoratingContentId;
                contentType =
                  focalInvestment.investmentFieldContent.decoratingContentType;
              } else {
                console.log(
                  "investment group setting content id/type from asset",
                  focalInvestment.investmentFieldContent,
                );
                contentId =
                  focalInvestment.investmentFieldContent.fieldContentId;
                contentType =
                  focalInvestment.investmentFieldContent.fieldContentType;
              }
            }
            break;
          default:
            console.log(
              "investment group setting content id/type",
              focalInvestment.investmentFieldContent,
            );
            contentId =
              focalInvestment.investmentFieldContent.decoratingContentId;
            contentType =
              focalInvestment.investmentFieldContent.decoratingContentType;
            break;
        }
      }

      return {
        investmentGroup: true,
        existingInvestmentId: focalInvestment.id,
        layerType,
        linkToInvestmentId: null,
        existingDataFieldId: null,
        contentId: contentId,
        contentType: contentType,
        collateralType: focalInvestment?.loanCollateralType,
        seniority: focalInvestment?.loanSeniority,
        companies: _.get(focalInvestment, "capitalProviders", []),
        advisors: _.get(focalInvestment, "advisors", []),
        prospects: _.get(focalInvestment, "prospects", []),
        entireInterestIds,
        partialInterestIds,
        investmentType,
        foreclosureLoanIds,
        assumptionLoanIds,
        date: focalInvestment.date,
        state: focalInvestment.state,
        dollarValue: validDollar ? focalInvestment.dollarValue : null,
        dollarValueType: validDollar ? focalInvestment.dollarValueType : null,
      };
    }

    if (dealBuilder.value) {
      const group = _.find(dealBuilder.value.investmentGroups, {
        placeholderId,
      });

      if (group) {
        const groupPayload = {
          dealAction: group.dealAction,
          investments: _.map(group.investments, persist),
          changeGroupId: changeGroupId.value,
        };

        const apiRequestFunc = () =>
          api.post(`investment_groups`, groupPayload);
        const failureCallback = () => {};
        await changeGroupStore.originateData(
          apiRequestFunc,
          successCallback,
          failureCallback,
        );
      }
    }
  }

  async function fetchInvestmentGroupInvestments(investmentGroupId) {
    if (investmentGroupId) {
      fetchingDealBuilderData.value = true;
      const response = await api.get(
        `investment_group_investments/${investmentGroupId}`,
      );
      const investmentDataFields = response.data;

      investmentDataFields.forEach((dataField) => {
        addDealBuilderInvestmentToGroup({
          existingGroupId: investmentGroupId,
          existingInvestment: dataField.fieldContent,
          groupInvestmentDataField: dataField,
        });
      });

      fetchingDealBuilderData.value = false;

      collapsePortfolioAssets({
        groupId: investmentGroupId,
      });
      setDealBuilderInvestmentGroupExpanded({
        groupId: investmentGroupId,
        expanded: true,
      });
    }
  }

  function setDealBuilderInvestmentGroupExpanded({ groupId, expanded }) {
    const existingGroup = _.find(dealBuilder.value.investmentGroups, {
      id: groupId,
    });
    const placeholderGroup = _.find(dealBuilder.value.investmentGroups, {
      placeholderId: groupId,
    });
    const group = existingGroup || placeholderGroup;

    if (group) {
      group.expanded = expanded;
    }
  }

  function setDealBuilderAssetExpanded({
    assetKey,
    expanded,
    investment = null,
  }) {
    if (assetKey && dealBuilder.value.assets[assetKey]) {
      dealBuilder.value.assets[assetKey].expanded = expanded;
    } else if (investment && investment.id) {
      const derivedAssetKey = _.findKey(
        dealBuilder.value.assets,
        function (asset) {
          return !!_.find(asset.investments, { id: investment.id });
        },
      );

      if (derivedAssetKey) {
        dealBuilder.value.assets[derivedAssetKey].expanded = expanded;
      }
    }
  }

  function collapsePortfolioAssets({ groupId }) {
    const existingGroup = _.find(dealBuilder.value.investmentGroups, {
      id: groupId,
    });
    const placeholderGroup = _.find(dealBuilder.value.investmentGroups, {
      placeholderId: groupId,
    });
    const group = existingGroup || placeholderGroup;

    if (group) {
      _.forEach(group.investments, function (investment) {
        const assetKey = investment.capitalStackTopLevelAsset
          ? decoratingAndFieldKey(investment.capitalStackTopLevelAsset)
          : null;

        setDealBuilderAssetExpanded({
          assetKey,
          expanded: false,
          investment: investment.existingInvestment,
        });
      });
    }
  }

  function collapseAllAssetsExcept({ assetKey }) {
    _.forEach(
      _.get(dealBuilder.value, "assets", {}),
      function (assetObject, key) {
        if (key !== assetKey) {
          setDealBuilderAssetExpanded({
            assetKey: key,
            expanded: false,
          });
        }
      },
    );
  }

  function setDealBuilderAssetOwnershipInterests({ assetKey, interests = [] }) {
    if (dealBuilder.value?.assets && dealBuilder.value.assets[assetKey]) {
      dealBuilder.value.assets[assetKey].ownershipInterests = interests;
    }
  }

  function setDealBuilderAssetTimelineFetchState({
    assetKey,
    dated,
    state = false,
  }) {
    if (assetKey && dealBuilder.value?.assets?.[assetKey]) {
      const key = dated ? "datedTimelineFetched" : "undatedTimelineFetched";

      if (dealBuilder.value?.assets?.[assetKey]?.[key]) {
        dealBuilder.value.assets[assetKey][key] = state;
      }
    }
  }
  function updateDealBuilderAssetInvestments({ assetKey, investments }) {
    console.log("update asset investments; new investments:", investments);
    const oldAssetObject = dealBuilder.value?.assets?.[assetKey];

    if (oldAssetObject) {
      investments.forEach((investment) => {
        let matchingId = null;

        if (investment.fieldContentId) {
          const newInvestments = _.unionBy(
            [investment],
            oldAssetObject.investments,
            "fieldContentId",
          );
          oldAssetObject.investments = newInvestments;
        } else if (investment.existingInvestment) {
          console.log("has existing investment");
          matchingId = investment.existingInvestment.id;
        } else {
          console.log("temp or direct id");
          matchingId = investment.temporaryId || investment.id;
        }

        if (matchingId) {
          let matchingOldInvestment = _.find(oldAssetObject.investments, {
            fieldContentId: matchingId,
          });

          if (matchingOldInvestment) {
            matchingOldInvestment.fieldContent = investment;
          }
        }
      });

      addDealBuilderAsset({
        assetObject: oldAssetObject,
        assetKey,
      });
    }
  }
  function addDealBuilderAssets(moreAssets) {
    moreAssets.forEach((assetObject) => {
      if (
        !dealBuilder.value.assets[decoratingAndFieldKey(assetObject.dataField)]
      ) {
        dealBuilder.value.assets[decoratingAndFieldKey(assetObject.dataField)] =
          assetObject;
      }
    });
  }

  const query = computed(() => route.query);
  const horizontalIsDeals = computed(
    () => _.get(query.value, "horizontalTab") === "Deals",
  );

  async function addDealBuilderAsset({ assetObject, assetKey }) {
    const newAsset = {
      dataField: _.get(assetObject, "dataField"),
      investments: _.get(assetObject, "investments", []),
      expanded: _.get(assetObject, "expanded", true),
      datedTimelineFetched: _.get(assetObject, "datedTimelineFetched", false),
      undatedTimelineFetched: _.get(
        assetObject,
        "undatedTimelineFetched",
        false,
      ),
      ownershipInterests: _.get(assetObject, "ownershipInterests", []),
      loans: _.get(assetObject, "loans", []),
      assumptionLoans: _.get(assetObject, "assumptionLoans", []),
      foreclosureLoans: _.get(assetObject, "foreclosureLoans", []),
      entireInterests: _.get(assetObject, "entireInterests", []),
      partialInterests: _.get(assetObject, "partialInterests", []),
    };

    dealBuilder.value.assets[assetKey] = newAsset;
    await nextTick();

    if (horizontalIsDeals.value) {
      router.push({
        name: route.name,
        query: {
          ...route.query,
          horizontalTab: "Deals",
          verticalTab: undefined,
          assetFieldIds: assetFieldIds.value,
        },
      });
    }
  }

  function addDealBuilderAssetLoan({ assetKey, loanId }) {
    if (dealBuilder.value?.assets && dealBuilder.value.assets[assetKey]) {
      const newLoans = _.union(
        [loanId],
        dealBuilder.value.assets[assetKey].loans,
      );

      dealBuilder.value.assets[assetKey].loans = newLoans;
    }
  }

  function clearDealBuilderAssetManipulatedLoans({ assetKey }) {
    if (dealBuilder.value?.assets && dealBuilder.value.assets[assetKey]) {
      dealBuilder.value.assets[assetKey].assumptionLoans = [];
      dealBuilder.value.assets[assetKey].foreclosureLoans = [];
      dealBuilder.value.foreclosureSubordinateKey = null;
    }
  }
  function clearDealBuilderAssetIndividualOwnershipInterests({ assetKey }) {
    if (dealBuilder.value?.assets && dealBuilder.value.assets[assetKey]) {
      dealBuilder.value.assets[assetKey].entireInterests = [];
      dealBuilder.value.assets[assetKey].partialInterests = [];
    }
  }

  function matchingAssetObject(assetKey) {
    if (dealBuilder.value) {
      return dealBuilder.value.assets[assetKey];
    } else {
      return null;
    }
  }

  // PREPOPULATE
  async function populateDealBuilderFromQuery(ids) {
    for await (const id of ids) {
      try {
        fetchingDealBuilderData.value = true;
        const response = await api.get(`data_field_by_id/${id}`);
        fetchingDealBuilderData.value = false;
        const dataField = response.data;
        const assetKey = decoratingAndFieldKey(dataField);
        if (dealBuilder.value) {
          const assetObject = {
            dataField,
            investments: [],
            expanded: true,
            ownershipInterests: [],
          };

          addDealBuilderAsset({
            assetObject,
            assetKey,
          });
        } else {
          newDealBuilder({
            assetDataField: dataField,
          });
        }
      } catch (error) {
        fetchingDealBuilderData.value = false;
        console.log(error);
      }
    }
  }

  function prepopulateDealBuilder({
    dealAction,
    depth,
    stubbedDeal = null,
    futureDeal = null,
    matchingInvestmentGroup = null,
    dealBuilderAssetDataField,
    localAssetDataField,
    decoratingDataField = null,
    ownershipInterestIds = [],
    nestedCapitalStack,
  }) {
    if (dealBuilder.value) {
      const alreadySellingInterest = _.includes(
        ["sellPartialInterest", "sellEntireInterest"],
        dealAction,
      );

      crossInteraction.value = null;

      if (futureDeal && alreadySellingInterest) {
        const newInvestment = _.merge({}, futureDeal, {
          dealAction: "sellMultipleInterests",
        });
        const existingGroup = _.find(dealBuilder.value.investmentGroups, {
          id: matchingInvestmentGroup.id,
        });
        const placeholderGroup = _.find(dealBuilder.value.investmentGroups, {
          placeholderId: matchingInvestmentGroup.placeholderId,
        });
        const group = existingGroup || placeholderGroup;
        const matchingInvestment = _.find(
          group.investments,
          function (investment, key) {
            const investmentKeyParts = _.split(key, "_");

            return (
              _.head(investmentKeyParts) ===
              decoratingAndFieldKey(dealBuilderAssetDataField)
            );
          },
        );
        const existingOwnershipInterestIds =
          matchingInvestment?.ownershipInterestIds;
        // this helps hide any outgoing companies from the "after" stack
        const newOwnershipInterestIds = _.union(
          [decoratingDataField.fieldContentId],
          existingOwnershipInterestIds,
        );
        const editedInvestment = _.merge({}, newInvestment, {
          ownershipInterestIds: newOwnershipInterestIds,
        });
        const patchableInvestment =
          dealAction === "sellPartialInterest"
            ? newInvestment
            : editedInvestment;
        updateInvestmentGroupField({
          groupId:
            matchingInvestmentGroup.id || matchingInvestmentGroup.placeholderId,
          fieldName: "dealAction",
          fieldValue: "sellMultipleInterests",
        });

        updateInvestmentGroupInvestment({
          groupId:
            matchingInvestmentGroup.id || matchingInvestmentGroup.placeholderId,
          newInvestment: patchableInvestment,
          fieldName: "dealAction",
        });
        updateInvestmentGroupInvestment({
          groupId:
            matchingInvestmentGroup.id || matchingInvestmentGroup.placeholderId,
          newInvestment: patchableInvestment,
          fieldName: "ownershipInterestIds",
        });
        // store.dispatch(
        //   "flash",
        //   "New investment updated to reflect multiple interest sale."
        // );
      } else {
        console.log("nested?", nestedCapitalStack);
        if (nestedCapitalStack) {
          extractNestedCapitalStackAsset({
            dealBuilderAssetDataField,
            localAssetDataField,
            expanded: true,
          });
        }
        addDealBuilderInvestmentGroup({
          assetDataField: nestedCapitalStack
            ? localAssetDataField
            : dealBuilderAssetDataField,
          investmentFieldContent: decoratingDataField,
          ownershipInterestIds,
          dealAction,
          depth,
          stubbedDeal,
        });
      }
    } else {
      beginDealBuilder({
        dealAction,
        depth,
        stubbedDeal,
        assetDataField: nestedCapitalStack
          ? localAssetDataField
          : dealBuilderAssetDataField,
        investmentFieldContent: decoratingDataField,
        ownershipInterestIds,
      });
    }

    if (dealAction === "sellPartialInterest") {
      sellPartialInterest({
        decoratingDataField,
        assetKey: decoratingAndFieldKey(dealBuilderAssetDataField),
      });
    } else if (dealAction === "sellEntireInterest") {
      sellEntireInterest({
        decoratingDataField,
        assetKey: decoratingAndFieldKey(dealBuilderAssetDataField),
      });
    }
  }

  function updateInvestmentGroupField({ groupId, fieldName, fieldValue }) {
    const existingGroup = _.find(dealBuilder.value.investmentGroups, {
      id: groupId,
    });
    const placeholderGroup = _.find(dealBuilder.value.investmentGroups, {
      placeholderId: groupId,
    });
    const group = existingGroup || placeholderGroup;

    if (group && fieldName && fieldValue) {
      group[fieldName] = fieldValue;
    }
  }

  const router = useRouter();
  const route = useRoute();

  function beginDealBuilder({
    dealAction = null,
    depth,
    stubbedDeal = null,
    assetDataField = null,
    investmentFieldContent = null,
    ownershipInterestIds,
  }) {
    newDealBuilder({
      assetDataField,
      investmentFieldContent,
      ownershipInterestIds: ownershipInterestIds,
      dealAction,
      depth,
      stubbedDeal,
    });
    router.push({
      name: route.name,
      query: {
        ...route.query,
        horizontalTab: "Deals",
        verticalTab: undefined,
        assetFieldIds: assetFieldIds.value,
      },
    });
  }

  async function loadTimelinePortfolioInvestment() {
    if (!selectedTimelineEvent.value) return;
    const { portfolioId, portfolioDealAction } =
      selectedTimelineEvent.value.event;

    if (portfolioId && portfolioDealAction) {
      addDealBuilderInvestmentGroup({
        existingGroupId: portfolioId,
        dealAction: portfolioDealAction,
      });

      collapseAllAssetsExcept({
        assetKey: null,
      });
      await nextTick();
      VueScrollTo.scrollTo("#investment-portfolios-list", 100, {
        container: "#lists-panel-container",
      });
    }
  }

  function loadPropertyRightTimelineInvestment({ propertyRightDataField }) {
    if (!selectedTimelineEvent.value) return;
    if (!propertyRightDataField) return;

    const { assetId, assetType } = selectedTimelineEvent.value.event;
    const idMatch = propertyRightDataField.fieldContentId === assetId;
    const typeMatch = "PropertyRight" === assetType;
    const matchingRight = idMatch && typeMatch;

    if (matchingRight) {
      addPropertyDiagramAssetToDealBuilder({
        matchingRight: propertyRightDataField,
      });
    } else if (assetType === "Loan") {
      addNestedLoanToDealBuilder({
        loanId: assetId,
        investmentId: selectedTimelineEvent.value.event.fieldContentId,
      });
    } else {
      console.log("no match at property diagram level");
    }
  }

  async function addPropertyDiagramAssetToDealBuilder({ matchingRight }) {
    const newCrossInteraction = {
      dealAction: null,
      assetKey: decoratingAndFieldKey(matchingRight),
      combinedKey: selectedTimelineEvent.value.event.fieldContentId,
      focus: "Valuations",
      source: "TimelineInvestment",
    };
    const dealBuilderPayload = {
      assetDataField: matchingRight,
      crossInteraction: newCrossInteraction,
    };

    if (dealBuilder.value) {
      const assetKey = decoratingAndFieldKey(matchingRight);
      const assetObject = {
        dataField: matchingRight,
        investments: [],
        expanded: true,
        datedTimelineFetched: false,
        undatedTimelineFetched: false,
        ownershipInterests: [],
      };

      addDealBuilderAsset({
        assetObject,
        assetKey,
      });
      crossInteraction.value = newCrossInteraction;
    } else {
      newDealBuilder(dealBuilderPayload);
      await nextTick();
      router.push({
        name: route.name,
        query: {
          ...route.query,
          horizontalTab: "Deals",
          verticalTab: undefined,
          assetFieldIds: assetFieldIds.value,
        },
      });
    }
    selectedTimelineEvent.value = null;
  }

  async function addNestedLoanToDealBuilder({ loanId, investmentId }) {
    fetchingDealBuilderData.value = true;
    const loanResponse = await api.get(`loans/${loanId}`);
    fetchingDealBuilderData.value = false;
    const loanDataField = loanResponse.data;
    const assetKey = decoratingAndFieldKey(loanDataField);
    const assetObject = {
      dataField: loanDataField,
      investments: [],
      expanded: true,
      datedTimelineFetched: false,
      undatedTimelineFetched: false,
      ownershipInterests: [],
    };
    const newCrossInteraction = {
      dealAction: null,
      assetKey,
      combinedKey: investmentId,
      focus: "Valuations",
      source: "TimelineInvestment",
    };
    const dealBuilderPayload = {
      assetDataField: loanDataField,
      crossInteraction: newCrossInteraction,
    };

    if (dealBuilder.value) {
      addDealBuilderAsset({
        assetObject,
        assetKey,
      });
      crossInteraction.value = newCrossInteraction;
    } else {
      newDealBuilder(dealBuilderPayload);
      await nextTick();
      router.push({
        name: route.name,
        query: {
          ...route.query,
          horizontalTab: "Deals",
          verticalTab: undefined,
          assetFieldIds: assetFieldIds.value,
        },
      });
    }
  }

  function extractNestedCapitalStackAsset({
    dealBuilderAssetDataField,
    localAssetDataField,
    expanded = true,
  }) {
    const assetKey = decoratingAndFieldKey(localAssetDataField);
    const assetObject = {
      dataField: localAssetDataField,
      investments: [],
      expanded,
      ownershipInterests: [],
    };

    addDealBuilderAsset({ assetObject, assetKey });
    setDealBuilderAssetExpanded({
      assetKey: decoratingAndFieldKey(dealBuilderAssetDataField),
      expanded: false,
    });
  }

  function sellEntireInterest({ decoratingDataField, assetKey }) {
    const entireInterestId = decoratingDataField.fieldContentId;

    addDealBuilderAssetEntireInterest({
      assetKey,
      entireInterestId,
    });
  }

  function sellPartialInterest({ decoratingDataField, assetKey }) {
    const partialInterestId = decoratingDataField.fieldContentId;

    addDealBuilderAssetPartialInterest({
      assetKey,
      partialInterestId,
    });
  }

  function addDealBuilderAssetEntireInterest({ assetKey, entireInterestId }) {
    if (dealBuilder.value?.assets && dealBuilder.value.assets[assetKey]) {
      const newInterests = _.union(
        [entireInterestId],
        dealBuilder.value.assets[assetKey].entireInterests,
      );

      dealBuilder.value.assets[assetKey].entireInterests = newInterests;
    }
  }
  function addDealBuilderAssetPartialInterest({ assetKey, partialInterestId }) {
    if (dealBuilder.value?.assets && dealBuilder.value.assets[assetKey]) {
      const newInterests = _.union(
        [partialInterestId],
        dealBuilder.value.assets[assetKey].partialInterests,
      );

      dealBuilder.value.assets[assetKey].partialInterests = newInterests;
    }
  }
  function addDealBuilderAssetAssumptionLoan({ assetKey, loanId }) {
    if (dealBuilder.value?.assets && dealBuilder.value.assets[assetKey]) {
      const newLoans = _.union(
        [loanId],
        dealBuilder.value.assets[assetKey].assumptionLoans,
      );

      dealBuilder.value.assets[assetKey].assumptionLoans = newLoans;
    }
  }
  function addDealBuilderAssetForeclosureLoan({ assetKey, loanId }) {
    if (dealBuilder.value?.assets && dealBuilder.value.assets[assetKey]) {
      const newLoans = _.union(
        [loanId],
        dealBuilder.value.assets[assetKey].foreclosureLoans,
      );

      dealBuilder.value.assets[assetKey].foreclosureLoans = newLoans;
    }
  }
  // END PREPOPULATE

  function mountInvestmentMetadata({ assetKey, investment }) {
    const foreclosureLoans = _.get(investment, "foreclosureLoans", []);
    const assumptionLoans = _.get(investment, "assumptionLoans", []);
    const partialInterests = _.get(investment, "partialInterests", []);
    const entireInterests = _.get(investment, "entireInterests", []);

    foreclosureLoans.forEach((loanId) => {
      addDealBuilderAssetForeclosureLoan({ assetKey, loanId });
    });
    assumptionLoans.forEach((loanId) => {
      addDealBuilderAssetAssumptionLoan({ assetKey, loanId });
    });
    partialInterests.forEach((partialInterestId) => {
      addDealBuilderAssetPartialInterest({ assetKey, partialInterestId });
    });
    entireInterests.forEach((entireInterestId) => {
      addDealBuilderAssetEntireInterest({ assetKey, entireInterestId });
    });
  }

  async function retireLoan({ loanId }) {
    const apiRequestFunc = () =>
      api.post(`loan_retirings`, {
        loanId,
        changeGroupId: changeGroupId.value,
      });
    await changeGroupStore.originateData(apiRequestFunc);
    timeTravelStore.triggerRefetch();
  }
  // TODO: TRIGGER EXISTING PRINCIPALS REFETCH
  async function awardInvestment({ investmentId, companyInvolvementId }) {
    const apiRequestFunc = () =>
      api.post(`investment_awardings`, {
        investmentId,
        companyInvolvementId,
        changeGroupId: changeGroupId.value,
      });
    await changeGroupStore.originateData(apiRequestFunc);
  }
  async function awardGroupInvestment({ investmentGroupId, companyId }) {
    const apiRequestFunc = () =>
      api.post(`investment_group_awardings`, {
        investmentGroupId,
        rawCompanyId: companyId,
        changeGroupId: changeGroupId.value,
      });
    await changeGroupStore.originateData(apiRequestFunc);
  }
  async function dropAwardedInvestment({ investmentId }) {
    const apiRequestFunc = () =>
      api.delete(
        `investment_awardings/${investmentId}?change_group_id=${changeGroupId.value}`,
      );
    await changeGroupStore.originateData(apiRequestFunc);
  }
  async function dropAwardedGroupInvestment({ investmentGroupId }) {
    const apiRequestFunc = () =>
      api.delete(
        `investment_group_awardings/${investmentGroupId}?change_group_id=${changeGroupId.value}`,
      );
    await changeGroupStore.originateData(apiRequestFunc);
  }

  // FETCH AND DISPLAY DEAL PRINCIPALS

  async function refetchInvestment(assetObject, investment) {
    if (investment?.id) {
      const response = await api.get(`investments/${investment?.id}`);

      updateStoreInvestment({
        assetObject,
        existingInvestment: investment,
        newInvestment: response?.data,
      });
    }
  }

  function updateStoreInvestment({
    assetObject,
    existingInvestment,
    newInvestment,
  }) {
    if (groupId(existingInvestment)) {
      console.log("has existing group id");
      updateInvestmentGroupInvestment({
        groupId: groupId(existingInvestment),
        newInvestment,
      });
    } else if (matchingInvestmentGroup(existingInvestment, dealBuilder.value)) {
      console.log("has new group id");
      updateInvestmentGroupInvestment({
        groupId:
          matchingInvestmentGroup(existingInvestment, dealBuilder.value).id ||
          matchingInvestmentGroup(existingInvestment, dealBuilder.value)
            .placeholderId,
        newInvestment,
      });
    } else if (existingInvestment?.id && assetObject) {
      console.log("has investment id and asset");
      updateDealBuilderAssetInvestments({
        assetKey: decoratingAndFieldKey(assetObject.dataField),
        investments: [newInvestment],
      });
    } else {
      console.log("nothing to update!");
    }
  }

  async function linkInvestment(investment, linkedInvestmentId) {
    if (investment?.id && linkedInvestmentId) {
      const payload = {
        investmentId: investment?.id,
        historicalLinkedInvestmentId: linkedInvestmentId,
      };

      console.log(
        "link",
        investment?.id,
        "to",
        linkedInvestmentId,
        "payload",
        payload,
      );

      const apiRequestFunc = () =>
        api.post(`historical_investment_links`, payload);
      const successCallback = () => {
        timeTravelStore.triggerRefetch();
        return "success";
      };
      const failureCallback = () => {
        timeTravelStore.triggerRefetch();
        return "failure";
      };
      await changeGroupStore.originateData(
        apiRequestFunc,
        successCallback,
        failureCallback,
      );
    }
  }

  async function fetchPrincipalsFor({ assetObject, investment, maybePayload }) {
    let responsePayload = {
      capitalConsumers: [],
      capitalProviders: [],
      awardedCapitalProviders: [],
      emitMessage: null,
    };
    if (!unknownSupplySide(investment)) {
      responsePayload.capitalConsumers = await fetchSupplySidePrincipals({
        assetObject,
        investment,
        maybePayload,
      });
    }

    if (investment?.id) {
      responsePayload.capitalProviders = await fetchDemandSidePrincipals({
        investment,
        maybePayload,
      });

      if (awardable(investment)) {
        responsePayload.awardedCapitalProviders = await fetchDemandSideAwardees(
          { investment, maybePayload },
        );

        if (
          responsePayload.capitalProviders.length === 0 &&
          responsePayload.awardedCapitalProviders.length === 0 &&
          localCapitalProviders(investment, dealBuilder.value).length == 0
        ) {
          responsePayload.emitMessage = "editingDemand";
        }
      }
    } else {
      responsePayload.emitMessage = "editingDemand";
    }

    console.log("fetch investment principals", responsePayload);

    return responsePayload;
  }

  async function fetchSupplySidePrincipals({
    assetObject,
    investment,
    maybePayload,
  }) {
    console.log("fetch supply side cap mkts principals");
    const consumerFetchRequestKey = `investment_${
      investment?.id
    }_ownership_interests_${
      existingContentType({
        containerObject: assetObject,
        transaction: investment,
      }) || contentType(investment)
    }_${
      existingContentId({
        containerObject: assetObject,
        transaction: investment,
      }) || contentId(investment)
    }${mustBeClosed(investment) ? "&investment_role=capital_consumer" : ""}`;

    console.log(consumerFetchRequestKey);

    if (alreadyFetched(consumerFetchRequestKey) && !maybePayload?.override) {
      const alreadyFetched = alreadyFetchedFieldsFor(consumerFetchRequestKey);

      return alreadyFetched.filter((cdf) => {
        return (
          cdf.fieldContentType === "OwnershipInterest" &&
          cdf.decoratingContentType !== "Investment"
        );
      });
    } else if (
      existingContentType({
        containerObject: assetObject,
        transaction: investment,
      }) ||
      contentType(investment)
    ) {
      const json = await api.get(
        `ownership_interests/?content_type=${
          existingContentType({
            containerObject: assetObject,
            transaction: investment,
          }) || contentType(investment)
        }&content_id=${
          existingContentId({
            containerObject: assetObject,
            transaction: investment,
          }) || contentId(investment)
        }&as_of=${fetchMilliseconds(investment, asOfMilliseconds.value)}${
          mustBeClosed(investment) ? "&investment_role=capital_consumer" : ""
        }`,
      );

      if (json?.data) {
        interceptablePatch(json.data, consumerFetchRequestKey);
        return json.data;
      }
    }
  }

  async function fetchDemandSidePrincipals({ investment, maybePayload }) {
    console.log("fetch demand side cap mkts principals");
    if (
      alreadyFetched(providersFetchRequestKey(investment)) &&
      !maybePayload?.override
    ) {
      const alreadyFetched = alreadyFetchedFieldsFor(
        providersFetchRequestKey(investment),
      );

      return alreadyFetched.filter((cdf) => {
        return (
          cdf.fieldContentType === "OwnershipInterest" &&
          cdf.decoratingContentType === "Investment"
        );
      });
    } else {
      const investmentInterestsResponse = await api.get(
        `investment_ownership_interests/?investment_id=${investment?.id}`,
      );

      if (investmentInterestsResponse?.data) {
        interceptablePatch(
          investmentInterestsResponse.data,
          providersFetchRequestKey(investment),
        );
        return investmentInterestsResponse.data;
      }
    }
  }

  async function fetchDemandSideAwardees({ investment, maybePayload }) {
    console.log("fetch demand side cap mkts awardees");
    if (
      alreadyFetched(awardedProvidersFetchRequestKey(investment)) &&
      !maybePayload?.override
    ) {
      const alreadyFetched = alreadyFetchedFieldsFor(
        awardedProvidersFetchRequestKey(investment),
      );

      return alreadyFetched.filter((cdf) => {
        return cdf.fieldContentType === "CompanyInvolvement";
      });
    } else {
      const awardedInterestsResponse = await api.get(
        `investment_ownership_interests/?investment_id=${investment?.id}&conditional_modifier=awarded`,
      );

      if (awardedInterestsResponse?.data) {
        interceptablePatch(
          awardedInterestsResponse.data,
          awardedProvidersFetchRequestKey(investment),
        );
        return awardedInterestsResponse.data;
      }
    }
  }

  return {
    dealBuilder,
    dealBuilderCalculationData,
    refetchDealBuilderEditor,
    assetFieldIds,
    crossInteraction,
    investmentGroups,
    fetchRequests,
    dealBuilderPropertyDataFields,
    allFetchedFields,
    contactFieldSize,
    fetchingDealBuilderData,
    patchableCompanyInvolvementFieldIds,
    patchableCompanySize,
    refetchDataField,
    patchDiagramFields,
    patchPropertyDataFields,
    dropFetchRequest,
    postEditingPatch,
    interceptablePatch,
    matchingAssetObject,
    setDealBuilderAssetOwnershipInterests,
    setDealBuilderAssetTimelineFetchState,
    updateDealBuilderAssetInvestments,
    resetDealBuilderInvestments,
    upsertDealBuilder,
    newDealBuilder,
    loadTimelinePortfolioInvestment,
    loadPropertyRightTimelineInvestment,
    addDealBuilderAssets,
    addDealBuilderAsset,
    addDealBuilderAssetLoan,
    addDealBuilderInvestmentToGroup,
    fetchGroupTimingFields,
    checkGroupTimingUnlockability,
    removeDealBuilderAsset,
    removeDealBuilderInvestment,
    removeDealBuilderInvestmentGroup,
    addInvestmentGroupInvestmentPlayers,
    removeInvestmentGroupInvestmentPlayer,
    updateInvestmentGroupInvestment,
    saveInvestmentGroup,
    fetchInvestmentGroupInvestments,
    addDealBuilderInvestmentGroup,
    setDealBuilderAssetExpanded,
    setDealBuilderInvestmentGroupExpanded,
    collapsePortfolioAssets,
    collapseAllAssetsExcept,
    addDealBuilderAssetAssumptionLoan,
    addDealBuilderAssetEntireInterest,
    addDealBuilderAssetForeclosureLoan,
    addDealBuilderAssetPartialInterest,
    clearDealBuilderAssetManipulatedLoans,
    clearDealBuilderAssetIndividualOwnershipInterests,
    prepopulateDealBuilder,
    populateDealBuilderFromQuery,
    mountInvestmentMetadata,
    retireLoan,
    awardInvestment,
    awardGroupInvestment,
    dropAwardedInvestment,
    dropAwardedGroupInvestment,
    alreadyFetched,
    alreadyFetchedFieldsFor,
    clearDealBuilder,
    refreshInvestmentGroup,
    refetchInvestment,
    updateStoreInvestment,
    fetchPrincipalsFor,
    linkInvestment,
    fetchPropertyDataField,
    fetchDealBuilderCalculationData,
    calculationDataByKey,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useDealBuilderStore, import.meta.hot));
}
