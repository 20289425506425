<template>
  <div class="m-1 p-2 rounded-md bg-yellow-100 border border-yellow-600">
    <div class="flex justify-between">
      <div class="flex items-center space-x-1">
        <button
          v-if="_.includes(item.payloadId, 'temp')"
          @mouseenter="easyDataInputAdminStore.itemGrabHover(item.id)"
          @mouseleave="easyDataInputAdminStore.stopItemGrabHovering"
          type="button"
          class="text-yellow-600 cursor-grab"
        >
          <i class="fa-solid fa-grip-vertical"></i>
        </button>
        <span v-else>Wave {{ item.executionOrder + 1 }}</span>
        <div>
          <div class="mt-1 flex space-x-2">
            <div class="w-48">
              <!-- Text -->
              <legend class="sr-only">Narrative</legend>
              <div class="space-y-1">
                <div class="flex items-center justify-between">
                  <label
                    for="narrative"
                    class="block text-xs font-medium text-gray-900"
                    >Narrative</label
                  >
                  <a
                    v-if="detailedSelectedJob.narrative"
                    href=""
                    @click.prevent="appendJobNarrative"
                    v-tooltip="'Append job narrative'"
                    class="text-xs font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    <Square2StackIcon class="h-4 w-4" />
                  </a>
                </div>
                <textarea
                  v-model="narrative"
                  rows="5"
                  name="narrative"
                  id="narrative"
                  placeholder="Describe what needs to be added. (optional)"
                  class="block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 text-xs"
                />
              </div>
            </div>
            <div class="space-y-1">
              <!-- Grading -->
              <PayloadItemDataValue :item="item" class="w-56" />
              <PayloadItemInputComplexity :item="item" class="w-56" />
              <PayloadItemReferred :item="item" class="w-56" />
            </div>
          </div>
          <div
            v-if="existingFiles.length > 0"
            class="mt-2 flex items-center space-x-1"
          >
            <div
              v-tooltip="'Link existing job files'"
              class="relative rounded-md shadow-sm max-w-[423px]"
            >
              <div
                class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2"
              >
                <PaperClipIcon
                  class="h-4 w-4 text-gray-400 z-20"
                  aria-hidden="true"
                />
              </div>
              <ExistingFileAutocomplete
                :input-key="`item-${item.id}-existing-files`"
                :focus="false"
                :selections="itemExistingFiles"
                :remove-inline="true"
                @new-files="(newFiles) => (itemExistingFiles = newFiles)"
                @remove="
                  (toRemove) =>
                    (itemExistingFiles = _.differenceBy(
                      itemExistingFiles,
                      [toRemove],
                      'name',
                    ))
                "
              />
            </div>
          </div>
          <div class="mt-1">
            <div v-if="filesDisplayable" class="space-y-1">
              <!-- File -->
              <div class="space-y-1">
                <label
                  for="files"
                  class="block text-xs font-medium text-gray-900"
                  >New File(s)</label
                >
                <FileDragDrop
                  local-commit="dataInputJobPayloadItem"
                  :direct-write="item"
                  :file-types="'image/*,' + all.join(',')"
                  file-type-labels="XLS, PPT, DOC, PDF, PNG, JPG, GIF"
                  :size-limit="100"
                  :multiple="true"
                  :full-width="true"
                  :compact="true"
                />
              </div>
            </div>
          </div>
          <div
            v-if="!_.includes(item.payloadId, 'temp')"
            class="p-2 flex items-center justify-end"
          >
            <button
              @click="easyDataInputAdminStore.appendPayloadItem(item)"
              type="button"
              class="inline-flex justify-center py-2 px-4 border border-transparent bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2"
            >
              Save
            </button>
          </div>
        </div>
      </div>
      <button
        @click="removePayloadItem(item)"
        type="button"
        v-tooltip="'Remove item'"
        class="ml-2 flex-shrink-0 inline-flex items-center text-gray-700 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
      >
        <XMarkIcon class="h-4 w-4" />
      </button>
    </div>
  </div>
</template>

<script setup>
import {
  PaperClipIcon,
  Square2StackIcon,
  XMarkIcon,
} from "@heroicons/vue/20/solid";
import { ref, computed } from "vue";
import { all } from "@/assets/documentFileTypes";
import { storeToRefs } from "pinia";
import { useEasyDataInputAdminStore } from "@/stores/easyDataInputAdmin";
import PayloadItemDataValue from "@/views/admin/PayloadItemDataValue.vue";
import PayloadItemInputComplexity from "@/views/admin/PayloadItemInputComplexity.vue";
import PayloadItemReferred from "@/views/admin/PayloadItemReferred.vue";
import FileDragDrop from "@/components/crowdsourcing/FileDragDrop.vue";
import _ from "lodash";
import ExistingFileAutocomplete from "./ExistingFileAutocomplete.vue";

const props = defineProps(["item"]);

const easyDataInputAdminStore = useEasyDataInputAdminStore();
const { detailedSelectedJobCRUD, detailedSelectedJob } = storeToRefs(
  easyDataInputAdminStore,
);

const filesDisplayable = ref(true);
const writableItem = computed(() =>
  _.find(payloadItems.value, { id: props.item.id }),
);
const narrative = computed({
  get() {
    return writableItem.value.narrative;
  },
  set(str) {
    writableItem.value.narrative = str;
  },
});

const payloadItems = computed({
  get() {
    return detailedSelectedJobCRUD.value?.payloadItems;
  },
  set(newArr) {
    detailedSelectedJobCRUD.value.payloadItems = newArr;
  },
});

const existingFiles = computed(() => detailedSelectedJob.value.files);
const itemExistingFiles = computed({
  get() {
    return writableItem.value.existingFiles;
  },
  set(arr) {
    writableItem.value.existingFiles = arr;
  },
});

function removePayloadItem({ id, payloadId, executionOrder }) {
  _.remove(payloadItems.value, function (item) {
    return (
      id === item.id &&
      payloadId === item.payloadId &&
      executionOrder === item.executionOrder
    );
  });
}

function appendJobNarrative() {
  const existingNarrative = narrative.value ? `${narrative.value}\n\n` : "";
  narrative.value = `${existingNarrative}${detailedSelectedJob.value.narrative}`;
}
</script>
