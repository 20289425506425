<template>
  <div class="flex items-center justify-between">
    <h3
      class="flex min-w-0 flex-1 items-center text-lg font-medium leading-6 text-gray-900"
    >
      My Lists
    </h3>
    <div v-if="isDesktop" class="flex flex-shrink-0">
      <button
        @click.prevent="newList"
        type="button"
        class="relative inline-flex items-center rounded-md border border-gray-300 bg-white p-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        data-test="create-task-list-button"
      >
        <span>Add List</span>
      </button>
    </div>
  </div>
  <div class="bg-white shadow rounded-md">
    <ul
      role="list"
      id="task-list-container"
      class="relative divide-y divide-gray-200"
    >
      <li
        v-for="list in effectiveTaskLists"
        :key="list.id"
        :id="`${list.accessTokenId}-list`"
        @mouseenter="hover(list.id)"
        @mouseleave="stopHovering"
        :class="signedIn ? 'absolute muuri-item' : ''"
        class="w-full bg-white"
      >
        <div class="flex items-center">
          <button
            v-if="hovering === list.id"
            type="button"
            class="pl-2 text-gray-400 cursor-grab"
          >
            <i class="fa-solid fa-grip-vertical"></i>
          </button>
          <a
            @click.prevent="selectedTaskList = list"
            href=""
            class="flex-grow block hover:bg-gray-50"
            :data-test="`${_.camelCase(list.name)}-list-item`"
          >
            <div class="flex items-center p-2">
              <div class="flex min-w-0 flex-1 items-center">
                <div
                  :class="`${taskListStore.colorFor(list)}`"
                  class="rounded-full p-1.5"
                >
                  <UserGroupIcon
                    v-if="list.shared"
                    class="h-5 w-5 text-white"
                    aria-hidden="true"
                  />
                  <ListBulletIcon
                    v-else
                    class="h-5 w-5 text-white"
                    aria-hidden="true"
                  />
                </div>
                <div class="min-w-0 flex-1 px-4">
                  <p class="truncate text-sm font-medium text-gray-700">
                    {{ list.name }}
                  </p>
                </div>
              </div>
              <div class="flex items-center space-x-1">
                <p class="truncate text-sm font-medium text-gray-500">
                  {{ tasksStore.taskCountFor(list) }}
                </p>
                <ChevronRightIcon
                  class="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
            </div>
          </a>
        </div>
      </li>
      <InfiniteLoading
        v-if="signedIn && taskListPagy && taskListPagy.next"
        @infinite="loadLists"
      />
    </ul>
  </div>
</template>

<script setup>
import Muuri from "muuri";
import {
  ListBulletIcon,
  ChevronRightIcon,
  UserGroupIcon,
} from "@heroicons/vue/20/solid";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { useTasksStore } from "@/stores/tasks";
import { useTaskListStore } from "@/stores/taskList";
import { useUserStore } from "@/stores/user";
import { storeToRefs } from "pinia";
import { ref, computed, onMounted } from "vue";
import api from "@/router/api";
import _ from "lodash";
import { useRoute } from "vue-router";

const tasksStore = useTasksStore();
const {
  addingList,
  effectiveTaskLists,
  selectedTaskList,
  taskLists,
  taskListPagy,
} = storeToRefs(tasksStore);
const taskListStore = useTaskListStore();
const layoutStore = useWorkspaceLayoutStore();
const { isDesktop } = storeToRefs(layoutStore);
const userStore = useUserStore();
const { signedIn } = storeToRefs(userStore);
const route = useRoute();
const query = computed(() => route.query);
const queryList = computed(() => _.get(query.value, "taskList"));
const hovering = ref(null);
const taskListGrid = ref(null);

onMounted(() => {
  tasksStore.fetchLists().then(async () => {
    setTimeout(() => {
      handleQueryListNavigation();
      const listEl = document.getElementById(`task-list-container`);
      if (signedIn.value && listEl) {
        taskListGrid.value = new Muuri(`#task-list-container`, {
          dragEnabled: true,
          dragAxis: "y",
        });
        taskListGrid.value.on("dragEnd", function () {
          const gridItems = taskListGrid.value.getItems();
          const orderables = gridItems.map((item, index) => {
            const accessTokenId = _.toNumber(_.split(item._element.id, "-")[0]);

            return {
              accessTokenId,
              order: index,
            };
          });
          updateListOrder(orderables);
        });
      }
    }, 500);
  });
});

function hover(listId) {
  if (signedIn.value) {
    stopHovering.cancel();
    hovering.value = listId;
  }
}
const stopHovering = _.debounce(function () {
  setTimeout(() => {
    hovering.value = null;
  }, 250);
}, 750);

function updateListOrder(orderableLists) {
  if (signedIn.value) {
    const payload = {
      orderableLists,
    };
    api.patch(`task_list_ordering`, payload).then(() => {});
  }
}

function handleQueryListNavigation() {
  if (signedIn.value) {
    const matchingList = _.find(effectiveTaskLists.value, function (list) {
      return (
        queryList.value == list.accessTokenId || queryList.value == list.name
      );
    });

    if (matchingList) {
      selectedTaskList.value = matchingList;
    } else {
      tasksStore.fetchTasks();
    }
  }
}

function newList() {
  addingList.value = true;
}

function cleanUrl(url) {
  return url.replace("/api/v1/", "");
}
const loadLists = async ($state) => {
  if (signedIn.value && taskListPagy.value.next) {
    const endpoint = cleanUrl(taskListPagy.value.next_url);
    try {
      api.get(endpoint).then((json) => {
        const { data, pagy } = json.data;

        taskLists.value.data = _.concat(taskLists.value.data, data);
        taskLists.value.pagy = pagy;
        if (data.length < 20) $state.complete();
        else {
          $state.loaded();
        }
      });
    } catch (error) {
      $state.error();
    }
  } else {
    $state.complete();
  }
};
</script>

<style scoped>
.muuri-item.muuri-item-dragging {
  z-index: 53;
}
.muuri-item.muuri-item-releasing {
  z-index: 52;
}
.muuri-item.muuri-item-hidden {
  z-index: 50;
}
</style>
