<template>
  <div
    class="w-full flex flex-col bg-white rounded-md border border-gray-300 mt-2"
  >
    <h2 class="p-2 text-gray-700 font-semibold text-sm">
      {{
        focalInvestmentId
          ? "Potential Duplicate Investments"
          : "Portfolio Investments"
      }}
    </h2>
    <PotentialDuplicateInvestment
      v-for="investment in sortedInvestments"
      :key="investment.localId"
      :investment="investment"
      :focal-investment-id="focalInvestmentId"
      :investment-valuations="investmentValuations"
    />
  </div>
</template>

<script setup>
import PotentialDuplicateInvestment from "@/components/crowdsourcing/PotentialDuplicateInvestment.vue";
import { computed } from "vue";
import moment from "moment";
import _ from "lodash";

const props = defineProps([
  "potentialDuplicateInvestments",
  "focalInvestmentId",
  "investmentValuations",
]);

const sortedInvestments = computed(() => {
  return _.orderBy(
    props.potentialDuplicateInvestments,
    [
      function (investmentField) {
        const rawDate = investmentField.fieldContent?.date;

        if (rawDate) {
          return moment(rawDate).valueOf();
        } else {
          return 0;
        }
      },
    ],
    ["desc"],
  );
});
</script>
