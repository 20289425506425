<template>
  <div class="h-full rounded-lg overflow-hidden">
    <CardBranding :card="card" style="z-index: 9999" />
    <div class="absolute inset-0">
      <div
        class="absolute inset-0 bg-gradient-to-r from-orange-500 to-pink-600 mix-blend-multiply"
      />
    </div>
    <div class="h-full relative flex flex-col">
      <div
        v-if="propertyId && locationCenter"
        :id="`development-location-map-${propertyId}-${dateDecoratingId}-${context}`"
        class="w-full bg-gray-100"
        style="height: 237.5px"
      />
      <div v-else class="w-full bg-gray-100" style="height: 237.5px" />
      <div class="mt-5 flex flex-col items-center">
        <h1
          v-if="cardData?.property"
          class="flex max-w-[18rem] justify-center text-center text-2xl font-bold tracking-tight space-x-2"
        >
          <span class="text-white">{{
            cardData.property.fieldContent.name
          }}</span>
        </h1>
        <p
          v-if="cardData"
          class="max-w-[16rem] text-center font-medium text text-pink-200"
        >
          {{ dateType }}
        </p>
        <p
          v-if="dateOutput"
          class="max-w-[16rem] text-center font-medium text-sm text-pink-300"
        >
          {{ dateOutput }}
        </p>
        <div
          v-if="propertyId && isDesktop"
          class="pb-3 flex flex-col items-center"
        >
          <a
            href=""
            @click.prevent="viewDiagram"
            class="mt-6 flex items-center justify-center rounded-md border border-transparent bg-white px-3 py-2 text-base font-medium text-pink-700 shadow-sm hover:bg-pink-50"
            >View Property</a
          >
          <a
            href=""
            @click.prevent="centerOnMap"
            class="mt-2 flex items-center justify-center rounded-md border border-white px-3 py-2 text-base font-medium text-white shadow-sm hover:border-pink-50 hover:text-pink-50"
            >Center on Map</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
/* global L */
import mapStyles from "@/components/maps/mapStyles";
import locationMarker from "@/components/maps/locationMarker";
import CardBranding from "@/components/collectible-cards/CardBranding.vue";
import { usePropertyDiagramStore } from "@/stores/propertyDiagram";
import { useMainMapStore } from "@/stores/mainMap";
import { usePropertyFieldsStore } from "@/stores/propertyFields";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { storeToRefs } from "pinia";
import api from "@/router/api";
import { ref, computed, watch, onMounted, nextTick } from "vue";
import _ from "lodash";
import moment from "moment";

const props = defineProps([
  "card",
  "cardIndex",
  "stackIndex",
  "completedCardIds",
  "displayBack",
  "generic",
  "context",
  "timelineOverrideField",
]);

const propertyDiagramStore = usePropertyDiagramStore();
const mapStore = useMainMapStore();
const propertyFieldsStore = usePropertyFieldsStore();
const { temporaryMapCenter, newOriginOverride } = storeToRefs(mapStore);
const layoutStore = useWorkspaceLayoutStore();
const { isDesktop } = storeToRefs(layoutStore);

const cardData = ref(null);
const propertyId = computed(() =>
  _.get(cardData.value, "property.fieldContentId"),
);
const dateDecoratingId = computed(() => {
  if (props.timelineOverrideField) {
    return `${props.timelineOverrideField.decoratingContentType}-${props.timelineOverrideField.decoratingContentId}`;
  } else if (cardData.value) {
    return `${cardData.value?.latestDateField?.decoratingContentType}-${cardData.value?.latestDateField?.decoratingContentId}`;
  } else {
    return null;
  }
});
const dateType = computed(() => {
  if (props.timelineOverrideField) {
    return _.startCase(
      _.replace(props.timelineOverrideField.fieldName, "_date", ""),
    );
  } else if (cardData.value) {
    return _.startCase(
      _.replace(cardData.value?.latestDateField?.fieldName, "_date", ""),
    );
  } else {
    return "Development Alert";
  }
});
const dateOutput = computed(() => {
  if (props.timelineOverrideField) {
    return moment(props.timelineOverrideField.fieldValue).format("MMMM YYYY");
  } else if (cardData.value) {
    return moment(cardData.value.latestDateField.fieldDate).format("MMMM YYYY");
  } else {
    return null;
  }
});
const locationCenter = computed(() => {
  const lat = _.get(
    cardData.value,
    "property.fieldContent.locationDataField.fieldContent.lat",
  );
  const lng = _.get(
    cardData.value,
    "property.fieldContent.locationDataField.fieldContent.lng",
  );

  if (lat && lng) {
    return [_.toNumber(lat), _.toNumber(lng)];
  } else {
    return null;
  }
});
const map = ref(null);
const baseLayers = ref({
  Base: L.gridLayer.googleMutant({
    type: "roadmap",
    styles: mapStyles.styles,
  }),
});

watch(
  () => props.stackIndex,
  () => {
    fetchCardData();
  },
);

onMounted(() => {
  var tempTimelineField = props.timelineOverrideField;
  fetchCardData();
});

async function fetchCardData() {
  if (
    props.stackIndex === props.cardIndex &&
    !_.includes(props.completedCardIds, props.card.id)
  ) {
    const cardDataResponse = await api.get(
      `unlocked_development_card_data/${
        props.card.collectibleCardId || props.card.id
      }?generic=${props.generic}`,
    );
    cardData.value = cardDataResponse.data;

    await nextTick();
    mountMap();
  }
}

function mountMap() {
  if (!map.value) {
    let blockEl = document.getElementById(
      `development-location-map-${propertyId.value}-${dateDecoratingId.value}-${props.context}`,
    );

    if (!blockEl) return;

    map.value = L.map(
      `development-location-map-${propertyId.value}-${dateDecoratingId.value}-${props.context}`,
      {
        scrollWheelZoom: false,
        zoomControl: false,
        dragging: false,
        boxZoom: false,
        doubleClickZoom: false,
        zoomAnimation: false,
        fadeAnimation: true,
        markerZoomAnimation: true,
      },
    ).setView(locationCenter.value, 13);

    baseLayers.value["Base"].addTo(map.value);
    addMarker();
  }
}

function addMarker() {
  const markerIcon = locationMarker({
    classes: "h-5 w-5 bg-orange-600 hover:bg-orange-700 focus:ring-orange-500",
    interactive: false,
    mapStore,
    propertyFieldsStore,
  });
  L.marker(locationCenter.value, {
    interactive: false,
    icon: markerIcon,
  }).addTo(map.value);
}

function viewDiagram() {
  newOriginOverride.value = true;
  propertyDiagramStore.navigateToDiagram(propertyId.value, true);
}
function centerOnMap() {
  newOriginOverride.value = true;
  temporaryMapCenter.value = {
    lat: locationCenter.value[0],
    lng: locationCenter.value[1],
    zoom: 16.5,
  };
}
</script>
