<template>
  <div class="flex-shrink-0">
    (<span v-tooltip="'Accepted'" class="text-green-500"
      >+{{ _.size(acceptVotes) }},
      {{ currencyAmount(votePercent(_.size(acceptVotes), voteCount)) }}% </span
    >;
    <span v-tooltip="'Rejected'" class="text-red-500"
      >-{{ _.size(rejectVotes) }},
      {{ currencyAmount(votePercent(_.size(rejectVotes), voteCount)) }}% </span
    >,
    <span v-tooltip="'Skipped'" class="text-blue-500"
      >? {{ _.size(skipVotes) }},
      {{ currencyAmount(votePercent(_.size(skipVotes), voteCount)) }}%</span
    >)
  </div>
</template>

<script setup>
import _ from "lodash";
import { computed } from "vue";
import { currencyAmount } from "@/assets/numberHelpers";

const props = defineProps(["validationSubmissions"]);

const voteCount = computed(() => _.size(props.validationSubmissions));
const acceptVotes = computed(() =>
  props.validationSubmissions.filter((v) => v.validationInput === "accept"),
);
const rejectVotes = computed(() =>
  props.validationSubmissions.filter((v) => v.validationInput === "reject"),
);
const skipVotes = computed(() =>
  props.validationSubmissions.filter((v) => v.validationInput === "skip"),
);

function votePercent(targetVotes, totalVotes) {
  if (totalVotes === 0) {
    return 0;
  } else {
    return (targetVotes / totalVotes) * 100;
  }
}
</script>
