<template>
  <div class="mt-4 p-2">
    <div class="flex items-center">
      <div class="flex-auto">
        <h1 class="text-base font-semibold leading-6 text-gray-900">
          Investments
        </h1>
        <p class="mt-2 text-sm text-gray-700">
          Use this table to identify datapoints of interest.
        </p>
      </div>
    </div>
    <div v-if="zoom >= 11" class="mt-4 flow-root">
      <div
        v-if="refreshingMainTimeline"
        class="mt-6 flex h-full w-full items-center justify-center"
      >
        <SquareLoader :loading="true" size="32px" color="#0d9488" />
      </div>
      <div
        v-else-if="paginatedRecords.length > 0"
        class="-my-2 overflow-x-auto"
      >
        <div class="inline-block min-w-full py-2 align-middle">
          <table class="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th scope="col" class="relative whitespace-nowrap p-3">
                  <span class="sr-only">Expand</span>
                </th>
                <th
                  scope="col"
                  class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Investment
                </th>
                <th
                  scope="col"
                  class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Area
                </th>
                <th
                  scope="col"
                  class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Contacts
                </th>
                <th
                  scope="col"
                  class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Value (Type)
                </th>
                <th
                  scope="col"
                  class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  $/Unit
                </th>
                <th
                  v-if="false"
                  scope="col"
                  class="relative whitespace-nowrap py-3.5 pl-3"
                >
                  <span class="sr-only">Edit</span>
                </th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200 bg-white">
              <InvestmentTableRow
                v-for="record in paginatedRecords"
                :key="`${record.type}${record.id}`"
                :record="record"
              />
            </tbody>
            <InfiniteLoading
              v-if="loaded && !reachedEnd"
              @infinite="infiniteNext"
            />
          </table>
        </div>
      </div>
      <div
        v-else
        class="relative block w-full rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        <FunnelIcon class="mx-auto h-12 w-12 text-gray-400" />
        <span class="mt-2 block text-sm font-medium text-gray-900">
          No matching investments
        </span>
      </div>
    </div>
    <section v-else class="mt-4">
      <div
        class="relative block w-full rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        <MagnifyingGlassPlusIcon class="mx-auto h-12 w-12 text-gray-400" />
        <span class="mt-2 block text-sm font-medium text-gray-900">
          Zoom in more
        </span>
      </div>
    </section>
  </div>
</template>

<script setup>
import SquareLoader from "vue-spinner/src/SquareLoader.vue";
import InvestmentTableRow from "@/components/analyze/InvestmentTableRow.vue";
import { MagnifyingGlassPlusIcon, FunnelIcon } from "@heroicons/vue/24/outline";
import { useTimeTravelStore } from "@/stores/timeTravel";
import { useAnalyzePanelStore } from "@/stores/analyzePanel";
import { useMainMapStore } from "@/stores/mainMap";
import { storeToRefs } from "pinia";
import { computed, onMounted, ref, watch } from "vue";
import _ from "lodash";

const timeTravelStore = useTimeTravelStore();
const { datedInvestmentFields, datedInvestmentIds, refreshingMainTimeline } =
  storeToRefs(timeTravelStore);
const mapStore = useMainMapStore();
const { zoom } = storeToRefs(mapStore);
const analyzePanelStore = useAnalyzePanelStore();
const {
  filteredInvestmentLikes,
  combinedFilteredInvestmentIds,
  lastCheckedPolygonArea,
  investmentLikeFilterable,
} = storeToRefs(analyzePanelStore);

const nearbyPageIncrement = 5;
const fetchedNearbyIndex = ref(0);
const loaded = ref(false);
const reachedEnd = ref(false);
const paginatedRecords = ref([]);

const datedMixedTypeIds = computed(() => {
  let rawRecords = datedInvestmentFields.value;

  const ids = _.intersection(
    datedInvestmentIds.value,
    combinedFilteredInvestmentIds.value,
  );
  rawRecords = rawRecords.filter((dataField) =>
    _.includes(ids, dataField.fieldContentId),
  );

  const typedRecords = rawRecords.map((dataField) => {
    if (dataField.portfolioId) {
      if (investmentLikeFilterable.value) {
        return [
          { id: dataField.portfolioId, type: "InvestmentGroup" },
          { id: dataField.fieldContentId, type: "Investment" },
        ];
      } else {
        return { id: dataField.portfolioId, type: "InvestmentGroup" };
      }
    } else {
      return { id: dataField.fieldContentId, type: "Investment" };
    }
  });

  const uniqueTyped = _.uniqBy(
    _.flatten(typedRecords),
    function ({ id, type }) {
      return `${type}${id}`;
    },
  );

  if (investmentLikeFilterable.value) {
    return _.intersectionBy(
      uniqueTyped,
      filteredInvestmentLikes.value,
      function ({ id, type }) {
        return `${type}${id}`;
      },
    );
  } else {
    return uniqueTyped;
  }
});
const mixedIdCount = computed(() => _.size(datedMixedTypeIds.value));

watch(mixedIdCount, () => {
  if (loaded.value) {
    debouncedReset();
  }
});

watch(lastCheckedPolygonArea, () => {
  if (loaded.value) {
    debouncedReset();
  }
});

onMounted(() => {
  if (fetchedNearbyIndex.value === 0) {
    nextNearbyPage("initial");
  }
});

function reset() {
  paginatedRecords.value = [];
  fetchedNearbyIndex.value = 0;
  reachedEnd.value = false;
  nextNearbyPage();
}

const debouncedReset = _.debounce(function () {
  reset();
}, 2000);

const infiniteNext = async ($state) => {
  if (!reachedEnd.value) {
    try {
      nextNearbyPage();
    } catch (error) {
      $state.error();
    }
  } else {
    $state.complete();
  }
};

function nextNearbyPage(loadState = null) {
  const arr = datedMixedTypeIds.value;
  const length = arr.length;
  const start = fetchedNearbyIndex.value;
  const end = _.min([length, fetchedNearbyIndex.value + nearbyPageIncrement]);
  const newSlice = _.slice(arr, start, end);

  newSlice.forEach((element) => {
    paginatedRecords.value.push(element);
  });

  if (paginatedRecords.value.length !== length) {
    fetchedNearbyIndex.value = fetchedNearbyIndex.value + nearbyPageIncrement;
  } else {
    reachedEnd.value = true;
  }

  if (loadState === "initial") loaded.value = true;
}
</script>
