<template>
  <div class="my-1 space-y-2">
    <div
      v-for="advisorField in filteredAdvisors"
      :key="advisorField.localId"
      class="flex items-center space-x-2"
    >
      <DataVisibilityButton visibility="safezone" class="w-full flex">
        <template v-slot:button>
          <button
            @click="selected = advisorField"
            type="button"
            :class="
              selected?.localId === advisorField.localId
                ? 'bg-yellow-100'
                : 'hover:bg-gray-50'
            "
            class="group p-1.5 w-full flex items-center justify-between rounded-full border border-gray-300 shadow-sm space-x-2 text-left focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <span class="min-w-0 flex-1 flex items-center space-x-2">
              <span class="block flex-shrink-0">
                <span
                  class="inline-flex items-center justify-center h-6 w-6 rounded-full bg-yellow-500"
                >
                  <LinkIcon class="h-4 w-4 text-white" />
                </span>
              </span>
              <span class="block min-w-0 flex-1">
                <span class="block text-sm font-medium text-gray-900 truncate"
                  >Link to {{ advisorField.fieldContent?.name }}</span
                >
                <div
                  class="flex items-center space-x-1 text-gray-600 text-xs truncate"
                >
                  <span>{{
                    advisorField.advisorRoles
                      ?.map(({ fieldContent }) => fieldContent.name)
                      ?.join(", ")
                  }}</span>
                  <template
                    v-if="
                      advisorField.advisorContacts &&
                      advisorField.advisorContacts.length > 0
                    "
                  >
                    <span>&middot;</span>
                    <span>{{
                      advisorField.advisorContacts
                        ?.map(({ fieldContent }) => fieldContent.name)
                        ?.join(", ")
                    }}</span>
                  </template>
                </div>
              </span>
            </span>
          </button>
        </template>
      </DataVisibilityButton>
      <template v-if="selected?.localId === advisorField.localId">
        <button
          @click="selected = null"
          type="button"
          class="inline-flex items-center p-1.5 border border-gray-300 rounded-full shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <XMarkIcon class="h-4 w-4" />
        </button>

        <DataVisibilityButton visibility="safezone" class="inline-flex">
          <template v-slot:button>
            <button
              @click="saveLinkage"
              :disabled="originatingData"
              type="button"
              class="flex-shrink-0 inline-flex items-center p-1.5 border border-transparent rounded-full shadow-sm text-white bg-yellow-500 hover:bg-yellow-600 focus:ring-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2"
            >
              <PulseLoader
                v-if="originatingData"
                :loading="true"
                size="3px"
                color="#f3f4f6"
              />
              <CheckIcon v-else class="h-4 w-4" />
            </button>
          </template>
        </DataVisibilityButton>
      </template>
    </div>
  </div>
</template>

<script setup>
import { XMarkIcon, CheckIcon } from "@heroicons/vue/20/solid";
import { LinkIcon } from "@heroicons/vue/24/outline";
import { useTimeTravelStore } from "@/stores/timeTravel";
import { storeToRefs } from "pinia";
import { useCrowdsourcedChangeGroupStore } from "@/stores/crowdsourcedChangeGroup";
import { onMounted, computed, ref, nextTick } from "vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import DataVisibilityButton from "@/components/crowdsourcing/DataVisibilityButton.vue";
import api from "@/router/api";
import _ from "lodash";

const props = defineProps([
  "ownershipInterestField",
  "involvementRoleNames",
  "availabilityId",
  "availabilityGroupId",
]);
const emit = defineEmits(["refetch"]);
const timeTravelStore = useTimeTravelStore();
const { asOfMilliseconds } = storeToRefs(timeTravelStore);
const changeGroupStore = useCrowdsourcedChangeGroupStore();
const { originatingData, changeGroupId } = storeToRefs(changeGroupStore);

const selected = ref(null);
const advisors = ref([]);
const content = computed(() => {
  return _.get(props.ownershipInterestField, "fieldContent");
});
const contentId = computed(() => {
  return _.get(props.ownershipInterestField, "fieldContentId");
});
const contentType = computed(() => {
  return _.get(props.ownershipInterestField, "fieldContentType");
});
const filteredAdvisors = computed(() => {
  // TODO: Guard against locked fields...
  return advisors.value.filter((appendedDataField) => {
    if (appendedDataField.advisorRoles) {
      return _.some(appendedDataField.advisorRoles, function (roleDataField) {
        return _.includes(
          props.involvementRoleNames,
          roleDataField.fieldContent?.name,
        );
      });
    } else {
      return false;
    }
  });
});

onMounted(() => {
  fetchAdvisors();
});

async function fetchAdvisors() {
  const advisorsResponse = await api.get(
    `operational_advisors/${contentType.value}/${contentId.value}?as_of=${asOfMilliseconds.value}`,
  );
  advisors.value = advisorsResponse?.data;

  for (const involvementField of advisors.value) {
    const rolesResponse = await api.get(
      `crowdsourced_data_fields/${involvementField.fieldContentType}/${involvementField.fieldContentId}?field_name=InvolvementRole`,
    );

    involvementField.advisorRoles = rolesResponse?.data;

    await nextTick();

    if (
      _.find(filteredAdvisors.value, {
        fieldContentId: involvementField.fieldContentId,
      })
    ) {
      const contactsResponse = await api.get(
        `crowdsourced_data_fields/${involvementField.fieldContentType}/${involvementField.fieldContentId}?field_name=ContactCompanyInvolvement`,
      );

      involvementField.advisorContacts = contactsResponse?.data;
    }
  }
}

async function persistLinkage() {
  const contentType = props.availabilityId
    ? "SpaceAvailability"
    : "SpaceAvailabilityGroup";
  const payload = {
    contentType,
    contentId: props.availabilityId || props.availabilityGroupId,
    advisorCompanyInvolvementId: selected.value.fieldContentId,
    roleNames: props.involvementRoleNames,
    advisorContactInvolvementFieldIds: selected.value.advisorContacts
      ? selected.value.advisorContacts.map((dataField) => dataField.localId)
      : [],
    changeGroupId: changeGroupId.value,
  };
  console.log("save", payload);

  const response = await api.post(
    `existing_advisor_company_involvement_linkages`,
    payload,
  );

  return response;
}

async function saveLinkage() {
  setTimeout(() => {
    if (selected.value) {
      const apiRequestFunc = () => persistLinkage();
      const successCallback = async () => {
        selected.value = null;
        emit("refetch", { override: true });
      };
      const failureCallback = () => (selected.value = null);

      return changeGroupStore.originateData(
        apiRequestFunc,
        successCallback,
        failureCallback,
      );
    }
  }, 125);
}
</script>
