<template>
  <main v-if="myAccountActive && selectedTab" class="h-full flex flex-1">
    <nav aria-label="Sidebar" class="shrink-0 p-2 bg-gray-100 overflow-y-auto">
      <div class="relative flex flex-col space-y-2">
        <a
          v-for="tab in tabs"
          :key="tab.name"
          @click.prevent="selectTab(tab)"
          :href="tab.href"
          v-tooltip="tab.name"
          :class="[
            selectedTab.name === tab.name
              ? 'text-gray-700 bg-gray-300'
              : 'text-gray-500 hover:bg-gray-200',
            'flex-shrink-0 inline-flex items-center justify-center h-8 w-8 rounded-lg',
          ]"
          :data-test="`${_.kebabCase(tab.tabQuery)}-nav`"
        >
          <span class="sr-only">{{ tab.name }}</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            :class="selectedTab.name === tab.name ? '' : ''"
            class="h-5 w-5"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 24 24"
            v-html="pathsForIcon(tab.svgPaths)"
          ></svg>
        </a>
      </div>
    </nav>
    <div class="flex-grow overflow-x-hidden">
      <component :is="selectedTab.component" v-bind="selectedTabProperties" />
    </div>
  </main>
</template>

<script setup>
import { useSecondaryPanelStore } from "@/stores/secondaryPanel";
import { ref, computed, onMounted, watch, markRaw } from "vue";
import { useRouter, useRoute } from "vue-router";
import _ from "lodash";
import { storeToRefs } from "pinia";
import pathsForIcon from "@/assets/pathsForIcon";
import AccountSettings from "@/components/users/AccountSettings.vue";

const secondaryPanelStore = useSecondaryPanelStore();
const { myAccountActive } = storeToRefs(secondaryPanelStore);

const tabs = [
  {
    name: "Settings",
    tabQuery: "Settings",
    href: "",
    svgPaths: [
      "M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z",
      "M15 12a3 3 0 11-6 0 3 3 0 016 0z",
    ],
    component: markRaw(AccountSettings),
  },
];
const selectedTab = ref(null);
const selectedTabProperties = {};
const horizontalIsAccount = computed(
  () => _.get(query.value, "horizontalTab") === "Account"
);

const router = useRouter();
const route = useRoute();
const query = computed(() => route.query);

function selectTab(tab) {
  const oldTabQuery = _.get(selectedTab.value, "tabQuery");

  return new Promise((resolve) => {
    if (tab) selectedTab.value = tab;
    else if (horizontalIsAccount.value) {
      const foundTab = _.find(tabs, {
        tabQuery: query.value.verticalTab || "Settings",
      });
      const defaultTab = _.find(tabs, {
        tabQuery: "Settings",
      });
      selectedTab.value = foundTab || defaultTab;
    }

    const newTabQuery = _.get(selectedTab.value, "tabQuery");
    if (
      myAccountActive.value &&
      selectedTab.value &&
      newTabQuery !== oldTabQuery
    ) {
      router.push({
        name: route.name,
        query: {
          ...route.query,
          verticalTab: selectedTab.value.tabQuery,
        },
      });
    }

    resolve();
  });
}

onMounted(() => {
  selectTab();
});

watch(query, () => {
  selectTab();
});
</script>
