import _ from "lodash";
import moment from "moment";

export function inPlaceRentFields(spaceUsage) {
  const baseRentScheduleField = spaceUsage?.baseRentSchedule;
  const commencementField = spaceUsage?.commencedField;
  const expirationField = spaceUsage?.expirationField;
  const perAreaSizeField = spaceUsage.space?.standardizedAreaField;

  return {
    baseRentScheduleField,
    commencementField,
    expirationField,
    perAreaSizeField,
  };
}

export function inPlaceRent(spaceUsage, timeTravelTo) {
  const {
    baseRentScheduleField,
    commencementField,
    expirationField,
    perAreaSizeField,
  } = inPlaceRentFields(spaceUsage);
  const allFields = [
    baseRentScheduleField,
    commencementField,
    expirationField,
    perAreaSizeField,
  ];

  if (_.every(allFields)) {
    const cashflowSteps = baseRentScheduleField.fieldContent?.buildingBlocks;
    const hasSteps = _.isArray(cashflowSteps) && cashflowSteps.length > 0;
    const validSteps = hasSteps
      ? _.every(cashflowSteps, function ({ date, nextDate }) {
          return date && nextDate;
        })
      : false;
    const perAreaSize = perAreaSizeField.fieldValue;

    if (validSteps && perAreaSize) {
      const missingOutputs = !_.every(
        cashflowSteps,
        function ({ monthlyOutput }) {
          return !!monthlyOutput;
        },
      );
      let currentStep = null;
      let actionableBlocks = [];

      if (missingOutputs) {
        actionableBlocks = createProcessedBuildingBlocks(
          cashflowSteps,
          perAreaSize,
          commencementField?.fieldDate,
          expirationField?.fieldDate,
        );
      } else {
        actionableBlocks = cashflowSteps;
      }

      currentStep = _.find(
        actionableBlocks,
        function ({ date, dateType, nextDate, nextDateType }) {
          let startDateMoment = null;
          switch (dateType) {
            case "Actual":
              startDateMoment = moment(date);
              break;
            case "Relative":
              {
                const months = date;
                startDateMoment = moment(commencementField.fieldDate).add(
                  months,
                  "months",
                );
              }
              break;
            default:
              break;
          }

          let finalDateMoment = null;
          switch (nextDateType) {
            case "Actual":
              {
                if (nextDate === "LXD" && expirationField.fieldDate) {
                  finalDateMoment = moment(expirationField.fieldDate);
                } else {
                  finalDateMoment = moment(nextDate);
                }
              }
              break;
            case "Relative":
              {
                const months = nextDate;
                finalDateMoment = moment(commencementField.fieldDate).add(
                  months,
                  "months",
                );
              }
              break;
          }

          if (startDateMoment && finalDateMoment) {
            return moment(timeTravelTo || undefined).isBetween(
              startDateMoment,
              finalDateMoment,
            );
          } else {
            return null;
          }
        },
      );

      if (currentStep) {
        let monthly = null;

        if (currentStep.monthlyOutput) {
          monthly = _.toNumber(currentStep.monthlyOutput);
        }

        if (monthly) {
          const annual = monthly * 12;
          const perArea = annual / _.toNumber(perAreaSize);

          return perArea;
        } else {
          console.log(
            "no monthly amount for usage",
            spaceUsage.id,
            currentStep,
          );
          return null;
        }
      } else if (spaceUsage.state === "commenced") {
        return 0.0;
      } else {
        return null;
      }
    } else {
      return null;
    }
  } else {
    return null;
  }
}

export function calculatedMonthlyAmount(
  inputType,
  inputValue,
  existingAmount,
  perAreaSize,
) {
  const actionableInputValue = _.isNumber(inputValue)
    ? inputValue
    : _.toNumber(inputValue);
  switch (inputType) {
    case "annualDollarIncrease": {
      if (existingAmount) {
        if (perAreaSize) {
          const stepped = existingAmount + actionableInputValue;
          const annual = stepped * perAreaSize;

          return annual / 12;
        } else {
          return null;
        }
      } else {
        return null;
      }
    }
    case "annualPercentageIncrease": {
      if (existingAmount) {
        const percentageIncrease = actionableInputValue / 100;
        const annualStepped = existingAmount * (1 + percentageIncrease);

        return annualStepped / 12;
      } else {
        return null;
      }
    }
    case "annualPerArea": {
      if (perAreaSize) {
        const annual = actionableInputValue * perAreaSize;

        return annual / 12;
      } else {
        return null;
      }
    }
    case "annual":
      return actionableInputValue / 12;
    case "monthlyPerArea": {
      if (perAreaSize) {
        return actionableInputValue * perAreaSize;
      } else {
        return null;
      }
    }
    case "monthly":
      return actionableInputValue;
    default:
      return null;
  }
}

export function buildingBlockMonthlyPerAreaAmount(monthlyOutput, perAreaSize) {
  return perAreaSize ? monthlyOutput / perAreaSize : null;
}

export function createProcessedBuildingBlocks(
  blocks,
  perAreaSize,
  commencementDate = null,
  expirationDate = null,
) {
  // console.log(blocks);
  const finalIndex = blocks.length - 1;
  let i = 0;
  let processedBlocks = [];

  blocks.forEach((block) => {
    let finalDate = null;
    let currentDateMoment = null;
    const previousPeriod = _.last(processedBlocks);

    if (i === finalIndex) {
      // console.log("final block", block);
      if (expirationDate) {
        finalDate = moment(expirationDate);
        // console.log("LXD PRESENT", expirationDate);
      } else {
        switch (block.dateType) {
          case "Relative": {
            const months = block.date;
            const forwardYear = months + 12;
            if (commencementDate) {
              finalDate = moment(commencementDate).add(forwardYear, "months");
              // console.log("LCD PRESENT", commencementDate);
            } else if (previousPeriod) {
              switch (previousPeriod.dateType) {
                case "Actual":
                  finalDate = moment(previousPeriod.date).add(
                    forwardYear,
                    "months",
                  );
                  // console.log(
                  //   "PREVIOUS PLUS CURRENT PLUS 12",
                  //   previousPeriod.date,
                  // );
                  break;
                case "Relative":
                  finalDate = forwardYear;
                  // console.log("FORWARD", forwardYear);
                  break;
              }
            }
            break;
          }
        }
      }
    } else {
      // console.log("non-final block", block);
      const nextPeriod = blocks[i + 1];
      const nextDateType = block.newNextDateType || nextPeriod.dateType;

      switch (nextDateType) {
        case "Actual":
          finalDate = moment(nextPeriod.date);
          // console.log("ACTUAL NEXT", nextPeriod.date);
          break;
        case "Relative": {
          const months = nextPeriod.date - 1;
          if (commencementDate) {
            finalDate = moment(commencementDate).add(months, "months");
            // console.log("LCD PRESENT", commencementDate, months);
          } else {
            switch (block.dateType) {
              case "Actual":
                finalDate = moment(block.date).add(months, "months");
                // console.log("ACTUAL CURRENT PLUS NEXT", block.date, months);
                break;
              case "Relative":
                {
                  finalDate = months;
                  // console.log("MONTHS", months);
                }
                break;
            }
          }
          break;
        }
        case "LXD":
          {
            if (expirationDate) {
              finalDate = moment(expirationDate);
              // console.log("LXD PRESENT", expirationDate);
            } else {
              switch (block.dateType) {
                case "Actual":
                  finalDate = moment(block.date).add(12, "months");
                  // console.log("ACTUAL CURRENT PLUS 12", block.date);
                  break;
                case "Relative":
                  {
                    finalDate = 12;
                    // console.log("12");
                  }
                  break;
              }
            }
          }
          break;
      }
    }

    switch (block.dateType) {
      case "Actual":
        currentDateMoment = moment(block.date);
        // console.log("ACTUAL CURRENT MOMENT", block.date);
        break;
      case "Relative": {
        if (commencementDate) {
          const months = block.date - 1;
          currentDateMoment = moment(commencementDate).add(months, "months");
          // console.log("LCD PRESENT", block.date);
        }
        break;
      }
    }

    let currentDate = currentDateMoment || block.date;
    let currentDateType = currentDateMoment ? "Moment" : "Relative";
    let finalDateType = _.isNumber(finalDate) ? "Relative" : "Moment";
    // const dateMeta = {
    //   currentDate,
    //   currentDateType,
    //   finalDate,
    //   finalDateType,
    // };
    // console.log("date meta", dateMeta);

    if (recursiveCashflowBuildingBlock(block)) {
      switch (block.inputType) {
        case "annualDollarIncrease":
        case "annualPercentageIncrease":
          applyCashflowAnnualIncreases({
            previousPeriod,
            currentInputPeriod: block,
            currentDate,
            currentDateType,
            finalDate,
            finalDateType,
            outputPeriods: processedBlocks,
            perAreaSize,
            commencementDate,
            expirationDate,
          });
          break;
        default:
      }
    } else {
      const monthlyOutput = calculatedMonthlyAmount(
        block.inputType,
        block.inputValue,
        null,
        perAreaSize,
      );
      processedBlocks.push(
        _.merge({}, block, {
          monthlyOutput,
          monthlyPerArea: buildingBlockMonthlyPerAreaAmount(
            monthlyOutput,
            perAreaSize,
          ),
          monthsDuration: buildingBlockMonthsDuration({
            date: currentDate,
            dateType: currentDateType,
            nextDate: finalDate,
            nextDateType: finalDateType,
            commencementDate,
            expirationDate,
          }),
        }),
      );
    }
    i++;
  });

  // console.log(processedBlocks);

  return processedBlocks;
}

function applyCashflowAnnualIncreases({
  previousPeriod,
  currentInputPeriod,
  currentDate,
  currentDateType,
  finalDate,
  finalDateType,
  outputPeriods,
  perAreaSize,
  commencementDate,
  expirationDate,
}) {
  let monthsRemaining = 12;
  if (currentDate && finalDate) {
    if (currentDateType === "Moment" && finalDateType === "Moment") {
      const adjustedFinalMoment =
        finalDate.date() === 1
          ? finalDate
          : finalDate.clone().endOf("month").add(1, "day");
      monthsRemaining = adjustedFinalMoment.diff(currentDate, "months");
    } else if (currentDateType === "Relative" && finalDateType === "Relative") {
      const forwardYear = finalDate;
      monthsRemaining = forwardYear - currentDate;
    }
  }
  const yearsRemaining = _.round(monthsRemaining / 12);

  let existingAmount = null;
  let annualIncrease = null;

  // console.log(
  //   "apply annual",
  //   currentInputPeriod.id,
  //   currentDateType,
  //   finalDateType,
  //   monthsRemaining,
  //   yearsRemaining,
  // );
  // console.log(
  //   "dates",
  //   currentDateType === "Moment"
  //     ? currentDate?.format("MM/DD/YYYY")
  //     : currentDate,
  //   finalDateType === "Moment" ? finalDate?.format("MM/DD/YYYY") : finalDate,
  // );

  const actionableInputValue = _.isNumber(currentInputPeriod.inputValue)
    ? currentInputPeriod.inputValue
    : _.toNumber(currentInputPeriod.inputValue);

  switch (currentInputPeriod.inputType) {
    case "annualDollarIncrease":
      annualIncrease = actionableInputValue;
      existingAmount =
        buildingBlockMonthlyPerAreaAmount(
          previousPeriod.monthlyOutput,
          perAreaSize,
        ) * 12;
      // console.log("initiate annual bump", existingAmount, annualIncrease);
      break;
    case "annualPercentageIncrease":
      annualIncrease = actionableInputValue / 100;
      existingAmount = previousPeriod.monthlyOutput * 12;
      // console.log("initiate annual %", existingAmount, annualIncrease);
      break;
  }

  for (let i = 0; i < yearsRemaining; i++) {
    const monthlyOutput = calculatedMonthlyAmount(
      currentInputPeriod.inputType,
      currentInputPeriod.inputValue,
      existingAmount,
      perAreaSize,
    );
    let rawDate = null;
    let newDate = null;
    let newNextDate = currentInputPeriod.nextDate;
    let newNextDateType = currentInputPeriod.nextDateType;

    if (currentInputPeriod.dateType === "Relative") {
      const months = i * 12;
      rawDate = currentInputPeriod.date + months;
    }

    switch (currentDateType) {
      case "Moment":
        newDate = currentDate.clone().add(i, "years");
        if (currentInputPeriod.nextDateType === "LXD") {
          newNextDate =
            i === yearsRemaining - 1
              ? "LXD"
              : newDate.clone().add(12, "months").toISOString();
          newNextDateType = i === yearsRemaining - 1 ? "LXD" : "Actual";
        } else if (!newNextDateType) {
          if (expirationDate && i === yearsRemaining - 1) {
            newNextDate = moment(expirationDate).toISOString();
          } else {
            newNextDate = newDate.clone().add(12, "months").toISOString();
          }
          newNextDateType = "Actual";
        }
        break;
      case "Relative":
        {
          const months = i * 12;
          newDate = currentDate + months;
          if (currentInputPeriod.nextDateType === "LXD") {
            newNextDate = i === yearsRemaining - 1 ? "LXD" : newDate + 12;
            newNextDateType = i === yearsRemaining - 1 ? "LXD" : "Relative";
          } else if (!newNextDateType) {
            if (expirationDate && i === yearsRemaining - 1) {
              newNextDate = moment(expirationDate).toISOString();
            } else {
              newNextDate = newDate + 12;
            }
            newNextDateType = "Relative";
          }
        }
        break;
    }

    const newDateMetaData = {
      rawDate,
      rawDateType: currentInputPeriod.dateType,
      date: _.isNumber(newDate) ? newDate : newDate.toISOString(),
      dateType: _.isNumber(newDate) ? "Relative" : "Actual",
      nextDate: newNextDate,
      nextDateType: newNextDateType,
    };
    const monthsDuration = buildingBlockMonthsDuration(
      _.merge({}, newDateMetaData, { commencementDate, expirationDate }),
    );

    // console.log(
    //   i,
    //   "annual bump new date meta",
    //   newDateMetaData,
    //   monthsDuration,
    // );

    const newPeriod = _.merge({}, currentInputPeriod, newDateMetaData, {
      dataSource: "calculated",
      monthlyOutput,
      monthlyPerArea: buildingBlockMonthlyPerAreaAmount(
        monthlyOutput,
        perAreaSize,
      ),
      monthsDuration,
    });
    outputPeriods.push(newPeriod);

    switch (currentInputPeriod.inputType) {
      case "annualDollarIncrease":
        // console.log("update annual bump", existingAmount, annualIncrease);
        existingAmount += annualIncrease;
        break;
      case "annualPercentageIncrease":
        // console.log("update annual %", existingAmount, annualIncrease);
        existingAmount = existingAmount * (1 + annualIncrease);
        break;
    }
  }
}

function buildingBlockMonthsDuration({
  date,
  dateType,
  nextDate,
  nextDateType,
  commencementDate,
  expirationDate,
}) {
  // console.log("bb duration", date, dateType, nextDate, nextDateType);
  let currentDate = null;
  let currentDateType = null;
  let finalDate = null;
  let finalDateType = null;

  switch (dateType) {
    case "Actual":
    case "Moment":
      {
        currentDate = moment(date);
        currentDateType = "Moment";
      }
      break;
    case "Relative":
      {
        currentDate = date;
        currentDateType = "Relative";
      }
      break;
  }
  switch (nextDateType) {
    case "LXD":
      {
        if (expirationDate) {
          finalDate = moment(expirationDate);
        } else if (nextDate !== "LXD") {
          finalDate = moment(nextDate);
        }
        finalDateType = "Moment";
      }
      break;
    case "Actual":
    case "Moment":
      {
        if (nextDate === "LXD" && expirationDate) {
          finalDate = moment(expirationDate);
        } else if (nextDate) {
          finalDate = moment(nextDate);
        }
        finalDateType = "Moment";
      }
      break;
    case "Relative":
      {
        finalDate = nextDate;
        finalDateType = "Relative";
      }
      break;
  }

  return periodMonthDuration({
    currentDate,
    currentDateType,
    finalDate,
    finalDateType,
    commencementDate,
  });
}

export function periodMonthDuration({
  currentDate,
  currentDateType,
  finalDate,
  finalDateType,
  commencementDate,
}) {
  // console.log(
  //   "duration calc",
  //   currentDate,
  //   currentDateType,
  //   finalDate,
  //   finalDateType,
  // );
  if (currentDate && finalDate) {
    let adjustedFinalMoment = null;
    switch (currentDateType) {
      case "Moment":
        {
          switch (finalDateType) {
            case "Moment": {
              adjustedFinalMoment =
                finalDate.date() === 1
                  ? finalDate
                  : finalDate.endOf("month").add(1, "day");
              break;
            }
            case "Relative":
              {
                if (commencementDate) {
                  const months = finalDate - 1;
                  adjustedFinalMoment = moment(commencementDate).add(
                    months,
                    "months",
                  );
                } else {
                  const finalMonths = finalDate;
                  adjustedFinalMoment = currentDate
                    .clone()
                    .add(finalMonths, "months");
                }
              }
              break;
          }
        }
        return adjustedFinalMoment.diff(currentDate, "months");
      case "Relative":
        {
          {
            switch (finalDateType) {
              case "Moment": {
                if (commencementDate) {
                  const months = currentDate - 1;
                  const currentMoment = moment(commencementDate).add(
                    months,
                    "months",
                  );
                  adjustedFinalMoment =
                    finalDate.date() === 1
                      ? finalDate
                      : finalDate.endOf("month").add(1, "day");
                  return adjustedFinalMoment.diff(currentMoment, "months");
                } else {
                  return null;
                }
              }
              case "Relative":
                return finalDate - currentDate;
            }
          }
        }
        break;
    }
  } else {
    return null;
  }
}

export function recursiveCashflowBuildingBlock({ inputType }) {
  return _.includes(
    ["annualDollarIncrease", "annualPercentageIncrease"],
    inputType,
  );
}
