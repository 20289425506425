import { ref, computed } from "vue";
import { defineStore, acceptHMRUpdate } from "pinia";
import _ from "lodash";

export const useModalStore = defineStore("modal", () => {
  const modalPayload = ref(null);
  const component = computed(() =>
    _.get(modalPayload.value, "component", null),
  );
  const props = computed(() => _.get(modalPayload.value, "props", null));
  const size = computed(() => _.get(modalPayload.value, "size", null));
  const theme = computed(() => _.get(modalPayload.value, "theme", null));
  function closeModal() {
    if (_.get(modalPayload.value, "props.value.afterClose", false)) {
      if (modalPayload.value.props.value.afterCloseDestination === "xlModal") {
        modalPayload.value = null;
      } else {
        modalPayload.value = modalPayload.value.props.value.afterClose;
      }
    } else if (modalPayload.value && modalPayload.value.afterClose) {
      if (_.isFunction(modalPayload.value.afterClose)) {
        console.log("is function");
        modalPayload.value.afterClose();
        modalPayload.value = null;
      } else if (modalPayload.value.afterCloseDestination === "xlModal") {
        modalPayload.value = null;
      } else {
        modalPayload.value = modalPayload.value.afterClose;
      }
    } else {
      modalPayload.value = null;
    }
  }

  const confirmationPayload = ref(null);
  const confirmationTitle = computed(() =>
    _.get(confirmationPayload.value, "title", null),
  );
  const confirmationMessage = computed(() =>
    _.get(confirmationPayload.value, "message", null),
  );
  const confirmationAffirmText = computed(() =>
    _.get(confirmationPayload.value, "affirmText", null),
  );
  function closeConfirmation() {
    confirmationPayload.value = null;
  }
  function affirmConfirmation() {
    confirmationPayload.value.accepted = true;
  }

  return {
    confirmationPayload,
    confirmationTitle,
    confirmationMessage,
    confirmationAffirmText,
    modalPayload,
    size,
    theme,
    component,
    props,
    closeModal,
    closeConfirmation,
    affirmConfirmation,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useModalStore, import.meta.hot));
}
