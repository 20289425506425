<template>
  <div class="h-full flex flex-col">
    <div class="p-2 bg-gray-50 flex items-center justify-around">
      <a @click.prevent="cancel" href="" class="text-sm text-indigo-500">
        Cancel
      </a>
      <h3
        class="flex min-w-0 flex-1 items-center justify-center font-medium text-gray-900"
      >
        Date & Time
      </h3>
      <a
        @click.prevent="applyTiming"
        href=""
        class="flex items-center text-sm text-indigo-500 font-medium"
      >
        Apply
      </a>
    </div>
    <TimingFieldSet class="p-2 flex-grow overflow-y-auto" />
  </div>
</template>

<script setup>
import TimingFieldSet from "@/components/tasks/form-elements/TimingFieldSet.vue";
import { useReminderStore } from "@/stores/reminder";
import { useTasksStore } from "@/stores/tasks";
import { storeToRefs } from "pinia";
import { onMounted } from "vue";
import moment from "moment";

const tasksStore = useTasksStore();
const { taskBatchTiming } = storeToRefs(tasksStore);
const reminderStore = useReminderStore();
const { usesTiming, date, time, repeat, repeatEndDate } =
  storeToRefs(reminderStore);

onMounted(() => {
  const tomorrow = moment().startOf("day").add(1, "day").add(8, "hours");
  usesTiming.value = true;
  date.value = tomorrow.format("YYYY-MM-DD");
  time.value = "08:00";
});

function cancel() {
  taskBatchTiming.value = false;
  reminderStore.reset();
}
function applyTiming() {
  const outputTime = time.value || "08:00";
  const momentDateString = `${date.value} ${outputTime}`;
  const timingPayload = {
    dueDate: date.value ? moment(momentDateString).valueOf() : null,
    repeat: repeat.value.apiValue,
    repeatEndDate: repeatEndDate.value
      ? moment(repeatEndDate.value).endOf("day").valueOf()
      : null,
  };
  tasksStore.batchTimingUpdate(timingPayload).then(() => cancel());
}
</script>
