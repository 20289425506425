import moment from "moment";
import _ from "lodash";

export function expirationNumberFor(usageDataField) {
  const dateStr = usageDataField.fieldContent?.expiredAt;
  const state = usageDataField.fieldContent?.state;

  if (dateStr && state) {
    return moment(dateStr).year();
  } else {
    return -1;
  }
}

export function expirationColorStyleFor(
  expirationNumber,
  timeTravelTo,
  selected = false,
) {
  const vacantUnknownStyling = selected
    ? "bg-stone-400"
    : "bg-stone-300 hover:bg-stone-400";
  if (!expirationNumber) return vacantUnknownStyling;

  const currentYear = moment(timeTravelTo || undefined).year();

  if (expirationNumber === -1) {
    return vacantUnknownStyling;
  } else {
    const yearsLeft = expirationNumber - currentYear;

    // console.log(expirationNumber, currentYear, yearsLeft);

    switch (yearsLeft) {
      case 0:
      case 1:
      case 2:
        return selected ? "bg-red-300" : "bg-red-200 hover:bg-red-300";
      case 3:
        return selected ? "bg-orange-300" : "bg-orange-200 hover:bg-orange-300";
      case 4:
        return selected ? "bg-yellow-300" : "bg-yellow-200 hover:bg-yellow-300";
      case 5:
        return selected ? "bg-green-300" : "bg-green-200 hover:bg-green-300";
      default:
        return selected ? "bg-blue-300" : "bg-blue-200 hover:bg-blue-300";
    }
  }
}

export function groupingNumberFor(rent, { q1, q2, q3 }) {
  if (_.isNumber(rent)) {
    if (rent >= q3) {
      return 3;
    } else if (rent >= q2) {
      return 2;
    } else if (rent >= q1) {
      return 1;
    } else {
      return 0;
    }
  } else {
    return -1;
  }
}

export function inPlaceRentColorStyleFor(quartile, selected = false) {
  const vacantUnknownStyling = selected
    ? "bg-stone-400"
    : "bg-stone-300 hover:bg-stone-400";
  if (!_.isNumber(quartile)) return vacantUnknownStyling;

  if (quartile === -1) {
    return vacantUnknownStyling;
  } else {
    // console.log(quartile);

    switch (quartile) {
      case 0:
        return selected ? "bg-red-300" : "bg-red-200 hover:bg-red-300";
      case 1:
        return selected ? "bg-orange-300" : "bg-orange-200 hover:bg-orange-300";
      case 2:
        return selected ? "bg-yellow-300" : "bg-yellow-200 hover:bg-yellow-300";
      case 3:
      default:
        return selected ? "bg-green-300" : "bg-green-200 hover:bg-green-300";
    }
  }
}
