<template>
  <div class="relative overflow-hidden">
    <div class="absolute inset-0">
      <div
        class="absolute inset-0 bg-gradient-to-r from-green-800 to-emerald-700 mix-blend-multiply"
      />
    </div>
    <div class="relative px-4 py-16">
      <h1 class="text-center text-4xl font-bold tracking-tight">
        <span class="block text-white">It takes money</span>
        <span class="block text-emerald-200">to make money</span>
      </h1>
      <p class="mx-auto mt-6 max-w-lg text-center text-lg text-emerald-200">
        You must put data credits at risk when you validate someone else's data.
        If your vote agrees with the consensus, you receive your credits back plus a reward.
      </p>
      <div class="mx-auto mt-10 max-w-sm flex justify-center">
        <div class="space-y-4">
          <a
            href=""
            @click.prevent="emit('acknowledge')"
            class="flex items-center justify-center rounded-md border border-transparent bg-white px-4 py-3 text-base font-medium text-emerald-700 shadow-sm hover:bg-emerald-50 sm:px-8"
            data-test="prompt-to-subscribe-acknowledge-button"
            >Fund credits</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const emit = defineEmits(["acknowledge"]);
</script>
