<template>
  <fieldset>
    <legend class="sr-only">Included Value Types</legend>
    <div class="space-y-2">
      <div
        v-for="valueType in valueTypes"
        :key="valueType.value"
        class="relative flex items-start"
      >
        <div class="flex h-6 items-center">
          <input
            :id="valueType.value"
            :aria-describedby="valueType.name"
            :name="valueType.value"
            :value="valueType.value"
            type="checkbox"
            v-model="includedValueTypes"
            class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
          />
        </div>
        <div class="ml-3 text-sm leading-6">
          <label
            :for="valueType.value"
            class="text-xs font-medium text-gray-900"
            >{{ valueType.name }}</label
          >
        </div>
      </div>
    </div>
  </fieldset>
</template>

<script setup>
import { useAnalyzePanelStore } from "@/stores/analyzePanel";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import valueTypes from "@/assets/valueTypes";

defineProps(["optionData"]);

const analyzePanelStore = useAnalyzePanelStore();
const { calculationOptions } = storeToRefs(analyzePanelStore);

const includedValueTypes = computed({
  get() {
    return calculationOptions.value.investmentVolume.valueTypes;
  },
  set(newTypes) {
    calculationOptions.value.investmentVolume.valueTypes = newTypes;
  },
});
</script>
