import { ref } from "vue";
import { defineStore, acceptHMRUpdate, storeToRefs } from "pinia";
import { useTasksStore } from "@/stores/tasks";
import { useUserStore } from "@/stores/user";
import { useGuestProfileStore } from "@/stores/guestProfile";
import api from "@/router/api";
import moment from "moment";
import _ from "lodash";

export const useReminderStore = defineStore("reminder", () => {
  const guestProfileStore = useGuestProfileStore();
  const { guestTasks } = storeToRefs(guestProfileStore);
  const userStore = useUserStore();
  const { signedIn } = storeToRefs(userStore);
  const tasksStore = useTasksStore();
  const { effectiveTaskLists, selectedTask } = storeToRefs(tasksStore);
  const name = ref("");
  const note = ref("");
  const usesTiming = ref(false);
  const date = ref(null);
  const time = ref(null);
  const repetitions = ref([
    {
      name: "Never",
      apiValue: "never",
    },
    {
      name: "Daily",
      apiValue: "daily",
    },
    {
      name: "Weekdays",
      apiValue: "weekdays",
    },
    {
      name: "Weekends",
      apiValue: "weekends",
    },
    {
      name: "Weekly",
      apiValue: "weekly",
    },
    {
      name: "Biweekly",
      apiValue: "biweekly",
    },
    {
      name: "Monthly",
      apiValue: "monthly",
    },
    {
      name: "Every 3 Months",
      apiValue: "three_months",
    },
    {
      name: "Every 6 Months",
      apiValue: "six_months",
    },
    {
      name: "Yearly",
      apiValue: "yearly",
    },
  ]);
  const repeat = ref(_.head(repetitions.value));
  const repeatEndOptions = ref([
    {
      name: "Repeat Forever",
      apiValue: false,
    },
    {
      name: "End Repeat Date",
      apiValue: true,
    },
  ]);
  const repeatEnds = ref(_.head(repeatEndOptions.value));
  const repeatEndDate = ref(null);
  const priorities = ref([
    {
      name: "None",
      apiValue: "none",
    },
    {
      name: "Low",
      apiValue: "low",
    },
    {
      name: "Medium",
      apiValue: "medium",
    },
    {
      name: "High",
      apiValue: "high",
    },
  ]);
  const priority = ref(_.head(priorities.value));
  const list = ref(_.head(effectiveTaskLists.value) || {});

  function resetTiming() {
    date.value = null;
    time.value = null;
    resetRepeat();
  }

  function resetRepeat() {
    repeat.value = _.head(repetitions.value);
    repeatEnds.value = _.head(repeatEndOptions.value);
    repeatEndDate.value = null;
  }

  function reset() {
    name.value = "";
    note.value = "";
    usesTiming.value = false;
    resetTiming();
    priority.value = _.head(priorities.value);
    list.value = _.head(effectiveTaskLists.value) || {};
  }

  function mountExisting(task) {
    name.value = task.name;
    note.value = task.note;
    mountTiming(task);
    priority.value = _.find(priorities.value, { apiValue: task.priority });
    list.value = _.find(effectiveTaskLists.value, function (comparableList) {
      if (signedIn.value) {
        return comparableList.accessTokenId === task.accessTokenId;
      } else {
        return comparableList.name === task.taskListName;
      }
    });
  }

  function mountTiming(task) {
    const dueDate = task.dueDate;
    const dueDateMoment = signedIn.value
      ? moment.unix(dueDate)
      : moment(dueDate);
    usesTiming.value = !!dueDate;

    if (dueDate) {
      date.value = dueDateMoment.format("YYYY-MM-DD");
      time.value = dueDateMoment.format("kk:mm");
      mountRepeat(task);
    }
  }

  function mountRepeat(task) {
    const endDate = task.repeatEndDate;
    const endDateMoment = signedIn.value
      ? moment.unix(endDate)
      : moment(endDate);
    repeat.value = _.find(repetitions.value, { apiValue: task.repeat });
    repeatEnds.value = _.find(repeatEndOptions.value, {
      apiValue: !!endDate,
    });
    if (endDate) {
      repeatEndDate.value = endDateMoment.format("YYYY-MM-DD");
    }
  }

  async function createTask() {
    const outputTime = time.value || "08:00";
    const momentDateString = `${date.value} ${outputTime}`;

    const payload = {
      name: _.isEmpty(_.trim(name.value)) ? null : _.trim(name.value),
      note: _.isEmpty(_.trim(note.value)) ? null : _.trim(note.value),
      dueDate: date.value ? moment(momentDateString).valueOf() : null,
      repeat: repeat.value.apiValue,
      repeatEndDate: repeatEndDate.value
        ? moment(repeatEndDate.value).endOf("day").valueOf()
        : null,
      priority: priority.value.apiValue,
      accessTokenId: list.value.accessTokenId,
      taskListName: list.value.name,
      completedAt: null,
      contentId: null,
      contentType: null,
    };

    const valid = !!payload.name || !!payload.contentId;

    if (valid) {
      if (selectedTask.value) {
        // UPDATE
        if (signedIn.value) {
          return api.patch(`tasks/${selectedTask.value.id}`, payload);
        } else {
          return new Promise((resolve) => {
            const newTask = _.merge({}, payload, { id: selectedTask.value.id });
            tasksStore.patchTasks([newTask]);
            resolve();
          });
        }
      } else {
        // CREATE
        if (signedIn.value) {
          return api.post(`tasks`, payload);
        } else {
          return new Promise((resolve) => {
            guestTasks.value.push(
              _.merge({}, payload, { id: moment().valueOf() })
            );
            resolve();
          });
        }
      }
    } else {
      return new Promise((resolve) => resolve());
    }
  }

  async function deleteTask() {
    if (selectedTask.value) {
      if (signedIn.value) {
        return api.delete(`tasks/${selectedTask.value.id}`);
      } else {
        return;
      }
    }
  }

  return {
    name,
    note,
    usesTiming,
    date,
    time,
    repetitions,
    repeat,
    repeatEndOptions,
    repeatEnds,
    repeatEndDate,
    priority,
    priorities,
    list,
    reset,
    resetTiming,
    resetRepeat,
    createTask,
    deleteTask,
    mountExisting,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useReminderStore, import.meta.hot));
}
