<template>
  <div class="flex flex-1">
    <!-- Narrow sidebar-->
    <nav
      aria-label="Sidebar"
      class="flex-shrink-0 p-2 bg-gray-100 overflow-y-auto"
    >
      <div class="relative flex flex-col space-y-2">
        <a
          v-for="tab in tabs"
          :key="tab.name"
          @click.prevent="selectedTab = tab"
          :href="tab.href"
          v-tooltip="tab.name"
          :class="[
            selectedTab.name === tab.name
              ? 'text-gray-700 bg-gray-300'
              : 'text-gray-500 hover:bg-gray-200',
            'flex-shrink-0 inline-flex items-center justify-center h-8 w-8 rounded-lg',
          ]"
          class=""
          :data-test="`${_.kebabCase(tab.name)}-nav`"
        >
          <span class="sr-only">{{ tab.name }}</span>
          <svg
            :class="selectedTab.name === tab.name ? '' : ''"
            class="h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1"
              :d="tab.svgPath"
            />
          </svg>
        </a>
      </div>
    </nav>

    <div class="flex-grow overflow-y-auto">
      <component :is="selectedTab.component" v-bind="selectedTabProperties" />
    </div>
  </div>
</template>

<script setup>
import AvailabilityGroupCashflows from "@/components/crowdsourcing/AvailabilityGroupCashflows.vue";
import AvailabilityGroupTiming from "@/components/space-usage-builder/AvailabilityGroupTiming.vue";
import { ref, computed, markRaw } from "vue";
import _ from "lodash";

const props = defineProps(["existingGroupId", "fieldContent", "dataField"]);

const tabs = ref([
  {
    name: "Contract Dates",
    href: "",
    svgPath:
      "M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z",
    component: markRaw(AvailabilityGroupTiming),
  },
  {
    name: "Occupancy",
    href: "",
    svgPath:
      "M8.25 18.75a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m3 0h6m-9 0H3.375a1.125 1.125 0 0 1-1.125-1.125V14.25m17.25 4.5a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m3 0h1.125c.621 0 1.129-.504 1.09-1.124a17.902 17.902 0 0 0-3.213-9.193 2.056 2.056 0 0 0-1.58-.86H14.25M16.5 18.75h-2.25m0-11.177v-.958c0-.568-.422-1.048-.987-1.106a48.554 48.554 0 0 0-10.026 0 1.106 1.106 0 0 0-.987 1.106v7.635m12-6.677v6.677m0 4.5v-4.5m0 0h-12",
    component: markRaw(AvailabilityGroupTiming),
  },
  {
    name: "Cashflows",
    href: "",
    svgPath:
      "M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m3.75 9v7.5m2.25-6.466a9.016 9.016 0 0 0-3.461-.203c-.536.072-.974.478-1.021 1.017a4.559 4.559 0 0 0-.018.402c0 .464.336.844.775.994l2.95 1.012c.44.15.775.53.775.994 0 .136-.006.27-.018.402-.047.539-.485.945-1.021 1.017a9.077 9.077 0 0 1-3.461-.203M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z",
    component: markRaw(AvailabilityGroupCashflows),
  },
]);
const selectedTab = ref({
  name: "Contract Dates",
  href: "",
  svgPath:
    "M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z",
  component: markRaw(AvailabilityGroupTiming),
});
const selectedTabProperties = computed(() => {
  return {
    existingGroupId: props.existingGroupId,
    context: selectedTab.value?.name === "Occupancy" ? "occupancy" : "contract",
  };
});
</script>
