<template>
  <tr v-if="record">
    <td class="pl-4 whitespace-nowrap py-2 pr-3">{{ alias }}</td>
    <td class="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
      {{ record.dataFieldId || record.id }}
    </td>
    <td class="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
      {{ moment(record.createdAt).format("MMM D YYYY, h:mm a") }}
    </td>
    <td class="whitespace-nowrap px-3 py-2 text-sm space-y-1">${{ cost }}</td>
  </tr>
</template>

<script setup>
import moment from "moment";
import { computed } from "vue";
import _ from "lodash";

const props = defineProps(["record"]);
const unitPrice = 0.00001;

const cost = computed(() => {
  return _.round(units.value * unitPrice, 3);
});

const units = computed(() => {
  switch (props.record.recordType) {
    case "Validation":
    case "Contribution":
      return props.record.stakedPricingUnits;
    default:
      return props.record.pricingUnits;
  }
});

const alias = computed(() => {
  switch (props.record.recordType) {
    case "Validation":
      return "Validation forfeit";
    case "Contribution":
      return "Contribution rejection";
    default:
      return props.record.recordType;
  }
});
</script>
