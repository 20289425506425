/* global L */
/* global LGeo */

import _ from "lodash";

/* global turf */
class PolygonDrawer {
  constructor(mask) {
    this._mask = mask;
    this._holes = {};
  }
  add = (union, key = "combined") => {
    this._mask.set(key, union);
  };
  consolidateIntents = async (geographyIntents) => {
    var self = this;
    this._holes = {};

    if (geographyIntents.length === 1) {
      if (geographyIntents[0].lat && geographyIntents[0].lng) {
        const circle = LGeo.circle(
          toLatLng(geographyIntents[0]),
          400,
        ).toGeoJSON();
        this.add(circle);
      }
    } else {
      const intentLines = getOriginIntentLines(geographyIntents);
      const intentGeoJson = _.map(intentLines, function (intents) {
        return self.buildJson(intents);
      });
      this._holes = await _.reduce(intentGeoJson, joinPolygons);
      this.add(this._holes);
    }
  };
  buildJson = (intents) => {
    if (intents.length === 1) {
      return LGeo.circle(toLatLng(intents[0]), 400).toGeoJSON();
    } else {
      const latlngs = intents.map((intent) => toLatLng(intent));
      return joinPointsIntoPolygon(latlngs);
    }
  };
}

const getOriginIntentLines = (intents) => {
  // intents are sorted by createdAt, descending
  let originLines = {};
  let currentLine = [];
  intents.forEach((intent, index) => {
    if (intent.lat && intent.lng) {
      currentLine.push(intent);

      const id = intent.id || index;
      originLines[id] = currentLine;
      currentLine = [];
    }
  });

  return originLines;
};

const joinPointsIntoPolygon = (points) => {
  const pointForTurf = (p) => {
    if (p.lat && p.lng) return [p.lng, p.lat];
    return [p[1], p[0]];
  };
  const pointsForTurf = points.map(pointForTurf);
  const linestring = turf.lineString(pointsForTurf);
  const buffered = turf.buffer(linestring, 300, { units: "meters" });
  return buffered;
};

const toLatLng = (geographyIntent) => {
  return L.latLng(geographyIntent.lat, geographyIntent.lng);
};

const toFixed = (p) => {
  p[0] = Math.round(p[0] * 1e6) / 1e6;
  p[1] = Math.round(p[1] * 1e6) / 1e6;
};

const joinPolygons = (polygon1GeoJson, polygon2GeoJson) => {
  turf.coordEach(polygon1GeoJson, toFixed);
  turf.coordEach(polygon2GeoJson, toFixed);
  return turf.union(polygon1GeoJson, polygon2GeoJson);
};

const isArray = (geojson) => L.Util.isArray(geojson);

const dimension = function (arr) {
  var j = 1;
  for (var i in arr) {
    if (arr[i] instanceof Array) {
      if (1 + dimension(arr[i]) > j) {
        j = j + dimension(arr[i]);
      }
    }
  }
  return j;
};
const coordsToLatLng = function (coords) {
  return new L.LatLng(coords[1], coords[0], coords[2]);
};
const coordsToLatLngs = function (coords) {
  var latlngs = [];
  var dimensions = dimension(coords);
  for (var i = 0, len = coords.length, latlng; i < len; i++) {
    if (dimensions > 2) {
      latlng = coordsToLatLngs(coords[i]);
    } else {
      latlng = coordsToLatLng(coords[i]);
    }
    latlngs.push(latlng);
  }
  return latlngs;
};

const polygonToLatLng = (coords) => {
  let latlngs = [];
  for (var i = 0, len = coords.length; i < len; i++) {
    latlngs.push(coords[i]);
  }
  return coordsToLatLngs(latlngs);
};

const multipolygonToLatLng = (coords) => {
  let latlngs = [];
  for (var i = 0, len = coords.length; i < len; i++) {
    latlngs.push(polygonToLatLng(coords[i]));
  }
  return latlngs;
};

const geojsonToLatLng = (geojson) => {
  if (isArray(geojson)) {
    let latlngs = [];
    for (let i = 0; i < geojson.length; i++) {
      latlngs.push(geojsonToLatLng(geojson[i]));
    }
    return latlngs;
  }
  let latlngs = [];
  switch (geojson.type) {
    case "FeatureCollection":
      var features = geojson.features;
      for (let i = 0, len = features.length; i < len; i++) {
        latlngs.push(geojsonToLatLng(features[i]));
      }
      return latlngs;
    case "Feature":
      return geojsonToLatLng(geojson.geometry);
    case "GeometryCollection":
      var geometries = geojson.geometries;
      for (let i = 0, len = geometries.length; i < len; i++) {
        latlngs.push(geojsonToLatLng(geometries[i]));
      }
      return latlngs;
    case "Polygon":
      return polygonToLatLng(geojson.coordinates);
    case "MultiPolygon":
      return multipolygonToLatLng(geojson.coordinates);
    default:
      throw new Error(`Not supported ${geojson.type}`);
  }
};

export { PolygonDrawer, geojsonToLatLng };
