<template>
  <div class="py-2 bg-gray-50 flex items-center justify-between">
    <button
      @click="batchPriority"
      :class="available ? 'text-indigo-500' : 'text-gray-200'"
      class="p-2 flex items-center justify-center"
    >
      <ExclamationCircleIcon class="h-6 w-6" />
    </button>

    <button
      @click="batchTiming"
      :class="available ? 'text-indigo-500' : 'text-gray-200'"
      class="p-2 flex items-center justify-center"
    >
      <CalendarDaysIcon class="h-6 w-6" />
    </button>

    <button
      @click="batchMove"
      :class="available ? 'text-indigo-500' : 'text-gray-200'"
      class="p-2 flex items-center justify-center"
    >
      <FolderIcon class="h-6 w-6" />
    </button>

    <button
      @click="batchDelete"
      :class="available ? 'text-indigo-500' : 'text-gray-200'"
      class="p-2 flex items-center justify-center"
      data-test="task-batch-delete-button"
    >
      <TrashIcon class="h-6 w-6" />
    </button>
  </div>
</template>

<script setup>
import {
  CalendarDaysIcon,
  ExclamationCircleIcon,
  FolderIcon,
  TrashIcon,
} from "@heroicons/vue/24/outline";
import { useTasksStore } from "@/stores/tasks";
import { storeToRefs } from "pinia";
import { computed } from "vue";

const tasksStore = useTasksStore();
const { batchTasks, taskBatchMove, taskBatchTiming, taskBatchPriority } =
  storeToRefs(tasksStore);

const available = computed(() => batchTasks.value.length > 0);

function batchPriority() {
  if (available.value) taskBatchPriority.value = true;
}

function batchTiming() {
  if (available.value) taskBatchTiming.value = true;
}

function batchMove() {
  if (available.value) taskBatchMove.value = true;
}

async function batchDelete() {
  if (available.value) tasksStore.batchDelete();
}
</script>
