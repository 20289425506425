<template>
  <div class="h-full flex flex-col">
    <nav
      class="p-2 bg-gray-50 flex items-center justify-between"
      aria-label="Breadcrumb"
    >
      <div class="flex items-center space-x-2">
        <a @click.prevent="backToLists" href="" class="flex items-center">
          <ChevronLeftIcon
            class="h-5 w-5 flex-shrink-0 text-indigo-400"
            aria-hidden="true"
          />
          <span
            class="text-sm font-medium text-indigo-500 hover:text-indigo-700"
            >Lists</span
          >
        </a>
      </div>
      <h3
        v-if="!mainTitleVisible"
        class="flex min-w-0 flex-1 items-center justify-center font-medium text-gray-900"
      >
        <template v-if="batchSelection">
          {{ batchTasks.length }} Selected
        </template>
        <template v-else>
          {{ selectedTaskList.name }}
        </template>
      </h3>
      <div class="flex items-center space-x-3">
        <VDropdown v-if="!batchSelection" class="flex items-center">
          <button
            type="button"
            class="inline-flex justify-center p-0.5 items-center rounded-full border border-indigo-400 text-sm text-indigo-500 hover:text-indigo-700"
            data-test="task-list-options-button"
          >
            <EllipsisHorizontalIcon class="h-4 w-4" />
          </button>

          <template #popper>
            <div
              class="w-64 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="menu-button"
              tabindex="-1"
            >
              <div class="py-1" role="none">
                <a
                  v-if="destroyableList"
                  @click.prevent="edit"
                  href=""
                  class="text-gray-700 hover:bg-gray-100 hover:text-gray-900 group flex items-center px-4 py-2 text-sm"
                  role="menuitem"
                  tabindex="-1"
                >
                  Show List Info
                </a>
                <a
                  v-if="destroyableList && selectedTaskList.shareable"
                  @click.prevent="share"
                  href=""
                  class="text-gray-700 hover:bg-gray-100 hover:text-gray-900 group flex items-center px-4 py-2 text-sm"
                  role="menuitem"
                  tabindex="-1"
                  data-test="task-list-sharing-settings"
                >
                  Sharing Settings
                </a>
                <a
                  @click.prevent="batchSelection = true"
                  href=""
                  class="text-gray-700 hover:bg-gray-100 hover:text-gray-900 group flex items-center px-4 py-2 text-sm"
                  role="menuitem"
                  tabindex="-1"
                  data-test="activate-batch-selection-button"
                >
                  Select Reminders
                </a>
                <VDropdown v-if="signedIn">
                  <a
                    @click.prevent
                    href=""
                    class="hover:bg-gray-100 group flex items-center p-2 space-x-1"
                    role="menuitem"
                    tabindex="-1"
                    id="menu-item-0"
                  >
                    <ChevronRightIcon class="h-4 w-4 text-gray-700" />
                    <div class="flex-grow">
                      <div class="font-semibold text-gray-700 text-sm">
                        Sort By
                      </div>
                      <div class="text-gray-500 text-xs">
                        {{ sortOrderFor(selectedTaskList.sortBy)?.name }}
                      </div>
                    </div>
                    <ArrowsUpDownIcon class="h-4 w-4 text-gray-700" />
                  </a>
                  <template #popper>
                    <div
                      class="w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="menu-button"
                      tabindex="-1"
                    >
                      <div class="py-1" role="none">
                        <div
                          class="group flex items-center justify-between p-2"
                          role="menuitem"
                          tabindex="-1"
                          id="menu-item-0"
                        >
                          <div>
                            <div class="font-semibold text-gray-700 text-sm">
                              Sort By
                            </div>
                            <div class="text-gray-500 text-xs">
                              {{ sortOrderFor(selectedTaskList.sortBy)?.name }}
                            </div>
                          </div>
                          <ArrowsUpDownIcon class="h-4 w-4 text-gray-700" />
                        </div>
                      </div>
                      <div class="py-1" role="none">
                        <a
                          v-for="option in standaloneListSortOptions"
                          :key="option.apiValue"
                          @click.prevent="
                            updateListSort('sort_by', option.apiValue)
                          "
                          href=""
                          class="text-gray-700 hover:bg-gray-100 hover:text-gray-900 group flex items-center space-x-2 p-2 text-sm"
                          role="menuitem"
                          tabindex="-1"
                          id="menu-item-0"
                          :data-test="`sort-task-list-by-${option.apiValue}-button`"
                        >
                          <CheckIcon
                            v-if="
                              sortOrderFor(selectedTaskList.sortBy)
                                ?.apiValue === option.apiValue
                            "
                            class="h-5 w-5"
                            aria-hidden="true"
                          />
                          <span>{{ option.name }}</span>
                        </a>
                      </div>
                      <div v-if="displaySortDirection" class="py-1" role="none">
                        <a
                          v-for="option in sortOrderFor(selectedTaskList.sortBy)
                            .directions"
                          :key="option.apiValue"
                          @click.prevent="
                            updateListSort('sort_direction', option.apiValue)
                          "
                          href=""
                          class="text-gray-700 hover:bg-gray-100 hover:text-gray-900 group flex items-center space-x-2 p-2 text-sm"
                          role="menuitem"
                          tabindex="-1"
                          id="menu-item-0"
                          :data-test="`sort-task-list-by-${option.apiValue}-button`"
                        >
                          <CheckIcon
                            v-if="
                              selectedTaskList.sortDirection === option.apiValue
                            "
                            class="h-5 w-5"
                            aria-hidden="true"
                          />
                          <span>{{ option.name }}</span>
                        </a>
                      </div>
                    </div>
                  </template>
                </VDropdown>
                <a
                  @click.prevent="toggleShowCompleted"
                  href=""
                  class="text-gray-700 hover:bg-gray-100 hover:text-gray-900 group flex items-center px-4 py-2 text-sm"
                  role="menuitem"
                  tabindex="-1"
                >
                  {{ selectedTaskList.showCompleted ? "Hide" : "Show" }}
                  Completed
                </a>
                <a
                  v-if="destroyableList"
                  @click.prevent="deleteList"
                  href=""
                  class="text-red-500 hover:bg-gray-100 hover:text-gray-700 group flex items-center px-4 py-2 text-sm"
                  role="menuitem"
                  tabindex="-1"
                  data-test="delete-task-list-button"
                >
                  Delete List
                </a>
              </div>
            </div>
          </template>
        </VDropdown>
        <a
          v-if="addingInlineTask || selectedTask"
          @click.prevent="add"
          href=""
          id="inline-reminder-form-done-button"
          class="flex items-center text-sm font-bold text-indigo-500"
        >
          Done
        </a>
        <a
          v-else-if="batchSelection"
          @click.prevent="resetBatch"
          href=""
          class="flex items-center text-sm font-bold text-indigo-500"
        >
          Done
        </a>
        <a
          v-else-if="!mainTitleVisible && isDesktop"
          @click.prevent="newReminder"
          href=""
          class="flex items-center text-sm font-bold text-indigo-500"
          data-test="create-new-task-button"
        >
          New
        </a>
      </div>
    </nav>

    <div class="p-2 flex flex-col flex-grow overflow-y-auto">
      <div class="lg:flex lg:items-center lg:justify-between">
        <div class="min-w-0 flex-1">
          <h2
            v-observe-visibility="pageTitleVisibilityChanged"
            :class="[taskListStore.colorFor(selectedTaskList, 'textColor')]"
            class="mt-2 text-xl font-bold leading-6 sm:truncate sm:text-2xl sm:tracking-tight"
          >
            <template v-if="batchSelection">
              {{ batchTasks.length }} Selected
            </template>
            <template v-else>
              {{ selectedTaskList.name }}
            </template>
          </h2>
          <div class="mt-2 flex items-center space-x-2">
            <div
              class="flex items-center text-sm text-gray-500"
              data-test="completed-task-count"
            >
              {{ complete.length }} completed
            </div>
            <div class="text-gray-500 text-sm">&middot;</div>
            <VDropdown :disabled="batchSelection || nothingCompleted" class="">
              <a
                @click.prevent
                href=""
                :class="
                  batchSelection || nothingCompleted
                    ? 'text-gray-200'
                    : 'text-indigo-500'
                "
                class="flex items-center text-sm"
              >
                Clear
              </a>
              <template #popper>
                <div
                  class="w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="menu-button"
                  tabindex="-1"
                >
                  <div class="py-1" role="none">
                    <div
                      class="font-medium text-gray-500 group flex items-center p-2 text-xs"
                      role="menuitem"
                      tabindex="-1"
                      id="menu-item-0"
                    >
                      Completed Reminders
                    </div>
                  </div>
                  <div class="py-1" role="none">
                    <a
                      @click.prevent="clearCompleted('month')"
                      href=""
                      class="text-gray-700 hover:bg-gray-100 hover:text-gray-900 group flex items-center space-x-2 p-2 text-sm"
                      role="menuitem"
                      tabindex="-1"
                      id="menu-item-0"
                      data-test="`clear-completed-tasks-month-plus-button`"
                    >
                      <span>Older Than a Month</span>
                    </a>
                    <a
                      @click.prevent="clearCompleted()"
                      href=""
                      class="text-gray-700 hover:bg-gray-100 hover:text-gray-900 group flex items-center space-x-2 p-2 text-sm"
                      role="menuitem"
                      tabindex="-1"
                      id="menu-item-0"
                      data-test="`clear-all-completed-tasks-button`"
                    >
                      <span>All Completed</span>
                    </a>
                  </div>
                </div>
              </template>
            </VDropdown>
          </div>
        </div>
        <div
          v-if="isDesktop && !batchSelection"
          class="ml-2 flex flex-shrink-0"
        >
          <button
            @click.prevent="newReminder"
            type="button"
            class="relative inline-flex items-center rounded-md border border-gray-300 bg-white p-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            data-test="create-new-task-button"
          >
            <PlusCircleIcon
              class="-ml-1 mr-1.5 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            <span>New Task</span>
          </button>
        </div>
      </div>

      <TaskBatchActions v-if="isDesktop && batchSelection" />
      <TasksList class="mt-2 flex-grow" />
    </div>

    <template v-if="!isDesktop">
      <TaskBatchActions v-if="batchSelection" />
      <div v-else class="p-2 bg-gray-50 flex items-center justify-between">
        <a
          @click.prevent="newReminder"
          href=""
          class="flex items-center text-sm font-medium text-indigo-500"
          data-test="create-new-task-button"
        >
          <PlusCircleIcon
            class="mr-1.5 h-5 w-5 flex-shrink-0 text-indigo-400"
            aria-hidden="true"
          />
          New Task
        </a>
      </div>
    </template>
  </div>
</template>

<script setup>
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  PlusCircleIcon,
  EllipsisHorizontalIcon,
  ArrowsUpDownIcon,
  CheckIcon,
} from "@heroicons/vue/20/solid";
import { useTaskListStore } from "@/stores/taskList";
import { useTasksStore } from "@/stores/tasks";
import { useReminderStore } from "@/stores/reminder";
import { useUserStore } from "@/stores/user";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { ref, computed, onMounted } from "vue";
import { storeToRefs } from "pinia";
import { standaloneListSortOptions } from "@/components/tasks/taskListSortOptions";
import TasksList from "@/components/tasks/TasksList.vue";
import TaskBatchActions from "@/components/tasks/TaskBatchActions.vue";
import api from "@/router/api";
import _ from "lodash";
import { useRoute, useRouter } from "vue-router";

const taskListStore = useTaskListStore();
const tasksStore = useTasksStore();
const {
  addingTask,
  addingList,
  sharingList,
  addingInlineTask,
  batchSelection,
  batchTasks,
  selectedTaskList,
  selectedTask,
  completedTasks,
} = storeToRefs(tasksStore);
const layoutStore = useWorkspaceLayoutStore();
const { isDesktop } = storeToRefs(layoutStore);
const userStore = useUserStore();
const { signedIn } = storeToRefs(userStore);
const reminderStore = useReminderStore();
const { list } = storeToRefs(reminderStore);
const mainTitleVisible = ref(true);

const destroyableList = computed(() => {
  return !_.includes(["Reminders", "News"], selectedTaskList.value.name);
});
const complete = computed(() =>
  completedTasks.value.filter(selectedListFilter)
);
const nothingCompleted = computed(() => complete.value.length === 0);
const router = useRouter();
const route = useRoute();

onMounted(() => {
  router.push({
    name: route.name,
    query: {
      ...route.query,
      taskList:
        selectedTaskList.value.accessTokenId || selectedTaskList.value.name,
    },
  });
});

function pageTitleVisibilityChanged(isVisible) {
  mainTitleVisible.value = isVisible;
}

function resetBatch() {
  tasksStore.resetBatch();
}
function selectedListFilter(task) {
  if (signedIn.value) {
    return task.accessTokenId === selectedTaskList.value.accessTokenId;
  } else {
    return task.taskListName === selectedTaskList.value.name;
  }
}
function backToLists() {
  tasksStore.backToLists();
}
function newReminder() {
  addingTask.value = true;
  list.value = selectedTaskList.value;
}
function deleteList() {
  if (signedIn.value) {
    api.delete(`task_lists/${selectedTaskList.value.id}`).then(() => {
      tasksStore.deleteTaskList(selectedTaskList.value);
      selectedTaskList.value = null;
    });
  } else {
    tasksStore.deleteTaskList(selectedTaskList.value);
    selectedTaskList.value = null;
  }
}
function toggleShowCompleted() {
  if (signedIn.value) {
    if (selectedTaskList.value.showCompleted) {
      api
        .delete(
          `task_list_show_completed/${selectedTaskList.value.accessTokenId}?attribute_name=show_completed`
        )
        .then((json) => {
          tasksStore.patchTaskList(json.data);
          selectedTaskList.value = json.data;
        });
    } else {
      api
        .patch(
          `task_list_show_completed/${selectedTaskList.value.accessTokenId}?attribute_name=show_completed`
        )
        .then((json) => {
          tasksStore.patchTaskList(json.data);
          selectedTaskList.value = json.data;
        });
    }
  } else {
    const newTaskList = _.merge({}, selectedTaskList.value, {
      showCompleted: !selectedTaskList.value.showCompleted,
    });
    tasksStore.patchTaskList(newTaskList);
    selectedTaskList.value = newTaskList;
  }
}
function sortOrderFor(sortOrderApiValue) {
  return _.find(standaloneListSortOptions, { apiValue: sortOrderApiValue });
}
const displaySortDirection = computed(() => {
  return (
    selectedTaskList.value &&
    !_.isEmpty(sortOrderFor(selectedTaskList.value.sortBy)?.directions)
  );
});
function updateListSort(attributeName, attributeValue) {
  if (signedIn.value) {
    api
      .patch(
        `task_list_sorting/${selectedTaskList.value.accessTokenId}?attribute_name=${attributeName}&attribute_value=${attributeValue}`
      )
      .then((json) => {
        tasksStore.patchTaskList(json.data);
        selectedTaskList.value = json.data;
        tasksStore.fetchTasks(selectedTaskList.value.id);
      });
  } else {
    let newTaskList = _.merge({}, selectedTaskList.value);
    newTaskList[_.camelCase(attributeName)] = attributeValue;

    tasksStore.patchTaskList(newTaskList);
    selectedTaskList.value = newTaskList;
  }
}
function edit() {
  taskListStore.mountExisting(selectedTaskList.value);
  addingList.value = true;
}
function share() {
  sharingList.value = true;
}
function add() {
  reminderStore.createTask().then(async (json) => {
    if (json) {
      const task = json.data;
      tasksStore.patchTasks([task]);
    }
    reminderStore.reset();
    addingInlineTask.value = false;
    selectedTask.value = null;
  });
}
function clearCompleted(threshold = "all") {
  if (signedIn.value) {
    api
      .post(
        `completed_task_deletions?threshold=${threshold}&task_list_id=${selectedTaskList.value.id}`
      )
      .then(() => {
        tasksStore.deleteCompletedTasks(threshold, selectedTaskList.value);
      });
  } else {
    tasksStore.deleteCompletedTasks(threshold, selectedTaskList.value);
  }
}
</script>
