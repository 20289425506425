<template>
  <div class="rounded-md border-l-2 border-teal-500 bg-teal-100 p-2 space-y-2">
    <div v-if="!scheduleDataField" class="flex items-center justify-between">
      <div class="text-sm font-medium bg-teal-100 text-gray-700">
        Output Preview
      </div>
    </div>
    <div class="bg-teal-100 max-h-64 overflow-y-auto">
      <table
        class="min-w-full border-separate border-spacing-0 divide-y bg-teal-100 divide-gray-300"
      >
        <thead>
          <tr>
            <th
              scope="col"
              class="sticky top-0 z-10 bg-teal-100 py-1.5 pl-2 pr-2 text-left text-xs font-semibold text-gray-700"
            >
              Date
            </th>
            <th
              scope="col"
              class="sticky top-0 z-10 bg-teal-100 px-3 py-1.5 text-left text-xs font-semibold text-gray-700"
            >
              # of Months
            </th>
            <th
              scope="col"
              class="sticky top-0 z-10 bg-teal-100 px-3 py-1.5 text-left text-xs font-semibold text-gray-700"
            >
              $/Area
            </th>
            <th
              scope="col"
              class="sticky top-0 z-10 bg-teal-100 px-3 py-1.5 text-left text-xs font-semibold text-gray-700"
            >
              $ Amount
            </th>
          </tr>
        </thead>
        <tbody class="bg-teal-100">
          <tr v-if="emptyCommencedPeriod" class="even:bg-teal-50">
            <td
              v-if="emptyCommencedPeriod.dateType === 'Actual'"
              class="whitespace-nowrap py-2 pl-2 pr-2 text-xs font-medium text-gray-700"
            >
              {{ moment(emptyCommencedPeriod.date).format("MM/DD/YYYY") }}
            </td>
            <td
              v-else-if="emptyCommencedPeriod.dateType === 'Relative'"
              class="whitespace-nowrap py-2 pl-2 pr-2 text-xs font-medium text-gray-700"
            >
              Month {{ emptyCommencedPeriod.date }}
            </td>
            <td
              v-else
              class="whitespace-nowrap py-2 pl-2 pr-2 text-xs font-medium text-gray-700"
            >
              Date unknown
            </td>
            <td class="whitespace-nowrap px-3 py-2 text-xs text-gray-500">
              {{ emptyCommencedPeriod.monthsDuration || "LXD unknown" }}
            </td>
            <td
              v-if="emptyCommencedPeriod.monthlyPerArea"
              class="whitespace-nowrap px-3 py-2 text-xs text-gray-500"
            >
              ${{ currencyAmount(emptyCommencedPeriod.monthlyPerArea * 12, 2) }}
            </td>
            <td
              v-else
              class="whitespace-nowrap px-3 py-2 text-xs text-gray-500"
            >
              $0.00
            </td>
            <td class="whitespace-nowrap px-3 py-2 text-xs text-gray-500">
              ${{ currencyAmount(emptyCommencedPeriod.monthlyOutput * 12, 0) }}
            </td>
          </tr>
          <tr
            v-for="period in outputs"
            :key="period.date"
            class="even:bg-teal-50"
          >
            <td
              v-if="period.dateType === 'Actual'"
              class="whitespace-nowrap py-2 pl-2 pr-2 text-xs font-medium text-gray-700"
            >
              {{ moment(period.date).format("MM/DD/YYYY") }}
            </td>
            <td
              v-else-if="
                period.dateType === 'Relative' && existingCommencementDate
              "
              class="whitespace-nowrap py-2 pl-2 pr-2 text-xs font-medium text-gray-700"
            >
              {{
                moment(existingCommencementDate)
                  .add(period.date - 1, "months")
                  .format("MM/DD/YYYY")
              }}
            </td>
            <td
              v-else-if="period.dateType === 'Relative'"
              class="whitespace-nowrap py-2 pl-2 pr-2 text-xs font-medium text-gray-700"
            >
              Month {{ period.date }}
            </td>
            <td
              v-else
              class="whitespace-nowrap py-2 pl-2 pr-2 text-xs font-medium text-gray-700"
            >
              Date unknown
            </td>
            <td class="whitespace-nowrap px-3 py-2 text-xs text-gray-500">
              {{ period.monthsDuration || "LXD unknown" }}
            </td>
            <td
              v-if="period.monthlyPerArea"
              class="whitespace-nowrap px-3 py-2 text-xs text-gray-500"
            >
              ${{ currencyAmount(period.monthlyPerArea * 12, 2) }}
            </td>
            <td
              v-else
              class="whitespace-nowrap px-3 py-2 text-xs text-gray-500"
            >
              Fetch or add size
            </td>
            <td class="whitespace-nowrap px-3 py-2 text-xs text-gray-500">
              ${{ currencyAmount(_.toNumber(period.monthlyOutput) * 12, 0) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>
import { useCashflowDefinitionStore } from "@/stores/cashflowDefinition";
import { computed, watch } from "vue";
import moment from "moment";
import { currencyAmount } from "@/assets/numberHelpers";
import {
  createProcessedBuildingBlocks,
  periodMonthDuration,
} from "@/components/analyze/calculations/inPlaceRent";
import { storeToRefs } from "pinia";
import _ from "lodash";

const props = defineProps([
  "dataField",
  "dateFields",
  "perAreaSize",
  "scheduleDataField",
]);

const cashflowDefinitionStore = useCashflowDefinitionStore();
const { cashflowSchedule, processedCashflowSchedule } = storeToRefs(
  cashflowDefinitionStore,
);

const existingCommencementDate = computed(
  () => props.dateFields.commenced_date?.fieldDate,
);
const existingExpirationDate = computed(
  () => props.dateFields.expired_date?.fieldDate,
);
const existingBuildingBlocks = computed(
  () => props.scheduleDataField?.fieldContent?.buildingBlocks,
);
const processablePeriods = computed(() => {
  return existingBuildingBlocks.value || actionableInputPeriods.value;
});
const actionableInitialPeriod = computed(() => {
  return processablePeriods.value[0];
});
const initialPeriodStart = computed(() => {
  switch (actionableInitialPeriod.value?.dateType) {
    case "Actual":
      return moment(actionableInitialPeriod.value.date);
    case "Relative": {
      if (existingCommencementDate.value) {
        const months = actionableInitialPeriod.value.date - 1;
        return moment(existingCommencementDate.value).add(months, "months");
      } else {
        return null;
      }
    }
    default:
      return null;
  }
});
const initalStartMatchesCommencement = computed(() => {
  return (
    initialPeriodStart.value &&
    existingCommencementDate.value &&
    moment(existingCommencementDate.value).isSame(
      initialPeriodStart.value,
      "day",
    )
  );
});
const emptyPeriodDisplayable = computed(
  () => !initalStartMatchesCommencement.value,
);
const initialPeriodEnd = computed(() => {
  if (actionableInitialPeriod.value) {
    switch (actionableInitialPeriod.value.dateType) {
      case "Actual":
        return moment(actionableInitialPeriod.value.date);
      case "Relative": {
        if (existingCommencementDate.value) {
          const months = actionableInitialPeriod.value.date - 1;
          return moment(existingCommencementDate.value).add(months, "months");
        } else {
          return null;
        }
      }
      default:
        return null;
    }
  } else if (existingExpirationDate.value) {
    return moment(existingExpirationDate.value);
  } else {
    return null;
  }
});

const actualDateEmptyPeriod = computed(() => {
  if (existingCommencementDate.value && initialPeriodEnd.value) {
    return {
      date: existingCommencementDate.value,
      dateType: "Actual",
      inputType: "monthly",
      inputValue: 0,
      dataSource: "datapoint",
      monthlyOutput: 0,
      monthlyPerArea: 0,
      monthsDuration: periodMonthDuration({
        currentDate: moment(existingCommencementDate.value),
        currentDateType: "Moment",
        finalDate: initialPeriodEnd.value,
        finalDateType: "Moment",
        commencementDate: existingCommencementDate.value,
      }),
      valid: true,
    };
  } else {
    return null;
  }
});
const relativeDateEmptyPeriod = computed(() => {
  if (
    actionableInitialPeriod.value?.dateType === "Relative" &&
    actionableInitialPeriod.value?.date &&
    actionableInitialPeriod.value.date !== 1
  ) {
    return {
      date: 1,
      dateType: "Relative",
      inputType: "monthly",
      inputValue: 0,
      dataSource: "datapoint",
      monthlyOutput: 0,
      monthlyPerArea: 0,
      monthsDuration: actionableInitialPeriod.value.date - 1,
      valid: true,
    };
  } else {
    return null;
  }
});

const emptyCommencedPeriod = computed(() => {
  if (emptyPeriodDisplayable.value) {
    return actualDateEmptyPeriod.value || relativeDateEmptyPeriod.value;
  } else {
    return null;
  }
});

const actionableInputPeriods = computed(() => {
  return _.filter(cashflowSchedule.value, function ({ valid }) {
    return valid;
  });
});

const processedInputPeriods = computed(() => {
  return createProcessedBuildingBlocks(
    actionableInputPeriods.value,
    props.perAreaSize,
    existingCommencementDate.value,
    existingExpirationDate.value,
  );
});

const outputs = computed(() => {
  if (props.scheduleDataField) {
    const blocks = existingBuildingBlocks.value || [];

    return createProcessedBuildingBlocks(
      blocks,
      props.perAreaSize,
      existingCommencementDate.value,
      existingExpirationDate.value,
    );
  } else {
    return processedInputPeriods.value;
  }
});

watch(
  processedInputPeriods,
  () => {
    processedCashflowSchedule.value = processedInputPeriods.value;
  },
  { deep: true },
);
</script>
