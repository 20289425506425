<template>
  <div class="h-full p-2 flex flex-col w-full space-y-4">
    <InvestmentGroupsList
      v-if="assetsLength > 1 || investmentGroups.length > 0"
    />
    <div class="flex items-center justify-between">
      <h2 class="text-gray-500 text-sm font-semibold uppercase tracking-wide">
        {{ pluralize("Asset", assetsLength, true) }}
      </h2>
      <button
        v-if="transferOriginAssetId"
        @click="transferOrigin = null"
        type="button"
        data-test="cancel-capital-stack-transfer-deal-builder-button"
        class="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-orange-300 hover:bg-orange-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-200"
      >
        <i class="-ml-0.5 mr-2 fas fa-ban" />
        Cancel capital stack transfer
      </button>
      <button
        v-else-if="canAddAsset"
        @click="addAsset"
        type="button"
        data-test="add-selected-asset-to-deal-builder-button"
        class="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        <svg
          class="-ml-0.5 mr-2 h-4 w-4"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM14 11a1 1 0 011 1v1h1a1 1 0 110 2h-1v1a1 1 0 11-2 0v-1h-1a1 1 0 110-2h1v-1a1 1 0 011-1z"
          />
        </svg>
        Add selected asset
      </button>
    </div>
    <EditorContainer
      v-for="assetObject in assets"
      :key="assetObject.dataField.localId"
      :asset-object="assetObject"
      :transfer-origin-asset-id="transferOriginAssetId"
      @initiate-capital-stack-transfer="initiateTransfer"
      @set-capital-stack-transfer-destination="capitalStackTransferConfirmation"
      @cancel-capital-stack-transfer="transferOrigin = null"
    />
    <div v-if="_.size(assets) === 0" class="w-full">
      <div
        class="h-full flex items-center justify-center max-w-2xl mx-auto py-16 px-4"
        data-test="deal-builder-empty-state"
      >
        <div class="text-center">
          <h1
            class="text-base leading-6 font-semibold text-indigo-600 tracking-wide uppercase"
          >
            Deal Hub
          </h1>
          <p class="mt-1 text-3xl leading-10 font-extrabold text-gray-900">
            Create and consume investment data
          </p>
          <p class="max-w-xl mt-5 mx-auto text-lg leading-7 text-gray-500">
            Add an asset to begin.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import EditorContainer from "@/components/deal-builder/EditorContainer.vue";
import InvestmentGroupsList from "@/components/deal-builder/InvestmentGroupsList.vue";
import decoratingAndFieldKey from "@/components/crowdsourcing/decoratingAndFieldKey";
import pluralize from "pluralize";
import { useModalStore } from "@/stores/modal";
import { useUserStore } from "@/stores/user";
import { usePropertyDiagramStore } from "@/stores/propertyDiagram";
import { useDealBuilderStore } from "@/stores/dealBuilder";
import { useSecondaryPanelStore } from "@/stores/secondaryPanel";
import { useTimeTravelStore } from "@/stores/timeTravel";
import { storeToRefs } from "pinia";
import { ref, computed, watch, onMounted, nextTick } from "vue";
import api from "@/router/api";
import _ from "lodash";
import { useRoute, useRouter } from "vue-router";

const transferOrigin = ref(null);
const transferOriginAssetId = computed(() => transferOrigin.value?.id);

const userStore = useUserStore();
const { emailUnverified } = storeToRefs(userStore);
const timeTravelStore = useTimeTravelStore();
const modalStore = useModalStore();
const { confirmationPayload } = storeToRefs(modalStore);
const dealBuilderStore = useDealBuilderStore();
const { dealBuilder, assetFieldIds } = storeToRefs(dealBuilderStore);
const secondaryPanelStore = useSecondaryPanelStore();
const propertyDiagramStore = usePropertyDiagramStore();
const { propertyDiagramSelected, propertyDiagramPropertyIds } =
  storeToRefs(propertyDiagramStore);

const investmentGroups = computed(() => {
  return _.get(dealBuilder.value, "investmentGroups", []);
});
const assets = computed(() => {
  return _.get(dealBuilder.value, "assets", {});
});
const assetsLength = computed(() => {
  return _.size(assets.value);
});
const investmentId = computed(() => {
  return _.get(dealBuilder.value, "investmentId");
});
const canAddAsset = computed(() => {
  const selectedDataField = _.get(propertyDiagramSelected.value, "dataField");

  if (selectedDataField) {
    const alreadySelected = _.includes(
      _.keys(_.get(dealBuilder.value, "assets", {})),
      decoratingAndFieldKey(selectedDataField),
    );
    const eligibleSelection = _.includes(
      ["fee_simple", "leasehold", "condominium", "co_op"],
      selectedDataField.fieldContentSubType,
    );

    return eligibleSelection && !alreadySelected;
  } else {
    return false;
  }
});

watch(investmentId, (id, oldId) => {
  if (oldId && id !== oldId) {
    fetchInvestment();
  }
});

onMounted(() => {
  setTimeout(() => {
    secondaryPanelStore.setSecondaryPanelWidth("auto 10px 800px");
  }, 250);

  if (investmentId.value) {
    fetchInvestment();
  }

  dealBuilderStore.loadTimelinePortfolioInvestment();
});

function fetchInvestment() {
  console.log("fetch investment", investmentId.value);
  dealBuilderStore.addDealBuilderAssets([]);
}

function initiateTransfer(assetData) {
  transferOrigin.value = assetData;
}

function capitalStackTransferConfirmation({
  destinationPropertyRightId,
  destinationLabel,
}) {
  if (emailUnverified.value) {
    userStore.promptToVerify();
  } else {
    confirmationPayload.value = {
      title: "Transfer capital stack",
      message: `This will propose that all investments from ${transferOrigin.value.label} be moved to ${destinationLabel}. This is potentially an expensive change. Are you sure?`,
      affirmText: "Transfer",
      affirmCallback: () => transferCapitalStack(destinationPropertyRightId),
    };
  }
}

async function transferCapitalStack(destinationPropertyRightId) {
  const payload = {
    originPropertyRightId: transferOriginAssetId.value,
    destinationPropertyRightId,
  };
  const response = await api.post(`capital_stack_transfers`, payload);

  if (response?.data) {
    transferOrigin.value = null;
    dealBuilderStore.clearDealBuilder(false);
    dealBuilderStore.resetDealBuilderInvestments();
    timeTravelStore.triggerRefetch();
  }
}

const router = useRouter();
const route = useRoute();

async function addAsset() {
  const dataField = _.get(propertyDiagramSelected.value, "dataField");

  if (dataField) {
    const assetKey = decoratingAndFieldKey(dataField);
    const assetObject = {
      dataField,
      investments: [],
      expanded: true,
      ownershipInterests: [],
    };

    dealBuilderStore.addDealBuilderAsset({
      assetObject,
      assetKey,
    });
    await nextTick();
    router.push({
      name: route.name,
      query: {
        ...route.query,
        horizontalTab: "Deals",
        verticalTab: undefined,
        assetFieldIds: assetFieldIds.value,
      },
    });
    dealBuilderStore.collapseAllAssetsExcept({ assetKey });

    if (dataField.joiningContentType === "Property") {
      if (
        !_.includes(
          propertyDiagramPropertyIds.value,
          dataField.joiningContentId,
        )
      ) {
        propertyDiagramStore.addPropertyToDiagram(dataField.joiningContentId);
      }
    }
  }
}
</script>
