/* global L */
import { mount } from "mount-vue-component";
import app from "@/entrypoints/application";
import ParcelPopup from "@/components/property-diagram/ParcelPopup.vue";

export default function parcelPopup({
  parcelDataField,
  propertyDiagramStore,
  changeGroupStore,
  modalStore,
  unlockerStore,
  userStore,
  tasksStore,
  taskListStore,
  reminderStore,
  guestProfileStore,
  layoutStore,
  secondaryPanelStore,
  documentationStore,
}) {
  const { el } = mount(ParcelPopup, {
    props: {
      propertyDiagramStore,
      changeGroupStore,
      modalStore,
      unlockerStore,
      userStore,
      tasksStore,
      taskListStore,
      reminderStore,
      guestProfileStore,
      layoutStore,
      secondaryPanelStore,
      documentationStore,
      parcelDataField,
    },
    app,
  });
  return L.responsivePopup({ offset: [0, 125] }).setContent(el); // Offset popup from center of parcel
}
