<template>
  <fieldset class="space-y-2">
    <TimingToggle />
    <div v-if="usesTiming">
      <label for="date" class="block text-sm font-medium text-gray-700"
        >Date</label
      >
      <div class="mt-1 flex rounded-md shadow-sm">
        <div class="relative flex flex-grow items-stretch focus-within:z-10">
          <div
            class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
          >
            <CalendarDaysIcon
              class="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </div>
          <input
            v-model="date"
            type="date"
            name="date"
            id="date"
            class="block w-full rounded-none rounded-l-md border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 text-xs"
          />
        </div>
        <button
          @click="date = null"
          type="button"
          class="relative -ml-px inline-flex items-center space-x-2 rounded-r-md border border-gray-300 bg-gray-50 px-4 py-2 text-xs font-medium text-gray-700 hover:bg-gray-100 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
        >
          <XMarkIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
          <span>Clear</span>
        </button>
      </div>
    </div>

    <div v-if="usesTiming">
      <label for="time" class="block text-sm font-medium text-gray-700"
        >Time</label
      >
      <div class="mt-1 flex rounded-md shadow-sm">
        <div class="relative flex flex-grow items-stretch focus-within:z-10">
          <div
            class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
          >
            <ClockIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
          <input
            v-model="time"
            type="time"
            name="time"
            id="time"
            class="block w-full rounded-none rounded-l-md border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 text-xs"
          />
        </div>
        <button
          @click="time = null"
          type="button"
          class="relative -ml-px inline-flex items-center space-x-2 rounded-r-md border border-gray-300 bg-gray-50 px-4 py-2 text-xs font-medium text-gray-700 hover:bg-gray-100 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
        >
          <XMarkIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
          <span>Clear</span>
        </button>
      </div>
    </div>

    <RepeatSelect v-if="usesTiming" />
    <EndRepeatDateSelect v-if="repeat.apiValue !== 'never'" />
  </fieldset>
</template>

<script setup>
import { useReminderStore } from "@/stores/reminder";
import { storeToRefs } from "pinia";
import RepeatSelect from "@/components/tasks/form-elements/RepeatSelect.vue";
import EndRepeatDateSelect from "@/components/tasks/form-elements/EndRepeatDateSelect.vue";
import TimingToggle from "@/components/tasks/form-elements/TimingToggle.vue";
import {
  CalendarDaysIcon,
  ClockIcon,
  XMarkIcon,
} from "@heroicons/vue/20/solid";

const reminderStore = useReminderStore();
const { usesTiming, date, time, repeat } = storeToRefs(reminderStore);
</script>
