<template>
  <div class="bg-white overflow-hidden">
    <div class="p-2">
      <dl class="grid grid-cols-1 gap-x-4 gap-y-8">
        <div class="col-span-1">
          <div class="flex items-start justify-between">
            <dt class="text-sm font-medium text-gray-700">
              {{ _.startCase(dataField.fieldContentType) }} Cashflows
            </dt>
            <div class="flex flex-col items-end space-y-2">
              <DataVisibilityButton v-if="!editing" visibility="safezone">
                <template v-slot:button>
                  <button
                    @click="edit"
                    :disabled="originatingData"
                    type="button"
                    class="group py-1 px-1.5 flex items-center justify-between rounded-full border border-gray-300 shadow-sm space-x-2 text-left hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    <PulseLoader
                      v-if="originatingData"
                      :loading="true"
                      size="3px"
                      color="#f3f4f6"
                    />
                    <span
                      v-else
                      class="min-w-0 flex-1 flex items-center space-x-1"
                    >
                      <span class="flex-shrink-0 flex items-center">
                        <span
                          class="inline-flex items-center justify-center h-5 w-5 rounded-full bg-yellow-500"
                        >
                          <PencilIcon class="h-3 w-3 text-white" />
                        </span>
                      </span>
                      <span class="min-w-0 flex-1">
                        <span class="text-sm font-medium text-gray-900 truncate"
                          >Add cashflow schedule</span
                        >
                      </span>
                    </span>
                  </button>
                </template>
              </DataVisibilityButton>
              <dt
                v-if="commencedDate || expiredDate"
                class="flex items-center space-x-1 text-sm text-gray-500"
              >
                <span v-if="commencedDate">LCD: {{ commencedDate }}</span>
                <span v-if="commencedDate && expiredDate">&middot;</span>
                <span v-if="expiredDate">LXD: {{ expiredDate }}</span>
              </dt>
              <div class="flex items-center space-x-1">
                <SizesUses
                  v-if="
                    _.includes(
                      ['FloorArea', 'SpaceUsage'],
                      dataField.fieldContent?.spaceType,
                    )
                  "
                  :standalone-content-type="dataField.fieldContentType"
                  :standalone-content-ids="[dataField.fieldContentId]"
                  @numeric-area="setPerAreaSize"
                />
              </div>
            </div>
          </div>
          <dd class="mt-2 space-y-2">
            <CashflowCreate
              v-if="editing"
              :data-field="dataField"
              :date-fields="dateFields"
              :per-area-size="perAreaSize"
              @refetch="refetch"
              @cancel="cancel"
            />

            <SquareLoader
              v-if="!schedulesLoaded"
              :loading="true"
              size="24px"
              color="#4338ca"
            />

            <CashflowScheduleField
              v-for="cashflowField in typeOrderedSchedules"
              :key="cashflowField.localId"
              :schedule-data-field="cashflowField"
              :content-data-field="dataField"
              :date-fields="dateFields"
              :per-area-size="perAreaSize"
              :removable="true"
            />
          </dd>
        </div>
      </dl>
    </div>
  </div>
</template>

<script setup>
import { useCashflowDefinitionStore } from "@/stores/cashflowDefinition";
import { useCrowdsourcedChangeGroupStore } from "@/stores/crowdsourcedChangeGroup";
import { useSpaceUsageBuilderStore } from "@/stores/spaceUsageBuilder";
import { ref, computed, onMounted } from "vue";
import { storeToRefs } from "pinia";
import { PencilIcon } from "@heroicons/vue/24/outline";
import SquareLoader from "vue-spinner/src/SquareLoader.vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import SizesUses from "@/components/analyze/calculations/SizesUses.vue";
import DataVisibilityButton from "@/components/crowdsourcing/DataVisibilityButton.vue";
import CashflowCreate from "@/components/crowdsourcing/CashflowCreate.vue";
import CashflowScheduleField from "@/components/crowdsourcing/CashflowScheduleField.vue";
import moment from "moment";
import api from "@/router/api";
import _ from "lodash";

const props = defineProps(["dataField"]);

const cashflowDefinitionStore = useCashflowDefinitionStore();
const changeGroupStore = useCrowdsourcedChangeGroupStore();
const { originatingData } = storeToRefs(changeGroupStore);
const spaceUsageBuilderStore = useSpaceUsageBuilderStore();

const editing = ref(false);
const schedules = ref([]);
const typeOrderedSchedules = computed(() =>
  _.orderBy(
    schedules.value,
    [
      function (field) {
        switch (field.fieldContent?.reportingGroup) {
          case "base_rent":
            return 1;
          case "capital_expenditures":
            return 5;
          default:
            return 10;
        }
      },
    ],
    ["asc"],
  ),
);
const schedulesLoaded = ref(true);
const dateFields = ref({
  commenced_date: null,
  expired_date: null,
});

const perAreaSize = ref(null);
function setPerAreaSize(numericArea) {
  perAreaSize.value = numericArea;
}

const commencedDate = computed(() => {
  if (dateFields.value.commenced_date?.fieldDate) {
    return formattedDate("commenced_date");
  } else {
    return null;
  }
});
const expiredDate = computed(() => {
  if (dateFields.value.expired_date?.fieldDate) {
    return formattedDate("expired_date");
  } else {
    return null;
  }
});

onMounted(() => {
  fetchDate("commenced_date");
  fetchDate("expired_date");
  fetchSchedules();
});

function fetchSchedules() {
  api
    .get(
      `crowdsourced_data_fields/${props.dataField.fieldContentType}/${props.dataField.fieldContentId}?field_name=CashflowSchedule&field_sub_type=any`,
    )
    .then((json) => {
      schedules.value = json.data;
      schedulesLoaded.value = true;
    });
}

function formattedDate(dateName) {
  return moment(dateFields.value[dateName].fieldDate).format("MMM D, YYYY");
}

function edit() {
  editing.value = true;
}

function cancel() {
  editing.value = false;
  cashflowDefinitionStore.resetSchedule();
}

function refetch() {
  editing.value = false;
  fetchSchedules();
  // emit("refetch");
}

async function fetchDate(dateName) {
  const fetchRequestKey = `crowdsourced_data_fields_${props.dataField.fieldContentType}_${props.dataField.fieldContentId}?field_name=${dateName}`;
  const alreadyFetchedFields =
    spaceUsageBuilderStore.alreadyFetchedFieldsFor(fetchRequestKey);
  const dateDataField = _.find(alreadyFetchedFields, {
    fieldValueType: "Date",
    deprecatedAt: null,
  });

  if (dateDataField) {
    dateFields.value[dateName] = dateDataField;
  } else {
    const dateResponse = await api.get(
      `crowdsourced_data_fields/${props.dataField.fieldContentType}/${props.dataField.fieldContentId}?field_name=${dateName}`,
    );

    if (dateResponse?.data) {
      spaceUsageBuilderStore.interceptablePatch(
        [dateResponse.data],
        fetchRequestKey,
      );
      dateFields.value[dateName] = dateResponse.data;
    }
  }
}
</script>
