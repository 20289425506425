<template>
  <div class="bg-white">
    <div class="p-2">
      <div v-if="!timingUnlocked" class="mt-4 text-center">
        <svg
          class="mx-auto h-8 w-8 text-gray-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
          />
        </svg>
        <h3 class="mt-2 text-sm font-medium text-gray-900">
          Unlockable dates/states
        </h3>
        <p class="mt-1 text-sm text-gray-500">
          All timing datapoints need to be unlocked. Please check the asset
          list.
        </p>
      </div>
      <dl v-else class="grid grid-cols-2 gap-x-4 gap-y-8">
        <GroupPrincipalsDetail
          transaction-context="capMarkets"
          transaction-side="supply"
          display-context="details-panel"
          :existingGroupId="existingGroupId"
          :placeholderGroupId="placeholderGroupId"
        />
        <GroupPrincipalsDetail
          transaction-context="capMarkets"
          transaction-side="demand"
          display-context="details-panel"
          :existingGroupId="existingGroupId"
          :placeholderGroupId="placeholderGroupId"
        />
        <InvestmentGroupAdvisors
          :existingGroupId="existingGroupId"
          :placeholderGroupId="placeholderGroupId"
          clientRole="capitalConsumer"
          :fetch-milliseconds="fetchMilliseconds"
        />
        <InvestmentGroupAdvisors
          :existingGroupId="existingGroupId"
          :placeholderGroupId="placeholderGroupId"
          clientRole="capitalProvider"
          :fetch-milliseconds="fetchMilliseconds"
        />
        <InvestmentGroupProspects
          v-if="allExistingInvestments"
          :existingGroupId="existingGroupId"
          :placeholderGroupId="placeholderGroupId"
          :fetch-milliseconds="fetchMilliseconds"
          @refetch="refetchGroupInvestments"
          @override-refetch="refetchGroupInvestments"
        />
      </dl>
    </div>
  </div>
</template>

<script setup>
import GroupPrincipalsDetail from "@/components/deal-builder/GroupPrincipalsDetail.vue";
import InvestmentGroupAdvisors from "@/components/deal-builder/InvestmentGroupAdvisors.vue";
import InvestmentGroupProspects from "@/components/deal-builder/InvestmentGroupProspects.vue";
import { useDealBuilderStore } from "@/stores/dealBuilder";
import { computed, onMounted } from "vue";
import { storeToRefs } from "pinia";
import moment from "moment";
import _ from "lodash";
import fullyUnlocked from "@/components/crowdsourcing/fullyUnlocked";

const props = defineProps(["existingGroupId", "placeholderGroupId"]);

const dealBuilderStore = useDealBuilderStore();
const { dealBuilder } = storeToRefs(dealBuilderStore);

const investmentGroup = computed(() => {
  const finder = props.existingGroupId
    ? { id: props.existingGroupId }
    : { placeholderId: props.placeholderGroupId };

  return _.find(_.get(dealBuilder.value, "investmentGroups", []), finder);
});
const timingUnlocked = computed(() => {
  return fullyUnlocked(timingFields.value);
});
const allExistingInvestments = computed(() => {
  return _.every(groupDateState.value.ids, function (id) {
    return !_.isNil(id);
  });
});
const groupDate = computed(() => {
  if (groupDateState.value.dates.length === 1) {
    let unparsed = _.head(groupDateState.value.dates);

    return moment(unparsed).toDate();
  } else {
    return null;
  }
});
const groupDateState = computed(() => {
  const investments = _.map(
    _.get(investmentGroup.value, "investments", []),
    function (groupInvestment) {
      return focalInvestmentFor(groupInvestment);
    },
  );
  const ids = investments.map((i) => i.id);
  const dates = _.uniq(
    investments.map((i) => {
      if (i.date) {
        return moment(i.date).format("YYYYMMDD");
      } else {
        return null;
      }
    }),
  );
  const states = _.uniq(investments.map((i) => i.state));
  const dealActions = _.uniq(investments.map((i) => i.dealAction));

  return { ids, dates, states, dealActions };
});
const timingFields = computed(() => {
  return _.get(investmentGroup.value, "timingFields", []);
});
const fetchMilliseconds = computed(() => {
  if (timingUnlocked.value) {
    const uniqueFields = _.uniqBy(timingFields.value, "fieldName");
    const dateField = _.head(
      uniqueFields.filter((field) => field.fieldName !== "state"),
    );

    return dateField ? moment(dateField.fieldValue).valueOf() : null;
  } else if (groupDate.value) {
    return moment(groupDate.value).valueOf();
  } else {
    return null;
  }
});

onMounted(() => {
  if (props.existingGroupId) {
    dealBuilderStore.fetchGroupTimingFields(props.existingGroupId);
  }
});

function focalInvestmentFor(groupInvestment) {
  return _.get(groupInvestment, "existingInvestment") || groupInvestment;
}
function refetchGroupInvestments() {
  if (props.existingGroupId) {
    dealBuilderStore.refreshInvestmentGroup({
      groupId: props.existingGroupId,
      dealAction: investmentGroup.value?.dealAction,
      isExistingGroup: true,
    });
  }
}
</script>
