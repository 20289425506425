<template>
  <div
    class="py-2 px-4 flex items-center min-h-16 bg-gray-100 shadow"
    style="z-index: 2"
  >
    <div class="w-full flex flex-nowrap">
      <h3 class="mt-2 leading-6 font-medium text-gray-900">To:</h3>
      <div class="flex flex-1 flex-wrap items-center">
        <ul v-if="combined.length > 0" class="mx-2 space-x-1 space-y-2">
          <li
            v-for="(recipient, index) in combined"
            :key="recipient.token || recipient.id"
            class="inline-flex rounded-full items-center py-0.5 px-1 space-x-0.5 text-sm font-medium bg-indigo-100 text-indigo-700"
            :data-test="`sharing-stage-recipient-${index}`"
          >
            <SharingSubsidySelector
              v-if="recipient.className"
              :recipient="recipient"
            />
            {{ recipient.name }}
            <button
              @click="remove(recipient)"
              type="button"
              class="flex-shrink-0 h-4 w-4 rounded-full inline-flex items-center justify-center text-indigo-400 hover:bg-indigo-200 hover:text-indigo-500 focus:outline-none focus:bg-indigo-500 focus:text-white"
            >
              <span class="sr-only">Remove</span>
              <svg
                class="h-2 w-2"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 8 8"
              >
                <path
                  stroke-linecap="round"
                  stroke-width="1.5"
                  d="M1 1l6 6m0-6L1 7"
                />
              </svg>
            </button>
          </li>
        </ul>
        <div class="relative flex-grow focus-within:z-10">
          <UserTeamAutocomplete
            :search-teams="true"
            :selections="combined"
            :remove-inline="true"
            input-key="sharing-stage"
            :focus="true"
            :no-self="true"
            @add-user="addUser"
            @add-team="addTeam"
            @remove="remove"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import UserTeamAutocomplete from "@/components/contacts/UserTeamAutocomplete.vue";
import SharingSubsidySelector from "@/components/crowdsourcing/safezone/SharingSubsidySelector.vue";
import { storeToRefs } from "pinia";
import { useSharingStore } from "@/stores/sharing";
import _ from "lodash";

const sharingStore = useSharingStore();
const { userRecipients, teamRecipients } = storeToRefs(sharingStore);

const defaultPayStructure = {
  label: "Recipient pays",
  value: "recipient",
};

const combined = computed(() => {
  const concatted = _.concat(userRecipients.value, teamRecipients.value);

  return _.orderBy(concatted, ["name"], ["asc"]);
});

function addTeam(newTeam) {
  const withStructure = _.merge({}, newTeam, {
    payStructure: defaultPayStructure,
  });
  sharingStore.addTeam(withStructure);
}

function addUser(newUser) {
  const withStructure = _.merge({}, newUser, {
    payStructure: defaultPayStructure,
  });
  sharingStore.addUser(withStructure);
}

function remove(recipient) {
  if (recipient.className === "Team") {
    sharingStore.removeTeam(recipient);
  } else {
    sharingStore.removeUser(recipient);
  }
}
</script>
