<template>
  <div class="pointer-events-none relative" :style="`z-index: 1;`">
    <div
      v-for="(gridRow, index) in singleParcelLandCoveringGridRows"
      :key="`${index}-single-land-covering-unit-layered-right-row`"
      :class="`pointer-events-none w-full h-full absolute grid top-0`"
      :style="`grid-template-columns: ${gridRow.gridColumnWidths}; z-index: ${gridRow.gridZIndex}`"
    >
      <div
        v-for="(gridBlock, i) in gridRow.gridBlocks"
        :key="`${i}-single-land-covering-unit-layered-right-block`"
        class="h-full w-full flex flex-col"
      >
        <VMenu
          theme="diagram-popup"
          :disabled="editingMode"
          v-for="layer in layersFor(gridBlock.coveringDataField)"
          :key="layer.order"
          :test-key="`layer-${layer.order}-single-land-covering-unit-layered-right-block-${i}`"
          class="h-full w-full"
        >
          <PropertyRightGridBlock
            :property-id="propertyId"
            :data-field="_.head(layer.dataFields)"
            :enhancements="enhancementsFor(_.head(layer.dataFields))"
            right-type="Leasehold"
            :background-style="
              diagramContentSelectionStore.propertyRightSelectionBackgroundStylingFor(
                {
                  propertyId,
                  dataField: _.head(layer.dataFields),
                },
              )
            "
            @clicked="
              diagramContentSelectionStore.feeSimpleColumnClickHandler({
                propertyId,
                dataField: _.head(layer.dataFields),
              })
            "
            @property-enhancement-click="
              diagramContentSelectionStore.singleClickSelect
            "
          />
          <template #popper>
            <DataFieldInfoPopup :data-field="_.head(layer.dataFields)" />
          </template>
        </VMenu>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { storeToRefs } from "pinia";
import { usePropertyDiagramContentSelectionStore } from "@/stores/propertyDiagramContentSelection";
import { usePropertyDiagramStore } from "@/stores/propertyDiagram";
import getFieldChildrenFromCollection from "@/assets/getFieldChildrenFromCollection";
import PropertyRightGridBlock from "@/components/property-diagram/PropertyRightGridBlock.vue";
import DataFieldInfoPopup from "@/components/crowdsourcing/DataFieldInfoPopup.vue";
import _ from "lodash";

const props = defineProps(["internalName", "propertyId"]);
const propertyDiagramStore = usePropertyDiagramStore();
const {
  renderableGridRows,
  landCoveringUnits,
  landCoveringUnitDataFields,
  propertyEnhancements,
  editingMode,
} = storeToRefs(propertyDiagramStore);
const diagramContentSelectionStore = usePropertyDiagramContentSelectionStore();

const singleParcelLandCoveringGridRows = computed(() => {
  return propertyDiagramStore.singleParcelGridRowsFor({
    internalRowName: "Land Coverings (above-grade)",
    propertyId: props.propertyId,
  });
});

const rowObject = computed(() =>
  _.find(renderableGridRows.value, { internalName: props.internalName }),
);
function layersFor(landCoveringDataField) {
  const rawLayers = _.get(rowObject.value, "layers", []);

  const scopedLayers = rawLayers.map(({ dataFields, order }) => {
    const newFields = dataFields.filter((df) => {
      return (
        df.fieldContent.nearestControllingRightId ===
        unitRightFor(landCoveringDataField)?.fieldContentId
      );
    });

    return {
      dataFields: newFields,
      order,
    };
  });

  return _.orderBy(scopedLayers, ["order"], ["desc"]);
}

function unitRightFor(landCoveringDataField) {
  if (landCoveringDataField) {
    const landCoveringUnit = _.find(landCoveringUnits.value, {
      fieldContentId: landCoveringDataField.fieldContentId,
    });

    if (landCoveringUnit) {
      return _.find(landCoveringUnitDataFields.value, {
        fieldContentId: landCoveringUnit.decoratingContentId,
      });
    } else return null;
  } else {
    return null;
  }
}
function enhancementsFor(focalDataField) {
  return getFieldChildrenFromCollection(
    focalDataField,
    propertyEnhancements.value,
  );
}
</script>
