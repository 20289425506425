import { ref } from "vue";
import { defineStore, acceptHMRUpdate } from "pinia";
import _ from "lodash";

export const usePayloadItemDataFieldChannelStore = defineStore(
  "payloadItemDataFieldsChannel",
  () => {
    const PayloadItemDataFieldChannel = ref(null);
    const payloadItemDataFieldsChannelDataQueue = ref([]);

    function pushAndTrim(data) {
      payloadItemDataFieldsChannelDataQueue.value.push(data);
      payloadItemDataFieldsChannelDataQueue.value = _.takeRight(
        payloadItemDataFieldsChannelDataQueue.value,
        3,
      );
    }

    return {
      PayloadItemDataFieldChannel,
      payloadItemDataFieldsChannelDataQueue,
      pushAndTrim,
    };
  },
);

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(usePayloadItemDataFieldChannelStore, import.meta.hot),
  );
}
