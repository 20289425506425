<template>
  <div
    class="flex flex-col divide-y divide-gray-200"
    :data-test="`Hunt-${huntId}-container`"
  >
    <div class="p-2 flex items-center justify-between text-gray-500 text-sm">
      <div class="flex items-center space-x-1">
        <button
          @click.prevent="expanded = !expanded"
          type="button"
          :data-test="`Hunt-${huntId}-details-header-collapse-icon`"
          class="h-5 w-5 inline-flex justify-center items-center text-sm text-gray-700 hover:text-gray-800"
        >
          <ChevronDownIcon class="h-5 w-5" />
        </button>
        <DataField
          :data-field="locationField"
          text-classes="text-sm font-medium"
          text-styles=""
          @open-sourced="refetch"
          @unlocked="refetch"
          @completed="refetch"
          @dismiss="refetch"
        />
      </div>
      <div class="flex items-center space-x-1">
        <div
          class="rounded w-4 h-4 border border-gray-700"
          :style="`background-color: ${locationColor || '#fdba74'}`"
        ></div>
      </div>
    </div>

    <div v-if="expanded" class="flex flex-1">
      <!-- Narrow sidebar-->
      <nav
        aria-label="Sidebar"
        class="flex-shrink-0 p-2 bg-gray-100 overflow-y-auto"
      >
        <div class="relative flex flex-col space-y-2">
          <a
            v-for="tab in tabs"
            :key="tab.name"
            @click.prevent="selectedTab = tab"
            :href="tab.href"
            v-tooltip="tab.name"
            :class="[
              selectedTab.name === tab.name
                ? 'text-gray-700 bg-gray-300'
                : 'text-gray-500 hover:bg-gray-200',
              'flex-shrink-0 inline-flex items-center justify-center h-8 w-8 rounded-lg',
            ]"
            class=""
            :data-test="`${_.kebabCase(tab.name)}-nav`"
          >
            <span class="sr-only">{{ tab.name }}</span>
            <svg
              :class="selectedTab.name === tab.name ? '' : ''"
              class="h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
                :d="tab.svgPath"
              />
            </svg>
          </a>
        </div>
      </nav>

      <div class="flex-grow">
        <component
          :is="selectedTab.component"
          v-bind="selectedTabProperties"
          @refetch="refetch"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import DataField from "@/components/crowdsourcing/DataField.vue";
import ContentAppearance from "@/components/crowdsourcing/ContentAppearance.vue";
import ContentPlayers from "@/components/crowdsourcing/ContentPlayers.vue";
import ContentPolygons from "@/components/crowdsourcing/ContentPolygons.vue";
import ContentNotes from "@/components/crowdsourcing/ContentNotes.vue";
import ComingSoon from "@/components/ComingSoon.vue";
import { ChevronDownIcon } from "@heroicons/vue/20/solid";
import { ref, markRaw, computed, onMounted } from "vue";
import _ from "lodash";

const props = defineProps([
  "hunt",
  "locationField",
  "decoratingContentDataField",
  "locationColor",
  "fromMenu",
]);
const emit = defineEmits(["refetch"]);

const expanded = ref(false);

const tabs = ref([
  {
    name: "Map Regions",
    href: "",
    svgPath:
      "M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z",
    component: markRaw(ContentPolygons),
  },
  {
    name: "Players",
    href: "",
    svgPath:
      "M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z",
    component: markRaw(ContentPlayers),
  },
  {
    name: "Appearance",
    href: "",
    svgPath:
      "M10 6H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V8a2 2 0 00-2-2h-5m-4 0V5a2 2 0 114 0v1m-4 0a2 2 0 104 0m-5 8a2 2 0 100-4 2 2 0 000 4zm0 0c1.306 0 2.417.835 2.83 2M9 14a3.001 3.001 0 00-2.83 2M15 11h3m-3 4h2",
    component: markRaw(ContentAppearance),
  },
  {
    name: "Notes",
    href: "",
    svgPath:
      "M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z",
    component: markRaw(ContentNotes),
  },
]);
const selectedTab = ref({
  name: "Map Regions",
  href: "",
  svgPath:
    "M96 151.4V360.6c9.7 5.6 17.8 13.7 23.4 23.4H328.6c0-.1 .1-.2 .1-.3l-4.5-7.9-32-56 0 0c-1.4 .1-2.8 .1-4.2 .1c-35.3 0-64-28.7-64-64s28.7-64 64-64c1.4 0 2.8 0 4.2 .1l0 0 32-56 4.5-7.9-.1-.3H119.4c-5.6 9.7-13.7 17.8-23.4 23.4zM384.3 352c35.2 .2 63.7 28.7 63.7 64c0 35.3-28.7 64-64 64c-23.7 0-44.4-12.9-55.4-32H119.4c-11.1 19.1-31.7 32-55.4 32c-35.3 0-64-28.7-64-64c0-23.7 12.9-44.4 32-55.4V151.4C12.9 140.4 0 119.7 0 96C0 60.7 28.7 32 64 32c23.7 0 44.4 12.9 55.4 32H328.6c11.1-19.1 31.7-32 55.4-32c35.3 0 64 28.7 64 64c0 35.3-28.5 63.8-63.7 64l-4.5 7.9-32 56-2.3 4c4.2 8.5 6.5 18 6.5 28.1s-2.3 19.6-6.5 28.1l2.3 4 32 56 4.5 7.9z",
  component: markRaw(ContentPolygons),
});

const selectedTabProperties = computed(() => {
  return {
    dataField: props.locationField,
    contentDataField: props.decoratingContentDataField,
    focus: false,
    context: "hunt",
  };
});
const huntId = computed(
  () => props.hunt?.id || props.locationField?.decoratingContentId,
);

onMounted(() => {
  if (props.fromMenu) {
    expanded.value = true;
  }
});

function refetch() {
  emit("refetch");
}
</script>
